import { SearchIndexTypeConfig } from '../../SchemaManagement/SchemaManagement.config';
import { ProductItem, Product } from './ProductPreview.types';
import _ from 'lodash';

export const mapProductListModelToDto = (productItemList: ProductItem[]): Product => {
  let product = {};

  for (let i = 0; i < productItemList.length; i++) {
    const productItem = productItemList[i];
    if (!!SearchIndexTypeConfig[productItem.type] && SearchIndexTypeConfig[productItem.type].dataType === 'Object') {
      const nestedObjectValue = mapProductListModelToDto(productItem.value as ProductItem[]);
      if (!_.isEqual(nestedObjectValue, SearchIndexTypeConfig[productItem.type].defaultValue)) {
        product = { ...product, [productItem.name]: nestedObjectValue };
      }
    } else if (
      !!productItem.value ||
      _.isEqual(productItem.value, SearchIndexTypeConfig[productItem.type].defaultValue)
    ) {
      product = { ...product, [productItem.name]: productItem.value };
    }
  }

  return product;
};

export const getProductItemList = (productItemList: ProductItem[], productItemKey: string): ProductItem[] | null => {
  const productItemIndex = productItemList.findIndex(productItem => productItem.key === productItemKey);
  if (productItemIndex !== -1) {
    return productItemList[productItemIndex].value as ProductItem[];
  }

  for (let i = 0; i < productItemList.length; i++) {
    if (
      !!SearchIndexTypeConfig[productItemList[i].type] &&
      SearchIndexTypeConfig[productItemList[i].type].dataType !== 'Object'
    ) {
      continue;
    }

    const candidateProductItemList = getProductItemList(productItemList[i].value as ProductItem[], productItemKey);
    if (candidateProductItemList !== null) {
      return candidateProductItemList;
    }
  }

  return null;
};
