import React, { useState, useEffect } from 'react';
import { classNamesFunction, Stack, Toggle, Callout, Label } from 'office-ui-fabric-react';
import {
  ScheduleCalloutProps,
  ScheduleCalloutStyleProps,
  ScheduleCalloutStyles,
  ValidationErrorType,
} from './ScheduleCallout.types';
import { Moment } from 'moment';
import moment from 'moment';
import { DateTimePicker } from '../../../../components/common/DateTimePicker';
import { PageFooter } from '../../../../components/common/PageFooter';

const getClassNames = classNamesFunction<ScheduleCalloutStyleProps, ScheduleCalloutStyles>();

export const ScheduleCalloutBase = (props: ScheduleCalloutProps) => {
  const { styles, theme, className, hidden, target, startDate, endDate, onSave, onDismiss } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const [currentStartDate, setCurrentStartDate] = useState<Moment | undefined>(startDate);
  const [currentEndDate, setCurrentEndDate] = useState<Moment | undefined>(endDate);
  const [scheduleEnabled, setScheduleEnabled] = useState<boolean>(!!startDate && !!endDate);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorType[]>([]);

  useEffect(() => {
    if (currentStartDate && currentEndDate && currentStartDate >= currentEndDate) {
      setValidationErrors(errors => [...errors.concat(ValidationErrorType.DATE_CONFLICT)]);
    } else {
      setValidationErrors(errors => [...errors.filter(e => e !== ValidationErrorType.DATE_CONFLICT)]);
    }
    return () => {
      setValidationErrors([]);
    };
  }, [currentEndDate, currentStartDate]);

  const onScheduleToglle = (enabled: boolean) => {
    setScheduleEnabled(enabled);
    if (enabled) {
      setCurrentStartDate(startDate);
      setCurrentEndDate(endDate);
    } else {
      setCurrentStartDate(undefined);
      setCurrentEndDate(undefined);
    }
  };
  const onSaveHandler = () => {
    if (scheduleEnabled) {
      onSave(currentStartDate, currentEndDate);
    } else {
      onSave(undefined, undefined);
    }
  };

  const onDismissHandler = () => {
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    setScheduleEnabled(!!startDate && !!endDate);
    onDismiss();
  };

  return (
    <Callout hidden={hidden} target={target} gapSpace={0} onDismiss={onDismissHandler} setInitialFocus>
      <Stack className={classNames.root} tokens={{ childrenGap: 10 }}>
        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'baseline'}>
          <Label styles={{ root: { fontSize: 16 } }}>Schedule</Label>
          <Toggle
            checked={scheduleEnabled}
            offText={'Off'}
            onText={'On'}
            onChange={(ev, checked) => onScheduleToglle(checked || false)}
          ></Toggle>
        </Stack>
        <DateTimePicker
          disabled={!scheduleEnabled}
          date={!!currentStartDate ? currentStartDate.toDate() : undefined}
          label={'Start date:'}
          onDateChange={date => setCurrentStartDate(moment(date))}
        />
        <DateTimePicker
          disabled={!scheduleEnabled}
          date={!!currentEndDate ? currentEndDate.toDate() : undefined}
          label={'End date:'}
          onDateChange={date => setCurrentEndDate(moment(date))}
        />
        <div className={classNames.validationErrors}>
          {validationErrors.map(error => {
            switch (error) {
              case ValidationErrorType.DATE_CONFLICT:
                return <div>Invalid schedule, start date must be before end date.</div>;
            }
          })}
        </div>
        <PageFooter
          styles={{ root: { paddingRight: 16, backgroundColor: 'none' } }}
          saveButtonText={'Save'}
          saveButtonDisabled={
            (startDate === currentStartDate && endDate === currentEndDate) || validationErrors.length > 0
          }
          onSave={onSaveHandler}
          onCancel={onDismissHandler}
        />
      </Stack>
    </Callout>
  );
};
