import React, { useState } from 'react';
import { classNamesFunction, Modal } from 'office-ui-fabric-react';
import {
  ProductSupportPanelProps,
  ProductSupportPanelStyleProps,
  ProductSupportPanelStyles,
} from './ProductSupportPanel.types';
import { ProductSupportPanelItem } from './ProductSupportPanelItem';
import { ContactUs } from '../../../../common/ContactUs';
import { CustomPanel } from '../../../../common/CustomPanel';
import { siteConsent } from '../../../../../services/consent.service';

const getClassNames = classNamesFunction<ProductSupportPanelStyleProps, ProductSupportPanelStyles>();

export const ProductSupportPanelBase = (props: ProductSupportPanelProps) => {
  const { isOpen, onDismiss, styles, className, theme, componentRef, t } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [showContactSupportForm, setShowContactSupportForm] = useState(false);

  const onClickContactSupport = () => {
    setShowContactSupportForm(true);
  };

  const manageCookies = () => {
    siteConsent && siteConsent.manageConsent();
    onDismiss();
  };

  const getSupportPanelItems = () => {
    let items = [
      {
        text: t('product-support-panel.frequently-asked-questions'),
        href: 'https://www.microsoft.com/en-us/bing/commerce#faq',
        iconName: 'FAQ',
      },
      {
        text: t('product-support-panel.contact-support'),
        iconName: 'ContactSupport',
        onClick: () => onClickContactSupport(),
      },
      {
        text: t('product-support-panel.privacy-cookies'),
        href: 'https://go.microsoft.com/fwlink/?LinkId=521839',
        iconName: 'KnowledgeArticle',
      },
      {
        text: t('product-support-panel.legal'),
        href: 'https://go.microsoft.com/fwlink/?LinkID=246338',
        iconName: 'KnowledgeArticle',
      },
    ];

    if (siteConsent && siteConsent.isConsentRequired) {
      items = items.concat([
        {
          text: t('homepage.footer.manage-cookies'),
          iconName: 'KnowledgeArticle',
          onClick: () => manageCookies(),
        },
      ]);
    }
    return items;
  };

  return (
    <div className={classNames.root} ref={componentRef}>
      <Modal isOpen={showContactSupportForm}>
        <ContactUs supportEmail={'contactMBC@microsoft.com'} onDismiss={() => setShowContactSupportForm(false)} />
      </Modal>

      <CustomPanel
        headerText={t('product-support-panel.header')}
        isHiddenOnDismiss={false}
        isOpen={isOpen}
        onDismiss={onDismiss}
        lightDismiss
      >
        <ProductSupportPanelItem listItems={getSupportPanelItems()} />

        <ProductSupportPanelItem
          title={t('product-support-panel.documentation-title')}
          subtitle={t('product-support-panel.documentation-subtitle')}
          listItems={[
            {
              text: t('product-support-panel.retail-search-api-docs'),
              href: '/docs/product-search/',
              iconName: 'Share',
            },
            {
              text: t('product-support-panel.portal-api-docs'),
              href: '/docs/Portal Documentation/',
              iconName: 'Share',
            },
          ]}
        />
      </CustomPanel>
    </div>
  );
};
