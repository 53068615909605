import { connectedLoading } from './ConnectedLoading/ConnectedLoading';

export const ActiveTenantLoading = connectedLoading(
  state => state.tenantsList.isLoading || !state.tenantsList.activeTenant.id,
);
export const ActiveEnvironmentLoading = connectedLoading(
  state => state.searchIndex.isLoading || !state.environmentList.activeEnvironment.id,
);

export const UserPermissionLoading = connectedLoading(
  state => state.roleList.isLoading || state.environmentList.isLoading,
);

export const ActiveRoleLoading = connectedLoading(state => {
  return state.roleList.isLoading || !state.roleList.activeRole.id || state.environmentList.isLoading;
});

export const ActiveSearchIndexLoading = connectedLoading(
  state => state.searchInstanceList.isLoading || !state.searchIndex.searchIndex.id,
);
export const ActiveSearchModelLoading = connectedLoading(
  state =>
    state.datasets.isLoading ||
    state.cookingJobs.isLoading ||
    state.searchModels.isLoading ||
    state.trainingJobs.isLoading ||
    state.evaluationJobs.isLoading ||
    state.deploymentJobs.isLoading ||
    !state.searchModels.activeSearchModel.key,
);
export const ActiveEvaluationJobLoading = connectedLoading(
  state =>
    state.datasets.isLoading ||
    state.cookingJobs.isLoading ||
    state.searchModels.isLoading ||
    state.trainingJobs.isLoading ||
    state.deploymentJobs.isLoading ||
    state.evaluationJobs.isLoading ||
    !state.searchModels.activeSearchModel.key ||
    !state.evaluationJobs.activeEvaluationJob.key,
);

export const ActiveSearchInstanceLoading = connectedLoading(
  state => state.searchInstanceList.isLoading || !state.searchInstanceList.activeSearchInstance.id,
);

export const ActiveBusinessRuleLoading = connectedLoading(
  state =>
    state.businessRule.isLoading ||
    state.businessRule.isRuleDetailsLoading ||
    !state.businessRule.activeBusinessRule.id,
);

export const ActiveSynonymSetLoading = connectedLoading(
  state => state.synonymSet.isLoading || !state.synonymSet.activeSynonymSet.id,
);

export const ActiveUrlRedirectSetLoading = connectedLoading(
  state => state.urlRedirectSet.isLoading || !state.urlRedirectSet.activeUrlRedirectSet.id,
);
