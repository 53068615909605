import { useSelector } from '..';
import { useMemo } from 'react';
import { SearchModel as SearchModelBase, ModelJobStatus } from '../../types/customML/searchModel.d';
import { useCookingJobList } from './useCookingJobList';
import { useRawDatasetList } from './useRawDatasetList';
import { getLatestJob } from '../../../utils';

export enum ModelJob {
  NotStarted = 0,
  ManageData = 1,
  CookingData = 2,
  TrainingModel = 3,
  EvaluatingModel = 4,
  DeployingModel = 5,
  Completed = 6,
}

interface Job {
  job: ModelJob;
  status: ModelJobStatus;
}

export type SearchModel = SearchModelBase & Job;

export const useSearchModelList = (): SearchModel[] => {
  const datasetList = useRawDatasetList();
  const cookingJobList = useCookingJobList();
  const searchModelMapper = useSelector(state => state.searchModels.searchModelMapper);
  const deploymentJobMapper = useSelector(state => state.deploymentJobs.deploymentJobMapper);
  const evaluationJobMapper = useSelector(state => state.evaluationJobs.evaluationJobMapper);
  const trainingJobMapper = useSelector(state => state.trainingJobs.trainingJobMapper);

  return useMemo(() => {
    const getSearchModelStatus = (searchModel: SearchModelBase): Job => {
      if (!!searchModel.deploymentJobKeys && searchModel.deploymentJobKeys.length > 0) {
        const deploymentJob = getLatestJob(
          searchModel.deploymentJobKeys.map(deploymentJobKey => deploymentJobMapper[deploymentJobKey]),
        );
        return {
          job: ModelJob.DeployingModel,
          status: deploymentJob === null ? ModelJobStatus.Unknown : deploymentJob.status,
        };
      }
      if (!!searchModel.evaluationJobKeys && searchModel.evaluationJobKeys.length > 0) {
        const evaluationJob = getLatestJob(
          searchModel.evaluationJobKeys.map(evaluationJobKey => evaluationJobMapper[evaluationJobKey]),
        );
        return {
          job: ModelJob.EvaluatingModel,
          status: evaluationJob === null ? ModelJobStatus.Unknown : evaluationJob.status,
        };
      }
      if (!!searchModel.trainingJobKeys && searchModel.trainingJobKeys.length > 0) {
        const trainingJob = getLatestJob(
          searchModel.trainingJobKeys.map(trainingJobKey => trainingJobMapper[trainingJobKey]),
        );
        return {
          job: ModelJob.TrainingModel,
          status: trainingJob === null ? ModelJobStatus.Unknown : trainingJob.status,
        };
      }
      if (datasetList.length > 0 && cookingJobList.length === 0) {
        return {
          job: ModelJob.CookingData,
          status: ModelJobStatus.Pending,
        };
      }
      if (datasetList.length > 0 && cookingJobList.length >= 0) {
        const hasCompletedCookingJob = cookingJobList.findIndex(job => job.status === ModelJobStatus.Finished) > -1;
        if (hasCompletedCookingJob) {
          return {
            job: ModelJob.CookingData,
            status: ModelJobStatus.Finished,
          };
        }
        const hasRunningCookingJob = cookingJobList.findIndex(job => job.status === ModelJobStatus.Running) > -1;
        if (hasRunningCookingJob) {
          return {
            job: ModelJob.CookingData,
            status: ModelJobStatus.Running,
          };
        }
        const latestCookingJob = getLatestJob(cookingJobList)!;
        return {
          job: ModelJob.CookingData,
          status: latestCookingJob.status,
        };
      }
      return {
        job: ModelJob.ManageData,
        status: ModelJobStatus.Pending,
      };
    };
    return Object.values(searchModelMapper).map(searchModel => ({
      ...searchModel,
      ...getSearchModelStatus(searchModel),
    }));
  }, [
    cookingJobList,
    datasetList.length,
    deploymentJobMapper,
    evaluationJobMapper,
    searchModelMapper,
    trainingJobMapper,
  ]);
};
