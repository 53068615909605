import { AppState } from '../../reducers';
import { useSelector } from '../../hooks';
import { useState, useEffect } from 'react';
import { createUserPermissions } from '../../../guards/PermissionGuard/utils';
import { useUserRoleList } from '../use-list/useUserRoleList';

export const usePermissions = () => {
  const roleList = useUserRoleList();
  const isRoleListLoading = useSelector((state: AppState) => state.roleList.isLoading);

  const [userPermissions, setUserPermissions] = useState(() => createUserPermissions(roleList));

  useEffect(() => {
    setUserPermissions(createUserPermissions(roleList));
  }, [isRoleListLoading, roleList]);

  return userPermissions;
};
