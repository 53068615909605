import React, { useState, useEffect } from 'react';
import { Stack, classNamesFunction, Pivot, PivotItem } from 'office-ui-fabric-react';
import {
  SetResponseCodesFilter,
  SetRegionsDistributionFilter,
  SetSearchInstancesFilter,
  setSearchAvgQPS,
  UpdateAllPerformanceCharts,
  UpdateAllTrafficCharts,
  ResetAnalyticsSelectedFilters,
  setSelectedSearchInstancesFilter,
  setSelectedRegionsFilter,
  setSelectedResponseCodesFilter,
} from '../../../store/actions/analyticsActions';

import { TopQueries } from './TopQueries';
import { SearchRequests } from './SearchRequests';
import { NoResultsQueries } from './NoResultsQueries';
import { ResponseCodes } from './ResponseCodes';
import { SearchInstances } from './SearchInstances';
import { ServingRegions } from './ServingRegions';
import { FilterCategories } from './FilterCategories';
import { FiltersUsage } from './FiltersUsage';
import { ExportAllCharts } from './ExportAllCharts';
import { PageTemplate } from '../../../components/common/PageTemplate';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { AnalyticsFilters } from './AnalyticsFilters';
import {
  SearchAnalyticsBaseProps,
  SearchAnalyticsStyleProps,
  SearchAnalyticsStyles,
  AnalyticsTabs,
} from './SearchAnalytics.types';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../store/hooks';
import { toggleSideNavExpanded } from '../../../store/actions/sideNavActions';
import { SearchLatency } from './SearchLatency';
import { SearchPerformanceMetricCards } from './SearchPerformanceMetricCards';
import { SearchTrafficMetricCards } from './SearchTrafficMetricCards';
import { SearchQueries } from './SearchQueries';
import { getAggregationInterval } from './SearchAnalytics.utils';
import moment from 'moment';
import { addDays } from '../../../utils';
import { AggregationInterval } from '../../../restful-apis/dto/analytics/analytics';
import { DateFormat } from '../../../store/types/analytics.d';

const getClassNames = classNamesFunction<SearchAnalyticsStyleProps, SearchAnalyticsStyles>();

export const SearchAnalyticsBase = (props: SearchAnalyticsBaseProps) => {
  const { t, className, styles, theme } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);

  const [activeTab, setActiveTab] = useState<string>(AnalyticsTabs.Peformance);
  // To prevent resending calls if no filters have been changed when switching tabs
  const [shouldUpdateTab, setShouldUpdateTab] = useState(true);

  const regionsDistributionFilter = useSelector(state => state.analytics.regionsDistributionFilter);
  const responseCodesFilter = useSelector(state => state.analytics.responseCodesFilter);
  const searchInstancesFilter = useSelector(state => state.analytics.searchInstancesFilter);
  const searchResultsFilter = useSelector(state => state.analytics.searchResultsFilter);
  const startDate = useSelector(state => state.analytics.startDateFilter);
  const endDate = useSelector(state => state.analytics.endDateFilter);
  const searchLatencyPercentile = useSelector(state => state.analytics.searchLatencyPercentile);
  const latencyCardPercentile = useSelector(state => state.analytics.latencyCardPercentile);

  const dispatch = useDispatch();

  // Get filter values only once at beginning.
  useEffect(() => {
    dispatch(SetRegionsDistributionFilter());
    dispatch(SetResponseCodesFilter());
    dispatch(SetSearchInstancesFilter());
    dispatch(setSearchAvgQPS());
    dispatch(
      UpdateAllPerformanceCharts(
        [],
        addDays(-1, new Date(moment.utc().format(DateFormat))),
        new Date(moment.utc().format(DateFormat)),
        [],
        [],
        [],
        [],
        AggregationInterval.HOURLY,
        75,
        NaN,
      ),
    );
    return () => {
      dispatch(ResetAnalyticsSelectedFilters());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setShouldUpdateTab(true);
  }, [
    regionsDistributionFilter.selectedValues,
    responseCodesFilter.selectedValues,
    searchInstancesFilter.selectedValues,
    searchResultsFilter.selectedValues,
    startDate,
    endDate,
  ]);

  return (
    <PageTemplate
      sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
      sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
      pageHeaderProps={{ title: t('analytics.title') }}
      breadcrumbVisible
      onRenderContent={() => (
        <div className={classNames.root}>
          <div className={classNames.exportButton}>
            <ExportAllCharts />
          </div>

          <AnalyticsFilters activeTab={activeTab} />
          <Pivot
            onLinkClick={(item?: PivotItem) => {
              if (!!item && item.props.itemKey) {
                if (shouldUpdateTab) {
                  if (item.props.itemKey === AnalyticsTabs.Peformance && activeTab !== AnalyticsTabs.Peformance) {
                    dispatch(
                      UpdateAllPerformanceCharts(
                        responseCodesFilter.selectedValues.checkedValues,
                        startDate,
                        endDate,
                        [], // Markets
                        regionsDistributionFilter.selectedValues.checkedValues,
                        searchResultsFilter.selectedValues.checkedValues,
                        searchInstancesFilter.selectedValues.checkedValues,
                        getAggregationInterval(startDate, endDate),
                        searchLatencyPercentile,
                        latencyCardPercentile,
                        false,
                      ),
                    );
                    setShouldUpdateTab(false);
                  } else if (item.props.itemKey === AnalyticsTabs.Traffic && activeTab !== AnalyticsTabs.Traffic) {
                    dispatch(
                      UpdateAllTrafficCharts(
                        responseCodesFilter.selectedValues.checkedValues,
                        startDate,
                        endDate,
                        [], // Markets
                        regionsDistributionFilter.selectedValues.checkedValues,
                        searchResultsFilter.selectedValues.checkedValues,
                        searchInstancesFilter.selectedValues.checkedValues,
                        getAggregationInterval(startDate, endDate),
                        false,
                      ),
                    );
                    setShouldUpdateTab(false);
                  }
                }
                if (activeTab !== item.props.itemKey) {
                  setActiveTab(item.props.itemKey);
                }
              }
            }}
          >
            <PivotItem headerText={'Performance insights'} itemKey={AnalyticsTabs.Peformance}>
              {
                <>
                  <Stack tokens={{ childrenGap: 24 }} style={{ marginTop: 24 }}>
                    <SearchPerformanceMetricCards />
                  </Stack>
                  <div className={classNames.mainStack}>
                    <SearchRequests className={classNames.searchRequestCharts} />
                    <SearchLatency className={classNames.searchLatencyCharts} />
                    <ServingRegions
                      onRegionInvoked={(activeRegion: string) => {
                        if (activeRegion) {
                          dispatch(
                            setSelectedRegionsFilter(
                              [activeRegion],
                              regionsDistributionFilter.availableValues.length === 1,
                            ),
                          );
                        }
                      }}
                      className={classNames.servingRegions}
                    />
                    <ResponseCodes
                      onResponseCodeInvoked={(activeResponseCode: string) => {
                        if (activeResponseCode) {
                          dispatch(
                            setSelectedResponseCodesFilter(
                              [activeResponseCode],
                              responseCodesFilter.availableValues.length === 1,
                            ),
                          );
                        }
                      }}
                      className={classNames.chartsGridThirdRow}
                    />
                  </div>
                </>
              }
            </PivotItem>
            <PivotItem headerText={'Traffic analysis'} itemKey={AnalyticsTabs.Traffic}>
              {
                <>
                  <Stack tokens={{ childrenGap: 24 }} style={{ marginTop: 24 }}>
                    <SearchTrafficMetricCards />
                  </Stack>
                  <div className={classNames.mainStack}>
                    <SearchQueries className={classNames.searchRequestCharts} />
                    <TopQueries className={classNames.topQueries} />
                    <NoResultsQueries className={classNames.noResultQueries} />
                    <SearchInstances
                      onSearchInstanceInvoked={(activeSearchInstance: string) => {
                        if (activeSearchInstance) {
                          dispatch(
                            setSelectedSearchInstancesFilter(
                              [activeSearchInstance],
                              searchInstancesFilter.availableValues.length === 1,
                            ),
                          );
                        }
                      }}
                      className={classNames.chartsGridThirdRow}
                    />
                    <FiltersUsage className={classNames.filtersUsage} />
                    <FilterCategories className={classNames.filterCategoriesChart} />
                  </div>
                </>
              }
            </PivotItem>
          </Pivot>
        </div>
      )}
    />
  );
};
