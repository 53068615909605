import { ActionFailureHandler, ActionSuccessHandler, ReduxCrudAction } from '../../CrudAction.types';
import { SAVE_ACTIVE_SEARCH_INDEX, SearchIndex } from '../../../../types/searchIndex.d';
import {
  SET_ENVIRONMENT_SEARCH_INDEX_UPDATED,
  SET_SEARCH_INDEX_ID,
  UPDATING_ENVIRONMENT_SEARCH_INDEX,
} from '../../../../types/environmentList.d';
import { createSearchIndex, updateSearchIndex } from '../../../../../restful-apis/searchIndex.api';

import { getI18n } from 'react-i18next';

export const updateSearchIndexSchemaAction = (
  tenantId: string,
  environmentId: string,
  searchIndex: SearchIndex,
  // commitMetadata: DevOpsCommitMetadata | undefined,
  onSuccess?: ActionSuccessHandler<SearchIndex>,
  onFailure?: ActionFailureHandler<SearchIndex>,
): ReduxCrudAction<SearchIndex> => {
  const i18n = getI18n();
  return {
    request: updateSearchIndex(tenantId, searchIndex),
    preActionMapper: {
      type: UPDATING_ENVIRONMENT_SEARCH_INDEX,
      environmentId: environmentId,
    },
    postActionMapper: updatedSearchIndex => [
      {
        type: SAVE_ACTIVE_SEARCH_INDEX,
        searchIndex: updatedSearchIndex,
      },
      {
        type: SET_ENVIRONMENT_SEARCH_INDEX_UPDATED,
        environmentId: environmentId,
      },
    ],
    notification: {
      failure: () => ({
        title: i18n.t('schema-management.update-req-failure', { name: searchIndex.id }),
      }),
      inProgress: {
        title: i18n.t('schema-management.update-req-inprogress', { name: searchIndex.id }),
      },
      success: () => ({
        title: i18n.t('schema-management.update-req-success', { name: searchIndex.id }),
      }),
    },

    onSuccess: onSuccess,
    onFailure: (response, dispatch) => {
      dispatch({
        type: SET_ENVIRONMENT_SEARCH_INDEX_UPDATED,
        environmentId: environmentId,
      });
      onFailure && onFailure(response, searchIndex);
    },
  };
};

export const createSearchIndexSchemaAction = (
  tenantId: string,
  environmentId: string,
  searchIndex: SearchIndex,
  // commitMetadata: DevOpsCommitMetadata | undefined,
  onSuccess?: ActionSuccessHandler<SearchIndex>,
  onFailure?: ActionFailureHandler<SearchIndex>,
): ReduxCrudAction<SearchIndex> => {
  const i18n = getI18n();
  return {
    request: createSearchIndex(tenantId, environmentId, searchIndex),
    preActionMapper: {
      type: UPDATING_ENVIRONMENT_SEARCH_INDEX,
      environmentId: environmentId,
    },
    postActionMapper: updatedSearchIndex => [
      {
        type: SAVE_ACTIVE_SEARCH_INDEX,
        searchIndex: updatedSearchIndex,
      },
      {
        type: SET_SEARCH_INDEX_ID,
        indexId: updatedSearchIndex.id,
      },
      {
        type: SET_ENVIRONMENT_SEARCH_INDEX_UPDATED,
        environmentId: environmentId,
      },
    ],
    notification: {
      failure: () => ({
        title: i18n.t('schema-management.create-req-failure', { name: searchIndex.id }),
      }),
      inProgress: {
        title: i18n.t('schema-management.create-req-inprogress', { name: searchIndex.id }),
      },
      success: () => ({
        title: i18n.t('schema-management.create-req-success', { name: searchIndex.id }),
      }),
    },

    onSuccess: newSearchIndex => {
      onSuccess && onSuccess(newSearchIndex);
    },
    onFailure: (response, dispatch) => {
      dispatch({
        type: SET_ENVIRONMENT_SEARCH_INDEX_UPDATED,
        environmentId: environmentId,
      });
      onFailure && onFailure(response, searchIndex);
    },
  };
};
