import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../../router/MbcRouter.base';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { useDispatch, useSelector } from '../../../store/hooks';
import { ActiveSearchInstanceLoading } from '../../../components/Loading';
import { useSearchInstanceRouter } from '../../../store/hooks/use-router/useSearchInstanceRouter';
import {
  setActiveSearchInstance,
  resetActiveSearchInstance,
} from '../../../store/actions/customizations/searchInstanceListActions';
import { useProxyRouter } from '../../../store/hooks/use-router/useProxyRouter';
import { useFacetableStringFields } from '../../../store/hooks/use-index-schema/useFacetableStringFields';
import {
  getFieldValueSuggestions,
  resetFieldValueSuggestions,
} from '../../../store/actions/customizations/queryTesterActions';
import { useActiveSearchInstanceAsserter } from '../../../store/hooks/use-asserter/useAsserter';

export const SearchInstanceProxy = (props: RouteComponentProps<{ searchInstanceId: string }>) => {
  const dispatch = useDispatch();

  const MbcSearchInstanceRouteMap = useSearchInstanceRouter();
  const MbcProxyRouteMapper = useProxyRouter();

  const facetableStringFields = useFacetableStringFields();
  const isSearchInstanceListLoading = useSelector(state => state.searchInstanceList.isLoading);

  useActiveSearchInstanceAsserter(props.match.params.searchInstanceId);

  useEffect(() => {
    dispatch(setActiveSearchInstance(props.match.params.searchInstanceId));
    return () => {
      dispatch(resetActiveSearchInstance());
    };
  }, [dispatch, isSearchInstanceListLoading, props, props.match.params.searchInstanceId]);

  useEffect(() => {
    dispatch(getFieldValueSuggestions(facetableStringFields));
    return () => {
      dispatch(resetFieldValueSuggestions());
    };
  }, [dispatch, facetableStringFields]);

  return (
    <ActiveSearchInstanceLoading>
      <MbcRouter
        routes={[
          MbcProxyRouteMapper(MbcRouteKey.BusinessRuleListProxy),
          MbcProxyRouteMapper(MbcRouteKey.UrlRedirectSetListProxy),
          MbcProxyRouteMapper(MbcRouteKey.SynonymSetListProxy),
          MbcSearchInstanceRouteMap(MbcRouteKey.CustomMLRanker),
        ]}
      />
    </ActiveSearchInstanceLoading>
  );
};
