import { ResponseInterceptors } from './response-interceptors/ResponseInterceptors.config';
import { RequestInterceptors } from './request-interceptors/RequestInterceptors.config';
import { FetchRequest, FetchResponse } from './Types';

export async function ExecuteRequestInterceptors(request: FetchRequest): Promise<FetchRequest> {
  return RequestInterceptors.reduce((p, interceptor) => {
    return p.then(request => interceptor.Execute(request));
  }, Promise.resolve(request));
}

export async function ExecuteResponseInterceptors(response: FetchResponse): Promise<FetchResponse> {
  return ResponseInterceptors.reduce((p, interceptor) => {
    return p.then(response => interceptor.Execute(response));
  }, Promise.resolve(response));
}
