import React from 'react';
import {
  classNamesFunction,
  Stack,
  IconButton,
  StackItem,
  TooltipHost,
  DirectionalHint,
  TooltipDelay,
} from 'office-ui-fabric-react';
import { CollapsiblePaneStyles, CollapsiblePaneStyleProps, CollapsiblePaneProps } from './CollapsiblePane.types';
import { PageFooter } from '../../PageFooter';

const getClassNames = classNamesFunction<CollapsiblePaneStyleProps, CollapsiblePaneStyles>();

export const CollapsiblePaneBase = (props: CollapsiblePaneProps) => {
  const {
    styles,
    theme,
    className,
    onRenderPaneHeader,
    footerProps,
    onRenderPaneContent,
    isExpanded,
    onSwitchStateClicked,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <Stack className={classNames.root} grow>
      <Stack
        className={isExpanded ? classNames.expandedHeader : classNames.collapsedHeader}
        horizontal
        verticalAlign={'center'}
        horizontalAlign={!!onRenderPaneHeader ? 'space-between' : 'end'}
      >
        {!!onRenderPaneHeader && (
          <StackItem grow className={isExpanded ? undefined : classNames.collapsedHeaderContent}>
            {onRenderPaneHeader()}
          </StackItem>
        )}
        <TooltipHost
          content={!isExpanded ? 'Customize settings' : undefined}
          directionalHint={DirectionalHint.leftCenter}
          delay={TooltipDelay.long}
        >
          <IconButton
            iconProps={{
              iconName: 'SidePanelMirrored',
            }}
            onClick={() => {
              onSwitchStateClicked();
            }}
          />
        </TooltipHost>
      </Stack>
      <Stack className={isExpanded ? classNames.expandedBody : classNames.collapsedBody} grow>
        <Stack grow>
          <Stack verticalAlign={'space-between'} grow>
            <Stack className={classNames.paneContent} grow>
              {onRenderPaneContent()}
            </Stack>
            {footerProps && (
              <PageFooter
                styles={{ root: { paddingLeft: 24, alignItems: 'flex-start', backgroundColor: '#FFF' } }}
                {...footerProps}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
