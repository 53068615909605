import React from 'react';
import { PermissionProviderProps } from './PermissionProvider.types';
import { EnvironmentPermissionContext } from '../../contexts';
import { usePermissions } from '../../store/hooks/use-user-permissions/UsePermissions';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/reducers';
import { isAuthorized } from '../../utils';

export const EnvironmentPermissionProvider = (props: PermissionProviderProps): JSX.Element => {
  const userPermissions = usePermissions();
  const activeEnvironmentId = useSelector((state: AppState) => state.environmentList.activeEnvironment.id);

  const getPermissions = (environmentId?: string): string[] => {
    return userPermissions.environmentPermissionsMapper[environmentId || activeEnvironmentId].concat(
      userPermissions.allEnvironmentsPermissions,
    );
  };
  return (
    <EnvironmentPermissionContext.Provider
      value={{
        isAuthorized: (minimumPermissions, environmentId) =>
          isAuthorized(getPermissions(environmentId), minimumPermissions),
        getPermissions: getPermissions,
      }}
    >
      {props.children}
    </EnvironmentPermissionContext.Provider>
  );
};
