import {
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_SEEN,
  MARK_ALL_NOTIFICATIONS_AS_SEEN,
  NotificationsStateActionTypes,
  NotificationsState,
} from '../types/notifications.d';

const initialState: NotificationsState = {
  notifications: [],
};

export function notificationsReducer(state = initialState, action: NotificationsStateActionTypes): NotificationsState {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.notifications,
      };
    }
    case CLEAR_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [],
      };
    }
    case MARK_NOTIFICATION_AS_SEEN: {
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          if (notification.id === action.notificationId) {
            notification.seen = true;
          }
          return notification;
        }),
      };
    }
    case MARK_ALL_NOTIFICATIONS_AS_SEEN: {
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          notification.seen = true;
          return notification;
        }),
      };
    }
    default:
      return state;
  }
}
