import {
  AddBlockedSuggestionRequest,
  ServingResponse,
  GetBlockedSuggestionsResponse,
} from '../dto/autosuggest/autosuggest.d';
import { SuggestionItem, BlockedSuggestionModel, BlockedSuggestionMatchType } from '../../store/types/autosuggest.d';

export function mapBlockSuggestionModelToDTO(
  submittedBlockedSuggestion: BlockedSuggestionModel,
): AddBlockedSuggestionRequest {
  var numericMatchType = 0;
  switch (submittedBlockedSuggestion.matchingType) {
    case BlockedSuggestionMatchType.Exact: {
      numericMatchType = 1;
      break;
    }
    case BlockedSuggestionMatchType.Prefix: {
      numericMatchType = 2;
      break;
    }
    case BlockedSuggestionMatchType.Contains: {
      numericMatchType = 3;
      break;
    }
  }

  return {
    BlockedSuggestions: [
      {
        query: submittedBlockedSuggestion.query,
        matchingtype: numericMatchType,
      },
    ],
  };
}

export function mapBlockedSuggestionsDtoToModel(response: GetBlockedSuggestionsResponse): BlockedSuggestionModel[] {
  return response.blockedSuggestions.map(item => {
    return {
      ...item,
      matchingType: BlockedSuggestionMatchType[item.matchingType],
    };
  });
}

export function mapServingSuggestionsDtoToModel(res: ServingResponse): SuggestionItem[] {
  var ret: SuggestionItem[] = [];
  for (var grp of res.suggestionGroups) {
    var toAdd = grp.searchSuggestions.map((sugg, index) => {
      return {
        key: index,
        displayValue: sugg.displayText,
        searchValue: sugg.query,
      };
    });
    ret.push(...toAdd);
  }
  return ret;
}
