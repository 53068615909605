import { useSelector, useDispatch } from '..';
import { useEffect, useCallback, useState } from 'react';
import { useCookingJobList } from '../use-list/useCookingJobList';
import { ModelJobStatus } from '../../types/customML/searchModel.d';
import { useTrainingJobList } from '../use-list/useTrainingJobList';
import { useEvaluationJobList } from '../use-list/useEvaluationJobList';
import { useDeploymentJobList } from '../use-list/useDeploymentList';
import { updateCookingJobList } from '../../actions/customML/cookingJobActions';
import { updateTrainingJobList } from '../../actions/customML/trainingJobActions';
import { updateEvaluationJobList } from '../../actions/customML/evaluationJobActions';
import { updateDeploymentJobList } from '../../actions/customML/deploymentJobActions';
import { updateActiveTenant } from '../../actions/tenantsListActions';

const MAX_COOKING_INTERVAL_IN_MS = 1 * 60 * 1000;
const MAX_TRAINING_INTERVAL_IN_MS = 2 * 60 * 1000;
const MAX_EVALUATION_INTERVAL_IN_MS = 1 * 60 * 1000;
const MAX_DEPLOYMENT_INTERVAL_IN_MS = 1 * 60 * 1000;

export const useCustomMLJobPolling = (): void => {
  const dispatch = useDispatch();
  const cookingJobList = useCookingJobList();
  const trainingJobList = useTrainingJobList();
  const evaluationJobList = useEvaluationJobList();
  const deploymentJobList = useDeploymentJobList();

  const tenantId = useSelector(state => state.tenantsList.activeTenant.id);
  const cookingJobListLoading = useSelector(state => state.cookingJobs.isLoading);
  const trainingJobListLoading = useSelector(state => state.trainingJobs.isLoading);
  const deploymentJobListLoading = useSelector(state => state.deploymentJobs.isLoading);
  const evaluationJobListLoading = useSelector(state => state.evaluationJobs.isLoading);

  const [deployedJobCount, setDeployedJobCount] = useState<number | null>(null);

  const isInprogress = useCallback(
    (status: ModelJobStatus) =>
      status === ModelJobStatus.Pending || status === ModelJobStatus.Running || status === ModelJobStatus.Unknown,
    [],
  );

  const getDeployedJobCount = useCallback(
    () => deploymentJobList.filter(job => job.status === ModelJobStatus.Finished).length,
    [deploymentJobList],
  );

  useEffect(() => {
    if (deploymentJobListLoading) {
      return;
    }

    const updatedDeployedJobCount = getDeployedJobCount();
    if (deployedJobCount !== null && updatedDeployedJobCount !== deployedJobCount) {
      dispatch(updateActiveTenant());
    }

    setDeployedJobCount(updatedDeployedJobCount);
    return () => {};
  }, [dispatch, deploymentJobListLoading, deploymentJobList, deployedJobCount, getDeployedJobCount]);

  useEffect(() => {
    if (cookingJobListLoading) {
      return;
    }

    if (cookingJobList.some(cookingJob => isInprogress(cookingJob.status))) {
      setTimeout(() => dispatch(updateCookingJobList(tenantId)), MAX_COOKING_INTERVAL_IN_MS);
    }

    return () => {};
  }, [tenantId, dispatch, isInprogress, cookingJobListLoading, cookingJobList]);

  useEffect(() => {
    if (trainingJobListLoading) {
      return;
    }

    if (trainingJobList.some(trainingJob => isInprogress(trainingJob.status))) {
      setTimeout(() => dispatch(updateTrainingJobList(tenantId)), MAX_TRAINING_INTERVAL_IN_MS);
    }

    return () => {};
  }, [tenantId, dispatch, isInprogress, trainingJobListLoading, trainingJobList]);

  useEffect(() => {
    if (evaluationJobListLoading) {
      return;
    }

    if (evaluationJobList.some(evaluationJob => isInprogress(evaluationJob.status))) {
      setTimeout(() => dispatch(updateEvaluationJobList(tenantId)), MAX_EVALUATION_INTERVAL_IN_MS);
    }

    return () => {};
  }, [tenantId, dispatch, isInprogress, evaluationJobListLoading, evaluationJobList]);

  useEffect(() => {
    if (deploymentJobListLoading) {
      return;
    }

    if (deploymentJobList.some(deploymentJob => isInprogress(deploymentJob.status))) {
      setTimeout(() => dispatch(updateDeploymentJobList(tenantId)), MAX_DEPLOYMENT_INTERVAL_IN_MS);
    }

    return () => {};
  }, [tenantId, dispatch, isInprogress, deploymentJobListLoading, deploymentJobList]);
};
