import { MetricCardsStyleProps, MetricCardsStyles } from './MetricCards.types';

export const styles = (props: MetricCardsStyleProps): MetricCardsStyles => ({
  root: [
    'mbc-metric-cards',
    {
      display: 'grid',

      selectors: {
        '.mbc-content-card': {
          minWidth: 'unset !important',
          selectors: {
            '@media screen and (min-width: 1221px)': {
              maxWidth: 285,
            },
          },
        },
        '.mbc-content-card-body': {
          justifyContent: 'flex-start',
          paddingTop: 12,
          fontWeight: 600,
          fontSize: 42,
        },

        '@media screen and (min-width: 1221px)': {
          gridRowGap: '24px',
          gridColumnGap: '24px',
        },
        '@media screen and (max-width: 1220px) and (min-width: 1021px)': {
          gridTemplateColumns: '50% 50%',
          width: 'calc(100% - 21px)',
          gridRowGap: '21px',
          gridColumnGap: '21px',
        },
        '@media screen and (max-width: 1020px)': {
          gridRowGap: '21px',
          gridColumnGap: '21px',
        },
      },
    },

    props.className,
  ],
  productUpdatesCard: [
    'mbc-product-updates-card',
    {
      selectors: {
        '@media screen and (min-width: 1221px)': {
          alignSelf: 'end',
        },
      },
    },
  ],
});
