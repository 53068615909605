import { FilterCategoriesStyleProps, FilterCategoriesStyles } from './FilterCategories.types';

export const styles = (props: FilterCategoriesStyleProps): FilterCategoriesStyles => {
  return {
    root: ['mbc-serving-regions-chart', props.className],
    modalFirstColumn: [
      'mbc-modal-first-column',
      {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: 'rgb(0, 120, 212)',
        fontWeight: 500,
      },
    ],
  };
};
