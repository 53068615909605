import { useRef, useLayoutEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export type ContainerRect = Pick<DOMRect, 'top' | 'bottom' | 'left' | 'right' | 'height' | 'width'>;

export const useResizeObserver = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [containerRect, setContainerRect] = useState<ContainerRect>({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  });

  const observer = useRef(
    new ResizeObserver(entries => {
      // Only care about the first element, we expect one element to be watched
      setContainerRect(entries[0].target.getBoundingClientRect());
    }),
  );

  useLayoutEffect(() => {
    const currentContainerRef = containerRef.current;
    currentContainerRef && observer.current.observe(currentContainerRef);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      currentContainerRef && observer.current && observer.current.unobserve(currentContainerRef);
    };
  }, [containerRef, observer]);

  return containerRect;
};
