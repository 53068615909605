import { PermissionsMapper, UserPermissions } from './PermissionGuard.types';
import { configureDefaultObject, distinct, flat } from '../../utils';
import { Role } from '../../store/types/roleList';

const createPermissionsMapper = (initialMapper: PermissionsMapper = {}): PermissionsMapper => {
  return configureDefaultObject<PermissionsMapper, string[]>(initialMapper, []);
};

const addDistinctPermissions = (key: string, permissions: string[], mapper: PermissionsMapper) => {
  const existingPermissions = mapper[key];
  if (!existingPermissions) {
    mapper[key] = permissions;
  } else {
    mapper[key] = distinct(existingPermissions.concat(permissions), (x, y) => x === y);
  }
};

const createEnvironmentPermissionsMapper = (userRoleList: Role[]): PermissionsMapper => {
  return userRoleList.reduce((mapper, role) => {
    role.environmentList.forEach(environmentId => addDistinctPermissions(environmentId, role.permissions, mapper));
    return mapper;
  }, createPermissionsMapper());
};

const createAllEnvironmentsPermissions = (userRoleList: Role[]): string[] => {
  const allEnvironmentsPermissions: string[] = userRoleList
    .filter(role => role.hasAccessToAllEnvironments)
    .reduce(
      (permissions, role) => {
        return permissions.concat(role.permissions);
      },
      [] as string[],
    );

  return distinct(allEnvironmentsPermissions, (x, y) => x === y);
};

const createTenantPermissionsMapper = (userRoleList: Role[]): string[] => {
  return distinct(flat(userRoleList.map(role => role.permissions)), (x, y) => x === y);
};

export const createUserPermissions = (userRoleList: Role[]): UserPermissions => ({
  tenantPermissions: createTenantPermissionsMapper(userRoleList),
  allEnvironmentsPermissions: createAllEnvironmentsPermissions(userRoleList),
  environmentPermissionsMapper: createEnvironmentPermissionsMapper(userRoleList),
});

export const createEmptyUserRole = (): UserPermissions => ({
  tenantPermissions: [],
  allEnvironmentsPermissions: [],
  environmentPermissionsMapper: createPermissionsMapper(),
});
