import { AppState } from '../reducers';
import { useSelector as useReduxSelector, useDispatch as useReduxDispatch } from 'react-redux';

type Compare<T> = (right: T, left: T) => boolean;
export type AppStateMapper<T> = (state: AppState) => T;

export const useDispatch = () => useReduxDispatch();

export const useSelector = <T>(mapStateToProps: AppStateMapper<T>, equalityFn?: Compare<T>) =>
  useReduxSelector((state: AppState) => mapStateToProps(state), equalityFn);
