import { DatasetUploadStyles, DatasetUploadStyleProps } from './DatasetUpload.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const styles = (props: DatasetUploadStyleProps): DatasetUploadStyles => ({
  subComponentStyles: {
    panel: {
      panelContent: ['ms-dataset-panel'],
    },
  },
});
