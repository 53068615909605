import React, { useState } from 'react';
import { AppState } from '../../../../../store/reducers';
import { MultiLineChart } from '../../../../../components/common/charts/MultiLineChart';
import { setLatencyPerDocument } from '../../../../../store/actions/insightsAction';
import { DashboardChartCard } from '../common/DashboardChartCard';
import { useSelector, useDispatch } from '../../../../../store/hooks';

import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import {
  LatencyPerDocumentStyleProps,
  LatencyPerDocumentStyles,
  LatencyPerDocumentProps,
} from './LatencyPerDocument.types';
import { TickType } from '../../../../../components/common/charts/common/AxisTickFormatter';
import { useMediaQuery } from 'react-responsive';
import { convertTimeSpanToInterval, TimeFilterOptions } from '../Insights.utils';
import { Trans } from 'react-i18next';
import { getChartTitleTooltipMessage } from '../Insights.utils';

const getClassNames = classNamesFunction<LatencyPerDocumentStyleProps, LatencyPerDocumentStyles>();

export const LatencyPerDocumentBase = (props: LatencyPerDocumentProps) => {
  const {
    t,
    styles,
    theme,
    className,
    componentRef,
    hasTimeSelector,
    onPercentileChange,
    currentlyShowingPercentile,
  } = props;
  const latencyPerDocument = useSelector((state: AppState) => state.insights.latencyPerDocument);
  const isLatencyPerDocumentLoading = useSelector((state: AppState) => state.insights.isLatencyPerDocumentLoading);

  const [selecedTimeSpan, setSelectedTimeSpan] = useState('P1D');

  const dispatch = useDispatch();

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const mediumScreen = useMediaQuery({ query: '(max-width: 935px)' });
  const smallScreen = useMediaQuery({ query: '(max-width: 800px)' });

  const getLegend = (percentileValue: number): JSX.Element => {
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 4 }}
        className={classNames.legendContainer}
        onClick={() => {
          if (!isLatencyPerDocumentLoading) {
            onPercentileChange(percentileValue);
            dispatch(
              setLatencyPerDocument(selecedTimeSpan, convertTimeSpanToInterval(selecedTimeSpan), percentileValue),
            );
          }
        }}
      >
        <div
          className={classNames.legendIcon}
          style={{
            backgroundColor:
              currentlyShowingPercentile === percentileValue && !isLatencyPerDocumentLoading
                ? theme!.palette.themePrimary
                : theme!.palette.neutralLight,
          }}
        />
        <div className={classNames.legendLabel}>
          {
            <Trans i18nKey="insights.latency-per-document-percentile" values={{ percentileValue }}>
              <sup>th</sup> percentile
            </Trans>
          }
        </div>
      </Stack>
    );
  };

  return (
    <div className={classNames.root}>
      <DashboardChartCard
        componentRef={componentRef}
        hasTimeSelector={hasTimeSelector}
        cardTitle={t('insights.latency-per-document-chart-y')}
        isLoading={isLatencyPerDocumentLoading}
        refreshOptions={{
          text: getChartTitleTooltipMessage(latencyPerDocument.cacheDetails),
          onRefreshClick: (timespan, interval) => {
            setSelectedTimeSpan(timespan);
            dispatch(setLatencyPerDocument(timespan, interval, currentlyShowingPercentile));
          },
        }}
        onChange={(timespan, interval) => {
          setSelectedTimeSpan(timespan);
          dispatch(setLatencyPerDocument(timespan, interval, currentlyShowingPercentile));
        }}
        defaultTimeSelectorValue={TimeFilterOptions.ONE_DAY}
        footer={undefined}
        body={
          <div className={classNames.cardBody}>
            <Stack
              horizontal
              verticalAlign={'center'}
              className={classNames.legendsContainer}
              horizontalAlign={'center'}
              tokens={{ childrenGap: 12 }}
              wrap
            >
              {getLegend(50)}
              {getLegend(75)}
              {getLegend(95)}
              {getLegend(99)}
            </Stack>

            <MultiLineChart
              points={latencyPerDocument.series}
              isLoading={isLatencyPerDocumentLoading}
              xAxisProps={{
                interval:
                  latencyPerDocument.series.length > 26
                    ? 23
                    : latencyPerDocument.series.length > 13
                    ? 2
                    : latencyPerDocument.series.length > 8
                    ? mediumScreen
                      ? 2
                      : 1
                    : 0,
                tickProps: {
                  tickType: TickType.DateTime12Hrs,
                  maximumTextLength: 13,
                  blockRotation: !smallScreen,
                  rotationBreakPoint: 7,
                  fontSize: 12,
                  rotatedFontSize: 10,
                },
              }}
              minWidth={50}
              minHeight={230}
              xLine={{
                name: t('insights.latency-per-document-chart-x'),
                stroke: 'grey',
              }}
              ylines={[
                {
                  name: t('insights.latency-per-document-chart-y'),
                  stroke: theme!.palette.themePrimary,
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};
