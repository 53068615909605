import { Action } from 'redux';
import { ModelJobStatus } from './searchModel';

export const SET_TRAINING_JOB_LIST = 'SET_TRAINING_JOB_LIST';
export const RESET_TRAINING_JOB_LIST = 'RESET_TRAINING_JOB_LIST';
export const UPDATE_TRAINING_JOB_LIST = 'UPDATE_TRAINING_JOB_LIST';
export const LOADING_TRAINING_JOB_LIST = 'LOADING_TRAINING_JOB_LIST';

export const ADD_TRAINING_JOB = 'ADD_TRAINING_JOB';
export const UPDATE_TRAINING_JOB = 'UPDATE_TRAINING_JOB';
export const SET_TRAINING_JOB_METRICS = 'SET_TRAINING_JOB_METRICS';
export const LOADING_TRAINING_JOB_METRICS = 'LOADING_TRAINING_JOB_METRICS';

export enum BasicParameters {
  NumTrees = 'num_trees',
  NumLeaves = 'num_leaves',
  Parameters = 'parameters',
  LabelGain = 'label_gain',
  LearningRate = 'learning_rate',
  MinDataInLeaf = 'min_data_in_leaf',
  FeatureSelected = 'feature_selected',
}

export interface TrainParams {
  [key: string]: string;
}

export interface TrainingJobMapper {
  [key: string]: TrainingJob;
}

export interface TrainingMetricsMapper {
  [key: string]: TrainingMetric;
}

interface Point {
  x: number;
  y: number;
}

export interface TrainingMetric {
  key: string;
  yLabel: string;
  xLabel: string;
  points: Point[];
}

export interface TrainingJob {
  key: string;
  name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  datasetKey: string;
  description: string;
  rankerModelKey: string;
  testDatasetKey: string;
  status: ModelJobStatus;
  parameters: TrainParams;
  isLoadingMetrics: boolean;
  metricsMapper: TrainingMetricsMapper;
}

export interface TrainingJobState {
  isLoading: boolean;
  trainingJobMapper: TrainingJobMapper;
}

interface AddTrainingJob extends Action<typeof ADD_TRAINING_JOB> {
  trainingJob: TrainingJob;
}

interface UpdateTrainingJob extends Action<typeof UPDATE_TRAINING_JOB> {
  trainingJob: TrainingJob;
}

interface SetTrainingJobMetrics extends Action<typeof SET_TRAINING_JOB_METRICS> {
  key: string;
  metrics: TrainingMetric[];
}

interface LoadTrainingJobMetrics extends Action<typeof LOADING_TRAINING_JOB_METRICS> {
  key: string;
}

interface SetTrainingJobList extends Action<typeof SET_TRAINING_JOB_LIST> {
  trainingJobList: TrainingJob[];
}

interface UpdateTrainingJobList extends Action<typeof UPDATE_TRAINING_JOB_LIST> {
  trainingJobList: TrainingJob[];
}

export type TrainingJobStateActionTypes =
  | AddTrainingJob
  | UpdateTrainingJob
  | SetTrainingJobList
  | UpdateTrainingJobList
  | SetTrainingJobMetrics
  | LoadTrainingJobMetrics
  | Action<typeof RESET_TRAINING_JOB_LIST>
  | Action<typeof LOADING_TRAINING_JOB_LIST>;
