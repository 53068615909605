import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../reducers';
import { refreshActiveIndexDevops } from '../devopsActions';

import {
  getBusinessRuleList as getBusinessRuleListService,
  getBusinessRuleDetails as getBusinessRuleDetailsService,
} from '../../../restful-apis/customizations/businessRule.api';

import {
  businessRuleCreateAction,
  businessRuleUpdateAction,
  businessRuleDeleteAction,
  ActionSuccessHandler,
  ActionFailureHandler,
  ExecuteReduxCrudAction,
} from '../utils';

import {
  BusinessRule,
  Banner,
  SET_BUSINESS_RULE_LIST,
  SET_ACTIVE_BUSINESS_RULE,
  SET_BUSINESS_RULE_DETAILS,
  RESET_BUSINESS_RULE_LIST,
  RESET_ACTIVE_BUSINESS_RULE,
  LOADING_BUSINESS_RULE_LIST,
  LOADING_BUSINESS_RULE_DETAILS,
  POPULATE_ACTIVE_BUSINESS_RULE,
} from '../../types/customizations/businessRule.d';
import moment, { Moment } from 'moment';
import {
  ConditionBlock,
  BoostCondition,
  createConditionBlock,
  createStringCondition,
  Condition,
  StringCondition,
} from '../../../utils/customizations/conditions';
import { createEmptyBusinessRule } from '../../reducers/customizations/businessRuleReducer';
import { MbcSearchInstanceRouter } from '../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { getSupportedConditionBlock, getSupportedBoostCondition } from './businessRuleUtils';
import { SearchIndex } from '../../types/searchIndex';

export const getBusinessRuleList = (searchInstanceId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;

    dispatch({
      type: LOADING_BUSINESS_RULE_LIST,
    });

    getBusinessRuleListService(tenantId, searchIndexId, searchInstanceId).then(businessRuleList =>
      dispatch({
        type: SET_BUSINESS_RULE_LIST,
        businessRuleList: businessRuleList,
      }),
    );
  };
};

export const getBusinessRuleDetails = (businessRuleId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndex = state.searchIndex.searchIndex;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const targetBusinessRule = state.businessRule.businessRuleIdMapper[businessRuleId];

    dispatch({
      type: LOADING_BUSINESS_RULE_DETAILS,
    });

    getBusinessRuleDetailsService(tenantId, searchIndex.id, searchInstanceId, targetBusinessRule.name).then(
      businessRule => {
        // banner-only business rule hack
        const productIdField = searchIndex.fields.filter(field => field.type === 'ProductId')[0];
        if (
          !!productIdField &&
          !!businessRule.filterCondition &&
          businessRule.filterCondition.conditions.filter(
            cnd =>
              cnd._type === Condition.StringCondition &&
              cnd.operator === 'ne' &&
              (cnd as StringCondition).field === productIdField.name,
          ).length === 1
        ) {
          businessRule.filterCondition = undefined;
        }

        dispatch({
          type: SET_BUSINESS_RULE_DETAILS,
          businessRule: businessRule,
        });
      },
    );
  };
};

export const setActiveBusinessRule = (businessRuleId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const targetBusinessRule = getState().businessRule.businessRuleIdMapper[businessRuleId];
    if (!!targetBusinessRule.id) {
      dispatch(getBusinessRuleDetails(businessRuleId));
      dispatch({
        type: SET_ACTIVE_BUSINESS_RULE,
        id: businessRuleId,
      });
    }
  };
};

export const resetBusinessRuleList = (): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: RESET_BUSINESS_RULE_LIST,
  });
};

export const resetActiveBusinessRule = (): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: RESET_ACTIVE_BUSINESS_RULE,
  });
};

// banner-only business rule hack
const checkBannerOnlyBusinessRule = (businessRule: BusinessRule, searchIndex: SearchIndex): BusinessRule => {
  const productIdField = searchIndex.fields.filter(field => field.type === 'ProductId')[0];

  if (
    !!productIdField &&
    !!businessRule.banner &&
    !businessRule.filterCondition &&
    !businessRule.promoteCondition &&
    !businessRule.demoteCondition
  ) {
    businessRule.filterCondition = createConditionBlock({
      conditions: [createStringCondition({ field: productIdField.name, value: '-1', operator: 'ne' })],
    });
  }
  return businessRule;
};

export const updateBusinessRule = (
  businessRule: BusinessRule,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndex = state.searchIndex.searchIndex;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;
    const currentUserName = !!state.user.name ? state.user.name : state.user.email;

    businessRule.lastModifiedBy = currentUserName;
    businessRule.lastModifiedTime = moment.utc();

    // workaround to support banner-only business rule
    businessRule = checkBannerOnlyBusinessRule(businessRule, searchIndex);

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Update ${businessRule.name} business rule` }
      : undefined;

    const onSuccessHandler = (updatedBusinessRule: BusinessRule) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(updatedBusinessRule);
    };

    dispatch(
      ExecuteReduxCrudAction(
        businessRuleUpdateAction(
          tenantId,
          searchIndex.id,
          searchInstanceId,
          businessRule,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const updateBusinessRuleStatus = (
  businessRuleId: string,
  status: boolean,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const targetBusinessRule = state.businessRule.businessRuleIdMapper[businessRuleId];

    dispatch({
      type: LOADING_BUSINESS_RULE_DETAILS,
    });

    getBusinessRuleDetailsService(tenantId, searchIndexId, searchInstanceId, targetBusinessRule.name).then(
      businessRule => {
        const updatedBusinessRule = { ...businessRule, enabled: status };
        dispatch({
          type: SET_BUSINESS_RULE_DETAILS,
          businessRule: updatedBusinessRule,
        });
        dispatch(updateBusinessRule(updatedBusinessRule, commitMessage, onSuccess, onFailure));
      },
    );
  };
};

export const updateBusinessRuleSchedule = (
  businessRuleId: string,
  startDate?: Moment,
  endDate?: Moment,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const targetBusinessRule = state.businessRule.businessRuleIdMapper[businessRuleId];

    dispatch({
      type: LOADING_BUSINESS_RULE_DETAILS,
    });

    getBusinessRuleDetailsService(tenantId, searchIndexId, searchInstanceId, targetBusinessRule.name).then(
      (businessRule: BusinessRule) => {
        const updatedBusinessRule: BusinessRule = { ...businessRule, startDate: startDate, endDate: endDate };
        dispatch({
          type: SET_BUSINESS_RULE_DETAILS,
          businessRule: updatedBusinessRule,
        });
        dispatch(updateBusinessRule(updatedBusinessRule, commitMessage, onSuccess, onFailure));
      },
    );
  };
};

export const createBusinessRule = (
  businessRule: BusinessRule,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndex = state.searchIndex.searchIndex;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;
    const currentUserName = !!state.user.name ? state.user.name : state.user.email;

    // work around to support banner-only business rule
    businessRule = checkBannerOnlyBusinessRule(businessRule, searchIndex);

    businessRule.lastModifiedBy = currentUserName;
    businessRule.lastModifiedTime = moment.utc();
    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Create ${businessRule.name} business rule` }
      : undefined;

    const onSuccessHandler = (createdBusinessRule: BusinessRule) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(createdBusinessRule);
    };

    dispatch(
      ExecuteReduxCrudAction(
        businessRuleCreateAction(
          tenantId,
          searchIndex.id,
          searchInstanceId,
          businessRule,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const deleteBusinessRule = (
  businessRuleId: string,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;
    const targetBusinessRule = state.businessRule.businessRuleIdMapper[businessRuleId];

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Delete ${targetBusinessRule.name} business rule` }
      : undefined;

    const onSuccessHandler = (deletedBusinessRule: BusinessRule) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(deletedBusinessRule);
    };

    dispatch(
      ExecuteReduxCrudAction(
        businessRuleDeleteAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          targetBusinessRule,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const populateActiveBusinessRule = (
  promoteCondition?: BoostCondition,
  demoteCondition?: BoostCondition,
  filterCondition?: ConditionBlock,
  banner?: Banner,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const environmentId = state.environmentList.activeEnvironment.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;

    const businessRule: BusinessRule = {
      ...createEmptyBusinessRule(),
      promoteCondition: !!promoteCondition ? getSupportedBoostCondition(promoteCondition) : undefined,
      demoteCondition: !!demoteCondition ? getSupportedBoostCondition(demoteCondition) : undefined,
      filterCondition: !!filterCondition ? getSupportedConditionBlock(filterCondition) : undefined,
      banner: banner,
    };

    dispatch({
      type: POPULATE_ACTIVE_BUSINESS_RULE,
      businessRule: businessRule,
    });

    MbcSearchInstanceRouter(tenantId, environmentId, searchInstanceId)(MbcRouteKey.NewBusinessRule).browserPush();
  };
};
