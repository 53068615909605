import React, { useCallback, useState, useMemo, useEffect, createRef } from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import { SynonymSetProps, SynonymSetStyleProps, SynonymSetStyles } from './SynonymSet.types';
import { SearchInstanceSelector } from '../../SearchInstance/SearchInstanceSelector';
import { SideNavMode } from '../../../../components/common/PageTemplate/PageTemplate.types';
import { SearchPreview } from '../../common/SearchPreview';
import { SynonymSetEditor } from '../SynonymSetEditor';
import { AppState } from '../../../../store/reducers';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { SynonymSet } from '../../../../store/types/customizations/synonymSet';
import { updateSynonymSet, createSynonymSet } from '../../../../store/actions/customizations/synonymSetActions';
import { MbcSearchInstanceRouter } from '../../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { SplitPageTemplate } from '../../../../components/common/PageTemplate/PageTemplate';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import { toggleSideNavExpanded } from '../../../../store/actions/sideNavActions';
import { Tutorial, TutorialSteps } from '../../../../components/Tutorials';
import { AvailableTutorials } from '../../../../store/types/tutorial.d';

const getClassNames = classNamesFunction<SynonymSetStyleProps, SynonymSetStyles>();

export const SynonymSetBase = (props: SynonymSetProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const editorRef = createRef<HTMLDivElement>();

  const dispatch = useDispatch();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const activeSynonymSet = useSelector<SynonymSet>((state: AppState) => state.synonymSet.activeSynonymSet);
  const MbcSearchInstancRouteMap = MbcSearchInstanceRouter(
    activeTenant.id,
    activeEnvironment.id,
    activeSearchInstance.id,
  );
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);
  const currentTutorial = useSelector(state => state.tutorial.currentTutorial);

  const [currentSynonymSet, setCurrentSynonymSet] = useState(
    currentTutorial === AvailableTutorials.SynonymsFull
      ? { id: 'sampleSynonym', synonymList: ['synonym1', 'synonym2'] }
      : activeSynonymSet,
  );

  const [isSaved, setIsSaved] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const shouldBlock = useMemo(() => isUpdated && !isSaved && currentTutorial !== AvailableTutorials.SynonymsFull, [
    currentTutorial,
    isSaved,
    isUpdated,
  ]);

  const navigateToSynonymSetList = useCallback(
    () => MbcSearchInstancRouteMap(MbcRouteKey.SynonymSetList).browserPush(),
    [MbcSearchInstancRouteMap],
  );

  useEffect(() => {
    isCanceled && navigateToSynonymSetList();
  }, [isCanceled, navigateToSynonymSetList]);

  useEffect(() => {
    const updated = !_.isEqual(activeSynonymSet, currentSynonymSet);
    updated !== isUpdated && setIsUpdated(updated);
  }, [activeSynonymSet, currentSynonymSet, isUpdated]);

  useEffect(() => {
    shouldBlock ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldBlock]);

  const editMode = useMemo(() => activeSynonymSet.id !== '', [activeSynonymSet.id]);

  const isFormValid = useMemo(() => {
    return currentSynonymSet.id.trim() !== '' && currentSynonymSet.synonymList.length >= 2;
  }, [currentSynonymSet]);

  const handleCancel = useCallback(() => {
    setIsSaved(true);
    setIsUpdated(false);
    setIsCanceled(true);
  }, []);

  const handleSave = useCallback(() => {
    setIsSaved(true);
    setIsUpdated(false);
    editMode
      ? dispatch(
          updateSynonymSet(currentSynonymSet, undefined, navigateToSynonymSetList, () => {
            setIsSaved(false);
            setIsUpdated(true);
          }),
        )
      : dispatch(
          createSynonymSet(currentSynonymSet, undefined, navigateToSynonymSetList, () => {
            setIsSaved(false);
            setIsUpdated(true);
          }),
        );
  }, [currentSynonymSet, dispatch, editMode, navigateToSynonymSetList]);

  const getEditorContent = useCallback(() => {
    return (
      <>
        <Tutorial
          target={editorRef}
          step={TutorialSteps.SynonymsEditorRightPane}
          headline={t('product-tours-panel.synonyms-tour.headline3')}
          onDismiss={() => {
            setCurrentSynonymSet({ id: '', synonymList: [] });
          }}
          lightBackground
        >
          <p>{t('product-tours-panel.synonyms-tour.step-3')}</p>
        </Tutorial>

        <div
          ref={editorRef}
          className={currentTutorial === AvailableTutorials.SynonymsFull ? classNames.editorTutorial : undefined}
        >
          <SynonymSetEditor
            synonymSet={currentSynonymSet}
            editMode={editMode}
            onUpdate={synonymSet => setCurrentSynonymSet(synonymSet)}
          />
        </div>
      </>
    );
  }, [classNames.editorTutorial, currentSynonymSet, currentTutorial, editMode, editorRef, t]);

  return (
    <div className={classNames.root}>
      <SplitPageTemplate
        pageHeaderProps={{
          title: editMode ? 'Edit synonym' : 'Add synonym',
          infoProps: {
            content: t('synonym-list.info-content'),
            headline: t('synonym-list.title'),
            linkProps: {
              linkText: t('synonym-list.info-link'),
              to: '/docs/Portal%20Documentation/#manage-synonyms',
            },
          },
        }}
        onRenderLeftSideContent={() => (
          <SearchPreview
            hidePostBody
            augmentations={{ synonyms: currentSynonymSet.synonymList.length > 0 ? [currentSynonymSet] : undefined }}
          />
        )}
        collapsiblePaneProps={{
          onRenderPaneContent: getEditorContent,
          footerProps: {
            saveButtonText: editMode ? 'Save' : 'Create',
            saveButtonDisabled: !isFormValid || !shouldBlock,
            onSave: handleSave,
            onCancel: handleCancel,
          },
        }}
        breadcrumbVisible={true}
        onSecondaryNavMenuRender={() => <SearchInstanceSelector />}
        sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
        sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
      />
      <Prompt when={shouldBlock} message={t('common.on-before-unload-msg')} />
    </div>
  );
};
