import React, { Component } from 'react';
import DemoContainer from './demo-container/DemoContainer';
import LandingPage from './landing-page/LandingPage';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppRoute } from '../../router/router.service';

interface HomePageProps {
  isLoggedIn: boolean;
  showDemo: boolean;
}

export class HomePageComponentBase extends Component<HomePageProps> {
  render() {
    if (this.props.isLoggedIn) return <Redirect to={AppRoute.append('tenants')} />;

    return (
      <div>
        {!this.props.showDemo && <LandingPage />}
        {this.props.showDemo && <DemoContainer />}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  showDemo: state.oobeDemo.showDemo,
});

export const HomePage = connect(mapStateToProps)(HomePageComponentBase);
