import { useSelector } from '..';
import { useMemo } from 'react';
import { EvaluationJob, EvaluationJobMapper } from '../../types/customML/evaluationJob.d';
import { SearchModel } from '../../types/customML/searchModel.d';

export const useEvaluationJobList = (): EvaluationJob[] => {
  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);
  const evaluationJobMapper = useSelector<EvaluationJobMapper>(state => state.evaluationJobs.evaluationJobMapper);
  return useMemo(() => searchModel.evaluationJobKeys.map(key => evaluationJobMapper[key]), [
    evaluationJobMapper,
    searchModel.evaluationJobKeys,
  ]);
};
