import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../router/MbcRouter.types';
import { useBreadcrumb } from '../../store/hooks/use-breadcrumb/useBreadcrumb';
import { useProxyRouter } from '../../store/hooks/use-router/useProxyRouter';
import { useEnvironmentRouter } from '../../store/hooks/use-router/useEnvironmentRouter';
import { ProductSelector } from '../products/ProductSelector';
import { useSelector, useDispatch } from '../../store/hooks';
import { retrieveSearchInstances } from '../../store/actions/customizations/searchInstanceListActions';

const ProductSearchBreadcrumbItemKey = 'Product Search';

export const ProductSearchProxy = (props: RouteComponentProps) => {
  useBreadcrumb(ProductSearchBreadcrumbItemKey, props.match.url, () => <ProductSelector />);

  const MbcProxyRouteMapper = useProxyRouter();
  const MbcEnvironmentRouteMap = useEnvironmentRouter();
  const dispatch = useDispatch();

  const searchInstanceListLoading = useSelector(state => state.searchInstanceList.isLoading);
  const tenantId = useSelector(state => state.tenantsList.activeTenant.id);
  const indexId = useSelector(state => state.environmentList.activeEnvironment.indexId);

  useEffect(() => {
    if (searchInstanceListLoading) {
      dispatch(retrieveSearchInstances(tenantId, indexId));
    }
  }, [dispatch, indexId, searchInstanceListLoading, tenantId]);

  // TODO
  // Needs to handle choosing active searchInstanceId id not specified in URL
  const getProductSearchRoutes = useCallback(
    (): MbcRouteBase[] => [
      MbcEnvironmentRouteMap(MbcRouteKey.QueryTester),
      MbcEnvironmentRouteMap(MbcRouteKey.SearchAnalytics),
      MbcProxyRouteMapper(MbcRouteKey.SearchInstanceProxy),
    ],
    [MbcEnvironmentRouteMap, MbcProxyRouteMapper],
  );

  return <MbcRouter routes={getProductSearchRoutes()} />;
};
