import { IndexField } from '../../../../../store/types/searchIndex.d';
import { ProductSearchResultItem } from '../../../../../store/types/productSearch.d';

export const ROOT_PRODUCT_KEY = 'ROOT_PRODUCT_KEY';

/**
 * Defines product preview mode
 */
export enum PreviewMode {
  New,
  Read,
  Edit,
}

/**
 * Defines product DTO type
 */
export type Product = any;

/**
 * Defines product primitive attribute type
 */
export type PrimitiveType = string | number | boolean;

/**
 * Defines product data value type
 */
export type ProductItemValueType = string | ProductItem[] | PrimitiveType[] | null | DateSegment[];

/**
 * Defines product data item props
 */
export type ProductItem = Pick<IndexField, 'key' | 'name' | 'type'> & {
  value: ProductItemValueType;
  placeholder: string | DateSegment;
  isWildcard: boolean;
  isWildcardMatch: boolean;
};

export interface DateSegment {
  ge: string;
  lt: string;
}

/**
 * Defines index product data base props
 */
export interface ProductPreviewProps {
  /**
   * Active product to edit.
   */
  product?: ProductSearchResultItem;

  /**
   * Active product preview mode.
   */
  previewMode: PreviewMode;

  /**
   * Active product preview mode change handler.
   */
  onPreviewModeChange?: (PreviewMode: PreviewMode) => void;

  /**
   * Action on dismiss handler.
   */
  onDismiss: () => void;

  /**
   * Active product actions props.
   */
  actionProps?: {
    /**
     * Active product to delete handler.
     */
    onDeleteProduct: (product: any) => void;

    /**
     * Active product to update handler.
     */
    onUpdateProduct: (product: any) => void;
  };
}
