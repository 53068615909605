import {
  GET_TENANT_ROLES,
  RESET_TENANT_ROLES,
  SET_ACTIVE_TENANT_ROLE,
  RESET_ACTIVE_TENANT_ROLE,
  Role,
  LOADING_TENANT_ROLES,
  RoleListStateActionTypes,
  SET_ROLE_EDITOR_MODE,
  EditorMode,
} from '../types/roleList.d';
import { getUserRoles } from '../../restful-apis/role.api';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import {
  roleUpdateAction,
  roleCreateAction,
  roleDeleteAction,
  ActionSuccessHandler,
  ActionFailureHandler,
  ExecuteReduxCrudAction,
} from './utils';
import { Tenant } from '../types/tenantsList.d';

export const getTenantRoles = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_TENANT_ROLES,
    });

    getUserRoles(tenantId).then(roleList =>
      dispatch({
        type: GET_TENANT_ROLES,
        roles: roleList,
      }),
    );
  };
};

export const setActiveTenantRole = (roleId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const role = getState().roleList.roleIdMapper[roleId];
    if (role) {
      dispatch({
        type: SET_ACTIVE_TENANT_ROLE,
        role: role,
      });
    }
  };
};

export const resetActiveTenantRole = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const roleId = getState().roleList.activeRole.id;
    if (roleId) {
      dispatch({
        type: RESET_ACTIVE_TENANT_ROLE,
      });
    }
  };
};

export const resetTenantRoles = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: RESET_TENANT_ROLES,
    });
  };
};

export const updateRole = (
  tenantId: string,
  role: Role,
  onSuccess?: ActionSuccessHandler<Role>,
  onFailure?: ActionFailureHandler<Role>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(roleUpdateAction(tenantId, role, onSuccess, onFailure));

export const createRole = (
  tenant: Tenant,
  role: Role,
  onSuccess?: ActionSuccessHandler<Role>,
  onFailure?: ActionFailureHandler<Role>,
  isClone?: boolean,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(roleCreateAction(tenant, role, isClone, onSuccess, onFailure));

export const deleteRole = (
  tenantId: string,
  roleId: string,
  onSuccess?: ActionSuccessHandler<Role>,
  onFailure?: ActionFailureHandler<string>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(roleDeleteAction(tenantId, roleId, onSuccess, onFailure));

export const setEditorMode = (roleEditorMode: EditorMode): RoleListStateActionTypes => {
  return {
    type: SET_ROLE_EDITOR_MODE,
    roleEditorMode: roleEditorMode,
  };
};
