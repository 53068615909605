import React from 'react';
import {
  ContentCardHeaderProps,
  ContentCardHeaderStyles,
  ContentCardHeaderStyleProps,
} from './ContentCardHeader.types';
import { classNamesFunction } from 'office-ui-fabric-react';

export const getClassNames = classNamesFunction<ContentCardHeaderStyleProps, ContentCardHeaderStyles>();

export function ContentCardHeader(props: ContentCardHeaderProps) {
  const { styles, theme, className, componentRef, title, subTitle, actions } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div ref={componentRef} className={classNames.root}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.actions}>{actions}</div>
      <div className={classNames.subTitle}>{subTitle}</div>
    </div>
  );
}
