import { Action } from 'redux';

export interface SuggestionItem {
  key: number;
  displayValue: string;
  searchValue: string;
}

export enum BlockedSuggestionMatchType {
  Exact = 'Exact',
  Prefix = 'Prefix',
  Contains = 'Contains',
}

export interface BlockedSuggestionModel {
  id: string;
  query: string;
  matchingType: BlockedSuggestionMatchType;
}

export interface BlockedSuggestionsListState {
  isLoading: boolean;
  blockedSuggestions: BlockedSuggestionModel[];
}

export const GET_BLOCKED_SUGGESTIONS = 'GET_BLOCKED_SUGGESTIONS';
export const SET_BLOCKED_LIST_LOADING = 'SET_BLOCKED_LIST_LOADING';
export const OPTIMISTIC_ADD_BLOCKED_SUGGESTION = 'OPTIMISTIC_ADD_BLOCKED_SUGGESTION';
export const ADD_BLOCKED_SUGGESTION = 'ADD_BLOCKED_SUGGESTION';
export const SET_ID_TO_RECENTLY_ADDED_BLOCKED_SUGGESTION = 'SET_ID_TO_RECENTLY_ADDED_BLOCKED_SUGGESTION';
export const OPTIMISTIC_REMOVE_BLOCKED_SUGGESTION = 'OPTIMISTIC_REMOVE_BLOCKED_SUGGESTION';
export const REMOVE_BLOCKED_SUGGESTION = 'REMOVE_BLOCKED_SUGGESTION';

interface GetBlockedSuggestionsList extends Action<typeof GET_BLOCKED_SUGGESTIONS> {
  blockedSuggestions: BlockedSuggestionModel[];
}

interface OptimisticAddBlockedSuggestion extends Action<typeof OPTIMISTIC_ADD_BLOCKED_SUGGESTION> {
  currentlyAddingTokenId: string;
}

interface AddBlockedSuggestion extends Action<typeof ADD_BLOCKED_SUGGESTION> {
  submittedBlockedSuggestion: BlockedSuggestionModel;
}

interface SetIdToRecentlyAddedBlockedSuggestion extends Action<typeof SET_ID_TO_RECENTLY_ADDED_BLOCKED_SUGGESTION> {
  newId: string;
  submittedBlockedSuggestion: BlockedSuggestionModel;
}

interface OptimisticRemoveBlockedSuggestion extends Action<typeof OPTIMISTIC_REMOVE_BLOCKED_SUGGESTION> {
  currentlyAddingTokenId: string;
}

interface RemovedBlockedSuggestion extends Action<typeof REMOVE_BLOCKED_SUGGESTION> {
  submittedBlockedSuggestion: BlockedSuggestionModel;
}

export type BlockedSuggestionsStateActionTypes =
  | GetBlockedSuggestionsList
  | OptimisticAddBlockedSuggestion
  | SetIdToRecentlyAddedBlockedSuggestion
  | OptimisticRemoveBlockedSuggestion
  | AddBlockedSuggestion
  | RemovedBlockedSuggestion
  | Action<typeof SET_BLOCKED_LIST_LOADING>;
