import React, { useCallback } from 'react';
import {
  classNamesFunction,
  GroupHeader,
  DetailsRow,
  Icon,
  TooltipHost,
  TooltipOverflowMode,
} from 'office-ui-fabric-react';
import { IndexFieldProps, IndexFieldStyles, IndexFieldStyleProps } from './IndexField.types';
import { SearchIndexTypeConfig } from '../SchemaManagement.config';
import { GROUD_INDENT_WIDTH_IN_PIXELS } from './IndexField.styles';

const getClassNames = classNamesFunction<IndexFieldStyleProps, IndexFieldStyles>();

export const IndexFieldBase = (props: IndexFieldProps) => {
  const { styles, theme, indexField } = props;

  const classNames = getClassNames(styles, {
    theme: theme!,
    groupLevel: props.groupLevel || 0,
    complexType:
      !!indexField.type &&
      !!SearchIndexTypeConfig[indexField.type] &&
      SearchIndexTypeConfig[indexField.type].dataType === 'Object',
  });

  const onRenderIndexFieldFeature = useCallback(
    (enabled: boolean) => (enabled ? <Icon style={{ fontSize: 14 }} iconName="Completed" /> : null),
    [],
  );

  const onRenderIndexFieldName = useCallback(() => {
    const column = props.columns && props.columns.find(c => c.key === 'name');
    return (
      <TooltipHost
        overflowMode={TooltipOverflowMode.Self}
        hostClassName={classNames.primaryCell}
        style={{ maxWidth: column && column.maxWidth }}
        content={indexField.name}
      >
        <div style={{ maxWidth: column && column.maxWidth && column.maxWidth + 1, display: 'inline' }}>
          {indexField.name}
        </div>
      </TooltipHost>
    );
  }, [classNames.primaryCell, indexField.name, props.columns]);

  const onRenderIndexFieldType = useCallback(() => <span>{indexField.type}</span>, [indexField.type]);

  const onRenderIndexFieldColumn = useCallback(
    (key: string) => {
      switch (key) {
        case 'name':
          return onRenderIndexFieldName();
        case 'type':
          return onRenderIndexFieldType();
        case 'searchable':
          return onRenderIndexFieldFeature(indexField.features.searchable);
        case 'filterable':
          return onRenderIndexFieldFeature(indexField.features.filterable);
        case 'retrievable':
          return onRenderIndexFieldFeature(indexField.features.retrievable);
        case 'facetable':
          return onRenderIndexFieldFeature(indexField.features.facetable);
        case 'sortable':
          return onRenderIndexFieldFeature(indexField.features.sortable);
      }
    },
    [
      onRenderIndexFieldName,
      onRenderIndexFieldType,
      onRenderIndexFieldFeature,
      indexField.features.sortable,
      indexField.features.facetable,
      indexField.features.filterable,
      indexField.features.searchable,
      indexField.features.retrievable,
    ],
  );

  return !!indexField.type &&
    !!SearchIndexTypeConfig[indexField.type] &&
    SearchIndexTypeConfig[indexField.type].dataType === 'Object' ? (
    <GroupHeader
      {...props}
      indentWidth={GROUD_INDENT_WIDTH_IN_PIXELS}
      styles={classNames.subComponentStyles.groupHeader}
    />
  ) : (
    <DetailsRow
      {...props}
      itemIndex={-1}
      styles={classNames.subComponentStyles.detailsRow}
      item={{
        key: indexField.key,
        name: onRenderIndexFieldColumn('name'),
        type: onRenderIndexFieldColumn('type'),
        sortable: onRenderIndexFieldColumn('sortable'),
        facetable: onRenderIndexFieldColumn('facetable'),
        filterable: onRenderIndexFieldColumn('filterable'),
        searchable: onRenderIndexFieldColumn('searchable'),
        retrievable: onRenderIndexFieldColumn('retrievable'),
      }}
    />
  );
};
