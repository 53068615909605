import { TFunction } from 'i18next';
import { configureDefaultObject } from '../utils';

export interface RegionLocaleMapper {
  [key: string]: string;
}

const createRegionLocaleMapper = (initialMapper: RegionLocaleMapper = {}, t: TFunction) => {
  return configureDefaultObject<RegionLocaleMapper, string>(initialMapper, t('environments.regions.unkown'));
};

export const getRegionLocaleMapper = (t: TFunction): RegionLocaleMapper => {
  return createRegionLocaleMapper(
    {
      EastAsia: t('environments.regions.east-asia'),
      EastUS2: t('environments.regions.east-us-2'),
      NorthCentralUS: t('environments.regions.north-central-us'),
      NorthEurope: t('environments.regions.north-europe'),
      WestUS2: t('environments.regions.west-us-2'),
      SoutheastAsia: t('environments.regions.southeast-asia'),
      AustraliaEast: t('environments.regions.australia-east'),
      AustraliaSoutheast: t('environments.regions.australia-southeast'),
      AustraliaCentral: t('environments.regions.australia-central'),
      AustraliaCentral2: t('environments.regions.australia-central-2'),
      BrazilSouth: t('environments.regions.brazil-south'),
      CanadaCentral: t('environments.regions.canada-central'),
      CanadaEast: t('environments.regions.canada-east'),
      ChinaNorth: t('environments.regions.china-north'),
      ChinaEast: t('environments.regions.china-east'),
      ChinaNorth2: t('environments.regions.china-north-2'),
      ChinaEast2: t('environments.regions.china-east-2'),
      WestEurope: t('environments.regions.west-europe'),
      FranceCentral: t('environments.regions.france-central'),
      FranceSouth: t('environments.regions.france-south'),
      GermanyCentral: t('environments.regions.germany-central'),
      GermanyNortheast: t('environments.regions.germany-north-east'),
      CentralIndia: t('environments.regions.central-india'),
      WestIndia: t('environments.regions.west-india'),
      SouthIndia: t('environments.regions.south-india'),
      JapanEast: t('environments.regions.japan-east'),
      JapanWest: t('environments.regions.japan-west'),
      KoreaCentral: t('environments.regions.korea-central'),
      KoreaSouth: t('environments.regions.korea-south'),
      EastUS: t('environments.regions.east-us'),
      WestUS: t('environments.regions.west-us'),
      CentralUS: t('environments.regions.central-us'),
      SouthCentralUS: t('environments.regions.south-central-us'),
      WestCentralUS: t('environments.regions.west-central-us'),
      SouthAfricaNorth: t('environments.regions.south-africa-north'),
      SouthAfricaWest: t('environments.regions.south-africa-west'),
      UKWest: t('environments.regions.uk-west'),
      UKSouth: t('environments.regions.uk-south'),
      UAENorth: t('environments.regions.uae-north'),
      UAECentral: t('environments.regions.uae-central'),
    },
    t,
  );
};
