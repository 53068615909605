import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../../router/MbcRouter.types';
import { useLazyBreadcrumb } from '../../../store/hooks/use-breadcrumb/useLazyBreadcrumb';
import { useDispatch, useSelector } from '../../../store/hooks';
import { setActiveTenantRole, resetActiveTenantRole } from '../../../store/actions/roleListActions';
import { useRoleRouter } from '../../../store/hooks/use-router/useAdminRouter';
import { ActiveRoleLoading } from '../../../components/Loading';
import { useActiveRoleAsserter } from '../../../store/hooks/use-asserter/useAsserter';

export const RoleProxy = (props: RouteComponentProps<{ roleId: string }>) => {
  const dispatch = useDispatch();
  const MbcRoleRouteMapper = useRoleRouter();
  const isRoleListLoading = useSelector(state => state.roleList.isLoading);
  const activeRole = useSelector(state => state.roleList.activeRole);

  useActiveRoleAsserter(props.match.params.roleId);

  useLazyBreadcrumb(
    props.match.params.roleId,
    props.match.url,
    activeRole.name,
    !isRoleListLoading && activeRole.name !== '',
  );

  useEffect(() => {
    dispatch(setActiveTenantRole(props.match.params.roleId));
    return () => {
      dispatch(resetActiveTenantRole());
    };
  }, [dispatch, isRoleListLoading, props.match.params.roleId]);

  const getMbcDefaultAdminRoutes = useCallback((): MbcRouteBase[] => [MbcRoleRouteMapper(MbcRouteKey.Role)], [
    MbcRoleRouteMapper,
  ]);

  return (
    <ActiveRoleLoading>
      <MbcRouter routes={getMbcDefaultAdminRoutes()} />
    </ActiveRoleLoading>
  );
};
