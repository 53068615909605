import {
  IndexProbeState,
  IndexProbeStateActionTypes,
  SET_SHOW_ADD_DOCUMENT_PICKER,
  RESET_SHOW_ADD_DOCUMENT_PICKER,
} from '../types/indexProbe.d';

const initialState: IndexProbeState = {
  showAddDocumentsPicker: false,
};

export function indexProbeReducer(state = initialState, action: IndexProbeStateActionTypes): IndexProbeState {
  switch (action.type) {
    case SET_SHOW_ADD_DOCUMENT_PICKER: {
      return {
        ...state,
        showAddDocumentsPicker: true,
      };
    }
    case RESET_SHOW_ADD_DOCUMENT_PICKER: {
      return {
        ...state,
        showAddDocumentsPicker: false,
      };
    }
    default:
      return state;
  }
}
