import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../router/MbcRouter.types';
import { useBreadcrumb } from '../../store/hooks/use-breadcrumb/useBreadcrumb';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { useProxyRouter } from '../../store/hooks/use-router/useProxyRouter';

const RoleListBreadcrumbItemKey = 'Roles & permissions';

export const RoleListProxy = (props: RouteComponentProps) => {
  useBreadcrumb(RoleListBreadcrumbItemKey, props.match.url);

  const MbcProxyRouteMapper = useProxyRouter();
  const MbcAdminRouteMapper = useAdminRouter();

  const getMbcDefaultAdminRoutes = useCallback(
    (): MbcRouteBase[] => [
      MbcAdminRouteMapper(MbcRouteKey.RoleList),
      MbcAdminRouteMapper(MbcRouteKey.RoleEditor),
      MbcProxyRouteMapper(MbcRouteKey.RoleProxy),
    ],
    [MbcAdminRouteMapper, MbcProxyRouteMapper],
  );

  return <MbcRouter routes={getMbcDefaultAdminRoutes()} />;
};
