import { RoleCloneEditorStyles, RoleCloneEditorStyleProps } from './RoleCloneEditor.types';

export const styles = (props: RoleCloneEditorStyleProps): RoleCloneEditorStyles => {
  return {
    root: [
      'mbc-role-clone-editor',
      {
        selectors: {
          '.mbc-content-card-body': {
            width: 350,
          },
          '.ms-Label': {
            fontSize: 12,
            fontWeight: 400,
            paddingBottom: 24,
          },
        },
      },
    ],
    footer: [
      'mbc-footer',
      {
        selectors: {
          '.ms-Button': {
            marginLeft: 16,
          },
          '.ms-Button--default': {
            color: props.theme.palette.blue,
          },
        },
      },
    ],
  };
};
