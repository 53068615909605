import { ReduxCrudAction, Dispatch } from './CrudAction.types';
import { showProgressIndicator, hideProgressIndicator } from '../progressIndicatorActions';
import { AppState } from '../../reducers';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { isArray } from 'util';
import { NotificationWithContext, NotificationStatus, NotificationBase } from '../../types/notificationCenter.d';
import { generateUniqueIdentifier } from '../../../utils';
import { updateNotification, pushSingleNotification } from '../notificationCenterActions';
import moment from 'moment';

/**
 * An intenral action to dispatch Notification state
 *
 * @param {string} uniqueId notification card identifier for updates
 * @param {Dispatch} dispatch thunk dispatch to trigger store side effects
 * @param {NotificationStatus} status determines the notification center state
 * @param {NotificationBase} notification detailed notification context
 */

const showActionNotificationInternal = (
  uniqueId: string,
  dispatch: Dispatch,
  status: NotificationStatus,
  notification: NotificationBase,
  errorMessage?: string,
) => {
  const notificationInternal: NotificationWithContext = {
    ...notification,
    read: false,
    id: uniqueId,
    status: status,
    creationTime: moment().format('LT'),
    errorMessage: errorMessage,
  };

  status === NotificationStatus.InProgress
    ? dispatch(pushSingleNotification(notificationInternal))
    : dispatch(updateNotification(notificationInternal));
};

/**
 * A Redux intenral Async action to perform CRUD requests
 * using ThunkDispatch, recursively manipulate all requred dependencies
 *
 * @param {Dispatch} dispatch thunk dispatch to trigger store side effects
 * @param {ReduxCrudAction} props async action configuration
 */
const ExecuteReduxCrudActionAsync = async <T>(
  dispatch: Dispatch,
  props: ReduxCrudAction<T>,
  uniqueId: string,
  supressAllProgressIndicators?: boolean,
) => {
  const { request, then, preActionMapper, postActionMapper, onSuccess, onFailure, notification } = props;

  // Dispatch notifiation center / progress indicator for coming Api request
  if (!supressAllProgressIndicators) {
    !!notification
      ? showActionNotificationInternal(uniqueId, dispatch, NotificationStatus.InProgress, notification.inProgress)
      : dispatch(showProgressIndicator());
  }

  // Pre Redux store actions
  if (!!preActionMapper) {
    const actionList = isArray(preActionMapper) ? preActionMapper : [preActionMapper];
    actionList.forEach(action => dispatch(action));
  }

  try {
    const model = await request;

    // Recursively execute next CrudAction as a dependency
    then && (await ExecuteReduxCrudActionAsync(dispatch, then(model), uniqueId, true));

    // Map resource to Redux store action
    const action = postActionMapper(model);
    const actionList = isArray(action) ? action : [action];
    actionList.forEach(action => dispatch(action));

    !!notification &&
      !supressAllProgressIndicators &&
      showActionNotificationInternal(uniqueId, dispatch, NotificationStatus.Succeeded, notification.success(model));

    // Execute on success handler if exists
    onSuccess && onSuccess(model, dispatch);
  } catch (exception) {
    !!notification &&
      !supressAllProgressIndicators &&
      showActionNotificationInternal(
        uniqueId,
        dispatch,
        NotificationStatus.Failed,
        notification.failure(exception.failureMessage),
        notification.supressErrorMessage ? undefined : exception.failureMessage,
      );

    // Execute on failure handler if exists
    onFailure && onFailure(exception, dispatch);
  } finally {
    // Hide progress indicator post Api request
    !notification && !supressAllProgressIndicators && dispatch(hideProgressIndicator());
  }
};

/**
 * A Redux action to perform CRUD (create/read/update/delete) requests
 *
 * @param {ReduxCrudAction} props async action configuration
 */
export const ExecuteReduxCrudAction = <T>(props: ReduxCrudAction<T>): ThunkAction<void, AppState, null, Action> => {
  return dispatch => ExecuteReduxCrudActionAsync<T>(dispatch, props, generateUniqueIdentifier());
};
