import React from 'react';
import { ProductToursCardProps, ProductToursCardStyleProps, ProductToursCardStyles } from './ProductToursCard.types';
import { Stack, Icon, DefaultButton, classNamesFunction } from 'office-ui-fabric-react';
import { useSelector } from '../../../../../../../store/hooks';
import { AppState } from '../../../../../../../store/reducers';

const getClassNames = classNamesFunction<ProductToursCardStyleProps, ProductToursCardStyles>();

export const ProductToursCardBase = (props: ProductToursCardProps) => {
  const {
    className,
    theme,
    styles,
    componentRef,
    tourTitle,
    titleIconName,
    tourDescription,
    onStartClick,
    tourId,
    t,
  } = props;

  const completedTutorials = useSelector((state: AppState) => state.user.sandBoxDetails.completedTutorials);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div ref={componentRef}>
      <Stack tokens={{ childrenGap: 22 }} className={classNames.root}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <Icon iconName={titleIconName} />
          <div className={classNames.cardTitle}>{tourTitle}</div>
        </Stack>
        <div className={classNames.cardSubtitle}>{tourDescription}</div>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <Icon
              iconName={completedTutorials.includes(tourId) ? 'Completed' : !!onStartClick ? 'RadioBtnOff' : 'Clock'}
            />
            <div className={classNames.tourStatus}>
              {completedTutorials.includes(tourId)
                ? t('product-tours-panel.completed-label')
                : !!onStartClick
                ? t('product-tours-panel.incomplete-label')
                : t('product-tours-panel.coming-soon-label')}
            </div>
          </Stack>

          <DefaultButton onClick={onStartClick} disabled={!onStartClick}>
            {t('product-tours-panel.start-button')}
          </DefaultButton>
        </Stack>
      </Stack>
    </div>
  );
};
