import { Component } from 'react';
import { connect } from 'react-redux';
import { AuthContext } from '../../contexts';
import { browserHistory } from '../../history.module';
import { AppRoute } from '../../router/router.service';
import { AppState } from '../../store/reducers';

interface InitialLoginHandlerProps {
  isLoggedIn: boolean;
}

export class InitialLoginHandlerBase extends Component<InitialLoginHandlerProps> {
  static contextType = AuthContext;

  render() {
    if (this.props.isLoggedIn) browserHistory.push(AppRoute.append('tenants'));
    else this.context.login();

    return null;
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
});

export default connect(mapStateToProps)(InitialLoginHandlerBase);
