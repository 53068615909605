import { useSelector } from '..';
import { useMemo } from 'react';
import { getLatestJob, getLocaleTimeNow } from '../../../utils';
import { SearchModel, ModelJobStatus } from '../../types/customML/searchModel.d';
import { TrainingJobMapper, TrainingJob } from '../../types/customML/trainingJob.d';
import { createInitialTrainingJob } from '../../reducers/customML/trainingJobReducer';

export const useActiveTraining = (): TrainingJob => {
  const userEmail = useSelector<string>(state => state.user.email);
  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);
  const trainingJobMapper = useSelector<TrainingJobMapper>(state => state.trainingJobs.trainingJobMapper);

  return useMemo(() => {
    const trainingJob = getLatestJob(
      searchModel.trainingJobKeys.map(trainingJobKey => trainingJobMapper[trainingJobKey]),
    );

    return !!trainingJob && !!trainingJob.key
      ? trainingJob
      : {
          ...createInitialTrainingJob(),
          email: userEmail,
          name: searchModel.name,
          createdAt: getLocaleTimeNow(),
          updatedAt: getLocaleTimeNow(),
          status: ModelJobStatus.NotStarted,
          description: searchModel.description,
        };
  }, [searchModel.description, searchModel.name, searchModel.trainingJobKeys, trainingJobMapper, userEmail]);
};
