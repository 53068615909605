import {
  TokenCreationStatusDialogStyles,
  TokenCreationStatusDialogStyleProps,
} from './TokenCreationStatusDialog.types';

export const styles = (props: TokenCreationStatusDialogStyleProps): TokenCreationStatusDialogStyles => {
  return {
    root: [
      'mbc-token-creation-dialog',
      {
        selectors: {
          '.ms-Dialog-header': {
            boxSizing: 'border-box',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            padding: '12px 24px',
          },
          '.ms-Dialog-title': {
            fontSize: 14,
            lineHeight: 28,
            fontWeight: 600,
            alignSelf: 'center',
            padding: 0,
          },
          '.ms-Dialog-subText': {
            fontSize: 12,
          },
          '.ms-Dialog-inner': {
            padding: '12px 24px',
            flex: '1 0 auto',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
      props.className,
    ],
    modalContent: [
      {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    ],
  };
};
