import { BaseResponseInterceptor } from './BaseResponseInterceptor';
import { FetchResponse, ServiceException } from '..';
import store from '../../../../store';
import { pushNotificationMessage } from '../../../../store/actions/notificationMessageActions';
import { MessageBarType } from 'office-ui-fabric-react';

export class AnalyticsServiceInterceptor extends BaseResponseInterceptor {
  constructor() {
    super(/^https:\/\/commerce.bing.com\/api\/analytics\/.*$/);
  }

  protected PerformAction(response: FetchResponse): Promise<FetchResponse> {
    if (!response.content.ok && !response.supressNotificationOnFailure && response.content.status !== 400) {
      // dispatch notification message
      // don't use json() directly to handle empty body responses exceptions
      response.content.text().then(exception => {
        if (exception) {
          store.dispatch(
            pushNotificationMessage({
              type: MessageBarType.error,
              content: this.ExtractExceptionMessage(JSON.parse(exception) as ServiceException),
            }),
          );
        }
      });
    }
    return Promise.resolve(response);
  }
}
