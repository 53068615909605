import React, { useCallback, ReactNode, useRef, useState, createRef, useEffect } from 'react';
import {
  classNamesFunction,
  IColumn,
  Toggle,
  Stack,
  IconButton,
  TooltipHost,
  TooltipOverflowMode,
  Link,
} from 'office-ui-fabric-react';
import { BusinessRuleListProps, BusinessRuleListStyleProps, BusinessRuleListStyles } from './BusinessRuleList.types';
import { useSelector, useDispatch } from '../../../store/hooks';
import { AppState } from '../../../store/reducers';
import { PageTemplate } from '../../../components/common/PageTemplate';
import { PageHeaderProps } from '../../../components/common/PageHeader';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { BusinessRule } from '../../../store/types/customizations/businessRule';
import { ColumnPriority, SortDataType, OptionsItemProps, ListItem } from '../../../components/common/DetailsList';
import { DetailsListCard } from '../../../components/common/DetailsListCard';
import { SearchInstanceSelector } from '../SearchInstance/SearchInstanceSelector';
import {
  deleteBusinessRule,
  updateBusinessRuleStatus,
  updateBusinessRuleSchedule,
} from '../../../store/actions/customizations/businessRuleActions';
import {
  MbcBusinessRuleRouter,
  MbcSearchInstanceRouter,
} from '../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { ScheduleCallout } from './ScheduleCallout';
import { ConfirmationDialog } from '../../../components/common/Dialog/ConfirmationDialog';
import { toggleSideNavExpanded } from '../../../store/actions/sideNavActions';
import { EnvironmentPermissionContext, EnvironmentPermissionContextType } from '../../../contexts';
import { Permission } from '../../../config/userPermissions.config';
import { Tutorial, TutorialSteps, TutorialsId } from '../../../components/Tutorials';
import { startTutorial } from '../../../store/actions/tutorialActions';
import { AvailableTutorials } from '../../../store/types/tutorial.d';
import { useTranslation } from 'react-i18next';

interface BusinessRuleListItem extends ListItem {
  name: string;
  status: boolean;
  modifiedAt: string;
  modifiedBy: string;
  schedule: JSX.Element;
}

const getClassNames = classNamesFunction<BusinessRuleListStyleProps, BusinessRuleListStyles>();

export const BusinessRuleListBase = (props: BusinessRuleListProps) => {
  const { styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const { t } = useTranslation();

  const businessRulesListRef = createRef<HTMLDivElement>();

  const [businessRuleToDelete, setBusinessRuleToDelete] = useState<BusinessRuleListItem | undefined>(undefined);

  const dispatch = useDispatch();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const isBusinessRuleListLoading = useSelector<boolean>((state: AppState) => state.businessRule.isLoading);
  const BusinessRuleList = useSelector<BusinessRule[]>((state: AppState) =>
    Object.values(state.businessRule.businessRuleIdMapper),
  );
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);
  const completedTutorials = useSelector(state => state.user.sandBoxDetails.completedTutorials);

  const [activeBusinessRule, setActiveBusinessRule] = useState<BusinessRule | undefined>(undefined);

  const businessRulesRef = useRef<(Element | null)[]>([]);

  const i18n = useTranslation();

  const pageHeaderProps: PageHeaderProps = {
    title: 'Business rules',
    infoProps: {
      headline: i18n.t('business-rule.title'),
      content: i18n.t('business-rule.info-content'),
      linkProps: {
        to: '/docs/Portal%20Documentation/#manage-business-rules-for-product-search',
        linkText: i18n.t('business-rule.info-link'),
      },
    },
  };

  useEffect(() => {
    if (!completedTutorials.includes(TutorialsId.BusinessRules)) {
      dispatch(startTutorial(AvailableTutorials.BusinessRulesFull));
    }
  }, [completedTutorials, dispatch]);

  const handleEditItemClick = useCallback(
    (item: BusinessRuleListItem) => {
      MbcBusinessRuleRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id, item.key)(
        MbcRouteKey.BusinessRuleEditor,
      ).browserPush();
    },
    [activeEnvironment.id, activeSearchInstance.id, activeTenant.id],
  );

  const handleAddItemClick = useCallback(() => {
    MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id)(
      MbcRouteKey.NewBusinessRule,
    ).browserPush();
  }, [activeEnvironment.id, activeSearchInstance.id, activeTenant.id]);

  const getOptionsItemProps = useCallback(
    (item: any, isAuthorized: boolean): OptionsItemProps[] => {
      return [
        {
          key: 'Edit',
          text: 'Edit',
          iconProps: { iconName: 'Edit' },
          disabled: !isAuthorized,
          onClick: () => {
            handleEditItemClick(item);
          },
        },
        {
          key: 'Delete',
          text: 'Delete',
          iconProps: { iconName: 'Delete' },
          disabled: !isAuthorized,
          onClick: () => {
            setBusinessRuleToDelete(item);
          },
        },
      ];
    },
    [handleEditItemClick],
  );

  const onRenderItemColumn = useCallback(
    (isAuthorized: boolean, item?: any, index?: number, column?: IColumn): ReactNode => {
      if (item && column) {
        switch (column.key) {
          case 'name':
            return (
              <TooltipHost
                overflowMode={TooltipOverflowMode.Self}
                hostClassName={classNames.nameColumnCell}
                style={{ maxWidth: column.maxWidth }}
                content={item.name}
              >
                {!!isAuthorized ? (
                  <Link
                    onClick={() => handleEditItemClick(item)}
                    style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
                  >
                    {item.name}
                  </Link>
                ) : (
                  item.name
                )}
              </TooltipHost>
            );
          case 'status':
            return (
              <Toggle
                checked={item[column.fieldName as string] as boolean}
                onText={'On'}
                offText={'Off'}
                onChange={(ev, checked) =>
                  checked !== undefined && dispatch(updateBusinessRuleStatus(item.key, checked))
                }
              />
            );

          default:
            return <span>{item[column.fieldName as string] as string}</span>;
        }
      }
    },
    [classNames.nameColumnCell, dispatch, handleEditItemClick],
  );

  const onDeleteConfirmation = useCallback(() => {
    businessRuleToDelete && dispatch(deleteBusinessRule(businessRuleToDelete.key));
    setBusinessRuleToDelete(undefined);
  }, [dispatch, businessRuleToDelete]);

  const onDeleteDismiss = useCallback(() => {
    setBusinessRuleToDelete(undefined);
  }, []);

  const getBusinessRulesListItems = useCallback((): BusinessRuleListItem[] => {
    return BusinessRuleList.map((businessRule, index) => {
      const scheduleText =
        businessRule.startDate && businessRule.endDate
          ? `${businessRule.startDate.format('YYYY-MM-DD')} to ${businessRule.endDate.format('YYYY-MM-DD')}`
          : 'Add a schedule';

      const schedule = (
        <Stack horizontal verticalAlign={'center'}>
          <div ref={el => (businessRulesRef.current[index] = el)}>
            <IconButton iconProps={{ iconName: 'Edit' }} onClick={() => setActiveBusinessRule(businessRule)} />
          </div>
          <span>{scheduleText}</span>
          <ScheduleCallout
            hidden={!(!!activeBusinessRule && activeBusinessRule.id === businessRule.id)}
            target={businessRulesRef.current[index]}
            startDate={businessRule.startDate}
            endDate={businessRule.endDate}
            onSave={(startDate, endDate) => {
              dispatch(updateBusinessRuleSchedule(businessRule.id, startDate, endDate));
              setActiveBusinessRule(undefined);
            }}
            onDismiss={() => setActiveBusinessRule(undefined)}
          />
        </Stack>
      );
      return {
        key: businessRule.id,
        name: businessRule.name,
        modifiedAt: businessRule.lastModifiedTime.fromNow(),
        modifiedBy: businessRule.lastModifiedBy,
        status: businessRule.enabled,
        schedule: schedule,
      };
    });
  }, [BusinessRuleList, activeBusinessRule, dispatch]);

  const getBusinessRuleListProps = useCallback(
    (isAuthorized: boolean) => {
      return {
        isLoading: isBusinessRuleListLoading,
        items: getBusinessRulesListItems(),
        onRenderNoItemsToDisplay: {
          text: 'No business rules created yet',
          subText: isAuthorized
            ? 'Get started by creating your first business rule'
            : 'Permission required to create business rules is not granted',
          actionProps: isAuthorized
            ? {
                buttonText: 'Get started',
                onClick: handleAddItemClick,
              }
            : undefined,
        },
        responsiveColumns: {
          columns: [
            {
              key: 'name',
              name: 'Name',
              fieldName: 'name',
              priority: ColumnPriority.Primary,
              sortDataType: SortDataType.text,
              minWidth: 200,
              onRender: (item?: any, index?: number, column?: IColumn) =>
                onRenderItemColumn(isAuthorized, item, index, column),
            },
            {
              key: 'status',
              name: 'Status',
              fieldName: 'status',
              priority: ColumnPriority.Secondary,
              minWidth: 100,
              onRender: (item?: any, index?: number, column?: IColumn) =>
                onRenderItemColumn(isAuthorized, item, index, column),
            },
            {
              key: 'schedule',
              name: 'Schedule',
              fieldName: 'schedule',
              priority: ColumnPriority.Secondary,
              minWidth: 200,
            },
            {
              key: 'modifiedBy',
              name: 'Modified by',
              fieldName: 'modifiedBy',
              priority: ColumnPriority.Tertiary,
              minWidth: 150,
            },
            {
              key: 'modifiedAt',
              name: 'Modified',
              fieldName: 'modifiedAt',
              priority: ColumnPriority.Tertiary,
              minWidth: 100,
            },
          ],
          secondaryBreakPoint: 500,
          teritaryBreakPoint: 800,
        },
        optionsItemProps: (item: any) => getOptionsItemProps(item, isAuthorized),
      };
    },
    [getBusinessRulesListItems, getOptionsItemProps, handleAddItemClick, isBusinessRuleListLoading, onRenderItemColumn],
  );

  const getHeaderActions = useCallback(
    (isAuthorized: boolean) => {
      return isAuthorized && getBusinessRulesListItems().length > 0
        ? {
            add: {
              onClick: () => {
                handleAddItemClick();
              },
            },
          }
        : undefined;
    },
    [getBusinessRulesListItems, handleAddItemClick],
  );

  const onRenderContent = (isAuthorized: boolean): JSX.Element => {
    return (
      <div ref={businessRulesListRef} style={{ height: '100%' }}>
        <DetailsListCard
          searchActionProps={{ searchBy: 'name' }}
          listProps={getBusinessRuleListProps(isAuthorized)}
          header={{
            actions: getHeaderActions(isAuthorized),
          }}
        />
      </div>
    );
  };

  return (
    <div className={classNames.root}>
      <EnvironmentPermissionContext.Consumer>
        {(props: EnvironmentPermissionContextType) => {
          const isAuthorized = props.isAuthorized(Permission.SearchCustomizationReadWrite);
          return (
            <>
              <Tutorial
                target={businessRulesListRef}
                step={TutorialSteps.BusinessRulesList}
                headline={t('product-tours-panel.business-rules-tour.headline2')}
                coverTarget={true}
                lightBackground
                onStepEnd={handleAddItemClick}
              >
                {
                  <>
                    <p>{t('product-tours-panel.business-rules-tour.step-2.pargraph1')}</p>
                    <p>{t('product-tours-panel.business-rules-tour.step-2.pargraph2')}</p>
                  </>
                }
              </Tutorial>
              <PageTemplate
                pageHeaderProps={pageHeaderProps}
                onRenderContent={() => onRenderContent(isAuthorized)}
                breadcrumbVisible={true}
                onSecondaryNavMenuRender={() => <SearchInstanceSelector />}
                sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
                sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
              />
              <ConfirmationDialog
                key={businessRuleToDelete && businessRuleToDelete.key}
                isOpen={!!businessRuleToDelete}
                title={'Delete business rule'}
                subText={`Are you sure you want to delete business rule ${businessRuleToDelete &&
                  businessRuleToDelete.name}?`}
                onConfirm={onDeleteConfirmation}
                onDismiss={onDeleteDismiss}
              ></ConfirmationDialog>
            </>
          );
        }}
      </EnvironmentPermissionContext.Consumer>
    </div>
  );
};
