import { IRefObject, IStyleFunctionOrObject, IStyle, ITheme } from 'office-ui-fabric-react';
import { WithTranslation } from 'react-i18next';

export enum PanelAction {
  List,
  Add,
  Clone,
  Delete,
}

export interface SearchInstanceListPanelBaseProps extends WithTranslation {
  className?: string;

  componentRef?: IRefObject<HTMLDivElement>;

  theme?: ITheme;

  styles?: IStyleFunctionOrObject<SearchInstanceListPanelStyleProps, SearchInstanceListPanelStyles>;
}

export interface SearchInstanceListPanelProps extends SearchInstanceListPanelBaseProps {
  isOpen: boolean;

  onDismiss: () => void;
}

export type SearchInstanceListPanelStyleProps = Required<Pick<SearchInstanceListPanelProps, 'theme'>> &
  Pick<SearchInstanceListPanelBaseProps, 'className'>;

export interface SearchInstanceListPanelStyles {
  root: IStyle;
}
