import { DatasetListStyles, DatasetListStyleProps } from './DatasetList.types';

export const styles = (props: DatasetListStyleProps): DatasetListStyles => ({
  item: [
    'mbc-dataset-name',
    {
      fontSize: 12,
      lineHeight: 19,
      fontWeight: 600,
      color: props.theme.palette.themePrimary,
    },
  ],
  newTag: [
    'mbc-new-dataset-name',
    {
      selectors: {
        ':after': {
          content: "'new'",
          fontWeight: 600,
          padding: '2px 8px',
          color: '#323130',
          textTransform: 'uppercase',
          backgroundColor: '#FFF2BF',
          lineHeight: 17,
          textAlign: 'center',
          borderRadius: 4,
          margin: '0 6px',
          fontSize: 9,
        },
      },
    },
  ],
  status: [
    'mbc-dataset-status',
    {
      width: 'fit-content',
      padding: '3px 8px',
      fontSize: 12,
      lineHeight: 19,
    },
  ],
  overflow: [
    'mbc-overflow-cell',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  subComponentStyles: {
    page: {
      root: [
        'mbc-dataset-list-actions',
        {
          selectors: {
            '.ms-Button--primary.is-disabled': {
              backgroundColor: '#EDEBE9',
            },
          },
        },
      ],
      cards: [
        'mbc-dataset-list-cards',
        {
          selectors: {
            '.mbc-details-list-card:last-child': {
              marginTop: 24,
            },
          },
        },
      ],
    },
  },
});
