import { useSelector } from '..';
import { useMemo } from 'react';
import { IndexField } from '../../types/searchIndex';

export const selectSortableFields = (fields: IndexField[], parentHierarchalFieldName: string = ''): string[] => {
  let SortableFields: string[] = [];
  fields.forEach(field => {
    const hierarchalFieldName = parentHierarchalFieldName ? `${parentHierarchalFieldName}.${field.name}` : field.name;
    if (field.fields && field.fields.length > 0) {
      SortableFields = SortableFields.concat(selectSortableFields(field.fields, hierarchalFieldName));
    }
    if (field.features.sortable) {
      SortableFields.push(hierarchalFieldName);
    }
  });
  return SortableFields;
};

export const useSortableFields = (): string[] => {
  const indexFields = useSelector(state => state.searchIndex.searchIndex.fields);

  return useMemo(() => selectSortableFields(indexFields), [indexFields]);
};
