import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { ActionSuccessHandler, ActionFailureHandler, ExecuteReduxCrudAction } from '../utils';
import {
  EvaluationJob,
  SET_ACTIVE_EVALUATION_JOB,
  RESET_ACTIVE_EVALUATION_JOB,
  LOADING_EVALUATION_JOB_LIST,
  EvaluationJobStateActionTypes,
  RESET_EVALUATION_JOB_LIST,
  SET_EVALUATION_JOB_LIST,
  LOADING_EVALUATION_JOB_METRICS,
  SET_EVALUATION_JOB_METRICS,
  UPDATE_EVALUATION_JOB,
  UPDATE_EVALUATION_JOB_LIST,
} from '../../types/customML/evaluationJob.d';
import { createEvaluationJobAction } from '../utils/crud-actions/evaluation-job/EvaluationJobCrudActions';
import { listEvaluationJobs, getEvaluationJobMetrics, getEvaluationJob } from '../../../restful-apis/customML.api';

export const createEvaluationJob = (
  tenantId: string,
  searchModelKey: string,
  evaluationJob: EvaluationJob,
  onSuccess?: ActionSuccessHandler<EvaluationJob>,
  onFailure?: ActionFailureHandler<EvaluationJob>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(createEvaluationJobAction(tenantId, searchModelKey, evaluationJob, onSuccess, onFailure));

export const setActiveEvaluationJob = (evaluationJobKey: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;
    const activeEvaluationJob = getState().evaluationJobs.evaluationJobMapper[evaluationJobKey];

    dispatch({
      type: SET_ACTIVE_EVALUATION_JOB,
      evaluationJob: activeEvaluationJob,
    });

    // Re-use already cached metrics
    if (!activeEvaluationJob.iterations || activeEvaluationJob.iterations.length === 0) {
      dispatch({
        type: LOADING_EVALUATION_JOB_METRICS,
        key: activeEvaluationJob.key,
      });
      getEvaluationJobMetrics(tenantId, evaluationJobKey).then(iterations => {
        dispatch({
          type: SET_EVALUATION_JOB_METRICS,
          key: activeEvaluationJob.key,
          iterations: iterations,
        });
      });
    }
  };
};

export const updateEvaluationJob = (key: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;

    getEvaluationJob(tenantId, key).then(evaluationJob => {
      dispatch({
        type: UPDATE_EVALUATION_JOB,
        evaluationJob: evaluationJob,
      });
    });
  };
};

export const resetActiveEvaluationJob = (): EvaluationJobStateActionTypes => ({
  type: RESET_ACTIVE_EVALUATION_JOB,
});

export const setEvaluationJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_EVALUATION_JOB_LIST,
    });
    listEvaluationJobs(tenantId).then(evaluationJobList => {
      dispatch({
        type: SET_EVALUATION_JOB_LIST,
        evaluationJobList: evaluationJobList,
      });
    });
  };
};

export const updateEvaluationJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    listEvaluationJobs(tenantId, undefined, true).then(evaluationJobList => {
      dispatch({
        type: UPDATE_EVALUATION_JOB_LIST,
        evaluationJobList: evaluationJobList,
      });
    });
  };
};

export const resetEvaluationJobList = (): EvaluationJobStateActionTypes => ({
  type: RESET_EVALUATION_JOB_LIST,
});
