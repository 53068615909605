import { useCallback } from 'react';
import { useSelector } from '..';
import {
  MbcSearchInstanceRouter,
  MbcBusinessRuleRouter,
  MbcSynonymSetRouter,
  MbcUrlRedirectSetRouter,
} from '../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { useDefaultSearchInstance } from '../use-search-instance/useDefaultSearchInstance';

export const useSearchInstanceRouter = (searchInstanceId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const searchInstanceIdMapper = useSelector(state => state.searchInstanceList.searchInstanceIdMapper);

  const defaultSearchInstanceId = useDefaultSearchInstance();

  const targetSearchInstanceId =
    !!searchInstanceId && !!searchInstanceIdMapper[searchInstanceId] ? searchInstanceId : defaultSearchInstanceId;
  return useCallback(MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, targetSearchInstanceId), [
    activeTenant,
    activeEnvironment,
    activeSearchInstance,
  ]);
};

export const useBusinessRuleRouter = (businessRuleId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const activeBusinessRule = useSelector(state => state.businessRule.activeBusinessRule);
  const businessRuleIdMapper = useSelector(state => state.businessRule.businessRuleIdMapper);

  const targetBusinessRuleId =
    !!businessRuleId && !!businessRuleIdMapper[businessRuleId] ? businessRuleId : activeBusinessRule.id;
  return useCallback(
    MbcBusinessRuleRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id, targetBusinessRuleId),
    [activeTenant, activeEnvironment, activeSearchInstance, activeBusinessRule],
  );
};

export const useSynonymSetRouter = (synonymSetId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const activeSynonymSet = useSelector(state => state.synonymSet.activeSynonymSet);
  const synonymSetIdMapper = useSelector(state => state.synonymSet.synonymSetIdMapper);

  const targetSynonymSetId =
    !!synonymSetId && !!synonymSetIdMapper[synonymSetId.toLowerCase()] ? synonymSetId : activeSynonymSet.id;
  return useCallback(
    MbcSynonymSetRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id, targetSynonymSetId),
    [activeTenant, activeEnvironment, activeSearchInstance, activeSynonymSet],
  );
};

export const useUrlRedirectSetRouter = (urlRedirectSetId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const activeUrlRedirectSet = useSelector(state => state.urlRedirectSet.activeUrlRedirectSet);
  const UrlRedirectSetIdMapper = useSelector(state => state.urlRedirectSet.urlRedirectSetIdMapper);

  const targetUrlRedirectSetId =
    !!urlRedirectSetId && !!UrlRedirectSetIdMapper[urlRedirectSetId] ? urlRedirectSetId : activeUrlRedirectSet.id;
  return useCallback(
    MbcUrlRedirectSetRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id, targetUrlRedirectSetId),
    [activeTenant, activeEnvironment, activeSearchInstance, activeUrlRedirectSet],
  );
};
