import { getI18n } from 'react-i18next';
import { ingestDocumentsFromFile, getIngestionStatus, ingestDocument } from '../../../../restful-apis/ingestion.api';
import { IngestionResponse, IngestionStatusResponse } from '../../../../restful-apis/dto/ingestion/ingestion';
import { SET_PRODUCT_SEARCH_NON_EDITABLE, SET_PRODUCT_SEARCH_EDITABLE } from '../../../types/productSearch.d';
import { PollActionFailureHandler, PollActionSuccessHandler, ReduxPollAction, PollStatus } from '../PollAction.types';
import { FetchResponse } from '../../../../restful-apis/http-client/http-interceptors';
import { isArray } from 'lodash';

export const ProductsIngestionPollAction = (
  document: File,
  tenantId: string,
  searchIndexId: string,
  onSuccess?: PollActionSuccessHandler<IngestionResponse>,
  onFailure?: PollActionFailureHandler<IngestionStatusResponse>,
): ReduxPollAction<IngestionResponse, IngestionStatusResponse> => {
  const i18n = getI18n();
  return {
    // Needs to figure out why we are using is OOBE here!
    request: ingestDocumentsFromFile(document, tenantId, searchIndexId, false),
    pollRequest: (response: IngestionResponse) => getIngestionStatus(tenantId, searchIndexId, response.updateId),
    pollStatus: (response: IngestionStatusResponse) =>
      response.status === 'Succeeded' || response.status === 'PartiallySucceeded'
        ? PollStatus.Succeeded
        : response.status === 'Failed'
        ? PollStatus.Failed
        : PollStatus.Pending,
    notification: {
      failure: () => ({
        title: i18n.t('product-management.file-ingestion-req-failure'),
      }),
      inProgress: {
        title: i18n.t('product-management.file-ingestion-req-inprogress'),
      },
      success: () => ({
        title: i18n.t('product-management.file-ingestion-req-success'),
      }),
    },
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

export const IngestOrUpdateProductPollAction = (
  productId: string,
  product: any,
  tenantId: string,
  searchIndexId: string,
  onSuccess?: PollActionSuccessHandler<IngestionResponse>,
  onFailure?: PollActionFailureHandler<IngestionStatusResponse>,
): ReduxPollAction<IngestionResponse, IngestionStatusResponse> => {
  const i18n = getI18n();
  return {
    // Needs to figure out why we are using is OOBE here!
    request: ingestDocument(product, tenantId, searchIndexId, false),
    pollRequest: (response: IngestionResponse) => getIngestionStatus(tenantId, searchIndexId, response.updateId),
    pollStatus: (response: IngestionStatusResponse) =>
      response.status === 'Succeeded' || response.status === 'PartiallySucceeded'
        ? PollStatus.Succeeded
        : response.status === 'Failed'
        ? PollStatus.Failed
        : PollStatus.Pending,
    errorMessage: (response: IngestionStatusResponse | FetchResponse) => {
      const responseInternal = response as IngestionStatusResponse;
      return !!responseInternal.failureMessages &&
        isArray(responseInternal.failureMessages) &&
        !!responseInternal.failureMessages.length
        ? responseInternal.failureMessages.join(', ')
        : !!responseInternal.records && isArray(responseInternal.records) && responseInternal.records.length
        ? responseInternal.records
            .filter(record => !!record.errorMessage)
            .map(record => record.errorMessage)
            .join(', ')
        : '';
    },
    preActionMapper: {
      type: SET_PRODUCT_SEARCH_NON_EDITABLE,
      productId: productId,
    },
    postActionMapper: () => ({
      type: SET_PRODUCT_SEARCH_EDITABLE,
      productId: productId,
    }),
    notification: {
      failure: () => ({
        title: i18n.t('product-management.ingestion-req-failure'),
      }),
      inProgress: {
        title: i18n.t('product-management.ingestion-req-inprogress'),
      },
      success: () => ({
        title: i18n.t('product-management.ingestion-req-success'),
      }),
    },
    onSuccess: onSuccess,
    onFailure: (response, dispatch) => {
      dispatch({
        type: SET_PRODUCT_SEARCH_EDITABLE,
        productId: productId,
      });
      onFailure && onFailure(response);
    },
  };
};
