import { useSelector } from '..';
import { useMemo } from 'react';
import { Role } from '../../types/roleList';

export const useUserRoleList = (): Role[] => {
  const userPrimaryEmail = useSelector(state => state.user.email);
  const roleIdMapper = useSelector(state => state.roleList.roleIdMapper);
  const userSecondaryEmail = useSelector(state => state.user.secondaryEmail);

  return useMemo(() => {
    const roleList = Object.values(roleIdMapper);
    const userRoleList = roleList.filter(
      r =>
        !!r.users.find(
          u =>
            u.email.toLocaleLowerCase() === userPrimaryEmail.toLocaleLowerCase() ||
            u.email.toLocaleLowerCase() === userSecondaryEmail.toLocaleLowerCase(),
        ),
    );

    return userRoleList.length > 0 ? userRoleList : roleList;
  }, [roleIdMapper, userPrimaryEmail, userSecondaryEmail]);
};
