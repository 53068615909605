import {
  GET_PERSONALIZATION_A_SEARCH_RESULT,
  GET_PERSONALIZATION_B_SEARCH_RESULT,
  GET_PROMOTED_A_SEARCH_RESULTS,
  GET_PROMOTED_B_SEARCH_RESULTS,
  GET_RELEVANCE_A_SEARCH_RESULT,
  GET_RELEVANCE_B_SEARCH_RESULT,
  GET_SEARCH_RESULT,
  LOADING_SEARCH_RESULT,
  SearchDemoState,
  SearchDemoStateActionTypes,
  RESET_SEARCH_RESULT,
  GET_AUGMENTED_SEARCH_RESULT,
  ProductSearchResult,
} from '../types/searchDemo.d';

const createEmptySearchResult = (): ProductSearchResult => {
  return {
    query: '',
    skip: 0,
    top: 0,
    isLoading: true,
    alteredQuery: '',
    searchRawResponse: {},
    searchResultItems: [],
    totalEstimatedMatches: 0,
  };
};

const initialState: SearchDemoState = {
  searchResult: createEmptySearchResult(),
  augmentedSearchResult: createEmptySearchResult(),
  personalizationASearchResults: {
    resultCount: 0,
    isLoading: true,
    alteredQuery: '',
    originalQuery: '',
    searchResultItems: [],
  },
  personalizationBSearchResults: {
    resultCount: 0,
    isLoading: true,
    alteredQuery: '',
    originalQuery: '',
    searchResultItems: [],
  },
  promotedASearchResults: {
    resultCount: 0,
    isLoading: true,
    alteredQuery: '',
    originalQuery: '',
    searchResultItems: [],
  },
  promotedBSearchResults: {
    resultCount: 0,
    isLoading: true,
    alteredQuery: '',
    originalQuery: '',
    searchResultItems: [],
  },
  relevanceASearchResults: {
    resultCount: 0,
    isLoading: true,
    alteredQuery: '',
    originalQuery: '',
    searchResultItems: [],
  },
  relevanceBSearchResults: {
    resultCount: 0,
    isLoading: true,
    alteredQuery: '',
    originalQuery: '',
    searchResultItems: [],
  },
};

export function searchDemoReducer(state = initialState, action: SearchDemoStateActionTypes): SearchDemoState {
  switch (action.type) {
    case GET_RELEVANCE_A_SEARCH_RESULT: {
      return {
        ...state,
        relevanceASearchResults: action.relevanceASearchResults,
      };
    }
    case GET_RELEVANCE_B_SEARCH_RESULT: {
      return {
        ...state,
        relevanceBSearchResults: action.relevanceBSearchResults,
      };
    }
    case GET_PERSONALIZATION_A_SEARCH_RESULT: {
      return {
        ...state,
        personalizationASearchResults: action.personalizationASearchResults,
      };
    }
    case GET_PERSONALIZATION_B_SEARCH_RESULT: {
      return {
        ...state,
        personalizationBSearchResults: action.personalizationBSearchResults,
      };
    }
    case GET_PROMOTED_A_SEARCH_RESULTS: {
      return {
        ...state,
        promotedASearchResults: action.promotedASearchResults,
      };
    }
    case GET_PROMOTED_B_SEARCH_RESULTS: {
      return {
        ...state,
        promotedBSearchResults: action.promotedBSearchResults,
      };
    }
    case LOADING_SEARCH_RESULT: {
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          isLoading: true,
        },
      };
    }
    case GET_SEARCH_RESULT: {
      return {
        ...state,
        searchResult: action.searchResult,
      };
    }
    case GET_AUGMENTED_SEARCH_RESULT: {
      return {
        ...state,
        augmentedSearchResult: action.searchResult,
      };
    }
    case RESET_SEARCH_RESULT: {
      return {
        ...state,
        searchResult: createEmptySearchResult(),
        augmentedSearchResult: createEmptySearchResult(),
      };
    }
    default:
      return state;
  }
}
