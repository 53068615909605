import React, { useState } from 'react';
import { AppState } from '../../../../../store/reducers';
import { setFeaturesCoverage } from '../../../../../store/actions/insightsAction';
import { DashboardChartCard } from '../common/DashboardChartCard';
import { useSelector, useDispatch } from '../../../../../store/hooks';

import {
  classNamesFunction,
  Modal,
  ShimmeredDetailsList,
  IDetailsRowProps,
  IDetailsRowStyles,
  DetailsRow,
  DetailsListLayoutMode,
  ConstrainMode,
  SelectionMode,
  IconButton,
  Link,
} from 'office-ui-fabric-react';
import { FeatureCoverageStyleProps, FeatureCoverageStyles, FeatureCoverageProps } from './FeatureCoverage.types';
import { BarChart } from '../../../../../components/common/charts/BarChart/BarChart';
import { LayoutType } from '../../../../../components/common/charts/BarChart/BarChart.types';
import numeral from 'numeral';
import { TitledContentCard } from '../../../../../components/common/ContentCard/TitledContentCard';
import { getChartTitleTooltipMessage } from '../Insights.utils';
import { NamePercentage } from '../../../../../store/types/insights';

const getClassNames = classNamesFunction<FeatureCoverageStyleProps, FeatureCoverageStyles>();

export const FeatureCoverageBase = (props: FeatureCoverageProps) => {
  const { t, styles, theme, className, componentRef, hasTimeSelector } = props;
  const featuresCoverage = useSelector((state: AppState) => state.insights.featureCoverage);
  const isFeatureCoverageLoading = useSelector((state: AppState) => state.insights.isFeatureCoverageLoading);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const onRenderRowInternal = (props?: IDetailsRowProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 !== 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: '#FAFAFA' };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const getChartDetails = () => {
    return (
      <ShimmeredDetailsList
        isHeaderVisible={true}
        shimmerLines={5}
        className={classNames.modalDetailsList}
        items={featuresCoverage.series}
        selectionMode={SelectionMode.none}
        onRenderRow={props => onRenderRowInternal(props)}
        columns={[
          {
            key: 'name',
            name: t('insights.features-coverage.modal-name-column'),
            fieldName: 'name',
            minWidth: 200,
          },
          {
            key: 'percentage',
            name: t('insights.features-coverage.percentage-label'),
            fieldName: 'percentage',
            minWidth: 200,
            // eslint-disable-next-line react/display-name
            onRender: (item: NamePercentage) => {
              return <span>{item.percentage === 100 ? '100%' : numeral(item.percentage).format('0.0000') + '%'}</span>;
            },
          },
        ]}
        constrainMode={ConstrainMode.unconstrained}
        layoutMode={DetailsListLayoutMode.justified}
      />
    );
  };

  return (
    <>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} isBlocking={true} isDarkOverlay={true}>
          <TitledContentCard
            body={getChartDetails()}
            title={t('insights.features-coverage.label')}
            className={classNames.modalContentCard}
            actions={
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel={t('common.cancel')}
                onClick={() => setIsModalOpen(false)}
              />
            }
          />
        </Modal>
      )}
      <div className={classNames.root}>
        <DashboardChartCard
          componentRef={componentRef}
          hasTimeSelector={hasTimeSelector}
          cardTitle={t('insights.features-coverage.label')}
          isLoading={isFeatureCoverageLoading}
          refreshOptions={{
            text: getChartTitleTooltipMessage(featuresCoverage.cacheDetails),
            onRefreshClick: () => {
              dispatch(setFeaturesCoverage('P1D'));
            },
          }}
          onChange={() => {
            dispatch(setFeaturesCoverage('P1D'));
          }}
          body={
            <BarChart
              yLabel={t('insights.features-coverage.percentage-label')}
              isLoadingLabel={t('common.charts-loading')}
              isLoading={isFeatureCoverageLoading}
              layout={LayoutType.vertical}
              yTickMaximumTextLength={10}
              data={featuresCoverage.series.slice(0, 10).map(feature => ({
                x: feature.percentage,
                y: feature.name,
              }))}
              minWidth={220}
              minHeight={250}
              noDataLabel={t('common.charts-no-data')}
              toolTipFormatter={(payload: any) => {
                let index = featuresCoverage.series.findIndex(value => value.name === payload.payload.y);
                return <>{numeral(featuresCoverage.series[index].percentage).format('0.00') + '%'}</>;
              }}
            />
          }
          footer={
            featuresCoverage.series.length > 10 ? (
              <div className={classNames.footer}>
                <Link className={classNames.footerLink} onClick={() => setIsModalOpen(true)}>
                  {t('insights.features-coverage.footer-link')}
                </Link>
              </div>
            ) : (
              undefined
            )
          }
        />
      </div>
    </>
  );
};
