import * as Operators from './operators';
import { Moment } from 'moment';
import { generateUniqueIdentifier } from '..';

export type ConditionType = StringCondition | StringSetCondition | NumericCondition | DateTimeCondition | BoolCondition;

export enum Condition {
  ConditionBlock = 'ConditionBlock',
  StringCondition = 'StringCondition',
  StringSetCondition = 'StringSetCondition',
  NumericCondition = 'NumericCondition',
  DateTimeCondition = 'DateTimeCondition',
  BoolCondition = 'BoolCondition',
}

export interface ConditionBase {
  key: string;
  _type: string;
}

export interface StringCondition extends ConditionBase {
  field: string;
  value?: string;
  operator: Operators.EquivalenceOperator;
}

export interface StringSetCondition extends ConditionBase {
  field: string;
  values?: string[];
  operator: Operators.SetOperator;
}

export interface NumericCondition extends ConditionBase {
  field: string;
  value?: number;
  operator: Operators.ComparisonOperator;
}

export interface DateTimeCondition extends ConditionBase {
  field: string;
  value?: Moment;
  operator: Operators.DateTimeOperator;
}

export interface BoolCondition extends ConditionBase {
  field: string;
  value?: boolean;
  operator: Operators.EquivalenceOperator;
}

export interface ConditionBlock extends ConditionBase {
  conditions: (ConditionType | ConditionBlock)[];
  operator: Operators.LogicalOperator;
}

export interface BoostCondition {
  condition: ConditionBlock;
  boost: number;
}

export const createStringCondition = (condition: {
  field: string;
  value?: string;
  operator?: Operators.EquivalenceOperator;
}): StringCondition => {
  return {
    key: generateUniqueIdentifier(),
    _type: Condition.StringCondition,
    field: condition.field,
    value: condition.value,
    operator: condition.operator || 'eq',
  };
};

export const createStringSetCondition = (condition: {
  field: string;
  values?: string[];
  operator?: Operators.SetOperator;
}): StringSetCondition => {
  return {
    key: generateUniqueIdentifier(),
    _type: Condition.StringSetCondition,
    field: condition.field,
    values: condition.values,
    operator: condition.operator || 'in',
  };
};

export const createNumericCondition = (condition: {
  field: string;
  value?: number;
  operator?: Operators.ComparisonOperator;
}): NumericCondition => {
  return {
    key: generateUniqueIdentifier(),
    _type: Condition.NumericCondition,
    field: condition.field,
    value: condition.value,
    operator: condition.operator || 'ge',
  };
};

export const createDateTimeCondition = (condition: {
  field: string;
  fieldType?: string;
  value?: Moment;
  operator?: Operators.DateTimeOperator;
}): DateTimeCondition => {
  return {
    key: generateUniqueIdentifier(),
    _type: Condition.DateTimeCondition,
    field: condition.field,
    value: condition.value,
    operator: condition.operator || (condition.fieldType === 'DateSegment' ? 'in' : 'ge'),
  };
};

export const createBoolCondition = (condition: {
  field: string;
  value?: boolean;
  operator?: Operators.EquivalenceOperator;
}): BoolCondition => {
  return {
    key: generateUniqueIdentifier(),
    _type: Condition.BoolCondition,
    field: condition.field,
    value: condition.value,
    operator: condition.operator || 'eq',
  };
};

export const createConditionBlock = (condition?: {
  conditions?: (ConditionType | ConditionBlock)[];
  operator?: Operators.LogicalOperator;
}): ConditionBlock => {
  return {
    key: generateUniqueIdentifier(),
    _type: Condition.ConditionBlock,
    conditions: condition && condition.conditions ? condition.conditions : [],
    operator: condition && condition.operator ? condition.operator : 'and',
  };
};
