import { Action } from 'redux';
import { ModelJobStatus } from './searchModel';

export const ADD_EVALUATION_JOB = 'ADD_EVALUATION_JOB';
export const UPDATE_EVALUATION_JOB = 'UPDATE_EVALUATION_JOB';
export const SET_EVALUATION_JOB_LIST = 'SET_EVALUATION_JOB_LIST';
export const RESET_EVALUATION_JOB_LIST = 'RESET_EVALUATION_JOB_LIST';
export const SET_ACTIVE_EVALUATION_JOB = 'SET_ACTIVE_EVALUATION_JOB';
export const UPDATE_EVALUATION_JOB_LIST = 'UPDATE_EVALUATION_JOB_LIST';
export const SET_EVALUATION_JOB_METRICS = 'SET_EVALUATION_JOB_METRICS';
export const RESET_ACTIVE_EVALUATION_JOB = 'RESET_ACTIVE_EVALUATION_JOB';
export const LOADING_EVALUATION_JOB_LIST = 'LOADING_EVALUATION_JOB_LIST';
export const LOADING_EVALUATION_JOB_METRICS = 'LOADING_EVALUATION_JOB_METRICS';

export interface EvaluationJobMapper {
  [key: string]: EvaluationJob;
}

export interface Evaluation {
  key: string;
  baselineModelkey: string;
  baselineModelName: string;
  baselineModelNdcg: number;
  comparisonModels: {
    key: string;
    Ndcg: number;
    pValue: number;
    NdcgDelta: number;
    evaluationPair: string;
  }[];
}

export interface EvaluationJob {
  key: string;
  name: string;
  email: string;
  isNew: boolean;
  createdAt: string;
  updatedAt: string;
  labelGain: string;
  testDatasetKey: string;
  description: string;
  status: ModelJobStatus;
  isLoading: boolean;
  iterations: Evaluation[];
  baselineModelKey: string;
  comparisonModelKey: string;
}

export interface EvaluationJobState {
  isLoading: boolean;
  activeEvaluationJob: EvaluationJob;
  evaluationJobMapper: EvaluationJobMapper;
}

interface AddEvaluationJob extends Action<typeof ADD_EVALUATION_JOB> {
  evaluationJob: EvaluationJob;
}

interface UpdateEvaluationJob extends Action<typeof UPDATE_EVALUATION_JOB> {
  evaluationJob: EvaluationJob;
}

interface LoadingEvaluationJobMetrics extends Action<typeof LOADING_EVALUATION_JOB_METRICS> {
  key: string;
}

interface SetEvaluationJobMetrics extends Action<typeof SET_EVALUATION_JOB_METRICS> {
  key: string;
  iterations: Evaluation[];
}

interface SetActiveEvaluationJob extends Action<typeof SET_ACTIVE_EVALUATION_JOB> {
  evaluationJob: EvaluationJob;
}

interface UpdateEvaluationJobList extends Action<typeof UPDATE_EVALUATION_JOB_LIST> {
  evaluationJobList: EvaluationJob[];
}

interface SetEvaluationJobList extends Action<typeof SET_EVALUATION_JOB_LIST> {
  evaluationJobList: EvaluationJob[];
}

export type EvaluationJobStateActionTypes =
  | AddEvaluationJob
  | UpdateEvaluationJob
  | SetEvaluationJobList
  | SetActiveEvaluationJob
  | SetEvaluationJobMetrics
  | UpdateEvaluationJobList
  | LoadingEvaluationJobMetrics
  | Action<typeof RESET_EVALUATION_JOB_LIST>
  | Action<typeof RESET_ACTIVE_EVALUATION_JOB>
  | Action<typeof LOADING_EVALUATION_JOB_LIST>;
