import React, { useCallback } from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import { MbcClampStyles, MbcClampStyleProps, MbcClampProps } from './MbcClamp.types';

const getClassNames = classNamesFunction<MbcClampStyleProps, MbcClampStyles>();

export const MbcClampBase = (props: MbcClampProps) => {
  const { styles, theme, className, componentRef, text, lines, clickable } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const Content = useCallback(
    () => (
      <div
        ref={componentRef}
        className={classNames.root}
        style={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: lines,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
        }}
      >
        {text}
      </div>
    ),
    [classNames.root, componentRef, lines, text],
  );

  return clickable ? (
    <a className={classNames.link} href={text}>
      <Content />
    </a>
  ) : (
    <Content />
  );
};
