import { TitledContentCard } from '../../common/ContentCard';
import React, { useState } from 'react';
import { TextField, PrimaryButton, DefaultButton, classNamesFunction, IconButton } from 'office-ui-fabric-react';
import { RoleCloneEditorProps, RoleCloneEditorStyles, RoleCloneEditorStyleProps } from './RoleCloneEditor.types';
import { useDispatch, useSelector } from '../../../store/hooks';
import { createRole } from '../../../store/actions/roleListActions';

const getClassNames = classNamesFunction<RoleCloneEditorStyleProps, RoleCloneEditorStyles>();

export const RoleCloneEditorBase = (props: RoleCloneEditorProps) => {
  const { onDismiss, cloneRole, t, theme, styles } = props;

  const dispatch = useDispatch();

  const [newRoleName, setNewRoleName] = useState(cloneRole.name);

  const activeTenant = useSelector(state => state.tenantsList.activeTenant);

  const classNames = getClassNames(styles, { theme: theme! });

  return (
    <div className={classNames.root}>
      <TitledContentCard
        title={'Clone role'}
        actions={<IconButton key="cancel" onClick={() => onDismiss()} iconProps={{ iconName: 'Cancel' }} />}
        body={
          <TextField
            required
            value={newRoleName}
            validateOnLoad={false}
            onGetErrorMessage={() => (!newRoleName ? t('role-clone-editor.error-message') : '')}
            label={t('role-clone-editor.text-field-label')}
            placeholder={t('role-clone-editor.text-field-placeholder')}
            onChange={(ev, value) => value !== undefined && setNewRoleName(value)}
          />
        }
        footer={
          <div className={classNames.footer}>
            <PrimaryButton
              key="create"
              disabled={!newRoleName || newRoleName === cloneRole.name}
              onClick={() => {
                dispatch(createRole(activeTenant, { ...cloneRole, name: newRoleName }, () => {}, () => {}, true));
                onDismiss();
              }}
            >
              {t('common.create')}
            </PrimaryButton>
            <DefaultButton key="cancel" onClick={onDismiss}>
              {t('common.cancel')}
            </DefaultButton>
          </div>
        }
      />
    </div>
  );
};
