export const NUMERIC_REGEXP = new RegExp(/^\d*(\.\d*)?$/);
export const URL_REGEXP = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
);

export function isNumeric(value: string): boolean {
  return NUMERIC_REGEXP.test(value);
}

export function isValidUrl(value: string): boolean {
  return URL_REGEXP.test(value);
}
