import React from 'react';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { MultiLineChart, Line } from '../../../../components/common/charts/MultiLineChart';
import { ChartContentCard } from '../Common/ChartContentCard';
import { TickType } from '../../../../components/common/charts/common/AxisTickFormatter';
import { useMediaQuery } from 'react-responsive';
import { TooltipProps } from 'recharts';
import { ToolTipFormatter } from '../../../../components/common/charts/common/ToolTipFormatter';
import { AppState } from '../../../../store/reducers';
import { classNamesFunction } from 'office-ui-fabric-react';
import { SearchRequestsStyleProps, SearchRequestsStyles, SearchRequestsBaseProps } from './SearchRequests.types';
import { updateSearchRequestsChart } from '../../../../store/actions/analyticsActions';
import { AggregationInterval } from '../../../../restful-apis/dto/analytics/analytics';

const getClassNames = classNamesFunction<SearchRequestsStyleProps, SearchRequestsStyles>();

export const SearchRequestsBase = (props: SearchRequestsBaseProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  const isTotalSearchRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isTotalSearchRequestsOverTimeLoading,
  );
  const totalSearchRequestsOverTime = useSelector((state: AppState) => state.analytics.totalSearchRequestsOverTime);

  const isFailedRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isFailedRequestsOverTimeLoading,
  );
  const failedRequestsOverTime = useSelector((state: AppState) => state.analytics.failedRequestsOverTime);

  const isSuccessfulRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isSuccessfulRequestsOverTimeLoading,
  );
  const successfulRequestsOverTime = useSelector((state: AppState) => state.analytics.successfulRequestsOverTime);

  const isUserErrorRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isUserErrorRequestsOverTimeLoading,
  );
  const userErrorRequestsOverTime = useSelector((state: AppState) => state.analytics.userErrorRequestsOverTime);

  const getSearchRequestsBreakdown = () => {
    if (
      isTotalSearchRequestsOverTimeLoading ||
      isFailedRequestsOverTimeLoading ||
      isSuccessfulRequestsOverTimeLoading ||
      isUserErrorRequestsOverTimeLoading ||
      totalSearchRequestsOverTime.series.length === 0 ||
      successfulRequestsOverTime.series.length === 0 ||
      failedRequestsOverTime.series.length === 0 ||
      userErrorRequestsOverTime.series.length === 0 ||
      totalSearchRequestsOverTime.series.length !== successfulRequestsOverTime.series.length ||
      successfulRequestsOverTime.series.length !== failedRequestsOverTime.series.length ||
      failedRequestsOverTime.series.length !== userErrorRequestsOverTime.series.length
    ) {
      return [];
    }

    return totalSearchRequestsOverTime.series.map((searchMetric, index) => {
      return {
        xValue: searchMetric.date,
        yValues: [
          failedRequestsOverTime.series[index].value,
          userErrorRequestsOverTime.series[index].value,
          successfulRequestsOverTime.series[index].value,
          searchMetric.value,
        ],
      };
    });
  };

  const largeScreen = useMediaQuery({ minWidth: 1400 });
  const mediumScreen = useMediaQuery({ maxWidth: 1399 });
  const smallScreen = useMediaQuery({ maxWidth: 960 });

  const data = getSearchRequestsBreakdown();
  const yLines: Line[] = [
    {
      name: t('analytics.search-metrics.failed-search-requests'),
      stroke: '#de3700',
    },
    {
      name: t('analytics.search-metrics.user-error-search-requests'),
      stroke: 'gray',
    },
    {
      name: t('analytics.search-metrics.successful-search-requests'),
      stroke: '#0078d4',
    },
    {
      name: t('analytics.search-metrics.total-search-requests'),
      stroke: '#004578',
    },
  ];

  const dataToExport = data.map(dataPoint => ({
    date: dataPoint.xValue,
    ...dataPoint.yValues.reduce(
      (prevValue, currentValue, index) => ({ ...prevValue, [yLines[index].name]: currentValue }),
      {},
    ),
  }));

  return (
    <div className={classNames.root}>
      <ChartContentCard
        className={className}
        exportData={{ fileName: t('analytics.search-metrics.search-requests-break-down'), data: dataToExport }}
        chart={
          <MultiLineChart
            points={data}
            minWidth={300}
            minHeight={220}
            xLine={{
              name: 'Date',
              stroke: 'grey',
            }}
            ylines={yLines}
            legendProps={{ legendContent: [...yLines].reverse() }}
            xAxisProps={{
              interval: smallScreen
                ? data.length > 14
                  ? totalSearchRequestsOverTime.interval + 3
                  : data.length > 8
                  ? totalSearchRequestsOverTime.interval + 2
                  : totalSearchRequestsOverTime.interval + 1
                : mediumScreen
                ? data.length > 14
                  ? totalSearchRequestsOverTime.interval + 2
                  : data.length > 8
                  ? totalSearchRequestsOverTime.interval + 1
                  : totalSearchRequestsOverTime.interval
                : totalSearchRequestsOverTime.interval,
              tickProps: {
                tickType: TickType.DateTime12Hrs,
                fontSize: 12,
                blockRotation: largeScreen || (!smallScreen && data.length < 14),
                rotationBreakPoint: 7,
                rotatedFontSize: 10,
              },
            }}
            toolTipContent={(props: TooltipProps) => <ToolTipFormatter shouldReverseOrder toolTipProps={props} />}
            isLoading={
              isTotalSearchRequestsOverTimeLoading ||
              isFailedRequestsOverTimeLoading ||
              isSuccessfulRequestsOverTimeLoading ||
              isUserErrorRequestsOverTimeLoading
            }
          />
        }
        title={t('analytics.search-metrics.search-requests-break-down')}
        isLoadedAndEmpty={
          !(
            isTotalSearchRequestsOverTimeLoading ||
            isFailedRequestsOverTimeLoading ||
            isSuccessfulRequestsOverTimeLoading ||
            isUserErrorRequestsOverTimeLoading
          ) &&
          (!totalSearchRequestsOverTime.series.length ||
            !successfulRequestsOverTime.series.length ||
            !failedRequestsOverTime.series.length ||
            !userErrorRequestsOverTime.series.length)
        }
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
          aggInterval: AggregationInterval,
        ) =>
          dispatch(
            updateSearchRequestsChart(
              responseCode,
              start,
              end,
              markets,
              regions,
              searchResultsStatus,
              searchInstances,
              aggInterval,
            ),
          )
        }
      />
    </div>
  );
};
