import { useDispatch } from '../store/hooks';
import { MbcRouter } from '../router/MbcRouter.base';
import React, { useEffect, useCallback } from 'react';
import { MbcRouteKey, MbcRouteBase } from '../router/MbcRouter.types';
import { useTenantList } from '../store/hooks/use-list/useTenantList';
import { getUserTenantList, resetUserTenantList } from '../store/actions/tenantsListActions';
import { MbcAdminRouteMapper } from '../router/MbcAdminRouter/MbcAdminRouter.config';
import { useProxyRouter } from '../store/hooks/use-router/useProxyRouter';
import { MbcNavBar } from '../components/MbcNavBar';
import { ToastNotification } from '../components/ToastNotification';
import { TrialEnvironmentModal } from '../components/TrialEnvironmentModal';
import AuthGuard from '../guards/AuthGuard';
import { NotificationStatus } from '../store/types/notificationCenter.d';
import { ErrorBoundary } from '../components/ErrorBoundary';
import store from '../store';

window.addEventListener('beforeunload', function(e) {
  const notifications = store.getState().notificationsCenter.notifications;
  if (e && notifications.some(n => n.status === NotificationStatus.InProgress)) {
    e.preventDefault();
    e.returnValue = '';
  }
});

export const Root = () => {
  const dispatch = useDispatch();
  const tenantList = useTenantList();
  const MbcProxyRouteMapper = useProxyRouter();

  useEffect(() => {
    dispatch(getUserTenantList());
    return () => {
      dispatch(resetUserTenantList());
    };
  }, [dispatch]);

  const getMbcDefaultAdminRedirectRoute = useCallback(
    (): MbcRouteBase | undefined =>
      tenantList.length > 0
        ? // Select first user tenant as default tenant
          MbcAdminRouteMapper(tenantList[0])[MbcRouteKey.Tenant]
        : undefined,
    [tenantList],
  );

  return (
    <AuthGuard>
      <ErrorBoundary component="Root">
        <ToastNotification />
        <MbcNavBar />
        <TrialEnvironmentModal />
        <ErrorBoundary component="Main">
          <MbcRouter
            useDefaultRoute={false}
            routes={[MbcProxyRouteMapper(MbcRouteKey.TenantProxy)]}
            defaultRedirectRoute={getMbcDefaultAdminRedirectRoute()}
          />
        </ErrorBoundary>
      </ErrorBoundary>
    </AuthGuard>
  );
};
