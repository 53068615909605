import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { browserHistory } from '../history.module';

class LoggerService {
  reactPlugin: ReactPlugin;
  appInsights: ApplicationInsights;
  INSTRUMENTATION_KEY = '18648397-8b4d-49e0-9618-0ef67b3f7332';

  constructor(isAnalyticsConsented: boolean = false) {
    this.reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.INSTRUMENTATION_KEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [this.reactPlugin],
        isCookieUseDisabled: !isAnalyticsConsented,
        isStorageUseDisabled: !isAnalyticsConsented,
        extensionConfig: {
          [this.reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
  }

  initialize() {
    this.appInsights.loadAppInsights();
  }

  logError(message: string) {
    this.appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error });
  }

  log(eventName: string) {
    this.appInsights.trackEvent({ name: eventName });
  }
}

export let logger: LoggerService | undefined = undefined;

export const initAnalytics = (isConsented = false) => {
  logger = new LoggerService(isConsented);
  logger.initialize();
};
