import { EnvironmentEditorStyles, EnvironmentEditorStyleProps } from './EnvironmentEditor.types';

export const styles = (props: EnvironmentEditorStyleProps): EnvironmentEditorStyles => {
  const { theme } = props;

  return {
    sectionTitle: [
      {
        fontSize: '20px',
        lineHeight: '28px',
      },
    ],
    sectionCaption: [
      {
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.neutralSecondary,
      },
    ],
    sectionSeparator: [
      'mbc-section-separator',
      {
        background: props.theme.palette.neutralLight,
        width: '100%',
        height: 1,
      },
    ],
    contentMaxWidth: [
      {
        maxWidth: '65%',
      },
    ],
    serviceRegionsDropdown: [
      {
        width: '200px',
        selectors: {
          '.ms-Dropdown-title': {
            backgroundColor: !props.regionsListEnabled ? props.theme.palette.neutralLighter : undefined,
            borderColor: !props.regionsListEnabled ? props.theme.palette.neutralLighter : undefined,
            color: !props.regionsListEnabled ? props.theme.palette.neutralTertiary : undefined,
          },
          '.ms-Dropdown-caretDown': {
            color: !props.regionsListEnabled ? props.theme.palette.neutralTertiary : undefined,
          },
        },
      },
    ],
  };
};
