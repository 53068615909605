import {
  EnvironmentsSectionContentStyleProps,
  EnvironmentsSectionContentStyles,
} from './EnvironmentsSectionContent.types';

export const styles = (props: EnvironmentsSectionContentStyleProps): EnvironmentsSectionContentStyles => {
  const { theme } = props;

  return {
    adminAccessLabel: [{ fontSize: 14, lineHeight: 19, color: theme.palette.black, fontWeight: 500, marginRight: 15 }],
    adminAccessLabelDesc: [
      {
        fontSize: 12,
        lineHeight: 22,
        textTransform: 'uppercase',
        fontVariant: 'small-caps',
        color: theme.palette.neutralSecondary,
      },
    ],
    adminAccessLabelContainer: { marginBottom: 10 },
  };
};
