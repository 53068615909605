import * as React from 'react';
import { ProgressIndicator as ProgressIndicatorUI } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';

interface ProgressIndicatorProps {
  showProgressIndicator: boolean;
}

class ProgressIndicator extends React.Component<ProgressIndicatorProps> {
  public render() {
    return this.props.showProgressIndicator ? <ProgressIndicatorUI barHeight={4} /> : null;
  }
}

const mapStateToProps = (state: AppState) => ({
  showProgressIndicator: state.progressIndicator.inProgress,
});

export default connect(mapStateToProps)(ProgressIndicator);
