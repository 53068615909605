import { LineChartPoint, MultiLineChart } from '../../../../components/common/charts/MultiLineChart';
import { ModelJobStatus, SearchModel } from '../../../../store/types/customML/searchModel.d';
import {
  NdcgMetric,
  PointsMapper,
  TrainSearchModelProps,
  TrainSearchModelStyleProps,
  TrainSearchModelStyles,
} from './TrainSearchModel.types';
import { NdcgTestMetric, NdcgTrainMetric } from './TrainSearchModel.config';
import React, { useEffect, useState } from 'react';
import { Stack, classNamesFunction } from 'office-ui-fabric-react';
import { configureDefaultObject, isAuthorized } from '../../../../utils';
import { setTrainingJobMetrics, trainSearchModel } from '../../../../store/actions/customML/trainingJobActions';
import { useDispatch, useSelector } from '../../../../store/hooks';

import { DashboardChartCard } from '../../../EnvironmentList/Environment/Insights/common/DashboardChartCard';
import { ModelParamsPreview } from './ModelParamsPreview';
import { PageTemplate } from '../../../../components/common/PageTemplate';
import { Permission } from '../../../../config/userPermissions.config';
import { Point } from '../../../../store/types/customML/trainingJob.d';
import { TickType } from '../../../../components/common/charts/common/AxisTickFormatter';
import { ToolTipFormatter } from '../../../../components/common/charts/common/ToolTipFormatter';
import { TooltipProps } from 'recharts';
import { TrainModelPanel } from './TrainModelPanel';
import { getStatusIconProps } from '../SearchModel.utils';
import { useActiveTraining } from '../../../../store/hooks/use-active-job/useActiveTraining';
import { useTenantPermissions } from '../../../../store/hooks/use-user-permissions/UseTenantPermissions';

const getClassNames = classNamesFunction<TrainSearchModelStyleProps, TrainSearchModelStyles>();

export function TrainSearchModelBase(props: TrainSearchModelProps) {
  const { t, styles, theme } = props;

  const dispatch = useDispatch();
  const trainingJob = useActiveTraining();
  const userPermissions = useTenantPermissions();
  const tenantId = useSelector<string>(state => state.tenantsList.activeTenant.id);
  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);

  const classNames = getClassNames(styles, { theme: theme!, isDefinedModel: !!trainingJob.key });

  const [isTrainPanelOpen, showTrainPanel] = useState<boolean>(
    () => !trainingJob.key && isAuthorized(userPermissions, Permission.SearchCustomMLReadWrite),
  );

  useEffect(() => {
    if (!!trainingJob.key && trainingJob.status === ModelJobStatus.Finished) {
      dispatch(setTrainingJobMetrics(trainingJob.key));
    }
    return () => {};
  }, [dispatch, trainingJob.key, trainingJob.status]);

  const getMultiLineNdcgMetrics = (NdcgAt1: Point[], NdcgAt3: Point[], NdcgAt5: Point[]): LineChartPoint[] => {
    const pointsMap: PointsMapper = configureDefaultObject<PointsMapper, number[]>({}, []);

    NdcgAt1.forEach(point => (pointsMap[point.x] = pointsMap[point.x].concat(point.y)));
    NdcgAt3.forEach(point => (pointsMap[point.x] = pointsMap[point.x].concat(point.y)));
    NdcgAt5.forEach(point => (pointsMap[point.x] = pointsMap[point.x].concat(point.y)));

    const points = Object.entries(pointsMap);
    return points.every(point => point[1].length === 3)
      ? points.map(point => ({ xValue: point[0], yValues: point[1] }))
      : [];
  };

  const getNdcgTrainingMetrics = (): LineChartPoint[] => {
    const NdcgAt1 = trainingJob.metricsMapper[NdcgTrainMetric.NDCGAt1.key].points;
    const NdcgAt3 = trainingJob.metricsMapper[NdcgTrainMetric.NDCGAt3.key].points;
    const NdcgAt5 = trainingJob.metricsMapper[NdcgTrainMetric.NDCGAt5.key].points;
    return getMultiLineNdcgMetrics(NdcgAt1, NdcgAt3, NdcgAt5);
  };

  const getNdcgTestingMetrics = (): LineChartPoint[] => {
    const NdcgAt1 = trainingJob.metricsMapper[NdcgTestMetric.NDCGAt1.key].points;
    const NdcgAt3 = trainingJob.metricsMapper[NdcgTestMetric.NDCGAt3.key].points;
    const NdcgAt5 = trainingJob.metricsMapper[NdcgTestMetric.NDCGAt5.key].points;
    return getMultiLineNdcgMetrics(NdcgAt1, NdcgAt3, NdcgAt5);
  };

  const statusIconColor = () => {
    const iconProps = getStatusIconProps(trainingJob.status);
    return iconProps.style ? iconProps.style.color : '';
  };
  const ChartFooter = (props: { Ndcg: NdcgMetric }) => (
    <Stack horizontal grow horizontalAlign={'space-between'} tokens={{ padding: '16px 0' }}>
      <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 6 }}>
        <div
          className={classNames.status}
          style={{ backgroundColor: !!trainingJob.key ? statusIconColor() : '#F04A47' }}
        />
        <div className={classNames.legendLabel}>
          {!!trainingJob.key ? t(`dynamic:custom-ml.job-status.${trainingJob.status}`) : 'No data defined'}
        </div>
      </Stack>
      <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 16 }}>
        <Stack horizontal tokens={{ childrenGap: 6 }}>
          <div className={classNames.legendIcon} style={{ backgroundColor: props.Ndcg.NDCGAt1.color }} />
          <div className={classNames.legendLabel}>{props.Ndcg.NDCGAt1.legend}</div>
        </Stack>
        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 6 }}>
          <div className={classNames.legendIcon} style={{ backgroundColor: props.Ndcg.NDCGAt3.color }} />
          <div className={classNames.legendLabel}>{props.Ndcg.NDCGAt3.legend}</div>
        </Stack>
        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 6 }}>
          <div className={classNames.legendIcon} style={{ backgroundColor: props.Ndcg.NDCGAt5.color }} />
          <div className={classNames.legendLabel}>{props.Ndcg.NDCGAt5.legend}</div>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <>
      <TrainModelPanel
        isOpen={isTrainPanelOpen}
        onDismiss={() => showTrainPanel(false)}
        onSubmit={trainingJob => dispatch(trainSearchModel(tenantId, searchModel.key, trainingJob))}
      />
      <PageTemplate
        breadcrumbVisible
        styles={classNames.subComponentStyles.page}
        pageHeaderProps={{
          title: t('custom-ml.train-model.page-title'),
        }}
        onRenderContent={() => (
          <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'stretch'} tokens={{ childrenGap: 16 }}>
            <ModelParamsPreview
              key="view-params"
              styles={classNames.subComponentStyles.parametersCard}
              onClick={!searchModel.isSubmittingTraining ? () => showTrainPanel(true) : undefined}
            />
            <Stack tokens={{ childrenGap: 16 }} style={{ flexBasis: '65%' }}>
              <DashboardChartCard
                key={'training-metrics'}
                cardTitle={t('custom-ml.train-model-details.training-chart-label')}
                styles={classNames.subComponentStyles.chartCard}
                footer={<ChartFooter Ndcg={NdcgTestMetric} />}
                isLoading={trainingJob.isLoadingMetrics}
                body={
                  <MultiLineChart
                    minWidth={360}
                    minHeight={252}
                    isLoading={trainingJob.isLoadingMetrics}
                    toolTipContent={(props: TooltipProps) => (
                      <ToolTipFormatter shouldReverseOrder toolTipProps={props} />
                    )}
                    legendProps={{
                      hideLegend: true,
                      legendContent: [],
                    }}
                    xAxisProps={{
                      tickProps: {
                        tickType: TickType.Numerical,
                        blockRotation: true,
                        fontSize: 12,
                        rotatedFontSize: 10,
                      },
                    }}
                    xLine={{
                      name: t('custom-ml.train-model-details.x-label'),
                      stroke: 'grey',
                    }}
                    ylines={[
                      {
                        name: NdcgTrainMetric.NDCGAt1.label,
                        stroke: NdcgTrainMetric.NDCGAt1.color,
                      },
                      {
                        name: NdcgTrainMetric.NDCGAt3.label,
                        stroke: NdcgTrainMetric.NDCGAt3.color,
                      },
                      {
                        name: NdcgTrainMetric.NDCGAt5.label,
                        stroke: NdcgTrainMetric.NDCGAt5.color,
                      },
                    ]}
                    points={getNdcgTrainingMetrics()}
                  />
                }
              />
              <DashboardChartCard
                key={'testing-metrics'}
                cardTitle={t('custom-ml.train-model-details.test-chart-label')}
                styles={classNames.subComponentStyles.chartCard}
                footer={<ChartFooter Ndcg={NdcgTestMetric} />}
                isLoading={trainingJob.isLoadingMetrics}
                body={
                  <MultiLineChart
                    minWidth={360}
                    minHeight={252}
                    isLoading={trainingJob.isLoadingMetrics}
                    toolTipContent={(props: TooltipProps) => (
                      <ToolTipFormatter shouldReverseOrder toolTipProps={props} />
                    )}
                    xAxisProps={{
                      tickProps: {
                        tickType: TickType.Numerical,
                        blockRotation: true,
                        fontSize: 12,
                        rotatedFontSize: 10,
                      },
                    }}
                    legendProps={{
                      hideLegend: true,
                      legendContent: [],
                    }}
                    xLine={{
                      name: t('custom-ml.train-model-details.x-label'),
                      stroke: 'grey',
                    }}
                    ylines={[
                      {
                        name: NdcgTestMetric.NDCGAt1.label,
                        stroke: NdcgTestMetric.NDCGAt1.color,
                      },
                      {
                        name: NdcgTestMetric.NDCGAt3.label,
                        stroke: NdcgTestMetric.NDCGAt3.color,
                      },
                      {
                        name: NdcgTestMetric.NDCGAt5.label,
                        stroke: NdcgTestMetric.NDCGAt5.color,
                      },
                    ]}
                    points={getNdcgTestingMetrics()}
                  />
                }
              />
            </Stack>
          </Stack>
        )}
      />
    </>
  );
}
