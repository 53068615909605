import { useSelector } from '../../hooks';
import { AppState } from '../../reducers';
import { ErrorType } from '../../../components/ErrorBoundary';

const assertNotFound = (isNotFound: boolean) => {
  if (isNotFound) {
    throw ErrorType.ResourceNotFound;
  }
};

export const useActiveTenantAsserter = (tenantId: string) => {
  const isLoading = useSelector((state: AppState) => state.tenantsList.isLoading);
  const tenantIdMapper = useSelector((state: AppState) => state.tenantsList.tenantIdMapper);
  assertNotFound(!isLoading && !tenantIdMapper[tenantId].id);
};

export const useActiveEnvironmentAsserter = (environmentId: string) => {
  const isLoading = useSelector((state: AppState) => state.environmentList.isLoading);
  const environmentIdMapper = useSelector((state: AppState) => state.environmentList.environmentIdMapper);
  assertNotFound(!isLoading && !environmentIdMapper[environmentId].id);
};

export const useActiveSearchInstanceAsserter = (searchInstanceId: string) => {
  const isLoading = useSelector((state: AppState) => state.searchInstanceList.isLoading);
  const searchInstanceIdMapper = useSelector((state: AppState) => state.searchInstanceList.searchInstanceIdMapper);
  assertNotFound(!isLoading && !searchInstanceIdMapper[searchInstanceId].id);
};

export const useActiveRoleAsserter = (roleId: string) => {
  const isLoading = useSelector((state: AppState) => state.roleList.isLoading);
  const roleIdMapper = useSelector((state: AppState) => state.roleList.roleIdMapper);
  assertNotFound(!isLoading && !roleIdMapper[roleId].id);
};

export const useActiveSearchModelAsserter = (searchModelId: string) => {
  const isLoading = useSelector((state: AppState) => state.searchModels.isLoading);
  const searchModelMapper = useSelector((state: AppState) => state.searchModels.searchModelMapper);
  assertNotFound(!isLoading && !searchModelMapper[searchModelId].key);
};

export const useActiveEvaluationJobAsserter = (evaluationJobId: string) => {
  const isLoading = useSelector((state: AppState) => state.evaluationJobs.isLoading);
  const evaluationJobMapper = useSelector((state: AppState) => state.evaluationJobs.evaluationJobMapper);
  assertNotFound(!isLoading && !evaluationJobMapper[evaluationJobId].key);
};
