import {
  ChoiceGroup,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IChoiceGroupOption,
  IColumn,
  SelectionMode,
  classNamesFunction,
  Checkbox,
} from 'office-ui-fabric-react';
import React, { ReactNode, useState, useEffect } from 'react';
import {
  CategoryPermissions,
  getCategoryPermissionsFilteredByEnvironment,
  getCategoryPermissionsFilteredByProductsAndFeatures,
  getPermissionsConfig,
  PermissionItem,
} from '../../../config/userPermissions.config';
import { EnvironmentGuard } from '../../../guards/EnvironmentGuard/EnvironmentGuard';
import { TenantProductGuard } from '../../../guards/ProductGuard/TenantProductGuard';
import {
  RolePermissionsEditorProps,
  RolePermissionsEditorStyleProps,
  RolePermissionsEditorStyles,
} from './RolePermissionsEditor.types';
import { styles } from './RolePermissionsEditor.styles';
import { TenantFeatureGuard } from '../../../guards/FeatureGuard/TenantFeatureGuard';

const getClassNames = classNamesFunction<RolePermissionsEditorStyleProps, RolePermissionsEditorStyles>();

export const RolePermissionsEditorBase = (props: RolePermissionsEditorProps) => {
  const {
    t,
    viewOnlyMode,
    initialPermissions,
    hiddenPermissions,
    onRolePermissionUpdate,
    disabled,
    theme,
    className,
  } = props;
  const [selectedPermissions, setSelectedPermissions] = useState(initialPermissions);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  useEffect(() => {
    if (viewOnlyMode) {
      setSelectedPermissions(initialPermissions);
    }
  }, [initialPermissions, viewOnlyMode]);

  const handlePermissionChange = (permissionItem: PermissionItem, option?: IChoiceGroupOption) => {
    if (option) {
      const permissionToAdd =
        option.key === 'read' ? permissionItem.readPermission : permissionItem.readWritePermission;
      const permissionToDelete =
        option.key === 'read' ? permissionItem.readWritePermission : permissionItem.readPermission;

      let updatedPermissions = [...selectedPermissions];
      if (permissionToAdd) {
        updatedPermissions =
          selectedPermissions.includes(permissionToAdd) && permissionToDelete === undefined
            ? updatedPermissions.filter(perm => perm !== permissionToAdd)
            : updatedPermissions.concat(permissionToAdd);
      }

      if (permissionToDelete) updatedPermissions = updatedPermissions.filter(perm => perm !== permissionToDelete);
      setSelectedPermissions(updatedPermissions);
      onRolePermissionUpdate(updatedPermissions);
    }
  };

  const handleCheckboxChange = (
    permissionItem: PermissionItem,
    permissionType: string,
    checked?: boolean | undefined,
  ) => {
    const permissionToAdd = checked
      ? permissionType === 'read'
        ? permissionItem.readPermission
        : permissionItem.readWritePermission
      : permissionType === 'read'
      ? permissionItem.readWritePermission
      : permissionItem.readPermission;

    const permissionToDelete = checked
      ? permissionType === 'read'
        ? permissionItem.readWritePermission
        : permissionItem.readPermission
      : permissionType === 'read'
      ? permissionItem.readPermission
      : permissionItem.readWritePermission;

    let updatedPermissions = [...selectedPermissions];
    if (permissionToAdd) updatedPermissions = updatedPermissions.concat(permissionToAdd);
    if (permissionToDelete) updatedPermissions = updatedPermissions.filter(perm => perm !== permissionToDelete);
    setSelectedPermissions(updatedPermissions);
    onRolePermissionUpdate(updatedPermissions);
  };

  const getChoiceGroupSelectedKey = (item: PermissionItem): string | undefined => {
    if (
      item.readPermission &&
      !(item.readWritePermission && selectedPermissions.includes(item.readWritePermission)) &&
      (selectedPermissions.includes(item.readPermission) ||
        (hiddenPermissions && hiddenPermissions.includes(item.readPermission)))
    ) {
      return 'read';
    }

    if (
      item.readWritePermission &&
      (selectedPermissions.includes(item.readWritePermission) ||
        (hiddenPermissions && hiddenPermissions.includes(item.readWritePermission)))
    ) {
      return 'readWrite';
    }
    return undefined;
  };

  const getChoiceGroupOptions = (): IChoiceGroupOption[] => {
    return [
      {
        key: 'read',
        text: '',
        styles: { choiceFieldWrapper: { width: '100px' } },
        ariaLabel: t('roles.permissions.ReadAriaLabel'),
      },
      {
        key: 'readWrite',
        text: '',
        styles: { choiceFieldWrapper: { width: '100px' } },
        ariaLabel: t('roles.permissions.ReadWriteAriaLabel'),
      },
    ];
  };
  const onRenderItemColumn = (item?: PermissionItem, column?: IColumn): ReactNode => {
    if (item && column) {
      switch (column.key) {
        case 'name':
          return <span>{item.permissionName}</span>;
        case 'choiceGroup':
          return (
            <div className={classNames.checkBox}>
              {item.readPermission && item.readWritePermission ? (
                <ChoiceGroup
                  styles={{ flexContainer: { display: 'flex' } }}
                  selectedKey={getChoiceGroupSelectedKey(item)}
                  options={getChoiceGroupOptions()}
                  disabled={viewOnlyMode || disabled}
                  onChange={(ev, options) => handlePermissionChange(item, options)}
                />
              ) : (
                <>
                  {item.readPermission && (
                    <Checkbox
                      checked={
                        selectedPermissions.includes(item.readPermission) || getChoiceGroupSelectedKey(item) === 'read'
                      }
                      onChange={(ev, checked) => handleCheckboxChange(item, 'read', checked)}
                      disabled={viewOnlyMode || disabled}
                    />
                  )}

                  {item.readWritePermission && (
                    <Checkbox
                      checked={
                        selectedPermissions.includes(item.readWritePermission) ||
                        getChoiceGroupSelectedKey(item) === 'readWrite'
                      }
                      onChange={(ev, checked) => handleCheckboxChange(item, 'readWrite', checked)}
                      disabled={viewOnlyMode || disabled}
                    />
                  )}
                </>
              )}
            </div>
          );
      }
    }
  };

  const renderCategoryPermissions = (
    category: CategoryPermissions,
    supportedProducts: string[],
    supportedFeatures: string[],
  ): JSX.Element => {
    const categoryPermissionsPerEnvironment = getCategoryPermissionsFilteredByEnvironment(category.permissionItems);
    const categoryPermissions = getCategoryPermissionsFilteredByProductsAndFeatures(
      categoryPermissionsPerEnvironment,
      supportedProducts,
      supportedFeatures,
    );
    return (
      <div key={category.key} className={classNames.categoryContainer}>
        <span className={classNames.categoryTitle}>{category.key}</span>
        <DetailsList
          styles={{
            headerWrapper: {
              selectors: {
                '.ms-DetailsHeader-cell:hover': { backgroundColor: 'inherit' },
                '.ms-DetailsHeader-cellName': { whiteSpace: 'pre-wrap' },
              },
            },
            contentWrapper: {
              selectors: {
                '.ms-DetailsRow-cell': { display: 'flex', alignItems: 'center', paddingTop: 0, paddingBottom: 0 },
              },
            },
          }}
          items={categoryPermissions}
          columns={[
            {
              key: 'name',
              name: 'Permission',
              fieldName: 'permissionName',
              headerClassName: classNames.fieldColumn,
              minWidth: 200,
            },
            {
              key: 'choiceGroup',
              // hacky solution instead of customizng specific header cell render
              name: 'Read \t\t Read/Write',
              fieldName: 'readPermission',
              headerClassName: classNames.fieldColumn,
              minWidth: 200,
            },
          ]}
          onRenderItemColumn={(item, index, column) => onRenderItemColumn(item, column)}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selectionMode={SelectionMode.none}
          constrainMode={ConstrainMode.unconstrained}
        />
      </div>
    );
  };

  return (
    <div className={classNames.root}>
      {getPermissionsConfig().map(category => (
        <EnvironmentGuard
          key={category.key}
          targetEnvironment={category.environments}
          onRenderChildren={() => (
            <TenantProductGuard products={category.products}>
              {products => (
                <TenantFeatureGuard features={[]}>
                  {features => renderCategoryPermissions(category, products, features)}
                </TenantFeatureGuard>
              )}
            </TenantProductGuard>
          )}
        />
      ))}
    </div>
  );
};
