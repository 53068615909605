import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import { HomePage } from './pages/HomePage/HomePage';
import store from './store';
import './App.scss';
import AuthProvider from './providers/AuthProvider';
import { ConnectedRouter } from 'connected-react-router';
import { browserHistory } from './history.module';
import Loading from './components/Loading/LoadingSpinner';
import InitialLoginHandler from './pages/InitialLogin/InitialLoginHandler';
import { TenantPermissionProvider } from './providers/UserPermissions/TenantPermissionProvider';
import { EnvironmentPermissionProvider } from './providers/UserPermissions/EnvironmentPermissionProvider';
import { registerLocale } from './services/numeral.service';
import { Root } from './pages/Root';
import registerSvgIcons from './components/common/svgIconsRegisterer/svgIconsRegisterer';
import { ErrorBoundary } from './components/ErrorBoundary';
import { MbcNotFoundError } from './components/MbcError';
import { Docs } from './docs/Docs';
import AuthGuard from './guards/AuthGuard';
import { initConsent } from './services/consent.service';
import { initAnalytics } from './services/logger.service';
import { AppRoute } from './router/router.service';

// Register locale file used by numeral library such that: thousands -> K, Million -> M ...
registerLocale();

registerSvgIcons();

initConsent();

initAnalytics();

const App: React.FC = () => {
  return (
    <ErrorBoundary component="App">
      <Provider store={store}>
        <Suspense fallback={null}>
          <TenantPermissionProvider>
            <EnvironmentPermissionProvider>
              <AuthProvider>
                <Suspense fallback={<Loading fullPage />}>
                  <ConnectedRouter history={browserHistory}>
                    <Switch>
                      <Route path={AppRoute.get()} exact component={HomePage}></Route>
                      <Route path={AppRoute.append('login')} exact component={InitialLoginHandler}></Route>
                      <AuthGuard>
                        <Route path={AppRoute.append('tenants')} component={Root}></Route>
                        <Route path={AppRoute.append('docs/:docPage/:docSubPage?')} component={Docs}></Route>
                      </AuthGuard>
                      <Route component={MbcNotFoundError} />
                    </Switch>
                  </ConnectedRouter>
                </Suspense>
              </AuthProvider>
            </EnvironmentPermissionProvider>
          </TenantPermissionProvider>
        </Suspense>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
