import React, { useState, useEffect, useCallback } from 'react';
import { AppState } from '../../../../store/reducers';
import {
  ComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
  IComboBox,
  classNamesFunction,
  Stack,
  DatePicker,
  PrimaryButton,
  IconButton,
} from 'office-ui-fabric-react';
import {
  SelectedFilters,
  AnalyticsFiltersStyleProps,
  AnalyticsFiltersStyles,
  AnalyticsFiltersProps,
} from './AnalyticsFilters.types';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { SearchResultsFilter } from '../../../../restful-apis/dto/analytics/analytics';
import {
  UpdateAllPerformanceCharts,
  setSelectedSearchInstancesFilter,
  setSelectedResponseCodesFilter,
  setSelectedRegionsFilter,
  setSelectedSearchResultsFilter,
  setSelectedStartDateFilter,
  setSelectedEndDateFilter,
  UpdateAllTrafficCharts,
} from '../../../../store/actions/analyticsActions';
import moment from 'moment';
import { addDays } from '../../../../utils';
import { useAnalyticsFilters } from '../../../../store/hooks/use-analytics-filter/useAnalyticsFilters';
import { getAggregationInterval } from '../SearchAnalytics.utils';
import { DateFormat } from '../../../../store/types/analytics.d';
import { AnalyticsTabs } from '../SearchAnalytics.types';

const getClassNames = classNamesFunction<AnalyticsFiltersStyleProps, AnalyticsFiltersStyles>();

export const AnalyticsFiltersBase = (props: AnalyticsFiltersProps) => {
  const { t, theme, className, styles, activeTab } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  let basicComboBox = React.createRef<IComboBox>();

  const dispatch = useDispatch();

  useAnalyticsFilters(activeTab);

  const regionsDistributionFilter = useSelector((state: AppState) => state.analytics.regionsDistributionFilter);
  const isRegionsDistributionFilterLoading = useSelector(
    (state: AppState) => state.analytics.isRegionsDistributionFilterLoading,
  );
  const responseCodesFilter = useSelector((state: AppState) => state.analytics.responseCodesFilter);
  const isResponseCodesFilterLoading = useSelector((state: AppState) => state.analytics.isResponseCodesFilterLoading);
  const searchInstancesFilter = useSelector((state: AppState) => state.analytics.searchInstancesFilter);
  const isSearchInstancesFilterLoading = useSelector(
    (state: AppState) => state.analytics.isSearchInstancesFilterLoading,
  );
  const searchResultsFilter = useSelector(state => state.analytics.searchResultsFilter);
  const searchLatencyPercentile = useSelector(state => state.analytics.searchLatencyPercentile);
  const latencyCardPercentile = useSelector(state => state.analytics.latencyCardPercentile);

  const [startDate, setStartDate] = useState(addDays(-1, new Date(moment.utc().format(DateFormat))));
  const [endDate, setEndDate] = useState(new Date(moment.utc().format(DateFormat)));

  const [timeUpdated, setTimeUpdated] = useState(false);

  const [disableDateSubmit, setDisbaleDateSubmit] = useState(false);

  const [timeComboBoxPlaceHolder, setTimeComboBoxPlaceHolder] = useState('Past 24 hours');

  useEffect(() => {
    if (timeUpdated) {
      let aggInterval = getAggregationInterval(startDate, endDate);
      if (activeTab === AnalyticsTabs.Traffic) {
        dispatch(
          UpdateAllTrafficCharts(
            responseCodesFilter.selectedValues.checkedValues,
            startDate,
            endDate,
            [], // Markets
            regionsDistributionFilter.selectedValues.checkedValues,
            searchResultsFilter.selectedValues.checkedValues,
            searchInstancesFilter.selectedValues.checkedValues,
            aggInterval,
          ),
        );
      } else {
        dispatch(
          UpdateAllPerformanceCharts(
            responseCodesFilter.selectedValues.checkedValues,
            startDate,
            endDate,
            [], // Markets
            regionsDistributionFilter.selectedValues.checkedValues,
            searchResultsFilter.selectedValues.checkedValues,
            searchInstancesFilter.selectedValues.checkedValues,
            aggInterval,
            searchLatencyPercentile,
            latencyCardPercentile,
          ),
        );
      }
      setTimeUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeUpdated]);

  const getAvailableComboBoxOptions = (
    headerText: string,
    availableFilters: string[],
    isLoading: boolean,
  ): IComboBoxOption[] => {
    let statusOptions = [
      { key: 'Header', text: headerText, itemType: SelectableOptionMenuItemType.Header },
      { key: 'All', text: t('analytics.filters.select-all-text') },
    ];

    if (isLoading && !availableFilters.length) {
      return statusOptions;
    } else {
      let retrievedRegions = availableFilters.map(
        (region): IComboBoxOption => {
          return { key: region, text: region };
        },
      );
      return [...statusOptions, ...retrievedRegions];
    }
  };

  const getTimeFrameOptions = (): IComboBoxOption[] => {
    return [
      { key: 'Header1', text: 'Time range', itemType: SelectableOptionMenuItemType.Header },
      { key: 'pastDay', text: t('analytics.filters.time-frame.past-day') },
      { key: 'pastWeek', text: t('analytics.filters.time-frame.past-week') },
      { key: 'pastMonth', text: t('analytics.filters.time-frame.past-month') },
      { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
      {
        key: 'Header2',
        text: t('analytics.filters.time-frame.custom-range'),
        itemType: SelectableOptionMenuItemType.Header,
      },
    ];
  };

  const getComboBoxText = (selectedFiltersObject: SelectedFilters): string => {
    return selectedFiltersObject.selectAll
      ? t('analytics.filters.select-all-text')
      : selectedFiltersObject.checkedValues.join();
  };

  const getComboBoxSelectedKeys = (selectedFiltersObject: SelectedFilters, availableFilters: string[]): string[] => {
    return selectedFiltersObject.selectAll
      ? [...availableFilters, t('analytics.filters.select-all-text')!]
      : selectedFiltersObject.checkedValues;
  };

  const onChangeSelectedFilters = (
    event: React.FormEvent<IComboBox>,
    previouslySelectedFilters: SelectedFilters,
    availableFilters: string[],
    option: IComboBoxOption,
  ): SelectedFilters => {
    let updatedSelectedFilters: SelectedFilters = previouslySelectedFilters;
    if (option.key === 'All') {
      updatedSelectedFilters = previouslySelectedFilters.selectAll
        ? { checkedValues: [], selectAll: false }
        : { checkedValues: availableFilters, selectAll: true };
    } else {
      // At beginning we set selectAll to true but the action isn't finished yet so values won't be added to checkedValues
      if (updatedSelectedFilters.selectAll) {
        updatedSelectedFilters = { checkedValues: availableFilters, selectAll: true };
      }
      const index = updatedSelectedFilters.checkedValues.indexOf(option.key as string);
      if (option.selected && index < 0) {
        updatedSelectedFilters.checkedValues.push(option.key as string);
      } else {
        updatedSelectedFilters = {
          ...updatedSelectedFilters,
          checkedValues: updatedSelectedFilters.checkedValues.filter(filter => filter !== option.text),
        };
      }

      if (updatedSelectedFilters.checkedValues.length === availableFilters.length) {
        updatedSelectedFilters.selectAll = true;
      } else {
        updatedSelectedFilters.selectAll = false;
      }
    }
    return { ...updatedSelectedFilters };
  };

  const onChangeStartDate = (date: Date | null | undefined) => {
    if (date) {
      if (moment(date).isBefore(endDate)) {
        setStartDate(date);
        setDisbaleDateSubmit(false);
      } else {
        setStartDate(date);
        setDisbaleDateSubmit(true);
      }
    }
  };

  const onChangeEndDate = (date: Date | null | undefined) => {
    let endDate = new Date(moment.min(moment(date).endOf('day'), moment.utc()).format(DateFormat));
    if (date) {
      if (moment(endDate).isAfter(startDate)) {
        setEndDate(endDate);
        setDisbaleDateSubmit(false);
      } else {
        setEndDate(endDate);
        setDisbaleDateSubmit(true);
      }
    }
  };

  const getSelectedFiltersTags = useCallback((): JSX.Element[] => {
    return [
      ...searchInstancesFilter.selectedValues.checkedValues.map((searchInstance: string) => (
        <div className={classNames.selectedFiltersTag} key={searchInstance}>
          <div className={classNames.filterName}>{searchInstance}</div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => {
              if (searchInstancesFilter.selectedValues.checkedValues.length === 1) {
                dispatch(setSelectedSearchInstancesFilter([], true));
              } else {
                let updatedSearchInstances = searchInstancesFilter.selectedValues.checkedValues.filter(
                  filter => filter !== searchInstance,
                );
                dispatch(setSelectedSearchInstancesFilter(updatedSearchInstances, false));
              }
            }}
          />
        </div>
      )),
      ...searchResultsFilter.selectedValues.checkedValues.map((searchResultValue: string) => (
        <div className={classNames.selectedFiltersTag} key={searchResultValue}>
          <div className={classNames.filterName}>{searchResultValue}</div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => {
              if (searchResultsFilter.selectedValues.checkedValues.length === 1) {
                dispatch(setSelectedSearchResultsFilter([], true));
              } else {
                dispatch(
                  setSelectedSearchResultsFilter(
                    searchResultsFilter.selectedValues.checkedValues.filter(filter => filter !== searchResultValue),
                    false,
                  ),
                );
              }
            }}
          />
        </div>
      )),
      ...regionsDistributionFilter.selectedValues.checkedValues.map((region: string) => (
        <div className={classNames.selectedFiltersTag} key={region}>
          <div className={classNames.filterName}>{region}</div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => {
              if (regionsDistributionFilter.selectedValues.checkedValues.length === 1) {
                dispatch(setSelectedRegionsFilter([], true));
              } else {
                dispatch(
                  setSelectedRegionsFilter(
                    regionsDistributionFilter.selectedValues.checkedValues.filter(filter => filter !== region),
                    false,
                  ),
                );
              }
            }}
          />
        </div>
      )),
      ...responseCodesFilter.selectedValues.checkedValues.map((responseCode: string) => (
        <div className={classNames.selectedFiltersTag} key={responseCode}>
          <div className={classNames.filterName}>{responseCode}</div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => {
              if (responseCodesFilter.selectedValues.checkedValues.length === 1) {
                dispatch(setSelectedResponseCodesFilter([], true));
              } else {
                dispatch(
                  setSelectedResponseCodesFilter(
                    responseCodesFilter.selectedValues.checkedValues.filter(filter => filter !== responseCode),
                    false,
                  ),
                );
              }
            }}
          />
        </div>
      )),
    ];
  }, [
    classNames.filterName,
    classNames.selectedFiltersTag,
    dispatch,
    regionsDistributionFilter.selectedValues.checkedValues,
    responseCodesFilter.selectedValues.checkedValues,
    searchInstancesFilter.selectedValues.checkedValues,
    searchResultsFilter.selectedValues.checkedValues,
  ]);

  const getAnalyticsDatePickers = () => {
    return (
      <Stack padding={10} verticalAlign={'end'} gap={10}>
        <DatePicker
          key="start"
          value={startDate}
          placeholder={t('analytics.filters.time-frame.select-start-date')}
          onSelectDate={onChangeStartDate}
          minDate={addDays(-89, new Date(moment.utc().format(DateFormat)))}
          maxDate={new Date(moment.utc().format(DateFormat))}
        />

        <DatePicker
          key="end"
          value={endDate}
          placeholder={t('analytics.filters.time-frame.select-end-date')}
          onSelectDate={onChangeEndDate}
          minDate={addDays(-89, new Date(moment.utc().format(DateFormat)))}
          maxDate={new Date(moment.utc().format(DateFormat))}
        />
        {disableDateSubmit && (
          <div className={classNames.dateErrorMessage}>{t('analytics.filters.time-frame.error-message')}</div>
        )}
        <PrimaryButton
          onClick={() => {
            setTimeComboBoxPlaceHolder(t('analytics.filters.time-frame.custom-range'));
            if (basicComboBox.current) {
              basicComboBox.current.dismissMenu();
            }
            dispatch(setSelectedStartDateFilter(startDate));
            dispatch(setSelectedEndDateFilter(endDate));
            setTimeUpdated(true);
          }}
          disabled={disableDateSubmit}
        >
          {t('analytics.filters.time-frame.custom-range-apply')}
        </PrimaryButton>
      </Stack>
    );
  };

  const onChangeTimeFrame = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option) {
      if (option.key === 'pastMonth') {
        let endDateInternal = new Date(moment.utc().format(DateFormat));
        let startDateInternal = addDays(-28, new Date(moment.utc().format(DateFormat)));
        setEndDate(endDateInternal);
        setStartDate(startDateInternal);
        dispatch(setSelectedStartDateFilter(startDateInternal));
        dispatch(setSelectedEndDateFilter(endDateInternal));
        setTimeComboBoxPlaceHolder(t('analytics.filters.time-frame.past-month'));
      } else if (option.key === 'pastWeek') {
        let endDateInternal = new Date(moment.utc().format(DateFormat));
        let startDateInternal = addDays(-7, new Date(moment.utc().format(DateFormat)));
        setEndDate(endDateInternal);
        setStartDate(startDateInternal);
        dispatch(setSelectedStartDateFilter(startDateInternal));
        dispatch(setSelectedEndDateFilter(endDateInternal));
        setTimeComboBoxPlaceHolder(t('analytics.filters.time-frame.past-week'));
      } else if (option.key === 'pastDay') {
        let endDateInternal = new Date(moment.utc().format(DateFormat));
        let startDateInternal = new Date(
          moment()
            .subtract(24, 'hours')
            .utc()
            .format(DateFormat),
        );
        setEndDate(endDateInternal);
        setStartDate(startDateInternal);
        dispatch(setSelectedStartDateFilter(startDateInternal));
        dispatch(setSelectedEndDateFilter(endDateInternal));
        setTimeComboBoxPlaceHolder(t('analytics.filters.time-frame.past-day'));
      }

      setTimeUpdated(true);
    }
  };

  return (
    <div className={classNames.root}>
      <div className={classNames.filtersContainer}>
        <ComboBox
          key="searchInstances"
          label={t('analytics.filters.search-instances-label')}
          multiSelect
          text={getComboBoxText(searchInstancesFilter.selectedValues)}
          selectedKey={getComboBoxSelectedKeys(
            searchInstancesFilter.selectedValues,
            searchInstancesFilter.availableValues,
          )}
          options={getAvailableComboBoxOptions(
            t('analytics.filters.search-instances-label'),
            searchInstancesFilter.availableValues,
            isSearchInstancesFilterLoading,
          )}
          onChange={(event, option) => {
            if (option) {
              let res = onChangeSelectedFilters(
                event,
                searchInstancesFilter.selectedValues,
                searchInstancesFilter.availableValues,
                option,
              );
              dispatch(setSelectedSearchInstancesFilter(res.checkedValues, res.selectAll));
            }
          }}
          className={classNames.filtersCombobox}
          useComboBoxAsMenuWidth={true}
        />
        <ComboBox
          key="searchResults"
          label={t('analytics.filters.search-results-label')}
          multiSelect
          text={getComboBoxText(searchResultsFilter.selectedValues)}
          selectedKey={getComboBoxSelectedKeys(searchResultsFilter.selectedValues, [
            SearchResultsFilter.HasResults,
            SearchResultsFilter.NotResults,
          ])}
          options={getAvailableComboBoxOptions(
            t('analytics.filters.search-results-label'),
            searchResultsFilter.availableValues,
            false,
          )}
          onChange={(event, option) => {
            if (option) {
              let res = onChangeSelectedFilters(
                event,
                searchResultsFilter.selectedValues,
                [SearchResultsFilter.HasResults, SearchResultsFilter.NotResults],
                option,
              );
              dispatch(setSelectedSearchResultsFilter(res.checkedValues, res.selectAll));
            }
          }}
          className={classNames.middleFilters}
          useComboBoxAsMenuWidth={true}
        />
        <ComboBox
          key="regions"
          label={t('analytics.filters.regions-label')}
          multiSelect
          text={getComboBoxText(regionsDistributionFilter.selectedValues)}
          selectedKey={getComboBoxSelectedKeys(
            regionsDistributionFilter.selectedValues,
            regionsDistributionFilter.availableValues,
          )}
          options={getAvailableComboBoxOptions(
            t('analytics.filters.regions-label'),
            regionsDistributionFilter.availableValues,
            isRegionsDistributionFilterLoading,
          )}
          onChange={(event, option) => {
            if (option) {
              let res = onChangeSelectedFilters(
                event,
                regionsDistributionFilter.selectedValues,
                regionsDistributionFilter.availableValues,
                option,
              );
              dispatch(setSelectedRegionsFilter(res.checkedValues, res.selectAll));
            }
          }}
          className={classNames.middleFilters}
          useComboBoxAsMenuWidth={true}
        />
        <ComboBox
          key="responseCode"
          label={t('analytics.filters.response-codes-label')}
          multiSelect
          text={getComboBoxText(responseCodesFilter.selectedValues)}
          selectedKey={getComboBoxSelectedKeys(responseCodesFilter.selectedValues, responseCodesFilter.availableValues)}
          options={getAvailableComboBoxOptions(
            t('analytics.filters.response-codes-label'),
            responseCodesFilter.availableValues,
            isResponseCodesFilterLoading,
          )}
          onChange={(event, option) => {
            if (option) {
              let res = onChangeSelectedFilters(
                event,
                responseCodesFilter.selectedValues,
                responseCodesFilter.availableValues,
                option,
              );
              dispatch(setSelectedResponseCodesFilter(res.checkedValues, res.selectAll));
            }
          }}
          className={classNames.middleFilters}
          useComboBoxAsMenuWidth={true}
        />
        <ComboBox
          key="timeframe"
          label={t('analytics.filters.time-frame.label')}
          placeholder={timeComboBoxPlaceHolder}
          componentRef={basicComboBox}
          options={getTimeFrameOptions()}
          className={classNames.middleFilters}
          onChange={(ev, option) => onChangeTimeFrame(ev, option)}
          onRenderLowerContent={() => getAnalyticsDatePickers()}
          useComboBoxAsMenuWidth={true}
        />
      </div>
      {(responseCodesFilter.selectedValues.checkedValues.length !== 0 ||
        regionsDistributionFilter.selectedValues.checkedValues.length !== 0 ||
        searchResultsFilter.selectedValues.checkedValues.length !== 0 ||
        searchInstancesFilter.selectedValues.checkedValues.length !== 0) && (
        <div key="SelectedFilters" className={classNames.selectedFiltersTagsContainer}>
          <div className={classNames.selectedfilterlabel}>{t('analytics.filters.selected-filters-label')}</div>
          {getSelectedFiltersTags()}
        </div>
      )}
    </div>
  );
};
