import { Action } from 'redux';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const MARK_NOTIFICATION_AS_SEEN = 'MARK_NOTIFICATION_AS_SEEN';
export const MARK_ALL_NOTIFICATIONS_AS_SEEN = 'MARK_ALL_NOTIFICATIONS_AS_SEEN';

export interface Notification {
  id: string;
  title: string;
  content: string;
  seen: boolean;
  createdTime: Date;
}

export interface NotificationsState {
  notifications: Notification[];
}

interface SetNotifications extends Action<typeof SET_NOTIFICATIONS> {
  notifications: Notification[];
}

interface MarkNotificationAsSeen extends Action<typeof MARK_NOTIFICATION_AS_SEEN> {
  notificationId: string;
}

export type NotificationsStateActionTypes =
  | SetNotifications
  | MarkNotificationAsSeen
  | Action<typeof MARK_ALL_NOTIFICATIONS_AS_SEEN>
  | Action<typeof GET_NOTIFICATIONS>
  | Action<typeof CLEAR_NOTIFICATIONS>;
