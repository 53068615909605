import React, { useCallback, useMemo } from 'react';
import {
  classNamesFunction,
  Stack,
  StackItem,
  Dropdown,
  IDropdownOption,
  DropdownMenuItemType,
  IconButton,
  IComboBox,
  IComboBoxOption,
  TextField,
  Button,
} from 'office-ui-fabric-react';
import { ConditionEditorProps, ConditionEditorStyleProps, ConditionEditorStyles } from './ConditionEditor.types';
import { HierarchalIndexField } from '../../../../store/types/searchIndex';
import { useFilterableFields } from '../../../../store/hooks/use-index-schema/useFilterableFields';
import { SearchIndexTypeConfig } from '../../../EnvironmentList/Environment/SchemaManagement/SchemaManagement.config';
import {
  ConditionType,
  createBoolCondition,
  Condition,
  BoolCondition,
  createStringSetCondition,
  createNumericCondition,
  createDateTimeCondition,
  DateTimeCondition,
  NumericCondition,
  StringSetCondition,
} from '../../../../utils/customizations/conditions';
import {
  EquivalenceOperator,
  SetOperator,
  ComparisonOperator,
  CategoryOperator,
} from '../../../../utils/customizations/operators';
import { SpecialConditionFields } from './utils';
import { MbcCompoBox } from '../../../../components/common/MbcCompoBox';
import _ from 'lodash';
import { ConditionValueWrapper } from './ConditionValueWrapper';
import { Moment } from 'moment';

const getClassNames = classNamesFunction<ConditionEditorStyleProps, ConditionEditorStyles>();

export const ConditionEditorBase = (props: ConditionEditorProps) => {
  const {
    styles,
    theme,
    className,
    initialCondition,
    showSpecialFields,
    excludedFieldTypes,
    onConditionUpdate,
    onConditionDelete,
  } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const filterableFields: HierarchalIndexField[] = useFilterableFields(excludedFieldTypes);
  const currentCondition: ConditionType | undefined = useMemo(() => _.cloneDeep(initialCondition), [initialCondition]);

  const getUpdatedConditionByOperator = useCallback(
    (operator: string): ConditionType | undefined => {
      let updatedCondition: ConditionType | undefined = undefined;
      if (currentCondition) {
        if (currentCondition.field in SpecialConditionFields) {
          updatedCondition = {
            ...(currentCondition as StringSetCondition),
            operator: operator in SetOperator ? (operator as SetOperator) : 'in',
          };
        } else {
          const selectedIndexField = filterableFields.filter(
            filterableField => filterableField.hierarchalFieldName === currentCondition.field,
          )[0];
          if (selectedIndexField) {
            const fieldType = selectedIndexField.type;
            const mappedDataType = !!SearchIndexTypeConfig[fieldType] && SearchIndexTypeConfig[fieldType].dataType;

            switch (mappedDataType) {
              case 'String':
              case 'Array':
                updatedCondition = {
                  ...(currentCondition as StringSetCondition),
                  operator: operator in SetOperator ? (operator as SetOperator) : 'in',
                };
                break;
              case 'Numeric':
              case 'Double':
                updatedCondition = {
                  ...(currentCondition as NumericCondition),
                  operator: operator in ComparisonOperator ? (operator as ComparisonOperator) : 'eq',
                };
                break;

              case 'DateTime':
                updatedCondition = {
                  ...(currentCondition as DateTimeCondition),
                  operator: operator in ComparisonOperator ? (operator as ComparisonOperator) : 'eq',
                };
                break;

              case 'DateSegment':
                updatedCondition = {
                  ...(currentCondition as DateTimeCondition),
                  operator: operator in CategoryOperator ? (operator as CategoryOperator) : 'in',
                };
                break;

              case 'Boolean':
                updatedCondition = {
                  ...(currentCondition as BoolCondition),
                  operator: operator as EquivalenceOperator,
                };
                break;

              default:
                updatedCondition = currentCondition;
                break;
            }
          }
        }
        return updatedCondition;
      } else {
        return undefined;
      }
    },
    [currentCondition, filterableFields],
  );

  const getDefaultConditionByField = useCallback(
    (field: string): ConditionType | undefined => {
      let defaultCondition: ConditionType | undefined = undefined;
      if (field in SpecialConditionFields) {
        defaultCondition = createStringSetCondition({ field: field });
      } else {
        const selectedIndexField = filterableFields.filter(
          filterableField => filterableField.hierarchalFieldName === field,
        )[0];
        if (selectedIndexField) {
          const fieldType = selectedIndexField.type;
          const mappedDataType = !!SearchIndexTypeConfig[fieldType] && SearchIndexTypeConfig[fieldType].dataType;

          switch (mappedDataType) {
            case 'String':
            case 'Array':
              defaultCondition = createStringSetCondition({ field: field });
              break;
            case 'Numeric':
            case 'Double':
              defaultCondition = createNumericCondition({ field: field });
              break;
            case 'Boolean':
              defaultCondition = createBoolCondition({ field: field });
              break;
            case 'DateTime':
              defaultCondition = createDateTimeCondition({ field: field, fieldType: 'DateTime' });
              break;
            case 'DateSegment':
              defaultCondition = createDateTimeCondition({ field: field, fieldType: 'DateSegment' });
              break;
          }
        }
      }
      return defaultCondition;
    },
    [filterableFields],
  );

  const fieldOptions = useMemo(() => {
    let fieldOptions: IDropdownOption[] = [];
    if (showSpecialFields) {
      fieldOptions = fieldOptions.concat(
        Object.keys(SpecialConditionFields).map(field => {
          return { key: field, text: field };
        }),
      );
    }
    fieldOptions.push({ key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider });
    fieldOptions = fieldOptions.concat(
      filterableFields.map(field => {
        return { key: field.hierarchalFieldName, text: field.hierarchalFieldName };
      }),
    );
    return fieldOptions;
  }, [filterableFields, showSpecialFields]);

  const operatorOptions = useMemo(() => {
    let options: IDropdownOption[] = [];
    if (currentCondition) {
      switch (currentCondition._type) {
        case Condition.BoolCondition:
          for (const [key, value] of Object.entries(EquivalenceOperator)) {
            options.push({ key: key, text: value });
          }
          break;

        // StringSetCondition has a special handling for usability concerns
        case Condition.StringSetCondition:
          options.push({ key: 'in', text: '=' });
          options.push({ key: 'notIn', text: '!=' });
          break;

        case Condition.NumericCondition:
          for (const [key, value] of Object.entries(ComparisonOperator)) {
            options.push({ key: key, text: value });
          }
          break;

        case Condition.DateTimeCondition:
          {
            const selectedIndexFieldType = filterableFields.filter(
              filterableField => filterableField.hierarchalFieldName === currentCondition.field,
            )[0].type;

            if (selectedIndexFieldType === 'DateSegment') {
              options.push({ key: 'in', text: 'includes' });
              break;
            }
            for (const [key, value] of Object.entries(ComparisonOperator)) {
              options.push({ key: key, text: value });
            }
          }
          break;
      }
    }
    return options;
  }, [currentCondition, filterableFields]);

  const getValueEditor = useCallback((): JSX.Element | null => {
    if (currentCondition) {
      switch (currentCondition._type) {
        case Condition.BoolCondition:
          const boolCondition = currentCondition as BoolCondition;
          return (
            <ConditionValueWrapper
              valueType={'boolean'}
              fieldName={boolCondition.field}
              value={boolCondition.value}
              operator={boolCondition.operator}
              onValueChange={value => {
                const updatedCondition = { ...boolCondition, value: value as boolean };
                onConditionUpdate(updatedCondition);
              }}
            />
          );
        case Condition.StringSetCondition:
          const stringSetCondition = currentCondition as StringSetCondition;
          return (
            <ConditionValueWrapper
              valueType={'string'}
              fieldName={stringSetCondition.field}
              value={stringSetCondition.values || []}
              operator={stringSetCondition.operator}
              onValueChange={values => {
                const updatedCondition = { ...stringSetCondition, values: values as string[] };
                onConditionUpdate(updatedCondition);
              }}
            />
          );

        case Condition.NumericCondition:
          const numericCondition = currentCondition as NumericCondition;
          return (
            <ConditionValueWrapper
              valueType={'number'}
              fieldName={numericCondition.field}
              value={numericCondition.value}
              operator={numericCondition.operator}
              onValueChange={value => {
                const updatedCondition = { ...numericCondition, value: value as number };
                onConditionUpdate(updatedCondition);
              }}
            />
          );

        case Condition.DateTimeCondition:
          const dateTimeCondition = currentCondition as DateTimeCondition;
          return (
            <ConditionValueWrapper
              valueType={'Moment'}
              fieldName={dateTimeCondition.field}
              value={dateTimeCondition.value}
              operator={dateTimeCondition.operator}
              onValueChange={value => {
                const updatedCondition = { ...dateTimeCondition, value: value as Moment };
                onConditionUpdate(updatedCondition);
              }}
            />
          );

        default:
          return null;
      }
    } else {
      return (
        <Stack horizontal tokens={{ childrenGap: '8' }}>
          <StackItem grow>
            <TextField disabled placeholder={'Value'} />
          </StackItem>
          <Button disabled styles={{ root: { width: 95 } }} iconProps={{ iconName: 'Add' }}>
            Add
          </Button>
        </Stack>
      );
    }
  }, [currentCondition, onConditionUpdate]);

  const onSelectedFieldChange = useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
      // If value is not one of the field options
      // Can be used by BR trigger condition to support custom attributes
      // Can be used by other condition types to support wildcard fields.
      // If used for non-wildcard fields we show the error notification from backend "undefiend field used in filter condition"
      if (!!value) {
        const defaultCondition = createStringSetCondition({ field: value });
        onConditionUpdate(defaultCondition);
      }

      if (option && option.text) {
        const defaultCondition = getDefaultConditionByField(option.text);
        defaultCondition && onConditionUpdate(defaultCondition);
      }
    },
    [getDefaultConditionByField, onConditionUpdate],
  );

  const onSelectedOperatorChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && option.key) {
        const updatedCondition = getUpdatedConditionByOperator(option.key as string);
        updatedCondition && onConditionUpdate(updatedCondition);
      }
    },
    [getUpdatedConditionByOperator, onConditionUpdate],
  );

  return (
    <Stack className={classNames.root}>
      <Stack tokens={{ childrenGap: '8' }} styles={{ root: { width: '100%' } }}>
        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
          <span className={classNames.header}>{currentCondition ? currentCondition.field : 'new'} condition</span>
          {!!onConditionDelete && (
            <IconButton
              styles={{ root: { color: '#605E5C' } }}
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => onConditionDelete(currentCondition)}
            />
          )}
        </Stack>
        <Stack horizontal tokens={{ childrenGap: '8' }}>
          <StackItem grow>
            <MbcCompoBox
              compoBoxProps={{
                placeholder: 'Field',
                allowFreeform: true,
                selectedKey: currentCondition ? currentCondition.field : undefined,
                text: currentCondition ? currentCondition.field : undefined,
                autoComplete: 'on',
                options: fieldOptions,
                onChange: onSelectedFieldChange,
              }}
            />
          </StackItem>
          <StackItem>
            <Dropdown
              styles={{ root: { width: 95 } }}
              options={operatorOptions}
              placeholder={'Operator'}
              selectedKey={currentCondition ? currentCondition.operator : undefined}
              onChange={onSelectedOperatorChange}
            />
          </StackItem>
        </Stack>
        <StackItem>{getValueEditor()}</StackItem>
      </Stack>
    </Stack>
  );
};
