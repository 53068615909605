import React, { Component } from 'react';
import Truncate from 'react-truncate';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './TruncatedText.module.scss';
import { Link, TooltipHost } from 'office-ui-fabric-react';
import { generateUniqueIdentifier } from '../../../utils';

interface TruncatedTextProps {
  linesCount?: number;
  value: string;
  width?: number;
  showLess?: boolean;
  showMore?: boolean;
}

interface TruncatedTextState {
  expanded: boolean;
  truncated: boolean;
  tooltipId: string;
}

class ReadMore extends Component<TruncatedTextProps & WithTranslation, TruncatedTextState> {
  constructor(props: TruncatedTextProps & WithTranslation) {
    super(props);
    this.state = {
      expanded: false,
      truncated: false,
      tooltipId: generateUniqueIdentifier(),
    };
  }

  handleTruncate(truncated: boolean) {
    if (this.state.truncated !== truncated) {
      this.setState(
        (state: TruncatedTextState): TruncatedTextState => ({
          ...state,
          truncated: truncated,
        }),
      );
    }
  }

  toggleLines(event: any) {
    event.preventDefault();

    this.setState(
      (state: TruncatedTextState): TruncatedTextState => ({
        ...state,
        expanded: !state.expanded,
      }),
    );
  }

  getTruncatedText(tooltipId?: string) {
    const linesCount = this.props.linesCount || 1;
    const { t } = this.props;

    return (
      <div className={styles.truncatedTextContainer}>
        <Truncate
          lines={!this.state.expanded && linesCount}
          ellipsis={
            <span>
              ...
              {this.props.showMore && (
                <Link
                  className={styles.seeMore}
                  onClick={this.toggleLines.bind(this)}
                  aria-describedby={tooltipId}
                  data-is-focusable={this.state.truncated}
                >
                  {t('common.see-more')}
                </Link>
              )}
            </span>
          }
          width={this.props.width}
          onTruncate={this.handleTruncate.bind(this)}
        >
          {this.props.value}
        </Truncate>
        {this.props.showLess && !this.state.truncated && this.state.expanded && (
          <span>
            <Link className={styles.seeLess} onClick={this.toggleLines.bind(this)}>
              {t('common.see-less')}
            </Link>
          </span>
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.truncated && !this.state.expanded ? (
          <TooltipHost
            content={this.props.value}
            id={this.state.tooltipId}
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
          >
            {this.getTruncatedText(this.state.tooltipId)}
          </TooltipHost>
        ) : (
          this.getTruncatedText()
        )}
      </>
    );
  }
}

export default withTranslation()(ReadMore);
