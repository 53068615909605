import { TrainSearchModelStyles, TrainSearchModelStyleProps } from './TrainSearchModel.types';

export const styles = (props: TrainSearchModelStyleProps): TrainSearchModelStyles => ({
  status: [
    'mbc-chart-status',
    {
      borderRadius: '50%',
      height: 8,
      width: 8,
    },
  ],
  legendIcon: [
    'mbc-legend-icon',
    {
      height: 13,
      width: 13,
    },
  ],
  legendLabel: [
    'mbc-legend-label',
    {
      fontSize: 12,
      lineHeight: 14,
    },
  ],
  subComponentStyles: {
    parametersCard: {
      subComponentStyles: {
        card: {
          root: [
            'mbc-param-card',
            {
              gridColumn: '1 / span 1',
              gridRow: '1 / span 2',
            },
            !props.isDefinedModel && {
              selectors: {
                '.mbc-content-card': {
                  flex: '0 0 auto',
                },
              },
            },
          ],
        },
      },
    },
    chartCard: {
      root: [
        'mbc-metrics-chart',
        {
          gridColumn: '2 / span 1',
          minHeight: '321px !important',
          maxHeight: 'unset !important',
          selectors: {
            '.mbc-card-title': {
              padding: 0,
              fontSize: 14,
              lineHeight: 28,
              fontWeight: 600,
              alignSelf: 'center',
            },
            '.mbc-content-card-header': {
              boxSizing: 'border-box',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              paddingTop: 12,
              paddingBottom: 12,
              paddingRight: 24,
              paddingLeft: 24,
            },
            '.mbc-content-card-body': {
              paddingTop: 12,
              paddingBottom: 12,
              paddingRight: 24,
              paddingLeft: 24,
            },
            '.mbc-card-actions .ms-Button--action': {
              height: 16,
            },
          },
        },
      ],
    },
    page: {
      cards: [
        {
          display: 'grid',
          gridRowGap: 16,
          gridColumnGap: 16,
          gridTemplateColumns: '2fr 4fr',
          marginBottom: 21,
          selectors: {
            '.mbc-content-card': {
              minHeight: 'unset !important',
              maxHeight: 'unset !important',
            },
          },
        },
      ],
    },
  },
});
