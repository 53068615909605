import React from 'react';
import { AppState } from '../../../../../store/reducers';
import { MultiLineChart } from '../../../../../components/common/charts/MultiLineChart';
import { setProvidedDocuments } from '../../../../../store/actions/insightsAction';
import { DashboardChartCard } from '../common/DashboardChartCard';
import { useSelector, useDispatch } from '../../../../../store/hooks';

import { classNamesFunction } from 'office-ui-fabric-react';
import {
  ProvidedDocumentsStyleProps,
  ProvidedDocumentsProps,
  ProvidedDocumentsStyles,
} from './ProvidedDocuments.types';
import { TickType } from '../../../../../components/common/charts/common/AxisTickFormatter';
import { useMediaQuery } from 'react-responsive';
import { TimeFilterOptions } from '../Insights.utils';
import { getChartTitleTooltipMessage } from '../Insights.utils';

const getClassNames = classNamesFunction<ProvidedDocumentsStyleProps, ProvidedDocumentsStyles>();

export const ProvidedDocumentsBase = (props: ProvidedDocumentsProps) => {
  const { t, styles, theme, className, componentRef, hasTimeSelector } = props;
  const providedDocuments = useSelector((state: AppState) => state.insights.providedDocuments);
  const isProvidedDocumentsLoading = useSelector((state: AppState) => state.insights.isProvidedDocumentsLoading);

  const dispatch = useDispatch();

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const mediumScreen = useMediaQuery({ query: '(max-width: 935px)' });
  const smallScreen = useMediaQuery({ query: '(max-width: 800px)' });

  return (
    <div className={classNames.root}>
      <DashboardChartCard
        componentRef={componentRef}
        hasTimeSelector={hasTimeSelector}
        cardTitle={t('insights.provided-documents-chart-y')}
        isLoading={isProvidedDocumentsLoading}
        refreshOptions={{
          text: getChartTitleTooltipMessage(providedDocuments.cacheDetails),
          onRefreshClick: (timespan, interval) => {
            dispatch(setProvidedDocuments(timespan, interval));
          },
        }}
        onChange={(timespan, interval) => {
          dispatch(setProvidedDocuments(timespan, interval));
        }}
        defaultTimeSelectorValue={TimeFilterOptions.ONE_DAY}
        body={
          <MultiLineChart
            points={providedDocuments.series}
            isLoading={isProvidedDocumentsLoading}
            xAxisProps={{
              interval:
                providedDocuments.series.length > 26
                  ? 23
                  : providedDocuments.series.length > 13
                  ? 2
                  : providedDocuments.series.length > 8
                  ? mediumScreen
                    ? 2
                    : 1
                  : 0,
              tickProps: {
                tickType: TickType.DateTime12Hrs,
                maximumTextLength: 13,
                blockRotation: !smallScreen,
                rotationBreakPoint: 7,
                fontSize: 12,
                rotatedFontSize: 10,
              },
            }}
            minWidth={50}
            minHeight={230}
            xLine={{
              name: t('insights.provided-documents-chart-x'),
              stroke: 'grey',
            }}
            ylines={[
              {
                name: t('insights.provided-documents-chart-y'),
                stroke: theme!.palette.themePrimary,
              },
            ]}
          />
        }
      />
    </div>
  );
};
