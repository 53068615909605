import React from 'react';
import { CustomMLCard } from '../CustomMLCard';
import { CardState } from '../CustomMLCard.types';
import { TrainModelCardProps } from './TrainModelCard.types';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';
import { ModelJobStatus } from '../../../../../store/types/customML/searchModel.d';
import { useCookingJobList } from '../../../../../store/hooks/use-list/useCookingJobList';
import { useCustomMLRouter } from '../../../../../store/hooks/use-router/useCustomMLRouter';
import { useActiveTraining } from '../../../../../store/hooks/use-active-job/useActiveTraining';

export function TrainModelCardBase(props: TrainModelCardProps) {
  const { t, cardComponentRef } = props;

  const cookingJobs = useCookingJobList();
  const trainingJob = useActiveTraining();

  const MbcCustomMLRouter = useCustomMLRouter();
  const hasCookedJobs = cookingJobs.some(job => job.status === ModelJobStatus.Finished);

  return (
    <CustomMLCard
      state={!!trainingJob.key ? CardState.Completed : hasCookedJobs ? CardState.Active : CardState.InActive}
      componentRef={cardComponentRef}
      title={t('custom-ml.job-card.model.title')}
      activeCardProps={{
        iconProps: { iconName: 'Tiles' },
        label: t('custom-ml.job-card.model.action'),
        onClick: MbcCustomMLRouter(MbcRouteKey.TrainingJob).browserPush,
      }}
      completedCardProps={{
        onClick: MbcCustomMLRouter(MbcRouteKey.TrainingJob).browserPush,
        label: t('custom-ml.job-card.model.action'),
        contentProps: {
          startTime: {
            label: t('custom-ml.job-card.model.start-time'),
            value: trainingJob.createdAt,
          },
          endTime:
            trainingJob.status > ModelJobStatus.Running
              ? {
                  status: trainingJob.status,
                  value: trainingJob.updatedAt,
                  label: t(`dynamic:custom-ml.job-status.${trainingJob.status}`),
                }
              : undefined,
        },
      }}
    />
  );
}
