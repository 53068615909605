import React, { useState, useMemo, useCallback } from 'react';
import {
  classNamesFunction,
  Breadcrumb,
  IBreadcrumbItem,
  DetailsList,
  SelectionMode,
  ConstrainMode,
  DetailsListLayoutMode,
  CheckboxVisibility,
  IColumn,
  IDetailsRowProps,
  Stack,
  IconButton,
  TooltipHost,
} from 'office-ui-fabric-react';
import {
  ProductViewerProps,
  ProductViewerStyles,
  ProductItemListProps,
  ProductViewerStyleProps,
} from './ProductViewer.types';
import { getProductItemList } from '../ProductPreview.utils';
import { SearchIndexTypeConfig } from '../../../SchemaManagement/SchemaManagement.config';
import { DateSegment, PrimitiveType, ProductItem, ROOT_PRODUCT_KEY } from '../ProductPreview.types';
import moment from 'moment';
import { getLocalTimeZone } from '../../../../../../components/common/DateTimePicker/DateTimePicker.utils';
import { ErrorBoundary } from '../../../../../../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const getClassNames = classNamesFunction<ProductViewerStyleProps, ProductViewerStyles>();

const ProductItemList = (props: ProductItemListProps) => {
  const { styles, theme, productItemList, onNavigateToProductItem } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const { t } = useTranslation();

  const onRenderDefaultRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.detailsRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <div className={classNames.value}>{props.item.value}</div>
        </div>
      ) : null,
    [classNames.detailsRow, classNames.label, classNames.value],
  );

  const onRenderDateTimeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.detailsRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <div className={classNames.value}>{`${moment(props.item.value)
            .local()
            .format('YYYY-MM-DD HH:mm')} ${getLocalTimeZone('short')}`}</div>
        </div>
      ) : null,
    [classNames.detailsRow, classNames.label, classNames.value],
  );

  const onRenderObjectTypeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.detailsRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <IconButton
            className={classNames.chevronIcon}
            iconProps={{ iconName: 'ChevronRight' }}
            onClick={() => onNavigateToProductItem(props.item)}
          />
        </div>
      ) : null,
    [classNames.chevronIcon, classNames.detailsRow, classNames.label, onNavigateToProductItem],
  );

  const onRenderArrayTypeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.detailsRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <Stack horizontalAlign={'start'} tokens={{ childrenGap: 8 }}>
            {(props.item.value as PrimitiveType[]).map((value, index) => (
              <div key={index} className={classNames.value}>
                {value}
              </div>
            ))}
          </Stack>
        </div>
      ) : null,
    [classNames.detailsRow, classNames.label, classNames.value],
  );

  const onRenderDateSegmentTypeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.detailsRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <Stack
            horizontalAlign={'start'}
            tokens={{ childrenGap: 8 }}
            styles={{ root: { gridColumnStart: '2', gridColumnEnd: '4' } }}
          >
            {(props.item.value as DateSegment[]).map((value, index) => (
              <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', width: '100%' }} key={index}>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <TooltipHost
                    content={t('schema-management.index-field-editor.date-segment-greater')}
                    hostClassName={classNames.label}
                  >
                    {t('schema-management.index-field-editor.date-segment-greater-abb')}
                  </TooltipHost>
                  <div className={classNames.value}>{`${moment(value.ge).format('YYYY-MM-DD')}`}</div>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <TooltipHost
                    content={t('schema-management.index-field-editor.date-segment-less-than')}
                    hostClassName={classNames.label}
                  >
                    {t('schema-management.index-field-editor.date-segment-less-than-abb')}
                  </TooltipHost>
                  <div className={classNames.value}>{`${moment(value.lt).format('YYYY-MM-DD')}`}</div>
                </Stack>
              </div>
            ))}
          </Stack>
        </div>
      ) : null,
    [classNames.detailsRow, classNames.label, classNames.value, t],
  );

  const productColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'name',
        minWidth: 200,
      },
      {
        key: 'value',
        name: 'value',
        minWidth: 300,
      },
    ],
    [],
  );

  const ProductDetailsRow = useCallback(
    (props: IDetailsRowProps) => {
      if (!!SearchIndexTypeConfig[props.item.type] && SearchIndexTypeConfig[props.item.type].dataType === 'DateTime') {
        return onRenderDateTimeRow(props);
      }

      if (!!SearchIndexTypeConfig[props.item.type] && SearchIndexTypeConfig[props.item.type].dataType === 'Object') {
        return onRenderObjectTypeRow(props);
      }

      if (!!SearchIndexTypeConfig[props.item.type] && SearchIndexTypeConfig[props.item.type].dataType === 'Array') {
        return onRenderArrayTypeRow(props);
      }

      if (
        !!SearchIndexTypeConfig[props.item.type] &&
        SearchIndexTypeConfig[props.item.type].dataType === 'DateSegment'
      ) {
        return onRenderDateSegmentTypeRow(props);
      }

      return onRenderDefaultRow(props);
    },
    [onRenderArrayTypeRow, onRenderDateSegmentTypeRow, onRenderDateTimeRow, onRenderDefaultRow, onRenderObjectTypeRow],
  );

  const onRenderRowErrorHandler = useCallback(
    (props: IDetailsRowProps) => (
      <div className={classNames.detailsRow}>
        <div className={classNames.label}>{props.item.name}</div>
        <div className={classNames.value}>{t('schema-management.index-field-editor.field-type-error')}</div>
      </div>
    ),
    [classNames.detailsRow, classNames.label, classNames.value, t],
  );

  const onRenderRowSafely = useCallback(
    (props?: IDetailsRowProps) => {
      if (!props) {
        return null;
      }
      return (
        <ErrorBoundary key={props.item.key} component="Row View" onRenderError={onRenderRowErrorHandler(props)}>
          <ProductDetailsRow key={props.item.key} {...props} />
        </ErrorBoundary>
      );
    },
    [onRenderRowErrorHandler],
  );

  return (
    <ErrorBoundary component="Product View" onRenderError={<div>Failed to view product details</div>}>
      <DetailsList
        key={'ProductItemList'}
        isHeaderVisible={false}
        columns={productColumns}
        onRenderRow={onRenderRowSafely}
        onShouldVirtualize={() => false}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        checkboxVisibility={CheckboxVisibility.hidden}
        constrainMode={ConstrainMode.horizontalConstrained}
        styles={classNames.subComponentStyles.detailsList}
        items={productItemList.filter(item => !item.isWildcard)}
      />
    </ErrorBoundary>
  );
};

export const ProductViewerBase = (props: ProductViewerProps) => {
  const { styles, theme, productItemList } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const [activeProductItemKey, setActiveProductItemKey] = useState<string>(ROOT_PRODUCT_KEY);

  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>(() => [
    {
      key: ROOT_PRODUCT_KEY,
      text: 'Product',
      onClick: () => {
        setBreadcrumbItems(items => items.slice(0, 1));
        setActiveProductItemKey(ROOT_PRODUCT_KEY);
      },
    },
  ]);

  const onBreadcrumbItemClick = useCallback((productItemKey: string) => {
    setBreadcrumbItems(items => {
      const index = items.findIndex(item => item.key === productItemKey);
      return items.slice(0, index + 1);
    });

    setActiveProductItemKey(productItemKey);
  }, []);

  const onNavigateToProductItem = useCallback(
    (productItem: ProductItem) => {
      setActiveProductItemKey(productItem.key);
      setBreadcrumbItems(items =>
        items.concat({
          text: productItem.name,
          key: productItem.key,
          onClick: () => onBreadcrumbItemClick(productItem.key),
        }),
      );
    },
    [onBreadcrumbItemClick],
  );

  const activeProductItemList = useMemo(() => {
    if (activeProductItemKey === ROOT_PRODUCT_KEY) {
      return productItemList;
    }

    return getProductItemList(productItemList, activeProductItemKey) || [];
  }, [activeProductItemKey, productItemList]);

  return (
    <Stack tokens={{ padding: '12px 12px 24px 12px', childrenGap: 8 }}>
      <Breadcrumb
        maxDisplayedItems={3}
        items={breadcrumbItems}
        styles={classNames.subComponentStyles.breadcrumb}
        ariaLabel="Breadcrumb with maxDisplayedItems set to 3"
        overflowAriaLabel="More links"
      />
      <Stack>
        <ProductItemList
          {...props}
          productItemList={activeProductItemList}
          onNavigateToProductItem={onNavigateToProductItem}
        />
      </Stack>
    </Stack>
  );
};
