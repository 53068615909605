import { ResponseBase } from '../ResponseBase';

export enum JobType {
  Unknown = 'Unknown',
  LightGBMCookingJob = 'LightGBMCookingJob',
  LightGBMTrainingJob = 'LightGBMTrainingJob',
  LightGBMEvaluationJob = 'LightGBMEvaluationJob',
  LightGBMDeploymentJob = 'LightGBMDeploymentJob',
}

export enum JobDatasetRelationRole {
  Unknown = 'unknown',
  RawData = 'rawData',
  CookedData = 'cookedData',
  TrainingData = 'trainingData',
  TestData = 'testData',
  Model = 'model',
  ControlModel = 'controlModel',
  TreatmentModel = 'treatmentModel',
  NDCGMetric = 'nDCGMetric',
  AMLLog = 'aMLLog',
  RankingModel = 'rankingModel',
}

export interface Job {
  name: string;
  jobId: string;
  owner: string;
  status: string;
  jobType: string;
  configs: any;
  metrics?: any;
  inputDatasets: any;
  outputDatasets: any;
  description: string;
  outputMessage: string;
  createTimeUTC: number;
  updateTimeUTC: number;
}

export type GetTrainingJobMetircsResponse = ResponseBase & {
  jobMetircGraphs: {
    graphName: string;
    yName: string;
    xName: string;
    points: {
      xValue: number;
      yValue: number;
    }[];
  }[];
};

export type GetEvaluationJobMetricsResponse = ResponseBase & {
  outputMetrics: {
    name: string;
    controlModelId: string;
    controlModelName: string;
    controlModelNdcg: number;
    outputMetricPairs: {
      pairName: string;
      treatmentModelId: string;
      treatmentModelNdcg: number;
      ndcgDelta: number;
      pvalue: number;
    }[];
  }[];
};

export type GetJobResponse = ResponseBase & { jobInfo: Job };

export type CreateJobRequest = Pick<Job, 'jobType' | 'name' | 'configs' | 'description' | 'owner'> & {
  superJobId: string;
  inputDatasets: {
    rawDatasetId?: string;
    testDatasetId?: string;
    trainingDatasetId?: string;
    rankingModelId?: string;
    controlModelId?: string;
    treatmentModelId?: string;
    featureSelectionDatasetId?: string;
  };
};

export type CreateJobResponse = ResponseBase & Pick<Job, 'jobId'>;
