import {
  READ_ALL_NOTIFICATIONS,
  DISMISS_NOTIFICATION,
  DISMISS_ALL_NOTIFICATIONS,
  NotificationCenterState,
  NotificationsCenterActionTypes,
  PUSH_NOTIFICATION,
  UPDATE_NOTIFICATION,
  NotificationStatus,
  PUSH_SINGLE_NOTIFICATION,
  NotificationWithContext,
  SET_IS_NOTIFICATIONS_PANEL_OPEN,
} from '../types/notificationCenter.d';

const initialState: NotificationCenterState = {
  notifications: [],
  unreadNotificationsCount: 0,
  showProgressBar: false,
  isPanelOpen: false,
};

export function notificationCenterReducer(
  state = initialState,
  action: NotificationsCenterActionTypes,
): NotificationCenterState {
  switch (action.type) {
    case PUSH_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
        unreadNotificationsCount: state.unreadNotificationsCount + 1,
        showProgressBar: true,
      };
    }
    case PUSH_SINGLE_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
        unreadNotificationsCount: state.unreadNotificationsCount + 1,
        showProgressBar: action.notification.status === NotificationStatus.InProgress,
      };
    }
    case UPDATE_NOTIFICATION: {
      let prevNotification: NotificationWithContext | undefined = state.notifications.find(
        notification => notification.id === action.notification.id,
      );
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(notification => notification.id !== action.notification.id),
          {
            ...action.notification,
            read: false,
          },
        ],
        unreadNotificationsCount:
          !prevNotification || prevNotification.read
            ? state.unreadNotificationsCount + 1
            : state.unreadNotificationsCount,
        showProgressBar:
          action.notification.status === NotificationStatus.InProgress ||
          !!state.notifications.find(
            notification =>
              notification.status === NotificationStatus.InProgress && notification.id !== action.notification.id,
          ),
      };
    }
    case READ_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: state.notifications.map(notification => ({ ...notification, read: true })),
        unreadNotificationsCount: 0,
      };
    }
    case DISMISS_ALL_NOTIFICATIONS: {
      return { ...state, notifications: [], unreadNotificationsCount: 0, showProgressBar: false };
    }
    case DISMISS_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.id !== action.notification.id),
        showProgressBar: !!state.notifications.find(
          notification =>
            notification.status === NotificationStatus.InProgress && notification.id !== action.notification.id,
        ),
      };
    }
    case SET_IS_NOTIFICATIONS_PANEL_OPEN: {
      return {
        ...state,
        isPanelOpen: action.isPanelOpen,
      };
    }
    default: {
      return state;
    }
  }
}
