import { ReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../../CrudAction.types';
import {
  updateCustomMlSearchConfig,
  deleteDefaultCustomMlSearchConfig,
} from '../../../../../restful-apis/customizations/customMlSearchConfig.api';
import {
  CustomMlSearchConfig,
  DefaultRankerId,
  SET_CUSTOM_ML_SEARCH_CONFIG,
} from '../../../../types/customizations/customMlSearchConfig.d';
import { DevOpsCommitMetadata } from '../../../../types/devOps';
import { getI18n } from 'react-i18next';

export const CustomMlSearchConfigUpdateAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  customMlSearchConfig: CustomMlSearchConfig,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<CustomMlSearchConfig>,
  onFailure?: ActionFailureHandler<CustomMlSearchConfig>,
): ReduxCrudAction<CustomMlSearchConfig> => {
  const i18n = getI18n();
  return {
    request:
      customMlSearchConfig.searchModel === DefaultRankerId
        ? deleteDefaultCustomMlSearchConfig(tenantId, searchIndexId, searchInstanceId, devOpsCommitMetadata)
        : updateCustomMlSearchConfig(
            tenantId,
            searchIndexId,
            searchInstanceId,
            customMlSearchConfig,
            devOpsCommitMetadata,
          ),
    postActionMapper: updatedCustomMlSearchConfig => ({
      type: SET_CUSTOM_ML_SEARCH_CONFIG,
      customMlSearchConfig: updatedCustomMlSearchConfig,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.ml-ranker-update-progress-message'),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.ml-ranker-update-success-message'),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.ml-ranker-update-failure-message'),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, customMlSearchConfig),
  };
};
