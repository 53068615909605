import React, { useState } from 'react';
import { ImageFit, Image, mergeStyles, ImageLoadState } from 'office-ui-fabric-react';

import { SearchItemCardProps, Promotion, ProductSearchResultItem } from './SearchItemCard.types';
import { getClassNames } from './SearchItemCard.styles';
import { splitIntegerDecimal } from '../../../utils';

const isPromotedOnBrand = (item: ProductSearchResultItem) => item.promotedValue === item.description;
const isPromotedOnSale = (item: ProductSearchResultItem) => item.promotedItem === Promotion.OnSale;
const isPromotedOnPrice = (item: ProductSearchResultItem, integer: number) =>
  item.promotedItem === Promotion.Price && integer < Number(item.promotedValue);
const ALTERNATIVE_IMAGE_URL = '/assets/images/NoImage.svg';

export function SearchItemCardBase({ styles, theme, item }: SearchItemCardProps): React.ReactElement {
  const classNames = getClassNames(styles, { theme });

  const { integer, decimal } = splitIntegerDecimal(item.price || 0);

  const [imageUrl, setImageUrl] = useState(item.imgUrl || ALTERNATIVE_IMAGE_URL);

  return (
    <div className={classNames.root}>
      <Image
        src={imageUrl}
        imageFit={ImageFit.cover}
        styles={classNames.subComponentStyles.imageStyles}
        alt={item.name}
        onLoadingStateChange={state =>
          state === ImageLoadState.error && imageUrl !== ALTERNATIVE_IMAGE_URL && setImageUrl(ALTERNATIVE_IMAGE_URL)
        }
      />
      <div className={classNames.detailsContainer}>
        <div className={classNames.descriptionContainer}>
          <div className={classNames.name}>{item.name}</div>
          <div
            className={
              isPromotedOnBrand(item)
                ? mergeStyles(classNames.description, classNames.highlight)
                : classNames.description
            }
          >
            {item.description}
          </div>
        </div>
        <div className={classNames.priceText}>
          <div className={isPromotedOnPrice(item, integer) ? classNames.highlight : ''}>
            <span className={classNames.currencyText}>{item.currency}</span>
            <span className={classNames.integerText}>{integer}</span>
            {decimal > 0 && <sup className={classNames.superscriptPriceText}>{decimal}</sup>}
          </div>

          {item.showSale && item.onSale && (
            <div
              className={
                isPromotedOnSale(item)
                  ? mergeStyles(classNames.onSaleText, classNames.highlight)
                  : classNames.onSaleText
              }
            >
              {'On Sale'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
