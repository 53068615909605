import React from 'react';
import { CustomMLCard } from '../CustomMLCard';
import { CardState } from '../CustomMLCard.types';
import { useSelector } from '../../../../../store/hooks';
import { EvaluateModelCardProps } from './EvaluateModelCard.types';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';
import { EvaluationJobMapper } from '../../../../../store/types/customML/evaluationJob';
import { useCustomMLRouter } from '../../../../../store/hooks/use-router/useCustomMLRouter';
import { SearchModel, ModelJobStatus } from '../../../../../store/types/customML/searchModel.d';
import { useActiveTraining } from '../../../../../store/hooks/use-active-job/useActiveTraining';

export function EvaluateModelCardBase(props: EvaluateModelCardProps) {
  const { t, cardComponentRef } = props;

  const trainingJob = useActiveTraining();
  const MbcCustomMLRouter = useCustomMLRouter();

  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);
  const evaluationJobMapper = useSelector<EvaluationJobMapper>(state => state.evaluationJobs.evaluationJobMapper);

  const evaluationJobList = searchModel.evaluationJobKeys.map(key => evaluationJobMapper[key]);
  const failedEvaluationJobCount = evaluationJobList.filter(
    evaluationJob => evaluationJob.status === ModelJobStatus.Error,
  ).length;
  const completedEvaluationJobCount = evaluationJobList.filter(
    evaluationJob => evaluationJob.status === ModelJobStatus.Finished,
  ).length;
  const loadingEvaluationJobCount = evaluationJobList.filter(
    evaluationJob => evaluationJob.status === ModelJobStatus.Running || evaluationJob.status === ModelJobStatus.Pending,
  ).length;

  let evaluationCardState =
    searchModel.evaluationJobKeys.length > 0
      ? CardState.Completed
      : trainingJob.status === ModelJobStatus.Finished
      ? CardState.Active
      : CardState.InActive;

  return (
    <CustomMLCard
      state={evaluationCardState}
      componentRef={cardComponentRef}
      title={t('custom-ml.job-card.eval.title')}
      activeCardProps={{
        iconProps: { iconName: 'Tiles' },
        label: t('custom-ml.job-card.eval.action'),
        onClick: MbcCustomMLRouter(MbcRouteKey.EvaluationJobList).browserPush,
      }}
      completedCardProps={{
        label: t('custom-ml.job-card.eval.action'),
        onClick: MbcCustomMLRouter(MbcRouteKey.EvaluationJobList).browserPush,
        contentProps: {
          title: t('custom-ml.job-card.eval.content-title', {
            count: searchModel.evaluationJobKeys.length,
          }),
          statusList: [
            {
              label: t('custom-ml.job-card.eval.status.completed', {
                count: completedEvaluationJobCount,
              }),
              status: ModelJobStatus.Finished,
            },
            {
              label: t('custom-ml.job-card.eval.status.loading', {
                count: loadingEvaluationJobCount,
              }),
              status: ModelJobStatus.Running,
            },
            {
              label: t('custom-ml.job-card.eval.status.failed', {
                count: failedEvaluationJobCount,
              }),
              status: ModelJobStatus.Error,
            },
          ],
        },
      }}
    />
  );
}
