import {
  UrlRedirectSet as UrlRedirectSetDto,
  UrlRedirectSetListResponse,
  UrlRedirectSetDeleteResponse,
} from '../../dto/customizations/urlRedirectSet';
import { UrlRedirectSet as UrlRedirectSetModel } from '../../../store/types/customizations/urlRedirectSet.d';
import { createEmptyUrlRedirectSet } from '../../../store/reducers/customizations/urlRedirectSetReducer';
import { StringSetCondition, createStringSetCondition } from '../../../utils/customizations/conditions';

export const mapUrlRedirectSetDtoToModel = (urlRedirectSetDto: UrlRedirectSetDto): UrlRedirectSetModel => {
  return {
    id: urlRedirectSetDto.redirectId,
    redirectUrl: urlRedirectSetDto.redirectUrl,
    queryList: (urlRedirectSetDto.searchRequestCondition as StringSetCondition).values || [],
  };
};

export const mapUrlRedirectSetModelToDto = (urlRedirectSetModel: UrlRedirectSetModel): UrlRedirectSetDto => {
  return {
    redirectId: urlRedirectSetModel.id,
    searchRequestCondition: createStringSetCondition({ field: 'query', values: urlRedirectSetModel.queryList }),
    redirectUrl: urlRedirectSetModel.redirectUrl,
  };
};

export const mapUrlRedirectSetListDtoToModel = (
  urlRedirectSetListDto: UrlRedirectSetListResponse,
): UrlRedirectSetModel[] => {
  return urlRedirectSetListDto.values.map(mapUrlRedirectSetDtoToModel);
};

export const mapDeletedUrlRedirectSetDtoToModel = (
  deleteResponse: UrlRedirectSetDeleteResponse,
): UrlRedirectSetModel => {
  return { ...createEmptyUrlRedirectSet(), id: deleteResponse.redirectId };
};
