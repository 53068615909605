import { IngestionResponse, IngestionStatusResponse } from './dto/ingestion/ingestion';
import HttpClient from './http-client/httpClient';
import { DocumentDeleteResponse } from './dto/ingestion/document';

function getDocumentsDeletionUri(tenantId: string, indexId: string): string {
  return `https://commerce.bing.com/api/ingestion/v1/${tenantId}/indexes/${indexId}/deletedata`;
}

function getIngestionUri(tenantId: string, indexId: string, isOobe: boolean, isFile: boolean): string {
  return `https://commerce.bing.com/api/ingestion/v1/${tenantId}/indexes/${indexId}/pushData${
    (isOobe && tenantId !== '57e385bf-6a10-481d-75f9-08d76cc81ddb') || !isFile ? '?notransform=true' : ''
  }`;
}

function getIngestionStatusUri(tenantId: string, indexId: string, updateId: string): string {
  return `https://commerce.bing.com/api/ingestion/v1/${tenantId}/indexes/${indexId}/status/${updateId}`;
}

export function ingestDocumentsFromFile(
  file: File,
  tenantId: string,
  indexId: string,
  isOobe: boolean,
): Promise<IngestionResponse> {
  return HttpClient.post<IngestionResponse>({
    url: getIngestionUri(tenantId, indexId, isOobe, true),
    body: file,
    // This is to prevent the HttpClient from sending the default content-type header
    headers: new Headers(),
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  });
}

export function ingestDocument(
  document: any,
  tenantId: string,
  indexId: string,
  isOobe: boolean,
): Promise<IngestionResponse> {
  return HttpClient.post<IngestionResponse>({
    url: getIngestionUri(tenantId, indexId, isOobe, false),
    body: [document],
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  });
}

export function deleteDocuments(
  tenantId: string,
  indexId: string,
  documentIds: string[],
): Promise<DocumentDeleteResponse> {
  return HttpClient.post<DocumentDeleteResponse>({
    url: getDocumentsDeletionUri(tenantId, indexId),
    body: {
      Values: documentIds,
    },
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  });
}

export function getIngestionStatus(
  tenantId: string,
  indexId: string,
  updateId: string,
): Promise<IngestionStatusResponse> {
  return HttpClient.get<IngestionStatusResponse>({
    url: getIngestionStatusUri(tenantId, indexId, updateId),
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
    supressNotificationOnFailure: true,
  });
}
