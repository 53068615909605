import { TextField, IconButton } from 'office-ui-fabric-react';
import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styles from './demoSearchBox.module.scss';
import DemoSearchBoxSuggestions from './suggestions/demoSearchBoxSuggestions';

interface DemoSearchBoxProps extends WithTranslation {
  defaultQuery: string;
  suggestions: string[];
  onChangeQuery: Function;
}

interface DemoSearchBoxState {
  isSuggestionsExpanded: boolean;
  currentQuery: string;
}

class DemoSearchBox extends Component<DemoSearchBoxProps, DemoSearchBoxState> {
  private searchBoxElement = React.createRef<HTMLDivElement>();
  private calloutElement = React.createRef<HTMLDivElement>();

  constructor(props: DemoSearchBoxProps) {
    super(props);
    this.state = {
      isSuggestionsExpanded: false,
      currentQuery: '',
    };
  }

  onExpandSuggestions() {
    this.setState({
      isSuggestionsExpanded: true,
    });
  }

  onDismissSuggestions(event: any) {
    if (
      this.searchBoxElement.current !== null &&
      !this.searchBoxElement.current.contains(event.target) &&
      this.calloutElement.current !== null &&
      !this.calloutElement.current.contains(event.target)
    ) {
      this.setState({
        isSuggestionsExpanded: false,
      });
    }
  }

  onChangeQueryInternal(query: string | undefined) {
    if (!query) {
      return;
    }
    this.setState({
      currentQuery: query,
      isSuggestionsExpanded: false,
    });
    this.props.onChangeQuery(query);
  }

  componentDidMount() {
    document.addEventListener('click', this.onDismissSuggestions.bind(this), true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDismissSuggestions.bind(this), true);
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <div ref={this.searchBoxElement} className={styles.tryNewExpSearchBox}>
          <TextField
            className={styles.searchBoxContainer}
            value={this.state.currentQuery ? this.state.currentQuery : this.props.defaultQuery}
            readOnly={true}
            onRenderSuffix={() => {
              return (
                <IconButton
                  iconProps={{ iconName: 'Search' }}
                  text={t('common.search-label')}
                  ariaLabel={t('common.search-label')}
                ></IconButton>
              );
            }}
            onFocus={this.onExpandSuggestions.bind(this)}
            autoComplete="off"
            ariaLabel={t('demo-search-box.text-field-label')}
          ></TextField>
        </div>
        <DemoSearchBoxSuggestions
          isExpanded={this.state.isSuggestionsExpanded}
          searchSuggestions={
            this.props.suggestions.length > 0
              ? [
                  'Searches to try', // This is the title, it has to remain the first item on the list
                  this.props.defaultQuery,
                ]
                  .concat(this.props.suggestions)
                  .filter(
                    query => query !== (this.state.currentQuery ? this.state.currentQuery : this.props.defaultQuery),
                  )
              : []
          }
          calloutElement={this.calloutElement}
          searchBoxElement={this.searchBoxElement}
          onSuggestionClick={this.onChangeQueryInternal.bind(this)}
        ></DemoSearchBoxSuggestions>
      </Fragment>
    );
  }
}

export default withTranslation()(DemoSearchBox);
