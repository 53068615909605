import { ProductSupportPanelStyleProps, ProductSupportPanelStyles } from './ProductSupportPanel.types';

export const styles = (props: ProductSupportPanelStyleProps): ProductSupportPanelStyles => {
  return {
    root: ['mbc-product-support-panel', props.className],
    panelHeaderContainer: [
      'mbc-header-container',
      {
        width: '100%',
        alignItems: 'center',
        height: 85,
        selectors: { '.ms-Button-flexContainer': { height: 36 } },
      },
    ],
    panelTitle: [
      'mbc-panel-title',
      {
        fontWeight: 600,
        paddingLeft: 10,
        fontSize: 20,
        lineHeight: 28,
      },
    ],
  };
};
