import { SearchIndex, IndexField, createInitialIndexField } from '../../../../store/types/searchIndex.d';
import { ROOT_FIELD_KEY } from './SchemaManagement.types';
import { ListItemsOrder } from '../../../../utils';
import { SearchIndexTypeConfig } from './SchemaManagement.config';

export const isComplexFieldEnabled = (searchIndex: SearchIndex): boolean =>
  searchIndex.schemaVersion !== undefined && searchIndex.schemaVersion !== '1.0';

export const sort = (indexFieldList: IndexField[], order: ListItemsOrder): IndexField[] =>
  [
    ...indexFieldList.sort((a, b) =>
      order === ListItemsOrder.ASCENDING
        ? a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : -1
        : a.name.toLowerCase() > b.name.toLowerCase()
        ? -1
        : 1,
    ),
  ].map(indexField =>
    !!indexField.fields
      ? {
          ...indexField,
          fields: sort(indexField.fields, order),
        }
      : indexField,
  );

const insertSearchIndexFieldInternal = (key: string, indexFieldList: IndexField[]): [boolean, IndexField[]] => {
  if (key === ROOT_FIELD_KEY) {
    return [true, [createInitialIndexField(), ...indexFieldList]];
  }

  const index = indexFieldList.findIndex(
    f =>
      f.key === key &&
      !!f.type &&
      !!SearchIndexTypeConfig[f.type] &&
      SearchIndexTypeConfig[f.type].dataType === 'Object',
  );

  if (index !== -1) {
    const updatedIndexFieldList = [...indexFieldList];
    const updatedIndexField = indexFieldList[index];
    updatedIndexFieldList[index] = {
      ...updatedIndexField,
      fields: [createInitialIndexField(), ...(updatedIndexField.fields || [])],
    };

    return [true, updatedIndexFieldList];
  }

  for (let i = 0; i < indexFieldList.length; i++) {
    const indexField = indexFieldList[i];
    if (!indexField.fields || indexField.fields.length === 0) {
      continue;
    }

    const [found, updatedIndexFieldList] = insertSearchIndexFieldInternal(key, indexField.fields);
    if (!found) {
      continue;
    }

    const clonedIndexFieldList = [...indexFieldList];
    clonedIndexFieldList[i] = {
      ...indexField,
      fields: updatedIndexFieldList,
    };

    return [true, clonedIndexFieldList];
  }

  return [false, indexFieldList];
};

export const insertSearchIndexField = (key: string, indexFieldList: IndexField[]): IndexField[] => {
  const [, updatedIndexFieldList] = insertSearchIndexFieldInternal(key, indexFieldList);
  return updatedIndexFieldList;
};

const removeSearchIndexFieldInternal = (key: string, indexFieldList: IndexField[]): [boolean, IndexField[]] => {
  const index = indexFieldList.findIndex(f => f.key === key);

  if (index !== -1) {
    return [true, indexFieldList.filter(f => f.key !== key)];
  }

  for (let i = 0; i < indexFieldList.length; i++) {
    const indexField = indexFieldList[i];
    if (!indexField.fields || indexField.fields.length === 0) {
      continue;
    }

    const [found, updatedIndexFieldList] = removeSearchIndexFieldInternal(key, indexField.fields);
    if (!found) {
      continue;
    }

    const clonedIndexFieldList = [...indexFieldList];
    clonedIndexFieldList[i] = {
      ...indexField,
      fields: updatedIndexFieldList,
      type: updatedIndexFieldList.length === 0 ? '' : indexField.type,
    };

    return [true, clonedIndexFieldList];
  }

  return [false, indexFieldList];
};

export const removeSearchIndexField = (key: string, indexFieldList: IndexField[]): IndexField[] => {
  const [, updatedIndexFieldList] = removeSearchIndexFieldInternal(key, indexFieldList);
  return updatedIndexFieldList;
};

export const updateSearchIndexFieldInternal = (
  key: string,
  property: string,
  value: string | boolean,
  indexFieldList: IndexField[],
): [boolean, IndexField[]] => {
  const index = indexFieldList.findIndex(f => f.key === key);

  if (index !== -1) {
    const updatedIndexFieldList = [...indexFieldList];
    const updatedIndexField = updatedIndexFieldList[index];
    switch (property) {
      case 'name':
      case 'type':
        updatedIndexFieldList[index] = {
          ...updatedIndexField,
          [property]: value as string,
        };
        break;
      case 'retrievable':
      case 'searchable':
      case 'filterable':
      case 'facetable':
      case 'sortable':
        updatedIndexFieldList[index] = {
          ...updatedIndexField,
          features: {
            ...updatedIndexField.features,
            [property]: value as boolean,
          },
        };
        break;
    }

    return [true, updatedIndexFieldList];
  }

  for (let i = 0; i < indexFieldList.length; i++) {
    const indexField = indexFieldList[i];
    if (!indexField.fields || indexField.fields.length === 0) {
      continue;
    }

    const [found, updatedIndexFieldList] = updateSearchIndexFieldInternal(key, property, value, indexField.fields);
    if (!found) {
      continue;
    }

    const clonedIndexFieldList = [...indexFieldList];
    clonedIndexFieldList[i].fields = updatedIndexFieldList;
    return [true, clonedIndexFieldList];
  }

  return [false, indexFieldList];
};

export const updateSearchIndexField = (
  key: string,
  property: string,
  value: string | boolean,
  indexFieldList: IndexField[],
): IndexField[] => {
  const [, updatedIndexFieldList] = updateSearchIndexFieldInternal(key, property, value, indexFieldList);
  return updatedIndexFieldList;
};
