import React, { useCallback, useState } from 'react';
import { AppState } from '../../../../../store/reducers';
import { MultiLineChart } from '../../../../../components/common/charts/MultiLineChart';
import { setDocumentsBreakDown, setKeyLevelError } from '../../../../../store/actions/insightsAction';
import { DashboardChartCard } from '../common/DashboardChartCard';
import { useSelector, useDispatch } from '../../../../../store/hooks';
import { useMediaQuery } from 'react-responsive';

import {
  classNamesFunction,
  IDetailsRowProps,
  IDetailsRowStyles,
  DetailsRow,
  IColumn,
  TooltipHost,
  TooltipOverflowMode,
  ShimmeredDetailsList,
  SelectionMode,
  ConstrainMode,
  DetailsListLayoutMode,
  Modal,
  IconButton,
  Link,
} from 'office-ui-fabric-react';
import {
  DocumentsBreakDownStyleProps,
  DocumentsBreakDownStyles,
  DocumentsBreakDownProps,
} from './DocumentsBreakDown.types';
import { TickType } from '../../../../../components/common/charts/common/AxisTickFormatter';
import { AxisInterval, TooltipProps } from 'recharts';
import { TimeFilterOptions } from '../Insights.utils';
import { ToolTipFormatter } from '../../../../../components/common/charts/common/ToolTipFormatter';
import { getChartTitleTooltipMessage } from '../Insights.utils';
import { TitledContentCard } from '../../../../../components/common/ContentCard/TitledContentCard';
import { isProductionEnvironment } from '../../../../../guards/EnvironmentGuard/utils';

const getClassNames = classNamesFunction<DocumentsBreakDownStyleProps, DocumentsBreakDownStyles>();

export const DocumentsBreakDownBase = (props: DocumentsBreakDownProps) => {
  const { t, styles, theme, className, componentRef, hasTimeSelector } = props;
  const documentsBreakDown = useSelector((state: AppState) => state.insights.documentsBreakDown);
  const isDocumentsBreakDownLoading = useSelector((state: AppState) => state.insights.isDocumentsBreakDownLoading);

  const isKeyLevelErrorLoading = useSelector((state: AppState) => state.insights.isKeyLevelErrorLoading);
  const keyLevelError = useSelector((state: AppState) => state.insights.keyLevelError);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const isLargeScreen = useMediaQuery({ query: '(min-width: 1400px)' });
  const isMediumLargeScreen = useMediaQuery({ query: '(min-width: 936px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 830px)' });

  const footerDisabled =
    isKeyLevelErrorLoading ||
    isDocumentsBreakDownLoading ||
    documentsBreakDown.series.length === 0 ||
    keyLevelError.series.length === 0;

  let chartInterval: AxisInterval = 1;

  if (documentsBreakDown.series.length > 26) {
    if (isLargeScreen) {
      chartInterval = 11;
    } else if (isMediumLargeScreen) {
      chartInterval = 17;
    } else if (isMediumScreen) {
      chartInterval = 23;
    } else {
      chartInterval = 31;
    }
  } else if (documentsBreakDown.series.length > 13) {
    if (isLargeScreen) {
      chartInterval = 1;
    } else if (isMediumScreen) {
      chartInterval = 2;
    } else {
      chartInterval = 3;
    }
  } else if (documentsBreakDown.series.length > 8) {
    if (isLargeScreen) {
      chartInterval = 0;
    } else if (isMediumScreen) {
      chartInterval = 1;
    } else {
      chartInterval = 2;
    }
  } else {
    chartInterval = 0;
  }

  const onRenderRowInternal = (props?: IDetailsRowProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 !== 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: '#FAFAFA' };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const onRenderColumnItemInternal = useCallback(
    (item?: any, column?: IColumn) => {
      if (item && column) {
        return (
          <TooltipHost
            overflowMode={TooltipOverflowMode.Self}
            hostClassName={classNames.toolTipCell}
            content={item.errorMessage}
          >
            <div className={classNames.toolTipContent}>{item.errorMessage}</div>
          </TooltipHost>
        );
      }
    },
    [classNames.toolTipCell, classNames.toolTipContent],
  );

  const getModalDetails = useCallback(() => {
    const keyLevelErrorItems = keyLevelError.series
      .map(item =>
        item.errorDetails.map(detail => ({
          count: detail.numberOfOccurance,
          errorMessage: detail.errorMessage,
        })),
      )
      .flat();

    return (
      <ShimmeredDetailsList
        isHeaderVisible={false}
        shimmerLines={5}
        enableShimmer={isKeyLevelErrorLoading}
        className={classNames.modalDetailsList}
        items={keyLevelErrorItems}
        selectionMode={SelectionMode.none}
        onRenderRow={props => onRenderRowInternal(props)}
        columns={[
          {
            key: 'errorMessage',
            name: t('insights.documents-break-down-chart.modal-error-details-column'),
            fieldName: 'errorMessage',
            minWidth: 200,
            onRender: (item, index, column) => onRenderColumnItemInternal(item, column),
          },
        ]}
        constrainMode={ConstrainMode.horizontalConstrained}
        layoutMode={DetailsListLayoutMode.justified}
      />
    );
  }, [classNames.modalDetailsList, isKeyLevelErrorLoading, keyLevelError.series, onRenderColumnItemInternal, t]);

  return (
    <>
      <div className={classNames.root}>
        <DashboardChartCard
          hasTimeSelector={hasTimeSelector}
          componentRef={componentRef}
          cardTitle={t('insights.documents-break-down-chart.title')}
          isLoading={isDocumentsBreakDownLoading}
          onChange={(timespan, interval) => {
            dispatch(setDocumentsBreakDown(timespan, interval));
            dispatch(setKeyLevelError(timespan));
          }}
          refreshOptions={{
            text: getChartTitleTooltipMessage(documentsBreakDown.cacheDetails),
            onRefreshClick: (timespan, interval) => {
              dispatch(setDocumentsBreakDown(timespan, interval));
              dispatch(setKeyLevelError(timespan));
            },
          }}
          defaultTimeSelectorValue={TimeFilterOptions.ONE_DAY}
          body={
            <MultiLineChart
              points={documentsBreakDown.series.map(value => ({
                xValue: value.xValue,
                yValues: [value.failedDocuments, value.successfulDocuments],
              }))}
              toolTipContent={(props: TooltipProps) => <ToolTipFormatter shouldReverseOrder toolTipProps={props} />}
              isLoading={isDocumentsBreakDownLoading}
              xAxisProps={{
                interval: chartInterval,
                tickProps: {
                  tickType: TickType.DateTime12Hrs,
                  blockRotation: true,
                  fontSize: 12,
                  rotatedFontSize: 10,
                },
              }}
              minWidth={50}
              minHeight={200}
              xLine={{
                name: t('insights.documents-break-down-chart.x-line-label'),
                stroke: 'grey',
              }}
              ylines={[
                {
                  name: t('insights.documents-break-down-chart.failed-y-line'),
                  stroke: 'rgb(239, 105, 80)',
                },
                {
                  name: t('insights.documents-break-down-chart.successful-y-line'),
                  stroke: theme!.palette.themePrimary,
                },
              ]}
              legendProps={{
                legendContent: [
                  {
                    name: t('insights.documents-break-down-chart.successful-y-line'),
                    stroke: theme!.palette.themePrimary,
                  },
                  {
                    name: t('insights.documents-break-down-chart.failed-y-line'),
                    stroke: 'rgb(239, 105, 80)',
                  },
                ],
              }}
              footer={
                !isProductionEnvironment() && !footerDisabled ? (
                  <div className={classNames.footer}>
                    <Link
                      className={classNames.footerLink}
                      onClick={() => setIsModalOpen(true)}
                      disabled={footerDisabled}
                    >
                      {t('insights.documents-break-down-chart.footer-link')}
                    </Link>
                  </div>
                ) : (
                  undefined
                )
              }
            />
          }
        />
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} isBlocking={true} isDarkOverlay={true}>
          <TitledContentCard
            body={getModalDetails()}
            title={t('insights.documents-break-down-chart.modal-error-details-column')}
            className={classNames.modalContentCard}
            footer={<div>{getChartTitleTooltipMessage(keyLevelError.cacheDetails)}</div>}
            actions={
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel={t('common.cancel')}
                onClick={() => {
                  setIsModalOpen(false);
                }}
              />
            }
          />
        </Modal>
      )}
    </>
  );
};
