import { ProductGuard } from './ProductGuard';
import React from 'react';
import { ProductGuardProps } from './ProductGuard.types';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';

const mapStateToProps = (state: AppState) => ({
  supportedProducts: state.tenantsList.activeTenant.products,
});

const ConnectedTenantProductGuard = connect(mapStateToProps)(ProductGuard);

export const TenantProductGuard: React.FC<ProductGuardProps> = (props: ProductGuardProps) => {
  return <ConnectedTenantProductGuard {...props} />;
};
