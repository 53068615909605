import { Dialog, DialogFooter, DialogType, PrimaryButton, classNamesFunction } from 'office-ui-fabric-react';
import React from 'react';
import {
  TokenCreationStatusDialogProps,
  TokenCreationStatusDialogStyleProps,
  TokenCreationStatusDialogStyles,
} from './TokenCreationStatusDialog.types';
import { CopyToClipboard } from '../../../components/common/CopyToClipboard';

const getClassNames = classNamesFunction<TokenCreationStatusDialogStyleProps, TokenCreationStatusDialogStyles>();

export const TokenCreationStatusDialogBase = (props: TokenCreationStatusDialogProps) => {
  const { accessToken, refreshToken, onDismiss, isOpen, theme, className, styles, t } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <Dialog
      hidden={!isOpen}
      dialogContentProps={{
        type: DialogType.normal,
        title: t('token-creation-status-dialog.created-title'),
        showCloseButton: false,
        subText:
          typeof refreshToken != 'undefined'
            ? t('token-creation-status-dialog.refresh-token-created')
            : t('token-creation-status-dialog.access-token-created'),
        styles: { content: classNames.modalContent },
      }}
      modalProps={{
        isBlocking: true,
        containerClassName: classNames.root,
      }}
    >
      <>
        <CopyToClipboard value={accessToken} ariaLabel={t('token-creation-status-dialog.access-token-aria-label')} />
        {!!refreshToken && (
          <CopyToClipboard
            value={refreshToken}
            ariaLabel={t('token-creation-status-dialog.refresh-token-aria-label')}
          />
        )}
        <DialogFooter>
          <PrimaryButton onClick={onDismiss} text={t('common.ok')} />
        </DialogFooter>
      </>
    </Dialog>
  );
};
