import {
  FetchRequest,
  ServiceException,
  GenericServiceException,
  AdminServiceException,
  IngestionServiceException,
} from './Types';
import { DocumentDeleteResponse } from '../../dto/ingestion/document';
import { IngestionStatusResponse } from '../../dto/ingestion/ingestion';

export function AddHeader(request: FetchRequest, key: string, value: string): FetchRequest {
  var headers = new Headers();

  if (request.init && request.init.headers) {
    headers = new Headers(request.init.headers);
  }

  headers.append(key, value);

  request.init = {
    ...request.init,
    headers: headers,
  };

  return request;
}

export const ExtractAdminExceptionMessage = (exception: AdminServiceException): string => {
  return exception.error.details
    .filter(x => x.moreDetails !== undefined)
    .map(x => x.moreDetails)
    .join(', ');
};
export const ExtractDocumentDeletionExceptionMessage = (exception: DocumentDeleteResponse) => exception.ErrorMessage;

export const ExtractIngestionExceptionMessage = (exp: IngestionServiceException): string => {
  const res = exp.errors.filter(x => x.moreDetails !== undefined)[0].moreDetails;
  if (res) return res;
  return 'Unknown error';
};

export function IsGenericServiceException(exception: ServiceException): exception is GenericServiceException {
  return (exception as GenericServiceException).errors !== undefined;
}

export function IsAdminServiceException(exception: ServiceException): exception is AdminServiceException {
  return (exception as AdminServiceException).error !== undefined;
}

export function IsDocumentIngestionDeletionException(exception: ServiceException): exception is DocumentDeleteResponse {
  return (exception as DocumentDeleteResponse).ErrorMessage !== undefined;
}

export function IsIngestionServiceException(exception: ServiceException): exception is IngestionServiceException {
  return (
    (exception as IngestionServiceException).errors !== undefined &&
    (exception as IngestionServiceException).errors.filter(x => x.moreDetails !== undefined).length > 0
  );
}

export function IsIngestionUpdateIdNotFound(exception: ServiceException): exception is IngestionServiceException {
  return (
    (exception as IngestionServiceException).errors !== undefined &&
    (exception as IngestionServiceException).errors.filter(x => x.parameter === 'updateId').length > 0
  );
}

export function IsIngestionPushDataStatusException(exception: ServiceException): exception is IngestionStatusResponse {
  const status = (exception as IngestionStatusResponse).status;
  const type = (exception as IngestionStatusResponse)._type;
  const errors = (exception as IngestionStatusResponse).failureMessages;

  return (
    typeof type !== 'undefined' &&
    type === 'PushUpdateStatusResponse' &&
    (typeof errors !== 'undefined' && errors.length > 0) &&
    (typeof status !== 'undefined' && status === 'Failed')
  );
}
