import { useSelector } from '..';
import { useMemo } from 'react';
import { IndexField, HierarchalIndexField } from '../../types/searchIndex';

export const getFilterableFields = (
  fields: IndexField[],
  excludedFieldTypes: string[],
  parentHierarchalFieldName: string = '',
): HierarchalIndexField[] => {
  let filterableFields: HierarchalIndexField[] = [];
  fields.forEach(field => {
    const hierarchalFieldName = parentHierarchalFieldName ? `${parentHierarchalFieldName}.${field.name}` : field.name;
    if (field.fields && field.fields.length > 0) {
      filterableFields = filterableFields.concat(
        getFilterableFields(field.fields, excludedFieldTypes, hierarchalFieldName),
      );
    }
    if (field.features.filterable && !excludedFieldTypes.includes(field.type)) {
      filterableFields.push({ ...field, hierarchalFieldName: hierarchalFieldName });
    }
  });
  return filterableFields;
};

export const useFilterableFields = (excludedFieldTypes: string[] = []): HierarchalIndexField[] => {
  const indexFields = useSelector(state => state.searchIndex.searchIndex.fields);

  return useMemo(() => getFilterableFields(indexFields, excludedFieldTypes), [excludedFieldTypes, indexFields]);
};
