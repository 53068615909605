import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import { CustomMLRankerProps, CustomMLRankerStyleProps, CustomMLRankerStyles } from './CustomMLRanker.types';
import { SplitPageTemplate } from '../../../components/common/PageTemplate/PageTemplate';
import { SearchInstanceSelector } from '../SearchInstance/SearchInstanceSelector';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { SearchPreview } from '../common/SearchPreview';
import { CustomMLRankerEditor } from './CustomMLRankerEditor';
import { Augmentations } from '../common/SearchPreview/SearchPrevew.utils';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../store/hooks';
import { AppState } from '../../../store/reducers';
import {
  DefaultRankerId,
  DefaultCustomMlSearchConfigId,
} from '../../../store/types/customizations/customMlSearchConfig.d';
import {
  getCustomMlSearchConfig,
  resetCustomMlSearchConfig,
  updateCustomMlSearchConfig,
} from '../../../store/actions/customizations/customMlSearchConfigActions';
import { TenantPermissionContext, TenantPermissionContextType } from '../../../contexts';
import { Permission } from '../../../config/userPermissions.config';
import _ from 'lodash';
import { Prompt } from 'react-router-dom';
import { toggleSideNavExpanded } from '../../../store/actions/sideNavActions';

const getClassNames = classNamesFunction<CustomMLRankerStyleProps, CustomMLRankerStyles>();

export const CustomMLRankerBase = (props: CustomMLRankerProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const dispatch = useDispatch();
  const tenantRankers = useSelector((state: AppState) => state.tenantsList.activeTenant.rankers);
  const loadingSearchModel = useSelector<boolean>((state: AppState) => state.customMlSearchConfig.isLoading);
  const activeSearchModel = useSelector<string>(
    (state: AppState) => state.customMlSearchConfig.customMlSearchConfig.searchModel,
  );
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);

  const [currentSearchModel, setCurrentSearchModel] = useState<string | undefined>(activeSearchModel);

  const [isSaved, setIsSaved] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const shouldBlock = useMemo(() => isUpdated && !isSaved, [isSaved, isUpdated]);

  useEffect(() => {
    const updated = !_.isEqual(activeSearchModel, currentSearchModel);
    updated !== isUpdated && setIsUpdated(updated);
  }, [activeSearchModel, currentSearchModel, isUpdated]);

  useEffect(() => {
    shouldBlock ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldBlock]);

  useEffect(() => {
    dispatch(getCustomMlSearchConfig());
    return () => {
      dispatch(resetCustomMlSearchConfig());
    };
  }, [dispatch]);

  useEffect(() => {
    !loadingSearchModel && setCurrentSearchModel(!!activeSearchModel ? activeSearchModel : DefaultRankerId);
  }, [dispatch, activeSearchModel, loadingSearchModel]);

  const getAugmentations = useCallback((): Augmentations => {
    return {
      CustomMlModels:
        !!currentSearchModel && currentSearchModel !== DefaultRankerId
          ? [{ searchModel: currentSearchModel }]
          : undefined,
      excludeCustomMlIds: [DefaultCustomMlSearchConfigId],
    };
  }, [currentSearchModel]);

  const handleSave = () => {
    setIsSaved(true);
    setIsUpdated(false);
    !!currentSearchModel && dispatch(updateCustomMlSearchConfig({ searchModel: currentSearchModel }));
  };

  const handleCancel = () => {
    setCurrentSearchModel(activeSearchModel);
  };

  const getEditorContent = useCallback(() => {
    return (
      <CustomMLRankerEditor
        rankerList={tenantRankers}
        selectedRanker={currentSearchModel}
        onUpdate={ranker => setCurrentSearchModel(ranker)}
      />
    );
  }, [currentSearchModel, tenantRankers]);

  return (
    <TenantPermissionContext.Consumer>
      {(props: TenantPermissionContextType) => {
        const isAuthorized = props.isAuthorized(Permission.SearchCustomMLReadWrite);

        return (
          <div className={classNames.root}>
            <SplitPageTemplate
              pageHeaderProps={{ title: 'ML Ranker' }}
              onRenderLeftSideContent={() => <SearchPreview hidePostBody augmentations={getAugmentations()} />}
              collapsiblePaneProps={{
                onRenderPaneContent: getEditorContent,
                footerProps: {
                  saveButtonText: 'Save',
                  saveButtonDisabled: !isAuthorized || !shouldBlock,
                  onSave: handleSave,
                  onCancel: handleCancel,
                },
              }}
              breadcrumbVisible={true}
              onSecondaryNavMenuRender={() => <SearchInstanceSelector />}
              sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
              sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
            />
            <Prompt when={shouldBlock} message={t('common.on-before-unload-msg')} />
          </div>
        );
      }}
    </TenantPermissionContext.Consumer>
  );
};
