import { Action } from 'redux';

export const MAX_PRODUCTS_PER_REQUEST = 15;

export const SET_PRODUCT_SEARCH_EDITABLE = 'SET_PRODUCT_SEARCH_EDITABLE';
export const SET_PRODUCT_SEARCH_NON_EDITABLE = 'SET_PRODUCT_SEARCH_NON_EDITABLE';

export const SET_PRODUCT_SEARCH_RESULTS = 'SET_PRODUCT_SEARCH_RESULTS';
export const RESET_PRODUCT_SEARCH_RESULTS = 'RESET_PRODUCT_SEARCH_RESULTS';
export const UPDATE_PRODUCT_SEARCH_RESULTS = 'UPDATE_PRODUCT_SEARCH_RESULTS';
export const LOADING_PRODUCT_SEARCH_RESULTS = 'LOADING_PRODUCT_SEARCH_RESULTS';

export interface ProductSearchResult {
  skip: number;
  query: string;
  alteredQuery: string;
  totalEstimatedMatches: number;
  searchResultItems: ProductSearchResultItem[];
}

export interface ProductSearchResultItem {
  id: string;
  url?: string;
  name?: string;
  size?: string;
  price?: string;
  rating?: string;
  imgUrl?: string;
  altImgUrl?: string;
  description?: string;
  data: { [key: string]: any };
}

export interface ProductSearchState {
  query: string;
  isLoading: boolean;
  alteredQuery: string;
  totalEstimatedMatches: number;
  nonEditableProductSet: Set<string>;
  productSearchResults: ProductSearchResultItem[];
}

interface UpdateProductSearchResults extends Action<typeof UPDATE_PRODUCT_SEARCH_RESULTS> {
  productSearchResult: ProductSearchResult;
}

interface SetProductSearchResults extends Action<typeof SET_PRODUCT_SEARCH_RESULTS> {
  productSearchResult: ProductSearchResult;
}

interface SetProductSearchEditable extends Action<typeof SET_PRODUCT_SEARCH_EDITABLE> {
  productId: string;
}

interface SetProductSearchNonEditable extends Action<typeof SET_PRODUCT_SEARCH_NON_EDITABLE> {
  productId: string;
}

export type ProductSearchStateActionTypes =
  | SetProductSearchResults
  | SetProductSearchEditable
  | UpdateProductSearchResults
  | SetProductSearchNonEditable
  | Action<typeof RESET_PRODUCT_SEARCH_RESULTS>
  | Action<typeof LOADING_PRODUCT_SEARCH_RESULTS>;
