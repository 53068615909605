import { MbcClampStyles, MbcClampStyleProps } from './MbcClamp.types';

export const styles = (props: MbcClampStyleProps): MbcClampStyles => {
  const { className } = props;

  return {
    root: ['mbc-clamp', className],
    link: [
      'mbc-link',
      {
        textDecoration: 'none',
        selectors: {
          ':hover': {
            textDecoration: 'underline',
          },
        },
      },
    ],
  };
};
