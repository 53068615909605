/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useMemo } from 'react';
import { Breadcrumb as FabricBreadcrumb, classNamesFunction, IBreadcrumbItem, Stack } from 'office-ui-fabric-react';
import { BreadcrumbProps, BreadcrumbStyleProps, BreadcrumbStyles } from './Breadcrumb.types';
import { useSelector } from '../../../store/hooks';
import { AppState } from '../../../store/reducers';
import { Link } from '../RouterLink';
import { BreadcrumbItem } from '../../../store/types/breadcrumbs.d';

const getClassNames = classNamesFunction<BreadcrumbStyleProps, BreadcrumbStyles>();

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const breadcrumbItems = useSelector((state: AppState) => state.breadcrumbs.breadcrumbItems.filter(item => item.text));
  const [lastItem, setLastItem] = useState<BreadcrumbItem | undefined>(undefined);

  const breadcrumbItemsMapper: { [key: string]: BreadcrumbItem } = useMemo(
    () =>
      breadcrumbItems.reduce((mapper: { [key: string]: BreadcrumbItem }, item: BreadcrumbItem) => {
        mapper[item.key] = item;
        return mapper;
      }, {}),
    [breadcrumbItems],
  );

  const currentBreadcrumbItems: IBreadcrumbItem[] = useMemo(
    () =>
      breadcrumbItems.map(item => ({
        key: item.key,
        href: item.href,
        text: item.text!,
      })),
    [breadcrumbItems],
  );

  useEffect(() => {
    setLastItem(breadcrumbItems[breadcrumbItems.length - 1]);
  }, [breadcrumbItems]);

  return breadcrumbItems.length > 1 ? (
    <Stack className={classNames.root} horizontal verticalAlign={'center'}>
      <FabricBreadcrumb
        data-test="fabric-breadcrumb"
        styles={{
          root: { marginTop: 0 },
          listItem: classNames.listItem,
          chevron: classNames.chevron,
        }}
        maxDisplayedItems={3}
        items={currentBreadcrumbItems}
        onRenderItem={(props?: IBreadcrumbItem) => {
          if (props) {
            const item = breadcrumbItemsMapper[props.key];
            if (item) {
              if (!!item.onItemRender) {
                return <div className={classNames.item}>{item.onItemRender()}</div>;
              }
              if (props.href && lastItem && props.key !== lastItem.key) {
                return (
                  <Link className={classNames.item} to={props.href}>
                    {props.text}
                  </Link>
                );
              } else {
                return <span className={classNames.item}>{props.text}</span>;
              }
            }
          }
          return null;
        }}
        onReduceData={() => undefined}
      />
    </Stack>
  ) : null;
};
