import { IStyle, IStyleFunctionOrObject, ITheme, Target } from 'office-ui-fabric-react';
import { Moment } from 'moment';

export interface ScheduleCallout {
  focus: () => void;
}

export enum ValidationErrorType {
  DATE_CONFLICT,
}

export interface ScheduleCalloutProps extends React.HTMLAttributes<HTMLDivElement> {
  componentRef?: (componentRef: ScheduleCallout) => void;
  theme?: ITheme;
  styles?: IStyleFunctionOrObject<ScheduleCalloutStyleProps, ScheduleCalloutStyles>;

  hidden: boolean;
  target: Target;
  startDate?: Moment;
  endDate?: Moment;
  onSave: (startDate?: Moment, endDate?: Moment) => void;
  onDismiss: () => void;
}

export interface ScheduleCalloutStyleProps {
  theme: ITheme;
  className?: string;
}

export interface ScheduleCalloutStyles {
  root: IStyle;
  schedule: IStyle;
  validationErrors: IStyle;
}
