import { Stack, CommandBarButton, classNamesFunction } from 'office-ui-fabric-react';
import React, { useState, useEffect } from 'react';
import { PageTemplate } from '../../components/common/PageTemplate';
import { SideNavMode } from '../../components/common/PageTemplate/PageTemplate.types';
import { GeneralSectionContent } from './GeneralSectionContent';
import { MembersSectionContent } from './MembersSectionContent';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { createRole, updateRole } from '../../store/actions/roleListActions';
import { useSelector } from '../../store/hooks';
import { Environment } from '../../store/types/environmentList';
import { Section } from '../../components/editors-common/Section';
import { RolePermissionsEditor } from '../../components/editors-common/PermissionsEditor/RolePermissionsEditor';
import { getDefaultPermissions, Permission } from '../../config/userPermissions.config';
import { EnvironmentsSectionContent } from './EnvironmentsSectionContent';
import { useDispatch } from 'react-redux';
import { TenantPermissionContext, TenantPermissionContextType } from '../../contexts';
import { useBreadcrumb } from '../../store/hooks/use-breadcrumb/useBreadcrumb';
import { EditorMode } from '../../store/types/roleList.d';
import { RoleEditorStyleProps, RoleEditorStyles, RoleEditorBaseProps } from './RoleEditor.types';
import { Prompt } from 'react-router-dom';
import { distinct } from '../../utils';

const getClassNames = classNamesFunction<RoleEditorStyleProps, RoleEditorStyles>();

export const RoleEditorBase = (props: RoleEditorBaseProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const adminRouter = useAdminRouter();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const userEmailMapper = useSelector(state => state.userList.userEmailMapper);
  const activeRole = useSelector(state =>
    state.roleList.activeRole.id
      ? state.roleList.activeRole
      : { ...state.roleList.activeRole, permissions: [Permission.AdminTokenCreate] },
  );
  const envIdMapper = useSelector(state => state.environmentList.environmentIdMapper);
  const roleEditorMode = useSelector(state => state.roleList.roleEditorMode);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [editorMode, setEditorMode] = useState(
    roleEditorMode !== EditorMode.None ? roleEditorMode : !!activeRole.id ? EditorMode.View : EditorMode.Create,
  );

  const [editableRole, setEditableRole] = useState(activeRole);
  const [envList, setEnvList] = useState<Environment[]>(
    activeRole.environmentList.map(envId => envIdMapper[envId]) || [],
  );
  const [membersEmails, setMembersEmails] = useState<string[]>(activeRole.users.map(u => u.email) || []);

  const breadCrumbKey = activeRole.id ? '' : t('role-editor.breadcrumb-key-create');
  useBreadcrumb(breadCrumbKey, props.match.url);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSaveButtonClicked) {
      adminRouter(MbcRouteKey.RoleList).browserPush();
    }
  }, [adminRouter, isSaveButtonClicked]);

  useEffect(() => {
    const defaultAddedKeys = [
      Permission.AdminEnvironmentRead,
      Permission.AdminRoleManagementRead,
      Permission.SearchAutosuggestRead,
      Permission.SearchIndexRead,
      Permission.SearchCustomizationRead,
      Permission.SearchCustomMLRead,
    ].filter(permission => activeRole.permissions.includes(permission));
    const updated =
      editableRole.name !== activeRole.name ||
      editableRole.description !== activeRole.description ||
      editableRole.hasAccessToAllEnvironments !== activeRole.hasAccessToAllEnvironments ||
      !(
        editableRole.permissions.every(permission => activeRole.permissions.includes(permission)) &&
        distinct([...defaultAddedKeys, ...editableRole.permissions], (x, y) => x === y).length ===
          activeRole.permissions.length
      ) ||
      !(
        envList.map(env => env.id).every(env => activeRole.environmentList.includes(env)) &&
        envList.length === activeRole.environmentList.length
      ) ||
      !(
        membersEmails.every(email => activeRole.users.map(u => u.email).includes(email)) &&
        membersEmails.length === activeRole.users.length
      );

    if (updated !== isUpdated) {
      setIsUpdated(updated);
    }

    updated ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);

    return () => {
      window.onbeforeunload = null;
    };
  }, [
    activeRole,
    editableRole.description,
    editableRole.hasAccessToAllEnvironments,
    editableRole.name,
    editableRole.permissions,
    envIdMapper,
    envList,
    isUpdated,
    membersEmails,
  ]);

  useEffect(() => {
    const disabled =
      !isUpdated ||
      !editableRole.name ||
      !editableRole.description ||
      !membersEmails.length ||
      (!envList.length && !editableRole.hasAccessToAllEnvironments) ||
      isSaveButtonClicked;

    if (disabled !== isSaveDisabled) {
      setIsSaveDisabled(disabled);
    }
  }, [
    activeRole,
    editableRole.description,
    editableRole.hasAccessToAllEnvironments,
    editableRole.name,
    envIdMapper,
    envList,
    isSaveButtonClicked,
    isSaveDisabled,
    isUpdated,
    membersEmails,
  ]);

  return (
    <>
      <Prompt when={isUpdated && !isSaveButtonClicked} message={t('common.on-before-unload-msg')} />

      <TenantPermissionContext.Consumer>
        {(props: TenantPermissionContextType) => {
          const isAuthorized = props.isAuthorized(Permission.AdminRoleManagementReadWrite);

          return (
            <PageTemplate
              breadcrumbVisible
              pageHeaderProps={{
                className: classNames.editorHeader,
                title: activeRole.id
                  ? editorMode === EditorMode.Edit
                    ? t('role-editor.title-edit')
                    : t('role-editor.title-view')
                  : t('role-editor.title-create'),
                titleCaption: t('role-editor.title-caption'),
                actions:
                  isAuthorized && editorMode === EditorMode.View
                    ? [
                        <CommandBarButton
                          key="Edit"
                          iconProps={{ iconName: 'Edit' }}
                          text={t('common.edit')}
                          onClick={() => setEditorMode(EditorMode.Edit)}
                        />,
                      ]
                    : [],
              }}
              sideNavMode={SideNavMode.None}
              onRenderContent={() => (
                <Stack tokens={{ childrenGap: 32 }}>
                  <Section
                    title={t('role-editor.general-section.title')}
                    onRenderContent={() => (
                      <GeneralSectionContent
                        viewOnlyMode={editorMode === EditorMode.View || !isAuthorized || isSaveButtonClicked}
                        name={editableRole.name}
                        description={editableRole.description}
                        onNameChange={(newVal: string) => setEditableRole(state => ({ ...state, name: newVal }))}
                        onDescriptionChange={(newVal: string) =>
                          setEditableRole(state => ({ ...state, description: newVal }))
                        }
                      />
                    )}
                  />

                  <div className={classNames.sectionSeparator} />

                  <Section
                    title={t('role-editor.members-section.title')}
                    caption={t('role-editor.members-section.title-caption')}
                    hasEdit={isAuthorized && editorMode === EditorMode.View}
                    onEditEnable={() => setEditorMode(EditorMode.Edit)}
                    onRenderContent={() => (
                      <MembersSectionContent
                        viewOnlyMode={editorMode === EditorMode.View || !isAuthorized || isSaveButtonClicked}
                        emailsList={membersEmails}
                        onAddEmail={(email: string) => {
                          setMembersEmails(membersEmails.concat(email));
                        }}
                        onRemoveEmail={(email: string) => {
                          setMembersEmails(membersEmails.filter(x => x !== email));
                        }}
                      />
                    )}
                  />

                  <div className={classNames.sectionSeparator} />

                  <Section
                    title={t('role-editor.envs-section.title')}
                    caption={t('role-editor.envs-section.title-caption')}
                    hasEdit={isAuthorized && editorMode === EditorMode.View}
                    onEditEnable={() => setEditorMode(EditorMode.Edit)}
                    onRenderContent={() => (
                      <EnvironmentsSectionContent
                        viewOnlyMode={editorMode === EditorMode.View || !isAuthorized || isSaveButtonClicked}
                        isAdminAccess={editableRole.hasAccessToAllEnvironments}
                        onChangeAdminAccess={(adminAccess: boolean) => {
                          setEditableRole(state => ({ ...state, hasAccessToAllEnvironments: adminAccess }));
                        }}
                        envs={envList}
                        onEnvsChanged={(newEnvList: Environment[]) => setEnvList(newEnvList)}
                      />
                    )}
                  />

                  <div className={classNames.sectionSeparator} />

                  <Section
                    title={t('role-editor.permissions-section.title')}
                    caption={t('role-editor.permissions-section.title-caption')}
                    hasEdit={isAuthorized && editorMode === EditorMode.View}
                    onEditEnable={() => setEditorMode(EditorMode.Edit)}
                    onRenderContent={() => (
                      <Stack data-test="role-editor-permissions-section" tokens={{ childrenGap: '21px' }}>
                        <RolePermissionsEditor
                          viewOnlyMode={editorMode === EditorMode.View || !isAuthorized || isSaveButtonClicked}
                          initialPermissions={activeRole.permissions || []}
                          hiddenPermissions={getDefaultPermissions()}
                          onRolePermissionUpdate={(newPermissons: string[]) => {
                            setEditableRole(state => ({
                              ...state,
                              permissions: newPermissons,
                            }));
                          }}
                          disabled={false}
                        ></RolePermissionsEditor>
                      </Stack>
                    )}
                  />
                </Stack>
              )}
              sideNavOnClick={() => {}}
              footerProps={
                editorMode === EditorMode.View
                  ? undefined
                  : {
                      saveButtonDisabled: isSaveDisabled || !isAuthorized,
                      cancelButtonDisabled: isSaveButtonClicked,
                      saveButtonText: activeRole.id ? t('common.save') : t('common.create'),
                      onSave: () => {
                        setIsSaveButtonClicked(true);
                        if (activeRole.id) {
                          // edit mode
                          dispatch(
                            updateRole(
                              activeTenant.id,
                              {
                                ...editableRole,
                                users: membersEmails.map(e => userEmailMapper[e]),
                                environmentList: envList.map(x => x.id),
                              },
                              () => {},
                              () => {},
                            ),
                          );
                        } else {
                          dispatch(
                            createRole(
                              activeTenant,
                              {
                                ...editableRole,
                                users: membersEmails.map(e => userEmailMapper[e]),
                                environmentList: envList.map(x => x.id),
                              },
                              () => {},
                              () => {},
                            ),
                          );
                        }
                      },
                      onCancel: () => {
                        if (editorMode === EditorMode.Edit) {
                          setEnvList(activeRole.environmentList.map(envId => envIdMapper[envId]) || []);
                          setMembersEmails(activeRole.users.map(u => u.email) || []);
                          setEditableRole(activeRole);
                          setEditorMode(EditorMode.View);
                        } else {
                          adminRouter(MbcRouteKey.RoleList).browserPush();
                        }
                      },
                    }
              }
            />
          );
        }}
      </TenantPermissionContext.Consumer>
    </>
  );
};
