import _ from 'lodash';
import React, { useCallback } from 'react';
import { PaginationProps, PaginationStyleProps, PaginationStyles } from './Pagination.types';
import { DefaultButton, IconButton, Stack, classNamesFunction, mergeStyles } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';

const MAX_PAGES_COUNT = 5;

const getClassNames = classNamesFunction<PaginationStyleProps, PaginationStyles>();

export const PaginationBase = (props: PaginationProps) => {
  const { styles, theme, className, currentPage, onPageUpdated, totalPageCount } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const { t } = useTranslation();

  const onChangePage = useCallback(
    (newPage: number) => {
      if (newPage < 1 || newPage > totalPageCount) return;
      onPageUpdated(newPage);
    },
    [onPageUpdated, totalPageCount],
  );

  const onRenderSection = useCallback(
    (pageStart: number, pageEnd: number): JSX.Element[] | null => {
      const curSectionLength = pageEnd - pageStart + 1;
      if (curSectionLength < 1) return null;
      return Array.from(Array(curSectionLength).keys()).map(i => {
        const pageNumber = pageStart + i;
        return (
          <DefaultButton
            key={pageNumber}
            onClick={() => onChangePage(pageNumber)}
            className={
              currentPage === pageNumber
                ? mergeStyles(classNames.pageButton, classNames.selected)
                : mergeStyles(classNames.pageButton, classNames.unselected)
            }
          >
            {pageNumber}
          </DefaultButton>
        );
      });
    },
    [classNames.pageButton, classNames.selected, classNames.unselected, currentPage, onChangePage],
  );

  // we exclude the current page from the left and right sections
  var pagesToRender: number[] = [];
  if (totalPageCount >= 3) {
    var leftSection = [currentPage - 2, currentPage - 1].filter(x => x >= 3);
    var rightSection = [currentPage + 1, currentPage + 2].filter(x => x <= totalPageCount && x >= 3);
    var maxPagesCount =
      currentPage === 1 ? MAX_PAGES_COUNT - 2 : currentPage === 2 ? MAX_PAGES_COUNT - 1 : MAX_PAGES_COUNT;
    var currentSectionsLength =
      currentPage >= 3 ? leftSection.length + rightSection.length + 1 : leftSection.length + rightSection.length;

    while (currentSectionsLength < maxPagesCount) {
      const calcMin = _.min(leftSection) || currentPage;
      const calcMax = _.max(rightSection) || currentPage;

      var canExpandLeft = calcMin - 1 >= 3;
      var canExpandRight = calcMax + 1 <= totalPageCount;

      if (canExpandLeft && !canExpandRight) leftSection.push(calcMin - 1);
      else if (!canExpandLeft && canExpandRight) rightSection.push(calcMax + 1);
      else if (canExpandLeft && canExpandRight) {
        if (leftSection.length <= rightSection.length) leftSection.push(calcMin - 1);
        else rightSection.push(calcMax + 1);
      } else break;
      currentSectionsLength =
        currentPage >= 3 ? leftSection.length + rightSection.length + 1 : leftSection.length + rightSection.length;
    }

    pagesToRender =
      currentPage >= 3 ? [...leftSection, ...rightSection, currentPage] : [...leftSection, ...rightSection];
    pagesToRender = _.sortBy(pagesToRender); // we do need that sort because we don't guarantee the order of inserting pages
  }
  return (
    <Stack horizontal className={classNames.root}>
      {currentPage > 1 && (
        <IconButton
          className={classNames.chevron}
          iconProps={{ iconName: 'ChevronLeftMed' }}
          ariaLabel={t('common.prev-page-aria-label')}
          onClick={() => onChangePage(currentPage - 1)}
        ></IconButton>
      )}

      <Stack horizontal className={classNames.sections}>
        <Stack horizontal tokens={{ childrenGap: '5px' }}>
          {totalPageCount >= 1 && (
            <DefaultButton
              key={1}
              onClick={() => onChangePage(1)}
              className={
                currentPage === 1
                  ? mergeStyles(classNames.pageButton, classNames.selected)
                  : mergeStyles(classNames.pageButton, classNames.unselected)
              }
            >
              {1}
            </DefaultButton>
          )}
          {totalPageCount >= 2 && (
            <DefaultButton
              key={currentPage > 5 ? 'shortcut' : '2'}
              onClick={() =>
                onChangePage(
                  currentPage > 5
                    ? currentPage === pagesToRender[pagesToRender.length - 1]
                      ? currentPage - 5
                      : currentPage - 4
                    : 2,
                )
              }
              className={
                currentPage > 5
                  ? mergeStyles(classNames.pageButton, classNames.unselected)
                  : currentPage === 2
                  ? mergeStyles(classNames.pageButton, classNames.selected)
                  : mergeStyles(classNames.pageButton, classNames.unselected)
              }
            >
              {currentPage > 5 ? '...' : 2}
            </DefaultButton>
          )}
          {pagesToRender.length && onRenderSection(pagesToRender[0], pagesToRender[pagesToRender.length - 1])}
        </Stack>
      </Stack>

      {currentPage < totalPageCount && (
        <IconButton
          className={classNames.chevron}
          disabled={currentPage === totalPageCount}
          iconProps={{ iconName: 'ChevronRightMed' }}
          ariaLabel={t('common.next-page-aria-label')}
          onClick={() => onChangePage(currentPage + 1)}
        ></IconButton>
      )}
    </Stack>
  );
};
