import { Token, CREATE_TENANT_TOKEN, TokenWithPermissions, REVOKE_TENANT_TOKEN } from '../../../../types/tokenList.d';
import { ActionSuccessHandler, ActionFailureHandler, ReduxCrudAction } from '../..';
import { createTenantToken, revokeTenantToken } from '../../../../../restful-apis/token.api';
import { getI18n } from 'react-i18next';

export const tokenCreateAction = (
  tenantId: string,
  token: TokenWithPermissions,
  onSuccess?: ActionSuccessHandler<Token>,
  onFailure?: ActionFailureHandler<Token>,
): ReduxCrudAction<Token> => {
  const i18n = getI18n();
  return {
    request: createTenantToken(tenantId, token),
    postActionMapper: createdToken => ({
      type: CREATE_TENANT_TOKEN,
      token: createdToken,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, token),
    notification: {
      inProgress: {
        title: i18n.t('tokens-list.notifications.token-is-being-created-msg', {
          tokenName: token.name,
        }),
        hideToast: true,
      },
      success: () => ({
        title: i18n.t('tokens-list.notifications.token-created-successfully-msg', {
          tokenName: token.name,
        }),
        hideToast: true,
      }),
      failure: () => ({
        title: i18n.t('tokens-list.notifications.token-creation-failed-msg', {
          tokenName: token.name,
        }),
      }),
    },
  };
};

export const tokenRevokeAction = (
  tenantId: string,
  token: Token,
  onSuccess?: ActionSuccessHandler<Token>,
  onFailure?: ActionFailureHandler<Token>,
): ReduxCrudAction<Token> => {
  const i18n = getI18n();
  return {
    request: revokeTenantToken(tenantId, token.id),
    postActionMapper: revokedToken => ({
      type: REVOKE_TENANT_TOKEN,
      tokenId: revokedToken.id,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, token.id),
    notification: {
      inProgress: {
        title: i18n.t('tokens-list.notifications.token-is-being-revoked-msg', {
          tokenName: token.name,
        }),
      },
      success: (revokedToken?: Token) => ({
        title: i18n.t('tokens-list.notifications.token-revoked-successfully-msg', {
          tokenName: revokedToken && revokedToken.name,
        }),
      }),
      failure: () => ({
        title: i18n.t('tokens-list.notifications.token-revoke-failed-msg', {
          tokenName: token.name,
        }),
      }),
    },
  };
};
