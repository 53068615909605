import { ToastNotificationStyleProps, ToastNotificationStyles } from './ToastNotification.types';
import { ToastNotificationStatus } from '../../store/types/toastNotification.d';
import { NotificationStatus } from '../../store/types/notificationCenter.d';

export const styles = (props: ToastNotificationStyleProps): ToastNotificationStyles => ({
  root: [
    'mbc-notification-item',
    {
      zIndex: 1000001,
      position: 'relative',
    },
    props.className,
  ],
  titleStackContainer: [
    'mbc-notification-title-stack',
    {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  ],
  titleContainer: [
    'mbc-notification-title-container',
    {
      display: 'flex',
      paddingLeft: 4,
    },
  ],
  title: [
    'mbc-notification-title',
    {
      paddingLeft: 8,
      color: props.theme.palette.neutralPrimary,
      fontSize: 13,
      width: 260,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  ],
  subtitle: [
    'mbc-notification-subtitle',
    {
      fontSize: 12,
      marginLeft: 28,
      width: 260,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  ],
  closeIcon: [
    'mbc-close-icon',
    {
      selectors: {
        i: {
          fontSize: 10,
          color: props.theme.palette.neutralPrimary,
        },
      },
    },
  ],
  toastContainer: [
    'mbc-toast-container',
    {
      width: '340px !important',
      top: '2.8em !important',
      right: '0 !important',
      selectors: {
        '.Toastify__toast': {
          boxShadow: '0px 6.4px 14.4px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px rgba(0, 0, 0, 0.1)',
          paddingBottom: 12,
          paddingRight: 8,
          paddingLeft: 8,
          paddingTop: 12,
          display: 'flex',
          flexDirection: 'column-reverse',
          background: props.theme.palette.neutralLighter,
          minHeight: 'unset',
        },
        '.ms-Button': {
          width: 20,
          height: 16,
        },
      },
    },
  ],
  statusIcon: [
    'mbc-status-icon',
    {
      color:
        props.notificationStatus === ToastNotificationStatus.Info
          ? props.theme.palette.neutralTertiary
          : props.notificationStatus === NotificationStatus.Succeeded
          ? props.theme.palette.greenLight
          : props.notificationStatus === NotificationStatus.Failed
          ? props.theme.palette.orangeLight
          : undefined,
    },
  ],
});
