import {
  ToastNotificationState,
  ToastNotificationActionTypes,
  SHOW_TOAST,
  REMOVE_TOAST,
  UPDATE_TOAST,
} from '../types/toastNotification.d';
import { NotificationStatus } from '../types/notificationCenter.d';

const initialState: ToastNotificationState = {
  toastNotificationContent: {
    id: '',
    title: '',
    status: NotificationStatus.UnSet,
    hasAction: false,
  },
};

export function toastNotificationReducer(
  state = initialState,
  action: ToastNotificationActionTypes,
): ToastNotificationState {
  switch (action.type) {
    case SHOW_TOAST:
    case UPDATE_TOAST: {
      return {
        ...state,
        toastNotificationContent: action.toastNotification,
      };
    }
    case REMOVE_TOAST: {
      return {
        ...state,
        toastNotificationContent:
          action.id === state.toastNotificationContent.id
            ? {
                id: '',
                title: '',
                status: NotificationStatus.UnSet,
              }
            : state.toastNotificationContent,
      };
    }
    default: {
      return state;
    }
  }
}
