import { SearchResults } from './dto/search/search';
import { ApiFacetOption, ApiFacet, DiscoveredFacets, Facet, FacetOption } from './dto/search/facet';
import { IndexField } from '../store/types/searchIndex';
import {
  createStringCondition,
  createBoolCondition,
  createDateTimeCondition,
  createNumericCondition,
  createConditionBlock,
  ConditionBlock,
  ConditionType,
} from '../utils/customizations/conditions';
import moment from 'moment';
import _ from 'lodash';

const DISCOVER_FACETS_NAME = 'discoverFacets';
const FILTER_NAME_PREFIX = 'filter-';
const LEVEL_SEPARATOR = '|';

export const exists = (x: any): boolean => x !== null && x !== undefined;

export const facetOptionAreEqual = (a: FacetOption, b: FacetOption): boolean => {
  if (a.field !== b.field) {
    return false;
  }

  if (exists(a.value) || exists(b.value)) {
    return a.value === b.value;
  }

  if (exists(a.ge) || exists(b.ge) || exists(a.lt) || exists(b.lt)) {
    return a.ge === b.ge && a.lt === b.lt;
  }

  return false;
};

const getRefinement = (
  refinement: ApiFacetOption,
  field: string,
  selectedRefinements: FacetOption[],
  parentValue?: string,
): FacetOption => {
  const value = parentValue ? parentValue + LEVEL_SEPARATOR + refinement.value : refinement.value;

  const facetOption: FacetOption = {
    field,
    value,
    ge: refinement.ge,
    lt: refinement.lt,
    label: refinement.label,
    count: refinement.estimatedCount,
    refinements: refinement.refinements
      ? refinement.refinements.map(x => getRefinement(x, field, selectedRefinements, value))
      : undefined,
  };

  facetOption.selected = selectedRefinements.some(x => facetOptionAreEqual(x, facetOption));

  if (facetOption.selected) {
    return facetOption;
  }

  return facetOption;
};

const getFacet = (apiFacet: ApiFacet, selectedRefinements: FacetOption[]): Facet => ({
  _type: apiFacet._type,
  field: apiFacet.field,
  label: apiFacet.label,
  refinements: apiFacet.refinements.map(x => getRefinement(x, apiFacet.field, selectedRefinements)),
});

const mergeRefinements = (a: Facet | FacetOption, b: Facet | FacetOption): Facet | FacetOption => {
  if (!a.refinements || !b.refinements) {
    return a;
  }

  for (const refinementB of b.refinements) {
    let found = false;

    for (const refinementA of a.refinements) {
      if (facetOptionAreEqual(refinementA, refinementB)) {
        refinementA.count = refinementB.count;
        mergeRefinements(refinementA, refinementB);
        found = true;
      }
    }

    if (!found) {
      a.refinements.push(refinementB);
    }
  }

  const facetA = a as Facet;
  const facetB = a as Facet;

  if (facetA._type === 'RangeFacet' && facetA._type === facetB._type) {
    a.refinements = a.refinements.sort((x, y) => {
      if (x.ge && y.ge) {
        return (x.ge as number) - (y.ge as number);
      } else {
        return y.count - x.count;
      }
    });
  } else {
    a.refinements = a.refinements.sort((x, y) => y.count - x.count);
  }

  return a;
};

export const getFacetsFromApiResponse = (response: SearchResults, selectedRefinements: FacetOption[]): Facet[] => {
  let facets = [] as any;
  let discoveredFacets: DiscoveredFacets = null as any;
  const individualFacets: { [field: string]: Facet } = {};

  if (response && response.aggregations) {
    for (const aggregation of response.aggregations) {
      if (aggregation.name === DISCOVER_FACETS_NAME || aggregation._type === 'DiscoveredFacets') {
        discoveredFacets = aggregation;
      } else if (
        aggregation.name &&
        aggregation.name.includes(FILTER_NAME_PREFIX) &&
        aggregation.aggregations &&
        aggregation.aggregations.length === 1 &&
        aggregation.aggregations[0]._type.includes('Facet')
      ) {
        const f = aggregation.aggregations[0];
        individualFacets[f.field] = getFacet(f, selectedRefinements);
      }
    }
  }

  if (discoveredFacets && discoveredFacets.aggregations) {
    facets = discoveredFacets.aggregations.map(x => getFacet(x, selectedRefinements));
  }

  const mergedFacets = [];

  for (const facet of facets) {
    if (individualFacets[facet.field]) {
      mergedFacets.push(mergeRefinements(facet, individualFacets[facet.field]));
    } else {
      mergedFacets.push(facet);
    }
  }

  return mergedFacets;
};

const getCategoryLevels = (categoryStr: string): string[] => categoryStr.split(LEVEL_SEPARATOR);

export const joinCategoryLevels = (...levels: string[]): string => levels.join(LEVEL_SEPARATOR);

export const getLeafCategory = (category: string): string => {
  const levels = getCategoryLevels(category);

  if (!levels.length) {
    return '';
  }

  return levels[levels.length - 1];
};

export const getParentRefinements = (category: string, root: Facet | FacetOption): FacetOption[] => {
  let currentNode = root;
  const parents = [];

  for (const level of getCategoryLevels(category)) {
    if (currentNode && currentNode.refinements) {
      const existingNodes = currentNode.refinements.filter(x => getLeafCategory(x.value as string) === level);
      const childNode = existingNodes.length ? existingNodes[0] : null;

      if (!childNode) {
        break;
      }

      parents.push(childNode);
      currentNode = childNode;
    }
  }

  return parents;
};

export const getParentCategory = (category: string): string => {
  const levels = getCategoryLevels(category);

  if (levels.length <= 1) {
    return '';
  }

  return levels.slice(0, levels.length - 1).join(LEVEL_SEPARATOR);
};

const groupRefinementsByField = (refinements: FacetOption[]): { [key: string]: [] } => {
  const refinementsByField = {} as any;

  for (const refinement of refinements) {
    if (refinement && refinement.field) {
      if (!(refinement.field in refinementsByField)) {
        refinementsByField[refinement.field] = [];
      }

      refinementsByField[refinement.field].push(refinement);
    }
  }

  return refinementsByField;
};

const buildFilterOptionBlock = (option: FacetOption): ConditionBlock => {
  const conditions: ConditionType[] = [];

  if (option.field) {
    if (!!option.value || _.isBoolean(option.value)) {
      typeof option.value === 'string'
        ? conditions.push(createStringCondition({ field: option.field, value: option.value as string }))
        : conditions.push(createBoolCondition({ field: option.field, value: option.value as boolean }));
    }

    if (!!option.ge) {
      isNaN(option.ge as number)
        ? conditions.push(createDateTimeCondition({ field: option.field, value: moment(option.ge as string) }))
        : conditions.push(createNumericCondition({ field: option.field, value: option.ge as number }));
    }

    if (!!option.lt) {
      isNaN(option.lt as number)
        ? conditions.push(
            createDateTimeCondition({
              field: option.field,
              value: moment(option.lt as string),
              operator: 'lt',
            }),
          )
        : conditions.push(
            createNumericCondition({
              field: option.field,
              value: option.lt as number,
              operator: 'lt',
            }),
          );
    }
  }
  return createConditionBlock({ conditions: conditions });
};

export const buildFilterObject = (selectedRefinements: FacetOption[]): ConditionBlock | undefined => {
  if (selectedRefinements && selectedRefinements.length) {
    const refinementsByField = groupRefinementsByField(selectedRefinements) as any;
    const allConditions: ConditionBlock[] = [];

    for (const field of Object.keys(refinementsByField)) {
      const refinements = refinementsByField[field];
      const fieldConditions = [];

      for (const option of refinements) {
        fieldConditions.push(buildFilterOptionBlock(option));
      }

      allConditions.push(
        createConditionBlock({
          conditions: fieldConditions,
          operator: 'or',
        }),
      );
    }

    return createConditionBlock({
      conditions: allConditions,
    });
  }

  return undefined;
};

function getFieldType(indexFields: IndexField[], fieldName: string): string {
  const field = indexFields.find(function(element: IndexField) {
    return element.name === fieldName;
  });

  if (field) {
    if (['Size', 'Price', 'Number'].includes(field.type)) return 'Number';
    if (field.type === 'Boolean') return 'Boolean';
    if (field.type === 'DateTime') return 'DateTime';
  }

  return 'String';
}

// TODO:: use typed search request entities instead of any
export function createAggregationFilters(indexFields: IndexField[], selectedRefinements: FacetOption[]): any[] {
  const refinementsByField = groupRefinementsByField(selectedRefinements) as any;
  const result: any[] = [];
  for (const refinement of selectedRefinements) {
    const field = refinement.field;
    if (field) {
      const fieldType = getFieldType(indexFields, field);

      if (fieldType === 'Category') {
        continue;
      }
    }

    const otherFields = selectedRefinements.filter(f => f.field !== refinement.field);
    const otherRefinements = [].concat(
      ...otherFields.map(function(field) {
        if (field.field) {
          return refinementsByField[field.field];
        }
      }),
    );

    const aggrigationFilter = buildFilterObject(otherRefinements);
    result.push({
      _type: 'Filter',
      name: FILTER_NAME_PREFIX + refinement.field,
      value: aggrigationFilter,
      aggregations: [
        {
          _type: 'Facet',
          field,
        },
      ],
    });
  }

  return result;
}
