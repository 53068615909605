import { HorizontalProductItemCard, VerticalProductItemCard } from '../../../../../components/common/ProductItemCard';
import React, { useCallback, useRef, useState } from 'react';
import {
  SearchResultCardsProps,
  SearchResultCardsStyleProps,
  SearchResultCardsStyles,
} from './SearchResultCards.types';
import { Stack, classNamesFunction, Modal } from 'office-ui-fabric-react';

import { ProductSearchResultItem } from '../../../../../store/types/productSearch.d';
import { SearchViewMode } from '../SearchPreview.types';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../../../../components/common/Pagination';
import { Banners } from '../Banners';
import { useResizeObserver } from '../../../../../store/hooks/useResizeObserver';
import { ProductPreview } from '../../../../EnvironmentList/Environment/ProductManagement/ProductPreview/ProductPreview';
import { PreviewMode } from '../../../../EnvironmentList/Environment/ProductManagement/ProductPreview/ProductPreview.types';

const getClassNames = classNamesFunction<SearchResultCardsStyleProps, SearchResultCardsStyles>();

export const SearchResultCardsBase = (props: SearchResultCardsProps): JSX.Element => {
  const { styles, theme, searchViewMode, sortBy, searchResult, paginationProps } = props;

  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width, left } = useResizeObserver(containerRef);

  const [activeProduct, setActiveProduct] = useState<ProductSearchResultItem>();

  const classNames = getClassNames(styles, {
    theme: theme!,
    width: width,
    left: left,
  });

  const displayedResultStartRange = searchResult.skip + 1;
  const totalSearachResultsCount = searchResult.totalEstimatedMatches;
  const displayedResultPerPageCount = searchResult.searchResultItems.length;
  const displayedResultEndRange = searchResult.skip + searchResult.searchResultItems.length;

  const listSearchResultItem = useCallback(
    (searchResultItem: ProductSearchResultItem) => (
      <HorizontalProductItemCard
        item={searchResultItem}
        key={searchResultItem.id}
        onClick={() => setActiveProduct(searchResultItem)}
        styles={classNames.subComponentStyles.listSearchItemCard}
      />
    ),
    [classNames.subComponentStyles.listSearchItemCard],
  );

  const gridSearchResultItem = useCallback(
    (searchResultItem: ProductSearchResultItem) => (
      <VerticalProductItemCard
        item={searchResultItem}
        key={searchResultItem.id}
        onClick={() => setActiveProduct(searchResultItem)}
      />
    ),
    [],
  );

  const isRedirectUrl = !!searchResult.redirectUrl;

  return (
    <Stack tokens={{ padding: 24 }} grow verticalAlign={'space-between'}>
      {isRedirectUrl && (
        <Stack grow tokens={{ childrenGap: 16 }}>
          <div className={classNames.redirectUrlHeader}>{t('query-tester.redirect-url')}</div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={searchResult.redirectUrl}
            className={classNames.redirectUrl}
          >
            {searchResult.redirectUrl}
          </a>
          <iframe className={classNames.iFrameContainer} src={searchResult.redirectUrl}></iframe>
        </Stack>
      )}
      {!isRedirectUrl && (
        <Stack grow>
          <div ref={containerRef} />
          <Banners banners={searchResult.banners} />
          <div className={classNames.header}>
            <div className={classNames.metadata}>
              {searchResult.alteredQuery || searchResult.query ? (
                <>
                  {t('query-tester.results-count-with-query', {
                    rangeStart: displayedResultStartRange,
                    rangeEnd: displayedResultEndRange,
                    total: totalSearachResultsCount,
                  })}
                  <span className={classNames.query}>{searchResult.alteredQuery || searchResult.query}</span>
                </>
              ) : (
                t('query-tester.results-count-without-query', {
                  rangeStart: displayedResultStartRange,
                  rangeEnd: displayedResultEndRange,
                  total: totalSearachResultsCount,
                })
              )}
            </div>
            <Stack horizontal className={classNames.bold} tokens={{ childrenGap: 8 }}>
              <div>
                {t('query-tester.search-info-page')}
                <span className={classNames.metadata}>{displayedResultPerPageCount}</span>
              </div>
              <div className={classNames.separator}> | </div>
              <div>
                {t('query-tester.search-info-sort')}
                <span className={classNames.metadata}>{sortBy || 'Relevance'}</span>
              </div>
            </Stack>
          </div>
          <Modal
            isBlocking={false}
            isOpen={!!activeProduct}
            styles={classNames.subComponentStyles.modal}
            onDismiss={() => setActiveProduct(undefined)}
          >
            <ProductPreview
              product={activeProduct}
              previewMode={PreviewMode.Read}
              onDismiss={() => setActiveProduct(undefined)}
            />
          </Modal>
          {searchViewMode === SearchViewMode.Grid && (
            <div className={classNames.cards}>{searchResult.searchResultItems.map(gridSearchResultItem)}</div>
          )}
          {searchViewMode === SearchViewMode.List && (
            <Stack style={{ margin: '0 16px 16px 16px' }} tokens={{ childrenGap: 12 }}>
              {searchResult.searchResultItems.map(listSearchResultItem)}
            </Stack>
          )}
        </Stack>
      )}
      {!isRedirectUrl && <Pagination {...paginationProps} />}
    </Stack>
  );
};
