import { Action } from 'redux';
import { NotificationStatus } from './notificationCenter';

export const SHOW_TOAST = 'SHOW_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const UPDATE_TOAST = 'UPDATE_TOAST';

export interface ToastNotificationState {
  toastNotificationContent: ToastNotification;
}

export interface ToastNotification {
  id: string;
  title: string;
  subtitle?: string;
  errorMessage?: string;
  status: NotificationStatus | ToastNotificationStatus;
  hasAction?: boolean;
}

export enum ToastNotificationStatus {
  Info = 'Info',
}

export interface ShowToast extends Action<typeof SHOW_TOAST> {
  toastNotification: ToastNotification;
}

export interface UpdateToast extends Action<typeof UPDATE_TOAST> {
  toastNotification: ToastNotification;
}

export interface RemoveToast extends Action<typeof REMOVE_TOAST> {
  id: string;
}

export type ToastNotificationActionTypes = ShowToast | UpdateToast | RemoveToast;
