import { TrialEnvironmentModalStyleProps, TrialEnvironmentModalStyles } from './TrialEnvironmentModal.types';

export const styles = (props: TrialEnvironmentModalStyleProps): TrialEnvironmentModalStyles => {
  return {
    root: [
      'trial-environment-modal',
      {
        backgroundColor: props.theme.palette.neutralLighter,
        minWidth: '40vw',
        minHeight: '35vh',
        padding: 20,
        boxShadow: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
        borderRadius: 2,
      },
    ],
    title: [
      'trial-environment-modal-title',
      {
        fontSize: 44,
        lineHeight: 52,
        display: 'flex',
        alignItems: 'center',
        color: '#272729',
      },
    ],
    paragraph: [
      'trial-environment-modal-paragraph',
      {
        fontSize: 14,
        lineHeight: 22,
        textAlign: 'center',
        maxWidth: 400,
        color: '#333333',
      },
    ],
  };
};
