import React, { useState } from 'react';
import { TreeListViewProps, TreeListViewStyleProps, TreeListViewStyles, TreeItemViewProps } from './TreeView.types';
import { classNamesFunction, Stack, IconButton, Separator } from 'office-ui-fabric-react';
import { ListItem } from './ListItem';

const getClassNames = classNamesFunction<TreeListViewStyleProps, TreeListViewStyles>();

export const TreeItemViewBase = (props: TreeItemViewProps) => {
  const { header, item, className, theme, componentRef, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div className={classNames.root} ref={componentRef}>
      <Stack>
        <Stack horizontal verticalAlign={'center'} gap={9}>
          <IconButton
            iconProps={{ iconName: item.header.iconName }}
            onClick={item.header.onIconClick}
            disabled={!!item.header.disabled}
            className={classNames.headerChevron}
          />
          <span className={classNames.listHeader}>{header}</span>
        </Stack>
        <ListItem
          item={{
            header: {
              title: item.header.title,
              width: item.header.width,
            },
            subHeader1: item.subHeader1,
            subHeader2: item.subHeader2,
          }}
          isLastItem
        />
      </Stack>
    </div>
  );
};

export const TreeListViewBase = (props: TreeListViewProps) => {
  const { header, disableCount, items, className, theme, componentRef, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={classNames.root} ref={componentRef}>
      <Stack>
        <Stack horizontal verticalAlign={'center'} gap={9}>
          <IconButton
            iconProps={{ iconName: isExpanded ? 'ChevronDownMed' : 'ChevronRightMed' }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            className={classNames.headerChevron}
          />
          <span className={classNames.listHeader}>{`${header}${!!disableCount ? '' : ' (' + items.length + ')'}`}</span>
        </Stack>
        {isExpanded && (
          <>
            {items.length > 1 && (
              <Stack horizontal className={classNames.listItemContainer}>
                <Separator vertical className={classNames.verticalSeparator} />
                <Stack>
                  {items.map((item, index) => {
                    if (index < items.length - 1) return <ListItem key={index} item={item} />;
                  })}
                </Stack>
              </Stack>
            )}
            {!!items.length && <ListItem item={items[items.length - 1]} isLastItem />}
          </>
        )}
      </Stack>
    </div>
  );
};
