import React from 'react';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { Tenant } from '../../../../store/types/tenantsList.d';
import { Environment } from '../../../../store/types/environmentList.d';
import { OverviewBaseProps, OverviewStyles, OverviewStyleProps } from './Overview.types';
import { classNamesFunction, Stack, DefaultButton, Icon } from 'office-ui-fabric-react';
import { Role } from '../../../../store/types/roleList.d';
import { RoleList } from './RoleList';
import { useEnvironmentRoleList } from '../../../../store/hooks/use-list/useEnvironmentRoleList';
import { updateAssignedRoleList } from '../../../../store/actions/environmentWithRoleListActions';
import { useTranslation } from 'react-i18next';
import { useCurrentServices } from '../../../../store/hooks/use-current-services/useCurrentServices';
import { LinkIconSize } from '../../../../store/hooks/use-current-services/useCurrentServices.types';
import { getRegionLocaleMapper } from '../../../../config/regions.config';

const getClassNames = classNamesFunction<OverviewStyleProps, OverviewStyles>();

export const OverviewBase = (props: OverviewBaseProps) => {
  const { styles, theme } = props;

  const { t } = useTranslation();

  const classNames = getClassNames(styles, { theme: theme! });

  const dispatch = useDispatch();
  const environmentRoleList = useEnvironmentRoleList();
  const isLoading = useSelector<boolean>(state => state.roleList.isLoading);
  const tenant = useSelector<Tenant>(state => state.tenantsList.activeTenant);
  const environment = useSelector<Environment>(state => state.environmentList.activeEnvironment);

  const productShortcutList = useCurrentServices(LinkIconSize.Large)();

  const regionLocaleMapper = getRegionLocaleMapper(t);

  return (
    <Stack tokens={{ childrenGap: 32, padding: 8 }}>
      <Stack tokens={{ childrenGap: 21 }}>
        <div className={classNames.title}>{t('overview.services-title')}</div>
        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 21 }}>
          {productShortcutList
            .filter(p => environment.products.includes(p.product))
            .map(p => (
              <DefaultButton
                key={p.product}
                disabled={p.disabled}
                className={classNames.serviceButton}
                onClick={p.onClick}
              >
                <Stack
                  key={p.product}
                  horizontalAlign={'center'}
                  tokens={{ childrenGap: 8 }}
                  verticalAlign={'space-between'}
                  className={p.disabled ? classNames.disabled : ''}
                >
                  <Icon iconName={p.icon} />
                  <div className={classNames.serviceLabel}>{p.text}</div>
                </Stack>
              </DefaultButton>
            ))}
        </Stack>
      </Stack>

      <div className={classNames.sectionSeparator} />

      <Stack tokens={{ childrenGap: 21 }}>
        <div className={classNames.title}>{t('overview.description')}</div>
        <div className={classNames.descriptionLabel}>{environment.description}</div>
      </Stack>

      <div className={classNames.sectionSeparator} />

      <Stack tokens={{ childrenGap: 21 }}>
        <div className={classNames.title}>{t('overview.regions')}</div>
        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 12 }}>
          {environment.regions.map(region => (
            <div key={region} className={classNames.regionsTag}>
              {regionLocaleMapper[region]}
            </div>
          ))}
        </Stack>
      </Stack>

      <div className={classNames.sectionSeparator} />

      <RoleList
        isLoading={isLoading}
        items={environmentRoleList}
        onRolesChanged={(selectedRoles: Role[]) =>
          dispatch(updateAssignedRoleList(tenant.id, { ...environment, roleIdList: selectedRoles.map(r => r.id) }))
        }
      />
    </Stack>
  );
};
