import { Action } from 'redux';

export const SET_LOGGED_IN_STATUS = 'SET_LOGGED_IN_STATUS';

export interface AuthenticationState {
  isLoggedIn: boolean;
}

interface SetLoggedInStatus extends Action<typeof SET_LOGGED_IN_STATUS> {
  isLoggedIn: boolean;
}

export type AuthenticationStateActionTypes = SetLoggedInStatus;
