import { DashboardChartCardStyleProps, DashboardChartCardStyles } from './DashboardChartCard.types';
import { getFlexColumnStyles } from '../../../../../../components/common/Styles';

export const styles = (props: DashboardChartCardStyleProps): DashboardChartCardStyles => ({
  root: [
    'ms-dashboard-chart-card',
    getFlexColumnStyles(),
    {
      selectors: {
        '.mbc-card-title': {
          fontWeight: 600,
          color: props.theme.palette.black,
        },
        '.mbc-content-card': {
          minHeight: '320px',
        },
        '.mbc-content-card-header': {
          padding: '4px 12px',
        },
        '.mbc-content-card-body': {
          padding: '0',
        },
        '.mbc-card-footer': {
          padding: '0 24px',
          justifyContent: 'flex-start',
        },
        '.mbc-Dropdown-container': {
          paddingRight: '0',
        },
      },
    },
    props.className,
  ],
  cardSubtitle: [
    'mbc-card-subtitle',
    {
      fontSize: 10,
      lineHeight: 22,
      fontStyle: 'italic',
      fontWeight: 'normal',
      color: props.theme.palette.neutralSecondary,
    },
  ],
  refreshButton: [
    'mbc-card-refresh-button',
    {
      paddingLeft: 12,
      paddingTop: 12,
      selectors: {
        '.ms-Button': {
          width: 24,
          height: 24,
          selectors: {
            ':hover': {
              backgroundColor: 'unset',
            },
            '.ms-Icon': {
              fontSize: 12,
              marginLeft: 0,
              marginRight: 0,
            },
          },
        },
      },
    },
  ],
  refreshText: [
    'mbc-card-refresh-text',
    {
      fontSize: 10,
    },
  ],
  subComponentStyles: {
    card: {},
    optionsCallout: {
      subComponentStyles: {
        callout: {
          root: {
            width: 142,
          },
        },
      },
    },
  },
});
