import { Action } from 'redux';

export const ADD_INDEX_COMMIT = 'ADD_INDEX_COMMIT';
export const SET_INDEX_COMMITS = 'SET_INDEX_COMMITS';
export const SET_INDEX_DEPLOYMENTS = 'SET_INDEX_DEPLOYMENTS';
export const RESET_INDEX_COMMITS_DEPLOYMENTS = 'RESET_INDEX_COMMITS_DEPLOYMENTS';

export interface DevOpsCommitMetadata {
  author: string;
  message: string;
}

export interface Commit {
  commitId: string;
  author: string;
  message: string;
  createdDateTime: string;
}

export enum DeploymentStatus {
  Queued = 'Queued',
  InProgress = 'InProgress',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
}

export interface DeploymentDetails {
  service: string;
  path: string;
  status: DeploymentStatus;
}
export interface Deployment {
  deploymentId: string;
  status: DeploymentStatus;
  source: string;
  author: string;
  commitId: string;
  startTime: string;
  endTime: string;
  isActive: boolean;
  details: DeploymentDetails[];
}

export interface DevopsState {
  commits: commit[];
  deployments: Deployment[];
}

interface SetIndexCommits extends Action<typeof SET_INDEX_COMMITS> {
  commits: Commit[];
}

interface AddIndexCommit extends Action<typeof ADD_INDEX_COMMIT> {
  commit: Commit;
}

interface SetIndexDeployments extends Action<typeof SET_INDEX_DEPLOYMENTS> {
  deployments: Deployment[];
}

export type DevopsActionTypes =
  | AddIndexCommit
  | SetIndexCommits
  | SetIndexDeployments
  | Action<typeof RESET_INDEX_COMMITS_DEPLOYMENTS>;
