import {
  IContextualMenuItem,
  IColumn,
  Persona,
  PersonaSize,
  Stack,
  TooltipHost,
  DirectionalHint,
  SelectionMode,
  CheckboxVisibility,
  TooltipOverflowMode,
  classNamesFunction,
  Link,
  Modal,
} from 'office-ui-fabric-react';
import React, { ReactNode, useState, useCallback } from 'react';
import { AppState } from '../../store/reducers';
import { deleteRole, setEditorMode } from '../../store/actions/roleListActions';
import { Role, EditorMode } from '../../store/types/roleList.d';
import { createEmptyRole } from '../../store/reducers/roleListReducer';
import { ConfirmationDialog as DeletionDialog } from '../../components/common/Dialog/ConfirmationDialog';
import { TenantPermissionContext, TenantPermissionContextType } from '../../contexts';
import { Permission } from '../../config/userPermissions.config';
import { useSelector, useDispatch } from '../../store/hooks';
import {
  SortDataType,
  ColumnPriority,
  ColumnWithPriority,
} from '../../components/common/DetailsList/DetailsList.types';
import { DetailsListCard } from '../../components/common/DetailsListCard/DetailsListCard';
import moment from 'moment';
import { RoleListProps, RoleListStyleProps, RoleListStyles } from './RoleList.types';
import { MbcRoleRouter } from '../../router/MbcAdminRouter/MbcAdminRouter';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { UserPersona, PersonaType } from '../common/UserPersona';
import { useRoleList } from '../../store/hooks/use-list/useRoleList';
import { useEnvironmentList } from '../../store/hooks/use-list/useEnvironmentList';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { RoleCloneEditor } from './RoleCloneEditor';
import { ListItemsOrder } from '../../utils';

const getClassNames = classNamesFunction<RoleListStyleProps, RoleListStyles>();

export const RoleListBase = (props: RoleListProps) => {
  const { t, isPanel, onSelectionChanged, selectedItems, styles, theme, className } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const activeTenant = useSelector((state: AppState) => state.tenantsList.activeTenant);
  const isRoleListLoading = useSelector((state: AppState) => state.roleList.isLoading);
  const userRoleList = useRoleList();
  const environmentListCount = useEnvironmentList().length;
  const isEnvironmentListLoading = useSelector((state: AppState) => state.environmentList.isLoading);
  const currentUserPrimaryEmail = useSelector((state: AppState) => state.user.email);
  const currentUserSecondaryEmail = useSelector((state: AppState) => state.user.secondaryEmail);

  const [editableRole, setEditableRole] = useState<Role>(createEmptyRole());
  const [showRoleDeletionDialog, setShowRoleDeletionDialog] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const adminRouteMap = useAdminRouter();

  const dispatch = useDispatch();

  const onMenuItemRemoveInvoked = (
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem,
  ) => {
    if (item) {
      setShowRoleDeletionDialog(true);
      setEditableRole(item.data);
    }
  };

  const onCancelRoleDeletion = () => {
    setShowRoleDeletionDialog(false);
    setEditableRole(createEmptyRole());
  };

  const onConfirmRoleDeletion = () => {
    dispatch(deleteRole(activeTenant.id, editableRole.id));

    onCancelRoleDeletion();
  };

  const getRoleMembers = useCallback(
    (item?: any) => {
      let usersInternal = item.users.slice(0, 4);
      let usersPersona: JSX.Element[] = [];

      usersPersona = usersInternal.map((roleUser: any) => {
        return (
          <TooltipHost directionalHint={DirectionalHint.bottomCenter} key={roleUser.email} content={roleUser.email}>
            <UserPersona
              user={{ email: roleUser.email }}
              personaType={PersonaType.HidePersonaDetails}
              key={roleUser.id}
              ariaLabel={roleUser.email}
            />
          </TooltipHost>
        );
      });
      return item.users.length > 4
        ? [
            ...usersPersona,
            <Persona
              key={'remaining'}
              size={PersonaSize.size28}
              imageInitials={`+${item.users.length - 4}`}
              imageUrl={''}
              className={classNames.countPersona}
            />,
          ]
        : usersPersona;
    },
    [classNames.countPersona],
  );

  const onRenderItemColumn = useCallback(
    (item?: any, index?: number, column?: IColumn): ReactNode => {
      if (item && column) {
        switch (column.key) {
          case 'name':
            return (
              <Stack
                verticalAlign={'space-between'}
                tokens={{ childrenGap: 4 }}
                className={classNames.columnWithSubText}
              >
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.nameColumnCell}
                  style={{ maxWidth: column.maxWidth }}
                  content={item.name}
                >
                  <Link
                    onClick={() => {
                      dispatch(setEditorMode(EditorMode.View));
                      MbcRoleRouter(activeTenant, item)(MbcRouteKey.Role).browserPush();
                    }}
                    style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
                  >
                    {item.name}
                  </Link>
                </TooltipHost>
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.subText}
                  styles={{ root: { maxWidth: column.maxWidth } }}
                  content={item.description}
                >
                  {item.description}
                </TooltipHost>
              </Stack>
            );
          case 'members':
            return (
              <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 6 }} horizontalAlign={'end'}>
                {getRoleMembers(item)}
              </Stack>
            );
          default:
            return item[column.fieldName as string] as string;
        }
      }
    },
    [
      activeTenant,
      classNames.columnWithSubText,
      classNames.nameColumnCell,
      classNames.subText,
      dispatch,
      getRoleMembers,
    ],
  );

  const getDisplayedItems = useCallback(
    (userRoleList: Role[]) => {
      return userRoleList
        .sort((item1, item2) => (new Date(item1.updatedAt) > new Date(item2.updatedAt) ? -1 : 1))
        .map(role => {
          return {
            ...role,
            key: role.id,
            createdAt: moment
              .parseZone(role.createdAt as string)
              .local()
              .format('YYYY-MM-DD'),
            modifiedAt: moment
              .parseZone(role.updatedAt as string)
              .local()
              .format('YYYY-MM-DD'),
            membersCount: role.users.length,
            environmentListCount: role.hasAccessToAllEnvironments ? environmentListCount : role.environmentList.length,
          };
        });
    },
    [environmentListCount],
  );

  const getColumns = (): ColumnWithPriority[] => {
    return isPanel
      ? [
          {
            key: 'name',
            name: t('roles-list.th-name'),
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 300,
            sortDataType: SortDataType.text,
            priority: ColumnPriority.Primary,
            subTextField: 'description',
          },
          {
            key: 'members',
            name: t('roles-list.th-members'),
            fieldName: 'membersCount',
            minWidth: 200,
            maxWidth: 300,
            isMultiline: false,
            priority: ColumnPriority.Primary,
            onRender: onRenderItemColumn,
            headerClassName: classNames.centeredColumns,
          },
        ]
      : [
          {
            key: 'name',
            name: t('roles-list.th-name'),
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 300,
            sortDataType: SortDataType.text,
            priority: ColumnPriority.Primary,
            subTextField: 'description',
            onRender: onRenderItemColumn,
          },
          {
            key: 'createdAt',
            name: t('roles-list.th-created'),
            fieldName: 'createdAt',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: false,
            centerAligned: true,
            priority: ColumnPriority.Tertiary,
            sortDataType: SortDataType.dateTime,
          },
          {
            key: 'CreatedBy',
            name: t('roles-list.th-created-by'),
            fieldName: 'createdBy',
            minWidth: 100,
            maxWidth: 200,
            isMultiline: false,
            priority: ColumnPriority.Tertiary,
          },
          {
            key: 'ModifiedAt',
            name: t('roles-list.th-modified-at'),
            fieldName: 'modifiedAt',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: false,
            centerAligned: true,
            priority: ColumnPriority.Tertiary,
          },
          {
            key: 'environmentListCount',
            name: t('roles-list.th-instances-count'),
            fieldName: 'environmentListCount',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            centerAligned: true,
            priority: ColumnPriority.Secondary,
          },
          {
            key: 'members',
            name: t('roles-list.th-members'),
            fieldName: 'membersCount',
            minWidth: 200,
            maxWidth: 300,
            isMultiline: false,
            priority: ColumnPriority.Primary,
            onRender: onRenderItemColumn,
            headerClassName: classNames.centeredColumns,
          },
        ];
  };

  return (
    <>
      <Modal onDismiss={() => setShowCloneModal(false)} isOpen={showCloneModal}>
        <RoleCloneEditor onDismiss={() => setShowCloneModal(false)} cloneRole={editableRole} />
      </Modal>
      <TenantPermissionContext.Consumer>
        {(props: TenantPermissionContextType) => {
          const isAuthorized = props.isAuthorized(Permission.AdminRoleManagementReadWrite);
          return (
            <>
              <DeletionDialog
                key={editableRole.id}
                isOpen={showRoleDeletionDialog}
                title={t('roles-list.deletion-title')}
                subText={`${t('roles-list.deletion-subtext')} ${editableRole.name}?`}
                onConfirm={onConfirmRoleDeletion}
                onDismiss={onCancelRoleDeletion}
              ></DeletionDialog>

              <DetailsListCard
                searchActionProps={{ searchBy: ['name', 'description'] }}
                header={{
                  actions: {
                    add: {
                      disabled: !isAuthorized,
                      onClick: () => {
                        dispatch(setEditorMode(EditorMode.Create));
                        adminRouteMap(MbcRouteKey.RoleEditor).browserPush();
                      },
                    },
                    filter: {
                      filterableFields: [
                        {
                          fieldName: 'users',
                          displayName: t('roles-list.th-members'),
                          compare: (item: Role, selectedUsers: string[]) =>
                            !!item.users.find(user =>
                              selectedUsers.includes(
                                user.email === currentUserPrimaryEmail || user.email === currentUserSecondaryEmail
                                  ? 'Me'
                                  : user.email,
                              ),
                            ),
                          optionsExtractionFunction: (items: Role[]) => {
                            let userEmails = new Set<string>();
                            if (isAuthorized) userEmails.add('Me');
                            items.forEach(role =>
                              role.users.forEach(user => {
                                if (user.email !== currentUserPrimaryEmail && user.email !== currentUserSecondaryEmail)
                                  userEmails.add(user.email);
                              }),
                            );
                            return Array.from(userEmails);
                          },
                        },
                      ],
                    },
                  },
                }}
                listProps={{
                  isLoading: isRoleListLoading || isEnvironmentListLoading,
                  items: getDisplayedItems(userRoleList),
                  onRenderNoItemsToDisplay: {
                    text: t('roles-list.no-data-text'),
                    subText: t('roles-list.no-data-sub-text'),
                    actionProps: {
                      buttonText: t('common.no-data-button-text'),
                      disabled: !isAuthorized,
                      onClick: () => {
                        adminRouteMap(MbcRouteKey.RoleEditor).browserPush();
                      },
                    },
                  },
                  selectionProps: isPanel
                    ? {
                        defaultSelected: !!selectedItems ? [...selectedItems] : [],
                        selectionMode: SelectionMode.multiple,
                        checkboxVisibility: CheckboxVisibility.always,
                        onSelectionChanged: (selection: string[]) => {
                          !!onSelectionChanged &&
                            onSelectionChanged(userRoleList.filter(role => selection.includes(role.id)));
                        },
                      }
                    : {
                        selectionMode: SelectionMode.none,
                        checkboxVisibility: CheckboxVisibility.hidden,
                        onSelectionChanged: () => {},
                      },
                  optionsItemProps: isPanel
                    ? undefined
                    : (item: Role) => [
                        {
                          key: 'edit',
                          text: t('roles-list.edit-menu'),
                          iconProps: { iconName: 'Edit' },
                          data: item,
                          disabled: !isAuthorized,
                          onClick: () => {
                            dispatch(setEditorMode(EditorMode.Edit));
                            MbcRoleRouter(activeTenant, item)(MbcRouteKey.Role).browserPush();
                          },
                        },
                        {
                          key: 'clone',
                          text: t('roles-list.clone-menu'),
                          iconProps: { iconName: 'Copy' },
                          data: item,
                          disabled: !isAuthorized,
                          onClick: () => {
                            setEditableRole({ ...item, createdAt: '', createdBy: '', id: '', updatedAt: '' });
                            setShowCloneModal(true);
                          },
                        },
                        {
                          key: 'remove',
                          text: t('roles-list.remove-menu'),
                          iconProps: { iconName: 'Delete' },
                          data: item,
                          disabled: !isAuthorized,
                          onClick: onMenuItemRemoveInvoked,
                        },
                      ],
                  responsiveColumns: {
                    columns: getColumns(),
                    initialSortingDetails: isPanel
                      ? {
                          fieldName: 'name',
                          dataType: SortDataType.text,
                        }
                      : {
                          fieldName: 'createdAt',
                          dataType: SortDataType.dateTime,
                          orderBy: ListItemsOrder.DESCENDING,
                        },
                    secondaryBreakPoint: 760,
                    teritaryBreakPoint: 1100,
                  },
                }}
              />
            </>
          );
        }}
      </TenantPermissionContext.Consumer>
    </>
  );
};
