import { CustomPanelStyleProps, CustomPanelStyles } from './CustomPanel.types';

export const styles = (props: CustomPanelStyleProps): CustomPanelStyles => {
  return {
    root: ['mbc-product-support-panel'],
    panelHeaderContainer: [
      'mbc-header-container',
      {
        width: '100%',
        alignItems: 'center',
        height: 85,
        selectors: { '.ms-Button-flexContainer': { height: 36 } },
      },
    ],
    panelTitle: [
      'mbc-panel-title',
      {
        fontWeight: 600,
        paddingLeft: 16,
        fontSize: 20,
        lineHeight: 28,
      },
    ],
    panelContent: [
      {
        selectors: {
          '.ms-Panel-scrollableContent': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
          '.ms-Panel-content': {
            flex: 1,
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
      props.className,
    ],
  };
};
