import { ContactUsStyleProps, ContactUsStyles } from './ContactUs.types';

export const styles = (props: ContactUsStyleProps): ContactUsStyles => {
  return {
    root: [
      'mbc-contact-us',
      {
        display: 'flex',
        flexDirection: 'column',
        selectors: {
          '@media only screen and (min-width: 850px)': {
            minWidth: 512,
          },
        },
      },
      props.className,
    ],
    title: [
      'mbc-contact-us-title',
      {
        lineHeight: 28,
        display: 'flex',
        alignItems: 'center',
        color: props.theme.palette.black,
        selectors: {
          '@media only screen and (min-width: 850px)': {
            padding: '18px 144px 34px 24px',
            fontSize: 20,
          },
          '@media only screen and (max-width: 849px)': {
            padding: '10px 20px',
            fontSize: 18,
            fontWeight: 500,
            justifyContent: 'center',
          },
        },
      },
    ],
    body: [
      'mbc-contact-us-body',
      {
        padding: 24,
        boxShadow:
          '0px 0.3px 0.9px ' +
          props.theme.palette.neutralLight +
          ', 0px 1.6px 3.6px ' +
          props.theme.palette.neutralQuaternaryAlt,
        borderRadius: '0px 0px 4px 4px',
      },
    ],
    privacyLink: [
      'mbc-contact-us-privacy-link',
      {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: 32,
        textDecoration: 'none',
        color: props.theme.palette.themePrimary,
      },
    ],
    sendBtn: [
      'mbc-contact-us-send-btn',
      {
        fontSize: 13,
        lineHeight: 17,
        textAlign: 'center',
        borderRadius: 4,
      },
    ],
    cancelBtn: [
      'mbc-contact-us-cancel-btn',
      {
        marginLeft: 8,
        background: props.theme.palette.white,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: props.theme.palette.themeLight,
        boxSizing: 'border-box',
        borderRadius: 2,
        color: props.theme.palette.themePrimary,
      },
    ],
  };
};
