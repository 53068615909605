import React, { useState, useCallback, useEffect } from 'react';
import { Stack, classNamesFunction, Panel, IconButton } from 'office-ui-fabric-react';
import { HoverableSideNavStyles, HoverableSideNavStyleProps, HoverableSideNavProps } from './HoverableSideNav.types';
import { MbcSideNav } from '../MbcSideNav';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../store/hooks';
import { AvailableTutorials } from '../../../store/types/tutorial.d';

const getClassNames = classNamesFunction<HoverableSideNavStyleProps, HoverableSideNavStyles>();

export const HoverableSideNavBase = (props: HoverableSideNavProps) => {
  const { styles, theme, top, panelType } = props;

  const classNames = getClassNames(styles, { theme: theme!, top: top });

  const { t } = useTranslation();

  const currentTutorial = useSelector(state => state.tutorial.currentTutorial);
  const currentTutorialStep = useSelector(state => state.tutorial.currentStep);

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  // Override navigation default rendering
  const overrideRendering = useCallback(() => null, []);

  const onUpdatePanel = useCallback(() => setIsPanelOpen(state => !state), []);

  const onRenderBody = useCallback(() => <MbcSideNav collapsed={false} onClick={onUpdatePanel} />, [onUpdatePanel]);

  useEffect(() => {
    if (
      currentTutorial === AvailableTutorials.QueryTester ||
      currentTutorial === AvailableTutorials.SynonymsFull ||
      currentTutorial === AvailableTutorials.BusinessRulesFull
    ) {
      if (currentTutorialStep === 1) {
        setIsPanelOpen(true);
      } else if (isPanelOpen) {
        setIsPanelOpen(false);
      }
    } else if (isPanelOpen) {
      setIsPanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTutorial, currentTutorialStep]);

  return (
    <Stack>
      <IconButton
        ariaLabel={t('side-nav.global-btn-aria-label')}
        iconProps={{ iconName: 'GlobalNavButton' }}
        onClick={onUpdatePanel}
      />
      <Panel
        isLightDismiss
        type={panelType}
        isOpen={isPanelOpen}
        onRenderBody={onRenderBody}
        onRenderHeader={overrideRendering}
        onLightDismissClick={onUpdatePanel}
        onRenderNavigation={overrideRendering}
        styles={classNames.subComponentStyles.panel}
      ></Panel>
    </Stack>
  );
};
