import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../../store/hooks';
import {
  setActiveEvaluationJob,
  resetActiveEvaluationJob,
} from '../../../../../store/actions/customML/evaluationJobActions';
import { ActiveEvaluationJobLoading } from '../../../../../components/Loading';
import { useLazyBreadcrumb } from '../../../../../store/hooks/use-breadcrumb/useLazyBreadcrumb';
import { MbcRouter } from '../../../../../router/MbcRouter.base';
import { useCustomMLEvaluationRouter } from '../../../../../store/hooks/use-router/useCustomMLRouter';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';
import { useActiveEvaluationJobAsserter } from '../../../../../store/hooks/use-asserter/useAsserter';

export const EvaluateSearchModelProxy = (props: RouteComponentProps<{ evaluationKey: string }>) => {
  const dispatch = useDispatch();
  const MbcCustomMLEvaluationRouteMap = useCustomMLEvaluationRouter();

  const isEvaluationJobListLoading = useSelector(state => state.evaluationJobs.isLoading);
  const activeEvaluationJob = useSelector(state => state.evaluationJobs.activeEvaluationJob);

  useActiveEvaluationJobAsserter(props.match.params.evaluationKey);

  useLazyBreadcrumb(
    props.match.params.evaluationKey,
    props.match.url,
    activeEvaluationJob.name,
    !isEvaluationJobListLoading && activeEvaluationJob.name !== '',
  );

  useEffect(() => {
    dispatch(setActiveEvaluationJob(props.match.params.evaluationKey));
    return () => {
      dispatch(resetActiveEvaluationJob());
    };
  }, [dispatch, props.match.params.evaluationKey, props.match.url, isEvaluationJobListLoading]);

  return (
    <ActiveEvaluationJobLoading>
      <MbcRouter routes={[MbcCustomMLEvaluationRouteMap(MbcRouteKey.EvaluationJob)]} />
    </ActiveEvaluationJobLoading>
  );
};
