import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { Notification } from '../../types/notificationCenter';
import { FetchResponse } from '../../../restful-apis/http-client/http-interceptors/Types';

export enum PollStatus {
  Failed,
  Pending,
  Succeeded,
}

/**
 * Exporting ThunkDispatch using defined AppState
 * Map created/updated/deleted resource to coresponding redux action
 */
export type PollDispatch = ThunkDispatch<AppState, null, AnyAction>;
export type PollActionMapper<T> = (model: T) => AnyAction | AnyAction[];

/**
 * Exposed only to components extending Action on success/failure handlers
 */
export type PollActionSuccessHandler<T> = (model: T) => void;
export type PollActionFailureHandler<R> = (exception: FetchResponse | R | string) => void;

/**
 * Exposed to Redux actions, ThunkDispatch shouldn't be exposed to components.
 */
export type PollSuccessHandler<T> = (model: T, dispatch: PollDispatch) => void;
export type PollFailureHandler<R> = (exception: FetchResponse | R, dispatch: PollDispatch) => void;

export interface ReduxPollAction<T, R> {
  request: Promise<T>;
  pollRequest: (model: T) => Promise<R>;
  pollStatus: (response: R) => PollStatus;
  errorMessage?: (response: R | FetchResponse) => string;

  notification: Notification<T>;

  preActionMapper?: AnyAction | AnyAction[];
  postActionMapper?: PollActionMapper<T>;

  onFailure?: PollFailureHandler<R>;
  onSuccess?: PollSuccessHandler<T>;
}
