import {
  ProductSearchState,
  ProductSearchStateActionTypes,
  SET_PRODUCT_SEARCH_RESULTS,
  LOADING_PRODUCT_SEARCH_RESULTS,
  SET_PRODUCT_SEARCH_EDITABLE,
  SET_PRODUCT_SEARCH_NON_EDITABLE,
  UPDATE_PRODUCT_SEARCH_RESULTS,
  RESET_PRODUCT_SEARCH_RESULTS,
} from '../types/productSearch.d';

const initialState: ProductSearchState = {
  query: '',
  isLoading: true,
  alteredQuery: '',
  totalEstimatedMatches: 0,
  productSearchResults: [],
  nonEditableProductSet: new Set(),
};

export function productSearchReducer(state = initialState, action: ProductSearchStateActionTypes): ProductSearchState {
  switch (action.type) {
    case SET_PRODUCT_SEARCH_NON_EDITABLE: {
      return {
        ...state,
        nonEditableProductSet: new Set(state.nonEditableProductSet.add(action.productId)),
      };
    }
    case SET_PRODUCT_SEARCH_EDITABLE: {
      const updatedNonEditableProductSet = new Set(state.nonEditableProductSet);
      updatedNonEditableProductSet.delete(action.productId);
      return {
        ...state,
        nonEditableProductSet: updatedNonEditableProductSet,
      };
    }
    case LOADING_PRODUCT_SEARCH_RESULTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_PRODUCT_SEARCH_RESULTS: {
      const updatedProductSearchResultItems = [...state.productSearchResults];
      updatedProductSearchResultItems.splice(
        action.productSearchResult.skip,
        action.productSearchResult.searchResultItems.length,
        ...action.productSearchResult.searchResultItems,
      );
      return {
        ...state,
        productSearchResults: updatedProductSearchResultItems,
      };
    }
    case SET_PRODUCT_SEARCH_RESULTS: {
      return {
        ...state,
        isLoading: false,
        query: action.productSearchResult.query,
        alteredQuery: action.productSearchResult.alteredQuery,
        productSearchResults: action.productSearchResult.searchResultItems,
        totalEstimatedMatches: action.productSearchResult.totalEstimatedMatches,
      };
    }
    case RESET_PRODUCT_SEARCH_RESULTS: {
      return {
        ...state,
        isLoading: false,
        query: '',
        alteredQuery: '',
        totalEstimatedMatches: 0,
        productSearchResults: [],
      };
    }
    default:
      return state;
  }
}
