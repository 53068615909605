import { SchemaListHeaderStyleProps, SchemaListHeaderStyles } from './SchemaListHeader.types';
import { getFlexRowStyles } from '../../../../../components/common/Styles';

export const styles = (props: SchemaListHeaderStyleProps): SchemaListHeaderStyles => ({
  root: ['ms-details-list', props.className],
  action: [
    'mbc-action',
    {
      fontSize: 14,
      height: 32,
    },
    getFlexRowStyles(),
    {
      background: props.isTutorialActive ? props.theme.palette.white : undefined,
      backgroundColor: props.isTutorialActive ? props.theme.palette.white + ' !important' : undefined,
      justifyContent: 'center',
      alignItems: 'center',
    },
  ],
});
