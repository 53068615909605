import { TreeListViewStyleProps, TreeListViewStyles } from './TreeView.types';

export const styles = (props: TreeListViewStyleProps): TreeListViewStyles => ({
  root: ['mbc-tree-list-container', { background: 'white' }, props.className],
  headerChevron: ['mbc-header-chevron-icon', { color: 'black' }],
  listHeader: ['mbc-list-header', { fontWeight: 600 }],
  listItemContainer: ['mbc-list-item-container', { paddingLeft: 14 }],
  verticalSeparator: [
    'mbc-vertical-separator',
    {
      paddingRight: 0,
      height: 'inherit',
      selectors: {
        ':after': {
          backgroundColor: '#D9D9D9',
        },
      },
    },
  ],
});
