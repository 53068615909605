import { Environment } from '../guards/EnvironmentGuard/EnvironmentGuard';
import { Product, Feature } from '../store/types/tenantsList.d';
import { isProductionEnvironment } from '../guards/EnvironmentGuard/utils';

export interface CategoryPermissions {
  permissionItems: PermissionItem[];
  environments: Environment[];
  products: Product[];
  key: string;
}

export interface PermissionItem {
  products?: Product[];
  features?: Feature[];
  permissionName: string;
  readPermission?: string;
  readWritePermission?: string;
  environments?: Environment[];
}

export enum Permission {
  AdminEnvironmentRead = 'Admin.Environment.Read',
  AdminEnvironmentReadWrite = 'Admin.Environment.ReadWrite',
  AdminRoleManagementRead = 'Admin.RoleManagement.Read',
  AdminRoleManagementReadWrite = 'Admin.RoleManagement.ReadWrite',
  AdminTokenCreate = 'Admin.Token.Create',
  AdminTokenRevoke = 'Admin.Token.Revoke',
  SearchAutosuggestRead = 'Search.Autosuggest.Read',
  SearchAutosuggestReadWrite = 'Search.Autosuggest.ReadWrite',
  SearchIndexRead = 'Search.Index.Read',
  SearchIndexReadWrite = 'Search.Index.ReadWrite',
  SearchCustomizationRead = 'Search.Customization.Read',
  SearchCustomizationReadWrite = 'Search.Customization.ReadWrite',
  SearchAnalyticsRead = 'Search.Analytics.Read',
  SearchCustomMLRead = 'Search.CustomML.Read',
  SearchCustomMLReadWrite = 'Search.CustomML.ReadWrite',
  RecommendationCustomizationRead = 'Recommendation.Customization.Read',
  RecommendationCustomizationReadWrite = 'Recommendation.Customization.ReadWrite',
}

export function getDefaultPermissions(): string[] {
  const hiddenPerms = [
    Permission.SearchCustomMLRead,
    Permission.AdminEnvironmentRead,
    Permission.AdminRoleManagementRead,
    Permission.SearchAutosuggestRead,
    Permission.SearchIndexRead,
    Permission.SearchCustomizationRead,
    Permission.RecommendationCustomizationRead,
  ];

  return hiddenPerms;
}

export function getPermissionsConfig(): CategoryPermissions[] {
  return [
    {
      key: 'general',
      products: [],
      environments: [Environment.Production, Environment.Staging],
      permissionItems: [
        {
          permissionName: 'Environment',
          readPermission: Permission.AdminEnvironmentRead,
          readWritePermission: Permission.AdminEnvironmentReadWrite,
          environments: [Environment.Production, Environment.Staging],
        },

        {
          permissionName: 'Role management',
          readPermission: Permission.AdminRoleManagementRead,
          readWritePermission: Permission.AdminRoleManagementReadWrite,
          environments: [Environment.Production, Environment.Staging],
        },

        {
          permissionName: 'Create token',
          readPermission: undefined,
          readWritePermission: Permission.AdminTokenCreate,
          environments: [Environment.Production, Environment.Staging],
        },
        {
          permissionName: 'Revoke token',
          readPermission: undefined,
          readWritePermission: Permission.AdminTokenRevoke,
          environments: [Environment.Production, Environment.Staging],
        },
      ],
    },
    {
      key: 'search',
      products: [Product.TextSearch, Product.VisualSearch],
      environments: [Environment.Production, Environment.Staging],
      permissionItems: [
        {
          permissionName: 'Autosuggest',
          products: [Product.TextSearch],
          readPermission: Permission.SearchAutosuggestRead,
          readWritePermission: Permission.SearchAutosuggestReadWrite,
          environments: [Environment.Production, Environment.Staging],
        },

        {
          permissionName: 'Search index',
          readPermission: Permission.SearchIndexRead,
          readWritePermission: Permission.SearchIndexReadWrite,
          environments: [Environment.Production, Environment.Staging],
        },
        {
          permissionName: 'Customization',
          products: [Product.TextSearch],
          readPermission: Permission.SearchCustomizationRead,
          readWritePermission: Permission.SearchCustomizationReadWrite,
          environments: [Environment.Production, Environment.Staging],
        },

        {
          permissionName: 'Analytics',
          products: [Product.TextSearch],
          readPermission: Permission.SearchAnalyticsRead,
          readWritePermission: undefined,
          environments: [Environment.Staging],
        },

        {
          permissionName: 'Machine learning',
          features: [Feature.CustomML],
          products: [Product.TextSearch],
          readPermission: Permission.SearchCustomMLRead,
          readWritePermission: Permission.SearchCustomMLReadWrite,
          environments: [Environment.Production, Environment.Staging],
        },
      ],
    },
    {
      key: 'recommendations',
      products: [Product.Recommendations],
      environments: [Environment.Staging],
      permissionItems: [
        {
          permissionName: 'Customization',
          readPermission: Permission.RecommendationCustomizationRead,
          readWritePermission: Permission.RecommendationCustomizationReadWrite,
          environments: [Environment.Staging],
        },
      ],
    },
  ];
}

export const getCategoryPermissionsFilteredByEnvironment = (permissionItems: PermissionItem[]): PermissionItem[] => {
  return isProductionEnvironment()
    ? permissionItems.filter(perm => perm.environments && perm.environments.includes(Environment.Production))
    : permissionItems.filter(perm => perm.environments && perm.environments.includes(Environment.Staging));
};

export const getCategoryPermissionsFilteredByProductsAndFeatures = (
  permissionItems: PermissionItem[],
  supportedProducts: string[],
  supportedFeatures: string[],
): PermissionItem[] => {
  return permissionItems.filter(
    c =>
      (!c.products || c.products.length === 0 || c.products.some(p => supportedProducts.includes(p))) &&
      (!c.features || c.features.length === 0 || c.features.some(p => supportedFeatures.includes(p))),
  );
};

export function getPermissionTranslationKey(permName: Permission | string) {
  return permName.replace(/\./g, '');
}
