import { BusinessRuleEditorStyles, BusinessRuleEditorStyleProps } from './BusinessRuleEditor.types';

export const styles = (props: BusinessRuleEditorStyleProps): BusinessRuleEditorStyles => {
  const { className } = props;
  return {
    root: [
      'mbc-BusinessRuleEditor',
      {
        maxHeight: 'calc(100vh - 140px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      className,
    ],
    panelContainer: [
      'mbc-BusinessRuleEditor-PanelContainer',
      { maxHeight: 'calc(100vh - 340px)', overflowY: 'auto', overflowX: 'hidden' },
    ],
    panelBody: [
      'mbc-BusinessRuleEditor-PanelBody',
      {
        selectors: {
          '.mbc-ConditionBlockEditor:first-child': {
            borderTop: 0,
          },
        },
      },
      className,
    ],
  };
};
