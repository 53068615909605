import { Token } from '../store/types/adminTokens';
import store from '../store';
import { AuthInfo, IndexAuthInfo, DEMO_AUTH_INFO } from '../restful-apis/http-client/httpClient';
import { getTenantToken, getIndexToken, getOobeDemoToken } from '../restful-apis/token.api';
import { resetAdminToken, setAdminToken } from '../store/actions/adminTokensActions';

function isDemoAuthInfo(authInfo: AuthInfo): authInfo is typeof DEMO_AUTH_INFO {
  return typeof authInfo === typeof DEMO_AUTH_INFO;
}

function isIndexAuthInfo(authInfo: AuthInfo): authInfo is IndexAuthInfo {
  return (authInfo as IndexAuthInfo).indexId !== undefined;
}

export interface AdminTokensService {
  getToken: (authInfo: AuthInfo) => Promise<string>;
}

export const AdminTokensService = (function AdminTokensManager() {
  const DemoTokenCacheKey = 'DEMO_TOKEN_CACHE_KEY';
  const MaxTokenExpirationTimeInMS = 10 * 60 * 1000;
  const getCachedToken = (key: string): Token | undefined => store.getState().adminTokens.adminTokenMap[key];

  // Assumes both values null and expired tokens as null per calling scope
  const isUndefinedOrExpiredToken = (token: Token | undefined): token is undefined =>
    !token || new Date().getTime() - token.ellapsedTimeInMs >= MaxTokenExpirationTimeInMS;

  const getAdminTokenKey = (authInfo: AuthInfo): string => {
    if (isDemoAuthInfo(authInfo)) {
      return DemoTokenCacheKey;
    } else if (isIndexAuthInfo(authInfo)) {
      return authInfo.indexId;
    }
    return authInfo.tenantId;
  };

  const getAdminTokenValue = (authInfo: AuthInfo): Promise<string> => {
    if (isDemoAuthInfo(authInfo)) {
      return getOobeDemoToken().catch(() => {
        store.dispatch(resetAdminToken(DemoTokenCacheKey));
        return Promise.resolve('');
      });
    } else if (isIndexAuthInfo(authInfo)) {
      return getIndexToken(authInfo.tenantId, authInfo.indexId).catch(() => {
        store.dispatch(resetAdminToken(authInfo.indexId));
        return Promise.resolve('');
      });
    }
    return getTenantToken(authInfo.tenantId).catch(() => {
      store.dispatch(resetAdminToken(authInfo.tenantId));
      return Promise.resolve('');
    });
  };

  return {
    getToken: (authInfo: AuthInfo): Promise<string> => {
      const adminTokenKey = getAdminTokenKey(authInfo);
      // Check local token cache
      const cachedToken = getCachedToken(adminTokenKey);
      if (isUndefinedOrExpiredToken(cachedToken)) {
        // Set token expiration window pre fetch request
        const adminTokenValue = getAdminTokenValue(authInfo);
        const timeBeforeServiceInvocationInMs = new Date().getTime();
        store.dispatch(
          setAdminToken(adminTokenKey, {
            value: adminTokenValue,
            ellapsedTimeInMs: timeBeforeServiceInvocationInMs,
            timeout: setTimeout(() => {
              store.dispatch(resetAdminToken(adminTokenKey));
            }, MaxTokenExpirationTimeInMS),
          }),
        );

        return adminTokenValue;
      }

      return cachedToken.value;
    },
  };
})();
