import React, { Component, RefObject } from 'react';
import { Overlay, IOverlayProps, Layer } from 'office-ui-fabric-react';
import styles from './overlayFocusElement.module.scss';
import { FocusState } from '../../Tutorials.types';

interface OverlayFocusElementProps {
  overlayProps: IOverlayProps;
  focusTarget: RefObject<HTMLDivElement>;
  disableTarget: boolean;
  focusOnTarget: FocusState;
  focusTargetStyles?: string;
  lightBackground?: boolean;
}

export default class OverlayFocusElement extends Component<OverlayFocusElementProps> {
  private interval: any = null;
  static defaultProps = {
    overlayProps: {
      isDarkThemed: true,
    },
    focusTarget: null,
    disableTarget: true,
    focusOnTarget: FocusState.Target,
  };

  componentDidUpdate() {
    this.addClass();
  }

  componentDidMount() {
    this.addClass();
  }

  componentWillUnmount() {
    this.removeClass();
  }

  private addClass() {
    if (this.props.focusTarget) {
      if (this.props.focusTarget.current) {
        if (this.props.focusTargetStyles) {
          this.props.focusTarget.current.classList.add(this.props.focusTargetStyles);
        } else {
          if (this.props.lightBackground) {
            this.props.focusTarget.current.classList.add(styles.lightBackground);
          }
          if (this.props.focusOnTarget !== FocusState.Overlay) {
            this.props.focusTarget.current.classList.add(styles.overlayVisible);
          }
        }
      } else {
        // TODO: find a better way to listen to the dom changes maybe using JQuery?
        this.interval = setInterval(() => {
          if (this.props.focusTarget.current) {
            if (this.props.focusTargetStyles) {
              this.props.focusTarget.current.classList.add(this.props.focusTargetStyles);
            } else {
              if (this.props.lightBackground) {
                this.props.focusTarget.current.classList.add(styles.lightBackground);
              }
              if (this.props.focusOnTarget !== FocusState.Overlay) {
                this.props.focusTarget.current.classList.add(styles.overlayVisible);
              }
            }
            clearInterval(this.interval);
            this.interval = null;
          }
        }, 100);
      }
    }
  }

  private removeClass() {
    if (this.props.focusTarget.current) {
      if (this.props.focusTargetStyles) {
        this.props.focusTarget.current.classList.remove(this.props.focusTargetStyles);
      } else {
        if (this.props.lightBackground) {
          this.props.focusTarget.current.classList.remove(styles.lightBackground);
        }
        if (this.props.focusOnTarget !== FocusState.Overlay) {
          this.props.focusTarget.current.classList.remove(styles.overlayVisible);
        }
      }
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <>
        {this.props.disableTarget && (
          <Layer styles={{ root: { zIndex: 1000002 } }}>
            <Overlay styles={{ root: { backgroundColor: 'transparent' } }}></Overlay>
          </Layer>
        )}
        {(this.props.focusOnTarget === FocusState.Target || this.props.focusOnTarget === FocusState.Overlay) && (
          <Layer styles={{ root: { zIndex: 99999 } }}>
            <Overlay {...this.props.overlayProps}></Overlay>
          </Layer>
        )}
      </>
    );
  }
}
