import React, { useCallback, useState, useRef, useLayoutEffect } from 'react';
import {
  ProductManagementProps,
  ProductManagementStyles,
  ProductManagementStyleProps,
} from './ProductManagement.types';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { ProductSearchResultItem } from '../../../../store/types/productSearch.d';
import { classNamesFunction, Stack, Modal, CommandBarButton } from 'office-ui-fabric-react';
import {
  searchIndexProducts,
  createOrUpdateIndexProduct,
  deleteIndexProduct,
  ingestIndexProductsFromFile,
  updateIndexProducts,
} from '../../../../store/actions/productSearchActions';
import { DetailsListCardHeader } from '../../../../components/common/DetailsListCard/DetailsListCardHeader';
import { ConfirmationDialog as DeletionDialog } from '../../../../components/common/Dialog/ConfirmationDialog';
import { ProductPreview } from './ProductPreview/ProductPreview';
import { PreviewMode, Product } from './ProductPreview/ProductPreview.types';
import { DatasetUpload } from '../../../../components/DatasetUpload';
import { useTranslation } from 'react-i18next';
import { BufferList } from '../../../../components/common/BufferList';
import { NoItemsToDisplay } from '../../../../components/common/NoItemsToDisplay';
import { HorizontalProductItemCard } from '../../../../components/common/ProductItemCard';
import { NoResultsView } from '../../../../components/NoResultsView';
import { EnvironmentPermissionContext, EnvironmentPermissionContextType } from '../../../../contexts';
import { Permission } from '../../../../config/userPermissions.config';

const getClassNames = classNamesFunction<ProductManagementStyleProps, ProductManagementStyles>();

export const ProductManagementBase = (props: ProductManagementProps) => {
  const { styles, theme } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const productRef = useRef<HTMLDivElement>(null);

  const [previewBoundRect, setPreviewBoundRect] = useState<{ width: number; left: number }>({ width: 0, left: 0 });

  const classNames = getClassNames(styles, {
    theme: theme!,
    left: previewBoundRect.left,
    width: previewBoundRect.width,
  });

  const updatePreviewBoundRect = useCallback(() => {
    productRef.current &&
      setPreviewBoundRect({
        left: productRef.current.getBoundingClientRect().left,
        width: productRef.current.getBoundingClientRect().width,
      });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePreviewBoundRect);
    return () => {
      window.removeEventListener('resize', updatePreviewBoundRect);
    };
  }, [updatePreviewBoundRect]);

  const [showUploadPanel, setShowUploadPanel] = useState<boolean>(false);
  const [previewMode, setPreviewMode] = useState<PreviewMode>(PreviewMode.Read);
  const [productIdToBeDeleted, setProductIdToBeDeleted] = useState<string | undefined>();
  const [activeProduct, setActiveProduct] = useState<ProductSearchResultItem | undefined>();

  const originalQuery = useSelector(state => state.productSearch.query);
  const isProductSearchLoading = useSelector(state => state.productSearch.isLoading);
  const productSearchResults = useSelector(state => state.productSearch.productSearchResults);

  const onSearchQueryChange = useCallback(
    (query: string | undefined) => {
      query !== originalQuery && dispatch(searchIndexProducts(query));
    },
    [dispatch, originalQuery],
  );

  const onRefreshIndexProducts = useCallback(() => {
    dispatch(searchIndexProducts());
  }, [dispatch]);

  const onBufferIndexProducts = useCallback(() => {
    dispatch(updateIndexProducts());
  }, [dispatch]);

  const onDismissProductPreview = useCallback(() => {
    setActiveProduct(undefined);
    setPreviewMode(PreviewMode.Read);
  }, []);

  const onDeleteProduct = useCallback(() => {
    productIdToBeDeleted && dispatch(deleteIndexProduct(productIdToBeDeleted, onRefreshIndexProducts));
    onDismissProductPreview();
    setProductIdToBeDeleted(undefined);
  }, [dispatch, onDismissProductPreview, onRefreshIndexProducts, productIdToBeDeleted]);

  const onRenderProductCard = useCallback(
    (product?: ProductSearchResultItem) =>
      product && (
        <EnvironmentPermissionContext.Consumer key="product">
          {(props: EnvironmentPermissionContextType) => {
            const isAuthorized = props.isAuthorized(Permission.SearchIndexReadWrite);
            return (
              <HorizontalProductItemCard
                item={product}
                key={product.id}
                onClick={() => {
                  updatePreviewBoundRect();
                  setActiveProduct(product);
                  setPreviewMode(PreviewMode.Read);
                }}
                actionProps={{
                  onEditClick: isAuthorized
                    ? () => {
                        updatePreviewBoundRect();
                        setActiveProduct(product);
                        setPreviewMode(PreviewMode.Edit);
                      }
                    : undefined,
                  onRemoveClick: isAuthorized ? () => setProductIdToBeDeleted(product.id) : undefined,
                }}
              />
            );
          }}
        </EnvironmentPermissionContext.Consumer>
      ),
    [updatePreviewBoundRect],
  );

  const onUpdateProduct = useCallback(
    (updatedProduct: Product) => {
      activeProduct && dispatch(createOrUpdateIndexProduct(activeProduct.id, updatedProduct, onRefreshIndexProducts));
      onDismissProductPreview();
    },
    [activeProduct, dispatch, onDismissProductPreview, onRefreshIndexProducts],
  );

  const onAddActionClick = useCallback(() => {
    const newProduct: ProductSearchResultItem = {
      id: '',
      data: {},
    };
    updatePreviewBoundRect();
    setActiveProduct(newProduct);
    setPreviewMode(PreviewMode.New);
  }, [updatePreviewBoundRect]);

  const onUploadActionClick = useCallback(() => setShowUploadPanel(true), []);

  const onFiledUploaded = useCallback(
    (document: File) => {
      dispatch(ingestIndexProductsFromFile(document, onRefreshIndexProducts));
      setShowUploadPanel(false);
    },
    [dispatch, onRefreshIndexProducts],
  );

  return (
    <div className={classNames.root}>
      <DeletionDialog
        onConfirm={onDeleteProduct}
        isOpen={!!productIdToBeDeleted}
        title={t('product-management.deletion-title')}
        onDismiss={() => setProductIdToBeDeleted(undefined)}
        subText={t('product-management.deletion-subtext', { product: productIdToBeDeleted })}
      />
      <Modal
        isOpen={!!activeProduct}
        onDismiss={onDismissProductPreview}
        styles={classNames.subComponentStyles.modal}
        isBlocking={previewMode !== PreviewMode.Read}
      >
        <ProductPreview
          product={activeProduct}
          previewMode={previewMode}
          onDismiss={onDismissProductPreview}
          onPreviewModeChange={p => setPreviewMode(p)}
          actionProps={{
            onUpdateProduct: onUpdateProduct,
            onDeleteProduct: product => setProductIdToBeDeleted(product.id),
          }}
        />
      </Modal>
      <DatasetUpload
        isOpen={showUploadPanel}
        onUpload={onFiledUploaded}
        title={'Upload Product Data'}
        onDismiss={() => setShowUploadPanel(false)}
      />
      <Stack tokens={{ childrenGap: 12 }} style={{ flexBasis: '70%' }}>
        <EnvironmentPermissionContext.Consumer key="actions">
          {(props: EnvironmentPermissionContextType) => {
            const isAuthorized = props.isAuthorized(Permission.SearchIndexReadWrite);
            return (
              <DetailsListCardHeader
                searchActionProps={{
                  searchBy: 'name',
                  ariaLabel: 'search',
                  placeholder: 'Search',
                  onSearch: onSearchQueryChange,
                  setSearchQuery: () => {},
                }}
                actions={{
                  add: {
                    disabled: !isAuthorized,
                    onClick: onAddActionClick,
                  },
                  actionsList: [
                    <CommandBarButton
                      key="upload"
                      disabled={!isAuthorized}
                      text={t('common.upload')}
                      onClick={onUploadActionClick}
                      className={classNames.action}
                      iconProps={{ iconName: 'Upload' }}
                    />,
                  ],
                }}
              />
            );
          }}
        </EnvironmentPermissionContext.Consumer>
        <div ref={productRef} />
        {!!productSearchResults.length && (
          <BufferList
            items={productSearchResults}
            onRenderCell={onRenderProductCard}
            onBufferItems={onBufferIndexProducts}
          />
        )}
        {!isProductSearchLoading && !productSearchResults.length && !!originalQuery && (
          <NoResultsView originalQuery={originalQuery} />
        )}
        {!isProductSearchLoading && !productSearchResults.length && !originalQuery && (
          <NoItemsToDisplay
            text={t('product-management.no-data-text')}
            subText={t('product-management.no-data-sub-text')}
            actionProps={{
              buttonText: t('common.no-data-button-text'),
              onClick: () => {
                setShowUploadPanel(true);
              },
            }}
          />
        )}
      </Stack>
    </div>
  );
};
