import { configureDefaultObject, getLocaleTimeNow } from '../../../utils';
import {
  SearchModel,
  SearchModelMapper,
  SearchModelState,
  SearchModelStateActionTypes,
  SET_SEARCH_MODEL_LIST,
  RESET_SEARCH_MODEL_LIST,
  UPDATE_SEARCH_MODEL_TRAINING_JOB,
  UPDATE_SEARCH_MODEL_DEPLOYMENT_JOB,
  ADD_SEARCH_MODEL,
  SET_ACTIVE_SEARCH_MODEL,
  RESET_ACTIVE_SEARCH_MODEL,
  LOADING_SEARCH_MODEL_LIST,
  UPDATE_SEARCH_MODEL_EVALUATION_JOB,
  ModelType,
  SUBMIT_SEARCH_MODEL_DEPLOYMENT_JOB,
  SUBMIT_SEARCH_MODEL_TRAINING_JOB,
  CANCEL_SEARCH_MODEL_DEPLOYMENT_JOB_SUBMISSION,
  CANCEL_SEARCH_MODEL_TRAINING_JOB_SUBMISSION,
} from '../../types/customML/searchModel.d';

export const createInitialSearchModel = (): SearchModel => ({
  key: '',
  name: '',
  isNew: true,
  description: '',
  trainingJobKeys: [],
  deploymentJobKeys: [],
  evaluationJobKeys: [],
  feature: 'Search model',
  isSubmittingTraining: false,
  isSubmittingDeployment: false,
  createdAt: getLocaleTimeNow(),
  updatedAt: getLocaleTimeNow(),
  modelType: ModelType.LightGBMRanker,
});

const createSearchModelMapper = (initialMapper: SearchModelMapper = {}) =>
  configureDefaultObject<SearchModelMapper, SearchModel>(initialMapper, createInitialSearchModel());

const initialState: SearchModelState = {
  isLoading: true,
  searchModelMapper: createSearchModelMapper(),
  activeSearchModel: createInitialSearchModel(),
};

export function searchModelReducer(state = initialState, action: SearchModelStateActionTypes): SearchModelState {
  switch (action.type) {
    case SET_SEARCH_MODEL_LIST: {
      return {
        ...state,
        isLoading: false,
        searchModelMapper: createSearchModelMapper({
          ...action.searchModelList.reduce((mapper: SearchModelMapper, searchModel: SearchModel) => {
            mapper[searchModel.key] = searchModel;
            return mapper;
          }, {}),
        }),
      };
    }
    case LOADING_SEARCH_MODEL_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_SEARCH_MODEL_LIST: {
      return {
        ...state,
        isLoading: true,
        searchModelMapper: createSearchModelMapper(),
      };
    }
    case UPDATE_SEARCH_MODEL_TRAINING_JOB: {
      const searchModel = state.searchModelMapper[action.key];
      const updatedSearchModel = {
        ...searchModel,
        isSubmittingTraining: false,
        trainingJobKeys: searchModel.trainingJobKeys.concat(action.trainingJobKey),
      };
      return {
        ...state,
        activeSearchModel:
          updatedSearchModel.key === state.activeSearchModel.key ? updatedSearchModel : state.activeSearchModel,
        searchModelMapper: createSearchModelMapper({
          ...state.searchModelMapper,
          [action.key]: updatedSearchModel,
        }),
      };
    }
    case CANCEL_SEARCH_MODEL_DEPLOYMENT_JOB_SUBMISSION: {
      const updatedSearchModel = {
        ...state.searchModelMapper[action.key],
        isSubmittingDeployment: false,
      };
      return {
        ...state,
        activeSearchModel:
          updatedSearchModel.key === state.activeSearchModel.key ? updatedSearchModel : state.activeSearchModel,
        searchModelMapper: createSearchModelMapper({
          ...state.searchModelMapper,
          [action.key]: updatedSearchModel,
        }),
      };
    }
    case CANCEL_SEARCH_MODEL_TRAINING_JOB_SUBMISSION: {
      const updatedSearchModel = { ...state.searchModelMapper[action.key], isSubmittingTraining: false };
      return {
        ...state,
        activeSearchModel:
          updatedSearchModel.key === state.activeSearchModel.key ? updatedSearchModel : state.activeSearchModel,
        searchModelMapper: createSearchModelMapper({
          ...state.searchModelMapper,
          [action.key]: updatedSearchModel,
        }),
      };
    }
    case SUBMIT_SEARCH_MODEL_DEPLOYMENT_JOB: {
      const updatedSearchModel = {
        ...state.searchModelMapper[action.key],
        isSubmittingDeployment: true,
      };
      return {
        ...state,
        activeSearchModel:
          updatedSearchModel.key === state.activeSearchModel.key ? updatedSearchModel : state.activeSearchModel,
        searchModelMapper: createSearchModelMapper({
          ...state.searchModelMapper,
          [action.key]: updatedSearchModel,
        }),
      };
    }
    case SUBMIT_SEARCH_MODEL_TRAINING_JOB: {
      const updatedSearchModel = { ...state.searchModelMapper[action.key], isSubmittingTraining: true };
      return {
        ...state,
        activeSearchModel:
          updatedSearchModel.key === state.activeSearchModel.key ? updatedSearchModel : state.activeSearchModel,
        searchModelMapper: createSearchModelMapper({
          ...state.searchModelMapper,
          [action.key]: updatedSearchModel,
        }),
      };
    }
    case UPDATE_SEARCH_MODEL_DEPLOYMENT_JOB: {
      const searchModel = state.searchModelMapper[action.key];
      const updatedSearchModel = {
        ...searchModel,
        isSubmittingDeployment: false,
        deploymentJobKeys: searchModel.deploymentJobKeys.concat(action.deploymentJobKey),
      };
      return {
        ...state,
        activeSearchModel:
          updatedSearchModel.key === state.activeSearchModel.key ? updatedSearchModel : state.activeSearchModel,
        searchModelMapper: createSearchModelMapper({
          ...state.searchModelMapper,
          [action.key]: updatedSearchModel,
        }),
      };
    }
    case UPDATE_SEARCH_MODEL_EVALUATION_JOB: {
      const searchModel = state.searchModelMapper[action.key];
      const evaluationJobKeys = searchModel.evaluationJobKeys;
      const updatedSearchModel = {
        ...searchModel,
        evaluationJobKeys: !!evaluationJobKeys
          ? evaluationJobKeys.concat(action.evaluationJobKey)
          : [action.evaluationJobKey],
      };
      return {
        ...state,
        activeSearchModel:
          updatedSearchModel.key === state.activeSearchModel.key ? updatedSearchModel : state.activeSearchModel,
        searchModelMapper: createSearchModelMapper({
          ...state.searchModelMapper,
          [action.key]: updatedSearchModel,
        }),
      };
    }
    case ADD_SEARCH_MODEL: {
      return {
        ...state,
        searchModelMapper: createSearchModelMapper({
          [action.searchModel.key]: action.searchModel,
          ...state.searchModelMapper,
        }),
      };
    }
    case SET_ACTIVE_SEARCH_MODEL: {
      return {
        ...state,
        activeSearchModel: state.searchModelMapper[action.key],
      };
    }
    case RESET_ACTIVE_SEARCH_MODEL: {
      return {
        ...state,
        activeSearchModel: createInitialSearchModel(),
      };
    }
    default:
      return state;
  }
}
