import { Action } from 'redux';

export const SET_FIELD_VALUE_SUGGESTIONS = 'SET_FIELD_VALUE_SUGGESTIONS';
export const RESET_FIELD_VALUE_SUGGESTIONS = 'RESET_FIELD_VALUE_SUGGESTIONS';
export const LOADING_FIELD_VALUE_SUGGESTIONS = 'LOADING_FIELD_VALUE_SUGGESTIONS';
export const TOGGLE_IS_PANE_EXAPNDED = 'TOGGLE_IS_PANE_EXAPNDED';

export interface FieldValueSuggestions {
  id: string;
  suggestions: string[];
}

export interface FieldValueSuggestionsMapper {
  [key: string]: FieldValueSuggestions;
}

export interface QueryTesterState {
  isLoading: boolean;
  fieldValueSuggestionsMapper: FieldValueSuggestionsMapper;
  isPaneExpanded: boolean;
}

interface SetFieldValueSuggestions extends Action<typeof SET_FIELD_VALUE_SUGGESTIONS> {
  fieldValueSuggestions: FieldValueSuggestions[];
}

export type QueryTesterActionTypes =
  | SetFieldValueSuggestions
  | Action<typeof RESET_FIELD_VALUE_SUGGESTIONS>
  | Action<typeof LOADING_FIELD_VALUE_SUGGESTIONS>
  | Action<typeof TOGGLE_IS_PANE_EXAPNDED>;
