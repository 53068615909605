import {
  CustomMlSearchConfig as CustomMlSearchConfigDto,
  CustomMlModel,
} from '../dto/customizations/customMlSearchConfig';
import {
  CustomMlSearchConfig as CustomMlSearchConfigModel,
  DefaultCustomMlSearchConfigId,
} from '../../store/types/customizations/customMlSearchConfig.d';

// Remove Ranker augmentation "if found" in persisted ranker name
function removeRankerAugmentation(augmentedRanker: string): string {
  if (augmentedRanker.startsWith('[gini={0}\\{2}\\')) {
    let rankerName = augmentedRanker.substring(14);
    rankerName = rankerName.substring(0, rankerName.length - 1);
    return rankerName;
  }
  return augmentedRanker;
}

export function mapCustomMlSearchConfigDtoToModel(
  customMlSearchConfig: CustomMlSearchConfigDto | CustomMlSearchConfigDto[],
): CustomMlSearchConfigModel {
  const customMlSearchConfigList =
    customMlSearchConfig instanceof Array ? customMlSearchConfig : [customMlSearchConfig];
  const defaultConfig = customMlSearchConfigList.filter(
    config => config.customMlId === DefaultCustomMlSearchConfigId,
  )[0];
  const activeSearchModel =
    defaultConfig && defaultConfig.customMlModels.length > 0
      ? removeRankerAugmentation(defaultConfig.customMlModels[0].value)
      : 'Default';
  return {
    searchModel: activeSearchModel,
  };
}

export const customMlModelModelToDto = (customMlSearchConfig: CustomMlSearchConfigModel): CustomMlModel => {
  return { _type: 'l2ranker', value: customMlSearchConfig.searchModel };
};

export function mapCustomMlSearchConfigModelToDto(
  customMlSearchConfig: CustomMlSearchConfigModel,
): CustomMlSearchConfigDto {
  return {
    customMlId: DefaultCustomMlSearchConfigId,
    searchRequestCondition: {
      field: 'Query',
      value: '*',
      _type: 'StringCondition',
    },
    customMlModels: [{ _type: 'l2ranker', value: customMlSearchConfig.searchModel }],
  };
}
