import React, { useCallback, useState, useMemo, useEffect, createRef } from 'react';
import {
  classNamesFunction,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Pivot,
  PivotItem,
} from 'office-ui-fabric-react';
import { BusinessRuleProps, BusinessRuleStyleProps, BusinessRuleStyles, BusinessRulePanel } from './BusinessRule.types';
import { SearchInstanceSelector } from '../../SearchInstance/SearchInstanceSelector';
import { SideNavMode } from '../../../../components/common/PageTemplate/PageTemplate.types';
import { SearchPreview } from '../../common/SearchPreview';
import { BusinessRuleEditor } from '../BusinessRuleEditor';
import { AppState } from '../../../../store/reducers';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { BusinessRule } from '../../../../store/types/customizations/businessRule';
import { updateBusinessRule, createBusinessRule } from '../../../../store/actions/customizations/businessRuleActions';
import { MbcSearchInstanceRouter } from '../../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { SplitPageTemplate } from '../../../../components/common/PageTemplate/PageTemplate';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import { useBusinessRuleAugmentation } from './hooks/useBusinessRuleAugmentation';
import { toggleSideNavExpanded } from '../../../../store/actions/sideNavActions';
import { useKeylessBusinessRule } from './hooks/useKeylessBusinessRule';
import { Tutorial, TutorialSteps } from '../../../../components/Tutorials';

const getClassNames = classNamesFunction<BusinessRuleStyleProps, BusinessRuleStyles>();

export const BusinessRuleBase = (props: BusinessRuleProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const editorRef = createRef<HTMLDivElement>();

  const dispatch = useDispatch();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);

  const activeBusinessRule = useSelector<BusinessRule>((state: AppState) => state.businessRule.activeBusinessRule);
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);

  const editMode = useMemo(() => !!activeBusinessRule.id, [activeBusinessRule.id]);

  const [currentBusinessRule, setCurrentBusinessRule] = useState<BusinessRule>(_.cloneDeep(activeBusinessRule));
  const [isSaved, setIsSaved] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const shouldBlock = useMemo(() => isUpdated && !isSaved, [isSaved, isUpdated]);
  const [showActivationDialog, setShowActivationDialog] = useState(false);

  const keylessActiveBusinessRule: BusinessRule = useKeylessBusinessRule(activeBusinessRule);
  const keylessCurrentBusinessRule: BusinessRule = useKeylessBusinessRule(currentBusinessRule);

  const [selectedPanel, setSelectedPanel] = useState<string>(BusinessRulePanel.General);

  const navigateToBusinessRuleList = useCallback(() => {
    MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id)(
      MbcRouteKey.BusinessRuleList,
    ).browserPush();
  }, [activeEnvironment.id, activeSearchInstance.id, activeTenant.id]);

  useEffect(() => {
    isCanceled && navigateToBusinessRuleList();
  }, [isCanceled, navigateToBusinessRuleList]);

  useEffect(() => {
    const updated = !_.isEqual(keylessActiveBusinessRule, keylessCurrentBusinessRule);
    !isSaved && updated !== isUpdated && setIsUpdated(updated);
  }, [keylessActiveBusinessRule, keylessCurrentBusinessRule, isSaved, isUpdated]);

  useEffect(() => {
    shouldBlock ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldBlock]);

  const memoizedAugmentations = useBusinessRuleAugmentation(
    currentBusinessRule.name,
    currentBusinessRule.filterCondition,
    currentBusinessRule.promoteCondition,
    currentBusinessRule.demoteCondition,
    currentBusinessRule.banner,
  );

  const isFormValid = useMemo(() => {
    return (
      !!currentBusinessRule.name &&
      !!currentBusinessRule.searchRequestCondition &&
      (currentBusinessRule.promoteCondition ||
        currentBusinessRule.demoteCondition ||
        currentBusinessRule.filterCondition ||
        currentBusinessRule.banner)
    );
  }, [currentBusinessRule]);

  const handleCancel = useCallback(() => {
    setIsSaved(true);
    setIsUpdated(false);
    setIsCanceled(true);
  }, []);

  const handleSave = useCallback(() => {
    if (editMode) {
      setIsSaved(true);
      setIsUpdated(false);
      dispatch(
        updateBusinessRule(currentBusinessRule, undefined, navigateToBusinessRuleList, () => {
          setIsSaved(false);
          setIsUpdated(true);
        }),
      );
    } else {
      setShowActivationDialog(true);
    }
  }, [currentBusinessRule, dispatch, editMode, navigateToBusinessRuleList]);

  const saveAsActive = useCallback(() => {
    setIsSaved(true);
    setIsUpdated(false);
    setShowActivationDialog(false);
    dispatch(
      createBusinessRule({ ...currentBusinessRule, enabled: true }, undefined, navigateToBusinessRuleList, () => {
        setIsSaved(false);
        setIsUpdated(true);
      }),
    );
  }, [currentBusinessRule, dispatch, navigateToBusinessRuleList]);

  const saveAsInactive = useCallback(() => {
    setIsSaved(true);
    setIsUpdated(false);
    setShowActivationDialog(false);
    dispatch(
      createBusinessRule(currentBusinessRule, undefined, navigateToBusinessRuleList, () => {
        setIsSaved(false);
        setIsUpdated(true);
      }),
    );
  }, [currentBusinessRule, dispatch, navigateToBusinessRuleList]);

  const getEditorContent = useCallback(() => {
    return (
      <div ref={editorRef}>
        <BusinessRuleEditor
          businessRule={currentBusinessRule}
          selectedPanel={selectedPanel}
          editMode={editMode}
          onUpdate={businessRule => setCurrentBusinessRule(businessRule)}
        />
      </div>
    );
  }, [currentBusinessRule, editMode, editorRef, selectedPanel]);

  const getEditorPanelHeader = useCallback(() => {
    return (
      <Pivot
        styles={{ root: { display: 'flex' }, link: { height: 43 }, linkIsSelected: { height: 43 } }}
        selectedKey={selectedPanel}
        onLinkClick={(item?: PivotItem) => {
          setSelectedPanel(
            item && item.props.itemKey ? (item.props.itemKey as BusinessRulePanel) : BusinessRulePanel.General,
          );
        }}
        headersOnly={true}
      >
        <PivotItem headerText={BusinessRulePanel.General} itemKey={BusinessRulePanel.General} />
        <PivotItem headerText={BusinessRulePanel.Promote} itemKey={BusinessRulePanel.Promote} />
        <PivotItem headerText={BusinessRulePanel.Demote} itemKey={BusinessRulePanel.Demote} />
        <PivotItem headerText={BusinessRulePanel.Filter} itemKey={BusinessRulePanel.Filter} />
        <PivotItem headerText={BusinessRulePanel.Banner} itemKey={BusinessRulePanel.Banner} />
      </Pivot>
    );
  }, [selectedPanel]);

  return (
    <div className={classNames.root}>
      <Tutorial
        target={editorRef}
        step={TutorialSteps.BusinessRulesRightPane}
        headline={t('product-tours-panel.business-rules-tour.headline4')}
        lightBackground
      >
        {
          <>
            <p>{t('product-tours-panel.business-rules-tour.step-4.pargraph1')}</p>
            <p>
              <span className={classNames.tutorialSubheader}>
                {t('product-tours-panel.business-rules-tour.step-4.pargraph2_v2.subheading')}
              </span>
              {t('product-tours-panel.business-rules-tour.step-4.pargraph2_v2.body')}
            </p>
            <p>
              <span className={classNames.tutorialSubheader}>
                {t('product-tours-panel.business-rules-tour.step-4.pargraph3.subheading')}
              </span>
              {t('product-tours-panel.business-rules-tour.step-4.pargraph3.body')}
            </p>
            <p>
              <span className={classNames.tutorialSubheader}>
                {t('product-tours-panel.business-rules-tour.step-4.pargraph4.subheading')}
              </span>
              {t('product-tours-panel.business-rules-tour.step-4.pargraph4.body')}
            </p>
          </>
        }
      </Tutorial>
      <SplitPageTemplate
        pageHeaderProps={{
          title: editMode ? 'Edit business rule' : 'Add business rule',
          infoProps: {
            headline: t('business-rule.title'),
            content: t('business-rule.info-content'),
            linkProps: {
              to: '/docs/Portal%20Documentation/#manage-business-rules-for-product-search',
              linkText: t('business-rule.info-link'),
            },
          },
        }}
        onRenderLeftSideContent={() => <SearchPreview hidePostBody augmentations={memoizedAugmentations} />}
        collapsiblePaneProps={{
          onRenderPaneHeader: getEditorPanelHeader,
          onRenderPaneContent: getEditorContent,
          footerProps: {
            saveButtonText: editMode ? 'Save' : 'Create',
            saveButtonDisabled: !isFormValid || !shouldBlock,
            onSave: handleSave,
            onCancel: handleCancel,
          },
        }}
        breadcrumbVisible={true}
        onSecondaryNavMenuRender={() => <SearchInstanceSelector />}
        sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
        sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
      />

      <Dialog
        hidden={!showActivationDialog}
        onDismiss={() => setShowActivationDialog(false)}
        minWidth={560}
        maxWidth={560}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Rule activation',
          isMultiline: true,
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        <span>
          Would you like to activate this business rule? This will apply it to query results in the system. You can
          activate it later from list menu.
        </span>
        <DialogFooter>
          <PrimaryButton onClick={saveAsActive} text={'Save and activate'} />
          <DefaultButton onClick={saveAsInactive} text={'Save it as inactive'} />
        </DialogFooter>
      </Dialog>

      <Prompt when={shouldBlock} message={t('common.on-before-unload-msg')} />
    </div>
  );
};
