import {
  SchemaListHeaderProps,
  SchemaListHeaderStyleProps,
  SchemaListHeaderStyles,
  FilterableFields,
} from './SchemaListHeader.types';
import {
  DetailsListCardHeader,
  DetailsListCardActions,
} from '../../../../../components/common/DetailsListCard/DetailsListCardHeader';
import { EnvironmentPermissionContext, EnvironmentPermissionContextType } from '../../../../../contexts';
import React, { useMemo, createRef, useState, useCallback, useEffect } from 'react';
import { CommandBarButton, classNamesFunction } from 'office-ui-fabric-react';
import { Permission } from '../../../../../config/userPermissions.config';
import { SchemaListFilters } from './SchemaListFilters';
import { ViewMode } from '..';
import { SchemaFilters, GroupExtended } from './SchemaListHeader.types';
import { Tutorial, TutorialSteps } from '../../../../../components/Tutorials';
import { useSelector } from '../../../../../store/hooks';
import { AvailableTutorials } from '../../../../../store/types/tutorial.d';
import { SearchIndexTypeConfig } from '../SchemaManagement.config';
import { IndexField as IndexFieldType } from '../../../../../store/types/searchIndex.d';
import { getFilterCorrespondingField } from './SchemaListHeader.utils';

const getClassNames = classNamesFunction<SchemaListHeaderStyleProps, SchemaListHeaderStyles>();

export const SchemaListHeaderBase = (props: SchemaListHeaderProps) => {
  const {
    actions,
    schemaViewMode,
    onViewModeChange,
    indexFields,
    onChange,
    onSchemaTutorialStepEnd,
    t,
    className,
    theme,
    styles,
  } = props;

  const tutorial = useSelector(state => state.tutorial);

  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
    isTutorialActive: tutorial.currentTutorial === AvailableTutorials.Catalog,
  });

  const [searchQuery, setSearchQuery] = useState('');

  const schemaDetectionButtonRef = createRef<HTMLDivElement>();
  const editButtonRef = createRef<HTMLDivElement>();
  const addButtonRef = createRef<HTMLDivElement>();

  const [selectedFilters, setSelectedFilters] = useState<SchemaFilters>({});

  const matchesFieldFilter = useCallback(
    (featureName: FilterableFields, fieldValue: any) => {
      if (
        !!selectedFilters[featureName] &&
        selectedFilters[featureName].length > 0 &&
        !selectedFilters[featureName].includes(fieldValue)
      ) {
        return false;
      }
      return true;
    },
    [selectedFilters],
  );

  const applyFeaturesFilter = useCallback(
    (field: IndexFieldType): boolean => {
      return Object.values(FilterableFields)
        .map(filter => matchesFieldFilter(filter, getFilterCorrespondingField(field, filter)))
        .reduce((prevValue, currValue) => prevValue && currValue, true);
    },
    [matchesFieldFilter],
  );

  const getSchemaGroups = useCallback(
    (fields: IndexFieldType[], level: number = 0, shouldParentRender = false): GroupExtended[] => {
      return fields
        .map(f => {
          const shouldRenderInternal =
            f.name.toLowerCase().includes(searchQuery.toLowerCase()) && applyFeaturesFilter(f);
          const fieldChildren =
            f.fields && f.fields.length > 0 ? getSchemaGroups(f.fields, level + 1, shouldRenderInternal) : [];
          return {
            data: f,
            count: 0,
            key: f.key,
            name: f.name,
            startIndex: 0,
            level: level,
            isCollapsed:
              schemaViewMode === ViewMode.Read ||
              !f.type ||
              (!!SearchIndexTypeConfig[f.type] && SearchIndexTypeConfig[f.type].dataType !== 'Object'),
            children: fieldChildren,
            shouldRender: shouldRenderInternal || shouldParentRender || fieldChildren.some(c => c.shouldRender),
          };
        })
        .filter(f => f.shouldRender);
    },
    [applyFeaturesFilter, schemaViewMode, searchQuery],
  );

  useEffect(() => {
    onChange(getSchemaGroups(indexFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, searchQuery, indexFields]);

  const onRenderActions: DetailsListCardActions = useMemo(() => {
    const actionsList = [
      <EnvironmentPermissionContext.Consumer key="upload">
        {(props: EnvironmentPermissionContextType) => {
          const isAuthorized = props.isAuthorized(Permission.SearchIndexReadWrite);
          return (
            <div ref={schemaDetectionButtonRef}>
              <CommandBarButton
                key="schema-detection"
                disabled={actions.upload.disabled || !isAuthorized}
                onClick={actions.upload.onClick}
                className={classNames.action}
                iconProps={{ iconName: 'Upload' }}
                text={t('common.upload')}
              />
            </div>
          );
        }}
      </EnvironmentPermissionContext.Consumer>,
      <EnvironmentPermissionContext.Consumer key="edit">
        {(props: EnvironmentPermissionContextType) => {
          const isAuthorized = props.isAuthorized(Permission.SearchIndexReadWrite);
          return (
            <div key="Edit" ref={editButtonRef}>
              <CommandBarButton
                key="Edit"
                disabled={actions.edit.disabled || !isAuthorized}
                onClick={actions.edit.onClick}
                className={classNames.action}
                iconProps={{ iconName: 'Edit' }}
                text={t('common.edit')}
              />
            </div>
          );
        }}
      </EnvironmentPermissionContext.Consumer>,
      <SchemaListFilters
        key="filter"
        indexFields={indexFields}
        onFilterChange={(changedField: string, changedValue: string, selected: boolean) => {
          if (changedValue === 'all') {
            setSelectedFilters({});
          } else {
            let newFilterValues = !!selectedFilters[changedField]
              ? selected
                ? [...selectedFilters[changedField], changedValue]
                : selectedFilters[changedField].filter(v => v !== changedValue)
              : [changedValue];
            setSelectedFilters(state => ({ ...state, [changedField]: newFilterValues }));
          }
        }}
      />,
    ];

    if (schemaViewMode === ViewMode.Edit) {
      actionsList.unshift(
        <div key="Add schema row" ref={addButtonRef}>
          <CommandBarButton
            key="Add schema row"
            disabled={actions.addRow.disabled}
            onClick={actions.addRow.onClick}
            className={classNames.action}
            iconProps={{ iconName: 'Add' }}
            text={t('common.add')}
          />
        </div>,
      );
    }

    return {
      actionsList: actionsList,
      filters: {
        items: [],
        onFileterChange: () => {},
      },
    };
  }, [
    actions.addRow.disabled,
    actions.addRow.onClick,
    actions.edit.disabled,
    actions.edit.onClick,
    actions.upload.disabled,
    actions.upload.onClick,
    addButtonRef,
    classNames.action,
    editButtonRef,
    indexFields,
    schemaDetectionButtonRef,
    schemaViewMode,
    selectedFilters,
    t,
  ]);

  const getTutorialSteps = () => {
    return (
      <>
        <Tutorial
          target={editButtonRef}
          step={TutorialSteps.SchemaEditButton}
          headline={t('tutorial.catalog.headline')}
          onStepStart={() => {
            onViewModeChange(ViewMode.Read);
          }}
          onStepEnd={() => {
            onViewModeChange(ViewMode.Edit);
          }}
        >
          {t('tutorial.catalog.step-1')}
        </Tutorial>
        <Tutorial
          target={addButtonRef}
          step={TutorialSteps.SchemaAddField}
          headline={t('tutorial.catalog.headline')}
          onDismiss={() => {
            onViewModeChange(ViewMode.Read);
          }}
        >
          {t('tutorial.catalog.step-2')}
        </Tutorial>
        <Tutorial
          target={schemaDetectionButtonRef}
          step={TutorialSteps.SchemaDetection}
          headline={t('tutorial.catalog.headline')}
          onStepEnd={() => {
            onSchemaTutorialStepEnd();
          }}
          onDismiss={() => {
            onViewModeChange(ViewMode.Read);
          }}
        >
          {t('tutorial.catalog.step-4')}
        </Tutorial>
      </>
    );
  };

  return (
    <>
      {getTutorialSteps()}
      <DetailsListCardHeader
        searchActionProps={{
          searchBy: 'name',
          ariaLabel: t('common.search-aria-label'),
          placeholder: t('common.search-placeholder'),
          setSearchQuery: (query: string | undefined) => {
            setSearchQuery(query || '');
          },
          onSearch: (query: string | undefined) => {
            setSearchQuery(query || '');
          },
        }}
        actions={onRenderActions}
      />
    </>
  );
};
