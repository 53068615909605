import HttpClient from './http-client/httpClient';
import { UserList as UserListDTO } from './dto/users/userList';
import { SandBoxDetails as UserModel } from '../store/types/user.d';
import { mapUsersListDtoToSingleModel, mapUserModelToDto } from './mappers/userMapper';

function getBaseUri(): string {
  return `https://commerce.bing.com${process.env.REACT_APP_HOME_PAGE_URL}api/admin/v2/me`;
}

export function createUser(accessToken: string): Promise<UserModel> {
  return HttpClient.post<UserListDTO>({
    url: getBaseUri() + '?aadAccessToken=' + accessToken,
    body: {},
  }).then(mapUsersListDtoToSingleModel);
}

export function getUserDetails(): Promise<UserModel> {
  return HttpClient.get<UserListDTO>({ url: getBaseUri(), supressNotificationOnFailure: true }).then(
    mapUsersListDtoToSingleModel,
  );
}

export function updateUserDetails(user: UserModel): Promise<UserModel> {
  const userDTO = mapUserModelToDto(user);
  return HttpClient.put<UserListDTO>({ url: getBaseUri(), body: userDTO }).then(mapUsersListDtoToSingleModel);
}
