import { BaseResponseInterceptor } from './BaseResponseInterceptor';
import { DocumentDeleteResponse } from '../../../dto/ingestion/document';
import { IngestionServiceException } from '..';
import { ServiceException } from '../Types';
import {
  IsIngestionServiceException,
  ExtractIngestionExceptionMessage,
  IsDocumentIngestionDeletionException,
  ExtractDocumentDeletionExceptionMessage,
} from '../Utils';

export class IngestionServiceInterceptor extends BaseResponseInterceptor {
  constructor() {
    super(/^https:\/\/commerce.bing.com\/api\/ingestion\/v1\/.*$/);
  }

  ExtractExceptionMessage(exception: ServiceException | DocumentDeleteResponse | IngestionServiceException): string {
    if (IsIngestionServiceException(exception)) {
      return ExtractIngestionExceptionMessage(exception);
    }

    if (IsDocumentIngestionDeletionException(exception)) {
      return ExtractDocumentDeletionExceptionMessage(exception);
    }

    return super.ExtractExceptionMessage(exception);
  }
}
