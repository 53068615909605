import React, { useState } from 'react';
import { CustomMLCard } from '../CustomMLCard';
import { CardState } from '../CustomMLCard.types';
import { DeployModelCardProps } from './DeployModelCard.types';
import { useDispatch, useSelector } from '../../../../../store/hooks';
import { SearchModel, ModelJobStatus } from '../../../../../store/types/customML/searchModel.d';
import { useActiveTraining } from '../../../../../store/hooks/use-active-job/useActiveTraining';
import { useActiveDeployment } from '../../../../../store/hooks/use-active-job/useActiveDeployment';
import { useTenantPermissions } from '../../../../../store/hooks/use-user-permissions/UseTenantPermissions';
import { ConfirmationDialog } from '../../../../../components/common/Dialog/ConfirmationDialog';
import { deploySearchModel } from '../../../../../store/actions/customML/deploymentJobActions';
import { getLocaleTimeNow, isAuthorized } from '../../../../../utils';
import { Permission } from '../../../../../config/userPermissions.config';
import { DeepLink } from './DeepLink';

export function DeployModelCardBase(props: DeployModelCardProps) {
  const { t, cardComponentRef } = props;

  const dispatch = useDispatch();
  const trainingJob = useActiveTraining();
  const deploymentJob = useActiveDeployment();
  const userPermissions = useTenantPermissions();

  const [isConfirmDialogOpen, openConfirmDialog] = useState(false);
  const [isDeepLinkModalOpen, openDeepLinkModal] = useState(false);

  const tenantId = useSelector<string>(state => state.tenantsList.activeTenant.id);
  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);

  const getDeploymentCardState = (): CardState =>
    searchModel.isSubmittingDeployment
      ? CardState.InProgress
      : !!deploymentJob.key
      ? CardState.Completed
      : trainingJob.status === ModelJobStatus.Finished
      ? CardState.Active
      : CardState.InActive;

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        styles={{ root: { margin: 0 } }}
        title={t('custom-ml.job-card.deploy.dialog.title')}
        subText={t('custom-ml.job-card.deploy.dialog.subtitle')}
        onConfirm={() => {
          dispatch(
            deploySearchModel(tenantId, searchModel.key, {
              ...deploymentJob,
              createdAt: getLocaleTimeNow(),
              updatedAt: getLocaleTimeNow(),
              status: ModelJobStatus.NotStarted,
              deployedModelKey: trainingJob.rankerModelKey,
            }),
          );
          openConfirmDialog(false);
        }}
        onDismiss={() => openConfirmDialog(false)}
        confirmButtonText={t('custom-ml.job-card.deploy.dialog.confirm')}
      />
      <DeepLink isOpen={isDeepLinkModalOpen} onDismiss={() => openDeepLinkModal(false)} />
      <CustomMLCard
        componentRef={cardComponentRef}
        state={getDeploymentCardState()}
        title={t('custom-ml.job-card.deploy.title')}
        activeCardProps={{
          iconProps: { iconName: 'Tiles' },
          label: t('custom-ml.job-card.deploy.deploy-action'),
          onClick: () =>
            isAuthorized(userPermissions, Permission.SearchCustomMLReadWrite) ? openConfirmDialog(true) : undefined,
        }}
        inProgressLabel={t('custom-ml.job-card.deploy.deploy-inprogress')}
        completedCardProps={{
          onClick:
            deploymentJob.status === ModelJobStatus.Error &&
            isAuthorized(userPermissions, Permission.SearchCustomMLReadWrite)
              ? () => openConfirmDialog(true)
              : deploymentJob.status === ModelJobStatus.Finished
              ? () => openDeepLinkModal(true)
              : undefined,
          label:
            deploymentJob.status !== ModelJobStatus.Error
              ? t('custom-ml.job-card.deploy.test-action')
              : t('custom-ml.job-card.deploy.re-deploy-action'),
          contentProps: {
            startTime: {
              label: t('custom-ml.job-card.deploy.start-time'),
              value: deploymentJob.createdAt,
            },
            endTime:
              deploymentJob.status > ModelJobStatus.Running
                ? {
                    status: deploymentJob.status,
                    value: deploymentJob.updatedAt,
                    label: t(`dynamic:custom-ml.job-status.${deploymentJob.status}`),
                  }
                : undefined,
          },
        }}
      />
    </>
  );
}
