import React, { useState } from 'react';
import { getClassNames } from './DraggableSlider.styles';
import { DraggableSliderProps } from './DraggableSlider.types';
import { Icon, TeachingBubble } from 'office-ui-fabric-react';

export function DraggableSlider(props: DraggableSliderProps) {
  const { styles, theme, onSliderClick, showTeachingBubble } = props;
  const classNames = getClassNames(styles, { theme });
  let sliderIconRef = React.createRef<HTMLDivElement>();

  const [showTeachingBubbleState, setshowTeachingBubbleState] = useState(false);

  // Without this timeout, the callout will appear momentarily at the top of the screen
  setTimeout(() => {
    setshowTeachingBubbleState(true);
  }, 3000);

  function onSliderClickInternal() {
    if (onSliderClick) {
      onSliderClick();
    }
  }
  return (
    <div className={classNames.root} onClick={onSliderClickInternal}>
      <div className={classNames.slider} ref={sliderIconRef}>
        <Icon iconName="MoreVertical"></Icon>
      </div>
      {showTeachingBubbleState && showTeachingBubble && (
        <TeachingBubble target={sliderIconRef} styles={classNames.subComponentStyles.teachingBubble}>
          Try the slider on the right to compare search results with query understanding
        </TeachingBubble>
      )}
    </div>
  );
}
