import { BoostCondition } from '../../../../utils/customizations/conditions';
import { Augmentations } from '../../common/SearchPreview/SearchPrevew.utils';
import { QueryTesterDetails } from '../QueryTester.types';
import { getValidConditionBlock } from '../../common/utils/ConditionBlock';

export const mapDetailsToAugmentation = (queryTesterDetails: QueryTesterDetails): Augmentations => {
  const promoteConditionBlock = getValidConditionBlock(
    queryTesterDetails.promoteCondition && queryTesterDetails.promoteCondition.condition,
  );

  const demoteConditionBlock = getValidConditionBlock(
    queryTesterDetails.demoteCondition && queryTesterDetails.demoteCondition.condition,
  );

  const filterConditionBlock = getValidConditionBlock(
    queryTesterDetails.filterCondition && queryTesterDetails.filterCondition,
  );

  const promoteCondition: BoostCondition | undefined = queryTesterDetails.promoteCondition &&
    promoteConditionBlock && {
      ...queryTesterDetails.promoteCondition,
      condition: promoteConditionBlock,
    };

  const demoteCondition: BoostCondition | undefined = queryTesterDetails.demoteCondition &&
    demoteConditionBlock && {
      ...queryTesterDetails.demoteCondition,
      condition: demoteConditionBlock,
    };

  const boostConditions: BoostCondition[] = [];
  promoteCondition && boostConditions.push(promoteCondition);
  demoteCondition && boostConditions.push(demoteCondition);

  return {
    filterCondition: filterConditionBlock,
    boostConditions: boostConditions.length > 0 ? boostConditions : undefined,
    banners: queryTesterDetails.banner ? [queryTesterDetails.banner] : undefined,
    alteration: queryTesterDetails.generalSettings.alterationEnabled,
    orderBy: queryTesterDetails.generalSettings.sortBy,
    urlParams: {
      setLang: queryTesterDetails.generalSettings.selectedLanguage,
      mkt: queryTesterDetails.generalSettings.selectedMarket,
    },
  };
};
