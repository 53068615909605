import React from 'react';
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  classNamesFunction,
} from 'office-ui-fabric-react';
import {
  ConfirmationDialogProps,
  ConfirmationDialogStyleProps,
  ConfirmationDialogStyles,
} from './ConfirmationDialog.types';

const getClassNames = classNamesFunction<ConfirmationDialogStyleProps, ConfirmationDialogStyles>();

export const ConfirmationDialogBase = (props: ConfirmationDialogProps) => {
  const {
    theme,
    className,
    styles,
    componentRef,
    t,
    isOpen,
    title,
    subText,
    content,
    onConfirm,
    onDismiss,
    confirmButtonText,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div className={classNames.root} ref={componentRef}>
      <Dialog
        hidden={!isOpen}
        onDismiss={() => onDismiss()}
        dialogContentProps={{
          type: DialogType.normal,
          title: title,
          subText: subText,
          isMultiline: !!content,
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        {content}
        <DialogFooter>
          <PrimaryButton
            onClick={() => onConfirm()}
            text={confirmButtonText ? confirmButtonText : t('common.confirm')}
          />
          <DefaultButton onClick={() => onDismiss()} text={t('common.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
