import React, { useState, useRef } from 'react';
import {
  FileUploaderTreeViewProps,
  FileUploaderTreeViewStyleProps,
  FileUploaderTreeViewStyles,
} from './FileUploaderTreeView.types';
import { classNamesFunction, Stack, ActionButton } from 'office-ui-fabric-react';
import { TreeListView } from '../TreeView';
import { ListItem } from '../TreeView/ListItem/ListItem.types';

const getClassName = classNamesFunction<FileUploaderTreeViewStyleProps, FileUploaderTreeViewStyles>();

// Default max uploaded file size is 200 MB
const MAX_UPLOADED_FILE_SIZE = 200000000;

export const FileUploaderTreeViewBase = (props: FileUploaderTreeViewProps) => {
  const {
    t,
    theme,
    width,
    label,
    styles,
    className,
    onFilesSelection,
    acceptedExtensions = ['.ljson', '.json', '.ndjson', '.jsonl', '.ldjson', '.csv', '.tsv'],
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const classNames = getClassName(styles, { theme: theme!, className: className });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef(React.createRef<HTMLInputElement>());

  const isInvalidFileSize = (file: File): boolean => file.size > MAX_UPLOADED_FILE_SIZE;
  const isInvalidFileExtension = (file: File): boolean =>
    !acceptedExtensions.includes(file.name.substr(file.name.lastIndexOf('.')));

  const isInvalidUploadedFiles = (files: File[]): boolean => {
    let uploadErrorMessage = '';

    const invalidExtensionFile = files.find(isInvalidFileExtension);
    if (invalidExtensionFile) {
      uploadErrorMessage = t('file-uploader.extension-validation-message', {
        extension: invalidExtensionFile.type,
        extensions: acceptedExtensions.join(', '),
      });
    }

    const invalidSizeFile = files.find(isInvalidFileSize);
    if (invalidSizeFile) {
      uploadErrorMessage = t('file-uploader.size-validation-message');
    }

    setErrorMessage(uploadErrorMessage);
    return !!uploadErrorMessage;
  };

  const onUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target === null || event.target.files === null) {
      return;
    }

    // Convert FileList to File[]
    const files = Array.from(event.target.files);
    if (isInvalidUploadedFiles(files)) {
      return;
    }

    setSelectedFiles(files);
    onFilesSelection(files);
  };

  const onRemoveUploadedFile = (filename: string) => {
    const remainingFiles = selectedFiles.filter(file => file.name !== filename);

    setSelectedFiles(remainingFiles);
    onFilesSelection(remainingFiles);
  };

  const getTreeListObjects = (): ListItem[] => {
    let currDate = new Date();
    let dateString =
      currDate.getDate() +
      '/' +
      (currDate.getMonth() + 1) +
      ' ' +
      currDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return selectedFiles.map(file => ({
      header: {
        width: width,
        title: file.name,
        iconName: 'Delete',
        onIconClick: () => onRemoveUploadedFile(file.name),
      },
      subHeader1: t('file-uploader.tree-first-subtext'),
      subHeader2: t('file-uploader.tree-second-subtext') + dateString,
    }));
  };

  return (
    <div className={classNames.root}>
      <input
        className={classNames.input}
        type="file"
        accept={acceptedExtensions.join(',')}
        ref={fileInputRef.current}
        onChange={ev => onUploadHandler(ev)}
        // This is used to clear the selected files
        value={selectedFiles.length === 0 ? '' : undefined}
      />
      <Stack gap={32}>
        {!!selectedFiles.length && (
          <TreeListView header={t('file-uploader.tree-header')} items={getTreeListObjects()} />
        )}
        <ActionButton
          iconProps={{ iconName: 'Upload' }}
          onClick={() => fileInputRef.current.current && fileInputRef.current.current.click()}
          disabled={!!selectedFiles.length}
        >
          {label}
        </ActionButton>
      </Stack>
      <div className={classNames.errorMessage}>{errorMessage}</div>
    </div>
  );
};
