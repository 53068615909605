import {
  AnalyticsStateActionTypes,
  AnalyticsState,
  SET_TOP_QUERIES,
  LOADING_TOP_QUERIES,
  LOADING_NO_RESULTS_QUERIES,
  SET_NO_RESULTS_QUERIES,
  LOADING_QUERY_LANGUAGES,
  SET_QUERY_LANGUAGES,
  LOADING_TOTAL_SEARCH_REQUESTS_OVER_TIME,
  SET_TOTAL_SEARCH_REQUESTS_OVER_TIME,
  LOADING_SUCCESSFUL_REQUESTS_OVER_TIME,
  SET_SUCCESSFUL_REQUESTS_OVER_TIME,
  LOADING_FAILED_REQUESTS_OVER_TIME,
  SET_FAILED_REQUESTS_OVER_TIME,
  LOADING_USER_ERROR_REQUESTS_OVER_TIME,
  SET_USER_ERROR_REQUESTS_OVER_TIME,
  LOADING_DISTINCT_SEARCH_REQUESTS_OVER_TIME,
  SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME,
  LOADING_SEARCH_REQUESTS_LATENCY_VALUE,
  SET_SEARCH_REQUESTS_LATENCY_VALUE,
  LOADING_SORTED_BY_PARAMETERS,
  SET_SORTED_BY_PARAMETERS,
  LOADING_TOTAL_SEARCH_REQUESTS,
  SET_TOTAL_SEARCH_REQUESTS,
  LOADING_TOTAL_DISTINCT_QUERIES,
  SET_TOTAL_DISTINCT_QUERIES,
  LOADING_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS,
  SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS,
  LOADING_REGIONS_DISTRIBUTION,
  SET_REGIONS_DISTRIBUTION,
  LOADING_REGIONS_DISTRIBUTION_FILTER,
  SET_REGIONS_DISTRIBUTION_FILTER,
  LOADING_FILTERS_USAGE,
  SET_FILTERS_USAGE,
  LOADING_RESPONSE_CODES,
  SET_RESPONSE_CODES,
  LOADING_RESPONSE_CODES_FILTER,
  SET_RESPONSE_CODES_FILTER,
  LOADING_AVAILABLE_MARKETS,
  SET_AVAILABLE_MARKETS,
  LOADING_FILTERS_DISTRIBUTION,
  SET_FILTERS_DISTRIBUTION,
  LOADING_SEARCH_INSTANCES_FILTER,
  SET_SEARCH_INSTANCES_FILTER,
  LOADING_SEARCH_INSTANCES_CHART,
  SET_SEARCH_INSTANCES_CHART,
  SET_LOADING_SEARCH_AVG_QPS,
  SET_SEARCH_AVG_QPS,
  SET_SELECTED_REGIONS_FILTER,
  SET_SELECTED_RESPONSE_CODES_FILTER,
  SET_SELECTED_SEARCH_INSTANCES_FILTER,
  SET_SELECTED_SEARCH_RESULTS_FILTER,
  SET_SELECTED_START_DATE_FILTER,
  SET_SELECTED_END_DATE_FILTER,
  DateFormat,
  LOADING_SEARCH_LATENCY_OVER_TIME,
  SET_SEARCH_LATENCY_OVER_TIME,
  SET_SEARCH_LATENCY_PERCENTILE,
  SET_LATENCY_CARD_PERCENTILE,
} from '../types/analytics.d';
import { CachePeriod } from '../types/insights.d';
import { addDays } from '../../utils';
import moment from 'moment';
import { SearchResultsFilter } from '../../restful-apis/dto/analytics/analytics';

const initialState: AnalyticsState = {
  isTotalSearchRequestsLoading: true,
  totalSearchRequests: 0,

  isTotalDistinctQueriesLoading: true,
  totalDistinctQueries: { percentage: 0, count: 0 },

  isTotalSearchRequestsWithNoResultsLoading: true,
  totalSearchRequestsWithNoResults: { percentage: 0, count: 0 },

  isSearchRequestsLatencyValueLoading: true,
  searchRequestsLatencyValue: 0,

  isTotalSearchRequestsOverTimeLoading: true,
  totalSearchRequestsOverTime: { series: [], interval: 0 },

  isSuccessfulRequestsOverTimeLoading: true,
  successfulRequestsOverTime: { series: [], interval: 0 },

  isFailedRequestsOverTimeLoading: true,
  failedRequestsOverTime: { series: [], interval: 0 },

  isUserErrorRequestsOverTimeLoading: true,
  userErrorRequestsOverTime: { series: [], interval: 0 },

  isDistinctSearchRequestsOverTimeLoading: true,
  distinctSearchRequestsOverTime: { series: [], interval: 0 },

  isTopQueriesLoading: true,
  topQueries: [],

  isNoResultsQueriesLoading: true,
  noResultsQueries: [],

  isQueryLanguagesLoading: true,
  queryLanguages: [],

  isSortedByParametersLoading: true,
  sortedByParameters: [],

  isResponseCodesLoading: true,
  responseCodes: [],

  isResponseCodesFilterLoading: true,
  responseCodesFilter: { availableValues: [], selectedValues: { checkedValues: [], selectAll: true } },

  isAvailableMarketsLoading: true,
  availableMarkets: { availableValues: [], selectedValues: { checkedValues: [], selectAll: true } },

  isRegionsDistributionLoading: true,
  regionsDistribution: [],

  isRegionsDistributionFilterLoading: true,
  regionsDistributionFilter: { availableValues: [], selectedValues: { checkedValues: [], selectAll: true } },

  isFiltersUsageLoading: true,
  filtersUsage: [],

  isFiltersDistributionLoading: true,
  filtersDistribution: [],

  isSearchInstancesChartLoading: true,
  searchInstancesChart: [],

  isSearchInstancesFilterLoading: true,
  searchInstancesFilter: { availableValues: [], selectedValues: { checkedValues: [], selectAll: true } },

  searchResultsFilter: {
    availableValues: [SearchResultsFilter.HasResults, SearchResultsFilter.NotResults],
    selectedValues: { checkedValues: [], selectAll: true },
  },

  startDateFilter: addDays(-1, new Date(moment.utc().format(DateFormat))),
  endDateFilter: new Date(moment.utc().format(DateFormat)),

  searchAvgQPS: {
    value: NaN,
    cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
  },
  isSearchAvgQPSLoading: true,

  isSearchLatencyOverTimeLoading: true,
  searchLatencyOverTime: { series: [], interval: 0 },
  searchLatencyPercentile: 75,
  latencyCardPercentile: NaN,
};

export function analyticsReducer(state = initialState, action: AnalyticsStateActionTypes): AnalyticsState {
  switch (action.type) {
    case LOADING_TOP_QUERIES: {
      return {
        ...state,
        isTopQueriesLoading: true,
      };
    }
    case SET_TOP_QUERIES: {
      return {
        ...state,
        isTopQueriesLoading: false,
        topQueries: action.topQueries,
      };
    }
    case LOADING_NO_RESULTS_QUERIES: {
      return {
        ...state,
        isNoResultsQueriesLoading: true,
      };
    }
    case SET_NO_RESULTS_QUERIES: {
      return {
        ...state,
        isNoResultsQueriesLoading: false,
        noResultsQueries: action.noResultsQueries,
      };
    }
    case LOADING_QUERY_LANGUAGES: {
      return {
        ...state,
        isQueryLanguagesLoading: true,
      };
    }
    case SET_QUERY_LANGUAGES: {
      return {
        ...state,
        isQueryLanguagesLoading: false,
        queryLanguages: action.queryLanguages,
      };
    }
    case LOADING_TOTAL_SEARCH_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isTotalSearchRequestsOverTimeLoading: true,
      };
    }
    case SET_TOTAL_SEARCH_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isTotalSearchRequestsOverTimeLoading: false,
        totalSearchRequestsOverTime: action.totalSearchRequestsOverTime,
      };
    }
    case LOADING_SUCCESSFUL_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isSuccessfulRequestsOverTimeLoading: true,
      };
    }
    case SET_SUCCESSFUL_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isSuccessfulRequestsOverTimeLoading: false,
        successfulRequestsOverTime: action.successfulRequestsOverTime,
      };
    }
    case LOADING_FAILED_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isFailedRequestsOverTimeLoading: true,
      };
    }
    case SET_FAILED_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isFailedRequestsOverTimeLoading: false,
        failedRequestsOverTime: action.failedRequestsOverTime,
      };
    }
    case LOADING_USER_ERROR_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isUserErrorRequestsOverTimeLoading: true,
      };
    }
    case SET_USER_ERROR_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isUserErrorRequestsOverTimeLoading: false,
        userErrorRequestsOverTime: action.userErrorRequestsOverTime,
      };
    }
    case LOADING_DISTINCT_SEARCH_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isDistinctSearchRequestsOverTimeLoading: true,
      };
    }
    case SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME: {
      return {
        ...state,
        isDistinctSearchRequestsOverTimeLoading: false,
        distinctSearchRequestsOverTime: action.distinctSearchRequestsOverTime,
      };
    }
    case LOADING_SEARCH_REQUESTS_LATENCY_VALUE: {
      return {
        ...state,
        isSearchRequestsLatencyValueLoading: true,
      };
    }
    case SET_SEARCH_REQUESTS_LATENCY_VALUE: {
      return {
        ...state,
        isSearchRequestsLatencyValueLoading: false,
        searchRequestsLatencyValue: action.searchRequestsLatencyValue,
      };
    }
    case LOADING_SORTED_BY_PARAMETERS: {
      return {
        ...state,
        isSortedByParametersLoading: true,
      };
    }
    case SET_SORTED_BY_PARAMETERS: {
      return {
        ...state,
        isSortedByParametersLoading: false,
        sortedByParameters: action.sortedByParameters,
      };
    }
    case LOADING_TOTAL_SEARCH_REQUESTS: {
      return {
        ...state,
        isTotalSearchRequestsLoading: true,
      };
    }
    case SET_TOTAL_SEARCH_REQUESTS: {
      return {
        ...state,
        isTotalSearchRequestsLoading: false,
        totalSearchRequests: action.totalSearchRequests,
      };
    }
    case LOADING_TOTAL_DISTINCT_QUERIES: {
      return {
        ...state,
        isTotalDistinctQueriesLoading: true,
      };
    }
    case SET_TOTAL_DISTINCT_QUERIES: {
      return {
        ...state,
        isTotalDistinctQueriesLoading: false,
        totalDistinctQueries: action.totalDistinctQueries,
      };
    }
    case LOADING_FILTERS_DISTRIBUTION: {
      return {
        ...state,
        isFiltersDistributionLoading: true,
      };
    }
    case SET_FILTERS_DISTRIBUTION: {
      return {
        ...state,
        isFiltersDistributionLoading: false,
        filtersDistribution: action.filtersDistribution,
      };
    }
    case LOADING_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS: {
      return {
        ...state,
        isTotalSearchRequestsWithNoResultsLoading: true,
      };
    }
    case SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS: {
      return {
        ...state,
        isTotalSearchRequestsWithNoResultsLoading: false,
        totalSearchRequestsWithNoResults: action.totalSearchRequestsWithNoResults,
      };
    }
    case LOADING_REGIONS_DISTRIBUTION: {
      return {
        ...state,
        isRegionsDistributionLoading: true,
      };
    }
    case SET_REGIONS_DISTRIBUTION: {
      return {
        ...state,
        isRegionsDistributionLoading: false,
        regionsDistribution: action.regionsDistribution,
      };
    }
    case LOADING_REGIONS_DISTRIBUTION_FILTER: {
      return {
        ...state,
        isRegionsDistributionFilterLoading: true,
      };
    }
    case SET_REGIONS_DISTRIBUTION_FILTER: {
      return {
        ...state,
        isRegionsDistributionLoading: false,
        regionsDistributionFilter: {
          ...state.regionsDistributionFilter,
          availableValues: action.regionsDistributionFilter,
        },
      };
    }
    case LOADING_FILTERS_USAGE: {
      return {
        ...state,
        isFiltersUsageLoading: true,
      };
    }
    case SET_FILTERS_USAGE: {
      return {
        ...state,
        isFiltersUsageLoading: false,
        filtersUsage: action.filtersUsage,
      };
    }
    case LOADING_RESPONSE_CODES: {
      return {
        ...state,
        isResponseCodesLoading: true,
      };
    }
    case SET_RESPONSE_CODES: {
      return {
        ...state,
        isResponseCodesLoading: false,
        responseCodes: action.responseCodes,
      };
    }
    case LOADING_RESPONSE_CODES_FILTER: {
      return {
        ...state,
        isResponseCodesFilterLoading: true,
      };
    }
    case SET_RESPONSE_CODES_FILTER: {
      return {
        ...state,
        isResponseCodesFilterLoading: false,
        responseCodesFilter: {
          ...state.responseCodesFilter,
          availableValues: action.responseCodesFilter,
        },
      };
    }
    case LOADING_AVAILABLE_MARKETS: {
      return {
        ...state,
        isAvailableMarketsLoading: true,
      };
    }
    case SET_AVAILABLE_MARKETS: {
      return {
        ...state,
        isAvailableMarketsLoading: false,
        availableMarkets: {
          ...state.availableMarkets,
          availableValues: action.availableMarkets,
        },
      };
    }
    case LOADING_SEARCH_INSTANCES_CHART: {
      return {
        ...state,
        isSearchInstancesChartLoading: true,
      };
    }
    case SET_SEARCH_INSTANCES_CHART: {
      return {
        ...state,
        isSearchInstancesChartLoading: false,
        searchInstancesChart: action.searchInstancesChart,
      };
    }
    case LOADING_SEARCH_INSTANCES_FILTER: {
      return {
        ...state,
        isSearchInstancesFilterLoading: true,
      };
    }
    case SET_SEARCH_INSTANCES_FILTER: {
      return {
        ...state,
        isSearchInstancesFilterLoading: false,
        searchInstancesFilter: {
          ...state.searchInstancesFilter,
          availableValues: action.searchInstancesFilter,
        },
      };
    }
    case SET_SELECTED_REGIONS_FILTER: {
      return {
        ...state,
        regionsDistributionFilter: {
          ...state.regionsDistributionFilter,
          selectedValues: {
            checkedValues: action.checkedValues,
            selectAll: action.selectAll,
          },
        },
      };
    }
    case SET_SELECTED_RESPONSE_CODES_FILTER: {
      return {
        ...state,
        responseCodesFilter: {
          ...state.responseCodesFilter,
          selectedValues: {
            checkedValues: action.checkedValues,
            selectAll: action.selectAll,
          },
        },
      };
    }
    case SET_SELECTED_SEARCH_INSTANCES_FILTER: {
      return {
        ...state,
        searchInstancesFilter: {
          ...state.searchInstancesFilter,
          selectedValues: {
            checkedValues: action.checkedValues,
            selectAll: action.selectAll,
          },
        },
      };
    }
    case SET_SELECTED_SEARCH_RESULTS_FILTER: {
      return {
        ...state,
        searchResultsFilter: {
          ...state.searchResultsFilter,
          selectedValues: {
            checkedValues: action.checkedValues,
            selectAll: action.selectAll,
          },
        },
      };
    }
    case SET_SELECTED_START_DATE_FILTER: {
      return {
        ...state,
        startDateFilter: action.startDateFilter,
      };
    }
    case SET_SELECTED_END_DATE_FILTER: {
      return {
        ...state,
        endDateFilter: action.endDateFilter,
      };
    }
    case SET_LOADING_SEARCH_AVG_QPS: {
      return {
        ...state,
        isSearchAvgQPSLoading: action.isSearchAvgQPSLoading,
      };
    }
    case SET_SEARCH_AVG_QPS: {
      return {
        ...state,
        isSearchAvgQPSLoading: false,
        searchAvgQPS: action.searchAvgQPS,
      };
    }
    case LOADING_SEARCH_LATENCY_OVER_TIME: {
      return {
        ...state,
        isSearchLatencyOverTimeLoading: true,
      };
    }
    case SET_SEARCH_LATENCY_OVER_TIME: {
      return {
        ...state,
        isSearchLatencyOverTimeLoading: false,
        searchLatencyOverTime: action.searchLatencyOverTime,
      };
    }
    case SET_SEARCH_LATENCY_PERCENTILE: {
      return {
        ...state,
        searchLatencyPercentile: action.searchLatencyPercentile,
      };
    }
    case SET_LATENCY_CARD_PERCENTILE: {
      return {
        ...state,
        latencyCardPercentile: action.latencyCardPercentile,
      };
    }
    default:
      return state;
  }
}
