import {
  ProgressIndicatorActionTypes,
  SHOW_PROGRESS_INDICATOR,
  HIDE_PROGRESS_INDICATOR,
} from '../types/progressIndicator.d';

export function showProgressIndicator(): ProgressIndicatorActionTypes {
  return {
    type: SHOW_PROGRESS_INDICATOR,
  };
}

export function hideProgressIndicator(): ProgressIndicatorActionTypes {
  return {
    type: HIDE_PROGRESS_INDICATOR,
  };
}
