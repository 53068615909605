import { SearchIndexFieldTypeState, SearchIndexFieldTypeConfig } from './SchemaManagement.types';
import _ from 'lodash';
import moment from 'moment';

const GeoPointRegex = new RegExp(/^([-+]?\d{1,2}([.]\d+)?),\s*([-+]?\d{1,3}([.]\d+)?)$/);

const matchesDateType = (value: any): boolean => moment(value, moment.ISO_8601, true).isValid();

const matchesObjectType = (value: any): boolean => _.isPlainObject(value);

const matchesBooleanType = (value: any): boolean =>
  _.isBoolean(value) || (_.isString(value) && ['true', 'false'].includes(value.toLowerCase()));

const matchesNumericType = (value: any): boolean => _.isNumber(value) || !isNaN(value);

const matchesStringType = (value: any): boolean => _.isString(value);

const matchesArrayType = (value: any): boolean => _.isArray(value);

const matchesGeoPointType = (value: any): boolean => GeoPointRegex.test(value);

const matchesDateSegmentType = (value: any): boolean => _.isArray(value);

export const SearchIndexTypeConfig: { [key: string]: SearchIndexFieldTypeConfig } = {
  Array: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesArrayType,
    dataType: 'Array',
    defaultValue: [],
  },
  Boolean: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Disabled,
    matches: matchesBooleanType,
    dataType: 'Boolean',
    defaultValue: false,
  },
  Number: {
    searchable: SearchIndexFieldTypeState.Disabled,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesNumericType,
    dataType: 'Numeric',
    defaultValue: 0,
  },
  String: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Object: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesObjectType,
    dataType: 'Object',
    defaultValue: {},
  },
  DateTime: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesDateType,
    dataType: 'DateTime',
    defaultValue: '',
  },
  Identifier: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  ProductId: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Required,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Url: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Disabled,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  ImageUrl: {
    searchable: SearchIndexFieldTypeState.Disabled,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Disabled,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  DateSegment: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesDateSegmentType,
    dataType: 'DateSegment',
    defaultValue: [],
  },
  Title: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Disabled,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Description: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Disabled,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Category: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Required,
    sortable: SearchIndexFieldTypeState.Required,
    matches: matchesArrayType,
    dataType: 'Array',
    defaultValue: [],
  },
  Price: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Required,
    sortable: SearchIndexFieldTypeState.Required,
    matches: matchesNumericType,
    dataType: 'Double',
    defaultValue: 0.0,
  },
  Rating: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Required,
    sortable: SearchIndexFieldTypeState.Required,
    matches: matchesNumericType,
    dataType: 'Numeric',
    defaultValue: 0,
  },
  Brand: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Required,
    sortable: SearchIndexFieldTypeState.Required,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Model: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Color: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Size: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Material: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Gender: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  AgeGroup: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  ExcludeFlag: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Disabled,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesBooleanType,
    dataType: 'Boolean',
    defaultValue: false,
  },
  DupId: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Disabled,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  DocumentId: {
    searchable: SearchIndexFieldTypeState.Required,
    retrievable: SearchIndexFieldTypeState.Required,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Disabled,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Author: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesArrayType,
    dataType: 'Array',
    defaultValue: [],
  },
  ModifiedAt: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesDateType,
    dataType: 'DateTime',
    defaultValue: '',
  },
  CreatedAt: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesDateType,
    dataType: 'DateTime',
    defaultValue: '',
  },
  Paragraph: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  SubHeading: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  SectionHeader: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  RatingCount: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesNumericType,
    dataType: 'Numeric',
    defaultValue: 0,
  },
  ReviewCount: {
    searchable: SearchIndexFieldTypeState.Disabled,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesNumericType,
    dataType: 'Numeric',
    defaultValue: 0,
  },
  RatingScale: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesNumericType,
    dataType: 'Numeric',
    defaultValue: 0,
  },
  Amenities: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesArrayType,
    dataType: 'Array',
    defaultValue: [],
  },
  Address: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  StreetAddress: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Locality: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  AddressRegion: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  SubRegion: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  PostalCode: {
    searchable: SearchIndexFieldTypeState.Disabled,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  PostOfficeBoxNumber: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Country: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  CountryIso: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  Neighborhood: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  OtherAreas: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesArrayType,
    dataType: 'Array',
    defaultValue: [],
  },
  PhoneNumber: {
    searchable: SearchIndexFieldTypeState.Disabled,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Required,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  GeoPoint: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesGeoPointType,
    dataType: 'GeoPoint',
    defaultValue: '',
  },
  Barcode: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesStringType,
    dataType: 'String',
    defaultValue: '',
  },
  SecondaryImageUrls: {
    searchable: SearchIndexFieldTypeState.Optional,
    retrievable: SearchIndexFieldTypeState.Optional,
    filterable: SearchIndexFieldTypeState.Optional,
    facetable: SearchIndexFieldTypeState.Optional,
    sortable: SearchIndexFieldTypeState.Optional,
    matches: matchesArrayType,
    dataType: 'Array',
    defaultValue: [],
  },
};
