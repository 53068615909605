import { ReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../../CrudAction.types';
import { EnvironmentWithRoles, Environment } from '../../../../types/environmentList.d';
import { Role, CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST } from '../../../../types/roleList.d';
import { createOrUpdateEnvironmentRoles } from '../../../../../restful-apis/role.api';
import { deleteEnvironment } from '../../../environmentListActions';

export const environmentRoleListCreateAction = (
  tenantId: string,
  createdEnvironment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ReduxCrudAction<Role[]> => ({
  request: createOrUpdateEnvironmentRoles(tenantId, createdEnvironment.id, createdEnvironment.roleIdList),
  postActionMapper: updatedRoleList => ({
    type: CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST,
    roleList: updatedRoleList,
    environmentId: createdEnvironment.id,
  }),
  onSuccess: () => onSuccess && onSuccess(createdEnvironment),
  onFailure: (exception, dispatch) =>
    dispatch(
      deleteEnvironment(
        tenantId,
        createdEnvironment,
        deletedEnvironment => onFailure && onFailure(exception, deletedEnvironment),
      ),
    ),
});

export const environmentRoleListUpdateAction = (
  tenantId: string,
  updatedEnvironment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ReduxCrudAction<Role[]> => ({
  request: createOrUpdateEnvironmentRoles(tenantId, updatedEnvironment.id, updatedEnvironment.roleIdList),
  postActionMapper: updatedRoleList => ({
    type: CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST,
    roleList: updatedRoleList,
    environmentId: updatedEnvironment.id,
  }),
  onSuccess: () => onSuccess && onSuccess(updatedEnvironment),
  onFailure: response => onFailure && onFailure(response, updatedEnvironment),
});
