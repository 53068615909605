import * as React from 'react';
import { CheckProps, CheckStyleProps, CheckStyles, CheckboxProps } from './Check.types';
import { CheckBase, CheckboxBase } from './Check.base';
import { getStyles } from './Check.styles';
import { styled } from 'office-ui-fabric-react';

export const Check: React.FunctionComponent<CheckProps> = styled<CheckProps, CheckStyleProps, CheckStyles>(
  CheckBase,
  getStyles,
  undefined,
  {
    scope: 'Check',
  },
  true,
);

export const Checkbox: React.FunctionComponent<CheckboxProps> = styled<CheckboxProps, CheckStyleProps, CheckStyles>(
  CheckboxBase,
  getStyles,
  undefined,
  {
    scope: 'Check',
  },
  true,
);
