import { TenantsListStyleProps, TenantsListStyles } from './TenantsList.types';

export const styles = (props: TenantsListStyleProps): TenantsListStyles => {
  return {
    root: ['mbc-tenants-list', props.className],
    searchCommandBar: [
      'mbc-tenants-list-search-bar',
      {
        marginBottom: 10,
        marginTop: 10,
      },
    ],
  };
};
