import { useSelector } from '..';
import { useMemo } from 'react';
import { getLatestJob, getLocaleTimeNow } from '../../../utils';
import { SearchModel, ModelJobStatus } from '../../types/customML/searchModel.d';
import { DeploymentJob } from '../../types/customML/deploymentJob.d';
import { useActiveTraining } from './useActiveTraining';
import { createInitialDeploymentJob } from '../../reducers/customML/deploymentJobReducer';

export const useActiveDeployment = (): DeploymentJob => {
  const trainingJob = useActiveTraining();
  const userEmail = useSelector<string>(state => state.user.email);
  const deploymentJobMapper = useSelector(state => state.deploymentJobs.deploymentJobMapper);
  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);

  return useMemo(() => {
    const deploymentJob = getLatestJob(
      searchModel.deploymentJobKeys.map(deploymentJobKey => deploymentJobMapper[deploymentJobKey]),
    );

    return !!deploymentJob && !!deploymentJob.key
      ? deploymentJob
      : {
          ...createInitialDeploymentJob(),
          email: userEmail,
          name: searchModel.name,
          createdAt: getLocaleTimeNow(),
          updatedAt: getLocaleTimeNow(),
          status: ModelJobStatus.NotStarted,
          description: searchModel.description,
          deployedModelKey: trainingJob.rankerModelKey,
        };
  }, [userEmail, searchModel, deploymentJobMapper, trainingJob]);
};
