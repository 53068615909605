import { PageFooterStyles, PageFooterStyleProps } from './PageFooter.types';

export const styles = (props: PageFooterStyleProps): PageFooterStyles => {
  const { className, theme } = props;

  return {
    root: [
      'mbc-page-footer',
      {
        alignItems: 'flex-end',
        backgroundColor: theme.palette.neutralLighter,
        paddingRight: '96px',
        height: '48px',
      },
      className,
    ],
  };
};
