import {
  mapTokenListDtoToEncodedToken,
  mapTokenListDtoToModel,
  mapTokenListDtoToSingleModel,
  mapTokenModelToDTO,
} from './mappers/tokenMapper';

import HttpClient from './http-client/httpClient';
import { TokenList as TokenListDto } from './dto/tokens/response/tokenList';
import { Token as TokenModel } from '../store/types/tokenList';
import { TokenWithPermissions as TokenWithPermissionsModel } from '../store/types/tokenList';

function getAdminApiV2BaseUri(): string {
  return 'https://commerce.bing.com/api/admin/v2';
}

/**
 * Constructs a get http request that returns the tokens.
 *
 * @param tenantId       The tokens tenant.
 *
 * @return The tokens, with the response body as an `Token` array.
 */
export function getTenantTokens(tenantId: string): Promise<TokenModel[]> {
  return HttpClient.get<TokenListDto>({ url: `${getAdminApiV2BaseUri()}/tenants/${tenantId}/tokens` }).then(
    mapTokenListDtoToModel,
  );
}

/**
 * Constructs a post http request that revokes a token.
 *
 * @param tenantId       The tokens tenant.
 * @param tokenId        The revoked token ID.
 */
export function revokeTenantToken(tenantId: string, tokenId: string): Promise<TokenModel> {
  return HttpClient.delete<TokenListDto>({
    url: `${getAdminApiV2BaseUri()}/tenants/${tenantId}/tokens/${tokenId}`,
    body: {},
    supressNotificationOnFailure: true,
  }).then(mapTokenListDtoToSingleModel);
}

/**
 * Constructs a post http request that adds a token.
 *
 * @param tenantId       The tokens tenant.
 *
 */
export function createTenantToken(tenantId: string, token: TokenWithPermissionsModel): Promise<TokenModel> {
  return HttpClient.post<TokenListDto>({
    url: `${getAdminApiV2BaseUri()}/tenants/${tenantId}/tokens`,
    body: mapTokenModelToDTO(token),
    supressNotificationOnFailure: true,
  }).then(mapTokenListDtoToSingleModel);
}

/**
 * Constructs a post http request that adds a token.
 *
 * @param tenantId       The tokens tenant.
 *
 */
export function getIndexToken(tenantId: string, indexId: string): Promise<string> {
  return HttpClient.get<TokenListDto>({
    url: `${getAdminApiV2BaseUri()}/tenants/${tenantId}/indexes/${indexId}/tokens`,
    supressNotificationOnFailure: true,
  }).then(mapTokenListDtoToEncodedToken);
}

/**
 * Constructs a post http request that adds a token.
 *
 * @param tenantId       The tokens tenant.
 *
 */
export function getTenantToken(tenantId: string): Promise<string> {
  return HttpClient.get<TokenListDto>({
    url: `${getAdminApiV2BaseUri()}/tenants/${tenantId}/tokens/default`,
    supressNotificationOnFailure: true,
  }).then(mapTokenListDtoToEncodedToken);
}

/**
 * Constructs a post http request that adds a token.
 *
 * @param tenantId       The tokens tenant.
 *
 */
export function getOobeDemoToken(): Promise<string> {
  return HttpClient.get<TokenListDto>({
    url: `${getAdminApiV2BaseUri()}/tokens/demo`,
    isPublic: true,
    supressNotificationOnFailure: true,
  }).then(mapTokenListDtoToEncodedToken);
}
