import { generateUniqueIdentifier } from '../../utils/index';
import { Action } from 'redux';

export const LOADING_SEARCH_INDEX_LIST = 'LOADING_SEARCH_INDEX_LIST';
export const LOADING_ACTIVE_SEARCH_INDEX = 'LOADING_ACTIVE_SEARCH_INDEX';
export const SAVE_ACTIVE_SEARCH_INDEX = 'SAVE_ACTIVE_SEARCH_INDEX';
export const RESET_ACTIVE_SEARCH_INDEX = 'RESET_ACTIVE_SEARCH_INDEX';
export const SET_ACTIVE_SEARCH_INDEX = 'SET_ACTIVE_SEARCH_INDEX';
export const DISMISS_SEARCH_INDEX = 'DISMISS_SEARCH_INDEX';
export const SET_SEARCH_INDEX_LIST = 'SET_SEARCH_INDEX_LIST';
export const RESET_SEARCH_INDEX_LIST = 'RESET_SEARCH_INDEX_LIST';

export enum ProvisioningState {
  Unknown = 'Unknown',
  NotStarted = 'NotStarted',
  Provisioning = 'Provisioning',
  Deprovisioning = 'Deprovisioning',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
}

export interface SearchIndexIdMapper {
  [key: string]: SearchIndex;
}

export interface SearchIndex {
  id: string;
  name: string;
  description: string;
  searchScenario?: string;
  fields: IndexField[];
  regions: string[];
  status: ProvisioningState;
  transformationScript?: string;
  searchServices: string[];
  schemaVersion?: string;
  devOpsEnabled: boolean;
}

export interface IndexFieldFeatures {
  sortable: boolean;
  facetable: boolean;
  filterable: boolean;
  retrievable: boolean;
  searchable: boolean;
}

export interface IndexField {
  key: string;
  name: string;
  type: string;
  features: IndexFieldFeatures;
  fields?: IndexField[];
}

export interface TransformationConfig {
  instanceId?: string;
  transformationScript?: string;
}

export interface SearchIndexState {
  isLoading: boolean;
  searchIndex: SearchIndex;
  isSearchIndexListLoading: boolean;
  editableSearchIndexSet: Set<string>;
  searchIndexIdMapper: SearchIndexIdMapper;
}

export interface HierarchalIndexField extends IndexField {
  hierarchalFieldName: string;
}

// TODO: revamp this function to reuse the config from instanceFieldType.config.ts
export function createInitialIndexField(name: string = '', type: string = ''): InstanceField {
  return {
    key: generateUniqueIdentifier(),
    name: name,
    type: type,
    features: {
      facetable: false,
      retrievable: false,
      filterable: type === 'ProductId',
      searchable: false,
      sortable: false,
    },
  };
}

export function createInitialSearchIndex(): SearchIndex {
  return {
    id: '',
    name: '',
    regions: [],
    description: '',
    transformationScript: '',
    status: ProvisioningState.Unknown,
    fields: [createInitialIndexField('ProductId', 'ProductId')],
  };
}

interface SetActiveSearchIndex extends Action<typeof SET_ACTIVE_SEARCH_INDEX> {
  searchIndexId: string;
  transformationScript: string;
}

interface SetSearchIndexList extends Action<typeof SET_SEARCH_INDEX_LIST> {
  searchIndexList: SearchIndex[];
}

interface SaveActiveSearchIndex extends Action<typeof SAVE_ACTIVE_SEARCH_INDEX> {
  searchIndex: SearchIndex;
}

export type SearchIndexStateActionTypes =
  | SetActiveSearchIndex
  | SaveActiveSearchIndex
  | SetSearchIndexList
  | Action<typeof RESET_SEARCH_INDEX_LIST>
  | Action<typeof RESET_SEARCH_INDEX_LIST>
  | Action<typeof RESET_ACTIVE_SEARCH_INDEX>
  | Action<typeof LOADING_SEARCH_INDEX_LIST>
  | Action<typeof LOADING_ACTIVE_SEARCH_INDEX>;
