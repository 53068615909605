import { Tenant } from '../../store/types/tenantsList.d';
import { MbcRouterBase } from '../MbcRouter';
import { CustomMLRouteKey, CustomMLEvaluationRouteKey } from '../MbcRouter.types';
import { SearchModel } from '../../store/types/customML/searchModel.d';
import { EvaluationJob } from '../../store/types/customML/evaluationJob';
import { MbcCustomMLRouteMapper, MbcCustomMLEvaluationRouteMapper } from './MbcCustomMLRouter.config';

export const MbcCustomMLRouter = (tenant: Tenant, searchModel: SearchModel) =>
  MbcRouterBase<CustomMLRouteKey>(MbcCustomMLRouteMapper(tenant, searchModel));

export const MbcCustomMLEvaluationRouter = (tenant: Tenant, searchModel: SearchModel, evaluationJob: EvaluationJob) =>
  MbcRouterBase<CustomMLEvaluationRouteKey>(MbcCustomMLEvaluationRouteMapper(tenant, searchModel, evaluationJob));
