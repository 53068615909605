import {
  SynonymSet,
  SET_ACTIVE_SYNONYM_SET,
  RESET_ACTIVE_SYNONYM_SET,
  SET_SYNONYM_SET_LIST,
  RESET_SYNONYM_SET_LIST,
  LOADING_SYNONYM_SET_LIST,
  CREATE_OR_UPDATE_SYNONYM_SET,
  DELETE_SYNONYM_SET,
  SynonymSetState,
  SynonymSetIdMapper,
  SynonymSetActionTypes,
} from '../../types/customizations/synonymSet.d';
import { configureDefaultObject } from '../../../utils';

export const createEmptySynonymSet = () => {
  return {
    id: '',
    synonymList: [],
  };
};

const createSynonymSetIdMapper = (initialMapper: SynonymSetIdMapper = {}) => {
  return configureDefaultObject<SynonymSetIdMapper, SynonymSet>(initialMapper, createEmptySynonymSet());
};

const initialState: SynonymSetState = {
  isLoading: true,
  activeSynonymSet: createEmptySynonymSet(),
  synonymSetIdMapper: createSynonymSetIdMapper(),
};

export function synonymSetReducer(state = initialState, action: SynonymSetActionTypes): SynonymSetState {
  switch (action.type) {
    case SET_ACTIVE_SYNONYM_SET: {
      return {
        ...state,
        activeSynonymSet: state.synonymSetIdMapper[action.id.toLowerCase()],
      };
    }
    case RESET_ACTIVE_SYNONYM_SET: {
      return {
        ...state,
        activeSynonymSet: createEmptySynonymSet(),
      };
    }
    case LOADING_SYNONYM_SET_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_SYNONYM_SET_LIST: {
      const synonymSetIdMapper = createSynonymSetIdMapper({
        ...action.synonymSetList.reduce((mapper: SynonymSetIdMapper, synonymSet: SynonymSet) => {
          mapper[synonymSet.id.toLowerCase()] = synonymSet;
          return mapper;
        }, {}),
      });
      return {
        ...state,
        isLoading: false,
        synonymSetIdMapper: synonymSetIdMapper,
      };
    }
    case RESET_SYNONYM_SET_LIST: {
      return {
        ...state,
        isLoading: true,
        synonymSetIdMapper: createSynonymSetIdMapper(),
      };
    }
    case CREATE_OR_UPDATE_SYNONYM_SET: {
      const updatedSynonymSetIdMapper = createSynonymSetIdMapper({
        ...state.synonymSetIdMapper,
        [action.synonymSet.id.toLowerCase()]: action.synonymSet,
      });
      return {
        ...state,
        synonymSetIdMapper: updatedSynonymSetIdMapper,
      };
    }
    case DELETE_SYNONYM_SET: {
      var updatedSynonymSetIdMapper = createSynonymSetIdMapper({ ...state.synonymSetIdMapper });
      delete updatedSynonymSetIdMapper[action.id.toLowerCase()];

      return {
        ...state,
        synonymSetIdMapper: updatedSynonymSetIdMapper,
      };
    }

    default:
      return state;
  }
}
