import { CustomMLCardStyles, CustomMLCardStyleProps, CardState } from './CustomMLCard.types';
import { keyframes } from 'office-ui-fabric-react';
import { getFlexRowStyles } from '../../../../components/common/Styles';

export const styles = (props: CustomMLCardStyleProps): CustomMLCardStyles => ({
  root: [
    'mbc-custom-ml-card',
    {
      selectors: {
        '.mbc-content-card': {
          width: 260,
          height: 280,
        },
        '.mbc-content-card-body': {
          padding: 16,
        },
      },
    },
    props.state === CardState.InActive && {
      selectors: {
        '.mbc-content-card': {
          backgroundColor: props.theme.palette.neutralLight,
        },
      },
    },
    props.className,
  ],
  title: [
    'mbc-card-title',
    {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: 500,
      color: '#171717',
    },
    props.state === CardState.InActive && {
      color: props.theme.palette.neutralSecondaryAlt,
    },
  ],
  itemIcon: [
    'mbc-card-icon',
    {
      color: props.theme.palette.themePrimary,
    },
  ],
  itemLabel: [
    'mbc-card-label',
    {
      fontSize: 14,
      lineHeight: 20,
      color: '#333333',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  ],
  actionIcon: [
    'mbc-action-icon',
    {
      height: 50,
      width: 50,
      borderRadius: '50%',
      backgroundColor: '#F4F6FF',
    },
    props.state === CardState.InProgress && getFlexRowStyles(),
    props.state === CardState.InProgress && {
      justifyContent: 'center',
      alignItems: 'center',
      color: props.theme.palette.themePrimary,
      backgroundColor: '#F4F5F7',
      animation: keyframes({
        from: {
          transform: 'rotate(0deg)',
        },
        to: {
          transform: 'rotate(360deg)',
        },
      }),
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
    props.state === CardState.InActive && {
      color: props.theme.palette.neutralSecondaryAlt,
      backgroundColor: props.theme.palette.neutralLighter,
    },
  ],
  actionLabel: [
    'mbc-action-label',
    {
      letterSpacing: '-0.08px',
      color: props.theme.palette.themePrimary,
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 21,
      borderColor: props.theme.palette.themePrimary,
      selectors: {
        ':hover': {
          color: props.theme.palette.themePrimary,
          backgroundColor: props.theme.palette.neutralLighterAlt,
        },
      },
    },
    ((props.state === CardState.Completed && props.disabledOnCompletion) ||
      props.state === CardState.InActive ||
      props.state === CardState.InProgress) && {
      color: props.theme.palette.neutralSecondaryAlt,
      border: 'none',
      backgroundColor: props.theme.palette.neutralLighter,
    },
  ],
  contentContainer: [
    'mbc-completed-content',
    {
      display: 'grid',
      gridRowGap: '8px',
      padding: '0 12px',
      justifyContent: 'space-between',
      gridTemplateColumns: 'auto auto',
      selectors: {
        '.mbc-card-title': {
          marginBottom: 4,
        },
      },
    },
  ],
});
