import React from 'react';
import { useSelector } from 'react-redux';
import { TotalPercentagContent } from '../../../../store/types/analytics.d';
import { AppState } from '../../../../store/reducers';
import { PieChart, NameValuePercentage } from '../../../../components/common/charts/PieChart';
import { ChartContentCard } from '../Common/ChartContentCard';
import { getPieChartColors } from '../../../../components/common/charts/chartsUtils';
import numeral from 'numeral';
import { SearchInstancesProps, SearchInstancesStyleProps, SearchInstancesStyles } from './SearchInstances.types';
import { classNamesFunction } from 'office-ui-fabric-react';
import { useDispatch } from '../../../../store/hooks';
import { SetsearchInstancesChart } from '../../../../store/actions/analyticsActions';

const getClassNames = classNamesFunction<SearchInstancesStyleProps, SearchInstancesStyles>();

export const SearchInstancesBase = (props: SearchInstancesProps) => {
  const { t, onSearchInstanceInvoked, className, theme, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  const searchInstances = useSelector((state: AppState) => state.analytics.searchInstancesChart);
  const isSearchInstancesLoading = useSelector((state: AppState) => state.analytics.isSearchInstancesChartLoading);

  const searchInstancesGrouped = searchInstances.length > 8 ? [...searchInstances.slice(0, 8)] : searchInstances;
  return (
    <div className={classNames.root}>
      <ChartContentCard
        exportData={{ fileName: t('analytics.search-instances-chart.title'), data: searchInstances }}
        chart={
          <PieChart
            isLoading={isSearchInstancesLoading}
            pieColors={getPieChartColors(156, 43, searchInstancesGrouped.map(searchInstance => searchInstance.content))}
            noDataLabel={t('common.charts-no-data')}
            isLoadingLabel={t('common.charts-loading')}
            onClick={(item: NameValuePercentage) => onSearchInstanceInvoked && onSearchInstanceInvoked(item.name)}
            data={searchInstancesGrouped.map(data => ({
              name: data.content,
              value: data.count,
              percentage: data.percentage,
            }))}
          />
        }
        title={t('analytics.search-instances-chart.title')}
        isLoadedAndEmpty={!isSearchInstancesLoading && !searchInstances.length}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
        ) =>
          dispatch(
            SetsearchInstancesChart(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
          )
        }
        cardExpandDetails={
          !isSearchInstancesLoading && searchInstances.length > 8
            ? {
                footerLinkText: t('analytics.search-instances-chart.see-more'),
                modalTitle: t('analytics.search-instances-chart.title'),
                items: searchInstances,
                columns: [
                  {
                    key: 'Search instance name',
                    name: t('analytics.search-instances-chart.search-instance-name-label'),
                    fieldName: 'content',
                    minWidth: 150,
                    isResizable: true,
                    isMultiline: false,
                    onRender: (item?: TotalPercentagContent): React.ReactNode =>
                      item && (
                        <div
                          data-selection-disabled={true}
                          title={item.content}
                          className={classNames.modalFirstColumn}
                        >
                          {item.content}
                        </div>
                      ),
                  },
                  {
                    key: 'count',
                    name: t('analytics.search-instances-chart.count-label'),
                    fieldName: 'count',
                    minWidth: 100,
                    isMultiline: false,
                  },
                  {
                    key: 'percentage',
                    name: t('analytics.search-instances-chart.percentage-label'),
                    fieldName: 'percentage',
                    minWidth: 100,
                    isMultiline: false,
                    // eslint-disable-next-line react/display-name
                    onRender: (item: TotalPercentagContent) => {
                      return <span>{numeral(item.percentage).format('0.0000') + '%'}</span>;
                    },
                  },
                ],
              }
            : undefined
        }
      />
    </div>
  );
};
