import { SchemaManagementStyleProps, SchemaManagementStyles } from './SchemaManagement.types';

export const styles = (props: SchemaManagementStyleProps): SchemaManagementStyles => ({
  headerGap: [
    'mbc-header-gap',
    {
      selectors: {
        '.ms-DetailsHeader-cellTitle': {
          padding: 0,
          width: 36,
        },
      },
    },
  ],
  cell: [
    'mbc-cell',
    {
      color: props.theme.palette.neutralPrimary,
      selectors: {
        '&.ms-DetailsRow-cell': {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  ],
  primaryCell: [
    'mbc-primary-cell',
    {
      color: props.theme.palette.neutralPrimary,
      selectors: {
        '&.ms-DetailsRow-cell': {
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 0,
          selectors: {
            span: {
              paddingLeft: 6,
            },
          },
        },
      },
    },
  ],
  primaryHeader: [
    'mbc-primary-header',
    {
      selectors: {
        '.ms-DetailsHeader-cellTitle': {
          paddingLeft: 6,
        },
        '.ms-DetailsHeader-cellName': {
          display: 'flex',
          flexDirection: 'row-reverse',
          selectors: {
            i: {
              fontSize: 12,
              paddingLeft: 8,
            },
          },
        },
      },
    },
  ],
  secondaryCell: [
    'mbc-secondary-cell',
    {
      textAlign: 'center',
      color: props.theme.palette.neutralSecondary,
      selectors: {
        '&.ms-DetailsRow-cell': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
  ],
  unhoverable: [
    'mbc-unhoverable-cell',
    {
      selectors: {
        ':hover': {
          backgroundColor: 'unset',
        },
        ':active': {
          backgroundColor: 'unset',
        },
      },
    },
  ],
  hoverable: [
    'mbc-hoverable-cell',
    {
      selectors: {
        ':hover': {
          backgroundColor: props.theme.palette.neutralLight,
        },
      },
    },
  ],
  secondaryHeader: [
    'mbc-secondary-header',
    {
      selectors: {
        '.ms-DetailsHeader-cellTitle': {
          justifyContent: 'center',
        },
      },
    },
  ],
  subComponentStyles: {
    detailsList: {
      root: [
        'mbc-list',
        {
          selectors: {
            '.ms-DetailsList-headerWrapper .ms-GroupSpacer': {
              display: 'none !important',
            },
          },
        },
      ],
    },
    scrollablePane: {
      root: [
        'mbc-list',
        {
          position: 'relative',
          height: '100%',
          selectors: {
            '.ms-DetailsHeader': {
              selectors: {
                '.ms-GroupSpacer': {
                  display: 'none !important',
                },
              },
            },
          },
        },
      ],
    },
  },
});
