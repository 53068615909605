import { configureDefaultObject, getLocaleTimeNow } from '../../../utils';
import {
  DeploymentJob,
  DeploymentJobMapper,
  DeploymentJobState,
  DeploymentJobStateActionTypes,
  RESET_DEPLOYMENT_JOB_LIST,
  SET_DEPLOYMENT_JOB_LIST,
  ADD_DEPLOYMENT_JOB,
  LOADING_DEPLOYMENT_JOB_LIST,
  UPDATE_DEPLOYMENT_JOB,
  UPDATE_DEPLOYMENT_JOB_LIST,
} from '../../types/customML/deploymentJob.d';
import { ModelJobStatus } from '../../types/customML/searchModel.d';

export const createInitialDeploymentJob = (): DeploymentJob => ({
  key: '',
  name: '',
  email: '',
  description: '',
  deployedModelKey: '',
  createdAt: getLocaleTimeNow(),
  updatedAt: getLocaleTimeNow(),
  status: ModelJobStatus.NotStarted,
});

const createDeploymentJobMapper = (initialMapper: DeploymentJobMapper = {}) =>
  configureDefaultObject<DeploymentJobMapper, DeploymentJob>(initialMapper, createInitialDeploymentJob());

const initialState: DeploymentJobState = {
  isLoading: true,
  deploymentJobMapper: createDeploymentJobMapper(),
};

export function deploymentJobReducer(state = initialState, action: DeploymentJobStateActionTypes): DeploymentJobState {
  switch (action.type) {
    case LOADING_DEPLOYMENT_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_DEPLOYMENT_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
        deploymentJobMapper: createDeploymentJobMapper(),
      };
    }
    case SET_DEPLOYMENT_JOB_LIST: {
      return {
        ...state,
        isLoading: false,
        deploymentJobMapper: createDeploymentJobMapper({
          ...action.deploymentJobList.reduce((mapper: DeploymentJobMapper, deploymentJob: DeploymentJob) => {
            mapper[deploymentJob.key] = deploymentJob;
            return mapper;
          }, {}),
        }),
      };
    }
    case ADD_DEPLOYMENT_JOB: {
      return {
        ...state,
        deploymentJobMapper: createDeploymentJobMapper({
          ...state.deploymentJobMapper,
          [action.deploymentJob.key]: action.deploymentJob,
        }),
      };
    }
    case UPDATE_DEPLOYMENT_JOB: {
      if (state.deploymentJobMapper[action.deploymentJob.key].key) {
        return {
          ...state,
          deploymentJobMapper: createDeploymentJobMapper({
            ...state.deploymentJobMapper,
            [action.deploymentJob.key]: action.deploymentJob,
          }),
        };
      }

      return state;
    }
    case UPDATE_DEPLOYMENT_JOB_LIST: {
      return {
        ...state,
        deploymentJobMapper: createDeploymentJobMapper({
          ...state.deploymentJobMapper,
          ...action.deploymentJobList.reduce((mapper: DeploymentJobMapper, trainingJob: DeploymentJob) => {
            const updatedDeploymentJob = {
              ...state.deploymentJobMapper[trainingJob.key],
              status: trainingJob.status,
            };
            if (!!updatedDeploymentJob.key) {
              mapper[trainingJob.key] = updatedDeploymentJob;
            }
            return mapper;
          }, {}),
        }),
      };
    }

    default:
      return state;
  }
}
