import React from 'react';
import { Stack, Icon, Link, classNamesFunction } from 'office-ui-fabric-react';
import { NotificationItem } from './NotificationItem';
import { useDispatch, useSelector } from '../../../../../store/hooks';
import { readAllNotifications, dismissAllNotifications } from '../../../../../store/actions/notificationCenterActions';
import { AppState } from '../../../../../store/reducers';
import {
  NotificationPanelProps,
  NotificationPanelStyleProps,
  NotificationPanelStyles,
} from './NotificationPanel.types';
import { styles } from './NotificationPanel.styles';
import { CustomPanel } from '../../../../common/CustomPanel';

const getClassNames = classNamesFunction<NotificationPanelStyleProps, NotificationPanelStyles>();

export const NotificationPanelBase = (props: NotificationPanelProps) => {
  const { openNotificationPanel, onDismiss, t, className, componentRef, theme } = props;
  const dispatch = useDispatch();
  const notifications = useSelector((state: AppState) => state.notificationsCenter.notifications);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div ref={componentRef} className={classNames.root}>
      <CustomPanel
        headerText={t('notifications-panel.title')}
        isHiddenOnDismiss
        lightDismiss
        isOpen={openNotificationPanel}
        onDismiss={() => {
          dispatch(readAllNotifications());
          onDismiss();
        }}
      >
        {notifications.length ? (
          <>
            <div className={classNames.dismissAllNotificationsLink}>
              <Link onClick={() => dispatch(dismissAllNotifications())}>{t('notifications-panel.dismiss-all')}</Link>
            </div>
            {notifications
              .slice()
              .reverse()
              .map(notification => {
                return <NotificationItem key={notification.id} notification={notification} />;
              })}
          </>
        ) : (
          <Stack horizontalAlign="center" verticalAlign="center" gap={16} className={classNames.noNotificationsStack}>
            <Icon iconName={'Ringer'} className={classNames.noNotificationsIcon} />
            <div className={classNames.noNotificationsText}>{t('notifications-panel.no-notifications')}</div>
          </Stack>
        )}
      </CustomPanel>
    </div>
  );
};
