import { ReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../../CrudAction.types';
import { Role, CREATE_OR_UPDATE_ROLE, DELETE_ROLE } from '../../../../types/roleList.d';
import { updateRole, createRole, deleteRole } from '../../../../../restful-apis/role.api';
import store from '../../../..';
import { getI18n } from 'react-i18next';
import { MbcRoleRouter } from '../../../../../router/MbcAdminRouter/MbcAdminRouter';
import { Tenant } from '../../../../types/tenantsList';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';

export const roleUpdateAction = (
  tenantId: string,
  role: Role,
  onSuccess?: ActionSuccessHandler<Role>,
  onFailure?: ActionFailureHandler<Role>,
): ReduxCrudAction<Role> => {
  const i18n = getI18n();
  return {
    request: updateRole(tenantId, role),
    postActionMapper: updatedRole => ({
      type: CREATE_OR_UPDATE_ROLE,
      role: updatedRole,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, role),
    notification: {
      inProgress: {
        title: i18n.t('roles-list.notifications.role-is-being-updated-message', {
          roleName: role.name,
        }),
      },
      success: () => ({
        title: i18n.t('roles-list.notifications.role-update-success-message', {
          roleName: role.name,
        }),
      }),
      failure: () => ({
        title: i18n.t('roles-list.notifications.role-update-failed-message', {
          roleName: role.name,
        }),
      }),
    },
  };
};

export const roleCreateAction = (
  tenant: Tenant,
  role: Role,
  isClone?: boolean,
  onSuccess?: ActionSuccessHandler<Role>,
  onFailure?: ActionFailureHandler<Role>,
): ReduxCrudAction<Role> => {
  const i18n = getI18n();
  return {
    request: createRole(tenant.id, role),
    postActionMapper: createdRole => ({
      type: CREATE_OR_UPDATE_ROLE,
      role: createdRole,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, role),
    notification: {
      inProgress: { title: i18n.t('roles-list.notifications.role-is-being-created-message', { roleName: role.name }) },
      success: (createdRole?: Role) => ({
        title: i18n.t('roles-list.notifications.role-create-success-message', { roleName: role.name }),
        primaryButtonProps:
          createdRole && isClone
            ? {
                text: i18n.t('roles-list.notifications.clone-success-action-text'),
                onClick: () => {
                  MbcRoleRouter(tenant, createdRole)(MbcRouteKey.Role).browserPush();
                },
              }
            : undefined,
      }),
      failure: () => ({
        title: i18n.t('roles-list.notifications.role-create-failed-message', { roleName: role.name }),
      }),
    },
  };
};

export const roleDeleteAction = (
  tenantId: string,
  roleId: string,
  onSuccess?: ActionSuccessHandler<Role>,
  onFailure?: ActionFailureHandler<string>,
): ReduxCrudAction<Role> => {
  const i18n = getI18n();
  return {
    request: deleteRole(tenantId, roleId),
    postActionMapper: deletedRole => ({
      type: DELETE_ROLE,
      roleId: deletedRole.id,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, roleId),
    notification: {
      inProgress: {
        title: i18n.t('roles-list.notifications.role-is-being-deleted-message', {
          roleName: store.getState().roleList.roleIdMapper[roleId].name,
        }),
      },
      success: (deletedRole?: Role) => ({
        title: i18n.t('roles-list.notifications.role-delete-success-message', {
          roleName: deletedRole && deletedRole.name,
        }),
      }),
      failure: () => ({
        title: i18n.t('roles-list.notifications.role-delete-failed-message', {
          roleName: store.getState().roleList.roleIdMapper[roleId].name,
        }),
      }),
    },
  };
};
