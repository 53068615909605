import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../Breadcrumb';
import { PageHeader } from '../PageHeader';
import { MbcSideNav } from '../../MbcSideNav';
import { useMediaQuery } from 'react-responsive';
import { HoverableSideNav } from '../../MbcSideNav/HoverableSideNav';
import {
  classNamesFunction,
  PanelType,
  Stack,
  MessageBar,
  MessageBarType,
  ActionButton,
  Modal,
} from 'office-ui-fabric-react';
// workaround for datatype, till react-split-pane V2 release
// @ts-ignore
import SplitPane from 'react-split-pane/lib/SplitPane';
// @ts-ignore
import Pane from 'react-split-pane/lib/Pane';
import { PageTemplateStyleProps, PageTemplateStyles, SideNavMode, SplitPageTemplateProps } from './PageTemplate.types';
import { CollapsiblePane } from './CollapsiblePane';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../store/hooks';
import { ContactUs } from '../ContactUs';

const getClassNames = classNamesFunction<PageTemplateStyleProps, PageTemplateStyles>();

export const SplitPageTemplateBase = (props: SplitPageTemplateProps) => {
  const {
    styles,
    theme,
    sideNavOnClick,
    pageHeaderProps,
    breadcrumbVisible,
    onRenderLeftSideContent,
    collapsiblePaneProps,
    onSecondaryNavMenuRender,
    sideNavMode: sideNavModeFromProp,
    dragPosition,
    onDragPositionChange,
  } = props;

  const i18n = useTranslation();

  const [showContactUsForm, setShowContactUsForm] = useState(false);

  const activeTenant = useSelector(state => state.tenantsList.activeTenant);

  const isLargeScreen = useMediaQuery({ query: '(min-width: 1366px)' });
  const isVeryLargeScreen = useMediaQuery({ query: '(min-width: 1440px)' });
  const isVeryVeryLargeScreen = useMediaQuery({ query: '(min-width: 1920px)' });
  const [paneExpanded, setPaneExpanded] = useState<boolean>(
    collapsiblePaneProps.isExpanded !== undefined ? collapsiblePaneProps.isExpanded : true,
  );

  useEffect(() => {
    if (collapsiblePaneProps.isExpanded !== undefined) {
      setPaneExpanded(collapsiblePaneProps.isExpanded);
    }
  }, [collapsiblePaneProps.isExpanded]);

  const getScreenSize = (): number => {
    if (isVeryVeryLargeScreen) return 4;
    if (isVeryLargeScreen) return 3;
    if (isLargeScreen) return 2;
    return 1;
  };

  let sideNavMode = sideNavModeFromProp;
  if (getScreenSize() === 1) sideNavMode = SideNavMode.Hoverable;

  const classNames = getClassNames(styles, {
    theme: theme!,
    isSplitPage: true,
    sideNavMode: sideNavMode,
    screenSize: getScreenSize(),
  });

  const secondaryNavVisivle = breadcrumbVisible || onSecondaryNavMenuRender;

  return (
    <>
      <Modal isOpen={showContactUsForm}>
        <ContactUs supportEmail={'DemoRequestMBC@microsoft.com'} onDismiss={() => setShowContactUsForm(false)} />
      </Modal>
      <Stack className={classNames.root}>
        <div role="main" className={classNames.allContentExceptFooter}>
          <Stack horizontal>
            <div className={classNames.sideNav}>
              {(sideNavMode === SideNavMode.Expanded || sideNavMode === SideNavMode.Collapsed) && (
                <MbcSideNav
                  onClick={typeof sideNavOnClick === 'undefined' ? () => {} : sideNavOnClick}
                  collapsed={sideNavMode === SideNavMode.Collapsed}
                />
              )}
              {sideNavMode === SideNavMode.Hoverable && <HoverableSideNav panelType={PanelType.smallFixedNear} />}
            </div>
            <Stack style={{ width: '100%' }}>
              {activeTenant.isSandbox && (
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  isMultiline={false}
                  actions={
                    <ActionButton
                      style={{ color: '#1b51c4', height: 32, fontSize: 12 }}
                      onClick={() => setShowContactUsForm(true)}
                    >
                      {i18n.t('common.sandbox-experience.btn-content')}
                    </ActionButton>
                  }
                >
                  {i18n.t('common.sandbox-experience.content')}
                </MessageBar>
              )}
              <Stack horizontal horizontalAlign={'stretch'} styles={{ root: { width: '100%' } }} verticalFill>
                <Stack data-test="main-container" className={classNames.mainContainer} verticalAlign={'stretch'}>
                  <Stack
                    horizontal={!paneExpanded}
                    className={classNames.splitContainer}
                    horizontalAlign={'stretch'}
                    verticalAlign={'stretch'}
                  >
                    <SplitPane
                      split="vertical"
                      className={
                        onDragPositionChange && paneExpanded
                          ? classNames.expandedSplitPane
                          : classNames.collapsedSplitPane
                      }
                      onResizeEnd={(x: string[]) =>
                        onDragPositionChange && onDragPositionChange(parseInt(x[1].split('.')[0]))
                      }
                    >
                      <Pane minSize={paneExpanded ? '600px' : undefined}>
                        <Stack className={classNames.leftSideContainer} grow>
                          {secondaryNavVisivle && (
                            <Stack horizontal verticalAlign={'center'} className={classNames.secondaryNav}>
                              {breadcrumbVisible && <Breadcrumb />}
                              {onSecondaryNavMenuRender && onSecondaryNavMenuRender()}
                            </Stack>
                          )}
                          <Stack className={classNames.leftSideHeader}>
                            <PageHeader {...{ ...pageHeaderProps, shrinkTopMargin: breadcrumbVisible }} />
                          </Stack>
                          {onRenderLeftSideContent()}
                        </Stack>
                      </Pane>
                      <Pane
                        minSize={paneExpanded ? '425px' : '36px'}
                        maxSize={paneExpanded ? undefined : '36px'}
                        initialSize={paneExpanded ? (!!dragPosition ? `${dragPosition}px` : '425px') : '36px'}
                      >
                        <Stack verticalFill>
                          <CollapsiblePane
                            {...{
                              ...collapsiblePaneProps,
                              isExpanded: paneExpanded,
                              onSwitchStateClicked: collapsiblePaneProps.onSwitchStateClicked
                                ? collapsiblePaneProps.onSwitchStateClicked
                                : () => {
                                    setPaneExpanded(state => !state);
                                  },
                            }}
                          />
                        </Stack>
                      </Pane>
                    </SplitPane>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </Stack>
    </>
  );
};
