import { SearchLatencyStyleProps, SearchLatencyStyles } from './SearchLatency.types';

export const styles = (props: SearchLatencyStyleProps): SearchLatencyStyles => {
  return {
    root: [
      'mbc-search-requests-chart',
      {
        selectors: {
          '.mbc-legend-container': {
            paddingLeft: 2,
          },
        },
      },
      props.className,
    ],
    legendsContainer: [
      'mbc-legends-container',
      {
        height: 'fit-content',
        width: '84%',
      },
    ],
    legendContainer: [
      'mbc-legend-container',
      {
        cursor: 'pointer',
        alignItems: 'center',
        paddingBottom: 4,
        width: 120,
        marginRight: 0,
        marginLeft: 0,
      },
    ],
    legendIcon: [
      'mbc-legend-icon',
      {
        height: 12,
        width: 21,
        borderRadius: 4,
      },
    ],
    legendLabel: [
      'mbc-legend-label',
      {
        fontSize: 12,
        lineHeight: 14,
      },
    ],
  };
};
