import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import {
  RESET_TENANTS_LIST,
  TenantsListStateActionTypes,
  LOADING_TENANTS_LIST,
  RESET_ACTIVE_TENANT,
  SET_ACTIVE_TENANT,
  SET_TENANTS_LIST,
  Feature,
  UPDATE_ACTIVE_TENANT,
} from '../types/tenantsList.d';
import { clearNotificationMessages } from './notificationMessageActions';
import { resetTenantTokens } from './tokenListActions';
import { getUserTenants, getUserTenant } from '../../restful-apis/tenant.api';
import { getEnvironmentList, resetEnvironmentList } from './environmentListActions';
import { getTenantRoles, resetTenantRoles } from './roleListActions';
import { resetCustomMLDatasetList, setCustomMLDatasetList } from './customML/datasetActions';
import { setSearchModelList, resetSearchModelList } from './customML/searchModelActions';
import { setTrainingJobList, resetTrainingJobList } from './customML/trainingJobActions';
import { setCookingJobList, resetCookingJobList } from './customML/cookingJobActions';
import { setEvaluationJobList, resetEvaluationJobList } from './customML/evaluationJobActions';
import { setDeploymentJobList, resetDeploymentJobList } from './customML/deploymentJobActions';
import { isFeatureEnabledPerTenant } from '../../guards/utils';

export const getUserTenantList = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_TENANTS_LIST,
    });

    getUserTenants()
      .then(tenantList => {
        dispatch({
          type: SET_TENANTS_LIST,
          tenants: tenantList,
        });
      })
      .catch(() => {});
  };
};

export const resetUserTenantList = (): TenantsListStateActionTypes => {
  return {
    type: RESET_TENANTS_LIST,
  };
};

export const resetActiveTenantId = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenant = getState().tenantsList.activeTenant;
    if (tenant.id) {
      dispatch(resetEnvironmentList());
      dispatch(resetTenantRoles());
      dispatch(resetTenantTokens());

      // Enable CustomML only on tenants with CustomML feature enabled
      if (isFeatureEnabledPerTenant(tenant, Feature.CustomML)) {
        dispatch(resetSearchModelList());
        dispatch(resetTrainingJobList());
        dispatch(resetCookingJobList());
        dispatch(resetEvaluationJobList());
        dispatch(resetDeploymentJobList());
        dispatch(resetCustomMLDatasetList());
      }

      dispatch(clearNotificationMessages());
      dispatch({
        type: RESET_ACTIVE_TENANT,
      });
    }
  };
};

export const setActiveTenantId = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenant = getState().tenantsList.tenantIdMapper[tenantId];
    if (tenant.id) {
      dispatch({
        type: SET_ACTIVE_TENANT,
        tenantId: tenantId,
      });
      dispatch(getEnvironmentList(tenantId));
      dispatch(getTenantRoles(tenantId));

      // Enable CustomML only on tenants with CustomML feature enabled
      if (isFeatureEnabledPerTenant(tenant, Feature.CustomML)) {
        dispatch(setSearchModelList(tenantId));
        dispatch(setTrainingJobList(tenantId));
        dispatch(setCookingJobList(tenantId));
        dispatch(setDeploymentJobList(tenantId));
        dispatch(setEvaluationJobList(tenantId));
        dispatch(setCustomMLDatasetList(tenantId));
      }
    }
  };
};

export const updateActiveTenant = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;
    getUserTenant(tenantId)
      .then(updatedTenant => {
        dispatch({
          type: UPDATE_ACTIVE_TENANT,
          tenant: updatedTenant,
        });
      })
      .catch(() => {});
  };
};
