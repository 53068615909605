import { Environment as EnvironmentModel } from '../../store/types/environmentList';
import { EnvironmentList as EnvironmentListDTO } from '../dto/environment/environmentList';
import { Environment as EnvironmentDTO } from '../dto/environment/environment';
import { Product } from '../../store/types/tenantsList.d';

export function mapEnvironmentDtoToModel(environment: EnvironmentDTO): EnvironmentModel {
  return {
    id: environment.id,
    name: environment.name,
    description: environment.description,
    regions: environment.regions,
    createdBy: environment.createdBy,
    createdAt: environment.createdAt,
    updatedAt: environment.updatedAt,
    indexId: environment.indexId,
    products: environment.products
      // TODO: Remove this workaround to consolidate Products enum
      .filter(p => Product[p as keyof typeof Product])
      .map(p => Product[p as keyof typeof Product]),
    analyticsEnabled: environment.analyticsEnabled,
    isIndexSoftDeleted: environment.isIndexSoftDeleted,
  };
}

export function mapEnvironmentListDtoToSingleModel(environmentList: EnvironmentListDTO): EnvironmentModel {
  return environmentList.environments.map(mapEnvironmentDtoToModel)[0];
}
export function mapEnvironmentListDtoToModel(environmentList: EnvironmentListDTO): EnvironmentModel[] {
  return environmentList.environments.map(mapEnvironmentDtoToModel);
}

export function mapEnvironmentModelToDTO(environment: EnvironmentModel): EnvironmentDTO {
  var finalRegions = environment.regions;
  if (!finalRegions || !finalRegions.length) finalRegions = ['Unknown'];

  return {
    id: environment.id,
    name: environment.name,
    description: environment.description,
    regions: finalRegions,
    createdBy: environment.createdBy,
    createdAt: environment.createdAt,
    updatedAt: environment.updatedAt,
    indexId: environment.indexId,
    products: environment.products,
    analyticsEnabled: environment.analyticsEnabled,
  };
}
