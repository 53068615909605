import {
  ADD_OR_UPDATE_ENVIRONMENT,
  DELETE_ENVIRONMENT,
  Environment,
  EnvironmentIdMapper,
  EnvironmentListState,
  EnvironmentListStateActionTypes,
  LOADING_ENVIRONMENT_LIST,
  RESET_ACTIVE_ENVIRONMENT,
  RESET_ENVIRONMENT_LIST,
  SET_ACTIVE_ENVIRONMENT,
  SET_ENVIRONMENT_LIST,
  SET_ENVIRONMENT_SEARCH_INDEX_UPDATED,
  SET_SEARCH_INDEX_ID,
  UPDATING_ENVIRONMENT_SEARCH_INDEX,
  createInitialEnvironment,
  SET_ENVIRONMENT_INDEX_SOFT_DELETED,
} from '../types/environmentList.d';

import { configureDefaultObjectWithId } from '../../utils';

const createEnvironmentIdMapper = (initialMapper: EnvironmentIdMapper = {}) => {
  return configureDefaultObjectWithId<EnvironmentIdMapper, Environment>(initialMapper, createInitialEnvironment());
};

const initialState: EnvironmentListState = {
  isLoading: true,
  nonEditableSearchIndexSet: new Set([]),
  activeEnvironment: createInitialEnvironment(),
  environmentIdMapper: createEnvironmentIdMapper(),
};

export function environmentListReducer(
  state = initialState,
  action: EnvironmentListStateActionTypes,
): EnvironmentListState {
  switch (action.type) {
    case SET_ENVIRONMENT_LIST: {
      const environmentIdMapper = createEnvironmentIdMapper({
        ...action.environmentList.reduce((mapper: EnvironmentIdMapper, environment: Environment) => {
          mapper[environment.id] = environment;
          return mapper;
        }, {}),
      });

      return {
        ...state,
        isLoading: false,
        environmentIdMapper: environmentIdMapper,
      };
    }
    case RESET_ENVIRONMENT_LIST: {
      return {
        ...state,
        isLoading: true,
        environmentIdMapper: createEnvironmentIdMapper(),
      };
    }
    case LOADING_ENVIRONMENT_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_ACTIVE_ENVIRONMENT: {
      return {
        ...state,
        activeEnvironment: action.environment,
      };
    }
    case SET_SEARCH_INDEX_ID: {
      const activeEnvironment = { ...state.activeEnvironment, indexId: action.indexId };
      const updatedEnvironmentIdMapper = createEnvironmentIdMapper({
        ...state.environmentIdMapper,
        [state.activeEnvironment.id]: activeEnvironment,
      });
      return {
        ...state,
        activeEnvironment: activeEnvironment,
        environmentIdMapper: updatedEnvironmentIdMapper,
      };
    }
    case RESET_ACTIVE_ENVIRONMENT: {
      return {
        ...state,
        activeEnvironment: createInitialEnvironment(),
      };
    }
    case ADD_OR_UPDATE_ENVIRONMENT: {
      const updatedEnvironmentIdMapper = createEnvironmentIdMapper({
        ...state.environmentIdMapper,
        [action.environment.id]: action.environment,
      });
      return {
        ...state,
        environmentIdMapper: updatedEnvironmentIdMapper,
      };
    }
    case DELETE_ENVIRONMENT: {
      var newEnvironmentIdMapper = createEnvironmentIdMapper({ ...state.environmentIdMapper });
      delete newEnvironmentIdMapper[action.environmentId];

      return {
        ...state,
        environmentIdMapper: newEnvironmentIdMapper,
      };
    }
    case SET_ENVIRONMENT_INDEX_SOFT_DELETED: {
      var newEnvironmentIdMapper = createEnvironmentIdMapper({ ...state.environmentIdMapper });
      newEnvironmentIdMapper[action.environmentId] = {
        ...newEnvironmentIdMapper[action.environmentId],
        isIndexSoftDeleted: true,
      };

      return {
        ...state,
        environmentIdMapper: newEnvironmentIdMapper,
      };
    }
    case UPDATING_ENVIRONMENT_SEARCH_INDEX: {
      return {
        ...state,
        nonEditableSearchIndexSet: new Set(state.nonEditableSearchIndexSet.add(action.environmentId)),
      };
    }
    case SET_ENVIRONMENT_SEARCH_INDEX_UPDATED: {
      const updatedNonEditableSearchIndexSet = new Set(state.nonEditableSearchIndexSet);
      updatedNonEditableSearchIndexSet.delete(action.environmentId);

      return {
        ...state,
        nonEditableSearchIndexSet: updatedNonEditableSearchIndexSet,
      };
    }

    default:
      return state;
  }
}
