import React, { Component } from 'react';
import SearchDemo, { Scenario } from '../../../common/search-demo/searchDemo';
import styles from './RelevanceA.module.scss';
import { SearchResult } from '../../../../../store/types/searchDemo';

interface RelevanceAProps {
  relevanceASearchResults: SearchResult;
  minHeight: number;
  componentRef: React.RefObject<HTMLDivElement>;
}

interface RelevanceAState {
  alteredQuery: string;
  originalQuery: string;
}

class RelevanceA extends Component<RelevanceAProps, RelevanceAState> {
  constructor(props: RelevanceAProps) {
    super(props);
    this.state = {
      originalQuery: '',
      alteredQuery: '',
    };
  }

  render() {
    return (
      <div
        ref={this.props.componentRef}
        className={styles.wrapper}
        style={{ minHeight: this.props.minHeight > 0 ? this.props.minHeight : '100vh' }}
      >
        <SearchDemo
          scenario={Scenario.QueryUnderstanding}
          searchDemoTitle={'Search without query understanding'}
          searchResultLabel={
            <div>
              {this.props.relevanceASearchResults.alteredQuery || this.props.relevanceASearchResults.originalQuery ? (
                <>
                  {this.props.relevanceASearchResults.searchResultItems.length > 0 && (
                    <span>{'Showing results for '}</span>
                  )}
                  {this.props.relevanceASearchResults.searchResultItems.length === 0 && (
                    <span>{'No results found for '}</span>
                  )}
                  <span className={styles.targetQuery}>
                    {this.props.relevanceASearchResults.alteredQuery
                      ? this.props.relevanceASearchResults.alteredQuery
                      : this.props.relevanceASearchResults.originalQuery}
                  </span>
                  {this.props.relevanceASearchResults.searchResultItems.length === 0 && (
                    <span>{' using traditional search'}</span>
                  )}
                </>
              ) : (
                <span>Loading...</span>
              )}
            </div>
          }
          SearchResultItems={this.props.relevanceASearchResults.searchResultItems}
          invisibleSearchBox={true}
          showPoweredBy={false}
        ></SearchDemo>
      </div>
    );
  }
}

export default RelevanceA;
