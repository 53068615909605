import { getI18n } from 'react-i18next';
import { CookingJob, ADD_COOKING_JOB_LIST } from '../../../../types/customML/cookingJob.d';
import { ActionSuccessHandler, ActionFailureHandler, ReduxCrudAction } from '../../CrudAction.types';
import { createCookingJobList } from '../../../../../restful-apis/customML.api';

export const batchCreateCookJobAction = (
  tenantId: string,
  searchModelKey: string,
  cookingJobList: CookingJob[],
  onSuccess?: ActionSuccessHandler<CookingJob[]>,
  onFailure?: ActionFailureHandler<CookingJob[]>,
): ReduxCrudAction<CookingJob[]> => {
  const i18n = getI18n();
  return {
    request: createCookingJobList(tenantId, searchModelKey, cookingJobList),
    postActionMapper: createdjobList => ({
      type: ADD_COOKING_JOB_LIST,
      cookingJobList: createdjobList.map(job => ({ ...job, isNew: true })),
    }),
    notification: {
      failure: () => ({
        title: i18n.t('custom-ml.dataset-list.cook-req-failure', { count: cookingJobList.length }),
      }),
      inProgress: {
        title: i18n.t('custom-ml.dataset-list.cook-req-inprogress', { count: cookingJobList.length }),
      },
      success: () => ({
        title: i18n.t('custom-ml.dataset-list.cook-req-success', { count: cookingJobList.length }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, cookingJobList),
  };
};
