import React, { Component } from 'react';
import SearchDemo, { Scenario } from '../../../common/search-demo/searchDemo';
import styles from './PersonalizationA.module.scss';
import { SearchResult } from '../../../../../store/types/searchDemo';

interface PersonalizationAProps {
  personalizationASearchResults: SearchResult;
  minHeight: number;
  componentRef: React.RefObject<HTMLDivElement>;
}

class PersonalizationA extends Component<PersonalizationAProps> {
  render() {
    return (
      <div
        ref={this.props.componentRef}
        className={styles.wrapper}
        style={{ minHeight: this.props.minHeight > 0 ? this.props.minHeight : '100vh' }}
      >
        <SearchDemo
          scenario={Scenario.Personalization}
          searchDemoTitle={'Search without personalization'}
          isPersonaMale={false}
          searchResultLabel={
            <div>
              {`Showing results `}
              <span className={styles.targetQuery}>{'ranked by relevance'}</span>
            </div>
          }
          SearchResultItems={this.props.personalizationASearchResults.searchResultItems}
          invisibleSearchBox={true}
          showPoweredBy={false}
        ></SearchDemo>
      </div>
    );
  }
}

export default PersonalizationA;
