import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useBreadcrumb } from '../../../../store/hooks/use-breadcrumb/useBreadcrumb';
import { MbcRouter } from '../../../../router/MbcRouter.base';
import { useCustomMLRouter } from '../../../../store/hooks/use-router/useCustomMLRouter';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { useProxyRouter } from '../../../../store/hooks/use-router/useProxyRouter';

const EvaluationListBreadcrumbKey = 'Evaluations';

export const EvaluationListProxy = (props: RouteComponentProps) => {
  useBreadcrumb(EvaluationListBreadcrumbKey, props.match.url);

  const MbcProxyRouteMapper = useProxyRouter();
  const MbcCustomMLRouteMapper = useCustomMLRouter();

  return (
    <MbcRouter
      routes={[
        MbcCustomMLRouteMapper(MbcRouteKey.EvaluationJobList),
        MbcProxyRouteMapper(MbcRouteKey.EvaluationJobProxy),
      ]}
    />
  );
};
