import { classNamesFunction } from 'office-ui-fabric-react';

import { DraggableRevealStyles, DraggableRevealStyleProps } from './DraggableReveal.types';

export const styles = (): DraggableRevealStyles => {
  return {
    root: {
      position: 'relative',
      width: '100%',
      minHeight: '100vh',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    topContainer: {
      position: 'fixed',
      top: 0,
      height: '100vh',
      zIndex: 1000100,
    },
    leftComponent: {
      overflow: 'hidden',
      zIndex: 10,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    leftComponentInner: {
      width: '100vw',
    },
    rightComponent: {
      position: 'absolute',
      width: '100vw',
      top: 0,
      left: 0,
      right: 0,
    },
    rightComponentInner: {
      width: '100vw',
      position: 'relative',
    },
  };
};

export const getClassNames = classNamesFunction<DraggableRevealStyleProps, DraggableRevealStyles>();
