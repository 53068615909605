import React, { useState } from 'react';
import { classNamesFunction, Stack, Label, TextField, ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react';
import { BannerEditorProps, BannerEditorStyleProps, BannerEditorStyles } from './BannerEditor.types';
import { Banner, BannerType } from '../../../../store/types/customizations/businessRule.d';

const getClassNames = classNamesFunction<BannerEditorStyleProps, BannerEditorStyles>();

export const BannerEditorBase = (props: BannerEditorProps) => {
  const { styles, theme, className, initialBanner, onBannerUpdate } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const [currentBanner, setCurrentBanner] = useState<Banner>(
    !!initialBanner ? initialBanner : { content: '', contentType: 'PlainText' },
  );

  const getBannerTypeOptions = (): IChoiceGroupOption[] => {
    let options: IChoiceGroupOption[] = [];
    for (const [key, value] of Object.entries(BannerType)) {
      options.push({ key: key, text: value });
    }
    return options;
  };

  const updateBannerContentType = (contentType: BannerType) => {
    const updatedBanner: Banner = { ...currentBanner, contentType: contentType };
    setCurrentBanner(updatedBanner);
    onBannerUpdate(updatedBanner);
  };

  const updateBannerContent = (content: string) => {
    const updatedBanner: Banner = { ...currentBanner, content: content };
    setCurrentBanner(updatedBanner);
    onBannerUpdate(updatedBanner);
  };

  return (
    <Stack className={classNames.root} tokens={{ childrenGap: '8' }}>
      <Label>{'Banner message'}</Label>
      <TextField
        value={currentBanner.content}
        multiline
        rows={5}
        onChange={(ev, newValue) => updateBannerContent(newValue || '')}
      />
      <ChoiceGroup
        styles={classNames.subComponentStyles.ChoiceGroup}
        defaultSelectedKey="PlainText"
        options={getBannerTypeOptions()}
        onChange={(ev, option) => updateBannerContentType(option ? (option.key as BannerType) : 'PlainText')}
      />
    </Stack>
  );
};
