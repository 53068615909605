import { AvailableTutorials } from '../../store/types/tutorial.d';
import { RefObject } from 'react';
import { WithTranslation } from 'react-i18next';

export enum TutorialSteps {
  //Basic tutorial steps
  EnvList = 'EnvList',
  EnvProducts = 'EnvProducts',
  EnvName = 'EnvName',
  // Schema detection --> Catalog
  SchemaDetection = 'SchemaDetection',
  SchemaDetectionPanel = 'SchemaDetectionPanel',
  SchemaEditButton = 'SchemaEditButton',
  SchemaAddField = 'SchemaAddField',
  SchemaFieldRow = 'SchemaFieldRow',
  SchemaDetectionNotification = 'SchemaDetectionNotification',
  // Query Tester
  QueryTesterSideNav = 'QueryTesterSideNav',
  QueryTesterSearch = 'QueryTesterSearch',
  QueryTesterTreatmentPanel = 'QueryTesterTreatmentPanel',
  QueryTesterPreviewTreatmentToggle = 'QueryTesterPreviewTreatmentToggle',
  QueryTesterNextSteps = 'QueryTesterNextSteps',
  // Business rules
  BusinessRulesSideNav = 'BusinessRulesSideNav',
  BusinessRulesList = 'BusinessRulesList',
  BusinessRulesEditorSearch = 'BusinessRulesEditorSearch',
  BusinessRulesRightPane = 'BusinessRulesRightPane',
  BusinessRulesTreatmentToggle = 'BusinessRulesTreatmentToggle',
  // Synonyms
  SynonymsSideNav = 'SynonymsSideNav',
  SynonymsList = 'SynonymsList',
  SynonymsEditorRightPane = 'SynonymsEditorRightPane',
  SynonymsTerms = 'SynonymsTerms',
}

export enum TutorialsId {
  None = 'None',
  EnvironmentList = 'EnvironmentList',
  Roles = 'Roles',
  Catalog = 'Catalog',
  QueryTester = 'QueryTester',
  BusinessRules = 'BusinessRules',
  Synonyms = 'Synonyms',
}

export interface TutorialConfigOptions {
  id: string;
  NumOfSteps: number;
  NextTutorial: AvailableTutorials;
  Steps: string[];
  TutorialStartUpPrep: Function;
}

export interface TutorialConfigItem {
  [key: string]: TutorialConfigOptions;
}

export enum FocusState {
  Target,
  Overlay,
  NoOverlay,
}

export interface TutorialProps extends WithTranslation {
  target: RefObject<HTMLDivElement>;
  step: string;
  headline: string;
  onStepEnd?: () => void;
  onStepStart?: () => void;
  onDismiss?: () => void;
  // If set to false target won't be focused and all screen will light
  focusOnTarget?: FocusState;
  // Gives white background to target to make it more visible
  lightBackground?: boolean;
  isBeakVisible?: boolean;
  coverTarget?: boolean;
  children: React.ReactNode;
}
