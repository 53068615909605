import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { classNamesFunction, Stack, Icon, PrimaryButton } from 'office-ui-fabric-react';
import { DropFileUploaderProps, DropFileUploaderStyleProps, DropFileUploaderStyles } from './DropFileUploader.types';

const getClassNames = classNamesFunction<DropFileUploaderStyleProps, DropFileUploaderStyles>();

const MAX_FILE_SIZE = 524288000;

export const DropFileUploaderBase = (props: DropFileUploaderProps) => {
  const { t, acceptedExtensions, onUpload, styles, theme, className } = props;

  const allowedExtensions = acceptedExtensions || ['json'];

  const [isValidFileType, setIsValidFileType] = useState<boolean>(true);

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const onDrop = (files: File[]) => {
    const targetFile = files[0];
    const isValid = allowedExtensions.reduce<boolean>(
      (isAccepted, extension) => isAccepted || targetFile.name.endsWith(extension),
      false,
    );

    isValid && onUpload(targetFile);
    setIsValidFileType(isValid);
  };

  const { getRootProps, getInputProps, open, rejectedFiles, isDragActive } = useDropzone({
    onDrop: onDrop,
    noClick: false,
    noKeyboard: true,
    maxSize: MAX_FILE_SIZE,
  });

  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_FILE_SIZE;

  return (
    <Stack
      verticalAlign={'center'}
      horizontalAlign={'center'}
      className={classNames.root}
      tokens={{ childrenGap: 21, padding: '45px 0' }}
    >
      <div
        {...getRootProps({
          className: classNames.fileDragDrop,
        })}
      >
        <Stack
          tokens={{ childrenGap: 16 }}
          horizontalAlign={'center'}
          verticalAlign={'space-between'}
          style={{
            backgroundColor: isDragActive ? '#c5d1de' : 'transparent',
          }}
        >
          <input {...getInputProps()} />
          <Icon iconName="Upload" className={classNames.uploadIcon} ariaLabel={t('file-uploader.upload-aria-label')} />
          <div>{t('file-uploader.upload-drop-label')}</div>
          {!isValidFileType && (
            <div className={classNames.errorMessage}>
              {t('file-uploader.type-validation-message', { extensions: allowedExtensions.join(', ') })}
            </div>
          )}
          {isFileTooLarge && (
            <div className={classNames.errorMessage}>{t('file-uploader.size-validation-message')}</div>
          )}
        </Stack>
      </div>
      <PrimaryButton iconProps={{ iconName: 'DocumentSearch' }} onClick={open}>
        {t('file-uploader.browse-files')}
      </PrimaryButton>
    </Stack>
  );
};
