import {
  ActionFailureHandler,
  ActionSuccessHandler,
  ExecuteReduxCrudAction,
  environmentCreateAction,
  environmentDeleteAction,
  environmentUpdateAction,
} from './utils';
import {
  Environment,
  EnvironmentListStateActionTypes,
  EnvironmentWithRoles,
  LOADING_ENVIRONMENT_LIST,
  RESET_ACTIVE_ENVIRONMENT,
  RESET_ENVIRONMENT_LIST,
  SET_ACTIVE_ENVIRONMENT,
  SET_ENVIRONMENT_LIST,
} from '../types/environmentList.d';
import {
  resetActiveSearchIndex,
  resetSearchIndexList,
  setActiveSearchIndex,
  setSearchIndexList,
  startLoadingSearchIndexList,
} from './searchIndexActions';

import { Action } from 'redux';
import { AppState } from '../reducers';
import { GET_SEARCH_INSTANCE_LIST } from '../types/customizations/searchInstanceList.d';
import { ThunkAction } from 'redux-thunk';
import { getEnvironments, createTrialEnvironment } from '../../restful-apis/environment.api';
import { getSearchIndexList } from '../../restful-apis/searchIndex.api';
import { getSearchInstances } from '../../restful-apis/customizations/searchInstance.api';
import { Tenant } from '../types/tenantsList.d';
import { getUserTenants } from '../../restful-apis/tenant.api';
import { getUserTenantList } from './tenantsListActions';
import { browserHistory } from '../../history.module';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { MbcProxyRouteMapper } from '../../router/MbcProxyRouter.config';
import { resetSearchInstances } from './customizations/searchInstanceListActions';

export function startLoadingEnvironmentList(): EnvironmentListStateActionTypes {
  return {
    type: LOADING_ENVIRONMENT_LIST,
  };
}

export const getEnvironmentList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    // Ignore environments with no search index created
    dispatch(startLoadingEnvironmentList());
    dispatch(startLoadingSearchIndexList());

    getSearchIndexList(tenantId).then(searchIndexList => {
      dispatch(setSearchIndexList(searchIndexList));
      getEnvironments(tenantId).then(environmentList => {
        if (getState().tenantsList.activeTenant.isSandbox && environmentList.length) {
          getSearchInstances(tenantId, environmentList[0].indexId)
            .then(searchInstances => {
              dispatch({
                type: GET_SEARCH_INSTANCE_LIST,
                instances: searchInstances,
              });
            })
            .then(() => {
              dispatch({
                type: SET_ENVIRONMENT_LIST,
                environmentList: environmentList,
              });
            })
            .catch(() => {
              dispatch({
                type: SET_ENVIRONMENT_LIST,
                environmentList: environmentList,
              });
            });
        } else {
          dispatch({
            type: SET_ENVIRONMENT_LIST,
            environmentList: environmentList,
          });
        }
      });
    });
  };
};

export const resetEnvironmentList = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: RESET_ENVIRONMENT_LIST,
    });
    dispatch(resetSearchIndexList());
  };
};

export const setActiveEnvironment = (environmentId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const environment = getState().environmentList.environmentIdMapper[environmentId];
    if (environment) {
      dispatch({
        type: SET_ACTIVE_ENVIRONMENT,
        environment: environment,
      });
      environment.indexId ? dispatch(setActiveSearchIndex(environment.indexId)) : dispatch(resetActiveSearchIndex());
      // dispatch(addBreadCrumbItem({ key: environmentId, text: environment.name, href: environmentUrl }));
    }
  };
};

export const resetActiveEnvironment = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const environmentId = getState().environmentList.activeEnvironment.id;
    if (environmentId) {
      dispatch({
        type: RESET_ACTIVE_ENVIRONMENT,
      });
      dispatch(resetActiveSearchIndex());
      dispatch(resetSearchInstances());
      // dispatch(removeBreadCrumbItem(environmentId));
    }
  };
};

export const createEnvironment = (
  tenant: Tenant,
  environment: Environment,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(environmentCreateAction(tenant, environment, onSuccess, onFailure));

export const updateEnvironment = (
  tenantId: string,
  environment: Environment,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(environmentUpdateAction(tenantId, environment, onSuccess, onFailure));

export const deleteEnvironment = (
  tenantId: string,
  environment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(environmentDeleteAction(tenantId, environment, onSuccess, onFailure));

export const createTrialEnvironmentAction = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    createTrialEnvironment()
      .then(() => {
        return new Promise(resolve => {
          const interval = setInterval(
            () =>
              getUserTenants()
                .then(tenantList => {
                  if (tenantList && tenantList.length) {
                    clearInterval(interval);
                    resolve();
                  }
                })
                .catch(() => {}),
            1000,
          );
        });
      })
      .then(() => {
        dispatch(getUserTenantList());
      })
      .catch(() => {
        browserHistory.push(MbcProxyRouteMapper()[MbcRouteKey.HomePage].url);
      });
  };
};
