import React from 'react';
import { AppState } from '../../../../store/reducers';
import { TotalPercentagContent } from '../../../../store/types/analytics';
import { ChartContentCard } from '../Common/ChartContentCard';
import { BarChart } from '../../../../components/common/charts/BarChart/BarChart';
import numeral from 'numeral';
import { LayoutType } from '../../../../components/common/charts/BarChart';
import { classNamesFunction } from 'office-ui-fabric-react';
import { useSelector } from 'react-redux';
import {
  FilterCategoriesStyleProps,
  FilterCategoriesStyles,
  FilterCategoriesBaseProps,
} from './FilterCategories.types';
import { SetFiltersDistribution } from '../../../../store/actions/analyticsActions';
import { useDispatch } from '../../../../store/hooks';

const getClassNames = classNamesFunction<FilterCategoriesStyleProps, FilterCategoriesStyles>();

export const FilterCategoriesBase = (props: FilterCategoriesBaseProps) => {
  const { t, className, theme, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  const isFiltersDistributionLoading = useSelector((state: AppState) => state.analytics.isFiltersDistributionLoading);
  const filtersDistribution = useSelector((state: AppState) => state.analytics.filtersDistribution);

  let filtersDistributionGrouped: TotalPercentagContent[] = [];
  filtersDistributionGrouped =
    filtersDistribution.length <= 8 ? filtersDistribution : [...filtersDistribution.slice(0, 8)];

  return (
    <div className={classNames.root}>
      <ChartContentCard
        exportData={{
          fileName: t('analytics.filter-category-chart.title'),
          data: filtersDistribution,
        }}
        chart={
          <BarChart
            yLabel={t('analytics.filter-category-chart.percentage-label')}
            isLoadingLabel={t('common.charts-loading')}
            isLoading={isFiltersDistributionLoading}
            layout={LayoutType.vertical}
            yTickMaximumTextLength={10}
            data={filtersDistributionGrouped.map(filter => ({
              x: filter.percentage,
              y: filter.content,
            }))}
            minWidth={220}
            minHeight={230}
            noDataLabel={t('common.charts-no-data')}
            toolTipFormatter={(payload: any) => {
              let index = filtersDistributionGrouped.findIndex(value => value.content === payload.payload.y);
              return (
                <>
                  {numeral(filtersDistributionGrouped[index].percentage).format('0.00') + '%'}
                  <div>
                    {t('analytics.filter-category-chart.count-label') + ' : ' + filtersDistributionGrouped[index].count}
                  </div>
                </>
              );
            }}
          />
        }
        title={t('analytics.filter-category-chart.title')}
        isLoadedAndEmpty={!isFiltersDistributionLoading && !filtersDistributionGrouped.length}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
        ) =>
          dispatch(
            SetFiltersDistribution(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
          )
        }
        cardExpandDetails={
          !isFiltersDistributionLoading && filtersDistribution.length > 8
            ? {
                footerLinkText: t('analytics.filter-category-chart.see-more'),
                modalTitle: t('analytics.filter-category-chart.title'),
                items: filtersDistribution,
                columns: [
                  {
                    key: 'Filter name',
                    name: t('analytics.filter-category-chart.filter-name-label'),
                    fieldName: 'content',
                    minWidth: 150,
                    isResizable: true,
                    isMultiline: false,
                    onRender: (item?: TotalPercentagContent): React.ReactNode =>
                      item && (
                        <div
                          data-selection-disabled={true}
                          title={item.content}
                          className={classNames.modalFirstColumn}
                        >
                          {item.content}
                        </div>
                      ),
                  },
                  {
                    key: 'count',
                    name: t('analytics.filter-category-chart.count-label'),
                    fieldName: 'count',
                    minWidth: 100,
                    isMultiline: false,
                  },
                  {
                    key: 'percentage',
                    name: t('analytics.filter-category-chart.percentage-label'),
                    fieldName: 'percentage',
                    minWidth: 100,
                    isMultiline: false,
                    // eslint-disable-next-line react/display-name
                    onRender: (item: TotalPercentagContent) => {
                      return <span>{numeral(item.percentage).format('0.0000') + '%'}</span>;
                    },
                  },
                ],
              }
            : undefined
        }
      />
    </div>
  );
};
