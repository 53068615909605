import { Action } from 'redux';

export const ADD_DATASET = 'ADD_DATASET';
export const SET_DATASET_LIST = 'SET_DATASET_LIST';
export const RESET_DATASET_LIST = 'RESET_DATASET_LIST';
export const LOADING_DATASET_LIST = 'LOADING_DATASET_LIST';

export enum DatasetType {
  Unknown = 'Unknown',
  RawData = 'RawData',
  LightGBMModel = 'LightGBMModel',
  LightGBMAMLLog = 'LightGBMAMLLog',
  LightGBMCookedData = 'LightGBMCookedData',
  LightGBMNDCGMetric = 'LightGBMNDCGMetric',
}

export interface DatasetMapper {
  [key: string]: Dataset;
}

export interface Dataset {
  key: string;
  name: string;
  email: string;
  isNew: boolean;
  createdAt: string;
  updatedAt: string;
  description: string;
  datasetType: DatasetType;
}

export interface DatasetState {
  isLoading: boolean;
  datasetMapper: DatasetMapper;
}

interface SetDatasetList extends Action<typeof SET_DATASET_LIST> {
  datasetList: Dataset[];
}

interface AddDataset extends Action<typeof ADD_DATASET> {
  dataset: Dataset;
}

export type DatasetStateActionTypes =
  | AddDataset
  | SetDatasetList
  | Action<typeof RESET_DATASET_LIST>
  | Action<typeof LOADING_DATASET_LIST>;
