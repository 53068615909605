import { OverviewStyleProps, OverviewStyles } from './Overview.types';

export const styles = (props: OverviewStyleProps): OverviewStyles => ({
  title: [
    'mbc-title',
    {
      fontSize: 18,
      lineHeight: 28,
      color: props.theme.palette.neutralPrimary,
    },
  ],
  disabled: [
    'mbc-disabled',
    {
      selectors: {
        path: {
          fill: props.theme.palette.neutralSecondaryAlt,
        },
        '.mbc-service-label': {
          color: props.theme.palette.neutralSecondaryAlt,
        },
      },
    },
  ],
  serviceButton: [
    'mbc-service-button',
    {
      width: 132,
      height: 112,
      border: 'none',
      selectors: {
        i: {
          width: 36,
          height: 36,
        },
        '&.is-disabled': {
          backgroundColor: 'unset',
        },
        ':hover': {
          borderWidth: 1,
          borderRadius: 4,
          borderStyle: 'solid',
          boxSizing: 'border-box',
          background: props.theme.palette.white,
          borderColor: props.theme.palette.themePrimary,
          boxShadow: '0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  ],
  serviceLabel: [
    'mbc-service-label',
    {
      fontSize: 14,
      lineHeight: 20,
      color: props.theme.palette.neutralPrimary,
    },
  ],
  descriptionLabel: [
    'mbc-desc-label',
    {
      fontSize: 14,
      maxWidth: 800,
      lineHeight: 20,
      color: props.theme.palette.neutralSecondaryAlt,
    },
  ],
  sectionSeparator: [
    'mbc-section-separator',
    {
      background: props.theme.palette.neutralLight,
      width: '100%',
      height: 1,
    },
  ],
  regionsTag: [
    {
      width: 'max-content',
      padding: '3px 8px',
      fontSize: 14,
      lineHeight: 20,
      background: props.theme.palette.neutralLighter,
      color: props.theme.palette.neutralPrimary,
    },
  ],
});
