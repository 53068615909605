import {
  PUSH_NOTIFICATION_MESSAGE,
  POP_NOTIFICATION_MESSAGE,
  CLEAR_NOTIFICATION_MESSAGES,
  NotificationMessageActionTypes,
  NotificationMessagesState,
} from '../types/notificationMessage.d';

const initialState: NotificationMessagesState = {
  notificationMessages: [],
};

export function NotificationMessageReducer(
  state = initialState,
  action: NotificationMessageActionTypes,
): NotificationMessagesState {
  switch (action.type) {
    case PUSH_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        notificationMessages: state.notificationMessages.concat(action.notificationMessage),
      };
    }
    case POP_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        notificationMessages: state.notificationMessages.slice(1),
      };
    }
    case CLEAR_NOTIFICATION_MESSAGES: {
      return {
        ...state,
        notificationMessages: [],
      };
    }
    default:
      return state;
  }
}
