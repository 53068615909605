import { PageHeaderStyles, PageHeaderStyleProps } from './PageHeader.types';

export const styles = (props: PageHeaderStyleProps): PageHeaderStyles => {
  const { className, theme, shrinkTopMargin } = props;

  return {
    root: [
      'mbc-page-header',
      {
        marginTop: 64 - (shrinkTopMargin ? 44 : 0),
        paddingBottom: 34,
      },
      className,
    ],
    title: [
      'mbc-page-header-title',
      {
        fontSize: 28,
        lineHeight: 36,
        fontWeight: 600,
        marginRight: 4,
        color: theme.palette.neutralDark,
      },
    ],
    titleCaption: [
      'mbc-page-header-title-caption',
      {
        fontSize: 14,
        lineHeight: 20,
        marginTop: '6px !important',
        color: theme.palette.neutralPrimary,
      },
    ],
  };
};
