import { Action } from 'redux';

export const ADD_BREADCRUMB_ITEM = 'ADD_BREADCRUMB_ITEM';
export const UPDATE_BREADCRUMB_ITEM = 'UPDATE_BREADCRUMB_ITEM';
export const REMOVE_BREADCRUMB_ITEM = 'REMOVE_BREADCRUMB_ITEM';
export const CLEAR_BREADCRUMB_ITEMS = 'CLEAR_BREADCRUMB_ITEMS';

export interface BreadcrumbItem {
  key: string;
  href: string;
  text?: string;
  onItemRender?: () => JSX.Element;
}

export interface BreadcrumbState {
  breadcrumbItems: BreadcrumbItem[];
}

interface AddBreadcrumbItem extends Action<typeof ADD_BREADCRUMB_ITEM> {
  breadcrumbItem: BreadcrumbItem;
}

interface UpdateBreadcrumbItem extends Action<typeof UPDATE_BREADCRUMB_ITEM> {
  breadcrumbItem: BreadcrumbItem;
}

interface RemoveBreadcrumbItem extends Action<typeof REMOVE_BREADCRUMB_ITEM> {
  breadcrumbItemKey: string;
}

export type BreadcrumbStateActionTypes =
  | AddBreadcrumbItem
  | UpdateBreadcrumbItem
  | RemoveBreadcrumbItem
  | Action<typeof CLEAR_BREADCRUMB_ITEMS>;
