import {
  SHOW_TOAST,
  ToastNotificationActionTypes,
  REMOVE_TOAST,
  ToastNotification,
  UPDATE_TOAST,
} from '../types/toastNotification.d';

export const removeToast = (toastId: string): ToastNotificationActionTypes => {
  return { type: REMOVE_TOAST, id: toastId };
};

export const showToast = (toastNotification: ToastNotification): ToastNotificationActionTypes => {
  return { type: SHOW_TOAST, toastNotification: toastNotification };
};

export const updateToast = (toastNotification: ToastNotification): ToastNotificationActionTypes => {
  return { type: UPDATE_TOAST, toastNotification: toastNotification };
};
