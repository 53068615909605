import {
  SET_INDEX_PRODUCT_COUNT,
  LOADING_INDEX_PRODUCT_COUNT,
  SET_INDEX_REQUESTS_PER_DAY,
  LOADING_INDEX_REQUESTS_PER_DAY,
  SET_INDEX_DOCUMENTS_PER_SECOND,
  LOADING_INDEX_DOCUMENTS_PER_SECOND,
  SET_LATENCY_PER_DOCUMENT,
  LOADING_LATENCY_PER_DOCUMENT,
  SET_INDEX_AVG_DPS,
  LOADING_INDEX_AVG_DPS,
  SET_DOCUMENTS_BREAK_DOWN,
  LOADING_DOCUMENTS_BREAK_DOWN,
  SET_PROVIDED_DOCUMENTS,
  LOADING_PROVIDED_DOCUMENTS,
  InsightsState,
  InsightsStateActionTypes,
  CachePeriod,
  LOADING_FEATURE_COVERAGE,
  SET_FEATURE_COVERAGE,
  LOADING_KEY_LEVEL_ERROR,
  SET_KEY_LEVEL_ERROR,
} from '../types/insights.d';

const initialState: InsightsState = {
  indexProductCount: {
    series: [],
    cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
  },
  isIndexProductCountLoading: true,

  indexRequestsPerDay: {
    series: [],
    cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
  },
  isIndexRequestsPerDayLoading: true,

  indexDocumentsPerSecond: {
    series: [],
    cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
  },
  isIndexDocumentsPerSecondLoading: true,

  latencyPerDocument: {
    series: [],
    cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
  },
  isLatencyPerDocumentLoading: true,

  documentsBreakDown: {
    series: [],
    cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
  },
  isDocumentsBreakDownLoading: true,

  providedDocuments: {
    series: [],
    cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
  },
  isProvidedDocumentsLoading: true,

  indexAvgDPS: { value: NaN, cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } },
  isIndexAvgDPSLoading: true,

  featureCoverage: { series: [], cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } },
  isFeatureCoverageLoading: true,

  keyLevelError: { series: [], cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } },
  isKeyLevelErrorLoading: true,
};

export function insightsReducer(state = initialState, action: InsightsStateActionTypes): InsightsState {
  switch (action.type) {
    case LOADING_INDEX_PRODUCT_COUNT: {
      return {
        ...state,
        isIndexProductCountLoading: true,
      };
    }
    case SET_INDEX_PRODUCT_COUNT: {
      return {
        ...state,
        isIndexProductCountLoading: false,
        indexProductCount: action.indexProductCount,
      };
    }
    case LOADING_INDEX_REQUESTS_PER_DAY: {
      return {
        ...state,
        isIndexRequestsPerDayLoading: true,
      };
    }
    case SET_INDEX_REQUESTS_PER_DAY: {
      return {
        ...state,
        isIndexRequestsPerDayLoading: false,
        indexRequestsPerDay: action.indexRequestsPerDay,
      };
    }
    case LOADING_INDEX_DOCUMENTS_PER_SECOND: {
      return {
        ...state,
        isIndexDocumentsPerSecondLoading: true,
      };
    }
    case SET_INDEX_DOCUMENTS_PER_SECOND: {
      return {
        ...state,
        isIndexDocumentsPerSecondLoading: false,
        indexDocumentsPerSecond: action.indexDocumentsPerSecond,
      };
    }
    case LOADING_LATENCY_PER_DOCUMENT: {
      return {
        ...state,
        isLatencyPerDocumentLoading: true,
      };
    }
    case SET_LATENCY_PER_DOCUMENT: {
      return {
        ...state,
        isLatencyPerDocumentLoading: false,
        latencyPerDocument: action.latencyPerDocument,
      };
    }
    case LOADING_INDEX_AVG_DPS: {
      return {
        ...state,
        isIndexAvgDPSLoading: true,
      };
    }
    case SET_INDEX_AVG_DPS: {
      return {
        ...state,
        isIndexAvgDPSLoading: false,
        indexAvgDPS: action.indexAvgDPS,
      };
    }
    case LOADING_DOCUMENTS_BREAK_DOWN: {
      return {
        ...state,
        isDocumentsBreakDownLoading: true,
      };
    }
    case SET_DOCUMENTS_BREAK_DOWN: {
      return {
        ...state,
        isDocumentsBreakDownLoading: false,
        documentsBreakDown: action.documentsBreakDown,
      };
    }
    case LOADING_PROVIDED_DOCUMENTS: {
      return {
        ...state,
        isProvidedDocumentsLoading: true,
      };
    }
    case SET_PROVIDED_DOCUMENTS: {
      return {
        ...state,
        isProvidedDocumentsLoading: false,
        providedDocuments: action.providedDocuments,
      };
    }
    case LOADING_FEATURE_COVERAGE: {
      return {
        ...state,
        isFeatureCoverageLoading: true,
      };
    }
    case SET_FEATURE_COVERAGE: {
      return {
        ...state,
        featureCoverage: action.featureCoverage,
        isFeatureCoverageLoading: false,
      };
    }
    case LOADING_KEY_LEVEL_ERROR: {
      return {
        ...state,
        isKeyLevelErrorLoading: true,
      };
    }
    case SET_KEY_LEVEL_ERROR: {
      return {
        ...state,
        keyLevelError: action.keyLevelError,
        isKeyLevelErrorLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}
