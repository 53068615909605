import React, { useMemo } from 'react';
import { ErrorType } from '../ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { MbcErrorProps } from './MbcError.types';
import { browserHistory } from '../../history.module';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { NoItemsToDisplay, ActionProps } from '../common/NoItemsToDisplay';
import { useProxyRouter } from '../../store/hooks/use-router/useProxyRouter';

export const MbcErrorBase = (props: MbcErrorProps) => {
  const { errorType } = props;

  const { t } = useTranslation();

  const MbcProxyRouter = useProxyRouter();

  const actionProps: ActionProps = useMemo(
    () => ({
      buttonText: t('error-page.action-text'),
      onClick: () => browserHistory.push(MbcProxyRouter(MbcRouteKey.HomePage).url),
    }),
    [MbcProxyRouter, t],
  );

  switch (errorType) {
    case ErrorType.AccessDenied:
      return (
        <NoItemsToDisplay
          iconName={'ErrorPage'}
          actionProps={actionProps}
          text={t('error-page.access-text')}
          subText={t('error-page.access-sub-text')}
        />
      );
    case ErrorType.ResourceNotFound:
      return (
        <NoItemsToDisplay
          iconName={'ErrorPage'}
          actionProps={actionProps}
          text={t('error-page.not-found-text')}
          subText={t('error-page.not-found-sub-text')}
        />
      );
    default:
      return (
        <NoItemsToDisplay
          iconName={'ErrorPage'}
          actionProps={actionProps}
          text={t('error-page.unhandled-exception-text')}
          subText={t('error-page.unhandled-exception-sub-text')}
        />
      );
  }
};
