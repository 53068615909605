import React, { useCallback, ReactNode, useState } from 'react';
import { classNamesFunction, IColumn, TooltipHost, TooltipOverflowMode, Link, Stack } from 'office-ui-fabric-react';
import {
  UrlRedirectSetListProps,
  UrlRedirectSetListStyleProps,
  UrlRedirectSetListStyles,
} from './UrlRedirectSetList.types';
import { useSelector, useDispatch } from '../../../store/hooks';
import { AppState } from '../../../store/reducers';
import { PageTemplate } from '../../../components/common/PageTemplate';
import { PageHeaderProps } from '../../../components/common/PageHeader';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { UrlRedirectSet } from '../../../store/types/customizations/urlRedirectSet';
import {
  ColumnPriority,
  ColumnWithPriority,
  SortDataType,
  OptionsItemProps,
  ListItem,
} from '../../../components/common/DetailsList';
import { DetailsListCard } from '../../../components/common/DetailsListCard';
import { SearchInstanceSelector } from '../SearchInstance/SearchInstanceSelector';
import { deleteUrlRedirectSet } from '../../../store/actions/customizations/urlRedirectSetActions';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import {
  MbcUrlRedirectSetRouter,
  MbcSearchInstanceRouter,
} from '../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { ConfirmationDialog } from '../../../components/common/Dialog/ConfirmationDialog';
import { toggleSideNavExpanded } from '../../../store/actions/sideNavActions';
import { EnvironmentPermissionContext, EnvironmentPermissionContextType } from '../../../contexts';
import { Permission } from '../../../config/userPermissions.config';
import { useTranslation } from 'react-i18next';

interface UrlRedirectSetListItem extends ListItem {
  name: string;
}

const getClassNames = classNamesFunction<UrlRedirectSetListStyleProps, UrlRedirectSetListStyles>();

export const UrlRedirectSetListBase = (props: UrlRedirectSetListProps) => {
  const { styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const [redirectToDelete, setRedirectToDelete] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const isUrlRedirectSetListLoading = useSelector<boolean>((state: AppState) => state.urlRedirectSet.isLoading);
  const urlRedirectSetList = useSelector<UrlRedirectSet[]>((state: AppState) =>
    Object.values(state.urlRedirectSet.urlRedirectSetIdMapper),
  );
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);

  const i18n = useTranslation();

  const pageHeaderProps: PageHeaderProps = {
    title: i18n.t('url-redirects.title'),
    infoProps: {
      content: i18n.t('url-redirects.info-content'),
      headline: i18n.t('url-redirects.title'),
      linkProps: {
        linkText: i18n.t('url-redirects.info-link'),
        to: '/docs/Portal%20Documentation/#manage-redirects',
      },
    },
  };

  const handleEditItemClick = useCallback(
    (item: UrlRedirectSetListItem) => {
      MbcUrlRedirectSetRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id, item.key)(
        MbcRouteKey.UrlRedirectSetEditor,
      ).browserPush();
    },
    [activeEnvironment.id, activeSearchInstance.id, activeTenant.id],
  );

  const handleAddItemClick = useCallback(() => {
    MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id)(
      MbcRouteKey.NewUrlRedirectSet,
    ).browserPush();
  }, [activeEnvironment.id, activeSearchInstance.id, activeTenant.id]);

  const onRenderItemColumn = useCallback(
    (isAuthorized: boolean, item?: any, index?: number, column?: IColumn): ReactNode => {
      if (item && column) {
        switch (column.key) {
          case 'name':
            return (
              <Stack>
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.nameColumnCell}
                  style={{ maxWidth: column.maxWidth }}
                  content={item.name}
                >
                  {!!isAuthorized ? (
                    <Link
                      onClick={() => handleEditItemClick(item)}
                      style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    item.name
                  )}
                </TooltipHost>
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.nameColumnCellDescription}
                  style={{ maxWidth: column.maxWidth }}
                  content={item.name}
                >
                  {item.description}
                </TooltipHost>
              </Stack>
            );

          default:
            return <span>{item[column.fieldName as string] as string}</span>;
        }
      }
    },
    [classNames.nameColumnCell, classNames.nameColumnCellDescription, handleEditItemClick],
  );

  const getUrlRedirectDetailsListColumns = useCallback(
    (isAuthorized: boolean): ColumnWithPriority[] => {
      return [
        {
          key: 'name',
          name: 'Name',
          fieldName: 'name',
          priority: ColumnPriority.Primary,
          sortDataType: SortDataType.text,
          minWidth: 200,
          subTextField: 'description',
          onRender: (item?: any, index?: number, column?: IColumn) =>
            onRenderItemColumn(isAuthorized, item, index, column),
        },
      ];
    },
    [onRenderItemColumn],
  );

  const getOptionsItemProps = useCallback(
    (item: any, isAuthorized: boolean): OptionsItemProps[] => {
      return [
        {
          key: 'Edit',
          text: 'Edit',
          iconProps: { iconName: 'Edit' },
          disabled: !isAuthorized,
          onClick: () => {
            handleEditItemClick(item);
          },
        },
        {
          key: 'Delete',
          text: 'Delete',
          iconProps: { iconName: 'Delete' },
          disabled: !isAuthorized,
          onClick: () => {
            setRedirectToDelete(item.key);
          },
        },
      ];
    },
    [handleEditItemClick],
  );

  const onDeleteConfirmation = useCallback(() => {
    redirectToDelete && dispatch(deleteUrlRedirectSet(redirectToDelete));
    setRedirectToDelete(undefined);
  }, [dispatch, redirectToDelete]);

  const onDeleteDismiss = useCallback(() => {
    setRedirectToDelete(undefined);
  }, []);

  const getUrlRedirectSetListItems = useCallback((): UrlRedirectSetListItem[] => {
    return urlRedirectSetList.map(urlRedirect => {
      return {
        key: urlRedirect.id,
        name: urlRedirect.redirectUrl,
        description: urlRedirect.queryList.join(', '),
      };
    });
  }, [urlRedirectSetList]);

  const getUrlRedirectDetailsListProps = useCallback(
    (isAuthorized: boolean) => {
      return {
        isLoading: isUrlRedirectSetListLoading,
        items: getUrlRedirectSetListItems(),
        onRenderNoItemsToDisplay: {
          text: 'No redirects created yet',
          subText: isAuthorized
            ? 'Get started by creating your first redirect'
            : 'Permission required to create redirects is not granted',
          actionProps: isAuthorized
            ? {
                buttonText: 'Get started',
                onClick: handleAddItemClick,
              }
            : undefined,
        },
        responsiveColumns: {
          columns: getUrlRedirectDetailsListColumns(isAuthorized),
          secondaryBreakPoint: 500,
          teritaryBreakPoint: 800,
        },
        optionsItemProps: (item: any) => getOptionsItemProps(item, isAuthorized),
      };
    },
    [
      getOptionsItemProps,
      getUrlRedirectDetailsListColumns,
      getUrlRedirectSetListItems,
      handleAddItemClick,
      isUrlRedirectSetListLoading,
    ],
  );

  const getHeaderActions = useCallback(
    (isAuthorized: boolean) => {
      return isAuthorized && getUrlRedirectSetListItems().length > 0
        ? {
            add: {
              onClick: () => {
                handleAddItemClick();
              },
            },
          }
        : undefined;
    },
    [getUrlRedirectSetListItems, handleAddItemClick],
  );

  const onRenderContent = (isAuthorized: boolean): JSX.Element => {
    return (
      <DetailsListCard
        searchActionProps={{ searchBy: 'name' }}
        listProps={getUrlRedirectDetailsListProps(isAuthorized)}
        header={{
          actions: getHeaderActions(isAuthorized),
        }}
      />
    );
  };

  return (
    <div className={classNames.root}>
      <EnvironmentPermissionContext.Consumer>
        {(props: EnvironmentPermissionContextType) => {
          const isAuthorized = props.isAuthorized(Permission.SearchCustomizationReadWrite);
          return (
            <>
              <PageTemplate
                pageHeaderProps={pageHeaderProps}
                onRenderContent={() => onRenderContent(isAuthorized)}
                breadcrumbVisible={true}
                onSecondaryNavMenuRender={() => <SearchInstanceSelector />}
                sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
                sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
              />
              <ConfirmationDialog
                key={redirectToDelete}
                isOpen={!!redirectToDelete}
                title={'Delete redirect'}
                subText={`Are you sure you want to delete redirect ${redirectToDelete}?`}
                onConfirm={onDeleteConfirmation}
                onDismiss={onDeleteDismiss}
              ></ConfirmationDialog>
            </>
          );
        }}
      </EnvironmentPermissionContext.Consumer>
    </div>
  );
};
