import {
  IndexField,
  ProvisioningState,
  SearchIndex as SearchIndexModel,
  TransformationConfig as TransformationConfigModel,
} from '../../store/types/searchIndex.d';

import { IndexField as IndexFieldDTO } from '../dto/search-index/indexField';
import { SchemaDetection as SchemaDetectionDTO } from '../dto/search-index/schemaDetection';
import { SchemaDetection as SchemaDetectionModel } from '../../store/types/schemaDetection.d';
import { SearchIndexBase as SearchIndexDTO } from '../dto/search-index/searchIndex';
import { SearchIndexList as SearchIndexListDTO } from '../dto/search-index/searchIndexList';
import { TransformationConfig as TransformationConfigDTO } from '../dto/search-index/transformationConfig';
import { generateUniqueIdentifier } from '../../utils';

export function mapIndexFieldDtoToModel(indexFieldDTO: IndexFieldDTO): IndexField {
  return {
    key: generateUniqueIdentifier(),
    name: indexFieldDTO.name,
    type: indexFieldDTO.type,
    fields: indexFieldDTO.fields ? indexFieldDTO.fields.map(mapIndexFieldDtoToModel) : [],
    features: {
      searchable: indexFieldDTO.searchable,
      sortable: indexFieldDTO.sortable,
      facetable: indexFieldDTO.facetable,
      filterable: indexFieldDTO.filterable,
      retrievable: indexFieldDTO.retrievable,
    },
  };
}

export function mapIndexFieldModelToDTO(indexField: IndexField): IndexFieldDTO {
  return {
    name: indexField.name,
    type: indexField.type,
    fields: indexField.fields ? indexField.fields.map(mapIndexFieldModelToDTO) : [],
    sortable: indexField.features.sortable,
    facetable: indexField.features.facetable,
    searchable: indexField.features.searchable,
    filterable: indexField.features.filterable,
    retrievable: indexField.features.retrievable,
  };
}

export function mapSearchIndexDtoToModel(searchIndices: SearchIndexListDTO): SearchIndexModel {
  const searchIndex = searchIndices.indexes[0];
  return {
    id: searchIndex.id,
    name: searchIndex.name,
    description: searchIndex.description,
    searchScenario: searchIndex.searchScenario,
    transformationScript: searchIndex.transformationConfig,
    status: searchIndex.provisioningState as ProvisioningState,
    regions: searchIndex.regions,
    searchServices: searchIndex.SearchServices,
    schemaVersion: searchIndex.schemaVersion,
    devOpsEnabled: searchIndex.devOpsEnabled,
    fields: searchIndex.fields.map(mapIndexFieldDtoToModel),
  };
}

export function mapSearchIndexListDtoToModel(searchIndices: SearchIndexListDTO): SearchIndexModel[] {
  return searchIndices.indexes.map(searchIndex => ({
    id: searchIndex.id,
    name: searchIndex.name,
    description: searchIndex.description,
    searchScenario: searchIndex.searchScenario,
    transformationScript: searchIndex.transformationConfig,
    status: searchIndex.provisioningState as ProvisioningState,
    regions: searchIndex.regions,
    searchServices: searchIndex.SearchServices,
    schemaVersion: searchIndex.schemaVersion,
    devOpsEnabled: searchIndex.devOpsEnabled,
    fields: searchIndex.fields.map(mapIndexFieldDtoToModel),
  }));
}

export function mapSearchIndexModelToDTO(searchIndex: SearchIndexModel): SearchIndexDTO {
  return {
    id: searchIndex.id,
    name: searchIndex.name,
    description: searchIndex.description,
    regions: searchIndex.regions,
    SearchServices: searchIndex.searchServices,
    schemaVersion: searchIndex.schemaVersion,
    devOpsEnabled: searchIndex.devOpsEnabled,
    fields: searchIndex.fields.map(mapIndexFieldModelToDTO),
  };
}

export function mapSchemaDetectionDtoToModel(detectedSchema: SchemaDetectionDTO): SchemaDetectionModel {
  return {
    key: '',
    environmentId: '',
    transformationScript: detectedSchema.transformationConfig ? detectedSchema.transformationConfig.value : '',
    fields: detectedSchema.index.fields.map(mapIndexFieldDtoToModel),
  };
}

export function mapTransformationConfigDtoToModel(
  transformationConfig: TransformationConfigDTO,
  instanceId?: string,
): TransformationConfigModel {
  return { instanceId: instanceId, transformationScript: transformationConfig.value };
}
