import { SearchResults as SearchResultDTO, SearchRequest, SearchResults } from '../dto/search/search';
import { SearchResult as SearchResultModel, BoostItem } from '../../store/types/searchDemo.d';
import { getFacetsFromApiResponse } from '../facet.api';
import { FacetOption } from '../dto/search/facet';
import { FieldValueSuggestions } from '../../store/types/customizations/queryTester';

export function mapSearchResultsDtoToModel(
  searchResult: SearchResultDTO,
  selectedRefinements: FacetOption[],
): SearchResultModel {
  return {
    isLoading: false,
    facets: getFacetsFromApiResponse(searchResult, selectedRefinements),
    resultCount: searchResult.items ? searchResult.items.totalEstimatedMatches : 0,
    alteredQuery: searchResult.queryContext ? searchResult.queryContext.alteredQuery : '',
    originalQuery: searchResult.queryContext ? searchResult.queryContext.originalQuery : '',
    searchResultItems: searchResult.items
      ? searchResult.items.value.map(item => ({
          id: item.fields.productid,
          name: item.fields.name,
          description: item.fields.brand,
          price: item.fields.price,
          imgUrl: item.fields.imageurl,
          onSale: item.fields.onsale,
          categories: item.fields.categories,
          collection: item.fields.collection,
          promotionDisplay: item.fields.promotiondisplay,
          currency: '$',
        }))
      : [],
  };
}

export function mapSearchRequestToDTO(
  query: string,
  skip: number,
  count: number,
  allowQueryAlteration: boolean,
  personalizeForFemale: boolean = false,
  boostItem?: BoostItem,
): SearchRequest {
  return {
    query: {
      matchAll: query,
      alteration: allowQueryAlteration ? allowQueryAlteration : undefined,
      boosts: boostItem
        ? [
            {
              boost: 500,
              condition: {
                field: boostItem.key,
                _type: boostItem.type,
                value: boostItem.value,
                operator: boostItem.operator,
              },
            },
          ]
        : undefined,
    },
    items: {
      select: ['_score', '*'],
      top: count,
      skip: skip,
      dedupe: true,
    },
    client: personalizeForFemale ? { kind: 'UID', value: '2' } : undefined,
  };
}

const extractSuggestions = (refinements: FacetOption[], parentRefinement?: string): string[] => {
  let suggestions: string[] = [];
  !!refinements &&
    refinements.forEach((refinement: FacetOption) => {
      const refinementValue = !!parentRefinement
        ? `${parentRefinement}|${refinement.value as string}`
        : (refinement.value as string);
      !!refinement.value && suggestions.push(refinementValue);
      if (refinement.refinements && refinement.refinements.length > 0) {
        suggestions = suggestions.concat(extractSuggestions(refinement.refinements, refinementValue));
      }
    });
  return suggestions;
};

export const mapSearchResultToFieldValueSuggestions = (searchResults: SearchResults): FieldValueSuggestions[] => {
  return searchResults.aggregations
    ? searchResults.aggregations.map(aggregarion => {
        return {
          id: aggregarion.field,
          suggestions: extractSuggestions(aggregarion.refinements).sort(),
        };
      })
    : [];
};
