import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../../router/MbcRouter.base';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { useDispatch, useSelector } from '../../../store/hooks';
import { ActiveSearchModelLoading } from '../../../components/Loading';
import { useProxyRouter } from '../../../store/hooks/use-router/useProxyRouter';
import { useLazyBreadcrumb } from '../../../store/hooks/use-breadcrumb/useLazyBreadcrumb';
import { useCustomMLRouter } from '../../../store/hooks/use-router/useCustomMLRouter';
import { setActiveSearchModel, resetActiveSearchModel } from '../../../store/actions/customML/searchModelActions';
import { useActiveSearchModelAsserter } from '../../../store/hooks/use-asserter/useAsserter';

export const SearchModelProxy = (props: RouteComponentProps<{ searchModelKey: string }>) => {
  const dispatch = useDispatch();

  const MbcProxyRouteMapper = useProxyRouter();
  const MbcCustomMLRouteMapper = useCustomMLRouter();
  const isSearchModelListLoading = useSelector(state => state.searchModels.isLoading);
  const activeSearchModel = useSelector(state => state.searchModels.activeSearchModel);

  useActiveSearchModelAsserter(props.match.params.searchModelKey);

  useLazyBreadcrumb(
    props.match.params.searchModelKey,
    props.match.url,
    activeSearchModel.name,
    !isSearchModelListLoading && activeSearchModel.name !== '',
  );

  useEffect(() => {
    dispatch(setActiveSearchModel(props.match.params.searchModelKey));
    return () => {
      dispatch(resetActiveSearchModel());
    };
  }, [dispatch, props.match.params.searchModelKey, props.match.url, isSearchModelListLoading]);

  return (
    <ActiveSearchModelLoading>
      <MbcRouter
        routes={[
          MbcCustomMLRouteMapper(MbcRouteKey.SearchModel),
          MbcCustomMLRouteMapper(MbcRouteKey.DatasetList),
          MbcCustomMLRouteMapper(MbcRouteKey.TrainingJob),
          MbcProxyRouteMapper(MbcRouteKey.EvaluationJobListProxy),
        ]}
      />
    </ActiveSearchModelLoading>
  );
};
