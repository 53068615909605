import { SearchResultCardsStyleProps, SearchResultCardsStyles } from './SearchResultCards.types';
import { getFlexColumnStyles } from '../../../../../components/common/Styles';

export const styles = (props: SearchResultCardsStyleProps): SearchResultCardsStyles => ({
  metadata: [
    'mbc-meta-data',
    {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: 400,
      color: props.theme.palette.neutralPrimary,
    },
  ],
  bold: [
    'mbc-bold-text',
    {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: 600,
      color: props.theme.palette.neutralPrimary,
    },
  ],
  query: [
    'mbc-search-query',
    {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: 600,
      color: props.theme.palette.themePrimary,
    },
  ],
  separator: [
    'mbc-separator',
    {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: 400,
      margin: '0 8px',
      color: props.theme.palette.neutralLight,
    },
  ],
  header: [
    'mbc-result-header',
    {
      marginBottom: 32,
      marginRight: 16,
      marginLeft: 16,
      display: 'grid',
      justifyContent: 'space-between',
      gridTemplateColumns: `auto auto`,
    },
  ],
  cards: [
    'mbc-result-cards',
    {
      display: 'grid',
      gridRowGap: '12px',
      alignSelf: 'center',
      gridColumnGap: '12px',
      gridTemplateColumns: `repeat(${Math.floor(props.width / 300)}, min-content)`,
    },
  ],
  redirectUrl: [
    'mbc-redirect-url',
    {
      maxWidth: 200,
      lineHeight: 21,
      fontSize: 12,
      margin: '0 0 4px 0',
      textDecoration: 'none',
      color: props.theme.palette.green,
      selectors: {
        ':hover': {
          textDecoration: 'underline',
        },
      },
    },
  ],
  iFrameContainer: [
    'mbc-iframe-container',
    getFlexColumnStyles(),
    {
      border: 'none',
    },
  ],
  redirectUrlHeader: [
    'mbc-redirect-url-header',
    {
      fontSize: 14,
      lineHeight: 16,
      fontWeight: 600,
      color: '#292827',
    },
  ],
  subComponentStyles: {
    modal: {
      root: {
        selectors: {
          '.ms-Overlay': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
      main: [
        'mbc-main-modal',
        {
          position: 'absolute',
          left: props.left,
          width: props.width,
          maxHeight: 'fit-content',
          minHeight: 'fit-content',
        },
      ],
      scrollableContent: [
        'mbc-modal-content',
        {
          overflow: 'auto',
          maxHeight: 'calc(100vh - 32px)',
        },
      ],
    },
    listSearchItemCard: {
      root: {
        border: '1px solid #D2D0CE',
        boxSizing: 'border-box',
        borderRadius: 4,
        maxHeight: 406,
        minHeight: 250,
      },
    },
  },
});
