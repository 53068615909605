import React from 'react';
import { NavBarRightMenu } from './NavBarRightMenu';
import ProgressIndicator from '../Loading/ProgressIndicator';
import { MbcTitle } from '../../pages/HomePage/common/MbcTitle';
import { classNamesFunction } from 'office-ui-fabric-react';
import { NavBarPivot } from './NavBarPivot';
import { MbcNavBarStyleProps, MbcNavBarStyles, MbcNavBarBaseProps } from './MbcNavBar.types';
import { useMediaQuery } from 'react-responsive';

const getClassNames = classNamesFunction<MbcNavBarStyleProps, MbcNavBarStyles>();

export const MbcNavBarBase = (props: MbcNavBarBaseProps) => {
  const { theme, styles, className, componentRef } = props;

  const mediumScreen = useMediaQuery({ query: '(max-width: 799px)' });
  const smallScreen = useMediaQuery({ query: '(max-width: 470px)' });

  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
    mediumScreen: mediumScreen,
    smallScreen: smallScreen,
  });

  return (
    <div className={classNames.root} ref={componentRef} role="navigation">
      <header>
        <div className={classNames.navBarContent}>
          <MbcTitle whiteText />
          <NavBarPivot className={classNames.pivotContainer} />
          <NavBarRightMenu className={classNames.navBarRightSide} />
        </div>
        <ProgressIndicator />
      </header>
    </div>
  );
};
