import { useSelector } from '../../hooks';
import { useMemo } from 'react';
import { AppState } from '../../reducers';
import { distinct } from '../../../utils';
import { usePermissions } from './UsePermissions';

export const useEnvironmentPermissions = (environmentId?: string) => {
  const userPermissions = usePermissions();
  const activeEnvironmentId = useSelector((state: AppState) => state.environmentList.activeEnvironment.id);
  return useMemo(
    () =>
      distinct(
        userPermissions.environmentPermissionsMapper[environmentId || activeEnvironmentId].concat(
          userPermissions.allEnvironmentsPermissions,
        ),
        (x, y) => x === y,
      ),
    [
      environmentId,
      activeEnvironmentId,
      userPermissions.environmentPermissionsMapper,
      userPermissions.allEnvironmentsPermissions,
    ],
  );
};
