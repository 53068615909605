import { SearchTrafficMetricCardsStyles, SearchTrafficMetricCardsStyleProps } from './SearchTrafficMetricCards.types';

export const getStyles = (props: SearchTrafficMetricCardsStyleProps): SearchTrafficMetricCardsStyles => ({
  root: [
    {
      selectors: {
        '@media only screen and (min-width:1400px)': {
          display: 'flex',
          justifyContent: 'space-between',
        },
        '@media only screen and (max-width:1399px)': {
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          columnGap: '21px',
          gridRowGap: '21px',
          width: 'calc(100% - 21px)',
        },
        '@media only screen and (max-width: 855px)': {
          display: 'grid',
          gridTemplateColumns: '100%',
          width: '100%',
        },
      },
    },
    'ms-search-metrics',
    {
      selectors: {
        '.ms-content-card': {
          minWidth: 350,
          height: 320,
        },
      },
    },
    props.className,
  ],
  middleCards: [
    {
      selectors: {
        '@media only screen and (min-width:1400px)': {
          marginLeft: '21px',
        },
      },
    },
  ],
  subComponentStyles: {
    contentCard: {},
    metricCard: {
      header: {
        justifyContent: 'center',
      },
    },
  },
});
