import React, { useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { createInitialToken } from '../../store/types/tokenList.d';
import { revokeToken } from '../../store/actions/tokenListActions';
import { classNamesFunction } from 'office-ui-fabric-react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../store/reducers';
import { useDispatch } from '../../store/hooks';
import { TokenListBaseProps, TokenListStyles, TokenListStyleProps } from './TokenList.types';
import { TenantPermissionContext, TenantPermissionContextType } from '../../contexts';
import { PageTemplate } from '../../components/common/PageTemplate';
import { SideNavMode } from '../../components/common/PageTemplate/PageTemplate.types';
import { Permission } from '../../config/userPermissions.config';
import { DetailsListCard } from '../../components/common/DetailsListCard/DetailsListCard';
import { ColumnPriority, ListItem, SortDataType } from '../../components/common/DetailsList';
import { ConfirmationDialog as RevokeDialog } from '../../components/common/Dialog/ConfirmationDialog';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { MbcRouteKey } from '../../router/MbcRouter.types';

const getClassNames = classNamesFunction<TokenListStyleProps, TokenListStyles>();

export const TokenListBase = (props: TokenListBaseProps) => {
  const { t, className, componentRef, styles, theme } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const tokens = useSelector((state: AppState) => state.tokenList.tokens);
  const tenantId = useSelector((state: AppState) => state.tenantsList.activeTenant.id);
  const isTokenListLoading = useSelector((state: AppState) => state.tokenList.isLoading);

  const [showTokenRevokeDialog, setShowTokenRevokeDialog] = useState(false);
  const [editableToken, setEditableToken] = useState(createInitialToken());

  const adminRouteMap = useAdminRouter();

  const dispatch = useDispatch();

  const onMenuItemRemoveInvoked = (item: any) => {
    setShowTokenRevokeDialog(true);
    setEditableToken({
      ...item,
      isRefreshToken: item.isRefreshToken === t('tokens-list.has-refresh-token'),
    });
  };

  const onCancelTokenRevoke = () => {
    setShowTokenRevokeDialog(false);
    setEditableToken(createInitialToken());
  };

  const onConfirmTokenRevoke = () => {
    dispatch(revokeToken(tenantId, editableToken));

    onCancelTokenRevoke();
  };

  const getDisplayedTokens = useCallback((): ListItem[] => {
    return tokens
      .map((item, index) => ({
        ...item,
        key: item.name + index,
        isRefreshToken: item.isRefreshToken ? t('tokens-list.has-refresh-token') : t('tokens-list.no-refresh-token'),
        expirationDate: moment
          .parseZone(item.expirationDate as string)
          .local()
          .format('YYYY-MM-DD HH:mm:ss'),
        createdAt: moment
          .parseZone(item.createdAt as string)
          .local()
          .format('YYYY-MM-DD HH:mm:ss'),
      }))
      .sort((item1, item2) => (new Date(item1.createdAt) > new Date(item2.createdAt) ? -1 : 1));
  }, [t, tokens]);

  return (
    <TenantPermissionContext.Consumer>
      {(props: TenantPermissionContextType) => {
        return (
          <div className={classNames.root} ref={componentRef}>
            <PageTemplate
              breadcrumbVisible
              pageHeaderProps={{ title: t('tokens-list.title'), titleCaption: t('tokens-list.title-caption') }}
              sideNavMode={SideNavMode.None}
              sideNavOnClick={() => {}}
              onRenderContent={() => (
                <>
                  <RevokeDialog
                    key={editableToken.id}
                    isOpen={showTokenRevokeDialog}
                    title={t('tokens-list.revoke-title')}
                    content={
                      editableToken.isRefreshToken ? (
                        <Trans i18nKey="tokens-list.revoke-note">
                          <strong>Note:</strong> This is a refresh token, revoking this token will also revoke all
                          access tokens related to it.
                        </Trans>
                      ) : (
                        undefined
                      )
                    }
                    subText={t('tokens-list.revoke-subtext', { name: editableToken.name })}
                    onConfirm={onConfirmTokenRevoke}
                    onDismiss={onCancelTokenRevoke}
                  ></RevokeDialog>

                  <DetailsListCard
                    header={{
                      actions: {
                        add: {
                          disabled: !props.isAuthorized(Permission.AdminTokenCreate),
                          onClick: () => {
                            adminRouteMap(MbcRouteKey.CreateToken).browserPush();
                          },
                        },
                        filter: {
                          filterableFields: [
                            { fieldName: 'isRefreshToken', displayName: t('tokens-list.th-refresh-token') },
                            { fieldName: 'createdBy', displayName: t('tokens-list.th-created-by') },
                          ],
                        },
                      },
                    }}
                    searchActionProps={{ searchBy: 'name' }}
                    listProps={{
                      isLoading: isTokenListLoading,
                      items: getDisplayedTokens(),
                      onRenderNoItemsToDisplay: {
                        text: t('tokens-list.no-data-text'),
                        subText: t('tokens-list.no-data-sub-text'),
                        actionProps: {
                          buttonText: t('common.no-data-button-text'),
                          disabled: !props.isAuthorized(Permission.AdminTokenCreate),
                          onClick: () => {
                            adminRouteMap(MbcRouteKey.CreateToken).browserPush();
                          },
                        },
                      },
                      optionsItemProps: (item: ListItem) => [
                        {
                          key: 'remove',
                          disabled: !props.isAuthorized(Permission.AdminTokenRevoke),
                          data: item,
                          text: t('tokens-list.remove-menu'),
                          iconProps: { iconName: 'Delete' },
                          onClick: () => onMenuItemRemoveInvoked(item),
                        },
                      ],
                      responsiveColumns: {
                        columns: [
                          {
                            key: 'name',
                            name: t('tokens-list.th-token-name'),
                            fieldName: 'name',
                            minWidth: 100,
                            maxWidth: 250,
                            priority: ColumnPriority.Primary,
                          },
                          {
                            key: 'createdAt',
                            name: t('tokens-list.th-created-at'),
                            fieldName: 'createdAt',
                            centerAligned: true,
                            minWidth: 100,
                            maxWidth: 200,
                            isMultiline: false,
                            priority: ColumnPriority.Tertiary,
                            sortDataType: SortDataType.dateTime,
                          },
                          {
                            key: 'expirationDate',
                            name: t('tokens-list.th-expiration-date'),
                            fieldName: 'expirationDate',
                            centerAligned: true,
                            minWidth: 120,
                            maxWidth: 200,
                            isMultiline: false,
                            sortDataType: SortDataType.dateTime,
                            priority: ColumnPriority.Primary,
                          },
                          {
                            key: 'createdBy',
                            name: t('tokens-list.th-created-by'),
                            fieldName: 'createdBy',
                            centerAligned: true,
                            minWidth: 100,
                            maxWidth: 220,
                            isMultiline: false,
                            priority: ColumnPriority.Tertiary,
                          },
                          {
                            key: 'refresh-token',
                            name: t('tokens-list.th-refresh-token'),
                            fieldName: 'isRefreshToken',
                            centerAligned: true,
                            minWidth: 120,
                            maxWidth: 300,
                            isMultiline: false,
                            priority: ColumnPriority.Secondary,
                          },
                        ],
                        secondaryBreakPoint: 750,
                        teritaryBreakPoint: 1100,
                      },
                      isHeaderVisible: true,
                    }}
                  />
                </>
              )}
            />
          </div>
        );
      }}
    </TenantPermissionContext.Consumer>
  );
};
