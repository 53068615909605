import { AnalyticsFiltersStyleProps, AnalyticsFiltersStyles } from './AnalyticsFilters.types';

export const styles = (props: AnalyticsFiltersStyleProps): AnalyticsFiltersStyles => {
  return {
    root: [
      'mbc-analytics-filters',
      {
        display: 'grid',
      },
      props.className,
    ],
    filtersContainer: [
      'mbc-filters-container',
      {
        paddingBottom: 21,

        selectors: {
          '@media only screen and (min-width: 1520px)': {
            display: 'flex',
            justifyContent: 'space-between',
          },
          '@media only screen and (max-width: 1519px)': {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            columnGap: '21px',
            gridRowGap: '21px',
            width: 'calc(100% - 21px)',
          },
          '@media only screen and (max-width: 855px)': {
            display: 'grid',
            gridTemplateColumns: '100%',
            width: '100%',
          },
        },
      },
    ],
    filtersCombobox: [
      'mbc-filters-combo-box',
      {
        flexGrow: 1,
        minWidth: 220,
        width: '100%',
        selectors: {
          '.ms-Label': {
            marginRight: 10,
            fontSize: 13,
            fontWeight: 600,
          },
          '.ms-ComboBox': {
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
            fontSize: '0.8rem',
            fontWeight: 200,
            selectors: {
              ':hover': {
                border: '1px solid rgba(0, 0, 0, 0.2)',
                boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
              },
            },
          },

          '.ms-Button': {
            border: '1px solid rgba(0, 0, 0, 0.1)',
            background: '#fbfcfe',
            selectors: {
              ':hover': {
                background: 'rgba(0, 0, 0, 0.1)',
              },
            },
          },
        },
      },
    ],
    middleFilters: [
      'mbc-middle-filters',
      {
        flexGrow: 1,
        minWidth: 220,
        width: '100%',
        selectors: {
          // '@extend .filtersCombobox',
          '@media only screen and (min-width: 1520px)': {
            marginLeft: 21,
          },
          '.ms-Label': {
            marginRight: 10,
            fontSize: 13,
            fontWeight: 600,
          },
          '.ms-ComboBox': {
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
            fontSize: '0.8rem',
            fontWeight: 200,
            selectors: {
              ':hover': {
                border: '1px solid rgba(0, 0, 0, 0.2)',
                boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
              },
            },
          },

          '.ms-Button': {
            border: '1px solid rgba(0, 0, 0, 0.1)',
            background: '#fbfcfe',
            selectors: {
              ':hover': {
                background: 'rgba(0, 0, 0, 0.1)',
              },
            },
          },
        },
      },
    ],
    selectedfilterlabel: [
      'mbc-selected-filters-label',
      {
        marginTop: 5,
        fontSize: 13,
      },
    ],
    selectedFiltersTagsContainer: [
      'mbc-selected-filters-tags',
      {
        paddingBottom: 21,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        selectors: {
          '.ms-Button': {
            backgroundColor: '#0061a5',
            height: 'inherit',
          },
          '.ms-Button-icon': {
            fontSize: 10,
            color: 'white',
          },
        },
      },
    ],
    selectedFiltersTag: [
      'mbc-selected-filters-tag',
      {
        marginLeft: 12,
        marginTop: 5,
        borderRadius: 4,
        display: 'flex',
        background: '#004185',
        color: 'white',
      },
    ],
    filterName: [
      'mbc-filter-name',
      {
        fontSize: 12,
        padding: 5,
        alignSelf: 'center',
      },
    ],
    dateErrorMessage: [
      'mbc-date-error-message',
      {
        fontSize: 12,
        color: '#f04a47',
      },
    ],
  };
};
