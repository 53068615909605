import { IndexField } from '../../../../../store/types/searchIndex.d';
import { FilterableFields } from '.';

export const getFilterCorrespondingField = (field: IndexField, filterName: string): any => {
  switch (filterName) {
    case FilterableFields.Searchable: {
      return field.features.searchable;
    }
    case FilterableFields.Filterable: {
      return field.features.filterable;
    }
    case FilterableFields.Facetable: {
      return field.features.facetable;
    }
    case FilterableFields.Retrievable: {
      return field.features.retrievable;
    }
    case FilterableFields.Sortable: {
      return field.features.sortable;
    }
    case FilterableFields.Type: {
      return field.type;
    }
    default: {
      return null;
    }
  }
};
