import HttpClient from '../http-client/httpClient';
import { getDevOpsCommitHeaders } from '../devops.api';
import { DevOpsCommitMetadata } from '../../store/types/devOps.d';
import {
  SynonymSetList as SynonymSetListDTO,
  SynonymSetUpdateResponse,
  SynonymSetDeleteResponse,
} from '../dto/customizations/synonymSet';
import { SynonymSet } from '../../store/types/customizations/synonymSet.d';
import {
  mapSynonymSetListDtoToModel,
  mapSynonymSetModelToDto,
  mapSynonymSetDtoToModel,
  mapDeletedSynonymSetDtoToModel,
} from '../mappers/customizations/synonymSetMapper';
import { isDynamicsTenant } from '../../utils/dynamics';

const getSynonymSetBaseUrl = (tenantId: string, searchIndexId: string): string => {
  return `https://commerce.bing.com/api/customization/v1/synonym/${tenantId}/indexes/${searchIndexId}`;
};

export function getSynonymSetList(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
): Promise<SynonymSet[]> {
  // special handling due to dynamics tenant limitations
  if (isDynamicsTenant(tenantId)) {
    return Promise.resolve([]);
  }
  const url = `${getSynonymSetBaseUrl(tenantId, searchIndexId)}?searchinstanceid=${searchInstanceId}`;
  return HttpClient.get<SynonymSetListDTO>({
    url: url,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapSynonymSetListDtoToModel);
}

export function updateSynonymSet(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  synonymSet: SynonymSet,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<SynonymSet> {
  const mappedSynonymSet = mapSynonymSetModelToDto(synonymSet);
  const requestBody = { ...mappedSynonymSet, searchInstanceId: searchInstanceId };

  const url = getSynonymSetBaseUrl(tenantId, searchIndexId);
  return HttpClient.post<SynonymSetUpdateResponse>({
    url: url,
    body: requestBody,
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapSynonymSetDtoToModel);
}

export function deleteSynonymSet(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  synonymSetId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<SynonymSet> {
  const url = `${getSynonymSetBaseUrl(
    tenantId,
    searchIndexId,
  )}?searchinstanceid=${searchInstanceId}&synonymId=${synonymSetId}`;

  return HttpClient.delete<SynonymSetDeleteResponse>({
    url: url,
    body: {},
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapDeletedSynonymSetDtoToModel);
}
