import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { Action } from 'redux';
import {
  CookingJob,
  LOADING_COOKING_JOB_LIST,
  SET_COOKING_JOB_LIST,
  RESET_COOKING_JOB_LIST,
  UPDATE_COOKING_JOB_LIST,
} from '../../types/customML/cookingJob.d';
import { listCookedJobs } from '../../../restful-apis/customML.api';
import { ExecuteReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../utils';
import { batchCreateCookJobAction } from '../utils/crud-actions/cooking-job/CookingJobCrudActions';

export const batchCookDataset = (
  tenantId: string,
  searchModelKey: string,
  cookingJobList: CookingJob[],
  onSuccess?: ActionSuccessHandler<CookingJob[]>,
  onFailure?: ActionFailureHandler<CookingJob[]>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(batchCreateCookJobAction(tenantId, searchModelKey, cookingJobList, onSuccess, onFailure));

export const setCookingJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_COOKING_JOB_LIST,
    });

    listCookedJobs(tenantId).then(cookingJobList => {
      dispatch({
        type: SET_COOKING_JOB_LIST,
        cookingJobList: cookingJobList,
      });
    });
  };
};

export const updateCookingJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    listCookedJobs(tenantId, undefined, true).then(cookingJobList => {
      dispatch({
        type: UPDATE_COOKING_JOB_LIST,
        cookingJobList: cookingJobList,
      });
    });
  };
};

export const resetCookingJobList = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: RESET_COOKING_JOB_LIST,
    });
  };
};
