import { useSelector } from '..';
import { useMemo } from 'react';
import { IndexField } from '../../types/searchIndex';
import { SearchIndexTypeConfig } from '../../../pages/EnvironmentList/Environment/SchemaManagement/SchemaManagement.config';

export const selectFacetableStringFields = (fields: IndexField[], parentHierarchalFieldName: string = ''): string[] => {
  let facetableStringFields: string[] = [];
  fields.forEach(field => {
    const hierarchalFieldName = parentHierarchalFieldName ? `${parentHierarchalFieldName}.${field.name}` : field.name;
    if (field.fields && field.fields.length > 0) {
      facetableStringFields = facetableStringFields.concat(
        selectFacetableStringFields(field.fields, hierarchalFieldName),
      );
    }
    let fieldTypeConfig = SearchIndexTypeConfig[field.type];
    if (field.features.facetable && !!fieldTypeConfig && ['String', 'Array'].includes(fieldTypeConfig.dataType)) {
      facetableStringFields.push(hierarchalFieldName);
    }
  });
  return facetableStringFields;
};

export const useFacetableStringFields = (): string[] => {
  const indexFields = useSelector(state => state.searchIndex.searchIndex.fields);

  return useMemo(() => selectFacetableStringFields(indexFields), [indexFields]);
};
