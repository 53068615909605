import React, { useCallback, useState, useEffect } from 'react';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import {
  QueryTesterEditorProps,
  QueryTesterEditorStyleProps,
  QueryTesterEditorStyles,
} from './QueryTesterEditor.types';
import { ConditionBlockEditor } from '../../common/ConditionBlockEditor';
import { ConditionBlock, BoostCondition } from '../../../../utils/customizations/conditions';
import { QueryTesterDetails, GeneralSettings, ViewMode, QueryTesterPanel } from '../../QueryTester/QueryTester.types';
import { GeneralSettingsEditor } from './GeneralSettings';
import { BannerEditor } from '../../common/BannerEditor';
import { Banner } from '../../../../store/types/customizations/businessRule';
import { QueryTesterCodeEditor } from './QueryTesterCodeEditor';
import { useSelector } from '../../../../store/hooks';
import { mapDetailsToPostBody } from '../utils/DetailsPostBodyMapper';
import { mapPostBodyToDetails } from '../utils/PostBodyDetailsMapper';
import { SearchPostRequest } from '../../../../utils/customizations/search/searchRequest';
import { useDefaultPostBody } from '../hooks/useDefaultPostBody';

const getClassNames = classNamesFunction<QueryTesterEditorStyleProps, QueryTesterEditorStyles>();

export const QueryTesterEditorBase = (props: QueryTesterEditorProps) => {
  const {
    styles,
    theme,
    className,
    queryTesterDetails,
    augmentedPostBody,
    viewMode,
    selectedPanel,
    onUpdate,
    sharedSettings,
  } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const [currentDetails, setCurrentDetails] = useState(queryTesterDetails);
  const [currentAugmentedPostBody, setCurrentAugmentedPostBody] = useState(augmentedPostBody);

  const activeSearchIndex = useSelector(state => state.searchIndex.searchIndex);
  const isOobe = useSelector(state => state.tenantsList.activeTenant.isOobe);

  const getMappedPostBody = useCallback(
    (details: QueryTesterDetails) => {
      return mapDetailsToPostBody(details, activeSearchIndex, sharedSettings.query, isOobe);
    },
    [activeSearchIndex, isOobe, sharedSettings.query],
  );

  const defaultPostBodyWithQuery = useDefaultPostBody(sharedSettings.query);

  const getMappedDetails = useCallback((postBody: SearchPostRequest) => {
    return mapPostBodyToDetails(postBody);
  }, []);

  useEffect(() => {
    onUpdate(currentDetails, currentAugmentedPostBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAugmentedPostBody]);

  useEffect(() => {
    //TODO: condition not needed ??
    if (
      sharedSettings.query !== currentAugmentedPostBody.query.matchAll ||
      sharedSettings.top !== currentAugmentedPostBody.items.top ||
      sharedSettings.skip !== currentAugmentedPostBody.items.skip
    ) {
      setCurrentAugmentedPostBody(postBody => {
        return {
          ...postBody,
          query: { ...postBody.query, matchAll: sharedSettings.query },
          items: { ...postBody.items, top: sharedSettings.top, skip: sharedSettings.skip },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedSettings]);

  const handleGeneralSettingsChange = useCallback(
    (generalSettings: GeneralSettings) => {
      const updatedDetails: QueryTesterDetails = {
        ...queryTesterDetails,
        generalSettings: generalSettings,
      };
      const mappedPostBody = getMappedPostBody(updatedDetails);
      setCurrentAugmentedPostBody(mappedPostBody);
      setCurrentDetails(updatedDetails);
      onUpdate(currentDetails, currentAugmentedPostBody);
    },
    [currentAugmentedPostBody, currentDetails, getMappedPostBody, onUpdate, queryTesterDetails],
  );

  const handlePromoteConditionChange = useCallback(
    (conditionBlock: ConditionBlock) => {
      const updatedBoostCondition: BoostCondition = { boost: 500, condition: conditionBlock };
      const updatedDetails = { ...currentDetails, promoteCondition: updatedBoostCondition };
      const mappedPostBody = getMappedPostBody(updatedDetails);
      setCurrentAugmentedPostBody(mappedPostBody);
      setCurrentDetails(updatedDetails);
    },
    [currentDetails, getMappedPostBody],
  );

  const handleDemoteConditionChange = useCallback(
    (conditionBlock: ConditionBlock) => {
      const updatedBoostCondition: BoostCondition = { boost: -500, condition: conditionBlock };
      const updatedDetails = { ...currentDetails, demoteCondition: updatedBoostCondition };
      const mappedPostBody = getMappedPostBody(updatedDetails);
      setCurrentAugmentedPostBody(mappedPostBody);
      setCurrentDetails(updatedDetails);
    },
    [currentDetails, getMappedPostBody],
  );

  const handleFilterConditionChange = useCallback(
    (conditionBlock: ConditionBlock) => {
      const updatedDetails = { ...currentDetails, filterCondition: conditionBlock };
      const mappedPostBody = getMappedPostBody(updatedDetails);
      setCurrentAugmentedPostBody(mappedPostBody);
      setCurrentDetails(updatedDetails);
    },
    [currentDetails, getMappedPostBody],
  );

  const handleBannerUpdate = useCallback(
    (banner: Banner) => {
      const updatedDetails = { ...currentDetails, banner: banner };
      const mappedPostBody = getMappedPostBody(updatedDetails);
      setCurrentAugmentedPostBody(mappedPostBody);
      setCurrentDetails(updatedDetails);
    },
    [currentDetails, getMappedPostBody],
  );

  const handlePostBodyUpdate = useCallback(
    (postBody: any) => {
      const mappedDetails = getMappedDetails(postBody);
      setCurrentDetails(mappedDetails);
      setCurrentAugmentedPostBody(postBody);
    },
    [getMappedDetails],
  );

  const getSelectedPanelContent = useCallback((): React.ReactNode => {
    switch (selectedPanel) {
      case QueryTesterPanel.General:
        return (
          <div className={classNames.panelBody}>
            <GeneralSettingsEditor
              generalSettings={currentDetails.generalSettings}
              onUpdate={handleGeneralSettingsChange}
            />
          </div>
        );

      case QueryTesterPanel.Promote:
        return (
          <div className={classNames.panelBody}>
            <ConditionBlockEditor
              key={'Promote-Condition-Block'}
              label={'Promote Conditions'}
              styles={classNames.subComponentStyles.ConditionBlock}
              conditionBlock={currentDetails.promoteCondition ? currentDetails.promoteCondition.condition : undefined}
              onConditionBlockUpdate={handlePromoteConditionChange}
            />
          </div>
        );
      case QueryTesterPanel.Demote:
        return (
          <div className={classNames.panelBody}>
            <ConditionBlockEditor
              key={'Demote-Condition-Block'}
              label={'Demote Conditions'}
              styles={classNames.subComponentStyles.ConditionBlock}
              conditionBlock={currentDetails.demoteCondition ? currentDetails.demoteCondition.condition : undefined}
              onConditionBlockUpdate={handleDemoteConditionChange}
            />
          </div>
        );
      case QueryTesterPanel.Filter:
        return (
          <div className={classNames.panelBody}>
            <ConditionBlockEditor
              key={'Filter-Condition-Block'}
              label={'Filter Conditions'}
              styles={classNames.subComponentStyles.ConditionBlock}
              conditionBlock={currentDetails.filterCondition}
              onConditionBlockUpdate={handleFilterConditionChange}
            />
          </div>
        );
      case QueryTesterPanel.Banner:
        return (
          <div className={classNames.panelBody}>
            <BannerEditor initialBanner={currentDetails.banner} onBannerUpdate={handleBannerUpdate} />
          </div>
        );
      default:
        return null;
    }
  }, [
    classNames.panelBody,
    classNames.subComponentStyles.ConditionBlock,
    currentDetails.banner,
    currentDetails.demoteCondition,
    currentDetails.filterCondition,
    currentDetails.generalSettings,
    currentDetails.promoteCondition,
    handleBannerUpdate,
    handleDemoteConditionChange,
    handleFilterConditionChange,
    handleGeneralSettingsChange,
    handlePromoteConditionChange,
    selectedPanel,
  ]);

  return (
    <Stack className={classNames.root}>
      {viewMode === ViewMode.FORM && getSelectedPanelContent()}
      {viewMode === ViewMode.CODE && (
        <QueryTesterCodeEditor
          value={currentAugmentedPostBody}
          defaultValue={defaultPostBodyWithQuery}
          onValueChange={handlePostBodyUpdate}
        />
      )}
    </Stack>
  );
};
