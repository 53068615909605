import React, { useCallback } from 'react';
import {
  Panel,
  IRenderFunction,
  IPanelProps,
  Stack,
  classNamesFunction,
  PanelType,
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react';
import { CustomPanelStyleProps, CustomPanelStyles, CustomPanelProps } from './CustomPanel.types';

const getClassNames = classNamesFunction<CustomPanelStyleProps, CustomPanelStyles>();

export const CustomPanelBase = (props: CustomPanelProps): JSX.Element => {
  const {
    className,
    styles,
    componentRef,
    theme,
    isOpen,
    onDismiss,
    onRenderHeader,
    headerText,
    isHiddenOnDismiss,
    children,
    footer,
    panelType,
    lightDismiss,
    t,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const onRenderNavigationContent: IRenderFunction<IPanelProps> = (props, defaultRender) => {
    return (
      <Stack horizontal horizontalAlign={'space-between'} className={classNames.panelHeaderContainer}>
        <div className={classNames.panelTitle}>{headerText}</div>
        {// This custom navigation still renders the close button (defaultRender).
        // If you don't use defaultRender, be sure to provide some other way to close the panel.
        defaultRender!(props)}
      </Stack>
    );
  };

  const onRenderFooterContent = useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 8 }} horizontalAlign={'end'}>
        <PrimaryButton
          disabled={!!footer && !!footer.isSaveDisabled && footer.isSaveDisabled}
          onClick={() => {
            !!footer && !!footer.onSaveClick && footer.onSaveClick();
          }}
        >
          {!!footer && !!footer.confirmButtonText ? footer.confirmButtonText : t('common.save')}
        </PrimaryButton>
        <DefaultButton
          onClick={() => {
            !!footer && !!footer.onCancelClick && footer.onCancelClick();
          }}
        >
          {t('common.cancel')}
        </DefaultButton>
      </Stack>
    ),
    [footer, t],
  );

  return (
    <div ref={componentRef} className={classNames.root}>
      <Panel
        isHiddenOnDismiss={isHiddenOnDismiss}
        type={panelType ? panelType : PanelType.smallFixedFar}
        onRenderNavigationContent={(props, defaultRender) =>
          !!onRenderHeader ? onRenderHeader(props) : onRenderNavigationContent(props, defaultRender)
        }
        isOpen={isOpen}
        onDismiss={onDismiss}
        layerProps={{ eventBubblingEnabled: true }}
        styles={{ navigation: { height: 'fit-content' } }}
        closeButtonAriaLabel={t('common.close')}
        className={classNames.panelContent}
        isFooterAtBottom={true}
        onRenderFooterContent={!!footer ? onRenderFooterContent : undefined}
        isLightDismiss={lightDismiss}
      >
        {children}
      </Panel>
    </div>
  );
};
