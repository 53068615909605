import { styled } from 'office-ui-fabric-react';
import { UserPersonaBase } from './UserPersona.base';
import { styles } from './UserPersona.styles';
import { connect } from 'react-redux';
import { updateTenantUserImage, updateTenantUserMetaData } from '../../../store/actions/userListActions';
import { AppState } from '../../../store/reducers';
import { UserPersonaProps } from './UserPersona.types';

export const UserPersona = styled(UserPersonaBase, styles);

const mapStateToProps = (state: AppState) => ({
  userEmailMapperStoreState: state.userList.userEmailMapper,
});

const mapDispatchToProps = (dispatch: Function, ownProps: UserPersonaProps) => ({
  updateTenantUserImageAction: () => dispatch(updateTenantUserImage(ownProps.user.email)),
  updateTenantUserMetaDataAction: () => dispatch(updateTenantUserMetaData(ownProps.user.email)),
});

export const ConnectedUserPersona = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPersona);
