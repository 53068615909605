import React from 'react';
import { classNamesFunction, IconButton, CommandButton } from 'office-ui-fabric-react';
import { MbcTitleStyleProps, MbcTitleStyles, MbcTitleProps } from './MbcTitle.types';
import { useMediaQuery } from 'react-responsive';

const getClassNames = classNamesFunction<MbcTitleStyleProps, MbcTitleStyles>();

export const MBCTitleBase = (props: MbcTitleProps) => {
  const { whiteText, className, styles, theme, componentRef, t } = props;

  const breakPoint = useMediaQuery({ query: '(max-width: 470px)' });

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div className={classNames.root} ref={componentRef}>
      {breakPoint ? (
        <CommandButton
          iconProps={{ iconName: 'MicrosoftLogo' }}
          href="https://aka.ms/msftbingforcommerce"
          target="_blank"
          ariaLabel={t('mbc-title')}
          text={t('mbc-title')}
          className={classNames.responsiveLink}
        />
      ) : (
        <IconButton
          iconProps={{ iconName: whiteText ? 'MbcLogoWhite' : 'MbcLogo' }}
          href="https://aka.ms/msftbingforcommerce"
          target="_blank"
          ariaLabel={t('mbc-title')}
        />
      )}
    </div>
  );
};
