import { Action } from 'redux';

export const SET_URL_REDIRECT_SET_LIST = 'SET_URL_REDIRECT_SET_LIST';
export const RESET_URL_REDIRECT_SET_LIST = 'RESET_URL_REDIRECT_SET_LIST';
export const SET_ACTIVE_URL_REDIRECT_SET = 'SET_ACTIVE_URL_REDIRECT_SET';
export const RESET_ACTIVE_URL_REDIRECT_SET = 'RESET_ACTIVE_URL_REDIRECT_SET';
export const LOADING_URL_REDIRECT_SET_LIST = 'LOADING_URL_REDIRECT_SET_LIST';
export const CREATE_OR_UPDATE_URL_REDIRECT_SET = 'CREATE_OR_UPDATE_URL_REDIRECT_SET';
export const DELETE_URL_REDIRECT_SET = 'Delete_URL_REDIRECT_SET';

export interface UrlRedirectSet {
  id: string;
  redirectUrl: string;
  queryList: string[];
}

export interface UrlRedirectSetIdMapper {
  [key: string]: UrlRedirectSet;
}

export interface UrlRedirectSetState {
  isLoading: boolean;
  activeUrlRedirectSet: UrlRedirectSet;
  urlRedirectSetIdMapper: UrlRedirectSetIdMapper;
}

interface SetUrlRedirectSetList extends Action<typeof SET_URL_REDIRECT_SET_LIST> {
  urlRedirectSetList: UrlRedirectSet[];
}

interface SetActiveUrlRedirectSet extends Action<typeof SET_ACTIVE_URL_REDIRECT_SET> {
  id: string;
}

interface CreateOrUpdateUrlRedirectSet extends Action<typeof CREATE_OR_UPDATE_URL_REDIRECT_SET> {
  urlRedirectSet: UrlRedirectSet;
}

interface DeleteUrlRedirectSet extends Action<typeof DELETE_URL_REDIRECT_SET> {
  id: string;
}

export type UrlRedirectSetActionTypes =
  | SetUrlRedirectSetList
  | SetActiveUrlRedirectSet
  | CREATE_OR_UPDATE_URL_REDIRECT_SET
  | DELETE_URL_REDIRECT_SET
  | Action<typeof RESET_URL_REDIRECT_SET_LIST>
  | Action<typeof RESET_ACTIVE_URL_REDIRECT_SET>
  | Action<typeof LOADING_URL_REDIRECT_SET_LIST>;
