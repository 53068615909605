import React from 'react';
import { PermissionProviderProps } from './PermissionProvider.types';
import { TenantPermissionContext } from '../../contexts';
import { useTenantPermissions } from '../../store/hooks/use-user-permissions/UseTenantPermissions';
import { isAuthorized } from '../../utils';

export const TenantPermissionProvider = (props: PermissionProviderProps): JSX.Element => {
  const userPermissions = useTenantPermissions();
  return (
    <TenantPermissionContext.Provider
      value={{
        isAuthorized: minimumPermissions => isAuthorized(userPermissions, minimumPermissions),
        getPermissions: () => userPermissions,
      }}
    >
      {props.children}
    </TenantPermissionContext.Provider>
  );
};
