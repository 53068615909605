import { Action } from 'redux';

export const SHOW_PROGRESS_INDICATOR = 'SHOW_PROGRESS_INDICATOR';
export const HIDE_PROGRESS_INDICATOR = 'HIDE_PROGRESS_INDICATOR';

export interface ProgressIndicatorState {
  inProgress: boolean;
}

export type ProgressIndicatorActionTypes =
  | Action<typeof SHOW_PROGRESS_INDICATOR>
  | Action<typeof HIDE_PROGRESS_INDICATOR>;
