import { ActionSuccessHandler, ActionFailureHandler, ExecuteReduxCrudAction } from './utils';
import { Action } from 'redux';
import { AppState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import {
  SET_DETECTED_INDEX_SCHEMA,
  SchemaDetection,
  SchemaDetectionStateActionTypes,
  RESET_DETECTED_INDEX_SCHEMA,
} from '../types/schemaDetection.d';
import { detectIndexSchemaAction } from './utils/crud-actions/schema-detection/SchemaDetectionCrudActions';

export const detectIndexSchema = (
  dataset: File,
  onSuccess?: ActionSuccessHandler<SchemaDetection>,
  onFailure?: ActionFailureHandler<string>,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenant = state.tenantsList.activeTenant;
  const environment = state.environmentList.activeEnvironment;
  dispatch(
    ExecuteReduxCrudAction(detectIndexSchemaAction(dataset, tenant, environment, dispatch, onSuccess, onFailure)),
  );
};

export const setActiveDetectedIndexSchema = (key: string): SchemaDetectionStateActionTypes => {
  return {
    type: SET_DETECTED_INDEX_SCHEMA,
    key: key,
  };
};

export const resetActiveDetectedIndexSchema = (key: string): SchemaDetectionStateActionTypes => {
  return {
    type: RESET_DETECTED_INDEX_SCHEMA,
    key: key,
  };
};
