import { NavBarPivotStyleProps, NavBarPivotStyles } from './NavBarPivot.types';

export const styles = (props: NavBarPivotStyleProps): NavBarPivotStyles => {
  return {
    root: [
      'mbc-nav-bar-pivot',
      {
        selectors: {
          '.ms-Button': {
            color: props.theme.palette.white,
            height: 48,
            selectors: {
              ':hover': {
                backgroundColor: props.theme.palette.blueMid,
                color: props.theme.palette.white,
              },
            },
          },
          '.is-selected::before': {
            backgroundColor: props.theme.palette.white,
            bottom: 2,
          },
        },
      },
      props.className,
    ],
    pivotCustomButton: [
      'mbc-nav-bar-custom-button',
      {
        padding: 0,
        selectors: {
          '.ms-Button-icon': {
            marginLeft: 0,
          },
        },
      },
    ],
  };
};
