import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/reducers';
import { PieChart, NameValuePercentage } from '../../../../components/common/charts/PieChart';
import { ChartContentCard } from '../Common/ChartContentCard';
import { FiltersUsageProps, FiltersUsageStyleProps, FiltersUsageStyles } from './FiltersUsage.types';
import { classNamesFunction } from 'office-ui-fabric-react';
import { SetFiltersUsage } from '../../../../store/actions/analyticsActions';
import { useDispatch } from '../../../../store/hooks';

const getClassNames = classNamesFunction<FiltersUsageStyleProps, FiltersUsageStyles>();

export const FiltersUsageBase = (props: FiltersUsageProps) => {
  const { t, onFilterInvoked, className, theme, styles } = props;
  const isFiltersUsageLoading = useSelector((state: AppState) => state.analytics.isFiltersUsageLoading);
  const filtersUsage = useSelector((state: AppState) => state.analytics.filtersUsage);

  const dispatch = useDispatch();

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div className={classNames.root}>
      <ChartContentCard
        exportData={{ fileName: t('analytics.filters-usage-pie-title'), data: filtersUsage }}
        chart={
          <PieChart
            isLoading={isFiltersUsageLoading}
            pieColors={{
              Filters: '#5c2d91',
              'No Filters': '#b4a0ff',
            }}
            isLoadingLabel={t('common.charts-loading')}
            noDataLabel={t('common.charts-no-data')}
            onClick={(activeIndex: NameValuePercentage) => onFilterInvoked && onFilterInvoked(activeIndex.name)}
            data={filtersUsage.map(data => ({
              name: data.content,
              value: data.count,
            }))}
          />
        }
        title={t('analytics.filters-usage-pie-title')}
        isLoadedAndEmpty={!isFiltersUsageLoading && !filtersUsage.length}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
        ) =>
          dispatch(SetFiltersUsage(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances))
        }
      />
    </div>
  );
};
