import {
  RESET_TENANT_USERS,
  UserListStateActionTypes,
  SET_TENANT_USER_IMAGE,
  ImageStatus,
  SET_TENANT_USER_META_DATA,
  UserMetaDataStatus,
} from '../types/userList.d';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import { getUserProfilePicture, getUserMetaData } from '../../restful-apis/graph.api';

export const updateTenantUserImage = (email: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: SET_TENANT_USER_IMAGE,
      email: email,
      imageUrl: '',
      imageStatus: ImageStatus.Loading,
    });
    window.getAccessToken().then(token => {
      getUserProfilePicture(email, token)
        .then(image => {
          var reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            dispatch({
              type: SET_TENANT_USER_IMAGE,
              email: email,
              imageUrl: reader.result,
              imageStatus: ImageStatus.Cached,
            });
          };
        })
        .catch(() => {
          dispatch({
            type: SET_TENANT_USER_IMAGE,
            email: email,
            imageUrl: '',
            imageStatus: ImageStatus.NotFound,
          });
        });
    });
  };
};

export const updateTenantUserMetaData = (email: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: SET_TENANT_USER_META_DATA,
      email: email,
      displayName: '',
      jobTitle: '',
      initials: '',
      userStatus: UserMetaDataStatus.Loading,
    });
    window.getAccessToken().then(token => {
      getUserMetaData(email, token)
        .then(metadata => {
          dispatch({
            type: SET_TENANT_USER_META_DATA,
            email: metadata.userPrincipalName,
            displayName: metadata.displayName,
            jobTitle: metadata.jobTitle,
            initials: metadata.initials,
            userStatus: UserMetaDataStatus.Cached,
          });
        })
        .catch(() => {
          dispatch({
            type: SET_TENANT_USER_META_DATA,
            email: email,
            displayName: '',
            jobTitle: '',
            initials: '',
            userStatus: UserMetaDataStatus.NotFound,
          });
        });
    });
  };
};

export const resetTenantUsers = (): UserListStateActionTypes => {
  return {
    type: RESET_TENANT_USERS,
  };
};
