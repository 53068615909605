import { ProductItem, Product } from '../ProductPreview.types';
import { IndexField } from '../../../../../../store/types/searchIndex';
import { SearchIndexTypeConfig } from '../../../SchemaManagement/SchemaManagement.config';
import { generateUniqueIdentifier } from '../../../../../../utils';

export const mapProductDtoToListModel = (indexFieldList: IndexField[], product: Product): ProductItem[] => {
  const nonWildcardItemList: ProductItem[] = indexFieldList
    .filter(field => {
      const productItemValue = product[field.name.toLowerCase()];
      return !!productItemValue && SearchIndexTypeConfig[field.type].matches(productItemValue);
    })
    .map(field => {
      const isObjectTypeField =
        !!SearchIndexTypeConfig[field.type] && SearchIndexTypeConfig[field.type].dataType === 'Object';
      const productItemValue = product[field.name.toLowerCase()] || SearchIndexTypeConfig[field.type].defaultValue;
      return {
        ...field,
        isWildcardMatch: false,
        isWildcard: field.name === '*',
        key: generateUniqueIdentifier(),
        placeholder: field.name === '*' ? '*' : '',
        value: isObjectTypeField ? mapProductDtoToListModel(field.fields || [], productItemValue) : productItemValue,
      };
    })
    .filter(
      item =>
        (!!SearchIndexTypeConfig[item.type] && SearchIndexTypeConfig[item.type].dataType !== 'Object') ||
        item.value.length > 0,
    );

  const wildcardItemIndex = indexFieldList.findIndex(field => field.name === '*');
  if (wildcardItemIndex === -1) {
    return nonWildcardItemList;
  }

  let wildcardItemList: ProductItem[] = [];
  const wildcardItem = indexFieldList[wildcardItemIndex];
  const isObjectTypeWildcard =
    !!SearchIndexTypeConfig[wildcardItem.type] && SearchIndexTypeConfig[wildcardItem.type].dataType === 'Object';
  wildcardItemList = Object.entries(product)
    .filter((entry: [string, any]) => {
      const matchesAnyOfSchemFields = indexFieldList.find(field => field.name.toLowerCase() === entry[0]);
      const matchesWildcardType = SearchIndexTypeConfig[wildcardItem.type].matches(entry[1]);

      return !matchesAnyOfSchemFields && !!entry[1] && matchesWildcardType;
    })
    .map((entry: [string, any]) => {
      const productItemLabel = entry[0];
      const productItemValue = entry[1] || SearchIndexTypeConfig[wildcardItem.type].defaultValue;
      return {
        ...wildcardItem,
        placeholder: '',
        isWildcard: false,
        isWildcardMatch: true,
        name: productItemLabel,
        key: generateUniqueIdentifier(),
        value: isObjectTypeWildcard
          ? mapProductDtoToListModel(wildcardItem.fields || [], productItemValue)
          : productItemValue,
      };
    })
    .filter(
      item =>
        (!!SearchIndexTypeConfig[item.type] && SearchIndexTypeConfig[item.type].dataType !== 'Object') ||
        item.value.length > 0,
    );

  return nonWildcardItemList.concat(wildcardItemList);
};
