import React, { Component } from 'react';
import { Stack, Modal, IconButton, Panel, PanelType, Nav, DefaultButton } from 'office-ui-fabric-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import styles from './NavBarMobile.module.scss';
import { AuthContext } from '../../../../contexts';
import { AppState } from '../../../../store/reducers';
import { connect } from 'react-redux';
import { browserHistory } from '../../../../history.module';
import { ContactUs } from '../../../../components/common/ContactUs';
import { showOobeDemo, hideOobeDemo } from '../../../../store/actions/oobeDemoActions';
import { AppRoute } from '../../../../router/router.service';

interface NavBarMobileProps extends WithTranslation {
  isLoggedIn: boolean;
  showDemo: boolean;
  showOobeDemo: Function;
  hideOobeDemo: Function;
}

interface NavBarState {
  showContactUsForm: boolean;
  panelOpen: boolean;
}

class NavBarMobile extends Component<NavBarMobileProps, NavBarState> {
  static contextType = AuthContext;

  constructor(props: NavBarMobileProps) {
    super(props);
    this.state = {
      showContactUsForm: false,
      panelOpen: false,
    };
  }

  private onClickContactUs = () => {
    this.setState({ showContactUsForm: true, panelOpen: false });
  };

  getListItems() {
    const { t } = this.props;
    let mainListItems = [
      {
        key: 'overview',
        name: t('homepage.navbar.overview'),
        url: 'http://Microsoft.com/bing/commerce',
        target: '_blank',
      },
      {
        key: 'tryNow',
        name: t('homepage.navbar.try-now'),
        url: '',
        onClick: () => {
          this.props.showOobeDemo();
          browserHistory.push(AppRoute.get());
        },
      },
      {
        key: 'contactUs',
        name: t('homepage.navbar.contact-us'),
        onClick: this.onClickContactUs.bind(this),
        url: '',
      },
      {
        key: 'faq',
        name: t('homepage.navbar.faq'),
        url: 'https://www.microsoft.com/en-us/bing/commerce#faq',
      },
    ];

    return [
      {
        links: mainListItems,
      },
    ];
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Modal isOpen={this.state.showContactUsForm}>
          <ContactUs
            supportEmail={'contactMBC@microsoft.com'}
            onDismiss={() => this.setState({ showContactUsForm: false })}
          />
        </Modal>
        <Stack className={styles.globalHeader} horizontal verticalAlign={'center'} horizontalAlign="space-between">
          <a href="https://www.microsoft.com/" rel="noopener noreferrer" target="_blank">
            <img src={'/assets/images/msft-logo.svg'} alt="Microsoft" />
          </a>
          <div className={styles.rightSideContainer}>
            <DefaultButton
              onClick={() => browserHistory.push(AppRoute.append('login'))}
              className={styles.freeTrialButton}
            >
              {t('homepage.navbar.trial-button')}
            </DefaultButton>

            <IconButton
              // Disable default search instance deletion
              iconProps={{ iconName: 'GlobalNavButton' }}
              ariaLabel={t('homepage.navbar.mobile-nav-label')}
              styles={{
                root: {
                  color: 'black',
                },
              }}
              onClick={() => this.setState({ panelOpen: true })}
            />
            <Panel
              isOpen={this.state.panelOpen}
              onDismiss={() => this.setState({ panelOpen: false })}
              type={PanelType.custom}
              closeButtonAriaLabel="Close"
              customWidth={'250px'}
              styles={{
                commands: {
                  background: '#fafbfc',
                },
              }}
            >
              <Nav
                styles={{
                  root: {
                    width: '100%',
                    overflowY: 'auto',
                  },
                  link: {
                    paddingLeft: '5px',
                    width: '280px',
                  },
                  navItem: {
                    borderBottom: '1px solid #eee',
                    minHeight: '55px',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'flex-start',
                    width: '100%',
                  },
                  linkText: {
                    verticalAlign: 'center',
                    textAlign: 'left',
                    color: '#444d56',
                    fontSize: '16px',
                    fontWeight: '400',
                  },
                }}
                groups={this.getListItems()}
              />
              <div className={styles.rightPanel}>
                {this.props.isLoggedIn ? (
                  <DefaultButton
                    styles={{
                      root: {
                        color: 'black',
                      },
                    }}
                    onClick={() => this.context.logout()}
                  >
                    {t('homepage.navbar.signout-button')}
                  </DefaultButton>
                ) : (
                  <DefaultButton
                    onClick={() => browserHistory.push(AppRoute.append('login'))}
                    styles={{
                      root: {
                        color: 'black',
                      },
                    }}
                  >
                    {t('homepage.navbar.signin-button')}
                  </DefaultButton>
                )}
              </div>
            </Panel>
          </div>
        </Stack>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  showDemo: state.oobeDemo.showDemo,
});

export const NavBarMobileWithTranslation = withTranslation()(NavBarMobile);
export default connect(
  mapStateToProps,
  { showOobeDemo, hideOobeDemo },
)(NavBarMobileWithTranslation);
