import { Action } from 'redux';
import { MetricCardData } from './insights.d';

export const SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS = 'SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS';
export const LOADING_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS = 'LOADING_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS';

export const SET_SEARCH_REQUESTS_LATENCY_VALUE = 'SET_SEARCH_REQUESTS_LATENCY_VALUE';
export const LOADING_SEARCH_REQUESTS_LATENCY_VALUE = 'LOADING_SEARCH_REQUESTS_LATENCY_VALUE';

export const SET_TOTAL_DISTINCT_QUERIES = 'SET_TOTAL_DISTINCT_QUERIES';
export const LOADING_TOTAL_DISTINCT_QUERIES = 'LOADING_TOTAL_DISTINCT_QUERIES';

export const SET_RESPONSE_CODES = 'SET_RESPONSE_CODES';
export const LOADING_RESPONSE_CODES = 'LOADING_RESPONSE_CODES';

export const SET_RESPONSE_CODES_FILTER = 'SET_RESPONSE_CODES_FILTER';
export const LOADING_RESPONSE_CODES_FILTER = 'LOADING_RESPONSE_CODES_FILTER';

export const SET_REGIONS_DISTRIBUTION_FILTER = 'SET_REGIONS_DISTRIBUTION_FILTER';
export const LOADING_REGIONS_DISTRIBUTION_FILTER = 'LOADING_REGIONS_DISTRIBUTION_FILTER';

export const SET_SORTED_BY_PARAMETERS = 'SET_SORTED_BY_PARAMETERS';
export const LOADING_SORTED_BY_PARAMETERS = 'LOADING_SORTED_BY_PARAMETERS';

export const SET_TOP_QUERIES = 'SET_TOP_QUERIES';
export const LOADING_TOP_QUERIES = 'LOADING_TOP_QUERIES';

export const SET_QUERY_LANGUAGES = 'SET_QUERY_LANGUAGES';
export const LOADING_QUERY_LANGUAGES = 'LOADING_QUERY_LANGUAGES';

export const SET_FILTERS_USED = 'SET_FILTERS_USED';
export const LOADING_FILTERS_USED = 'LOADING_FILTERS_USED';

export const SET_NO_RESULTS_QUERIES = 'SET_NO_RESULTS_QUERIES';
export const LOADING_NO_RESULTS_QUERIES = 'LOADING_NO_RESULTS_QUERIES';

export const SET_TOTAL_SEARCH_REQUESTS_OVER_TIME = 'SET_TOTAL_SEARCH_REQUESTS_OVER_TIME';
export const LOADING_TOTAL_SEARCH_REQUESTS_OVER_TIME = 'LOADING_TOTAL_SEARCH_REQUESTS_OVER_TIME';

export const SET_SUCCESSFUL_REQUESTS_OVER_TIME = 'SET_SUCCESSFUL_REQUESTS_OVER_TIME';
export const LOADING_SUCCESSFUL_REQUESTS_OVER_TIME = 'LOADING_SUCCESSFUL_REQUESTS_OVER_TIME';

export const SET_FAILED_REQUESTS_OVER_TIME = 'SET_FAILED_REQUESTS_OVER_TIME';
export const LOADING_FAILED_REQUESTS_OVER_TIME = 'LOADING_FAILED_REQUESTS_OVER_TIME';

export const SET_USER_ERROR_REQUESTS_OVER_TIME = 'SET_USER_ERROR_REQUESTS_OVER_TIME';
export const LOADING_USER_ERROR_REQUESTS_OVER_TIME = 'LOADING_USER_ERROR_REQUESTS_OVER_TIME';

export const SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME = 'SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME';
export const LOADING_DISTINCT_SEARCH_REQUESTS_OVER_TIME = 'LOADING_DISTINCT_SEARCH_REQUESTS_OVER_TIME';

export const SET_TOTAL_SEARCH_REQUESTS = 'SET_TOTAL_SEARCH_REQUESTS';
export const LOADING_TOTAL_SEARCH_REQUESTS = 'LOADING_TOTAL_SEARCH_REQUESTS';

export const SET_AVAILABLE_MARKETS = 'SET_AVAILABLE_MARKETS';
export const LOADING_AVAILABLE_MARKETS = 'LOADING_AVAILABLE_MARKETS';

export const SET_FILTERS_USAGE = 'SET_FILTERS_USAGE';
export const LOADING_FILTERS_USAGE = 'LOADING_FILTERS_USAGE';

export const SET_REGIONS_DISTRIBUTION = 'SET_REGIONS_DISTRIBUTION';
export const LOADING_REGIONS_DISTRIBUTION = 'LOADING_REGIONS_DISTRIBUTION';

export const SET_FILTERS_DISTRIBUTION = 'SET_FILTERS_DISTRIBUTION';
export const LOADING_FILTERS_DISTRIBUTION = 'LOADING_FILTERS_DISTRIBUTION';

export const SET_SEARCH_INSTANCES_CHART = 'SET_SEARCH_INSTANCES_CHART';
export const LOADING_SEARCH_INSTANCES_CHART = 'LOADING_SEARCH_INSTANCES_CHART';

export const SET_SEARCH_INSTANCES_FILTER = 'SET_SEARCH_INSTANCES_FILTER';
export const LOADING_SEARCH_INSTANCES_FILTER = 'LOADING_SEARCH_INSTANCES_FILTER';

export const SET_LOADING_SEARCH_AVG_QPS = 'SET_LOADING_SEARCH_AVG_QPS';
export const SET_SEARCH_AVG_QPS = 'SET_SEARCH_AVG_QPS';

export const SET_SEARCH_LATENCY_OVER_TIME = 'SET_SEARCH_LATENCY_OVER_TIME';
export const LOADING_SEARCH_LATENCY_OVER_TIME = 'LOADING_SEARCH_LATENCY_OVER_TIME';

export const SET_SELECTED_REGIONS_FILTER = 'SET_SELECTED_REGIONS_FILTER';
export const SET_SELECTED_RESPONSE_CODES_FILTER = 'SET_SELECTED_RESPONSE_CODES_FILTER';
export const SET_SELECTED_SEARCH_INSTANCES_FILTER = 'SET_SELECTED_SEARCH_INSTANCES_FILTER';
export const SET_SELECTED_SEARCH_RESULTS_FILTER = 'SET_SELECTED_SEARCH_RESULTS_FILTER';
export const SET_SELECTED_START_DATE_FILTER = 'SET_SELECTED_START_DATE_FILTER';
export const SET_SELECTED_END_DATE_FILTER = 'SET_SELECTED_END_DATE_FILTER';
export const SET_SEARCH_LATENCY_PERCENTILE = 'SET_SEARCH_LATENCY_PERCENTILE';
export const SET_LATENCY_CARD_PERCENTILE = 'SET_LATENCY_CARD_PERCENTILE';

export const DateFormat = 'YYYY-MM-DD HH:mm:ss';

export interface TotalCount {
  count: number;
}

export interface TotalPercentage extends TotalCount {
  percentage: number;
}

export interface TotalPercentagContent extends TotalPercentage {
  content: string;
}

export interface QueryCount extends TotalCount {
  query: string;
}

export interface SearchParameter extends TotalCount {
  name: string;
}

export interface SearchMetrics {
  date: string;
  value: number;
}

export interface SearchMetricsModel {
  series: SearchMetrics[];
  interval: number;
}

export interface FilterModel {
  availableValues: string[];
  selectedValues: { checkedValues: string[]; selectAll: boolean };
}

export interface AnalyticsState {
  isTotalSearchRequestsLoading: boolean;
  totalSearchRequests: number;

  isTotalDistinctQueriesLoading: boolean;
  totalDistinctQueries: TotalPercentage;

  isTotalSearchRequestsWithNoResultsLoading: boolean;
  totalSearchRequestsWithNoResults: TotalPercentage;

  isSearchRequestsLatencyValueLoading: boolean;
  searchRequestsLatencyValue: number;

  isTotalSearchRequestsOverTimeLoading: boolean;
  totalSearchRequestsOverTime: SearchMetricsModel;

  isSuccessfulRequestsOverTimeLoading: boolean;
  successfulRequestsOverTime: SearchMetricsModel;

  isFailedRequestsOverTimeLoading: boolean;
  failedRequestsOverTime: SearchMetricsModel;

  isUserErrorRequestsOverTimeLoading: boolean;
  userErrorRequestsOverTime: SearchMetricsModel;

  isDistinctSearchRequestsOverTimeLoading: boolean;
  distinctSearchRequestsOverTime: SearchMetricsModel;

  isTopQueriesLoading: boolean;
  topQueries: TopQuery[];

  isNoResultsQueriesLoading: boolean;
  noResultsQueries: TopQuery[];

  isQueryLanguagesLoading: boolean;
  queryLanguages: SearchParameter[];

  isSortedByParametersLoading: boolean;
  sortedByParameters: SearchParameter[];

  isResponseCodesLoading: boolean;
  responseCodes: TotalPercentagContent[];

  isResponseCodesFilterLoading: boolean;
  responseCodesFilter: FilterModel;

  isAvailableMarketsLoading: boolean;
  availableMarkets: FilterModel;

  isRegionsDistributionLoading: boolean;
  regionsDistribution: TotalPercentagContent[];

  isRegionsDistributionFilterLoading: boolean;
  regionsDistributionFilter: FilterModel;

  isFiltersUsageLoading: boolean;
  filtersUsage: TotalPercentagContent[];

  isFiltersDistributionLoading: boolean;
  filtersDistribution: TotalPercentagContent[];

  isSearchInstancesChartLoading: boolean;
  searchInstancesChart: TotalPercentagContent[];

  isSearchInstancesFilterLoading: boolean;
  searchInstancesFilter: FilterModel;

  searchResultsFilter: FilterModel;

  startDateFilter: Date;
  endDateFilter: Date;

  searchAvgQPS: MetricCardData;
  isSearchAvgQPSLoading: boolean;

  isSearchLatencyOverTimeLoading: boolean;
  searchLatencyOverTime: SearchMetricsModel;
  searchLatencyPercentile: number;
  latencyCardPercentile: number;
}

interface SetTotalSearchRequestsWithNoResults extends Action<typeof SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS> {
  totalSearchRequestsWithNoResults: TotalPercentage;
}

interface SetSearchRequestsLatencyValue extends Action<typeof SET_SEARCH_REQUESTS_LATENCY_VALUE> {
  searchRequestsLatencyValue: number;
}

interface SetTotalDistinctQueries extends Action<typeof SET_TOTAL_DISTINCT_QUERIES> {
  totalDistinctQueries: TotalPercentage;
}

interface SetResponseCodes extends Action<typeof SET_RESPONSE_CODES> {
  responseCodes: TotalPercentagContent[];
}

interface SetResponseCodesFilter extends Action<typeof SET_RESPONSE_CODES_FILTER> {
  responseCodesFilter: string[];
}

interface SetRegionsDistribution extends Action<typeof SET_REGIONS_DISTRIBUTION> {
  regionsDistribution: SearchParameter[];
}

interface SetRegionsDistributionFilter extends Action<typeof SET_REGIONS_DISTRIBUTION_FILTER> {
  regionsDistributionFilter: string[];
}

interface SetFiltersUsage extends Action<typeof SET_FILTERS_USAGE> {
  filtersUsage: TotalPercentagContent[];
}

interface SetSortedByParameters extends Action<typeof SET_SORTED_BY_PARAMETERS> {
  sortedByParameters: SearchParameter[];
}

interface SetQueryLanguages extends Action<typeof SET_QUERY_LANGUAGES> {
  queryLanguages: SearchParameter[];
}

// interface SetFiltersUsed extends Action<typeof SET_FILTERS_USED> {
//   filtersUsed: SearchParameter[];
// }

interface SetNoResultsQueries extends Action<typeof SET_NO_RESULTS_QUERIES> {
  noResultsQueries: TopQuery[];
}

interface SetTopQueries extends Action<typeof SET_TOP_QUERIES> {
  topQueries: TopQuery[];
}

interface SetTotalSearchRequestsOverTime extends Action<typeof SET_TOTAL_SEARCH_REQUESTS_OVER_TIME> {
  totalSearchRequestsOverTime: SearchMetricsModel;
}

interface SetSuccessfulRequestsOverTime extends Action<typeof SET_SUCCESSFUL_REQUESTS_OVER_TIME> {
  successfulRequestsOverTime: SearchMetricsModel;
}

interface SetFailedRequestsOverTime extends Action<typeof SET_FAILED_REQUESTS_OVER_TIME> {
  failedRequestsOverTime: SearchMetricsModel;
}

interface SetUserErrorRequestsOverTime extends Action<typeof SET_USER_ERROR_REQUESTS_OVER_TIME> {
  userErrorRequestsOverTime: SearchMetricsModel;
}

interface SetDistinctSearchRequestsOverTime extends Action<typeof SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME> {
  distinctSearchRequestsOverTime: SearchMetricsModel;
}

interface SetTotalSearchRequests extends Action<typeof SET_TOTAL_SEARCH_REQUESTS> {
  totalSearchRequests: number;
}

interface SetAvailableMarkets extends Action<typeof SET_AVAILABLE_MARKETS> {
  availableMarkets: string[];
}

interface SetFiltersDistribution extends Action<typeof SET_FILTERS_DISTRIBUTION> {
  filtersDistribution: SearchParameter[];
}

interface SetSearchInstancesChart extends Action<typeof SET_SEARCH_INSTANCES_CHART> {
  searchInstancesChart: SearchParameter[];
}

interface SetSearchInstancesFilter extends Action<typeof SET_SEARCH_INSTANCES_FILTER> {
  searchInstancesFilter: string[];
}

export interface SetSearchAvgQPS extends Action<typeof SET_SEARCH_AVG_QPS> {
  searchAvgQPS: MetricCardData;
}

export interface SetLoadingSearchAvgQPS extends Action<typeof SET_LOADING_SEARCH_AVG_QPS> {
  isSearchAvgQPSLoading: boolean;
}

interface SetSearchLatencyOverTime extends Action<typeof SET_SEARCH_LATENCY_OVER_TIME> {
  searchLatencyOverTime: SearchMetricsModel;
}

interface SetSearchLatencyPercentile extends Action<typeof SET_SEARCH_LATENCY_PERCENTILE> {
  searchLatencyPercentile: number;
}

interface SetSelectedRegionsFilter extends Action<typeof SET_SELECTED_REGIONS_FILTER> {
  checkedValues: string[];
  selectAll: boolean;
}

interface SetSelectedResponseCodesFilter extends Action<typeof SET_SELECTED_RESPONSE_CODES_FILTER> {
  checkedValues: string[];
  selectAll: boolean;
}

interface SetSelectedSearchInstancesFilter extends Action<typeof SET_SELECTED_SEARCH_INSTANCES_FILTER> {
  checkedValues: string[];
  selectAll: boolean;
}

interface SetSelectedSearchResultsFilter extends Action<typeof SET_SELECTED_SEARCH_RESULTS_FILTER> {
  checkedValues: string[];
  selectAll: boolean;
}

interface SetSelectedStartDate extends Action<typeof SET_SELECTED_START_DATE_FILTER> {
  startDate: Date;
}

interface SetSelectedEndDate extends Action<typeof SET_SELECTED_END_DATE_FILTER> {
  endDate: Date;
}

interface SetLatencyCardPercentile extends Action<typeof SET_LATENCY_CARD_PERCENTILE> {
  latencyCardPercentile: number;
}

export type AnalyticsStateActionTypes =
  | SetTopQueries
  | SetFiltersUsed
  | SetSearchAvgQPS
  | SetFiltersUsage
  | SetResponseCodes
  | SetQueryLanguages
  | SetNoResultsQueries
  | SetAvailableMarkets
  | SetSelectedStartDate
  | SetSelectedEndDate
  | SetSortedByParameters
  | SetRegionsDistribution
  | SetResponseCodesFilter
  | SetSortedByParameters
  | SetTotalSearchRequests
  | SetFiltersDistribution
  | SetLoadingSearchAvgQPS
  | SetSearchInstancesChart
  | SetTotalDistinctQueries
  | SetSearchInstancesFilter
  | SetSearchLatencyOverTime
  | SetLatencyCardPercentile
  | SetFailedRequestsOverTime
  | SetSearchLatencyPercentile
  | SetRegionsDistributionFilter
  | SetSuccessfulRequestsOverTime
  | SetUserErrorRequestsOverTime
  | SetTotalSearchRequestsOverTime
  | SetSelectedSearchResultsFilter
  | SetSearchRequestsLatencyValue
  | SetDistictSearchRequestsOverTime
  | SetTotalSearchRequestsWithNoResults
  | Action<typeof LOADING_TOP_QUERIES>
  | Action<typeof LOADING_FILTERS_USAGE>
  | Action<typeof LOADING_RESPONSE_CODES>
  | Action<typeof LOADING_QUERY_LANGUAGES>
  | Action<typeof LOADING_AVAILABLE_MARKETS>
  | Action<typeof LOADING_NO_RESULTS_QUERIES>
  | Action<typeof LOADING_REGIONS_DISTRIBUTION>
  | Action<typeof LOADING_SORTED_BY_PARAMETERS>
  | Action<typeof LOADING_FILTERS_DISTRIBUTION>
  | Action<typeof LOADING_RESPONSE_CODES_FILTER>
  | Action<typeof LOADING_TOTAL_SEARCH_REQUESTS>
  | Action<typeof LOADING_SEARCH_LATENCY_OVER_TIME>
  | Action<typeof LOADING_SEARCH_INSTANCES_CHART>
  | Action<typeof LOADING_TOTAL_DISTINCT_QUERIES>
  | Action<typeof LOADING_SEARCH_INSTANCES_FILTER>
  | Action<typeof LOADING_FAILED_REQUESTS_OVER_TIME>
  | Action<typeof LOADING_REGIONS_DISTRIBUTION_FILTER>
  | Action<typeof LOADING_SUCCESSFUL_REQUESTS_OVER_TIME>
  | Action<typeof LOADING_USER_ERROR_REQUESTS_OVER_TIME>
  | Action<typeof LOADING_TOTAL_SEARCH_REQUESTS_OVER_TIME>
  | Action<typeof LOADING_SEARCH_REQUESTS_LATENCY_VALUE>
  | Action<typeof LOADING_DISTINCT_SEARCH_REQUESTS_OVER_TIME>
  | Action<typeof LOADING_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS>;
