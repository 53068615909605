export const filterItemsOnQuery = (items: any[], searchBy: string[], query: string) =>
  items.filter(
    item =>
      query === '' ||
      searchBy.length === 0 ||
      searchBy.some(
        searchValue =>
          typeof item[searchValue] === 'string' &&
          (item[searchValue] as string).toLowerCase().includes(query.toLowerCase()),
      ),
  );
