import { InsightsStyleProps, InsightsStyles } from './Insights.types';

export const styles = (props: InsightsStyleProps): InsightsStyles => {
  return {
    root: ['mbc-insights', props.className],
    customCard: [
      'mbc-insights-custom-card',
      {
        marginTop: 21,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'fit-content',
      },
    ],

    chartsWithMetrics: [
      'mbc-insights-charts-metrics',
      {
        display: 'grid',
        gridRowGap: '21px',
        gridColumnGap: '21px',
        selectors: {
          '@media screen and (min-width: 1221px)': {
            gridTemplateColumns: '285px auto',
          },
        },
      },
    ],

    chartsContainer: [
      'mbc-charts-container',
      {
        display: 'grid',
        gridRowGap: '21px',
        gridColumnGap: '21px',

        selectors: {
          '@media screen and (min-width: 1308px)': {
            gridTemplateColumns: '50% 50%',
            width: 'calc(100% - 21px)',
          },
          '@media screen and (max-width: 1307px)': {
            gridTemplateColumns: '100%',
          },
        },
      },
    ],

    footer: [
      'mbc-insights-footer',
      {
        padding: 10,
        margin: '0 23px',
        color: '#666',
        fontStyle: 'italic',
        fontSize: '0.85rem',
        alignSelf: 'flex-end',
      },
    ],
  };
};
