import React from 'react';
import numeral from 'numeral';
import { AxisTickFormatterProps, TickType } from './AxisTickFormatter.types';
import { getChartsFormatedValue } from '../../chartsUtils';
import moment from 'moment';

export const AxisTickFormatterBase = (props: AxisTickFormatterProps) => {
  const {
    payload,
    points,
    isXAxis,
    fontWeight,
    fontSize,
    fill,
    blockRotation,
    rotationAngle,
    rotationBreakPoint,
    rotatedFontSize,
    maximumTextLength,
    tickType,
    dx,
    dy,
    textAnchor,
  } = props;

  const getFormattedValueInternal = (value: any): string => {
    if (tickType) {
      switch (tickType) {
        case TickType.Numerical: {
          // Input 1000, 1000000 or 0.012343 ---> 1K, 1M or 0.0123 (consecutively)
          return getChartsFormatedValue(value);
        }
        case TickType.Categorical: {
          return value;
        }
        case TickType.DateTime: {
          // Input (Date Time) ex: yyyy-mm-dd or yyyy-mm-dd hh or yyyy-mm-dd hh:mm ---> Output yyyy-mm-dd or yyyy-mm-dd hh:00:00 or yyyy-mm-dd hh:mm:00 (consecutively)
          let dateTimeSplit = (value as string).split(' ');
          if (dateTimeSplit.length <= 1) return value;
          let formattedTime = dateTimeSplit[1].length === 2 ? dateTimeSplit[1] + ':00:00' : dateTimeSplit[1] + ':00';
          return dateTimeSplit[0] + ' ' + formattedTime;
        }
        case TickType.DateTime12Hrs: {
          // Input (Date Time) ex: yyyy-mm-dd or yyyy-mm-dd hh or yyyy-mm-dd hh:mm ---> Output yyyy-mm-dd or hhAM/PM or hh:mmAM/PM (consecutively)
          let dateTimeSplit = (value as string).split(' ');
          if (dateTimeSplit.length <= 1) return moment(dateTimeSplit[0]).format('MM/DD');
          let timeSplit = dateTimeSplit[1].split(':');
          let hrs = parseInt(timeSplit[0]);
          let formattedTime =
            timeSplit.length > 1
              ? hrs === 0
                ? '12:' + timeSplit[1] + 'AM'
                : hrs === 12
                ? '12:' + timeSplit[1] + 'PM'
                : hrs < 12
                ? hrs + ':' + timeSplit[1] + 'AM'
                : hrs - 12 + ':' + timeSplit[1] + 'PM'
              : hrs === 0
              ? '12AM'
              : hrs === 12
              ? '12PM'
              : hrs < 12
              ? hrs + 'AM'
              : hrs - 12 + 'PM';
          return formattedTime;
        }
        case TickType.Percentage: {
          return numeral(value).format('0.00') + '%';
        }
        default: {
          return value;
        }
      }
    } else {
      return value;
    }
  };

  let rotationBreakPointInternal = rotationBreakPoint ? rotationBreakPoint : 8;

  let fontSizeInternal = fontSize ? fontSize : 14;
  let rotationAngleInternal = '';
  if (!blockRotation && points.length > rotationBreakPointInternal) {
    fontSizeInternal = rotatedFontSize ? rotatedFontSize : 12;
    rotationAngleInternal = rotationAngle ? 'rotate(' + rotationAngle + ')' : 'rotate(-43)';
  }

  let formattedValue = getFormattedValueInternal(payload.payload.value);

  return (
    <g transform={isXAxis ? `translate(${payload.x},${payload.y - 16})` : undefined}>
      <text
        x={isXAxis ? 0 : payload.x}
        y={isXAxis ? 0 : payload.y}
        dx={dx}
        dy={dy ? dy : isXAxis ? 16 : undefined}
        fill={fill ? fill : 'black'}
        textAnchor={
          textAnchor ? textAnchor : !blockRotation && points.length > rotationBreakPointInternal ? 'end' : 'middle'
        }
        transform={rotationAngleInternal}
        fontSize={fontSizeInternal}
        fontWeight={fontWeight ? fontWeight : 400}
      >
        {maximumTextLength && (formattedValue as string).length > maximumTextLength
          ? formattedValue.substring(0, maximumTextLength) + '...'
          : formattedValue}
      </text>
    </g>
  );
};
