import numeral from 'numeral';
import React from 'react';
import { Point } from './BarChart/BarChart.types';
import { PieColors } from './PieChart';

export const getChartsFormatedValue = (value: number): string => {
  var numFormat = '0[.][0000]';
  if (value >= 1000) var numFormat = '0.0a';
  if (value < 1e-4 && value > 0) numFormat = '0e+0';
  return numeral(value).format(numFormat);
};

export const getRandColorCode = (): string => {
  return '#0000' + (100 + Math.floor(Math.random() * 155)).toString(16);
};

export const getPieChartColors = (
  startHue: number,
  startSaturation: number,
  series: string[],
  skipValue?: number,
  startLightness?: number,
): PieColors | undefined => {
  let startLightnessInternal = startLightness ? startLightness : 20;
  let endLightness = 90;

  let incrementBy = skipValue
    ? skipValue
    : Math.min(Math.floor((endLightness - startLightnessInternal) / series.length), 25);

  let map: PieColors = { initial: 'hsl(156,43%,20%)' };

  series.map((data, index) => {
    map[data] =
      'hsl(' + startHue + ',' + startSaturation + '%,' + (startLightnessInternal + index * incrementBy) + '%)';
  });

  return map;
};

export const xAxisTickFormatter = (payload: any, points: any, rotationBreakPoint: number = 8) => {
  let payloadValue: string = payload.payload.value;
  if (payloadValue.length > 10) {
    payloadValue = payloadValue.substring(0, 10) + '...';
  }
  return (
    <g transform={`translate(${payload.x - 5},${payload.y - 16})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fill="black"
        textAnchor={points.length > rotationBreakPoint ? 'end' : 'middle'}
        transform={points.length > rotationBreakPoint ? 'rotate(-40)' : ''}
        fontSize={points.length > rotationBreakPoint ? '12' : '14'}
        fontWeight={400}
      >
        {payloadValue}
      </text>
    </g>
  );
};

export const yAxisTickFormatter = (
  payload: any,
  points: any,
  formatAsPercentage: boolean = false,
  fill?: string,
  textAnchor?: string,
) => {
  return (
    <g>
      <text
        stroke="none"
        width="60"
        height="250"
        x={payload.x}
        y={payload.y}
        fill={fill ? fill : 'black'}
        textAnchor={textAnchor ? textAnchor : 'end'}
        fontSize={'13'}
        fontWeight={400}
      >
        <tspan x={payload.x} dy={payload.dy}>
          {formatAsPercentage
            ? numeral(payload.payload.value).format('0.00') + '%'
            : getChartsFormatedValue(payload.payload.value)}
        </tspan>
      </text>
    </g>
  );
};

export const customizedLabel = (props: any, displayedData: Point[]) => {
  return (
    <text
      x={props.x + 2}
      y={props.y + props.height / 2 + 4}
      fill={'black'}
      stroke="none"
      width="60"
      height="250"
      fontSize={displayedData.length > 6 ? '10' : '13'}
      fontWeight={400}
    >
      {displayedData[props.index].y}
    </text>
  );
};
