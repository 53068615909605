import { Action } from 'redux';

export const SET_SEARCH_MODEL_LIST = 'SET_SEARCH_MODEL_LIST';
export const RESET_SEARCH_MODEL_LIST = 'RESET_SEARCH_MODEL_LIST';

export const ADD_SEARCH_MODEL = 'ADD_SEARCH_MODEL';
export const SET_ACTIVE_SEARCH_MODEL = 'SET_ACTIVE_SEARCH_MODEL';
export const RESET_ACTIVE_SEARCH_MODEL = 'RESET_ACTIVE_SEARCH_MODEL';
export const LOADING_SEARCH_MODEL_LIST = 'LOADING_SEARCH_MODEL_LIST';

export const UPDATE_SEARCH_MODEL_TRAINING_JOB = 'UPDATE_SEARCH_MODEL_TRAINING_JOB';
export const SUBMIT_SEARCH_MODEL_TRAINING_JOB = 'SUBMIT_SEARCH_MODEL_TRAINING_JOB';
export const CANCEL_SEARCH_MODEL_TRAINING_JOB_SUBMISSION = 'CANCEL_SEARCH_MODEL_TRAINING_JOB_SUBMISSION';
export const UPDATE_SEARCH_MODEL_EVALUATION_JOB = 'UPDATE_SEARCH_MODEL_EVALUATION_JOB';
export const UPDATE_SEARCH_MODEL_DEPLOYMENT_JOB = 'UPDATE_SEARCH_MODEL_DEPLOYMENT_JOB';
export const SUBMIT_SEARCH_MODEL_DEPLOYMENT_JOB = 'SUBMIT_SEARCH_MODEL_DEPLOYMENT_JOB';
export const CANCEL_SEARCH_MODEL_DEPLOYMENT_JOB_SUBMISSION = 'CANCEL_SEARCH_MODEL_DEPLOYMENT_JOB_SUBMISSION';

export enum ModelJobStatus {
  NotStarted = -1,
  Unknown = 0,
  Pending = 1,
  Running = 2,
  Finished = 3,
  Error = 4,
  Canceling = 5,
  Canceled = 6,
}

export enum ModelType {
  LightGBMRanker = 'Light GBM ranker',
}

export interface SearchModelMapper {
  [key: string]: SearchModel;
}

export interface SearchModel {
  key: string;
  name: string;
  isNew: boolean;
  feature: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  modelType: ModelType;
  trainingJobKeys: string[];
  deploymentJobKeys: string[];
  evaluationJobKeys: string[];
  isSubmittingTraining: boolean;
  isSubmittingDeployment: boolean;
}

export interface SearchModelState {
  isLoading: boolean;
  activeSearchModel: SearchModel;
  searchModelMapper: SearchModelMapper;
}

interface SetSearchModelList extends Action<typeof SET_SEARCH_MODEL_LIST> {
  searchModelList: SearchModel[];
}

interface AddSearchModel extends Action<typeof ADD_SEARCH_MODEL> {
  searchModel: SearchModel;
}

interface UpdateSearchModelTrainingJob extends Action<typeof UPDATE_SEARCH_MODEL_TRAINING_JOB> {
  key: string;
  trainingJobKey: string;
}

interface UpdateSearchModelDeploymentJob extends Action<typeof UPDATE_SEARCH_MODEL_DEPLOYMENT_JOB> {
  key: string;
  deploymentJobKey: string;
}

interface SubmitSearchModelDeploymentJob extends Action<typeof SUBMIT_SEARCH_MODEL_DEPLOYMENT_JOB> {
  key: string;
}

interface CancelSearchModelDeploymentJobSubmission
  extends Action<typeof CANCEL_SEARCH_MODEL_DEPLOYMENT_JOB_SUBMISSION> {
  key: string;
}

interface SubmitSearchModelTrainingJob extends Action<typeof SUBMIT_SEARCH_MODEL_TRAINING_JOB> {
  key: string;
}

interface CancelSearchModelTrainingJobSubmission extends Action<typeof CANCEL_SEARCH_MODEL_TRAINING_JOB_SUBMISSION> {
  key: string;
}

interface UpdateSearchModelEvaluationJob extends Action<typeof UPDATE_SEARCH_MODEL_EVALUATION_JOB> {
  key: string;
  evaluationJobKey: string;
}

interface SetActiveSearchModel extends Action<typeof SET_ACTIVE_SEARCH_MODEL> {
  key: string;
}

export type SearchModelStateActionTypes =
  | AddSearchModel
  | SetSearchModelList
  | SetActiveSearchModel
  | UpdateSearchModelTrainingJob
  | SubmitSearchModelTrainingJob
  | UpdateSearchModelDeploymentJob
  | SubmitSearchModelDeploymentJob
  | UpdateSearchModelEvaluationJob
  | CancelSearchModelTrainingJobSubmission
  | CancelSearchModelDeploymentJobSubmission
  | Action<typeof RESET_SEARCH_MODEL_LIST>
  | Action<typeof LOADING_SEARCH_MODEL_LIST>
  | Action<typeof RESET_ACTIVE_SEARCH_MODEL>;
