import { EvaluateModelPanelStyles, EvaluateModelPanelStyleProps } from './EvaluateModelPanel.types';

export const styles = (props: EvaluateModelPanelStyleProps): EvaluateModelPanelStyles => ({
  root: [
    'mbc-evaluate-panel',
    {
      selectors: {
        '.ms-Panel-contentInner': {
          justifyContent: 'space-between',
        },
      },
    },
  ],
  header: [
    'mbc-header',
    {
      fontSize: 21,
      lineHeight: 28,
      fontWeight: 500,
    },
  ],
  footer: [
    'mbc-footer',
    {
      selectors: {
        '.ms-Button--default': {
          color: props.theme.palette.blue,
        },
      },
    },
  ],
  item: [
    'mbc-item-name',
    {
      fontSize: 12,
      lineHeight: 19,
      fontWeight: 600,
      color: props.theme.palette.themePrimary,
    },
  ],

  selectionAction: [
    'mbc-selection-action',
    {
      paddingBottom: 8,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: props.theme.palette.neutralLighter,
    },
  ],
  textInput: [
    'mbc-text-input',
    {
      width: 290,
      selectors: {
        '.ms-TextField-fieldGroup': {
          borderStyle: 'none',
        },
        '.ms-TextField-field': {
          backgroundColor: props.theme.palette.neutralLighter,
        },
        '.ms-Dropdown-title': {
          borderStyle: 'none',
          backgroundColor: props.theme.palette.neutralLighter,
        },
      },
    },
  ],
  overflow: [
    'mbc-overflow-cell',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
});
