import React, { useCallback } from 'react';
import { MbcRouter } from '../../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../../router/MbcRouter.types';
import { useSearchInstanceRouter } from '../../../store/hooks/use-router/useSearchInstanceRouter';
import { useProxyRouter } from '../../../store/hooks/use-router/useProxyRouter';

export const UrlRedirectSetListProxy = () => {
  const MbcSearchInstanceRouteMap = useSearchInstanceRouter();
  const MbcProxyRouteMapper = useProxyRouter();

  const getUrlRedirectSetRoutes = useCallback(
    (): MbcRouteBase[] => [
      MbcSearchInstanceRouteMap(MbcRouteKey.UrlRedirectSetList),
      MbcSearchInstanceRouteMap(MbcRouteKey.NewUrlRedirectSet),
      MbcProxyRouteMapper(MbcRouteKey.UrlRedirectSetProxy),
    ],
    [MbcProxyRouteMapper, MbcSearchInstanceRouteMap],
  );

  return <MbcRouter routes={getUrlRedirectSetRoutes()} />;
};
