import { classNamesFunction } from 'office-ui-fabric-react';

import { DraggableSliderStyles, DraggableSliderStyleProps } from './DraggableSlider.types';

export const styles = (): DraggableSliderStyles => {
  return {
    root: {
      background: '#719AD8',
      opacity: '0.8',
      width: '10px',
      height: '100%',
      cursor: 'pointer',
    },
    slider: {
      background: '#719AD8',
      opacity: '0.8',
      width: '14px',
      height: '100px',
      position: 'absolute',
      top: 'calc(50% - 50px)',
      left: '-2px',
      borderRadius: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      cursor: 'pointer',
    },
    subComponentStyles: {
      teachingBubble: {
        bodyContent: {
          padding: '10px',
        },
        subText: {
          color: '#666666',
        },
        subComponentStyles: {
          callout: {
            root: {
              maxWidth: '280px',
            },
            calloutMain: {
              background: 'white',
            },
            beak: {
              background: 'white',
            },
          },
        },
      },
    },
  };
};

export const getClassNames = classNamesFunction<DraggableSliderStyleProps, DraggableSliderStyles>();
