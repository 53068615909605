import {
  ADD_DETECTED_INDEX_SCHEMA,
  SET_DETECTED_INDEX_SCHEMA,
  SchemaDetection,
} from '../../../../types/schemaDetection.d';
import { ActionFailureHandler, ActionSuccessHandler, Dispatch, ReduxCrudAction } from '../../CrudAction.types';

import { Environment } from '../../../../types/environmentList.d';
import { MbcEnvironmentRouter } from '../../../../../router/MbcEnvironmentRouter/MbcEnvironmentRouter';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';
import { Tenant } from '../../../../types/tenantsList.d';
import { detectSchema } from '../../../../../restful-apis/schemaDetection.api';
import { generateUniqueIdentifier } from '../../../../../utils';
import { getI18n } from 'react-i18next';

export const detectIndexSchemaAction = (
  dataset: File,
  tenant: Tenant,
  environment: Environment,
  dispatch: Dispatch,
  onSuccess?: ActionSuccessHandler<SchemaDetection>,
  onFailure?: ActionFailureHandler<string>,
): ReduxCrudAction<SchemaDetection> => {
  const i18n = getI18n();
  const schemaKey = generateUniqueIdentifier();
  const format = dataset.name.split('.').pop() as string;
  const MbcEnvironmentRouteMap = MbcEnvironmentRouter(tenant, environment);
  return {
    request: detectSchema(dataset, tenant.id, format),
    postActionMapper: createdSchemaDetection => ({
      type: ADD_DETECTED_INDEX_SCHEMA,
      schemaDetection: { ...createdSchemaDetection, environmentId: environment.id, key: schemaKey },
    }),
    notification: {
      failure: () => ({
        title: i18n.t('schema-detection.req-failure', { name: dataset.name }),
      }),
      inProgress: {
        title: i18n.t('schema-detection.req-inprogress', { name: dataset.name }),
      },
      success: () => ({
        title: i18n.t('schema-detection.req-success', { name: dataset.name }),
        primaryButtonProps: {
          text: i18n.t('schema-detection.nav-text'),
          onClick: () => {
            dispatch({
              type: SET_DETECTED_INDEX_SCHEMA,
              key: schemaKey,
            });
            MbcEnvironmentRouteMap(MbcRouteKey.SchemaManagement).browserPush();
          },
        },
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, dataset.name),
  };
};
