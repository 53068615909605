import { ProductSelectorStyles, ProductSelectorStyleProps } from './ProductSelector.types';

export const styles = (props: ProductSelectorStyleProps): ProductSelectorStyles => {
  const { className, theme } = props;

  return {
    root: ['mbc-ProductSelector', {}, className],
    selectorTitle: [
      'mbc-ProductSelector-title',
      {
        color: theme.palette.neutralSecondary,
      },
    ],
  };
};
