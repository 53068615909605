import React, { useState, useEffect } from 'react';
import { PageTemplate } from '../../components/common/PageTemplate';
import { GeneralSectionContent } from './GeneralSectionContent';
import { Section } from '../../components/editors-common/Section';
import { Stack, classNamesFunction, Overlay, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { EnvironmentsSectionContent } from './EnvironmentsSectionContent';
import { PermissionsSectionContent } from './PermissionsSectionContent';
import { createToken } from '../../store/actions/tokenListActions';
import { useSelector, useDispatch } from '../../store/hooks';
import { createInitialTokenWithPermissions, Token } from '../../store/types/tokenList.d';
import { Environment } from '../../store/types/environmentList';
import { ConfigurationSectionContent } from './ConfigurationSectionContent';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import moment from 'moment';
import { useBreadcrumb } from '../../store/hooks/use-breadcrumb/useBreadcrumb';
import { Prompt } from 'react-router-dom';
import { addDays } from 'office-ui-fabric-react/lib/utilities/dateMath/DateMath';
import { TokenEditorStyleProps, TokenEditorStyles, TokenEditorBaseProps } from './TokenEditor.types';
import { TokenCreationStatusDialog } from './TokenCreationStatusDialog';

const getClassNames = classNamesFunction<TokenEditorStyleProps, TokenEditorStyles>();

export const TokenEditorBase = (props: TokenEditorBaseProps) => {
  const { t, theme, styles, className } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  useBreadcrumb(t('token-editor.breadcrumb-key'), props.match.url);

  const tenantId = useSelector(state => state.tenantsList.activeTenant.id);

  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [tokenName, setTokenName] = useState<string>('');
  const [isTokenAdminAccess, setIsTokenAdminAccess] = useState<boolean>(false);
  const [generateRefreshToken, setGenerateRefreshToken] = useState<boolean>(false);
  const [tokenEnvs, setTokenEnvs] = useState<Environment[]>([]);
  const [tokenPerms, setTokenPerms] = useState<string[]>([]);
  const [tokenExpDate, setTokenExpDate] = useState<Date>(
    moment()
      .endOf('day')
      .toDate(),
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [createdTokens, setCreatedTokens] = useState({ accessToken: '', refreshToken: '' });

  const adminRouter = useAdminRouter();

  const dispatch = useDispatch();

  useEffect(() => {
    const updated =
      tokenName !== '' ||
      tokenPerms.length !== 0 ||
      (tokenEnvs.length !== 0 || isTokenAdminAccess) ||
      generateRefreshToken;
    if (updated !== isUpdated) {
      setIsUpdated(updated);
    }

    updated ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);

    return () => {
      window.onbeforeunload = null;
    };
  }, [generateRefreshToken, isTokenAdminAccess, isUpdated, tokenEnvs.length, tokenName, tokenPerms.length]);

  return (
    <PageTemplate
      breadcrumbVisible
      pageHeaderProps={{
        title: t('token-editor.page-title'),
        titleCaption: t('token-editor.page-title-caption'),
      }}
      onRenderContent={() => (
        <>
          <Prompt when={isUpdated && !isSaveButtonClicked} message={t('common.on-before-unload-msg')} />
          {isSaveButtonClicked && !createdTokens.accessToken && (
            <Overlay isDarkThemed className={classNames.tokenCreationOverlay}>
              <Spinner size={SpinnerSize.large} label={t('token-creation-status-dialog.being-created-title')} />
            </Overlay>
          )}
          <TokenCreationStatusDialog
            accessToken={createdTokens.accessToken}
            refreshToken={createdTokens.refreshToken}
            onDismiss={() => {
              setCreatedTokens({ accessToken: '', refreshToken: '' });
              adminRouter(MbcRouteKey.ApiAndTokenList).browserPush();
            }}
            isOpen={isSaveButtonClicked && !!createdTokens.accessToken}
          />

          <Stack tokens={{ childrenGap: 32 }}>
            <Section
              title={t('token-editor.general-section.title')}
              onRenderContent={() => (
                <GeneralSectionContent
                  name={tokenName}
                  onNameChange={(_ev: any, newName?: string) => setTokenName(newName || '')}
                  disabled={isSaveButtonClicked}
                />
              )}
            />

            <div className={classNames.sectionSeparator} />

            <Section
              title={t('token-editor.configuration-section.title')}
              onRenderContent={() => (
                <Stack tokens={{ childrenGap: 8 }}>
                  <ConfigurationSectionContent
                    date={tokenExpDate}
                    onDateChange={(newExpDate: Date) => setTokenExpDate(newExpDate)}
                    generateRefreshToken={generateRefreshToken}
                    onGenerateRefreshTokenToggleChange={(newValue: boolean) => {
                      setGenerateRefreshToken(newValue);
                      newValue && setTokenExpDate(addDays(new Date(), 3));
                    }}
                    disabled={isSaveButtonClicked}
                  />
                  {(tokenExpDate >
                    moment()
                      .add(180, 'd')
                      .toDate() ||
                    tokenExpDate.getTime() - new Date().getTime() <= 900000) && (
                    /* Token expiry date must be more than 15 minutes and less than 180 days */
                    <div className={classNames.errorMessage}>{t('token-editor.expiry-date-error')}</div>
                  )}
                </Stack>
              )}
            />

            <div className={classNames.sectionSeparator} />

            <Section
              title={t('token-editor.envs-section.title')}
              caption={t('token-editor.envs-section.caption')}
              onRenderContent={() => (
                <EnvironmentsSectionContent
                  isAdminAccess={isTokenAdminAccess}
                  onChangeAdminAccess={(newValue: boolean) => setIsTokenAdminAccess(newValue)}
                  envs={tokenEnvs}
                  onEnvsChanged={(newEnvList: Environment[]) => setTokenEnvs(newEnvList)}
                  disabled={isSaveButtonClicked}
                />
              )}
            />

            <div className={classNames.sectionSeparator} />

            <Section
              title={t('token-editor.perms-section.title')}
              caption={t('token-editor.perms-section.caption')}
              onRenderContent={() => (
                <PermissionsSectionContent
                  onPermissionsChange={(newTokenPermissions: string[]) => setTokenPerms(newTokenPermissions)}
                  disabled={isSaveButtonClicked}
                />
              )}
            />
          </Stack>
        </>
      )}
      footerProps={{
        saveButtonDisabled:
          tokenName === '' ||
          tokenPerms.length === 0 ||
          (tokenEnvs.length === 0 && !isTokenAdminAccess) ||
          !tokenExpDate ||
          !moment(tokenExpDate).isValid() ||
          tokenExpDate >
            moment()
              .add(180, 'd')
              .toDate() ||
          tokenExpDate.getTime() - new Date().getTime() <= 900000 ||
          isSaveButtonClicked,
        cancelButtonDisabled: isSaveButtonClicked,
        onSave: () => {
          setIsSaveButtonClicked(true);
          dispatch(
            createToken(
              tenantId,
              {
                ...createInitialTokenWithPermissions(),
                name: tokenName,
                hasAccessToAllEnvironments: isTokenAdminAccess,
                environments: tokenEnvs,
                permissions: tokenPerms,
                expirationDate: !generateRefreshToken ? tokenExpDate.toUTCString() : '',
                isRefreshToken: generateRefreshToken,
              },
              (token: Token) => {
                setCreatedTokens({ accessToken: token.accessToken, refreshToken: token.refreshToken });
              },
              () => {
                setIsSaveButtonClicked(false);
              },
            ),
          );
        },
        onCancel: adminRouter(MbcRouteKey.ApiAndTokenList).browserPush,
      }}
    />
  );
};
