import { RoleListStyleProps, RoleListStyles } from './RoleList.types';

export const styles = (props: RoleListStyleProps): RoleListStyles => {
  return {
    root: ['mbc-Role-list', props.className],
    columnWithSubText: [
      'mbc-column-with-subtext',
      {
        paddingTop: 8,
        paddingBottom: 8,
      },
    ],
    subText: [
      'mbc-sub-text',
      {
        fontSize: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: props.theme.palette.neutralSecondary,
      },
    ],
    nameColumnCell: [
      'mbc-name-column-cell',
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        selectors: {
          '.ms-Link': {
            color: props.theme.palette.themePrimary,
          },
        },
      },
    ],
    countPersona: [
      'mbc-count-persona',
      {
        width: 28,
        selectors: {
          '.ms-Persona-initials': {
            backgroundColor: 'rgb(3, 131, 135)',
          },
        },
      },
    ],
    centeredColumns: [
      'mbc-centered',
      {
        alignItems: 'center',
        selectors: {
          '.ms-DetailsHeader-cellName': {
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '100%',
            justifyContent: 'center',
            selectors: {
              i: {
                paddingLeft: 4,
              },
            },
          },
        },
      },
    ],
  };
};
