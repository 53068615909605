import React from 'react';
import { Pivot, PivotItem, classNamesFunction } from 'office-ui-fabric-react';
import { NavBarPivotStyleProps, NavBarPivotStyles, NavBarPivotBaseProps } from './NavBarPivot.types';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { useAdminRouter } from '../../../store/hooks/use-router/useAdminRouter';
import { useCustomMLRouter } from '../../../store/hooks/use-router/useCustomMLRouter';
import { useMediaQuery } from 'react-responsive';
import { Feature } from '../../../store/types/tenantsList.d';
import { isFeatureEnabledPerTenant } from '../../../guards/utils';

const getClassName = classNamesFunction<NavBarPivotStyleProps, NavBarPivotStyles>();

export const NavBarPivotBase = (props: NavBarPivotBaseProps) => {
  const { styles, theme, className, componentRef, t } = props;
  const classNames = getClassName(styles, { theme: theme!, className: className });

  const currentLocation = useSelector((state: AppState) => state.router.location.state.mbcRouteKey);
  const activeTenant = useSelector((state: AppState) => state.tenantsList.activeTenant);
  const MbcAdminRouteMap = useAdminRouter();
  const MbcCustomMLRouteMap = useCustomMLRouter();

  const smallScreen = useMediaQuery({ query: '(max-width: 470px)' });

  const onPivotLinkClick = (item?: PivotItem) => {
    if (item) {
      switch (item.props.itemKey) {
        case 'Environments': {
          MbcAdminRouteMap(MbcRouteKey.EnvironmentList).browserPush();
          return;
        }
        case 'MachineLearning': {
          MbcCustomMLRouteMap(MbcRouteKey.SearchModelList).browserPush();
          return;
        }
        default: {
          return;
        }
      }
    }
  };

  const machineLearningKeys = [
    MbcRouteKey.SearchModelListProxy,
    MbcRouteKey.SearchModelList,
    MbcRouteKey.SearchModelProxy,
    MbcRouteKey.SearchModel,
    MbcRouteKey.DatasetList,
    MbcRouteKey.TrainingJob,
    MbcRouteKey.EvaluationJobListProxy,
    MbcRouteKey.EvaluationJobList,
    MbcRouteKey.EvaluationJobProxy,
    MbcRouteKey.EvaluationJob,
  ];

  const noSelectionKeys = [
    MbcRouteKey.Unknown,
    MbcRouteKey.HomePage,
    MbcRouteKey.Administration,
    MbcRouteKey.Role,
    MbcRouteKey.RoleList,
    MbcRouteKey.RoleProxy,
    MbcRouteKey.RoleListProxy,
    MbcRouteKey.ApiAndTokenList,
  ];

  return (
    <div className={classNames.root} ref={componentRef}>
      {activeTenant.id && (
        <Pivot
          selectedKey={
            noSelectionKeys.includes(currentLocation)
              ? null
              : machineLearningKeys.includes(currentLocation)
              ? 'MachineLearning'
              : 'Environments'
          }
          onLinkClick={item => onPivotLinkClick(item)}
        >
          <PivotItem
            headerText={smallScreen ? undefined : t('nav-bar-pivot.environments')}
            itemKey="Environments"
            itemIcon="Environments"
          />
          {isFeatureEnabledPerTenant(activeTenant, Feature.CustomML) && (
            <PivotItem
              headerText={smallScreen ? undefined : t('nav-bar-pivot.custom-ml')}
              itemKey={'MachineLearning'}
              itemIcon="MachineLearning"
            />
          )}
        </Pivot>
      )}
    </div>
  );
};
