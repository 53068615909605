import { NoResultsViewStyleProps, NoResultsViewStyles } from './NoResultsView.types';
import { getFlexColumnStyles } from '../common/Styles';

export const styles = (props: NoResultsViewStyleProps): NoResultsViewStyles => ({
  root: [
    'ms-ranker-preview',
    getFlexColumnStyles(),
    {
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 350,
    },
    props.className,
  ],
  content: [
    'ms-content',
    {
      fontSize: 16,
      lineHeight: 16,
      color: '#323130',
      textAlign: 'center',
    },
  ],
  subContent: [
    'ms-sub-content',
    {
      marginTop: 10,
      fontSize: 12,
      lineHeight: 16,
      color: '#323130',
      textAlign: 'center',
    },
  ],
  query: {
    fontWeight: 600,
  },
});
