import { initClarity } from './clarity.service';
import { initAnalytics } from './logger.service';
import Cookies from 'universal-cookie';

export let siteConsent: WcpConsent.SiteConsent | undefined = undefined;

const clearCookies = () => {
  const cookies = new Cookies();
  const analyticsCookiesKeys = ['ai_user', 'ai_session', '_clck'];
  Object.keys(cookies.getAll()).forEach(key => {
    if (analyticsCookiesKeys.includes(key)) {
      cookies.remove(key, { path: '/' });
    }
  });
};

//call back method when consent is changed by user
export const onConsentChanged = (newConsent: Record<WcpConsent.consentCategories, boolean>) => {
  const isConsented = !!newConsent
    ? newConsent.Analytics
    : siteConsent && siteConsent.getConsentFor(WcpConsent.consentCategories.Analytics);
  initAnalytics(isConsented);
  !!isConsented && initClarity();
  if (!isConsented) {
    clearCookies();
  }
};

export const initConsent = () => {
  //Init method
  !!WcpConsent &&
    WcpConsent.init(
      'en-US',
      'consent-banner',
      (err, _siteConsent) => {
        if (err) {
          alert(err);
        } else {
          siteConsent = _siteConsent!;
          !!_siteConsent && onConsentChanged(_siteConsent.getConsent());
        }
      },
      onConsentChanged,
    );
};
