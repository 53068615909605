import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { rootReducer } from './reducers';
import { routerMiddleware } from 'connected-react-router';
import { browserHistory } from '../history.module';

const middleware = [thunk, routerMiddleware(browserHistory)];
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
