import React, { Component, Fragment } from 'react';
import { DefaultButton, Stack, Link as FabricLink, Modal } from 'office-ui-fabric-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import styles from './NavBar.module.scss';
import { AuthContext } from '../../../../contexts';
import { AppState } from '../../../../store/reducers';
import { connect } from 'react-redux';
import { browserHistory } from '../../../../history.module';
import { showOobeDemo } from '../../../../store/actions/oobeDemoActions';
import MediaQuery from 'react-responsive';
import NavBarMobile from './NavBarMobile';
import { MbcTitle } from '../../common/MbcTitle';
import { ContactUs } from '../../../../components/common/ContactUs';
import { AppRoute } from '../../../../router/router.service';

interface NavBarProps extends WithTranslation {
  // from store
  isLoggedIn: boolean;
  showOobeDemo: Function;
}

interface NavBarState {
  showContactUsForm: boolean;
}

class NavBar extends Component<NavBarProps, NavBarState> {
  static contextType = AuthContext;

  constructor(props: NavBarProps) {
    super(props);
    this.state = {
      showContactUsForm: false,
    };
  }

  //be careful what you are doing with every set state

  private onClickContactUs = () => {
    this.setState({ showContactUsForm: true });
  };

  getActionButtons = (t: Function): JSX.Element[] | JSX.Element =>
    this.props.isLoggedIn ? (
      <DefaultButton onClick={() => this.context.logout()}>{t('homepage.navbar.signout-button')}</DefaultButton>
    ) : (
      [
        <DefaultButton key={'trial'} onClick={() => browserHistory.push(AppRoute.append('login'))}>
          {t('homepage.navbar.trial-button')}
        </DefaultButton>,
        <DefaultButton key={'login'} onClick={() => browserHistory.push(AppRoute.append('login'))}>
          {t('homepage.navbar.signin-button')}
        </DefaultButton>,
      ]
    );

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <MediaQuery query={'(max-device-width:1059px)'}>
          <NavBarMobile />
        </MediaQuery>
        <MediaQuery query={'(min-device-width:1060px)'}>
          <Modal isOpen={this.state.showContactUsForm}>
            <ContactUs
              supportEmail={'contactMBC@microsoft.com'}
              onDismiss={() => this.setState({ showContactUsForm: false })}
            />
          </Modal>
          <Stack className={styles.globalHeader} horizontal verticalAlign={'center'} tokens={{ childrenGap: 'l2' }}>
            <div>
              <MbcTitle />
            </div>
            <FabricLink className={styles.links} href="http://Microsoft.com/bing/commerce" target="_blank">
              {t('homepage.navbar.overview')}
            </FabricLink>
            <FabricLink
              className={styles.links}
              onClick={() => {
                this.props.showOobeDemo();
                browserHistory.push(AppRoute.get());
              }}
              styles={{ root: { textDecorationLine: '0' } }}
            >
              {t('homepage.navbar.try-now')}
            </FabricLink>
            <FabricLink className={styles.links} onClick={this.onClickContactUs.bind(this)}>
              {t('homepage.navbar.contact-us')}
            </FabricLink>
            <FabricLink className={styles.links} href="https://www.microsoft.com/en-us/bing/commerce#faq">
              {t('homepage.navbar.faq')}
            </FabricLink>
            <div className={styles.rightMenu}>{this.getActionButtons(t)}</div>
          </Stack>
        </MediaQuery>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
});

export const NavBarWithTranslation = withTranslation()(NavBar);
export default connect(
  mapStateToProps,
  { showOobeDemo },
)(NavBarWithTranslation);
