import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../../../router/MbcRouter.base';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { ActiveBusinessRuleLoading } from '../../../../components/Loading';
import { useBusinessRuleRouter } from '../../../../store/hooks/use-router/useSearchInstanceRouter';
import {
  setActiveBusinessRule,
  resetActiveBusinessRule,
} from '../../../../store/actions/customizations/businessRuleActions';
import { useEnvironmentPermissions } from '../../../../store/hooks/use-user-permissions/UseEnvironmentPermissions';

export const BusinessRuleProxy = (props: RouteComponentProps<{ businessRuleId: string }>) => {
  const dispatch = useDispatch();

  const MbcBusinessRuleRouteMap = useBusinessRuleRouter();
  const environmentPermissions = useEnvironmentPermissions();
  const isBusinessRuleListLoading = useSelector(state => state.businessRule.isLoading);

  useEffect(() => {
    dispatch(setActiveBusinessRule(props.match.params.businessRuleId));
    return () => {
      dispatch(resetActiveBusinessRule());
    };
  }, [dispatch, props.match.params.businessRuleId, isBusinessRuleListLoading]);

  return (
    <ActiveBusinessRuleLoading>
      <MbcRouter
        userPermissions={environmentPermissions}
        routes={[MbcBusinessRuleRouteMap(MbcRouteKey.BusinessRuleEditor)]}
      />
    </ActiveBusinessRuleLoading>
  );
};
