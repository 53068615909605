import React, { useEffect, useState } from 'react';
import LoadingSpinner from './LoadingSpinner';

interface LoadingProps {
  isLoading: boolean;
  children: JSX.Element;
}

export const Loading = (props: LoadingProps) => {
  const { isLoading, children } = props;

  // This is used to ensure rendering children with latest state values
  const [isLoadingInternal, setIsLoadingInternal] = useState(true);

  useEffect(() => {
    setIsLoadingInternal(isLoading);
    return () => {};
  }, [isLoading]);

  return isLoadingInternal ? <LoadingSpinner /> : children;
};
