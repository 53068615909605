import { DropFileUploaderStyles, DropFileUploaderStyleProps } from './DropFileUploader.types';

export const styles = (props: DropFileUploaderStyleProps): DropFileUploaderStyles => {
  const { className } = props;
  return {
    root: ['ms-DropFileUploader', className],
    fileDragDrop: [
      'ms-fileDragDrop',
      {
        width: '90%',
        margin: '10px',
        height: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 2,
        borderStyle: 'solid',
        borderColor: props.theme.palette.neutralTertiaryAlt,
      },
    ],
    uploadIcon: [
      'mbc-upload-icon',
      {
        fontSize: 18,
        marginLeft: 10,
        color: props.theme.palette.themePrimary,
      },
    ],
    errorMessage: [
      'mbc-error-msg',
      {
        textAlign: 'center',
        color: props.theme.palette.redDark,
      },
    ],
  };
};
