import {
  UserState,
  UserStateActionTypes,
  SET_USER_IMAGE,
  SET_LOGGED_IN_USER_DETAILS,
  SET_SANDBOX_USER_DETAILS,
  UPDATE_USER_DETAILS,
} from '../types/user.d';

const initialState: UserState = {
  email: '',
  name: '',
  initials: '',
  image: '',
  secondaryEmail: '',
  sandBoxDetails: { SandboxTrialEndDate: '', SandboxTrialStartDate: '', completedTutorials: [] },
};

export function userReducer(state = initialState, action: UserStateActionTypes): UserState {
  switch (action.type) {
    case SET_LOGGED_IN_USER_DETAILS: {
      return {
        ...state,
        name: action.name,
        email: action.email,
        initials: action.initials,
        secondaryEmail: action.secondaryEmail,
      };
    }
    case SET_SANDBOX_USER_DETAILS: {
      return {
        ...state,
        sandBoxDetails: action.sandBoxDetails,
      };
    }
    case UPDATE_USER_DETAILS: {
      return {
        ...state,
        sandBoxDetails: action.sandBoxDetails,
      };
    }
    case SET_USER_IMAGE: {
      return {
        ...state,
        image: action.image,
      };
    }
    default:
      return state;
  }
}
