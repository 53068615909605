import { Action } from 'redux';

export const SET_CUSTOM_ML_SEARCH_CONFIG = 'SET_CUSTOM_ML_SEARCH_CONFIG';
export const RESET_CUSTOM_ML_SEARCH_CONFIG = 'RESET_CUSTOM_ML_SEARCH_CONFIG';

export const DefaultRankerId = 'Default';
export const DefaultCustomMlSearchConfigId = 'DefaultCustomMlSet';
export const DefaultRankerText = 'Default Ranker';

export interface CustomMlSearchConfig {
  searchModel: string;
}

export interface CustomMlSearchConfigState {
  isLoading: boolean;
  customMlSearchConfig: CustomMlSearchConfig;
}

export interface SetCustomMlSearchConfig extends Action<typeof SET_CUSTOM_ML_SEARCH_CONFIG> {
  customMlSetList: CustomMlSearchConfig[];
}

export type CustomMlSearchConfigActionTypes =
  | SetCustomMlSearchConfigList
  | Action<typeof RESET_CUSTOM_ML_SEARCH_CONFIG>;
