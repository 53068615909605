import {
  IColumn,
  Stack,
  IconButton,
  TooltipHost,
  DirectionalHint,
  classNamesFunction,
  SelectionMode,
  CheckboxVisibility,
} from 'office-ui-fabric-react';
import React, { ReactNode, useState } from 'react';
import { Environment } from '../../../store/types/environmentList.d';

import { EnvironmentListStyleProps, EnvironmentListStyles, EnvironmentListProps } from './EnvironmentList.types';
import { DetailsListCard } from '../../../components/common/DetailsListCard/DetailsListCard';
import { ColumnPriority, SortDataType } from '../../../components/common/DetailsList';
import { Product } from '../../../store/types/tenantsList.d';
import { AssignEnvironments } from './AssignEnvironments';
import { EnvironmentStatus } from '../../../components/EnvironmentList/EnvironmentStatus';

const getClassNames = classNamesFunction<EnvironmentListStyleProps, EnvironmentListStyles>();

export const EnvironmentListBase = (props: EnvironmentListProps) => {
  const { viewOnlyMode, t, styles, theme, className, isLoading, items, onEnironmentsChanged } = props;

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const getProductIcon = (product: Product): JSX.Element | null => {
    switch (product) {
      case Product.TextSearch: {
        return (
          <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={'Text search'}>
            <IconButton iconProps={{ iconName: 'TextSearch' }} ariaLabel={'Text search'} />
          </TooltipHost>
        );
      }
      case Product.Recommendations: {
        return (
          <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={'Recommendations'}>
            <IconButton iconProps={{ iconName: 'Recommendations' }} ariaLabel={'Recommendations'} />
          </TooltipHost>
        );
      }
      default: {
        return null;
      }
    }
  };

  const onRenderItemColumn = (item?: Environment, column?: IColumn): ReactNode => {
    if (item && column) {
      const fieldContent = item[column.fieldName as keyof Environment] as string;
      switch (column.key) {
        case 'products':
          return (
            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 16 }} className={classNames.productsIcon}>
              {item.products.map(itemProduct => getProductIcon(itemProduct))}
            </Stack>
          );
        case 'status':
          return <EnvironmentStatus searchIndexId={item.indexId} />;
        default:
          return <span>{fieldContent}</span>;
      }
    }
  };

  return (
    <>
      {isPanelOpen && (
        <AssignEnvironments
          isOpen={isPanelOpen}
          onDismiss={() => setIsPanelOpen(false)}
          onSaveClick={(selectedItems: Environment[]) => {
            setIsPanelOpen(false);
            !!onEnironmentsChanged && onEnironmentsChanged(selectedItems);
          }}
          onCancelClick={() => setIsPanelOpen(false)}
          items={items}
        />
      )}
      <DetailsListCard
        className={classNames.root}
        header={{
          actions: !viewOnlyMode
            ? {
                edit: {
                  disabled: viewOnlyMode,
                  onClick: () => {
                    setIsPanelOpen(true);
                  },
                },
              }
            : undefined,
        }}
        searchActionProps={{ searchBy: 'name' }}
        listProps={{
          isLoading: isLoading,
          items: items.map(item => ({ ...item, key: item.id })),
          onRenderNoItemsToDisplay: {
            text: t('environment-list.no-data-text'),
            subText: t('environment-list.no-data-sub-text'),
            actionProps: {
              buttonText: t('common.no-data-button-text'),
              disabled: viewOnlyMode,
              onClick: () => {
                setIsPanelOpen(true);
              },
            },
          },
          selectionProps: {
            selectionMode: SelectionMode.none,
            checkboxVisibility: CheckboxVisibility.hidden,
            onSelectionChanged: () => {},
          },
          responsiveColumns: {
            columns: [
              {
                key: 'name',
                name: t('environment-list.th-name'),
                fieldName: 'name',
                minWidth: 200,
                maxWidth: 300,
                isMultiline: false,
                priority: ColumnPriority.Primary,
                sortDataType: SortDataType.text,
              },
              {
                key: 'status',
                name: t('environment-list.th-status'),
                fieldName: '',
                minWidth: 100,
                maxWidth: 120,
                isMultiline: false,
                priority: ColumnPriority.Primary,
                centerAligned: true,
                onRender: (item?: Environment, index?: number, column?: IColumn) => onRenderItemColumn(item, column),
              },
              {
                key: 'products',
                name: t('environment-list.th-products'),
                fieldName: 'product',
                minWidth: 200,
                maxWidth: 300,
                isMultiline: false,
                priority: ColumnPriority.Secondary,
                onRender: (item?: Environment, index?: number, column?: IColumn) => onRenderItemColumn(item, column),
                centerAligned: true,
              },
            ],
            secondaryBreakPoint: 550,
            teritaryBreakPoint: 1156,
          },
        }}
      />
    </>
  );
};
