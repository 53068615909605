import { IndexFieldEditorStyleProps, IndexFieldEditorStyles } from './IndexFieldEditor.types';

export const GROUD_INDENT_WIDTH_IN_PIXELS = 16;

export const styles = (props: IndexFieldEditorStyleProps): IndexFieldEditorStyles => {
  const calculatedWidth = (props.column && props.column.calculatedWidth) || 0;
  return {
    action: [
      'mbc-action',
      {
        width: '32px !important',
        height: '32px !important',
        selectors: {
          ':hover': {
            backgroundColor: props.theme.palette.neutralLight,
          },
          '.ms-Icon': {
            fontSize: '12px !important',
          },
        },
      },
    ],
    primaryCell: [
      'mbc-cell',
      {
        width: calculatedWidth - GROUD_INDENT_WIDTH_IN_PIXELS * props.groupLevel,
        // Incase of object row types add 4px padding to seperate the group label from chevron icon
        paddingLeft: (props.complexType && 4) || GROUD_INDENT_WIDTH_IN_PIXELS * props.groupLevel + 6,
      },
    ],
    subComponentStyles: {
      callout: {
        calloutMain: {
          maxHeight: '250px !important',
          selectors: {
            '.ms-Dropdown-item': {
              fontSize: 12,
              fontWeight: 400,
              color: props.theme.palette.neutralSecondary,
            },
          },
        },
      },
      textField: {
        field: {
          fontSize: 12,
          fontWeight: 400,
          color: props.theme.palette.neutralPrimary,
        },
        fieldGroup: {
          height: 26,
          display: 'flex',
          alignItems: 'center',
          border: 'none',
          justifyContent: 'center',
          selectors: {
            ':hover': {
              borderWidth: 1,
              borderRadius: 2,
              borderColor: props.theme.palette.neutralSecondary,
              borderStyle: 'solid',
            },
          },
        },
        root: {
          selectors: {
            input: {
              height: '100%',
              selectors: {
                '::placeholder': {
                  fontSize: 12,
                  fontWeight: 400,
                  color: props.theme.palette.neutralSecondaryAlt,
                },
              },
            },
          },
        },
      },
      dropdown: {
        root: {
          width: '100%',
        },
        caretDownWrapper: {
          height: 26,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        title: {
          height: 26,
          display: 'flex',
          padding: '0 8px',
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: props.theme.palette.neutralLight,
          borderRadius: 2,
          fontSize: 12,
          fontWeight: 400,
          color: props.theme.palette.neutralSecondary,
        },
      },
      detailsRow: {
        root: [
          {
            width: '100%',
            selectors: {
              '.ms-GroupSpacer': {
                display: 'none !important',
              },
            },
          },
        ],
      },
      groupHeader: {
        groupHeaderContainer: [
          'mbc-group-header',
          {
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: props.theme.palette.neutralLighter,
            selectors: {
              '.ms-GroupHeader-expand': {
                width: 32,
                height: 32,
                fontSize: 12,
                margin: '0 2px',
                cursor: 'pointer',
              },
            },
          },
        ],
        title: [
          'mbc-group-title',
          {
            fontSize: 12,
            fontWeight: 400,
            color: props.theme.palette.neutralPrimary,
          },
        ],
        headerCount: [
          'mbc-header-count',
          {
            display: 'none',
          },
        ],
      },
    },
  };
};
