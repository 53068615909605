import React, { useState, useCallback, ReactNode, useEffect, useMemo } from 'react';
import { classNamesFunction, Stack, StackItem, IconButton, Button } from 'office-ui-fabric-react';
import {
  ConditionValueWrapperProps,
  ConditionValueWrapperStyleProps,
  ConditionValueWrapperStyles,
} from './ConditionValueWrapper.types';
import { StringValueEditor } from '../StingValueEditor';
import { SetOperator } from '../../../../../utils/customizations/operators';
import { Moment } from 'moment';
import { NumericValueEditor } from '../NumericValueEditor';
import { DatetimeValueEditor } from '../DatetimeValueEditor';
import { BooleanValueEditor } from '../BooleanValueEditor';

const getClassNames = classNamesFunction<ConditionValueWrapperStyleProps, ConditionValueWrapperStyles>();

export const ConditionValueWrapperBase = (props: ConditionValueWrapperProps) => {
  const { styles, theme, className, fieldName, value, onValueChange, operator, valueType } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const multiValued = useMemo(() => operator in SetOperator && valueType !== 'Moment', [operator, valueType]);

  const [newValue, setNewValue] = useState<any>(undefined);
  const [currentValues, setCurrentValues] = useState<Set<any>>(
    value === undefined ? new Set() : new Set(multiValued ? value : [value]),
  );
  const disabled = useMemo(() => !multiValued && currentValues.size > 0, [currentValues.size, multiValued]);

  useEffect(() => {
    if (operator === SetOperator.in && fieldName === 'query' && !value.length) {
      setNewValue(undefined);
      setCurrentValues(new Set(['*']));
      onValueChange(['*']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator]);

  const addNewValue = useCallback(() => {
    if (newValue !== undefined) {
      setNewValue(undefined);
      if (multiValued) {
        let updatedCurrentValues = currentValues.add(newValue);
        if (fieldName === 'query' && operator === SetOperator.in) {
          updatedCurrentValues.delete('*');
        }
        setCurrentValues(updatedCurrentValues);
        onValueChange(Array.from(updatedCurrentValues));
      } else {
        setCurrentValues(new Set([newValue]));
        onValueChange(newValue);
      }
    }
  }, [currentValues, fieldName, multiValued, newValue, onValueChange, operator]);

  const deleteValue = useCallback(
    (valueToDelete: any) => {
      let updatedCurrentValues = new Set(currentValues);
      updatedCurrentValues.delete(valueToDelete);
      if (updatedCurrentValues.size === 0 && fieldName === 'query' && operator === SetOperator.in) {
        updatedCurrentValues = new Set(['*']);
      }
      setCurrentValues(updatedCurrentValues);
      onValueChange(multiValued ? Array.from(updatedCurrentValues) : undefined);
    },
    [currentValues, fieldName, multiValued, onValueChange, operator],
  );

  const getValueEditor = useCallback((): JSX.Element | null => {
    switch (valueType) {
      case 'boolean':
        return (
          <BooleanValueEditor
            value={newValue as boolean}
            disabled={disabled}
            onValueChange={val => setNewValue(val)}
            onSubmit={() => addNewValue()}
            onDismiss={() => setNewValue(undefined)}
          />
        );
      case 'string':
        return (
          <StringValueEditor
            fieldName={fieldName}
            disabled={disabled}
            value={newValue as string}
            onValueChange={val => setNewValue(val)}
            onSubmit={() => addNewValue()}
            onDismiss={() => setNewValue(undefined)}
          />
        );

      case 'number':
        return (
          <NumericValueEditor
            disabled={disabled}
            value={newValue ? (newValue as number) : undefined}
            onValueChange={val => setNewValue(val)}
            onSubmit={() => addNewValue()}
            onDismiss={() => setNewValue(undefined)}
          />
        );

      case 'Moment':
        return (
          <DatetimeValueEditor disabled={disabled} value={newValue as Moment} onValueChange={val => setNewValue(val)} />
        );

      default:
        return null;
    }
  }, [addNewValue, disabled, fieldName, newValue, valueType]);

  const currentValueItems = useMemo((): ReactNode[] => {
    const items: ReactNode[] = [];
    currentValues.forEach(value => {
      if (value !== undefined) {
        const valueString = value.toString();
        items.push(
          <Stack key={valueString} className={classNames.valueItem} horizontal verticalAlign={'center'}>
            <span className={classNames.valueItemText}>{valueString}</span>
            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => deleteValue(value)} />
          </Stack>,
        );
      }
    });
    return items;
  }, [classNames.valueItem, classNames.valueItemText, currentValues, deleteValue]);

  return (
    <Stack className={classNames.root} tokens={{ childrenGap: '8' }}>
      <Stack horizontal tokens={{ childrenGap: '8' }}>
        <StackItem grow>{getValueEditor()}</StackItem>

        <Button
          disabled={disabled}
          styles={{ root: { width: 95 } }}
          iconProps={{ iconName: 'Add' }}
          onClick={() => addNewValue()}
        >
          Add
        </Button>
      </Stack>
      <StackItem>
        {currentValues.size > 0 && (
          <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
            {currentValueItems}
          </Stack>
        )}
      </StackItem>
    </Stack>
  );
};
