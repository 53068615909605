import { EnvironmentListStyleProps, EnvironmentListStyles } from './EnvironmentList.types';

export const styles = (props: EnvironmentListStyleProps): EnvironmentListStyles => {
  return {
    root: [
      'mbc-environments-list',
      {
        height: 'unset',
      },
      props.className,
    ],
    productsIcon: [
      'mbc-products-icon',
      {
        height: '100%',
        selectors: {
          '.ms-TooltipHost': {
            height: '100%',
            selectors: {
              '.ms-Button': {
                height: '100%',
                selectors: {
                  ':hover': {
                    background: props.theme.palette.neutralQuaternaryAlt,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };
};
