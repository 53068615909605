import React from 'react';
import { useSelector } from '../../../../../store/hooks';
import {
  ModelParamsPreviewProps,
  ModelParamsPreviewStyles,
  ModelParamsPreviewStyleProps,
} from './ModelParamsPreview.types';
import { Stack, classNamesFunction, DefaultButton, Separator, TooltipHost, mergeStyles } from 'office-ui-fabric-react';
import { BasicParameters } from '../../../../../store/types/customML/trainingJob.d';
import { DatasetMapper } from '../../../../../store/types/customML/dataset.d';
import { ModelJobStatus } from '../../../../../store/types/customML/searchModel.d';
import { useActiveTraining } from '../../../../../store/hooks/use-active-job/useActiveTraining';
import { TenantPermissionContextType, TenantPermissionContext } from '../../../../../contexts';
import { TitledContentCard } from '../../../../../components/common/ContentCard';
import { Permission } from '../../../../../config/userPermissions.config';

const getClassNames = classNamesFunction<ModelParamsPreviewStyleProps, ModelParamsPreviewStyles>();

export function ModelParamsPreviewBase(props: ModelParamsPreviewProps) {
  const { t, styles, theme, onClick } = props;

  const trainingJob = useActiveTraining();
  const datasetMapper = useSelector<DatasetMapper>(state => state.datasets.datasetMapper);

  const classNames = getClassNames(styles, { theme: theme!, isDefinedModel: !!trainingJob.key });

  const LabelGainParameter = () => {
    const label = BasicParameters.LabelGain;
    const value = trainingJob.parameters[BasicParameters.LabelGain];
    return (
      <Stack key={label} horizontal horizontalAlign={'space-between'} className={classNames.borderLine}>
        <div className={classNames.parameterLabel}>{label}</div>
        <TooltipHost content={value} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div className={mergeStyles(classNames.parameterValue, classNames.overFlow)}>{value}</div>
        </TooltipHost>
      </Stack>
    );
  };

  const NumericTrainingParameter = (props: { parameter: BasicParameters }) => {
    const { parameter } = props;
    const value = trainingJob.parameters[parameter];
    return (
      <Stack key={parameter} horizontal horizontalAlign={'space-between'} className={classNames.borderLine}>
        <div className={classNames.parameterLabel}>{parameter}</div>
        <div className={classNames.parameterValue}>{value}</div>
      </Stack>
    );
  };

  // const SelectedFeatures = () => {
  //   const selectedFeatures = trainingJob.parameters[BasicParameters.FeatureSelected];
  //   return (
  //     <Stack tokens={{ childrenGap: 8 }} className={classNames.borderLine}>
  //       <div className={classNames.parameterLabel}>{t('custom-ml.train-model.features-label')}</div>
  //       <Stack style={{ maxHeight: 100, overflow: 'auto' }}>
  //         {!selectedFeatures && (
  //           <div className={classNames.parameterValue}>{t('custom-ml.train-model.no-features-label')}</div>
  //         )}
  //         {selectedFeatures &&
  //           selectedFeatures.split('\n').map((feature, index) => (
  //             <div key={index} className={classNames.parameterValue}>
  //               {feature}
  //             </div>
  //           ))}
  //       </Stack>
  //     </Stack>
  //   );
  // };

  const AdditionalParameters = () => {
    const additionalParameters = trainingJob.parameters[BasicParameters.Parameters];
    return (
      <Stack tokens={{ childrenGap: 8, padding: '8px 0' }}>
        <div className={classNames.parameterLabel}>{t('custom-ml.train-model.params-label')}</div>
        <Stack style={{ maxHeight: 250, overflow: 'auto' }}>
          {!additionalParameters && (
            <div className={classNames.parameterValue}>{t('custom-ml.train-model.no-params-label')}</div>
          )}
          {additionalParameters &&
            additionalParameters.split('\n').map((feature, index) => (
              <div key={index} className={classNames.parameterValue}>
                {feature}
              </div>
            ))}
        </Stack>
      </Stack>
    );
  };

  const TrainingDataset = () => (
    <Stack tokens={{ childrenGap: 4 }}>
      <div className={classNames.parameterLabel}>{t('custom-ml.train-model.training-data-label')}</div>
      <div className={classNames.datasetName}>{datasetMapper[trainingJob.datasetKey].name}</div>
    </Stack>
  );

  const TestingDataset = () => (
    <Stack tokens={{ childrenGap: 4 }}>
      <div className={classNames.parameterLabel}>{t('custom-ml.train-model.testing-data-label')}</div>
      <div className={classNames.datasetName}>{datasetMapper[trainingJob.testDatasetKey].name}</div>
    </Stack>
  );

  const ParametersPreview = () => (
    <Stack>
      <TrainingDataset />
      <Separator />
      <TestingDataset />
      <Separator />
      <Stack>
        <LabelGainParameter />
        <NumericTrainingParameter parameter={BasicParameters.LearningRate} />
        <NumericTrainingParameter parameter={BasicParameters.MinDataInLeaf} />
        <NumericTrainingParameter parameter={BasicParameters.NumLeaves} />
        <NumericTrainingParameter parameter={BasicParameters.NumTrees} />
        {/* <SelectedFeatures /> */}
        <AdditionalParameters />
      </Stack>
    </Stack>
  );

  return (
    <TitledContentCard
      key="train-params"
      styles={classNames.subComponentStyles.card}
      title={t('custom-ml.train-model.params-card-title')}
      body={
        <Stack grow verticalAlign={'space-between'} tokens={{ childrenGap: 42 }}>
          {!!trainingJob.key && <ParametersPreview />}
          <Stack className={classNames.parametersBtn}>
            <TenantPermissionContext.Consumer key="add">
              {(props: TenantPermissionContextType) => {
                const isDisabled =
                  !onClick ||
                  !props.isAuthorized(Permission.SearchCustomMLReadWrite) ||
                  (!!trainingJob.key && trainingJob.status !== ModelJobStatus.Error);
                return (
                  <DefaultButton onClick={() => onClick && onClick()} disabled={isDisabled}>
                    {!!trainingJob.key
                      ? t('custom-ml.train-model.re-define-params-card-action')
                      : t('custom-ml.train-model.params-card-action')}
                  </DefaultButton>
                );
              }}
            </TenantPermissionContext.Consumer>
          </Stack>
        </Stack>
      }
    />
  );
}
