import React, { useState, useRef, useMemo, useCallback, ReactNode } from 'react';
import {
  classNamesFunction,
  SelectionMode,
  Modal,
  CheckboxVisibility,
  Persona,
  PersonaSize,
  PersonaInitialsColor,
  Stack,
  Icon,
  IconButton,
  IColumn,
  TooltipHost,
  TooltipOverflowMode,
  Link,
  ActionButton,
} from 'office-ui-fabric-react';
import {
  DatasetListProps,
  DatasetListStyleProps,
  DatasetListStyles,
  DatasetListBaseProps,
  CookingJobListProps,
} from './DatasetList.types';
import { DatasetUploadPanel } from './DatasetUploadPanel';
import { useDispatch } from 'react-redux';
import { CookDatasetEditor } from './CookDatasetEditor';
import { getStatusIconProps } from '../SearchModel/SearchModel.utils';
import { useTenantPermissions } from '../../../store/hooks/use-user-permissions/UseTenantPermissions';
import { useRawDatasetList } from '../../../store/hooks/use-list/useRawDatasetList';
import { useCookingJobList } from '../../../store/hooks/use-list/useCookingJobList';
import { isAuthorized } from '../../../utils';
import { Permission } from '../../../config/userPermissions.config';
import { PanelAction, PivotCard } from '../../../components/common/PivotCard';
import { Dataset } from '../../../store/types/customML/dataset.d';
import { useSelector } from '../../../store/hooks';
import { batchCookDataset } from '../../../store/actions/customML/cookingJobActions';
import { CookingJob } from '../../../store/types/customML/cookingJob.d';
import { PageTemplate } from '../../../components/common/PageTemplate';
import { ColumnPriority, SortDataType, ColumnWithPriority } from '../../../components/common/DetailsList';
import { DetailsListCard } from '../../../components/common/DetailsListCard';
import { ImageStatus, UserEmailMapper } from '../../../store/types/userList.d';
import { updateTenantUserImage } from '../../../store/actions/userListActions';
import { TenantPermissionContext, TenantPermissionContextType } from '../../../contexts';
import { createRawDataset } from '../../../store/actions/customML/datasetActions';

const SOURCE_DATA_PANEL = 'SOURCE_DATA_PANEL';
const COOKED_DATA_PANEL = 'COOKED_DATA_PANEL';

export const getClassNames = classNamesFunction<DatasetListStyleProps, DatasetListStyles>();

const DatasetListCard = (props: DatasetListProps): JSX.Element => {
  const { t, styles, theme, onSelectionChange } = props;
  const classNames = getClassNames(styles, { theme: theme! });

  const dispatch = useDispatch();
  const datasetList = useRawDatasetList();
  const isLoading = useSelector<boolean>(state => state.searchModels.isLoading);
  const userEmailMapper = useSelector<UserEmailMapper>(state => state.userList.userEmailMapper);

  const onDatasetSelectionChanged = (selection: string[]) =>
    onSelectionChange(datasetList.filter(dataset => selection.includes(dataset.key)));

  const onRenderItemColumnName = useCallback(
    (item?: Dataset, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.name}
        >
          <Link style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}>{item.name}</Link>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderItemColumnDescription = useCallback(
    (item?: Dataset, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.description}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.description}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderItemColumnPersona = useCallback(
    (item?: Dataset, index?: number, column?: IColumn): ReactNode => {
      if (!item || !column) {
        return null;
      }
      const user = userEmailMapper[item.email];
      if (user.email && user.imageStatus === ImageStatus.NotInitialized) {
        dispatch(updateTenantUserImage(item.email));
      }
      return (
        <Persona
          text={item.email}
          imageUrl={user.imageUrl}
          size={PersonaSize.size24}
          hidePersonaDetails={false}
          initialsColor={PersonaInitialsColor.darkBlue}
        />
      );
    },
    [dispatch, userEmailMapper],
  );

  const columns: ColumnWithPriority[] = useMemo(
    () => [
      {
        minWidth: 250,
        maxWidth: 250,
        key: 'model-name',
        fieldName: 'name',
        onRender: onRenderItemColumnName,
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        name: t('custom-ml.dataset-list.th-name'),
      },
      {
        minWidth: 300,
        maxWidth: 350,
        isMultiline: true,
        key: 'model-description',
        fieldName: 'description',
        onRender: onRenderItemColumnDescription,
        priority: ColumnPriority.Tertiary,
        name: t('custom-ml.dataset-list.th-description'),
      },
      {
        minWidth: 250,
        maxWidth: 350,
        key: 'model-owner',
        fieldName: 'email',
        onRender: onRenderItemColumnPersona,
        priority: ColumnPriority.Primary,
        name: t('custom-ml.dataset-list.th-email'),
      },
      {
        minWidth: 100,
        maxWidth: 200,
        key: 'model-create-date',
        fieldName: 'createdAt',
        priority: ColumnPriority.Secondary,
        name: t('custom-ml.dataset-list.th-upload-date'),
      },
    ],
    [onRenderItemColumnDescription, onRenderItemColumnName, onRenderItemColumnPersona, t],
  );

  return (
    <DetailsListCard
      searchActionProps={{
        searchBy: 'name',
        ariaLabel: t('custom-ml.dataset-list.search-aria-label'),
        placeholder: t('custom-ml.dataset-list.search-placeholder'),
      }}
      header={{
        actions: {
          filter: {},
          actionsList: [<IconButton key="sort" disabled iconProps={{ iconName: 'SortLines' }}></IconButton>],
        },
      }}
      listProps={{
        isLoading: isLoading,
        isHeaderVisible: true,
        items: datasetList,
        optionsItemProps: (item: Dataset) => [
          {
            key: item.key,
            disabled: true,
            text: t('custom-ml.dataset-list.item-delete'),
            iconProps: { iconName: 'Delete' },
            onClick: () => {},
          },
        ],
        selectionProps: {
          selectionMode: SelectionMode.multiple,
          checkboxVisibility: CheckboxVisibility.always,
          onSelectionChanged: selection => onDatasetSelectionChanged(selection),
        },
        responsiveColumns: {
          columns: columns,
          secondaryBreakPoint: 800,
          teritaryBreakPoint: 1156,
        },
      }}
    />
  );
};

const CookingJobListCard = (props: CookingJobListProps) => {
  const { t, styles, theme } = props;
  const classNames = getClassNames(styles, { theme: theme! });

  const dispatch = useDispatch();
  const cookingJobList = useCookingJobList();
  const isLoading = useSelector<boolean>(state => state.searchModels.isLoading);
  const userEmailMapper = useSelector<UserEmailMapper>(state => state.userList.userEmailMapper);

  const onRenderItemColumnName = useCallback(
    (item?: CookingJob, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.name}
        >
          <Link style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}>{item.name}</Link>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderItemColumnDescription = useCallback(
    (item?: CookingJob, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.description}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.description}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderItemColumnStatus = useCallback(
    (item?: CookingJob, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign={'center'} key="status">
          <Icon {...getStatusIconProps(item.status)}></Icon>
          <div style={{ fontSize: 12 }}>{t(`dynamic:custom-ml.job-status.${item.status}`)}</div>
        </Stack>
      ),
    [t],
  );

  const onRenderItemColumnPersona = useCallback(
    (item?: CookingJob, index?: number, column?: IColumn): ReactNode => {
      if (!item || !column) {
        return null;
      }
      const user = userEmailMapper[item.email];
      if (user.email && user.imageStatus === ImageStatus.NotInitialized) {
        dispatch(updateTenantUserImage(item.email));
      }
      return (
        <Persona
          text={item.email}
          imageUrl={user.imageUrl}
          size={PersonaSize.size24}
          hidePersonaDetails={false}
          initialsColor={PersonaInitialsColor.darkBlue}
        />
      );
    },
    [dispatch, userEmailMapper],
  );

  const columns: ColumnWithPriority[] = useMemo(
    () => [
      {
        minWidth: 200,
        maxWidth: 300,
        key: 'model-name',
        fieldName: 'name',
        onRender: onRenderItemColumnName,
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        name: t('custom-ml.dataset-list.th-name'),
      },
      {
        minWidth: 200,
        maxWidth: 200,
        isMultiline: true,
        key: 'model-description',
        fieldName: 'description',
        onRender: onRenderItemColumnDescription,
        priority: ColumnPriority.Tertiary,
        name: t('custom-ml.dataset-list.th-description'),
      },
      {
        minWidth: 200,
        maxWidth: 200,
        key: 'model-owner',
        fieldName: 'email',
        onRender: onRenderItemColumnPersona,
        priority: ColumnPriority.Secondary,
        name: t('custom-ml.dataset-list.th-email'),
      },
      {
        minWidth: 150,
        maxWidth: 200,
        fieldName: 'createdAt',
        key: 'model-create-date',
        priority: ColumnPriority.Tertiary,
        name: t('custom-ml.dataset-list.th-create-date'),
      },
      {
        minWidth: 150,
        maxWidth: 200,
        key: 'model-status',
        fieldName: 'status',
        onRender: onRenderItemColumnStatus,
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        name: t('custom-ml.dataset-list.th-status'),
      },
    ],
    [onRenderItemColumnDescription, onRenderItemColumnName, onRenderItemColumnPersona, onRenderItemColumnStatus, t],
  );

  return (
    <DetailsListCard
      searchActionProps={{
        searchBy: 'name',
        ariaLabel: t('custom-ml.dataset-list.search-aria-label'),
        placeholder: t('custom-ml.dataset-list.search-placeholder'),
      }}
      header={{
        actions: {
          filter: {},
          actionsList: [<IconButton key="sort" disabled iconProps={{ iconName: 'SortLines' }}></IconButton>],
        },
      }}
      listProps={{
        isLoading: isLoading,
        isHeaderVisible: true,
        items: cookingJobList,
        optionsItemProps: (item: CookingJob) => [
          {
            key: item.key,
            disabled: true,
            text: t('custom-ml.dataset-list.item-delete'),
            iconProps: { iconName: 'Delete' },
            onClick: () => {},
          },
        ],
        responsiveColumns: {
          columns: columns,
          secondaryBreakPoint: 800,
          teritaryBreakPoint: 1156,
        },
      }}
    />
  );
};

export function DatasetListBase(props: DatasetListBaseProps) {
  const { t, styles, theme } = props;
  const classNames = getClassNames(styles, { theme: theme! });

  const dispatch = useDispatch();
  const userPermissions = useTenantPermissions();
  const datasetListCount = useRawDatasetList().length;
  const cookingJobListCount = useCookingJobList().length;
  const [isUploadPanelOpen, showUploadPanel] = useState<boolean>(
    () =>
      datasetListCount === 0 &&
      cookingJobListCount === 0 &&
      isAuthorized(userPermissions, Permission.SearchCustomMLReadWrite),
  );

  const goToPanelRef = useRef<PanelAction>();
  const [isCookModalOpen, showCookModal] = useState<boolean>(false);
  const [selectedDatasetList, setSelectedDatasetList] = useState<Dataset[]>([]);
  const tenantId = useSelector<string>(state => state.tenantsList.activeTenant.id);
  const searchModelKey = useSelector<string>(state => state.searchModels.activeSearchModel.key);

  const onUploadRawDataset = (file: File, dataset: Dataset) => {
    dispatch(createRawDataset(tenantId, dataset, file));
    goToPanelRef.current && goToPanelRef.current(SOURCE_DATA_PANEL);
  };

  const onCreateCookingJobList = (cookingJobList: CookingJob[]) => {
    dispatch(batchCookDataset(tenantId, searchModelKey, cookingJobList));
    goToPanelRef.current && goToPanelRef.current(COOKED_DATA_PANEL);
  };

  return (
    <>
      <DatasetUploadPanel
        key="upload-panel"
        isOpen={isUploadPanelOpen}
        onDismiss={() => showUploadPanel(false)}
        onUpload={(file, dataset) => onUploadRawDataset(file, dataset)}
      />
      <Modal key="cook-modal" isOpen={isCookModalOpen}>
        <CookDatasetEditor
          datasetList={selectedDatasetList}
          onDismiss={() => showCookModal(false)}
          onCookDatasetList={cookingJobList => onCreateCookingJobList(cookingJobList)}
        />
      </Modal>
      <PageTemplate
        breadcrumbVisible
        styles={classNames.subComponentStyles.page}
        pageHeaderProps={{
          title: t('custom-ml.dataset-list.dataset-title'),
          actions: [
            <TenantPermissionContext.Consumer key="add">
              {(props: TenantPermissionContextType) => {
                const isAuthorized = props.isAuthorized(Permission.SearchCustomMLReadWrite);
                return (
                  <ActionButton
                    key="cook"
                    iconProps={{ iconName: 'Play' }}
                    onClick={() => showCookModal(true)}
                    disabled={!isAuthorized || selectedDatasetList.length === 0}
                  >
                    {t('custom-ml.dataset-list.cook-dataset')}
                  </ActionButton>
                );
              }}
            </TenantPermissionContext.Consumer>,
            <TenantPermissionContext.Consumer key="add">
              {(props: TenantPermissionContextType) => {
                const isAuthorized = props.isAuthorized(Permission.SearchCustomMLReadWrite);
                return (
                  <ActionButton
                    key="upload"
                    iconProps={{ iconName: 'Add' }}
                    disabled={!isAuthorized}
                    onClick={() => showUploadPanel(true)}
                  >
                    {t('custom-ml.dataset-list.upload-dataset')}
                  </ActionButton>
                );
              }}
            </TenantPermissionContext.Consumer>,
          ],
        }}
        onRenderContent={() => (
          <PivotCard
            panels={(goToPanel: PanelAction) => {
              goToPanelRef.current = goToPanel;
              return [
                {
                  key: SOURCE_DATA_PANEL,
                  title: t('custom-ml.dataset-list.source-data-panel'),
                  panel: (
                    <DatasetListCard
                      key="dataset"
                      {...props}
                      onSelectionChange={datasets => setSelectedDatasetList(datasets)}
                    />
                  ),
                },
                {
                  key: COOKED_DATA_PANEL,
                  title: t('custom-ml.dataset-list.cooked-data-panel'),
                  panel: <CookingJobListCard key="cookingJob" {...props} />,
                },
              ];
            }}
          />
        )}
      />
    </>
  );
}
