import { FacetOption } from './dto/search/facet';
import HttpClient from './http-client/httpClient';
import { SearchResults } from './dto/search/search';
import { SearchIndex, IndexField } from '../store/types/searchIndex.d';
import { ProductSearchResult } from '../store/types/searchDemo.d';
import { buildFilterObject, createAggregationFilters } from './facet.api';
import { Augmentations, UrlParamAugmentations } from '../pages/ProductSearch/common/SearchPreview/SearchPrevew.utils';
import { SearchPostRequest } from '../utils/customizations/search/searchRequest';
import {
  Condition,
  ConditionBlock,
  createConditionBlock,
  createStringCondition,
} from '../utils/customizations/conditions';
import { mapSynonymSetModelToDto } from './mappers/customizations/synonymSetMapper';
import { customMlModelModelToDto } from './mappers/customMlSearchConfigMapper';
import { mapSearchPreviewProductListDtoToModel } from './mappers/productSearchMapper';
import { FieldValueSuggestions } from '../store/types/customizations/queryTester';
import { mapSearchResultToFieldValueSuggestions } from './mappers/searchDemoMapper';
import { mapBannerModelToSearchBanner } from './mappers/customizations/businessRuleMapper';
import { Facet } from '../utils/customizations/search/aggrigation';
import { isDynamicsTenant } from '../utils/dynamics';

function objectFieldHasDupIdChildField(indexField: IndexField): boolean {
  let hasDupId = false;

  if (indexField && indexField.fields) {
    for (let index = 0; index < indexField.fields.length; ++index) {
      const field = indexField.fields[index];
      if (field.type.toLowerCase() === 'dupid') {
        hasDupId = true;
        break;
      } else if (field.type.toLowerCase() === 'object') {
        hasDupId = objectFieldHasDupIdChildField(field);
        if (hasDupId) {
          break;
        }
      }
    }
  }

  return hasDupId;
}

function indexHasDupIdField(searchIndex: SearchIndex) {
  let hasDupId = false;
  if (searchIndex && searchIndex.fields) {
    for (let index = 0; index < searchIndex.fields.length; ++index) {
      const field = searchIndex.fields[index];
      if (field.type.toLowerCase() === 'dupid') {
        hasDupId = true;
        break;
      } else if (field.type.toLowerCase() === 'object') {
        hasDupId = objectFieldHasDupIdChildField(field);
        if (hasDupId) {
          break;
        }
      }
    }
  }

  return hasDupId;
}

const addRequestAugmentations = (postBody: SearchPostRequest, augmentations: Augmentations): SearchPostRequest => {
  const boosts = augmentations.boostConditions
    ? augmentations.boostConditions.filter(cnd => cnd.condition.conditions.length > 0)
    : undefined;
  return {
    ...postBody,
    query: {
      ...postBody.query,
      alteration: augmentations.alteration,
      filter:
        !!augmentations.filterCondition && (augmentations.filterCondition as ConditionBlock).conditions.length > 0
          ? augmentations.filterCondition
          : undefined,
      boosts: !!boosts && boosts.length > 0 ? boosts : undefined,
      synonyms: !!augmentations.synonyms ? augmentations.synonyms.map(mapSynonymSetModelToDto) : undefined,
      redirectUrl: augmentations.redirectUrl,
      excludeBusinessRules: augmentations.excludeBusinessRules,
    },
    items: {
      ...postBody.items,
      orderBy: augmentations.orderBy,
    },
    CustomMlModels: augmentations.CustomMlModels && augmentations.CustomMlModels.map(customMlModelModelToDto),
    ExcludeCustomMlIds: augmentations.excludeCustomMlIds && augmentations.excludeCustomMlIds,
    banners: augmentations.banners && augmentations.banners.map(mapBannerModelToSearchBanner),
  };
};

export function createDefaultPostBody(
  searchInstanceId: string,
  searchIndex: SearchIndex,
  query: string | undefined,
  skip: number = 0,
  top: number = 10,
  isOobe: boolean = false,
): SearchPostRequest {
  const fields = [];
  fields.push('_score');
  fields.push('*');

  let enableDedupe = indexHasDupIdField(searchIndex);

  return {
    searchInstanceId: searchInstanceId,
    query: {
      matchAll: query ? query : '',
      alteration: true,
    },
    items: {
      select: fields,
      top: top,
      skip: skip,
      dedupe: enableDedupe,
    },
    aggregations: [
      {
        name: 'disc',
        _type: 'DiscoverFacets',
        rangeFacetDefaults: {
          orderBy: '_value asc',
          _type: 'FacetBase',
        },
        pin: isOobe
          ? [
              { _type: 'Facet', field: 'categories' },
              { _type: 'Facet', field: 'brand' },
              { _type: 'Facet', field: 'price' },
            ]
          : undefined,
      },
    ],
  };
}

export function createAugmentedPostBody(
  searchInstanceId: string,
  searchIndex: SearchIndex,
  query: string | undefined,
  augmentations?: Augmentations,
  skip: number = 0,
  top: number = 10,
  isOobe: boolean = false,
): SearchPostRequest {
  const defaultPostBody = createDefaultPostBody(searchInstanceId, searchIndex, query, skip, top, isOobe);
  return augmentations ? addRequestAugmentations(defaultPostBody, augmentations) : defaultPostBody;
}

export function createFieldValueSuggestionsPostBody(
  searchInstanceId: string,
  searchIndex: SearchIndex,
  facets: Facet[],
): SearchPostRequest | null {
  const filterableField =
    searchIndex.fields.filter(field => field.type === 'ProductId')[0] ||
    searchIndex.fields.filter(field => field.type === 'Price' && field.features.filterable)[0];
  return !!filterableField
    ? {
        searchInstanceId: searchInstanceId,
        query: {
          matchAll: '',
          filter: createConditionBlock({
            conditions: [createStringCondition({ field: filterableField.name, value: '-1', operator: 'ne' })],
          }),
        },
        items: {
          select: ['_score', '*'],
          top: 15,
          skip: 0,
        },
        aggregations: facets,
      }
    : null;
}

const createSearchUrl = (indexId: string, tenantId: string, urlParamAugmentations?: UrlParamAugmentations): string => {
  let baseUrl = `https://commerce.bing.com/api/search/v1/${tenantId}/indexes/${indexId}?traffictype=portal`;
  if (!!urlParamAugmentations && !!urlParamAugmentations.mkt) baseUrl = `${baseUrl}?mkt=${urlParamAugmentations.mkt}`;
  if (!!urlParamAugmentations && !!urlParamAugmentations.setLang)
    baseUrl = `${baseUrl}?setLang=${urlParamAugmentations.setLang}`;

  return baseUrl;
};

const search = (
  indexId: string,
  tenantId: string,
  postBody: SearchPostRequest,
  urlParamAugmentations?: UrlParamAugmentations,
): Promise<SearchResults> => {
  // special handling due to dynamics tenant limitations
  if (isDynamicsTenant(tenantId)) {
    delete postBody.searchInstanceId;
  }
  return HttpClient.post<SearchResults>({
    url: createSearchUrl(indexId, tenantId, urlParamAugmentations),
    body: postBody,
    authInfo: { tenantId: tenantId, indexId: indexId },
  });
};

export function getSearchResults(
  skip: number,
  top: number,
  query: string | undefined,
  tenantId: string,
  searchIndex: SearchIndex,
  searchInstanceId: string,
  selectedRefinements: FacetOption[],
  augmentations?: Augmentations,
  augmentedPostBody?: SearchPostRequest,
  isOobe?: boolean,
): Promise<ProductSearchResult> {
  let postBody: SearchPostRequest = augmentedPostBody
    ? augmentedPostBody
    : createAugmentedPostBody(searchInstanceId, searchIndex, query, augmentations, skip, top, isOobe);

  const urlParamAugmentations = augmentations ? augmentations.urlParams : undefined;
  const filterObject: ConditionBlock | undefined = buildFilterObject(selectedRefinements);
  const aggregationFilters: any[] = createAggregationFilters(searchIndex.fields, selectedRefinements);

  if (filterObject) {
    if (postBody.query) {
      if (postBody.query.filter) {
        if (postBody.query.filter._type === Condition.ConditionBlock) {
          const filterConditonBlock = createConditionBlock({
            conditions: (postBody.query.filter as ConditionBlock).conditions.concat(filterObject),
          });
          postBody.query.filter = filterConditonBlock;
        } else {
          postBody.query.filter = createConditionBlock({ conditions: filterObject.conditions });
        }
      } else {
        postBody.query.filter = filterObject;
      }
    } else {
      postBody.query = {
        filter: filterObject,
      };
    }

    if (aggregationFilters && aggregationFilters.length > 0 && postBody.aggregations) {
      postBody.aggregations = postBody.aggregations.concat(aggregationFilters);
    }
  }

  return search(searchIndex.id, tenantId, postBody, urlParamAugmentations).then(searchResults =>
    mapSearchPreviewProductListDtoToModel(
      augmentedPostBody && augmentedPostBody.items.skip ? augmentedPostBody.items.skip : skip,
      augmentedPostBody && augmentedPostBody.items.top ? augmentedPostBody.items.top : top,
      searchResults,
      searchIndex,
      selectedRefinements,
      createSearchUrl(searchIndex.id, tenantId, urlParamAugmentations),
      postBody,
    ),
  );
}

export function getFieldValueSuggestions(
  tenantId: string,
  searchIndex: SearchIndex,
  searchInstanceId: string,
  facetableStringFields: string[],
): Promise<FieldValueSuggestions[]> {
  const facets: Facet[] = facetableStringFields.map(fieldName => {
    return { _type: 'Facet', field: fieldName };
  });

  let postBody: SearchPostRequest | null = createFieldValueSuggestionsPostBody(searchInstanceId, searchIndex, facets);
  return !!postBody
    ? search(searchIndex.id, tenantId, postBody).then(mapSearchResultToFieldValueSuggestions)
    : Promise.resolve([]);
}
