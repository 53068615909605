import React, { useState, useMemo, useCallback } from 'react';
import {
  classNamesFunction,
  Stack,
  IconButton,
  PrimaryButton,
  DefaultButton,
  SelectionMode,
  Dropdown,
  CheckboxVisibility,
  Persona,
  PersonaSize,
  PersonaInitialsColor,
  TooltipHost,
  TooltipOverflowMode,
  IColumn,
} from 'office-ui-fabric-react';
import {
  CookDatasetEditorStyleProps,
  CookDatasetEditorStyles,
  CookDatasetEditorProps,
} from './CookDatasetEditor.types';
import { generateUniqueIdentifier } from '../../../../utils';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { UserEmailMapper, ImageStatus } from '../../../../store/types/userList.d';
import { CookingJob } from '../../../../store/types/customML/cookingJob.d';
import { ModelJobStatus } from '../../../../store/types/customML/searchModel.d';
import { createInitialCookingJob } from '../../../../store/reducers/customML/cookingJobReducer';
import {
  DetailsList,
  ColumnWithPriority,
  ColumnPriority,
  SortDataType,
} from '../../../../components/common/DetailsList';
import { TitledContentCard } from '../../../../components/common/ContentCard';
import { updateTenantUserImage } from '../../../../store/actions/userListActions';
import { useRoleEnvironmentList } from '../../../../store/hooks/use-list/useRoleEnvironmentList';

export const getClassNames = classNamesFunction<CookDatasetEditorStyleProps, CookDatasetEditorStyles>();

export function CookDatasetEditorBase(props: CookDatasetEditorProps) {
  const { styles, theme, t, onDismiss, onCookDatasetList, datasetList } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const dispatch = useDispatch();
  const environmentList = useRoleEnvironmentList();
  const userEmail = useSelector<string>(state => state.user.email);
  const userEmailMapper = useSelector<UserEmailMapper>(state => state.userList.userEmailMapper);

  const [editableCookingJobList, setEditableCookingJobList] = useState<CookingJob[]>(() =>
    datasetList.map(dataset => ({
      ...createInitialCookingJob(),
      key: generateUniqueIdentifier(),
      email: userEmail,
      name: dataset.name,
      inputDatasetKey: dataset.key,
      status: ModelJobStatus.NotStarted,
      description: dataset.description,
    })),
  );

  const [selectedCookingJobKeys, setSelectedCookingJobKeys] = useState<string[]>([]);

  const isInvalidForm = () =>
    selectedCookingJobKeys.length === 0 ||
    !!editableCookingJobList.find(
      cookingJob => selectedCookingJobKeys.includes(cookingJob.key) && !!!cookingJob.indexId,
    );

  const onRenderCookingJobName = useCallback(
    (item?: CookingJob, index?: number, column?: IColumn) =>
      column &&
      item && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.name}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.name}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const updateCookingJob = (cookingJob: CookingJob) =>
    setEditableCookingJobList(cookingJobList => {
      const updatedCookingJobList = [...cookingJobList];
      const updatedJobIndex = updatedCookingJobList.findIndex(job => job.key === cookingJob.key);
      if (updatedJobIndex !== -1) {
        updatedCookingJobList[updatedJobIndex] = cookingJob;
      }

      return updatedCookingJobList;
    });

  const onRenderCookingJobEnvironment = useCallback(
    (cookingJob: CookingJob) => (
      // This is a workaround to set target.tagName to "BUTTON" and disable auto selection on input
      <button style={{ border: 'none', outline: 'none', background: 'unset' }}>
        <Dropdown
          required
          key={cookingJob.key}
          calloutProps={{
            styles: classNames.subComponentStyles.callout,
          }}
          className={classNames.dropdown}
          selectedKey={cookingJob.indexId ? cookingJob.indexId : undefined}
          onChange={(ev, option) => option && updateCookingJob({ ...cookingJob, indexId: option.key.toString() })}
          placeholder={t('custom-ml.cook-list.th-environment-placeholder')}
          options={environmentList.map(environment => ({
            key: environment.indexId,
            text: environment.name,
            disabled: !!!environment.indexId,
          }))}
        />
      </button>
    ),
    [classNames.dropdown, classNames.subComponentStyles.callout, environmentList, t],
  );

  const onRenderOwnerPerosna = useCallback(
    (cookingJob: CookingJob) => {
      const user = userEmailMapper[cookingJob.email];
      if (user.email && user.imageStatus === ImageStatus.NotInitialized) {
        dispatch(updateTenantUserImage(cookingJob.email));
      }
      return (
        <Persona
          text={cookingJob.email}
          imageUrl={user.imageUrl}
          size={PersonaSize.size24}
          hidePersonaDetails={false}
          initialsColor={PersonaInitialsColor.darkBlue}
        />
      );
    },
    [dispatch, userEmailMapper],
  );

  const onSubmitCookingJobs = () => {
    const selectedCookingJobList = editableCookingJobList.filter(cookingJob =>
      selectedCookingJobKeys.includes(cookingJob.key),
    );
    onCookDatasetList(selectedCookingJobList);
    onDismiss();
  };

  const columns: ColumnWithPriority[] = useMemo(
    () => [
      {
        minWidth: 100,
        maxWidth: 150,
        key: 'job-name',
        fieldName: 'name',
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        name: t('custom-ml.cook-list.th-name'),
        onRender: onRenderCookingJobName,
      },
      {
        minWidth: 200,
        maxWidth: 300,
        key: 'job-environment',
        fieldName: 'environment',
        priority: ColumnPriority.Primary,
        name: t('custom-ml.cook-list.th-environment'),
        onRender: onRenderCookingJobEnvironment,
      },
      {
        minWidth: 200,
        maxWidth: 300,
        fieldName: 'email',
        key: 'dataset-owner',
        priority: ColumnPriority.Secondary,
        name: t('custom-ml.cook-list.th-owner'),
        onRender: onRenderOwnerPerosna,
      },
      {
        minWidth: 150,
        maxWidth: 200,
        fieldName: 'createdAt',
        key: 'dataset-create-date',
        priority: ColumnPriority.Tertiary,
        name: t('custom-ml.cook-list.th-upload-date'),
      },
    ],
    [onRenderCookingJobEnvironment, onRenderCookingJobName, onRenderOwnerPerosna, t],
  );

  return (
    <TitledContentCard
      styles={classNames.subComponentStyles.card}
      title={t('custom-ml.cook-list.cook-modal-title')}
      actions={<IconButton key="cancel" onClick={() => onDismiss()} iconProps={{ iconName: 'Cancel' }} />}
      body={
        <Stack tokens={{ childrenGap: 24 }}>
          <div className={classNames.subTitle}>{t('custom-ml.cook-list.modal-subtitle')}</div>
          <DetailsList
            isLoading={false}
            isLoadedAndEmpty={editableCookingJobList.length === 0}
            isHeaderVisible={false}
            items={editableCookingJobList}
            optionsItemProps={() => []}
            selectionProps={{
              isAllSelected: true,
              selectionMode: SelectionMode.multiple,
              checkboxVisibility: CheckboxVisibility.always,
              onSelectionChanged: (selection: string[]) => setSelectedCookingJobKeys(selection),
            }}
            responsiveColumns={{
              columns: columns,
              secondaryBreakPoint: 800,
              teritaryBreakPoint: 1156,
            }}
          />
        </Stack>
      }
      footer={
        <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 16 }}>
          <PrimaryButton key="create" disabled={isInvalidForm()} onClick={() => onSubmitCookingJobs()}>
            {t('custom-ml.jobs.cook')}
          </PrimaryButton>
          <DefaultButton key="cancel" onClick={() => onDismiss()}>
            {t('custom-ml.jobs.cancel')}
          </DefaultButton>
        </Stack>
      }
    />
  );
}
