import { useMemo } from 'react';
import { getKeylessConditionBlock } from '../../../common/utils/ConditionBlock';
import { BusinessRule } from '../../../../../store/types/customizations/businessRule';

export const useKeylessBusinessRule = (businessRule: BusinessRule): BusinessRule => {
  return useMemo(() => {
    return {
      ...businessRule,
      searchRequestCondition: businessRule.searchRequestCondition
        ? getKeylessConditionBlock(businessRule.searchRequestCondition)
        : undefined,
      filterCondition: businessRule.filterCondition
        ? getKeylessConditionBlock(businessRule.filterCondition)
        : undefined,
      promoteCondition: businessRule.promoteCondition
        ? {
            boost: businessRule.promoteCondition.boost,
            condition: getKeylessConditionBlock(businessRule.promoteCondition.condition),
          }
        : undefined,
      demoteCondition: businessRule.demoteCondition
        ? {
            boost: businessRule.demoteCondition.boost,
            condition: getKeylessConditionBlock(businessRule.demoteCondition.condition),
          }
        : undefined,
    };
  }, [businessRule]);
};
