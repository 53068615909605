import { Stack, classNamesFunction } from 'office-ui-fabric-react';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  setIndexProductCount,
  setIndexAvgDPS,
  setIndexDocumentsPerSecond,
  setLatencyPerDocument,
  setDocumentsBreakDown,
  setProvidedDocuments,
  setFeaturesCoverage,
  setKeyLevelError,
} from '../../../../store/actions/insightsAction';
import { DocumentsPerSecond } from './DocumentsPerSecond';
import { DocumentsBreakDown } from './DocumentsBreakDown';
import { ProductsCountChart } from './ProductsCountChart';
import { LatencyPerDocument } from './LatencyPerDocument';
import { ProvidedDocuments } from './ProvidedDocuments';
import { FeatureCoverage } from './FeatureCoverage/FeatureCoverage';
import { InsightsBaseProps, InsightsStyleProps, InsightsStyles } from './Insights.types';
import { MetricCards } from './MetricCards';
import { isProductionEnvironment } from '../../../../guards/EnvironmentGuard/utils';

const getClassNames = classNamesFunction<InsightsStyleProps, InsightsStyles>();

export const InsightsBase = (props: InsightsBaseProps) => {
  const { t, className, styles, theme } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [latencyPercentile, setLatencyPercentile] = useState(75);

  const [productCountSpan, setProductCountSpan] = useState('P7D');
  const [productCountInterval, setProductCountInterval] = useState('1d');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIndexAvgDPS('PT1H')); //Average product updates per second
    dispatch(setIndexProductCount('P7D', '1d')); //Product count
    dispatch(setIndexDocumentsPerSecond('PT1H', '5m')); //Average product updates per second
    dispatch(setLatencyPerDocument('P1D', '1h', latencyPercentile)); //75th percentile latency update (ms)
    dispatch(setDocumentsBreakDown('P1D', '1h')); //Products update status breakdown
    dispatch(setProvidedDocuments('P1D', '1h')); //Requested product updates
    dispatch(setFeaturesCoverage('P1D'));
    if (!isProductionEnvironment()) {
      dispatch(setKeyLevelError('P1D'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={classNames.root}>
      <Stack className={classNames.customCard} tokens={{ childrenGap: '30px' }}>
        <div className={classNames.chartsWithMetrics}>
          <MetricCards productCountInterval={productCountInterval} productCountSpan={productCountSpan} />

          <DocumentsBreakDown hasTimeSelector />
        </div>

        <div className={classNames.chartsContainer}>
          <ProductsCountChart
            hasTimeSelector
            onTimeSelectorChange={(timespan, interval) => {
              setProductCountSpan(timespan);
              setProductCountInterval(interval);
            }}
          />
          <ProvidedDocuments hasTimeSelector />
        </div>
        <div className={classNames.chartsContainer}>
          <DocumentsPerSecond hasTimeSelector />
          <LatencyPerDocument
            hasTimeSelector
            currentlyShowingPercentile={latencyPercentile}
            onPercentileChange={(percentileValue: number) => setLatencyPercentile(percentileValue)}
          />
        </div>

        <FeatureCoverage hasTimeSelector={false} />

        <Stack.Item className={classNames.footer}>{t('insights.index-card-footer')}</Stack.Item>
      </Stack>
    </div>
  );
};
