import React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import { MetricCard } from '../Common/MetricCard';
import {
  SearchTrafficMetricCardsProps,
  SearchTrafficMetricCardsStyleProps,
  SearchTrafficMetricCardsStyles,
} from './SearchTrafficMetricCards.types';
import { useSelector } from '../../../../store/hooks';

const getClassNames = classNamesFunction<SearchTrafficMetricCardsStyleProps, SearchTrafficMetricCardsStyles>();

export const SearchTrafficMetricCardsBase = (props: SearchTrafficMetricCardsProps) => {
  const { styles, theme, className, componentRef, t } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });
  const totalSearchRequests = useSelector(state => state.analytics.totalSearchRequests);
  const isTotalSearchRequestsLoading = useSelector(state => state.analytics.isTotalSearchRequestsLoading);

  const totalDistinctQueries = useSelector(state => state.analytics.totalDistinctQueries);
  const isTotalDistinctQueriesLoading = useSelector(state => state.analytics.isTotalDistinctQueriesLoading);

  const totalSearchRequestsWithNoResults = useSelector(state => state.analytics.totalSearchRequestsWithNoResults);
  const isTotalSearchRequestsWithNoResultsLoading = useSelector(
    state => state.analytics.isTotalSearchRequestsWithNoResultsLoading,
  );

  return (
    <div ref={componentRef} className={classNames.root}>
      <MetricCard
        styles={classNames.subComponentStyles.metricCard}
        title={t('analytics.search-metrics.total-queries')}
        value={totalSearchRequests}
        isLoading={isTotalSearchRequestsLoading}
        borderColor="#0078D4"
      />
      <MetricCard
        styles={classNames.subComponentStyles.metricCard}
        className={classNames.middleCards}
        title={t('analytics.search-metrics.distinct-queries')}
        value={totalDistinctQueries.percentage}
        isPercentageValue={isNaN(totalDistinctQueries.percentage) ? false : true}
        isLoading={isTotalDistinctQueriesLoading}
        borderColor="#00188F"
      />
      <MetricCard
        styles={classNames.subComponentStyles.metricCard}
        className={classNames.middleCards}
        title={t('analytics.search-metrics.no-result-count')}
        value={totalSearchRequestsWithNoResults.percentage}
        isPercentageValue={isNaN(totalSearchRequestsWithNoResults.percentage) ? false : true}
        isLoading={isTotalSearchRequestsWithNoResultsLoading}
        borderColor="#EF6950"
      />
    </div>
  );
};
