import { DefaultButton, Icon, Stack, classNamesFunction } from 'office-ui-fabric-react';
import { NoItemsToDisplay, NoItemsToDisplayStyleProps, NoItemsToDisplayStyles } from './NoItemsToDisplay.types';

import React from 'react';

const getClassNames = classNamesFunction<NoItemsToDisplayStyleProps, NoItemsToDisplayStyles>();

export const NoItemsToDisplayBase = (props: NoItemsToDisplay) => {
  const { text, subText, actionProps, styles, className, theme, iconName } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const actionPropsInternal = !!actionProps ? (Array.isArray(actionProps) ? actionProps : [actionProps]) : [];
  return (
    <Stack className={classNames.root} tokens={{ childrenGap: 16 }}>
      <Icon iconName={iconName || 'EmptyListBackground'} />
      <div>{text}</div>
      <div className={classNames.noItemsSubText}>{subText}</div>
      <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ childrenGap: 12 }}>
        {actionPropsInternal.map((action, index) => (
          <DefaultButton key={index} onClick={action.onClick} text={action.buttonText} disabled={action.disabled} />
        ))}
      </Stack>
    </Stack>
  );
};
