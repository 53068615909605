import { Action } from 'redux';

export const SET_TENANTS_LIST = 'SET_TENANTS_LIST';
export const RESET_TENANTS_LIST = 'RESET_TENANTS_LIST';
export const LOADING_TENANTS_LIST = 'LOADING_TENANTS_LIST';
export const SET_ACTIVE_TENANT = 'SET_ACTIVE_TENANT';
export const RESET_ACTIVE_TENANT = 'RESET_ACTIVE_TENANT';
export const UPDATE_ACTIVE_TENANT = 'UPDATE_ACTIVE_TENANT';

export enum SearchScenario {
  Unknown = 'Unknown',
  Retail = 'Retail',
  Hotel = 'Hotel',
  Document = 'Document',
}

export const createEmptyTenant = () => {
  return {
    id: '',
    name: '',
    tier: '',
    regions: [],
    isOobe: false,
    isSandbox: false,
    products: [],
    features: [],
    rankers: [],
    searchScenario: SearchScenario.Unknown,
  };
};

export interface TenantIdMapper {
  [key: string]: Tenant;
}

export enum Feature {
  CustomML = 'customMLEnabled',
  GeoLocation = 'locationEnabled',
}

export enum Product {
  Unknown = 'Unknown',
  TextSearch = 'TextSearch',
  AutoSuggest = 'AutoSuggest',
  VisualSearch = 'VisualSearch',
  Recommendations = 'Recommendations',
  Ads = 'Ads',
}

export interface Tenant {
  id: string;
  name: string;
  tier: string;
  isOobe: boolean;
  isSandbox: boolean;
  regions: string[];
  products: Product[];
  features: Feature[];
  rankers: string[];
  searchScenario: SearchScenario;
}

export interface TenantsListState {
  isLoading: boolean;
  activeTenant: Tenant;
  tenantIdMapper: TenantIdMapper;
}

interface SetTenantsList extends Action<typeof SET_TENANTS_LIST> {
  tenants: Tenant[];
}

interface SetActiveTenant extends Action<typeof SET_ACTIVE_TENANT> {
  tenantId: string;
}

interface UpdateActiveTenant extends Action<typeof UPDATE_ACTIVE_TENANT> {
  tenant: Tenant;
}

export type TenantsListStateActionTypes =
  | SetTenantsList
  | SetActiveTenant
  | UpdateActiveTenant
  | Action<typeof RESET_TENANTS_LIST>
  | Action<typeof RESET_ACTIVE_TENANT>
  | Action<typeof LOADING_TENANTS_LIST>;
