import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { Loading } from '../Loading.base';

/* A higher order component that enhances loading component extracting loading boolean props from Redux store */
export function connectedLoading(selectPropsTextFromState: (state: AppState) => boolean) {
  const mapStateToProps = (state: AppState) => ({
    isLoading: selectPropsTextFromState(state),
  });
  return connect(mapStateToProps)(Loading);
}
