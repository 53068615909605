import React, { useMemo } from 'react';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { MultiLineChart, Line } from '../../../../components/common/charts/MultiLineChart';
import { ChartContentCard } from '../Common/ChartContentCard';
import { TickType } from '../../../../components/common/charts/common/AxisTickFormatter';
import { useMediaQuery } from 'react-responsive';
import { TooltipPayload, TooltipProps } from 'recharts';
import { ToolTipFormatter } from '../../../../components/common/charts/common/ToolTipFormatter';
import { AppState } from '../../../../store/reducers';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import { SearchLatencyStyleProps, SearchLatencyStyles, SearchLatencyBaseProps } from './SearchLatency.types';
import { Trans } from 'react-i18next';
import { AggregationInterval } from '../../../../restful-apis/dto/analytics/analytics';
import { SetSearchLatencyOverTime, SetSearchLatencyPercentile } from '../../../../store/actions/analyticsActions';
import { getAggregationInterval } from '../SearchAnalytics.utils';
import numeral from 'numeral';

const getClassNames = classNamesFunction<SearchLatencyStyleProps, SearchLatencyStyles>();

export const SearchLatencyBase = (props: SearchLatencyBaseProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  const isSearchLatencyOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isSearchLatencyOverTimeLoading,
  );
  const searchLatencyOverTime = useSelector((state: AppState) => state.analytics.searchLatencyOverTime);

  const searchLatencyPercentile = useSelector(state => state.analytics.searchLatencyPercentile);
  const regionsDistributionFilter = useSelector(state => state.analytics.regionsDistributionFilter);
  const responseCodesFilter = useSelector(state => state.analytics.responseCodesFilter);
  const searchInstancesFilter = useSelector(state => state.analytics.searchInstancesFilter);
  const searchResultsFilter = useSelector(state => state.analytics.searchResultsFilter);
  const startDate = useSelector(state => state.analytics.startDateFilter);
  const endDate = useSelector(state => state.analytics.endDateFilter);

  const largeScreen = useMediaQuery({ minWidth: 1400 });
  const mediumScreen = useMediaQuery({ maxWidth: 1399 });
  const smallScreen = useMediaQuery({ maxWidth: 960 });

  const getLegend = (percentileValue: number): JSX.Element => {
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 4 }}
        className={classNames.legendContainer}
        onClick={() => {
          if (!isSearchLatencyOverTimeLoading && percentileValue !== searchLatencyPercentile) {
            dispatch(SetSearchLatencyPercentile(percentileValue));
            dispatch(
              SetSearchLatencyOverTime(
                getAggregationInterval(startDate, endDate),
                percentileValue,
                responseCodesFilter.selectedValues.checkedValues,
                startDate,
                endDate,
                [],
                regionsDistributionFilter.selectedValues.checkedValues,
                searchResultsFilter.selectedValues.checkedValues,
                searchInstancesFilter.selectedValues.checkedValues,
              ),
            );
          }
        }}
      >
        <div
          className={classNames.legendIcon}
          style={{
            backgroundColor:
              searchLatencyPercentile === percentileValue && !isSearchLatencyOverTimeLoading
                ? theme!.palette.themePrimary
                : theme!.palette.neutralLight,
          }}
        />
        <div className={classNames.legendLabel}>
          {
            <Trans i18nKey="analytics.search-latency-chart.latency-percentile" values={{ percentileValue }}>
              <sup>th</sup> percentile
            </Trans>
          }
        </div>
      </Stack>
    );
  };

  const data = useMemo(
    () =>
      searchLatencyOverTime.series.map(seriesPoint => ({
        xValue: seriesPoint.date,
        yValues: [seriesPoint.value],
      })),
    [searchLatencyOverTime.series],
  );

  const yLines: Line[] = useMemo(
    () => [
      {
        name: t('analytics.search-latency-chart.title'),
        stroke: '#004578',
      },
    ],
    [t],
  );

  const dataToExport = useMemo(
    () =>
      data.map(dataPoint => ({
        date: dataPoint.xValue,
        ...dataPoint.yValues.reduce(
          (prevValue, currentValue, index) => ({ ...prevValue, [yLines[index].name]: currentValue }),
          {},
        ),
      })),
    [data, yLines],
  );

  return (
    <div className={classNames.root}>
      <ChartContentCard
        className={className}
        exportData={{ fileName: t('analytics.search-latency-chart.export-file-name'), data: dataToExport }}
        chart={
          <>
            <Stack
              horizontal
              verticalAlign={'center'}
              className={classNames.legendsContainer}
              horizontalAlign={'center'}
              tokens={{ childrenGap: 12 }}
              wrap
            >
              {getLegend(25)}
              {getLegend(50)}
              {getLegend(75)}
              {getLegend(90)}
              {getLegend(95)}
            </Stack>
            <MultiLineChart
              points={data}
              minWidth={300}
              minHeight={220}
              xLine={{
                name: 'Date',
                stroke: 'grey',
              }}
              ylines={yLines}
              legendProps={{ legendContent: [...yLines].reverse() }}
              xAxisProps={{
                interval: smallScreen
                  ? data.length > 14
                    ? searchLatencyOverTime.interval + 3
                    : data.length > 8
                    ? searchLatencyOverTime.interval + 2
                    : searchLatencyOverTime.interval + 1
                  : mediumScreen
                  ? data.length > 14
                    ? searchLatencyOverTime.interval + 2
                    : data.length > 8
                    ? searchLatencyOverTime.interval + 1
                    : searchLatencyOverTime.interval
                  : searchLatencyOverTime.interval,
                tickProps: {
                  tickType: TickType.DateTime12Hrs,
                  fontSize: 12,
                  blockRotation: largeScreen || (!smallScreen && data.length < 14),
                  rotationBreakPoint: 7,
                  rotatedFontSize: 10,
                },
              }}
              toolTipContent={(props: TooltipProps) => {
                let newProps: TooltipProps = props;
                if (props.payload && props.payload.length) {
                  newProps = {
                    ...newProps,
                    payload: props.payload.map(
                      (p): TooltipPayload =>
                        p.payload && p.payload.yValues
                          ? {
                              ...p,
                              payload: {
                                ...p.payload,
                                yValues: p.payload.yValues.map((value: number) => numeral(value).format('0[.][00]')),
                              },
                            }
                          : p,
                    ),
                  };
                }
                return <ToolTipFormatter toolTipProps={newProps} />;
              }}
              isLoading={isSearchLatencyOverTimeLoading}
            />
          </>
        }
        title={t('analytics.search-latency-chart.title')}
        isLoadedAndEmpty={!isSearchLatencyOverTimeLoading && !searchLatencyOverTime.series.length}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
          aggInterval: AggregationInterval,
          percentile,
        ) =>
          dispatch(
            SetSearchLatencyOverTime(
              aggInterval,
              percentile,
              responseCode,
              start,
              end,
              markets,
              regions,
              searchResultsStatus,
              searchInstances,
            ),
          )
        }
      />
    </div>
  );
};
