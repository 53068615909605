import React, { useCallback, useEffect } from 'react';
import {
  ToastNotificationBaseProps,
  ToastNotificationStyleProps,
  ToastNotificationStyles,
} from './ToastNotification.types';
import { Stack, Icon, classNamesFunction, IconButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { ToastNotification, ToastNotificationStatus } from '../../store/types/toastNotification.d';
import { useDispatch, useSelector } from '../../store/hooks';
import { ToastContainer, ToastOptions, toast } from 'react-toastify';
import { AppState } from '../../store/reducers';
import { removeToast } from '../../store/actions/toastNotificationActions';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationStatus } from '../../store/types/notificationCenter.d';
import { setIsNotificationsPanelOpen } from '../../store/actions/notificationCenterActions';
import { MbcClamp } from '../common/MbcClamp';

const getClassNames = classNamesFunction<ToastNotificationStyleProps, ToastNotificationStyles>();

export const ToastNotificationBase = (props: ToastNotificationBaseProps) => {
  const { theme, className, styles, componentRef } = props;
  const dispatch = useDispatch();

  const toastNotification = useSelector((state: AppState) => state.toastNotification.toastNotificationContent);
  const isNotificationPanelOpen = useSelector((state: AppState) => state.notificationsCenter.isPanelOpen);

  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
    notificationStatus: toastNotification.status,
  });

  const getNotificationItem = useCallback(
    (toastNotification: ToastNotification): JSX.Element => {
      return (
        <div
          ref={componentRef}
          className={classNames.root}
          onClick={() => {
            if (!!toastNotification.hasAction) {
              toast.dismiss();
              dispatch(setIsNotificationsPanelOpen(true));
            }
          }}
        >
          <Stack gap={12}>
            <Stack
              horizontal
              key={toastNotification.id}
              verticalAlign={'center'}
              className={classNames.titleStackContainer}
            >
              <div className={classNames.titleContainer}>
                <Icon
                  iconName={
                    toastNotification.status === ToastNotificationStatus.Info
                      ? 'InfoSolid'
                      : toastNotification.status === NotificationStatus.Succeeded
                      ? 'SkypeCircleCheck'
                      : toastNotification.status === NotificationStatus.Failed
                      ? 'StatusErrorFull'
                      : undefined
                  }
                  className={classNames.statusIcon}
                />
                <Stack horizontal verticalAlign={'center'}>
                  {toastNotification.status === NotificationStatus.InProgress && <Spinner size={SpinnerSize.small} />}
                  <div className={classNames.title}>{toastNotification.title}</div>
                </Stack>
              </div>
              <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                className={classNames.closeIcon}
                onClick={() => {
                  dispatch(removeToast(toastNotification.id));
                  toast.dismiss(toastNotification.id);
                }}
              />
            </Stack>
            {!!toastNotification.subtitle && <div className={classNames.subtitle}>{toastNotification.subtitle}</div>}
            {!!toastNotification.errorMessage && toastNotification.status === NotificationStatus.Failed && (
              <MbcClamp text={toastNotification.errorMessage} className={classNames.subtitle} lines={3} />
            )}
          </Stack>
        </div>
      );
    },
    [
      classNames.closeIcon,
      classNames.root,
      classNames.statusIcon,
      classNames.subtitle,
      classNames.title,
      classNames.titleContainer,
      classNames.titleStackContainer,
      componentRef,
      dispatch,
    ],
  );

  const getToastOptions = useCallback((id: string): ToastOptions => {
    return {
      autoClose: 6000,
      position: 'top-right',
      toastId: id,
      closeButton: false,
    };
  }, []);

  const getToast = useCallback(() => {
    if (toastNotification.title !== '' && !isNotificationPanelOpen) {
      if (!toast.isActive(toastNotification.id)) {
        dispatch(removeToast(toastNotification.id));
        toast(getNotificationItem(toastNotification), getToastOptions(toastNotification.id));
      } else {
        toast.update(toastNotification.id, {
          ...getToastOptions(toastNotification.id),
          render: getNotificationItem(toastNotification),
        });
        dispatch(removeToast(toastNotification.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getNotificationItem, getToastOptions, toastNotification]);

  useEffect(() => {
    getToast();
  }, [getToast, toastNotification]);

  return (
    <div className={classNames.root}>
      <ToastContainer hideProgressBar enableMultiContainer={false} className={classNames.toastContainer} />
    </div>
  );
};
