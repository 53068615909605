import { BaseRequestInterceptor } from './BaseRequestInterceptor';
import { FetchRequest } from '../Types';
import { AddHeader } from '../Utils';
import { AdminTokensService } from '../../../../services/tokens.service';

export class DevopsServiceInterceptor extends BaseRequestInterceptor {
  constructor() {
    super(/^https:\/\/commerce.bing.com\/api\/devops\/v1\/.*$/);
  }

  PerformAction(request: FetchRequest): Promise<FetchRequest> {
    return AdminTokensService.getToken(request.authInfo).then(adminToken => {
      return AddHeader(request, 'Authorization', `Bearer ${adminToken}`);
    });
  }
}
