import { ServiceException } from '../Types';
import { BaseResponseInterceptor } from './BaseResponseInterceptor';
import {
  IsAdminServiceException,
  ExtractAdminExceptionMessage,
  IsIngestionServiceException,
  ExtractIngestionExceptionMessage,
} from '../Utils';

export class AdminServiceInterceptor extends BaseResponseInterceptor {
  constructor() {
    super(/^https:\/\/commerce.bing.com\/(ca\/)?api\/admin\/.*$/);
  }

  ExtractExceptionMessage(exception: ServiceException): string {
    if (IsAdminServiceException(exception)) {
      return ExtractAdminExceptionMessage(exception);
    }
    // admin service api just pass exceptions from ingestion service failed requests (requests through admin service, such as createIndex) as it is
    if (IsIngestionServiceException(exception)) {
      return ExtractIngestionExceptionMessage(exception);
    }
    return super.ExtractExceptionMessage(exception);
  }
}
