import React, { useEffect } from 'react';
import { AppState } from '../../store/reducers';
import { useSelector } from '../../store/hooks';
import { PageTemplate } from '../../components/common/PageTemplate';
import { SideNavMode } from '../../components/common/PageTemplate/PageTemplate.types';

import { useTranslation } from 'react-i18next';
import { EnvironmentList } from '../../components/EnvironmentList';
import { TutorialsId } from '../../components/Tutorials';
import { AvailableTutorials } from '../../store/types/tutorial.d';
import { startTutorial } from '../../store/actions/tutorialActions';
import { useDispatch } from 'react-redux';

export const EnvironmentListBase = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const userName = useSelector((state: AppState) => (!!state.user.name ? state.user.name : state.user.email));
  const activeTenant = useSelector((state: AppState) => state.tenantsList.activeTenant);
  const completedTutorials = useSelector((state: AppState) => state.user.sandBoxDetails.completedTutorials);
  const isEnvironmentListLoading = useSelector((state: AppState) => state.environmentList.isLoading);

  useEffect(() => {
    if (!completedTutorials.includes(TutorialsId.EnvironmentList) && !isEnvironmentListLoading) {
      dispatch(startTutorial(AvailableTutorials.EnvironmentList));
    }
  }, [completedTutorials, dispatch, isEnvironmentListLoading]);

  return (
    <PageTemplate
      pageHeaderProps={{
        title: <div data-clarity-mask="True">{t('environment-list.page-header', { userName: userName })}</div>,
        titleCaption: activeTenant.name,
      }}
      sideNavMode={SideNavMode.None}
      sideNavOnClick={() => {}}
      onRenderContent={() => <EnvironmentList />}
    />
  );
};
