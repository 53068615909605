import { ContentCardHeaderStyles, ContentCardHeaderStyleProps } from './ContentCardHeader.types';

export const styles = (props: ContentCardHeaderStyleProps): ContentCardHeaderStyles => ({
  root: [
    'mbc-card-header',
    {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
    },
  ],
  title: [
    'mbc-card-title',
    {
      fontSize: 14,
      lineHeight: 28,
      fontWeight: 600,
      alignSelf: 'center',
    },
  ],
  subTitle: [
    'mbc-card-subtitle',
    {
      gridColumn: '1 / span 2',
      fontSize: 12,
      color: props.theme.palette.neutralSecondary,
    },
  ],
  actions: [
    'mbc-card-actions',
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      selectors: {
        '.ms-Button': {
          marginLeft: 16,
          backgroundColor: 'unset',
        },
        '.ms-SearchBox': {
          marginLeft: 16,
        },
      },
    },
  ],
});
