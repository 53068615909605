import HttpClient from './http-client/httpClient';
import { RoleList as RoleListDTO } from './dto/role/roleList';
import { Role as RoleModel } from '../store/types/roleList';
import { mapRoleListDtoToSingleModel, mapRoleModelToDTO, mapRoleListDtoToModel } from './mappers/roleMapper';

function getAdminBaseUri(tenantId: string): string {
  return `https://commerce.bing.com/api/admin/v2/tenants/${tenantId}`;
}

export function createOrUpdateEnvironmentRoles(
  tenantId: string,
  environmentId: string,
  roleList: string[],
): Promise<RoleModel[]> {
  return HttpClient.put<RoleListDTO>({
    url: `${getAdminBaseUri(tenantId)}/environments/${environmentId}/roles`,
    body: { roles: roleList },
    supressNotificationOnFailure: true,
  }).then(mapRoleListDtoToModel);
}

/**
 * Constructs a get http request that returns the instances.
 *
 * @param tenantId       The instances tenant.
 *
 * @return The instances, with the response body as an `Instance` array.
 */
export function getUserRoles(tenantId: string): Promise<RoleModel[]> {
  return HttpClient.get<RoleListDTO>({ url: `${getAdminBaseUri(tenantId)}/roles` }).then(mapRoleListDtoToModel);
}

/**
 * Constructs a post http request that returns the response in `Instance` model.
 *
 * @param tenantId      The instance tenant.
 * @param instance      The DTO body to send with the request.
 *
 * @return An `Instance` model of the response, with the response body as an `Instance` DTO.
 */
export function createRole(tenantId: string, role: RoleModel): Promise<RoleModel> {
  const roleDTO = mapRoleModelToDTO(role);
  return HttpClient.post<RoleListDTO>({
    url: `${getAdminBaseUri(tenantId)}/roles`,
    body: roleDTO,
    supressNotificationOnFailure: true,
  }).then(mapRoleListDtoToSingleModel);
}

/**
 * Constructs a post http request that returns the response in `Instance` model.
 *
 * @param tenantId      The instance tenant.
 * @param instance      The DTO body to send with the request.
 *
 * @return An `Instance` model of the response, with the response body as an `Instance` DTO.
 */
export function updateRole(tenantId: string, role: RoleModel): Promise<RoleModel> {
  const roleDTO = mapRoleModelToDTO(role);
  return HttpClient.put<RoleListDTO>({
    url: `${getAdminBaseUri(tenantId)}/roles/${role.id}`,
    body: roleDTO,
    supressNotificationOnFailure: true,
  }).then(mapRoleListDtoToSingleModel);
}

/**
 * Constructs a delete http request that returns the no response.
 *
 * @param tenantId      The tenant's user.
 * @param role      The DTO body to send with the request.
 *
 * @return void.
 */
export function deleteRole(tenantId: string, roleId: string): Promise<RoleModel> {
  return HttpClient.delete<RoleListDTO>({
    url: `${getAdminBaseUri(tenantId)}/roles/${roleId}`,
    body: {},
    supressNotificationOnFailure: true,
  }).then(mapRoleListDtoToSingleModel);
}
