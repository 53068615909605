import React, { useState, useCallback } from 'react';
import {
  classNamesFunction,
  PrimaryButton,
  Stack,
  Panel,
  CheckboxVisibility,
  SearchBox,
  ActionButton,
  SelectionMode,
} from 'office-ui-fabric-react';
import {
  SelectActionButtonStyleProps,
  SelectActionButtonStyles,
  SelectActionButtonProps,
  ListItem,
} from './SelectActionButton.types';
import { DetailsList } from '../DetailsList';
import { TreeItemView } from '../TreeView';

export const getClassNames = classNamesFunction<SelectActionButtonStyleProps, SelectActionButtonStyles>();

export function SelectActionButtonBase(props: SelectActionButtonProps) {
  const {
    t,
    styles,
    theme,
    className,
    items,
    header,
    responsiveColumns,
    isLoading,
    itemLabel,
    actionLabel,
    onSelectionChange,
    defaultSelectedItem,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [isOpen, openPanel] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<ListItem | undefined>(() => {
    const itemIndex = items.findIndex(item => item.key === defaultSelectedItem);
    return itemIndex !== -1 ? items[itemIndex] : undefined;
  });
  const onChangeSearchQuery = (query: string = '') => setSearchQuery(query);
  const getDisplayedItems = () =>
    items.filter(item => !searchQuery || item.name.toLocaleLowerCase().startsWith(searchQuery.toLocaleLowerCase()));

  const onDismissSelection = useCallback(() => {
    openPanel(false);
    setSearchQuery('');
    setSelectedItem(undefined);
  }, []);

  const onRemoveItem = useCallback(() => {
    onSelectionChange(undefined);
    setSelectedItem(undefined);
  }, [onSelectionChange]);

  const onSelectionChangeInternal = useCallback(
    (selection: string[]) => {
      if (selection.length === 1) {
        const newSelectedItemKey = selection[0];
        setSelectedItem(items.find(item => item.key === newSelectedItemKey));
        onSelectionChange(newSelectedItemKey);
      }
    },
    [items, onSelectionChange],
  );

  return (
    <div className={classNames.root}>
      {!isOpen && !!selectedItem && (
        <TreeItemView
          header={itemLabel}
          item={{
            header: {
              width: 250,
              iconName: 'Delete',
              title: selectedItem.name,
              onIconClick: onRemoveItem,
            },
          }}
        />
      )}
      {!isOpen && !selectedItem && (
        <ActionButton className={classNames.action} iconProps={{ iconName: 'Add' }} onClick={() => openPanel(true)}>
          {actionLabel}
        </ActionButton>
      )}
      <Panel
        isOpen={isOpen}
        onRenderNavigation={() => (
          <Stack horizontalAlign={'start'} verticalAlign={'center'} tokens={{ padding: '12px 24px', childrenGap: 12 }}>
            <ActionButton iconProps={{ iconName: 'Back' }} onClick={onDismissSelection}>
              {t('common.back')}
            </ActionButton>
            <div className={classNames.header}>{header}</div>
            <SearchBox
              className={classNames.searchBox}
              onSearch={query => onChangeSearchQuery(query)}
              onChange={(ev, query) => onChangeSearchQuery(query)}
              placeholder={t('common.search-placeholder')}
            />
          </Stack>
        )}
        onRenderFooter={() => (
          <Stack horizontal horizontalAlign={'end'} grow tokens={{ padding: 32 }}>
            <PrimaryButton key="upload" onClick={() => openPanel(false)}>
              {t('common.add')}
            </PrimaryButton>
          </Stack>
        )}
      >
        <DetailsList
          styles={classNames.subComponentStyles.list}
          isHeaderVisible={false}
          isLoading={isLoading}
          isLoadedAndEmpty={!isLoading && items.length === 0}
          items={getDisplayedItems()}
          optionsItemProps={() => []}
          selectionProps={{
            isAllSelected: false,
            selectionMode: SelectionMode.single,
            checkboxVisibility: CheckboxVisibility.always,
            onSelectionChanged: onSelectionChangeInternal,
          }}
          responsiveColumns={responsiveColumns}
        />
      </Panel>
    </div>
  );
}
