import { BoostCondition, ConditionBlock } from '../../../../utils/customizations/conditions';
import { QueryTesterDetails, GeneralSettings } from '../QueryTester.types';
import { Banner } from '../../../../store/types/customizations/businessRule';
import { mapSearchBannerToBannerModel } from '../../../../restful-apis/mappers/customizations/businessRuleMapper';
import { SearchBanner } from '../../../../restful-apis/dto/customizations/businessRules';
import { mapConditionBlockDtoToModel } from '../../../../restful-apis/mappers/customizations/conditionMapper';
import { SearchPostRequest } from '../../../../utils/customizations/search/searchRequest';

export const mapPostBodyToDetails = (postBody: SearchPostRequest): QueryTesterDetails => {
  let generalSettings: GeneralSettings = { selectedSearchInstance: '' };
  let promoteCondition: BoostCondition | undefined = undefined;
  let demoteCondition: BoostCondition | undefined = undefined;
  let filterCondition: ConditionBlock | undefined = undefined;
  let banner: Banner | undefined = undefined;

  generalSettings.selectedSearchInstance = postBody.searchInstanceId || '';
  generalSettings.alterationEnabled = postBody.query.alteration === undefined ? true : postBody.query.alteration;
  generalSettings.sortBy = postBody.items.orderBy;

  const filter = postBody.query.filter as ConditionBlock;
  if (!!filter && filter.conditions && filter.conditions.length > 0) {
    filterCondition = mapConditionBlockDtoToModel(postBody.query.filter);
  }

  const promotion =
    postBody.query.boosts &&
    postBody.query.boosts.filter(
      (boostCondition: BoostCondition) => boostCondition.boost && boostCondition.boost > 0,
    )[0];

  promoteCondition = !!promotion
    ? { boost: promotion.boost, condition: mapConditionBlockDtoToModel(promotion.condition) }
    : undefined;

  const demotion =
    postBody.query.boosts &&
    postBody.query.boosts.filter((boostCondition: any) => boostCondition.boost && boostCondition.boost < 0)[0];

  demoteCondition = !!demotion
    ? { boost: demotion.boost, condition: mapConditionBlockDtoToModel(demotion.condition) }
    : undefined;

  banner = postBody.banners && mapSearchBannerToBannerModel(postBody.banners[0] as SearchBanner);

  return {
    generalSettings: generalSettings,
    promoteCondition: promoteCondition,
    demoteCondition: demoteCondition,
    filterCondition: filterCondition,
    banner: banner,
  };
};
