import HttpClient from './http-client/httpClient';
import {
  GetBlockedSuggestionsResponse,
  AddBlockedSuggestionResponse,
  ServingResponse,
  AutoSuggestIndexResponse,
  RemoveBlockedSuggestionResponse,
} from './dto/autosuggest/autosuggest';
import {
  mapBlockSuggestionModelToDTO,
  mapServingSuggestionsDtoToModel,
  mapBlockedSuggestionsDtoToModel,
} from './mappers/autosuggestMapper';
import { SuggestionItem, BlockedSuggestionModel } from '../store/types/autosuggest.d';

function getBaseUri(tenantId: string, indexId: string): string {
  return `https://commerce.bing.com/api/autosuggestauthoring/v1/${tenantId}/indexes/${indexId}/blocking`;
}

function getAutoSuggestIndexBaseUri(tenantId: string, indexId: string) {
  return `https://commerce.bing.com/api/autosuggestauthoring/v1/${tenantId}/indexes/${indexId}`;
}

function getServingBaseUri(tenantId: string, indexId: string, query: string) {
  return `https://commerce.bing.com/api/autosuggest/v1/${tenantId}/indexes/${indexId}?q=${query}`;
}

export function getBlockedSuggestions(tenantId: string, indexId: string): Promise<BlockedSuggestionModel[]> {
  return HttpClient.get<GetBlockedSuggestionsResponse>({
    url: getBaseUri(tenantId, indexId),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(response => {
    return mapBlockedSuggestionsDtoToModel(response);
  });
}

export function addBlockedSuggestion(
  tenantId: string,
  indexId: string,
  submittedBlockedSuggestion: BlockedSuggestionModel,
): Promise<AddBlockedSuggestionResponse> {
  return HttpClient.post<AddBlockedSuggestionResponse>({
    url: getBaseUri(tenantId, indexId),
    body: mapBlockSuggestionModelToDTO(submittedBlockedSuggestion),
    authInfo: { tenantId: tenantId, indexId: indexId },
  });
}

export function getServingSuggestions(tenantId: string, indexId: string, query: string): Promise<SuggestionItem[]> {
  return HttpClient.get<ServingResponse>({
    url: getServingBaseUri(tenantId, indexId, query),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(res => mapServingSuggestionsDtoToModel(res));
}

export function getLastUpdateTime(tenantId: string, indexId: string): Promise<string> {
  return HttpClient.get<AutoSuggestIndexResponse>({
    url: getAutoSuggestIndexBaseUri(tenantId, indexId),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(res => {
    return res.index.latestTrieTimestamp;
  });
}

export function removeBlockedSuggestion(tenantId: string, indexId: string, id: string) {
  return HttpClient.delete<RemoveBlockedSuggestionResponse>({
    url: getBaseUri(tenantId, indexId),
    body: {
      ids: [id],
    },
    authInfo: { tenantId: tenantId, indexId: indexId },
  });
}
