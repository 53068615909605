import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { classNamesFunction, Stack, Dropdown, IDropdownOption, CommandButton } from 'office-ui-fabric-react';
import {
  ConditionBlockEditorProps,
  ConditionBlockEditorStyleProps,
  ConditionBlockEditorStyles,
  NewConditionItem,
} from './ConditionBlockEditor.types';
import { ConditionType, ConditionBlock, createConditionBlock } from '../../../../utils/customizations/conditions';
import { LogicalOperator } from '../../../../utils/customizations/operators';
import { ConditionEditor } from '../ConditionEditor';
import { isValidCondition } from '../utils/ConditionBlock';
import { generateUniqueIdentifier } from '../../../../utils';
import _ from 'lodash';

const getClassNames = classNamesFunction<ConditionBlockEditorStyleProps, ConditionBlockEditorStyles>();

export const ConditionBlockEditorBase = (props: ConditionBlockEditorProps) => {
  const {
    styles,
    theme,
    className,
    showSpecialFields,
    conditionBlock,
    excludedFieldTypes,
    newConditionTrigger,
    onConditionBlockUpdate,
  } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const currentConditionBlock = useMemo(() => (conditionBlock ? conditionBlock : createConditionBlock()), [
    conditionBlock,
  ]);

  const [newConditions, setNewConditions] = useState<NewConditionItem[]>(
    conditionBlock && conditionBlock.conditions.length > 0
      ? []
      : [{ id: generateUniqueIdentifier(), condition: undefined }],
  );

  const [targetNewCondition, settargetNewCondition] = useState<string | undefined>(
    newConditions.length > 0 ? newConditions[newConditions.length - 1].id : undefined,
  );

  const newConditionItemRefs: { [key: string]: React.RefObject<HTMLDivElement> } = useMemo(() => {
    return newConditions.reduce((acc: { [key: string]: React.RefObject<HTMLDivElement> }, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});
  }, [newConditions]);

  useEffect(() => {
    if (!!targetNewCondition) {
      const newConditionItemRef = newConditionItemRefs[targetNewCondition];
      newConditionItemRef.current && newConditionItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      settargetNewCondition(undefined);
    }
  }, [newConditionItemRefs, targetNewCondition]);

  const updateNewConditions = useCallback(
    (id, newCondition) => {
      const updatedConditions = _.cloneDeep(newConditions);

      if (newCondition && isValidCondition(newCondition)) {
        onConditionBlockUpdate({
          ...currentConditionBlock,
          conditions: currentConditionBlock.conditions.concat(newCondition),
        });
        setNewConditions(updatedConditions.filter(conditionItem => conditionItem.id !== id));
      } else {
        setNewConditions(
          updatedConditions.map(conditionItem =>
            conditionItem.id === id ? { ...conditionItem, condition: newCondition } : conditionItem,
          ),
        );
      }
    },
    [currentConditionBlock, newConditions, onConditionBlockUpdate],
  );

  const addNewCondition = useCallback(() => {
    const updatedConditions = _.cloneDeep(newConditions);
    const newConditionItem = { id: generateUniqueIdentifier(), condition: undefined };
    updatedConditions.push(newConditionItem);
    setNewConditions(updatedConditions);
    settargetNewCondition(newConditionItem.id);
  }, [newConditions]);

  const deleteNewCondition = useCallback(
    (id: string) => {
      const updatedConditions = _.cloneDeep(newConditions);
      setNewConditions(updatedConditions.filter(item => item.id !== id));
    },
    [newConditions],
  );

  useEffect(() => {
    newConditionTrigger && addNewCondition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConditionTrigger]);

  const onConditionBlockOperatorChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      onConditionBlockUpdate({
        ...currentConditionBlock,
        operator: option ? (option.key as LogicalOperator) : 'and',
      });
    },
    [currentConditionBlock, onConditionBlockUpdate],
  );

  const onConditionUpdate = useCallback(
    (conditionToUpdate: ConditionType, key: string) => {
      onConditionBlockUpdate({
        ...currentConditionBlock,
        conditions: currentConditionBlock.conditions.map(condition =>
          condition.key === key ? conditionToUpdate : condition,
        ),
      });
    },
    [currentConditionBlock, onConditionBlockUpdate],
  );

  const onConditionDelete = useCallback(
    (key: string) => {
      currentConditionBlock.conditions.length === 1 && addNewCondition();
      onConditionBlockUpdate({
        ...currentConditionBlock,
        conditions: currentConditionBlock.conditions.filter(condition => condition.key !== key),
      });
    },
    [addNewCondition, currentConditionBlock, onConditionBlockUpdate],
  );

  return (
    <Stack className={classNames.root}>
      <Stack tokens={{ childrenGap: '8' }}>
        <Stack horizontal className={classNames.header} verticalAlign={'center'} tokens={{ childrenGap: '8' }}>
          <Dropdown
            options={[{ key: 'and', text: 'All' }, { key: 'or', text: 'Any' }]}
            selectedKey={currentConditionBlock.operator}
            onChange={onConditionBlockOperatorChange}
          />
          <span>of the following conditions need to be met:</span>
        </Stack>
        <div
          className={
            newConditionTrigger === undefined
              ? classNames.scrollableConditionsContainer
              : classNames.conditionsContainer
          }
        >
          {currentConditionBlock.conditions.map((condition: ConditionType | ConditionBlock) => (
            <ConditionEditor
              key={condition.key}
              initialCondition={condition as ConditionType}
              showSpecialFields={showSpecialFields}
              excludedFieldTypes={excludedFieldTypes}
              onConditionUpdate={cnd => onConditionUpdate(cnd, condition.key)}
              onConditionDelete={() => onConditionDelete(condition.key)}
            />
          ))}

          {newConditions.map(newConditionItem => (
            <div key={newConditionItem.id} ref={newConditionItemRefs[newConditionItem.id]}>
              <ConditionEditor
                initialCondition={newConditionItem.condition}
                showSpecialFields={showSpecialFields}
                excludedFieldTypes={excludedFieldTypes}
                onConditionUpdate={cnd => updateNewConditions(newConditionItem.id, cnd)}
                onConditionDelete={
                  newConditions.length + currentConditionBlock.conditions.length > 1
                    ? () => deleteNewCondition(newConditionItem.id)
                    : undefined
                }
              />
            </div>
          ))}
        </div>
      </Stack>
      {newConditionTrigger === undefined && (
        <div className={classNames.addNewCondition}>
          <CommandButton iconProps={{ iconName: 'Add' }} text="Add new condition" onClick={() => addNewCondition()} />
        </div>
      )}
    </Stack>
  );
};
