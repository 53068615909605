import { getI18n } from 'react-i18next';
import { ActionSuccessHandler, ReduxCrudAction, ActionFailureHandler } from '../../CrudAction.types';
import {
  UPDATE_SEARCH_MODEL_DEPLOYMENT_JOB,
  SUBMIT_SEARCH_MODEL_DEPLOYMENT_JOB,
  CANCEL_SEARCH_MODEL_DEPLOYMENT_JOB_SUBMISSION,
} from '../../../../types/customML/searchModel.d';
import { DeploymentJob, ADD_DEPLOYMENT_JOB } from '../../../../types/customML/deploymentJob.d';
import { createDeploymentJob } from '../../../../../restful-apis/customML.api';

export const createDeploymentJobAction = (
  tenantId: string,
  searchModelKey: string,
  deploymentJob: DeploymentJob,
  onSuccess?: ActionSuccessHandler<DeploymentJob>,
  onFailure?: ActionFailureHandler<DeploymentJob>,
): ReduxCrudAction<DeploymentJob> => {
  const i18n = getI18n();
  return {
    request: createDeploymentJob(tenantId, searchModelKey, deploymentJob),
    preActionMapper: {
      type: SUBMIT_SEARCH_MODEL_DEPLOYMENT_JOB,
      key: searchModelKey,
    },
    postActionMapper: createdJob => [
      {
        type: ADD_DEPLOYMENT_JOB,
        deploymentJob: createdJob,
      },
      {
        type: UPDATE_SEARCH_MODEL_DEPLOYMENT_JOB,
        key: searchModelKey,
        deploymentJobKey: createdJob.key,
      },
    ],
    notification: {
      failure: () => ({
        title: i18n.t('custom-ml.job-card.deploy.req-failure', { name: deploymentJob.name }),
      }),
      inProgress: {
        title: i18n.t('custom-ml.job-card.deploy.req-inprogress', { name: deploymentJob.name }),
      },
      success: () => ({
        title: i18n.t('custom-ml.job-card.deploy.req-success', { name: deploymentJob.name }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: (response, dispatch) => {
      dispatch({
        type: CANCEL_SEARCH_MODEL_DEPLOYMENT_JOB_SUBMISSION,
        key: searchModelKey,
      });
      onFailure && onFailure(response, deploymentJob);
    },
  };
};
