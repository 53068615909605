import { SearchInstanceSelectorStyles, SearchInstanceSelectorStyleProps } from './SearchInstanceSelector.types';

export const styles = (props: SearchInstanceSelectorStyleProps): SearchInstanceSelectorStyles => {
  const { className, theme } = props;
  return {
    root: ['mbc-SearchInstanceSelector', {}, className],
    selectorTitle: [
      'mbc-SearchInstanceSelector-title',
      {
        color: theme.palette.neutralSecondary,
      },
    ],
  };
};
