import { Action } from 'redux';

export const SET_RIGHT_PANEL_TYPE = 'SET_RIGHT_PANEL_TYPE';

export interface RightPanelState {
  rightPanelType: RightPanelType;
}

export enum RightPanelType {
  Notifications,
  TenantsList,
  None,
}

interface SetRightPanelType extends Action<typeof SET_RIGHT_PANEL_TYPE> {
  rightPanelType: RightPanelType;
}

export type RightPanelStateActionTypes = SetRightPanelType;
