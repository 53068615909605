import { MetricCardStyleProps, MetricCardStyles } from './MetricCard.types';
import { getFlexColumnStyles } from '../../../../../components/common/Styles';

export const getStyles = (props: MetricCardStyleProps): MetricCardStyles => ({
  root: [
    'mbc-metric-card',
    getFlexColumnStyles(),
    {
      flex: '1 0 0',
      selectors: {
        '.mbc-card-title': {
          fontSize: 16,
          lineHeight: 20,
          fontWeight: 'normal',
          color: props.theme.palette.black,
          justifySelf: 'center',
        },
        '.mbc-content-card': {
          maxHeight: 120,
          minHeight: 120,
          minWidth: 230,
          borderTop: '4px solid ' + props.borderColor,
          borderTopLeftRadius: 3,
        },
        '.mbc-content-card-header': {
          borderBottom: 'none',
          selectors: {
            '@media only screen and (min-width: 736px)': {
              padding: '12px 24px 0 24px',
            },
            '@media only screen and (max-width: 735px)': {
              padding: '12px 0 0 0',
            },
          },
        },
        '.mbc-content-card-body': {
          paddingTop: '0',
          paddingBottom: '0',
          fontWeight: 'bold',
          fontSize: 36,
          lineHeight: 48,
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.mbc-card-header': {
          alignItems: 'baseline',
          gridTemplateColumns: !!props.actions ? '80% 20%' : '100%',
        },
        '.mbc-card-footer': {
          padding: '0 24px',
          justifyContent: 'flex-start',
        },
      },
    },
    props.className,
  ],
  footer: [
    'mbc-metric-card-footer',
    {
      color: '#666',
      fontStyle: 'italic',
      fontSize: '0.7rem',
      alignSelf: 'center',
      marginLeft: 'auto',
      paddingBottom: 3,
    },
  ],
  subComponentStyles: {
    card: {},
  },
});
