import React, { useState, useCallback } from 'react';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import {
  DetailsListCardProps,
  DetailsListCardStyleProps,
  DetailsListCardStyles,
  FiltersModel,
} from './DetailsListCard.types';
import { DetailsList } from '../DetailsList/DetailsList';
import { DetailsListCardHeader } from './DetailsListCardHeader';
import { filterItemsOnQuery } from './DetailsListCard.utils';
import { ListItem } from '../DetailsList/DetailsList.types';
import { isArray } from '../../../utils';

const getClassNames = classNamesFunction<DetailsListCardStyleProps, DetailsListCardStyles>();

export function DetailsListCardBase(props: DetailsListCardProps) {
  const { styles, theme, className, componentRef, searchActionProps, header, footer, listProps } = props;
  const { title, actions, subTitle, infoProps } = header;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const isLoadedAndEmpty = !listProps.isLoading && listProps.items.length === 0;

  const [searchQuery, setSearchQuery] = useState('');

  const [selectedFilters, setSelectedFilters] = useState<FiltersModel>({});

  const onChangeSearchQuery = (query: string = '') => setSearchQuery(query);

  const getItems = useCallback((): ListItem[] => {
    let filteredItems: any[] = searchActionProps
      ? filterItemsOnQuery(
          listProps.items,
          isArray(searchActionProps.searchBy) ? searchActionProps.searchBy : [searchActionProps.searchBy],
          searchQuery,
        )
      : listProps.items;

    let selectedFiltersKeys = Object.keys(selectedFilters);
    selectedFiltersKeys.forEach(key => {
      if (selectedFilters[key].length) {
        let currentFilterableField =
          actions &&
          actions.filter &&
          actions.filter.filterableFields &&
          actions.filter.filterableFields.find(filterableField => filterableField.fieldName === key);
        let compare = currentFilterableField && currentFilterableField.compare;
        filteredItems = filteredItems.filter(item =>
          !!compare ? compare(item, selectedFilters[key]) : selectedFilters[key].includes(item[key]),
        );
      }
    });
    return filteredItems;
  }, [actions, listProps.items, searchActionProps, searchQuery, selectedFilters]);

  return (
    <div ref={componentRef} className={classNames.root}>
      <DetailsListCardHeader
        title={title}
        subTitle={subTitle}
        infoProps={infoProps}
        searchActionProps={
          searchActionProps && !isLoadedAndEmpty && !listProps.isLoading
            ? {
                ...searchActionProps,
                onSearch: (query: string | undefined) => onChangeSearchQuery(query),
                setSearchQuery: (query: string | undefined) => onChangeSearchQuery(query),
              }
            : undefined
        }
        actions={
          !!actions && !!actions.filter
            ? {
                ...actions,
                filter: {
                  ...actions.filter,
                  items: listProps.items,
                  onFilterChange: (changedField: string, changedValue: string, selected: boolean) => {
                    changedField === 'all' && changedValue === 'all'
                      ? setSelectedFilters({})
                      : setSelectedFilters(state => ({
                          ...state,
                          [changedField]: selected
                            ? state[changedField]
                              ? [...state[changedField], changedValue]
                              : [changedValue]
                            : state[changedField].filter(value => value !== changedValue),
                        }));
                  },
                },
              }
            : actions
        }
      />

      <Stack verticalAlign={'space-between'} verticalFill styles={classNames.subComponentStyles.body}>
        <DetailsList
          {...{ ...listProps, isLoadedAndEmpty: isLoadedAndEmpty }}
          styles={classNames.subComponentStyles.list}
          items={getItems()}
        />
        {footer && (
          <Stack
            horizontal
            horizontalAlign={'end'}
            tokens={{ childrenGap: 16 }}
            styles={classNames.subComponentStyles.footer}
          >
            {footer.actions}
          </Stack>
        )}
      </Stack>
    </div>
  );
}
