import { Action } from 'redux';

export const SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN';
export const RESET_ADMIN_TOKEN = 'RESET_ADMIN_TOKEN';
export const RESET_ALL_ADMIN_TOKENS = 'RESET_ALL_ADMIN_TOKENS';

export interface Token {
  value: Promise<string>;
  timeout: NodeJS.Timeout;
  ellapsedTimeInMs: number;
}

interface AdminTokenMap {
  [key: string]: Token;
}

export interface AdminTokensState {
  adminTokenMap: AdminTokenMap;
}

interface SetAdminToken extends Action<typeof SET_ADMIN_TOKEN> {
  key: string;
  token: Token;
}

interface ResetAdminToken extends Action<typeof RESET_ADMIN_TOKEN> {
  key: string;
}

export type AdminTokensStateActionTypes = SetAdminToken | ResetAdminToken | Action<typeof RESET_ALL_ADMIN_TOKENS>;
