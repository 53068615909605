import React, { Component } from 'react';
import { Stack, Persona, IPersonaSharedProps, PersonaSize } from 'office-ui-fabric-react';
import styles from './searchDemo.module.scss';
import DemoSearchBox from '../demo-search-box/demoSearchBox';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ProductSearchResultItem, SearchItemCard } from '../../../../components/common/SearchItemCard';

export enum Scenario {
  BusinessOptimizations = 0,
  QueryUnderstanding = 1,
  Personalization = 2,
}

interface ScenarioSuggestions {
  [key: number]: string[];
}

interface SearchDemoProps extends WithTranslation {
  scenario: Scenario;
  defaultQuery?: string;
  searchDemoTitle: string;
  onChangeQuery?: Function;
  searchResultLabel: JSX.Element;
  SearchResultItems: ProductSearchResultItem[];
  isPersonaMale?: boolean;
  invisibleSearchBox?: boolean;
  showPoweredBy?: boolean;
}

class SearchDemo extends Component<SearchDemoProps> {
  static MaxSearchResultsPerRow = 3;
  static ScenarioSuggestions: ScenarioSuggestions = {
    [Scenario.BusinessOptimizations]: [],
    [Scenario.QueryUnderstanding]: ['sports cap', 'winter wear', 'short handle bags', 'party dress', 'chocker'],
    [Scenario.Personalization]: [],
  };
  constructor(props: Readonly<SearchDemoProps>) {
    super(props);

    this.genPersonaProps = this.genPersonaProps.bind(this);
  }

  componentDidMount() {
    if (this.props.onChangeQuery) {
      this.props.onChangeQuery(this.props.defaultQuery);
    }
  }

  private genPersonaProps() {
    const { t } = this.props;
    const examplePersona: IPersonaSharedProps = {
      imageUrl: this.props.isPersonaMale ? '/assets/images/personaMale.jpg' : '/assets/images/personaFemale.jpg',
      imageInitials: this.props.isPersonaMale
        ? t('search-demo.persona-male-initials')
        : t('search-demo.persona-female-initials'),
      text: this.props.isPersonaMale ? t('search-demo.persona-male-text') : t('search-demo.persona-female-text'),
    };
    return examplePersona;
  }

  render() {
    return (
      <div className={styles.searchDemoContainer}>
        <div className={styles.searchDemoTitle}>{this.props.searchDemoTitle}</div>
        <div className={styles.searchBoxArea}>
          <Stack horizontal className={styles.searchDemoPersonaContainer} horizontalAlign="end">
            {typeof this.props.isPersonaMale !== 'undefined' && (
              <Persona className={styles.searchDemoPersona} {...this.genPersonaProps()} size={PersonaSize.size40} />
            )}
          </Stack>
          <div className={styles.searchBoxContainer}>
            {!this.props.invisibleSearchBox && (
              <DemoSearchBox
                suggestions={SearchDemo.ScenarioSuggestions[this.props.scenario]}
                defaultQuery={this.props.defaultQuery ? this.props.defaultQuery : ''}
                onChangeQuery={this.props.onChangeQuery ? this.props.onChangeQuery.bind(this) : () => null}
              />
            )}
          </div>
          <div className={styles.poweredByContainer}>
            {this.props.showPoweredBy && (
              <>
                <span className={styles.poweredBy}>Powered by</span>
                <span className={styles.productName}>Microsoft Bing for Commerce</span>
              </>
            )}
          </div>
        </div>
        <div className={styles.searchDemoResultLabel}>{this.props.searchResultLabel}</div>
        <div
          className={styles.searchDemoResultGrid}
          style={{
            gridTemplateColumns: `repeat(${SearchDemo.MaxSearchResultsPerRow}, 272px)`,
          }}
        >
          {this.props.SearchResultItems.map(SearchResultItems => {
            return <SearchItemCard key={SearchResultItems.id} item={SearchResultItems}></SearchItemCard>;
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SearchDemo);
