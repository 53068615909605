import React, { useState, useEffect } from 'react';
import { classNamesFunction, Pivot, PivotItem } from 'office-ui-fabric-react';
import { PivotCardProps, PivotCardStyleProps, PivotCardStyles } from './PivotCard.types';

const getClassNames = classNamesFunction<PivotCardStyleProps, PivotCardStyles>();

export function PivotCardBase(props: PivotCardProps) {
  const { styles, theme, componentRef, className, panels, selectedKey, onLinkClick } = props;
  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [selectedPanelKey, setSelectedPanelKey] = useState(selectedKey);
  const goToPanel = (key: string) => setSelectedPanelKey(key);
  const panelContentList = panels(goToPanel);

  useEffect(() => {
    setSelectedPanelKey(selectedKey);
  }, [selectedKey]);

  return (
    <Pivot
      className={classNames.root}
      componentRef={componentRef}
      selectedKey={selectedPanelKey}
      onLinkClick={item => {
        if (!!item) {
          // Controlled component
          if (!!onLinkClick) {
            onLinkClick(item);
            return;
          }
          setSelectedPanelKey(item.props.itemKey);
        }
      }}
      styles={classNames.subComponentStyles.pivotContainer}
    >
      {panelContentList.map(props => (
        <PivotItem
          key={props.key}
          itemKey={props.key}
          headerText={props.title}
          className={classNames.panel}
          headerButtonProps={{
            disabled: props.disabled,
            iconProps: props.iconProps,
          }}
          alwaysRender={props.alwaysRender}
        >
          {props.panel}
        </PivotItem>
      ))}
    </Pivot>
  );
}
