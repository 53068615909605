import React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import {
  ProductToursPanelStyleProps,
  ProductToursPanelStyles,
  ProductToursPanelProps,
} from './ProductToursPanel.types';
import { ProductToursItem } from './ProductToursItem';
import { CustomPanel } from '../../../../common/CustomPanel';
import { useDispatch } from '../../../../../store/hooks';
import { startTutorial } from '../../../../../store/actions/tutorialActions';
import { AvailableTutorials } from '../../../../../store/types/tutorial.d';
import { TutorialsId } from '../../../../Tutorials';

const getClassNames = classNamesFunction<ProductToursPanelStyleProps, ProductToursPanelStyles>();

export const ProductToursPanelBase = (props: ProductToursPanelProps): JSX.Element => {
  const { className, styles, componentRef, theme, t, isOpen, onDismiss } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  return (
    <div ref={componentRef} className={classNames.root}>
      <CustomPanel
        isHiddenOnDismiss={false}
        isOpen={isOpen}
        onDismiss={onDismiss}
        headerText={t('product-tours-panel.header')}
        lightDismiss
        className={classNames.root}
      >
        <ProductToursItem
          title={t('product-tours-panel.bacis-features-header')}
          toursCards={[
            {
              tourTitle: t('product-tours-panel.environments-tour.title'),
              tourDescription: t('product-tours-panel.environments-tour.description'),
              titleIconName: 'PreviewLink',
              tourId: TutorialsId.EnvironmentList,
              onStartClick: () => {
                dispatch(startTutorial(AvailableTutorials.EnvironmentList));
                onDismiss();
              },
            },
            {
              tourTitle: t('product-tours-panel.catalog-tour.title'),
              tourDescription: t('product-tours-panel.catalog-tour.description'),
              titleIconName: 'Product',
              tourId: TutorialsId.Catalog,
              onStartClick: () => {
                dispatch(startTutorial(AvailableTutorials.Catalog));
                onDismiss();
              },
            },
          ]}
        />
        <ProductToursItem
          title={t('product-tours-panel.search-features-header')}
          toursCards={[
            {
              tourTitle: t('product-tours-panel.quey-tester-tour.title'),
              tourDescription: t('product-tours-panel.quey-tester-tour.description'),
              titleIconName: 'QueryTester',
              tourId: TutorialsId.QueryTester,
              onStartClick: () => {
                dispatch(startTutorial(AvailableTutorials.QueryTester));
                onDismiss();
              },
            },
            {
              tourTitle: t('product-tours-panel.business-rules-tour.title'),
              tourDescription: t('product-tours-panel.business-rules-tour.description'),
              titleIconName: 'BusinessRules',
              tourId: TutorialsId.BusinessRules,
              onStartClick: () => {
                dispatch(startTutorial(AvailableTutorials.BusinessRulesFull));
                onDismiss();
              },
            },
            {
              tourTitle: t('product-tours-panel.synonyms-tour.title'),
              tourDescription: t('product-tours-panel.synonyms-tour.description'),
              titleIconName: 'Synonyms',
              tourId: TutorialsId.Synonyms,
              onStartClick: () => {
                dispatch(startTutorial(AvailableTutorials.SynonymsFull));
                onDismiss();
              },
            },
          ]}
        />
      </CustomPanel>
    </div>
  );
};
