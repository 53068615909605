import React from 'react';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import { SearchModelProps, SearchModelStyleProps, SearchModelStyles } from './SearchModel.types';
import { useSelector } from '../../../store/hooks';
import { SearchModel } from '../../../store/types/customML/searchModel.d';
import { ManageDataCard } from './CustomMLCard/ManageDataCard';
import { TrainModelCard } from './CustomMLCard/TrainModelCard';
import { EvaluateModelCard } from './CustomMLCard/EvaluateModelCard';
import { DeployModelCard } from './CustomMLCard/DeployModelCard';
import { PageTemplate } from '../../../components/common/PageTemplate';

export const getClassNames = classNamesFunction<SearchModelStyleProps, SearchModelStyles>();

export function SearchModelBase(props: SearchModelProps) {
  const { t, styles, theme } = props;
  const classNames = getClassNames(styles, { theme: theme! });

  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);

  return (
    <PageTemplate
      styles={classNames.subComponentStyles.page}
      breadcrumbVisible
      pageHeaderProps={{
        title: searchModel.name,
        titleCaption: t('custom-ml.job-card.title-caption', { date: searchModel.updatedAt }),
      }}
      onRenderContent={() => (
        <Stack>
          <Stack className={classNames.description} tokens={{ childrenGap: 21 }}>
            <div className={classNames.descriptionLabel}>{t('custom-ml.job-card.description')}</div>
            <div className={classNames.descriptionText}>{searchModel.description}</div>
          </Stack>
          <Stack horizontal wrap horizontalAlign={'start'} verticalAlign={'center'} tokens={{ childrenGap: 32 }}>
            <ManageDataCard key={'dataset'} />
            <TrainModelCard key={'train'} />
            <EvaluateModelCard key={'evaluate'} />
            <DeployModelCard key={'deploy'} />
          </Stack>
        </Stack>
      )}
    />
  );
}
