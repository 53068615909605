import { ConditionEditorStyles, ConditionEditorStyleProps } from './ConditionEditor.types';

export const styles = (props: ConditionEditorStyleProps): ConditionEditorStyles => {
  const { className } = props;
  return {
    root: [
      'mbc-ConditionEditor',
      {
        borderTop: '1px solid #EDEBE9',
        paddingTop: 10,
        paddingBottom: 20,
        marginBottom: 10,
        alignItems: 'baseline',
      },
      className,
    ],
    header: [
      'mbc-ConditionEditor-Header',
      {
        selectors: {
          '::first-letter': {
            textTransform: 'capitalize',
          },
        },
      },
    ],
  };
};
