import { PivotCardStyleProps, PivotCardStyles } from './PivotCard.types';
import { getFlexColumnStyles } from '../Styles';

export const getStyles = (props: PivotCardStyleProps): PivotCardStyles => ({
  root: ['mbc-pivot-card', { height: '100%' }, getFlexColumnStyles(), props.className],
  panel: [
    'mbc-pivot-item',
    {
      marginTop: 16,
      height: '100%',
    },
    getFlexColumnStyles(),
  ],
  subComponentStyles: {
    pivotContainer: {
      itemContainer: {
        height: '100%',
      },
    },
  },
});
