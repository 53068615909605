import React, { useRef, useEffect } from 'react';
import { classNamesFunction, Stack, IconButton, Icon, StackItem, DefaultButton } from 'office-ui-fabric-react';
import {
  CardState,
  ActiveCardProps,
  CustomMLCardProps,
  CustomMLCardStyles,
  CompletedCardProps,
  CustomMLCardStyleProps,
  ContentProps,
  ListContentProps,
  StatusProps,
} from './CustomMLCard.types';
import { getStatusIconProps } from '../SearchModel.utils';
import { TitledContentCard } from '../../../../components/common/ContentCard';
import { ModelJobStatus } from '../../../../store/types/customML/searchModel.d';

export const getClassNames = classNamesFunction<CustomMLCardStyleProps, CustomMLCardStyles>();

export function CustomMLCardBase(props: CustomMLCardProps) {
  const { t, theme, styles, className, componentRef } = props;
  const { title, state, activeCardProps, completedCardProps, inProgressLabel } = props;

  const animate = useRef<boolean>(true);
  useEffect(() => {
    animate.current = false;
    return () => {};
  }, []);

  const classNames = getClassNames(styles, {
    state: state,
    theme: theme!,
    className: className,
    disabledOnCompletion: !!completedCardProps && !completedCardProps.onClick,
  });

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
  };

  function isListContentProps(contentProps: ListContentProps | ContentProps): contentProps is ListContentProps {
    return (contentProps as ListContentProps).statusList !== undefined;
  }

  const ActiveCardContent = (props: ActiveCardProps) => {
    const { label, iconProps, onClick } = props;
    return (
      <Stack verticalFill tokens={{ childrenGap: 12 }}>
        <Stack horizontalAlign={'center'} verticalAlign={'center'} grow tokens={{ childrenGap: 16 }}>
          <IconButton
            disabled={state === CardState.InActive}
            className={classNames.actionIcon}
            iconProps={iconProps}
            onClick={onClick}
          />
        </Stack>
        <DefaultButton className={classNames.actionLabel} onClick={onClick} disabled={state === CardState.InActive}>
          {label}
        </DefaultButton>
      </Stack>
    );
  };

  const InProgressCardContent = () => (
    <Stack verticalFill tokens={{ childrenGap: 12 }}>
      <Stack horizontalAlign={'center'} verticalAlign={'center'} grow tokens={{ childrenGap: 16 }}>
        <StackItem>
          <Icon className={classNames.actionIcon} iconName={'Sync'} />
        </StackItem>
      </Stack>
      <DefaultButton className={classNames.actionLabel} disabled={state === CardState.InActive}>
        {inProgressLabel}
      </DefaultButton>
    </Stack>
  );

  const StatusView = (props: StatusProps) => (
    <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign={'center'} key="completed-upload">
      <Icon {...getStatusIconProps(props.status)}></Icon>
      <div style={{ fontSize: 13 }}>{props.label}</div>
    </Stack>
  );

  const SummaryCardListContent = (props: ListContentProps) => (
    <Stack horizontalAlign={'start'} grow tokens={{ childrenGap: 12, padding: '0 12px' }}>
      <div className={classNames.title}>{props.title}</div>
      <Stack tokens={{ childrenGap: 8 }}>
        {props.statusList.map((statusProps, index) => (
          <StatusView key={index} {...statusProps} />
        ))}
      </Stack>
    </Stack>
  );

  const SummaryCardContent = (props: ContentProps) => (
    <div className={classNames.contentContainer}>
      <div className={classNames.title}>{t('custom-ml.job-card.status-label')}</div>
      <div className={classNames.title}>{t('custom-ml.job-card.time-label')}</div>
      <StatusView label={props.startTime.label} status={ModelJobStatus.Pending} />
      <div style={{ fontSize: 13 }}>{new Date(props.startTime.value).toLocaleDateString('en-US', options)}</div>
      {!!props.endTime && (
        <>
          <StatusView label={props.endTime.label} status={props.endTime.status} />
          <div style={{ fontSize: 13 }}>{new Date(props.endTime.value).toLocaleDateString('en-US', options)}</div>
        </>
      )}
    </div>
  );

  const CompletedCardContent = (props: CompletedCardProps) => {
    const { onClick, label, contentProps } = props;
    return (
      <Stack verticalFill verticalAlign={'space-between'} tokens={{ childrenGap: 12 }}>
        {isListContentProps(contentProps) ? (
          <SummaryCardListContent {...contentProps} />
        ) : (
          <SummaryCardContent {...contentProps} />
        )}
        <DefaultButton className={classNames.actionLabel} disabled={!onClick} onClick={onClick}>
          {label}
        </DefaultButton>
      </Stack>
    );
  };

  return (
    <div ref={componentRef} className={classNames.root}>
      <TitledContentCard
        title={
          <Stack horizontal verticalAlign={'center'} horizontalAlign={'start'} tokens={{ childrenGap: 8 }}>
            <div className={classNames.title}>{title}</div>
          </Stack>
        }
        body={
          <>
            {state === CardState.Completed && completedCardProps && <CompletedCardContent {...completedCardProps} />}
            {state <= CardState.Active && activeCardProps && <ActiveCardContent {...activeCardProps} />}
            {state === CardState.InProgress && <InProgressCardContent />}
          </>
        }
      />
    </div>
  );
}
