import { Stack, TextField, classNamesFunction } from 'office-ui-fabric-react';
import React from 'react';
import {
  GeneralSectionContentProps,
  GeneralSectionContentStyleProps,
  GeneralSectionContentStyles,
} from './GeneralSectionContent.types';

const getClassNames = classNamesFunction<GeneralSectionContentStyleProps, GeneralSectionContentStyles>();

export const GeneralSectionContentBase = (props: GeneralSectionContentProps) => {
  const { t } = props;
  const classNames = getClassNames(props.styles);

  return (
    <Stack data-test="role-editor-general-section" tokens={{ childrenGap: '21px' }}>
      <form autoComplete="off">
        <TextField
          id="Name"
          name="Name"
          autoComplete={navigator.userAgent.indexOf('Edg') !== -1 ? 'none' : 'off'}
          type="text"
          required
          value={props.name}
          label={t('role-editor.general-section.name')}
          className={classNames.inputField}
          onChange={(ev, newVal) => props.onNameChange(newVal || '')}
          disabled={props.viewOnlyMode}
        />
      </form>
      <TextField
        required
        value={props.description}
        label={t('role-editor.general-section.description')}
        className={classNames.inputField}
        onChange={(ev, newVal) => props.onDescriptionChange(newVal || '')}
        disabled={props.viewOnlyMode}
      />
    </Stack>
  );
};
