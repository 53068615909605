import { InfoCardStyleProps, InfoCardStyles } from './InfoCard.types';

export const styles = (props: InfoCardStyleProps): InfoCardStyles => ({
  root: [
    'mbc-info-card',
    {
      marginTop: 4,
      selectors: {
        '.ms-Icon': {
          color: props.theme.palette.neutralPrimaryAlt,
          fontSize: 13,
        },
        '.ms-Button': {
          padding: 0,
          height: navigator.userAgent.indexOf('Firefox') === -1 ? 'fit-content' : '-moz-fit-content',
          selectors: {
            ':hover': {
              backgroundColor: 'unset',
              cursor: 'pointer',
            },
          },
        },
        '.ms-Button-flexContainer': {
          width: navigator.userAgent.indexOf('Firefox') === -1 ? 'fit-content' : '-moz-fit-content',
          height: navigator.userAgent.indexOf('Firefox') === -1 ? 'fit-content' : '-moz-fit-content',
        },
      },
    },
    props.className,
  ],
  callout: [
    'mbc-info-callout',
    {
      selectors: {
        '.ms-Callout-beak': {
          backgroundColor: props.theme.palette.white,
        },

        '.ms-Callout-main': {
          backgroundColor: props.theme.palette.white,
        },
        '.ms-TeachingBubble-headline': {
          color: props.theme.palette.neutralSecondary,
          fontWeight: 600,
          fontSize: 20,
        },
        '.ms-TeachingBubble-subText': {
          color: props.theme.palette.neutralSecondaryAlt,
          fontSize: 14,
        },
        '.ms-TeachingBubble-closebutton': {
          selectors: {
            ':hover': {
              backgroundColor: 'unset',
            },
            i: {
              color: props.theme.palette.neutralSecondary,
            },
          },
        },
        a: {
          fontSize: 12,
        },
      },
    },
  ],
});
