import { Action } from 'redux';
import { ModelJobStatus } from './searchModel';

export const SET_DEPLOYMENT_JOB_LIST = 'SET_DEPLOYMENT_JOB_LIST';
export const RESET_DEPLOYMENT_JOB_LIST = 'RESET_DEPLOYMENT_JOB_LIST';
export const UPDATE_DEPLOYMENT_JOB_LIST = 'UPDATE_DEPLOYMENT_JOB_LIST';
export const LOADING_DEPLOYMENT_JOB_LIST = 'LOADING_DEPLOYMENT_JOB_LIST';

export const ADD_DEPLOYMENT_JOB = 'ADD_DEPLOYMENT_JOB';
export const UPDATE_DEPLOYMENT_JOB = 'UPDATE_DEPLOYMENT_JOB';

export interface DeploymentJobMapper {
  [key: string]: DeploymentJob;
}

export interface DeploymentJob {
  key: string;
  name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  deployedModelKey: string;
  status: ModelJobStatus;
}

export interface DeploymentJobState {
  isLoading: boolean;
  deploymentJobMapper: DeploymentJobMapper;
}

interface AddDeploymentJob extends Action<typeof ADD_DEPLOYMENT_JOB> {
  deploymentJob: DeploymentJob;
}

interface UpdateDeploymentJob extends Action<typeof UPDATE_DEPLOYMENT_JOB> {
  deploymentJob: DeploymentJob;
}

interface UpdateDeploymentJobList extends Action<typeof UPDATE_DEPLOYMENT_JOB_LIST> {
  deploymentJobList: DeploymentJob[];
}

interface SetDeploymentJobList extends Action<typeof SET_DEPLOYMENT_JOB_LIST> {
  deploymentJobList: DeploymentJob[];
}

export type DeploymentJobStateActionTypes =
  | AddDeploymentJob
  | UpdateDeploymentJob
  | SetDeploymentJobList
  | UpdateDeploymentJobList
  | Action<typeof RESET_DEPLOYMENT_JOB_LIST>
  | Action<typeof LOADING_DEPLOYMENT_JOB_LIST>;
