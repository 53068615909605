import {
  QueryCount as QueryCountModel,
  SearchParameter as SearchParameterModel,
  TotalCount as TotalCountModel,
  SearchMetricsModel,
  TotalPercentage as TotalPercentageModel,
  TotalPercentagContent as TotalPercentagContentModel,
} from '../../store/types/analytics.d';
import {
  DateValueMapper,
  FilterType,
  OperatorType,
  AggregationInterval,
  AnalyticsRequest,
  Filter,
  ContentCountResponse as ContentCountResponseDTO,
  ContentCountPercentageResponse as ContentCountPercentageResponseDTO,
  BaseCount as TotalCountDTO,
  SearchMetricsResponse as SearchMetricsDTO,
  PercentageResponse as TotalPercentageDTO,
  SearchResultsFilter,
} from '../dto/analytics/analytics';
import numeral from 'numeral';
import moment from 'moment';

function mapAnalyticsFilterToDtoInternal(
  filters: Filter[][],
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): AnalyticsRequest {
  if (regions.length) {
    filters.push(
      regions.map(region => {
        return {
          key: FilterType.Regions,
          operator: OperatorType.Equals,
          value: region,
          valueType: 'string',
        };
      }),
    );
  }

  if (searchResultsStatus.length) {
    filters.push(
      searchResultsStatus.map(status => {
        if (status === SearchResultsFilter.HasResults) {
          return {
            key: FilterType.SearchResultsStatus,
            operator: OperatorType.Equals,
            value: 'true',
            valueType: 'bool',
          };
        } else {
          return {
            key: FilterType.SearchResultsStatus,
            operator: OperatorType.Equals,
            value: 'false',
            valueType: 'bool',
          };
        }
      }),
    );
  }

  if (markets.length) {
    filters.push(
      markets.map(market => {
        return {
          key: FilterType.Market,
          operator: OperatorType.Equals,
          value: market,
          valueType: 'string',
        };
      }),
    );
  }

  if (responseCode.length) {
    filters.push(
      responseCode.map(code => {
        return {
          key: FilterType.ResponseCode,
          operator: OperatorType.Equals,
          value: code,
          valueType: 'int',
        };
      }),
    );
  }

  if (searchInstances.length) {
    filters.push(
      searchInstances.map(searchInstance => {
        return {
          key: FilterType.SearchInstances,
          operator: OperatorType.Equals,
          value: searchInstance,
          valueType: 'string',
        };
      }),
    );
  }

  filters.push([
    {
      key: FilterType.StartTime,
      operator: OperatorType.GreaterThanOrEquals,
      value: moment(start).format('YYYY-MM-DD HH:mm:ss'),
      valueType: 'DateTime',
    },
  ]);

  filters.push([
    {
      key: FilterType.StartTime,
      operator: OperatorType.LessThanOrEquals,
      value: moment(end).format('YYYY-MM-DD HH:mm:ss'),
      valueType: 'DateTime',
    },
  ]);

  return {
    filters: { generalFilters: filters },
  };
}

export function mapAnalyticsRequestToDto(
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): AnalyticsRequest {
  return mapAnalyticsFilterToDtoInternal(
    [],
    responseCode,
    start,
    end,
    markets,
    regions,
    searchResultsStatus,
    searchInstances,
  );
}

export function mapAnalyticsAggregatedRequestToDto(
  aggregationInterval: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): AnalyticsRequest {
  const analyticsAggregatedRequest = mapAnalyticsFilterToDtoInternal(
    [],
    responseCode,
    start,
    end,
    markets,
    regions,
    searchResultsStatus,
    searchInstances,
  );
  return {
    ...analyticsAggregatedRequest,
    aggregationInterval: aggregationInterval,
  };
}

export function mapContentCountDtoToQueryCountModel(contentCountResponse: ContentCountResponseDTO): QueryCountModel[] {
  return contentCountResponse.content.map(item => ({
    query: item.content,
    count: item.value,
  }));
}

export function mapContentCountDtoToTotalCountModel(totalCountDTO: TotalCountDTO): TotalCountModel {
  return {
    count: totalCountDTO.value,
  };
}

export function mapSearchMetricsDtoToModel(
  searchMetricsDTO: SearchMetricsDTO,
  startDate: Date,
  endDate: Date,
  aggregationInterval: AggregationInterval,
): SearchMetricsModel {
  // Dictionary for values from backend
  var defaultDict: DateValueMapper = {};
  let dt: Date = new Date(startDate);
  let reConstructedSeries: SearchMetricsModel = { series: [], interval: 1 };

  if (aggregationInterval === AggregationInterval.HOURLY) {
    searchMetricsDTO.result.forEach(searchMetric => {
      defaultDict[searchMetric.date.replace('T', ' ')] = searchMetric.value;
    });
    while (dt <= endDate) {
      let currIsoDate = dt.toLocaleDateString('en-CA') + ' ' + numeral(dt.getHours()).format('00') + ':00:00';
      dt = new Date(dt.getTime() + 3600000);
      reConstructedSeries.series = [
        ...reConstructedSeries.series,
        {
          date: currIsoDate,
          value: defaultDict.hasOwnProperty(currIsoDate) ? defaultDict[currIsoDate] : 0,
        },
      ];
    }

    if (reConstructedSeries.series.length <= 25) {
      reConstructedSeries.interval = 1;
    } else if (reConstructedSeries.series.length <= 49) {
      reConstructedSeries.interval = 2;
    } else {
      reConstructedSeries.interval = 4;
    }
  } else {
    searchMetricsDTO.result.forEach(searchMetric => {
      defaultDict[searchMetric.date.split('T')[0]] = searchMetric.value;
    });
    while (dt <= endDate) {
      let currIsoDate = dt.toLocaleDateString('en-CA');
      dt.setDate(dt.getDate() + 1);
      reConstructedSeries.series = [
        ...reConstructedSeries.series,
        {
          date: currIsoDate,
          value: defaultDict.hasOwnProperty(currIsoDate) ? defaultDict[currIsoDate] : 0,
        },
      ];
    }

    if (reConstructedSeries.series.length <= 8) {
      reConstructedSeries.interval = 0;
    } else if (reConstructedSeries.series.length <= 20) {
      reConstructedSeries.interval = 1;
    } else if (reConstructedSeries.series.length <= 60) {
      reConstructedSeries.interval = 6;
    } else if (reConstructedSeries.series.length <= 120) {
      reConstructedSeries.interval = 13;
    } else {
      reConstructedSeries.interval = 29;
    }
  }

  return reConstructedSeries;
}

export function mapPercentageResponseDtoToModel(percentageResponse: TotalPercentageDTO): TotalPercentageModel {
  // Backend returns empty object when values are Zeroes
  return {
    count: percentageResponse.value ? percentageResponse.value : 0,
    percentage: percentageResponse.percentage ? percentageResponse.percentage : 0,
  };
}

export function mapContentCountDtoToSearchParameterModel(
  contentCountResponse: ContentCountResponseDTO,
): SearchParameterModel[] {
  return contentCountResponse.content.map(item => ({
    name: item.content,
    count: item.value,
  }));
}

export function mapContentCountDtoToStringArray(contentCountResponse: ContentCountResponseDTO): string[] {
  return contentCountResponse.content.map(item => item.content);
}

export function mapContentCountPercentageDtoToPercentagContentModel(
  contentCountPercentageResponse: ContentCountPercentageResponseDTO,
): TotalPercentagContentModel[] {
  return contentCountPercentageResponse.content
    .filter(item => item.value || item.percentage)
    .map(item => ({
      content: item.content,
      count: item.value,
      percentage: item.percentage,
    }));
}
