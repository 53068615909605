import { getI18n } from 'react-i18next';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { Product } from '../../types/tenantsList.d';
import { Environment } from '../../types/environmentList.d';
import { MbcEnvironmentRouter } from '../../../router/MbcEnvironmentRouter/MbcEnvironmentRouter';
import { AppState } from '../../reducers';
import { useSelector } from 'react-redux';
import { ProductShortcut, ProductShortcutMap, LinkIconSize } from './useCurrentServices.types';
import { useCallback } from 'react';
import _ from 'lodash';

export const useCurrentServices = (linkIconSize: LinkIconSize) => {
  const i18n = getI18n();

  const activeTenant = useSelector((state: AppState) => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector((state: AppState) => state.environmentList.activeEnvironment);

  const getProductsMap = useCallback(
    (environment: Environment): ProductShortcutMap => {
      const MbcEnvironmentRouteMap = MbcEnvironmentRouter(activeTenant, environment);
      return {
        [Product.TextSearch]: {
          priority: 1,
          disabled: false,
          icon: linkIconSize === LinkIconSize.Large ? 'TextSearchLarge' : 'TextSearch',
          product: Product.TextSearch,
          text: i18n.t('products.product-search'),
          ariaLabel: i18n.t('products.product-search'),
          onClick: () => MbcEnvironmentRouteMap(MbcRouteKey.QueryTester).browserPush(),
        },
        [Product.Recommendations]: {
          priority: 2,
          disabled: false,
          icon: linkIconSize === LinkIconSize.Large ? 'RecommendationsLarge' : 'Recommendations',
          product: Product.Recommendations,
          text: i18n.t('products.recommendations'),
          ariaLabel: i18n.t('products.recommendations'),
        },
      };
    },
    [activeTenant, i18n, linkIconSize],
  );

  return useCallback(
    (environment?: Environment): ProductShortcut[] => {
      const environmentInternal = environment ? environment : activeEnvironment;
      const enabledProductList = environmentInternal.products;
      const PredefinedShortcutMap = getProductsMap(environmentInternal);

      return _.orderBy(
        [Product.TextSearch, Product.Recommendations].map(p => ({
          ...PredefinedShortcutMap[p],
          disabled:
            environmentInternal.isIndexSoftDeleted || !enabledProductList.includes(p) || !environmentInternal.indexId,
        })),
        ['disabled', 'priority'],
      );
    },
    [activeEnvironment, getProductsMap],
  );
};
