import { NoResultsQueriesStyleProps, NoResultsQueriesStyles } from './NoResultsQueries.types';

export const styles = (props: NoResultsQueriesStyleProps): NoResultsQueriesStyles => {
  return {
    root: [
      'mbc-no-results-queries-chart',
      {
        selectors: {
          '.mbc-content-card': {
            borderTop: '4px solid #ef6950',
            boxSizing: 'border-box',
          },
        },
      },
      props.className,
    ],
  };
};
