export interface AnalyticsRequest {
  count?: number;
  filters: FiltersContainer;
  aggregationInterval?: AggregationInterval;
}

export interface FiltersContainer {
  generalFilters: Filter[][];
}

export enum AggregationInterval {
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum FilterType {
  Market = 'Market',
  ResponseCode = 'ResponseCode',
  StartTime = 'StartTime',
  Regions = 'Zone',
  SearchResultsStatus = 'HasSearchResults',
  SearchInstances = 'SearchInstanceId',
}

export enum OperatorType {
  Equals = '==',
  GreaterThanOrEquals = '>=',
  GreaterThan = '>',
  LessThanOrEquals = '<=',
  LessThan = '<',
}

export enum SearchResultsFilter {
  NotResults = 'No results',
  HasResults = 'Has results',
}

export interface DateValueMapper {
  [key: string]: number;
}

export interface Filter {
  key: FilterType;
  operator: OperatorType;
  value: string;
  valueType: string;
}

export interface BaseCount {
  value: number;
}

export interface PercentageResponse extends BaseCount {
  percentage: number;
}

interface Content extends BaseCount {
  content: string;
}

interface PercentageContent extends PercentageResponse {
  content: string;
}
export interface ContentCountResponse {
  content: Content[];
}

export interface ContentCountPercentageResponse {
  content: PercentageContent[];
}

// will need to rename
interface SearchMetrics {
  date: string;
  value: number;
}

// will need to rename
export interface SearchMetricsResponse {
  result: SearchMetrics[];
}
