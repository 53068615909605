import HttpClient from './http-client/httpClient';
import { SchemaDetection as SchemaDetectionDTO } from './dto/search-index/schemaDetection';
import { SchemaDetection as SchemaDetectionModel } from '../store/types/schemaDetection';
import { mapSchemaDetectionDtoToModel } from './mappers/searchIndexMapper';

function getBaseUri(fileFormat: string): string {
  return `https://commerce.bing.com/api/ingestion/v1/schemadetection?format=${fileFormat}`;
}

export function detectSchema(file: File, tenantId: string, fileFormat: string): Promise<SchemaDetectionModel> {
  // The right extension for JSON-LD is 'jsonld', but the current api accepts 'ldjson' format instead
  // TODO::should be corrected from API side and remove the following condition
  if (fileFormat === 'jsonld') {
    fileFormat = 'ldjson';
  } else if (fileFormat === 'json') {
    fileFormat = 'JsonArray';
  }
  return HttpClient.post<SchemaDetectionDTO>({
    url: `${getBaseUri(fileFormat)}`,
    body: file,
    // This is to prevent the HttpClient from sending the default content-type header
    headers: new Headers(),
    authInfo: {
      tenantId: tenantId,
    },
  }).then(mapSchemaDetectionDtoToModel);
}
