import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  addBlockedSuggestion,
  getBlockedSuggestions,
  removeBlockedSuggestion,
} from '../../restful-apis/autosuggest.api';
import { AppState } from '../reducers';
import {
  ADD_BLOCKED_SUGGESTION,
  BlockedSuggestionModel,
  GET_BLOCKED_SUGGESTIONS,
  REMOVE_BLOCKED_SUGGESTION,
  SET_BLOCKED_LIST_LOADING,
  SET_ID_TO_RECENTLY_ADDED_BLOCKED_SUGGESTION,
} from '../types/autosuggest.d';

export const getBlockedSuggestionsAction = (
  tenantId: string,
  indexId: string,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: SET_BLOCKED_LIST_LOADING,
    });
    getBlockedSuggestions(tenantId, indexId).then(blockedSuggestions => {
      dispatch({
        type: GET_BLOCKED_SUGGESTIONS,
        blockedSuggestions: blockedSuggestions,
      });
    });
  };
};

export const optimisticAddBlockedSuggestion = (
  tenantId: string,
  indexId: string,
  submittedBlockedSuggestion: BlockedSuggestionModel,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: ADD_BLOCKED_SUGGESTION,
      submittedBlockedSuggestion: submittedBlockedSuggestion,
    });
    addBlockedSuggestion(tenantId, indexId, submittedBlockedSuggestion)
      .then(res => {
        if (!res.blockedSuggestions[0].posted) {
          dispatch({
            type: REMOVE_BLOCKED_SUGGESTION,
            submittedBlockedSuggestion: submittedBlockedSuggestion,
          });
        } else {
          dispatch({
            type: SET_ID_TO_RECENTLY_ADDED_BLOCKED_SUGGESTION,
            newId: res.blockedSuggestions[0].id,
            submittedBlockedSuggestion: submittedBlockedSuggestion,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: REMOVE_BLOCKED_SUGGESTION,
          submittedBlockedSuggestion: submittedBlockedSuggestion,
        });
      });
  };
};

export const optimisticRemoveBlockedSuggestion = (
  tenantId: string,
  indexId: string,
  submittedBlockedSuggestion: BlockedSuggestionModel,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    if (!submittedBlockedSuggestion.id) return;
    dispatch({
      type: REMOVE_BLOCKED_SUGGESTION,
      submittedBlockedSuggestion: submittedBlockedSuggestion,
    });
    removeBlockedSuggestion(tenantId, indexId, submittedBlockedSuggestion.id)
      .then(res => {
        if (!res.deletedSuggestions[0].deleted) {
          dispatch({
            type: ADD_BLOCKED_SUGGESTION,
            submittedBlockedSuggestion: submittedBlockedSuggestion,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: ADD_BLOCKED_SUGGESTION,
          submittedBlockedSuggestion: submittedBlockedSuggestion,
        });
      });
  };
};
