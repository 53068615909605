import { ReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../../CrudAction.types';
import {
  updateUrlRedirectSet,
  deleteUrlRedirectSet,
} from '../../../../../restful-apis/customizations/urlRedirectSet.api';
import {
  UrlRedirectSet,
  DELETE_URL_REDIRECT_SET,
  CREATE_OR_UPDATE_URL_REDIRECT_SET,
} from '../../../../types/customizations/urlRedirectSet.d';
import { DevOpsCommitMetadata } from '../../../../types/devOps.d';
import { getI18n } from 'react-i18next';

export const UrlRedirectSetUpdateAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  urlRedirectSet: UrlRedirectSet,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<UrlRedirectSet>,
  onFailure?: ActionFailureHandler<UrlRedirectSet>,
): ReduxCrudAction<UrlRedirectSet> => {
  const i18n = getI18n();
  return {
    request: updateUrlRedirectSet(tenantId, searchIndexId, searchInstanceId, urlRedirectSet, devOpsCommitMetadata),
    postActionMapper: updatedUrlRedirectSet => ({
      type: CREATE_OR_UPDATE_URL_REDIRECT_SET,
      urlRedirectSet: updatedUrlRedirectSet,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.redirects-update-progress-message'),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.redirects-update-success-message'),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.redirects-update-failure-message'),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, urlRedirectSet),
  };
};

export const UrlRedirectSetCreateAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  urlRedirectSet: UrlRedirectSet,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<UrlRedirectSet>,
  onFailure?: ActionFailureHandler<UrlRedirectSet>,
): ReduxCrudAction<UrlRedirectSet> => {
  const i18n = getI18n();
  return {
    request: updateUrlRedirectSet(tenantId, searchIndexId, searchInstanceId, urlRedirectSet, devOpsCommitMetadata),
    postActionMapper: createdUrlRedirectSet => ({
      type: CREATE_OR_UPDATE_URL_REDIRECT_SET,
      urlRedirectSet: createdUrlRedirectSet,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.redirects-create-progress-message'),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.redirects-create-success-message'),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.redirects-create-failure-message'),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, urlRedirectSet),
  };
};

export const UrlRedirectSetDeleteAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  urlRedirectSetId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<UrlRedirectSet>,
  onFailure?: ActionFailureHandler<UrlRedirectSet>,
): ReduxCrudAction<UrlRedirectSet> => {
  const i18n = getI18n();
  return {
    request: deleteUrlRedirectSet(tenantId, searchIndexId, searchInstanceId, urlRedirectSetId, devOpsCommitMetadata),
    postActionMapper: DeletedUrlRedirectSet => ({
      type: DELETE_URL_REDIRECT_SET,
      id: DeletedUrlRedirectSet.id,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.redirects-delete-progress-message'),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.redirects-delete-success-message'),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.redirects-delete-failure-message'),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, urlRedirectSetId),
  };
};
