import { SearchInstance as SearchInstanceModel } from '../../store/types/customizations/searchInstanceList';
import { SearchInstanceUpdateDTO, SearchInstance as SearchInstanceDTO } from '../dto/search-instance/searchInstance';

export function mapSearchInstanceDtoToModel(searchInstance: SearchInstanceDTO): SearchInstanceModel {
  return {
    id: searchInstance.instanceId.toLowerCase(),
    name: searchInstance.instanceId,
  };
}

export function mapSearchInstanceModelToDto(searchInstance: SearchInstanceModel): SearchInstanceUpdateDTO {
  return {
    SearchInstanceId: searchInstance.id,
  };
}
