import { ProductSupportPanelItemStyleProps, ProductSupportPanelItemStyles } from './ProductSupportPanelItem.types';
import { getFlexColumnStyles, getFlexRowStyles } from '../../../../../common/Styles';

export const styles = (props: ProductSupportPanelItemStyleProps): ProductSupportPanelItemStyles => {
  return {
    root: ['mbc-product-support-panel-item', getFlexColumnStyles(), props.className],
    itemTitle: [
      'mbc-product-support-panel-item-title',
      {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: 600,
        margin: '8px 0',
      },
    ],
    itemSubtitle: [
      'mbc-product-support-panel-item-subtitle',
      {
        fontSize: 12,
        lineHeight: 16,
        marginBottom: 16,
      },
    ],
    itemLink: [
      'mbc-product-support-panel-item-link',
      getFlexRowStyles(),
      {
        fontSize: 14,
        lineHeight: 20,
        color: props.theme.palette.themePrimary,
      },
    ],
    itemLinkText: [
      'mbc-product-support-panel-item-link-text',
      {
        marginLeft: 8,
      },
    ],
  };
};
