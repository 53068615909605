import HttpClient from './http-client/httpClient';
import { Dataset } from '../store/types/customML/dataset';
import { ListRequestBase } from './dto/custom-ml/ListRequestBase';
import { ListDatasetsResponse } from './dto/custom-ml/Dataset/DatasetList';
import { DatasetPushResponse, DatasetCreateResponse } from './dto/custom-ml/Dataset/Dataset';
import {
  mapToDatasetCreateRequestDto,
  mapListDatasetDtoToModel,
  mapListCookedJobDtoToModel,
  mapToCookingJobCreateRequestDto,
  mapListSearchModelDtoToModel,
  mapListTrainingJobDtoToModel,
  mapJobMetricGraphsDtoToModel,
  mapToSearchModelCreateRequestDto,
  mapToTrainingJobCreateRequestDto,
  mapListDeploymentJobDtoToModel,
  mapToDeploymentJobCreateRequestDto,
  mapListEvaluationJobDtoToModel,
  mapToEvaluationJobCreateRequestDto,
  mapEvaluationMetricsDtoToModel,
  mapTrainingJobDtoToModel,
  mapDeploymentJobDtoToModel,
  mapEvaluationJobDtoToModel,
} from './mappers/customMLMapper';
import { CookingJob } from '../store/types/customML/cookingJob.d';
import { ListJobResponse } from './dto/custom-ml/Job/JobList';
import {
  CreateJobResponse,
  JobType,
  GetTrainingJobMetircsResponse,
  GetEvaluationJobMetricsResponse,
  GetJobResponse,
} from './dto/custom-ml/Job/Job';
import { SearchModel } from '../store/types/customML/searchModel.d';
import { ListSuperJobsResponse } from './dto/custom-ml/SuperJob/SuperJobList';
import { TrainingJob, TrainingMetric } from '../store/types/customML/trainingJob.d';
import { CreateSuperJobResponse } from './dto/custom-ml/SuperJob/SuperJob';
import { EvaluationJob, Evaluation } from '../store/types/customML/evaluationJob';
import { DeploymentJob } from '../store/types/customML/deploymentJob';
import { ResponseBase } from './dto/custom-ml/ResponseBase';

const customMLServiceBaseUri = (tenantId: string) => `https://commerce.bing.com/api/ml/v1/${tenantId}`;

/**
 * @method cancel custom ml job.
 * @description canceling a custom ml job.
 * @param {string} tenantId current custom ML tenant id.
 * @param {string} jobId current custom ML job id.
 * @returns {Promise<void>} A promise of the custom ml canceling job.
 */
export function cancelCustomMLJob(tenantId: string, jobId: string): Promise<void> {
  return HttpClient.delete<ResponseBase>({
    url: `${customMLServiceBaseUri(tenantId)}/job/${jobId}/cancel`,
    body: {},
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then();
}

/**
 * @method delete local dataset file.
 * @description upload a local dataset file.
 * @param {string} tenantId current custom ML tenant id.
 * @param {string} datasetId current custom ML dataset id.
 * @returns {Promise<string>} A promise of void.
 */
export function deleteRawDataset(tenantId: string, datasetId: string): Promise<void> {
  return HttpClient.post<ResponseBase>({
    url: `${customMLServiceBaseUri(tenantId)}/dataset/${datasetId}`,
    body: {},
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then();
}

/**
 * @method push local dataset file.
 * @description upload a local dataset file.
 * @param {string} tenantId current custom ML tenant id.
 * @returns {Promise<string>} A promise of the update if for the uploaded dataset file.
 */
function uploadRawDatasetFile(tenantId: string, file: File): Promise<string> {
  return HttpClient.post<DatasetPushResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/dataset/push`,
    body: file,
    // This is to prevent the HttpClient from sending the default content-type header
    headers: new Headers(),
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(response => response.updateId);
}

/**
 * @method create dataset entity.
 * @description creates a dataset.
 * @param {string} tenantId current custom ML tenant id.
 * @param {string} updateId current custom ML dataset update id.
 * @returns {Promise<Dataset>} A promise of created dataset.
 */
export function createRawDataset(tenantId: string, file: File, dataset: Dataset): Promise<Dataset> {
  return uploadRawDatasetFile(tenantId, file).then(updateId => {
    return HttpClient.post<DatasetCreateResponse>({
      url: `${customMLServiceBaseUri(tenantId)}/dataset`,
      body: mapToDatasetCreateRequestDto(updateId, dataset),
      authInfo: {
        tenantId: tenantId,
      },
      supressNotificationOnFailure: true,
    }).then(response => ({
      ...dataset,
      key: response.datasetId,
    }));
  });
}

/**
 * @method list dataset entities.
 * @description lists all datasets.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML dataset filter config.
 * @returns {Promise<Dataset[]>} A promise of created dataset list.
 */
export function listRawDatasets(
  tenantId: string,
  options: ListRequestBase = {
    top: 100,
    skip: 0,
    orderBys: [
      {
        fieldName: 'createTimeUTC',
        isASC: false,
      },
    ],
  },
): Promise<Dataset[]> {
  return HttpClient.post<ListDatasetsResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/datasets/list`,
    body: {
      ...options,
      filters: {},
    },
    authInfo: {
      tenantId: tenantId,
    },
  }).then(mapListDatasetDtoToModel);
}

/**
 * @method list cooked jobs.
 * @description lists all cooked jobs.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML cooked jobs filter config.
 * @returns {Promise<CookingJob[]>} A promise of created cooked job list.
 */
export function listCookedJobs(
  tenantId: string,
  options: ListRequestBase = {
    top: 100,
    skip: 0,
    orderBys: [
      {
        fieldName: 'createTimeUTC',
        isASC: false,
      },
    ],
  },
  supressNotificationOnFailure?: boolean,
): Promise<CookingJob[]> {
  return HttpClient.post<ListJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/jobs/list`,
    body: {
      ...options,
      filters: {
        JobTypeFilter: [JobType.LightGBMCookingJob],
      },
    },
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure,
  }).then(mapListCookedJobDtoToModel);
}

/**
 * @method create cook job.
 * @description create cook job.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML cooked job filter config.
 * @returns {Promise<CookingJob[]>} A promise of created cooked job.
 */
export function createCookingJob(tenantId: string, searchModelKey: string, cookJob: CookingJob): Promise<CookingJob> {
  return HttpClient.post<CreateJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job`,
    body: mapToCookingJobCreateRequestDto(searchModelKey, cookJob),
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(response => ({ ...cookJob, key: response.jobId }));
}

/**
 * @method post cook job list.
 * @description create cook job list.
 * @param {string} tenantId current custom ML tenant id.
 * @returns {Promise<CookingJob[]>} A promise of created cooked job list.
 */
export function createCookingJobList(
  tenantId: string,
  searchModelKey: string,
  cookJobs: CookingJob[],
): Promise<CookingJob[]> {
  return Promise.all(cookJobs.map(cookJob => createCookingJob(tenantId, searchModelKey, cookJob)));
}

/**
 * @method list super job list.
 * @description list super job list.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML search model filter config.
 * @returns {Promise<SearchModel[]>} A promise of created search model list.
 */
export function listSearchModels(
  tenantId: string,
  options: ListRequestBase = {
    top: 100,
    skip: 0,
    orderBys: [
      {
        fieldName: 'createTimeUTC',
        isASC: false,
      },
    ],
  },
  supressNotificationOnFailure?: boolean,
): Promise<SearchModel[]> {
  return HttpClient.post<ListSuperJobsResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/superjobs/list`,
    body: {
      ...options,
      filters: {},
    },
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure,
  }).then(mapListSearchModelDtoToModel);
}

/**
 * @method POST search model.
 * @description create search model entity.
 * @param {string} tenantId current custom ML tenant id.
 * @param {SearchModel} searchModel custom ML search model to be created.
 * @returns {Promise<SearchModel>} A promise of created search model.
 */
export function createSearchModel(tenantId: string, searchModel: SearchModel): Promise<SearchModel> {
  return HttpClient.post<CreateSuperJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/superjob`,
    body: mapToSearchModelCreateRequestDto(searchModel),
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(response => ({ ...searchModel, key: response.superJobId }));
}

/**
 * @method list trained jobs.
 * @description lists all trained jobs.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML trained jobs filter config.
 * @returns {Promise<TrainingJob[]>} A promise of created trained job list.
 */
export function listTrainingJobs(
  tenantId: string,
  options: ListRequestBase = {
    top: 100,
    skip: 0,
    orderBys: [
      {
        fieldName: 'createTimeUTC',
        isASC: false,
      },
    ],
  },
  supressNotificationOnFailure?: boolean,
): Promise<TrainingJob[]> {
  return HttpClient.post<ListJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/jobs/list`,
    body: {
      ...options,
      filters: {
        JobTypeFilter: [JobType.LightGBMTrainingJob],
      },
    },
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure,
  }).then(mapListTrainingJobDtoToModel);
}

/**
 * @method get trained job.
 * @description get trained job.
 * @param {string} tenantId current custom ML tenant id.
 * @returns {Promise<TrainingJob>} A promise of created trained job.
 */
export function getTrainingJob(tenantId: string, trainingJobKey: string): Promise<TrainingJob> {
  return HttpClient.get<GetJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job/${trainingJobKey}`,
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(dto => mapTrainingJobDtoToModel(dto.jobInfo));
}

/**
 * @method create train job.
 * @description create train job.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML trained job filter config.
 * @returns {Promise<TrainingJob[]>} A promise of created trained job.
 */
export function createTrainingJob(
  tenantId: string,
  searchModelKey: string,
  trainingJob: TrainingJob,
): Promise<TrainingJob> {
  return HttpClient.post<CreateJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job`,
    body: mapToTrainingJobCreateRequestDto(searchModelKey, trainingJob),
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(response => ({ ...trainingJob, key: response.jobId }));
}

/**
 * @method post train job list.
 * @description create train job list.
 * @param {string} tenantId current custom ML tenant id.
 * @returns {Promise<CookingJob[]>} A promise of created trained job list.
 */
export function createTrainingJobList(
  tenantId: string,
  searchModelKey: string,
  trainingJobList: TrainingJob[],
): Promise<TrainingJob[]> {
  return Promise.all(trainingJobList.map(trainingJob => createTrainingJob(tenantId, searchModelKey, trainingJob)));
}

/**
 * @method GET training job metrics.
 * @description gets a training job metric graphs.
 * @param {string} tenantId current custom ML tenant id.
 * @returns {Promise<TrainingMetric[]>} A promise of created trained job metrics.
 */
export function getTrainingJobMetrics(tenantId: string, trainingJobKey: string): Promise<TrainingMetric[]> {
  return HttpClient.get<GetTrainingJobMetircsResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job/${trainingJobKey}/allmetricgraphs`,
    authInfo: {
      tenantId: tenantId,
    },
  }).then(mapJobMetricGraphsDtoToModel);
}

/**
 * @method list evaluation jobs.
 * @description lists all evaluation jobs.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML evaluation jobs filter config.
 * @returns {Promise<DeploymentJob[]>} A promise of evaluation job list.
 */
export function listDeploymentJobs(
  tenantId: string,
  options: ListRequestBase = {
    top: 100,
    skip: 0,
    orderBys: [
      {
        fieldName: 'createTimeUTC',
        isASC: false,
      },
    ],
  },
  supressNotificationOnFailure?: boolean,
): Promise<DeploymentJob[]> {
  return HttpClient.post<ListJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/jobs/list`,
    body: {
      ...options,
      filters: {
        JobTypeFilter: [JobType.LightGBMDeploymentJob],
      },
    },
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure,
  }).then(mapListDeploymentJobDtoToModel);
}

/**
 * @method get deployment job.
 * @description get deployment job.
 * @param {string} tenantId current custom ML tenant id.
 * @returns {Promise<TrainingJob>} A promise of created deployment job.
 */
export function getDeploymentJob(tenantId: string, deploymentJobKey: string): Promise<DeploymentJob> {
  return HttpClient.get<GetJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job/${deploymentJobKey}`,
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(dto => mapDeploymentJobDtoToModel(dto.jobInfo));
}

/**
 * @method create evaluation job.
 * @description create evaluation job.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML evaluation job filter config.
 * @returns {Promise<DeploymentJob[]>} A promise of created evaluation job.
 */
export function createDeploymentJob(
  tenantId: string,
  searchModelKey: string,
  deploymentJob: DeploymentJob,
): Promise<DeploymentJob> {
  return HttpClient.post<CreateJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job`,
    body: mapToDeploymentJobCreateRequestDto(searchModelKey, deploymentJob),
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(response => ({ ...deploymentJob, key: response.jobId }));
}

/**
 * @method list evaluation jobs.
 * @description lists all evaluation jobs.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML evaluation jobs filter config.
 * @returns {Promise<EvaluationJob[]>} A promise of evaluation job list.
 */
export function listEvaluationJobs(
  tenantId: string,
  options: ListRequestBase = {
    top: 100,
    skip: 0,
    orderBys: [
      {
        fieldName: 'createTimeUTC',
        isASC: false,
      },
    ],
  },
  supressNotificationOnFailure?: boolean,
): Promise<EvaluationJob[]> {
  return HttpClient.post<ListJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/jobs/list`,
    body: {
      ...options,
      filters: {
        JobTypeFilter: [JobType.LightGBMEvaluationJob],
      },
    },
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure,
  }).then(mapListEvaluationJobDtoToModel);
}

/**
 * @method get evaluation job.
 * @description get evaluation job.
 * @param {string} tenantId current custom ML tenant id.
 * @returns {Promise<TrainingJob>} A promise of created evaluation job.
 */
export function getEvaluationJob(tenantId: string, evaluationJobKey: string): Promise<EvaluationJob> {
  return HttpClient.get<GetJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job/${evaluationJobKey}`,
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(dto => mapEvaluationJobDtoToModel(dto.jobInfo));
}

/**
 * @method list evaluation jobs.
 * @description lists all evaluation jobs.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML evaluation jobs filter config.
 * @returns {Promise<EvaluationJob[]>} A promise of evaluation job list.
 */
export function getEvaluationJobMetrics(tenantId: string, evaluationJobKey: string): Promise<Evaluation[]> {
  return HttpClient.get<GetEvaluationJobMetricsResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job/${evaluationJobKey}/lightgbmevaluationresult`,
    authInfo: {
      tenantId: tenantId,
    },
  }).then(mapEvaluationMetricsDtoToModel);
}

/**
 * @method create evaluation job.
 * @description create evaluation job.
 * @param {string} tenantId current custom ML tenant id.
 * @param {ListRequestBase} options current custom ML evaluation job filter config.
 * @returns {Promise<DeploymentJob[]>} A promise of created evaluation job.
 */
export function createEvaluationJob(
  tenantId: string,
  searchModelKey: string,
  evaluationJob: EvaluationJob,
): Promise<EvaluationJob> {
  return HttpClient.post<CreateJobResponse>({
    url: `${customMLServiceBaseUri(tenantId)}/job`,
    body: mapToEvaluationJobCreateRequestDto(searchModelKey, evaluationJob),
    authInfo: {
      tenantId: tenantId,
    },
    supressNotificationOnFailure: true,
  }).then(response => ({ ...evaluationJob, key: response.jobId }));
}
