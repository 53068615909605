import React, { Component } from 'react';
import { UserAgentApplication, Account } from 'msal';
import { AuthContext } from '../contexts';
import { AppState } from '../store/reducers';
import { setLoggedInStatus } from '../store/actions/authenticationActions';
import { setLoggedInUserDetails, setUserImage, setSandboxUserDetails } from '../store/actions/userActions';
import { connect } from 'react-redux';
import { getUserDetails as getGraphApiUserDetails, getProfilePicture } from '../restful-apis/graph.api';
import { createUser as createAdminServiceUser, getUserDetails as getAdminServiceUser } from '../restful-apis/me.api';
import Loading from '../components/Loading/LoadingSpinner';

interface AuthProviderProps {
  children: React.ReactNode;
  setLoggedInStatus: Function;
  isLoggedIn: boolean;
  setSandboxUserDetails: Function;
  setLoggedInUserDetails: Function;
  setUserImage: Function;
}

interface AuthProviderState {
  isInitialized: boolean;
}

declare global {
  interface Window {
    getAccessToken(): Promise<string>;
    getIdToken(): Promise<string>;
  }
}

export class AuthProviderBase extends Component<AuthProviderProps, AuthProviderState> {
  userAgentApplication: UserAgentApplication;

  constructor(props: AuthProviderProps) {
    super(props);
    this.state = {
      isInitialized: false,
    };
    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID as string,
        // RedirectUri should be set explicitly to avoid default value (window.location.href)
        // That might not exactly match values set for AAD application
        redirectUri: window.location.origin + process.env.REACT_APP_HOME_PAGE_URL,
        // MSAL library by default navigates to the requesting URL after Authentication success
        navigateToLoginRequestUrl: true,

        // RedirectUri should be set explicitly to avoid origin post logout
        postLogoutRedirectUri: window.location.origin + process.env.REACT_APP_HOME_PAGE_URL,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });
    this.userAgentApplication.handleRedirectCallback(() => {});
    window.getAccessToken = () => {
      return this.getAccessToken();
    };
    window.getIdToken = () => {
      return this.getIdToken();
    };
  }

  componentDidMount() {
    this.setAuthenticationStatusAsync();
  }

  login() {
    if (this.props.isLoggedIn) {
      return;
    }

    this.loginRedirect();
  }

  loginRedirect() {
    this.userAgentApplication.loginRedirect({
      scopes: [process.env.REACT_APP_AAD_READ_BASIC_SCOPE as string],
      prompt: 'select_account',
    });
  }

  logout() {
    this.userAgentApplication.logout();
  }

  async getIdTokenInternal(forceRefresh: boolean = false): Promise<string> {
    // Get the Id token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    return await this.userAgentApplication
      .acquireTokenSilent({
        scopes: [process.env.REACT_APP_AAD_CLIENT_ID as string],
        forceRefresh: forceRefresh,
      })
      .then(response => response.idToken.rawIdToken);
  }

  async getFreshIdToken(): Promise<string> {
    // Get the Id token silently
    // it will make a request to the Azure OAuth endpoint to get a token
    // and force updating token local cache
    return this.getIdTokenInternal(true).catch(() => {
      // Clear browser local storage to avoid any cache collisions
      // Call loginRedirect interactively in case of acquireTokenSilent failure
      // Due to consent or interaction required
      window.localStorage.clear();
      this.loginRedirect();
      return '';
    });
  }

  async getIdToken(): Promise<string> {
    // Get the Id token silently
    return this.getIdTokenInternal().catch(() => {
      return this.getFreshIdToken();
    });
  }

  async getAccessToken() {
    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    return await this.userAgentApplication
      .acquireTokenSilent({
        scopes: [process.env.REACT_APP_AAD_READ_BASIC_SCOPE as string],
      })
      .then(response => response.accessToken);
  }

  async setAuthenticationStatusAsync() {
    try {
      var account = this.userAgentApplication.getAccount();
      var accessToken = await this.getAccessToken();
      await this.getLoggedInUserProfile(account, accessToken);
    } catch (exception) {
      /* Swallow any unhandled authentication exception
       * Those unhandled exceptions will not set loggedIn flag to true
       * User will be redirected to homepage
       */
    } finally {
      /* Allow user to access homepage in all cases */
      this.setState({ isInitialized: true });
    }
  }

  async getLoggedInUserProfile(account: Account, accessToken: string): Promise<void> {
    await Promise.all([
      this.handleGraphApiUserDetails(account, accessToken),
      this.handleGraphApiProfilePicture(accessToken),
      this.handleReduxUserDetails(true, accessToken),
    ]);
  }

  async handleGraphApiUserDetails(account: Account, accessToken: string): Promise<void> {
    var email = '';
    var initials = '';
    var displayName = '';
    var secondaryEmail = '';
    try {
      var user = await getGraphApiUserDetails(accessToken);
      displayName = user.displayName;
      email = user.mail || user.userPrincipalName;
      secondaryEmail = user.userPrincipalName || user.email;
      initials = user.givenName && user.surname ? user.givenName[0] + user.surname[0] : '';
    } catch (exception) {
      /* Fallback to account details */
      displayName = account.name ? account.name : '';
      email = account.userName ? account.userName : '';
      secondaryEmail = account.userName ? account.userName : '';
      initials = account.name
        ? account.name
            .split(' ')
            .map(x => x[0]) // Only take the first character of each name
            .join('')
        : '';
    } finally {
      this.props.setLoggedInUserDetails(displayName, email, secondaryEmail, initials);
    }
  }

  async handleReduxUserDetails(createIfNotFound = true, aadAccessToken: string) {
    try {
      var user = await getAdminServiceUser();
      this.props.setSandboxUserDetails(user);
      this.props.setLoggedInStatus(true);
    } catch (response) {
      if (response.content.status === 404 && createIfNotFound) {
        await createAdminServiceUser(aadAccessToken);
        // TODO: better handle this when the APIs force update
        // this.props.setSandboxUserDetails(createdUser);
        // this.props.setLoggedInStatus(true);
      }
      // This delay and check again are needed to handle if the
      // created user is not replicated to the current region
      await new Promise(resolve => setTimeout(resolve, 1000));
      await this.handleReduxUserDetails(false, aadAccessToken);
    }
  }

  async handleGraphApiProfilePicture(accessToken: string): Promise<void> {
    try {
      var response = await getProfilePicture(accessToken);
      var reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        this.props.setUserImage(reader.result);
      };
    } catch (exception) {
      /* Swallow Graph Api get profile pictures exceptions */
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          login: () => this.login(),
          logout: () => this.logout(),
        }}
      >
        {this.state.isInitialized && this.props.children}
        {!this.state.isInitialized && <Loading fullPage />}
      </AuthContext.Provider>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
});

export default connect(
  mapStateToProps,
  { setLoggedInStatus, setLoggedInUserDetails, setUserImage, setSandboxUserDetails },
)(AuthProviderBase);
