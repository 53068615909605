import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import {
  Notification,
  OnSuccess,
  OnFailure,
  UPDATE_NOTIFICATION,
  NotificationStatus,
  NotificationWithContext,
  NotificationsCenterActionTypes,
  DISMISS_NOTIFICATION,
  DISMISS_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  PUSH_SINGLE_NOTIFICATION,
  SET_IS_NOTIFICATIONS_PANEL_OPEN,
} from '../types/notificationCenter.d';
import { generateUniqueIdentifier } from '../../utils';
import { showToast, updateToast, removeToast } from './toastNotificationActions';
import { toast } from 'react-toastify';
import { getI18n } from 'react-i18next';
import moment from 'moment';

export const setIsNotificationsPanelOpen = (isPanelOpen: boolean): NotificationsCenterActionTypes => {
  return {
    type: SET_IS_NOTIFICATIONS_PANEL_OPEN,
    isPanelOpen: isPanelOpen,
  };
};

export const updateNotification = (
  notification: NotificationWithContext,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    const i18n = getI18n();
    if (!notification.hideToast) {
      dispatch(
        updateToast({
          id: notification.id,
          title: notification.title,
          subtitle: notification.primaryButtonProps ? i18n.t('notifications.toast-action-message') : undefined,
          hasAction: !!notification.primaryButtonProps,
          errorMessage: notification.errorMessage,
          status: notification.status,
        }),
      );
    } else {
      toast.dismiss(notification.id);
      dispatch(removeToast(notification.id));
    }
    dispatch({
      type: UPDATE_NOTIFICATION,
      notification: notification,
    });
  };
};

export const pushSingleNotification = (
  notification: NotificationWithContext,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    const i18n = getI18n();

    !notification.hideToast &&
      dispatch(
        showToast({
          id: notification.id,
          title: notification.title,
          subtitle: notification.primaryButtonProps ? i18n.t('notifications.toast-action-message') : undefined,
          hasAction: !!notification.primaryButtonProps,
          errorMessage: notification.errorMessage,
          status: notification.status,
        }),
      );

    dispatch({
      type: PUSH_SINGLE_NOTIFICATION,
      notification: notification,
    });
  };
};

export const pushNotification = <T>(
  notification: Notification<T>,
  request: Promise<T>,
  onSuccess: OnSuccess<T>,
  onFailure: OnFailure,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    let uniqueId = generateUniqueIdentifier();

    let notificationInternal: NotificationWithContext = {
      ...notification.inProgress,
      id: uniqueId,
      creationTime: moment().format('LT'),
      status: NotificationStatus.InProgress,
      read: false,
    };

    dispatch(pushSingleNotification(notificationInternal));

    request
      .then((model: T) => {
        notificationInternal = {
          ...notification.success(model),
          status: NotificationStatus.Succeeded,
          id: notificationInternal.id,
          read: notificationInternal.read,
          creationTime: moment().format('LT'),
        };

        dispatch(updateNotification(notificationInternal));
        onSuccess(model);
      })
      .catch(error => {
        notificationInternal = {
          ...notification.failure(error),
          status: NotificationStatus.Failed,
          id: notificationInternal.id,
          read: notificationInternal.read,
          creationTime: moment().format('LT'),
          errorMessage: notification.supressErrorMessage ? undefined : error.failureMessage,
        };

        dispatch(updateNotification(notificationInternal));
        onFailure(error);
      });
  };
};

export const dismissNotification = (
  notification: NotificationWithContext,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    toast.dismiss(notification.id);
    dispatch(removeToast(notification.id));
    dispatch({
      type: DISMISS_NOTIFICATION,
      notification: notification,
    });
  };
};

export const dismissAllNotifications = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    getState().notificationsCenter.notifications.map(notification => {
      if (toast.isActive(notification.id)) {
        toast.dismiss(notification.id);
        dispatch(removeToast(notification.id));
      }
    });
    dispatch({
      type: DISMISS_ALL_NOTIFICATIONS,
    });
  };
};

export const readAllNotifications = (): NotificationsCenterActionTypes => {
  return {
    type: READ_ALL_NOTIFICATIONS,
  };
};
