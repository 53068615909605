import React, { useState, useCallback } from 'react';
import { SearchInstanceList } from './SearchInstanceList';
import {
  SearchInstanceListPanelProps,
  SearchInstanceListPanelStyleProps,
  SearchInstanceListPanelStyles,
  PanelAction,
} from './SearchInstanceListPanel.types';
import { classNamesFunction, TextField } from 'office-ui-fabric-react';
import { CustomPanel, FooterProps } from '../../../../../components/common/CustomPanel';
import { MbcSearchInstanceRouter } from '../../../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { useSelector, useDispatch } from '../../../../../store/hooks';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';
import {
  createSearchInstance,
  cloneSearchInstance,
  deleteSearchInstance,
} from '../../../../../store/actions/customizations/searchInstanceListActions';
import { useSearchInstanceList } from '../../../../../store/hooks/use-list/useSearchInstanceList';
import { DefaultSearchInstanceId } from '../../../../../store/types/customizations/searchInstanceList.d';
import { useAdminRouter } from '../../../../../store/hooks/use-router/useAdminRouter';

const getClassNames = classNamesFunction<SearchInstanceListPanelStyleProps, SearchInstanceListPanelStyles>();

export const SearchInstanceListPanelBase = (props: SearchInstanceListPanelProps) => {
  const { t, className, componentRef, styles, theme, isOpen, onDismiss } = props;

  const dispatch = useDispatch();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const routeKey = useSelector(state => state.router.location.state.mbcRouteKey);

  const classNames = getClassNames(styles, { theme: theme!, className: className });
  const [currentPanelAction, setCurrentPanelAction] = useState(PanelAction.List);
  const [targetSearchInstance, setTargetSearchInstance] = useState<string>('');
  const [srcSearchInstance, setSrcSearchInstance] = useState<string>('');

  const searchInstanceList = useSearchInstanceList();
  const adminRouteMapper = useAdminRouter();

  const getPanelHeader = useCallback((): string => {
    switch (currentPanelAction) {
      case PanelAction.List:
        return t('search-instance-list-panel.list-header');
      case PanelAction.Add:
        return t('search-instance-list-panel.add-header');
      case PanelAction.Clone:
        return t('search-instance-list-panel.clone-header');
      case PanelAction.Delete:
        return t('search-instance-list-panel.delete-header');
    }
  }, [currentPanelAction, t]);

  const getPanelBody = useCallback((): JSX.Element => {
    switch (currentPanelAction) {
      case PanelAction.List:
        return (
          <SearchInstanceList
            onAddItemClick={() => {
              setCurrentPanelAction(PanelAction.Add);
              setTargetSearchInstance(``);
            }}
            onDeleteItemClick={key => {
              setCurrentPanelAction(PanelAction.Delete);
              setTargetSearchInstance(key);
            }}
            onCloneItemClick={key => {
              setCurrentPanelAction(PanelAction.Clone);
              setSrcSearchInstance(key);
              setTargetSearchInstance(`${key} - clone`);
            }}
            onItemSelection={key => {
              // dispatch(setActiveSearchInstance(key));
              MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, key)(
                MbcRouteKey.BusinessRuleList,
              ).browserPush();
              onDismiss();
            }}
          />
        );
      case PanelAction.Add:
        return (
          <TextField
            required
            value={targetSearchInstance}
            label={'Name:'}
            onChange={(event, newValue) => setTargetSearchInstance(newValue || '')}
          />
        );
      case PanelAction.Clone:
        return (
          <TextField
            required
            value={targetSearchInstance}
            label={'Name:'}
            onChange={(event, newValue) => setTargetSearchInstance(newValue || '')}
          />
        );
      case PanelAction.Delete:
        return (
          <div>
            <p>
              Are you sure you want to delete <span style={{ fontWeight: 'bold' }}>{targetSearchInstance}</span>
            </p>
          </div>
        );
    }
  }, [activeEnvironment.id, activeTenant.id, currentPanelAction, onDismiss, targetSearchInstance]);

  const getPanelFooterProps = useCallback((): FooterProps | undefined => {
    switch (currentPanelAction) {
      case PanelAction.List:
        return undefined;
      case PanelAction.Add:
        return {
          confirmButtonText: 'Create',
          onSaveClick: () => {
            dispatch(createSearchInstance({ id: targetSearchInstance, name: targetSearchInstance }));
            setCurrentPanelAction(PanelAction.List);
          },
          onCancelClick: () => {
            setTargetSearchInstance('');
            setCurrentPanelAction(PanelAction.List);
          },
        };
      case PanelAction.Clone:
        return {
          confirmButtonText: 'Clone',
          onSaveClick: () => {
            dispatch(cloneSearchInstance(srcSearchInstance, targetSearchInstance, undefined));
            setCurrentPanelAction(PanelAction.List);
          },
          onCancelClick: () => {
            setTargetSearchInstance('');
            setCurrentPanelAction(PanelAction.List);
          },
        };
      case PanelAction.Delete:
        return {
          confirmButtonText: 'Delete',
          onSaveClick: () => {
            dispatch(deleteSearchInstance(targetSearchInstance));
            if (activeSearchInstance.id === targetSearchInstance) {
              const caseInsensitiveInstanceIds = searchInstanceList.map(searchInstance =>
                searchInstance.id.toLowerCase(),
              );
              const redirectInstanceId = caseInsensitiveInstanceIds.includes(DefaultSearchInstanceId)
                ? DefaultSearchInstanceId
                : caseInsensitiveInstanceIds.find(id => id !== targetSearchInstance);

              !!redirectInstanceId
                ? MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, redirectInstanceId)(
                    routeKey,
                  ).browserPush()
                : adminRouteMapper(MbcRouteKey.EnvironmentList).browserPush();
            }
            setCurrentPanelAction(PanelAction.List);
          },
          onCancelClick: () => {
            setCurrentPanelAction(PanelAction.List);
            setTargetSearchInstance('');
          },
        };
    }
  }, [
    activeEnvironment.id,
    activeSearchInstance.id,
    activeTenant.id,
    adminRouteMapper,
    currentPanelAction,
    dispatch,
    routeKey,
    searchInstanceList,
    srcSearchInstance,
    targetSearchInstance,
  ]);

  const onDismissHandler = useCallback(() => {
    setCurrentPanelAction(PanelAction.List);
    onDismiss();
  }, [onDismiss]);
  return (
    <div ref={componentRef} className={classNames.root}>
      <CustomPanel
        isOpen={isOpen}
        headerText={getPanelHeader()}
        onDismiss={onDismissHandler}
        isHiddenOnDismiss={true}
        footer={getPanelFooterProps()}
        lightDismiss={currentPanelAction === PanelAction.List}
      >
        {getPanelBody()}
      </CustomPanel>
    </div>
  );
};
