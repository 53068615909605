import { getI18n } from 'react-i18next';
import { SearchModel, ADD_SEARCH_MODEL } from '../../../../types/customML/searchModel.d';
import { ActionSuccessHandler, ReduxCrudAction, ActionFailureHandler } from '../../CrudAction.types';
import { createSearchModel } from '../../../../../restful-apis/customML.api';

export const createSearchModelAction = (
  tenantId: string,
  searchModel: SearchModel,
  onSuccess?: ActionSuccessHandler<SearchModel>,
  onFailure?: ActionFailureHandler<SearchModel>,
): ReduxCrudAction<SearchModel> => {
  const i18n = getI18n();
  return {
    request: createSearchModel(tenantId, searchModel),
    postActionMapper: createdSearchModel => ({
      type: ADD_SEARCH_MODEL,
      searchModel: { ...createdSearchModel, isNew: true },
    }),
    notification: {
      failure: () => ({
        title: i18n.t('custom-ml.model-list.req-failure', { name: searchModel.name }),
      }),
      inProgress: {
        title: i18n.t('custom-ml.model-list.req-inprogress', { name: searchModel.name }),
      },
      success: () => ({
        title: i18n.t('custom-ml.model-list.req-success', { name: searchModel.name }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, searchModel),
  };
};
