import React, { useCallback, useMemo } from 'react';
import { classNamesFunction, TextField, IComboBoxOption } from 'office-ui-fabric-react';
import {
  StringValueEditorProps,
  StringValueEditorStyleProps,
  StringValueEditorStyles,
} from './StringValueEditor.types';
import { useSelector } from '../../../../../store/hooks';
import { SpecialConditionFields, MarketOptions, LanguageOptions } from '../utils';
import { MbcCompoBox } from '../../../../../components/common/MbcCompoBox';

const getClassNames = classNamesFunction<StringValueEditorStyleProps, StringValueEditorStyles>();

export const StringValueEditorBase = (props: StringValueEditorProps) => {
  const { styles, theme, className, fieldName, value, disabled, onValueChange, onSubmit, onDismiss } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });
  const fieldValueSuggestionsMapper = useSelector(state => state.queryTester.fieldValueSuggestionsMapper);

  const options: IComboBoxOption[] = useMemo(() => {
    let options: IComboBoxOption[] = [];
    if (fieldName === SpecialConditionFields.language) {
      options = LanguageOptions;
    }
    if (fieldName === SpecialConditionFields.market) {
      options = MarketOptions;
    }

    if (!!fieldValueSuggestionsMapper[fieldName].id) {
      options = fieldValueSuggestionsMapper[fieldName].suggestions.map(suggestion => {
        return { key: suggestion, text: suggestion };
      });
    }
    return options;
  }, [fieldName, fieldValueSuggestionsMapper]);

  const selectedOptionKey = useMemo((): string | undefined => {
    const selectedOption = options.filter(option => option.key === value)[0];
    return selectedOption ? (selectedOption.key as string) : undefined;
  }, [options, value]);

  const onValueEditorKeyUp = useCallback(
    (key: string) => {
      key === 'Escape' && onDismiss && onDismiss();
      key === 'Enter' && onSubmit && onSubmit();
    },
    [onDismiss, onSubmit],
  );

  const getStringConditionValue = useCallback(() => {
    if (options.length > 0) {
      return (
        <MbcCompoBox
          compoBoxProps={{
            disabled: disabled,
            allowFreeform: true,
            selectedKey: selectedOptionKey,
            text: value || '',
            autoComplete: 'on',
            options: options,
            onKeyUp: ev => onValueEditorKeyUp(ev.key),
            onChange: (ev, option, index, value) => {
              const newValue = option ? (option.key as string) : value;
              onValueChange(newValue);
            },
          }}
        />
      );
    } else {
      return (
        <TextField
          value={value || ''}
          disabled={disabled}
          onChange={(event, newValue) => onValueChange(newValue)}
          onKeyUp={ev => onValueEditorKeyUp(ev.key)}
        />
      );
    }
  }, [disabled, onValueChange, onValueEditorKeyUp, options, selectedOptionKey, value]);

  return <div className={classNames.root}>{getStringConditionValue()}</div>;
};
