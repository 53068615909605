import { useSelector } from '..';
import { useMemo } from 'react';
import { Environment } from '../../types/environmentList';
import { useEnvironmentList } from './useEnvironmentList';
import { flat, distinct } from '../../../utils';

export const useRoleEnvironmentList = (): Environment[] => {
  const environmentList = useEnvironmentList();
  const userPrimaryEmail = useSelector(state => state.user.email);
  const roleIdMapper = useSelector(state => state.roleList.roleIdMapper);
  const userSecondaryEmail = useSelector(state => state.user.secondaryEmail);
  const environmentIdMapper = useSelector(state => state.environmentList.environmentIdMapper);

  const userRoles = useMemo(
    () =>
      Object.values(roleIdMapper).filter(
        r => !!r.users.find(u => u.email === userPrimaryEmail || u.email === userSecondaryEmail),
      ),
    [roleIdMapper, userPrimaryEmail, userSecondaryEmail],
  );

  const hasAccessToAllEnvironments = !!userRoles.find(r => r.hasAccessToAllEnvironments);

  /**
   * We only show all environments in two cases:
   *  1. You have at least a role with access to all environments
   *  2. The lookup for your email within roles returned no roles (maybe using your secondary email)
   */
  return useMemo(
    () =>
      hasAccessToAllEnvironments || userRoles.length === 0
        ? environmentList
        : distinct(flat(userRoles.map(r => r.environmentList)), (a, b) => a === b).map(e => environmentIdMapper[e]),
    [environmentIdMapper, environmentList, hasAccessToAllEnvironments, userRoles],
  );
};
