import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LinkProps } from './Link.types';

export const Link = (props: LinkProps) => {
  const { to, target, className, children } = props;
  return (
    <RouterLink target={target} to={to} className={className}>
      {children}
    </RouterLink>
  );
};
