import { RoleList as TenantRolesDTO } from '../dto/role/roleList';
import { Role as RoleDTO } from '../dto/role/role';
import { Role as RoleModel } from '../../store/types/roleList';
import { ImageStatus, UserMetaDataStatus } from '../../store/types/userList.d';

export function mapRoleDtoToModel(role: RoleDTO): RoleModel {
  return {
    id: role.id,
    name: role.name,
    users: role.users.map(email => ({
      email: email,
      displayName: '',
      jobTitle: '',
      initials: '',
      imageUrl: '',
      imageStatus: ImageStatus.NotInitialized,
      userStatus: UserMetaDataStatus.NotInitialized,
    })),
    createdAt: role.createdAt,
    createdBy: role.createdBy,
    updatedAt: role.updatedAt,
    description: role.description,
    environmentList: role.environments,
    hasAccessToAllEnvironments: role.allEnvironments,
    permissions: role.permissions,
  };
}

export function mapRoleListDtoToSingleModel(rolesDTO: TenantRolesDTO): RoleModel {
  return rolesDTO.roles.map(mapRoleDtoToModel)[0];
}

export function mapRoleListDtoToModel(rolesDTO: TenantRolesDTO): RoleModel[] {
  return rolesDTO.roles.map(mapRoleDtoToModel);
}

export function mapRoleModelToDTO(role: RoleModel): RoleDTO {
  return {
    id: role.id,
    name: role.name,
    users: role.users.map(user => user.email),
    createdAt: role.createdAt,
    createdBy: role.createdBy,
    updatedAt: role.updatedAt,
    description: role.description,
    environments: role.environmentList,
    allEnvironments: role.hasAccessToAllEnvironments,
    permissions: role.permissions,
  };
}

export function mapRoleListModelToDto(roleList: RoleModel[]): TenantRolesDTO {
  return {
    roles: roleList.map(mapRoleModelToDTO),
  };
}
