import { ResponseCodesStyleProps, ResponseCodesStyles } from './ResponseCodes.types';

export const styles = (props: ResponseCodesStyleProps): ResponseCodesStyles => {
  return {
    root: [
      'mbc-serving-regions-chart',
      {
        selectors: {
          '.ms-footer': {
            justifyContent: 'flex-start',
          },
        },
      },
      props.className,
    ],
    modalFirstColumn: [
      'mbc-modal-first-column',
      {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: 'rgb(0, 120, 212)',
        fontWeight: 500,
      },
    ],
  };
};
