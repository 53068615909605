import { Tenant } from '../../store/types/tenantsList.d';
import { MbcRouteMap, MbcRouteKey } from '../MbcRouter.types';
import { SearchModel } from '../../store/types/customML/searchModel.d';
import { Environment as RuntimeEnvironment } from '../../guards/EnvironmentGuard/EnvironmentGuard';
import { EvaluationJob } from '../../store/types/customML/evaluationJob';
import { Permission } from '../../config/userPermissions.config';
import { SearchModelList } from '../../pages/CustomML/SearchModelList';
import { SearchModel as SearchModelPage } from '../../pages/CustomML/SearchModel/SearchModel';
import { DatasetList } from '../../pages/CustomML/DatasetList/DatasetList';
import { TrainSearchModel } from '../../pages/CustomML/SearchModel/TrainSearchModel';
import { EvaluationList } from '../../pages/CustomML/SearchModel/EvaluationList/EvaluationList';
import { EvaluateSearchModel } from '../../pages/CustomML/SearchModel/EvaluationList/EvaluateSearchModel/EvaluateSearchModel';
import { AppRoute } from '../router.service';

export const MbcCustomMLRouteMapper = (tenant: Tenant, searchModel: SearchModel): MbcRouteMap => ({
  [MbcRouteKey.SearchModelList]: {
    Component: SearchModelList,
    key: MbcRouteKey.SearchModelList,
    url: AppRoute.append(`tenants/${tenant.id}/models`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SearchModel]: {
    Component: SearchModelPage,
    key: MbcRouteKey.SearchModel,
    url: AppRoute.append(`tenants/${tenant.id}/models/${searchModel.key}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomMLRead],
  },
  [MbcRouteKey.DatasetList]: {
    Component: DatasetList,
    key: MbcRouteKey.DatasetList,
    url: AppRoute.append(`tenants/${tenant.id}/models/${searchModel.key}/datasets`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomMLRead],
  },
  [MbcRouteKey.TrainingJob]: {
    Component: TrainSearchModel,
    key: MbcRouteKey.TrainingJob,
    url: AppRoute.append(`tenants/${tenant.id}/models/${searchModel.key}/train`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomMLRead],
  },
  [MbcRouteKey.EvaluationJobList]: {
    Component: EvaluationList,
    key: MbcRouteKey.EvaluationJobList,
    url: AppRoute.append(`tenants/${tenant.id}/models/${searchModel.key}/evals`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomMLRead],
  },
});

export const MbcCustomMLEvaluationRouteMapper = (
  tenant: Tenant,
  searchModel: SearchModel,
  evaluationJob: EvaluationJob,
): MbcRouteMap => ({
  [MbcRouteKey.EvaluationJob]: {
    Component: EvaluateSearchModel,
    key: MbcRouteKey.EvaluationJob,
    url: AppRoute.append(`tenants/${tenant.id}/models/${searchModel.key}/evals/${evaluationJob.key}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomMLRead],
  },
});
