import React from 'react';
import AggregatedDataList from '../Common/aggregated-data-list/aggregatedDataList';
import { AppState } from '../../../../store/reducers';
import { classNamesFunction } from 'office-ui-fabric-react';
import {
  NoResultsQueriesStyleProps,
  NoResultsQueriesStyles,
  NoResultsQueriesBaseProps,
} from './NoResultsQueries.types';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { SetNoResultsQueries } from '../../../../store/actions/analyticsActions';

const getClassNames = classNamesFunction<NoResultsQueriesStyleProps, NoResultsQueriesStyles>();

export const NoResultsQueriesBase = (props: NoResultsQueriesBaseProps) => {
  const { t, className, theme, styles } = props;

  const dispatch = useDispatch();

  const noResultsQueries = useSelector((state: AppState) => state.analytics.noResultsQueries);
  const isNoResultsQueriesLoading = useSelector((state: AppState) => state.analytics.isNoResultsQueriesLoading);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div className={classNames.root}>
      <AggregatedDataList
        title={t('analytics.no-results-queries')}
        data={noResultsQueries}
        isDataLoading={isNoResultsQueriesLoading}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
        ) =>
          dispatch(
            SetNoResultsQueries(responseCode, 100, start, end, markets, regions, searchResultsStatus, searchInstances),
          )
        }
      ></AggregatedDataList>
    </div>
  );
};
