import { MultiLineChartStyleProps, MultiLineChartStyles } from './MultiLineChart.types';

export const styles = (props: MultiLineChartStyleProps): MultiLineChartStyles => ({
  root: [
    'mbc-multi-line-chart',
    {
      width: '100%',
      minHeight: 200,
      paddingBottom: 16,
    },
    props.className,
  ],
  footerContainer: [
    'mbc-footer-container',
    {
      paddingTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
      width: '100%',
    },
  ],
  legendContainer: [
    'mbc-legend-container',
    {
      width: 'max-content',
    },
  ],
  legendIcon: [
    'mbc-legend-icon',
    {
      height: 13,
      width: 13,
    },
  ],
  legendText: [
    'mbc-legend-text',
    {
      fontSize: 12,
      lineHeight: 14,
    },
  ],
});
