import { ProductManagementStyleProps, ProductManagementStyles } from './ProductManagement.types';
import { getFlexRowStyles } from '../../../../components/common/Styles';

export const styles = (props: ProductManagementStyleProps): ProductManagementStyles => ({
  root: [
    'mbc-root',
    {
      display: 'flex',
    },
  ],
  action: [
    'mbc-action',
    {
      fontSize: 14,
      height: 32,
    },
    getFlexRowStyles(),
    {
      justifyContent: 'center',
      alignItems: 'center',
    },
  ],
  subComponentStyles: {
    modal: {
      root: {
        selectors: {
          '.ms-Overlay': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
      main: [
        'mbc-main-modal',
        {
          position: 'absolute',
          left: props.left,
          width: props.width,
          maxHeight: 'fit-content',
          minHeight: 'fit-content',
        },
      ],
      scrollableContent: [
        'mbc-modal-content',
        {
          overflow: 'auto',
          maxHeight: 'calc(100vh - 32px)',
        },
      ],
      layer: ['mbc-overlay'],
    },
  },
});
