/* eslint-disable react/prop-types */
import { classNamesFunction, getId, Stack, IconButton, TeachingBubble } from 'office-ui-fabric-react';
import React, { useState } from 'react';
import { InfoCardProps } from '.';
import { styles } from './InfoCard.styles';
import { InfoCardStyleProps, InfoCardStyles } from './InfoCard.types';
import { Link } from '../RouterLink';

const getClassNames = classNamesFunction<InfoCardStyleProps, InfoCardStyles>();

export function InfoCardBase(props: InfoCardProps) {
  const iconButtonId = getId('infoIconButtonId');
  const classNames = getClassNames(styles, { theme: props.theme!, className: props.className });
  const [isBubbleHidden, setIsBubbleHidden] = useState(true);

  return (
    <>
      <Stack horizontal horizontalAlign={'start'} className={classNames.root}>
        <IconButton
          id={iconButtonId}
          iconProps={{
            iconName: 'Info',
          }}
          onClick={() => {
            setIsBubbleHidden(false);
          }}
          onMouseOver={() => {
            setIsBubbleHidden(false);
          }}
        ></IconButton>
      </Stack>

      {!isBubbleHidden && (
        <TeachingBubble
          target={`#${iconButtonId}`}
          onDismiss={() => {
            setIsBubbleHidden(true);
          }}
          hasCloseIcon={true}
          footerContent={
            props.linkProps ? (
              <Link to={props.linkProps.to} target="_blank">
                {props.linkProps.linkText}
              </Link>
            ) : (
              undefined
            )
          }
          headline={props.headline ? props.headline : undefined}
          calloutProps={{ className: classNames.callout }}
        >
          {props.content ? props.content : null}
        </TeachingBubble>
      )}
    </>
  );
}
