import {
  classNamesFunction,
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  Separator,
  Stack,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import React, { Fragment, useState, useEffect } from 'react';
import { PageTemplate } from '../../../components/common/PageTemplate';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { getRegionLocaleMapper } from '../../../config/regions.config';
import { TenantProductGuard } from '../../../guards/ProductGuard/TenantProductGuard';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { useDispatch, useSelector } from '../../../store/hooks';
import { useAdminRouter } from '../../../store/hooks/use-router/useAdminRouter';
import { useEnvironmentRouter } from '../../../store/hooks/use-router/useEnvironmentRouter';
import { Product } from '../../../store/types/tenantsList.d';
import {
  EnvironmentEditorProps,
  EnvironmentEditorStyleProps,
  EnvironmentEditorStyles,
} from './EnvironmentEditor.types';
import { useEnvironmentRoleList } from '../../../store/hooks/use-list/useEnvironmentRoleList';
import { Role } from '../../../store/types/roleList.d';
import { RoleList } from './RoleList';
import {
  createEnvironmentWithRoleList,
  updateEnvironmentWithRoleList,
} from '../../../store/actions/environmentWithRoleListActions';
import { useBreadcrumb } from '../../../store/hooks/use-breadcrumb/useBreadcrumb';
import { Prompt } from 'react-router-dom';

const getClassNames = classNamesFunction<EnvironmentEditorStyleProps, EnvironmentEditorStyles>();

export const EnvironmentEditorBase = (props: EnvironmentEditorProps) => {
  const { t, styles } = props;

  const adminRouter = useAdminRouter();
  const envRouter = useEnvironmentRouter();

  const dispatch = useDispatch();

  const environmentRoleList = useEnvironmentRoleList();
  const tenantId = useSelector(state => state.tenantsList.activeTenant.id);
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);

  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [editableEnvironment, setEditableEnvironment] = useState({
    ...activeEnvironment,
    products: !!activeEnvironment.products.length ? activeEnvironment.products : [activeTenant.products[0]],
    regions: !!activeEnvironment.regions.length ? activeEnvironment.regions : [activeTenant.regions[0]],
  });
  const [roleList, setRoleList] = useState(environmentRoleList);

  const classNames = getClassNames(styles, {
    theme: props.theme!,
    className: props.className,
    regionsListEnabled: editableEnvironment.regions.length > 0,
  });

  useEffect(() => {
    setRoleList(environmentRoleList);
  }, [environmentRoleList]);

  useEffect(() => {
    setEditableEnvironment({
      ...activeEnvironment,
      products: !!activeEnvironment.products.length
        ? activeEnvironment.products
        : activeTenant.products.includes(Product.TextSearch)
        ? [Product.TextSearch]
        : [activeTenant.products[0]],
      regions: !!activeEnvironment.regions.length ? activeEnvironment.regions : [activeTenant.regions[0]],
    });
  }, [activeEnvironment, activeTenant.products, activeTenant.regions]);

  useEffect(() => {
    const updated =
      editableEnvironment.name !== activeEnvironment.name ||
      editableEnvironment.description !== activeEnvironment.description ||
      !(!!activeEnvironment.id
        ? editableEnvironment.products.every(product => activeEnvironment.products.includes(product)) &&
          editableEnvironment.products.length === activeEnvironment.products.length
        : editableEnvironment.products.length &&
          (activeTenant.products.includes(Product.TextSearch)
            ? editableEnvironment.products[0] === Product.TextSearch && editableEnvironment.products.length === 1
            : editableEnvironment.products[0] === activeTenant.products[0])) ||
      !(!!activeEnvironment.id
        ? editableEnvironment.regions.every(region => activeEnvironment.regions.includes(region)) &&
          editableEnvironment.regions.length === activeEnvironment.regions.length
        : editableEnvironment.regions.length && editableEnvironment.regions[0] === activeTenant.regions[0]) ||
      !(
        roleList.map(role => role.id).every(role => environmentRoleList.map(envRole => envRole.id).includes(role)) &&
        roleList.length === environmentRoleList.length
      );

    if (updated !== isUpdated) {
      setIsUpdated(updated);
    }

    updated ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);

    return () => {
      window.onbeforeunload = null;
    };
  }, [
    activeEnvironment,
    activeTenant.products,
    activeTenant.regions,
    editableEnvironment,
    environmentRoleList,
    isUpdated,
    roleList,
  ]);

  useEffect(() => {
    if (isSaveClicked) {
      adminRouter(MbcRouteKey.EnvironmentList).browserPush();
    }
  }, [adminRouter, isSaveClicked]);

  useEffect(() => {
    const disabled =
      !isUpdated ||
      !editableEnvironment.name ||
      !editableEnvironment.description ||
      !editableEnvironment.products.length ||
      !editableEnvironment.regions.length ||
      isSaveClicked;

    if (disabled !== isSaveDisabled) {
      setIsSaveDisabled(disabled);
    }
  }, [
    editableEnvironment.description,
    editableEnvironment.name,
    editableEnvironment.products.length,
    editableEnvironment.regions.length,
    isSaveClicked,
    isSaveDisabled,
    isUpdated,
  ]);

  const breadCrumbKey = activeEnvironment.id ? '' : t('environment-editor.breadcrumb-key-create');
  useBreadcrumb(breadCrumbKey, props.match.url);

  const onProductSelectionChange = (targetProduct: Product, checked?: boolean) => {
    let productsList = editableEnvironment.products;
    if (checked) {
      productsList = productsList.concat(targetProduct);
    } else {
      productsList = editableEnvironment.products.filter(x => x !== targetProduct);
    }

    setEditableEnvironment({ ...editableEnvironment, products: productsList });
  };

  const regionLocaleMapper = getRegionLocaleMapper(t);

  const onSelectedRegionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    var newMyRegionsListBase: string[] = editableEnvironment.regions;

    if (option) {
      if (option.selected) {
        newMyRegionsListBase = newMyRegionsListBase.concat([option.key as string]);
      } else {
        newMyRegionsListBase = newMyRegionsListBase.filter(x => x !== (option.key as string));
      }

      // remove empty
      newMyRegionsListBase = newMyRegionsListBase.filter(x => x);

      setEditableEnvironment({ ...editableEnvironment, regions: newMyRegionsListBase });
    }
  };

  return (
    <PageTemplate
      breadcrumbVisible
      pageHeaderProps={{
        title: activeEnvironment.id ? t('environment-editor.title-edit') : t('environment-editor.title-create'),
        titleCaption: t('environment-editor.title-caption'),
      }}
      onRenderContent={() => (
        <>
          <Prompt when={isUpdated && !isSaveClicked} message={t('common.on-before-unload-msg')} />

          <Stack tokens={{ childrenGap: '32px' }} style={{ height: '100%' }}>
            <Stack data-test="general-section" tokens={{ childrenGap: '21px' }}>
              <div data-test="general-section-title" className={classNames.sectionTitle}>
                {t('environment-editor.general.title')}
              </div>
              <TextField
                required
                disabled={isSaveClicked}
                value={editableEnvironment.name}
                label={t('common.name')}
                className={classNames.contentMaxWidth}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  let canUpdate = true;
                  if (newValue && newValue.length >= 64) canUpdate = false;
                  if (canUpdate) setEditableEnvironment({ ...editableEnvironment, name: newValue || '' });
                }}
              />
              <TextField
                required
                disabled={isSaveClicked}
                value={editableEnvironment.description}
                label={t('environment-editor.general.description')}
                className={classNames.contentMaxWidth}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  let canUpdate = true;
                  if (newValue && newValue.length >= 128) canUpdate = false;
                  if (canUpdate) setEditableEnvironment({ ...editableEnvironment, description: newValue || '' });
                }}
              />
            </Stack>

            <div className={classNames.sectionSeparator} />

            <Stack data-test="services-section" tokens={{ childrenGap: '21px' }}>
              <div>
                <div data-test="services-section-title" className={classNames.sectionTitle}>
                  {t('environment-editor.services.title')}
                </div>
                <div data-test="services-section-caption" className={classNames.sectionCaption}>
                  {t('environment-editor.services.caption')}
                </div>
              </div>
              <div data-test="services-section-product-list">
                <Stack horizontal horizontalAlign={'space-between'} className={classNames.contentMaxWidth}>
                  <div>
                    <TenantProductGuard products={Product.TextSearch}>
                      <Toggle
                        label={t('environment-editor.services.product-search')}
                        checked={editableEnvironment.products.includes(Product.TextSearch)}
                        onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) =>
                          onProductSelectionChange(Product.TextSearch, checked)
                        }
                        disabled={editableEnvironment.products.includes(Product.TextSearch)}
                        onText={t('common.on')}
                        offText={t('common.off')}
                      />
                    </TenantProductGuard>
                    <TenantProductGuard products={Product.Recommendations}>
                      <Fragment>
                        <Separator />
                        <Toggle
                          label={t('environment-editor.services.recommendations')}
                          checked={editableEnvironment.products.includes(Product.Recommendations)}
                          onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) =>
                            onProductSelectionChange(Product.Recommendations, checked)
                          }
                          disabled={isSaveClicked}
                          onText={t('common.on')}
                          offText={t('common.off')}
                        />
                      </Fragment>
                    </TenantProductGuard>
                  </div>
                  <TenantProductGuard products={[Product.TextSearch, Product.VisualSearch]}>
                    <Dropdown
                      className={classNames.serviceRegionsDropdown}
                      defaultSelectedKeys={editableEnvironment.regions}
                      label={t('environment-editor.services.product-region-dropdown-label')}
                      placeHolder={t('environment-editor.services.product-region-placeholder')}
                      multiSelect
                      disabled={!!activeEnvironment.id || isSaveClicked}
                      options={activeTenant.regions.map(region => ({
                        key: region,
                        text: regionLocaleMapper[region],
                        itemType: DropdownMenuItemType.Normal,
                      }))}
                      onChange={onSelectedRegionChange}
                    />
                  </TenantProductGuard>
                </Stack>
              </div>
            </Stack>

            <div className={classNames.sectionSeparator} />

            <Stack data-test="roles-section" maxWidth={'65%'} style={{ height: '100%' }}>
              <div data-test="roles-section-title" className={classNames.sectionTitle}>
                {t('environment-editor.roles.title')}
              </div>
              <div data-test="roles-section-caption" className={classNames.sectionCaption}>
                {t('environment-editor.roles.caption')}
              </div>
              <RoleList
                isLoading={false}
                items={roleList}
                onRolesChanged={(roles: Role[]) => setRoleList(roles)}
                disableEdit={isSaveClicked}
              />
            </Stack>
          </Stack>
        </>
      )}
      sideNavMode={SideNavMode.None}
      sideNavOnClick={() => {}}
      footerProps={{
        saveButtonDisabled: isSaveDisabled,
        cancelButtonDisabled: isSaveClicked,
        saveButtonText: activeEnvironment.id ? t('common.save') : t('common.create'),
        onSave: () => {
          setIsSaveClicked(true);
          if (activeEnvironment.id) {
            dispatch(
              updateEnvironmentWithRoleList(
                tenantId,
                {
                  ...editableEnvironment,
                  roleIdList: [...roleList.map(x => x.id)],
                },
                () => {},
                () => {},
              ),
            );
          } else {
            dispatch(
              createEnvironmentWithRoleList(
                activeTenant,
                {
                  ...editableEnvironment,
                  roleIdList: [...roleList.map(x => x.id)],
                },
                () => {},
                () => {},
              ),
            );
          }
        },
        onCancel: () => {
          if (activeEnvironment.id) envRouter(MbcRouteKey.Environment).browserPush();
          else adminRouter(MbcRouteKey.EnvironmentList).browserPush();
        },
      }}
    />
  );
};
