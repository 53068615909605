import { IStyleFunctionOrObject, ITheme, IRefObject, IStyle, IIconProps } from 'office-ui-fabric-react';
import { WithTranslation } from 'react-i18next';
import { ModelJobStatus } from '../../../../store/types/customML/searchModel.d';

export enum CardState {
  InActive = 0,
  Active = 1,
  InProgress = 2,
  Completed = 3,
}

export interface ContentProps {
  startTime: {
    label: string;
    value: string;
  };
  endTime?: {
    label: string;
    value: string;
    status: ModelJobStatus;
  };
}

export interface StatusProps {
  label: string;
  status: ModelJobStatus;
}

export interface ListContentProps {
  title: string;
  statusList: StatusProps[];
}

export interface CompletedCardProps {
  label: string;
  onClick?: () => void;
  contentProps: ListContentProps | ContentProps;
}

export interface ActiveCardProps {
  label: string;
  onClick: () => void;
  iconProps: IIconProps;
}

interface CustomMLCardBaseProps extends WithTranslation {
  /**
   * Theme provided by the Higher Order Component
   */
  theme?: ITheme;

  /**
   * Style function to be passed in to override the themed or default styles.
   */
  styles?: IStyleFunctionOrObject<CustomMLCardStyleProps, CustomMLCardStyles>;

  /**
   * Optional class name to add to the root element.
   */
  className?: string;

  /**
   * Optional details list element Ref.
   */
  componentRef?: IRefObject<HTMLDivElement>;
}

export interface CustomMLCardProps extends CustomMLCardBaseProps {
  title: string;
  state: CardState;
  inProgressLabel?: string;
  activeCardProps?: ActiveCardProps;
  completedCardProps?: CompletedCardProps;
}

export type CustomMLCardStyleProps = Required<Pick<CustomMLCardProps, 'theme'>> &
  Pick<CustomMLCardProps, 'className'> & { state: CardState; disabledOnCompletion: boolean };

export interface CustomMLCardStyles {
  root: IStyle;
  title: IStyle;
  itemIcon: IStyle;
  itemLabel: IStyle;
  actionIcon: IStyle;
  actionLabel: IStyle;
  contentContainer: IStyle;
}
