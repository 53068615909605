import { ITheme, IStyleFunctionOrObject, IStyle } from 'office-ui-fabric-react';
import { PageFooterProps } from '../../../../components/common/PageFooter';

/**
 * Used to detect the search index field type requirement rule
 */
export enum SearchIndexFieldTypeState {
  Required,
  Disabled,
  Optional,
}

/**
 * Used to detect the search index manadtory fields
 */
export const MandatoryFieldTypes = ['ProductId'];

/**
 * Used to detect the search index features initial/default config
 */
export interface SearchIndexFieldTypeConfig {
  searchable: SearchIndexFieldTypeState;
  retrievable: SearchIndexFieldTypeState;
  filterable: SearchIndexFieldTypeState;
  facetable: SearchIndexFieldTypeState;
  sortable: SearchIndexFieldTypeState;
  matches: (value: any) => boolean;
  defaultValue: any;
  dataType: string;
}

/**
 * Used to flag the search index root
 */
export const ROOT_FIELD_KEY = 'ROOT_FIELD_KEY';

/**
 * Defines index schema view mode
 */
export enum ViewMode {
  Read,
  Edit,
}

/**
 * Defines index schema base props
 */
export interface SchemaManagementProps {
  /**
   * Theme provided by the Higher Order Component
   */
  theme?: ITheme;

  /**
   * Style function to be passed in to override the themed or default styles.
   */
  styles?: IStyleFunctionOrObject<SchemaManagementStyleProps, SchemaManagementStyles>;

  /**
   * Set page template footer props on Edit mode
   */
  onRenderPageFooterProps: (props?: PageFooterProps) => void;
}

/**
 * Defines index schema style props
 */
export type SchemaManagementStyleProps = Required<Pick<SchemaManagementProps, 'theme'>>;

/**
 * Defines index schema styles
 */
export interface SchemaManagementStyles {
  /**
   * Defines index schema file icon styles
   */
  headerGap: IStyle;

  /**
   * Defines index schema file icon styles
   */
  unhoverable: IStyle;

  /**
   * Defines index schema cell styles
   */
  cell: IStyle;

  /**
   * Defines index schema primary column header styles
   */
  primaryHeader: IStyle;

  /**
   * Defines index schema primary column cell styles
   */
  primaryCell: IStyle;

  /**
   * Defines index schema secondary column cell styles
   */
  secondaryCell: IStyle;

  /**
   * Defines index schema secondary column header styles
   */
  secondaryHeader: IStyle;

  /**
   * Defines index schema hoverable column cell styles
   */
  hoverable: IStyle;

  /**
   * Defines index schema sub component styles
   */
  subComponentStyles: {
    detailsList: IStyleFunctionOrObject<any, any>;
    scrollablePane: IStyleFunctionOrObject<any, any>;
  };
}
