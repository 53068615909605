import { Action } from 'redux';

export const GOTO_NEXT_STEP = 'SHOW_OOBE_DEMO';
export const GOTO_PREVIOUS_STEP = 'HIDE_OOBE_DEMO';
export const START_TUTORIAL = 'START_TUTORIAL';
export const SHOW_TUTORIAL_HINT = 'SHOW_TUTORIAL_HINT';
export const HIDE_TUTORIAL_HINT = 'HIDE_TUTORIAL_HINT';

export enum AvailableTutorials {
  None = 'None',
  EnvironmentList = 'Basic',
  Catalog = 'Catalog',
  Roles = 'Roles',
  QueryTester = 'QueryTester',
  BusinessRulesShort = 'BusinessRulesShort',
  BusinessRulesFull = 'BusinessRulesFull',
  SynonymsShort = 'SynonymsShort',
  SynonymsFull = 'SynonymsFull',
}

export interface TutorialState {
  currentTutorial: AvailableTutorials;
  currentStep: number;
  showHint: boolean;
}

interface StartTutorial extends Action<typeof START_TUTORIAL> {
  tutorial: AvailableTutorials;
}

export type TutorialStateActionTypes =
  | StartTutorial
  | Action<typeof GOTO_NEXT_STEP>
  | Action<typeof GOTO_PREVIOUS_STEP>
  | Action<typeof SHOW_TUTORIAL_HINT>
  | Action<typeof HIDE_TUTORIAL_HINT>;
