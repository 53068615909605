import {
  GOTO_NEXT_STEP,
  GOTO_PREVIOUS_STEP,
  TutorialStateActionTypes,
  AvailableTutorials,
  SHOW_TUTORIAL_HINT,
  HIDE_TUTORIAL_HINT,
} from '../types/tutorial.d';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import { tutorialConfig } from '../../components/Tutorials';

export function startTutorial(tutorial: AvailableTutorials): ThunkAction<void, AppState, null, Action> {
  return (dispatch, getState) => {
    tutorialConfig[tutorial].TutorialStartUpPrep(getState());
  };
}

export function gotoNextStep(): TutorialStateActionTypes {
  return {
    type: GOTO_NEXT_STEP,
  };
}

export function gotoPreviousStep(): TutorialStateActionTypes {
  return {
    type: GOTO_PREVIOUS_STEP,
  };
}

export function showTutorialHint(): TutorialStateActionTypes {
  return {
    type: SHOW_TUTORIAL_HINT,
  };
}

export function hideTutorialHint(): TutorialStateActionTypes {
  return {
    type: HIDE_TUTORIAL_HINT,
  };
}
