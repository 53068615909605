import React, { ReactNode, useState } from 'react';
import { AppState } from '../../../../../../store/reducers';
import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  SearchBox,
  ShimmeredDetailsList,
  Link,
  classNamesFunction,
} from 'office-ui-fabric-react';
import { Tenant } from '../../../../../../store/types/tenantsList';
import { useSelector } from '../../../../../../store/hooks';
import { TenantsListStyles, TenantsListProps } from './TenantsList.types';
import { TenantsListPanelStyleProps } from '../TenantsListPanel.types';
import { MbcRouteKey } from '../../../../../../router/MbcRouter.types';
import { MbcAdminRouter } from '../../../../../../router/MbcAdminRouter/MbcAdminRouter';

const getClassNames = classNamesFunction<TenantsListPanelStyleProps, TenantsListStyles>();

export const TenantsListBase = (props: TenantsListProps) => {
  const { t, className, styles, theme, componentRef, onClick } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [query, setQuery] = useState('');

  const tenantIdMapper = useSelector((state: AppState) => state.tenantsList.tenantIdMapper);
  const isTenantsListLoading = useSelector((state: AppState) => state.tenantsList.isLoading);

  const search = (query: string): void => {
    setQuery(query);
  };

  const onChangeInvoked = (event?: React.ChangeEvent<HTMLElement>, query: string = ''): void => {
    search(query);
  };

  const onSearchInvoked = (query: string): void => {
    search(query);
  };

  const onRenderItemColumn = (item?: Tenant, index?: number, column?: IColumn): ReactNode => {
    if (item && column) {
      const ItemContent = item[column.fieldName as keyof Tenant] as string;
      switch (column.key) {
        case 'name':
          return (
            <Link
              onClick={() => {
                onClick();
                MbcAdminRouter(item)(MbcRouteKey.EnvironmentList).browserPush();
              }}
            >
              {ItemContent}
            </Link>
          );
        default:
          return <span>{ItemContent}</span>;
      }
    }
  };

  const getDisplayedItems = (): Tenant[] => {
    if (isTenantsListLoading === true) {
      return [];
    }
    return Object.values(tenantIdMapper).filter(
      tenant => query === '' || tenant.name.toLowerCase().includes(query.toLowerCase()),
    );
  };

  return (
    <div ref={componentRef} className={classNames.root}>
      <div className={classNames.searchCommandBar}>
        <SearchBox
          styles={{ root: { width: 250 } }}
          placeholder={t('common.search-placeholder')}
          onSearch={onSearchInvoked}
          onChange={onChangeInvoked}
          ariaLabel={t('common.search-placeholder')}
        />
      </div>
      <div>
        <ShimmeredDetailsList
          enableShimmer={isTenantsListLoading}
          items={getDisplayedItems()}
          columns={[
            {
              key: 'name',
              name: t('tenants-list.tenant-header'),
              fieldName: 'name',
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
            },
          ]}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          onRenderItemColumn={onRenderItemColumn}
        />
      </div>
    </div>
  );
};
