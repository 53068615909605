import React from 'react';
import {
  TitledContentCardProps,
  TitledContentCardStyles,
  TitledContentCardStyleProps,
} from './TitledContentCard.types';
import { ContentCard } from '../ContentCard';
import { ContentCardHeader } from '../ContentCardHeader';
import { classNamesFunction } from 'office-ui-fabric-react';

export const getClassNames = classNamesFunction<TitledContentCardStyleProps, TitledContentCardStyles>();

export function TitledContentCardBase(props: TitledContentCardProps) {
  const {
    styles,
    theme,
    className,
    componentRef,
    contentCardRef,
    contentCardHeaderRef,
    title,
    subTitle,
    actions,
    body,
    footer,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div ref={componentRef} className={classNames.root}>
      <ContentCard
        header={
          <ContentCardHeader
            title={title}
            subTitle={subTitle}
            actions={actions}
            componentRef={contentCardHeaderRef}
            styles={classNames.subComponentStyles.header}
          ></ContentCardHeader>
        }
        body={body}
        footer={footer}
        componentRef={contentCardRef}
        styles={classNames.subComponentStyles.card}
      ></ContentCard>
    </div>
  );
}
