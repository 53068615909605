import { HoverableSideNavStyleProps, HoverableSideNavStyles } from './HoverableSideNav.types';

export const styles = (props: HoverableSideNavStyleProps): HoverableSideNavStyles => ({
  subComponentStyles: {
    panel: {
      root: {
        top: props.top,
      },
    },
  },
});
