import {
  GET_TENANT_ROLES,
  RESET_TENANT_ROLES,
  LOADING_TENANT_ROLES,
  RoleListState,
  RoleListStateActionTypes,
  SET_ACTIVE_TENANT_ROLE,
  RESET_ACTIVE_TENANT_ROLE,
  RoleIdMapper,
  Role,
  DELETE_ROLE,
  CREATE_OR_UPDATE_ROLE,
  CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST,
  DELETE_ENVIRONMENT_ROLE_LIST,
  EditorMode,
  SET_ROLE_EDITOR_MODE,
} from '../types/roleList.d';
import { configureDefaultObjectWithId } from '../../utils';

export const createEmptyRole = () => {
  return {
    id: '',
    name: '',
    users: [],
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    description: '',
    permissions: [],
    environmentList: [],
    hasAccessToAllEnvironments: false,
  };
};

const createRoleIdMapper = (initialMapper: RoleIdMapper = {}) => {
  return configureDefaultObjectWithId<RoleIdMapper, Role>(initialMapper, createEmptyRole());
};

const initialState: RoleListState = {
  isLoading: true,
  activeRole: createEmptyRole(),
  roleIdMapper: createRoleIdMapper(),
  roleEditorMode: EditorMode.None,
};

export function roleListReducer(state = initialState, action: RoleListStateActionTypes): RoleListState {
  switch (action.type) {
    case SET_ACTIVE_TENANT_ROLE: {
      return {
        ...state,
        activeRole: action.role,
      };
    }
    case RESET_ACTIVE_TENANT_ROLE: {
      return {
        ...state,
        activeRole: createEmptyRole(),
      };
    }
    case LOADING_TENANT_ROLES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_TENANT_ROLES: {
      const roleIdMapper = createRoleIdMapper({
        ...action.roles.reduce((mapper: RoleIdMapper, role: Role) => {
          mapper[role.id] = role;
          return mapper;
        }, {}),
      });
      return {
        ...state,
        isLoading: false,
        roleIdMapper: roleIdMapper,
      };
    }
    case RESET_TENANT_ROLES: {
      return {
        ...state,
        isLoading: true,
        roleIdMapper: createRoleIdMapper(),
      };
    }
    case CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST: {
      return {
        ...state,
        roleIdMapper: createRoleIdMapper({
          ...Object.values(state.roleIdMapper).reduce((mapper: RoleIdMapper, role: Role) => {
            const updatedRole = action.roleList.find(updatedRole => updatedRole.id === role.id);
            mapper[role.id] = updatedRole
              ? // Role was assigned to this environment or already assigned (not updated).
                updatedRole
              : // Role might be unassigned to this environment
                {
                  ...role,
                  environmentList: role.environmentList.filter(environmentId => environmentId !== action.environmentId),
                };
            return mapper;
          }, {}),
        }),
      };
    }
    case DELETE_ENVIRONMENT_ROLE_LIST: {
      return {
        ...state,
        roleIdMapper: createRoleIdMapper({
          ...action.roleList.reduce(
            (mapper, roleId) => {
              const role = mapper[roleId];
              mapper[roleId] = {
                ...role,
                environmentList: role.environmentList.filter(environmentId => environmentId !== action.environmentId),
              };
              return mapper;
            },
            { ...state.roleIdMapper },
          ),
        }),
      };
    }
    case CREATE_OR_UPDATE_ROLE: {
      const updatedRoleIdMapper = createRoleIdMapper({
        ...state.roleIdMapper,
        [action.role.id]: action.role,
      });
      return {
        ...state,
        roleIdMapper: updatedRoleIdMapper,
      };
    }
    case DELETE_ROLE: {
      var updatedRoleIdMapper = createRoleIdMapper({ ...state.roleIdMapper });
      delete updatedRoleIdMapper[action.roleId];

      return {
        ...state,
        roleIdMapper: updatedRoleIdMapper,
      };
    }
    case SET_ROLE_EDITOR_MODE: {
      return {
        ...state,
        roleEditorMode: action.roleEditorMode,
      };
    }
    default:
      return state;
  }
}
