import {
  GOTO_NEXT_STEP,
  GOTO_PREVIOUS_STEP,
  START_TUTORIAL,
  TutorialState,
  TutorialStateActionTypes,
  AvailableTutorials,
  SHOW_TUTORIAL_HINT,
  HIDE_TUTORIAL_HINT,
} from '../types/tutorial.d';

const initialState: TutorialState = {
  currentTutorial: AvailableTutorials.None,
  currentStep: 1,
  showHint: false,
};

export function tutorialReducer(state = initialState, action: TutorialStateActionTypes): TutorialState {
  switch (action.type) {
    case START_TUTORIAL: {
      return {
        ...state,
        currentStep: 1,
        currentTutorial: action.tutorial,
      };
    }
    case GOTO_NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }
    case GOTO_PREVIOUS_STEP: {
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    }
    case SHOW_TUTORIAL_HINT: {
      return {
        ...state,
        showHint: true,
      };
    }
    case HIDE_TUTORIAL_HINT: {
      return {
        ...state,
        showHint: false,
      };
    }
    default:
      return state;
  }
}
