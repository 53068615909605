import { Action } from 'redux';
import { ModelJobStatus } from './searchModel';

export const SET_COOKING_JOB_LIST = 'SET_COOKING_JOB_LIST';
export const RESET_COOKING_JOB_LIST = 'RESET_COOKING_JOB_LIST';
export const LOADING_COOKING_JOB_LIST = 'LOADING_COOKING_JOB_LIST';

export const ADD_COOKING_JOB_LIST = 'ADD_COOKING_JOB_LIST';
export const UPDATE_COOKING_JOB_LIST = 'UPDATE_COOKING_JOB_LIST';

export interface CookingJobMapper {
  [key: string]: CookingJob;
}

export interface CookingJob {
  key: string;
  name: string;
  email: string;
  indexId: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  status: ModelJobStatus;
  inputDatasetKey: string;
  cookedDatasetKey: string;
}

export interface CookingJobState {
  isLoading: boolean;
  cookingJobMapper: CookingJobMapper;
}

interface AddCookingJobList extends Action<typeof ADD_COOKING_JOB_LIST> {
  cookingJobList: CookingJob[];
}

interface UpdateCookingJobList extends Action<typeof UPDATE_COOKING_JOB_LIST> {
  cookingJobList: CookingJob[];
}

interface SetCookingJobList extends Action<typeof SET_COOKING_JOB_LIST> {
  cookingJobList: CookingJob[];
}

export type CookingJobStateActionTypes =
  | AddCookingJob
  | AddCookingJobList
  | SetCookingJobList
  | UpdateCookingJobList
  | Action<typeof RESET_COOKING_JOB_LIST>
  | Action<typeof LOADING_COOKING_JOB_LIST>;
