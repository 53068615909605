import { Tenant as TenantDTO } from '../dto/tenant/tenant';
import { Tenant as TenantModel, Product, Feature, SearchScenario } from '../../store/types/tenantsList.d';
import { TenantList as TenantListDTO } from '../dto/tenant/tenantList';

export function mapTenantDtoToModel(tenant: TenantDTO): TenantModel {
  return {
    id: tenant.id,
    name: tenant.name,
    isOobe: tenant.tier === 'Trial',
    isSandbox: tenant.id === '57e385bf-6a10-481d-75f9-08d76cc81ddb',
    tier: tenant.tier,
    regions: tenant.regionParameters.map(params => params.region),
    rankers: tenant.rankers,
    products: tenant.searchServices
      // This workaround to consolidate Products enum
      .filter(p => Product[p as keyof typeof Product])
      .map(p => Product[p as keyof typeof Product]),
    features: tenant.features.map(f => f as Feature),
    searchScenario: tenant.searchScenario as SearchScenario,
  };
}

export function mapTenantListDtoToModel(tenantList: TenantListDTO): TenantModel[] {
  return tenantList.tenants.filter(tenant => !!tenant.name).map(mapTenantDtoToModel);
}
