import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../store/hooks';
import { useLazyBreadcrumb } from '../../../store/hooks/use-breadcrumb/useLazyBreadcrumb';
import { setActiveEnvironment, resetActiveEnvironment } from '../../../store/actions/environmentListActions';
import { ActiveEnvironmentLoading } from '../../../components/Loading';
import { MbcRouter } from '../../../router/MbcRouter.base';
import { useEnvironmentRouter } from '../../../store/hooks/use-router/useEnvironmentRouter';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { useProxyRouter } from '../../../store/hooks/use-router/useProxyRouter';
import { useActiveEnvironmentAsserter } from '../../../store/hooks/use-asserter/useAsserter';
import { useEnvironmentPermissions } from '../../../store/hooks/use-user-permissions/UseEnvironmentPermissions';

export const EnvironmentProxy = (props: RouteComponentProps<{ environmentId: string }>) => {
  const dispatch = useDispatch();

  const environmentPermissions = useEnvironmentPermissions();
  const MbcEnvironmentRouteMap = useEnvironmentRouter();
  const MbcProxyRouteMapper = useProxyRouter();

  const isEnvironmentListLoading = useSelector(state => state.environmentList.isLoading);
  const activeEnvironemnt = useSelector(state => state.environmentList.activeEnvironment);

  useActiveEnvironmentAsserter(props.match.params.environmentId);

  useLazyBreadcrumb(
    props.match.params.environmentId,
    props.match.url,
    activeEnvironemnt.name,
    !isEnvironmentListLoading && activeEnvironemnt.name !== '',
  );

  useEffect(() => {
    dispatch(setActiveEnvironment(props.match.params.environmentId));
    return () => {
      dispatch(resetActiveEnvironment());
    };
  }, [dispatch, isEnvironmentListLoading, props.match.params.environmentId]);

  return (
    <ActiveEnvironmentLoading>
      <MbcRouter
        userPermissions={environmentPermissions}
        routes={[
          MbcEnvironmentRouteMap(MbcRouteKey.Environment),
          MbcEnvironmentRouteMap(MbcRouteKey.EnvironmentEditor),
          MbcEnvironmentRouteMap(MbcRouteKey.IndexAnalytics),
          MbcEnvironmentRouteMap(MbcRouteKey.SchemaManagement),
          MbcEnvironmentRouteMap(MbcRouteKey.DocumentManagement),
          MbcEnvironmentRouteMap(MbcRouteKey.LegacySearchAnalytics),
          MbcProxyRouteMapper(MbcRouteKey.ProductSearchProxy),
        ]}
      />
    </ActiveEnvironmentLoading>
  );
};
