export const EquivalenceOperator = { eq: '=', ne: '!=' };
export type EquivalenceOperator = 'eq' | 'ne';

export const LogicalOperator = { and: 'and', or: 'or' };
export type LogicalOperator = 'and' | 'or';

export const ComparisonOperator = { eq: '=', nq: '!=', gt: '>', ge: '>=', lt: '<', le: '<=' };
export type ComparisonOperator = 'eq' | 'nq' | 'gt' | 'ge' | 'lt' | 'le';

export const CategoryOperator = { in: 'in', notIn: 'not in' };
export type CategoryOperator = 'in' | 'notIn';

export const SetOperator = { in: 'in', notIn: 'not in' };
export type SetOperator = 'in' | 'notIn';

export const DateTimeOperator = { ...ComparisonOperator, in: 'in' };
export type DateTimeOperator = ComparisonOperator | 'in';

export type ConditionOperatorType =
  | EquivalenceOperator
  | ComparisonOperator
  | CategoryOperator
  | SetOperator
  | DateTimeOperator;
