import { Action } from 'redux';
import { BaseButton, Button, MessageBarType } from 'office-ui-fabric-react';

export const PUSH_NOTIFICATION_MESSAGE = 'PUSH_NOTIFICATION_MESSAGE';
export const POP_NOTIFICATION_MESSAGE = 'POP_NOTIFICATION_MESSAGE';
export const CLEAR_NOTIFICATION_MESSAGES = 'CLEAR_NOTIFICATION_MESSAGES';

export interface NotificationMessageDetails {
  type: MessageBarType;
  content: string;
  onDismiss?: (
    ev?:
      | React.MouseEvent<HTMLButtonElement | BaseButton | HTMLAnchorElement | HTMLDivElement | Button, MouseEvent>
      | undefined,
  ) => any;
  actionText?: string;
  actionOnClick?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button, MouseEvent>,
  ) => void;
}

export interface NotificationMessagesState {
  notificationMessages: NotificationMessageDetails[];
}

interface PushNotificationMessage extends Action<typeof PUSH_NOTIFICATION_MESSAGE> {
  notificationMessage: NotificationMessageDetails;
}

export type NotificationMessageActionTypes =
  | PushNotificationMessage
  | Action<typeof POP_NOTIFICATION_MESSAGE>
  | Action<typeof CLEAR_NOTIFICATION_MESSAGES>;
