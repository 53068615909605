import { DatasetCreateRequest as DatasetDto } from '../dto/custom-ml/Dataset/Dataset';
import { Dataset as DatasetModel, DatasetType } from '../../store/types/customML/dataset.d';
import { ListDatasetsResponse as DatasetListDto } from '../dto/custom-ml/Dataset/DatasetList';
import { ListJobResponse as JobListDto } from '../dto/custom-ml/Job/JobList';
import { CookingJob as CookingJobModel } from '../../store/types/customML/cookingJob.d';
import { ModelJobStatus, SearchModel, ModelType } from '../../store/types/customML/searchModel.d';
import {
  CreateJobRequest as CreateJobDto,
  JobType,
  JobDatasetRelationRole,
  GetTrainingJobMetircsResponse as TrainingMetricDto,
  GetEvaluationJobMetricsResponse as EvaluationDto,
  Job as JobDto,
} from '../dto/custom-ml/Job/Job';
import { CreateSuperJobRequest as SearchModelDto, SuperJobRelationType } from '../dto/custom-ml/SuperJob/SuperJob';
import { ListSuperJobsResponse as SearchModelListDto } from '../dto/custom-ml/SuperJob/SuperJobList';
import {
  TrainingJob as TrainingJobModel,
  BasicParameters,
  TrainingMetric,
} from '../../store/types/customML/trainingJob.d';
import { EvaluationJob as EvaluationJobModel, Evaluation } from '../../store/types/customML/evaluationJob';
import { DeploymentJob } from '../../store/types/customML/deploymentJob';
import { createTrainingMetricsMapper } from '../../store/reducers/customML/trainingJobReducer';
import { getLocaleDateTime } from '../../utils';

export const mapListDatasetDtoToModel = (dto: DatasetListDto): DatasetModel[] =>
  dto.datasets.map(dataset => ({
    isNew: false,
    name: dataset.name,
    email: dataset.owner,
    key: dataset.datasetId,
    description: dataset.description,
    createdAt: getLocaleDateTime(dataset.createTimeUTC),
    updatedAt: getLocaleDateTime(dataset.updateTimeUTC),
    datasetType: DatasetType[dataset.datasetType as keyof typeof DatasetType],
  }));

export const mapToDatasetCreateRequestDto = (updateId: string, dataset: DatasetModel): DatasetDto => ({
  name: dataset.name,
  updateId: updateId,
  owner: dataset.email,
  datasetType: dataset.datasetType,
  description: dataset.description,
});

export const mapToCookingJobCreateRequestDto = (searchModelKey: string, job: CookingJobModel): CreateJobDto => {
  const indexIdKey = 'index_id';
  return {
    name: job.name,
    owner: job.email,
    superJobId: searchModelKey,
    description: job.description,
    jobType: JobType.LightGBMCookingJob,
    configs: {
      [indexIdKey]: job.indexId,
    },
    inputDatasets: {
      rawDatasetId: job.inputDatasetKey,
    },
  };
};

export const mapListCookedJobDtoToModel = (dto: JobListDto): CookingJobModel[] =>
  dto.jobs.map(job => {
    const rawDatasetList = job.inputDatasets[JobDatasetRelationRole.RawData];
    const cookedDatasetList = job.outputDatasets[JobDatasetRelationRole.CookedData];
    return {
      isNew: false,
      key: job.jobId,
      name: job.name,
      email: job.owner,
      description: job.description,
      indexId: job.configs.index_id,
      createdAt: getLocaleDateTime(job.createTimeUTC),
      updatedAt: getLocaleDateTime(job.updateTimeUTC),
      inputDatasetKey: rawDatasetList && rawDatasetList.length > 0 ? rawDatasetList[0].datasetId : '',
      cookedDatasetKey: cookedDatasetList && cookedDatasetList.length > 0 ? cookedDatasetList[0].datasetId : '',
      status: ModelJobStatus[job.status as keyof typeof ModelJobStatus],
    };
  });

export const mapToTrainingJobCreateRequestDto = (searchModelKey: string, job: TrainingJobModel): CreateJobDto => {
  return {
    name: job.name,
    owner: job.email,
    superJobId: searchModelKey,
    description: job.description,
    jobType: JobType.LightGBMTrainingJob,
    configs: {
      [BasicParameters.NumTrees]: job.parameters[BasicParameters.NumTrees],
      [BasicParameters.LabelGain]: job.parameters[BasicParameters.LabelGain],
      [BasicParameters.NumLeaves]: job.parameters[BasicParameters.NumLeaves],
      [BasicParameters.Parameters]: job.parameters[BasicParameters.Parameters],
      [BasicParameters.LearningRate]: job.parameters[BasicParameters.LearningRate],
      [BasicParameters.MinDataInLeaf]: job.parameters[BasicParameters.MinDataInLeaf],
      [BasicParameters.FeatureSelected]: job.parameters[BasicParameters.FeatureSelected],
    },
    inputDatasets: {
      trainingDatasetId: job.datasetKey,
      testDatasetId: job.testDatasetKey,
    },
  };
};

export const mapTrainingJobDtoToModel = (job: JobDto): TrainingJobModel => {
  const testingDatasetList = job.inputDatasets[JobDatasetRelationRole.TestData];
  const trainingDatasetList = job.inputDatasets[JobDatasetRelationRole.TrainingData];
  const rankerModelList = job.outputDatasets[JobDatasetRelationRole.Model];
  return {
    key: job.jobId,
    name: job.name,
    email: job.owner,
    description: job.description,
    createdAt: getLocaleDateTime(job.createTimeUTC),
    updatedAt: getLocaleDateTime(job.updateTimeUTC),
    rankerModelKey: rankerModelList && rankerModelList.length > 0 ? rankerModelList[0].datasetId : '',
    datasetKey: trainingDatasetList && trainingDatasetList.length > 0 ? trainingDatasetList[0].datasetId : '',
    testDatasetKey: testingDatasetList && testingDatasetList.length > 0 ? testingDatasetList[0].datasetId : '',
    parameters: {
      [BasicParameters.LabelGain]: job.configs[BasicParameters.LabelGain],
      [BasicParameters.LearningRate]: job.configs[BasicParameters.LearningRate],
      [BasicParameters.MinDataInLeaf]: job.configs[BasicParameters.MinDataInLeaf],
      [BasicParameters.NumLeaves]: job.configs[BasicParameters.NumLeaves],
      [BasicParameters.NumTrees]: job.configs[BasicParameters.NumTrees],
      [BasicParameters.FeatureSelected]: job.configs[BasicParameters.FeatureSelected],
      [BasicParameters.Parameters]: job.configs[BasicParameters.Parameters],
    },
    status: ModelJobStatus[job.status as keyof typeof ModelJobStatus],
    isLoadingMetrics: false,
    metricsMapper: createTrainingMetricsMapper(),
  };
};

export const mapListTrainingJobDtoToModel = (dto: JobListDto): TrainingJobModel[] =>
  dto.jobs.map(mapTrainingJobDtoToModel);

export const mapJobMetricGraphsDtoToModel = (dto: TrainingMetricDto): TrainingMetric[] =>
  dto.jobMetircGraphs.map(metric => ({
    key: metric.graphName,
    xLabel: metric.xName,
    yLabel: metric.yName,
    points: metric.points.map(point => ({
      x: point.xValue,
      y: point.yValue,
    })),
  }));

export const mapToDeploymentJobCreateRequestDto = (searchModelKey: string, job: DeploymentJob): CreateJobDto => ({
  name: job.name,
  owner: job.email,
  superJobId: searchModelKey,
  description: job.description,
  jobType: JobType.LightGBMDeploymentJob,
  inputDatasets: {
    rankingModelId: job.deployedModelKey,
  },
  configs: {},
});

export const mapDeploymentJobDtoToModel = (job: JobDto): DeploymentJob => {
  const deployedModelList = job.inputDatasets[JobDatasetRelationRole.RankingModel];
  return {
    key: job.jobId,
    name: job.name,
    email: job.owner,
    description: job.description,
    createdAt: getLocaleDateTime(job.createTimeUTC),
    updatedAt: getLocaleDateTime(job.updateTimeUTC),
    deployedModelKey: deployedModelList && deployedModelList.length > 0 ? deployedModelList[0].datasetId : '',
    status: ModelJobStatus[job.status as keyof typeof ModelJobStatus],
  };
};

export const mapListDeploymentJobDtoToModel = (dto: JobListDto): DeploymentJob[] =>
  dto.jobs.map(mapDeploymentJobDtoToModel);

export const mapToEvaluationJobCreateRequestDto = (searchModelKey: string, job: EvaluationJobModel): CreateJobDto => ({
  name: job.name,
  owner: job.email,
  superJobId: searchModelKey,
  description: job.description,
  jobType: JobType.LightGBMEvaluationJob,
  inputDatasets: {
    testDatasetId: job.testDatasetKey,
    controlModelId: job.baselineModelKey,
    treatmentModelId: job.comparisonModelKey,
  },
  configs: {
    [BasicParameters.LabelGain]: job.labelGain,
  },
});

export const mapEvaluationMetricsDtoToModel = (evaluation: EvaluationDto): Evaluation[] =>
  evaluation.outputMetrics.map(metric => ({
    key: metric.name,
    baselineModelkey: metric.controlModelId,
    baselineModelName: metric.controlModelName,
    baselineModelNdcg: metric.controlModelNdcg,
    comparisonModels: metric.outputMetricPairs.map(pair => ({
      pValue: pair.pvalue,
      NdcgDelta: pair.ndcgDelta,
      key: pair.treatmentModelId,
      evaluationPair: pair.pairName,
      Ndcg: pair.treatmentModelNdcg,
    })),
  }));

export const mapEvaluationJobDtoToModel = (job: JobDto): EvaluationJobModel => {
  const treatmentModelList = job.inputDatasets[JobDatasetRelationRole.TreatmentModel];
  const controlModelList = job.inputDatasets[JobDatasetRelationRole.ControlModel];
  const datasetList = job.inputDatasets[JobDatasetRelationRole.TestData];
  return {
    isNew: false,
    isLoading: true,
    iterations: [],
    key: job.jobId,
    name: job.name,
    email: job.owner,
    description: job.description,
    createdAt: getLocaleDateTime(job.createTimeUTC),
    updatedAt: getLocaleDateTime(job.updateTimeUTC),
    labelGain: job.configs[BasicParameters.LabelGain] ? job.configs[BasicParameters.LabelGain] : '',
    testDatasetKey: datasetList && datasetList.length > 0 ? datasetList[0].datasetId : '',
    comparisonModelKey: treatmentModelList && treatmentModelList.length > 0 ? treatmentModelList[0].datasetId : [],
    baselineModelKey: controlModelList && controlModelList.length > 0 ? controlModelList[0].datasetId : '',
    status: ModelJobStatus[job.status as keyof typeof ModelJobStatus],
  };
};

export const mapListEvaluationJobDtoToModel = (dto: JobListDto): EvaluationJobModel[] =>
  dto.jobs.map(mapEvaluationJobDtoToModel);

export const mapListSearchModelDtoToModel = (dto: SearchModelListDto): SearchModel[] =>
  dto.superJobs.map(searchModel => {
    const deploymentJobList = searchModel.jobs[SuperJobRelationType.DeploymentJobs];
    const trainingJobList = searchModel.jobs[SuperJobRelationType.TrainingJobs];
    const evaluationJobList = searchModel.jobs[SuperJobRelationType.EvaluationJobs];

    return {
      isNew: false,
      name: searchModel.name,
      feature: 'Search model',
      key: searchModel.superJobId,
      isSubmittingTraining: false,
      isSubmittingDeployment: false,
      modelType: ModelType.LightGBMRanker,
      description: searchModel.description,
      createdAt: getLocaleDateTime(searchModel.createTimeUTC),
      updatedAt: getLocaleDateTime(searchModel.updateTimeUTC),
      trainingJobKeys: !!trainingJobList ? trainingJobList : [],
      deploymentJobKeys: !!deploymentJobList ? deploymentJobList : [],
      evaluationJobKeys: !!evaluationJobList ? evaluationJobList : [],
    };
  });

export const mapToSearchModelCreateRequestDto = (searchModel: SearchModel): SearchModelDto => ({
  name: searchModel.name,
  description: searchModel.description,
});
