import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../reducers';
import { refreshActiveIndexDevops } from '../devopsActions';

import { getUrlRedirectSetList as getUrlRedirectSetListService } from '../../../restful-apis/customizations/urlRedirectSet.api';
import {
  UrlRedirectSetCreateAction,
  UrlRedirectSetUpdateAction,
  UrlRedirectSetDeleteAction,
  ActionSuccessHandler,
  ActionFailureHandler,
  ExecuteReduxCrudAction,
} from '../utils';

import {
  UrlRedirectSet,
  SET_ACTIVE_URL_REDIRECT_SET,
  RESET_ACTIVE_URL_REDIRECT_SET,
  SET_URL_REDIRECT_SET_LIST,
  RESET_URL_REDIRECT_SET_LIST,
  LOADING_URL_REDIRECT_SET_LIST,
} from '../../types/customizations/urlRedirectSet.d';
import { generateUniqueIdentifier } from '../../../utils';

export const getUrlRedirectSetList = (searchInstanceId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;

    dispatch({
      type: LOADING_URL_REDIRECT_SET_LIST,
    });

    getUrlRedirectSetListService(tenantId, searchIndexId, searchInstanceId).then(urlRedirectSetList =>
      dispatch({
        type: SET_URL_REDIRECT_SET_LIST,
        urlRedirectSetList: urlRedirectSetList,
      }),
    );
  };
};

export const setActiveUrlRedirectSet = (
  urlRedirectSetId: string,
): ThunkAction<void, AppState, null, Action> => dispatch =>
  dispatch({
    type: SET_ACTIVE_URL_REDIRECT_SET,
    id: urlRedirectSetId,
  });

export const resetActiveUrlRedirectSet = (): ThunkAction<void, AppState, null, Action> => dispatch =>
  dispatch({
    type: RESET_ACTIVE_URL_REDIRECT_SET,
  });

export const resetUrlRedirectSetList = (): ThunkAction<void, AppState, null, Action> => dispatch =>
  dispatch({
    type: RESET_URL_REDIRECT_SET_LIST,
  });

export const updateUrlRedirectSet = (
  urlRedirectSet: UrlRedirectSet,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<UrlRedirectSet>,
  onFailure?: ActionFailureHandler<UrlRedirectSet>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Update ${urlRedirectSet.id} URL redirect set` }
      : undefined;

    const onSuccessHandler = (updatedUrlRedirectSet: UrlRedirectSet) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(updatedUrlRedirectSet);
    };

    dispatch(
      ExecuteReduxCrudAction(
        UrlRedirectSetUpdateAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          urlRedirectSet,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const createUrlRedirectSet = (
  urlRedirectSet: UrlRedirectSet,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<UrlRedirectSet>,
  onFailure?: ActionFailureHandler<UrlRedirectSet>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    urlRedirectSet.id = generateUniqueIdentifier();

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Create ${urlRedirectSet.id} URL redirect set` }
      : undefined;

    const onSuccessHandler = (createdUrlRedirectSet: UrlRedirectSet) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(createdUrlRedirectSet);
    };

    dispatch(
      ExecuteReduxCrudAction(
        UrlRedirectSetCreateAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          urlRedirectSet,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const deleteUrlRedirectSet = (
  urlRedirectSetId: string,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<UrlRedirectSet>,
  onFailure?: ActionFailureHandler<UrlRedirectSet>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Delete ${urlRedirectSetId} URL redirect set` }
      : undefined;

    const onSuccessHandler = (deletedUrlRedirectSet: UrlRedirectSet) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(deletedUrlRedirectSet);
    };

    dispatch(
      ExecuteReduxCrudAction(
        UrlRedirectSetDeleteAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          urlRedirectSetId,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};
