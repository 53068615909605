import { createAugmentedPostBody } from '../../../../restful-apis/queryTester.api';
import { QueryTesterDetails } from '../QueryTester.types';
import { mapDetailsToAugmentation } from './DetailsAugmentationMapper';
import { SearchIndex } from '../../../../store/types/searchIndex';
import { getKeylessConditionBlock } from '../../common/utils/ConditionBlock';
import _ from 'lodash';

export const mapDetailsToPostBody = (
  queryTesterDetails: QueryTesterDetails,
  searchIndex: SearchIndex,
  query?: string,
  isOobe?: boolean,
) => {
  const augmentations = mapDetailsToAugmentation(queryTesterDetails);

  let keylessAugmentations = _.cloneDeep(augmentations);

  keylessAugmentations = {
    ...keylessAugmentations,
    filterCondition:
      keylessAugmentations.filterCondition && getKeylessConditionBlock(keylessAugmentations.filterCondition),
    boostConditions:
      keylessAugmentations.boostConditions &&
      keylessAugmentations.boostConditions.map(boostCondition => {
        return { ...boostCondition, condition: getKeylessConditionBlock(boostCondition.condition) };
      }),
  };
  return createAugmentedPostBody(
    queryTesterDetails.generalSettings.selectedSearchInstance,
    searchIndex,
    query,
    keylessAugmentations,
    undefined,
    undefined,
    isOobe,
  );
};
