import React from 'react';
import { NotificationItemProps, NotificationItemStyleProps, NotificationItemStyles } from './NotificationItem.types';
import { Stack, Icon, IconButton, classNamesFunction, ProgressIndicator, PrimaryButton } from 'office-ui-fabric-react';
import { NotificationStatus } from '../../../../../../store/types/notificationCenter.d';
import {
  dismissNotification,
  setIsNotificationsPanelOpen,
} from '../../../../../../store/actions/notificationCenterActions';
import { useDispatch } from '../../../../../../store/hooks';
import { MbcClamp } from '../../../../../common/MbcClamp';

const getClassNames = classNamesFunction<NotificationItemStyleProps, NotificationItemStyles>();

export const NotificationItemBase = (props: NotificationItemProps) => {
  const { notification, theme, className, styles, componentRef } = props;
  const dispatch = useDispatch();

  const classNames = getClassNames(styles, { theme: theme!, className: className, notification: notification });

  return (
    <div className={classNames.root} ref={componentRef}>
      <Stack className={classNames.itemStackContainer}>
        <IconButton
          iconProps={{ iconName: 'ChromeClose' }}
          className={classNames.dismissButton}
          onClick={() => {
            dispatch(dismissNotification(notification));
          }}
        />
        <Stack horizontal key={notification.id} verticalAlign={'center'} className={classNames.titleStackContainer}>
          <div className={classNames.titleContainer}>
            <Icon
              iconName={
                notification.status === NotificationStatus.InProgress
                  ? 'AwayStatus'
                  : notification.status === NotificationStatus.Succeeded
                  ? 'SkypeCircleCheck'
                  : notification.status === NotificationStatus.Failed
                  ? 'StatusErrorFull'
                  : undefined
              }
              className={classNames.titleIcon}
            />
            <div className={classNames.title}>{notification.title}</div>
          </div>
        </Stack>
        {!!notification.content && notification.content}
        {!!notification.errorMessage && notification.status === NotificationStatus.Failed && (
          <MbcClamp text={notification.errorMessage} className={classNames.errorMessage} lines={3} />
        )}
        {notification.status === NotificationStatus.InProgress && <ProgressIndicator />}
        <Stack horizontal className={classNames.footer} verticalAlign="center" horizontalAlign="space-between">
          {!!notification.primaryButtonProps && (
            <PrimaryButton
              {...notification.primaryButtonProps}
              className={classNames.primaryButton}
              onClick={() => {
                dispatch(setIsNotificationsPanelOpen(false));
                !!notification.primaryButtonProps && notification.primaryButtonProps.onClick();
              }}
            />
          )}
          <div className={classNames.creationTime}>{notification.creationTime}</div>
        </Stack>
      </Stack>
    </div>
  );
};
