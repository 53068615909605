import { SearchAnalyticsStyleProps, SearchAnalyticsStyles } from './SearchAnalytics.types';

const chartContentCardBody = {
  selectors: {
    '.mbc-content-card-body': {
      padding: 0,
      alignItems: 'center',
      minWidth: 350,
    },
  },
};

export const styles = (props: SearchAnalyticsStyleProps): SearchAnalyticsStyles => {
  return {
    root: [
      'mbc-search-analytics',
      {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      props.className,
    ],
    exportButton: [
      'mbc-export-button',
      {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 8,
      },
    ],
    mainStack: [
      'mbc-main-stack',
      {
        marginTop: 25,
        display: 'grid',
        gridGap: '12px 12px',
        alignItems: 'center',
        paddingBottom: 48,
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridTemplateColumns: '33% 17% 17% 33%',
            width: 'calc(100% - 36px)',
            gridRowGap: '25px',
            gridColumnGap: '12px',
          },
          '@media screen and (max-width: 1399px)': {
            gridTemplateColumns: '100%',
            width: '100%',
            gridGap: '21px 0',
          },
        },
      },
    ],
    searchRequestCharts: [
      'mbc-search-requests-chart',
      {
        gridRow: '1',
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridColumnStart: '1',
            gridColumnEnd: '5',
          },
        },
      },
    ],
    searchLatencyCharts: [
      'mbc-search-requests-chart',
      {
        gridRow: '2',
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridColumnStart: '1',
            gridColumnEnd: '5',
          },
        },
      },
    ],
    topQueries: [
      'mbc-top-queries-chart',
      {
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridRow: '2',
            gridColumnStart: '1',
            gridColumnEnd: '3',
          },
        },
      },
    ],
    noResultQueries: [
      'mbc-no-result-queries-chart',
      {
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridRow: '2',
            gridColumnStart: '3',
            gridColumnEnd: '5',
            marginLeft: 7,
          },
        },
      },
    ],
    chartsGridThirdRow: [
      'mbc-grid-third-row',
      {
        ...chartContentCardBody,
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridRow: '3',
            gridColumnStart: '1',
            gridColumnEnd: '3',
          },
        },
      },
    ],
    filtersUsage: [
      'mbc-filters-usage-chart',
      {
        ...chartContentCardBody,
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridRow: '3',
            gridColumnStart: '3',
            gridColumnEnd: '5',
            marginLeft: 7,
          },
        },
      },
    ],
    servingRegions: [
      'mbc-serving-regions-chart',
      {
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridRow: '3',
            gridColumnStart: '3',
            gridColumnEnd: '5',
            marginLeft: 7,
          },
        },
      },
    ],
    filterCategoriesChart: [
      'mbc-filters-categories-chart',
      {
        selectors: {
          '@media screen and (min-width: 1400px)': {
            gridRow: '4',
            gridColumnStart: '1',
            gridColumnEnd: '5',
          },
        },
      },
    ],
  };
};
