import { Permission } from '../config/userPermissions.config';
import { Environment as RuntimeEnvironment } from '../guards/EnvironmentGuard/EnvironmentGuard';

export enum MbcRouteHash {
  Schema = '#schema',
  General = '#general',
  Insights = '#insights',
  ProductData = '#productData',
}

export enum MbcRouteKey {
  Unknown,
  HomePage,
  Tenant,
  TenantProxy,
  EnvironmentList,
  EnvironmentCreator,
  EnvironmentListProxy,

  Administration,
  Role,
  RoleList,
  RoleEditor,
  RoleProxy,
  RoleListProxy,
  ApiAndTokenList,
  CreateToken,
  TokenListProxy,

  Autosuggest,
  Environment,
  EnvironmentProxy,
  EnvironmentEditor,
  IndexAnalytics,
  SchemaManagement,
  DocumentManagement,

  LegacySearchAnalytics,
  SearchInstanceProxy,
  ProductSearchProxy,
  ProductSearch,
  SearchAnalytics,
  QueryTester,

  BusinessRuleList,
  BusinessRuleListProxy,
  BusinessRuleProxy,
  NewBusinessRule,
  BusinessRuleEditor,

  SynonymSetList,
  SynonymSetListProxy,
  SynonymSetProxy,
  NewSynonymSet,
  SynonymSetEditor,

  UrlRedirectSetList,
  UrlRedirectSetListProxy,
  UrlRedirectSetProxy,
  NewUrlRedirectSet,
  UrlRedirectSetEditor,

  CustomMLRanker,

  SearchModelListProxy,
  SearchModelList,
  SearchModelProxy,
  SearchModel,
  DatasetList,
  TrainingJob,
  EvaluationJobListProxy,
  EvaluationJobList,
  EvaluationJobProxy,
  EvaluationJob,
}

export type RoleRouteKey = MbcRouteKey.Role;

export type CustomMLRouteKey =
  | MbcRouteKey.SearchModel
  | MbcRouteKey.DatasetList
  | MbcRouteKey.TrainingJob
  | MbcRouteKey.SearchModelList
  | MbcRouteKey.EvaluationJobList;

export type CustomMLEvaluationRouteKey = MbcRouteKey.EvaluationJob;

export type ProxyRouteKey =
  | MbcRouteKey.HomePage
  | MbcRouteKey.RoleProxy
  | MbcRouteKey.TenantProxy
  | MbcRouteKey.RoleListProxy
  | MbcRouteKey.TokenListProxy
  | MbcRouteKey.EnvironmentProxy
  | MbcRouteKey.SearchModelProxy
  | MbcRouteKey.ProductSearchProxy
  | MbcRouteKey.EvaluationJobProxy
  | MbcRouteKey.SearchInstanceProxy
  | MbcRouteKey.BusinessRuleProxy
  | MbcRouteKey.BusinessRuleListProxy
  | MbcRouteKey.SynonymSetProxy
  | MbcRouteKey.SynonymSetListProxy
  | MbcRouteKey.UrlRedirectSetProxy
  | MbcRouteKey.UrlRedirectSetListProxy
  | MbcRouteKey.SearchModelListProxy
  | MbcRouteKey.EnvironmentListProxy
  | MbcRouteKey.EvaluationJobListProxy;

export type AdminRouteKey =
  | MbcRouteKey.EnvironmentList
  | MbcRouteKey.ApiAndTokenList
  | MbcRouteKey.CreateToken
  | MbcRouteKey.RoleList
  | MbcRouteKey.EnvironmentCreator
  | MbcRouteKey.RoleEditor;

export type SearchInstanceRouteKey =
  | MbcRouteKey.BusinessRuleList
  | MbcRouteKey.NewBusinessRule
  | MbcRouteKey.SynonymSetList
  | MbcRouteKey.NewSynonymSet
  | MbcRouteKey.UrlRedirectSetList
  | MbcRouteKey.NewUrlRedirectSet
  | MbcRouteKey.CustomMLRanker;

export type EnvironmentRouteKey =
  | MbcRouteKey.QueryTester
  | MbcRouteKey.Environment
  | MbcRouteKey.EnvironmentEditor
  | MbcRouteKey.IndexAnalytics
  | MbcRouteKey.SearchAnalytics
  | MbcRouteKey.SchemaManagement
  | MbcRouteKey.DocumentManagement
  | MbcRouteKey.LegacySearchAnalytics;

export type BusinessRuleRouteKey = MbcRouteKey.BusinessRuleEditor;
export type SynonymSetRouteKey = MbcRouteKey.SynonymSetEditor;
export type UrlRedirectSetRouteKey = MbcRouteKey.UrlRedirectSetEditor;

export interface MbcRouteBase {
  url: string;
  Component?: any;
  key: MbcRouteKey;
  disabled?: boolean;
  redirectUrl?: string;
  isPartialMatch?: boolean;
  minimumPermissions?: Permission[];
  runtimeEnvironments: RuntimeEnvironment[];
}

export interface MbcRoute extends MbcRouteBase {
  browserPush: () => void;
}

export interface MbcRouteMap {
  [key: string]: MbcRouteBase;
}

export interface MbcRouterProps {
  routes: MbcRouteBase[];
  useDefaultRoute?: boolean;
  userPermissions?: string[];
  defaultRedirectRoute?: MbcRouteBase;
}
