import React, { useState, useMemo, useCallback, ReactNode } from 'react';
import { EvaluationListProps, EvaluationListStyles, EvaluationListStyleProps } from './EvaluationList.types';
import {
  Persona,
  PersonaSize,
  PersonaInitialsColor,
  Stack,
  Icon,
  classNamesFunction,
  TooltipHost,
  TooltipOverflowMode,
  IColumn,
  Link,
  ActionButton,
} from 'office-ui-fabric-react';
import { getStatusIconProps } from '../SearchModel.utils';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { useTenantPermissions } from '../../../../store/hooks/use-user-permissions/UseTenantPermissions';
import { DatasetMapper } from '../../../../store/types/customML/dataset.d';
import { UserEmailMapper, ImageStatus } from '../../../../store/types/userList.d';
import { Permission } from '../../../../config/userPermissions.config';
import { isAuthorized } from '../../../../utils';
import { ColumnPriority, SortDataType, ColumnWithPriority } from '../../../../components/common/DetailsList';
import { EvaluateModelPanel } from './EvaluateSearchModel/EvaluateModelPanel';
import { PageTemplate } from '../../../../components/common/PageTemplate';
import { useEvaluationJobList } from '../../../../store/hooks/use-list/useEvaluationJobList';
import { updateTenantUserImage } from '../../../../store/actions/userListActions';
import { EvaluationJob } from '../../../../store/types/customML/evaluationJob.d';
import { ModelJobStatus, SearchModel } from '../../../../store/types/customML/searchModel.d';
import { DetailsListCard } from '../../../../components/common/DetailsListCard';
import { TenantPermissionContextType, TenantPermissionContext } from '../../../../contexts';
import { MbcCustomMLEvaluationRouter } from '../../../../router/MbcCustomMLRouter/MbcCustomMLRouter';
import { Tenant } from '../../../../store/types/tenantsList.d';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { createEvaluationJob } from '../../../../store/actions/customML/evaluationJobActions';

export const getClassNames = classNamesFunction<EvaluationListStyleProps, EvaluationListStyles>();

export const EvaluationListBase = (props: EvaluationListProps) => {
  const { t, styles, theme, componentRef } = props;
  const classNames = getClassNames(styles, { theme: theme! });

  const dispatch = useDispatch();
  const userPermissions = useTenantPermissions();
  const evaluationJobList = useEvaluationJobList();
  const isLoading = useSelector<boolean>(state => state.evaluationJobs.isLoading);
  const activeTenant = useSelector<Tenant>(state => state.tenantsList.activeTenant);
  const searchModel = useSelector<SearchModel>(state => state.searchModels.activeSearchModel);
  const datasetMapper = useSelector<DatasetMapper>(state => state.datasets.datasetMapper);
  const userEmailMapper = useSelector<UserEmailMapper>(state => state.userList.userEmailMapper);

  const [isEvaluatePanelOpen, showEvaluatePanel] = useState<boolean>(
    () =>
      !isLoading && evaluationJobList.length === 0 && isAuthorized(userPermissions, Permission.SearchCustomMLReadWrite),
  );

  const onRenderItemColumnName = useCallback(
    (item?: EvaluationJob, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.name}
        >
          {item.status === ModelJobStatus.Finished && (
            <Link
              onClick={
                MbcCustomMLEvaluationRouter(activeTenant, searchModel, item)(MbcRouteKey.EvaluationJob).browserPush
              }
              style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
            >
              {item.name}
            </Link>
          )}
          {item.status !== ModelJobStatus.Finished && (
            <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
              {item.name}
            </div>
          )}
        </TooltipHost>
      ),
    [activeTenant, classNames.overflow, searchModel],
  );

  const onRenderItemColumnLabelGain = useCallback(
    (item?: EvaluationJob, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.labelGain}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.labelGain}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderItemColumnComparisonModel = useCallback(
    (item?: EvaluationJob, index?: number, column?: IColumn): ReactNode => {
      if (!item || !column) {
        return null;
      }
      const comparisonModel = datasetMapper[item.comparisonModelKey];
      return (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={comparisonModel.name}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {comparisonModel.name}
          </div>
        </TooltipHost>
      );
    },
    [classNames.overflow, datasetMapper],
  );

  const onRenderItemColumnPersona = useCallback(
    (item?: EvaluationJob, index?: number, column?: IColumn): ReactNode => {
      if (!item || !column) {
        return null;
      }
      const user = userEmailMapper[item.email];
      if (user.email && user.imageStatus === ImageStatus.NotInitialized) {
        dispatch(updateTenantUserImage(item.email));
      }
      return (
        <Persona
          text={item.email}
          imageUrl={user.imageUrl}
          size={PersonaSize.size24}
          hidePersonaDetails={false}
          initialsColor={PersonaInitialsColor.darkBlue}
        />
      );
    },
    [dispatch, userEmailMapper],
  );

  const onRenderItemColumnStatus = useCallback(
    (item?: EvaluationJob, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign={'center'} key="status">
          <Icon {...getStatusIconProps(item.status)}></Icon>
          <div style={{ fontSize: 12 }}>{t(`dynamic:custom-ml.job-status.${item.status}`)}</div>
        </Stack>
      ),
    [t],
  );

  const columns: ColumnWithPriority[] = useMemo(
    () => [
      {
        minWidth: 200,
        maxWidth: 200,
        key: 'model-name',
        fieldName: 'name',
        name: t('custom-ml.eval-list.th-name'),
        onRender: onRenderItemColumnName,
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
      },
      {
        minWidth: 200,
        maxWidth: 250,
        key: 'model-owner',
        fieldName: 'email',
        onRender: onRenderItemColumnPersona,
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        name: t('custom-ml.eval-list.th-email'),
      },
      {
        minWidth: 100,
        maxWidth: 150,
        isMultiline: true,
        key: 'model-status',
        fieldName: 'status',
        name: t('custom-ml.eval-list.th-status'),
        priority: ColumnPriority.Tertiary,
        onRender: onRenderItemColumnStatus,
      },
      {
        minWidth: 150,
        maxWidth: 250,
        key: 'comparison-model',
        fieldName: 'comparisonModelKey',
        priority: ColumnPriority.Tertiary,
        name: t('custom-ml.eval-list.th-comparison-model'),
        onRender: onRenderItemColumnComparisonModel,
      },
      {
        minWidth: 150,
        maxWidth: 200,
        key: 'label-gain',
        fieldName: 'labelGain',
        priority: ColumnPriority.Secondary,
        name: t('custom-ml.eval-list.th-label-gain'),
        onRender: onRenderItemColumnLabelGain,
      },
      {
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'createdAt',
        key: 'model-create-date',
        name: t('custom-ml.eval-list.th-create-date'),
        priority: ColumnPriority.Secondary,
      },
    ],
    [
      onRenderItemColumnComparisonModel,
      onRenderItemColumnLabelGain,
      onRenderItemColumnPersona,
      onRenderItemColumnStatus,
      onRenderItemColumnName,
      t,
    ],
  );

  return (
    <>
      <EvaluateModelPanel
        isOpen={isEvaluatePanelOpen}
        onDismiss={() => showEvaluatePanel(false)}
        onSubmit={evaluationJob => dispatch(createEvaluationJob(activeTenant.id, searchModel.key, evaluationJob))}
      />
      <PageTemplate
        breadcrumbVisible
        pageHeaderProps={{
          title: t('custom-ml.eval-list.page-title'),
          actions: [
            <TenantPermissionContext.Consumer key="add">
              {(props: TenantPermissionContextType) => {
                const isAuthorized = props.isAuthorized(Permission.SearchCustomMLReadWrite);
                return (
                  <ActionButton
                    key="eval"
                    disabled={!isAuthorized}
                    iconProps={{ iconName: 'Add' }}
                    onClick={() => showEvaluatePanel(true)}
                  >
                    {t('custom-ml.eval-list.create')}
                  </ActionButton>
                );
              }}
            </TenantPermissionContext.Consumer>,
          ],
        }}
        onRenderContent={() => (
          <DetailsListCard
            componentRef={componentRef}
            searchActionProps={{
              searchBy: 'name',
              ariaLabel: t('custom-ml.eval-list.search-aria-label'),
              placeholder: t('custom-ml.eval-list.search-placeholder'),
            }}
            header={{
              actions: {
                filter: {},
              },
            }}
            listProps={{
              isLoading: isLoading,
              isHeaderVisible: true,
              items: evaluationJobList,
              optionsItemProps: (item: EvaluationJob) => [
                {
                  key: item.key,
                  disabled: true,
                  text: t('custom-ml.eval-list.item-delete'),
                  iconProps: { iconName: 'Delete' },
                  onClick: () => {},
                },
              ],
              responsiveColumns: {
                columns: columns,
                secondaryBreakPoint: 800,
                teritaryBreakPoint: 1156,
              },
            }}
          />
        )}
      />
    </>
  );
};
