import React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import { NoResultsViewProps, NoResultsViewStyleProps, NoResultsViewStyles } from './NoResultsView.types';
import { Trans, useTranslation } from 'react-i18next';

const getClassNames = classNamesFunction<NoResultsViewStyleProps, NoResultsViewStyles>();

export const NoResultsViewBase = (props: NoResultsViewProps): JSX.Element => {
  const { styles, theme, className, componentRef, originalQuery } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });
  const { t } = useTranslation();

  return (
    <div ref={componentRef} className={classNames.root}>
      <div className={classNames.content}>
        {!!originalQuery ? (
          <Trans i18nKey="query-tester.no-results-query">
            No results found for <span className={classNames.query}>{{ originalQuery }}</span>
          </Trans>
        ) : (
          t('query-tester.no-results-filter')
        )}
      </div>
      <div className={classNames.subContent}>
        {!!originalQuery
          ? t('query-tester.no-results-query-subcontent')
          : t('query-tester.no-results-filter-subcontent')}
      </div>
    </div>
  );
};
