import {
  UrlRedirectSet,
  SET_ACTIVE_URL_REDIRECT_SET,
  RESET_ACTIVE_URL_REDIRECT_SET,
  SET_URL_REDIRECT_SET_LIST,
  RESET_URL_REDIRECT_SET_LIST,
  LOADING_URL_REDIRECT_SET_LIST,
  CREATE_OR_UPDATE_URL_REDIRECT_SET,
  DELETE_URL_REDIRECT_SET,
  UrlRedirectSetState,
  UrlRedirectSetIdMapper,
  UrlRedirectSetActionTypes,
} from '../../types/customizations/urlRedirectSet.d';
import { configureDefaultObject } from '../../../utils';

export const createEmptyUrlRedirectSet = () => {
  return {
    id: '',
    redirectUrl: '',
    queryList: [],
  };
};

const createUrlRedirectSetIdMapper = (initialMapper: UrlRedirectSetIdMapper = {}) => {
  return configureDefaultObject<UrlRedirectSetIdMapper, UrlRedirectSet>(initialMapper, createEmptyUrlRedirectSet());
};

const initialState: UrlRedirectSetState = {
  isLoading: true,
  activeUrlRedirectSet: createEmptyUrlRedirectSet(),
  urlRedirectSetIdMapper: createUrlRedirectSetIdMapper(),
};

export function urlRedirectSetReducer(state = initialState, action: UrlRedirectSetActionTypes): UrlRedirectSetState {
  switch (action.type) {
    case SET_ACTIVE_URL_REDIRECT_SET: {
      return {
        ...state,
        activeUrlRedirectSet: state.urlRedirectSetIdMapper[action.id],
      };
    }
    case RESET_ACTIVE_URL_REDIRECT_SET: {
      return {
        ...state,
        activeUrlRedirectSet: createEmptyUrlRedirectSet(),
      };
    }
    case LOADING_URL_REDIRECT_SET_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_URL_REDIRECT_SET_LIST: {
      const UrlRedirectSetIdMapper = createUrlRedirectSetIdMapper({
        ...action.urlRedirectSetList.reduce((mapper: UrlRedirectSetIdMapper, urlRedirectSet: UrlRedirectSet) => {
          mapper[urlRedirectSet.id] = urlRedirectSet;
          return mapper;
        }, {}),
      });
      return {
        ...state,
        isLoading: false,
        urlRedirectSetIdMapper: UrlRedirectSetIdMapper,
      };
    }
    case RESET_URL_REDIRECT_SET_LIST: {
      return {
        ...state,
        isLoading: true,
        urlRedirectSetIdMapper: createUrlRedirectSetIdMapper(),
      };
    }
    case CREATE_OR_UPDATE_URL_REDIRECT_SET: {
      const updatedUrlRedirectSetIdMapper = createUrlRedirectSetIdMapper({
        ...state.urlRedirectSetIdMapper,
        [action.urlRedirectSet.id]: action.urlRedirectSet,
      });
      return {
        ...state,
        urlRedirectSetIdMapper: updatedUrlRedirectSetIdMapper,
      };
    }
    case DELETE_URL_REDIRECT_SET: {
      var updatedUrlRedirectSetIdMapper = createUrlRedirectSetIdMapper({ ...state.urlRedirectSetIdMapper });
      delete updatedUrlRedirectSetIdMapper[action.id];

      return {
        ...state,
        urlRedirectSetIdMapper: updatedUrlRedirectSetIdMapper,
      };
    }

    default:
      return state;
  }
}
