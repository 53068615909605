import { IsGenericServiceException } from '../Utils';
import { FetchResponse, ServiceException, GenericServiceException } from '../Types';
import store from '../../../../store';
import { NotificationStatus } from '../../../../store/types/notificationCenter.d';
import { generateUniqueIdentifier } from '../../../../utils';
import { showToast } from '../../../../store/actions/toastNotificationActions';

export class BaseResponseInterceptor {
  private targetUrl: RegExp;
  private GenericServiceExceptionMessage = 'Unexpected error, please try again!';

  constructor(targetUrl: RegExp) {
    this.targetUrl = targetUrl;
  }

  public Execute(response: FetchResponse): Promise<FetchResponse> {
    if (this.isMatching(response)) {
      return this.PerformAction(response);
    }
    return Promise.resolve(response);
  }

  /*
   * Common behaviour accross response interceptors
   * Should be overriden by child interceptors when required.
   */
  protected PerformAction(response: FetchResponse): Promise<FetchResponse> {
    if (!response.content.ok) {
      // dispatch notification message
      // don't use json() directly to handle empty body responses exceptions
      return response.content.text().then(exception => {
        let failureMessageInternal = '';
        if (exception) {
          failureMessageInternal = this.ExtractExceptionMessage(JSON.parse(exception) as ServiceException);
          if (!response.supressNotificationOnFailure) {
            store.dispatch(
              showToast({
                id: generateUniqueIdentifier(),
                title: failureMessageInternal,
                status: NotificationStatus.Failed,
              }),
            );
          }
        }
        return { ...response, failureMessage: failureMessageInternal };
      });
    } else {
      return Promise.resolve(response);
    }
  }

  private isMatching(response: FetchResponse) {
    return this.targetUrl.test(response.content.url);
  }

  private ExtractGenericExceptionMessage(exception: GenericServiceException) {
    const serviceExceptionMessage = exception.errors
      .map(error => error.moreDetails)
      .filter(error => error)
      .join(', ');

    return serviceExceptionMessage ? serviceExceptionMessage : this.GenericServiceExceptionMessage;
  }

  protected ExtractExceptionMessage(exception: ServiceException): string {
    if (IsGenericServiceException(exception)) {
      return this.ExtractGenericExceptionMessage(exception);
    }

    return this.GenericServiceExceptionMessage;
  }
}
