import { configureDefaultObject, getLocaleTimeNow } from '../../../utils';
import { ModelJobStatus } from '../../types/customML/searchModel.d';
import {
  EvaluationJob,
  EvaluationJobMapper,
  EvaluationJobState,
  EvaluationJobStateActionTypes,
  SET_EVALUATION_JOB_LIST,
  RESET_EVALUATION_JOB_LIST,
  ADD_EVALUATION_JOB,
  SET_ACTIVE_EVALUATION_JOB,
  RESET_ACTIVE_EVALUATION_JOB,
  LOADING_EVALUATION_JOB_METRICS,
  SET_EVALUATION_JOB_METRICS,
  LOADING_EVALUATION_JOB_LIST,
  UPDATE_EVALUATION_JOB,
  UPDATE_EVALUATION_JOB_LIST,
} from '../../types/customML/evaluationJob.d';

export const createInitialEvaluationJob = (): EvaluationJob => ({
  key: '',
  name: '',
  email: '',
  isNew: false,
  iterations: [],
  description: '',
  isLoading: false,
  testDatasetKey: '',
  baselineModelKey: '',
  comparisonModelKey: '',
  labelGain: '0,1,3,7,15',
  createdAt: getLocaleTimeNow(),
  updatedAt: getLocaleTimeNow(),
  status: ModelJobStatus.NotStarted,
});

const createEvaluationJobMapper = (initialMapper: EvaluationJobMapper = {}) =>
  configureDefaultObject<EvaluationJobMapper, EvaluationJob>(initialMapper, createInitialEvaluationJob());

const initialState: EvaluationJobState = {
  isLoading: true,
  evaluationJobMapper: createEvaluationJobMapper(),
  activeEvaluationJob: createInitialEvaluationJob(),
};

export function evaluationJobReducer(state = initialState, action: EvaluationJobStateActionTypes): EvaluationJobState {
  switch (action.type) {
    case LOADING_EVALUATION_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_EVALUATION_JOB_LIST: {
      return {
        ...state,
        isLoading: false,
        evaluationJobMapper: createEvaluationJobMapper({
          ...action.evaluationJobList.reduce((mapper: EvaluationJobMapper, evaluationJob: EvaluationJob) => {
            mapper[evaluationJob.key] = evaluationJob;
            return mapper;
          }, {}),
        }),
      };
    }
    case SET_ACTIVE_EVALUATION_JOB: {
      return {
        ...state,
        activeEvaluationJob: action.evaluationJob,
      };
    }
    case RESET_ACTIVE_EVALUATION_JOB: {
      return {
        ...state,
        activeEvaluationJob: createInitialEvaluationJob(),
      };
    }
    case RESET_EVALUATION_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
        evaluationJobMapper: createEvaluationJobMapper(),
      };
    }
    case ADD_EVALUATION_JOB: {
      return {
        ...state,
        evaluationJobMapper: createEvaluationJobMapper({
          [action.evaluationJob.key]: action.evaluationJob,
          ...state.evaluationJobMapper,
        }),
      };
    }
    case UPDATE_EVALUATION_JOB: {
      const evaluationJob = state.evaluationJobMapper[action.evaluationJob.key];
      if (evaluationJob.key) {
        return {
          ...state,
          activeEvaluationJob:
            state.activeEvaluationJob.key === action.evaluationJob.key
              ? { isNew: evaluationJob.isNew, ...action.evaluationJob }
              : state.activeEvaluationJob,
          evaluationJobMapper: createEvaluationJobMapper({
            ...state.evaluationJobMapper,
            [action.evaluationJob.key]: { isNew: evaluationJob.isNew, ...action.evaluationJob },
          }),
        };
      }

      return state;
    }
    case LOADING_EVALUATION_JOB_METRICS: {
      const updatedEvaluationJob = { ...state.evaluationJobMapper[action.key], isLoading: true };
      return {
        ...state,
        activeEvaluationJob:
          state.activeEvaluationJob.key === action.key ? updatedEvaluationJob : state.activeEvaluationJob,
        evaluationJobMapper: createEvaluationJobMapper({
          ...state.evaluationJobMapper,
          [action.key]: updatedEvaluationJob,
        }),
      };
    }
    case SET_EVALUATION_JOB_METRICS: {
      const updatedEvaluationJob = {
        ...state.evaluationJobMapper[action.key],
        isLoading: false,
        iterations: action.iterations,
      };
      return {
        ...state,
        activeEvaluationJob:
          state.activeEvaluationJob.key === action.key ? updatedEvaluationJob : state.activeEvaluationJob,
        evaluationJobMapper: createEvaluationJobMapper({
          ...state.evaluationJobMapper,
          [action.key]: updatedEvaluationJob,
        }),
      };
    }
    case UPDATE_EVALUATION_JOB_LIST: {
      return {
        ...state,
        evaluationJobMapper: createEvaluationJobMapper({
          ...state.evaluationJobMapper,
          ...action.evaluationJobList.reduce((mapper: EvaluationJobMapper, trainingJob: EvaluationJob) => {
            const updatedEvaluationJob = {
              ...state.evaluationJobMapper[trainingJob.key],
              status: trainingJob.status,
            };
            if (!!updatedEvaluationJob.key) {
              mapper[trainingJob.key] = updatedEvaluationJob;
            }
            return mapper;
          }, {}),
        }),
      };
    }
    default:
      return state;
  }
}
