import React, { useEffect } from 'react';
import { Persona, PersonaSize, classNamesFunction } from 'office-ui-fabric-react';
import { PersonaType, UserPersonaStyleProps, UserPersonaStyles, ConnectedUserPersonaProps } from './UserPersona.types';
import { ImageStatus, UserMetaDataStatus } from '../../../store/types/userList.d';

const getClassNames = classNamesFunction<UserPersonaStyleProps, UserPersonaStyles>();

export const UserPersonaBase = (props: ConnectedUserPersonaProps): JSX.Element => {
  const {
    key,
    personaType,
    ariaLabel,
    className,
    componentRef,
    styles,
    theme,
    user,
    useNameAsPrimaryText,
    userEmailMapperStoreState,
    updateTenantUserImageAction,
    updateTenantUserMetaDataAction,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  useEffect(() => {
    if (typeof user.image === 'undefined') {
      if (userEmailMapperStoreState[user.email].imageStatus === ImageStatus.NotInitialized) {
        updateTenantUserImageAction(user.email);
      }
    }

    if (typeof user.name === 'undefined') {
      if (userEmailMapperStoreState[user.email].userStatus === UserMetaDataStatus.NotInitialized) {
        updateTenantUserMetaDataAction(user.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let nameToUse = user.name;
  if (typeof user.name === 'undefined') {
    nameToUse = userEmailMapperStoreState[user.email].displayName;
  }

  return (
    <div ref={componentRef} className={classNames.root} data-clarity-mask="True">
      <Persona
        coinProps={{
          // eslint-disable-next-line react/display-name
          onRenderPersonaCoin: (props, defaultRender) =>
            !!defaultRender ? <div data-clarity-mask="True">{defaultRender(props)}</div> : null,
        }}
        key={key}
        {...{
          text: useNameAsPrimaryText ? nameToUse || user.email : user.email,
          secondaryText: useNameAsPrimaryText ? userEmailMapperStoreState[user.email].jobTitle : nameToUse,
          imageInitials: user.initials,
          imageUrl: user.image || userEmailMapperStoreState[user.email].imageUrl,
        }}
        onRenderSecondaryText={() => (
          <div data-clarity-mask="True">
            {useNameAsPrimaryText ? userEmailMapperStoreState[user.email].jobTitle : nameToUse}
          </div>
        )}
        showInitialsUntilImageLoads={true}
        hidePersonaDetails={personaType === PersonaType.HidePersonaDetails}
        size={personaType === PersonaType.WithDetails ? PersonaSize.size40 : PersonaSize.size28}
        className={personaType === PersonaType.WithDetails ? classNames.personaDetails : ''}
        aria-label={ariaLabel}
      />
    </div>
  );
};
