import React, { useState } from 'react';
import { Stack, TextField, PrimaryButton, classNamesFunction } from 'office-ui-fabric-react';
import { ContactUsProps, ContactUsStyleProps, ContactUsStyles } from './ContactUs.types';
import { validateUsernameRequiredTextField } from '../../../utils/validators/validateUserName';

const getClassNames = classNamesFunction<ContactUsStyleProps, ContactUsStyles>();

export const ContactUsBase = (props: ContactUsProps): JSX.Element => {
  const { t, styles, theme, className, componentRef } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  // Define user form question/issue state
  const [username, setUsername] = useState('');
  const [question, setQuestion] = useState('');
  const [isFormChanged, setFormChanged] = useState(false);
  const [isInvalidUsername, setUsernameValidation] = useState(false);

  // Generate mail content
  const generateMailContent = () =>
    `mailto:${props.supportEmail}?subject=${encodeURI(username)}&body=${encodeURI(question)}`;

  const getIsInvalidForm = (): boolean => isInvalidUsername || !isFormChanged;

  const onChangeQuestion = (value?: string) => setQuestion(value ? value : '');

  const onChangeUsername = (value?: string) => {
    const newValue = value ? value : '';
    setUsername(newValue);
    setFormChanged(true);
    setUsernameValidation(validateUsernameRequiredTextField(newValue));
  };

  return (
    <div className={classNames.root} ref={componentRef}>
      <div className={classNames.title}>{t('contact-us-form.title')}</div>
      <Stack className={classNames.body} tokens={{ childrenGap: 'l1' }}>
        <TextField
          label={t('common.name')}
          required
          value={username}
          onChange={(ev, v) => onChangeUsername(v)}
          validateOnLoad={false}
          onGetErrorMessage={() => (isInvalidUsername ? t('contact-us-form.required-text-field') : '')}
        />
        <TextField
          label={t('contact-us-form.query')}
          value={question}
          multiline
          rows={5}
          onChange={(ev, v) => onChangeQuestion(v)}
        />
        <Stack horizontal horizontalAlign={'space-between'}>
          <a
            className={classNames.privacyLink}
            href="https://go.microsoft.com/fwlink/?LinkId=521839"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('contact-us-form.privacy-and-policy')}
          </a>
          <div>
            <PrimaryButton
              className={classNames.sendBtn}
              href={generateMailContent()}
              disabled={getIsInvalidForm()}
              onClick={() => props.onDismiss()}
            >
              {t('common.send')}
            </PrimaryButton>
            <PrimaryButton className={classNames.cancelBtn} onClick={() => props.onDismiss()}>
              {t('common.cancel')}
            </PrimaryButton>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};
