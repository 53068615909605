import { ProductToursItemStyleProps, ProductToursItemStyles } from './ProductToursItem.types';
import { getFlexColumnStyles } from '../../../../../common/Styles';

export const styles = (props: ProductToursItemStyleProps): ProductToursItemStyles => {
  return {
    root: ['mbc-product-tours-item', getFlexColumnStyles(), props.className],
    itemTitle: [
      'mbc-product-tours-item-title',
      {
        margin: '22px 0 22px 6px',
        fontSize: 16,
        lineHeight: 22,
      },
    ],
  };
};
