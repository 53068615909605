import React, { Component } from 'react';
import { DirectionalHint, Callout, List } from 'office-ui-fabric-react';
import styles from './demoSearchBoxSuggestions.module.scss';
import { generateUniqueId } from '../../../../../utils';
import classNames from 'classnames';

interface DemoSearchBoxSuggestionsProps {
  isExpanded: boolean;
  searchSuggestions: string[];
  calloutElement: React.RefObject<HTMLDivElement>;
  searchBoxElement: React.RefObject<HTMLDivElement>;
  onSuggestionClick: (suggestion: string | undefined) => void;
}

export default class DemoSearchBoxSuggestions extends Component<DemoSearchBoxSuggestionsProps> {
  private onRenderSuggestion(item?: string, index?: number): React.ReactNode {
    return (
      <div
        className={classNames(styles.suggestionCalloutItem, index !== 0 ? styles.selectable : '')}
        key={generateUniqueId()}
        data-is-focusable={true}
        // Disable onClick on the first suggestions because it's the title
        onClick={index !== 0 ? () => this.props.onSuggestionClick(item) : undefined}
      >
        <div className={index === 0 ? styles.header : ''}>{item}</div>
      </div>
    );
  }

  render() {
    return (
      <Callout
        id="SuggestionContainer"
        gapSpace={2}
        coverTarget={false}
        alignTargetEdge={true}
        hidden={!this.props.isExpanded}
        calloutMaxHeight={300}
        target={this.props.searchBoxElement}
        directionalHint={DirectionalHint.bottomLeftEdge}
        calloutWidth={this.props.searchBoxElement.current ? this.props.searchBoxElement.current.clientWidth : 0}
        isBeakVisible={false}
      >
        <div ref={this.props.calloutElement}>
          <List
            id="SearchList"
            tabIndex={0}
            items={this.props.searchSuggestions}
            onRenderCell={this.onRenderSuggestion.bind(this)}
          />
        </div>
      </Callout>
    );
  }
}
