import { CodeEditorStyleProps, CodeEditorStyles } from './CodeEditor.types';

export const styles = (props: CodeEditorStyleProps): CodeEditorStyles => ({
  root: [
    'mbc-code-editor',
    {
      width: '100%',
      height: '100%',
    },
  ],
  label: {},
  aceEditor: [
    'mbc-ace',
    {
      lineHeight: 20,
      selectors: {
        '.ace_gutter': {
          backgroundColor: props.theme.palette.white,
          color: '#237893 !important',
          selectors: {
            '.ace_gutter-active-line': {
              backgroundColor: props.theme.palette.white,
              color: '#000 !important',
            },
          },
        },
        '.ace_print-margin': {
          display: 'none',
        },
        '.ace_string': {
          color: '#0451a5 !important',
        },
        '.ace_boolean': {
          color: '#0451a5 !important',
        },
        '.ace_numeric': {
          color: '#09885a !important',
        },
      },
    },
  ],
});
