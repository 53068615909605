export enum ErrorType {
  Unknown,
  AccessDenied,
  ResourceNotFound,
}

export interface ErrorBoundaryProps {
  component: string;
  onRenderError?: JSX.Element;
}

export interface ErrorBoundaryState {
  errorType: ErrorType | null;
}
