import React, { Component, RefObject } from 'react';
import { TeachingBubble, ITeachingBubbleProps } from 'office-ui-fabric-react';
import OverlayFocusElement from './overlay-focus-element/overlayFocusElement';
import { FocusState } from '../Tutorials.types';

interface TourStepProps {
  teachingBubbleProps: ITeachingBubbleProps;
  target: RefObject<HTMLDivElement>;
  disableTarget: boolean;
  onDismiss: () => void;
  focusOnTarget: FocusState;
  lightBackground?: boolean;
}

interface TourStepState {
  showStep: boolean;
}
export default class TourStep extends Component<TourStepProps, TourStepState> {
  private interval: any = null;
  static defaultProps = {
    target: null,
    disableTarget: true,
    onDismiss: () => null,
    focusOnTarget: FocusState.Target,
  };

  constructor(props: TourStepProps) {
    super(props);
    this.state = { showStep: false };
    this.interval = setInterval(() => {
      if (this.props.target.current) {
        clearInterval(this.interval);
        this.interval = null;
        this.setState({ showStep: true });
      }
    }, 100);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const calloutProps = this.props.teachingBubbleProps.calloutProps;
    const beakVisible =
      calloutProps !== undefined && calloutProps.isBeakVisible !== undefined ? calloutProps.isBeakVisible : true;
    return (
      this.state.showStep && (
        <>
          <OverlayFocusElement
            focusTarget={this.props.target}
            disableTarget={this.props.disableTarget}
            focusOnTarget={this.props.focusOnTarget}
            lightBackground={this.props.lightBackground}
          ></OverlayFocusElement>
          <TeachingBubble
            {...this.props.teachingBubbleProps}
            hasCondensedHeadline={true}
            target={this.props.target}
            hasCloseIcon={true}
            onDismiss={this.props.onDismiss}
            calloutProps={{
              preventDismissOnLostFocus: true,
              preventDismissOnResize: true,
              preventDismissOnScroll: true,
              layerProps: {
                styles: {
                  root: {
                    zIndex: 1000005,
                  },
                },
              },
              ...this.props.teachingBubbleProps.calloutProps,
            }}
            styles={
              !beakVisible
                ? {
                    root: {
                      alignSelf: 'center',
                    },
                    subComponentStyles: {
                      callout: {
                        container: {
                          height: '100vh',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        },
                      },
                    },
                    footer: { flexDirection: 'row-reverse', justifyContent: 'space-between' },
                    ...this.props.teachingBubbleProps.styles,
                  }
                : {
                    footer: { flexDirection: 'row-reverse', justifyContent: 'space-between' },
                    ...this.props.teachingBubbleProps.styles,
                  }
            }
          >
            {this.props.children}
          </TeachingBubble>
        </>
      )
    );
  }
}
