import React, { ReactNode } from 'react';
import { classNamesFunction, IColumn, IconButton } from 'office-ui-fabric-react';
import { ColumnPriority, SortDataType, DetailsListProps, DetailsList } from '../../../../components/common/DetailsList';
import { SimpleListProps, SimpleListStyleProps, SimpleListStyles } from './SimpleList.types';

const getClassNames = classNamesFunction<SimpleListStyleProps, SimpleListStyles>();

export const SimpleListBase = (props: SimpleListProps) => {
  const { styles, theme, className, items, onItemDelete } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const onRenderItemColumn = (item?: any, column?: IColumn): ReactNode => {
    if (item && column) {
      switch (column.key) {
        case 'delete':
          return <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => onItemDelete(item.key)} />;

        default:
          return <span>{item[column.fieldName as string] as string}</span>;
      }
    }
  };

  const synonymDetailsListProps: DetailsListProps = {
    isLoading: false,
    isLoadedAndEmpty: items.length === 0,
    items: items.map(item => {
      return { key: item, name: item };
    }),
    responsiveColumns: {
      columns: [
        {
          key: 'name',
          name: 'Name',
          fieldName: 'name',
          priority: ColumnPriority.Primary,
          sortDataType: SortDataType.text,
          minWidth: 100,
        },
        {
          key: 'delete',
          name: '',
          fieldName: 'delete',
          priority: ColumnPriority.Primary,
          minWidth: 30,
          onRender: (item, index, column) => onRenderItemColumn(item, column),
        },
      ],
      secondaryBreakPoint: 0,
      teritaryBreakPoint: 0,
    },
  };

  return (
    <div className={classNames.root}>
      <DetailsList {...synonymDetailsListProps} />
    </div>
  );
};
