import React from 'react';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { DefaultButton, classNamesFunction } from 'office-ui-fabric-react';
import { ExcelExportProps, ExcelExportStyleProps, ExcelExportStyles } from './ExcelExport.types';

const getClassNames = classNamesFunction<ExcelExportStyleProps, ExcelExportStyles>();

export const ExcelExportBase = (props: ExcelExportProps): JSX.Element => {
  const { className, theme, componentRef, styles, fileName, sheets, disabled, t } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className, disabled: disabled });

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = () => {
    let sheetNames: string[] = [];
    let sheetsMap: { [key: string]: XLSX.WorkSheet } = {};
    sheets.map(sheet => {
      sheetNames = [...sheetNames, sheet.sheetName];
      sheetsMap[sheet.sheetName] = XLSX.utils.json_to_sheet(sheet.sheetContent);
    });

    const wb = { Sheets: sheetsMap, SheetNames: sheetNames };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={classNames.root} ref={componentRef}>
      <DefaultButton
        text={t('common.export-label')}
        iconProps={{ iconName: 'ExcelLogo' }}
        onClick={exportToCSV}
        className={classNames.exportButton}
        disabled={disabled}
      />
    </div>
  );
};
