import { DocumentsPerSecondStyleProps, DocumentsPerSecondStyles } from './DocumentsPerSecond.types';
import { getFlexColumnStyles } from '../../../../../components/common/Styles';

export const styles = (props: DocumentsPerSecondStyleProps): DocumentsPerSecondStyles => {
  return {
    root: ['ms-documents-per-second-container', getFlexColumnStyles(), props.className],
    subComponentStyles: {
      card: {},
    },
  };
};
