import { flat, distinct } from '../utils';
import { useDispatch } from '../store/hooks';
import { MbcNotFoundError, MbcAccessError } from '../components/MbcError';
import { browserHistory } from '../history.module';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { setLocationRoute } from '../store/actions/routerActions';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router';
import { MbcRouterProps, MbcRouteBase, MbcRouteKey } from './MbcRouter.types';
import { AppRuntimeEnvironment } from '../guards/EnvironmentGuard/utils';
import { UserPermissionLoading } from '../components/Loading';

export const MbcRouter = (props: MbcRouterProps) => {
  const { routes, userPermissions, useDefaultRoute = true, defaultRedirectRoute = MbcRouteKey.Unknown } = props;

  const dispatch = useDispatch();

  const routeDistinctCompare = (x: JSX.Element, y: JSX.Element) => x.key === y.key;

  const routeHasRequiredPermissions = useCallback(
    (route: MbcRouteBase, userRolePermissions: string[]) =>
      !route.minimumPermissions ||
      route.minimumPermissions.length === 0 ||
      route.minimumPermissions.some(permission => userRolePermissions.includes(permission)),
    [],
  );

  const onRenderRouteComponent = useCallback(
    (props: RouteComponentProps<any>, route: MbcRouteBase): ReactNode => {
      dispatch(setLocationRoute(route.key));
      return <route.Component {...props} />;
    },
    [dispatch],
  );

  const ConfigureRedirectRoute = useCallback((route: MbcRouteBase | undefined): JSX.Element[] => {
    return !!route ? [<Redirect key={route.url} to={route.url} />] : [];
  }, []);

  const ConfigureDirectRoute = useCallback(
    (route: MbcRouteBase | undefined, hasPermission: boolean): JSX.Element[] => {
      return !!route
        ? [
            <Route
              key={route.url}
              path={route.url}
              exact={!route.isPartialMatch}
              render={props => (hasPermission ? onRenderRouteComponent(props, route) : <MbcAccessError />)}
            />,
          ]
        : [];
    },
    [onRenderRouteComponent],
  );

  const configureRouteWithRedirection = useCallback(
    (route: MbcRouteBase): JSX.Element[] => {
      if (route.runtimeEnvironments.every(e => e.toLowerCase() !== AppRuntimeEnvironment())) {
        return [];
      }
      const hasPermission = !userPermissions || routeHasRequiredPermissions(route, userPermissions);
      if (!!route.redirectUrl) {
        const withDirectComponentRoute = !!route.Component
          ? [
              <Route
                key={route.redirectUrl}
                path={route.redirectUrl}
                exact={!route.isPartialMatch}
                render={props => (hasPermission ? onRenderRouteComponent(props, route) : <MbcAccessError />)}
              />,
            ]
          : [];
        return [
          <Redirect key={route.url} exact from={route.url} to={route.redirectUrl} />,
          ...withDirectComponentRoute,
        ];
      }
      return ConfigureDirectRoute(route, hasPermission);
    },
    [ConfigureDirectRoute, onRenderRouteComponent, routeHasRequiredPermissions, userPermissions],
  );

  const configureMbcRoutes = useCallback(
    () => distinct(flat(routes.map(configureRouteWithRedirection)), routeDistinctCompare),
    [routes, configureRouteWithRedirection],
  );

  const ConnectedRouterSwitch = useMemo(
    () => (
      <ConnectedRouter history={browserHistory}>
        <Switch>
          {configureMbcRoutes()}
          {defaultRedirectRoute && ConfigureRedirectRoute(defaultRedirectRoute)}
          {useDefaultRoute && <Route component={MbcNotFoundError} />}
        </Switch>
      </ConnectedRouter>
    ),
    [ConfigureRedirectRoute, configureMbcRoutes, defaultRedirectRoute, useDefaultRoute],
  );

  return useMemo(
    () =>
      !!userPermissions ? (
        <UserPermissionLoading>{ConnectedRouterSwitch}</UserPermissionLoading>
      ) : (
        ConnectedRouterSwitch
      ),
    [ConnectedRouterSwitch, userPermissions],
  );
};
