/* eslint-disable react/display-name */
import React, { useCallback, useState } from 'react';
import {
  classNamesFunction,
  SearchBox,
  IconButton,
  IContextualMenuProps,
  IContextualMenuListProps,
  IRenderFunction,
  Stack,
  IContextualMenuItem,
  DirectionalHint,
  Checkbox,
  Link,
  TooltipOverflowMode,
  TooltipHost,
  Icon,
} from 'office-ui-fabric-react';
import {
  DetailsListFilterProps,
  DetailsListFilterStyles,
  DetailsListFilterStyleProps,
  FiltersOptionsObject,
} from './DetailsListFilter.types';

const getClassNames = classNamesFunction<DetailsListFilterStyleProps, DetailsListFilterStyles>();

export const DetailsListFilterBase = (props: DetailsListFilterProps): JSX.Element => {
  const { theme, listItems, className, styles, filterableFields, onClick, disabled, onFilterChange, t } = props;

  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
  });

  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState<string[]>([]);

  // Available subMenu options with which to show and which to hide based on search query
  const [checkBoxesOptions, setCheckBoxesOptions] = useState<FiltersOptionsObject[]>([]);

  const onRenderSubMenuItem = useCallback(
    (item: any, field: string) => (
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
        className={classNames.menuCheckBox}
        onClick={() => {
          let checked = selectedCheckBoxes.includes(item.key);
          if (checked) {
            setSelectedCheckBoxes(state => state.filter(checkBoxKey => checkBoxKey !== item.key));
          } else {
            setSelectedCheckBoxes(state => [...state, item.key]);
          }
          !!item && !!item.onClick && item.onClick();
          onFilterChange(field, item.key, !checked);
        }}
        styles={{ root: { background: selectedCheckBoxes.includes(item.key) ? '#edebe9' : undefined } }}
      >
        <Checkbox
          checked={selectedCheckBoxes.includes(item.key)}
          onChange={(ev, checked) => {
            if (checked) {
              setSelectedCheckBoxes(state => state.filter(checkBoxKey => checkBoxKey !== item.key));
            } else {
              setSelectedCheckBoxes(state => [...state, item.key]);
            }
            !!item && !!item.onClick && item.onClick();
            onFilterChange(field, item.key, !checked);
          }}
        />
        <TooltipHost overflowMode={TooltipOverflowMode.Self} content={item.name}>
          <div className={classNames.drillDownContent}>
            {item.name && item.name.length > 60 ? item.name.slice(0, 60) + '...' : item.name}
          </div>
        </TooltipHost>
      </Stack>
    ),
    [classNames.drillDownContent, classNames.menuCheckBox, onFilterChange, selectedCheckBoxes],
  );

  const getSearchBox = useCallback(
    (currentFieldName: string): JSX.Element => {
      return (
        <SearchBox
          key={currentFieldName}
          underlined={true}
          ariaLabel={currentFieldName}
          placeholder={t('details-list-filter.search-box-placeholder')}
          onSearch={query =>
            setCheckBoxesOptions(state =>
              state.map(value => ({
                value: value.value,
                display: value.value.toLowerCase().includes(query.toLowerCase()),
              })),
            )
          }
          onChange={(ev, query) => {
            if (query) {
              setCheckBoxesOptions(state =>
                state.map(value => ({
                  value: value.value,
                  display: value.value.toLowerCase().includes(query.toLowerCase()),
                })),
              );
            } else {
              setCheckBoxesOptions(state =>
                state.map(value => ({
                  value: value.value,
                  display: true,
                })),
              );
            }
          }}
        />
      );
    },
    [t],
  );

  const onRenderSubMenuList = useCallback(
    (
      currentFieldName: string,
      menuListProps?: IContextualMenuListProps,
      defaultRender?: IRenderFunction<IContextualMenuListProps>,
    ) => {
      return (
        <>
          {getSearchBox(currentFieldName)}
          {defaultRender && defaultRender(menuListProps)}
        </>
      );
    },
    [getSearchBox],
  );

  const getSubMenuProps = useCallback(
    (fieldName: string, displayName: string, optionsExtractionFunction?: (items: any[]) => string[]) => {
      const itemsToDisplay = checkBoxesOptions.filter(uniqueValue => uniqueValue.display);
      return {
        onMenuDismissed: () => {
          setCheckBoxesOptions([]);
        },
        onMenuOpened: () => {
          const options = !!optionsExtractionFunction
            ? optionsExtractionFunction(listItems)
            : listItems.map(listItem => listItem[fieldName]);
          setCheckBoxesOptions(
            options
              .filter((currValue, index, array) => array.indexOf(currValue) === index)
              .map(uniqueValue => ({ value: uniqueValue, display: true })),
          );
        },
        styles: { container: { width: 170 }, list: { width: 170 } },
        onRenderMenuList: (
          menuListProps?: IContextualMenuListProps,
          defaultRender?: IRenderFunction<IContextualMenuListProps>,
        ) => onRenderSubMenuList(displayName, menuListProps, defaultRender),
        items: itemsToDisplay.length
          ? itemsToDisplay.map(uniqueValue => ({
              key: uniqueValue.value,
              name: uniqueValue.value,
              canCheck: true,
              onRender: (item: any) => onRenderSubMenuItem(item, fieldName),
            }))
          : [
              {
                key: 'no_results',
                // eslint-disable-next-line react/display-name
                onRender: () => (
                  <Stack horizontal key="no_results" className={classNames.noDataContainer} tokens={{ childrenGap: 8 }}>
                    <Icon iconName="SearchIssue" title={t('common.no-search-result')} />
                    <span>{t('common.no-search-result')}</span>
                  </Stack>
                ),
              },
            ],
        directionalHint: DirectionalHint.rightTopEdge,
        directionalHintFixed: true,
        gapSpace: -6,
      };
    },
    [checkBoxesOptions, classNames.noDataContainer, listItems, onRenderSubMenuItem, onRenderSubMenuList, t],
  );

  const getItems = useCallback((): IContextualMenuItem[] => {
    if (!!filterableFields && filterableFields.length) {
      if (filterableFields.length === 1) {
        return [];
      } else {
        return filterableFields.map(field => ({
          key: field.fieldName,
          name: field.displayName,
          subMenuProps: getSubMenuProps(field.fieldName, field.displayName, field.optionsExtractionFunction),
        }));
      }
    } else {
      if (listItems.length) {
        let availableFields = Object.keys(listItems[0]).filter(key => typeof listItems[0][key] === 'string');
        return availableFields.map(field => ({
          key: field,
          name: field,
          subMenuProps: getSubMenuProps(field, field),
        }));
      } else {
        return [];
      }
    }
  }, [filterableFields, getSubMenuProps, listItems]);

  const getMenuProps = useCallback((): IContextualMenuProps => {
    const isSingleFilterableField = !!filterableFields && filterableFields.length === 1;
    const singleFieldProps = isSingleFilterableField
      ? getSubMenuProps(
          filterableFields![0].fieldName,
          filterableFields![0].displayName,
          filterableFields![0].optionsExtractionFunction,
        )
      : {};
    return {
      items: getItems(),
      ...singleFieldProps,
      shouldFocusOnMount: true,
      hidden: false,
      className: classNames.contextualMenu,
      directionalHint: DirectionalHint.bottomRightEdge,
      styles: {
        container: { width: 180 },
        list: { width: 180 },
      },
      onRenderMenuList: (
        menuListProps?: IContextualMenuListProps,
        defaultRender?: IRenderFunction<IContextualMenuListProps>,
      ) => (
        <>
          <Stack horizontal horizontalAlign={isSingleFilterableField ? 'space-between' : 'end'} padding={8}>
            {isSingleFilterableField && filterableFields![0].displayName}
            <Link
              onClick={() => {
                setSelectedCheckBoxes([]);
                onFilterChange('all', 'all', false);
              }}
              disabled={selectedCheckBoxes.length === 0}
            >
              {'Clear all'}
            </Link>
          </Stack>
          {defaultRender && defaultRender(menuListProps)}
        </>
      ),
    };
  }, [
    classNames.contextualMenu,
    filterableFields,
    getItems,
    getSubMenuProps,
    onFilterChange,
    selectedCheckBoxes.length,
  ]);

  return (
    <IconButton
      key="Filter"
      iconProps={{ iconName: 'Filter' }}
      ariaLabel={t('common.filter')}
      disabled={listItems.length === 0 || disabled}
      menuProps={getMenuProps()}
      onClick={onClick}
    />
  );
};
