import React, { useCallback, useEffect, useMemo, createRef } from 'react';
import {
  Nav,
  Icon,
  Stack,
  HoverCard,
  IconButton,
  HoverCardType,
  DirectionalHint,
  IPlainCardProps,
  classNamesFunction,
} from 'office-ui-fabric-react';
import { browserHistory } from '../../history.module';
import { useMbcProducts } from './hooks/useMbcProduct';
import { useProductNavLinks, LinksKeys } from './hooks/useProductNavLinks';
import { MbcNavLink, MbcSideNavProps, MbcSideNavStyles, MbcSideNavStyleProps } from './MbcSideNav.types';
import { useTranslation } from 'react-i18next';
import { AvailableTutorials, START_TUTORIAL } from '../../store/types/tutorial.d';
import { Tutorial, TutorialSteps, FocusState } from '../Tutorials';
import { useSelector, useDispatch } from '../../store/hooks';
import { AppState } from '../../store/reducers';
import { MbcRouteKey } from '../../router/MbcRouter.types';

const getClassNames = classNamesFunction<MbcSideNavStyleProps, MbcSideNavStyles>();

export const MbcSideNavBase = (props: MbcSideNavProps) => {
  const { styles, theme, collapsed, onClick } = props;

  const classNames = getClassNames(styles, { theme: theme!, collapsed: collapsed });

  const currentTutorialLinkRef = createRef<HTMLDivElement>();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [selectedMbcProduct] = useMbcProducts();

  const currentTutorial = useSelector((state: AppState) => state.tutorial.currentTutorial);
  const currentRouteKey = useSelector((state: AppState) => state.router.location.state.mbcRouteKey);

  const [selectedNavLinks, setSelectedNavLinks] = useProductNavLinks(selectedMbcProduct);

  useEffect(() => {
    setSelectedNavLinks(selectedMbcProduct);
    return () => {};
  }, [selectedMbcProduct, setSelectedNavLinks]);

  const getCurrentTutorialLinkKey = useCallback(() => {
    switch (currentTutorial) {
      case AvailableTutorials.QueryTester: {
        return LinksKeys.queryTester;
      }
      case AvailableTutorials.SynonymsFull: {
        return LinksKeys.synonyms;
      }
      case AvailableTutorials.BusinessRulesFull: {
        return LinksKeys.businessRules;
      }
    }
  }, [currentTutorial]);

  const onRenderLink = useCallback(
    (link: any): JSX.Element | null => {
      return link ? (
        <div
          className={link.locked ? classNames.linkLockedTag : undefined}
          ref={link.key === getCurrentTutorialLinkKey() && !collapsed ? currentTutorialLinkRef : undefined}
        >
          <span>{link.name}</span>
          {link.beta && <span className={classNames.linkSuperscript}>beta</span>}
          {link.comingSoon && <span className={classNames.linkSuperscript}>soon</span>}
          {link.locked && (!link.links || link.links.length === 0) && (
            <span>
              <Icon iconName="Lock" />
            </span>
          )}
        </div>
      ) : null;
    },
    [
      classNames.linkLockedTag,
      classNames.linkSuperscript,
      collapsed,
      currentTutorialLinkRef,
      getCurrentTutorialLinkKey,
    ],
  );

  const onRenderGroupHeader = useCallback(
    (group: any): JSX.Element | null => {
      return <div className={classNames.collapsedGroupHeader}>{group.name}</div>;
    },
    [classNames.collapsedGroupHeader],
  );

  const onLinkClick = (ev?: any, item?: MbcNavLink) => {
    if (ev && item) {
      ev.preventDefault();
      browserHistory.push(item.url);
    }
  };

  const onRenderPlainCard = useCallback(
    (pages: MbcNavLink[]): JSX.Element | null => {
      if (pages.length === 0 || !pages[0].links || pages[0].links.length === 0) {
        return null;
      }
      return (
        <Nav
          onLinkClick={onLinkClick}
          onRenderGroupHeader={onRenderGroupHeader}
          styles={classNames.subComponentStyles.calloutNav}
          groups={[{ name: pages[0].name, links: pages[0].links }]}
          onRenderLink={link => (link ? onRenderLink(link) : null)}
        />
      );
    },
    [classNames.subComponentStyles.calloutNav, onRenderGroupHeader, onRenderLink],
  );

  const collapsedLinkAs = useCallback(
    (linkProps: any) => {
      const plainCardProps: IPlainCardProps = {
        renderData: linkProps.title,
        // eslint-disable-next-line react/prop-types
        onRenderPlainCard: title => onRenderPlainCard(selectedNavLinks.filter(page => page.name === title)),
        directionalHint: DirectionalHint.rightTopEdge,
      };

      // eslint-disable-next-line react/prop-types
      const currLink = selectedNavLinks.filter(page => page.name === linkProps.title);
      return (
        <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps} cardOpenDelay={100}>
          <div
            ref={linkProps.link.key === getCurrentTutorialLinkKey() && collapsed ? currentTutorialLinkRef : undefined}
            style={{ height: '100%' }}
          >
            <IconButton
              onClick={linkProps.onClick}
              ariaLabel={linkProps.title}
              disabled={linkProps.disabled}
              iconProps={linkProps.iconProps}
              title={currLink[0] && currLink[0].links && currLink[0].links.length > 0 ? '' : linkProps.title}
            />
          </div>
        </HoverCard>
      );
    },
    [collapsed, currentTutorialLinkRef, getCurrentTutorialLinkKey, onRenderPlainCard, selectedNavLinks],
  );

  const navLinkGroups = useMemo(
    () => [
      {
        links: selectedNavLinks.map(navLink => ({
          ...navLink,
          links: collapsed ? undefined : navLink.links,
        })),
      },
    ],
    [collapsed, selectedNavLinks],
  );

  const getTutorial = useCallback(() => {
    switch (currentTutorial) {
      case AvailableTutorials.QueryTester: {
        return (
          <Tutorial
            target={currentTutorialLinkRef}
            step={TutorialSteps.QueryTesterSideNav}
            headline={t('product-tours-panel.quey-tester-tour.headline1')}
            focusOnTarget={FocusState.NoOverlay}
          >
            <>
              <p>{t('product-tours-panel.quey-tester-tour.step-1')}</p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={'/docs/Portal Documentation/#debug-search-queries-for-product-search'}
                className={classNames.tutorialLink}
              >
                {t('product-tours-panel.quey-tester-tour.step-1-link')}
              </a>
            </>
          </Tutorial>
        );
      }
      case AvailableTutorials.BusinessRulesFull: {
        return (
          <Tutorial
            target={currentTutorialLinkRef}
            step={TutorialSteps.BusinessRulesSideNav}
            headline={t('product-tours-panel.business-rules-tour.headline1')}
            focusOnTarget={FocusState.NoOverlay}
            onStepStart={() => {
              if (currentRouteKey !== MbcRouteKey.BusinessRuleList) {
                dispatch({
                  type: START_TUTORIAL,
                  tutorial: AvailableTutorials.None,
                });
              }
            }}
          >
            <>
              <p>{t('product-tours-panel.business-rules-tour.step-1')}</p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={'/docs/Portal Documentation/#manage-business-rules-for-product-search'}
                className={classNames.tutorialLink}
              >
                {t('product-tours-panel.business-rules-tour.step-1-link')}
              </a>
            </>
          </Tutorial>
        );
      }
      case AvailableTutorials.SynonymsFull: {
        return (
          <Tutorial
            target={currentTutorialLinkRef}
            step={TutorialSteps.SynonymsSideNav}
            headline={t('product-tours-panel.synonyms-tour.headline1')}
            focusOnTarget={FocusState.NoOverlay}
            onStepStart={() => {
              if (currentRouteKey !== MbcRouteKey.SynonymSetList) {
                dispatch({
                  type: START_TUTORIAL,
                  tutorial: AvailableTutorials.None,
                });
              }
            }}
          >
            <>
              <p>{t('product-tours-panel.synonyms-tour.step-1')}</p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={'/docs/Portal Documentation/#manage-synonyms'}
                className={classNames.tutorialLink}
              >
                {t('product-tours-panel.synonyms-tour.step-1-link')}
              </a>
            </>
          </Tutorial>
        );
      }
    }
  }, [classNames.tutorialLink, currentRouteKey, currentTutorial, currentTutorialLinkRef, dispatch, t]);

  const selectedNavLink: string | undefined = useMemo(() => {
    switch (currentRouteKey) {
      case MbcRouteKey.NewBusinessRule:
      case MbcRouteKey.BusinessRuleEditor:
        return LinksKeys.businessRules;

      case MbcRouteKey.NewSynonymSet:
      case MbcRouteKey.SynonymSetEditor:
        return LinksKeys.synonyms;

      case MbcRouteKey.NewUrlRedirectSet:
      case MbcRouteKey.UrlRedirectSetEditor:
        return LinksKeys.redirects;

      default:
        return undefined;
    }
  }, [currentRouteKey]);

  return (
    <>
      {getTutorial()}
      <Stack>
        <div className={classNames.navigation}>
          <IconButton
            ariaLabel={t('side-nav.global-btn-aria-label')}
            iconProps={{ iconName: 'GlobalNavButton' }}
            onClick={onClick}
          />
        </div>

        <Nav
          groups={navLinkGroups}
          onLinkClick={onLinkClick}
          onRenderLink={onRenderLink}
          ariaLabel={t('side-nav.aria-label')}
          linkAs={collapsed ? collapsedLinkAs : undefined}
          selectedKey={selectedNavLink}
          styles={classNames.subComponentStyles.collapsibleNav}
        />
      </Stack>
    </>
  );
};
