import { useSelector } from '..';
import { useMemo } from 'react';
import { DatasetType, Dataset } from '../../types/customML/dataset.d';

export const useRawDatasetList = (): Dataset[] => {
  const datasetMapper = useSelector(state => state.datasets.datasetMapper);
  return useMemo(() => Object.values(datasetMapper).filter(dataset => dataset.datasetType === DatasetType.RawData), [
    datasetMapper,
  ]);
};
