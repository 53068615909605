import { IStyle, IStyleFunctionOrObject, ITheme, Omit } from 'office-ui-fabric-react';
import { PageHeaderProps } from '../PageHeader';
import { PageFooterProps } from '../PageFooter';
import { CollapsiblePaneProps } from './CollapsiblePane';

export interface PageTemplateBaseProps {
  theme?: ITheme;
  className?: string;
  styles?: IStyleFunctionOrObject<PageTemplateStyleProps, PageTemplateStyles>;
  pageHeaderProps: PageHeaderProps;

  // Secondary navigation, includes breadcrumb (left) and menu (right)
  breadcrumbVisible?: boolean;
  onSecondaryNavMenuRender?: () => JSX.Element;

  // Footer props
  footerProps?: PageFooterProps;

  // SideNav Props
  sideNavOnClick?: () => void;
}

export interface PageTemplateProps extends PageTemplateBaseProps {
  onRenderContent: () => JSX.Element;
  sideNavMode?: SideNavMode;
}

export interface SplitPageTemplateProps extends PageTemplateBaseProps {
  onRenderLeftSideContent: () => JSX.Element;
  collapsiblePaneProps: Pick<CollapsiblePaneProps, 'onRenderPaneContent'> &
    Partial<Omit<CollapsiblePaneProps, 'onRenderPaneContent'>>;
  dragPosition?: number;
  onDragPositionChange?: (pos: number) => void;
  // SideNav Props
  sideNavMode: SideNavMode.Collapsed | SideNavMode.Expanded | SideNavMode.Hoverable;
}

export enum SideNavMode {
  None,
  Expanded,
  Collapsed,
  Hoverable,
}

export type PageTemplateStyleProps = Required<Pick<PageTemplateProps, 'theme'>> &
  Pick<PageTemplateProps, 'className'> &
  Pick<PageTemplateProps, 'sideNavMode'> & { screenSize: number; isSplitPage: boolean };

export interface PageTemplateStyles {
  root: IStyle;
  allContentExceptFooter: IStyle;
  sideNav: IStyle;
  mainContainer: IStyle;
  splitContainer: IStyle;
  expandedSplitPane: IStyle;
  collapsedSplitPane: IStyle;
  leftSideContainer: IStyle;
  leftSideHeader: IStyle;
  secondaryNav: IStyle;
}
