import React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import {
  DatetimeValueEditorProps,
  DatetimeValueEditorStyleProps,
  DatetimeValueEditorStyles,
} from './DatetimeValueEditor.types';
import { DateTimePicker } from '../../../../../components/common/DateTimePicker';
import { DATE_TIME_FORMAT } from '../../../../../restful-apis/mappers/customizations/businessRuleMapper';
import moment from 'moment';
const getClassNames = classNamesFunction<DatetimeValueEditorStyleProps, DatetimeValueEditorStyles>();

export const DatetimeValueEditorBase = (props: DatetimeValueEditorProps) => {
  const { styles, theme, className, value, disabled, onValueChange } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  return (
    <div className={classNames.root}>
      <DateTimePicker
        disabled={disabled}
        date={value ? moment(value, DATE_TIME_FORMAT).toDate() : undefined}
        onDateChange={date => {
          const updatedValue = moment(date);
          onValueChange(updatedValue);
        }}
      />
    </div>
  );
};
