import { IconButton, Stack, classNamesFunction, TooltipHost } from 'office-ui-fabric-react';
import {
  DashboardChartCardProps,
  DashboardChartCardStyleProps,
  DashboardChartCardStyles,
} from './DashboardChartCard.types';
import React, { useState } from 'react';
import { TimeFilterOptions, convertTimeSpanToInterval } from '../../Insights.utils';

import { TimeSpanSelector } from '../../common/TimeSpanSelector';
import { TitledContentCard } from '../../../../../../components/common/ContentCard/TitledContentCard';

const getClassNames = classNamesFunction<DashboardChartCardStyleProps, DashboardChartCardStyles>();

export const DashboardChartCardBase = (props: DashboardChartCardProps) => {
  const {
    styles,
    theme,
    cardTitle,
    cardSubTitle,
    hasTimeSelector,
    timeSelectorOptions,
    body,
    onChange,
    refreshOptions,
    isLoading,
    defaultTimeSelectorValue,
    footer,
    className,
    componentRef,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const intialTimeSpan: string = defaultTimeSelectorValue ? defaultTimeSelectorValue : 'P7D';

  const [selectedTimeSpan, setSelectedTimeSpan] = useState(intialTimeSpan);
  const [selectedInterval, setSelectedInterval] = useState(
    defaultTimeSelectorValue ? convertTimeSpanToInterval(intialTimeSpan) : '1d',
  );

  return (
    <div className={classNames.root}>
      <TitledContentCard
        componentRef={componentRef}
        styles={classNames.subComponentStyles.card}
        body={
          <Stack tokens={{ childrenGap: 12 }} horizontalAlign={'start'}>
            {!!refreshOptions && (
              <Stack horizontal verticalAlign={'center'} className={classNames.refreshButton}>
                <TooltipHost content="Refresh">
                  <IconButton
                    iconProps={{ iconName: 'Refresh' }}
                    onClick={() =>
                      !!refreshOptions.onRefreshClick &&
                      refreshOptions.onRefreshClick(selectedTimeSpan, selectedInterval)
                    }
                    disabled={isLoading || refreshOptions.disabled}
                  />
                </TooltipHost>
                {!isLoading && <div className={classNames.refreshText}> {refreshOptions.text}</div>}
              </Stack>
            )}
            {body}
          </Stack>
        }
        footer={footer}
        title={
          <Stack horizontal verticalAlign={'end'} tokens={{ childrenGap: 4 }}>
            <div>{cardTitle}</div>
            <div className={classNames.cardSubtitle}>{cardSubTitle}</div>
          </Stack>
        }
        actions={
          hasTimeSelector ? (
            <TimeSpanSelector
              isLoading={isLoading}
              defaultTimeSpan={defaultTimeSelectorValue ? defaultTimeSelectorValue : TimeFilterOptions.ONE_WEEK}
              availableTimes={
                !!timeSelectorOptions
                  ? timeSelectorOptions
                  : [
                      TimeFilterOptions.ONE_HOUR,
                      TimeFilterOptions.TWELVE_HOURS,
                      TimeFilterOptions.ONE_DAY,
                      TimeFilterOptions.ONE_WEEK,
                    ]
              }
              onTimeSpanChange={(timespan: string, interval: string) => {
                setSelectedTimeSpan(timespan);
                setSelectedInterval(interval);
                onChange && onChange(timespan, interval);
              }}
            />
          ) : (
            undefined
          )
        }
      />
    </div>
  );
};
