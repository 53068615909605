import { ProductToursCardStyleProps, ProductToursCardStyles } from './ProductToursCard.types';

export const styles = (props: ProductToursCardStyleProps): ProductToursCardStyles => {
  return {
    root: [
      'ms-product-tours-card',
      {
        marginRight: 6,
        marginLeft: 6,
        padding: '18px 24px',
        border: '1px solid rgba(0,0,0,0.1)',
        selectors: {
          path: {
            fill: props.theme.palette.neutralPrimaryAlt,
          },
        },
      },
      props.className,
    ],
    cardTitle: [
      'ms-card-title',
      {
        fontSize: 20,
        lineHeight: 28,
        fontWeight: 600,
      },
    ],
    cardSubtitle: [
      'ms-card-title',
      {
        fontSize: 13,
        lineHeight: 18,
      },
    ],
    tourStatus: [
      'ms-tour-card-status',
      {
        fontSize: 14,
        lineHeight: 19,
        color: '#605E5C',
      },
    ],
  };
};
