import { BusinessRuleListStyles, BusinessRuleListStyleProps } from './BusinessRuleList.types';

export const styles = (props: BusinessRuleListStyleProps): BusinessRuleListStyles => {
  const { className } = props;
  return {
    root: ['mbc-BusinessRuleList', {}, className],
    nameColumnCell: [
      'mbc-nameColumnCell',
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        selectors: {
          '.ms-Link': {
            color: '#355eff',
          },
        },
      },
    ],
  };
};
