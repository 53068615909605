import { ReduxPollAction, PollDispatch as Dispatch, PollStatus } from './PollAction.types';
import { AppState } from '../../reducers';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { isArray } from 'util';
import { NotificationWithContext, NotificationStatus, NotificationBase } from '../../types/notificationCenter.d';
import { generateUniqueIdentifier } from '../../../utils';
import { updateNotification, pushSingleNotification } from '../notificationCenterActions';
import moment from 'moment';
import { FetchResponse } from '../../../restful-apis/http-client/http-interceptors';

/**
 * An intenral action to dispatch Notification state
 *
 * @param {string} uniqueId notification card identifier for updates
 * @param {Dispatch} dispatch thunk dispatch to trigger store side effects
 * @param {NotificationStatus} status determines the notification center state
 * @param {NotificationBase} notification detailed notification context
 */

const showActionNotificationInternal = (
  uniqueId: string,
  dispatch: Dispatch,
  status: NotificationStatus,
  notification: NotificationBase,
  errorMessage?: string,
) => {
  const notificationInternal: NotificationWithContext = {
    ...notification,
    read: false,
    id: uniqueId,
    status: status,
    creationTime: moment().format('LT'),
    errorMessage: errorMessage,
  };

  status === NotificationStatus.InProgress
    ? dispatch(pushSingleNotification(notificationInternal))
    : dispatch(updateNotification(notificationInternal));
};

/**
 * An intenral action to dispatch Notification state
 *
 * @param {string} uniqueId notification card identifier for updates
 * @param {Dispatch} dispatch thunk dispatch to trigger store side effects
 * @param {NotificationStatus} status determines the notification center state
 * @param {NotificationBase} notification detailed notification context
 */

const ExecutePollFailureAsync = async <T, R>(
  exception: R | FetchResponse,
  dispatch: Dispatch,
  props: ReduxPollAction<T, R>,
  uniqueId: string,
  supressAllProgressIndicators?: boolean,
) => {
  const { onFailure, notification, errorMessage } = props;

  const exceptionMessage =
    (errorMessage && errorMessage(exception)) || (exception as FetchResponse).failureMessage || undefined;

  !supressAllProgressIndicators &&
    showActionNotificationInternal(
      uniqueId,
      dispatch,
      NotificationStatus.Failed,
      notification.failure(exceptionMessage),
      notification.supressErrorMessage ? undefined : exceptionMessage,
    );

  // Execute on failure handler if exists
  onFailure && onFailure(exception, dispatch);
};

/**
 * An intenral action to dispatch Notification state
 *
 * @param {string} uniqueId notification card identifier for updates
 * @param {Dispatch} dispatch thunk dispatch to trigger store side effects
 * @param {NotificationStatus} status determines the notification center state
 * @param {NotificationBase} notification detailed notification context
 */

const ExecuteIntervalPollCheckAsync = async <T, R>(
  model: T,
  dispatch: Dispatch,
  props: ReduxPollAction<T, R>,
  uniqueId: string,
  supressAllProgressIndicators?: boolean,
) => {
  const { onSuccess, pollStatus, pollRequest, notification, postActionMapper } = props;

  const interval = await setInterval(async () => {
    try {
      const pollResponse = await pollRequest(model);
      switch (pollStatus(pollResponse)) {
        case PollStatus.Succeeded:
          // Clear current interval
          clearInterval(interval);
          // Map resource to Redux store action
          if (postActionMapper) {
            const action = postActionMapper(model);
            const actionList = isArray(action) ? action : [action];
            actionList.forEach(action => dispatch(action));
          }

          !supressAllProgressIndicators &&
            showActionNotificationInternal(
              uniqueId,
              dispatch,
              NotificationStatus.Succeeded,
              notification.success(model),
            );

          // Execute on success handler if exists
          onSuccess && onSuccess(model, dispatch);
          break;

        case PollStatus.Failed:
          // Clear current interval
          clearInterval(interval);
          throw pollResponse;
      }
    } catch (exception) {
      await ExecutePollFailureAsync(exception, dispatch, props, uniqueId, supressAllProgressIndicators);
    }
  }, 3000);
};

/**
 * A Redux intenral Async action to perform Poll requests
 * using ThunkDispatch, recursively manipulate all requred dependencies
 *
 * @param {Dispatch} dispatch thunk dispatch to trigger store side effects
 * @param {ReduxPollAction} props async action configuration
 */
const ExecuteReduxPollActionAsync = async <T, R>(
  dispatch: Dispatch,
  props: ReduxPollAction<T, R>,
  uniqueId: string,
  supressAllProgressIndicators?: boolean,
) => {
  const { request, notification, preActionMapper } = props;

  // Dispatch notifiation center / progress indicator for coming Api request
  !supressAllProgressIndicators &&
    showActionNotificationInternal(uniqueId, dispatch, NotificationStatus.InProgress, notification.inProgress);

  // Pre Redux store actions
  if (!!preActionMapper) {
    const actionList = isArray(preActionMapper) ? preActionMapper : [preActionMapper];
    actionList.forEach(action => dispatch(action));
  }

  try {
    await ExecuteIntervalPollCheckAsync(await request, dispatch, props, uniqueId, supressAllProgressIndicators);
  } catch (exception) {
    await ExecutePollFailureAsync(exception, dispatch, props, uniqueId, supressAllProgressIndicators);
  }
};

/**
 * A Redux action to perform Poll (create/read/update/delete) requests
 *
 * @param {ReduxPollAction} props async action configuration
 */
export const ExecuteReduxPollAction = <T, R>(
  props: ReduxPollAction<T, R>,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => ExecuteReduxPollActionAsync<T, R>(dispatch, props, generateUniqueIdentifier());
};
