import { UserList as UsersDTO } from '../dto/users/userList';
import { User as UserDTO } from '../dto/users/user';
import { SandBoxDetails as UserModel } from '../../store/types/user.d';

export function mapUserDtoToModel(user: UserDTO): UserModel {
  return {
    completedTutorials: user.completedTutorials,
    SandboxTrialStartDate: user.sandboxTrialStartDate,
    SandboxTrialEndDate: user.sandboxTrialEndDate,
  };
}

export function mapUserModelToDto(user: UserModel): UserDTO {
  return {
    completedTutorials: user.completedTutorials,
    sandboxTrialStartDate: user.SandboxTrialStartDate,
    sandboxTrialEndDate: user.SandboxTrialEndDate,
  };
}

export function mapUsersListDtoToSingleModel(usersDTO: UsersDTO): UserModel {
  return usersDTO.users.map(mapUserDtoToModel)[0];
}
