import {
  ConfigurationSectionContentStyleProps,
  ConfigurationSectionContentStyles,
} from './ConfigurationSectionContent.types';

export const styles = (props: ConfigurationSectionContentStyleProps): ConfigurationSectionContentStyles => {
  const { theme } = props;

  return {
    root: ['mbc-token-config', props.className],
    expirationMsg: [
      {
        fontSize: '12px',
        lineHeight: '22px',
        textTransform: 'uppercase',
        fontVariant: 'small-caps',
        color: theme.palette.neutralQuaternary,
      },
    ],
  };
};
