import { Action } from 'redux';
import { Environment } from './environmentList.d';

export const GET_TENANT_TOKENS = 'GET_TENANT_TOKENS';
export const LOADING_TENANT_TOKENS = 'LOADING_TENANT_TOKENS';
export const RESET_TENANT_TOKENS = 'RESET_TENANT_TOKENS';

export const CREATE_TENANT_TOKEN = 'CREATE_TENANT_TOKEN';
export const REVOKE_TENANT_TOKEN = 'REVOKE_TENANT_TOKEN';

export interface TokenListState {
  isLoading: boolean;
  tokens: Token[];
}

export interface Token {
  name: string;
  id: string;
  createdAt: string;
  createdBy: string;
  expirationDate: string;
  isRefreshToken: boolean;
  accessToken: string;
  refreshToken: string;
}

export interface TokenWithPermissions extends Token {
  permissions: string[];
  environments: Environment[];
  hasAccessToAllEnvironments: boolean;
}

export function createInitialToken(): Token {
  return {
    name: '',
    id: '',
    createdAt: '',
    createdBy: '',
    expirationDate: '',
    isRefreshToken: false,
    accessToken: '',
    refreshToken: '',
  };
}

export function createInitialTokenWithPermissions(): TokenWithPermissions {
  return {
    ...createInitialToken(),
    permissions: [],
    environments: [],
    hasAccessToAllEnvironments: false,
  };
}

interface CreateToken extends Action<typeof CREATE_TENANT_TOKEN> {
  token: Token;
}

interface RevokeToken extends Action<typeof REVOKE_TENANT_TOKEN> {
  tokenId: string;
}

interface GetTenantTokens extends Action<typeof GET_TENANT_TOKENS> {
  tokens: Token[];
}

export type TokenListStateActionTypes =
  | CreateToken
  | RevokeToken
  | GetTenantTokens
  | Action<typeof RESET_TENANT_TOKENS>
  | Action<typeof LOADING_TENANT_TOKENS>;
