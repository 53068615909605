import { SideNavState, SideNavStateActionTypes, TOGGLE_SIDE_NAV_EXPANDED } from '../types/sideNav.d';

const initialState: SideNavState = {
  isExpanded: true,
};

export const sideNavReducer = (state = initialState, action: SideNavStateActionTypes) => {
  switch (action.type) {
    case TOGGLE_SIDE_NAV_EXPANDED: {
      return {
        ...state,
        isExpanded: !state.isExpanded,
      };
    }
    default:
      return state;
  }
};
