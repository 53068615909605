import { IStyle, IStyleFunctionOrObject, ITheme, IImageStyles } from 'office-ui-fabric-react';

export interface SearchItemCardOwnProps {
  styles?: IStyleFunctionOrObject<SearchItemCardStyleProps, SearchItemCardStyles>;
  theme?: ITheme;
  item: ProductSearchResultItem;
}

export enum Promotion {
  OnSale = 'OnSale',
  Brand = 'Brand',
  Price = 'Price',
}

export type SearchItemCardProps = SearchItemCardOwnProps;

export type SearchItemCardStyleProps = Pick<SearchItemCardOwnProps, 'theme'>;

export interface SearchItemCardStyles {
  root: IStyle;
  detailsContainer: IStyle;
  priceContainer: IStyle;
  superscriptPriceText: IStyle;
  currencyText: IStyle;
  priceText: IStyle;
  name: IStyle;
  description: IStyle;
  integerText: IStyle;
  onSaleText: IStyle;
  highlight: IStyle;
  descriptionContainer: IStyle;
  subComponentStyles: {
    imageStyles: Partial<IImageStyles>;
  };
}

export interface ProductSearchResultItem {
  id?: string;
  name?: string;
  price?: number;
  description?: string;
  imgUrl?: string;
  isFavorited?: boolean;
  currency?: string;
  onSale?: string;
  categories?: string;
  collection?: string;
  promotionDisplay?: string;
  promotedItem?: Promotion;
  promotedValue?: string;
  showSale?: boolean;
}
