import React from 'react';
import { AppState } from '../../../../../store/reducers';
import { MultiLineChart } from '../../../../../components/common/charts/MultiLineChart';
import { setIndexProductCount } from '../../../../../store/actions/insightsAction';
import { DashboardChartCard } from '../common/DashboardChartCard';
import { useSelector, useDispatch } from '../../../../../store/hooks';

import { classNamesFunction } from 'office-ui-fabric-react';
import {
  ProductsCountChartStyleProps,
  ProductsCountChartProps,
  ProductsCountChartStyles,
} from './ProductsCountChart.types';
import { TickType } from '../../../../../components/common/charts/common/AxisTickFormatter';
import { useMediaQuery } from 'react-responsive';
import { TimeFilterOptions } from '../Insights.utils';
import { getChartTitleTooltipMessage } from '../Insights.utils';

const getClassNames = classNamesFunction<ProductsCountChartStyleProps, ProductsCountChartStyles>();

export const ProductsCountChartBase = (props: ProductsCountChartProps) => {
  const { t, styles, theme, className, componentRef, hasTimeSelector, onTimeSelectorChange } = props;
  const indexProductCount = useSelector((state: AppState) => state.insights.indexProductCount);
  const isIndexProductCountLoading = useSelector((state: AppState) => state.insights.isIndexProductCountLoading);

  const dispatch = useDispatch();

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const mediumScreen = useMediaQuery({ query: '(max-width: 935px)' });
  const smallScreen = useMediaQuery({ query: '(max-width: 800px)' });

  return (
    <div className={classNames.root}>
      <DashboardChartCard
        componentRef={componentRef}
        hasTimeSelector={hasTimeSelector}
        cardTitle={t('insights.ingestion-product-count-chart-y')}
        isLoading={isIndexProductCountLoading}
        refreshOptions={{
          text: getChartTitleTooltipMessage(indexProductCount.cacheDetails),
          onRefreshClick: (timespan, interval) => {
            dispatch(setIndexProductCount(timespan, interval));
          },
        }}
        onChange={(timespan, interval) => {
          dispatch(setIndexProductCount(timespan, interval));
          onTimeSelectorChange(timespan, interval);
        }}
        defaultTimeSelectorValue={TimeFilterOptions.ONE_WEEK}
        body={
          <MultiLineChart
            points={indexProductCount.series}
            isLoading={isIndexProductCountLoading}
            xAxisProps={{
              interval:
                indexProductCount.series.length > 26
                  ? 23
                  : indexProductCount.series.length > 13
                  ? 2
                  : indexProductCount.series.length > 8
                  ? mediumScreen
                    ? 2
                    : 1
                  : 0,
              tickProps: {
                tickType: TickType.DateTime12Hrs,
                maximumTextLength: 13,
                blockRotation: !smallScreen,
                rotationBreakPoint: 7,
                fontSize: 12,
                rotatedFontSize: 10,
              },
            }}
            minWidth={50}
            minHeight={230}
            xLine={{
              name: t('insights.ingestion-product-count-chart-x'),
              stroke: 'grey',
            }}
            ylines={[
              {
                name: t('insights.ingestion-product-count-chart-y'),
                stroke: theme!.palette.themePrimary,
              },
            ]}
          />
        }
      />
    </div>
  );
};
