import React from 'react';
import { Stack, Separator, IconButton, classNamesFunction } from 'office-ui-fabric-react';
import { ListItemProps, ListItemStyleProps, ListItemStyles } from './ListItem.types';
import TruncatedText from '../../truncated-text/TruncatedText';

export const getClassNames = classNamesFunction<ListItemStyleProps, ListItemStyles>();

export const ListItemBase = (props: ListItemProps) => {
  const { item, isLastItem, theme, className, componentRef, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div ref={componentRef} className={classNames.root}>
      <Stack gap={4}>
        <Stack
          horizontal
          verticalAlign={isLastItem ? 'end' : 'center'}
          className={isLastItem ? classNames.listItemContainer : classNames.listItemHeaderContainer}
        >
          {isLastItem && <Separator vertical className={classNames.verticalSeparatorLastItem} />}
          <Separator className={isLastItem ? classNames.horizontalSeparatorLastItem : classNames.horizontalSeparator} />
          <div className={classNames.listItemHeader}>
            <TruncatedText value={item.header.title} width={item.header.width} />
          </div>
          {item.header.iconName && (
            <IconButton
              className={classNames.listItemIcon}
              iconProps={{ iconName: item.header.iconName }}
              onClick={item.header.onIconClick}
            />
          )}
        </Stack>
        <Stack>
          <div className={isLastItem ? classNames.listItemSubheaderLastItem : classNames.listItemSubheader}>
            {item.subHeader1}
          </div>
          <div className={isLastItem ? classNames.listItemSubheaderLastItem : classNames.listItemSubheader}>
            {item.subHeader2}
          </div>
        </Stack>
      </Stack>
    </div>
  );
};
