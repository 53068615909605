import {
  QueryTesterState,
  FieldValueSuggestionsMapper,
  FieldValueSuggestions,
  QueryTesterActionTypes,
  SET_FIELD_VALUE_SUGGESTIONS,
  LOADING_FIELD_VALUE_SUGGESTIONS,
  RESET_FIELD_VALUE_SUGGESTIONS,
  TOGGLE_IS_PANE_EXAPNDED,
} from '../../types/customizations/queryTester.d';
import { configureDefaultObject } from '../../../utils';

export const createEmptyFieldValueSuggestions = (): FieldValueSuggestions => {
  return {
    id: '',
    suggestions: [],
  };
};

const createFieldValueSuggestionMapper = (initialMapper: FieldValueSuggestionsMapper = {}) => {
  return configureDefaultObject<FieldValueSuggestionsMapper, FieldValueSuggestions>(
    initialMapper,
    createEmptyFieldValueSuggestions(),
  );
};

const initialState: QueryTesterState = {
  isLoading: true,
  fieldValueSuggestionsMapper: createFieldValueSuggestionMapper(),
  isPaneExpanded: true,
};

export function queryTesterReducer(state = initialState, action: QueryTesterActionTypes): QueryTesterState {
  switch (action.type) {
    case SET_FIELD_VALUE_SUGGESTIONS: {
      const fieldValueSuggestionsMapper = createFieldValueSuggestionMapper({
        ...action.fieldValueSuggestions.reduce(
          (mapper: FieldValueSuggestionsMapper, fieldValueSuggestions: FieldValueSuggestions) => {
            mapper[fieldValueSuggestions.id] = fieldValueSuggestions;
            return mapper;
          },
          {},
        ),
      });

      return {
        ...state,
        isLoading: false,
        fieldValueSuggestionsMapper: fieldValueSuggestionsMapper,
      };
    }

    case LOADING_FIELD_VALUE_SUGGESTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_FIELD_VALUE_SUGGESTIONS: {
      return {
        ...state,
        fieldValueSuggestionsMapper: createFieldValueSuggestionMapper(),
      };
    }
    case TOGGLE_IS_PANE_EXAPNDED: {
      return {
        ...state,
        isPaneExpanded: !state.isPaneExpanded,
      };
    }
    default:
      return state;
  }
}
