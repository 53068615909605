import React, { useState } from 'react';
import { TitledContentCard } from '../../../../../components/common/ContentCard/TitledContentCard';
import {
  ChartContentCardProps,
  ChartContentCardStyles,
  ChartContentCardStyleProps,
  ExportProps,
} from './ChartContentCard.types';
import {
  classNamesFunction,
  CommandButton,
  Link,
  Modal,
  IconButton,
  ShimmeredDetailsList,
  SelectionMode,
  ConstrainMode,
  DetailsListLayoutMode,
  IDetailsRowProps,
  IDetailsRowStyles,
  DetailsRow,
} from 'office-ui-fabric-react';
import { ExcelExport } from '../../../../../components/common/ExcelExport';
import { InfoCard } from '../../../../../components/common/InfoCard';
import { getAggregationInterval } from '../../SearchAnalytics.utils';
import { useSelector } from '../../../../../store/hooks';

const getClassNames = classNamesFunction<ChartContentCardStyleProps, ChartContentCardStyles>();

const ExportLink = (props: ExportProps) => {
  const { fileName, data, theme, styles, className } = props;
  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <ExcelExport
      fileName={fileName}
      sheets={data.length > 0 ? [{ sheetName: fileName, sheetContent: data }] : []}
      className={classNames.exportLink}
      disabled={data.length === 0}
    />
  );
};

export const ChartContentCardBase = (props: ChartContentCardProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    styles,
    theme,
    title,
    chart,
    cardExpandDetails,
    className,
    componentRef,
    t,
    infoProps,
    footer,
    exportData = { fileName: '', data: [] },
    isLoadedAndEmpty,
    refreshCall,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const regionsDistributionFilter = useSelector(state => state.analytics.regionsDistributionFilter);
  const responseCodesFilter = useSelector(state => state.analytics.responseCodesFilter);
  const searchInstancesFilter = useSelector(state => state.analytics.searchInstancesFilter);
  const searchResultsFilter = useSelector(state => state.analytics.searchResultsFilter);
  const startDateFilter = useSelector(state => state.analytics.startDateFilter);
  const endDateFilter = useSelector(state => state.analytics.endDateFilter);
  const searchLatencyPercentile = useSelector(state => state.analytics.searchLatencyPercentile);

  const onRenderExportLink = () => <ExportLink {...props} {...exportData} />;

  const {
    menuItems = [
      {
        key: 'export',
        text: t('analytics.export'),
        onRender: onRenderExportLink,
      },
    ],
  } = props;

  const onRenderRowInternal = (props?: IDetailsRowProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 !== 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: '#FAFAFA' };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const getChartDetails = () => {
    return (
      <ShimmeredDetailsList
        isHeaderVisible={true}
        shimmerLines={5}
        className={classNames.modalDetailsList}
        items={cardExpandDetails!.items}
        selectionMode={SelectionMode.none}
        onRenderRow={props => onRenderRowInternal(props)}
        columns={cardExpandDetails!.columns}
        constrainMode={ConstrainMode.unconstrained}
        layoutMode={DetailsListLayoutMode.justified}
      />
    );
  };

  return (
    <>
      {cardExpandDetails && (
        <Modal isOpen={isModalOpen} isBlocking={true} isDarkOverlay={true}>
          <TitledContentCard
            body={getChartDetails()}
            title={cardExpandDetails.modalTitle}
            className={classNames.modalContentCard}
            actions={
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel={t('analytics.close-icon-label')}
                onClick={() => setIsModalOpen(false)}
              />
            }
          />
        </Modal>
      )}
      <div className={classNames.root}>
        <TitledContentCard
          componentRef={componentRef}
          styles={classNames.subComponentStyles.card}
          body={chart}
          title={
            <div className={classNames.titleContainer}>
              <div>{title}</div>
              {!!infoProps && <InfoCard {...infoProps} />}
            </div>
          }
          actions={
            <CommandButton
              iconProps={{ iconName: isLoadedAndEmpty ? 'Refresh' : 'More', style: { color: 'black' } }}
              menuProps={
                isLoadedAndEmpty
                  ? undefined
                  : {
                      items: menuItems,
                    }
              }
              onClick={() =>
                isLoadedAndEmpty &&
                refreshCall(
                  responseCodesFilter.selectedValues.checkedValues,
                  startDateFilter,
                  endDateFilter,
                  [],
                  regionsDistributionFilter.selectedValues.checkedValues,
                  searchResultsFilter.selectedValues.checkedValues,
                  searchInstancesFilter.selectedValues.checkedValues,
                  getAggregationInterval(startDateFilter, endDateFilter),
                  searchLatencyPercentile,
                )
              }
            />
          }
          footer={
            !!footer
              ? footer
              : cardExpandDetails && (
                  <div className={classNames.footer}>
                    <Link className={classNames.footerLink} onClick={() => setIsModalOpen(true)}>
                      {cardExpandDetails.footerLinkText}
                    </Link>
                  </div>
                )
          }
        />
      </div>
    </>
  );
};
