import HttpClient from './http-client/httpClient';

import { EnvironmentList as EnvironmentListDTO } from './dto/environment/environmentList';
import { Environment as EnvironmentModel } from '../store/types/environmentList';
import {
  mapEnvironmentModelToDTO,
  mapEnvironmentListDtoToSingleModel,
  mapEnvironmentListDtoToModel,
} from './mappers/environmentMapper';

function getAdminApiV2BaseUri(tenantId: string): string {
  return `https://commerce.bing.com/api/admin/v2/tenants/${tenantId}/environments`;
}

function getTrialEnvironmentAPIUri(): string {
  return `https://commerce.bing.com/api/admin/v2/sandboxes`;
}

export function getEnvironments(tenantId: string): Promise<EnvironmentModel[]> {
  return HttpClient.get<EnvironmentListDTO>({ url: getAdminApiV2BaseUri(tenantId) }).then(mapEnvironmentListDtoToModel);
}

export function createEnvironment(tenantId: string, environment: EnvironmentModel): Promise<EnvironmentModel> {
  const environmentDto = mapEnvironmentModelToDTO(environment);
  return HttpClient.post<EnvironmentListDTO>({
    url: getAdminApiV2BaseUri(tenantId),
    body: environmentDto,
    supressNotificationOnFailure: true,
  }).then(mapEnvironmentListDtoToSingleModel);
}

export function createTrialEnvironment(): Promise<EnvironmentListDTO> {
  return HttpClient.post<EnvironmentListDTO>({ url: getTrialEnvironmentAPIUri(), body: {} });
}

export function updateEnvironment(tenantId: string, environment: EnvironmentModel): Promise<EnvironmentModel> {
  const environmentDto = mapEnvironmentModelToDTO(environment);
  return HttpClient.put<EnvironmentListDTO>({
    url: `${getAdminApiV2BaseUri(tenantId)}/${environment.id}`,
    body: environmentDto,
    supressNotificationOnFailure: true,
  }).then(mapEnvironmentListDtoToSingleModel);
}

export function deleteEnvironment(tenantId: string, environmentId: string): Promise<EnvironmentModel> {
  return HttpClient.delete<EnvironmentListDTO>({
    url: `${getAdminApiV2BaseUri(tenantId)}/${environmentId}`,
    body: {},
    supressNotificationOnFailure: true,
  }).then(mapEnvironmentListDtoToSingleModel);
}
