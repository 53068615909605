import { Action } from 'redux';

export const SET_USER_IMAGE = 'SET_USER_IMAGE';
export const SET_LOGGED_IN_USER_DETAILS = 'SET_LOGGED_IN_USER_DETAILS';
export const SET_SANDBOX_USER_DETAILS = 'SET_SANDBOX_USER_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

export interface SandBoxDetails {
  completedTutorials: string[];
  SandboxTrialStartDate: string;
  SandboxTrialEndDate: string;
}
export interface UserState {
  name: string;
  email: string;
  secondaryEmail: string;
  initials: string;
  image: string;
  sandBoxDetails: SandBoxDetails;
}

interface SetLoggedInUserDetails extends Action<typeof SET_LOGGED_IN_USER_DETAILS> {
  name: string;
  email: string;
  initials: string;
  secondaryEmail: string;
}

interface SetSandboxUserDetails extends Action<typeof SET_SANDBOX_USER_DETAILS> {
  sandBoxDetails: SandBoxDetails;
}

interface SetUserImage extends Action<typeof SET_USER_IMAGE> {
  image: string;
}

interface UpdateUserDetailsAction extends Action<typeof UPDATE_USER_DETAILS> {
  sandBoxDetails: SandBoxDetails;
}

export type UserStateActionTypes =
  | SetUserImage
  | SetSandboxUserDetails
  | SetLoggedInUserDetails
  | UpdateUserDetailsAction;
