export const initClarity = () => {
  var s = document.createElement('script');
  s.type = 'text/javascript';
  var code =
    '(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "4beo4n2rqz");';
  try {
    s.appendChild(document.createTextNode(code));
    document.head.appendChild(s);
  } catch (e) {
    s.text = code;
    document.head.appendChild(s);
  }
};
