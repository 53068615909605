import { FileUploaderTreeViewStyleProps, FileUploaderTreeViewStyles } from './FileUploaderTreeView.types';

export const styles = (props: FileUploaderTreeViewStyleProps): FileUploaderTreeViewStyles => ({
  root: ['mbc-file-uploder-tree-view', props.className],
  input: [
    'mbc-file-input',
    {
      display: 'none',
    },
  ],
  errorMessage: [
    {
      fontSize: 12,
      color: 'rgb(164, 38, 44)',
      marginTop: 0,
      paddingLeft: 15,
    },
  ],
});
