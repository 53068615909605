import React from 'react';
import { ExcelExport } from '../../../../components/common/ExcelExport';
import { AppState } from '../../../../store/reducers';
import { useSelector } from '../../../../store/hooks';
import { ExportAllChartsProps, ExportAllChartsStyleProps, ExportAllChartsStyles } from './ExportAllCharts.types';
import { classNamesFunction } from 'office-ui-fabric-react';

const getClassNames = classNamesFunction<ExportAllChartsStyleProps, ExportAllChartsStyles>();

export const ExportAllChartsBase = (props: ExportAllChartsProps): JSX.Element => {
  const { className, componentRef, styles, theme, t } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const topQueries = useSelector((state: AppState) => state.analytics.topQueries);
  const isTopQueriesLoading = useSelector((state: AppState) => state.analytics.isTopQueriesLoading);

  const noResultsQueries = useSelector((state: AppState) => state.analytics.noResultsQueries);
  const isNoResultsQueriesLoading = useSelector((state: AppState) => state.analytics.isNoResultsQueriesLoading);

  const searchInstances = useSelector((state: AppState) => state.analytics.searchInstancesChart);
  const isSearchInstancesChartLoading = useSelector((state: AppState) => state.analytics.isSearchInstancesChartLoading);

  const servingRegions = useSelector((state: AppState) => state.analytics.regionsDistribution);
  const isServingRegionsLoading = useSelector((state: AppState) => state.analytics.isRegionsDistributionLoading);

  const responseCodes = useSelector((state: AppState) => state.analytics.responseCodes);
  const isResponseCodesLoading = useSelector((state: AppState) => state.analytics.isResponseCodesLoading);

  const filtersUsage = useSelector((state: AppState) => state.analytics.filtersUsage);
  const isFiltersUsageLoading = useSelector((state: AppState) => state.analytics.isFiltersUsageLoading);

  const filtersDistribution = useSelector((state: AppState) => state.analytics.filtersDistribution);
  const isFiltersDistributionLoading = useSelector((state: AppState) => state.analytics.isFiltersDistributionLoading);

  const totalSearchRequests = useSelector((state: AppState) => state.analytics.totalSearchRequestsOverTime);
  const isTotalSearchRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isTotalSearchRequestsOverTimeLoading,
  );
  const successfulSearchRequests = useSelector((state: AppState) => state.analytics.successfulRequestsOverTime);
  const isSuccessfulRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isSuccessfulRequestsOverTimeLoading,
  );
  const failedSearchRequests = useSelector((state: AppState) => state.analytics.failedRequestsOverTime);
  const isFailedRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isFailedRequestsOverTimeLoading,
  );
  const clientErrors = useSelector((state: AppState) => state.analytics.userErrorRequestsOverTime);
  const isUserErrorRequestsOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isUserErrorRequestsOverTimeLoading,
  );

  const searchLatencyOverTime = useSelector((state: AppState) => state.analytics.searchLatencyOverTime);
  const isSearchLatencyOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isSearchLatencyOverTimeLoading,
  );
  const searchLatencyPercentile = useSelector(state => state.analytics.searchLatencyPercentile);

  let searchRequestsMap: { [key: string]: {} } = {};
  let searchRequests: any[] = [];
  totalSearchRequests.series.map(
    item => (searchRequestsMap[item.date] = { ...searchRequestsMap[item.date], totalSearchRequests: item.value }),
  );
  successfulSearchRequests.series.map(
    item => (searchRequestsMap[item.date] = { ...searchRequestsMap[item.date], successfulSearchRequests: item.value }),
  );
  failedSearchRequests.series.map(
    item => (searchRequestsMap[item.date] = { ...searchRequestsMap[item.date], failedSearchRequests: item.value }),
  );
  clientErrors.series.map(
    item => (searchRequestsMap[item.date] = { ...searchRequestsMap[item.date], clientErrors: item.value }),
  );

  const dates = Object.keys(searchRequestsMap);
  searchRequests = dates.map(key => ({ date: key, ...searchRequestsMap[key] }));

  return (
    <div ref={componentRef} className={classNames.root}>
      <ExcelExport
        fileName={t('analytics.export-all-charts.file-name')}
        sheets={[
          { sheetName: t('analytics.export-all-charts.top-queries-sheet'), sheetContent: topQueries },
          {
            sheetName: t('analytics.export-all-charts.no-results-queries-sheet'),
            sheetContent: noResultsQueries,
          },
          {
            sheetName: t('analytics.export-all-charts.search-instances-distribution-sheet'),
            sheetContent: searchInstances,
          },
          {
            sheetName: t('analytics.export-all-charts.Serving-regions-distribution-sheet'),
            sheetContent: servingRegions,
          },
          {
            sheetName: t('analytics.export-all-charts.response-codes-distribution-sheet'),
            sheetContent: responseCodes,
          },
          { sheetName: t('analytics.export-all-charts.filters-usage-sheet'), sheetContent: filtersUsage },
          {
            sheetName: t('analytics.export-all-charts.filters-distribution-sheet'),
            sheetContent: filtersDistribution,
          },
          {
            sheetName: t('analytics.export-all-charts.latency-over-time', { percentile: searchLatencyPercentile }),
            sheetContent: searchLatencyOverTime.series,
          },
          {
            sheetName: t('analytics.export-all-charts.search-requests-breakdown-sheet'),
            sheetContent: searchRequests,
          },
        ]}
        disabled={
          isTopQueriesLoading ||
          isNoResultsQueriesLoading ||
          isSearchInstancesChartLoading ||
          isServingRegionsLoading ||
          isResponseCodesLoading ||
          isFiltersUsageLoading ||
          isFiltersDistributionLoading ||
          isTotalSearchRequestsOverTimeLoading ||
          isSuccessfulRequestsOverTimeLoading ||
          isFailedRequestsOverTimeLoading ||
          isUserErrorRequestsOverTimeLoading ||
          isSearchLatencyOverTimeLoading
        }
      />
    </div>
  );
};
