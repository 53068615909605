import {
  Environment,
  ADD_OR_UPDATE_ENVIRONMENT,
  DELETE_ENVIRONMENT,
  EnvironmentWithRoles,
  SET_ENVIRONMENT_INDEX_SOFT_DELETED,
} from '../../../../types/environmentList.d';
import { ActionSuccessHandler, ActionFailureHandler, ReduxCrudAction } from '../../CrudAction.types';
import { updateEnvironment, createEnvironment, deleteEnvironment } from '../../../../../restful-apis/environment.api';
import { getI18n } from 'react-i18next';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';
import { MbcEnvironmentRouter } from '../../../../../router/MbcEnvironmentRouter/MbcEnvironmentRouter';
import { Tenant } from '../../../../types/tenantsList.d';
import store from '../../../..';

export const environmentUpdateAction = (
  tenantId: string,
  environment: Environment,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ReduxCrudAction<Environment> => {
  const i18n = getI18n();
  return {
    request: updateEnvironment(tenantId, environment),
    postActionMapper: updatedEnvironment => ({
      type: ADD_OR_UPDATE_ENVIRONMENT,
      environment: updatedEnvironment,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, environment),
    notification: {
      inProgress: {
        title: i18n.t('environment-list.notifications.env-is-being-edited-message', { envName: environment.name }),
      },
      success: updatedEnvironment => ({
        title: i18n.t('environment-list.notifications.env-edited-message', { envName: environment.name }),
        primaryButtonProps: updatedEnvironment
          ? {
              text: i18n.t('environment-list.notifications.env-updated-message-bar-action-text'),
              onClick: () => {
                MbcEnvironmentRouter(store.getState().tenantsList.activeTenant, updatedEnvironment)(
                  MbcRouteKey.Environment,
                ).browserPush();
              },
            }
          : undefined,
      }),
      failure: () => ({
        title: i18n.t('environment-list.notifications.env-edit-failed-message', { envName: environment.name }),
      }),
    },
  };
};

export const environmentCreateAction = (
  tenant: Tenant,
  environment: Environment,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ReduxCrudAction<Environment> => {
  const i18n = getI18n();
  return {
    request: createEnvironment(tenant.id, environment),
    postActionMapper: createdEnvironment => ({
      type: ADD_OR_UPDATE_ENVIRONMENT,
      environment: createdEnvironment,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, environment),
    notification: {
      inProgress: {
        title: i18n.t('environment-list.notifications.env-is-being-created-message', { envName: environment.name }),
      },
      success: (createdEnvironment?: Environment) => ({
        title: i18n.t('environment-list.notifications.env-created-message', {
          envName: environment.name,
        }),
        primaryButtonProps: createdEnvironment
          ? {
              text: i18n.t('environment-list.notifications.env-created-message-bar-action-text'),
              onClick: () => {
                MbcEnvironmentRouter(tenant, createdEnvironment)(MbcRouteKey.SchemaManagement).browserPush();
              },
            }
          : undefined,
      }),
      failure: () => ({
        title: i18n.t('environment-list.notifications.env-creation-failed-message', { envName: environment.name }),
      }),
    },
  };
};

export const environmentDeleteAction = (
  tenantId: string,
  environment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ReduxCrudAction<Environment> => {
  const i18n = getI18n();
  return {
    request: deleteEnvironment(tenantId, environment.id),
    postActionMapper: deletedEnvironment => ({
      type: deletedEnvironment.indexId ? SET_ENVIRONMENT_INDEX_SOFT_DELETED : DELETE_ENVIRONMENT,
      environmentId: deletedEnvironment.id,
    }),
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, environment.id),
    notification: {
      inProgress: {
        title: i18n.t('environment-list.notifications.env-is-being-deleted-message', { envName: environment.name }),
      },
      success: () => ({
        title: i18n.t('environment-list.notifications.env-deleted-message', { envName: environment.name }),
      }),
      failure: () => ({
        title: i18n.t('environment-list.notifications.env-deletion-failed-message', { envName: environment.name }),
      }),
    },
  };
};
