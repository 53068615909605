import { EnvrionmentStatusStyleProps, EnvrionmentStatusStyles } from './EnvironmentStatus.types';

export const styles = (props: EnvrionmentStatusStyleProps): EnvrionmentStatusStyles => {
  return {
    root: ['mbc-environments-list', props.className],
    instanceStatusIcon: [
      'mbc-instance-status-icon',
      {
        fontSize: 'xx-small',
      },
    ],
  };
};
