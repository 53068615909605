import React from 'react';
import {
  DetailsListCardHeaderProps,
  DetailsListCardHeaderStyles,
  DetailsListCardHeaderStyleProps,
  DetailsListCardActions,
} from './DetailsListCardHeader.types';
import { classNamesFunction, SearchBox, IconButton, CommandBarButton, Stack } from 'office-ui-fabric-react';
import { DetailsListFilter } from '../../DetailsList/DetailsListFilter';
import { InfoCard } from '../../InfoCard';

export const getClassNames = classNamesFunction<DetailsListCardHeaderStyleProps, DetailsListCardHeaderStyles>();

export function DetailsListCardHeaderBase(props: DetailsListCardHeaderProps) {
  const { t, styles, theme, className, componentRef, title, subTitle, actions, searchActionProps, infoProps } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const getCardActions = (actions?: DetailsListCardActions): JSX.Element[] => {
    if (!actions) {
      return [];
    }

    let actionsListInternal: JSX.Element[] = actions.actionsList ? actions.actionsList : [];

    if (!!actions.edit) {
      actionsListInternal = [
        ...actionsListInternal,
        <CommandBarButton
          key="Edit"
          iconProps={{ iconName: 'Edit' }}
          text={t('common.edit')}
          onClick={actions.edit.onClick}
          disabled={actions.edit.disabled}
        />,
      ];
    }

    if (!!actions.remove) {
      actionsListInternal = [
        ...actionsListInternal,
        <IconButton
          key="Delete"
          iconProps={{ iconName: 'Delete' }}
          ariaLabel={t('common.delete')}
          onClick={actions.remove.onClick}
          disabled={actions.remove.disabled}
        />,
      ];
    }

    if (!!actions.add) {
      actionsListInternal = [
        ...actionsListInternal,
        <CommandBarButton
          key="Add"
          iconProps={{ iconName: 'Add' }}
          text={t('common.add')}
          onClick={actions.add.onClick}
          disabled={actions.add.disabled}
        />,
      ];
    }

    if (!!actions.filter) {
      actionsListInternal = [
        ...actionsListInternal,
        <DetailsListFilter
          key="filter"
          listItems={actions.filter.items}
          filterableFields={actions.filter.filterableFields}
          {...actions.filter}
        />,
      ];
    }

    if (!!actions.options) {
      actionsListInternal = [
        ...actionsListInternal,
        <IconButton
          key="Options"
          iconProps={{ iconName: 'MoreVertical' }}
          ariaLabel={t('common.options')}
          onClick={actions.options.onClick}
          disabled={actions.options.disabled}
        />,
      ];
    }

    return actionsListInternal;
  };

  return (
    <div ref={componentRef} className={classNames.root}>
      <Stack horizontal>
        <div className={classNames.title}>{title}</div>
        {infoProps && <InfoCard {...infoProps} />}
      </Stack>
      {!!subTitle && <div className={classNames.subTitle}>{subTitle}</div>}
      <div className={classNames.actionsContainer}>
        {!!searchActionProps && (
          <SearchBox
            key={'Search'}
            underlined={true}
            value={searchActionProps.defaultSearchQuery}
            ariaLabel={searchActionProps.ariaLabel ? searchActionProps.ariaLabel : t('common.search-label')}
            placeholder={searchActionProps.placeholder ? searchActionProps.placeholder : t('common.search-placeholder')}
            onSearch={query => searchActionProps.onSearch(query)}
            onChange={(ev, query) => {
              searchActionProps.setSearchQuery(query);
            }}
            className={classNames.searchBox}
          />
        )}
        <div className={classNames.actions}>{getCardActions(actions)}</div>
      </div>
    </div>
  );
}
