import {
  RESET_TENANT_USERS,
  UserListState,
  UserListStateActionTypes,
  UserEmailMapper,
  SET_TENANT_USER_IMAGE,
  User,
  ImageStatus,
  SET_TENANT_USER_META_DATA,
  UserMetaDataStatus,
} from '../types/userList.d';
import { configureDefaultObject } from '../../utils';

export const createUserEmailMapper = (initialMapper: UserEmailMapper = {}) => {
  const defaultUser: User = {
    email: '',
    displayName: '',
    jobTitle: '',
    initials: '',
    imageUrl: '',
    imageStatus: ImageStatus.NotInitialized,
    userStatus: UserMetaDataStatus.NotInitialized,
  };
  return configureDefaultObject<UserEmailMapper, User>(initialMapper, defaultUser);
};

const initialState: UserListState = {
  userEmailMapper: createUserEmailMapper(),
};

export function userListReducer(state = initialState, action: UserListStateActionTypes): UserListState {
  const initialUserEmailMapper: UserEmailMapper = createUserEmailMapper();
  switch (action.type) {
    case SET_TENANT_USER_META_DATA: {
      const user = state.userEmailMapper[action.email];
      return {
        ...state,
        userEmailMapper: createUserEmailMapper({
          ...state.userEmailMapper,
          [action.email]: {
            ...user,
            email: action.email,
            displayName: action.displayName,
            jobTitle: action.jobTitle,
            initials: action.initials,
            userStatus: action.userStatus,
          },
        }),
      };
    }
    case SET_TENANT_USER_IMAGE: {
      const user = state.userEmailMapper[action.email];
      return {
        ...state,
        userEmailMapper: createUserEmailMapper({
          ...state.userEmailMapper,
          [action.email]: {
            ...user,
            email: action.email,
            imageUrl: action.imageUrl,
            imageStatus: action.imageStatus,
          },
        }),
      };
    }
    case RESET_TENANT_USERS: {
      return {
        ...state,
        userEmailMapper: initialUserEmailMapper,
      };
    }
    default:
      return state;
  }
}
