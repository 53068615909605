import React, { useCallback } from 'react';
import { MbcRouter } from '../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../router/MbcRouter.types';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { useProxyRouter } from '../../store/hooks/use-router/useProxyRouter';
import { useTenantPermissions } from '../../store/hooks/use-user-permissions/UseTenantPermissions';

export const EnvironmentListProxy = () => {
  const tenantPermissions = useTenantPermissions();
  const MbcAdminRouteMapper = useAdminRouter();
  const MbcProxyRouteMapper = useProxyRouter();

  const getMbcDefaultAdminRoutes = useCallback(
    (): MbcRouteBase[] => [
      MbcAdminRouteMapper(MbcRouteKey.EnvironmentList),
      MbcAdminRouteMapper(MbcRouteKey.EnvironmentCreator),
      MbcProxyRouteMapper(MbcRouteKey.EnvironmentProxy),
    ],
    [MbcAdminRouteMapper, MbcProxyRouteMapper],
  );

  return <MbcRouter userPermissions={tenantPermissions} routes={getMbcDefaultAdminRoutes()} />;
};
