import {
  SearchPerformanceMetricCardsStyles,
  SearchPerformanceMetricCardsStyleProps,
} from './SearchPerformanceMetricCards.types';

export const getStyles = (props: SearchPerformanceMetricCardsStyleProps): SearchPerformanceMetricCardsStyles => ({
  root: [
    {
      selectors: {
        '@media only screen and (min-width:1400px)': {
          display: 'flex',
          justifyContent: 'space-between',
        },
        '@media only screen and (max-width:1399px)': {
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          columnGap: '21px',
          gridRowGap: '21px',
          width: 'calc(100% - 21px)',
        },
        '@media only screen and (max-width: 855px)': {
          display: 'grid',
          gridTemplateColumns: '100%',
          width: '100%',
        },
      },
    },
    'mbc-search-metrics',
    {
      selectors: {
        '.mbc-content-card': {
          minWidth: 350,
          height: 320,
        },
      },
    },
    props.className,
  ],
  latencyCard: [
    {
      lineHeight: 16,
      display: 'flex',
      justifyContent: 'flex-end',

      selectors: {
        '.mbc-content-card-header': {
          display: 'flex',
          justifyContent: 'center',
        },
        '.mbc-card-header': {
          gridTemplateColumns: '70% 30%',
          width: '100%',
        },
        '.mbc-card-title': {
          justifySelf: 'start',
          paddingLeft: 8,
        },

        '@media only screen and (min-width:1400px)': {
          marginRight: 21,
          marginLeft: 21,
          gridRow: '1',
          gridColumn: '2',
        },
        '.ms-Dropdown-title': {
          border: 'none',
          background: 'white',
          fontWeight: 400,
          selectors: {
            '@media screen and (min-width: 785px)': {
              fontSize: 13,
            },
            '@media screen and (max-width: 784px)': {
              fontSize: 10,
            },
          },
        },
        '.dropDownItem': {
          border: 'none',
          color: '#605e5c',
          fontWeight: 400,
          selectors: {
            '@media screen and (min-width: 785px)': {
              fontSize: 13,
            },
            '@media screen and (max-width: 784px)': {
              fontSize: 10,
            },
          },
        },
      },
    },
  ],
  subComponentStyles: {
    contentCard: {},
    dropDownCallout: {
      dropdownItem: { fontWeight: 600, fontSize: 11 },
      dropdownItemSelected: { fontWeight: 700, fontSize: 11 },
    },
    metricCard: {
      header: {
        justifyContent: 'center',
      },
    },
  },
});
