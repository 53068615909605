import { FeatureCoverageStyleProps, FeatureCoverageStyles } from './FeatureCoverage.types';
import { getFlexColumnStyles } from '../../../../../components/common/Styles';

export const styles = (props: FeatureCoverageStyleProps): FeatureCoverageStyles => ({
  root: ['ms-latency-per-document-container', getFlexColumnStyles(), props.className],
  footer: [
    'ms-footer',
    {
      justifyContent: 'flex-end',
      width: '100%',
      display: 'flex',
    },
  ],
  footerLink: [
    'ms-footer-link',
    {
      height: 'min-content',
      fontWeight: 700,
      fontSize: '0.7rem',
      display: 'flex',
      alignItems: 'flex-end',
      paddingBottom: 5,
      fontStyle: 'italic',
    },
  ],
  footerIcon: [
    'ms-footer-icon',
    {
      fontSize: 12,
      color: 'rgb(0, 120, 212)',
      height: 'fit-content',
    },
  ],
  modalDetailsList: [
    'ms-modal-list',
    {
      selectors: {
        '.ms-DetailsHeader-cellName': {
          fontWeight: 700,
          color: 'black',
          fontSize: '0.7rem',
        },
        '.ms-DetailsRow-cell': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  ],
  modalContentCard: [
    'ms-modal-card',
    {
      selectors: {
        '.mbc-card-title': {
          fontWeight: 600,
          color: 'black',
          fontSize: '16px',
        },
        '.mbc-content-card': {
          maxHeight: 450,
          minHeight: 450,
          minWidth: 400,
          width: '50vw',
          boxShadow: 'none',
        },
        '.mbc-content-card-header': {
          borderBottom: 'none',
          padding: '12px 24px 0 24px',
        },
      },
    },
  ],
  titleContainer: [
    'mbc-title-container',
    {
      display: 'flex',
      alignItems: 'center',

      selectors: {
        '.ms-Button-icon': {
          fontSize: 14,
        },
      },
    },
  ],
  subComponentStyles: {
    card: {},
  },
});
