/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import {
  searchIndexProductsAction,
  deleteSearchIndexProductAction,
  updateIndexProductsAction,
} from './utils/crud-actions/product-search/ProductSearchAction';
import {
  ProductSearchResult,
  RESET_PRODUCT_SEARCH_RESULTS,
  ProductSearchStateActionTypes,
  MAX_PRODUCTS_PER_REQUEST,
} from '../types/productSearch.d';
import { DocumentDeleteResponse } from '../../restful-apis/dto/ingestion/document';
import { IngestionResponse, IngestionStatusResponse } from '../../restful-apis/dto/ingestion/ingestion';
import {
  ActionSuccessHandler,
  ActionFailureHandler,
  ExecuteReduxCrudAction,
  ExecuteReduxPollAction,
  PollActionSuccessHandler,
  PollActionFailureHandler,
  ProductsIngestionPollAction,
  IngestOrUpdateProductPollAction,
} from './utils';

export const updateIndexProducts = (
  top: number = MAX_PRODUCTS_PER_REQUEST,
  onSuccess?: ActionSuccessHandler<ProductSearchResult>,
  onFailure?: ActionFailureHandler<string>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;
    const searchIndex = getState().searchIndex.searchIndex;
    const originalQuery = getState().productSearch.query;
    const totalEstimatedMatches = getState().productSearch.totalEstimatedMatches;
    const searchResultCount = getState().productSearch.productSearchResults.length;
    if (searchResultCount >= totalEstimatedMatches) {
      return;
    }
    dispatch(
      ExecuteReduxCrudAction(
        updateIndexProductsAction(tenantId, searchIndex, originalQuery, searchResultCount, top, onSuccess, onFailure),
      ),
    );
  };
};

export const resetSearchIndexProducts = (): ProductSearchStateActionTypes => {
  return {
    type: RESET_PRODUCT_SEARCH_RESULTS,
  };
};

export const searchIndexProducts = (
  query: string = '',
  top: number = MAX_PRODUCTS_PER_REQUEST,
  onSuccess?: ActionSuccessHandler<ProductSearchResult>,
  onFailure?: ActionFailureHandler<string>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;
    const searchIndex = getState().searchIndex.searchIndex;
    dispatch(
      ExecuteReduxCrudAction(searchIndexProductsAction(tenantId, searchIndex, query, 0, top, onSuccess, onFailure)),
    );
  };
};

export const deleteIndexProduct = (
  productId: string,
  onSuccess?: ActionSuccessHandler<DocumentDeleteResponse>,
  onFailure?: ActionFailureHandler<string>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;
    const searchIndexId = getState().searchIndex.searchIndex.id;
    dispatch(
      ExecuteReduxCrudAction(deleteSearchIndexProductAction(productId, tenantId, searchIndexId, onSuccess, onFailure)),
    );
  };
};

export const createOrUpdateIndexProduct = (
  productId: string,
  product: any,
  onSuccess?: PollActionSuccessHandler<IngestionResponse>,
  onFailure?: PollActionFailureHandler<IngestionStatusResponse>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;
    const searchIndexId = getState().searchIndex.searchIndex.id;
    dispatch(
      ExecuteReduxPollAction(
        IngestOrUpdateProductPollAction(productId, product, tenantId, searchIndexId, onSuccess, onFailure),
      ),
    );
  };
};
export const ingestIndexProductsFromFile = (
  document: File,
  onSuccess?: PollActionSuccessHandler<IngestionResponse>,
  onFailure?: PollActionFailureHandler<IngestionStatusResponse>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;
    const searchIndexId = getState().searchIndex.searchIndex.id;
    dispatch(
      ExecuteReduxPollAction(ProductsIngestionPollAction(document, tenantId, searchIndexId, onSuccess, onFailure)),
    );
  };
};
