import { QueryTesterStyles, QueryTesterStyleProps } from './QueryTester.types';

export const styles = (props: QueryTesterStyleProps): QueryTesterStyles => {
  const { className } = props;
  return {
    root: ['mbc-QueryTester', {}, className],
    tutorialSubheader: [
      'mbc-tutorial-subheader',
      {
        fontWeight: 600,
      },
    ],
    tutorialTargetBackground: [
      'mbc-tutorial-target-background',
      {
        backgroundColor: props.theme.palette.white,
      },
    ],
    supportHint: [
      'mbc-QueryTester-supportHint',
      {
        fontSize: 12,
        color: '#878783;',
        margin: '10px 0',
      },
    ],
  };
};
