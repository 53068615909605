import { ResponseBase } from '../ResponseBase';

export enum SuperJobRelationType {
  Unknown = 'unknown',
  TrainingJobs = 'trainingJobs',
  EvaluationJobs = 'evaluationJobs',
  DeploymentJobs = 'deploymentJobs',
}

export interface SuperJob {
  name: string;
  superJobId: string;
  description: string;
  createTimeUTC: number;
  updateTimeUTC: number;
  jobs: {
    [key: string]: string[];
  };
}

export type CreateSuperJobRequest = Pick<SuperJob, 'name' | 'description'>;
export type CreateSuperJobResponse = ResponseBase & Pick<SuperJob, 'superJobId'>;

export type GetSuperJobRequest = Pick<SuperJob, 'superJobId'>;
export type GetSuperJobResponse = ResponseBase & SuperJob;
