import { TimeSpanSelectorStyleProps, TimeSpanSelectorStyles } from './TimeSpanSelector.types';

export const styles = (props: TimeSpanSelectorStyleProps): TimeSpanSelectorStyles => ({
  root: [
    'mbc-time-span-selector',
    {
      gridRow: '1',
      gridColumn: '2',
      paddingRight: 5,
      lineHeight: 16,
      display: 'flex',
      justifyContent: 'flex-end',

      selectors: {
        '.ms-Dropdown-title': {
          border: 'none',
          background: 'white',
          fontWeight: 400,
          selectors: {
            '@media screen and (min-width: 785px)': {
              fontSize: 13,
            },
            '@media screen and (max-width: 784px)': {
              fontSize: 10,
            },
          },
        },
        '.dropDownItem': {
          border: 'none',
          color: '#605e5c',
          fontWeight: 400,
          selectors: {
            '@media screen and (min-width: 785px)': {
              fontSize: 13,
            },
            '@media screen and (max-width: 784px)': {
              fontSize: 10,
            },
          },
        },
      },
    },
    props.className,
  ],
  subComponentStyles: {
    dropDownCallout: {
      dropdownItem: { fontWeight: 600, fontSize: 11 },
      dropdownItemSelected: { fontWeight: 700, fontSize: 11 },
    },
  },
});
