import React, { useState, useMemo, useCallback } from 'react';
import {
  classNamesFunction,
  PrimaryButton,
  Stack,
  DefaultButton,
  TextField,
  Panel,
  IColumn,
  TooltipHost,
  TooltipOverflowMode,
} from 'office-ui-fabric-react';
import { TrainModelPanelStyleProps, TrainModelPanelStyles, TrainModelPanelProps } from './TrainModelPanel.types';
import {
  onGetLabelGainErrorMsg,
  onGetNumericParameterErrorMsg,
  onGetAdditionalParamsErrorMsg,
  onGetLearningRateErrorMsg,
  isInvalidLabelGain,
  isInvalidNumericParameter,
  isInvalidAdditionalParams,
} from '../../SearchModel.utils';
import { useActiveTraining } from '../../../../../store/hooks/use-active-job/useActiveTraining';
import { useSelector } from '../../../../../store/hooks';
import { useCookingJobList } from '../../../../../store/hooks/use-list/useCookingJobList';
import { TrainingJob, BasicParameters } from '../../../../../store/types/customML/trainingJob.d';
import { getLocaleTimeNow } from '../../../../../utils';
import { ModelJobStatus } from '../../../../../store/types/customML/searchModel.d';
import { ColumnWithPriority, SortDataType, ColumnPriority } from '../../../../../components/common/DetailsList';
import { SelectActionButton } from '../../../../../components/common/SelectActionButton';
import { InfoCard } from '../../../../../components/common/InfoCard';

export const getClassNames = classNamesFunction<TrainModelPanelStyleProps, TrainModelPanelStyles>();

export function TrainModelPanelBase(props: TrainModelPanelProps) {
  const { t, styles, theme, onDismiss, onSubmit, isOpen } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const defaultTrainingJob = useActiveTraining();
  const isCookedDatasetListLoading = useSelector<boolean>(state => state.cookingJobs.isLoading);
  const cookedDatasetList = useCookingJobList().filter(cookingJob => cookingJob.status === ModelJobStatus.Finished);

  const [trainingJob, setTrainingJob] = useState<TrainingJob>({
    ...defaultTrainingJob,
    createdAt: getLocaleTimeNow(),
    updatedAt: getLocaleTimeNow(),
    status: ModelJobStatus.NotStarted,
  });

  const isInvalidForm = () =>
    trainingJob.datasetKey === '' ||
    trainingJob.testDatasetKey === '' ||
    isInvalidLabelGain(trainingJob.parameters[BasicParameters.LabelGain]) ||
    isInvalidNumericParameter(trainingJob.parameters[BasicParameters.NumTrees]) ||
    isInvalidNumericParameter(trainingJob.parameters[BasicParameters.NumLeaves]) ||
    isInvalidAdditionalParams(trainingJob.parameters[BasicParameters.Parameters]) ||
    isInvalidNumericParameter(trainingJob.parameters[BasicParameters.LearningRate]) ||
    isInvalidNumericParameter(trainingJob.parameters[BasicParameters.MinDataInLeaf]);

  const onRenderItemNameColumn = useCallback(
    (item?: any, index?: number, column?: IColumn) =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.name}
        >
          <div
            // onClick={() => MbcEnvironmentRouter(activeTenant, item)(MbcRouteKey.Environment).browserPush()}
            style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
          >
            {item.name}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const panelColumns: ColumnWithPriority[] = useMemo(
    () => [
      {
        name: '',
        minWidth: 200,
        maxWidth: 300,
        key: 'model-name',
        fieldName: 'name',
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        onRender: onRenderItemNameColumn,
      },
    ],
    [onRenderItemNameColumn],
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={onDismiss}
      onRenderNavigation={(props, defaultRender) => (
        <Stack
          horizontal
          verticalAlign={'center'}
          horizontalAlign={'space-between'}
          tokens={{ padding: '16px 16px 38px 16px' }}
        >
          <div className={classNames.header}>{t('custom-ml.train-panel.title')}</div>
          {defaultRender!(props)}
        </Stack>
      )}
      onRenderFooter={() => (
        <Stack horizontal className={classNames.footer} tokens={{ padding: 16, childrenGap: 16 }}>
          <PrimaryButton
            key="train"
            disabled={isInvalidForm()}
            onClick={() => {
              const additionalParameters = trainingJob.parameters[BasicParameters.Parameters];
              if (!!additionalParameters) {
                trainingJob.parameters[BasicParameters.Parameters] = additionalParameters.trim();
              }
              onSubmit(trainingJob);
              onDismiss();
            }}
          >
            {t('custom-ml.jobs.train')}
          </PrimaryButton>
          <DefaultButton key="cancel" onClick={() => onDismiss()}>
            {t('custom-ml.jobs.cancel')}
          </DefaultButton>
        </Stack>
      )}
    >
      <Stack tokens={{ childrenGap: 12 }}>
        <SelectActionButton
          className={classNames.selectionAction}
          responsiveColumns={{
            columns: panelColumns,
            secondaryBreakPoint: 800,
            teritaryBreakPoint: 1156,
          }}
          defaultSelectedItem={trainingJob.datasetKey}
          items={cookedDatasetList.map(dataset => ({
            name: dataset.name,
            key: dataset.cookedDatasetKey,
          }))}
          isLoading={isCookedDatasetListLoading}
          header={t('custom-ml.train-panel.select-train-data-title')}
          itemLabel={t('custom-ml.train-panel.train-data-label')}
          actionLabel={t('custom-ml.train-panel.select-train-data-label')}
          onSelectionChange={(key: string | undefined) =>
            setTrainingJob({ ...trainingJob, datasetKey: !!key ? key : '' })
          }
        />
        <SelectActionButton
          className={classNames.selectionAction}
          responsiveColumns={{
            columns: panelColumns,
            secondaryBreakPoint: 800,
            teritaryBreakPoint: 1156,
          }}
          defaultSelectedItem={trainingJob.testDatasetKey}
          items={cookedDatasetList.map(dataset => ({
            name: dataset.name,
            key: dataset.cookedDatasetKey,
          }))}
          isLoading={isCookedDatasetListLoading}
          header={t('custom-ml.train-panel.select-test-data-title')}
          itemLabel={t('custom-ml.train-panel.test-data-label')}
          actionLabel={t('custom-ml.train-panel.select-test-data-label')}
          onSelectionChange={(key: string | undefined) =>
            setTrainingJob({ ...trainingJob, testDatasetKey: !!key ? key : '' })
          }
        />
        <TextField
          validateOnLoad={false}
          className={classNames.textInput}
          value={trainingJob.parameters[BasicParameters.NumTrees]}
          onGetErrorMessage={value => onGetNumericParameterErrorMsg(value, BasicParameters.NumTrees)}
          onChange={(ev, value) =>
            value !== undefined &&
            setTrainingJob(trainingJob => ({
              ...trainingJob,
              parameters: { ...trainingJob.parameters, [BasicParameters.NumTrees]: value },
            }))
          }
          label={t('custom-ml.train-jobs.num-trees-label')}
        />
        <TextField
          validateOnLoad={false}
          className={classNames.textInput}
          label={t('custom-ml.train-jobs.num_leaves-label')}
          onGetErrorMessage={value => onGetNumericParameterErrorMsg(value, BasicParameters.NumLeaves)}
          value={trainingJob.parameters[BasicParameters.NumLeaves]}
          onChange={(ev, value) =>
            value !== undefined &&
            setTrainingJob(trainingJob => ({
              ...trainingJob,
              parameters: { ...trainingJob.parameters, [BasicParameters.NumLeaves]: value },
            }))
          }
        />
        <TextField
          validateOnLoad={false}
          className={classNames.textInput}
          label={t('custom-ml.train-jobs.min-data-in-leaf-label')}
          value={trainingJob.parameters[BasicParameters.MinDataInLeaf]}
          onGetErrorMessage={value => onGetNumericParameterErrorMsg(value, BasicParameters.MinDataInLeaf)}
          onChange={(ev, value) =>
            value !== undefined &&
            setTrainingJob(trainingJob => ({
              ...trainingJob,
              parameters: { ...trainingJob.parameters, [BasicParameters.MinDataInLeaf]: value },
            }))
          }
        />
        <TextField
          validateOnLoad={false}
          className={classNames.textInput}
          label={t('custom-ml.train-jobs.learning-rate-label')}
          onGetErrorMessage={onGetLearningRateErrorMsg}
          value={trainingJob.parameters[BasicParameters.LearningRate]}
          onChange={(ev, value) =>
            value !== undefined &&
            setTrainingJob(trainingJob => ({
              ...trainingJob,
              parameters: { ...trainingJob.parameters, [BasicParameters.LearningRate]: value },
            }))
          }
        />
        <TextField
          validateOnLoad={false}
          className={classNames.textInput}
          onGetErrorMessage={onGetLabelGainErrorMsg}
          label={t('custom-ml.train-jobs.label-gain-label')}
          value={trainingJob.parameters[BasicParameters.LabelGain]}
          onChange={(ev, value) =>
            value !== undefined &&
            setTrainingJob(trainingJob => ({
              ...trainingJob,
              parameters: { ...trainingJob.parameters, [BasicParameters.LabelGain]: value },
            }))
          }
        />
        {/* <TextField
          multiline
          validateOnLoad={false}
          style={{ minHeight: 112 }}
          className={classNames.textInput}
          onGetErrorMessage={onGetSelectedFeaturesErrorMsg}
          label={t('custom-ml.train-jobs.add-features-label')}
          placeholder={t('custom-ml.train-jobs.add-features-placeholder')}
          value={trainingJob.parameters[BasicParameters.FeatureSelected]}
          onChange={(ev, value) =>
            value !== undefined &&
            setTrainingJob(trainingJob => ({
              ...trainingJob,
              parameters: { ...trainingJob.parameters, [BasicParameters.FeatureSelected]: value },
            }))
          }
        /> */}
        <TextField
          multiline
          validateOnLoad={false}
          style={{ minHeight: 112 }}
          className={classNames.textInput}
          onRenderLabel={() => (
            <div className={classNames.label}>
              {t('custom-ml.train-jobs.add-params-label')}
              <InfoCard
                headline={t('custom-ml.train-jobs.add-params-info-header')}
                content={t('custom-ml.train-jobs.add-params-info-content')}
              />
            </div>
          )}
          onGetErrorMessage={onGetAdditionalParamsErrorMsg}
          placeholder={t('custom-ml.train-jobs.add-params-placeholder')}
          value={trainingJob.parameters[BasicParameters.Parameters]}
          onChange={(ev, value) =>
            value !== undefined &&
            setTrainingJob(trainingJob => ({
              ...trainingJob,
              parameters: { ...trainingJob.parameters, [BasicParameters.Parameters]: value },
            }))
          }
        />
      </Stack>
    </Panel>
  );
}
