import HttpClient from './http-client/httpClient';
import { MetricResponse, ErrorReportResponse } from './dto/insights/insights';
import {
  mapMetricDtoToModel,
  mapMetricDtoToDocumentBreakDownModel,
  mapMetricDtoToFeatureCoverageModel,
  mapErrorReportDtoToModel,
} from './mappers/insightsMapper';
import { MetricModel, DocumentBreakDownModel, FeatureCoverageModel, ErrorReportModel } from '../store/types/insights.d';

function getBaseUri(): string {
  return 'https://commerce.bing.com/api/analytics/v1/retail';
}

export function getMetric(
  tenantId: string,
  indexId: string,
  metricName: string,
  apiType: string,
  interval: string,
  timespan: string,
): Promise<MetricModel> {
  return HttpClient.get<MetricResponse>({
    url: `${getBaseUri()}/${tenantId}/indexes/${indexId}/metric?metricName=${metricName}&apiType=${apiType}&timespan=${timespan}&interval=${interval}`,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapMetricDtoToModel);
}

export function getLatencyPerDocumentMetric(
  tenantId: string,
  indexId: string,
  metricName: string,
  apiType: string,
  interval: string,
  timespan: string,
  percentile: number,
): Promise<MetricModel> {
  return HttpClient.get<MetricResponse>({
    url: `${getBaseUri()}/${tenantId}/indexes/${indexId}/metric?metricName=${metricName}&apiType=${apiType}&timespan=${timespan}&interval=${interval}&percentile=${percentile}`,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapMetricDtoToModel);
}

export function getDocumentBreakDownMetric(
  tenantId: string,
  indexId: string,
  interval: string,
  timespan: string,
): Promise<DocumentBreakDownModel> {
  return HttpClient.post<ErrorReportResponse>({
    url: `${getBaseUri()}/${tenantId}/indexes/${indexId}/document/breakdown?timespan=${timespan}&interval=${interval}`,
    authInfo: { tenantId: tenantId, indexId: indexId },
    body: {},
  }).then(mapMetricDtoToDocumentBreakDownModel);
}

export function getFeaturesCoverageMetric(
  tenantId: string,
  indexId: string,
  interval: string,
  timespan: string,
): Promise<FeatureCoverageModel> {
  return HttpClient.get<MetricResponse>({
    url: `${getBaseUri()}/${tenantId}/indexes/${indexId}/metric?metricName=Coverage&apiType=Feature&timespan=${timespan}&interval=${interval}`,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapMetricDtoToFeatureCoverageModel);
}

export const getKeyLevelError = (tenantId: string, indexId: string, timespan: string): Promise<ErrorReportModel> => {
  return HttpClient.post<ErrorReportResponse>({
    url: `${getBaseUri()}/${tenantId}/indexes/${indexId}/document/keylevelerror?timespan=${timespan}`,
    authInfo: { tenantId: tenantId, indexId: indexId },
    body: {},
  }).then(mapErrorReportDtoToModel);
};
