import React, { ReactNode } from 'react';
import {
  SelectionMode,
  ConstrainMode,
  CheckboxVisibility,
  classNamesFunction,
  DetailsListLayoutMode,
  DetailsList as FabricDetailsList,
  IColumn,
} from 'office-ui-fabric-react';
import { DetailsSummaryProps, DetailsSummaryStyles, DetailsSummaryStyleProps } from './DetailsSummary.types';

const getClassNames = classNamesFunction<DetailsSummaryStyleProps, DetailsSummaryStyles>();

export const DetailsSummaryBase = (props: DetailsSummaryProps): JSX.Element => {
  const { styles, theme, componentRef, className, item, columns } = props;
  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const onRenderSummaryColumn = (item?: any, index?: number, column?: IColumn): ReactNode => {
    if (!item || !column || (!index && index !== 0)) {
      return null;
    }

    return column.onRender ? column.onRender(item, index, column) : <div>{item[column.fieldName as string]}</div>;
  };

  return (
    <div className={classNames.root}>
      <FabricDetailsList
        styles={{
          contentWrapper: classNames.contentWrapper,
        }}
        componentRef={componentRef}
        isHeaderVisible={false}
        items={[item]}
        columns={columns}
        selectionMode={SelectionMode.none}
        constrainMode={ConstrainMode.unconstrained}
        layoutMode={DetailsListLayoutMode.justified}
        checkboxVisibility={CheckboxVisibility.onHover}
        onRenderItemColumn={(item, index, column) => onRenderSummaryColumn(item, index, column)}
      />
    </div>
  );
};
