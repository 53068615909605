import { ExcelExportStyleProps, ExcelExportStyles } from './ExcelExport.types';

export const styles = (props: ExcelExportStyleProps): ExcelExportStyles => ({
  root: ['mbc-excel-export', props.className],
  exportButton: [
    'mbc-export-button',
    {
      selectors: {
        '.ms-Button-icon': {
          color: props.disabled ? props.theme.palette.neutralTertiary : '#217346',
        },
      },
    },
  ],
});
