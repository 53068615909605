export const Markets = [
  { text: 'es-AR', key: 'es-AR' },
  { text: 'en-AU', key: 'en-AU' },
  { text: 'de-AT', key: 'de-AT' },
  { text: 'nl-BE', key: 'nl-BE' },
  { text: 'fr-BE', key: 'fr-BE' },
  { text: 'pt-BR', key: 'pt-BR' },
  { text: 'en-CA', key: 'en-CA' },
  { text: 'fr-CA', key: 'fr-CA' },
  { text: 'es-CL', key: 'es-CL' },
  { text: 'da-DK', key: 'da-DK' },
  { text: 'fi-FI', key: 'fi-FI' },
  { text: 'fr-FR', key: 'fr-FR' },
  { text: 'de-DE', key: 'de-DE' },
  { text: 'zh-HK', key: 'zh-HK' },
  { text: 'en-IN', key: 'en-IN' },
  { text: 'en-ID', key: 'en-ID' },
  { text: 'it-IT', key: 'it-IT' },
  { text: 'ja-JP', key: 'ja-JP' },
  { text: 'ko-KR', key: 'ko-KR' },
  { text: 'en-MY', key: 'en-MY' },
  { text: 'es-MX', key: 'es-MX' },
  { text: 'nl-NL', key: 'nl-NL' },
  { text: 'en-NZ', key: 'en-NZ' },
  { text: 'no-NO', key: 'no-NO' },
  { text: 'zh-CN', key: 'zh-CN' },
  { text: 'pl-PL', key: 'pl-PL' },
  { text: 'en-PH', key: 'en-PH' },
  { text: 'ru-RU', key: 'ru-RU' },
  { text: 'en-ZA', key: 'en-ZA' },
  { text: 'es-ES', key: 'es-ES' },
  { text: 'sv-SE', key: 'sv-SE' },
  { text: 'fr-CH', key: 'fr-CH' },
  { text: 'de-CH', key: 'de-CH' },
  { text: 'zh-TW', key: 'zh-TW' },
  { text: 'tr-TR', key: 'tr-TR' },
  { text: 'en-GB', key: 'en-GB' },
  { text: 'en-US', key: 'en-US' },
  { text: 'es-US', key: 'es-US' },
];
