import * as React from 'react';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import { DraggableRevealProps } from './DraggableReveal.types';
import { getClassNames } from './DraggableReveal.styles';
import { DraggableSlider, DraggableSliderSide } from './DraggableSlider';
import { useEffect, useState } from 'react';

export function DraggableReveal(props: DraggableRevealProps) {
  const {
    bottomComponent,
    topComponent,
    width,
    peakWidth,
    onDraggableSliderSwiped,
    showTeachingBubble,
    styles,
    theme,
  } = props;

  const classNames = getClassNames(styles, { theme });

  const hint = useAnimation();
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [isSliderSwiped, setIsSliderSwiped] = useState(false);
  const [isSliderTouched, setIsSliderTouched] = useState(false);
  const x = useMotionValue(0);
  const [draggableSliderSide, setDraggableSliderSide] = useState(DraggableSliderSide.Right);

  let leftComponentRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    function updateDraggableSlider() {
      if (!isSliderSwiped && x.get() < -10) {
        setIsSliderSwiped(true);
      }
      if (leftComponentRef.current && width) {
        const newWidth = width + x.get() - peakWidth + 6;
        leftComponentRef.current.style.width = newWidth + 'px';
        if (draggableSliderSide === DraggableSliderSide.Right && newWidth <= width / 2) {
          onDraggableSliderSwiped();
          setDraggableSliderSide(DraggableSliderSide.Left);
        } else if (draggableSliderSide === DraggableSliderSide.Left && newWidth >= width / 2) {
          onDraggableSliderSwiped();
          setDraggableSliderSide(DraggableSliderSide.Right);
        }
        if (!isSliderTouched) {
          setIsSliderTouched(true);
        }
      }
    }

    const unsubscribeX = x.onChange(updateDraggableSlider);

    return () => {
      unsubscribeX();
    };
  }, [
    width,
    peakWidth,
    leftComponentRef,
    x,
    isSliderSwiped,
    draggableSliderSide,
    isSliderTouched,
    onDraggableSliderSwiped,
  ]);

  if (!animationCompleted) {
    hint
      .start({
        left: [width - peakWidth, width - Math.ceil(peakWidth * 1.2), width - peakWidth],

        transition: {
          type: 'spring',
          duration: 0.25,
          loop: 1,
          repeatDelay: 0,
        },
      })
      .then(() => setAnimationCompleted(true));
  }

  function compare() {
    if (!isSliderSwiped) {
      x.set(-width / 2 + peakWidth);
    }
  }

  return (
    <div className={classNames.root}>
      <div className={classNames.leftComponent} style={{ width: width - peakWidth + 'px' }} ref={leftComponentRef}>
        <div className={classNames.leftComponentInner}>{bottomComponent}</div>
      </div>
      <div className={classNames.rightComponent}>
        <div className={classNames.rightComponentInner}>{topComponent}</div>
      </div>
      <motion.div
        animate={hint}
        onDragStart={() => {
          hint.stop();
          setAnimationCompleted(true);
        }}
        drag="x"
        dragPropagation
        dragConstraints={{
          left: -width + 2 * peakWidth,
          right: 0,
          top: 0,
          bottom: 0,
        }}
        className={classNames.topContainer}
        style={{ x }}
      >
        <DraggableSlider onSliderClick={compare} showTeachingBubble={showTeachingBubble && !isSliderTouched} />
      </motion.div>
    </div>
  );
}

DraggableReveal.defaultProps = {
  width: 1416,
  peakWidth: 75,
};
