import { ToolTipFormatterStyleProps, ToolTipFormatterStyles } from './ToolTipFormatter.types';

export const getStyles = (props: ToolTipFormatterStyleProps): ToolTipFormatterStyles => ({
  root: [
    'ms-tool-tip',
    {
      fontSize: ' 13px',
      background: 'white',
      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132)',
      borderRadius: '4px',
      padding: '5px 10px 5px 5px',
      lineHeight: '20px',
    },
    props.className,
  ],
  label: [
    'ms-label',
    {
      paddingLeft: '8px',
    },
  ],
  contentItemContainer: ['ms-content-item', { display: 'flex', alignItems: 'center' }],
  toolTipIcon: ['ms-tool-tip-icon', { fontSize: '25px' }],
});
