import { createContext } from 'react';

export interface TenantPermissionContextType {
  isAuthorized: (minimumPermissions: string | string[]) => boolean;
  getPermissions: () => string[];
}

export interface EnvironmentPermissionContextType {
  isAuthorized: (minimumPermissions: string | string[], environmentId?: string) => boolean;
  getPermissions: (environmentId?: string) => string[];
}

export const EnvironmentPermissionContext = createContext<EnvironmentPermissionContextType>({
  isAuthorized: () => false,
  getPermissions: () => [],
});

export const TenantPermissionContext = createContext<TenantPermissionContextType>({
  isAuthorized: () => false,
  getPermissions: () => [],
});
