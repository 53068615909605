import React, { useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styles from './Docs.module.scss';
import { MbcNavBar } from '../components/MbcNavBar';

enum DocPages {
  ProductSearch = 'product-search',
  PortalDocumentation = 'portal documentation',
  CommerceServices = 'commerce-services',
  CustomVisualSearch = 'custom-visual-search',
  JavaSdk = 'product-search/java-sdk',
  PythonSdkIngestion = 'product-search/python-sdk-ingestion',
  PythonSdkSearch = 'product-search/python-sdk-search',
}

interface DocFrameProps extends RouteComponentProps {
  docSrc: string;
}

export const DocFrame = (props: DocFrameProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iFrameContentWindow: Window | null = iframeRef.current && iframeRef.current.contentWindow;
    iFrameContentWindow &&
      iFrameContentWindow.addEventListener(
        'scroll',
        () => {
          window.location.hash = iFrameContentWindow ? iFrameContentWindow.location.hash : '';
        },
        true,
      );
    return () => {
      iFrameContentWindow &&
        iFrameContentWindow.removeEventListener(
          'scroll',
          () => {
            window.location.hash = iFrameContentWindow ? iFrameContentWindow.location.hash : '';
          },
          true,
        );
    };
  }, [iframeRef]);

  return <iframe ref={iframeRef} src={props.docSrc} width={'100%'} height={'100%'} style={{ border: 'none' }} />;
};

export const Docs = (props: RouteComponentProps<{ docPage: string; docSubPage?: string }>) => {
  const { docPage, docSubPage } = props.match.params;
  const targetDocPage = docSubPage
    ? `${docPage.toLocaleLowerCase()}/${docSubPage.toLocaleLowerCase()}`
    : docPage.toLocaleLowerCase();

  const loadDocPage = (): JSX.Element => {
    switch (targetDocPage) {
      case DocPages.PythonSdkIngestion:
        return DocFrame({
          ...props,
          docSrc: `/documentation/Product-Search/python-sdk-docs/ingestion/${props.location.hash}`,
        });
      case DocPages.PythonSdkSearch:
        return DocFrame({
          ...props,
          docSrc: `/documentation/Product-Search/python-sdk-docs/search/${props.location.hash}`,
        });
      case DocPages.JavaSdk:
        return DocFrame({ ...props, docSrc: `/documentation/Product-Search/java-sdk-doc/${props.location.hash}` });
      case DocPages.ProductSearch:
        return DocFrame({ ...props, docSrc: `/documentation/Product-Search/${props.location.hash}` });
      case DocPages.CommerceServices:
        return DocFrame({ ...props, docSrc: `/documentation/commerce-services/${props.location.hash}` });
      case DocPages.PortalDocumentation:
        return DocFrame({ ...props, docSrc: `/documentation/Portal Documentation/${props.location.hash}` });
      case DocPages.CustomVisualSearch:
        return DocFrame({ ...props, docSrc: `/documentation/custom-visual-search/${props.location.hash}` });
      default:
        return DocFrame({ ...props, docSrc: `/documentation/Portal Documentation/${props.location.hash}` });
    }
  };

  return (
    <div className={styles.wrapper}>
      <MbcNavBar />
      <div className={styles.main}>{loadDocPage()}</div>
    </div>
  );
};
