import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { classNamesFunction } from 'office-ui-fabric-react';
import { CodeEditorProps, CodeEditorStyleProps, CodeEditorStyles } from './CodeEditor.types';

const getClassNames = classNamesFunction<CodeEditorStyleProps, CodeEditorStyles>();

export const CodeEditorBase = (props: CodeEditorProps) => {
  const { styles, theme, className, componentRef, viewTheme, readOnly, label, value, onChange } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <label ref={componentRef} className={classNames.root}>
      {label && <div className={classNames.label}>{label}</div>}
      <AceEditor
        className={classNames.aceEditor}
        wrapEnabled
        mode="json"
        fontSize={14}
        width={'100%'}
        height={'100%'}
        name="CodeEditor"
        onChange={onChange}
        readOnly={!!readOnly}
        highlightActiveLine={false}
        theme={viewTheme || 'tomorrow'}
        value={value}
        editorProps={{ $blockScrolling: true }}
      />
    </label>
  );
};
