import { Action } from 'redux';

export const SHOW_OOBE_DEMO = 'SHOW_OOBE_DEMO';
export const HIDE_OOBE_DEMO = 'HIDE_OOBE_DEMO';

export interface OobeDemoState {
  showDemo: boolean;
}

export type OobeDemoStateActionTypes = Action<typeof SHOW_OOBE_DEMO> | Action<typeof HIDE_OOBE_DEMO>;
