import React from 'react';
import { AppState } from '../../../../../store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { MetricCard } from '../common/MetricCard';
import { TimeFilterOptions } from '../Insights.utils';
import { setIndexAvgDPS, setIndexProductCount } from '../../../../../store/actions/insightsAction';
import { getChartTitleTooltipMessage } from '../Insights.utils';
import { MetricCardsProps, MetricCardsStyleProps, MetricCardsStyles } from './MetricCards.types';
import { classNamesFunction } from 'office-ui-fabric-react';

const getClassNames = classNamesFunction<MetricCardsStyleProps, MetricCardsStyles>();

export const MetricCardsBase = (props: MetricCardsProps) => {
  const { t, styles, className, theme, productCountInterval, productCountSpan } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const indexProductCount = useSelector((state: AppState) => state.insights.indexProductCount);
  const isIndexProductCountLoading = useSelector((state: AppState) => state.insights.isIndexProductCountLoading);
  const indexAvgDPS = useSelector((state: AppState) => state.insights.indexAvgDPS);
  const isIndexAvgDPSLoading = useSelector((state: AppState) => state.insights.isIndexAvgDPSLoading);

  const dispatch = useDispatch();

  return (
    <div className={classNames.root}>
      <MetricCard
        isLoading={isIndexProductCountLoading}
        title={t('insights.index-product-count')}
        valueDescription={t('insights.index-product-count-description')}
        value={
          indexProductCount.series.length
            ? indexProductCount.series[indexProductCount.series.length - 1].yValues[0]
            : NaN
        }
        refreshOptions={{
          text: getChartTitleTooltipMessage(indexProductCount.cacheDetails, false),
          defaultTimeSpan: TimeFilterOptions.ONE_WEEK,
          onRefreshClick: () => dispatch(setIndexProductCount(productCountSpan, productCountInterval)),
        }}
      />

      <MetricCard
        isLoading={isIndexAvgDPSLoading}
        title={t('insights.requests-per-second')}
        value={indexAvgDPS.value}
        valueDescription={t('insights.requests-per-second-description')}
        className={classNames.productUpdatesCard}
        refreshOptions={{
          defaultTimeSpan: TimeFilterOptions.ONE_HOUR,
          text: getChartTitleTooltipMessage(indexAvgDPS.cacheDetails, false),
          onRefreshClick: (timeSpan: string) => dispatch(setIndexAvgDPS(timeSpan)), // Needs updating
        }}
        footer={t('insights.requests-per-second-card-footer')}
      />
    </div>
  );
};
