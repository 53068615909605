import { classNamesFunction, List, Stack, IconButton } from 'office-ui-fabric-react';
import React from 'react';
import { UserPersona, PersonaType } from '../../../components/common/UserPersona';
import { styles } from './MembersList.styles';
import { MembersListProps, MembersListStyles } from './MembersList.types';
import { useTranslation } from 'react-i18next';
import { NoItemsToDisplay } from '../../../components/common/NoItemsToDisplay';

const getClassNames = classNamesFunction<{}, MembersListStyles>();

export const MembersListBase: React.FunctionComponent<MembersListProps> = (props: MembersListProps) => {
  const { viewOnlyMode, membersEmails, onRemoveMember } = props;
  const { t } = useTranslation();

  const classNames = getClassNames(styles);

  if (membersEmails.length == 0)
    return (
      <NoItemsToDisplay
        text={t('role-editor.members-section.members-list-no-items-text')}
        subText={t('role-editor.members-section.members-list-no-items-subtext')}
      />
    );

  return (
    <div data-test="members-list-personas-container" className={classNames.root} data-is-scrollable>
      <List
        items={membersEmails}
        onRenderCell={(memberEmail, ind) => {
          if (memberEmail)
            return (
              <Stack horizontal horizontalAlign="space-between">
                <UserPersona
                  key={`${ind}`}
                  useNameAsPrimaryText={true}
                  user={{ email: memberEmail }}
                  personaType={PersonaType.WithDetails}
                  ariaLabel={'Role member'}
                />
                {!viewOnlyMode && (
                  <Stack verticalAlign="center">
                    <IconButton
                      disabled={viewOnlyMode}
                      iconProps={{ iconName: 'CalculatorSubtract' }}
                      onClick={() => onRemoveMember(memberEmail)}
                    />
                  </Stack>
                )}
              </Stack>
            );
          else return null;
        }}
      />
    </div>
  );
};
