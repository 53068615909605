import React, { useState, useCallback, useEffect } from 'react';
import { classNamesFunction, TextField } from 'office-ui-fabric-react';
import {
  NumericValueEditorProps,
  NumericValueEditorStyleProps,
  NumericValueEditorStyles,
} from './NumericValueEditor.types';
import { isNumeric } from '../../../../../utils/validators/regexp';
const getClassNames = classNamesFunction<NumericValueEditorStyleProps, NumericValueEditorStyles>();

export const NumericValueEditorBase = (props: NumericValueEditorProps) => {
  const { styles, theme, className, value, disabled, onValueChange, onSubmit, onDismiss } = props;

  const [currentValue, setCurrentValue] = useState<string>(value ? value.toString() : '');
  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  useEffect(() => {
    setCurrentValue(value ? value.toString() : '');
    return () => {
      setCurrentValue('');
    };
  }, [value]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const updateValue = useCallback(
    (val: string) => {
      setCurrentValue(val);
      if (isNumeric(val)) {
        setErrorMessage(undefined);
        onValueChange(parseInt(val));
      } else {
        setErrorMessage('Please enter a valid number');
      }
    },
    [onValueChange],
  );

  const onValueEditorKeyUp = useCallback(
    (key: string) => {
      key === 'Escape' && onDismiss && onDismiss();
      key === 'Enter' && !errorMessage && onSubmit && onSubmit();
    },
    [errorMessage, onDismiss, onSubmit],
  );

  return (
    <div className={classNames.root}>
      <TextField
        value={currentValue}
        disabled={disabled}
        onChange={(event, newValue) => updateValue(newValue || '')}
        errorMessage={errorMessage}
        onKeyUp={ev => onValueEditorKeyUp(ev.key)}
      />
    </div>
  );
};
