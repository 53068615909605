import React, { useState, useEffect } from 'react';
import { CustomPanel } from '../../../../components/common/CustomPanel';
import { AssignEnvironmentsProps } from './AssignEnvironments.types';
import { Environment } from '../../../../store/types/environmentList';
import { EnvironmentList } from '../../../../components/EnvironmentList';
import { PanelType } from 'office-ui-fabric-react';
import _ from 'lodash';

export const AssignEnvironmentsBase = (props: AssignEnvironmentsProps): JSX.Element => {
  const { isOpen, onDismiss, onSaveClick, onCancelClick, t, items } = props;

  const [selectedEnvironments, setSelectedEnvironments] = useState<Environment[]>(items);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    setSelectedEnvironments(items);
  }, [items]);

  return (
    <CustomPanel
      isOpen={isOpen}
      headerText={t('roles-list.role-editor-panel.header')}
      onDismiss={onDismiss}
      footer={{
        onSaveClick: () => onSaveClick(selectedEnvironments),
        onCancelClick: () => onCancelClick(),
        isSaveDisabled: saveDisabled,
      }}
      panelType={PanelType.medium}
    >
      <EnvironmentList
        isPanel={true}
        onSelectionChanged={selection => {
          setSelectedEnvironments(selection);
          setSaveDisabled(_.isEqual(items, selection));
        }}
        selectedItems={items.map(item => ({ ...item, key: item.id, canSelect: true }))}
      />
    </CustomPanel>
  );
};
