import { ProductItemCardStyles, ProductItemCardStyleProps } from './ProductItemCard.types';
import { classNamesFunction } from 'office-ui-fabric-react';

export const listViewStyles = (props: ProductItemCardStyleProps): ProductItemCardStyles => {
  const enabled = props.enabled;
  const hoverable = props.interactable;
  const showActionsOnHover = hoverable && props.hasActions;
  return {
    root: {
      minHeight: 200,
      borderRadius: 4,
      marginBottom: 8,
      padding: '12px 16px',
      background: props.theme.palette.white,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: props.theme.palette.neutralLighter,
      selectors: {
        '.ms-Button--icon': enabled &&
          showActionsOnHover && {
            display: 'none',
          },
        '.ms-Button--commandBar': enabled &&
          showActionsOnHover && {
            display: 'none',
          },
        ':hover': {
          selectors: {
            '.ms-Button--icon': {
              display: 'inline-block',
            },
            '.ms-Button--commandBar': {
              display: 'inline-block',
            },
          },
          boxShadow:
            hoverable && enabled ? '0px 6.4px 14.4px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px rgba(0, 0, 0, 0.1)' : 'none',
        },
      },
    },
    superscriptPriceText: {
      fontSize: 8,
      fontWeight: 'bold',
    },
    priceText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 18,
      fontWeight: 600,
      color: '#4E4E4E',
      minWidth: 60,
    },
    name: {
      fontSize: 14,
      lineHeight: 21,
      fontWeight: 500,
      color: props.theme.palette.themePrimary,
    },
    description: {
      fontSize: 13,
      lineHeight: 21,
      height: 'auto',
      color: props.theme.palette.neutralPrimary,
    },
    integerText: {
      margin: '0 3px 0 0',
    },
    id: {
      fontSize: 14,
      maxWidth: 500,
      lineHeight: 21,
      fontWeight: 400,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: props.theme.palette.neutralSecondaryAlt,
    },
    url: {
      lineHeight: 21,
      color: props.theme.palette.green,
    },
    descriptionContainer: {},
    detailsContainer: {},
    subComponentStyles: {
      imageStyles: {
        root: {
          width: props.hasActions ? 150 : 200,
          height: props.hasActions ? 150 : 200,
        },
      },
    },
  };
};

export const gridViewStyles = (props: ProductItemCardStyleProps): ProductItemCardStyles => {
  const hoverable = props.interactable && props.enabled;
  return {
    root: {
      margin: '0 12px 12px 12px',
      padding: '12px 24px',
      border: '1px solid #D2D0CE',
      boxSizing: 'border-box',
      borderRadius: 4,
      width: '258px',
      height: '370px',
      display: 'grid',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '232px auto',
      gridRowGap: '16',
      background: '#fff',
      selectors: {
        ':hover': {
          boxShadow: hoverable ? '0px 6.4px 14.4px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px rgba(0, 0, 0, 0.1)' : 'none',
        },
      },
    },
    detailsContainer: {
      marginTop: 8,
      flex: '1 0 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
    },
    superscriptPriceText: { fontSize: 8, fontWeight: 'bold' },
    priceText: {
      height: 20,
      margin: '0 0 8px 0',
      alignItems: 'baseline',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 18,
      lineHeight: 16,
      fontWeight: 600,
      color: '#4E4E4E',
      minWidth: 60,
    },
    id: {},
    url: {
      maxWidth: 200,
      lineHeight: 21,
      fontSize: 12,
      margin: '0 0 4px 0',
      color: props.theme.palette.green,
    },
    descriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: 208,
    },
    name: { fontSize: 14, lineHeight: 16, margin: '0 0 4px 0' },
    description: { fontSize: 13, lineHeight: 15, color: '#666666', margin: '0 0 8px 0', height: 29 },
    integerText: { margin: '0 3px 0 0' },
    subComponentStyles: {
      imageStyles: {
        root: {
          flex: '0 1 auto',
          width: 208,
          height: 232,
          margin: '0 auto',
        },
      },
    },
  };
};

export const getClassNames = classNamesFunction<ProductItemCardStyleProps, ProductItemCardStyles>();
