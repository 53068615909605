import { EnvironmentStatusIcon } from './EnvironmentStatus.types';
import { getI18n } from 'react-i18next';

export const environmentStatusIcon = (): EnvironmentStatusIcon => {
  const i18n = getI18n();
  return {
    Unknown: {
      displayName: i18n.t('environment-status.created'),
      iconName: 'StatusCircleInner',
      color: 'orange',
      tooltipContent: i18n.t('environment-status.created-tool-tip-content'),
    },
    NotStarted: {
      displayName: i18n.t('environment-status.pending'),
      iconName: 'CircleStopSolid',
      color: 'black',
      tooltipContent: i18n.t('environment-status.pending-tool-tip-content'),
    },
    Provisioning: {
      displayName: i18n.t('environment-status.provisioning'),
      iconName: 'CircleStopSolid',
      color: 'black',
      tooltipContent: i18n.t('environment-status.provisioning-tool-tip-content'),
    },
    Deprovisioning: {
      displayName: i18n.t('environment-status.deprovisioning'),
      iconName: 'CircleStopSolid',
      color: 'black',
      tooltipContent: i18n.t('environment-status.deprovisioning-tool-tip-content'),
    },
    Succeeded: {
      displayName: i18n.t('environment-status.online'),
      iconName: 'StatusCircleInner',
      color: 'green',
      tooltipContent: i18n.t('environment-status.online-tool-tip-content'),
    },
    Failed: {
      displayName: i18n.t('environment-status.error'),
      iconName: 'StatusCircleInner',
      color: 'red',
      tooltipContent: i18n.t('environment-status.error-tool-tip-content'),
    },
    Deleting: {
      displayName: i18n.t('environment-status.deleting'),
      iconName: 'StatusCircleInner',
      color: 'grey',
      tooltipContent: i18n.t('environment-status.deleting-tool-tip-content'),
    },
  };
};
