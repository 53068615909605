import React from 'react';
import { styled } from 'office-ui-fabric-react';

import { SearchItemCardBase } from './SearchItemCard.base';
import { styles } from './SearchItemCard.styles';
import { ProductSearchResultItem } from './SearchItemCard.types';

export const SearchItemCard = styled(SearchItemCardBase, styles);

export const SearchItemCardExample: React.FunctionComponent<{}> = () => {
  const productSearchResultItem: ProductSearchResultItem = {
    name: 'Gucci Lexi Slide High Heels (Women)',
    description: 'A good looking garment',
    price: 179.99,
    imgUrl:
      'https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE33HbR?ver=e326&w=498&h=408&q=90&m=6&b=%23FFF0F0F0&f=jpg&o=f&p=0&aim=true',
    currency: '$',
  };

  return <SearchItemCard item={productSearchResultItem}></SearchItemCard>;
};
