import React, { useMemo, useCallback } from 'react';
import { ProductSelectorProps, ProductSelectorStyleProps, ProductSelectorStyles } from './ProductSelector.types';
import { classNamesFunction, Stack, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { Product } from '../../../store/types/tenantsList.d';
import { useMbcProducts } from '../../../components/MbcSideNav/hooks/useMbcProduct';
import { useEnvironmentRouter } from '../../../store/hooks/use-router/useEnvironmentRouter';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { useTranslation } from 'react-i18next';

const getClassNames = classNamesFunction<ProductSelectorStyleProps, ProductSelectorStyles>();

export const ProductSelector = (props: ProductSelectorProps) => {
  const { styles, theme, className } = props;
  const { t } = useTranslation();
  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const MbcEnvironmentRouteMap = useEnvironmentRouter();

  const [selectedMbcProduct, setSelectedMbcProduct] = useMbcProducts();

  const MbcProductList = useMemo(
    () => [
      {
        key: Product.TextSearch,
        text: t('side-nav.products.product-search'),
      },
    ],
    [t],
  );

  const onSelectedMbcProductChange = useCallback(
    (ev: any, item?: IDropdownOption) => {
      if (!item) {
        return;
      }
      const product = item.key.toString() as Product;
      setSelectedMbcProduct(product);
      switch (product) {
        case Product.TextSearch:
          MbcEnvironmentRouteMap(MbcRouteKey.QueryTester);
          break;
      }
    },
    [MbcEnvironmentRouteMap, setSelectedMbcProduct],
  );

  return (
    <Stack className={classNames.root}>
      <Dropdown
        options={MbcProductList}
        selectedKey={selectedMbcProduct}
        onChange={onSelectedMbcProductChange}
        ariaLabel={t('side-nav.dropdown.label')}
        styles={{ title: { border: 0 }, dropdown: { fontWeight: 'inherit' } }}
      />
    </Stack>
  );
};
