import { Action } from 'redux';

export const RESET_TENANT_USERS = 'RESET_TENANT_USERS';
export const SET_TENANT_USER_IMAGE = 'SET_TENANT_USER_IMAGE';
export const SET_TENANT_USER_META_DATA = 'SET_TENANT_USER_META_DATA';

export interface UserEmailMapper {
  [key: string]: User;
}

export enum ImageStatus {
  Cached = 'Cached',
  NotFound = 'NotFound',
  Loading = 'Loading',
  NotInitialized = 'NotInitialized',
}

export enum UserMetaDataStatus {
  Cached = 'Cached',
  NotFound = 'NotFound',
  Loading = 'Loading',
  NotInitialized = 'NotInitialized',
}

export interface User {
  email: string;
  displayName: string;
  jobTitle: string;
  initials: string;
  imageUrl?: string;
  imageStatus: ImageStatus;
  userStatus: UserMetaDataStatus;
}

export interface UserListState {
  userEmailMapper: UserEmailMapper;
}

interface SetTenantUserImage extends Action<typeof SET_TENANT_USER_IMAGE> {
  email: string;
  imageUrl: string;
  imageStatus: ImageStatus;
}

interface SetTenantUserMetaData extends Action<typeof SET_TENANT_USER_META_DATA> {
  email: string;
  displayName: string;
  jobTitle: string;
  initials: string;
  userStatus: UserMetaDataStatus;
}

export type UserListStateActionTypes = SetTenantUserImage | SetTenantUserMetaData | Action<typeof RESET_TENANT_USERS>;
