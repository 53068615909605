import { flatten } from 'lodash';
import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  IconButton,
  classNamesFunction,
  TooltipHost,
  TooltipOverflowMode,
  IColumn,
  Icon,
} from 'office-ui-fabric-react';
import {
  EvaluateSearchModelProps,
  EvaluationItem,
  EvaluateSearchModelStyleProps,
  EvaluateSearchModelStyles,
} from './EvaluateSearchModel.types';
import { useSelector } from '../../../../../store/hooks';
import { PageTemplate } from '../../../../../components/common/PageTemplate';
import { SearchModel } from '../../../../../store/types/customML/searchModel.d';
import { DetailsListCard } from '../../../../../components/common/DetailsListCard';
import { EvaluationJob } from '../../../../../store/types/customML/evaluationJob.d';
import { ColumnPriority, SortDataType, ColumnWithPriority } from '../../../../../components/common/DetailsList';

export const getClassNames = classNamesFunction<EvaluateSearchModelStyleProps, EvaluateSearchModelStyles>();

const MINIMUM_PRECISION = 1e-3;
const P_VALUE_COMPARISON_THRESHOLD = 0.05;
const MINIMUM_PRECISION_STRING = '< 1e-3';
const MINIMUM_POSITIVE_PRECISION = '< 1e-3';
const MINIMUM_NEGATIVE_PRECISION = '< -1e-3';

export function EvaluateSearchModelBase(props: EvaluateSearchModelProps) {
  const { t, styles, theme, componentRef } = props;
  const classNames = getClassNames(styles, { theme: theme! });

  const evaluationJob = useSelector<EvaluationJob>(state => state.evaluationJobs.activeEvaluationJob);

  const getDetailsListItems = (): EvaluationItem[] =>
    flatten(
      evaluationJob.iterations.map(iteration =>
        iteration.comparisonModels.map(model => ({
          key: iteration.key,
          evaluationPair: model.evaluationPair,
          baselineModelNdcg: iteration.baselineModelNdcg,
          comparisonModelNdcg: model.Ndcg,
          NdcgDelta: model.NdcgDelta,
          Pvalue: model.pValue,
        })),
      ),
    );

  const onRenderNumericDeltaMetric = useCallback(
    (item?: EvaluationItem): ReactNode => {
      if (!item) {
        return null;
      }
      // Delta Ndcg sometimes is undefined
      // because -1 * 0 on console results in -0 (signed zero), give it a try.
      const normalizedValue = !!item.NdcgDelta ? -1 * item.NdcgDelta : 0;
      const minimumValue = normalizedValue < 0 ? MINIMUM_NEGATIVE_PRECISION : MINIMUM_POSITIVE_PRECISION;
      const onRenderValue =
        normalizedValue === 0
          ? normalizedValue
          : Math.abs(normalizedValue) < MINIMUM_PRECISION
          ? minimumValue
          : Number(normalizedValue).toPrecision(4);
      const indicatorColor =
        // Win
        normalizedValue > 0 && item.Pvalue < P_VALUE_COMPARISON_THRESHOLD
          ? '#34C759'
          : // Lose
          normalizedValue < 0 && item.Pvalue < P_VALUE_COMPARISON_THRESHOLD
          ? '#F04A47'
          : // Neutral
            '#8A8886';

      return (
        <div key="status" className={classNames.status}>
          <div style={{ fontSize: 12, justifySelf: 'end' }}>{onRenderValue}</div>
          <Icon iconName={'CircleShapeSolid'} style={{ color: indicatorColor, fontSize: 8 }}></Icon>
        </div>
      );
    },
    [classNames.status],
  );

  const onRenderItemColumnName = useCallback(
    (item?: EvaluationItem, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.key}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.key}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderItemColumnEvaluationPair = useCallback(
    (item?: EvaluationItem, index?: number, column?: IColumn): ReactNode =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.evaluationPair}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.evaluationPair}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderItemColumnMetric = useCallback((item?: SearchModel, index?: number, column?: IColumn): ReactNode => {
    if (item && column) {
      const value = item[column.fieldName as keyof SearchModel] as string;
      return (
        <span style={{ textAlign: 'end' }}>
          {Number(value) < MINIMUM_PRECISION ? MINIMUM_PRECISION_STRING : Number(value).toPrecision(4)}
        </span>
      );
    }
  }, []);

  const columns: ColumnWithPriority[] = useMemo(
    () => [
      {
        key: 'name',
        minWidth: 150,
        maxWidth: 200,
        fieldName: 'key',
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        onRender: onRenderItemColumnName,
        name: t('custom-ml.eval-job.th-name'),
      },
      {
        minWidth: 300,
        maxWidth: 350,
        isMultiline: true,
        key: 'evaluation-pair',
        fieldName: 'evaluationPair',
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Tertiary,
        name: t('custom-ml.eval-job.th-pair'),
        onRender: onRenderItemColumnEvaluationPair,
      },
      {
        minWidth: 150,
        maxWidth: 200,
        key: 'baseline-model-Ndcg',
        onRender: onRenderItemColumnMetric,
        fieldName: 'baselineModelNdcg',
        priority: ColumnPriority.Primary,
        name: t('custom-ml.eval-job.th-baseline-model'),
        headerClassName: classNames.columnEndAlignHeader,
      },
      {
        minWidth: 150,
        maxWidth: 200,
        key: 'comparison-model-Ndcg',
        fieldName: 'comparisonModelNdcg',
        priority: ColumnPriority.Primary,
        onRender: onRenderItemColumnMetric,
        name: t('custom-ml.eval-job.th-comparison-model'),
        headerClassName: classNames.columnEndAlignHeader,
      },
      {
        minWidth: 150,
        maxWidth: 150,
        key: 'Ndcg-delta',
        fieldName: 'NdcgDelta',
        priority: ColumnPriority.Primary,
        onRender: onRenderNumericDeltaMetric,
        name: t('custom-ml.eval-job.th-Ndcg-delta'),
        headerClassName: classNames.columnEndAlignHeader,
      },
      {
        minWidth: 150,
        maxWidth: 200,
        key: 'p-value',
        fieldName: 'Pvalue',
        onRender: onRenderItemColumnMetric,
        priority: ColumnPriority.Secondary,
        name: t('custom-ml.eval-job.th-p-value'),
        headerClassName: classNames.columnEndAlignHeader,
      },
    ],
    [
      classNames.columnEndAlignHeader,
      onRenderItemColumnEvaluationPair,
      onRenderItemColumnMetric,
      onRenderItemColumnName,
      onRenderNumericDeltaMetric,
      t,
    ],
  );

  return (
    <PageTemplate
      breadcrumbVisible
      pageHeaderProps={{
        title: evaluationJob.name,
      }}
      onRenderContent={() => (
        <DetailsListCard
          componentRef={componentRef}
          styles={classNames.subComponentStyles.list}
          searchActionProps={{
            searchBy: 'evaluationPair',
            ariaLabel: t('custom-ml.eval-job.search-aria-label'),
            placeholder: t('custom-ml.eval-job.search-placeholder'),
          }}
          header={{
            actions: {
              filter: {},
              actionsList: [<IconButton key="sort" disabled iconProps={{ iconName: 'SortLines' }}></IconButton>],
            },
          }}
          listProps={{
            isHeaderVisible: true,
            items: getDetailsListItems(),
            isLoading: evaluationJob.isLoading,
            optionsItemProps: () => [],
            responsiveColumns: {
              columns: columns,
              secondaryBreakPoint: 800,
              teritaryBreakPoint: 1156,
            },
          }}
        />
      )}
    />
  );
}
