import { getI18n } from 'react-i18next';
import { TrainingJob, ADD_TRAINING_JOB } from '../../../../types/customML/trainingJob.d';
import { ActionSuccessHandler, ReduxCrudAction, ActionFailureHandler } from '../../CrudAction.types';
import { createTrainingJob } from '../../../../../restful-apis/customML.api';
import {
  UPDATE_SEARCH_MODEL_TRAINING_JOB,
  SUBMIT_SEARCH_MODEL_TRAINING_JOB,
  CANCEL_SEARCH_MODEL_TRAINING_JOB_SUBMISSION,
} from '../../../../types/customML/searchModel.d';

export const createTrainingJobAction = (
  tenantId: string,
  searchModelKey: string,
  trainingJob: TrainingJob,
  onSuccess?: ActionSuccessHandler<TrainingJob>,
  onFailure?: ActionFailureHandler<TrainingJob>,
): ReduxCrudAction<TrainingJob> => {
  const i18n = getI18n();
  return {
    request: createTrainingJob(tenantId, searchModelKey, trainingJob),
    preActionMapper: {
      type: SUBMIT_SEARCH_MODEL_TRAINING_JOB,
      key: searchModelKey,
    },
    postActionMapper: createdJob => [
      {
        type: ADD_TRAINING_JOB,
        trainingJob: createdJob,
      },
      {
        type: UPDATE_SEARCH_MODEL_TRAINING_JOB,
        key: searchModelKey,
        trainingJobKey: createdJob.key,
      },
    ],
    notification: {
      failure: () => ({
        title: i18n.t('custom-ml.train-model.req-failure', { name: trainingJob.name }),
      }),
      inProgress: {
        title: i18n.t('custom-ml.train-model.req-inprogress', { name: trainingJob.name }),
      },
      success: () => ({
        title: i18n.t('custom-ml.train-model.req-success', { name: trainingJob.name }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: (response, dispatch) => {
      dispatch({
        type: CANCEL_SEARCH_MODEL_TRAINING_JOB_SUBMISSION,
        key: searchModelKey,
      });
      onFailure && onFailure(response, trainingJob);
    },
  };
};
