import { IIconProps } from 'office-ui-fabric-react';
import isEmpty from '../../../utils/validators/required';
import { isNumeric } from '../../../utils/validators/regexp';
import notExceedingLimit from '../../../utils/validators/lengthLimit';
import { ModelJobStatus } from '../../../store/types/customML/searchModel.d';
import { getI18n } from 'react-i18next';

const MAX_NUMERIC_VALUE = 1e6;
const MAX_NAME_STRING_SIZE = 64;
const MAX_LABEL_GAIN_COUNT = 1e3;
const MAX_LEARNING_RATE_VALUE = 1;
const MAX_DESCRIPTION_STRING_SIZE = 256;
const SELECTED_FEATURES_FORMAT = new RegExp('^[0-9a-zA-Z-_]+$');
const ADDITIONAL_PARAMS_FORMAT = new RegExp('^[a-zA-Z0-9-_]+=[a-zA-Z0-9_]+$');

export const isWithinConstraintsLabelGainValue = (value: string) => {
  return value.split(',').length <= MAX_LABEL_GAIN_COUNT;
};

export const isRequiredLabelGainValue = (value: string) => {
  if (isEmpty(value)) {
    return false;
  }
  const parts = value.split(',');
  return parts.length > 0 && parts.some(part => isNumeric(part));
};

export const isWithinConstraintsLearningRateValue = (value: string) => {
  return parseFloat(value) <= MAX_LEARNING_RATE_VALUE && parseFloat(value) >= 0;
};

export const isWithinConstraintsNumericValue = (value: string) => {
  return parseInt(value, 10) <= MAX_NUMERIC_VALUE && parseInt(value, 10) >= 0;
};

export const isRequiredNumericValue = (value: string) => {
  return !isEmpty(value) && isNumeric(value);
};

export const isRequiredFeatureFormatValue = (value: string) => {
  return !isEmpty(value) && value.split('\n').length > 0;
};

export const isInvalidFeatureFormatValue = (value: string) => {
  return !isRequiredFeatureFormatValue(value) || value.split('\n').some(line => !SELECTED_FEATURES_FORMAT.test(line));
};

export const isOptionalParamsFormatValue = (value: string) => {
  const normalized = value.trim();
  return isEmpty(normalized) || normalized.split(' ').every(line => ADDITIONAL_PARAMS_FORMAT.test(line));
};

export const onGetLearningRateErrorMsg = (value: string) => {
  return isEmpty(value)
    ? getI18n().t('custom-ml.required-error-message', { label: 'learning_rate' })
    : !isWithinConstraintsLearningRateValue(value)
    ? getI18n().t('custom-ml.learning-rate-constraint-error-message')
    : '';
};

export const isInvalidLearningRate = (value: string) => !!onGetLearningRateErrorMsg(value);

export const onGetLabelGainErrorMsg = (value: string) => {
  return !isRequiredLabelGainValue(value)
    ? getI18n().t('custom-ml.required-error-message', { label: 'label_gain' })
    : !isWithinConstraintsLabelGainValue(value)
    ? getI18n().t('custom-ml.constraint-error-message', { label: 'label_gain' })
    : '';
};

export const isInvalidLabelGain = (value: string) => !!onGetLabelGainErrorMsg(value);

// export const onGetSelectedFeaturesErrorMsg = (value: string) => {
//   return !isRequiredFeatureFormatValue(value)
//     ? getI18n().t('custom-ml.required-error-message', { label: 'selected_features' })
//     : isInvalidFeatureFormatValue(value)
//     ? getI18n().t('custom-ml.invalid-selected-feature-error-message')
//     : '';
// };

// export const isInvalidSelectedFeatures = (value: string) => !!onGetSelectedFeaturesErrorMsg(value);

export const onGetAdditionalParamsErrorMsg = (value: string) => {
  return !isOptionalParamsFormatValue(value) ? getI18n().t('custom-ml.invalid-add-params-error-message') : '';
};

export const isInvalidAdditionalParams = (value: string) => !!onGetAdditionalParamsErrorMsg(value);

export const onGetNumericParameterErrorMsg = (value: string, label: string) => {
  return !isRequiredNumericValue(value)
    ? getI18n().t('custom-ml.required-error-message', { label: label })
    : !isWithinConstraintsNumericValue(value)
    ? getI18n().t('custom-ml.constraint-error-message', { label: label })
    : '';
};

export const isInvalidNumericParameter = (value: string) => !!onGetNumericParameterErrorMsg(value, '');

export const onGetEntityNameErrorMsg = (value: string, maxSize?: number) => {
  return isEmpty(value)
    ? getI18n().t('custom-ml.name-required-error-message')
    : !notExceedingLimit(value, maxSize || MAX_NAME_STRING_SIZE)
    ? getI18n().t('custom-ml.name-constraint-error-message')
    : '';
};

export const isInvalidEntityName = (value: string, maxSize?: number) => !!onGetEntityNameErrorMsg(value, maxSize);

export const onGetEntityDescriptionErrorMsg = (value: string, maxSize?: number) => {
  return isEmpty(value)
    ? getI18n().t('custom-ml.desc-required-error-message')
    : !notExceedingLimit(value, maxSize || MAX_DESCRIPTION_STRING_SIZE)
    ? getI18n().t('custom-ml.desc-constraint-error-message')
    : '';
};

export const isInvalidEntityDescription = (value: string, maxSize?: number) =>
  !!onGetEntityDescriptionErrorMsg(value, maxSize);

export const getStatusIconProps = (status: ModelJobStatus): IIconProps => {
  switch (status) {
    case ModelJobStatus.Unknown:
      return { iconName: 'UnknownSolid', style: { color: '#8A8886', fontSize: 16 } };
    case ModelJobStatus.Canceled:
    case ModelJobStatus.Canceling:
      return { iconName: 'CircleStopSolid', style: { color: '#8A8886', fontSize: 16 } };
    case ModelJobStatus.Error:
      return { iconName: 'StatusErrorFull', style: { color: '#F04A47', fontSize: 16 } };
    case ModelJobStatus.Running:
    case ModelJobStatus.Pending:
    case ModelJobStatus.NotStarted:
      return { iconName: 'AwayStatus', style: { color: '#ffaa44', fontSize: 16 } };
    case ModelJobStatus.Finished:
      return { iconName: 'SkypeCircleCheck', style: { color: '#34C759', fontSize: 16 } };
  }
};
