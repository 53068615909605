import { loadTheme } from 'office-ui-fabric-react/lib/Styling';

export const loadMbcTheme = () =>
  loadTheme({
    fonts: {
      small: {
        fontSize: '12px',
        fontWeight: '400',
      },
      medium: {
        fontSize: '14px',
        fontWeight: '400',
      },
      large: {
        fontSize: '14px',
        fontWeight: '500',
      },
      xLarge: {
        fontSize: '28px',
        fontWeight: '600',
      },
    },
    palette: {
      themePrimary: '#355eff',
      themeLighterAlt: '#f7f9ff',
      themeLighter: '#dfe5ff',
      themeLight: '#c3cfff',
      themeTertiary: '#869eff',
      themeSecondary: '#4e71ff',
      themeDarkAlt: '#3054e6',
      themeDark: '#2947c2',
      themeDarker: '#1e358f',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
      greenLight: '#34c759',
    },
  });
