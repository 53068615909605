import { BusinessRuleStyles, BusinessRuleStyleProps } from './BusinessRule.types';

export const styles = (props: BusinessRuleStyleProps): BusinessRuleStyles => {
  const { className } = props;
  return {
    root: ['mbc-BusinessRule', {}, className],
    tutorialSubheader: [
      'mbc-tutorials-dubheader',
      {
        fontWeight: 600,
      },
    ],
  };
};
