import HttpClient from '../http-client/httpClient';
import {
  CustomMlSearchConfigListResponse,
  CustomMlSearchConfigUpdateResponse,
  CustomMlSearchConfigDeleteResponse,
} from '../dto/customizations/customMlSearchConfig';
import { DevOpsCommitMetadata } from '../../store/types/devOps';
import {
  CustomMlSearchConfig,
  DefaultCustomMlSearchConfigId,
} from '../../store/types/customizations/customMlSearchConfig.d';
import {
  mapCustomMlSearchConfigDtoToModel,
  mapCustomMlSearchConfigModelToDto,
} from '../mappers/customMlSearchConfigMapper';

const getCustomMlSetBaseUri = (tenantId: string, indexId: string): string => {
  return `https://commerce.bing.com/api/customization/v1/customMl/${tenantId}/indexes/${indexId}`;
};

function getDevOpsCommitHeaders(devOpsCommitMetadata: DevOpsCommitMetadata): Headers {
  return new Headers({
    'Content-Type': 'application/json',
    'x-ms-author': devOpsCommitMetadata.author,
    'x-ms-author-message': devOpsCommitMetadata.message,
  });
}

export function getCustomMlSearchConfig(
  tenantId: string,
  indexId: string,
  searchInstanceId: string,
): Promise<CustomMlSearchConfig> {
  let url = `${getCustomMlSetBaseUri(tenantId, indexId)}?searchinstanceid=${searchInstanceId}`;

  return HttpClient.get<CustomMlSearchConfigListResponse>({
    url: url,
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  }).then(searchConfigList => mapCustomMlSearchConfigDtoToModel(searchConfigList.values));
}

export function updateCustomMlSearchConfig(
  tenantId: string,
  indexId: string,
  searchInstanceId: string,
  customMlSearchConfig: CustomMlSearchConfig,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<CustomMlSearchConfig> {
  const url = getCustomMlSetBaseUri(tenantId, indexId);

  const customMlSetDto = mapCustomMlSearchConfigModelToDto(customMlSearchConfig);
  let postBody = {
    ...customMlSetDto,
    searchInstanceId: searchInstanceId,
  };

  return HttpClient.post<CustomMlSearchConfigUpdateResponse>({
    url: url,
    body: postBody,
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  }).then(mapCustomMlSearchConfigDtoToModel);
}

export function deleteDefaultCustomMlSearchConfig(
  tenantId: string,
  indexId: string,
  searchInstanceId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<CustomMlSearchConfig> {
  const url = `${getCustomMlSetBaseUri(
    tenantId,
    indexId,
  )}?searchinstanceid=${searchInstanceId}&custommlid=${DefaultCustomMlSearchConfigId}`;
  return HttpClient.delete<CustomMlSearchConfigDeleteResponse>({
    url: url,
    body: {},
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  }).then(() => mapCustomMlSearchConfigDtoToModel([]));
}
