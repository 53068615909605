import { ProductEditorStyleProps, ProductEditorStyles } from './ProductEditor.types';
import { IStyle } from 'office-ui-fabric-react';

const getTextFieldStyles = (props: ProductEditorStyleProps): IStyle => ({
  width: '100%',
  justifySelf: 'start',
  alignSelf: 'center',
  selectors: {
    '.ms-TextField-errorMessage': {
      fontSize: 10,
      marginLeft: 8,
      fontWeight: 400,
    },
    '&.is-disabled .ms-TextField-fieldGroup': {
      selectors: {
        ':hover': {
          border: 'none',
        },
      },
    },
    input: {
      height: '100%',
      selectors: {
        '::placeholder': {
          fontSize: 12,
          fontWeight: 400,
          color: props.theme.palette.neutralSecondaryAlt,
        },
      },
    },
    '.ms-TextField-fieldGroup': {
      border: 0,
      height: 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      selectors: {
        ':hover': {
          borderWidth: 1,
          borderRadius: 2,
          borderStyle: 'solid',
          borderColor: props.theme.palette.neutralLight,
        },
      },
    },
    '.ms-TextField-field': {
      fontSize: 12,
      fontWeight: 400,
      color: props.theme.palette.neutralSecondaryAlt,
    },
  },
});

export const styles = (props: ProductEditorStyleProps): ProductEditorStyles => {
  const textFieldStyles = getTextFieldStyles(props);
  return {
    required: [
      'mbc-required-label',
      {
        selectors: {
          ':after': {
            content: "'*'",
            marginLeft: 4,
            fontWeight: 600,
            color: props.theme.palette.redDark,
          },
        },
      },
    ],
    action: [
      'mbc-action',
      {
        width: 21,
        height: 21,
        padding: 12,
        selectors: {
          '.ms-Icon': {
            fontSize: 14,
          },
        },
      },
    ],
    wildcardLabel: [
      'mbc-wildcard',
      {
        alignSelf: 'center',
        selectors: {
          '.ms-TextField-fieldGroup': {
            borderWidth: 1,
            borderRadius: 2,
            borderStyle: 'solid',
            borderColor: props.theme.palette.neutralLight,
          },
          '.ms-TextField-fieldGroup:hover': {
            borderWidth: 1,
            borderRadius: 2,
            borderStyle: 'solid',
            borderColor: '#323130',
          },
        },
      },
    ],
    label: [
      'mbc-label',
      {
        fontSize: 12,
        fontWeight: 400,
        alignSelf: 'center',
        color: props.theme.palette.neutralPrimary,
      },
    ],
    errorMessage: [
      'mbc-err-message',
      {
        fontSize: 12,
        fontWeight: 400,
        padding: '0 8px',
        color: props.theme.palette.neutralSecondaryAlt,
      },
    ],
    defaultRow: [
      'mbc-default-row',
      {
        display: 'grid',
        gridTemplateColumns: '5fr 10fr 1fr 1fr',
        padding: '11px 8px 11px 12px',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: props.theme.palette.neutralLighter,
      },
    ],
    multilineRow: [
      'mbc-multi-row',
      {
        display: 'grid',
        gridTemplateColumns: '5fr 12fr',
        padding: '11px 8px 11px 12px',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: props.theme.palette.neutralLighter,
      },
    ],
    multilineRowGrid: [
      'mbc-multi-grid',
      {
        gridColumn: '2 / span 1',
        display: 'grid',
        gridRowGap: '12px',
        gridTemplateColumns: '10fr 1fr 1fr',
      },
    ],
    dateSegmentMultiLineGrid: [
      'mbc-date-segmentmulti-grid',
      {
        gridColumn: '2 / span 1',
        display: 'grid',
        gridRowGap: '12px',
        gridTemplateColumns: '5fr 5fr 1fr',
      },
    ],
    dateSegmentPicker: [
      'mbc-date-segment-picker',
      {
        width: '85%',
      },
    ],
    textField: ['mbc-text-field', textFieldStyles],
    subComponentStyles: {
      breadcrumb: {
        root: {
          marginTop: 0,
          marginLeft: 4,
          marginRight: 4,
        },
        listItem: {
          selectors: {
            ':last-child .ms-Breadcrumb-itemLink': {
              color: props.theme.palette.neutralSecondary,
            },
          },
        },
        itemLink: {
          fontSize: 16,
          color: props.theme.palette.themePrimary,
        },
      },
      detailsList: {
        root: {
          maxHeight: 300,
          minHeight: 200,
          borderTopWidth: 1,
          borderTopRadius: 2,
          borderTopStyle: 'solid',
          borderTopColor: props.theme.palette.neutralLighter,
        },
      },
      datePicker: {
        textField: ['mbc-mask-field', textFieldStyles],
        subComponentStyles: {
          datePicker: {
            textField: textFieldStyles,
          },
        },
      },
    },
  };
};
