import { Action } from 'redux';
import { User } from './userList.d';

export const GET_TENANT_ROLES = 'GET_TENANT_ROLES';
export const RESET_TENANT_ROLES = 'RESET_TENANT_ROLES';
export const LOADING_TENANT_ROLES = 'LOADING_TENANT_ROLES';
export const SET_ACTIVE_TENANT_ROLE = 'SET_ACTIVE_TENANT_ROLE';
export const RESET_ACTIVE_TENANT_ROLE = 'RESET_ACTIVE_TENANT_ROLE';
export const CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST = 'CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST';
export const DELETE_ENVIRONMENT_ROLE_LIST = 'DELETE_ENVIRONMENT_ROLE_LIST';
export const CREATE_OR_UPDATE_ROLE = 'CREATE_OR_UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const SET_ROLE_EDITOR_MODE = 'SET_ROLE_EDITOR_MODE';
export interface RoleIdMapper {
  [key: string]: Role;
}

export interface Role {
  id: string;
  name: string;
  users: User[];
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  description: string;
  permissions: string[];
  environmentList: string[];
  hasAccessToAllEnvironments: boolean;
}

export enum EditorMode {
  None,
  Create,
  Edit,
  View,
}

export interface RoleListState {
  activeRole: Role;
  isLoading: boolean;
  roleIdMapper: RoleIdMapper;
  roleEditorMode: EditorMode.None;
}

interface GetTenantRoles extends Action<typeof GET_TENANT_ROLES> {
  roles: Role[];
}

interface SetActiveTenantRole extends Action<typeof SET_ACTIVE_TENANT_ROLE> {
  role: Role;
}

interface DeleteEnvironmentRoleList extends Action<typeof DELETE_ENVIRONMENT_ROLE_LIST> {
  environmentId: string;
  roleList: string[];
}

interface CreateOrUpdateEnvironmentRoleList extends Action<typeof CREATE_OR_UPDATE_ENVIRONMENT_ROLE_LIST> {
  environmentId: string;
  roleList: Role[];
}

interface CreateOrUpdateRole extends Action<typeof CREATE_OR_UPDATE_ROLE> {
  role: Role;
}

interface DeleteRole extends Action<typeof DELETE_ROLE> {
  roleId: string;
}

interface SetRoleEditorMode extends Action<typeof SET_ROLE_EDITOR_MODE> {
  roleEditorMode: EditorMode;
}

export type RoleListStateActionTypes =
  | GetTenantRoles
  | SetActiveTenantRole
  | DeleteRole
  | CreateOrUpdateRole
  | DeleteEnvironmentRoleList
  | CreateOrUpdateEnvironmentRoleList
  | SetRoleEditorMode
  | Action<typeof LOADING_TENANT_ROLES>
  | Action<typeof RESET_TENANT_ROLES>
  | Action<typeof RESET_ACTIVE_TENANT_ROLE>;
