import { useMemo } from 'react';
import { Augmentations } from '../../common/SearchPreview/SearchPrevew.utils';
import { QueryTesterDetails } from '../QueryTester.types';
import { mapDetailsToAugmentation } from '../utils/DetailsAugmentationMapper';

export const useQueryTesterAugmentations = (queryTesterDetails: QueryTesterDetails): Augmentations => {
  return useMemo(() => {
    return mapDetailsToAugmentation(queryTesterDetails);
  }, [queryTesterDetails]);
};
