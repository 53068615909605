import React, { Component } from 'react';
import styles from './DemoContainer.module.scss';
import { DefaultButton, ActionButton } from 'office-ui-fabric-react';
import RelevanceA from './relevance-demo/relevance-a/RelevanceA';
import RelevanceB from './relevance-demo/relevance-b/RelevanceB';
import PersonalizationA from './personalization-demo/personalization-a/PersonalizationA';
import PersonalizationB from './personalization-demo/personalization-b/PersonalizationB';
import CustomizationsA from './customizations-demo/customizations-a/customizationsA';
import CustomizationsB from './customizations-demo/customizations-b/customizationsB';
import { withTranslation, WithTranslation } from 'react-i18next';
import RequestTrial from './request-trial/requestTrial';
import { AppState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { SearchResult } from '../../../store/types/searchDemo';
import { hideOobeDemo } from '../../../store/actions/oobeDemoActions';
import Footer from '../landing-page/footer/Footer';
import { DraggableReveal } from '../../../components/common/DraggableReveal';

interface DemoContainerState {
  width: number;
  maxRelevanceComponentHeight: number;
  maxPersonalizationComponentHeight: number;
  maxCustomizationsComponentHeight: number;
  demoScenario: number;
  showNextButton: boolean;
  visitedScenario: boolean[];
  lastVisitedScenario: number;
}

// TODO, Find better way to organize demo components props
// Each component should own its results not the demo container
// This was a workaround to fix height bug using refs, check forward refs as a suggested improvement
interface DemoContainerProps extends WithTranslation {
  relevanceASearchResults: SearchResult;
  relevanceBSearchResults: SearchResult;
  promotedASearchResults: SearchResult;
  promotedBSearchResults: SearchResult;
  personalizationASearchResults: SearchResult;
  personalizationBSearchResults: SearchResult;
  hideOobeDemo: Function;
}

export class DemoContainerBase extends Component<DemoContainerProps, DemoContainerState> {
  relevanceARef: React.RefObject<HTMLDivElement>;
  relevanceBRef: React.RefObject<HTMLDivElement>;
  promotedARef: React.RefObject<HTMLDivElement>;
  promotedBRef: React.RefObject<HTMLDivElement>;
  personalizationARef: React.RefObject<HTMLDivElement>;
  personalizationBRef: React.RefObject<HTMLDivElement>;
  static NScenarios = 4;
  constructor(props: DemoContainerProps) {
    super(props);
    this.relevanceARef = React.createRef();
    this.relevanceBRef = React.createRef();
    this.promotedARef = React.createRef();
    this.promotedBRef = React.createRef();
    this.personalizationARef = React.createRef();
    this.personalizationBRef = React.createRef();
    this.state = {
      width: 0,
      maxCustomizationsComponentHeight: 0,
      maxPersonalizationComponentHeight: 0,
      maxRelevanceComponentHeight: 0,
      demoScenario: 0,
      showNextButton: false,
      lastVisitedScenario: 0,
      // initialize visited array by false except for RequestTrial scenario
      visitedScenario: { ...[], [DemoContainerBase.NScenarios - 1]: true },
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentDidUpdate() {
    const maxRelevanceComponentHeight = Math.max(
      this.relevanceARef.current ? this.relevanceARef.current.getBoundingClientRect().height : 0,
      this.relevanceBRef.current ? this.relevanceBRef.current.getBoundingClientRect().height : 0,
    );
    const maxPersonalizationComponentHeight = Math.max(
      this.personalizationARef.current ? this.personalizationARef.current.getBoundingClientRect().height : 0,
      this.personalizationBRef.current ? this.personalizationBRef.current.getBoundingClientRect().height : 0,
    );
    const maxCustomizationsComponentHeight = Math.max(
      this.promotedARef.current ? this.promotedARef.current.getBoundingClientRect().height : 0,
      this.promotedBRef.current ? this.promotedBRef.current.getBoundingClientRect().height : 0,
    );

    if (
      this.state.maxRelevanceComponentHeight !== maxRelevanceComponentHeight ||
      this.state.maxPersonalizationComponentHeight !== maxPersonalizationComponentHeight ||
      this.state.maxCustomizationsComponentHeight !== maxCustomizationsComponentHeight
    ) {
      this.setState(
        (state: DemoContainerState): DemoContainerState => ({
          ...state,
          maxRelevanceComponentHeight: maxRelevanceComponentHeight,
          maxPersonalizationComponentHeight: maxPersonalizationComponentHeight,
          maxCustomizationsComponentHeight: maxCustomizationsComponentHeight,
        }),
      );
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  getFooter() {
    return (
      <div className={styles.footerContainer}>
        <Footer></Footer>
      </div>
    );
  }
  goToNextScenario() {
    const nextScenario = (this.state.demoScenario + 1) % DemoContainerBase.NScenarios;
    this.setState((state: DemoContainerState) => ({
      showNextButton: state.visitedScenario[nextScenario],
      lastVisitedScenario: Math.max(state.lastVisitedScenario, nextScenario),
      demoScenario: nextScenario,
    }));
  }

  goToPreviousScenario() {
    const prevScenario = (this.state.demoScenario - 1) % DemoContainerBase.NScenarios;
    this.setState((state: DemoContainerState) => ({
      showNextButton: true,
      demoScenario: prevScenario,
      lastVisitedScenario: Math.max(state.lastVisitedScenario, prevScenario),
    }));
  }

  onDraggableSliderSwiped() {
    this.setState((state: DemoContainerState) => ({
      showNextButton: true,
      visitedScenario: { ...state.visitedScenario, [state.demoScenario]: true },
    }));
  }

  getCurrentScenario() {
    switch (this.state.demoScenario) {
      case 0:
        return (
          <DraggableReveal
            styles={{
              leftComponent: {
                minHeight:
                  this.state.maxRelevanceComponentHeight === 0 ? '100vh' : this.state.maxRelevanceComponentHeight,
              },
              rightComponent: {
                minHeight:
                  this.state.maxRelevanceComponentHeight === 0 ? '100vh' : this.state.maxRelevanceComponentHeight,
              },
            }}
            key={
              this.state.demoScenario < this.state.lastVisitedScenario
                ? this.state.lastVisitedScenario
                : this.state.demoScenario
            }
            onDraggableSliderSwiped={this.onDraggableSliderSwiped.bind(this)}
            bottomComponent={
              <RelevanceA
                componentRef={this.relevanceARef}
                relevanceASearchResults={this.props.relevanceASearchResults}
                minHeight={this.state.maxRelevanceComponentHeight}
              />
            }
            topComponent={
              <>
                {this.getFooter()}
                <RelevanceB
                  componentRef={this.relevanceBRef}
                  relevanceBSearchResults={this.props.relevanceBSearchResults}
                  minHeight={this.state.maxRelevanceComponentHeight}
                />
              </>
            }
            width={this.state.width}
            peakWidth={75}
            showTeachingBubble={true}
          />
        );
      case 1:
        return (
          <DraggableReveal
            styles={{
              leftComponent: {
                minHeight:
                  this.state.maxCustomizationsComponentHeight === 0
                    ? '100vh'
                    : this.state.maxCustomizationsComponentHeight,
              },
              rightComponent: {
                minHeight:
                  this.state.maxCustomizationsComponentHeight === 0
                    ? '100vh'
                    : this.state.maxCustomizationsComponentHeight,
              },
            }}
            key={
              this.state.demoScenario < this.state.lastVisitedScenario
                ? this.state.lastVisitedScenario
                : this.state.demoScenario
            }
            onDraggableSliderSwiped={this.onDraggableSliderSwiped.bind(this)}
            bottomComponent={
              <CustomizationsA
                componentRef={this.promotedARef}
                promotedASearchResults={this.props.promotedASearchResults}
                minHeight={this.state.maxCustomizationsComponentHeight}
              />
            }
            topComponent={
              <>
                {this.getFooter()}
                <CustomizationsB
                  componentRef={this.promotedBRef}
                  promotedBSearchResults={this.props.promotedBSearchResults}
                  minHeight={this.state.maxCustomizationsComponentHeight}
                />
              </>
            }
            width={this.state.width}
            peakWidth={75}
          />
        );
      case 2:
        return (
          <DraggableReveal
            styles={{
              leftComponent: {
                minHeight:
                  this.state.maxPersonalizationComponentHeight === 0
                    ? '100vh'
                    : this.state.maxPersonalizationComponentHeight,
              },
              rightComponent: {
                minHeight:
                  this.state.maxPersonalizationComponentHeight === 0
                    ? '100vh'
                    : this.state.maxPersonalizationComponentHeight,
              },
            }}
            key={
              this.state.demoScenario < this.state.lastVisitedScenario
                ? this.state.lastVisitedScenario
                : this.state.demoScenario
            }
            onDraggableSliderSwiped={this.onDraggableSliderSwiped.bind(this)}
            bottomComponent={
              <PersonalizationA
                componentRef={this.personalizationARef}
                personalizationASearchResults={this.props.personalizationASearchResults}
                minHeight={this.state.maxPersonalizationComponentHeight}
              />
            }
            topComponent={
              <>
                {this.getFooter()}
                <PersonalizationB
                  componentRef={this.personalizationBRef}
                  personalizationBSearchResults={this.props.personalizationBSearchResults}
                  minHeight={this.state.maxPersonalizationComponentHeight}
                />
              </>
            }
            width={this.state.width}
            peakWidth={75}
          />
        );
      case 3:
        return <RequestTrial />;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.demoWrapper}>
        {this.getCurrentScenario()}
        <div className={styles.demoNavActions}>
          {this.state.demoScenario > 0 ? (
            <ActionButton
              iconProps={{ iconName: 'ChevronLeft' }}
              className={styles.actionButton}
              onClick={() => this.goToPreviousScenario()}
            >
              {t('homepage.demo.prev-button')}
            </ActionButton>
          ) : (
            <div className={styles.buttonPlaceholder}></div>
          )}

          {this.state.showNextButton &&
            (this.state.demoScenario === DemoContainerBase.NScenarios - 1 ? (
              <DefaultButton className={styles.secondaryButton} onClick={() => this.props.hideOobeDemo()}>
                {t('homepage.demo.homepage-button')}
              </DefaultButton>
            ) : (
              <DefaultButton className={styles.secondaryButton} onClick={() => this.goToNextScenario()}>
                {t('homepage.demo.next-button')}
              </DefaultButton>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  relevanceASearchResults: state.searchDemo.relevanceASearchResults,
  relevanceBSearchResults: state.searchDemo.relevanceBSearchResults,
  promotedASearchResults: state.searchDemo.promotedASearchResults,
  promotedBSearchResults: state.searchDemo.promotedBSearchResults,
  personalizationASearchResults: state.searchDemo.personalizationASearchResults,
  personalizationBSearchResults: state.searchDemo.personalizationBSearchResults,
});

export const DemoContainerWithTranslation = withTranslation()(DemoContainerBase);

export default connect(
  mapStateToProps,
  { hideOobeDemo },
)(DemoContainerWithTranslation);
