import React, { Component } from 'react';
import SearchDemo, { Scenario } from '../../../common/search-demo/searchDemo';
import styles from './customizationsB.module.scss';
import { connect } from 'react-redux';
import { SearchResult, BoostType, BoostItem, BoostOperator } from '../../../../../store/types/searchDemo.d';
import { getPromotedSearchResult } from '../../../../../store/actions/searchDemoActions';
import BoostGroup from '../boost-group/boostGroup';
import { Promotion } from '../../../../../components/common/SearchItemCard';

interface CustomizationsBProps {
  // from store
  getPromotedSearchResult: Function;

  // passed by parent
  promotedBSearchResults: SearchResult;
  minHeight: number;
  componentRef: React.RefObject<HTMLDivElement>;
}

interface CustomizationsBState {
  selectedBoostItem?: BoostItem;
  query: string;
}

export class CustomizationsBBase extends Component<CustomizationsBProps, CustomizationsBState> {
  constructor(props: CustomizationsBProps) {
    super(props);
    this.state = {
      query: '',
      selectedBoostItem: this.getBoostOptions()[2],
    };
  }

  getBoostOptions() {
    return [
      {
        key: Promotion.OnSale,
        text: 'Sale',
        value: 'true',
        type: BoostType.Bool,
        operator: BoostOperator.Eq,
      },
      {
        key: Promotion.Price,
        text: 'Price more than $100 ',
        value: '100',
        type: BoostType.Numeric,
        operator: BoostOperator.Ge,
      },
      {
        key: Promotion.Brand,
        text: 'Brand: Litware',
        value: 'Litware, Inc.',
        type: BoostType.String,
        operator: BoostOperator.Eq,
      },
    ];
  }

  onBoostSelectionChange(selectedBoostItem: BoostItem) {
    this.setState({
      selectedBoostItem: selectedBoostItem,
    });
    this.props.getPromotedSearchResult(this.state.query, selectedBoostItem);
  }

  onChangeQuery(query: string) {
    this.setState({
      query: query,
    });
    this.props.getPromotedSearchResult(query, this.state.selectedBoostItem);
  }

  render() {
    return (
      <div
        ref={this.props.componentRef}
        className={styles.wrapper}
        style={{ minHeight: this.props.minHeight > 0 ? this.props.minHeight : '100vh' }}
      >
        <SearchDemo
          defaultQuery={'shoes'}
          scenario={Scenario.BusinessOptimizations}
          searchDemoTitle={'Search using business optimizations'}
          searchResultLabel={
            <BoostGroup
              boostOptions={this.getBoostOptions()}
              selectedKey={this.state.selectedBoostItem ? this.state.selectedBoostItem.key : undefined}
              onChange={this.onBoostSelectionChange.bind(this)}
            ></BoostGroup>
          }
          SearchResultItems={this.props.promotedBSearchResults.searchResultItems.map(item => ({
            ...item,
            promotedItem: this.state.selectedBoostItem ? (this.state.selectedBoostItem.key as Promotion) : undefined,
            promotedValue: this.state.selectedBoostItem ? this.state.selectedBoostItem.value : undefined,
            showSale: true,
          }))}
          onChangeQuery={this.onChangeQuery.bind(this)}
          showPoweredBy={true}
        ></SearchDemo>
      </div>
    );
  }
}

export default connect(
  undefined,
  { getPromotedSearchResult },
)(CustomizationsBBase);
