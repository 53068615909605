import React from 'react';
import { AppState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import AggregatedDataList from '../Common/aggregated-data-list/aggregatedDataList';
import { TopQueriesBaseProps, TopQueriesStyleProps, TopQueriesStyles } from './TopQueries.types';
import { classNamesFunction } from 'office-ui-fabric-react';
import { useDispatch } from '../../../../store/hooks';
import { SetTopQueries } from '../../../../store/actions/analyticsActions';

const getClassNames = classNamesFunction<TopQueriesStyleProps, TopQueriesStyles>();

export const TopQueriesBase = (props: TopQueriesBaseProps) => {
  const { t, className, theme, styles } = props;

  const dispatch = useDispatch();

  const topQueries = useSelector((state: AppState) => state.analytics.topQueries);
  const isTopQueriesLoading = useSelector((state: AppState) => state.analytics.isTopQueriesLoading);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div className={classNames.root}>
      <AggregatedDataList
        title={t('analytics.top-queries.top-queries')}
        data={topQueries}
        isDataLoading={isTopQueriesLoading}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
        ) =>
          dispatch(SetTopQueries(responseCode, 100, start, end, markets, regions, searchResultsStatus, searchInstances))
        }
      ></AggregatedDataList>
    </div>
  );
};
