import { getI18n } from 'react-i18next';
import { CachePeriod, CacheDetails } from '../../../../store/types/insights.d';

export enum TimeFilterOptions {
  NO_TIME = '',
  ONE_HOUR = 'PT1H',
  TWELVE_HOURS = 'PT12H',
  ONE_DAY = 'P1D',
  ONE_WEEK = 'P7D',
  ONE_MONTH = 'P30D',
  ALL = 'All',
}

export const convertTimeSpanToInterval = (timeSpan: string): string => {
  switch (timeSpan) {
    case 'PT1H':
      return '5m';
    case 'PT12H':
      return '5m';
    case 'P1D':
      return '1h';
    case 'P30D':
      return '1d';
    default:
      return '1d';
  }
};

export const getChartTitleTooltipMessage = (cacheDetails: CacheDetails, isChart = true): string => {
  const i18n = getI18n();
  if (cacheDetails.cachePeriod === CachePeriod.RealTime) {
    return i18n.t('insights.chart-title-tooltip.real-time', { containerType: isChart ? 'Chart' : 'Value' });
  } else if (cacheDetails.cachePeriod === CachePeriod.Days) {
    return cacheDetails.lastUpdatedFrom === 1
      ? i18n.t('insights.chart-title-tooltip.day', {
          updatedFrom: cacheDetails.lastUpdatedFrom,
        })
      : i18n.t('insights.chart-title-tooltip.days', {
          updatedFrom: cacheDetails.lastUpdatedFrom,
        });
  } else if (cacheDetails.cachePeriod === CachePeriod.Hours) {
    return cacheDetails.lastUpdatedFrom === 1
      ? i18n.t('insights.chart-title-tooltip.hour', {
          updatedFrom: cacheDetails.lastUpdatedFrom,
        })
      : i18n.t('insights.chart-title-tooltip.hours', {
          updatedFrom: cacheDetails.lastUpdatedFrom,
        });
  } else if (cacheDetails.cachePeriod === CachePeriod.Minutes) {
    return i18n.t('insights.chart-title-tooltip.minutes', {
      updatedFrom: cacheDetails.lastUpdatedFrom,
    });
  } else {
    return '';
  }
};
