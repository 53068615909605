import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import { getTenantTokens } from '../../restful-apis/token.api';
import {
  GET_TENANT_TOKENS,
  TokenListStateActionTypes,
  RESET_TENANT_TOKENS,
  LOADING_TENANT_TOKENS,
  TokenWithPermissions,
  Token,
} from '../types/tokenList.d';
import {
  tokenCreateAction,
  tokenRevokeAction,
  ActionSuccessHandler,
  ActionFailureHandler,
  ExecuteReduxCrudAction,
} from './utils';

export const resetTenantTokens = (): TokenListStateActionTypes => {
  return {
    type: RESET_TENANT_TOKENS,
  };
};

export const getTokens = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_TENANT_TOKENS,
    });

    getTenantTokens(tenantId).then(tokens => {
      dispatch({
        type: GET_TENANT_TOKENS,
        tokens: tokens,
      });
    });
  };
};

export const createToken = (
  tenantId: string,
  token: TokenWithPermissions,
  onSuccess?: ActionSuccessHandler<Token>,
  onFailure?: ActionFailureHandler<Token>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(tokenCreateAction(tenantId, token, onSuccess, onFailure));

export const revokeToken = (
  tenantId: string,
  token: Token,
  onSuccess?: ActionSuccessHandler<Token>,
  onFailure?: ActionFailureHandler<Token>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(tokenRevokeAction(tenantId, token, onSuccess, onFailure));
