import {
  IColumn,
  Stack,
  TooltipHost,
  DirectionalHint,
  classNamesFunction,
  SelectionMode,
  CheckboxVisibility,
  Persona,
  PersonaSize,
  TooltipOverflowMode,
  Link,
} from 'office-ui-fabric-react';
import React, { useState, useCallback, ReactNode } from 'react';
import { Role, EditorMode } from '../../../../store/types/roleList.d';

import { RoleListStyleProps, RoleListStyles, RoleListProps } from './RoleList.types';
import { DetailsListCard } from '../../../../components/common/DetailsListCard';
import { ColumnPriority, SortDataType } from '../../../../components/common/DetailsList';
import { AssignRoles } from '../../../../components/panels/AssignRoles';
import { UserPersona, PersonaType } from '../../../../components/common/UserPersona';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../../store/reducers';
import { EnvironmentPermissionContext, EnvironmentPermissionContextType } from '../../../../contexts';
import { Permission } from '../../../../config/userPermissions.config';
import { setEditorMode } from '../../../../store/actions/roleListActions';
import { MbcRoleRouter } from '../../../../router/MbcAdminRouter/MbcAdminRouter';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';

const getClassNames = classNamesFunction<RoleListStyleProps, RoleListStyles>();

export const RoleListBase = (props: RoleListProps) => {
  const { t, styles, theme, className, isLoading, items, onRolesChanged, disableEdit } = props;

  const dispatch = useDispatch();

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const activeTenant = useSelector((state: AppState) => state.tenantsList.activeTenant);
  const environmentListCount = useSelector(
    (state: AppState) => Object.values(state.environmentList.environmentIdMapper).length,
  );
  const isEnvironmentListLoading = useSelector((state: AppState) => state.environmentList.isLoading);

  const activeEnvironment = useSelector((state: AppState) => state.environmentList.activeEnvironment);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const getRoleMembers = useCallback(
    (item?: any) => {
      let usersInternal = item.users.slice(0, 4);
      let usersPersona: JSX.Element[] = [];

      usersPersona = usersInternal.map((roleUser: any) => {
        return (
          <TooltipHost directionalHint={DirectionalHint.bottomCenter} key={roleUser.email} content={roleUser.email}>
            <UserPersona
              user={{ email: roleUser.email }}
              personaType={PersonaType.HidePersonaDetails}
              key={roleUser.id}
              ariaLabel={roleUser.email}
            />
          </TooltipHost>
        );
      });
      return item.users.length > 4
        ? [
            ...usersPersona,
            <Persona
              key={'remaining'}
              size={PersonaSize.size28}
              imageInitials={`+${item.users.length - 4}`}
              imageUrl={''}
              className={classNames.countPersona}
            />,
          ]
        : usersPersona;
    },
    [classNames.countPersona],
  );

  const onRenderItemColumn = useCallback(
    (item?: any, index?: number, column?: IColumn): ReactNode => {
      if (item && column) {
        switch (column.key) {
          case 'name':
            return (
              <Stack
                verticalAlign={'space-between'}
                tokens={{ childrenGap: 4 }}
                className={classNames.columnWithSubText}
              >
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.nameColumnCell}
                  style={{ maxWidth: column.maxWidth }}
                  content={item.name}
                >
                  <Link
                    onClick={() => {
                      dispatch(setEditorMode(EditorMode.View));
                      MbcRoleRouter(activeTenant, item)(MbcRouteKey.Role).browserPush();
                    }}
                    style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
                  >
                    {item.name}
                  </Link>
                </TooltipHost>
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.subText}
                  styles={{ root: { maxWidth: column.maxWidth } }}
                  content={item.description}
                >
                  {item.description}
                </TooltipHost>
              </Stack>
            );
          case 'members':
            return (
              <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 6 }} horizontalAlign={'end'}>
                {getRoleMembers(item)}
              </Stack>
            );
          default:
            return item[column.fieldName as string] as string;
        }
      }
    },
    [
      activeTenant,
      classNames.columnWithSubText,
      classNames.nameColumnCell,
      classNames.subText,
      dispatch,
      getRoleMembers,
    ],
  );

  const getColumns = useCallback(() => {
    return [
      {
        key: 'name',
        name: t('roles-list.th-name'),
        fieldName: 'name',
        minWidth: 200,
        maxWidth: 300,
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        onRender: onRenderItemColumn,
        subTextField: 'description',
      },
      {
        key: 'environmentListCount',
        name: t('roles-list.th-instances-count'),
        fieldName: 'environmentListCount',
        minWidth: 200,
        maxWidth: 300,
        isMultiline: true,
        centerAligned: true,
        priority: ColumnPriority.Secondary,
      },
      {
        key: 'members',
        name: t('roles-list.th-members'),
        fieldName: 'membersCount',
        minWidth: 200,
        maxWidth: 300,
        isMultiline: false,
        priority: ColumnPriority.Primary,
        onRender: onRenderItemColumn,
        headerClassName: classNames.centeredColumns,
      },
    ];
  }, [classNames.centeredColumns, onRenderItemColumn, t]);

  const getDisplayedItems = useCallback(
    (userRoleList: Role[]) => {
      return userRoleList
        .sort((item1, item2) => (new Date(item1.updatedAt) > new Date(item2.updatedAt) ? -1 : 1))
        .map(role => {
          return {
            ...role,
            key: role.id,
            createdAt: moment
              .parseZone(role.createdAt as string)
              .local()
              .format('YYYY-MM-DD'),
            modifiedAt: moment
              .parseZone(role.updatedAt as string)
              .local()
              .format('YYYY-MM-DD'),
            membersCount: role.users.length,
            environmentListCount: role.hasAccessToAllEnvironments ? environmentListCount : role.environmentList.length,
          };
        });
    },
    [environmentListCount],
  );

  return (
    <>
      {isPanelOpen && (
        <AssignRoles
          isOpen={isPanelOpen}
          onDismiss={() => setIsPanelOpen(false)}
          onSaveClick={(selectedItems: Role[]) => {
            setIsPanelOpen(false);
            !!onRolesChanged && onRolesChanged(selectedItems);
          }}
          onCancelClick={() => setIsPanelOpen(false)}
          items={items}
        />
      )}
      <EnvironmentPermissionContext.Consumer>
        {(props: EnvironmentPermissionContextType) => (
          <DetailsListCard
            header={{
              actions: {
                edit: {
                  disabled:
                    !props.isAuthorized(Permission.AdminEnvironmentReadWrite, activeEnvironment.id) || disableEdit,
                  onClick: () => {
                    setIsPanelOpen(true);
                  },
                },
              },
            }}
            searchActionProps={{ searchBy: ['name', 'description'] }}
            listProps={{
              isLoading: isLoading || isEnvironmentListLoading,
              items: getDisplayedItems(items),
              onRenderNoItemsToDisplay: {
                text: t('roles-list.no-data-text'),
                subText: t('roles-list.no-data-sub-text'),
                actionProps: {
                  disabled:
                    !props.isAuthorized(Permission.AdminEnvironmentReadWrite, activeEnvironment.id) || disableEdit,
                  buttonText: t('common.no-data-button-text'),
                  onClick: () => {
                    setIsPanelOpen(true);
                  },
                },
              },
              selectionProps: {
                selectionMode: SelectionMode.none,
                checkboxVisibility: CheckboxVisibility.hidden,
                onSelectionChanged: () => {},
              },
              responsiveColumns: {
                columns: getColumns(),
                secondaryBreakPoint: 760,
                teritaryBreakPoint: 1100,
              },
            }}
          />
        )}
      </EnvironmentPermissionContext.Consumer>
    </>
  );
};
