import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from '../../../../../store/hooks';
import { ProductEditor } from './ProductEditor/ProductEditor';
import { ProductViewer } from './ProductViewer/ProductViewer';
import { mapProductListModelToDto } from './ProductPreview.utils';
import { ProductPreviewProps, PreviewMode, ProductItem } from './ProductPreview.types';
import { mapProductDtoToListModel } from './ProductViewer/ProductViewer.utils';
import { mapProductDtoToDefaultListModel } from './ProductEditor/ProductEditor.utils';
import { HorizontalProductItemCard } from '../../../../../components/common/ProductItemCard';
import { EnvironmentPermissionContext, EnvironmentPermissionContextType } from '../../../../../contexts';
import { Permission } from '../../../../../config/userPermissions.config';

export const ProductPreviewBase = (props: ProductPreviewProps) => {
  const {
    product = {
      id: '',
      data: {},
    },
    previewMode,
    actionProps,
    onDismiss,
    onPreviewModeChange,
  } = props;

  const searchIndex = useSelector(state => state.searchIndex.searchIndex);

  const [updatedProductItemList, setUpdatedProductItemList] = useState<ProductItem[]>([]);

  useEffect(() => {
    setUpdatedProductItemList(mapProductDtoToListModel(searchIndex.fields, product.data));
    return () => {};
  }, [product.data, searchIndex.fields]);

  const onCancelEditChanges = useCallback(
    () =>
      previewMode === PreviewMode.New ? onDismiss() : onPreviewModeChange && onPreviewModeChange(PreviewMode.Read),
    [onDismiss, onPreviewModeChange, previewMode],
  );

  const onSaveEditChanges = useCallback(
    productItemList => !!actionProps && actionProps.onUpdateProduct(mapProductListModelToDto(productItemList)),
    [actionProps],
  );

  const EditableProductItemList = useMemo(() => mapProductDtoToDefaultListModel(searchIndex.fields, product.data), [
    product.data,
    searchIndex.fields,
  ]);

  return (
    <>
      {previewMode !== PreviewMode.New && (
        <EnvironmentPermissionContext.Consumer key="product">
          {(props: EnvironmentPermissionContextType) => {
            const isAuthorized = props.isAuthorized(Permission.SearchIndexReadWrite);
            return (
              <HorizontalProductItemCard
                onDismiss={onDismiss}
                key={product.id}
                item={product}
                actionProps={
                  !!actionProps
                    ? {
                        onEditClick:
                          !isAuthorized || previewMode === PreviewMode.Edit
                            ? undefined
                            : () => onPreviewModeChange && onPreviewModeChange(PreviewMode.Edit),
                        onRemoveClick: isAuthorized ? () => actionProps.onDeleteProduct(product) : undefined,
                      }
                    : undefined
                }
              />
            );
          }}
        </EnvironmentPermissionContext.Consumer>
      )}
      {previewMode === PreviewMode.Read && <ProductViewer productItemList={updatedProductItemList} />}
      {(previewMode === PreviewMode.Edit || previewMode === PreviewMode.New) && (
        <ProductEditor
          previewMode={previewMode}
          onSaveEditChanges={onSaveEditChanges}
          onCancelEditChanges={onCancelEditChanges}
          productItemList={EditableProductItemList}
        />
      )}
    </>
  );
};
