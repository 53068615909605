import {
  AdminTokensStateActionTypes,
  RESET_ADMIN_TOKEN,
  RESET_ALL_ADMIN_TOKENS,
  SET_ADMIN_TOKEN,
  Token,
} from '../types/adminTokens.d';

export function resetAdminToken(key: string): AdminTokensStateActionTypes {
  return {
    type: RESET_ADMIN_TOKEN,
    key: key,
  };
}

export function resetAllAdminTokens(): AdminTokensStateActionTypes {
  return {
    type: RESET_ALL_ADMIN_TOKENS,
  };
}

export function setAdminToken(key: string, token: Token): AdminTokensStateActionTypes {
  return {
    type: SET_ADMIN_TOKEN,
    token: token,
    key: key,
  };
}
