import {
  CheckboxVisibility,
  SelectionMode,
  IColumn,
  IContextualMenuItem,
  Stack,
  IconButton,
  TooltipHost,
  DirectionalHint,
  classNamesFunction,
  CommandBarButton,
  TooltipOverflowMode,
} from 'office-ui-fabric-react';
import React, { ReactNode, useState, useCallback, createRef } from 'react';
import { AppState } from '../../store/reducers';
import { deleteEnvironmentWithRoleList } from '../../store/actions/environmentWithRoleListActions';
import {
  Environment,
  EnvironmentWithRoles,
  createInitialEnvironmentWithRoleList,
} from '../../store/types/environmentList.d';
import { ConfirmationDialog as DeletionDialog } from '../common/Dialog/ConfirmationDialog';
import { Permission } from '../../config/userPermissions.config';
import {
  EnvironmentPermissionContextType,
  TenantPermissionContext,
  TenantPermissionContextType,
  EnvironmentPermissionContext,
} from '../../contexts';
import { EnvironmentListStyleProps, EnvironmentListStyles, EnvironmentListProps } from './EnvironmentList.types';
import { useSelector, useDispatch } from '../../store/hooks';
import { DetailsListCard } from '../common/DetailsListCard/DetailsListCard';
import { ColumnPriority, SortDataType, ColumnWithPriority } from '../common/DetailsList';
import { EnvironmentStatus } from './EnvironmentStatus';
import moment from 'moment';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { setActiveEnvironment } from '../../store/actions/environmentListActions';
import { browserHistory } from '../../history.module';
import { MbcEnvironmentRouter } from '../../router/MbcEnvironmentRouter/MbcEnvironmentRouter';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { Tutorial, TutorialSteps } from '../Tutorials';
import { AvailableTutorials } from '../../store/types/tutorial.d';
import { useCurrentServices } from '../../store/hooks/use-current-services/useCurrentServices';
import { LinkIconSize, ProductShortcut } from '../../store/hooks/use-current-services/useCurrentServices.types';
import { useRoleEnvironmentList } from '../../store/hooks/use-list/useRoleEnvironmentList';
import { ListItemsOrder } from '../../utils';
import { useEnvironmentListWithoutDeletingStatus } from '../../store/hooks/use-list/useEnvironmentListWithoutDeleting';

const getClassNames = classNamesFunction<EnvironmentListStyleProps, EnvironmentListStyles>();

export const EnvironmentListBase = (props: EnvironmentListProps) => {
  const { t, styles, theme, className, isPanel, onSelectionChanged, selectedItems } = props;

  const dispatch = useDispatch();

  const listContainerRef = createRef<HTMLDivElement>();
  const environmentNameRef = createRef<HTMLDivElement>();
  const availableServicesContainer = createRef<HTMLDivElement>();

  const roleList = useSelector((state: AppState) => Object.values(state.roleList.roleIdMapper));
  const activeTenant = useSelector((state: AppState) => state.tenantsList.activeTenant);
  const isUserRoleLoading = useSelector((state: AppState) => state.roleList.isLoading);
  const isEnvironmentListLoading = useSelector((state: AppState) => state.environmentList.isLoading);
  const isSearchIndexListLoading = useSelector((state: AppState) => state.searchIndex.isSearchIndexListLoading);
  const isSandboxTenant = useSelector((state: AppState) => state.tenantsList.activeTenant.isSandbox);
  const tutorial = useSelector((state: AppState) => state.tutorial);
  const roleEnvironmentList = useRoleEnvironmentList();
  const environmentListWithoutDeleting = useEnvironmentListWithoutDeletingStatus();

  const displayedEnvironmentList = isPanel ? environmentListWithoutDeleting : roleEnvironmentList;

  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
    tutorialActive: tutorial.currentTutorial === AvailableTutorials.EnvironmentList,
    isPanel: isPanel,
  });

  const adminRouteMap = useAdminRouter();
  const productDetailsMapper = useCurrentServices(LinkIconSize.Small);

  const [editableEnvironment, setEditableEnvironment] = useState<EnvironmentWithRoles>(
    createInitialEnvironmentWithRoleList(),
  );
  const [showEnvironmentDeletionDialog, setShowEnvironmentDeletionDialog] = useState(false);

  const getEnvironmentRoleList = (environmentId: string): string[] => {
    return roleList.filter(role => role.environmentList.includes(environmentId)).map(role => role.id);
  };

  const onDismissEnvironmentDeletionDialog = (): void => {
    setShowEnvironmentDeletionDialog(false);
    setEditableEnvironment(createInitialEnvironmentWithRoleList());
  };

  const onConfirmEnvironmentDeletion = (): void => {
    dispatch(deleteEnvironmentWithRoleList(activeTenant.id, editableEnvironment, () => {}));
    onDismissEnvironmentDeletionDialog();
  };

  const onMenuItemRemoveInvoked = (
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem,
  ) => {
    if (item) {
      setShowEnvironmentDeletionDialog(true);
      setEditableEnvironment({
        id: item.data.id,
        name: item.data.name,
        regions: item.data.regions,
        createdBy: item.data.createdBy,
        description: item.data.description,
        createdAt: item.data.createdAt,
        updatedAt: item.data.updatedAt,
        indexId: item.data.indexId,
        products: item.data.products,
        roleIdList: getEnvironmentRoleList(item.data.id),
        analyticsEnabled: item.data.analyticsEnabled,
      });
    }
  };

  const getProductIcon = (product: ProductShortcut): JSX.Element => {
    return (
      <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={product.text}>
        <IconButton
          iconProps={{ iconName: product.icon }}
          ariaLabel={product.ariaLabel}
          onClick={isPanel ? undefined : product.onClick}
          disabled={product.disabled}
        />
      </TooltipHost>
    );
  };

  const onRenderItemColumn = (item?: Environment, index?: number, column?: IColumn): ReactNode => {
    if (item && column && typeof index !== 'undefined') {
      const fieldContent = item[column.fieldName as keyof Environment] as string;
      switch (column.key) {
        case 'name':
          return item.isIndexSoftDeleted ? (
            <div
              ref={index === 0 ? environmentNameRef : undefined}
              style={{
                maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined,
              }}
              className={classNames.nameColumnCell}
            >
              {item.name}
            </div>
          ) : (
            <TooltipHost
              overflowMode={TooltipOverflowMode.Self}
              hostClassName={classNames.nameColumnToolTip}
              style={{ maxWidth: column.maxWidth }}
              content={item.name}
            >
              <div
                ref={index === 0 ? environmentNameRef : undefined}
                style={{
                  maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined,
                }}
                className={classNames.nameColumnCell}
                onClick={() =>
                  MbcEnvironmentRouter(activeTenant, item)(
                    !!item.indexId ? MbcRouteKey.Environment : MbcRouteKey.SchemaManagement,
                  ).browserPush()
                }
              >
                {item.name}
              </div>
            </TooltipHost>
          );
        case 'products':
          // TODO: implement onCLick handler after other pages are implementes
          return (
            <div ref={index === 0 ? availableServicesContainer : undefined} className={classNames.productsContainer}>
              <Stack
                horizontal
                verticalAlign={'center'}
                tokens={{ childrenGap: 16 }}
                className={classNames.productsIcon}
              >
                {productDetailsMapper(item)
                  .filter(product => item.products.includes(product.product))
                  .map(product => getProductIcon(product))}
              </Stack>
            </div>
          );
        case 'status':
          return <EnvironmentStatus searchIndexId={item.indexId} />;
        default:
          return <span>{fieldContent}</span>;
      }
    }
  };

  const getDisplayedItems = useCallback(
    () =>
      displayedEnvironmentList
        .sort((item1, item2) => (new Date(item1.createdAt) > new Date(item2.createdAt) ? -1 : 1))
        .map(environment => ({
          ...environment,
          key: environment.id,
          createdAt: moment
            .parseZone(environment.createdAt as string)
            .local()
            .format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment
            .parseZone(environment.createdAt as string)
            .local()
            .format('YYYY-MM-DD HH:mm:ss'),
          indexId: environment.indexId || t('environment-list.no-index-id'),
        })),
    [displayedEnvironmentList, t],
  );

  const getActions = (): JSX.Element[] => [
    <TenantPermissionContext.Consumer key="add">
      {(props: TenantPermissionContextType) => {
        const isAuthorized = props.isAuthorized(Permission.AdminEnvironmentReadWrite);
        return (
          <CommandBarButton
            className={classNames.addButton}
            iconProps={{
              iconName: 'Add',
            }}
            disabled={!isAuthorized || isSandboxTenant}
            onClick={() => {
              adminRouteMap(MbcRouteKey.EnvironmentCreator).browserPush();
            }}
          >
            {t('common.add')}
          </CommandBarButton>
        );
      }}
    </TenantPermissionContext.Consumer>,
  ];

  const getColumnsList = (): ColumnWithPriority[] => {
    let columns = [
      {
        key: 'name',
        name: t('environment-list.th-name'),
        fieldName: 'name',
        minWidth: 190,
        maxWidth: 250,
        isMultiline: false,
        priority: ColumnPriority.Primary,
        sortDataType: SortDataType.text,
        onRender: isPanel
          ? undefined
          : (item?: Environment, index?: number, column?: IColumn) => onRenderItemColumn(item, index, column),
      },
      {
        key: 'products',
        name: t('environment-list.th-products'),
        fieldName: 'product',
        minWidth: 70,
        maxWidth: 120,
        isMultiline: false,
        priority: ColumnPriority.Primary,
        onRender: (item?: Environment, index?: number, column?: IColumn) => onRenderItemColumn(item, index, column),
        centerAligned: true,
      },
    ];

    return isPanel
      ? columns
      : [
          ...columns,
          {
            key: 'status',
            name: t('environment-list.th-status'),
            fieldName: '',
            minWidth: 100,
            maxWidth: 120,
            isMultiline: false,
            priority: ColumnPriority.Primary,
            centerAligned: true,
            onRender: (item?: Environment, index?: number, column?: IColumn) => onRenderItemColumn(item, index, column),
          },
          {
            key: 'indexId',
            name: t('environment-list.th-index-id'),
            fieldName: 'indexId',
            minWidth: 220,
            maxWidth: 230,
            isMultiline: false,
            priority: ColumnPriority.Secondary,
          },
          {
            key: 'created',
            name: t('environment-list.th-created'),
            fieldName: 'createdAt',
            minWidth: 150,
            maxWidth: 300,
            isMultiline: false,
            priority: ColumnPriority.Tertiary,
            centerAligned: true,
            sortDataType: SortDataType.dateTime,
          },
          {
            key: 'owner',
            name: t('environment-list.th-owner'),
            fieldName: 'createdBy',
            minWidth: 150,
            maxWidth: 300,
            isMultiline: false,
            priority: ColumnPriority.Tertiary,
          },
        ];
  };

  const getTutorialSteps = (): JSX.Element => {
    return (
      <>
        <Tutorial target={listContainerRef} step={TutorialSteps.EnvList} headline={t('tutorial.env-list.headline')}>
          {t('tutorial.env-list.step-1')}
        </Tutorial>
        <Tutorial
          target={availableServicesContainer}
          step={TutorialSteps.EnvProducts}
          headline={t('tutorial.env-list.headline')}
        >
          {t('tutorial.env-list.step-2')}
        </Tutorial>
        <Tutorial target={environmentNameRef} step={TutorialSteps.EnvName} headline={t('tutorial.env-list.headline')}>
          {t('tutorial.env-list.step-3')}
        </Tutorial>
      </>
    );
  };

  return (
    <>
      {getTutorialSteps()}
      <DeletionDialog
        key={editableEnvironment.id}
        isOpen={showEnvironmentDeletionDialog}
        title={t('environment-list.deletion-title')}
        subText={`${t('environment-list.deletion-subtext')}  ${editableEnvironment.name}?`}
        onConfirm={onConfirmEnvironmentDeletion}
        onDismiss={onDismissEnvironmentDeletionDialog}
      ></DeletionDialog>
      {
        <TenantPermissionContext.Consumer key="add">
          {(tenantProps: TenantPermissionContextType) => (
            <EnvironmentPermissionContext.Consumer>
              {(props: EnvironmentPermissionContextType) => (
                <DetailsListCard
                  header={{
                    title: isPanel ? undefined : t('environment-list.instances-title'),
                    infoProps: isPanel
                      ? undefined
                      : {
                          headline: t('environment-list.info-headline'),
                          content: t('environment-list.info-content'),
                          linkProps: {
                            linkText: t('environment-list.info-link'),
                            to: '/docs/Portal%20Documentation/#manage-environments',
                          },
                        },
                    actions: {
                      filter: isPanel
                        ? undefined
                        : {
                            filterableFields: [{ fieldName: 'createdBy', displayName: t('environment-list.th-owner') }],
                          },
                      actionsList: getActions(),
                    },
                  }}
                  searchActionProps={{ searchBy: 'name' }}
                  listProps={{
                    componentRef: listContainerRef,
                    isLoading: isEnvironmentListLoading || isSearchIndexListLoading || isUserRoleLoading,
                    items: getDisplayedItems(),
                    onRenderNoItemsToDisplay: {
                      text: t('environment-list.no-data-text'),
                      subText: t('environment-list.no-data-sub-text'),
                      actionProps: {
                        buttonText: t('common.no-data-button-text'),
                        disabled: !tenantProps.isAuthorized,
                        onClick: () => {
                          adminRouteMap(MbcRouteKey.EnvironmentCreator).browserPush();
                        },
                      },
                    },
                    selectionProps: isPanel
                      ? {
                          defaultSelected: selectedItems ? selectedItems : [],
                          selectionMode: SelectionMode.multiple,
                          checkboxVisibility: CheckboxVisibility.always,
                          onSelectionChanged: (selection: string[]) => {
                            !!onSelectionChanged &&
                              onSelectionChanged(displayedEnvironmentList.filter(env => selection.includes(env.id)));
                          },
                        }
                      : {
                          selectionMode: SelectionMode.none,
                          checkboxVisibility: CheckboxVisibility.hidden,
                          onSelectionChanged: () => {},
                        },
                    optionsItemProps: isPanel
                      ? undefined
                      : (environment: Environment) => [
                          {
                            key: 'edit',
                            text: t('common.edit'),
                            iconProps: { iconName: 'Edit' },
                            data: environment,
                            disabled:
                              environment.isIndexSoftDeleted ||
                              !props.isAuthorized(Permission.AdminEnvironmentReadWrite, environment.id),
                            onClick: () => {
                              setActiveEnvironment(environment.id);
                              browserHistory.push(
                                MbcEnvironmentRouter(activeTenant, environment)(MbcRouteKey.EnvironmentEditor).url,
                              );
                            },
                          },
                          {
                            key: 'remove',
                            text: t('common.delete'),
                            iconProps: { iconName: 'Delete' },
                            data: environment,
                            disabled:
                              environment.isIndexSoftDeleted ||
                              !props.isAuthorized(Permission.AdminEnvironmentReadWrite, environment.id),
                            onClick: onMenuItemRemoveInvoked,
                          },
                        ],
                    responsiveColumns: {
                      columns: getColumnsList(),
                      initialSortingDetails: isPanel
                        ? {
                            fieldName: 'name',
                            dataType: SortDataType.text,
                          }
                        : {
                            fieldName: 'createdAt',
                            dataType: SortDataType.dateTime,
                            orderBy: ListItemsOrder.DESCENDING,
                          },
                      secondaryBreakPoint: 850,
                      teritaryBreakPoint: 1200,
                    },
                  }}
                />
              )}
            </EnvironmentPermissionContext.Consumer>
          )}
        </TenantPermissionContext.Consumer>
      }
    </>
  );
};
