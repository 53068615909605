import {
  RightPanelState,
  RightPanelStateActionTypes,
  SET_RIGHT_PANEL_TYPE,
  RightPanelType,
} from '../types/rightPanel.d';

const initialState: RightPanelState = {
  rightPanelType: RightPanelType.None,
};

export function rightPanelReducer(state = initialState, action: RightPanelStateActionTypes): RightPanelState {
  switch (action.type) {
    case SET_RIGHT_PANEL_TYPE: {
      return {
        ...state,
        rightPanelType: action.rightPanelType,
      };
    }

    default:
      return state;
  }
}
