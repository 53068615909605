import { ActionButton, SearchBox, Stack, TextField, classNamesFunction, IconButton } from 'office-ui-fabric-react';
import React, { useState, useEffect, createRef } from 'react';
import { MembersList } from '../MembersList';
import { useSelector } from '../../../store/hooks';
import {
  MembersSectionContentProps,
  MembersSectionContentStyleProps,
  MembersSectionContentStyles,
} from './MembersSectionContent.types';

const getClassNames = classNamesFunction<MembersSectionContentStyleProps, MembersSectionContentStyles>();

export const MembersSectionContentBase = (props: MembersSectionContentProps) => {
  const { viewOnlyMode, emailsList, onAddEmail, t, styles, className, theme } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [email, setEmail] = useState('');
  const [filterTerm, setFilterTerm] = useState('');
  const [showNewField, setShowNewField] = useState(false);
  const [newEmailErrorMessage, setNewEmailErrorMessage] = useState('');
  const userEmailMapper = useSelector(state => state.userList.userEmailMapper);

  const isInValidEmail = () => !email.includes('@');

  const newEmailContainer = createRef<HTMLDivElement>();

  const getFilteredEmails = () => {
    return emailsList.filter(curEmail => {
      if (filterTerm === '') return true;

      return (
        curEmail.toLowerCase().includes(filterTerm.toLowerCase()) ||
        userEmailMapper[curEmail].displayName.toLowerCase().includes(filterTerm.toLowerCase())
      );
    });
  };

  useEffect(() => {
    window.onclick = (ev: MouseEvent) => {
      if (newEmailContainer.current && !email) {
        const BB = newEmailContainer.current.getBoundingClientRect();
        if (ev.clientY < BB.top || ev.clientY > BB.bottom || ev.clientX > BB.right || ev.clientX < BB.left) {
          setShowNewField(false);
        }
      }
    };
  }, [email, newEmailContainer]);

  useEffect(() => {
    setFilterTerm('');
    setEmail('');
  }, [viewOnlyMode]);

  return (
    <Stack data-test="role-editor-members-section" className={classNames.root}>
      <Stack
        horizontal
        horizontalAlign={'space-between'}
        verticalAlign="start"
        className={classNames.inputFieldsContainer}
      >
        {!!emailsList.length && (
          <SearchBox
            data-test="search-box"
            value={filterTerm}
            placeholder={t('common.search-label')}
            underlined={true}
            onChange={(ev: any, newVal?: string) => setFilterTerm(newVal || '')}
            className={classNames.searchBox}
          />
        )}
        <div ref={newEmailContainer} className={classNames.addEmailContainer}>
          {!viewOnlyMode && !showNewField && (
            <ActionButton
              data-test="add-user-button"
              iconProps={{ iconName: 'Add' }}
              onClick={() => {
                setShowNewField(true);
              }}
            >
              {t('common.add')}
            </ActionButton>
          )}

          {!viewOnlyMode && showNewField && (
            <>
              <TextField
                value={email}
                onChange={(ev: any, newVal?: string) => {
                  let newEmail = newVal || '';
                  setEmail(newEmail);
                  if (!newEmail) {
                    setNewEmailErrorMessage('');
                  }
                }}
                data-test="add-user-text-field"
                disabled={viewOnlyMode}
                underlined={true}
                placeholder={t('role-editor.members-section.email-field-placeholder')}
                className={classNames.emailInputField}
                errorMessage={newEmailErrorMessage}
                autoComplete="email"
              />
              <IconButton
                iconProps={{ iconName: 'Add' }}
                onClick={() => {
                  if (email !== '') {
                    if (emailsList.includes(email)) {
                      setNewEmailErrorMessage(t('role-editor.members-section.email-duplicated-error-msg'));
                    } else if (isInValidEmail()) {
                      setNewEmailErrorMessage(t('role-editor.members-section.email-invalid-msg'));
                    } else {
                      if (newEmailErrorMessage) {
                        setNewEmailErrorMessage('');
                      }
                      onAddEmail(email);
                      setEmail('');
                    }
                  }
                }}
              />
            </>
          )}
        </div>
      </Stack>

      <MembersList
        viewOnlyMode={viewOnlyMode}
        membersEmails={getFilteredEmails()}
        onRemoveMember={props.onRemoveEmail}
      />
    </Stack>
  );
};
