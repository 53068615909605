import { ProductToursPanelStyleProps, ProductToursPanelStyles } from './ProductToursPanel.types';

export const styles = (props: ProductToursPanelStyleProps): ProductToursPanelStyles => {
  return {
    root: [
      'mbc-product-tours-panel',
      {
        selectors: {
          '.ms-Panel-main': {
            width: 408,
          },
        },
      },
      props.className,
    ],
    panelHeaderContainer: [
      'mbc-header-container',
      {
        width: '100%',
        alignItems: 'center',
        height: 85,
        selectors: { '.ms-Button-flexContainer': { height: 36 } },
      },
    ],
    panelTitle: [
      'mbc-panel-title',
      {
        fontWeight: 600,
        paddingLeft: 10,
        fontSize: 20,
        lineHeight: 28,
      },
    ],
  };
};
