import { ConditionValueWrapperStyles, ConditionValueWrapperStyleProps } from './ConditionValueWrapper.types';

export const styles = (props: ConditionValueWrapperStyleProps): ConditionValueWrapperStyles => {
  const { className } = props;
  return {
    root: ['mbc-ConditionValueWrapper', {}, className],

    valueItem: [
      'mbc-ConditionValueWrapper-valueItem',
      {
        marginBottom: 10,
        border: '1px solid #D2D0CE',
        backgroundColor: '#F3F2F1',
        selectors: {
          button: {
            border: 0,
            color: '#605E5C',
          },
        },
      },
      className,
    ],

    valueItemText: [
      'mbc-ConditionValueWrapper-valueItemText',
      {
        margin: '5px 10px',
      },
    ],
  };
};
