import moment from 'moment';
import { DatePicker, Label, Stack, StackItem, TextField, classNamesFunction } from 'office-ui-fabric-react';
import React, { useState } from 'react';
import { DateTimePickerProps, DateTimeStyles, DateTimeStyleProps } from './DateTimePicker.types';
import { getLocalTimeZone } from './DateTimePicker.utils';

const getClassNames = classNamesFunction<DateTimeStyleProps, DateTimeStyles>();

const IsNumberRegex = new RegExp(/^\d{1,2}$/);

export const DateTimePickerBase = (props: DateTimePickerProps) => {
  const { t, date, label, setMinDate, maxDate, isVertical, onDateChange, styles, theme, className, disabled } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const currentDate = new Date(Date.now());
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [time, setTime] = useState(date ? moment(date).format('HH:mm') : '00:00');

  const onChangeDateTime = (newDate: Date, newTime: string) => {
    const updatedDate = moment(newDate).isValid() ? newDate : new Date(Date.now());
    const [hours, minutes] = newTime.includes(':') ? newTime.split(':') : ['H', 'M'];
    updatedDate.setHours(IsNumberRegex.test(hours) && parseInt(hours) <= 23 ? parseInt(hours) : parseInt('H'));
    updatedDate.setMinutes(IsNumberRegex.test(minutes) && parseInt(minutes) <= 59 ? parseInt(minutes) : parseInt('M'));

    setTime(newTime);
    onDateChange(updatedDate);
    setErrorMessage(!moment(updatedDate).isValid() ? t('common.datetime-picker.time-validation-msg') : undefined);
  };

  const onChangeDate = (newDate: Date) => onChangeDateTime(newDate, !!date && moment(date).isValid() ? time : '00:00');

  const onChangeTime = (newTime: string) =>
    onChangeDateTime(date && moment(date).isValid() ? date : new Date(Date.now()), newTime);

  return (
    <Stack>
      {label && (
        <StackItem grow className={classNames.label}>
          <Label>{label}</Label>
        </StackItem>
      )}
      <Stack horizontal={!isVertical} tokens={{ childrenGap: 5 }}>
        <StackItem grow>
          <DatePicker
            value={date}
            disabled={disabled}
            minDate={setMinDate ? currentDate : undefined}
            maxDate={maxDate}
            styles={classNames.subComponentStyles.datePicker}
            onSelectDate={newDate => newDate && onChangeDate(newDate)}
            formatDate={(date?: Date) => (date ? moment(date).format('YYYY-MM-DD') : '')}
          ></DatePicker>
        </StackItem>
        <StackItem>
          <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'baseline'}>
            <TextField
              value={time}
              disabled={disabled}
              validateOnLoad={false}
              errorMessage={errorMessage}
              className={classNames.textField}
              suffix={getLocalTimeZone('short')}
              onChange={(ev, newValue) => onChangeTime(newValue || '')}
            />
          </Stack>
        </StackItem>
      </Stack>
    </Stack>
  );
};
