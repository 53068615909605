import { Action } from 'redux';
import { Banner } from './customizations/businessRule.d';
import { Facet } from '../../restful-apis/dto/search/facet';
import { ProductSearchResultItem } from './productSearch.d';

export const GET_PERSONALIZATION_A_SEARCH_RESULT = 'GET_PERSONALIZATION_A_SEARCH_RESULT';
export const GET_PERSONALIZATION_B_SEARCH_RESULT = 'GET_PERSONALIZATION_B_SEARCH_RESULT';
export const GET_PROMOTED_A_SEARCH_RESULTS = 'GET_PROMOTED_A_SEARCH_RESULTS';
export const GET_PROMOTED_B_SEARCH_RESULTS = 'GET_PROMOTED_B_SEARCH_RESULTS';
export const GET_RELEVANCE_A_SEARCH_RESULT = 'GET_RELEVANCE_A_SEARCH_RESULT';
export const GET_RELEVANCE_B_SEARCH_RESULT = 'GET_RELEVANCE_B_SEARCH_RESULT';

export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const GET_AUGMENTED_SEARCH_RESULT = 'GET_AUGMENTED_SEARCH_RESULT';

export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';
export const LOADING_SEARCH_RESULT = 'LOADING_SEARCH_RESULT';

export enum BoostType {
  Numeric = 'NumericCondition',
  String = 'StringCondition',
  Bool = 'BoolCondition',
}

export enum BoostOperator {
  Eq = 'Eq',
  Ne = 'Ne',
  Gt = 'Gt',
  Lt = 'Lt',
  Le = 'Le',
  Ge = 'Ge',
}

export interface ProductSearchResult {
  top: number;
  skip: number;
  query: string;
  facets?: Facet[];
  banners?: Banner[];
  isLoading: boolean;
  alteredQuery: string;
  redirectUrl?: string;
  searchRawResponse: any;
  totalEstimatedMatches: number;
  searchResultItems: ProductSearchResultItem[];
}

export interface BoostItem {
  key: string;
  value: string;
  type: BoostType;
  operator: BoostOperator;
}

export interface SearchResultItem {
  id?: string;
  name?: string;
  price?: number;
  description?: string;
  imgUrl?: string;
  isFavorited?: boolean;
  currency?: string;
  onSale?: string;
  categories?: string;
  collection?: string;
  promotionDisplay?: string;
}

export interface SearchResult {
  resultCount: number;
  alteredQuery: string;
  facets?: Facet[];
  isLoading: boolean;
  originalQuery: string;
  searchResultItems: SearchResultItem[];
}

export interface SearchDemoState {
  personalizationASearchResults: SearchResult;
  personalizationBSearchResults: SearchResult;
  relevanceASearchResults: SearchResult;
  relevanceBSearchResults: SearchResult;
  promotedASearchResults: SearchResult;
  promotedBSearchResults: SearchResult;
  searchResult: ProductSearchResult;
  augmentedSearchResult: ProductSearchResult;
}

interface GetPromotedASearchResults extends Action<typeof GET_PROMOTED_A_SEARCH_RESULT> {
  promotedASearchResults: SearchResult;
}

interface GetPromotedBSearchResults extends Action<typeof GET_PROMOTED_B_SEARCH_RESULT> {
  promotedBSearchResults: SearchResult;
}

interface GetRelevanceASearchResults extends Action<typeof GET_RELEVANCE_A_SEARCH_RESULT> {
  relevanceASearchResults: SearchResult;
}

interface GetRelevanceBSearchResults extends Action<typeof GET_RELEVANCE_B_SEARCH_RESULT> {
  relevanceBSearchResults: SearchResult;
}

interface GetPersonalizationASearchResults extends Action<typeof GET_PERSONALIZATION_A_SEARCH_RESULT> {
  personalizationASearchResults: SearchResult;
}

interface GetPersonalizationBSearchResults extends Action<typeof GET_PERSONALIZATION_B_SEARCH_RESULT> {
  personalizationBSearchResults: SearchResult;
}

interface GetSearchResults extends Action<typeof GET_SEARCH_RESULT> {
  searchResult: ProductSearchResult;
}

interface GetAugmentedSearchResults extends Action<typeof GET_AUGMENTED_SEARCH_RESULT> {
  searchResult: ProductSearchResult;
}

export type SearchDemoStateActionTypes =
  | GetSearchResults
  | GetAugmentedSearchResults
  | GetPromotedSearchResult
  | GetPromotedASearchResults
  | GetPromotedBSearchResults
  | GetRelevanceASearchResults
  | GetRelevanceBSearchResults
  | GetPersonalizationASearchResults
  | GetPersonalizationBSearchResults
  | Action<typeof RESET_SEARCH_RESULT>
  | Action<typeof LOADING_SEARCH_RESULT>;
