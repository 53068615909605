import { ProductViewerStyleProps, ProductViewerStyles } from './ProductViewer.types';

export const styles = (props: ProductViewerStyleProps): ProductViewerStyles => ({
  chevronIcon: [
    'mbc-nav-icon',
    {
      justifySelf: 'self-end',
      gridColumn: '4 / span 1',
      width: 21,
      height: 21,
      selectors: {
        '.ms-Icon': {
          fontSize: 14,
        },
      },
    },
  ],
  label: [
    'mbc-label',
    {
      fontSize: 12,
      fontWeight: 400,
      color: props.theme.palette.neutralPrimary,
    },
  ],
  value: [
    'mbc-value',
    {
      fontSize: 12,
      fontWeight: 400,
      color: props.theme.palette.neutralSecondaryAlt,
    },
  ],
  detailsRow: [
    'mbc-details-row',
    {
      display: 'grid',
      gridTemplateColumns: '5fr 10fr 1fr 1fr',
      padding: '11px 8px 11px 12px',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: props.theme.palette.neutralLighter,
    },
  ],
  subComponentStyles: {
    breadcrumb: {
      root: {
        marginTop: 0,
        marginLeft: 4,
        marginRight: 4,
      },
      listItem: {
        selectors: {
          ':last-child .ms-Breadcrumb-itemLink': {
            color: props.theme.palette.neutralSecondary,
          },
        },
      },
      itemLink: {
        fontSize: 16,
        color: props.theme.palette.themePrimary,
      },
    },
    detailsList: {
      root: {
        maxHeight: 300,
        minHeight: 200,
        borderTopWidth: 1,
        borderTopRadius: 2,
        borderTopStyle: 'solid',
        borderTopColor: props.theme.palette.neutralLighter,
      },
    },
  },
});
