import React, { useState } from 'react';
import { SectionProps, SectionStyleProps, SectionStyles } from './Section.types';
import { Stack, CommandBarButton, classNamesFunction } from 'office-ui-fabric-react';

const getClassNames = classNamesFunction<SectionStyleProps, SectionStyles>();

export const SectionBase = (props: SectionProps) => {
  const { styles, theme, className, t } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  return (
    <Stack
      tokens={{ childrenGap: '21px' }}
      className={classNames.root}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        <Stack horizontal horizontalAlign="space-between">
          <div className={classNames.title}>{props.title}</div>

          {props.hasEdit && isHovered && (
            <CommandBarButton
              key="Edit"
              iconProps={{ iconName: 'Edit' }}
              text={t('common.edit')}
              onClick={props.onEditEnable}
            />
          )}
        </Stack>
        {props.caption && <div className={classNames.caption}>{props.caption}</div>}
      </div>

      {props.onRenderContent()}
    </Stack>
  );
};
