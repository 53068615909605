import React, { useState } from 'react';
import { classNamesFunction, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { MetricCard } from '../Common/MetricCard';
import {
  SearchPerformanceMetricCardsProps,
  SearchPerformanceMetricCardsStyleProps,
  SearchPerformanceMetricCardsStyles,
} from './SearchPerformanceMetricCards.types';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { SetLatencyCardPercentile, SetSearchRequestsLatencyValue } from '../../../../store/actions/analyticsActions';
import { parseInt } from 'lodash';

const getClassNames = classNamesFunction<SearchPerformanceMetricCardsStyleProps, SearchPerformanceMetricCardsStyles>();

export const SearchPerformanceMetricCardsBase = (props: SearchPerformanceMetricCardsProps) => {
  const { styles, theme, className, componentRef, t } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  const totalSearchRequests = useSelector(state => state.analytics.totalSearchRequests);
  const isTotalSearchRequestsLoading = useSelector(state => state.analytics.isTotalSearchRequestsLoading);

  const searchRequestsLatencyValue = useSelector(state => state.analytics.searchRequestsLatencyValue);
  const isSearchRequestsLatencyValueLoading = useSelector(state => state.analytics.isSearchRequestsLatencyValueLoading);

  const searchAvgQPS = useSelector(state => state.analytics.searchAvgQPS);
  const isSearchAvgQPSLoading = useSelector(state => state.analytics.isSearchAvgQPSLoading);

  const regionsDistributionFilter = useSelector(state => state.analytics.regionsDistributionFilter);
  const responseCodesFilter = useSelector(state => state.analytics.responseCodesFilter);
  const searchInstancesFilter = useSelector(state => state.analytics.searchInstancesFilter);
  const searchResultsFilter = useSelector(state => state.analytics.searchResultsFilter);
  const startDate = useSelector(state => state.analytics.startDateFilter);
  const endDate = useSelector(state => state.analytics.endDateFilter);

  const [selectedKey, setSelectedKey] = useState('average');

  return (
    <div ref={componentRef} className={classNames.root}>
      <MetricCard
        styles={classNames.subComponentStyles.metricCard}
        title={t('analytics.search-metrics.total-calls')}
        value={totalSearchRequests}
        isLoading={isTotalSearchRequestsLoading}
        borderColor="#0078D4"
      />
      <MetricCard
        styles={classNames.subComponentStyles.metricCard}
        className={classNames.latencyCard}
        title={t('analytics.search-metrics.latency-value')}
        actions={
          <Dropdown
            disabled={isSearchRequestsLatencyValueLoading}
            styles={classNames.subComponentStyles.dropDownCallout}
            selectedKey={selectedKey}
            dropdownWidth={102}
            options={[
              { key: 'average', text: 'Average' },
              { key: '25', text: '25th percentile' },
              { key: '50', text: '50th percentile' },
              { key: '75', text: '75th percentile' },
              { key: '90', text: '90th percentile' },
              { key: '95', text: '95th percentile' },
            ]}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
              if (!option) return;

              if (selectedKey !== (option.key as string)) {
                setSelectedKey(option.key as string);
                if (option.key === 'average') {
                  dispatch(SetLatencyCardPercentile(NaN));
                  dispatch(
                    SetSearchRequestsLatencyValue(
                      responseCodesFilter.selectedValues.checkedValues,
                      startDate,
                      endDate,
                      [],
                      regionsDistributionFilter.selectedValues.checkedValues,
                      searchResultsFilter.selectedValues.checkedValues,
                      searchInstancesFilter.selectedValues.checkedValues,
                      NaN,
                    ),
                  );
                } else {
                  dispatch(SetLatencyCardPercentile(parseInt(option.key as string)));
                  dispatch(
                    SetSearchRequestsLatencyValue(
                      responseCodesFilter.selectedValues.checkedValues,
                      startDate,
                      endDate,
                      [],
                      regionsDistributionFilter.selectedValues.checkedValues,
                      searchResultsFilter.selectedValues.checkedValues,
                      searchInstancesFilter.selectedValues.checkedValues,
                      parseInt(option.key as string),
                    ),
                  );
                }
              }
            }}
          ></Dropdown>
        }
        value={searchRequestsLatencyValue}
        isLoading={isSearchRequestsLatencyValueLoading}
        borderColor="lightgrey"
      />
      <MetricCard
        styles={classNames.subComponentStyles.metricCard}
        title={t('analytics.search-metrics.average-qps')}
        value={searchAvgQPS.value}
        isLoading={isSearchAvgQPSLoading}
        borderColor="#008272"
        footer={t('analytics.search-metrics.average-qps-footer')}
      />
    </div>
  );
};
