import { BaseResponseInterceptor } from './BaseResponseInterceptor';
import { AdminServiceInterceptor } from './AdminServiceInterceptor';
import { IngestionServiceInterceptor } from './IngestionServiceInterceptor';
import { CustomizationServiceInterceptor } from './CustomizationServiceInterceptor';
import { SearchServiceInterceptor } from './SearchServiceInterceptor';
import { AnalyticsServiceInterceptor } from './AnalyticsServiceInterceptor';
import { RecommendationServiceInterceptor } from './RecommendationServiceInterceptor';
import { CustomMLServiceInterceptor } from './CustomMLServiceInterceptor';

export const ResponseInterceptors: BaseResponseInterceptor[] = [
  new AdminServiceInterceptor(),
  new IngestionServiceInterceptor(),
  new CustomizationServiceInterceptor(),
  new SearchServiceInterceptor(),
  new AnalyticsServiceInterceptor(),
  new RecommendationServiceInterceptor(),
  new CustomMLServiceInterceptor(),
];
