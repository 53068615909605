/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Condition,
  ConditionType,
  createConditionBlock,
  createNumericCondition,
  NumericCondition,
  createBoolCondition,
  BoolCondition,
  createStringCondition,
  StringCondition,
  StringSetCondition,
  createStringSetCondition,
  ConditionBlock,
  createDateTimeCondition,
  DateTimeCondition,
} from '../../../utils/customizations/conditions';
import _ from 'lodash';

export const mapConditionBlockDtoToModel = (conditionDto: any): ConditionBlock => {
  const nestedConditions: ConditionType[] | ConditionBlock[] = conditionDto.conditions.map((condition: any) =>
    mapConditionDtoToModel(condition),
  );
  return createConditionBlock({
    ...(conditionDto as ConditionBlock),
    conditions: nestedConditions,
  });
};

export const mapConditionDtoToModel = (conditionDto: any): ConditionType | ConditionBlock => {
  const conditionType = conditionDto._type;

  if (conditionDto.operator) {
    conditionDto.operator = (conditionDto.operator as string).toLowerCase();
  }
  let conditionModel: ConditionType | ConditionBlock;
  switch (conditionType) {
    case Condition.ConditionBlock:
      conditionModel = mapConditionBlockDtoToModel(conditionDto);
      break;
    case Condition.NumericCondition:
      conditionModel = createNumericCondition({ ...(conditionDto as NumericCondition) });
      break;
    case Condition.DateTimeCondition:
      conditionModel = createDateTimeCondition({ ...(conditionDto as DateTimeCondition) });
      break;

    case Condition.BoolCondition:
      conditionModel = createBoolCondition({ ...(conditionDto as BoolCondition) });
      break;
    case Condition.StringCondition:
      conditionModel = createStringCondition({ ...(conditionDto as StringCondition) });
      break;
    case Condition.StringSetCondition:
      conditionModel = createStringSetCondition({ ...(conditionDto as StringSetCondition) });
      break;
    default:
      conditionModel = createStringCondition({ ...(conditionDto as StringCondition) });
      break;
  }

  return conditionModel;
};

export const mapConditionBlockModelToDto = (conditionBlockModel: ConditionBlock) => {
  const conditionBlockDto = _.cloneDeep(conditionBlockModel);
  conditionBlockDto.conditions.map((condition: ConditionType | ConditionBlock) => delete condition['key']);
  delete conditionBlockDto['key'];
  return conditionBlockDto;
};
