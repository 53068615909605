import { useTranslation } from 'react-i18next';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  classNamesFunction,
  Breadcrumb,
  IBreadcrumbItem,
  DetailsList,
  SelectionMode,
  ConstrainMode,
  DetailsListLayoutMode,
  CheckboxVisibility,
  IColumn,
  TextField,
  IDetailsRowProps,
  Stack,
  IconButton,
  PrimaryButton,
  DefaultButton,
  mergeStyles,
  DatePicker,
  TooltipHost,
} from 'office-ui-fabric-react';
import {
  ProductEditorProps,
  ProductEditorStyles,
  ProductItemListProps,
  ProductEditorStyleProps,
} from './ProductEditor.types';
import {
  updateProductItemList,
  insertwildcardItemToProductItemList,
  removewildcardItemFromProductItemList,
  isInvalidProductItemList,
  isInvalidProductItem,
} from './ProductEditor.utils';
import { ProductItem, ROOT_PRODUCT_KEY, PreviewMode, PrimitiveType, DateSegment } from '../ProductPreview.types';
import { SearchIndexTypeConfig } from '../../../SchemaManagement/SchemaManagement.config';
import { getProductItemList } from '../ProductPreview.utils';
import {
  GridFirstRow,
  GridForthColumn,
  GridFirstColumn,
  GridThirdColumn,
  GridSecondColumn,
} from '../../../../../../components/common/Styles';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import { DateTimePicker } from '../../../../../../components/common/DateTimePicker';
import moment from 'moment';
import { ErrorBoundary } from '../../../../../../components/ErrorBoundary';

const getClassNames = classNamesFunction<ProductEditorStyleProps, ProductEditorStyles>();

const ProductItemList = (props: ProductItemListProps) => {
  const {
    styles,
    theme,
    previewMode,
    productItemList,
    onUpdateProductItem,
    onInsertProductItem,
    onRemoveProductItem,
    onNavigateToProductItem,
  } = props;

  const { t } = useTranslation();

  const classNames = getClassNames(styles, { theme: theme! });

  const onRenderDefaultRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.defaultRow}>
          <div
            className={
              props.item.type === 'ProductId' && previewMode === PreviewMode.New
                ? mergeStyles(classNames.label, classNames.required)
                : classNames.label
            }
          >
            {props.item.name}
          </div>
          <TextField
            className={classNames.textField}
            value={props.item.value as string}
            ariaLabel={t('schema-management.index-field-editor.value-aria-label')}
            placeholder={t('schema-management.index-field-editor.value-placeholder')}
            validateOnLoad={props.item.type === 'ProductId'}
            onGetErrorMessage={newValue =>
              props.item.type === 'ProductId' && previewMode === PreviewMode.New && !newValue
                ? t('common.required-text-field')
                : isInvalidProductItem(props.item.type, newValue)
                ? t('schema-management.index-field-editor.invalid-field-value', { type: props.item.type })
                : undefined
            }
            disabled={props.item.type === 'ProductId' && previewMode === PreviewMode.Edit}
            onChange={(ev: any, newValue?: string) => onUpdateProductItem({ ...props.item, value: newValue || '' })}
          />
          {props.item.isWildcardMatch && (
            <IconButton
              iconProps={{ iconName: 'Remove' }}
              onClick={() => onRemoveProductItem(props.item)}
              className={mergeStyles(classNames.action, GridForthColumn, GridFirstRow)}
            />
          )}
        </div>
      ) : null,
    [
      classNames.defaultRow,
      classNames.label,
      classNames.required,
      classNames.textField,
      classNames.action,
      previewMode,
      t,
      onUpdateProductItem,
      onRemoveProductItem,
    ],
  );

  const onRenderDatetimeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.defaultRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <DateTimePicker
            styles={classNames.subComponentStyles.datePicker}
            date={!!props.item.value ? new Date(props.item.value) : undefined}
            onDateChange={newValue =>
              onUpdateProductItem({
                ...props.item,
                value: moment(newValue, moment.ISO_8601, true).toISOString(),
              })
            }
          />
          {props.item.isWildcardMatch && (
            <IconButton
              iconProps={{ iconName: 'Remove' }}
              onClick={() => onRemoveProductItem(props.item)}
              className={mergeStyles(classNames.action, GridForthColumn, GridFirstRow)}
            />
          )}
        </div>
      ) : null,
    [
      classNames.defaultRow,
      classNames.label,
      classNames.subComponentStyles.datePicker,
      classNames.action,
      onUpdateProductItem,
      onRemoveProductItem,
    ],
  );

  const productColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'name',
        minWidth: 200,
      },
      {
        key: 'value',
        name: 'value',
        minWidth: 300,
      },
    ],
    [],
  );

  const onRenderGeoPointRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.defaultRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <TextField
            className={classNames.textField}
            value={props.item.value as string}
            ariaLabel={t('schema-management.index-field-editor.geo-point-aria-label')}
            placeholder={t('schema-management.index-field-editor.geo-point-placeholder')}
            validateOnLoad={false}
            onGetErrorMessage={newValue =>
              !!newValue && !SearchIndexTypeConfig[props.item.type].matches(newValue)
                ? t('schema-management.geo-point-invalid-value')
                : undefined
            }
            onChange={(ev: any, newValue?: string) => onUpdateProductItem({ ...props.item, value: newValue || '' })}
          />
          {props.item.isWildcardMatch && (
            <IconButton
              iconProps={{ iconName: 'Remove' }}
              onClick={() => onRemoveProductItem(props.item)}
              className={mergeStyles(classNames.action, GridForthColumn, GridFirstRow)}
            />
          )}
        </div>
      ) : null,
    [
      classNames.defaultRow,
      classNames.label,
      classNames.textField,
      classNames.action,
      t,
      onUpdateProductItem,
      onRemoveProductItem,
    ],
  );

  const onRenderWildcardRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.defaultRow} style={{ paddingLeft: 4 }}>
          <TextField
            value={props.item.placeholder}
            className={mergeStyles(classNames.wildcardLabel, classNames.textField)}
            ariaLabel={t('schema-management.index-field-editor.wildcard-aria-label')}
            placeholder={t('schema-management.index-field-editor.wildcard-label-placeholder')}
            onChange={(ev: any, newValue?: string) =>
              onUpdateProductItem({ ...props.item, placeholder: newValue || '' })
            }
          />
          <IconButton
            iconProps={{ iconName: 'Add' }}
            disabled={!props.item.placeholder}
            className={mergeStyles(classNames.action, GridForthColumn)}
            onClick={() => {
              onInsertProductItem({
                ...props.item,
                placeholder: '',
                isWildcard: false,
                isWildcardMatch: true,
                name: props.item.placeholder,
              });
              onUpdateProductItem({ ...props.item, placeholder: '' });
            }}
          />
        </div>
      ) : null,
    [
      classNames.defaultRow,
      classNames.wildcardLabel,
      classNames.textField,
      classNames.action,
      t,
      onUpdateProductItem,
      onInsertProductItem,
    ],
  );

  const onAddProductArrayItem = useCallback(
    (item: ProductItem) => {
      onUpdateProductItem({
        ...item,
        placeholder: '',
        value: ((item.value as PrimitiveType[]) || []).concat(item.placeholder as string),
      });
    },
    [onUpdateProductItem],
  );

  const onRemoveProductArrayItem = useCallback(
    (item: ProductItem, index: number) => {
      const updatedArray = ((item.value as PrimitiveType[]) || []).filter((item, itemIndex) => itemIndex !== index);
      onUpdateProductItem({ ...item, value: updatedArray.length === 0 ? null : updatedArray });
    },
    [onUpdateProductItem],
  );

  const onUpdateProductArrayItem = useCallback(
    (item: ProductItem, index: number, value: string) => {
      const updatedArray = [...(item.value as PrimitiveType[])];
      updatedArray[index] = value;
      onUpdateProductItem({ ...item, value: updatedArray });
    },
    [onUpdateProductItem],
  );

  const onAddProductDateSegmentItem = useCallback(
    (item: ProductItem) => {
      onUpdateProductItem({
        ...item,
        placeholder: '',
        value: ((item.value as DateSegment[]) || []).concat(item.placeholder as DateSegment),
      });
    },
    [onUpdateProductItem],
  );

  const onRemoveProductDateSegmentItem = useCallback(
    (item: ProductItem, index: number) => {
      const updatedDateSegmentArray = ((item.value as DateSegment[]) || []).filter(
        (item, itemIndex) => itemIndex !== index,
      );
      onUpdateProductItem({ ...item, value: updatedDateSegmentArray.length === 0 ? null : updatedDateSegmentArray });
    },
    [onUpdateProductItem],
  );

  const onUpdateProductStartDateSegmentItem = useCallback(
    (item: ProductItem, index: number, value: string) => {
      const updatedDateSegmentArray = [...(item.value as DateSegment[])];
      updatedDateSegmentArray[index] = { ...updatedDateSegmentArray[index], ge: value };
      onUpdateProductItem({ ...item, value: updatedDateSegmentArray });
    },
    [onUpdateProductItem],
  );

  const onUpdateProductEndDateSegmentItem = useCallback(
    (item: ProductItem, index: number, value: string) => {
      const updatedDateSegmentArray = [...(item.value as DateSegment[])];
      updatedDateSegmentArray[index] = { ...updatedDateSegmentArray[index], lt: value };
      onUpdateProductItem({ ...item, value: updatedDateSegmentArray });
    },
    [onUpdateProductItem],
  );

  const onRenderArrayTypeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.multilineRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <div className={classNames.multilineRowGrid}>
            {!!props.item.value &&
              (props.item.value as PrimitiveType[]).map((value, index) => (
                <>
                  <TextField
                    key={index}
                    value={value.toString()}
                    className={mergeStyles(GridFirstColumn, classNames.textField)}
                    ariaLabel={t('schema-management.index-field-editor.arr-value-aria-label')}
                    placeholder={t('schema-management.index-field-editor.arr-value-placeholder')}
                    onChange={(ev: any, newValue?: string) =>
                      onUpdateProductArrayItem(props.item, index, newValue || '')
                    }
                  />
                  <IconButton
                    key={index + '_remove'}
                    iconProps={{ iconName: 'Remove' }}
                    onClick={() => onRemoveProductArrayItem(props.item, index)}
                    className={mergeStyles(classNames.action, GridThirdColumn)}
                  />
                </>
              ))}
            <TextField
              value={props.item.placeholder}
              className={mergeStyles(GridFirstColumn, classNames.textField)}
              ariaLabel={t('schema-management.index-field-editor.arr-value-aria-label')}
              placeholder={t('schema-management.index-field-editor.arr-value-placeholder')}
              onChange={(ev: any, newValue?: string) =>
                onUpdateProductItem({ ...props.item, placeholder: newValue || '' })
              }
            />
            {props.item.isWildcardMatch && (
              <IconButton
                iconProps={{ iconName: 'Remove' }}
                onClick={() => onRemoveProductItem(props.item)}
                className={mergeStyles(classNames.action, GridSecondColumn)}
              />
            )}
            <IconButton
              iconProps={{ iconName: 'Add' }}
              disabled={props.item.placeholder === ''}
              onClick={() => onAddProductArrayItem(props.item)}
              className={mergeStyles(classNames.action, GridThirdColumn)}
            />
          </div>
        </div>
      ) : null,
    [
      classNames.multilineRow,
      classNames.label,
      classNames.multilineRowGrid,
      classNames.action,
      classNames.textField,
      t,
      onUpdateProductArrayItem,
      onRemoveProductArrayItem,
      onUpdateProductItem,
      onRemoveProductItem,
      onAddProductArrayItem,
    ],
  );

  const getDateSegmentView = useCallback(
    (value: DateSegment, item: ProductItem, index: number) => (
      <>
        <Stack horizontal tokens={{ childrenGap: 4 }}>
          <TooltipHost
            content={t('schema-management.index-field-editor.date-segment-greater')}
            hostClassName={classNames.label}
          >
            {t('schema-management.index-field-editor.date-segment-greater-abb')}
          </TooltipHost>
          <DatePicker
            value={moment(value.ge).toDate()}
            styles={classNames.subComponentStyles.datePicker}
            className={classNames.dateSegmentPicker}
            onSelectDate={newDate =>
              newDate && onUpdateProductStartDateSegmentItem(item, index, moment(newDate).format('YYYY-MM-DD'))
            }
            formatDate={(date?: Date) => (date ? moment(date).format('YYYY-MM-DD') : '')}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 4 }}>
          <TooltipHost
            content={t('schema-management.index-field-editor.date-segment-less-than')}
            hostClassName={classNames.label}
          >
            {t('schema-management.index-field-editor.date-segment-less-than-abb')}
          </TooltipHost>

          <DatePicker
            value={moment(value.lt).toDate()}
            styles={classNames.subComponentStyles.datePicker}
            className={classNames.dateSegmentPicker}
            onSelectDate={newDate =>
              newDate && onUpdateProductEndDateSegmentItem(item, index, moment(newDate).format('YYYY-MM-DD'))
            }
            formatDate={(date?: Date) => (date ? moment(date).format('YYYY-MM-DD') : '')}
          />
        </Stack>
      </>
    ),
    [
      classNames.dateSegmentPicker,
      classNames.label,
      classNames.subComponentStyles.datePicker,
      onUpdateProductEndDateSegmentItem,
      onUpdateProductStartDateSegmentItem,
      t,
    ],
  );

  const onRenderDateSegmentTypeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.multilineRow}>
          <div className={classNames.label}>{props.item.name}</div>
          <div className={classNames.dateSegmentMultiLineGrid}>
            {!!props.item.value &&
              (props.item.value as DateSegment[]).map((value, index) => (
                <>
                  {getDateSegmentView(value, props.item, index)}
                  <IconButton
                    key={index + '_remove'}
                    iconProps={{ iconName: 'Remove' }}
                    onClick={() => onRemoveProductDateSegmentItem(props.item, index)}
                    className={mergeStyles(classNames.action, GridThirdColumn)}
                  />
                </>
              ))}
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <TooltipHost
                content={t('schema-management.index-field-editor.date-segment-greater')}
                hostClassName={classNames.label}
              >
                {t('schema-management.index-field-editor.date-segment-greater-abb')}
              </TooltipHost>
              <DatePicker
                value={props.item.placeholder.ge ? moment(props.item.placeholder.ge).toDate() : undefined}
                styles={classNames.subComponentStyles.datePicker}
                className={classNames.dateSegmentPicker}
                onSelectDate={newDate =>
                  newDate &&
                  onUpdateProductItem({
                    ...props.item,
                    placeholder: { ...props.item.placeholder, ge: moment(newDate).format('YYYY-MM-DD') },
                  })
                }
                formatDate={(date?: Date) => (date ? moment(date).format('YYYY-MM-DD') : '')}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <TooltipHost
                content={t('schema-management.index-field-editor.date-segment-less-than')}
                hostClassName={classNames.label}
              >
                {t('schema-management.index-field-editor.date-segment-less-than-abb')}
              </TooltipHost>
              <DatePicker
                value={props.item.placeholder.lt ? moment(props.item.placeholder.lt).toDate() : undefined}
                styles={classNames.subComponentStyles.datePicker}
                className={classNames.dateSegmentPicker}
                onSelectDate={newDate =>
                  newDate &&
                  onUpdateProductItem({
                    ...props.item,
                    placeholder: { ...props.item.placeholder, lt: moment(newDate).format('YYYY-MM-DD') },
                  })
                }
                formatDate={(date?: Date) => (date ? moment(date).format('YYYY-MM-DD') : '')}
              />
            </Stack>
            <IconButton
              iconProps={{ iconName: 'Add' }}
              disabled={!props.item.placeholder.ge || !props.item.placeholder.lt}
              onClick={() => onAddProductDateSegmentItem(props.item)}
              className={mergeStyles(classNames.action, GridThirdColumn)}
            />
          </div>
        </div>
      ) : null,
    [
      classNames.multilineRow,
      classNames.label,
      classNames.dateSegmentMultiLineGrid,
      classNames.subComponentStyles.datePicker,
      classNames.dateSegmentPicker,
      classNames.action,
      t,
      getDateSegmentView,
      onRemoveProductDateSegmentItem,
      onUpdateProductItem,
      onAddProductDateSegmentItem,
    ],
  );

  const onRenderObjectTypeRow = useCallback(
    (props?: IDetailsRowProps) =>
      props ? (
        <div className={classNames.defaultRow}>
          <div className={classNames.label}>{props.item.name}</div>
          {props.item.isWildcardMatch && (
            <IconButton
              iconProps={{ iconName: 'Remove' }}
              className={mergeStyles(classNames.action, GridThirdColumn)}
              onClick={() => onRemoveProductItem(props.item)}
            />
          )}
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            className={mergeStyles(classNames.action, GridForthColumn)}
            onClick={() => onNavigateToProductItem(props.item)}
          />
        </div>
      ) : null,
    [classNames.defaultRow, classNames.label, classNames.action, onNavigateToProductItem, onRemoveProductItem],
  );

  const ProductDetailsRow = useCallback(
    (props: IDetailsRowProps) => {
      if (props.item.isWildcard) {
        return onRenderWildcardRow(props);
      }

      if (!!SearchIndexTypeConfig[props.item.type] && SearchIndexTypeConfig[props.item.type].dataType === 'DateTime') {
        return onRenderDatetimeRow(props);
      }

      if (!!SearchIndexTypeConfig[props.item.type] && SearchIndexTypeConfig[props.item.type].dataType === 'Object') {
        return onRenderObjectTypeRow(props);
      }

      if (!!SearchIndexTypeConfig[props.item.type] && SearchIndexTypeConfig[props.item.type].dataType === 'Array') {
        return onRenderArrayTypeRow(props);
      }

      if (!!SearchIndexTypeConfig[props.item.type] && SearchIndexTypeConfig[props.item.type].dataType === 'GeoPoint') {
        return onRenderGeoPointRow(props);
      }

      if (
        !!SearchIndexTypeConfig[props.item.type] &&
        SearchIndexTypeConfig[props.item.type].dataType === 'DateSegment'
      ) {
        return onRenderDateSegmentTypeRow(props);
      }

      return onRenderDefaultRow(props);
    },
    [
      onRenderDefaultRow,
      onRenderWildcardRow,
      onRenderDatetimeRow,
      onRenderObjectTypeRow,
      onRenderArrayTypeRow,
      onRenderGeoPointRow,
      onRenderDateSegmentTypeRow,
    ],
  );

  const onRenderRowErrorHandler = useCallback(
    (props: IDetailsRowProps) => (
      <div className={classNames.defaultRow}>
        <div className={classNames.label}>{props.item.name}</div>
        <div className={classNames.errorMessage}>{t('schema-management.index-field-editor.field-type-error')}</div>
      </div>
    ),
    [classNames.defaultRow, classNames.errorMessage, classNames.label, t],
  );

  const onRenderRowSafely = useCallback(
    (props?: IDetailsRowProps) => {
      if (!props) {
        return null;
      }
      return (
        <ErrorBoundary component="Row Edit" onRenderError={onRenderRowErrorHandler(props)}>
          <ProductDetailsRow key={props.item.key} {...props} />
        </ErrorBoundary>
      );
    },
    [onRenderRowErrorHandler],
  );

  return (
    <DetailsList
      key={'ProductItemList'}
      items={productItemList}
      isHeaderVisible={false}
      columns={productColumns}
      onRenderRow={onRenderRowSafely}
      onShouldVirtualize={() => false}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
      checkboxVisibility={CheckboxVisibility.hidden}
      constrainMode={ConstrainMode.horizontalConstrained}
      styles={classNames.subComponentStyles.detailsList}
    />
  );
};

export const ProductEditorBase = (props: ProductEditorProps) => {
  const { styles, theme, previewMode, productItemList, onSaveEditChanges, onCancelEditChanges } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const { t } = useTranslation();

  const [updatedProductItemList, setUpdatedProductItemList] = useState<ProductItem[]>(_.cloneDeep(productItemList));

  const [activeProductItemKey, setActiveProductItemKey] = useState<string>(ROOT_PRODUCT_KEY);

  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>(() => [
    {
      key: ROOT_PRODUCT_KEY,
      text: 'Product',
      onClick: () => {
        setBreadcrumbItems(items => items.slice(0, 1));
        setActiveProductItemKey(ROOT_PRODUCT_KEY);
      },
    },
  ]);

  const [isProductUpdated, setIsProductUpdated] = useState<boolean>(false);

  useEffect(() => {
    const updated = !_.isEqual(updatedProductItemList, productItemList);
    if (updated !== isProductUpdated) {
      setIsProductUpdated(updated);
    }

    updated ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isProductUpdated, productItemList, updatedProductItemList]);

  const onBreadcrumbItemClick = useCallback((productItemKey: string) => {
    setBreadcrumbItems(items => {
      const index = items.findIndex(item => item.key === productItemKey);
      return items.slice(0, index + 1);
    });

    setActiveProductItemKey(productItemKey);
  }, []);

  const onNavigateToProductItem = useCallback(
    (productItem: ProductItem) => {
      setActiveProductItemKey(productItem.key);
      setBreadcrumbItems(items =>
        items.concat({
          text: productItem.name,
          key: productItem.key,
          onClick: () => onBreadcrumbItemClick(productItem.key),
        }),
      );
    },
    [onBreadcrumbItemClick],
  );

  const onUpdateProductItem = useCallback((productItem: ProductItem) => {
    setUpdatedProductItemList(
      productItemList => updateProductItemList(productItemList, productItem) || productItemList,
    );
  }, []);

  const onInsertProductItem = useCallback(
    (productItem: ProductItem) =>
      setUpdatedProductItemList(
        productItemList =>
          insertwildcardItemToProductItemList(productItemList, activeProductItemKey, productItem) || productItemList,
      ),
    [activeProductItemKey],
  );

  const onRemoveProductItem = useCallback(
    (productItem: ProductItem) =>
      setUpdatedProductItemList(
        productItemList =>
          removewildcardItemFromProductItemList(productItemList, activeProductItemKey, productItem) || productItemList,
      ),
    [activeProductItemKey],
  );

  const activeProductItemList = useMemo(() => {
    if (activeProductItemKey === ROOT_PRODUCT_KEY) {
      return updatedProductItemList;
    }

    return getProductItemList(updatedProductItemList, activeProductItemKey) || [];
  }, [activeProductItemKey, updatedProductItemList]);

  const isProductIdValueSet = useCallback(() => {
    const productIdIndex = updatedProductItemList.findIndex(item => item.type === 'ProductId');
    return productIdIndex !== -1 && !!updatedProductItemList[productIdIndex].value;
  }, [updatedProductItemList]);

  const isInvalidUpdatedProduct = useMemo(() => isInvalidProductItemList(updatedProductItemList), [
    updatedProductItemList,
  ]);

  const disableUpdateProduct = useMemo(
    () =>
      isInvalidUpdatedProduct ||
      (previewMode === PreviewMode.Edit && !isProductUpdated) ||
      (previewMode === PreviewMode.New && !isProductIdValueSet()),
    [isInvalidUpdatedProduct, isProductIdValueSet, isProductUpdated, previewMode],
  );

  return (
    <Stack tokens={{ padding: 12, childrenGap: 8 }}>
      <Breadcrumb
        maxDisplayedItems={3}
        items={breadcrumbItems}
        styles={classNames.subComponentStyles.breadcrumb}
        ariaLabel={t('product-management.breadcrumb-aria-label')}
        overflowAriaLabel={t('product-management.breadcrumb-overflow-aria-label')}
      />
      <ErrorBoundary component="Product Edit" onRenderError={<div>Failed to view product details</div>}>
        <ProductItemList
          {...props}
          productItemList={activeProductItemList}
          onInsertProductItem={onInsertProductItem}
          onRemoveProductItem={onRemoveProductItem}
          onUpdateProductItem={onUpdateProductItem}
          onNavigateToProductItem={onNavigateToProductItem}
        />
        <Stack
          horizontal
          verticalAlign={'center'}
          horizontalAlign={'start'}
          style={{ justifySelf: 'end' }}
          tokens={{ childrenGap: 16, padding: 12 }}
        >
          <PrimaryButton disabled={disableUpdateProduct} onClick={() => onSaveEditChanges(updatedProductItemList)}>
            {t('common.save')}
          </PrimaryButton>
          <DefaultButton onClick={onCancelEditChanges}> {t('common.cancel')}</DefaultButton>
        </Stack>
        <Prompt when={isProductUpdated} message={t('common.on-before-unload-msg')} />
      </ErrorBoundary>
    </Stack>
  );
};
