import { DetailsListCardStyleProps, DetailsListCardStyles } from './DetailsListCard.types';
import { getFlexColumnStyles } from '../Styles';

export const styles = (props: DetailsListCardStyleProps): DetailsListCardStyles => {
  return {
    root: ['mbc-details-list-card', getFlexColumnStyles(), { height: '100%' }, props.className],
    subComponentStyles: {
      list: {},
      card: {},
      body: {},
      footer: {},
    },
  };
};
