import { DetailsListCardHeaderStyleProps, DetailsListCardHeaderStyles } from './DetailsListCardHeader.types';

export const styles = (props: DetailsListCardHeaderStyleProps): DetailsListCardHeaderStyles => ({
  root: [
    'mbc-card-header',
    {
      display: 'grid',
      gridRowGap: '16px',
    },
  ],
  title: [
    'mbc-card-title',
    {
      fontSize: 20,
      lineHeight: 28,
      fontWeight: 400,
      marginRight: 4,
    },
  ],
  subTitle: [
    'mbc-card-subtitle',
    {
      fontSize: 12,
      color: props.theme.palette.neutralSecondary,
    },
  ],
  actionsContainer: [
    'mbc-card-actions-container',
    {
      display: 'flex',
      selectors: {
        '@media only screen and (min-width: 870px)': {
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '@media only screen and (max-width: 870px)': {
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    },
  ],
  actions: [
    'mbc-card-actions',
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: 10,
      marginLeft: 'auto',
      selectors: {
        '.is-disabled': {
          selectors: {
            '.ms-Button-icon': {
              color: props.theme.palette.neutralTertiaryAlt,
            },
            '.ms-Button-textContainer': {
              color: props.theme.palette.neutralTertiaryAlt,
            },
          },
        },
        '.ms-Button': {
          marginLeft: 16,
          backgroundColor: 'unset',
          height: 32,
          selectors: {
            ':hover': {
              backgroundColor: props.theme.palette.neutralLighter,
            },
          },
        },
        '.ms-Button-icon': {
          color: props.theme.palette.themePrimary,
          selectors: {
            ':hover': {
              color: props.theme.palette.themePrimary + '!important',
            },
          },
        },
        '.ms-Button-textContainer': {
          color: props.theme.palette.black,
        },
        '@media only screen and (max-width: 870px)': {
          marginTop: 16,
        },
      },
    },
  ],
  searchBox: [
    'mbc-search-box',
    {
      width: 300,
    },
  ],
});
