import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateAllPerformanceCharts, UpdateAllTrafficCharts } from '../../actions/analyticsActions';
import { getAggregationInterval } from '../../../pages/ProductSearch/SearchAnalytics/SearchAnalytics.utils';
import { useSelector } from '..';
import { AnalyticsTabs } from '../../../pages/ProductSearch/SearchAnalytics';

export const useAnalyticsFilters = (activeTab: string) => {
  const dispatch = useDispatch();

  // Needed not to cause requests to be sent multiple times when page mounts for first time
  let regionsMounted = useRef<boolean>(false);
  let responseCodesMounted = useRef<boolean>(false);
  let searchInstancesMounted = useRef<boolean>(false);
  let searchResultsMounted = useRef<boolean>(false);

  const regionsDistributionFilter = useSelector(state => state.analytics.regionsDistributionFilter);
  const responseCodesFilter = useSelector(state => state.analytics.responseCodesFilter);
  const searchInstancesFilter = useSelector(state => state.analytics.searchInstancesFilter);
  const searchResultsFilter = useSelector(state => state.analytics.searchResultsFilter);
  const startDate = useSelector(state => state.analytics.startDateFilter);
  const endDate = useSelector(state => state.analytics.endDateFilter);
  const searchLatencyPercentile = useSelector(state => state.analytics.searchLatencyPercentile);
  const latencyCardPercentile = useSelector(state => state.analytics.latencyCardPercentile);

  useEffect(() => {
    if (regionsMounted.current) {
      if (
        regionsDistributionFilter.selectedValues.checkedValues.length ||
        regionsDistributionFilter.selectedValues.selectAll
      ) {
        let aggInterval = getAggregationInterval(startDate, endDate);
        if (activeTab === AnalyticsTabs.Traffic) {
          dispatch(
            UpdateAllTrafficCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
            ),
          );
        } else {
          dispatch(
            UpdateAllPerformanceCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
              searchLatencyPercentile,
              latencyCardPercentile,
            ),
          );
        }
      }
    } else {
      regionsMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, regionsDistributionFilter.selectedValues]);

  useEffect(() => {
    if (responseCodesMounted.current) {
      if (responseCodesFilter.selectedValues.checkedValues.length || responseCodesFilter.selectedValues.selectAll) {
        let aggInterval = getAggregationInterval(startDate, endDate);
        if (activeTab === AnalyticsTabs.Traffic) {
          dispatch(
            UpdateAllTrafficCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
            ),
          );
        } else {
          dispatch(
            UpdateAllPerformanceCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
              searchLatencyPercentile,
              latencyCardPercentile,
            ),
          );
        }
      }
    } else {
      responseCodesMounted.current = true;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, responseCodesFilter.selectedValues]);

  useEffect(() => {
    if (searchInstancesMounted.current) {
      if (searchInstancesFilter.selectedValues.checkedValues.length || searchInstancesFilter.selectedValues.selectAll) {
        let aggInterval = getAggregationInterval(startDate, endDate);
        if (activeTab === AnalyticsTabs.Traffic) {
          dispatch(
            UpdateAllTrafficCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
            ),
          );
        } else {
          dispatch(
            UpdateAllPerformanceCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
              searchLatencyPercentile,
              latencyCardPercentile,
            ),
          );
        }
      }
    } else {
      searchInstancesMounted.current = true;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchInstancesFilter.selectedValues]);

  useEffect(() => {
    if (searchResultsMounted.current) {
      if (searchResultsFilter.selectedValues.checkedValues.length || searchResultsFilter.selectedValues.selectAll) {
        let aggInterval = getAggregationInterval(startDate, endDate);
        if (activeTab === AnalyticsTabs.Traffic) {
          dispatch(
            UpdateAllTrafficCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
            ),
          );
        } else {
          dispatch(
            UpdateAllPerformanceCharts(
              responseCodesFilter.selectedValues.checkedValues,
              startDate,
              endDate,
              [], // Markets
              regionsDistributionFilter.selectedValues.checkedValues,
              searchResultsFilter.selectedValues.checkedValues,
              searchInstancesFilter.selectedValues.checkedValues,
              aggInterval,
              searchLatencyPercentile,
              latencyCardPercentile,
            ),
          );
        }
      }
    } else {
      searchResultsMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchResultsFilter.selectedValues]);
};
