import React, { Fragment } from 'react';
import { isArray } from '../../utils';
import { AppRuntimeEnvironment } from './utils';

export enum Environment {
  // Defines existing Environments
  // Changes to .env.* files should be reflected here!
  Production = 'production',
  Staging = 'staging',
}

interface EnvironmentGuardProps {
  targetEnvironment: Environment | Environment[];
  onRenderChildren: () => JSX.Element;
}

export class EnvironmentGuard extends React.Component<EnvironmentGuardProps> {
  render() {
    // THIS TO ENFORCE LOCAL BUILD TO SIMULATE PRODUCTION ENVIRONMENT
    let targetEnvironments: Environment[] = isArray(this.props.targetEnvironment)
      ? this.props.targetEnvironment
      : [this.props.targetEnvironment];
    return (
      <Fragment>
        {targetEnvironments.some(e => e.toLocaleLowerCase() === AppRuntimeEnvironment())
          ? this.props.onRenderChildren()
          : null}
      </Fragment>
    );
  }
}
