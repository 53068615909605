import { CookDatasetEditorStyles, CookDatasetEditorStyleProps } from './CookDatasetEditor.types';

export const styles = (props: CookDatasetEditorStyleProps): CookDatasetEditorStyles => ({
  item: [
    'mbc-dataset-name',
    {
      fontSize: 12,
      lineHeight: 19,
      fontWeight: 600,
      textDecoration: 'none',
      color: props.theme.palette.themePrimary,
    },
  ],
  subTitle: [
    'mbc-editor-subtitle',
    {
      fontSize: 12,
      lineHeight: 16,
      maxWidth: 976,
    },
  ],
  dropdown: [
    'mbc-environment-dropdown',
    {
      width: 250,
      selectors: {
        '.ms-Dropdown-title': {
          border: '1px solid rgba(0, 0, 0, 0.1)',
        },
      },
    },
  ],
  overflow: [
    'mbc-overflow-cell',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  subComponentStyles: {
    callout: {
      calloutMain: {
        maxHeight: '250px !important',
      },
    },
    card: {
      root: [
        'mbc-cook-editor',
        {
          selectors: {
            '.ms-Button': {
              marginLeft: 16,
            },
            '.ms-Button--default': {
              color: props.theme.palette.blue,
            },
          },
        },
      ],
    },
  },
});
