import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { Action } from 'redux';
import {
  LOADING_DEPLOYMENT_JOB_LIST,
  SET_DEPLOYMENT_JOB_LIST,
  RESET_DEPLOYMENT_JOB_LIST,
  DeploymentJob,
  UPDATE_DEPLOYMENT_JOB,
  UPDATE_DEPLOYMENT_JOB_LIST,
} from '../../types/customML/deploymentJob.d';
import { listDeploymentJobs, getDeploymentJob } from '../../../restful-apis/customML.api';
import { ExecuteReduxCrudAction, ActionFailureHandler, ActionSuccessHandler } from '../utils';
import { createDeploymentJobAction } from '../utils/crud-actions/deployment-job/DeploymentJobCrudActions';

export const deploySearchModel = (
  tenantId: string,
  searchModelKey: string,
  deploymentJob: DeploymentJob,
  onSuccess?: ActionSuccessHandler<DeploymentJob>,
  onFailure?: ActionFailureHandler<DeploymentJob>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(createDeploymentJobAction(tenantId, searchModelKey, deploymentJob, onSuccess, onFailure));

export const updateDeploymentJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    listDeploymentJobs(tenantId, undefined, true).then(deploymentJobList => {
      dispatch({
        type: UPDATE_DEPLOYMENT_JOB_LIST,
        deploymentJobList: deploymentJobList,
      });
    });
  };
};

export const setDeploymentJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_DEPLOYMENT_JOB_LIST,
    });

    listDeploymentJobs(tenantId).then(deploymentJobList => {
      dispatch({
        type: SET_DEPLOYMENT_JOB_LIST,
        deploymentJobList: deploymentJobList,
      });
    });
  };
};

export const updateDeploymentJob = (key: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;

    getDeploymentJob(tenantId, key).then(deploymentJob => {
      dispatch({
        type: UPDATE_DEPLOYMENT_JOB,
        deploymentJob: deploymentJob,
      });
    });
  };
};

export const resetDeploymentJobList = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: RESET_DEPLOYMENT_JOB_LIST,
    });
  };
};
