import { classNamesFunction } from 'office-ui-fabric-react';

import { SearchItemCardStyles, SearchItemCardStyleProps } from './SearchItemCard.types';

export const styles = (): SearchItemCardStyles => {
  return {
    root: {
      width: 240,
      height: 259,
      padding: 16,
      boxShadow: '0px 1.2px 3.6px rgba(112, 118, 138, 0.108), 0px 6.4px 14.4px rgba(112, 118, 138, 0.132)',
      borderRadius: 4,
      background: '#fff',
    },
    detailsContainer: {
      flex: '1 0 0',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      fontSize: 9,
      marginTop: 24,
    },
    priceContainer: { marginLeft: 5 },
    superscriptPriceText: { fontSize: 8, fontWeight: 'bold' },
    currencyText: { marginLeft: '3px' },
    priceText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 18,
      fontWeight: 600,
      color: '#4E4E4E',
      minWidth: 60,
    },
    highlight: { color: '#d80e0e' },
    descriptionContainer: { maxWidth: 175 },
    name: { fontSize: 14, lineHeight: 16 },
    description: { marginTop: 8, fontSize: 13, lineHeight: 15, color: '#666666' },
    integerText: { margin: '0 3px 0 0' },
    onSaleText: { fontFamily: 'Helvetica', fontSize: 13, lineHeight: 15, paddingTop: 11 },
    subComponentStyles: {
      imageStyles: {
        root: {
          flex: '0 1 auto',
          width: 208,
          height: 146,
          margin: '0 auto',
        },
      },
    },
  };
};

export const getClassNames = classNamesFunction<SearchItemCardStyleProps, SearchItemCardStyles>();
