import { FacetsStyleProps, FacetsStyles } from './Facets.types';

export const styles = (props: FacetsStyleProps): FacetsStyles => ({
  root: [
    'mbc-facets-preview',
    {
      borderRight: '1px solid #EDEBE9',
      maxWidth: 200,
      minWidth: 200,
      padding: 24,
    },
    props.className,
  ],
  colorGroup: [
    'mbc-color-group',
    {
      display: 'grid',
      gridTemplateColumns: '34px 34px 34px',
      gridColumnGap: '32px',
      gridRowGap: '16px',
    },
  ],
  color: [
    'mbc-color-facet',
    {
      width: 32,
      height: 32,
      cursor: 'pointer',
      border: '1px solid #e4e4e4',
      borderRadius: '50%',
    },
  ],
  selectedColor: [
    'mbc-selected-color',
    {
      width: 32,
      height: 32,
      display: 'flex',
      cursor: 'pointer',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      border: '1px solid black',
    },
  ],
  titleTooltip: [
    'mbc-facet-title-tooltip',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      marginBottom: 20,
    },
  ],
  title: [
    'mbc-facet-title',
    {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: 500,
      color: '#292827',
      display: 'inline',
    },
  ],
  subtitle: [
    'mbc-subtitle',
    {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: 500,
      color: '#292827',
    },
  ],
  option: {},
  action: [
    'mbc-facet-action',
    {
      fontSize: 12,
      lineHeight: 16,
      cursor: 'pointer',
      color: props.theme.palette.themePrimary,
    },
  ],
  overflow: [
    'mbc-overflow-cell',
    {
      maxWidth: 132,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  categoryFacetIcon: ['mbc-category-facet-icon', { color: '#323130', fontSize: 12, marginRight: 6, cursor: 'pointer' }],
  categoryFacetLabel: [
    'mbc-category-facet-label',
    { fontSize: 14, fontWeight: 400, lineHeight: 20, whiteSpace: 'nowrap', cursor: 'pointer', display: 'inline' },
  ],
  selectedCategoryFacet: ['mbc-category-facet-label', { color: props.theme.palette.themePrimary, fontWeight: 500 }],
});
