import { BreadcrumbStyles, BreadcrumbStyleProps } from './Breadcrumb.types';

export const styles = (props: BreadcrumbStyleProps): BreadcrumbStyles => {
  const { className } = props;
  return {
    root: ['mbc-Breadcrumb', {}, className],
    item: [
      'mbc-Breadcrumb-item',
      {
        fontSize: 14,
        lineHeight: 20,
        textDecoration: 'none',
        color: '#605E5C',
      },
    ],
    chevron: [
      'mbc-Breadcrumb-chevron',
      {
        marginLeft: 8,
        marginRight: 8,
        color: '#605E5C',
        fontSize: 12,
      },
    ],
    listItem: {
      selectors: {
        ':last-child .mbc-Breadcrumb-item ': {
          fontWeight: '600',
          color: '#323130',
        },
      },
    },
  };
};
