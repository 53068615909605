import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  CheckboxVisibility,
  classNamesFunction,
  IDetailsRowProps,
  ConstrainMode,
  CommandButton,
  Stack,
  IColumn,
  SelectionMode,
  TooltipHost,
  TooltipOverflowMode,
  IDetailsHeaderProps,
  Checkbox,
  ColumnActionsMode,
  StickyPositionType,
  Sticky,
  ScrollablePane,
  ScrollbarVisibility,
} from 'office-ui-fabric-react';
import React, { ReactNode, useState, useEffect, useCallback, useMemo } from 'react';
import { generateUniqueIdentifier, ListItemsOrder } from '../../../utils';
import { DetailsSummary } from './DetailsSummary/DetailsSummary';
import { getItemsWithSummary } from './DetailsList.utils';
import {
  ListItem,
  OptionsItemProps,
  DetailsListProps,
  DetailsListStyles,
  DetailsListStyleProps,
  ColumnPriority,
  SortDataType,
  ColumnWithPriority,
  NoItemsToDisplayProps,
  OrderByDetails,
} from './DetailsList.types';
import { useMediaQuery } from 'react-responsive';
import { NoItemsToDisplay } from '../NoItemsToDisplay';

const getClassNames = classNamesFunction<DetailsListStyleProps, DetailsListStyles>();

export const DetailsListBase = (props: DetailsListProps): JSX.Element => {
  const {
    items,
    theme,
    styles,
    responsiveColumns,
    className,
    groups,
    isLoading,
    isLoadedAndEmpty,
    groupProps,
    componentRef,
    summaryProps,
    selectionProps,
    optionsItemProps,
    isHeaderVisible = true,
    onRenderNoItemsToDisplay,
  } = props;

  const getInitalSortDetails = useCallback((): OrderByDetails => {
    if (!!responsiveColumns.initialSortingDetails) {
      return responsiveColumns.initialSortingDetails;
    }
    let sortableColumns = responsiveColumns.columns.filter(column => !!column.sortDataType);
    if (sortableColumns.length) {
      return {
        fieldName: sortableColumns[0].fieldName,
        dataType: sortableColumns[0].sortDataType!,
        orderBy: undefined,
      };
    } else {
      return {
        fieldName: '',
        dataType: SortDataType.numeric,
        orderBy: undefined,
      };
    }
  }, [responsiveColumns.columns, responsiveColumns.initialSortingDetails]);

  const [keyWithSummary, setKeyWithSummary] = useState('');
  const [listItemsOrder, setListItemsOrder] = useState<OrderByDetails>(getInitalSortDetails());
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isDefaultSelectionSet, setIsDefaultSelectionSet] = useState(false);

  const isAllItemsSelected = !!selectionProps && !!selectionProps.isAllSelected;
  const columnKeyWithChevron =
    summaryProps && !!!groups && responsiveColumns.columns.length > 0 && !responsiveColumns.columns[0].onRender
      ? responsiveColumns.columns[0].key
      : '';
  const checkboxVisibility = !!selectionProps ? selectionProps.checkboxVisibility : CheckboxVisibility.hidden;
  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
    enableSummary: !!columnKeyWithChevron,
  });

  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1700px)',
  });

  const isXLargeScreen = useMediaQuery({ query: '(min-width: 2050px)' });

  const hideSecondaryColumns = useMediaQuery({
    query: '(max-width: ' + responsiveColumns.secondaryBreakPoint + 'px)',
  });

  const hideTeritaryColumns = useMediaQuery({
    query: '(max-width: ' + responsiveColumns.teritaryBreakPoint + 'px)',
  });

  useEffect(() => {
    if (
      !!selectionProps &&
      !isDefaultSelectionSet &&
      selectionProps.defaultSelected &&
      selectionProps.defaultSelected.length
    ) {
      setIsDefaultSelectionSet(true);
      setSelectedKeys(selectionProps.defaultSelected.map(item => item.key));
    }
  }, [isDefaultSelectionSet, selectionProps]);

  let itemsToDisplay = items;
  if (summaryProps) {
    itemsToDisplay = getItemsWithSummary(itemsToDisplay, keyWithSummary);
  }

  useEffect(() => {
    setIsAllSelected(isAllItemsSelected);
    if (isAllItemsSelected) {
      setSelectedKeys(itemsToDisplay.map(item => item.key));
      selectionProps && selectionProps.onSelectionChanged(itemsToDisplay.map(item => item.key));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllItemsSelected]);

  const hasSubfield = useMemo(() => {
    return responsiveColumns.columns.some(column => !!column.subTextField);
  }, [responsiveColumns.columns]);

  const sortItems = useCallback(
    (filteredItems: any[]) => {
      if (!listItemsOrder.orderBy && listItemsOrder.orderBy !== ListItemsOrder.ASCENDING) {
        return filteredItems;
      }
      return filteredItems.sort((a, b) =>
        listItemsOrder.orderBy === ListItemsOrder.ASCENDING
          ? listItemsOrder.dataType === SortDataType.dateTime
            ? new Date(a[listItemsOrder.fieldName]).getTime() - new Date(b[listItemsOrder.fieldName]).getTime()
            : listItemsOrder.dataType === SortDataType.text
            ? (a[listItemsOrder.fieldName] as string).toLowerCase() >
              (b[listItemsOrder.fieldName] as string).toLowerCase()
              ? 1
              : -1
            : a - b
          : listItemsOrder.dataType === SortDataType.dateTime
          ? new Date(b[listItemsOrder.fieldName]).getTime() - new Date(a[listItemsOrder.fieldName]).getTime()
          : listItemsOrder.dataType === SortDataType.text
          ? (b[listItemsOrder.fieldName] as string).toLowerCase() >
            (a[listItemsOrder.fieldName] as string).toLowerCase()
            ? 1
            : -1
          : b - a,
      );
    },
    [listItemsOrder.dataType, listItemsOrder.fieldName, listItemsOrder.orderBy],
  );

  const getItems = useCallback((): any[] => {
    let sortedItems: any[] = [...itemsToDisplay];

    if (listItemsOrder.fieldName !== '') {
      sortedItems = sortItems(sortedItems);
    }

    return sortedItems;
  }, [itemsToDisplay, listItemsOrder.fieldName, sortItems]);

  const onRenderDetailsOptions = (optionsItems: OptionsItemProps[]): JSX.Element => (
    <div className={classNames.optionsItem}>
      <CommandButton
        ariaLabel={'morebutton'}
        disabled={optionsItems.length === 0}
        iconProps={{ iconName: 'MoreVertical' }}
        menuProps={{
          styles: classNames.subComponentStyles.optionsCallout,
          items: optionsItems,
        }}
      />
    </div>
  );

  const onRenderDetailsChevronItem = (item: ListItem, onRenderColumnItem: ReactNode): JSX.Element => {
    const isExpanded = item.key === keyWithSummary;
    const expandedLabel = summaryProps!.expandedLabel;
    const collapsedLabel = summaryProps!.collapsedLabel;
    return (
      <Stack
        horizontal
        horizontalAlign={'baseline'}
        verticalFill
        verticalAlign={'center'}
        tokens={{ childrenGap: 'm' }}
      >
        <CommandButton
          className={isExpanded ? classNames.chevronExpanded : classNames.chevronCollapsed}
          ariaLabel="Expand"
          iconProps={{
            iconName: isExpanded ? 'ChevronDown' : 'ChevronRight',
            className: classNames.chevronIcon,
            ariaLabel: isExpanded ? expandedLabel : collapsedLabel,
          }}
          onClick={() => setKeyWithSummary(item.key === keyWithSummary ? '' : item.key)}
        />
        {onRenderColumnItem}
      </Stack>
    );
  };

  const onRenderDefaultColumnItem = (item: any, columnProps: IColumn): JSX.Element => {
    // eslint-disable-next-line react/prop-types
    const curCol: ColumnWithPriority | undefined = responsiveColumns.columns.find(
      column => column.key === columnProps.key,
    );

    const columnWithSubText = !!curCol && !!curCol.subTextField;

    return (
      <Stack
        verticalAlign={'space-between'}
        tokens={{ childrenGap: 4 }}
        className={columnWithSubText ? classNames.columnWithSubText : undefined}
      >
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.listCell}
          styles={{ root: { maxWidth: columnProps.maxWidth } }}
          content={item[columnProps.fieldName as keyof any]}
        >
          {item[columnProps.fieldName as keyof any]}
        </TooltipHost>
        {columnWithSubText && (
          <TooltipHost
            overflowMode={TooltipOverflowMode.Self}
            hostClassName={classNames.subText}
            styles={{ root: { maxWidth: columnProps.maxWidth } }}
            content={item[curCol!.subTextField!]}
          >
            {item[curCol!.subTextField!]}
          </TooltipHost>
        )}
      </Stack>
    );
  };

  const onRenderColumnItemInternal = (item: any, index?: number, columnProps?: IColumn): ReactNode => {
    if (!item || !columnProps || (!index && index !== 0)) {
      return null;
    }

    const onRenderColumnItem: ReactNode = columnProps.onRender
      ? columnProps.onRender(item, index, columnProps)
      : onRenderDefaultColumnItem(item, columnProps);

    return columnProps.key === columnKeyWithChevron
      ? onRenderDetailsChevronItem(item, onRenderColumnItem)
      : onRenderColumnItem;
  };

  const getListCheckboxRender = useCallback(
    (isDisabled: boolean, props: IDetailsRowProps) => (
      <div style={{ paddingLeft: 22 }}>
        <Checkbox
          disabled={isDisabled}
          checked={selectedKeys.includes(props.item.key)}
          onChange={() => {
            let wasSelected = selectedKeys.includes(props.item.key);
            if (wasSelected && isAllSelected) {
              setIsAllSelected(false);
            } else if (selectedKeys.length + 1 === itemsToDisplay.length) {
              setIsAllSelected(true);
            }
            let newSelectedKeys = wasSelected
              ? selectedKeys.filter(key => key !== props.item.key)
              : !!selectionProps && selectionProps.selectionMode === SelectionMode.single
              ? [props.item.key]
              : [...selectedKeys, props.item.key];
            setSelectedKeys(newSelectedKeys);
            selectionProps && selectionProps.onSelectionChanged(newSelectedKeys);
          }}
        />
      </div>
    ),
    [isAllSelected, itemsToDisplay.length, selectedKeys, selectionProps],
  );

  const onRenderRowInternal = (
    props?: IDetailsRowProps,
    defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    const currentItem =
      !!props &&
      !!selectionProps &&
      selectionProps.defaultSelected &&
      selectionProps.defaultSelected.find(item => item.key === props.item.key);
    const isDisabled = !!currentItem ? !currentItem.canSelect : false;
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          onRenderCheck: () => getListCheckboxRender(isDisabled, props),
          styles: { root: { height: hasSubfield ? 56 : 42 } },
        }
      : props;
    return props && props.item.isSummaryItem ? (
      <DetailsSummary item={props.item} columns={summaryProps!.columns} className={classNames.summary} />
    ) : defaultRender ? (
      defaultRender(newProps)
    ) : null;
  };

  const onColumnHeaderClick = (column?: ColumnWithPriority) => {
    if (column && !!column.sortDataType) {
      setListItemsOrder(state => ({
        fieldName: column.fieldName,
        dataType: column.sortDataType!,
        orderBy:
          state.fieldName === column.fieldName
            ? state.orderBy === ListItemsOrder.ASCENDING
              ? ListItemsOrder.DESCENDING
              : ListItemsOrder.ASCENDING
            : ListItemsOrder.ASCENDING,
      }));
    }
  };

  const getHeaderCheckboxRender = useCallback(
    () => (
      <div style={{ paddingLeft: 22 }}>
        <Checkbox
          checked={selectedKeys.length === itemsToDisplay.length}
          onChange={() => {
            setIsAllSelected(state => !state);
            let newSelectedKeys = !isAllSelected
              ? itemsToDisplay.map(item => item.key)
              : selectionProps!.defaultSelected
              ? selectionProps!.defaultSelected
                  .filter(inItem => !inItem.canSelect)
                  .map(filteredItem => filteredItem.key)
              : [];
            setSelectedKeys([...newSelectedKeys]);
            selectionProps && selectionProps.onSelectionChanged(newSelectedKeys);
          }}
        />
      </div>
    ),
    [isAllSelected, itemsToDisplay, selectedKeys.length, selectionProps],
  );

  const onRenderDetailsHeaderInternal = useCallback(
    (props, defaultRender) => {
      const newProps: IDetailsHeaderProps | undefined = props
        ? {
            ...props,
            onRenderDetailsCheckbox: getHeaderCheckboxRender,
          }
        : props;
      return !!defaultRender ? (
        <Sticky stickyPosition={StickyPositionType.Header}>{defaultRender(newProps)}</Sticky>
      ) : null;
    },
    [getHeaderCheckboxRender],
  );

  let columnsWithActions: ColumnWithPriority[] = responsiveColumns.columns.map(
    (column: ColumnWithPriority): ColumnWithPriority =>
      !!column.sortDataType
        ? {
            ...column,
            iconName:
              listItemsOrder.fieldName === column.fieldName
                ? listItemsOrder.orderBy === ListItemsOrder.ASCENDING
                  ? 'SortDown'
                  : 'SortUp'
                : undefined,
            onColumnClick: () => onColumnHeaderClick(column),
            maxWidth: column.maxWidth
              ? isXLargeScreen
                ? column.maxWidth * 2
                : isLargeScreen
                ? column.maxWidth * 1.5
                : column.maxWidth
              : undefined,
            className: column.className
              ? column.className
              : column.centerAligned
              ? classNames.centeredColumns
              : classNames.leftAlignedColumns,
            headerClassName: column.headerClassName
              ? column.headerClassName
              : column.centerAligned
              ? classNames.centeredColumns
              : classNames.leftAlignedColumns,
          }
        : {
            ...column,
            columnActionsMode: ColumnActionsMode.disabled,
            maxWidth: column.maxWidth
              ? isXLargeScreen
                ? column.maxWidth * 2
                : isLargeScreen
                ? column.maxWidth * 1.5
                : column.maxWidth
              : undefined,
            className: column.className
              ? column.className
              : column.centerAligned
              ? classNames.centeredColumns
              : classNames.leftAlignedColumns,
            headerClassName: column.headerClassName
              ? column.headerClassName
              : column.centerAligned
              ? classNames.centeredColumns
              : classNames.leftAlignedColumns,
          },
  );

  if (!!optionsItemProps && optionsItemProps.length > 0) {
    const onRenderOptionsItem = (item: any) => onRenderDetailsOptions(optionsItemProps(item));
    columnsWithActions = columnsWithActions.concat({
      key: generateUniqueIdentifier(),
      name: '',
      minWidth: 50,
      maxWidth: 50,
      iconName: undefined,
      fieldName: 'options',
      onRender: onRenderOptionsItem,
      priority: ColumnPriority.Primary,
      columnActionsMode: ColumnActionsMode.disabled,
    });
  }

  if (hideSecondaryColumns) {
    columnsWithActions = columnsWithActions.filter(column => column.priority === ColumnPriority.Primary);
  } else if (hideTeritaryColumns) {
    columnsWithActions = columnsWithActions.filter(
      column => column.priority === ColumnPriority.Primary || column.priority === ColumnPriority.Secondary,
    );
  }

  if (isLoadedAndEmpty && onRenderNoItemsToDisplay) {
    if (typeof onRenderNoItemsToDisplay === 'object') {
      const onRenderNoItemsToDisplayInternal = onRenderNoItemsToDisplay as NoItemsToDisplayProps;
      return (
        <NoItemsToDisplay
          text={onRenderNoItemsToDisplayInternal.text}
          subText={onRenderNoItemsToDisplayInternal.subText}
          actionProps={onRenderNoItemsToDisplayInternal.actionProps}
        />
      );
    } else {
      return onRenderNoItemsToDisplay;
    }
  }

  return (
    <div
      className={classNames.root}
      ref={componentRef}
      style={{
        minHeight: Math.min(itemsToDisplay.length * (hasSubfield ? 56 : 42) + 64, 400),
      }}
    >
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <ShimmeredDetailsList
          setKey="MBCDetailsList"
          detailsListStyles={{
            headerWrapper: classNames.headerWrapper,
            contentWrapper: classNames.contentWrapper,
          }}
          groups={groups}
          groupProps={groupProps}
          enableShimmer={isLoading}
          shimmerLines={8}
          items={getItems()}
          columns={columnsWithActions}
          isHeaderVisible={isHeaderVisible}
          checkboxVisibility={checkboxVisibility}
          checkButtonAriaLabel="Select row"
          layoutMode={DetailsListLayoutMode.justified}
          constrainMode={ConstrainMode.horizontalConstrained}
          onRenderRow={(props, defaultRender) => onRenderRowInternal(props, defaultRender)}
          selectionMode={selectionProps ? selectionProps.selectionMode : SelectionMode.none}
          onRenderItemColumn={(item, index, column) => onRenderColumnItemInternal(item, index, column)}
          onRenderDetailsHeader={onRenderDetailsHeaderInternal}
        />
      </ScrollablePane>
    </div>
  );
};
