import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  classNamesFunction,
  Modal,
  Stack,
  Icon,
  CommandButton,
  Link,
  TooltipHost,
  TooltipOverflowMode,
  IColumn,
} from 'office-ui-fabric-react';
import { SearchModelListProps, SearchModelListStyleProps, SearchModelListStyles } from './SearchModelList.types';
import { useDispatch, useSelector } from '../../store/hooks';
import { useSearchModelList, SearchModel } from '../../store/hooks/use-list/useSearchModelList';
import { PageTemplate } from '../../components/common/PageTemplate';
import { TenantPermissionContext, TenantPermissionContextType } from '../../contexts';
import { Permission } from '../../config/userPermissions.config';
import { DetailsListCard } from '../../components/common/DetailsListCard';
import { getStatusIconProps } from './SearchModel/SearchModel.utils';
import { SortDataType, ColumnPriority, ColumnWithPriority } from '../../components/common/DetailsList';
import { SearchModelEditor } from './SearchModelEditor';
import { MbcCustomMLRouter } from '../../router/MbcCustomMLRouter/MbcCustomMLRouter';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { Tenant } from '../../store/types/tenantsList.d';
import { createSearchModel } from '../../store/actions/customML/searchModelActions';
import { AppState } from '../../store/reducers';

export const getClassNames = classNamesFunction<SearchModelListStyleProps, SearchModelListStyles>();

export function SearchModelListBase(props: SearchModelListProps) {
  const { t, styles, theme } = props;
  const classNames = getClassNames(styles, { theme: theme! });

  const dispatch = useDispatch();
  const searchModelList = useSearchModelList();
  const userName = useSelector((state: AppState) => (!!state.user.name ? state.user.name : state.user.email));
  const activeTenant = useSelector<Tenant>(state => state.tenantsList.activeTenant);
  const isSearchModelLoading = useSelector<boolean>(state => state.searchModels.isLoading);
  const isRawDatasetListLoading = useSelector<boolean>(state => state.datasets.isLoading);
  const isTrainingJobListLoading = useSelector<boolean>(state => state.trainingJobs.isLoading);
  const isCookedDatasetListLoading = useSelector<boolean>(state => state.cookingJobs.isLoading);
  const isEvaluationListLoading = useSelector<boolean>(state => state.evaluationJobs.isLoading);
  const isDeploymentListLoading = useSelector<boolean>(state => state.deploymentJobs.isLoading);

  const isLoading =
    isSearchModelLoading ||
    isDeploymentListLoading ||
    isRawDatasetListLoading ||
    isEvaluationListLoading ||
    isTrainingJobListLoading ||
    isCookedDatasetListLoading;

  const tenantId = useSelector<string>(state => state.tenantsList.activeTenant.id);
  const [isSearchModelEditorOpen, showSearchModelEditor] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && !searchModelList.length) {
      showSearchModelEditor(true);
    }
    return () => {};
  }, [isLoading, searchModelList]);

  const onRenderSearchModelName = useCallback(
    (item?: SearchModel, index?: number, column?: IColumn) =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.name}
        >
          <Link
            style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
            onClick={MbcCustomMLRouter(activeTenant, item)(MbcRouteKey.SearchModel).browserPush}
          >
            {item.name}
          </Link>
        </TooltipHost>
      ),
    [activeTenant, classNames.overflow],
  );

  const onRenderSearchModelDescription = useCallback(
    (item?: SearchModel, index?: number, column?: IColumn) =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.description}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.description}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const onRenderSearchModelStatus = useCallback(
    (item?: SearchModel, index?: number, column?: IColumn) =>
      item &&
      column && (
        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign={'center'} key="status">
          <Icon {...getStatusIconProps(item.status)}></Icon>
          <div style={{ fontSize: 12 }}>{t(`dynamic:custom-ml.model-status.${item.job}-${item.status}`)}</div>
        </Stack>
      ),
    [t],
  );

  const columns: ColumnWithPriority[] = useMemo(
    () => [
      {
        minWidth: 200,
        maxWidth: 300,
        key: 'model-name',
        fieldName: 'name',
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        onRender: onRenderSearchModelName,
        name: t('custom-ml.model-list.th-name'),
      },
      {
        minWidth: 150,
        maxWidth: 150,
        fieldName: 'status',
        key: 'model-status',
        priority: ColumnPriority.Primary,
        onRender: onRenderSearchModelStatus,
        name: t('custom-ml.model-list.th-status'),
      },
      {
        minWidth: 200,
        maxWidth: 250,
        key: 'model-description',
        fieldName: 'description',
        priority: ColumnPriority.Tertiary,
        onRender: onRenderSearchModelDescription,
        name: t('custom-ml.model-list.th-description'),
      },
      {
        minWidth: 100,
        maxWidth: 100,
        key: 'model-feature',
        fieldName: 'feature',
        priority: ColumnPriority.Tertiary,
        name: t('custom-ml.model-list.th-feature'),
      },
      {
        minWidth: 100,
        maxWidth: 100,
        key: 'model-type',
        fieldName: 'modelType',
        priority: ColumnPriority.Secondary,
        name: t('custom-ml.model-list.th-type'),
      },
      {
        minWidth: 100,
        maxWidth: 200,
        fieldName: 'createdAt',
        key: 'model-create-date',
        priority: ColumnPriority.Secondary,
        sortDataType: SortDataType.dateTime,
        name: t('custom-ml.model-list.th-create-date'),
      },
    ],
    [onRenderSearchModelDescription, onRenderSearchModelName, onRenderSearchModelStatus, t],
  );

  return (
    <>
      <Modal onDismiss={() => showSearchModelEditor(false)} isOpen={isSearchModelEditorOpen}>
        <SearchModelEditor
          onSubmit={searchModel => dispatch(createSearchModel(tenantId, searchModel))}
          onDismiss={() => showSearchModelEditor(false)}
        />
      </Modal>
      <PageTemplate
        pageHeaderProps={{
          title: <div data-clarity-mask="True">{t('environment-list.page-header', { userName: userName })}</div>,
          titleCaption: activeTenant.name,
        }}
        onRenderContent={() => (
          <DetailsListCard
            searchActionProps={{
              searchBy: 'name',
              ariaLabel: t('custom-ml.model-list.search-aria-label'),
              placeholder: t('custom-ml.model-list.search-placeholder'),
            }}
            header={{
              title: t('custom-ml.model-list.list-title'),
              actions: {
                filter: {},
                actionsList: [
                  <TenantPermissionContext.Consumer key="add">
                    {(props: TenantPermissionContextType) => {
                      const isAuthorized = props.isAuthorized(Permission.SearchCustomMLReadWrite);
                      return (
                        <CommandButton
                          key="add"
                          iconProps={{ iconName: 'Add' }}
                          disabled={!isAuthorized}
                          onClick={() => showSearchModelEditor(true)}
                        >
                          {t('custom-ml.model-list.add-model')}
                        </CommandButton>
                      );
                    }}
                  </TenantPermissionContext.Consumer>,
                  // <CommandButton
                  //   key="options"
                  //   ariaLabel={'morebuttonaria'}
                  //   iconProps={{ iconName: 'MoreVertical' }}
                  //   menuProps={{
                  //     items: [
                  //       {
                  //         key: 'delete',
                  //         disabled: true,
                  //         text: t('custom-ml.model-list.item-delete'),
                  //         iconProps: { iconName: 'Delete' },
                  //         onClick: () => {},
                  //       },
                  //     ],
                  //   }}
                  // />,
                ],
              },
            }}
            listProps={{
              isLoading: isLoading,
              isHeaderVisible: true,
              items: searchModelList,
              // selectionProps: {
              //   checkboxVisibility: CheckboxVisibility.always,
              //   selectionMode: SelectionMode.multiple,
              //   onSelectionChanged: () => {},
              // },
              optionsItemProps: (item: SearchModel) => [
                {
                  key: item.key,
                  disabled: true,
                  text: t('custom-ml.model-list.item-delete'),
                  iconProps: { iconName: 'Delete' },
                  onClick: () => {},
                },
              ],
              responsiveColumns: {
                columns: columns,
                secondaryBreakPoint: 800,
                teritaryBreakPoint: 1156,
              },
            }}
          />
        )}
      />
    </>
  );
}
