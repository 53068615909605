import { excludedConditionTypes } from '../../types/customizations/businessRule.d';
import { ConditionBlock, BoostCondition } from '../../../utils/customizations/conditions';

export const getSupportedConditionBlock = (conditionBlock: ConditionBlock): ConditionBlock | undefined => {
  const supportedConditions = conditionBlock.conditions.filter(
    condtion => !excludedConditionTypes.includes(condtion._type),
  );
  return supportedConditions.length > 0 ? { ...conditionBlock, conditions: supportedConditions } : undefined;
};

export const getSupportedBoostCondition = (boostCondition: BoostCondition): BoostCondition | undefined => {
  const supportedConditions = boostCondition.condition.conditions.filter(
    condtion => !excludedConditionTypes.includes(condtion._type),
  );
  return supportedConditions.length > 0
    ? { ...boostCondition, condition: { ...boostCondition.condition, conditions: supportedConditions } }
    : undefined;
};
