import { DetailsListFilterStyleProps, DetailsListFilterStyles } from './DetailsListFilter.types';

export const styles = (props: DetailsListFilterStyleProps): DetailsListFilterStyles => ({
  root: ['ms-details-list', props.className],
  contextualMenu: [
    'mbc-contextual-menu',
    {
      width: '100%',
      minWidth: 'unset',
      selectors: {
        '.ms-ContextualMenu-item': {
          borderTop: '1px solid ' + props.theme.palette.neutralLight,
          height: 36,
          display: 'flex',
          boxSizing: 'unset',
        },
        '.ms-Button': {
          width: '100%',
          height: '100%',
          paddingLeft: 8,
        },
        '.ms-ContextualMenu-link': {
          height: '100%',
        },
        '.ms-ContextualMenu-list': {
          maxHeight: 188,
          overflow: 'auto',
        },
        '.ms-SearchBox': {
          margin: '12px 8px 12px 8px',
          border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: 4,
          outline: 'unset',
          selectors: {
            '.ms-Button': {
              height: 'unset',
            },
            '.is-active': {
              border: '2px solid ' + props.theme.palette.themePrimary,
            },
            ':active': {
              border: '2px solid ' + props.theme.palette.themePrimary,
            },
            ':hover': {
              border: '1px solid ' + props.theme.palette.themePrimary,
            },
          },
        },
        '.ms-Link': {
          fontSize: 12,
          minWidth: 50,
        },
      },
    },
  ],
  menuItemButton: [
    'mbc-menu-item-button',
    {
      width: 150,
      color: props.theme.palette.neutralSecondary,
    },
  ],
  menuCheckBox: [
    'mbc-menu-check-box',
    {
      paddingLeft: 10,
      maxHeight: 36,
      lineHeight: 16,
      width: '100%',
      selectors: {
        '.ms-Check': {
          width: 14,
          height: 14,
        },
        '.ms-Icon': {
          width: 14,
          height: 14,
          fontSize: 14,
        },
        '.ms-TooltipHost': {
          maxWidth: 120,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'pre',
        },
        ':hover': {
          background: props.theme.palette.neutralLighter,
        },
      },
    },
  ],
  drillDownContent: [
    'mbc-drill-down-content',
    {
      display: 'inline',
      maxHeight: '2.4em',
      fontSize: 13,
    },
  ],
  noDataContainer: [
    'mbc-no-data-container',
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  ],
});
