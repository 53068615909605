import { ContentCardStyles, ContentCardStyleProps } from './ContentCard.types';
import { getFlexColumnStyles, getFlexRowStyles } from '../../Styles';

export const styles = (props: ContentCardStyleProps): ContentCardStyles => ({
  root: [
    'mbc-content-card',
    getFlexColumnStyles(),
    {
      background: props.theme.palette.white,
      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132)',
      borderRadius: 4,
    },
    props.className,
  ],
  header: [
    'mbc-content-card-header',
    props.containsHeader && {
      boxSizing: 'border-box',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 24,
      paddingLeft: 24,
    },
  ],
  body: [
    'mbc-content-card-body',
    getFlexColumnStyles(),
    {
      paddingTop: 16,
      paddingBottom: 16,
      paddingRight: 24,
      paddingLeft: 24,
    },
  ],
  footer: props.containsFooter && [
    'mbc-card-footer',
    getFlexRowStyles(),
    {
      justifyContent: 'flex-end',
      paddingTop: 16,
      paddingBottom: 16,
      paddingRight: 24,
      paddingLeft: 24,
    },
  ],
});
