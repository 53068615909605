import { ListItem } from './DetailsList.types';
import { generateUniqueIdentifier } from '../../../utils';

export const getItemsWithSummary = <T extends ListItem>(items: T[], keyWithSummary: string) => {
  const itemsWithSummary = items.map(item => ({
    ...item,
    isSummaryItem: false,
  }));

  const expandedItemIndex = itemsWithSummary.findIndex(item => item.key === keyWithSummary);
  if (expandedItemIndex !== -1) {
    itemsWithSummary.splice(expandedItemIndex + 1, 0, {
      ...itemsWithSummary[expandedItemIndex],
      key: generateUniqueIdentifier(),
      isSummaryItem: true,
    });
  }

  return itemsWithSummary;
};
