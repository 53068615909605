import { QueryTesterEditorStyles, QueryTesterEditorStyleProps } from './QueryTesterEditor.types';

export const styles = (props: QueryTesterEditorStyleProps): QueryTesterEditorStyles => {
  const { className } = props;
  return {
    root: ['mbc-QueryTesterEditor', {}, className],
    panelContainer: [
      'mbc-BusinessRuleEditor-PanelContainer',
      { maxHeight: 'calc(100vh - 340px)', overflowY: 'auto', overflowX: 'hidden' },
    ],
    panelBody: [
      'mbc-QueryTesterEditor-Body',
      {
        selectors: {
          '.mbc-ConditionBlockEditor:first-child': {
            borderTop: 0,
          },
        },
      },
      className,
    ],
    subComponentStyles: {
      ConditionBlock: { scrollableConditionsContainer: { maxHeight: 'calc(100vh - 296px)' } },
    },
  };
};
