import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from '../../store/hooks';
import { useLazyBreadcrumb } from '../../store/hooks/use-breadcrumb/useLazyBreadcrumb';
import { setActiveTenantId, resetActiveTenantId } from '../../store/actions/tenantsListActions';
import { ActiveTenantLoading } from '../../components/Loading';
import { MbcRouter } from '../../router/MbcRouter.base';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { useProxyRouter } from '../../store/hooks/use-router/useProxyRouter';
import { useActiveTenantAsserter } from '../../store/hooks/use-asserter/useAsserter';
import { useTenantPermissions } from '../../store/hooks/use-user-permissions/UseTenantPermissions';

export const TenantProxy = (props: RouteComponentProps<{ tenantId: string }>) => {
  const dispatch = useDispatch();
  const tenantPermissions = useTenantPermissions();
  const MbcProxyRouteMapper = useProxyRouter();
  const MbcAdminRouteMapper = useAdminRouter();

  const isTenantListLoading = useSelector(state => state.tenantsList.isLoading);
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);

  useActiveTenantAsserter(props.match.params.tenantId);

  useLazyBreadcrumb(
    props.match.params.tenantId,
    props.match.url,
    activeTenant.name,
    !isTenantListLoading && activeTenant.name !== '',
  );

  useEffect(() => {
    dispatch(setActiveTenantId(props.match.params.tenantId));
    return () => {
      dispatch(resetActiveTenantId());
    };
  }, [dispatch, isTenantListLoading, props.match.params.tenantId]);

  return (
    <ActiveTenantLoading>
      <MbcRouter
        userPermissions={tenantPermissions}
        routes={[
          MbcAdminRouteMapper(MbcRouteKey.EnvironmentList),
          MbcProxyRouteMapper(MbcRouteKey.EnvironmentListProxy),
          MbcProxyRouteMapper(MbcRouteKey.RoleListProxy),
          MbcProxyRouteMapper(MbcRouteKey.TokenListProxy),
          MbcProxyRouteMapper(MbcRouteKey.SearchModelListProxy),
        ]}
      />
    </ActiveTenantLoading>
  );
};
