import { MembersSectionContentStyles, MembersSectionContentStyleProps } from './MembersSectionContent.types';

export const styles = (props: MembersSectionContentStyleProps): MembersSectionContentStyles => {
  return {
    root: ['mbc-members-section', props.className],
    inputFieldsContainer: [
      {
        width: '100%',
        height: 40,
      },
    ],
    searchBox: [
      {
        width: '280px',
      },
    ],
    emailInputField: [
      {
        width: '280px',
      },
    ],
    addEmailContainer: [
      'mbc-add-email-container',
      {
        display: 'flex',
        alignItems: 'flex-start',
        width: 'max-width',
        marginLeft: 'auto',
      },
    ],
  };
};
