import { IStyle } from 'office-ui-fabric-react';

export const getFlexColumnStyles = (): IStyle => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
});

export const getFlexRowStyles = (): IStyle => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '1 0 auto',
});

export const GridFirstRow: IStyle = [
  'mbc-first-row',
  {
    gridRow: '1 / span 1',
  },
];

export const GridFirstColumn: IStyle = [
  'mbc-first-col',
  {
    gridColumn: '1 / span 1',
    justifySelf: 'self-end',
    alignSelf: 'center',
  },
];
export const GridSecondColumn: IStyle = [
  'mbc-sec-col',
  {
    gridColumn: '2 / span 1',
    justifySelf: 'self-end',
    alignSelf: 'center',
  },
];
export const GridThirdColumn: IStyle = [
  'mbc-third-col',
  {
    gridColumn: '3 / span 1',
    justifySelf: 'self-end',
    alignSelf: 'center',
  },
];
export const GridForthColumn: IStyle = [
  'mbc-forth-col',
  {
    gridColumn: '4 / span 1',
    alignSelf: 'center',
    justifySelf: 'self-end',
  },
];
