import { IStyle, ITheme, IStyleFunction, IRefObject } from 'office-ui-fabric-react';
import { WithTranslation } from 'react-i18next';

export enum AnalyticsTabs {
  Peformance = 'Peformance',
  Traffic = 'Traffic',
}
export interface SearchAnalyticsBaseProps extends WithTranslation {
  /**
   * Theme provided by the Higher Order Component
   */
  theme?: ITheme;

  /**
   * Style function to be passed in to override the themed or default styles.
   */
  styles?: IStyleFunction<SearchAnalyticsStyleProps, SearchAnalyticsStyles>;

  /**
   * Optional class name to add to the root element.
   */
  className?: string;

  /**
   * Optional dev element Ref.
   */
  componentRef?: IRefObject<HTMLDivElement>;
}

export type SearchAnalyticsStyleProps = Required<Pick<SearchAnalyticsBaseProps, 'theme'>> &
  Pick<SearchAnalyticsBaseProps, 'className'>;

export interface SearchAnalyticsStyles {
  root: IStyle;

  exportButton: IStyle;

  mainStack: IStyle;

  searchRequestCharts: IStyle;

  searchLatencyCharts: IStyle;

  topQueries: IStyle;

  noResultQueries: IStyle;

  chartsGridThirdRow: IStyle;

  filtersUsage: IStyle;

  servingRegions: IStyle;

  filterCategoriesChart: IStyle;
}
