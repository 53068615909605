import {
  SearchModel,
  SET_ACTIVE_SEARCH_MODEL,
  RESET_ACTIVE_SEARCH_MODEL,
  SearchModelStateActionTypes,
  SET_SEARCH_MODEL_LIST,
  RESET_SEARCH_MODEL_LIST,
  LOADING_SEARCH_MODEL_LIST,
} from '../../types/customML/searchModel.d';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { listSearchModels } from '../../../restful-apis/customML.api';
import { createSearchModelAction } from '../utils/crud-actions/search-model/SearchModelCrudActions';
import { ActionSuccessHandler, ActionFailureHandler, ExecuteReduxCrudAction } from '../utils';

export const createSearchModel = (
  tenantId: string,
  searchModel: SearchModel,
  onSuccess?: ActionSuccessHandler<SearchModel>,
  onFailure?: ActionFailureHandler<SearchModel>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(createSearchModelAction(tenantId, searchModel, onSuccess, onFailure));

export const setActiveSearchModel = (searchModelKey: string): SearchModelStateActionTypes => ({
  type: SET_ACTIVE_SEARCH_MODEL,
  key: searchModelKey,
});

export const resetActiveSearchModel = (): SearchModelStateActionTypes => ({
  type: RESET_ACTIVE_SEARCH_MODEL,
});

export const setSearchModelList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_SEARCH_MODEL_LIST,
    });
    listSearchModels(tenantId).then(searchModelList => {
      dispatch({
        type: SET_SEARCH_MODEL_LIST,
        searchModelList: searchModelList,
      });
    });
  };
};

export const resetSearchModelList = (): SearchModelStateActionTypes => ({
  type: RESET_SEARCH_MODEL_LIST,
});
