import React, { useState, useEffect, useCallback } from 'react';
import TourStep from './tour-step/tourStep';
import { AvailableTutorials } from '../../store/types/tutorial.d';
import { AppState } from '../../store/reducers';
import { useDispatch } from 'react-redux';
import { startTutorial, gotoNextStep, showTutorialHint } from '../../store/actions/tutorialActions';
import { updateUserDetailsAction } from '../../store/actions/userActions';
import { SandBoxDetails } from '../../store/types/user.d';
import { useSelector } from '../../store/hooks';
import { TutorialProps, FocusState } from './Tutorials.types';
import { tutorialConfig } from './Tutorials.config';

export const TutorialBase = (props: TutorialProps) => {
  const {
    t,
    target,
    step,
    headline,
    onStepEnd,
    onStepStart,
    onDismiss,
    focusOnTarget = FocusState.Target,
    lightBackground,
    isBeakVisible,
    coverTarget,
    children,
  } = props;

  const [isStepShown, setIsStepShown] = useState(false);

  const currentTutorial = useSelector((state: AppState) => state.tutorial.currentTutorial);
  const currentStep = useSelector((state: AppState) => state.tutorial.currentStep);
  const sandBoxUserDetails = useSelector((state: AppState) => state.user.sandBoxDetails);

  const dispatch = useDispatch();

  const onBackButtonEvent = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(startTutorial(AvailableTutorials.None));
    },
    [dispatch],
  );

  useEffect(() => {
    window.onpopstate = onBackButtonEvent;
  }, [onBackButtonEvent]);

  useEffect(() => {
    const currentIsStepShown =
      tutorialConfig[currentTutorial].Steps.length > 0 &&
      tutorialConfig[currentTutorial].Steps[currentStep - 1] === step;
    if (currentIsStepShown && !isStepShown && onStepStart) {
      onStepStart();
    }
    setIsStepShown(currentIsStepShown);
  }, [currentStep, currentTutorial, isStepShown, onStepStart, step]);

  const updateCompletedTutorialsList = () => {
    if (!sandBoxUserDetails.completedTutorials.includes(tutorialConfig[currentTutorial].id)) {
      let updatedUserDetails: SandBoxDetails = {
        ...sandBoxUserDetails,
        completedTutorials: [...sandBoxUserDetails.completedTutorials, tutorialConfig[currentTutorial].id],
      };
      dispatch(updateUserDetailsAction(updatedUserDetails));
    }
  };

  const onDismissInternal = () => {
    dispatch(startTutorial(AvailableTutorials.None));
    updateCompletedTutorialsList();
    if (!sandBoxUserDetails.completedTutorials.includes(tutorialConfig[currentTutorial].id)) {
      dispatch(showTutorialHint());
    }
    !!onDismiss && onDismiss();
  };

  const gotoNextStepInternal = () => {
    dispatch(gotoNextStep());
    if (onStepEnd) {
      onStepEnd();
    }
  };

  const onTutorialEnd = () => {
    updateCompletedTutorialsList();
    dispatch(startTutorial(AvailableTutorials.None));
    if (onStepEnd) {
      onStepEnd();
    }
  };

  const gotoNextTutorial = () => {
    updateCompletedTutorialsList();
    dispatch(startTutorial(tutorialConfig[currentTutorial].NextTutorial));
  };

  const getTeachingBubbleProps = () => {
    const totalSteps = tutorialConfig[currentTutorial].NumOfSteps;
    let mainProps = {
      calloutProps: {
        isBeakVisible: isBeakVisible,
        coverTarget: coverTarget,
      },
      headline: headline,
      footerContent: t('tutorial.step-count', {
        currentStep: currentStep,
        totalSteps: totalSteps,
      }),
    };

    if (currentStep !== totalSteps)
      return {
        ...mainProps,
        primaryButtonProps: {
          children: t('tutorial.next'),
          onClick: gotoNextStepInternal,
        },
      };
    else {
      if (tutorialConfig[currentTutorial].NextTutorial === AvailableTutorials.None)
        return {
          ...mainProps,
          primaryButtonProps: {
            children: t('tutorial.complete'),
            onClick: onTutorialEnd,
          },
        };
      else {
        return {
          ...mainProps,
          primaryButtonProps: {
            children: t('tutorial.next-tutoial'),
            onClick: gotoNextTutorial,
          },
          secondaryButtonProps: {
            children: t('tutorial.complete'),
            onClick: onTutorialEnd,
          },
        };
      }
    }
  };

  return (
    <>
      {isStepShown && (
        <TourStep
          target={target}
          teachingBubbleProps={getTeachingBubbleProps()}
          onDismiss={onDismissInternal}
          focusOnTarget={focusOnTarget}
          lightBackground={lightBackground}
        >
          {children}
        </TourStep>
      )}
    </>
  );
};
