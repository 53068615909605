import { AuthenticationStateActionTypes, AuthenticationState, SET_LOGGED_IN_STATUS } from '../types/authentication.d';

const initialState: AuthenticationState = {
  isLoggedIn: false,
};

export function authenticationReducer(
  state = initialState,
  action: AuthenticationStateActionTypes,
): AuthenticationState {
  switch (action.type) {
    case SET_LOGGED_IN_STATUS: {
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    }
    default:
      return state;
  }
}
