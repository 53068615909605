import { SearchModelEditorStyles, SearchModelEditorStyleProps } from './SearchModelEditor.types';
import { getFlexColumnStyles } from '../../../components/common/Styles';

export const styles = (props: SearchModelEditorStyleProps): SearchModelEditorStyles => {
  return {
    footer: [
      'mbc-footer',
      {
        selectors: {
          '.ms-Button': {
            marginLeft: 16,
          },
          '.ms-Button--default': {
            color: props.theme.palette.blue,
          },
        },
      },
    ],
    input: [
      'mbc-input',
      {
        minWidth: 340,
        selectors: {
          '.ms-TextField-fieldGroup': {
            borderStyle: 'none',
          },
          '.ms-TextField-field': {
            backgroundColor: props.theme.palette.neutralLighter,
          },
          '.ms-Dropdown-title': {
            borderStyle: 'none',
            backgroundColor: props.theme.palette.neutralLighter,
          },
        },
      },
    ],
    multiLineInput: [
      'mbc-multi-line-input',
      getFlexColumnStyles(),
      {
        margin: 8,
        minWidth: 340,
        selectors: {
          '.ms-TextField-wrapper': getFlexColumnStyles(),
          '.ms-TextField-fieldGroup': {
            borderStyle: 'none',
            flex: '1 0 auto',
          },
          '.ms-TextField-field': {
            backgroundColor: props.theme.palette.neutralLighter,
            minHeight: 180,
          },
        },
      },
    ],
    subComponentStyles: {
      card: [
        'mbc-search-model-card',
        {
          selectors: {
            '.mbc-card-title': {
              fontSize: 18,
            },
          },
        },
      ],
    },
  };
};
