import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import { UrlRedirectSetProps, UrlRedirectSetStyleProps, UrlRedirectSetStyles } from './UrlRedirectSet.types';
import { SearchInstanceSelector } from '../../SearchInstance/SearchInstanceSelector';
import { SideNavMode } from '../../../../components/common/PageTemplate/PageTemplate.types';
import { SearchPreview } from '../../common/SearchPreview';
import { UrlRedirectSetEditor } from '../UrlRedirectSetEditor';
import { AppState } from '../../../../store/reducers';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { UrlRedirectSet } from '../../../../store/types/customizations/urlRedirectSet';
import {
  updateUrlRedirectSet,
  createUrlRedirectSet,
} from '../../../../store/actions/customizations/urlRedirectSetActions';
import { MbcSearchInstanceRouter } from '../../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { SplitPageTemplate } from '../../../../components/common/PageTemplate/PageTemplate';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import { toggleSideNavExpanded } from '../../../../store/actions/sideNavActions';

const getClassNames = classNamesFunction<UrlRedirectSetStyleProps, UrlRedirectSetStyles>();

export const UrlRedirectSetBase = (props: UrlRedirectSetProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const dispatch = useDispatch();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const activeUrlRedirectSet = useSelector<UrlRedirectSet>(
    (state: AppState) => state.urlRedirectSet.activeUrlRedirectSet,
  );
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);

  const [currentQuery, setCurrentQuery] = useState('');
  const [currentUrlRedirectSet, setCurrentUrlRedirectSet] = useState(activeUrlRedirectSet);

  const [isSaved, setIsSaved] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const shouldBlock = useMemo(() => isUpdated && !isSaved, [isSaved, isUpdated]);

  const navigateToUrlRedirectSetList = useCallback(() => {
    MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id)(
      MbcRouteKey.UrlRedirectSetList,
    ).browserPush();
  }, [activeEnvironment.id, activeSearchInstance.id, activeTenant.id]);

  useEffect(() => {
    isCanceled && navigateToUrlRedirectSetList();
  }, [isCanceled, navigateToUrlRedirectSetList]);

  useEffect(() => {
    const updated = !_.isEqual(activeUrlRedirectSet, currentUrlRedirectSet);
    updated !== isUpdated && setIsUpdated(updated);
  }, [activeUrlRedirectSet, currentUrlRedirectSet, isUpdated]);

  useEffect(() => {
    shouldBlock ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldBlock]);

  const editMode = activeUrlRedirectSet.id !== '';

  const isFormValid = useMemo(() => {
    return !!currentUrlRedirectSet.redirectUrl && currentUrlRedirectSet.queryList.length > 0;
  }, [currentUrlRedirectSet]);

  const currentAugmentations = useMemo(() => {
    const shouldAugmentUrlRedirect =
      !!currentUrlRedirectSet.redirectUrl && currentUrlRedirectSet.queryList.includes(currentQuery);
    return {
      redirectUrl: shouldAugmentUrlRedirect ? currentUrlRedirectSet.redirectUrl : undefined,
    };
  }, [currentQuery, currentUrlRedirectSet.queryList, currentUrlRedirectSet.redirectUrl]);

  const handleCancel = useCallback(() => {
    setIsSaved(true);
    setIsUpdated(false);
    setIsCanceled(true);
  }, []);

  const handleSave = useCallback(() => {
    setIsSaved(true);
    setIsUpdated(false);
    editMode
      ? dispatch(
          updateUrlRedirectSet(currentUrlRedirectSet, undefined, navigateToUrlRedirectSetList, () => {
            setIsSaved(false);
            setIsUpdated(true);
          }),
        )
      : dispatch(
          createUrlRedirectSet(currentUrlRedirectSet, undefined, navigateToUrlRedirectSetList, () => {
            setIsSaved(false);
            setIsUpdated(true);
          }),
        );
  }, [currentUrlRedirectSet, dispatch, editMode, navigateToUrlRedirectSetList]);
  const getEditorContent = useCallback(() => {
    return (
      <UrlRedirectSetEditor
        urlRedirectSet={currentUrlRedirectSet}
        editMode={editMode}
        onUpdate={urlRedirectSet => setCurrentUrlRedirectSet(urlRedirectSet)}
      />
    );
  }, [currentUrlRedirectSet, editMode]);

  return (
    <div className={classNames.root}>
      <SplitPageTemplate
        pageHeaderProps={{
          title: editMode ? 'Edit redirect' : 'Add redirect',
          infoProps: {
            content: t('url-redirects.info-content'),
            headline: t('url-redirects.title'),
            linkProps: {
              linkText: t('url-redirects.info-link'),
              to: '/docs/Portal%20Documentation/#manage-redirects',
            },
          },
        }}
        onRenderLeftSideContent={() => (
          <SearchPreview
            hidePostBody
            onSharedSettingsChange={sharedSettings => setCurrentQuery(sharedSettings.query)}
            augmentations={currentAugmentations}
          />
        )}
        collapsiblePaneProps={{
          onRenderPaneContent: getEditorContent,
          footerProps: {
            saveButtonText: editMode ? 'Save' : 'Create',
            saveButtonDisabled: !isFormValid || !shouldBlock,
            onSave: handleSave,
            onCancel: handleCancel,
          },
        }}
        breadcrumbVisible={true}
        onSecondaryNavMenuRender={() => <SearchInstanceSelector />}
        sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
        sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
      />
      <Prompt when={shouldBlock} message={t('common.on-before-unload-msg')} />
    </div>
  );
};
