import { useSelector } from '..';
import { useCallback } from 'react';
import { MbcAdminRouter, MbcRoleRouter } from '../../../router/MbcAdminRouter/MbcAdminRouter';

export const useAdminRouter = (tenantId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const tenantMapper = useSelector(state => state.tenantsList.tenantIdMapper);

  return useCallback(MbcAdminRouter(!!tenantId ? tenantMapper[tenantId] : activeTenant), [
    tenantId,
    activeTenant,
    tenantMapper,
  ]);
};

export const useRoleRouter = (roleId: string = '') => {
  const activeRole = useSelector(state => state.roleList.activeRole);
  const roleMapper = useSelector(state => state.roleList.roleIdMapper);
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);

  return useCallback(MbcRoleRouter(activeTenant, !!roleId ? roleMapper[roleId] : activeRole), [
    roleId,
    activeRole,
    roleMapper,
    activeTenant,
  ]);
};
