import React from 'react';
import { ReactElement } from 'react';
import { CallableChildren, ConnectedFeatureGuardProps, GuardType } from './FeatureGuard.types';
import { isArray } from '../../utils';

export const FeatureGuard: React.FC<ConnectedFeatureGuardProps> = (
  props: ConnectedFeatureGuardProps,
): ReactElement | null => {
  const { supportedFeatures, features, type, children } = props;
  const featuresInternal = isArray(features) ? features : [features];

  function isCallableChildren(children: CallableChildren | ReactElement): children is CallableChildren {
    return typeof children === 'function';
  }

  const onRenderChildren = (children: CallableChildren | ReactElement): ReactElement | null =>
    isCallableChildren(children) ? children(supportedFeatures) : children;

  const isAllowedFeaturesIncluded = (): boolean =>
    !featuresInternal || featuresInternal.length === 0 || featuresInternal.some(p => supportedFeatures.includes(p));

  const isOmittedFeaturesExcluded = (): boolean =>
    !featuresInternal || featuresInternal.every(p => !supportedFeatures.includes(p));

  const isRenderingChildrenAllowed = (): boolean =>
    type === GuardType.Exclude ? isOmittedFeaturesExcluded() : isAllowedFeaturesIncluded();

  return children && isRenderingChildrenAllowed() ? onRenderChildren(children) : null;
};
