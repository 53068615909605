import React, { useState } from 'react';
import { classNamesFunction, Stack, StackItem, TextField, Button } from 'office-ui-fabric-react';
import {
  UrlRedirectSetEditorProps,
  UrlRedirectSetEditorStyleProps,
  UrlRedirectSetEditorStyles,
} from './UrlRedirectSetEditor.types';
import { SimpleList } from '../../common/SimpleList';
import { isValidUrl } from '../../../../utils/validators/regexp';

const getClassNames = classNamesFunction<UrlRedirectSetEditorStyleProps, UrlRedirectSetEditorStyles>();

export const UrlRedirectSetEditorBase = (props: UrlRedirectSetEditorProps) => {
  const { styles, theme, className, urlRedirectSet, onUpdate } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const [redirectUrl, setRedirectUrl] = useState<string>(urlRedirectSet.redirectUrl);
  const [newQuery, setNewQuery] = useState<string>('');
  const [queryList, setQueryList] = useState<string[]>(urlRedirectSet.queryList);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const addNewQuery = () => {
    if (newQuery) {
      const updatedqueryList = queryList.concat(newQuery);
      setQueryList(updatedqueryList);
      onUpdate({ ...urlRedirectSet, queryList: updatedqueryList });
      setNewQuery('');
    }
  };

  const deleteQuery = (queryToDelete: string) => {
    const updatedqueryList = queryList.filter(query => query !== queryToDelete);
    setQueryList(updatedqueryList);
    onUpdate({ ...urlRedirectSet, queryList: updatedqueryList });
  };

  const handleNewQueryKeyUp = (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    ev.key === 'Enter' && addNewQuery();
    ev.key === 'Escape' && setNewQuery('');
  };

  const updateUrlRedirectSet = () => {
    if (isValidUrl(redirectUrl)) {
      setErrorMessage(undefined);
      onUpdate({ ...urlRedirectSet, redirectUrl: redirectUrl });
    } else {
      setErrorMessage('Please enter a valid URL, http://wwww.example.com');
    }
  };

  return (
    <Stack className={classNames.root} tokens={{ childrenGap: '10' }}>
      <StackItem>
        <TextField
          required
          value={redirectUrl}
          label={'Redirect URL'}
          placeholder={'http://www.example.com'}
          errorMessage={errorMessage}
          onChange={(event, newValue) => setRedirectUrl(newValue || '')}
          onBlur={updateUrlRedirectSet}
        />
      </StackItem>
      <Stack horizontal tokens={{ childrenGap: '8' }} horizontalAlign={'space-between'} verticalAlign={'end'}>
        <StackItem grow>
          <TextField
            value={newQuery}
            label={'Query'}
            onChange={(event, newValue) => setNewQuery(newValue || '')}
            onKeyUp={ev => handleNewQueryKeyUp(ev)}
          />
        </StackItem>

        <Button styles={{ root: { width: 95 } }} iconProps={{ iconName: 'Add' }} onClick={() => addNewQuery()}>
          Add
        </Button>
      </Stack>

      {queryList.length > 0 && (
        <StackItem>
          <SimpleList items={queryList} onItemDelete={key => deleteQuery(key)} />
        </StackItem>
      )}
    </Stack>
  );
};
