import React, { createRef } from 'react';
import { CustomPanel } from '../common/CustomPanel';
import { classNamesFunction } from 'office-ui-fabric-react';
import { DropFileUploader } from '../common/DropFileUploader/DropFileUploader';
import { DatasetUploadStyleProps, DatasetUploadStyles, DatasetUploadProps } from './DatasetUpload.types';
import { TutorialSteps, Tutorial } from '../Tutorials';
import { useTranslation } from 'react-i18next';

export const getClassNames = classNamesFunction<DatasetUploadStyleProps, DatasetUploadStyles>();

export function DatasetUploadBase(props: DatasetUploadProps) {
  const { styles, theme, title, onDismiss, onUpload, isOpen, onTutorialDismiss } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const schemaDetectionPanelRef = createRef<HTMLDivElement>();

  const i18n = useTranslation();

  return (
    <>
      <CustomPanel
        isOpen={isOpen}
        headerText={title}
        onDismiss={() => {
          onDismiss();
        }}
        styles={classNames.subComponentStyles.panel}
        lightDismiss
      >
        <div ref={schemaDetectionPanelRef}>
          <DropFileUploader
            onUpload={onUpload}
            acceptedExtensions={['json', 'ljson', 'ndjson', 'jsonl', 'ldjson', 'csv', 'tsv']}
          />
        </div>
      </CustomPanel>
      <Tutorial
        target={schemaDetectionPanelRef}
        step={TutorialSteps.SchemaDetectionPanel}
        headline={i18n.t('tutorial.catalog.headline')}
        onStepEnd={() => {
          !!onTutorialDismiss && onTutorialDismiss();
        }}
        onDismiss={() => {
          !!onTutorialDismiss && onTutorialDismiss();
        }}
      >
        {i18n.t('tutorial.catalog.step-5')}
      </Tutorial>
    </>
  );
}
