import { MbcRouteMap, MbcRouteKey } from '../MbcRouter.types';
import { Environment as RuntimeEnvironment } from '../../guards/EnvironmentGuard/EnvironmentGuard';
import { BusinessRuleList } from '../../pages/ProductSearch/BusinessRuleList';
import { SynonymSetList } from '../../pages/ProductSearch/SynonymSetList';
import { UrlRedirectSetList } from '../../pages/ProductSearch/UrlRedirectSetList';
import { BusinessRule as BusinessRuleEditor } from '../../pages/ProductSearch/BusinessRuleList/BusinessRule';
import { SynonymSet as SynonymSetEditor } from '../../pages/ProductSearch/SynonymSetList/SynonymSet';
import { UrlRedirectSet as UrlRedirectSetEditor } from '../../pages/ProductSearch/UrlRedirectSetList/UrlRedirectSet';
import { CustomMLRanker } from '../../pages/ProductSearch/CustomMLRanker';
import { Permission } from '../../config/userPermissions.config';
import { AppRoute } from '../router.service';

export const MbcSearchInstanceRouteMapper = (
  tenantId: string,
  environmentId: string,
  searchInstanceId: string,
): MbcRouteMap => ({
  [MbcRouteKey.BusinessRuleList]: {
    Component: BusinessRuleList,
    key: MbcRouteKey.BusinessRuleList,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/businessrules`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.NewBusinessRule]: {
    Component: BusinessRuleEditor,
    key: MbcRouteKey.NewBusinessRule,
    url: AppRoute.append(
      `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/businessrules/new`,
    ),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomizationReadWrite],
  },
  [MbcRouteKey.SynonymSetList]: {
    Component: SynonymSetList,
    key: MbcRouteKey.SynonymSetList,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/synonyms`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.NewSynonymSet]: {
    Component: SynonymSetEditor,
    key: MbcRouteKey.NewSynonymSet,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/synonyms/new`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomizationReadWrite],
  },
  [MbcRouteKey.UrlRedirectSetList]: {
    Component: UrlRedirectSetList,
    key: MbcRouteKey.UrlRedirectSetList,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/queryredirects`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.NewUrlRedirectSet]: {
    Component: UrlRedirectSetEditor,
    key: MbcRouteKey.NewUrlRedirectSet,
    url: AppRoute.append(
      `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/queryredirects/new`,
    ),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomizationReadWrite],
  },
  [MbcRouteKey.CustomMLRanker]: {
    Component: CustomMLRanker,
    key: MbcRouteKey.CustomMLRanker,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/custom-ml`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchCustomizationReadWrite],
  },
});

export const MbcBusinessRuleRouteMapper = (
  tenantId: string,
  environmentId: string,
  searchInstanceId: string,
  businessRuleId: string,
): MbcRouteMap => {
  return {
    [MbcRouteKey.BusinessRuleEditor]: {
      Component: BusinessRuleEditor,
      key: MbcRouteKey.BusinessRuleEditor,
      url: AppRoute.append(
        `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/businessrules/${businessRuleId}/edit`,
      ),
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
      minimumPermissions: [Permission.SearchCustomizationReadWrite],
    },
  };
};

export const MbcSynonymSetRouteMapper = (
  tenantId: string,
  environmentId: string,
  searchInstanceId: string,
  synonymSetId: string,
): MbcRouteMap => {
  return {
    [MbcRouteKey.SynonymSetEditor]: {
      Component: SynonymSetEditor,
      key: MbcRouteKey.SynonymSetEditor,
      url: AppRoute.append(
        `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/synonyms/${synonymSetId}/edit`,
      ),
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
      minimumPermissions: [Permission.SearchCustomizationReadWrite],
    },
  };
};

export const MbcUrlRedirectSetRouteMapper = (
  tenantId: string,
  environmentId: string,
  searchInstanceId: string,
  urlRedirectSetId: string,
): MbcRouteMap => {
  return {
    [MbcRouteKey.UrlRedirectSetEditor]: {
      Component: UrlRedirectSetEditor,
      key: MbcRouteKey.UrlRedirectSetEditor,
      url: AppRoute.append(
        `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/queryredirects/${urlRedirectSetId}/edit`,
      ),
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
      minimumPermissions: [Permission.SearchCustomizationReadWrite],
    },
  };
};
