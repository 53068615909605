import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  Stack,
  DefaultButton,
  PrimaryButton,
  Modal,
  IconButton,
  classNamesFunction,
  SearchBox,
} from 'office-ui-fabric-react';
import { useDispatch, useSelector } from '../../../../../../store/hooks';
import { DeepLinkProps, DeepLinkStyleProps, DeepLinkStyles } from './DeepLink.types';
import { SearchInstance } from '../../../../../../store/types/customizations/searchInstanceList';
import { Environment } from '../../../../../../store/types/environmentList';
import { showProgressIndicator, hideProgressIndicator } from '../../../../../../store/actions/progressIndicatorActions';
import { getSearchInstances } from '../../../../../../restful-apis/customizations/searchInstance.api';
import { TitledContentCard } from '../../../../../../components/common/ContentCard';
import { browserHistory } from '../../../../../../history.module';
import { useRoleEnvironmentList } from '../../../../../../store/hooks/use-list/useRoleEnvironmentList';
import { AppRoute } from '../../../../../../router/router.service';

export const getClassNames = classNamesFunction<DeepLinkStyleProps, DeepLinkStyles>();

export const DeepLinkBase = (props: DeepLinkProps) => {
  const { styles, theme, isOpen, onDismiss } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classNames = getClassNames(styles, { theme: theme! });

  const environmentList = useRoleEnvironmentList();
  const tenantId = useSelector(state => state.tenantsList.activeTenant.id);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showEmptyListMessage, setShowEmptyListMessage] = useState<boolean>(false);
  const [searchInstanceList, setSearchInstanceList] = useState<SearchInstance[]>([]);
  const [updatedEnvironmentList, setUpdatedEnvironmentList] = useState<Environment[]>(environmentList);

  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | undefined>(undefined);
  const [selectedSearchInstance, setSelectedSearchInstance] = useState<string | undefined>(undefined);

  useEffect(() => {
    setUpdatedEnvironmentList(environmentList);
    return () => {};
  }, [environmentList]);

  useEffect(() => {
    setUpdatedEnvironmentList(
      !!searchQuery
        ? environmentList.filter(e => e.name.toLowerCase().startsWith(searchQuery.toLowerCase()))
        : environmentList,
    );
    return () => {};
  }, [environmentList, searchQuery]);

  useEffect(() => {
    selectedEnvironment && dispatch(showProgressIndicator());
    selectedEnvironment &&
      getSearchInstances(tenantId, selectedEnvironment.indexId)
        .then(selectedSearchInstanceList => {
          setShowErrorMessage(false);
          setSearchInstanceList(selectedSearchInstanceList);
          setShowEmptyListMessage(!selectedSearchInstanceList.length);
          !!selectedSearchInstanceList.length && setSelectedSearchInstance(selectedSearchInstanceList[0].id);
        })
        .catch(() => setShowErrorMessage(true))
        .finally(() => {
          dispatch(hideProgressIndicator());
        });

    return () => {
      setSearchInstanceList([]);
    };
  }, [dispatch, selectedEnvironment, tenantId]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <TitledContentCard
        title={<div className={classNames.title}>{t('custom-ml.deep-link.title')}</div>}
        footer={
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <PrimaryButton
              disabled={!selectedEnvironment || !selectedSearchInstance}
              onClick={() =>
                selectedEnvironment &&
                browserHistory.push(
                  AppRoute.append(
                    `tenants/${tenantId}/environments/${selectedEnvironment.id}/search/${selectedSearchInstance}/custom-ml`,
                  ),
                )
              }
            >
              {t('custom-ml.deep-link.test')}
            </PrimaryButton>
            <DefaultButton onClick={onDismiss}>{t('common.cancel')}</DefaultButton>
          </Stack>
        }
        actions={<IconButton iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} />}
        body={
          <Stack horizontalAlign={'stretch'} verticalAlign={'center'} tokens={{ childrenGap: 16 }}>
            <div className={classNames.caption}>{t('custom-ml.deep-link.subtitle')}</div>
            <Stack horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 21 }}>
              <Dropdown
                calloutProps={{
                  styles: classNames.subComponentStyles.callout,
                }}
                className={classNames.dropdown}
                onChange={(ev, option) => {
                  setShowErrorMessage(false);
                  setSelectedSearchInstance(undefined);
                  setSelectedEnvironment(option && (option.data as Environment));
                }}
                placeholder={t('custom-ml.deep-link.environment-placeholder')}
                selectedKey={selectedEnvironment && selectedEnvironment.id}
                label={t('custom-ml.deep-link.environment-label')}
                options={updatedEnvironmentList.map(environment => ({
                  key: environment.id,
                  text: environment.name,
                  data: environment,
                  disabled: !!!environment.indexId,
                }))}
                onDismiss={() => setSearchQuery('')}
                onRenderList={(props, defaultRender) =>
                  props && defaultRender ? (
                    <div>
                      <div style={{ margin: 6 }}>
                        <SearchBox
                          className={classNames.searchbox}
                          placeholder="Search"
                          onSearch={value => setSearchQuery(value)}
                          onChange={(ev, value) => setSearchQuery(value || '')}
                        />
                      </div>
                      {defaultRender(props)}
                    </div>
                  ) : null
                }
              />
              <Dropdown
                calloutProps={{
                  styles: classNames.subComponentStyles.callout,
                }}
                className={classNames.dropdown}
                selectedKey={selectedSearchInstance}
                disabled={searchInstanceList.length === 0}
                label={t('custom-ml.deep-link.search-instance-label')}
                placeholder={t('custom-ml.deep-link.search-instance-placeholder')}
                onChange={(ev, option) => setSelectedSearchInstance(option && option.key.toString())}
                errorMessage={
                  showEmptyListMessage
                    ? t('custom-ml.deep-link.no-search-instances')
                    : showErrorMessage
                    ? t('custom-ml.deep-link.error-message')
                    : undefined
                }
                options={searchInstanceList.map(searchInstance => ({
                  key: searchInstance.id,
                  text: searchInstance.name,
                }))}
              />
            </Stack>
          </Stack>
        }
      />
    </Modal>
  );
};
