import { WithTranslation } from 'react-i18next';
import { IStyleFunctionOrObject, ITheme, IStyle } from 'office-ui-fabric-react';

export enum TrialEnvStatus {
  NOT_CREATED,
  CREATING,
  INDEXING,
  EXPIRED,
}

export interface TrialEnvironmentModalBaseProps extends WithTranslation {
  styles?: IStyleFunctionOrObject<TrialEnvironmentModalStyleProps, TrialEnvironmentModalStyles>;

  theme?: ITheme;
}

export type TrialEnvironmentModalStyleProps = Required<Pick<TrialEnvironmentModalBaseProps, 'theme'>>;

export interface TrialEnvironmentModalStyles {
  root: IStyle;

  title: IStyle;

  paragraph: IStyle;
}
