import React from 'react';
import { ProductToursItemProps, ProductToursItemStyleProps, ProductToursItemStyles } from './ProductToursItem.types';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import { ProductToursCard } from './ProductToursCard';

const getClassNames = classNamesFunction<ProductToursItemStyleProps, ProductToursItemStyles>();

export const ProductToursItemBase = (props: ProductToursItemProps): JSX.Element => {
  const { className, styles, theme, componentRef, title, toursCards } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div ref={componentRef} className={classNames.root}>
      <div className={classNames.itemTitle}>{title}</div>
      <Stack horizontalAlign="center" gap={8}>
        {toursCards.map(cardProps => (
          <ProductToursCard key={cardProps.tourTitle} {...cardProps} />
        ))}
      </Stack>
    </div>
  );
};
