import HttpClient from '../http-client/httpClient';
import { getDevOpsCommitHeaders } from '../devops.api';
import { DevOpsCommitMetadata } from '../../store/types/devOps';
import {
  BusinessRuleListResponse,
  BusinessRuleUpdateResponse,
  BusinessRuleDetailsResponse,
  BusinessRuleDeleteResponse,
} from '../dto/customizations/businessRules';

import {
  mapBusinessRuleListDtoToModel,
  mapBusinessRuleModelToDto,
  mapBusinessRuleDtoToModel,
  mapDeletedBusinessRuleDtoToModel,
} from '../mappers/customizations/businessRuleMapper';
import { BusinessRule } from '../../store/types/customizations/businessRule';
import { isDynamicsTenant } from '../../utils/dynamics';

const getBusinessRuleBaseUrl = (tenantId: string, searchIndexId: string): string => {
  return `https://commerce.bing.com/api/customization/v1/businessrules/rule/${tenantId}/indexes/${searchIndexId}`;
};

const getBusinessRuleListUrl = (tenantId: string, searchIndexId: string, searchInstanceId: string): string => {
  return `https://commerce.bing.com/api/customization/v1/businessrules/rules/${tenantId}/indexes/${searchIndexId}?searchinstanceid=${searchInstanceId}`;
};

export function getBusinessRuleList(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
): Promise<BusinessRule[]> {
  // special handling due to dynamics tenant limitations
  if (isDynamicsTenant(tenantId)) {
    return Promise.resolve([]);
  }
  return HttpClient.get<BusinessRuleListResponse>({
    url: getBusinessRuleListUrl(tenantId, searchIndexId, searchInstanceId),
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapBusinessRuleListDtoToModel);
}

export function getBusinessRuleDetails(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  businessRuleName: string,
): Promise<BusinessRule> {
  return HttpClient.get<BusinessRuleDetailsResponse>({
    url: `${getBusinessRuleBaseUrl(
      tenantId,
      searchIndexId,
    )}?searchinstanceid=${searchInstanceId}&rule=${businessRuleName}`,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapBusinessRuleDtoToModel);
}

export function createOrUpdateBusinessRule(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  businessRule: BusinessRule,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<BusinessRule> {
  const mappedBusinessRule = mapBusinessRuleModelToDto(businessRule);
  const requestBody = { ...mappedBusinessRule, searchInstanceId: searchInstanceId };

  const url = getBusinessRuleBaseUrl(tenantId, searchIndexId);
  return HttpClient.post<BusinessRuleUpdateResponse>({
    url: url,
    body: requestBody,
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
    //this is a work around, as the update API doesn't return the updated businessRule details.
  }).then(response => getBusinessRuleDetails(tenantId, searchIndexId, searchInstanceId, response.rule));
}

export function deleteBusinessRule(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  businessRuleName: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<BusinessRule> {
  const url = `${getBusinessRuleBaseUrl(
    tenantId,
    searchIndexId,
  )}?searchinstanceid=${searchInstanceId}&rule=${businessRuleName}`;

  return HttpClient.delete<BusinessRuleDeleteResponse>({
    url: url,
    body: {},
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapDeletedBusinessRuleDtoToModel);
}
