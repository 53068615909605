import { MetricResponse, ErrorReportResponse } from '../dto/insights/insights';
import {
  MetricModel,
  CachePeriod,
  CacheDetails,
  DocumentBreakDownModel,
  BreakDownPoint,
  FeatureCoverageModel,
  ErrorReportModel,
} from '../../store/types/insights.d';

function formatCacheDetails(isCached: boolean, cacheDateString: string): CacheDetails {
  if (!isCached) {
    return { lastUpdatedFrom: 0, cachePeriod: CachePeriod.RealTime };
  }

  //date from backend 'YYYY-MM-DD HH:MM'
  //All Dates must be UTC
  let currUTCTime = new Date(new Date().toUTCString().replace(' GMT', '')).getTime();
  let dateDiffInMilli: number = currUTCTime - new Date(cacheDateString).getTime();

  let cacheDays = Math.floor(dateDiffInMilli / (1000 * 3600 * 24));
  if (cacheDays >= 7) {
    return { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit };
  } else {
    if (cacheDays > 0) {
      return { lastUpdatedFrom: cacheDays, cachePeriod: CachePeriod.Days };
    } else {
      let cacheHours = Math.floor(dateDiffInMilli / (1000 * 3600));
      if (cacheHours > 0) {
        return { lastUpdatedFrom: cacheHours, cachePeriod: CachePeriod.Hours };
      } else {
        let cacheMinutes = Math.floor(dateDiffInMilli / (1000 * 60));
        if (cacheMinutes <= 5) {
          return { lastUpdatedFrom: cacheMinutes, cachePeriod: CachePeriod.RealTime };
        } else {
          return { lastUpdatedFrom: cacheMinutes, cachePeriod: CachePeriod.Minutes };
        }
      }
    }
  }
}

export function mapMetricDtoToModel(metricResponse: MetricResponse): MetricModel {
  if (metricResponse.result) {
    return {
      series: metricResponse.result.result
        .filter(item => item.values.length && !Number.isNaN(item.values[0].value) && item.values[0].value >= 0)
        .map(item => {
          return {
            xValue: item.date,
            yValues: item.values.map(value => value.value),
          };
        })
        .reverse(),
      cacheDetails: formatCacheDetails(metricResponse.result.isCached, metricResponse.result.lastUpdatedTime),
    };
  } else return { series: [], cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } };
}

export function mapMetricDtoToDocumentBreakDownModel(metricResponse: ErrorReportResponse): DocumentBreakDownModel {
  if (metricResponse.result) {
    let series: BreakDownPoint[] = [];

    metricResponse.result.result.forEach(item => {
      if (item.contents.length === 2) {
        let successfulDocumentsValue = item.contents.find(value => value.content === 'CompletedDocuments');
        let failedDocumentsValue = item.contents.find(value => value.content === 'FailedDocuments');
        if (
          !!successfulDocumentsValue &&
          successfulDocumentsValue.value >= 0 &&
          !!failedDocumentsValue &&
          failedDocumentsValue.value >= 0
        ) {
          series = [
            ...series,
            {
              xValue: item.date,
              successfulDocuments: successfulDocumentsValue.value,
              failedDocuments: failedDocumentsValue.value,
            },
          ];
        }
      }
    });
    return {
      series: series.reverse(),
      cacheDetails: formatCacheDetails(metricResponse.isCached, metricResponse.lastUpdatedTime),
    };
  } else return { series: [], cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } };
}

export function mapMetricDtoToFeatureCoverageModel(metricResponse: MetricResponse): FeatureCoverageModel {
  if (metricResponse.result) {
    return {
      series: metricResponse.result.result[0].values
        .filter(value => {
          let nameValueSplit = value.name.split('.');
          return nameValueSplit[nameValueSplit.length - 1] === 'Count';
        })
        .map(value => {
          let nameValueSplit = value.name.split('.');
          return { name: nameValueSplit.slice(0, nameValueSplit.length - 2).join('.'), percentage: value.value * 100 };
        }),
      cacheDetails: formatCacheDetails(metricResponse.result.isCached, metricResponse.result.lastUpdatedTime),
    };
  } else return { series: [], cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } };
}

export function mapErrorReportDtoToModel(errorReportResponse: ErrorReportResponse): ErrorReportModel {
  if (errorReportResponse.result) {
    return {
      series: errorReportResponse.result.result
        .filter(item => item.contents.length)
        .map(item => {
          return {
            date: item.date,
            errorDetails: item.contents.map(value => ({ numberOfOccurance: value.value, errorMessage: value.content })),
          };
        })
        .reverse(),
      cacheDetails: formatCacheDetails(errorReportResponse.isCached, errorReportResponse.lastUpdatedTime),
    };
  } else return { series: [], cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } };
}
