import { IStyle, IStyleFunctionOrObject, ITheme } from 'office-ui-fabric-react';

export interface DraggableSliderOwnProps {
  styles?: IStyleFunctionOrObject<DraggableSliderStyleProps, DraggableSliderStyles>;
  theme?: ITheme;
  onSliderClick?: () => void;
  showTeachingBubble?: boolean;
}

export type DraggableSliderProps = DraggableSliderOwnProps;

export type DraggableSliderStyleProps = Pick<DraggableSliderOwnProps, 'theme'>;

export interface DraggableSliderStyles {
  root: IStyle;
  slider: IStyle;
  subComponentStyles: {
    teachingBubble: IStyleFunctionOrObject<any, any>;
  };
}

export enum DraggableSliderSide {
  Left,
  Right,
}
