import React, { useState, useCallback, useEffect } from 'react';
import { classNamesFunction, Stack, StackItem, ActionButton } from 'office-ui-fabric-react';
import {
  QueryTesterCodeEditorProps,
  QueryTesterCodeEditorStyleProps,
  QueryTesterCodeEditorStyles,
} from './QueryTesterCodeEditor.types';
import { CodeEditor } from '../../../../../components/common/CodeEditor';

const getClassNames = classNamesFunction<QueryTesterCodeEditorStyleProps, QueryTesterCodeEditorStyles>();

export const QueryTesterCodeEditorBase = (props: QueryTesterCodeEditorProps) => {
  const { styles, theme, className, value, defaultValue, onValueChange } = props;
  const [currentValue, setCurrentValue] = useState<string>(JSON.stringify(value, null, 2));
  const [showParsingError, setShowParsingError] = useState(false);

  useEffect(() => {
    setCurrentValue(JSON.stringify(value, null, 2));
  }, [value]);

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const onSubmitClick = useCallback(() => {
    let parsedRequest: any;
    try {
      parsedRequest = JSON.parse(currentValue);
      setShowParsingError(false);
      onValueChange(parsedRequest);
    } catch (error) {
      setShowParsingError(true);
    }
  }, [currentValue, onValueChange]);

  const onResetClick = useCallback(() => {
    setCurrentValue(JSON.stringify(defaultValue, null, 2));
    setShowParsingError(false);
    onValueChange(defaultValue);
  }, [defaultValue, onValueChange]);

  return (
    <Stack className={classNames.root} tokens={{ childrenGap: 8 }}>
      <Stack horizontal horizontalAlign={'end'}>
        <ActionButton iconProps={{ iconName: 'SendMirrored' }} onClick={onSubmitClick}>
          Submit
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Refresh' }} onClick={onResetClick}>
          Reset
        </ActionButton>
      </Stack>
      {showParsingError && (
        <StackItem className={classNames.errors}>Failed to parse treatment body, please check syntax</StackItem>
      )}
      <StackItem className={classNames.codeContainer}>
        <CodeEditor value={currentValue} onChange={v => setCurrentValue(v)} />
      </StackItem>
    </Stack>
  );
};
