import { DetailsSummaryStyles, DetailsSummaryStyleProps } from './DetailsSummary.types';

export const getStyles = (props: DetailsSummaryStyleProps): DetailsSummaryStyles => ({
  root: ['ms-summary', props.className],
  contentWrapper: [
    {
      selectors: {
        '.ms-DetailsRow': {
          backgroundColor: props.theme.palette.neutralLighterAlt,
          borderTopStyle: 'solid',
          borderTopWidth: 'thin',
          borderTopColor: props.theme.palette.neutralLight,
          borderBottomStyle: 'solid',
          borderBottomWidth: 'thin',
          borderBottomColor: props.theme.palette.neutralLight,
        },
        ':hover': {
          backgroundColor: props.theme.palette.neutralLighterAlt,
        },
      },
    },
  ],
});
