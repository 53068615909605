import HttpClient from './http-client/httpClient';
import { mapTenantListDtoToModel, mapTenantDtoToModel } from './mappers/tenantMapper';
import { TenantList as TenantListDTO } from './dto/tenant/tenantList';
import { Tenant as TenantDTO } from './dto/tenant/tenant';
import { Tenant as TenantModel } from '../store/types/tenantsList';

/**
 * Constructs a get http request that returns tenant list.
 *
 * @return tenant list.
 */
export function getUserTenants(): Promise<TenantModel[]> {
  return HttpClient.get<TenantListDTO>({
    url: `https://commerce.bing.com${process.env.REACT_APP_HOME_PAGE_URL}api/admin/v2/tenants`,
  }).then(mapTenantListDtoToModel);
}

/**
 * Constructs a get http request that returns a specified tenant.
 *
 * @return tenant.
 */
export function getUserTenant(tenantId: string): Promise<TenantModel> {
  return HttpClient.get<TenantDTO>({ url: `https://commerce.bing.com/api/admin/v2/tenants/${tenantId}` }).then(
    mapTenantDtoToModel,
  );
}
