import { ReactElement } from 'react';

export type CallableChildren = (products: string[]) => ReactElement;

export enum GuardType {
  Include,
  Exclude,
}

export interface ProductGuardProps {
  children?: CallableChildren | ReactElement;
  // TODO: products type should be changed to enum
  // When both tenant and environment enums are unified
  type?: GuardType;
  products: string | string[];
}

export interface ConnectedProductGuardProps extends ProductGuardProps {
  supportedProducts: string[];
}
