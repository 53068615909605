import { UrlRedirectSetListStyles, UrlRedirectSetListStyleProps } from './UrlRedirectSetList.types';

export const styles = (props: UrlRedirectSetListStyleProps): UrlRedirectSetListStyles => {
  const { className, theme } = props;
  return {
    root: ['mbc-UrlRedirectSetList', {}, className],
    nameColumnCell: [
      'mbc-nameColumnCell',
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        selectors: {
          '.ms-Link': {
            color: theme.palette.themePrimary,
          },
        },
      },
    ],
    nameColumnCellDescription: [
      'mbc-nameColumnCellDescription',
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.neutralSecondary,
      },
    ],
  };
};
