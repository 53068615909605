import { ConditionBlockEditorStyles, ConditionBlockEditorStyleProps } from './ConditionBlockEditor.types';

export const styles = (props: ConditionBlockEditorStyleProps): ConditionBlockEditorStyles => {
  const { className } = props;
  return {
    root: [
      'mbc-ConditionBlockEditor',
      {
        padding: '10px 0',
      },
      className,
    ],
    header: [
      'mbc-ConditionBlockEditor-Header',
      {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 12,
      },
    ],
    addNewCondition: [
      'mbc-ConditionBlockEditor-Header',
      {
        border: '1px solid #EDEBE9',
      },
    ],
    conditionsContainer: [
      'mbc-ConditionBlockEditor-conditionsContainer',
      {
        display: 'flex',
        flexDirection: 'column',
      },
    ],
    scrollableConditionsContainer: [
      'mbc-ConditionBlockEditor-scrollableConditionsContainer',
      {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 270px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    ],
  };
};
