import { BaseRequestInterceptor } from './BaseRequestInterceptor';
import { AdminServiceInterceptor } from './AdminServiceInterceptor';
import { RecommendationServiceInterceptor } from './RecommendationServiceInterceptor';
import { IngestionServiceInterceptor } from './IngestionServiceInterceptor';
//import { MockServiceInterceptor } from './MockServiceInterceptor';
import { CustomizationServiceInterceptor } from './CustomizationServiceInterceptor';
import { AnalyticsServiceInterceptor } from './AnalyticsServiceInterceptor';
import { SearchServiceInterceptor } from '../request-interceptors/SearchServiceInterceptor';
import { AutosuggestSearchInterceptor } from './AutosuggestSearchInterceptor';
import { AutosuggestAuthoringInterceptor } from './AutosuggestAuthoringInterceptor';
import { RecommendationDataServiceInterceptor } from './RecommendationDataServiceInterceptor';
import { CustomMLServiceInterceptor } from './CustomMLServiceInterceptor';
import { DevopsServiceInterceptor } from './DevopsServiceInterceptor';

export const RequestInterceptors: BaseRequestInterceptor[] = [
  //new MockServiceInterceptor(),
  new AdminServiceInterceptor(),
  new SearchServiceInterceptor(),
  new IngestionServiceInterceptor(),
  new AnalyticsServiceInterceptor(),
  new DevopsServiceInterceptor(),
  new CustomizationServiceInterceptor(),
  new AutosuggestAuthoringInterceptor(),
  new AutosuggestSearchInterceptor(),
  new RecommendationServiceInterceptor(),
  new RecommendationDataServiceInterceptor(),
  new CustomMLServiceInterceptor(),
];
