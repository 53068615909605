import { IStyle, ITheme, IStyleFunction } from 'office-ui-fabric-react';

/**
 * Defines bar chart data point
 */
export interface Point {
  /**
   * Bar chart x axis data point
   */
  x: any;

  /**
   * Bar chart y axis data point
   */
  y: any;
}

/**
 * Defines bar chart base props
 */
export interface BarChartBaseProps {
  /**
   * Theme provided by the Higher Order Component
   */
  theme?: ITheme;

  /**
   * Style function to be passed in to override the themed or default styles.
   */
  styles?: IStyleFunction<BarChartStyleProps, BarChartStyles>;

  /**
   * Optional class name to add to the root element.
   */
  className?: string;

  /**
   * Optional dev element Ref.
   */
  componentRef?: React.RefObject<HTMLDivElement>;
}

export enum LayoutType {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

/**
 * Defines bar chart props
 */
export interface BarChartProps extends BarChartBaseProps {
  /**
   * Required bar chart data points
   */
  data: Point[];

  /**
   * Required bar chart min height prop
   */
  yLabel: string;

  /**
   * Required bar chart no data label
   */
  noDataLabel: string;

  /**
   * Required bar chart is data loading label
   */
  isLoadingLabel: string;

  /**
   * Required bar chart is data loading flag
   */
  isLoading: boolean;

  /**
   * Required bar chart min width prop
   */
  minWidth: number;

  /**
   * Required bar chart min height prop
   */
  minHeight: number;

  /**
   * Optional bar chart tool tip formatter prop
   */
  toolTipFormatter?: (payload: any) => React.ReactNode;

  layout?: LayoutType;

  yTickMaximumTextLength?: number;
}

/**
 * Defines bar chart style props
 */
export type BarChartStyleProps = Required<Pick<BarChartProps, 'theme'>> & Pick<BarChartProps, 'className'>;

/**
 * Defines bar chart styles
 */
export interface BarChartStyles {
  /**
   * bar chart root style
   */
  root: IStyle;
}
