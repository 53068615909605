import {
  AdminTokensState,
  AdminTokensStateActionTypes,
  SET_ADMIN_TOKEN,
  RESET_ADMIN_TOKEN,
  RESET_ALL_ADMIN_TOKENS,
} from '../types/adminTokens.d';

const initialState: AdminTokensState = {
  adminTokenMap: {},
};

export function adminTokensReducer(state = initialState, action: AdminTokensStateActionTypes): AdminTokensState {
  switch (action.type) {
    case SET_ADMIN_TOKEN: {
      return {
        ...state,
        adminTokenMap: {
          ...state.adminTokenMap,
          [action.key]: action.token,
        },
      };
    }
    case RESET_ADMIN_TOKEN: {
      var expiredToken = state.adminTokenMap[action.key];
      if (expiredToken) {
        clearTimeout(expiredToken.timeout);
        var newAdminTokensMap = { ...state.adminTokenMap };
        delete newAdminTokensMap[action.key];
        return {
          ...state,
          adminTokenMap: newAdminTokensMap,
        };
      }
      return state;
    }
    case RESET_ALL_ADMIN_TOKENS: {
      Object.values(state.adminTokenMap).forEach(i => clearTimeout(i.timeout));
      return {
        ...state,
        adminTokenMap: {},
      };
    }
    default:
      return state;
  }
}
