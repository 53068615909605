import { DELETE_ENVIRONMENT_ROLE_LIST, RoleListStateActionTypes, Role } from '../types/roleList.d';
import { ActionSuccessHandler, ActionFailureHandler, Dispatch } from './utils';
import { Environment, EnvironmentWithRoles } from '../types/environmentList.d';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import {
  ExecuteReduxCrudAction,
  environmentUpdateAction,
  environmentCreateAction,
  environmentDeleteAction,
  environmentRoleListCreateAction,
  environmentRoleListUpdateAction,
} from './utils';
import { Tenant } from '../types/tenantsList.d';

const unAssignEnvironmentRoleList = (environmentId: string, roleList: string[]): RoleListStateActionTypes => ({
  type: DELETE_ENVIRONMENT_ROLE_LIST,
  environmentId: environmentId,
  roleList: roleList,
});

export const deleteEnvironmentWithRoleList = (
  tenantId: string,
  environment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction<Environment>({
    ...environmentDeleteAction(tenantId, environment),
    onSuccess: (deletedEnvironment: Environment, dispatch: Dispatch) => {
      dispatch(unAssignEnvironmentRoleList(environment.id, environment.roleIdList));
      onSuccess && onSuccess(deletedEnvironment);
    },
    onFailure: response => onFailure && onFailure(response, environment),
  });

export const createEnvironmentWithRoleList = (
  tenant: Tenant,
  environment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction<Environment>({
    ...environmentCreateAction(tenant, environment),
    then: (createdEnvironment: Environment) =>
      environmentRoleListCreateAction(
        tenant.id,
        { ...createdEnvironment, roleIdList: environment.roleIdList },
        onSuccess,
        onFailure,
      ),
  });

export const updateEnvironmentWithRoleList = (
  tenantId: string,
  environment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction<Environment>({
    ...environmentUpdateAction(tenantId, environment),
    then: (updateEnvironment: Environment) =>
      environmentRoleListUpdateAction(
        tenantId,
        { ...updateEnvironment, roleIdList: environment.roleIdList },
        onSuccess,
        onFailure,
      ),
  });

export const updateAssignedRoleList = (
  tenantId: string,
  environment: EnvironmentWithRoles,
  onSuccess?: ActionSuccessHandler<Environment>,
  onFailure?: ActionFailureHandler<Environment>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction<Role[]>(environmentRoleListUpdateAction(tenantId, environment, onSuccess, onFailure));
