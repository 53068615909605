import React, { useState, useEffect } from 'react';
import { Stack, classNamesFunction, TooltipHost } from 'office-ui-fabric-react';
import { PieChart as RePieChart, Pie, Cell, Sector, Tooltip } from 'recharts';
import { PieChartProps, PieChartStyleProps, PieChartStyles, PieData, NameValuePercentage } from './PieChart.types';
import { getRandColorCode } from '../chartsUtils';
import numeral from 'numeral';

const getClassNames = classNamesFunction<PieChartStyleProps, PieChartStyles>();

const PIE_CHART_WIDTH = 200;
const PIE_CHART_HEIGHT = 200;

export const PieChartBase: React.FunctionComponent<PieChartProps> = (props: PieChartProps) => {
  const {
    styles,
    theme,
    className,
    componentRef,
    data,
    onClick,
    isLoading,
    pieColors,
    noDataLabel,
    isLoadingLabel,
    t,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [activeIndex, setActiveIndex] = useState(0);

  const isLoadedAndEmpty = !isLoading && data.length === 0;
  const isLoadedWithData = !isLoading && data.length > 0;

  const [displayedData, setDisplayedData] = useState<PieData[]>([
    {
      isMockData: true,
      name: isLoadingLabel,
      value: 1,
      color: 'lightgrey',
    },
  ]);

  useEffect(() => {
    if (isLoading) {
      setDisplayedData([
        {
          isMockData: true,
          name: isLoadingLabel,
          value: 1,
          color: 'lightgrey',
        },
      ]);
    }
    return () => {};
  }, [isLoading, isLoadingLabel]);

  useEffect(() => {
    if (isLoadedAndEmpty) {
      setDisplayedData([
        {
          isMockData: true,
          name: noDataLabel,
          value: 1,
          color: 'lightgrey',
        },
      ]);
    }
    return () => {};
  }, [isLoadedAndEmpty, noDataLabel]);

  useEffect(() => {
    if (isLoadedWithData) {
      setDisplayedData(
        data.map(item => ({
          name: item.name,
          value: item.value,
          percentage: item.percentage,
          color: pieColors && pieColors[item.name] ? pieColors[item.name] : getRandColorCode(),
        })),
      );
    }
    return () => {};
  }, [data, isLoadedWithData, pieColors]);

  const onRenderActiveSlice = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent, payload } = props;
    return (
      <g>
        {!payload.isMockData && (
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.payload.percentage !== undefined
              ? numeral(payload.payload.percentage).format('0.00')
              : (percent * 100).toFixed(2)}
            %
          </text>
        )}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <div ref={componentRef} className={classNames.root}>
      <RePieChart width={PIE_CHART_WIDTH} height={PIE_CHART_HEIGHT} style={{ justifySelf: 'center' }}>
        <Pie
          data={displayedData}
          activeIndex={activeIndex}
          activeShape={onRenderActiveSlice}
          isAnimationActive={!isLoading}
          cx={PIE_CHART_WIDTH / 2}
          cy={PIE_CHART_HEIGHT / 2}
          dataKey={(data: NameValuePercentage) => data.value}
          innerRadius={40}
          outerRadius={80}
          fill="#36ab92"
          onMouseEnter={(data, index) => setActiveIndex(index)}
          onClick={(data: NameValuePercentage) => onClick && !isLoading && onClick(data)}
        >
          {displayedData.map(item => (
            <Cell key={item.name} fill={item.color} />
          ))}
        </Pie>
        {!isLoadedAndEmpty && <Tooltip contentStyle={{ fontSize: 'x-small', fontWeight: 600 }} />}
      </RePieChart>
      <Stack
        verticalAlign={'center'}
        horizontalAlign={'start'}
        tokens={{ childrenGap: 8 }}
        className={classNames.labelGroup}
      >
        {displayedData.map(item => (
          <Stack
            horizontal
            key={item.name}
            verticalAlign={'center'}
            horizontalAlign={'start'}
            tokens={{ childrenGap: 6 }}
            className={classNames.itemLabel}
            onClick={() => onClick && !isLoading && onClick(item)}
          >
            <div
              className={classNames.labelItemShape}
              style={{
                backgroundColor: item.color,
              }}
            />
            <TooltipHost
              content={
                <Stack>
                  <div className={classNames.tooltipLabel}>{item.name}</div>
                  <div className={classNames.tooltipLabel}>
                    {t('common.pie-chart.legend-tooltip-value') + item.value}
                  </div>
                  {!!item.percentage && (
                    <div className={classNames.tooltipLabel}>
                      {t('common.pie-chart.legend-tooltip-percentage') +
                        numeral(item.percentage).format('0.0000') +
                        '%'}
                    </div>
                  )}
                </Stack>
              }
            >
              <div className={classNames.labelItemKey}>{item.name}</div>
            </TooltipHost>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};
