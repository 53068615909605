import { SearchModelListStyles, SearchModelListStyleProps } from './SearchModelList.types';

export const styles = (props: SearchModelListStyleProps): SearchModelListStyles => ({
  link: [
    'mbc-model-link',
    {
      fontSize: 12,
      lineHeight: 19,
      fontWeight: 600,
      textDecoration: 'none',
      color: props.theme.palette.themePrimary,
    },
  ],
  newTag: [
    'mbc-new-model-link',
    {
      selectors: {
        ':after': {
          content: "'new'",
          fontWeight: 600,
          padding: '2px 8px',
          color: '#323130',
          textTransform: 'uppercase',
          backgroundColor: '#FFF2BF',
          lineHeight: 17,
          textAlign: 'center',
          borderRadius: 4,
          margin: '0 6px',
          fontSize: 9,
        },
      },
    },
  ],
  status: [
    'mbc-model-status',
    {
      width: 'fit-content',
      padding: '3px 8px',
      fontSize: 12,
      lineHeight: 19,
    },
  ],
  overflow: [
    'mbc-overflow-cell',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
});
