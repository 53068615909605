import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import {
  LOADING_TOP_QUERIES,
  SET_TOP_QUERIES,
  LOADING_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS,
  SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS,
  LOADING_SEARCH_REQUESTS_LATENCY_VALUE,
  SET_SEARCH_REQUESTS_LATENCY_VALUE,
  LOADING_TOTAL_DISTINCT_QUERIES,
  SET_TOTAL_DISTINCT_QUERIES,
  LOADING_RESPONSE_CODES,
  SET_RESPONSE_CODES,
  LOADING_RESPONSE_CODES_FILTER,
  SET_RESPONSE_CODES_FILTER,
  LOADING_QUERY_LANGUAGES,
  SET_QUERY_LANGUAGES,
  LOADING_NO_RESULTS_QUERIES,
  SET_NO_RESULTS_QUERIES,
  LOADING_TOTAL_SEARCH_REQUESTS_OVER_TIME,
  SET_TOTAL_SEARCH_REQUESTS_OVER_TIME,
  LOADING_SUCCESSFUL_REQUESTS_OVER_TIME,
  SET_SUCCESSFUL_REQUESTS_OVER_TIME,
  LOADING_FAILED_REQUESTS_OVER_TIME,
  SET_FAILED_REQUESTS_OVER_TIME,
  LOADING_USER_ERROR_REQUESTS_OVER_TIME,
  SET_USER_ERROR_REQUESTS_OVER_TIME,
  LOADING_DISTINCT_SEARCH_REQUESTS_OVER_TIME,
  SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME,
  LOADING_TOTAL_SEARCH_REQUESTS,
  SET_TOTAL_SEARCH_REQUESTS,
  LOADING_AVAILABLE_MARKETS,
  SET_AVAILABLE_MARKETS,
  SET_REGIONS_DISTRIBUTION,
  LOADING_REGIONS_DISTRIBUTION,
  SET_REGIONS_DISTRIBUTION_FILTER,
  LOADING_REGIONS_DISTRIBUTION_FILTER,
  LOADING_FILTERS_USAGE,
  SET_FILTERS_USAGE,
  LOADING_FILTERS_DISTRIBUTION,
  SET_FILTERS_DISTRIBUTION,
  LOADING_SEARCH_INSTANCES_CHART,
  SET_SEARCH_INSTANCES_CHART,
  LOADING_SEARCH_INSTANCES_FILTER,
  SET_SEARCH_INSTANCES_FILTER,
  SET_SEARCH_AVG_QPS,
  SET_LOADING_SEARCH_AVG_QPS,
  AnalyticsStateActionTypes,
  SET_SELECTED_SEARCH_INSTANCES_FILTER,
  SET_SELECTED_RESPONSE_CODES_FILTER,
  SET_SELECTED_REGIONS_FILTER,
  SET_SELECTED_SEARCH_RESULTS_FILTER,
  SET_SELECTED_START_DATE_FILTER,
  SET_SELECTED_END_DATE_FILTER,
  LOADING_SEARCH_LATENCY_OVER_TIME,
  SET_SEARCH_LATENCY_OVER_TIME,
  SET_SEARCH_LATENCY_PERCENTILE,
  DateFormat,
  SET_LATENCY_CARD_PERCENTILE,
} from '../types/analytics.d';
import {
  getTopQueries,
  getTotalSearchRequestsWithNoResults,
  getAverageSearchLatency,
  getTotalDistinctQueries,
  getQueryLanguages,
  getNoResultsQueries,
  getSuccessfulRequestsOverTime,
  getFailedRequestsOverTime,
  getUserErrorRequestsOverTime,
  getTotalSearchRequestsOverTime,
  getDistinctSearchRequestsOverTime,
  getTotalSearchRequests,
  getAvailableMarkets,
  getResponseCodes,
  getResponseCodesFilter,
  getRegionsDistribution,
  getRegionsDistributionFilter,
  getFiltersUsage,
  getFiltersDistribution,
  getSearchInstancesChart,
  getSearchInstancesFilter,
  getSearchLatencyOverTime,
  getSearchLatencyPercentile,
} from '../../restful-apis/analytics.api';
import { AggregationInterval } from '../../restful-apis/dto/analytics/analytics';
import { getMetric } from '../../restful-apis/insights.api';
import { CachePeriod } from '../types/insights.d';
import { addDays } from '../../utils';
import moment from 'moment';

export const SetTotalSearchRequestsWithNoResults = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS,
    });
    getTotalSearchRequestsWithNoResults(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(totalSearchRequestsWithNoResults => {
        dispatch({
          type: SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS,
          totalSearchRequestsWithNoResults: totalSearchRequestsWithNoResults,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_TOTAL_SEARCH_REQUESTS_WITH_NO_RESULTS,
          totalSearchRequestsWithNoResults: { percentage: NaN, count: NaN },
        });
      });
  };
};

export const SetSearchRequestsLatencyValue = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
  percentile: number,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_SEARCH_REQUESTS_LATENCY_VALUE,
    });
    const latencyApi =
      !!percentile && !isNaN(percentile)
        ? getSearchLatencyPercentile(
            getState().tenantsList.activeTenant.id,
            getState().environmentList.activeEnvironment.indexId,
            responseCode,
            start,
            end,
            markets,
            regions,
            searchResultsStatus,
            searchInstances,
            percentile,
          )
        : getAverageSearchLatency(
            getState().tenantsList.activeTenant.id,
            getState().environmentList.activeEnvironment.indexId,
            responseCode,
            start,
            end,
            markets,
            regions,
            searchResultsStatus,
            searchInstances,
          );
    latencyApi
      .then(searchRequestsLatencyValue => {
        dispatch({
          type: SET_SEARCH_REQUESTS_LATENCY_VALUE,
          searchRequestsLatencyValue: searchRequestsLatencyValue.count,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_SEARCH_REQUESTS_LATENCY_VALUE,
          searchRequestsLatencyValue: NaN,
        });
      });
  };
};

export const SetTotalDistinctQueries = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_TOTAL_DISTINCT_QUERIES,
    });
    getTotalDistinctQueries(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(totalDistinctQueries => {
        dispatch({
          type: SET_TOTAL_DISTINCT_QUERIES,
          totalDistinctQueries: totalDistinctQueries,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_TOTAL_DISTINCT_QUERIES,
          totalDistinctQueries: { percentage: NaN, count: NaN },
        });
      });
  };
};

export const SetResponseCodes = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_RESPONSE_CODES,
    });
    getResponseCodes(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(responseCodes => {
        dispatch({
          type: SET_RESPONSE_CODES,
          responseCodes: responseCodes,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_RESPONSE_CODES,
          responseCodes: [],
        });
      });
  };
};

export const SetResponseCodesFilter = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_RESPONSE_CODES_FILTER,
    });
    getResponseCodesFilter(getState().tenantsList.activeTenant.id, getState().environmentList.activeEnvironment.indexId)
      .then(responseCodesFilter => {
        dispatch({
          type: SET_RESPONSE_CODES_FILTER,
          responseCodesFilter: responseCodesFilter,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_RESPONSE_CODES_FILTER,
          responseCodesFilter: [],
        });
      });
  };
};

export const SetRegionsDistribution = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_REGIONS_DISTRIBUTION,
    });
    getRegionsDistribution(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(regionsDistribution => {
        dispatch({
          type: SET_REGIONS_DISTRIBUTION,
          regionsDistribution: regionsDistribution,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_REGIONS_DISTRIBUTION,
          regionsDistribution: [],
        });
      });
  };
};

export const SetRegionsDistributionFilter = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_REGIONS_DISTRIBUTION_FILTER,
    });
    getRegionsDistributionFilter(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
    )
      .then(regionsDistributionFilter => {
        dispatch({
          type: SET_REGIONS_DISTRIBUTION_FILTER,
          regionsDistributionFilter: regionsDistributionFilter,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_REGIONS_DISTRIBUTION_FILTER,
          regionsDistributionFilter: [],
        });
      });
  };
};

export const SetFiltersUsage = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_FILTERS_USAGE,
    });
    getFiltersUsage(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(filtersUsage => {
        dispatch({
          type: SET_FILTERS_USAGE,
          filtersUsage: filtersUsage,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_FILTERS_USAGE,
          filtersUsage: [],
        });
      });
  };
};

export const SetFiltersDistribution = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_FILTERS_DISTRIBUTION,
    });
    getFiltersDistribution(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(filtersDistribution => {
        dispatch({
          type: SET_FILTERS_DISTRIBUTION,
          filtersDistribution: filtersDistribution,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_FILTERS_DISTRIBUTION,
          filtersDistribution: [],
        });
      });
  };
};

export const SetsearchInstancesChart = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_SEARCH_INSTANCES_CHART,
    });
    getSearchInstancesChart(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(searchInstancesChart => {
        dispatch({
          type: SET_SEARCH_INSTANCES_CHART,
          searchInstancesChart: searchInstancesChart,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_SEARCH_INSTANCES_CHART,
          searchInstancesChart: [],
        });
      });
  };
};

export const SetQueryLanguages = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_QUERY_LANGUAGES,
    });
    getQueryLanguages(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(queryLanguages => {
        dispatch({
          type: SET_QUERY_LANGUAGES,
          queryLanguages: queryLanguages,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_QUERY_LANGUAGES,
          queryLanguages: [],
        });
      });
  };
};

export const SetNoResultsQueries = (
  responseCode: string[],
  count: number,
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_NO_RESULTS_QUERIES,
    });
    getNoResultsQueries(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      count,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(noResultsQueries => {
        dispatch({
          type: SET_NO_RESULTS_QUERIES,
          noResultsQueries: noResultsQueries,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_NO_RESULTS_QUERIES,
          noResultsQueries: [],
        });
      });
  };
};

export const SetTopQueries = (
  responseCode: string[],
  count: number,
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_TOP_QUERIES,
    });
    getTopQueries(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      count,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(topQueries => {
        dispatch({
          type: SET_TOP_QUERIES,
          topQueries: topQueries,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_TOP_QUERIES,
          topQueries: [],
        });
      });
  };
};

export const SetTotalSearchRequestsOverTime = (
  aggregationType: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_TOTAL_SEARCH_REQUESTS_OVER_TIME,
    });
    getTotalSearchRequestsOverTime(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      aggregationType,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(totalSearchRequestsOverTime => {
        dispatch({
          type: SET_TOTAL_SEARCH_REQUESTS_OVER_TIME,
          totalSearchRequestsOverTime: totalSearchRequestsOverTime,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_TOTAL_SEARCH_REQUESTS_OVER_TIME,
          totalSearchRequestsOverTime: { series: [], interval: 0 },
        });
      });
  };
};

export const SetSuccessfulRequestsOverTime = (
  aggregationType: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_SUCCESSFUL_REQUESTS_OVER_TIME,
    });
    getSuccessfulRequestsOverTime(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      aggregationType,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(successfulRequestsOverTime => {
        dispatch({
          type: SET_SUCCESSFUL_REQUESTS_OVER_TIME,
          successfulRequestsOverTime: successfulRequestsOverTime,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_SUCCESSFUL_REQUESTS_OVER_TIME,
          successfulRequestsOverTime: { series: [], interval: 0 },
        });
      });
  };
};

export const SetFailedRequestsOverTime = (
  aggregationType: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_FAILED_REQUESTS_OVER_TIME,
    });
    getFailedRequestsOverTime(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      aggregationType,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(failedRequestsOverTime => {
        dispatch({
          type: SET_FAILED_REQUESTS_OVER_TIME,
          failedRequestsOverTime: failedRequestsOverTime,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_FAILED_REQUESTS_OVER_TIME,
          failedRequestsOverTime: { series: [], interval: 0 },
        });
      });
  };
};

export const SetUserErrorRequestsOverTime = (
  aggregationType: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_USER_ERROR_REQUESTS_OVER_TIME,
    });
    getUserErrorRequestsOverTime(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      aggregationType,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(userErrorRequestsOverTime => {
        dispatch({
          type: SET_USER_ERROR_REQUESTS_OVER_TIME,
          userErrorRequestsOverTime: userErrorRequestsOverTime,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_USER_ERROR_REQUESTS_OVER_TIME,
          userErrorRequestsOverTime: { series: [], interval: 0 },
        });
      });
  };
};

export const SetDistinctSearchRequestsOverTime = (
  aggregationType: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_DISTINCT_SEARCH_REQUESTS_OVER_TIME,
    });
    getDistinctSearchRequestsOverTime(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      aggregationType,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(distinctSearchRequestsOverTime => {
        dispatch({
          type: SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME,
          distinctSearchRequestsOverTime: distinctSearchRequestsOverTime,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_DISTINCT_SEARCH_REQUESTS_OVER_TIME,
          distinctSearchRequestsOverTime: { series: [], interval: 0 },
        });
      });
  };
};

export const SetTotalSearchRequests = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_TOTAL_SEARCH_REQUESTS,
    });
    getTotalSearchRequests(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(totalSearchRequests => {
        dispatch({
          type: SET_TOTAL_SEARCH_REQUESTS,
          totalSearchRequests: totalSearchRequests.count,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_TOTAL_SEARCH_REQUESTS,
          totalSearchRequests: NaN,
        });
      });
  };
};

export const SetAvailableMarkets = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_AVAILABLE_MARKETS,
    });
    getAvailableMarkets(getState().tenantsList.activeTenant.id, getState().environmentList.activeEnvironment.indexId)
      .then(availableMarkets => {
        dispatch({
          type: SET_AVAILABLE_MARKETS,
          availableMarkets: availableMarkets,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_AVAILABLE_MARKETS,
          availableMarkets: { availableValues: [], checkedValues: [] },
        });
      });
  };
};

export const SetSearchInstancesFilter = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_SEARCH_INSTANCES_FILTER,
    });
    getSearchInstancesFilter(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
    )
      .then(searchInstancesFilter => {
        dispatch({
          type: SET_SEARCH_INSTANCES_FILTER,
          searchInstancesFilter: searchInstancesFilter,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_SEARCH_INSTANCES_FILTER,
          searchInstancesFilter: [],
        });
      });
  };
};

export const setLoadingSearchAvgQPS = (isSearchAvgQPSLoading: boolean): AnalyticsStateActionTypes => {
  return { type: SET_LOADING_SEARCH_AVG_QPS, isSearchAvgQPSLoading: isSearchAvgQPSLoading };
};

export const setSearchAvgQPS = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch(setLoadingSearchAvgQPS(true));

    getMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      'RPS',
      'Search',
      '1h',
      'PT1H',
    )
      .then(searchAvgQPS => {
        dispatch({
          type: SET_SEARCH_AVG_QPS,
          searchAvgQPS: searchAvgQPS.series.length
            ? { ...searchAvgQPS, value: searchAvgQPS.series[0].yValues[0] }
            : { ...searchAvgQPS, value: NaN },
        });
      })
      .catch(() => {
        dispatch({
          type: SET_SEARCH_AVG_QPS,
          searchAvgQPS: {
            value: NaN,
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setSelectedSearchInstancesFilter = (
  checkedValues: string[],
  selectAll: boolean,
): AnalyticsStateActionTypes => {
  return {
    type: SET_SELECTED_SEARCH_INSTANCES_FILTER,
    checkedValues: checkedValues,
    selectAll: selectAll,
  };
};

export const setSelectedResponseCodesFilter = (
  checkedValues: string[],
  selectAll: boolean,
): AnalyticsStateActionTypes => {
  return {
    type: SET_SELECTED_RESPONSE_CODES_FILTER,
    checkedValues: checkedValues,
    selectAll: selectAll,
  };
};

export const setSelectedRegionsFilter = (checkedValues: string[], selectAll: boolean): AnalyticsStateActionTypes => {
  return {
    type: SET_SELECTED_REGIONS_FILTER,
    checkedValues: checkedValues,
    selectAll: selectAll,
  };
};

export const setSelectedSearchResultsFilter = (
  checkedValues: string[],
  selectAll: boolean,
): AnalyticsStateActionTypes => {
  return {
    type: SET_SELECTED_SEARCH_RESULTS_FILTER,
    checkedValues: checkedValues,
    selectAll: selectAll,
  };
};

export const setSelectedStartDateFilter = (startDateFilter: Date): AnalyticsStateActionTypes => {
  return {
    type: SET_SELECTED_START_DATE_FILTER,
    startDateFilter: startDateFilter,
  };
};

export const setSelectedEndDateFilter = (endDateFilter: Date): AnalyticsStateActionTypes => {
  return {
    type: SET_SELECTED_END_DATE_FILTER,
    endDateFilter: endDateFilter,
  };
};

export const SetSearchLatencyOverTime = (
  aggregationType: AggregationInterval,
  percentile = 75,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_SEARCH_LATENCY_OVER_TIME,
    });
    getSearchLatencyOverTime(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      percentile,
      aggregationType,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    )
      .then(searchLatencyOverTime => {
        dispatch({
          type: SET_SEARCH_LATENCY_OVER_TIME,
          searchLatencyOverTime: searchLatencyOverTime,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_SEARCH_LATENCY_OVER_TIME,
          searchLatencyOverTime: { series: [], interval: 0 },
        });
      });
  };
};

export const SetSearchLatencyPercentile = (searchLatencyPercentile: number): AnalyticsStateActionTypes => {
  return {
    type: SET_SEARCH_LATENCY_PERCENTILE,
    searchLatencyPercentile: searchLatencyPercentile,
  };
};

export const SetLatencyCardPercentile = (latencyCardPercentile: number): AnalyticsStateActionTypes => {
  return {
    type: SET_LATENCY_CARD_PERCENTILE,
    latencyCardPercentile: latencyCardPercentile,
  };
};

export const updateSearchRequestsChart = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
  aggInterval: AggregationInterval,
  updateAll = true,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    if (updateAll) {
      dispatch(
        SetTotalSearchRequestsOverTime(
          aggInterval,
          responseCode,
          start,
          end,
          markets,
          regions,
          searchResultsStatus,
          searchInstances,
        ),
      );
    }

    dispatch(
      SetSuccessfulRequestsOverTime(
        aggInterval,
        responseCode,
        start,
        end,
        markets,
        regions,
        searchResultsStatus,
        searchInstances,
      ),
    );

    dispatch(
      SetFailedRequestsOverTime(
        aggInterval,
        responseCode,
        start,
        end,
        markets,
        regions,
        searchResultsStatus,
        searchInstances,
      ),
    );

    dispatch(
      SetUserErrorRequestsOverTime(
        aggInterval,
        responseCode,
        start,
        end,
        markets,
        regions,
        searchResultsStatus,
        searchInstances,
      ),
    );
  };
};

export const UpdateAllPerformanceCharts = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
  aggInterval: AggregationInterval,
  latencyChartPercentile: number,
  latencyCardPercentile: number,
  updateAll = true,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    // Metric cards
    const avgQpsLoading = getState().analytics.isSearchAvgQPSLoading;

    !avgQpsLoading && dispatch(setLoadingSearchAvgQPS(true));

    if (updateAll) {
      dispatch(
        SetTotalSearchRequests(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
      );
    }
    dispatch(
      SetSearchRequestsLatencyValue(
        responseCode,
        start,
        end,
        markets,
        regions,
        searchResultsStatus,
        searchInstances,
        latencyCardPercentile,
      ),
    );

    // Multi line chart
    dispatch(
      updateSearchRequestsChart(
        responseCode,
        start,
        end,
        markets,
        regions,
        searchResultsStatus,
        searchInstances,
        aggInterval,
        updateAll,
      ),
    );

    dispatch(
      SetSearchLatencyOverTime(
        aggInterval,
        latencyChartPercentile,
        responseCode,
        start,
        end,
        markets,
        regions,
        searchResultsStatus,
        searchInstances,
      ),
    );

    // Pie charts
    dispatch(SetRegionsDistribution(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances));

    dispatch(SetResponseCodes(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances));

    !avgQpsLoading &&
      setTimeout(() => {
        dispatch(setLoadingSearchAvgQPS(false));
      }, 500);
  };
};

export const UpdateAllTrafficCharts = (
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
  aggInterval: AggregationInterval,
  updateAll = true,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    // Metric cards
    if (updateAll) {
      dispatch(
        SetTotalSearchRequests(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
      );
    }
    dispatch(SetTotalDistinctQueries(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances));

    dispatch(
      SetTotalSearchRequestsWithNoResults(
        responseCode,
        start,
        end,
        markets,
        regions,
        searchResultsStatus,
        searchInstances,
      ),
    );

    // Multi line chart
    if (updateAll) {
      dispatch(
        SetTotalSearchRequestsOverTime(
          aggInterval,
          responseCode,
          start,
          end,
          markets,
          regions,
          searchResultsStatus,
          searchInstances,
        ),
      );
    }

    // Pie charts
    dispatch(SetFiltersUsage(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances));

    dispatch(SetsearchInstancesChart(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances));
    // Bar chart
    dispatch(SetFiltersDistribution(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances));

    // Tables
    dispatch(SetTopQueries(responseCode, 100, start, end, markets, regions, searchResultsStatus, searchInstances));

    dispatch(
      SetNoResultsQueries(responseCode, 100, start, end, markets, regions, searchResultsStatus, searchInstances),
    );
  };
};

export const ResetAnalyticsSelectedFilters = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch(setSelectedRegionsFilter([], true));
    dispatch(setSelectedResponseCodesFilter([], true));
    dispatch(setSelectedSearchResultsFilter([], true));
    dispatch(setSelectedSearchInstancesFilter([], true));
    dispatch(setSelectedStartDateFilter(addDays(-1, new Date(moment.utc().format(DateFormat)))));
    dispatch(setSelectedEndDateFilter(new Date(moment.utc().format(DateFormat))));
    dispatch(SetSearchLatencyPercentile(75));
  };
};
