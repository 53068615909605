import { useSelector } from '..';
import { useMemo } from 'react';
import { Role } from '../../types/roleList';

export const useEnvironmentRoleList = (): Role[] => {
  const environment = useSelector(state => state.environmentList.activeEnvironment);
  const roleIdMapper = useSelector(state => state.roleList.roleIdMapper);
  return useMemo(
    () =>
      Object.values(roleIdMapper).filter(
        role => role.hasAccessToAllEnvironments || role.environmentList.includes(environment.id),
      ),
    [environment.id, roleIdMapper],
  );
};
