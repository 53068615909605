import { MbcTitleStyleProps, MbcTitleStyles } from './MbcTitle.types';

export const styles = (props: MbcTitleStyleProps): MbcTitleStyles => {
  return {
    root: [
      'mbc-title',
      {
        color: props.theme.palette.white,
        paddingLeft: 8,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        selectors: {
          '.ms-Button': {
            width: 246,
          },
          '.ms-Button:hover': {
            background: 'transparent',
          },
          '.ms-Button-icon': {
            height: 20,
          },
        },
      },
      props.className,
    ],
    responsiveLink: [
      'mbc-link',
      {
        border: 0,
        padding: 0,
        marginLeft: 16,
        selectors: {
          '.ms-Button-icon': {
            marginRight: 4,
            marginLeft: 0,
          },
          '.ms-Button-textContainer': {
            fontWeight: 600,
            color: props.theme.palette.white + '!important',
            fontSize: 16,
            lineHeight: 22,
          },
        },
      },
    ],
  };
};
