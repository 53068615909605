import { IStyleFunctionOrObject, IStyle, ITheme, IRefObject } from 'office-ui-fabric-react';
import { WithTranslation } from 'react-i18next';
import { Augmentations } from './SearchPrevew.utils';
import { SearchPostRequest } from '../../../../utils/customizations/search/searchRequest';
import { SharedSettings } from '../../QueryTester/QueryTester.types';

/**
 * Defines the search results view mode
 */
export enum SearchViewMode {
  List = -1,
  Grid = 0,
  Raw = 1,
}

export interface SearchPreviewProps extends WithTranslation {
  /**
   * Theme provided by the Higher Order Component
   */
  theme?: ITheme;

  /**
   * Style function to be passed in to override the themed or default styles.
   */
  styles?: IStyleFunctionOrObject<SearchPreviewStyleProps, SearchPreviewStyles>;

  /**
   * Optional class name to add to the root element.
   */
  className?: string;

  /**
   * Optional details list element Ref.
   */
  componentRef?: IRefObject<HTMLDivElement>;

  augmentations?: Augmentations;

  augmentedPostBody?: SearchPostRequest;

  onSharedSettingsChange?: (settings: SharedSettings) => void;

  sortBy?: string;

  hidePostBody?: boolean;
}

export type SearchPreviewStyleProps = Required<Pick<SearchPreviewProps, 'theme'>> &
  Pick<SearchPreviewProps, 'className'>;

export interface SearchPreviewStyles {
  root: IStyle;
  content: IStyle;
  scrollablePane: IStyle;
  resultContainer: IStyle;
  tutorialSubheader: IStyle;
  tutorialToggle: IStyle;
  subComponentStyles: {
    card?: IStyleFunctionOrObject<any, any>;
    actionsCard?: IStyleFunctionOrObject<any, any>;
  };
}
