import { Action } from 'redux';

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const PUSH_SINGLE_NOTIFICATION = 'PUSH_SINGLE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const DISMISS_ALL_NOTIFICATIONS = 'DISMISS_ALL_NOTIFICATIONS';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';
export const SET_IS_NOTIFICATIONS_PANEL_OPEN = 'SET_IS_NOTIFICATIONS_PANEL_OPEN';

export interface NotificationCenterState {
  notifications: NotificationWithContext[];
  unreadNotificationsCount: number;
  showProgressBar: boolean;
  isPanelOpen: boolean;
}

export enum NotificationStatus {
  UnSet = 'UnSet',
  InProgress = 'InProgress',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
}

export interface NotificationButton {
  text: string;
  onClick: () => void;
}

export interface NotificationBase {
  title: string;
  content?: React.ReactNode;
  primaryButtonProps?: NotificationButton;
  hideToast?: boolean;
}

export interface Notification<T> {
  inProgress: NotificationBase;
  success: (model?: T) => NotificationBase;
  failure: (errorMessage?: string) => NotificationBase;
  supressErrorMessage?: boolean;
}

export interface NotificationWithContext extends NotificationBase {
  id: string;
  creationTime: string;
  status: NotificationStatus;
  read: boolean;
  errorMessage?: string;
}

export type OnSuccess<T> = (model: T) => void;

export type OnFailure = (exception: any) => void;

interface PushNotification extends Action<typeof PUSH_NOTIFICATION> {
  notification: NotificationWithContext;
}

interface PushSingleNotification extends Action<typeof PUSH_SINGLE_NOTIFICATION> {
  notification: NotificationWithContext;
}

interface DismissNotification extends Action<typeof DISMISS_NOTIFICATION> {
  notification: NotificationWithContext;
}

interface UpdateNotification extends Action<typeof UPDATE_NOTIFICATION> {
  notification: NotificationWithContext;
}

interface SetIsNotificationsPanelOpen extends Action<typeof SET_IS_NOTIFICATIONS_PANEL_OPEN> {
  isPanelOpen: boolean;
}

export type NotificationsCenterActionTypes =
  | PushNotification
  | PushSingleNotification
  | UpdateNotification
  | DismissNotification
  | SetIsNotificationsPanelOpen
  | Action<typeof DISMISS_ALL_NOTIFICATIONS>
  | Action<typeof READ_ALL_NOTIFICATIONS>;
