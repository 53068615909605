import { Action } from 'redux';

export const GET_SEARCH_INSTANCE_LIST = 'GET_SEARCH_INSTANCE_LIST';
export const RESET_SEARCH_INSTANCE_LIST = 'RESET_SEARCH_INSTANCE_LIST';
export const LOADING_SEARCH_INSTANCE_LIST = 'LOADING_SEARCH_INSTANCE_LIST';
export const SET_ACTIVE_SEARCH_INSTANCE = 'SET_ACTIVE_SEARCH_INSTANCE';
export const RESET_ACTIVE_SEARCH_INSTANCE = 'RESET_ACTIVE_SEARCH_INSTANCE';
export const CREATE_SEARCH_INSTANCE = 'CREATE_SEARCH_INSTANCE';
export const CLONE_SEARCH_INSTANCE = 'CLONE_SEARCH_INSTANCE';
export const DELETE_SEARCH_INSTANCE = 'DELETE_SEARCH_INSTANCE';

export const DefaultSearchInstanceId = 'Default';
export interface SearchInstance {
  id: string;
  name: string;
}

export interface SearchInstanceIdMapper {
  [key: string]: SearchInstance;
}

export interface SearchInstanceListState {
  isLoading: boolean;
  activeSearchInstance: SearchInstance;
  searchInstanceIdMapper: SearchInstanceIdMapper;
}

interface GetSearchInstanceList extends Action<typeof GET_SEARCH_INSTANCE_LIST> {
  searchInstanceList: SearchInstance[];
}

interface SetActiveSearchInstance extends Action<typeof SET_ACTIVE_SEARCH_INSTANCE> {
  id: string;
}

interface CreateSearchInstance extends Action<typeof CREATE_SEARCH_INSTANCE> {
  searchInstance: SearchInstance;
}

interface CloneSearchInstance extends Action<typeof CLONE_SEARCH_INSTANCE> {
  srcSearchInstanceId: string;
  dstSearchInstanceId: string;
}

interface DeleteSearchInstance extends Action<typeof DELETE_SEARCH_INSTANCE> {
  id: string;
}

export type SearchInstanceActionTypes =
  | GetSearchInstances
  | SetActiveSearchInstance
  | CreateSearchInstance
  | CloneSearchInstance
  | DeleteSearchInstance
  | Action<typeof RESET_SEARCH_INSTANCE_LIST>
  | Action<typeof LOADING_SEARCH_INSTANCE_LIST>
  | Action<typeof RESET_ACTIVE_SEARCH_INSTANCE>;
