import { SearchModelStyles, SearchModelStyleProps } from './SearchModel.types';
import { getFlexRowStyles } from '../../../components/common/Styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const styles = (props: SearchModelStyleProps): SearchModelStyles => ({
  description: [
    'mbc-description',
    {
      margin: '16px 48px 32px 0',
    },
  ],
  descriptionText: [
    'mbc-description-text',
    {
      fontSize: 14,
      lineHeight: 21,
      wordBreak: 'break-all',
      letterSpacing: '-0.08px',
    },
  ],
  descriptionLabel: [
    'mbc-description-label',
    {
      fontSize: 16,
      lineHeight: 22,
      fontWeight: 600,
    },
  ],
  subComponentStyles: {
    page: {
      cards: [
        'mbc-search-model-cards',
        getFlexRowStyles(),
        {
          flexWrap: 'wrap',
          marginLeft: 72,
          marginRight: 48,
          selectors: {
            '.mbc-content-card': {
              marginLeft: 24,
              marginBottom: 24,
            },
          },
        },
      ],
    },
  },
});
