import {
  DevopsState,
  DevopsActionTypes,
  ADD_INDEX_COMMIT,
  SET_INDEX_COMMITS,
  SET_INDEX_DEPLOYMENTS,
  RESET_INDEX_COMMITS_DEPLOYMENTS,
} from '../types/devOps.d';

const initialState: DevopsState = {
  commits: [],
  deployments: [],
};

export function devopsReducer(state = initialState, action: DevopsActionTypes): DevopsState {
  switch (action.type) {
    case ADD_INDEX_COMMIT: {
      return {
        ...state,
        commits: state.commits.concat(action.commit),
      };
    }
    case SET_INDEX_COMMITS: {
      return {
        ...state,
        commits: action.commits,
      };
    }
    case SET_INDEX_DEPLOYMENTS: {
      return {
        ...state,
        deployments: action.deployments,
      };
    }
    case RESET_INDEX_COMMITS_DEPLOYMENTS: {
      return {
        ...state,
        commits: [],
        deployments: [],
      };
    }

    default:
      return state;
  }
}
