import { BaseRequestInterceptor } from './BaseRequestInterceptor';
import { FetchRequest } from '../Types';
import { AddHeader } from '../Utils';

export class AdminServiceInterceptor extends BaseRequestInterceptor {
  constructor() {
    super(/^https:\/\/commerce.bing.com\/(ca\/)?api\/admin\/.*$/);
  }

  PerformAction(request: FetchRequest): Promise<FetchRequest> {
    if (request.isPublic) {
      return Promise.resolve(request);
    }
    return window.getIdToken().then(idToken => {
      return AddHeader(request, 'Authorization', `Bearer ${idToken}`);
    });
  }
}
