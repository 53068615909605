export const Languages = [
  { text: 'Arabic [ar]', key: 'ar' },
  { text: 'Basque [eu]', key: 'eu' },
  { text: 'Bengali [bn]', key: 'bn' },
  { text: 'Bulgarian [bg]', key: 'bg' },
  { text: 'Catalan [ca]', key: 'ca' },
  { text: 'Chinese (Simplified) [zh-hans]', key: 'zh-hans' },
  { text: 'Chinese (Traditional) [zh-hant]', key: 'zh-hant' },
  { text: 'Croatian [hr]', key: 'hr' },
  { text: 'Czech [cs]', key: 'cs' },
  { text: 'Danish [da]', key: 'da' },
  { text: 'Dutch [nl]', key: 'nl' },
  { text: 'English [en]', key: 'en' },
  { text: 'English-United Kingdom [en-gb]', key: 'en-gb' },
  { text: 'Estonian [et]', key: 'et' },
  { text: 'Finnish [fi]', key: 'fi' },
  { text: 'French [fr]', key: 'fr' },
  { text: 'Galician [gl]', key: 'gl' },
  { text: 'German [de]', key: 'de' },
  { text: 'Gujarati [gu]', key: 'gu' },
  { text: 'Hebrew [he]', key: 'he' },
  { text: 'Hindi [hi]', key: 'hi' },
  { text: 'Hungarian [hu]', key: 'hu' },
  { text: 'Icelandic [is]', key: 'is' },
  { text: 'Italian [it]', key: 'it' },
  { text: 'Japanese [jp]', key: 'jp' },
  { text: 'Kannada [kn]', key: 'kn' },
  { text: 'Korean [ko]', key: 'ko' },
  { text: 'Latvian [lv]', key: 'lv' },
  { text: 'Lithuanian [lt]', key: 'lt' },
  { text: 'Malay [ms]', key: 'ms' },
  { text: 'Malayalam  [ml]', key: 'ml' },
  { text: 'Marathi [mr]', key: 'mr' },
  { text: 'Norwegian (Bokmål) [nb]', key: 'nb' },
  { text: 'Polish [pl]', key: 'pl' },
  { text: 'Portuguese (Brazil) [pt-br]', key: 'pt-br' },
  { text: 'Portuguese (Portugal) [pt-pt]', key: 'pt-pt' },
  { text: 'Punjabi [pa]', key: 'pa' },
  { text: 'Romanian [ro]', key: 'ro' },
  { text: 'Russian [ru]', key: 'ru' },
  { text: 'Serbian (Cyrylic) [sr]', key: 'sr' },
  { text: 'Slovak [sk]', key: 'sk' },
  { text: 'Slovenian [sl]', key: 'sl' },
  { text: 'Spanish [es]', key: 'es' },
  { text: 'Swedish [sv]', key: 'sv' },
  { text: 'Tamil [ta]', key: 'ta' },
  { text: 'Telugu [te]', key: 'te' },
  { text: 'Thai [th]', key: 'th' },
  { text: 'Turkish [tr]', key: 'tr' },
  { text: 'Ukrainian [uk]', key: 'uk' },
  { text: 'Vietnamese [vi]', key: 'vi' },
];
