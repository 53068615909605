import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Stack, PrimaryButton, StackItem } from 'office-ui-fabric-react';
import NavBar from './nav-bar/NavBar';
import Footer from './footer/Footer';
import styles from './LandingPage.module.scss';
import { ItemTag } from '../common/item-tag/itemTag';
import { showOobeDemo } from '../../../store/actions/oobeDemoActions';
import { connect } from 'react-redux';

interface LandingPageProps extends WithTranslation {
  showOobeDemo: Function;
}

class LandingPage extends Component<LandingPageProps> {
  render() {
    const { t } = this.props;
    return (
      <Stack>
        <NavBar />
        <Stack verticalAlign={'space-between'} className={styles.homepageBodyContainer}>
          <div className={styles.homepageBackground}></div>
          <Stack verticalAlign={'center'} className={styles.homepageBody} role="main">
            <div className={styles.homepageBodyTitle}>
              {t('homepage.header.title')}
              <ItemTag tag={t('homepage.preview-tag')} />
            </div>
            <div className={styles.homepageBodyStartButton}>
              <PrimaryButton className={styles.TryItButton} onClick={() => this.props.showOobeDemo()}>
                {t('homepage.header.button-go-to-tenants')}
              </PrimaryButton>
            </div>
          </Stack>
          <StackItem className={styles.footerContainer}>
            <Footer></Footer>
          </StackItem>
        </Stack>
      </Stack>
    );
  }
}

export const LandingPageWithTranslation = withTranslation()(LandingPage);
export default connect(
  null,
  { showOobeDemo },
)(LandingPageWithTranslation);
