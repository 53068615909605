export enum TickType {
  Default,
  Numerical,
  Categorical,
  DateTime,
  DateTime12Hrs,
  Percentage,
}

/**
 * Defines metric card props
 */
export interface AxisTickFormatterProps {
  payload: any;

  points: any;

  isXAxis: boolean;

  fontWeight?: number;

  fontSize?: number;

  fill?: string;

  // Optional parameters for tick position

  dx?: number;

  dy?: number;

  textAnchor?: 'start' | 'end' | 'middle';

  // Optional parameters tick rotation
  blockRotation?: boolean;

  rotationAngle?: number;

  rotationBreakPoint?: number;

  rotatedFontSize?: number;

  // Optional prameters if tick will be cropped after exceeding a certain length
  maximumTextLength?: number;

  tickType?: TickType;
}
