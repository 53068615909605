import { SearchRequestsStyleProps, SearchRequestsStyles } from './SearchRequests.types';

export const styles = (props: SearchRequestsStyleProps): SearchRequestsStyles => {
  return {
    root: [
      'mbc-search-requests-chart',
      {
        selectors: {
          '.mbc-legend-container': {
            paddingLeft: 2,
          },
        },
      },
      props.className,
    ],
  };
};
