import {
  ADD_BREADCRUMB_ITEM,
  UPDATE_BREADCRUMB_ITEM,
  REMOVE_BREADCRUMB_ITEM,
  CLEAR_BREADCRUMB_ITEMS,
  BreadcrumbStateActionTypes,
  BreadcrumbState,
} from '../types/breadcrumbs.d';

const initialState: BreadcrumbState = {
  breadcrumbItems: [],
};

export function breadcrumbsReducer(state = initialState, action: BreadcrumbStateActionTypes): BreadcrumbState {
  switch (action.type) {
    case ADD_BREADCRUMB_ITEM: {
      return {
        ...state,
        breadcrumbItems: state.breadcrumbItems.concat(action.breadcrumbItem),
      };
    }
    case UPDATE_BREADCRUMB_ITEM: {
      return {
        ...state,
        breadcrumbItems: state.breadcrumbItems.map(item =>
          item.key === action.breadcrumbItem.key ? { ...action.breadcrumbItem } : item,
        ),
      };
    }
    case REMOVE_BREADCRUMB_ITEM: {
      return {
        ...state,
        breadcrumbItems: state.breadcrumbItems.filter(item => item.key !== action.breadcrumbItemKey),
      };
    }
    case CLEAR_BREADCRUMB_ITEMS: {
      return {
        ...state,
        breadcrumbItems: [],
      };
    }
    default:
      return state;
  }
}
