import {
  SearchBox,
  Callout,
  FocusZone,
  FocusZoneDirection,
  List,
  DirectionalHint,
  Text,
  classNamesFunction,
  ISearchBox,
} from 'office-ui-fabric-react';
import _ from 'lodash';
import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { SuggestionItem } from '../../../store/types/autosuggest';
import { AutosuggestProps, AutosuggestStyleProps, AutosuggestStyles } from './AutoSuggest.types';
import { useTranslation } from 'react-i18next';

const getClassNames = classNamesFunction<AutosuggestStyleProps, AutosuggestStyles>();

const SEGMENT_END_MARK = '\ue001';
const SEGMENT_START_MARK = '\ue000';
const SuggestionMatchRegExp = new RegExp(`[${SEGMENT_START_MARK}${SEGMENT_END_MARK}]`);

export const AutosuggestBase = (props: AutosuggestProps) => {
  const { styles, theme, className, autosuggest, defaultValue, suggestions, onSearch } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const { t } = useTranslation();

  const calloutRef = useRef<Callout>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const searchboxRef = useRef<ISearchBox>(null);

  const autosuggestDebounced = _.debounce(autosuggest, 500);

  const [hideCallout, setHideCallout] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(defaultValue);

  const onSelectSuggestion = useCallback(
    (value: string | undefined) => {
      onSearch(value);
      setSearchQuery(value);
      autosuggestDebounced(value);
    },
    [autosuggestDebounced, onSearch],
  );

  useEffect(() => {
    defaultValue !== undefined && defaultValue !== searchQuery && setSearchQuery(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onRenderSuggestionItem = useCallback(
    (item?: SuggestionItem) => {
      if (!item) {
        return null;
      }

      return (
        <div
          key={item.key}
          data-is-focusable
          className={classNames.calloutItem}
          onClick={() => onSelectSuggestion(item.searchValue)}
        >
          <Text>
            {item.displayValue
              .split(SuggestionMatchRegExp)
              .filter(segment => segment.length)
              .map((segment, index) => {
                return (
                  <Text
                    key={index}
                    className={
                      (index % 2 === 0 && item.displayValue[0] === SEGMENT_START_MARK) ||
                      (index % 2 !== 0 && item.displayValue[0] !== SEGMENT_START_MARK)
                        ? undefined
                        : classNames.suggestion
                    }
                  >
                    {segment}
                  </Text>
                );
              })}
          </Text>
        </div>
      );
    },
    [classNames.calloutItem, classNames.suggestion, onSelectSuggestion],
  );

  const SuggestionsCallout = useMemo(() => {
    return (
      <Callout
        gapSpace={5}
        ref={calloutRef}
        coverTarget={false}
        hidden={hideCallout}
        isBeakVisible={false}
        alignTargetEdge={true}
        target={rootRef.current}
        directionalHint={DirectionalHint.bottomLeftEdge}
        calloutWidth={rootRef && rootRef.current ? rootRef.current.clientWidth : 0}
      >
        <FocusZone direction={FocusZoneDirection.vertical}>
          <List tabIndex={0} items={suggestions} onRenderCell={onRenderSuggestionItem} />
        </FocusZone>
      </Callout>
    );
  }, [hideCallout, onRenderSuggestionItem, suggestions]);

  const onShowCallout = useCallback(() => setHideCallout(false), []);
  const onHideCallout = useCallback(() => setTimeout(() => setHideCallout(true), 300), []);
  const onChangeSearchQuery = useCallback(
    (ev, value: string | undefined) => {
      setSearchQuery(value);
      autosuggestDebounced(value);
    },
    [autosuggestDebounced],
  );

  const onClearSearchQuery = useCallback(() => onChangeSearchQuery(undefined, ''), [onChangeSearchQuery]);

  return (
    <div ref={rootRef} className={classNames.root}>
      <SearchBox
        underlined
        id={'searchbox'}
        value={searchQuery}
        defaultValue={defaultValue}
        onSearch={onSearch}
        onBlur={onHideCallout}
        onFocus={onShowCallout}
        componentRef={searchboxRef}
        onClear={onClearSearchQuery}
        onChange={onChangeSearchQuery}
        placeholder={t('common.search-placeholder')}
      />
      {SuggestionsCallout}
    </div>
  );
};
