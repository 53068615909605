import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import {
  getCompetitiveSearchResult,
  getSearchResultWithQueryAlteration,
  getPromotedSearchResultWithQueryAlteration,
} from '../../restful-apis/search.api';
import {
  GET_RELEVANCE_A_SEARCH_RESULT,
  GET_RELEVANCE_B_SEARCH_RESULT,
  GET_PERSONALIZATION_A_SEARCH_RESULT,
  GET_PERSONALIZATION_B_SEARCH_RESULT,
  BoostItem,
  GET_PROMOTED_B_SEARCH_RESULTS,
  GET_PROMOTED_A_SEARCH_RESULTS,
  BoostType,
  BoostOperator,
  GET_SEARCH_RESULT,
  RESET_SEARCH_RESULT,
  SearchDemoStateActionTypes,
  GET_AUGMENTED_SEARCH_RESULT,
} from '../types/searchDemo.d';
import { FacetOption } from '../../restful-apis/dto/search/facet';
import { getSearchResults } from '../../restful-apis/queryTester.api';
import { Augmentations } from '../../pages/ProductSearch/common/SearchPreview/SearchPrevew.utils';
import { showProgressIndicator, hideProgressIndicator } from './progressIndicatorActions';
import { SearchPostRequest } from '../../utils/customizations/search/searchRequest';
import _ from 'lodash';

export const getRelevanceSearchResult = (query: string): ThunkAction<void, AppState, null, Action> => dispatch => {
  getCompetitiveSearchResult(query)
    .then(searchResult => {
      dispatch({
        type: GET_RELEVANCE_A_SEARCH_RESULT,
        relevanceASearchResults: searchResult,
      });
    })
    .catch(() =>
      dispatch({
        type: GET_RELEVANCE_A_SEARCH_RESULT,
        relevanceASearchResults: {
          resultCount: 0,
          alteredQuery: query,
          originalQuery: query,
          searchResultItems: [],
        },
      }),
    );

  getSearchResultWithQueryAlteration(query)
    .then(searchResult => {
      dispatch({
        type: GET_RELEVANCE_B_SEARCH_RESULT,
        relevanceBSearchResults: searchResult,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_RELEVANCE_B_SEARCH_RESULT,
        relevanceBSearchResults: {
          resultCount: 0,
          alteredQuery: query,
          originalQuery: query,
          searchResultItems: [],
        },
      });
    });
};

export const getPersonalizationSearchResult = (
  query: string,
): ThunkAction<void, AppState, null, Action> => dispatch => {
  const boostItem: BoostItem = {
    key: 'Gender',
    value: 'female',
    type: BoostType.String,
    operator: BoostOperator.Eq,
  };

  getCompetitiveSearchResult(query)
    .then(searchResult => {
      dispatch({
        type: GET_PERSONALIZATION_A_SEARCH_RESULT,
        personalizationASearchResults: searchResult,
      });
    })
    .catch(() =>
      dispatch({
        type: GET_PERSONALIZATION_A_SEARCH_RESULT,
        personalizationASearchResults: {
          resultCount: 0,
          alteredQuery: query,
          originalQuery: query,
          searchResultItems: [],
        },
      }),
    );
  getPromotedSearchResultWithQueryAlteration(boostItem, true)
    .then(searchResult => {
      dispatch({
        type: GET_PERSONALIZATION_B_SEARCH_RESULT,
        personalizationBSearchResults: searchResult,
      });
    })
    .catch(() =>
      dispatch({
        type: GET_PERSONALIZATION_B_SEARCH_RESULT,
        personalizationBSearchResults: {
          resultCount: 0,
          alteredQuery: query,
          originalQuery: query,
          searchResultItems: [],
        },
      }),
    );
};

export const getPromotedSearchResult = (
  query: string,
  boostItem: BoostItem,
): ThunkAction<void, AppState, null, Action> => dispatch => {
  getPromotedSearchResultWithQueryAlteration(boostItem)
    .then(searchResult => {
      dispatch({
        type: GET_PROMOTED_B_SEARCH_RESULTS,
        promotedBSearchResults: searchResult,
      });
    })
    .catch(() =>
      dispatch({
        type: GET_PROMOTED_B_SEARCH_RESULTS,
        promotedBSearchResults: {
          resultCount: 0,
          alteredQuery: query,
          originalQuery: query,
          searchResultItems: [],
        },
      }),
    );

  getCompetitiveSearchResult(query)
    .then(searchResult => {
      dispatch({
        type: GET_PROMOTED_A_SEARCH_RESULTS,
        promotedASearchResults: searchResult,
      });
    })
    .catch(() =>
      dispatch({
        type: GET_PROMOTED_A_SEARCH_RESULTS,
        promotedASearchResults: {
          resultCount: 0,
          alteredQuery: query,
          originalQuery: query,
          searchResultItems: [],
        },
      }),
    );
};

export const resetSearchResult = (): SearchDemoStateActionTypes => ({
  type: RESET_SEARCH_RESULT,
});

const getSearchResultInternal = (
  skip: number,
  top: number,
  query: string | undefined,
  facets: FacetOption[],
  augmentations?: Augmentations,
  augmentedPostBody?: SearchPostRequest,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const tenantId = getState().tenantsList.activeTenant.id;
  const searchIndex = getState().searchIndex.searchIndex;
  const searchInstanceId = getState().searchInstanceList.activeSearchInstance.id;
  const isOobe = getState().tenantsList.activeTenant.isOobe;

  if (!!searchInstanceId) {
    dispatch(showProgressIndicator());
    getSearchResults(
      skip,
      top,
      query,
      tenantId,
      searchIndex,
      searchInstanceId,
      facets,
      augmentations,
      augmentedPostBody,
      isOobe,
    )
      .then(searchResult =>
        dispatch({
          type: !!augmentations || !!augmentedPostBody ? GET_AUGMENTED_SEARCH_RESULT : GET_SEARCH_RESULT,
          searchResult: searchResult,
        }),
      )
      .catch(() =>
        dispatch({
          type: !!augmentations || !!augmentedPostBody ? GET_AUGMENTED_SEARCH_RESULT : GET_SEARCH_RESULT,
          searchResult: {
            query: query,
            isLoading: false,
            alteredQuery: query,
            searchResultItems: [],
            totalEstimatedMatches: 0,
          },
        }),
      )
      .finally(() => {
        dispatch(hideProgressIndicator());
      });
  }
};

export const getSearchResult = (
  skip: number,
  top: number,
  query: string | undefined,
  facets: FacetOption[],
): ThunkAction<void, AppState, null, Action> => getSearchResultInternal(skip, top, query, facets);

export const getAugmentedSearchResult = (
  skip: number,
  top: number,
  query: string | undefined,
  facets: FacetOption[],
  augmentations: Augmentations | undefined,
  augmentedPostBody?: SearchPostRequest,
): ThunkAction<void, AppState, null, Action> => {
  const postBody = augmentedPostBody ? _.cloneDeep(augmentedPostBody) : undefined;
  return getSearchResultInternal(skip, top, query, facets, augmentations, postBody);
};
