import React, { useState } from 'react';
import {
  ImageFit,
  Image,
  classNamesFunction,
  Stack,
  CommandBarButton,
  IconButton,
  ImageLoadState,
} from 'office-ui-fabric-react';
import { ProductItemCardProps, ProductItemCardStyleProps, ProductItemCardStyles } from '../ProductItemCard.types';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../../store/hooks';
import { splitIntegerDecimal } from '../../../../utils';
import { MbcClamp } from '../../MbcClamp';

const ALTERNATIVE_IMAGE_URL = 'https://www.beautycounter.com/images/placeholder.jpg';

const getClassNames = classNamesFunction<ProductItemCardStyleProps, ProductItemCardStyles>();

export function ProductItemCardBase(props: ProductItemCardProps): React.ReactElement {
  const { styles, theme, item, actionProps, onClick, onDismiss } = props;

  const { t } = useTranslation();

  const activeIndex = useSelector(state => state.environmentList.activeEnvironment.indexId);
  const activeTenant = useSelector(state => state.tenantsList.activeTenant.id);

  const nonEditableProductSet = useSelector(state => state.productSearch.nonEditableProductSet);

  const enabled = !nonEditableProductSet.has(item.id);
  const classNames = getClassNames(styles, {
    theme: theme!,
    enabled: enabled,
    interactable: !!onClick,
    hasActions: !!actionProps,
  });

  const { integer, decimal } = splitIntegerDecimal(parseFloat(item.price || '0'));

  const [imageUrl, setImageUrl] = useState<string>(item.imgUrl || ALTERNATIVE_IMAGE_URL);

  return (
    <Stack className={classNames.root} tokens={{ childrenGap: 8 }} onClick={enabled ? onClick : undefined}>
      <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} style={{ minHeight: '32px' }}>
        <div className={classNames.id}>{item.id}</div>
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          {actionProps && (
            <>
              <CommandBarButton
                key="edit"
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  actionProps.onEditClick && actionProps.onEditClick();
                }}
                text={t('common.edit')}
                iconProps={{ iconName: 'Edit' }}
                disabled={!actionProps.onEditClick || !enabled}
              />
              <IconButton
                key="delete"
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  actionProps.onRemoveClick && actionProps.onRemoveClick();
                }}
                text={t('common.delete')}
                iconProps={{ iconName: 'Delete' }}
                disabled={!actionProps.onRemoveClick || !enabled}
              />
            </>
          )}
          {onDismiss && (
            <IconButton
              key="dimiss"
              onClick={(ev: any) => {
                ev.stopPropagation();
                onDismiss();
              }}
              iconProps={{ iconName: 'Cancel' }}
            />
          )}
        </Stack>
      </Stack>
      <Stack grow horizontal verticalAlign={'center'} horizontalAlign={'space-between'}>
        <Stack verticalAlign={'center'} tokens={{ childrenGap: 8, maxWidth: '70%' }}>
          {item.name && <MbcClamp text={item.name} lines={1} className={classNames.name} />}
          {item.url && <MbcClamp clickable text={item.url} lines={1} className={classNames.url} />}
          {item.description && <MbcClamp text={item.description} lines={2} className={classNames.description} />}
          {integer && (
            <div className={classNames.priceText}>
              <div>
                <span>
                  {activeTenant === '008bad85-dc86-46b4-ae5d-da85c77f5d24' ||
                  activeIndex === '5800ada6-115e-41a8-8d7c-ee42f7c2f550'
                    ? '£'
                    : '$'}
                </span>
                <span className={classNames.integerText}>{integer}</span>
                {decimal > 0 && <sup className={classNames.superscriptPriceText}>{decimal}</sup>}
              </div>
            </div>
          )}
        </Stack>
        <Image
          src={imageUrl}
          alt={item.name}
          imageFit={ImageFit.cover}
          onLoadingStateChange={state =>
            state === ImageLoadState.error && imageUrl !== ALTERNATIVE_IMAGE_URL && setImageUrl(ALTERNATIVE_IMAGE_URL)
          }
          styles={classNames.subComponentStyles.imageStyles}
        />
      </Stack>
    </Stack>
  );
}
