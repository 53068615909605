import { SectionStyles, SectionStyleProps } from './Section.types';

export const styles = (props: SectionStyleProps): SectionStyles => {
  const { className, theme } = props;

  return {
    root: [{ maxWidth: '65%' }, className],
    title: [
      {
        fontSize: '20px',
        lineHeight: '28px',
      },
    ],
    caption: [
      {
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.neutralSecondary,
      },
    ],
  };
};
