import { IconButton, classNamesFunction, Stack, Toggle } from 'office-ui-fabric-react';
import React, { useEffect, useState, useMemo, useCallback, createRef, useRef } from 'react';
import {
  SearchPreviewProps,
  SearchPreviewStyleProps,
  SearchPreviewStyles,
  SearchViewMode,
} from './SearchPreview.types';
import { Facets } from './Facets';
import { SearchResultCards } from './SearchResultCards';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { CodeEditor } from '../../../../components/common/CodeEditor';
import { ContentCard } from '../../../../components/common/ContentCard';
import { FacetOption } from '../../../../restful-apis/dto/search/facet';
import {
  getSearchResult,
  resetSearchResult,
  getAugmentedSearchResult,
} from '../../../../store/actions/searchDemoActions';
import { NoResultsView } from '../../../../components/NoResultsView/NoResultsView';
import { SharedSettings } from '../../QueryTester/QueryTester.types';
import { NoItemsToDisplay } from '../../../../components/common/NoItemsToDisplay';
import { useTranslation } from 'react-i18next';
import { Autosuggest } from '../../../../components/common/AutoSuggest';
import { getServingSuggestions } from '../../../../restful-apis/autosuggest.api';
import { SuggestionItem } from '../../../../store/types/autosuggest';
import _ from 'lodash';
import { Tutorial, TutorialSteps } from '../../../../components/Tutorials';
import { AvailableTutorials } from '../../../../store/types/tutorial.d';
import { useResizeObserver } from '../../../../store/hooks/useResizeObserver';

const TOP_SEARCH_RESULT_COUNT = 10;
const FIRST_PAGE_RESULT_NUMBER = 1;

const getClassNames = classNamesFunction<SearchPreviewStyleProps, SearchPreviewStyles>();

export const SearchPreviewBase = (props: SearchPreviewProps): JSX.Element => {
  const {
    styles,
    theme,
    sortBy,
    className,
    componentRef,
    augmentations,
    augmentedPostBody,
    hidePostBody,
    onSharedSettingsChange,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const searchBoxRef = createRef<HTMLDivElement>();
  const toggleButtonRef = createRef<HTMLDivElement>();

  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(containerRef);
  const isSmallScreen: boolean = useMemo(() => width < 700, [width]);

  const indexId = useSelector(state => state.searchIndex.searchIndex.id);
  const tenantId = useSelector(state => state.tenantsList.activeTenant.id);
  const searchResult = useSelector(state => state.searchDemo.searchResult);
  const augmentedSearchResult = useSelector(state => state.searchDemo.augmentedSearchResult);
  const activeSearchInstanceId = useSelector(state => state.searchInstanceList.activeSearchInstance.id);
  const currentTutorial = useSelector(state => state.tutorial.currentTutorial);

  const [autosuggestions, setAutosuggestions] = useState<SuggestionItem[]>([]);

  const [selectedFacets, setSelectedFacets] = useState<FacetOption[]>([]);
  const [selectedAugmentedFacets, setSelectedAugmentedFacets] = useState<FacetOption[]>([]);

  const [selectedPage, setSelectedPage] = useState<number>(FIRST_PAGE_RESULT_NUMBER);
  const [selectedAugmentedPage, setSelectedAugmentedPage] = useState<number>(FIRST_PAGE_RESULT_NUMBER);

  const [showAugmentedResults, setShowAugmentedResults] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    augmentedPostBody ? augmentedPostBody.query.matchAll : '',
  );
  const [selectedViewMode, setSelectedViewMode] = useState<SearchViewMode>(SearchViewMode.Grid);

  const defaultSharedSettings = useMemo<SharedSettings>(() => {
    return {
      query: augmentedPostBody && augmentedPostBody.query.matchAll ? augmentedPostBody.query.matchAll : '',
      top: augmentedPostBody && augmentedPostBody.items.top ? augmentedPostBody.items.top : TOP_SEARCH_RESULT_COUNT,
      skip: augmentedPostBody && augmentedPostBody.items.skip ? augmentedPostBody.items.skip : 0,
    };
  }, [augmentedPostBody]);

  useEffect(() => {
    return () => {
      dispatch(resetSearchResult());
    };
  }, [dispatch]);

  const onUpdateAugmentedSearchResult = useCallback(
    (page: number, query: string | undefined, facets: FacetOption[], skip?: number) => {
      const postBody =
        !!augmentedPostBody && (!!augmentedPostBody.query.matchAll || !!augmentedPostBody.query.filter)
          ? augmentedPostBody
          : undefined;
      if (postBody || (!!augmentations && !!augmentations.filterCondition) || !!query) {
        dispatch(
          getAugmentedSearchResult(
            TOP_SEARCH_RESULT_COUNT * (page - 1),
            TOP_SEARCH_RESULT_COUNT,
            query,
            facets,
            augmentations,
            skip !== undefined && !!postBody ? { ...postBody, items: { ...postBody.items, skip } } : postBody,
          ),
        );
      }
    },
    [augmentations, augmentedPostBody, dispatch],
  );

  const onUpdateSearchResult = useCallback(
    (page: number, query: string | undefined, facets: FacetOption[]) => {
      !!query &&
        dispatch(getSearchResult(TOP_SEARCH_RESULT_COUNT * (page - 1), TOP_SEARCH_RESULT_COUNT, query, facets));
    },
    [dispatch],
  );

  const onSearchQueryChanged = useCallback(
    (query: string | undefined) => {
      setSelectedFacets([]);
      setSelectedAugmentedFacets([]);

      setSelectedPage(FIRST_PAGE_RESULT_NUMBER);
      setSelectedAugmentedPage(FIRST_PAGE_RESULT_NUMBER);

      setSearchQuery(query || '');
      onSharedSettingsChange && onSharedSettingsChange({ ...defaultSharedSettings, query: query || '', skip: 0 });

      if (!augmentedPostBody) {
        onUpdateSearchResult(FIRST_PAGE_RESULT_NUMBER, query, []);
        !onSharedSettingsChange && onUpdateAugmentedSearchResult(FIRST_PAGE_RESULT_NUMBER, query, []);
      }
    },
    [
      augmentedPostBody,
      defaultSharedSettings,
      onSharedSettingsChange,
      onUpdateAugmentedSearchResult,
      onUpdateSearchResult,
    ],
  );

  useEffect(() => {
    if (!!augmentedPostBody) {
      const augmentedPage = Math.round(defaultSharedSettings.skip / defaultSharedSettings.top) + 1;
      setSelectedPage(FIRST_PAGE_RESULT_NUMBER);
      setSelectedAugmentedPage(augmentedPage);
      setSearchQuery(defaultSharedSettings.query);

      onUpdateSearchResult(FIRST_PAGE_RESULT_NUMBER, defaultSharedSettings.query, []);
      onUpdateAugmentedSearchResult(FIRST_PAGE_RESULT_NUMBER, defaultSharedSettings.query, selectedAugmentedFacets);
    } else {
      onUpdateAugmentedSearchResult(FIRST_PAGE_RESULT_NUMBER, searchQuery, selectedAugmentedFacets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [augmentedPostBody, augmentations]);

  useEffect(() => {
    onUpdateSearchResult(FIRST_PAGE_RESULT_NUMBER, searchQuery, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSearchInstanceId]);

  useEffect(() => {
    if (!!!searchQuery && !(augmentations && !!augmentations.filterCondition)) {
      dispatch(resetSearchResult());
    }
  }, [augmentations, dispatch, searchQuery]);

  const onSelectedAugmentedPageChanged = useCallback(
    (page: number) => {
      if (!!augmentedPostBody) {
        onSharedSettingsChange &&
          onSharedSettingsChange({ ...defaultSharedSettings, skip: defaultSharedSettings.top * (page - 1) });
      } else {
        setSelectedAugmentedPage(page);
        onUpdateAugmentedSearchResult(page, searchQuery, selectedAugmentedFacets);
      }
    },
    [
      augmentedPostBody,
      defaultSharedSettings,
      onSharedSettingsChange,
      onUpdateAugmentedSearchResult,
      searchQuery,
      selectedAugmentedFacets,
    ],
  );

  const onSelectedPageChanged = useCallback(
    (page: number) => {
      setSelectedPage(page);
      onUpdateSearchResult(page, searchQuery, selectedFacets);
    },
    [onUpdateSearchResult, selectedFacets, searchQuery],
  );

  const onSelectedPageChangedProxy = useCallback(
    (page: number) => (showAugmentedResults ? onSelectedAugmentedPageChanged(page) : onSelectedPageChanged(page)),
    [onSelectedAugmentedPageChanged, onSelectedPageChanged, showAugmentedResults],
  );

  const onSelectedAugmentedFacetsChanged = useCallback(
    (facets: FacetOption[]) => {
      setSelectedAugmentedFacets(facets);
      setSelectedAugmentedPage(FIRST_PAGE_RESULT_NUMBER);
      !!onSharedSettingsChange && onSharedSettingsChange({ ...defaultSharedSettings, skip: 0 });
      onUpdateAugmentedSearchResult(FIRST_PAGE_RESULT_NUMBER, searchQuery, facets, 0);
    },
    [defaultSharedSettings, onSharedSettingsChange, onUpdateAugmentedSearchResult, searchQuery],
  );

  const onSelectedFacetsChanged = useCallback(
    (facets: FacetOption[]) => {
      setSelectedFacets(facets);
      setSelectedPage(FIRST_PAGE_RESULT_NUMBER);
      onUpdateSearchResult(FIRST_PAGE_RESULT_NUMBER, searchQuery, facets);
    },
    [onUpdateSearchResult, searchQuery],
  );

  const onSelectedFacetsChangedProxy = useCallback(
    (facets: FacetOption[]) =>
      showAugmentedResults ? onSelectedAugmentedFacetsChanged(facets) : onSelectedFacetsChanged(facets),
    [onSelectedAugmentedFacetsChanged, onSelectedFacetsChanged, showAugmentedResults],
  );

  const autosuggest = useCallback(
    (query: string | undefined) =>
      getServingSuggestions(tenantId, indexId, query || '')
        .then(items => setAutosuggestions(items))
        .catch(() => setAutosuggestions([])),
    [indexId, tenantId],
  );

  // TODO: Move SearchPreviewHeader to a separate component
  const SearchPreviewHeader = useMemo(
    () => (
      <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'baseline'} tokens={{ childrenGap: 16 }}>
        <div ref={searchBoxRef}>
          <Autosuggest
            suggestions={autosuggestions}
            defaultValue={searchQuery}
            autosuggest={autosuggest}
            onSearch={onSearchQueryChanged}
          />
        </div>
        <Stack horizontal={!isSmallScreen} tokens={{ childrenGap: isSmallScreen ? 8 : 16 }}>
          <div
            ref={toggleButtonRef}
            className={
              [AvailableTutorials.QueryTester, AvailableTutorials.BusinessRulesFull].includes(currentTutorial)
                ? classNames.tutorialToggle
                : undefined
            }
          >
            <Toggle
              key={'treatment-toggle'}
              styles={{
                root: { marginBottom: 0, justifyContent: 'flex-end' },
                label: { marginLeft: 5, fontWeight: 400 },
              }}
              checked={showAugmentedResults}
              label={'Treatment'}
              inlineLabel
              onChange={(ev, checked) => setShowAugmentedResults(!!checked)}
            />
          </div>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <IconButton
              key="grid-view"
              iconProps={{ iconName: 'GridViewMedium' }}
              disabled={selectedViewMode === SearchViewMode.Grid}
              onClick={() => setSelectedViewMode(SearchViewMode.Grid)}
            />
            <IconButton
              key="list-view"
              iconProps={{ iconName: 'List' }}
              disabled={selectedViewMode === SearchViewMode.List}
              onClick={() => setSelectedViewMode(SearchViewMode.List)}
            />
            <IconButton
              key="raw-view"
              iconProps={{ iconName: 'Embed' }}
              disabled={selectedViewMode === SearchViewMode.Raw}
              onClick={() => setSelectedViewMode(SearchViewMode.Raw)}
            />
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      searchBoxRef,
      autosuggestions,
      searchQuery,
      autosuggest,
      onSearchQueryChanged,
      isSmallScreen,
      toggleButtonRef,
      currentTutorial,
      classNames.tutorialToggle,
      showAugmentedResults,
      selectedViewMode,
    ],
  );

  const selectedSearchResult = showAugmentedResults ? augmentedSearchResult : searchResult;
  const showSearchResults = !selectedSearchResult.isLoading;
  const hasSearchResultItems = !!selectedSearchResult.searchResultItems.length || !!selectedSearchResult.redirectUrl;

  const rawSearchResult = useMemo(() => {
    if (!!hidePostBody) {
      let searchRawResponseWithoutPostBody: any = _.cloneDeep(selectedSearchResult.searchRawResponse);
      if (!!searchRawResponseWithoutPostBody && !!searchRawResponseWithoutPostBody['postBody']) {
        delete searchRawResponseWithoutPostBody['postBody'];
      }
      return searchRawResponseWithoutPostBody;
    } else {
      return selectedSearchResult.searchRawResponse;
    }
  }, [hidePostBody, selectedSearchResult.searchRawResponse]);

  const getTutorials = useCallback(() => {
    switch (currentTutorial) {
      case AvailableTutorials.QueryTester: {
        return (
          <>
            <Tutorial
              target={searchBoxRef}
              step={TutorialSteps.QueryTesterSearch}
              headline={t('product-tours-panel.quey-tester-tour.headline2')}
            >
              <p>{t('product-tours-panel.quey-tester-tour.step-2')}</p>
            </Tutorial>

            <Tutorial
              target={toggleButtonRef}
              step={TutorialSteps.QueryTesterPreviewTreatmentToggle}
              headline={t('product-tours-panel.quey-tester-tour.headline4')}
            >
              <p>{t('product-tours-panel.quey-tester-tour.step-4')}</p>
            </Tutorial>
          </>
        );
      }
      case AvailableTutorials.BusinessRulesFull: {
        return (
          <>
            <Tutorial
              target={searchBoxRef}
              step={TutorialSteps.BusinessRulesEditorSearch}
              headline={t('product-tours-panel.business-rules-tour.headline3')}
            >
              <p>{t('product-tours-panel.business-rules-tour.step-3')}</p>
            </Tutorial>

            <Tutorial
              target={toggleButtonRef}
              step={TutorialSteps.BusinessRulesTreatmentToggle}
              headline={t('product-tours-panel.business-rules-tour.headline5')}
              lightBackground
            >
              <>
                <p>{t('product-tours-panel.business-rules-tour.step-5.pargraph1')}</p>
                <p className={classNames.tutorialSubheader}>
                  {t('product-tours-panel.business-rules-tour.step-5.pargraph2')}
                </p>
              </>
            </Tutorial>
          </>
        );
      }
    }
  }, [classNames.tutorialSubheader, currentTutorial, searchBoxRef, t, toggleButtonRef]);

  return (
    <div className={classNames.root}>
      {getTutorials()}
      <ContentCard
        componentRef={componentRef}
        styles={classNames.subComponentStyles.card}
        header={SearchPreviewHeader}
        body={
          <div ref={containerRef} className={classNames.content}>
            {showSearchResults && !hasSearchResultItems && <NoResultsView originalQuery={selectedSearchResult.query} />}
            {showSearchResults && hasSearchResultItems && (
              <div className={classNames.resultContainer}>
                <div className={classNames.scrollablePane}>
                  {selectedViewMode === SearchViewMode.Raw && (
                    <CodeEditor readOnly viewTheme={'terminal'} value={JSON.stringify(rawSearchResult, null, 2)} />
                  )}
                  {selectedViewMode !== SearchViewMode.Raw && (
                    <Stack horizontal grow verticalFill style={{ position: 'relative' }}>
                      <Facets
                        facets={_.uniqBy(selectedSearchResult.facets, a => a.field)}
                        onSelectedFacetsChanged={onSelectedFacetsChangedProxy}
                        selectedFacets={showAugmentedResults ? selectedAugmentedFacets : selectedFacets}
                      />
                      <SearchResultCards
                        searchViewMode={selectedViewMode}
                        searchResult={selectedSearchResult}
                        paginationProps={{
                          onPageUpdated: onSelectedPageChangedProxy,
                          currentPage: showAugmentedResults ? selectedAugmentedPage : selectedPage,
                          totalPageCount: showAugmentedResults
                            ? Math.ceil(selectedSearchResult.totalEstimatedMatches / defaultSharedSettings.top)
                            : Math.ceil(selectedSearchResult.totalEstimatedMatches / TOP_SEARCH_RESULT_COUNT),
                        }}
                        sortBy={sortBy}
                      />
                    </Stack>
                  )}
                </div>
              </div>
            )}
            {!showSearchResults && (
              <Stack horizontal verticalFill verticalAlign={'center'} horizontalAlign={'center'}>
                <NoItemsToDisplay iconName={'NoResultsView'} text={t('search-preview.no-items-to-display-text')} />
              </Stack>
            )}
          </div>
        }
      />
    </div>
  );
};
