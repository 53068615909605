import { BannerEditorStyles, BannerEditorStyleProps } from './BannerEditor.types';

export const styles = (props: BannerEditorStyleProps): BannerEditorStyles => {
  const { className } = props;
  return {
    root: ['mbc-BannerEditor', {}, className],
    subComponentStyles: {
      ChoiceGroup: {
        flexContainer: {
          display: 'flex',
          flexDirection: 'row',
          selectors: { '.ms-ChoiceField': { marginRight: 14 } },
        },
      },
    },
  };
};
