import { DateTimeStyleProps, DateTimeStyles } from './DateTimePicker.types';

export const styles = (props: DateTimeStyleProps): DateTimeStyles => {
  return {
    root: ['mbc-date-time-picker', props.className],
    label: ['mbc-picker-label', { margin: '5px 0' }],
    textField: [
      'mbc-time-field',
      {
        selectors: {
          '.ms-TextField-suffix': {
            fontWeight: 400,
            fontSize: 12,
            lineHeight: 0,
            selectors: {
              span: {
                paddingBottom: '0px !important',
              },
            },
          },
        },
      },
    ],
    subComponentStyles: {
      datePicker: {},
    },
  };
};
