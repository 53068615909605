import {
  classNamesFunction,
  PanelType,
  Stack,
  MessageBar,
  MessageBarType,
  ActionButton,
  Modal,
} from 'office-ui-fabric-react';
import React, { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MbcSideNav } from '../../MbcSideNav';
import { HoverableSideNav } from '../../MbcSideNav/HoverableSideNav';
import { PageHeader } from '../PageHeader';
import { PageTemplateProps, PageTemplateStyleProps, PageTemplateStyles, SideNavMode } from './PageTemplate.types';
import { Breadcrumb } from '../Breadcrumb';
import { PageFooter } from '../PageFooter';
import { useSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { ContactUs } from '../ContactUs';

const getClassNames = classNamesFunction<PageTemplateStyleProps, PageTemplateStyles>();

export const PageTemplateBase = (props: PageTemplateProps) => {
  const {
    styles,
    theme,
    footerProps,
    sideNavOnClick,
    onRenderContent,
    pageHeaderProps,
    breadcrumbVisible,
    onSecondaryNavMenuRender,
    sideNavMode: sideNavModeFromProp,
  } = props;

  const i18n = useTranslation();

  const [showContactUsForm, setShowContactUsForm] = useState(false);

  const activeTenant = useSelector(state => state.tenantsList.activeTenant);

  const isLargeScreen = useMediaQuery({ query: '(min-width: 1366px)' });
  const isVeryLargeScreen = useMediaQuery({ query: '(min-width: 1440px)' });
  const isVeryVeryLargeScreen = useMediaQuery({ query: '(min-width: 1920px)' });

  const getScreenSize = (): number => {
    if (isVeryVeryLargeScreen) return 4;
    if (isVeryLargeScreen) return 3;
    if (isLargeScreen) return 2;
    return 1;
  };

  let sideNavMode = sideNavModeFromProp || SideNavMode.None;
  if (getScreenSize() === 1 && sideNavMode !== SideNavMode.None) sideNavMode = SideNavMode.Hoverable;

  const classNames = getClassNames(styles, {
    theme: theme!,
    isSplitPage: false,
    sideNavMode: sideNavMode,
    screenSize: getScreenSize(),
  });

  const secondaryNavVisivle = breadcrumbVisible || onSecondaryNavMenuRender;

  const DefaultPageTemplateContent = useMemo(
    () => (
      <Stack style={{ width: '100%', height: '100%' }}>
        {secondaryNavVisivle && (
          <Stack horizontal verticalAlign={'center'} className={classNames.secondaryNav}>
            {breadcrumbVisible && <Breadcrumb />}
            {onSecondaryNavMenuRender && onSecondaryNavMenuRender()}
          </Stack>
        )}
        <Stack horizontal horizontalAlign="center" verticalFill>
          <Stack data-test="main-container" className={classNames.mainContainer} verticalAlign={'stretch'} verticalFill>
            <PageHeader {...{ ...pageHeaderProps, shrinkTopMargin: breadcrumbVisible }} />
            {onRenderContent && onRenderContent()}
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      secondaryNavVisivle,
      classNames.secondaryNav,
      classNames.mainContainer,
      breadcrumbVisible,
      onSecondaryNavMenuRender,
      pageHeaderProps,
      onRenderContent,
    ],
  );

  const PageTemplateWithSideNavContent = useMemo(
    () => (
      <Stack horizontal>
        <div className={classNames.sideNav}>
          {(sideNavMode === SideNavMode.Expanded || sideNavMode === SideNavMode.Collapsed) && (
            <MbcSideNav
              onClick={typeof sideNavOnClick === 'undefined' ? () => {} : sideNavOnClick}
              collapsed={sideNavMode === SideNavMode.Collapsed}
            />
          )}
          {sideNavMode === SideNavMode.Hoverable && <HoverableSideNav panelType={PanelType.smallFixedNear} />}
        </div>
        <Stack style={{ width: '100%' }}>
          {secondaryNavVisivle && (
            <Stack horizontal verticalAlign={'center'} className={classNames.secondaryNav}>
              {breadcrumbVisible && <Breadcrumb />}
              {onSecondaryNavMenuRender && onSecondaryNavMenuRender()}
            </Stack>
          )}
          <Stack horizontal horizontalAlign={'center'} styles={{ root: { width: '100%' } }} verticalFill>
            <Stack data-test="main-container" className={classNames.mainContainer} verticalAlign={'stretch'}>
              <PageHeader {...{ ...pageHeaderProps, shrinkTopMargin: breadcrumbVisible }} />
              {onRenderContent && onRenderContent()}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      classNames.sideNav,
      classNames.secondaryNav,
      classNames.mainContainer,
      sideNavMode,
      sideNavOnClick,
      secondaryNavVisivle,
      breadcrumbVisible,
      onSecondaryNavMenuRender,
      pageHeaderProps,
      onRenderContent,
    ],
  );

  const PageTemplateContent = useMemo(
    () => (sideNavMode === SideNavMode.None ? DefaultPageTemplateContent : PageTemplateWithSideNavContent),
    [DefaultPageTemplateContent, PageTemplateWithSideNavContent, sideNavMode],
  );

  return (
    <>
      <Modal isOpen={showContactUsForm}>
        <ContactUs supportEmail={'DemoRequestMBC@microsoft.com'} onDismiss={() => setShowContactUsForm(false)} />
      </Modal>
      <Stack className={classNames.root}>
        <div role="main" className={classNames.allContentExceptFooter}>
          {activeTenant.isSandbox && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={false}
              actions={
                <ActionButton
                  style={{ color: '#1b51c4', height: 32, fontSize: 12 }}
                  onClick={() => setShowContactUsForm(true)}
                >
                  {i18n.t('common.sandbox-experience.btn-content')}
                </ActionButton>
              }
            >
              {i18n.t('common.sandbox-experience.content')}
            </MessageBar>
          )}
          {PageTemplateContent}
        </div>
        {footerProps && <PageFooter {...footerProps} />}
      </Stack>
    </>
  );
};
