import { FeatureGuard } from './FeatureGuard';
import React from 'react';
import { FeatureGuardProps } from './FeatureGuard.types';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';

const mapStateToProps = (state: AppState) => ({
  supportedFeatures: state.tenantsList.activeTenant.features,
});

const ConnectedTenantFeatureGuard = connect(mapStateToProps)(FeatureGuard);

export const TenantFeatureGuard: React.FC<FeatureGuardProps> = (props: FeatureGuardProps) => {
  return <ConnectedTenantFeatureGuard {...props} />;
};
