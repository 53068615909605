import React, { useCallback, useMemo } from 'react';
import { classNamesFunction, IComboBoxOption } from 'office-ui-fabric-react';
import {
  BooleanValueEditorProps,
  BooleanValueEditorStyleProps,
  BooleanValueEditorStyles,
} from './BooleanValueEditor.types';
import { MbcCompoBox } from '../../../../../components/common/MbcCompoBox';

const getClassNames = classNamesFunction<BooleanValueEditorStyleProps, BooleanValueEditorStyles>();

export const BooleanValueEditorBase = (props: BooleanValueEditorProps) => {
  const { styles, theme, className, value, disabled, onValueChange, onSubmit, onDismiss } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const options: IComboBoxOption[] = [{ key: 'true', text: 'True' }, { key: 'false', text: 'False' }];

  const selectedOptionKey = useMemo((): string | undefined => {
    if (value === undefined) return undefined;
    const selectedOption = options.filter(option => option.key === (value ? 'true' : 'false'))[0];
    return selectedOption ? (selectedOption.key as string) : undefined;
  }, [options, value]);

  const onValueEditorKeyUp = useCallback(
    (key: string) => {
      key === 'Escape' && onDismiss && onDismiss();
      key === 'Enter' && onSubmit && onSubmit();
    },
    [onDismiss, onSubmit],
  );

  return (
    <div className={classNames.root}>
      <MbcCompoBox
        compoBoxProps={{
          disabled: disabled,
          allowFreeform: true,
          selectedKey: selectedOptionKey,
          text: value === undefined ? undefined : value ? 'True' : 'False',
          autoComplete: 'on',
          options: options,
          onKeyUp: ev => onValueEditorKeyUp(ev.key),
          onChange: (ev, option, index, value) => {
            const newValue = option ? (option.key as string) : value;
            onValueChange(newValue === '' ? undefined : newValue === 'true');
          },
        }}
      />
    </div>
  );
};
