import { IStyle, IStyleFunctionOrObject, ITheme } from 'office-ui-fabric-react';
import { BoostCondition, ConditionBlock } from '../../../utils/customizations/conditions';
import { Banner } from '../../../store/types/customizations/businessRule';

export interface QueryTester {
  focus: () => void;
}

export enum ViewMode {
  FORM,
  CODE,
}

export const QueryTesterPanel = {
  General: 'General',
  Promote: 'Promote',
  Demote: 'Demote',
  Filter: 'Filter',
  Banner: 'Banner',
};
export type QueryTesterPanel = 'General' | 'Promote' | 'Demote' | 'Filter' | 'Banner';

export interface GeneralSettings {
  selectedSearchInstance: string;
  sortBy?: string;
  alterationEnabled?: boolean;
  selectedLanguage?: string;
  selectedMarket?: string;
}

export interface SharedSettings {
  query: string;
  top: number;
  skip: number;
}

export interface QueryTesterDetails {
  generalSettings: GeneralSettings;
  promoteCondition?: BoostCondition;
  demoteCondition?: BoostCondition;
  filterCondition?: ConditionBlock;
  banner?: Banner;
}

export interface QueryTesterProps extends React.HTMLAttributes<HTMLDivElement> {
  componentRef?: (componentRef: QueryTester) => void;
  theme?: ITheme;
  styles?: IStyleFunctionOrObject<QueryTesterStyleProps, QueryTesterStyles>;
}

export interface QueryTesterStyleProps {
  theme: ITheme;
  className?: string;
}

export interface QueryTesterStyles {
  root: IStyle;
  supportHint: IStyle;
  tutorialSubheader: IStyle;
  tutorialTargetBackground: IStyle;
}
