import { Stack, classNamesFunction } from 'office-ui-fabric-react';
import React, { useCallback } from 'react';
import {
  CartesianGrid,
  Line as ReLine,
  LineChart as ReLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  TooltipProps,
} from 'recharts';
import { yAxisTickFormatter } from '../chartsUtils';
import { AxisTickFormatter, TickType } from '../common/AxisTickFormatter';
import _ from 'lodash';
import { MultiLineChartProps, MultiLineChartStyleProps, MultiLineChartStyles } from './MultiLineChart.types';

const getClassNames = classNamesFunction<MultiLineChartStyleProps, MultiLineChartStyles>();

export const MultiLineChartBase = (props: MultiLineChartProps) => {
  const {
    points,
    minWidth,
    minHeight,
    xLine,
    ylines,
    isLoading,
    toolTipContent,
    legendProps,
    xAxisProps,
    footer,

    t,
    styles,
    theme,
    className,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const isLoadedWithData = !isLoading && points.length > 0;
  const isLoadedAndEmpty = !isLoading && points.length === 0;

  const displayedData = isLoadedWithData ? points : _.range(1, 10).map(i => ({ xValue: i, yValues: [0] }));

  const showMockData = isLoading || isLoadedAndEmpty;
  const displayedLabel = isLoading ? t('common.charts-loading') : t('common.charts-no-data');

  const shouldTickRotate =
    !!xAxisProps && !xAxisProps.tickProps.blockRotation
      ? !!xAxisProps.tickProps.rotationBreakPoint
        ? displayedData.length > xAxisProps.tickProps.rotationBreakPoint
        : displayedData.length > 8
      : false;

  const legendToDisplay = !!legendProps ? (legendProps.hideLegend ? [] : legendProps.legendContent || ylines) : ylines;

  const getLegend = useCallback(
    (color: string, text: string) => (
      <Stack horizontal tokens={{ childrenGap: 6 }} className={classNames.legendContainer}>
        <div className={classNames.legendIcon} style={{ backgroundColor: color }} />
        <div className={classNames.legendText}>{text}</div>
      </Stack>
    ),
    [classNames.legendContainer, classNames.legendIcon, classNames.legendText],
  );

  return (
    <Stack className={classNames.root} tokens={{ childrenGap: 12 }} horizontalAlign={'start'}>
      <ResponsiveContainer width="99%" minHeight={minHeight} minWidth={minWidth}>
        <ReLineChart
          data={displayedData}
          margin={{
            top: 20,
            right: 35,
            left: 0,
            bottom: 10,
          }}
        >
          <XAxis
            name={xLine.name}
            dataKey={displayedData => displayedData.xValue}
            height={shouldTickRotate ? 60 : 15}
            axisLine={false}
            interval={
              showMockData ? 0 : !!xAxisProps && xAxisProps.interval !== undefined ? xAxisProps.interval : 'preserveEnd'
            }
            tickMargin={15}
            padding={{ left: 15, right: 15 }}
            stroke={xLine.stroke}
            tick={payload => (
              <AxisTickFormatter
                payload={payload}
                points={displayedData}
                isXAxis
                tickType={
                  showMockData
                    ? TickType.Default
                    : !!xAxisProps && xAxisProps.tickProps.tickType
                    ? xAxisProps.tickProps.tickType
                    : TickType.Categorical
                }
                fontWeight={!!xAxisProps ? xAxisProps.tickProps.fontWeight : undefined}
                fontSize={!!xAxisProps ? xAxisProps.tickProps.fontSize : undefined}
                fill={!!xAxisProps ? xAxisProps.tickProps.fill : undefined}
                blockRotation={showMockData ? true : !shouldTickRotate}
                rotationAngle={!!xAxisProps ? xAxisProps.tickProps.rotationAngle : undefined}
                rotationBreakPoint={!!xAxisProps ? xAxisProps.tickProps.rotationBreakPoint : undefined}
                rotatedFontSize={!!xAxisProps ? xAxisProps.tickProps.rotatedFontSize : undefined}
                maximumTextLength={!!xAxisProps ? xAxisProps.tickProps.maximumTextLength : undefined}
              />
            )}
          />
          <YAxis
            stroke="grey"
            yAxisId="left"
            orientation="left"
            tick={payload => yAxisTickFormatter(payload, displayedData)}
            tickLine={false}
            axisLine={false}
          />

          <CartesianGrid stroke="rgba(0,0,0,0.1)" vertical={false} />

          {!showMockData && (
            <Tooltip
              contentStyle={{ fontSize: '14px' }}
              labelFormatter={label => {
                let dateTimeSplit = (label as string).split(' ');
                if (dateTimeSplit.length <= 1) return label;
                let formattedTime =
                  dateTimeSplit[1].length <= 2 ? dateTimeSplit[1] + ':00:00' : dateTimeSplit[1] + ':00';
                return dateTimeSplit[0] + ' ' + formattedTime;
              }}
              content={
                toolTipContent
                  ? (props: TooltipProps) => {
                      return toolTipContent!(props);
                    }
                  : undefined
              }
            />
          )}

          {showMockData && <ReferenceLine yAxisId="left" x={5} label={displayedLabel} opacity={0} />}

          {!showMockData ? (
            ylines.map((line, index) => (
              <ReLine
                key={line.name}
                type="linear"
                yAxisId="left"
                name={line.name}
                dataKey={displayedData => displayedData.yValues[index]}
                stroke={line.stroke}
                strokeWidth={2}
                dot={{ r: displayedData.length > 26 ? 0 : 2 }}
              />
            ))
          ) : (
            <ReLine
              key={'y'}
              type="linear"
              yAxisId="left"
              name={'y'}
              dataKey={displayedData => displayedData.yValues[0]}
              stroke={'lightGrey'}
              strokeWidth={2}
              dot={{ r: 2 }}
            />
          )}
        </ReLineChart>
      </ResponsiveContainer>

      {((!showMockData && legendToDisplay.length > 1 && !isLoading) || !!footer) && (
        <Stack
          horizontal
          horizontalAlign={'space-between'}
          className={classNames.footerContainer}
          verticalAlign={'center'}
        >
          {!showMockData && legendToDisplay.length > 1 && !isLoading && (
            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 16 }}>
              {legendToDisplay.map(legend => getLegend(legend.stroke, legend.name))}
            </Stack>
          )}
          {!!footer && footer}
        </Stack>
      )}
    </Stack>
  );
};
