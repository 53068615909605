import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../reducers';
import { refreshActiveIndexDevops } from '../devopsActions';

import { getSynonymSetList as getSynonymSetListService } from '../../../restful-apis/customizations/synonymSet.api';
import {
  synonymSetCreateAction,
  synonymSetUpdateAction,
  synonymSetDeleteAction,
  ActionSuccessHandler,
  ActionFailureHandler,
  ExecuteReduxCrudAction,
} from '../utils';

import {
  SynonymSet,
  SET_ACTIVE_SYNONYM_SET,
  RESET_ACTIVE_SYNONYM_SET,
  SET_SYNONYM_SET_LIST,
  RESET_SYNONYM_SET_LIST,
  LOADING_SYNONYM_SET_LIST,
} from '../../types/customizations/synonymSet.d';

export const getSynonymSetList = (searchInstanceId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;

    dispatch({
      type: LOADING_SYNONYM_SET_LIST,
    });

    getSynonymSetListService(tenantId, searchIndexId, searchInstanceId).then(synonymSetList =>
      dispatch({
        type: SET_SYNONYM_SET_LIST,
        synonymSetList: synonymSetList,
      }),
    );
  };
};

export const resetActiveSynonymSet = (): ThunkAction<void, AppState, null, Action> => dispatch =>
  dispatch({
    type: RESET_ACTIVE_SYNONYM_SET,
  });

export const setActiveSynonymSet = (synonymSetId: string): ThunkAction<void, AppState, null, Action> => dispatch =>
  dispatch({
    type: SET_ACTIVE_SYNONYM_SET,
    id: synonymSetId,
  });

export const resetSynonymSetList = (): ThunkAction<void, AppState, null, Action> => dispatch =>
  dispatch({
    type: RESET_SYNONYM_SET_LIST,
  });

export const updateSynonymSet = (
  synonymSet: SynonymSet,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<SynonymSet>,
  onFailure?: ActionFailureHandler<SynonymSet>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Update ${synonymSet.id} synonym set` }
      : undefined;

    const onSuccessHandler = (updatedSynonymSet: SynonymSet) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(updatedSynonymSet);
    };

    dispatch(
      ExecuteReduxCrudAction(
        synonymSetUpdateAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          synonymSet,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const createSynonymSet = (
  synonymSet: SynonymSet,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<SynonymSet>,
  onFailure?: ActionFailureHandler<SynonymSet>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Create ${synonymSet.id} synonym set` }
      : undefined;

    const onSuccessHandler = (createdSynonymSet: SynonymSet) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(createdSynonymSet);
    };

    dispatch(
      ExecuteReduxCrudAction(
        synonymSetCreateAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          synonymSet,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const deleteSynonymSet = (
  synonymSetId: string,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<SynonymSet>,
  onFailure?: ActionFailureHandler<SynonymSet>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Delete ${synonymSetId} synonym set` }
      : undefined;

    const onSuccessHandler = (deletedSynonymSet: SynonymSet) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(deletedSynonymSet);
    };

    dispatch(
      ExecuteReduxCrudAction(
        synonymSetDeleteAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          synonymSetId,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};
