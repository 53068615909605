import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from '../../../store/hooks';
import { SchemaManagement } from './SchemaManagement';
import { ProductManagement } from './ProductManagement';
import { MbcRouteHash, MbcRouteKey } from '../../../router/MbcRouter.types';
import { PivotCard, PanelProps } from '../../../components/common/PivotCard';
import { PageTemplate } from '../../../components/common/PageTemplate';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { EnvironmentOverview } from './Overview/Overview';
import { Environment } from '../../../store/types/environmentList.d';
import { useTranslation } from 'react-i18next';
import { Insights } from './Insights';
import { useEnvironmentRouter } from '../../../store/hooks/use-router/useEnvironmentRouter';
import { PivotItem } from 'office-ui-fabric-react';
import { Tenant } from '../../../store/types/tenantsList.d';
import { resetSearchIndexProducts, searchIndexProducts } from '../../../store/actions/productSearchActions';
import { PageFooterProps } from '../../../components/common/PageFooter';

export const EnvironmentBase = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const mbcEnvironmentRouteMap = useEnvironmentRouter();

  const selectedPanel =
    useSelector<MbcRouteHash>(state => state.router.location.hash as MbcRouteHash) || MbcRouteHash.General;

  const environment = useSelector<Environment>(state => state.environmentList.activeEnvironment);

  const activeTenant = useSelector<Tenant>(state => state.tenantsList.activeTenant);

  const [pageFooterProps, setPageFooterProps] = useState<PageFooterProps | undefined>(undefined);

  const optionalPanelProps: Partial<PanelProps> = useMemo(
    () => ({
      disabled: !environment.indexId || activeTenant.isSandbox,
      iconProps:
        !environment.indexId || activeTenant.isSandbox
          ? {
              iconName: 'Lock',
              style: { fontSize: 12 },
            }
          : undefined,
    }),
    [activeTenant.isSandbox, environment.indexId],
  );

  const onRenderPageFooterProps = useCallback(props => {
    setPageFooterProps(props);
  }, []);

  const onRenderEnvironmentPanel = useCallback(
    () => (
      <PivotCard
        selectedKey={selectedPanel}
        onLinkClick={(item: PivotItem) => {
          switch (item.props.itemKey) {
            case MbcRouteHash.General:
              mbcEnvironmentRouteMap(MbcRouteKey.Environment).browserPush();
              break;
            case MbcRouteHash.Schema:
              mbcEnvironmentRouteMap(MbcRouteKey.SchemaManagement).browserPush();
              break;
            case MbcRouteHash.ProductData:
              mbcEnvironmentRouteMap(MbcRouteKey.DocumentManagement).browserPush();
              break;
            case MbcRouteHash.Insights:
              mbcEnvironmentRouteMap(MbcRouteKey.IndexAnalytics).browserPush();
              break;
            default:
              break;
          }
        }}
        panels={() => [
          {
            key: MbcRouteHash.General,
            panel: <EnvironmentOverview />,
            title: t('environment.general-panel-title'),
          },
          {
            key: MbcRouteHash.Schema,
            panel: <SchemaManagement onRenderPageFooterProps={onRenderPageFooterProps} />,
            title: t('environment.schema-panel-title'),
          },
          {
            key: MbcRouteHash.ProductData,
            panel: <ProductManagement />,
            // Note: don't change until you resolve the dependency with useEffect below
            alwaysRender: !!environment.indexId,
            title: t('environment.products-panel-title'),
            ...optionalPanelProps,
          },
          {
            key: MbcRouteHash.Insights,
            panel: <Insights />,
            title: t('environment.insights-panel-title'),
            ...optionalPanelProps,
          },
        ]}
      />
    ),
    [selectedPanel, mbcEnvironmentRouteMap, t, onRenderPageFooterProps, environment.indexId, optionalPanelProps],
  );

  /**
   * This is to avoid sending an initial products search request just after search index creation
   * Note: this is dependent on the idea that product management tab should always be rendered in the background
   * incase no index is set we just set the product search loading state to false and empty [] (to mimic an initial empty response)
   */
  useEffect(() => {
    !!environment.indexId && dispatch(searchIndexProducts());
    !environment.indexId && dispatch(resetSearchIndexProducts());
    return () => {
      dispatch(resetSearchIndexProducts());
    };
    // This should run only once when landing on the environment screen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <PageTemplate
      breadcrumbVisible
      footerProps={pageFooterProps}
      sideNavMode={SideNavMode.None}
      onRenderContent={onRenderEnvironmentPanel}
      pageHeaderProps={{
        title: environment.name,
        titleCaption: !!environment.indexId
          ? t('environment.title-caption', {
              id: environment.indexId,
            })
          : t('environment.no-index-caption'),
      }}
    />
  );
};
