import React, { useRef, useState } from 'react';
import { CopyToClipboardProps, CopyToClipboardStyleProps, CopyToClipboardStyles } from './CopyToClipboard.types';
import { TextField, TooltipHost, IconButton, ITextField, getId, classNamesFunction } from 'office-ui-fabric-react';

const getClassNames = classNamesFunction<CopyToClipboardStyleProps, CopyToClipboardStyles>();

export const CopyToClipboardBase = (props: CopyToClipboardProps): JSX.Element => {
  const { t, styles, theme, className, componentRef, value, ariaLabel } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const readonlyTextFieldRef = useRef<ITextField>(null);
  const copyTokenToClipboardButtonId: string = getId('copyTokenToClipboardButtonId');
  const [copyingFromTextFieldMsg, setCopyingFromTextFieldMsg] = useState<string>(t('copy-to-clipboard.copy'));

  return (
    <div className={classNames.root} ref={componentRef}>
      <TextField label={ariaLabel} value={value} componentRef={readonlyTextFieldRef} />
      <TooltipHost
        content={copyingFromTextFieldMsg}
        calloutProps={{
          gapSpace: 0,
          isBeakVisible: false,
          target: `#${copyTokenToClipboardButtonId}`,
        }}
      >
        <IconButton
          id={copyTokenToClipboardButtonId}
          iconProps={{ iconName: 'Copy' }}
          onClick={() => {
            if (readonlyTextFieldRef.current !== null) {
              readonlyTextFieldRef.current.select();
              setCopyingFromTextFieldMsg(
                !!document.execCommand('copy')
                  ? t('copy-to-clipboard.successful-copy')
                  : t('copy-to-clipboard.failed-copy'),
              );
            }
          }}
          onMouseOut={() => setCopyingFromTextFieldMsg(t('copy-to-clipboard.copy'))}
          onBlur={() => setCopyingFromTextFieldMsg(t('copy-to-clipboard.copy'))}
        ></IconButton>
      </TooltipHost>
    </div>
  );
};
