import { Action } from 'redux';

export const SET_SHOW_ADD_DOCUMENT_PICKER = 'SET_SHOW_ADD_DOCUMENT_PICKER';
export const RESET_SHOW_ADD_DOCUMENT_PICKER = 'RESET_SHOW_ADD_DOCUMENT_PICKER';

export interface IndexProbeState {
  showAddDocumentsPicker: boolean;
}

export type IndexProbeStateActionTypes =
  | Action<typeof SET_SHOW_ADD_DOCUMENT_PICKER>
  | Action<typeof RESET_SHOW_ADD_DOCUMENT_PICKER>;
