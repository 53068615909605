import HttpClient from '../http-client/httpClient';
import {
  UrlRedirectSetListResponse,
  UrlRedirectSetUpdateResponse,
  UrlRedirectSetDeleteResponse,
} from '../dto/customizations/urlRedirectSet';
import { UrlRedirectSet } from '../../store/types/customizations/urlRedirectSet.d';
import { DevOpsCommitMetadata } from '../../store/types/devOps.d';
import { getDevOpsCommitHeaders } from '../devops.api';
import {
  mapUrlRedirectSetListDtoToModel,
  mapUrlRedirectSetModelToDto,
  mapUrlRedirectSetDtoToModel,
  mapDeletedUrlRedirectSetDtoToModel,
} from '../mappers/customizations/urlRedirectSetMapper';
import { isDynamicsTenant } from '../../utils/dynamics';

const getUrlRedirectSetBaseUrl = (tenantId: string, searchIndexId: string): string => {
  return `https://commerce.bing.com/api/customization/v1/redirect/${tenantId}/indexes/${searchIndexId}`;
};

export function getUrlRedirectSetList(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
): Promise<UrlRedirectSet[]> {
  // special handling due to dynamics tenant limitations
  if (isDynamicsTenant(tenantId)) {
    return Promise.resolve([]);
  }
  const url = `${getUrlRedirectSetBaseUrl(tenantId, searchIndexId)}?searchinstanceid=${searchInstanceId}`;
  return HttpClient.get<UrlRedirectSetListResponse>({
    url: url,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapUrlRedirectSetListDtoToModel);
}

export function updateUrlRedirectSet(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  urlRedirectSet: UrlRedirectSet,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<UrlRedirectSet> {
  const mappedUrlRedirectSet = mapUrlRedirectSetModelToDto(urlRedirectSet);
  const requestBody = { ...mappedUrlRedirectSet, searchInstanceId: searchInstanceId };

  const url = getUrlRedirectSetBaseUrl(tenantId, searchIndexId);
  return HttpClient.post<UrlRedirectSetUpdateResponse>({
    url: url,
    body: requestBody,
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
    // this is a work around, update endpoint should return the updated set (including searchRequestCondition)
  }).then(res =>
    mapUrlRedirectSetDtoToModel({ ...res, searchRequestCondition: mappedUrlRedirectSet.searchRequestCondition }),
  );
}

export function deleteUrlRedirectSet(
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  urlRedirectSetId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<UrlRedirectSet> {
  const url = `${getUrlRedirectSetBaseUrl(
    tenantId,
    searchIndexId,
  )}?searchinstanceid=${searchInstanceId}&redirectid=${urlRedirectSetId}`;

  return HttpClient.delete<UrlRedirectSetDeleteResponse>({
    url: url,
    body: {},
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapDeletedUrlRedirectSetDtoToModel);
}
