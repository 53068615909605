import { Toggle, classNamesFunction } from 'office-ui-fabric-react';
import React from 'react';
import { EnvironmentList } from './EnvironmentList';
import {
  EnvironmentsSectionContentProps,
  EnvironmentsSectionContentStyleProps,
  EnvironmentsSectionContentStyles,
} from './EnvironmentsSectionContent.types';

const getClassNames = classNamesFunction<EnvironmentsSectionContentStyleProps, EnvironmentsSectionContentStyles>();

export const EnvironmentsSectionContentBase = (props: EnvironmentsSectionContentProps) => {
  const { t, styles, theme, disabled } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  return (
    <div data-test="token-editor-environments-section">
      <div className={classNames.adminAccessLabelContainer}>
        <span className={classNames.adminAccessLabel}>{t('token-editor.envs-section.adminAccessLabel')}</span>
        <span className={classNames.adminAccessLabelDesc}>
          {t('token-editor.envs-section.adminAccessLabel-description')}
        </span>
      </div>

      <Toggle
        data-test={'all-envs-toggle'}
        checked={props.isAdminAccess}
        onText={t('token-editor.envs-section.admin-access-toggle-on-text')}
        offText={t('token-editor.envs-section.admin-access-toggle-off-text')}
        onChange={(ev, newVal) => props.onChangeAdminAccess(newVal || false)}
        disabled={disabled}
      />

      {!props.isAdminAccess && (
        <EnvironmentList
          disableEdit={disabled}
          isLoading={false}
          items={props.envs}
          onEnironmentsChanged={props.onEnvsChanged}
        />
      )}
    </div>
  );
};
