import { PageTemplateStyleProps, PageTemplateStyles, SideNavMode } from './PageTemplate.types';

export const styles = (props: PageTemplateStyleProps): PageTemplateStyles => {
  const { sideNavMode, screenSize, isSplitPage } = props;

  let sideNavVirtualMode = sideNavMode || SideNavMode.None;
  if (sideNavVirtualMode == SideNavMode.Hoverable) sideNavVirtualMode = SideNavMode.Collapsed;

  const getProperSideNavAreaWidth = (mode: SideNavMode) => {
    if (mode === SideNavMode.None) return '168px';
    if (mode === SideNavMode.Expanded) return '208px';

    return 'auto';
  };

  let leftRightPadding = [64, 152, 160, 192][screenSize - 1];
  if (sideNavVirtualMode === SideNavMode.Expanded) leftRightPadding = screenSize > 1 ? 48 : 0;
  if (sideNavVirtualMode === SideNavMode.Expanded && screenSize === 4) leftRightPadding = 88;
  if (sideNavVirtualMode === SideNavMode.Collapsed) leftRightPadding = screenSize === 2 ? 60 : 127;
  if (sideNavVirtualMode === SideNavMode.Collapsed && screenSize === 1) leftRightPadding = 48;

  return {
    root: [
      'mbc-page-template',
      {
        justifyContent: 'space-between',
        height: 'calc(100vh - 48px)', // 48px: for nav bar height
      },
      props.className,
    ],
    allContentExceptFooter: [
      { height: '100%' },
      !isSplitPage && {
        overflow: 'auto',
        paddingBottom: '20px',
      },
    ],
    sideNav: [
      {
        borderRight: '1px solid #EDEBE9',
        height: 'calc(100vh - 48px)', // 48px: for nav bar height
        width: sideNavVirtualMode === SideNavMode.Collapsed ? '33px' : getProperSideNavAreaWidth(sideNavVirtualMode),
      },
    ],
    mainContainer: [
      {
        width: isSplitPage ? '100%' : `calc(100% - 2 * ${leftRightPadding}px)`,
        height: '100%',
      },
    ],
    splitContainer: [
      {
        position: 'relative',
        height: '100%',
      },
    ],
    expandedSplitPane: [
      {
        selectors: {
          '.fXAXjb': {
            width: 7,
            margin: '0 -3px',
            borderLeft: '3px solid #fff',
            borderRight: '3px solid #fff',
          },
          '.fXAXjb:hover': {
            borderLeft: '3px solid #00000080',
            borderRight: '3px solid #00000080',
          },
        },
      },
    ],
    collapsedSplitPane: [
      {
        selectors: {
          '.fXAXjb': {
            display: 'none',
          },
        },
      },
    ],
    leftSideContainer: [],
    leftSideHeader: [{ paddingLeft: 24, paddingRight: 24 }],
    secondaryNav: [
      {
        height: 44,
        minHeight: 44,
        borderBottom: '1px solid #EDEBE9',
        width: '100%',
        paddingRight: leftRightPadding,
        paddingLeft: isSplitPage ? 24 : leftRightPadding,
      },
    ],
  };
};
