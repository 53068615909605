import {
  SET_CUSTOM_ML_SEARCH_CONFIG,
  RESET_CUSTOM_ML_SEARCH_CONFIG,
  CustomMlSearchConfigState,
  CustomMlSearchConfigActionTypes,
} from '../../types/customizations/customMlSearchConfig.d';

const initialState: CustomMlSearchConfigState = {
  isLoading: true,
  customMlSearchConfig: {
    searchModel: '',
  },
};

export function CustomMlSearchConfigReducer(
  state = initialState,
  action: CustomMlSearchConfigActionTypes,
): CustomMlSearchConfigState {
  switch (action.type) {
    case SET_CUSTOM_ML_SEARCH_CONFIG: {
      return {
        ...state,
        isLoading: false,
        customMlSearchConfig: action.customMlSearchConfig,
      };
    }

    case RESET_CUSTOM_ML_SEARCH_CONFIG: {
      return {
        ...state,
        isLoading: true,
        customMlSearchConfig: {
          searchModel: '',
        },
      };
    }
    default:
      return state;
  }
}
