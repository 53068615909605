import React from 'react';
import { DateTimePicker } from '../../../components/common/DateTimePicker';
import { Stack, Toggle, classNamesFunction } from 'office-ui-fabric-react';
import {
  ConfigurationSectionContentProps,
  ConfigurationSectionContentStyleProps,
  ConfigurationSectionContentStyles,
} from './ConfigurationSectionContent.types';
import moment from 'moment';

const getClassNames = classNamesFunction<ConfigurationSectionContentStyleProps, ConfigurationSectionContentStyles>();

export const ConfigurationSectionContentBase = (props: ConfigurationSectionContentProps) => {
  const { t, styles, theme, className, disabled } = props;

  const classNames = getClassNames(styles!, { theme: theme!, className });

  return (
    <Stack className={classNames.root}>
      <Toggle
        label={t('token-editor.configuration-section.generate-refresh')}
        onText={t('common.yes')}
        offText={t('common.no')}
        checked={props.generateRefreshToken}
        onChange={(ev, newVal) => props.onGenerateRefreshTokenToggleChange(newVal || false)}
        disabled={disabled}
      />

      <DateTimePicker
        label={
          props.generateRefreshToken
            ? t('token-editor.configuration-section.refresh-expiration-date')
            : t('token-editor.configuration-section.access-expiration-date')
        }
        disabled={props.generateRefreshToken || disabled}
        date={props.date}
        maxDate={moment()
          .add(180, 'd')
          .toDate()}
        setMinDate={true}
        onDateChange={(newDate: Date) => {
          props.onDateChange(new Date(newDate));
        }}
      />

      {props.generateRefreshToken && (
        <div className={classNames.expirationMsg}>
          {t('token-editor.configuration-section.refresh-token-expiration-msg')}
        </div>
      )}
    </Stack>
  );
};
