import { NavBarRightMenuStyleProps, NavBarRightMenuStyles } from './NavBarRightMenu.types';

export const styles = (props: NavBarRightMenuStyleProps): NavBarRightMenuStyles => {
  return {
    root: [
      'mbc-nav-bar-right-menu',
      {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginRight: 8,
        selectors: {
          '.ms-Button--command': {
            color: props.theme.palette.white,
            backgroundColor: 'transparent',
            width: 48,
            height: 48,
          },
          '.ms-Button--command:hover': {
            color: props.theme.palette.white,
            background: props.theme.palette.blueMid,
          },
          '.ms-Button-flexContainer': {
            justifyContent: 'center',
          },
          '.ms-Button-icon': {
            color: props.theme.palette.white + '!important',
            backgroundColor: 'transparent',
          },
          '.ms-Button--icon:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      props.className,
    ],
    personaButton: [
      'mbc-persona-button',
      {
        selectors: {
          '.ms-Persona-initials': {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: props.theme.palette.white,
            boxSizing: 'border-box',
            backgroundColor: '#038387',
          },
          ':hover': {
            background: 'transparent !important',
          },
        },
      },
    ],
    notificationsCenterWrapper: [
      'mbc-notifications-center-wrapper',
      {
        selectors: {
          '.ms-Button-flexContainer': {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: 32,
          },
        },
      },
    ],
    notificationsCenterLink: [
      'mbc-notifications-center-link',
      {
        position: 'relative',
        margin: 'auto',
      },
    ],
    notificationsCenterIcon: [
      'mbc-notifications-center-icon',
      {
        fontSize: 18,
        display: 'flex',
      },
    ],
    unreadNotificationsCountTag: [
      'mbc-unread-notifications-count-tag',
      {
        margin: 0,
        padding: 4,
        lineHeight: 10,
        position: 'absolute',
        top: -5,
        right: -4,
        background: props.theme.palette.white,
        color: props.theme.palette.themePrimary,
        borderRadius: 7,
        fontSize: 11,
        fontWeight: 600,
        width: 8,
        height: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    ],
    notificationsProgressIndicator: [
      'mbc-notifications-progress-indicator',
      {
        minWidth: 18,
        position: 'relative',
        selectors: {
          '.ms-ProgressIndicator-progressTrack': {
            backgroundColor: props.theme.palette.themePrimary,
          },
          '.ms-ProgressIndicator-progressBar': {
            minWidth: '75%',
            background: props.theme.palette.white,
          },
        },
      },
    ],
  };
};
