/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useMemo, useCallback } from 'react';
import {
  SearchInstanceSelectorProps,
  SearchInstanceSelectorStyleProps,
  SearchInstanceSelectorStyles,
} from './SearchInstanceSelector.types';
import { useSelector } from '../../../../store/hooks';
import { AppState } from '../../../../store/reducers';
import {
  classNamesFunction,
  Stack,
  Dropdown,
  IDropdownOption,
  DropdownMenuItemType,
  StackItem,
  Icon,
} from 'office-ui-fabric-react';
import { ActiveSearchInstanceLoading } from '../../../../components/Loading';
import { SearchInstanceListPanel } from '../panels/SearchInstanceListPanel';
import { MbcSearchInstanceRouter } from '../../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
const getClassNames = classNamesFunction<SearchInstanceSelectorStyleProps, SearchInstanceSelectorStyles>();

export const SearchInstanceSelector = (props: SearchInstanceSelectorProps) => {
  const { styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);

  const [openSearchInstanceListPanel, setOpenSearchInstanceListPanel] = useState<boolean>(false);
  const isSearchInstanceListLoading = useSelector((state: AppState) => state.searchInstanceList.isLoading);
  const activeSearchInstance = useSelector((state: AppState) => state.searchInstanceList.activeSearchInstance);
  const searchInstanceIdMapper = useSelector((state: AppState) => state.searchInstanceList.searchInstanceIdMapper);

  const searchInstanceOptions = useMemo((): IDropdownOption[] => {
    let items: IDropdownOption[] = [];
    for (const [key, value] of Object.entries(searchInstanceIdMapper)) {
      items.push({
        key: key,
        text: value.name,
      });
    }
    items.push({ key: 'divider', text: 'divider', itemType: DropdownMenuItemType.Divider });
    items.push({
      key: 'manageSearchInstances',
      text: 'Manage configurations',
    });

    return items;
  }, [searchInstanceIdMapper]);

  const onSelectionChange = useCallback(
    (option: IDropdownOption | undefined) => {
      if (option) {
        if ((option.key as string) === 'manageSearchInstances') {
          setOpenSearchInstanceListPanel(true);
        } else {
          MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, option.key as string)(
            MbcRouteKey.BusinessRuleList,
          ).browserPush();
        }
      }
    },
    [activeEnvironment.id, activeTenant.id],
  );
  return (
    <ActiveSearchInstanceLoading>
      <>
        <SearchInstanceListPanel
          onDismiss={() => setOpenSearchInstanceListPanel(false)}
          isOpen={openSearchInstanceListPanel}
        />

        <Stack className={classNames.root} horizontal tokens={{ childrenGap: 10 }}>
          <div>
            <Dropdown
              styles={{
                dropdownItem: {
                  selectors: {
                    '.ms-Button-flexContainer': {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  },
                },
                title: { border: 0, textAlign: 'start' },
              }}
              selectedKey={activeSearchInstance.id}
              options={searchInstanceOptions}
              disabled={isSearchInstanceListLoading}
              onChange={(ev, option) => onSelectionChange(option)}
              dropdownWidth={200}
              onRenderTitle={() => {
                return (
                  <Stack
                    className={classNames.selectorTitle}
                    horizontal
                    verticalAlign={'center'}
                    tokens={{ childrenGap: 10 }}
                  >
                    <Icon styles={{ root: { display: 'flex' } }} iconName={'SearchInstance'} />
                    <StackItem styles={{ root: { overflow: 'hidden', textOverflow: 'ellipsis' } }}>
                      {activeSearchInstance.name}{' '}
                    </StackItem>
                  </Stack>
                );
              }}
            />
          </div>
        </Stack>
      </>
    </ActiveSearchInstanceLoading>
  );
};
