import { IndexFieldStyleProps, IndexFieldStyles } from './IndexField.types';

export const GROUD_INDENT_WIDTH_IN_PIXELS = 16;

export const styles = (props: IndexFieldStyleProps): IndexFieldStyles => ({
  primaryCell: [
    'mbc-cell',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: GROUD_INDENT_WIDTH_IN_PIXELS * props.groupLevel + 6,
    },
  ],
  subComponentStyles: {
    detailsRow: {
      root: [
        {
          width: '100%',
          selectors: {
            '.ms-GroupSpacer': {
              display: 'none !important',
            },
          },
        },
      ],
    },
    groupHeader: {
      groupHeaderContainer: [
        'mbc-group-header',
        {
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: props.theme.palette.neutralLighter,
          selectors: {
            '.ms-GroupHeader-expand': {
              width: 32,
              height: 32,
              fontSize: 12,
              margin: '0 2px',
              cursor: 'pointer',
            },
          },
        },
      ],
      title: [
        'mbc-group-title',
        {
          fontSize: 12,
          paddingLeft: 4,
          fontWeight: 400,
          color: props.theme.palette.neutralPrimary,
        },
      ],
      headerCount: [
        'mbc-header-count',
        {
          display: 'none',
        },
      ],
    },
  },
});
