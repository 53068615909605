import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { Action } from 'redux';
import { getFieldValueSuggestions as getFieldValueSuggestionsService } from '../../../restful-apis/queryTester.api';
import {
  RESET_FIELD_VALUE_SUGGESTIONS,
  SET_FIELD_VALUE_SUGGESTIONS,
  LOADING_FIELD_VALUE_SUGGESTIONS,
  TOGGLE_IS_PANE_EXAPNDED,
} from '../../types/customizations/queryTester.d';

export const getFieldValueSuggestions = (
  facetableStringFields: string[],
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const tenantId = getState().tenantsList.activeTenant.id;
  const searchIndex = getState().searchIndex.searchIndex;
  const searchInstanceId = getState().searchInstanceList.activeSearchInstance.id;
  if (!!searchInstanceId && facetableStringFields.length > 0) {
    dispatch({
      type: LOADING_FIELD_VALUE_SUGGESTIONS,
    });
    getFieldValueSuggestionsService(tenantId, searchIndex, searchInstanceId, facetableStringFields).then(
      fieldValueSuggestions => {
        dispatch({
          type: SET_FIELD_VALUE_SUGGESTIONS,
          fieldValueSuggestions: fieldValueSuggestions,
        });
      },
    );
  }
};

export const resetFieldValueSuggestions = (): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: RESET_FIELD_VALUE_SUGGESTIONS,
  });
};

export const toggleIsPaneExpanded = (): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: TOGGLE_IS_PANE_EXAPNDED,
  });
};
