import { ScheduleCalloutStyles, ScheduleCalloutStyleProps } from './ScheduleCallout.types';

export const styles = (props: ScheduleCalloutStyleProps): ScheduleCalloutStyles => {
  const { className } = props;
  return {
    root: ['mbc-ScheduleCallout', { padding: 16 }, className],
    schedule: [
      'mbc-ScheduleCallout-schedule',
      {
        borderTop: '1px solid #EDEBE9',
        borderBottom: '1px solid #EDEBE9',
        padding: '16px 0',
      },
    ],
    validationErrors: [{ color: '#a4262c' }],
  };
};
