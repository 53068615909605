import React, { useState } from 'react';
import { TimeSpanSelector } from '../TimeSpanSelector';
import { MetricCardProps, MetricCardStyleProps, MetricCardStyles } from './MetricCard.types';
import { TitledContentCard } from '../../../../../../components/common/ContentCard';
import { Spinner, SpinnerSize, classNamesFunction, Stack, IconButton, TooltipHost } from 'office-ui-fabric-react';
import numeral from 'numeral';

const getClassNames = classNamesFunction<MetricCardStyleProps, MetricCardStyles>();

export const MetricCardBase = (props: MetricCardProps) => {
  const {
    t,
    componentRef,
    styles,
    theme,
    className,
    title,
    value,
    formatAsPercentage,
    isLoading,
    valueDescription,
    refreshOptions,
    timeSpanSelectorOptions,
    footer,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [selectedTimeSpan, setSelectedTimeSpan] = useState(
    !!timeSpanSelectorOptions
      ? timeSpanSelectorOptions.defaultTimeSpan
      : !!refreshOptions
      ? refreshOptions.defaultTimeSpan
      : '',
  );

  const getCardFormatedValue = (value: number): string => {
    numeral.nullFormat(t('insights.null-format'));
    if (value === 0 || value < 1e-4) {
      return '0';
    }
    if (formatAsPercentage) {
      return numeral(value).format('0.00') + '%';
    }

    var numFormat = '0,0[.]0';
    return numeral(value).format(numFormat);
  };

  return (
    <div ref={componentRef} className={classNames.root}>
      <TitledContentCard
        componentRef={componentRef}
        title={title}
        actions={
          !!timeSpanSelectorOptions ? (
            <TimeSpanSelector
              isLoading={isLoading}
              defaultTimeSpan={timeSpanSelectorOptions.defaultTimeSpan}
              availableTimes={timeSpanSelectorOptions.availableTimes}
              onTimeSpanChange={(timespan: string, interval: string) => {
                setSelectedTimeSpan(timespan);
                timeSpanSelectorOptions.onTimeSpanChange &&
                  timeSpanSelectorOptions.onTimeSpanChange(timespan, interval);
              }}
            />
          ) : (
            undefined
          )
        }
        body={
          isLoading ? (
            <Spinner size={SpinnerSize.medium} />
          ) : (
            <Stack tokens={{ childrenGap: 12 }} horizontalAlign={'start'} className={classNames.bodyContainer}>
              {!!refreshOptions && (
                <Stack horizontal verticalAlign={'center'} className={classNames.refreshButton}>
                  <TooltipHost content="Refresh">
                    <IconButton
                      iconProps={{ iconName: 'Refresh' }}
                      onClick={() => !!refreshOptions.onRefreshClick && refreshOptions.onRefreshClick(selectedTimeSpan)}
                      disabled={isLoading || refreshOptions.disabled}
                    />
                  </TooltipHost>
                  {!isLoading && <div className={classNames.refreshText}> {refreshOptions.text}</div>}
                </Stack>
              )}
              <Stack horizontal verticalAlign={'end'} tokens={{ childrenGap: 8 }}>
                <div className={classNames.value}>{getCardFormatedValue(value)}</div>
                {!!valueDescription && !isNaN(value) && (
                  <div className={classNames.valueDescription}>{valueDescription}</div>
                )}
              </Stack>
            </Stack>
          )
        }
        footer={footer}
      />
    </div>
  );
};
