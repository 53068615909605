import {
  CheckboxVisibility,
  CollapseAllVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  IColumn,
  IDetailsGroupDividerProps,
  SelectionMode,
  ShimmeredDetailsList,
  classNamesFunction,
  mergeStyles,
  ColumnActionsMode,
  ScrollablePane,
  ScrollbarVisibility,
  StickyPositionType,
  Sticky,
} from 'office-ui-fabric-react';
import { SearchIndex, createInitialIndexField, createInitialSearchIndex } from '../../../../store/types/searchIndex.d';
import {
  ROOT_FIELD_KEY,
  SchemaManagementProps,
  SchemaManagementStyleProps,
  SchemaManagementStyles,
  ViewMode,
} from './SchemaManagement.types';
import React, { useCallback, useEffect, useMemo, useState, createRef } from 'react';
import { createNewSearchIndex, saveActiveSearchIndex } from '../../../../store/actions/searchIndexActions';
import { detectIndexSchema, resetActiveDetectedIndexSchema } from '../../../../store/actions/schemaDetectionActions';
import { insertSearchIndexField, removeSearchIndexField, sort, updateSearchIndexField } from './SchemaManagement.utils';
import { useDispatch, useSelector } from '../../../../store/hooks';

import { DatasetUpload } from '../../../../components/DatasetUpload';
import { IndexField } from './IndexField';
import { IndexFieldEditor } from './IndexFieldEditor';
import { ListItemsOrder } from '../../../../utils';
import { NoItemsToDisplay } from '../../../../components/common/NoItemsToDisplay';
import { SearchIndexTypeConfig } from './SchemaManagement.config';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TutorialSteps, TutorialsId } from '../../../../components/Tutorials';
import { Tutorial } from '../../../../components/Tutorials';
import { AvailableTutorials } from '../../../../store/types/tutorial.d';
import { startTutorial } from '../../../../store/actions/tutorialActions';
import { MbcRouteHash } from '../../../../router/MbcRouter.types';
import { isFeatureEnabledPerTenant } from '../../../../guards/utils';
import { Feature } from '../../../../store/types/tenantsList.d';
import { Prompt } from 'react-router-dom';
import { SchemaListHeader } from './SchemaListHeader';
import { GroupExtended } from './SchemaListHeader';

const getClassNames = classNamesFunction<SchemaManagementStyleProps, SchemaManagementStyles>();

export const SchemaManagementBase = (props: SchemaManagementProps) => {
  const { styles, theme, onRenderPageFooterProps } = props;

  const { t } = useTranslation();

  const tutorial = useSelector(state => state.tutorial);

  const classNames = getClassNames(styles, {
    theme: theme!,
  });

  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.searchIndex.isLoading);
  const searchIndex = useSelector(state => state.searchIndex.searchIndex);
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const environmentId = useSelector(state => state.environmentList.activeEnvironment.id);
  const activeSchemaDetection = useSelector(state => state.schemaDetection.activeSchemaDetection);
  const isNonEditableIndex = useSelector(state => state.environmentList.nonEditableSearchIndexSet.has(environmentId));
  const completedTutorials = useSelector(state => state.user.sandBoxDetails.completedTutorials);
  const currentTutorial = useSelector(state => state.tutorial.currentTutorial);
  const currentRouteHash = useSelector<MbcRouteHash>(state => state.router.location.hash as MbcRouteHash);

  const [schemaViewMode, setSchemaViewMode] = useState(ViewMode.Read);
  const [showUploadPanel, setShowUploadPanel] = useState<boolean>(false);
  const [enableNewIndexCreation, setEnableNewIndexCreation] = useState(false);
  const [listItemsOrder, setListItemsOrder] = useState(ListItemsOrder.DESCENDING);
  const [updatedSearchIndex, setUpdatedSearchIndex] = useState<SearchIndex>(createInitialSearchIndex());
  const [schemaGroups, setSchemaGroups] = useState<GroupExtended[]>([]);

  const rowRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!completedTutorials.includes(TutorialsId.Catalog) && currentRouteHash === MbcRouteHash.Schema) {
      dispatch(startTutorial(AvailableTutorials.Catalog));
    }
  }, [completedTutorials, currentRouteHash, dispatch]);

  useEffect(() => {
    setUpdatedSearchIndex(_.cloneDeep(searchIndex));
    return () => {};
  }, [searchIndex]);

  useEffect(() => {
    if (activeSchemaDetection.environmentId === environmentId) {
      setUpdatedSearchIndex(s => ({
        ...s,
        fields: activeSchemaDetection.fields,
        transformationScript: activeSchemaDetection.transformationScript,
      }));
      setSchemaViewMode(ViewMode.Edit);
      setEnableNewIndexCreation(!searchIndex.id);
    }
    return () => {
      if (activeSchemaDetection.environmentId === environmentId) {
        dispatch(resetActiveDetectedIndexSchema(activeSchemaDetection.key));
        setEnableNewIndexCreation(!searchIndex.id);
      }
    };
  }, [activeSchemaDetection, dispatch, enableNewIndexCreation, environmentId, searchIndex]);

  const [isSchemaUpdated, setIsSchemaUpdated] = useState<boolean>(false);

  useEffect(() => {
    const updated = !_.isEqual(updatedSearchIndex, searchIndex);
    if (updated !== isSchemaUpdated) {
      setIsSchemaUpdated(updated);
    }

    updated ? (window.onbeforeunload = () => true) : (window.onbeforeunload = null);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isSchemaUpdated, searchIndex, updatedSearchIndex]);

  const onColumnHeaderClick = useCallback(() => {
    const updatedListItemsOrder =
      listItemsOrder === ListItemsOrder.ASCENDING ? ListItemsOrder.DESCENDING : ListItemsOrder.ASCENDING;
    setListItemsOrder(updatedListItemsOrder);

    setUpdatedSearchIndex(s => ({
      ...s,
      fields: sort(searchIndex.fields, updatedListItemsOrder),
    }));
  }, [listItemsOrder, searchIndex.fields]);

  const getSchemaColumns = useCallback(
    (rootHasComplexType: boolean): IColumn[] => {
      const tertiaryCell =
        schemaViewMode === ViewMode.Edit
          ? mergeStyles(classNames.secondaryCell, classNames.hoverable)
          : classNames.secondaryCell;
      const columns: IColumn[] = [
        {
          key: 'name',
          minWidth: 100,
          fieldName: 'name',
          className: classNames.primaryCell,
          headerClassName: classNames.primaryHeader,
          name: t('schema-management.index-schema.th-name'),
          onColumnClick: onColumnHeaderClick,
          iconName: listItemsOrder === ListItemsOrder.ASCENDING ? 'SortDown' : 'SortUp',
        },
        {
          key: 'type',
          minWidth: 175,
          maxWidth: 175,
          fieldName: 'type',
          isMultiline: false,
          name: t('schema-management.index-schema.th-type'),
          className: classNames.secondaryCell,
          headerClassName: classNames.secondaryHeader,
          columnActionsMode: ColumnActionsMode.disabled,
        },
        {
          minWidth: 75,
          maxWidth: 75,
          key: 'searchable',
          isMultiline: false,
          fieldName: 'searchable',
          className: tertiaryCell,
          headerClassName: classNames.secondaryHeader,
          name: t('schema-management.index-schema.th-searchable'),
          columnActionsMode: ColumnActionsMode.disabled,
        },
        {
          minWidth: 75,
          maxWidth: 75,
          key: 'retrievable',
          isMultiline: false,
          fieldName: 'retrievable',
          className: tertiaryCell,
          headerClassName: classNames.secondaryHeader,
          name: t('schema-management.index-schema.th-retrievable'),
          columnActionsMode: ColumnActionsMode.disabled,
        },
        {
          minWidth: 75,
          maxWidth: 75,
          key: 'filterable',
          isMultiline: false,
          fieldName: 'filterable',
          className: tertiaryCell,
          headerClassName: classNames.secondaryHeader,
          name: t('schema-management.index-schema.th-filterable'),
          columnActionsMode: ColumnActionsMode.disabled,
        },
        {
          minWidth: 75,
          maxWidth: 75,
          key: 'facetable',
          isMultiline: false,
          fieldName: 'facetable',
          className: tertiaryCell,
          headerClassName: classNames.secondaryHeader,
          name: t('schema-management.index-schema.th-facetable'),
          columnActionsMode: ColumnActionsMode.disabled,
        },
        {
          minWidth: 75,
          maxWidth: 75,
          key: 'sortable',
          isMultiline: false,
          fieldName: 'sortable',
          className: tertiaryCell,
          headerClassName: classNames.secondaryHeader,
          name: t('schema-management.index-schema.th-sortable'),
          columnActionsMode: ColumnActionsMode.disabled,
        },
        {
          key: 'options',
          name: '',
          minWidth: 70,
          maxWidth: 70,
          fieldName: 'options',
          headerClassName: classNames.unhoverable,
        },
      ];

      return rootHasComplexType
        ? [
            {
              key: 'icon',
              minWidth: 14,
              maxWidth: 14,
              isIconOnly: true,
              name: 'File Icon',
              headerClassName: mergeStyles(classNames.headerGap, classNames.unhoverable),
            },
            ...columns,
          ]
        : columns;
    },
    [
      schemaViewMode,
      classNames.secondaryCell,
      classNames.hoverable,
      classNames.primaryCell,
      classNames.primaryHeader,
      classNames.secondaryHeader,
      classNames.headerGap,
      classNames.unhoverable,
      t,
      onColumnHeaderClick,
      listItemsOrder,
    ],
  );

  const onInsertIndexField = useCallback(
    (key: string) => {
      const updatedIndexFieldList = insertSearchIndexField(key, updatedSearchIndex.fields);
      setUpdatedSearchIndex(s => ({
        ...s,
        fields: updatedIndexFieldList,
      }));
    },
    [updatedSearchIndex.fields],
  );

  const onUpdateIndexField = useCallback(
    (key: string, property: string, value: string | boolean) => {
      let updatedIndexFieldList = updateSearchIndexField(key, property, value, updatedSearchIndex.fields);

      // Add initial child to fields updgraded to objects
      if (property === 'type' && value === 'Object') {
        updatedIndexFieldList = insertSearchIndexField(key, updatedIndexFieldList);
      }

      setUpdatedSearchIndex(s => ({
        ...s,
        fields: updatedIndexFieldList,
      }));
    },
    [updatedSearchIndex.fields],
  );

  const onRemoveIndexField = useCallback(
    (key: string) => {
      const updatedIndexFieldList = removeSearchIndexField(key, updatedSearchIndex.fields);
      setUpdatedSearchIndex(s => ({
        ...s,
        fields: updatedIndexFieldList,
      }));
    },
    [updatedSearchIndex.fields],
  );

  const onRenderIndexField = useCallback(
    (props?: IDetailsGroupDividerProps) => {
      const indexFieldKey = props && props.group ? props.group.data.key : '';
      const indexField = props && props.group ? props.group.data : createInitialIndexField();
      switch (schemaViewMode) {
        case ViewMode.Read:
          return <IndexField {...props} indexField={indexField} />;
        case ViewMode.Edit:
          return (
            <div ref={!!props && props.groupIndex === 0 ? rowRef : undefined}>
              <IndexFieldEditor
                {...props}
                indexField={indexField}
                enableComplexFieldTypes
                onRemoveIndexField={() => onRemoveIndexField(indexFieldKey)}
                enableGeoPointType={isFeatureEnabledPerTenant(activeTenant, Feature.GeoLocation)}
                onUpdateIndexField={(key, value) => onUpdateIndexField(indexFieldKey, key, value)}
                onInsertIndexField={() =>
                  !!indexField.type &&
                  !!SearchIndexTypeConfig[indexField.type] &&
                  SearchIndexTypeConfig[indexField.type].dataType === 'Object' &&
                  onInsertIndexField(indexFieldKey)
                }
              />
            </div>
          );
        default:
          return null;
      }
    },
    [activeTenant, onInsertIndexField, onRemoveIndexField, onUpdateIndexField, rowRef, schemaViewMode],
  );

  const onAddActionClick = useCallback(() => {
    onInsertIndexField(ROOT_FIELD_KEY);
  }, [onInsertIndexField]);

  const onSaveActionClick = useCallback(() => {
    if (!!searchIndex.id) {
      dispatch(saveActiveSearchIndex(updatedSearchIndex, undefined, () => setSchemaViewMode(ViewMode.Edit)));
    } else {
      dispatch(
        createNewSearchIndex(
          updatedSearchIndex,
          () => setEnableNewIndexCreation(false),
          () => setSchemaViewMode(ViewMode.Edit),
        ),
      );
    }
    dispatch(resetActiveDetectedIndexSchema(activeSchemaDetection.key));
    setSchemaViewMode(ViewMode.Read);
  }, [activeSchemaDetection.key, dispatch, searchIndex, updatedSearchIndex]);

  const onCancelActionClick = useCallback(() => {
    setEnableNewIndexCreation(false);
    dispatch(resetActiveDetectedIndexSchema(activeSchemaDetection.key));
    setUpdatedSearchIndex(searchIndex);
    setSchemaViewMode(ViewMode.Read);
  }, [activeSchemaDetection.key, dispatch, searchIndex]);

  const onEditActionClick = useCallback(() => {
    setSchemaViewMode(ViewMode.Edit);
  }, []);

  const onUploadActionClick = useCallback(() => {
    setShowUploadPanel(true);
  }, []);

  useEffect(() => {
    onRenderPageFooterProps(
      schemaViewMode === ViewMode.Edit
        ? {
            onSave: onSaveActionClick,
            onCancel: onCancelActionClick,
            saveButtonText: t('common.save'),
            cancelButtonDisabled: isNonEditableIndex,
            saveButtonDisabled: !!searchIndex.id && (isNonEditableIndex || !isSchemaUpdated),
          }
        : undefined,
    );
    return () => {
      onRenderPageFooterProps(undefined);
    };
  }, [
    isNonEditableIndex,
    isSchemaUpdated,
    onCancelActionClick,
    onRenderPageFooterProps,
    onSaveActionClick,
    schemaViewMode,
    searchIndex.id,
    t,
  ]);

  const onDatasetUploaded = useCallback(
    (dataset: File) => {
      dispatch(detectIndexSchema(dataset));
      setShowUploadPanel(false);
    },
    [dispatch],
  );

  const getTutorialSteps = () => {
    return (
      <Tutorial
        target={rowRef}
        step={TutorialSteps.SchemaFieldRow}
        headline={t('tutorial.catalog.headline')}
        onDismiss={() => {
          setSchemaViewMode(ViewMode.Read);
        }}
      >
        <p>{t('tutorial.catalog.step-3.paragraph-1')}</p>
        <p>{t('tutorial.catalog.step-3.paragraph-2')}</p>
      </Tutorial>
    );
  };

  const rootHasComplexType = useMemo(
    () =>
      !!updatedSearchIndex.fields.find(
        f => !!f.type && !!SearchIndexTypeConfig[f.type] && SearchIndexTypeConfig[f.type].dataType === 'Object',
      ),
    [updatedSearchIndex.fields],
  );

  const onRenderDetailsHeaderInternal = useCallback((props, defaultRender) => {
    return !!defaultRender ? <Sticky stickyPosition={StickyPositionType.Header}>{defaultRender(props)}</Sticky> : null;
  }, []);

  return (
    <>
      {getTutorialSteps()}
      <DatasetUpload
        isOpen={showUploadPanel}
        onUpload={onDatasetUploaded}
        title={t('schema-management.upload-title')}
        onDismiss={() => {
          tutorial.currentTutorial !== AvailableTutorials.Catalog && setShowUploadPanel(false);
        }}
        onTutorialDismiss={() => {
          setShowUploadPanel(false);
          setSchemaViewMode(ViewMode.Read);
        }}
      />
      {!searchIndex.id && !enableNewIndexCreation && (
        <NoItemsToDisplay
          text={t('schema-management.no-index-text')}
          subText={t('schema-management.no-index-sub-text')}
          actionProps={[
            {
              disabled: isNonEditableIndex,
              buttonText: t('schema-management.no-index-upload-text'),
              onClick: () => setShowUploadPanel(true),
            },
            {
              disabled: isNonEditableIndex,
              buttonText: t('schema-management.no-index-edit-text'),
              onClick: () => {
                setEnableNewIndexCreation(true);
                setSchemaViewMode(ViewMode.Edit);
              },
            },
          ]}
        />
      )}
      {(!!searchIndex.id || enableNewIndexCreation) && (
        <>
          <SchemaListHeader
            indexFields={updatedSearchIndex.fields}
            onViewModeChange={(viewMode: ViewMode) => setSchemaViewMode(viewMode)}
            actions={{
              upload: { onClick: onUploadActionClick, disabled: isNonEditableIndex || activeTenant.isSandbox },
              edit: {
                onClick: onEditActionClick,
                disabled:
                  isNonEditableIndex ||
                  schemaViewMode === ViewMode.Edit ||
                  (activeTenant.isSandbox && currentTutorial !== AvailableTutorials.Catalog),
              },
              addRow: { onClick: onAddActionClick, disabled: isNonEditableIndex },
            }}
            schemaViewMode={schemaViewMode}
            onChange={(newGroups: GroupExtended[]) => {
              setSchemaGroups(newGroups);
            }}
            onSchemaTutorialStepEnd={() => {
              setShowUploadPanel(true);
            }}
          />
          <Prompt when={isSchemaUpdated} message={t('common.on-before-unload-msg')} />
          <ScrollablePane
            scrollbarVisibility={ScrollbarVisibility.auto}
            styles={classNames.subComponentStyles.scrollablePane}
          >
            <ShimmeredDetailsList
              items={[]}
              isHeaderVisible={true}
              columns={getSchemaColumns(rootHasComplexType)}
              enableShimmer={isLoading}
              selectionMode={SelectionMode.none}
              constrainMode={ConstrainMode.unconstrained}
              layoutMode={DetailsListLayoutMode.justified}
              checkboxVisibility={CheckboxVisibility.onHover}
              groups={schemaGroups}
              detailsListStyles={classNames.subComponentStyles.detailsList}
              onRenderDetailsHeader={onRenderDetailsHeaderInternal}
              onShouldVirtualize={() => false}
              groupProps={{
                showEmptyGroups: true,
                onRenderHeader: onRenderIndexField,
                collapseAllVisibility: CollapseAllVisibility.hidden,
              }}
            />
          </ScrollablePane>
        </>
      )}
    </>
  );
};
