/* eslint-disable react/display-name */
import React, { useState, useContext, createRef } from 'react';
import {
  CommandButton,
  IContextualMenuItem,
  IconButton,
  Icon,
  ProgressIndicator,
  classNamesFunction,
  OverflowSet,
  IOverflowSetItemProps,
} from 'office-ui-fabric-react';
import { useSelector, useDispatch } from 'react-redux';
import { AuthContext } from '../../../contexts';
import { AppState } from '../../../store/reducers';
import { readAllNotifications, setIsNotificationsPanelOpen } from '../../../store/actions/notificationCenterActions';
import { NotificationPanel } from './Panels/NotificationsPanel';
import { ProductSupportPanel } from './Panels/ProductSupportPanel';
import { ProductToursPanel } from './Panels/ProductToursPanel';
import { useAdminRouter } from '../../../store/hooks/use-router/useAdminRouter';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { TenantsListPanel } from './Panels/TenantsListPanel';
import { NavBarRightMenuBaseProps, NavBarRightMenuStyleProps, NavBarRightMenuStyles } from './NavBarRightMenu.types';
import { useMediaQuery } from 'react-responsive';
import { UserPersona, PersonaType } from '../../common/UserPersona';
import { TutorialSteps, Tutorial } from '../../Tutorials';
import { toast } from 'react-toastify';
import { useTenantPermissions } from '../../../store/hooks/use-user-permissions/UseTenantPermissions';
import { Permission } from '../../../config/userPermissions.config';

const getClassNames = classNamesFunction<NavBarRightMenuStyleProps, NavBarRightMenuStyles>();

export const NavBarRightMenuBase = (props: NavBarRightMenuBaseProps): JSX.Element => {
  const { t, className, componentRef, theme, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();
  const tenantPermissions = useTenantPermissions();

  const tenantsCount = useSelector((state: AppState) => Object.keys(state.tenantsList.tenantIdMapper).length);
  const activeTenant = useSelector((state: AppState) => state.tenantsList.activeTenant);
  const unreadNotificationsCount = useSelector((state: AppState) => state.notificationsCenter.unreadNotificationsCount);
  const showNotificationsProgress = useSelector((state: AppState) => state.notificationsCenter.showProgressBar);
  const openNotificationPanel = useSelector((state: AppState) => state.notificationsCenter.isPanelOpen);
  const currentUser = useSelector((state: AppState) => state.user);

  const notificationButtonRef = createRef<HTMLDivElement>();

  const context = useContext(AuthContext);

  const [openProductSupportPanel, setOpenProductSupportPanel] = useState(false);
  const [openProductToursPanel, setOpenProductToursPanel] = useState(false);
  const [openTenantsListPanel, setOpenTenantsListPanel] = useState(false);

  const firstBreakPoint = useMediaQuery({ query: '(max-width: 850px)' });
  const secondBreakPoint = useMediaQuery({ query: '(max-width: 372px)' });

  const adminRouteMap = useAdminRouter();

  const switchTenantOption =
    tenantsCount > 1
      ? [
          {
            key: 'switch',
            text: t('nav-bar-right-menu.switch-tenant-option'),
            onClick: () => setOpenTenantsListPanel(true),
            iconProps: {
              iconName: 'UnstackSelected',
            },
          },
        ]
      : [];

  const onRenderItemInternal = (item: IOverflowSetItemProps): JSX.Element => {
    return (
      <CommandButton
        key={item.key}
        aria-label={item.key}
        onRenderText={item.onRenderText}
        onClick={item.onClick}
        className={item.className}
        menuProps={item.menuProps}
      />
    );
  };

  const onRenderOverflowButtonInternal = (overflowItems: IOverflowSetItemProps[] | undefined): JSX.Element | null => {
    return !!overflowItems ? (
      <CommandButton
        key="More items"
        ariaLabel={t('nav-bar-right-menu.more-items-aria')}
        iconProps={{ iconName: 'More' }}
        menuProps={{ items: overflowItems.map(item => ({ ...item, text: item.key })) }}
      />
    ) : null;
  };

  const getOverFlowSetItems = (): [IOverflowSetItemProps[], IOverflowSetItemProps[]] => {
    let overFlowItemsInternal: IOverflowSetItemProps[] = [];
    let itemsInternal: IOverflowSetItemProps[] = [
      {
        key: 'Notifications center',
        onClick: () => {
          toast.dismiss();
          dispatch(readAllNotifications());
          dispatch(setIsNotificationsPanelOpen(true));
        },
        className: classNames.notificationsCenterWrapper,
        onRenderText: (): JSX.Element => {
          return (
            <>
              <div className={classNames.notificationsCenterLink} ref={notificationButtonRef}>
                <Icon
                  iconName={'Ringer'}
                  ariaLabel={t('nav-bar-right-menu.notification-center-aria')}
                  className={classNames.notificationsCenterIcon}
                />
                {unreadNotificationsCount !== 0 && (
                  <div className={classNames.unreadNotificationsCountTag}> {unreadNotificationsCount} </div>
                )}
              </div>
              {showNotificationsProgress && <ProgressIndicator className={classNames.notificationsProgressIndicator} />}
            </>
          );
        },
      },
    ];

    let firstBreakPointItems: IOverflowSetItemProps[] = !!activeTenant.id
      ? [
          {
            key: 'Product tours',
            iconProps: { iconName: 'ReadingMode' },
            onRenderText: (): JSX.Element => {
              return (
                <IconButton
                  iconProps={{ iconName: 'ReadingMode' }}
                  ariaLabel={t('nav-bar-right-menu.documentation-button-aria')}
                />
              );
            },
            onClick: () => setOpenProductToursPanel(true),
          },
          {
            key: 'Product support',
            iconProps: { iconName: 'Help' },
            onRenderText: (): JSX.Element => {
              return (
                <IconButton iconProps={{ iconName: 'Help' }} ariaLabel={t('nav-bar-right-menu.help-button-aria')} />
              );
            },
            onClick: () => setOpenProductSupportPanel(true),
          },
        ]
      : [
          {
            key: 'Product support',
            iconProps: { iconName: 'Help' },
            onRenderText: (): JSX.Element => {
              return (
                <IconButton iconProps={{ iconName: 'Help' }} ariaLabel={t('nav-bar-right-menu.help-button-aria')} />
              );
            },
            onClick: () => setOpenProductSupportPanel(true),
          },
        ];

    let secondBreakPointItems: IOverflowSetItemProps[] = activeTenant.id
      ? [
          {
            key: 'Adminstration',
            iconProps: {
              iconName: 'Admin',
            },
            menuProps: {
              items: [
                {
                  key: 'Roles',
                  text: t('nav-bar-right-menu.roles-permission-option'),
                  disabled: activeTenant.isSandbox,
                  iconProps: {
                    iconName: 'SecurityGroup',
                  },
                  onClick: () => adminRouteMap(MbcRouteKey.RoleList).browserPush(),
                },
                {
                  key: 'Tokens',
                  text: t('nav-bar-right-menu.api-tokens-option'),
                  disabled:
                    activeTenant.isSandbox ||
                    !(
                      tenantPermissions.includes(Permission.AdminTokenCreate) ||
                      tenantPermissions.includes(Permission.AdminTokenRevoke)
                    ),
                  iconProps: {
                    iconName: 'Permissions',
                  },
                  onClick: () => adminRouteMap(MbcRouteKey.ApiAndTokenList).browserPush(),
                },
              ],
            },
            subMenuProps: {
              items: [
                {
                  key: 'Roles',
                  text: t('nav-bar-right-menu.roles-permission-option'),
                  disabled: activeTenant.isSandbox,
                  iconProps: {
                    iconName: 'SecurityGroup',
                  },
                  onClick: () => adminRouteMap(MbcRouteKey.RoleList).browserPush(),
                },
                {
                  key: 'Tokens',
                  text: t('nav-bar-right-menu.api-tokens-option'),
                  disabled:
                    activeTenant.isSandbox ||
                    !(
                      tenantPermissions.includes(Permission.AdminTokenCreate) ||
                      tenantPermissions.includes(Permission.AdminTokenRevoke)
                    ),
                  iconProps: {
                    iconName: 'Permissions',
                  },
                  onClick: () => adminRouteMap(MbcRouteKey.ApiAndTokenList).browserPush(),
                },
              ],
            },
            onRenderText: (): JSX.Element => {
              return (
                <IconButton iconProps={{ iconName: 'Admin' }} ariaLabel={t('nav-bar-right-menu.administration-aria')} />
              );
            },
          },
        ]
      : [];

    if (secondBreakPoint) {
      overFlowItemsInternal = [...secondBreakPointItems, ...firstBreakPointItems];
    } else if (firstBreakPoint) {
      itemsInternal = [...itemsInternal, ...secondBreakPointItems];
      overFlowItemsInternal = firstBreakPointItems;
    } else {
      itemsInternal = [...itemsInternal, ...secondBreakPointItems, ...firstBreakPointItems];
    }
    return [itemsInternal, overFlowItemsInternal];
  };

  const [items, overFlowItems] = getOverFlowSetItems();

  return (
    <div ref={componentRef} className={classNames.root}>
      <Tutorial
        target={notificationButtonRef}
        step={TutorialSteps.SchemaDetectionNotification}
        headline={t('tutorial.catalog.headline')}
      >
        <p>{t('tutorial.catalog.step-6.paragraph-1')}</p>
        <p>{t('tutorial.catalog.step-6.paragraph-2')}</p>
      </Tutorial>
      <NotificationPanel
        onDismiss={() => dispatch(setIsNotificationsPanelOpen(false))}
        openNotificationPanel={openNotificationPanel}
      />
      <ProductSupportPanel onDismiss={() => setOpenProductSupportPanel(false)} isOpen={openProductSupportPanel} />
      <ProductToursPanel onDismiss={() => setOpenProductToursPanel(false)} isOpen={openProductToursPanel} />
      <TenantsListPanel onDismiss={() => setOpenTenantsListPanel(false)} isOpen={openTenantsListPanel} />

      <OverflowSet
        onRenderItem={onRenderItemInternal}
        onRenderOverflowButton={onRenderOverflowButtonInternal}
        items={items}
        overflowItems={overFlowItems}
      />

      <CommandButton
        key="User details"
        className={classNames.personaButton}
        menuProps={{
          items: [
            {
              key: 'accountdetails',
              // eslint-disable-next-line react/display-name
              onRender: (item: IContextualMenuItem): JSX.Element | null => {
                return (
                  <UserPersona
                    key={item.key}
                    personaType={PersonaType.WithDetails}
                    ariaLabel={t('nav-bar-right-menu.persona-aria-label')}
                    user={currentUser}
                    className={classNames.personaButton}
                  ></UserPersona>
                );
              },
            },
            ...switchTenantOption,

            {
              key: 'signout',
              text: t('nav-bar-right-menu.sign-out'),
              iconProps: {
                iconName: 'SignOut',
              },
              onClick: () => context.logout(),
            },
          ],
        }}
        onRenderText={(): JSX.Element => {
          return (
            <UserPersona
              key={'account'}
              personaType={PersonaType.HidePersonaDetails}
              ariaLabel={t('nav-bar-right-menu.persona-aria-label')}
              user={currentUser}
            ></UserPersona>
          );
        }}
      />
    </div>
  );
};
