import { RolePermissionsEditorStyleProps, RolePermissionsEditorStyles } from './RolePermissionsEditor.types';

export const styles = (props: RolePermissionsEditorStyleProps): RolePermissionsEditorStyles => {
  const { className, theme } = props;

  return {
    root: [
      'mbc-role-permissions-editor',
      {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      className,
    ],
    categoryContainer: [
      {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0',
      },
    ],
    categoryTitle: [
      {
        fontSize: '14px',
        fontWeight: '600',
        color: theme.palette.black,
        textTransform: 'capitalize',
      },
    ],
    permissionsPerCategory: [
      {
        margin: '5px',
      },
    ],
    fieldColumn: [
      {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        color: '#605e5c',
      },
    ],
    emptyChoiceGroup: [
      {
        display: 'inline-block',
        width: '100px',
      },
    ],
    checkBox: [
      'mbc-permission-check-box',
      {
        selectors: {
          '.ms-Checkbox-checkbox': {
            borderRadius: 16,
            borderColor: props.theme.palette.neutralPrimary,
          },
          '.is-disabled': {
            selectors: {
              '.ms-Checkbox-checkbox': {
                borderRadius: 16,
                borderColor: props.theme.palette.neutralTertiaryAlt,
              },
            },
          },
        },
      },
    ],
  };
};
