import { DatasetUploadPanelStyles, DatasetUploadPanelStyleProps } from './DatasetUploadPanel.types';

export const styles = (props: DatasetUploadPanelStyleProps): DatasetUploadPanelStyles => ({
  root: [
    'mbc-dataset-panel',
    {
      selectors: {
        '.ms-Panel-contentInner': {
          justifyContent: 'space-between',
        },
      },
    },
  ],
  header: [
    'mbc-header',
    {
      fontSize: 21,
      lineHeight: 28,
      fontWeight: 500,
    },
  ],
  footer: [
    'mbc-footer',
    {
      selectors: {
        '.ms-Button--default': {
          color: props.theme.palette.blue,
        },
      },
    },
  ],
  multiLineInput: [
    'mbc-multi-line-input',
    {
      width: 290,
      selectors: {
        '.ms-TextField-fieldGroup': {
          borderStyle: 'none',
        },
        '.ms-TextField-field': {
          backgroundColor: props.theme.palette.neutralLighter,
          minHeight: 100,
        },
      },
    },
  ],
  textInput: [
    'mbc-text-input',
    {
      width: 290,
      selectors: {
        '.ms-TextField-fieldGroup': {
          borderStyle: 'none',
        },
        '.ms-TextField-field': {
          backgroundColor: props.theme.palette.neutralLighter,
        },
        '.ms-Dropdown-title': {
          borderStyle: 'none',
          backgroundColor: props.theme.palette.neutralLighter,
        },
      },
    },
  ],
});
