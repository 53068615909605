import { MbcRouteKey, MbcRouteMap, MbcRoute } from './MbcRouter.types';
import { browserHistory } from '../history.module';

export const MbcRouterBase = <T extends MbcRouteKey>(mbcRouteMapper: MbcRouteMap) => {
  const browserPush = (mbcRouteKey: T) => () => {
    !!mbcRouteMapper[mbcRouteKey] &&
      browserHistory.push(mbcRouteMapper[mbcRouteKey].redirectUrl || mbcRouteMapper[mbcRouteKey].url);
  };

  return (mbcRouteKey: T): MbcRoute => ({
    ...mbcRouteMapper[mbcRouteKey],
    browserPush: browserPush(mbcRouteKey),
  });
};
