import { Product } from '../../types/tenantsList.d';

export interface ProductShortcut {
  text: string;
  ariaLabel: string;
  icon: string;
  product: Product;
  priority: number;
  disabled: boolean;
  onClick?: () => void;
}

export interface ProductShortcutMap {
  [key: string]: ProductShortcut;
}

export enum LinkIconSize {
  Small,
  Large,
}
