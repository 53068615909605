import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';

import styles from './LoadingSpinner.module.scss';
import classNames from 'classnames';

interface LoadingProps {
  fullPage?: boolean;
}

class Loading extends Component<LoadingProps & WithTranslation> {
  render() {
    const { t } = this.props;
    return (
      <div className={classNames(styles.spinnerContainer, this.props.fullPage ? styles.fullPage : '')}>
        <Spinner className={styles.spinner} size={SpinnerSize.large} label={t('loading.loading-message')} />
      </div>
    );
  }
}

export default withTranslation()(Loading);
