import React from 'react';
import { AppState } from '../../../../../../store/reducers';
import { classNamesFunction, CheckboxVisibility, SelectionMode } from 'office-ui-fabric-react';
import { DefaultSearchInstanceId } from '../../../../../../store/types/customizations/searchInstanceList.d';
import { useSelector } from '../../../../../../store/hooks';
import { SearchInstanceListStyles, SearchInstanceListProps } from './SearchInstanceList.types';
import { SearchInstanceListPanelStyleProps } from '../SearchInstanceListPanel.types';
import { DetailsListCard } from '../../../../../../components/common/DetailsListCard';
import {
  ColumnPriority,
  SortDataType,
  OptionsItemProps,
  SelectionItem,
  DetailsListProps,
} from '../../../../../../components/common/DetailsList';

interface SearchInstaceListItem extends SelectionItem {
  name: string;
}
const getClassNames = classNamesFunction<SearchInstanceListPanelStyleProps, SearchInstanceListStyles>();

export const SearchInstanceListBase = (props: SearchInstanceListProps) => {
  const {
    t,
    className,
    styles,
    theme,
    componentRef,
    onItemSelection,
    onDeleteItemClick,
    onCloneItemClick,
    onAddItemClick,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const isSearchInstanceListLoading = useSelector((state: AppState) => state.searchInstanceList.isLoading);
  const searchInstanceIdMapper = useSelector((state: AppState) => state.searchInstanceList.searchInstanceIdMapper);
  const activeSearchInstance = useSelector((state: AppState) => state.searchInstanceList.activeSearchInstance);

  const getListItems = (): SearchInstaceListItem[] => {
    let items: SearchInstaceListItem[] = [];
    for (const [key, value] of Object.entries(searchInstanceIdMapper)) {
      items.push({ key: key, name: value.name, canSelect: true });
    }
    return items;
  };

  const getSelectedListItem = (): SearchInstaceListItem => {
    return getListItems().filter(item => item.key === activeSearchInstance.id)[0];
  };

  const getOptionsItemProps = (item: any): OptionsItemProps[] => {
    return [
      {
        key: 'Delete',
        text: 'Delete',
        iconProps: { iconName: 'Delete' },
        disabled: (item.key as string).toLowerCase() === DefaultSearchInstanceId.toLowerCase(),
        onClick: () => {
          onDeleteItemClick(item.key);
        },
      },
      {
        key: 'Clone',
        text: 'Clone',
        iconProps: { iconName: 'Copy' },
        disabled: false,
        onClick: () => {
          onCloneItemClick(item.key);
        },
      },
    ];
  };

  const detailsListProps: DetailsListProps = {
    isLoading: isSearchInstanceListLoading,
    isLoadedAndEmpty: isSearchInstanceListLoading && Object.keys(searchInstanceIdMapper).length > 0,
    items: getListItems(),
    onRenderNoItemsToDisplay: { text: t('synonym-list.no-data-text') },
    selectionProps: {
      checkboxVisibility: CheckboxVisibility.onHover,
      selectionMode: SelectionMode.single,
      defaultSelected: [getSelectedListItem()],
      onSelectionChanged: (selection: string[]) => onItemSelection(selection[0]),
    },
    responsiveColumns: {
      columns: [
        {
          key: 'name',
          name: 'Name',
          fieldName: 'name',
          priority: ColumnPriority.Primary,
          sortDataType: SortDataType.text,
          minWidth: 100,
        },
      ],
      secondaryBreakPoint: 50,
      teritaryBreakPoint: 100,
    },
    optionsItemProps: (item: any) => getOptionsItemProps(item),
  };

  return (
    <div ref={componentRef} className={classNames.root}>
      <DetailsListCard
        styles={{
          root: {
            selectors: {
              '.mbc-search-box': { width: 'auto' },
            },
          },
        }}
        searchActionProps={{ searchBy: 'name' }}
        listProps={detailsListProps}
        header={{
          actions: {
            add: {
              onClick: () => {
                onAddItemClick();
              },
            },
          },
        }}
      />
    </div>
  );
};
