import {
  ProgressIndicatorActionTypes,
  SHOW_PROGRESS_INDICATOR,
  HIDE_PROGRESS_INDICATOR,
  ProgressIndicatorState,
} from '../types/progressIndicator.d';

const initialState: ProgressIndicatorState = {
  inProgress: false,
};

export function ProgressIndicatorReducer(
  state = initialState,
  action: ProgressIndicatorActionTypes,
): ProgressIndicatorState {
  switch (action.type) {
    case SHOW_PROGRESS_INDICATOR: {
      return {
        ...state,
        inProgress: true,
      };
    }
    case HIDE_PROGRESS_INDICATOR: {
      return {
        ...state,
        inProgress: false,
      };
    }
    default:
      return state;
  }
}
