import { useCallback } from 'react';
import { useSelector } from '..';
import { MbcEnvironmentRouter } from '../../../router/MbcEnvironmentRouter/MbcEnvironmentRouter';

export const useEnvironmentRouter = (environmentId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const environmentMapper = useSelector(state => state.environmentList.environmentIdMapper);

  return useCallback(
    MbcEnvironmentRouter(activeTenant, !!environmentId ? environmentMapper[environmentId] : activeEnvironment),
    [activeTenant, activeEnvironment, environmentMapper, environmentId],
  );
};
