import { CollapsiblePaneStyles, CollapsiblePaneStyleProps } from './CollapsiblePane.types';

export const styles = (props: CollapsiblePaneStyleProps): CollapsiblePaneStyles => {
  const { className } = props;

  return {
    root: ['mbc-CollapsiblePane', {}, className],
    paneContent: [
      {
        paddingTop: 16,
        paddingLeft: 24,
        paddingRight: 24,
      },
    ],
    expandedBody: [
      {
        minWidth: 420,
        height: '100%',
        borderLeft: '1px solid #EDEBE9',
      },
    ],

    collapsedBody: [
      {
        maxWidth: 32,
        minWidth: 32,
        display: 'none',
      },
    ],
    collapsedHeader: [
      {
        minHeight: 44,
        maxHeight: 44,
        marginRight: 4,
        borderBottom: '1px solid #EDEBE9',
      },
    ],
    collapsedHeaderContent: [
      {
        display: 'none',
      },
    ],
    expandedHeader: [
      {
        minHeight: 44,
        maxHeight: 44,
        marginRight: 4,
        paddingLeft: 24,
        borderBottom: '1px solid #EDEBE9',
        borderLeft: '1px solid #EDEBE9',
      },
    ],
  };
};
