import { SearchInstanceListStyleProps, SearchInstanceListStyles } from './SearchInstanceList.types';

export const styles = (props: SearchInstanceListStyleProps): SearchInstanceListStyles => {
  return {
    root: ['mbc-tenants-list', props.className],
    searchCommandBar: [
      'mbc-tenants-list-search-bar',
      {
        marginBottom: 10,
        marginTop: 10,
      },
    ],
  };
};
