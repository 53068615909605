import { PieChartStyleProps, PieChartStyles } from './PieChart.types';

export const getStyles = (props: PieChartStyleProps): PieChartStyles => ({
  root: [
    'ms-pie-chart',
    {
      alignItems: 'center',
      display: 'grid',
      gridTemplateColumns: '65% 35%',
      width: '100%',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    props.className,
  ],
  itemLabel: [
    'ms-label-item',
    {
      width: '100%',
      position: 'relative',
      selectors: {
        '.ms-Button-icon': { fontSize: 12 },
      },
    },
  ],
  labelGroup: [
    'ms-label-group',
    {
      width: '100%',
    },
  ],
  labelItemKey: [
    'ms-label-key',
    { fontSize: 'small', textOverflow: 'ellipsis', overflow: 'hidden', marginRight: '5px' },
  ],
  labelItemShape: [
    'ms-label-shape',
    {
      minWidth: 12,
      height: 12,
      cursor: 'pointer',
      borderRadius: '50%',
      border: '1px solid ' + props.theme.palette.neutralLight,
    },
  ],
  tooltipLabel: [
    'ms-label-tooltip',
    {
      fontSize: 12,
      color: props.theme.palette.black,
    },
  ],
});
