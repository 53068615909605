import _ from 'lodash';
import { FacetColorSet } from './Facets.config';
import { normalizeColor, getSearchIndexField } from './Facets.utils';
import React, { useState, useEffect, useCallback } from 'react';
import { FacetsStyleProps, FacetsStyles, FacetsProps, FacetFieldMap } from './Facets.types';
import { FacetOption, Facet } from '../../../../../restful-apis/dto/search/facet';
import {
  classNamesFunction,
  Stack,
  Checkbox,
  Separator,
  TooltipHost,
  TooltipOverflowMode,
  Icon,
  mergeStyles,
} from 'office-ui-fabric-react';
import { SearchIndex } from '../../../../../store/types/searchIndex';
import { useSelector } from '../../../../../store/hooks';
import moment from 'moment';
import { isProductionEnvironment } from '../../../../../guards/EnvironmentGuard/utils';

const MAX_FACET_GROUP_ITEMS = 4;
const MAX_COLOR_FACET_GROUP_ITEMS = 6;

const getClassNames = classNamesFunction<FacetsStyleProps, FacetsStyles>();

export const FacetsBase = (props: FacetsProps): JSX.Element | null => {
  const { t, styles, theme, className, componentRef, facets, selectedFacets, onSelectedFacetsChanged } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const searchIndex = useSelector<SearchIndex>(state => state.searchIndex.searchIndex);
  const activeTenant = useSelector(state => state.tenantsList.activeTenant.id);

  const [showAllFacetMap, setShowAllFacetMap] = useState<FacetFieldMap>({});

  const [expandedCategoryFacets, setExpandedCategoryFacets] = useState<string[]>([]);

  useEffect(() => {
    setShowAllFacetMap({});
    return () => {};
  }, [facets]);

  const onFacetOptionToggle = useCallback(
    (refinement: FacetOption, checked?: boolean) => {
      if (checked) {
        refinement.selected = true;
        onSelectedFacetsChanged(selectedFacets.concat({ ...refinement }));
      } else {
        onSelectedFacetsChanged([
          ...selectedFacets.filter(
            r =>
              !(
                r.field === refinement.field &&
                r.value === refinement.value &&
                r.ge === refinement.ge &&
                r.lt === refinement.lt
              ),
          ),
        ]);
      }
    },
    [onSelectedFacetsChanged, selectedFacets],
  );

  const onRenderFacetCheckbox = useCallback(
    (facet: FacetOption, value: string) => {
      return (
        <Checkbox
          checked={facet.selected}
          onRenderLabel={() => (
            <TooltipHost overflowMode={TooltipOverflowMode.Self} hostClassName={classNames.overflow} content={value}>
              <div style={{ whiteSpace: 'nowrap', display: 'inline' }}>{value}</div>
            </TooltipHost>
          )}
          onChange={(ev, checked) => onFacetOptionToggle(facet, checked || false)}
        />
      );
    },
    [classNames.overflow, onFacetOptionToggle],
  );

  const updateExpandedCategoryFacets = useCallback(
    (value: string) => {
      const found = expandedCategoryFacets.some(v => v.startsWith(value));
      if (found) {
        setExpandedCategoryFacets(facets => facets.filter(f => !f.startsWith(value)));
      } else {
        setExpandedCategoryFacets(facets => [...facets, value]);
      }
    },
    [expandedCategoryFacets],
  );

  const onRenderCategoryFacetItem = useCallback(
    (facet: FacetOption, value: string, level: number = 0) => {
      let collapsedCount = 0;
      let visibleRefinements: JSX.Element | null = null;

      if (facet.refinements && level < 3) {
        if (showAllFacetMap[value]) {
          visibleRefinements = (
            <Stack styles={{ root: { paddingLeft: (level + 1) * 8 } }} tokens={{ childrenGap: 8 }}>
              {facet.refinements.map(r => {
                if (!!r.value) {
                  const refTokens = r.value.toString().split('|');
                  return onRenderCategoryFacetItem(r, refTokens[refTokens.length - 1], level + 1);
                }
              })}
              <div
                className={classNames.action}
                onClick={() => setShowAllFacetMap(map => ({ ...map, [value]: false }))}
              >
                {t('query-tester.results-facets-collapse')}
              </div>
            </Stack>
          );
        } else {
          collapsedCount = facet.refinements.length - Math.min(facet.refinements.length, MAX_FACET_GROUP_ITEMS);

          visibleRefinements = (
            <Stack styles={{ root: { paddingLeft: (level + 1) * 8 } }} tokens={{ childrenGap: 8 }}>
              {facet.refinements.slice(0, MAX_FACET_GROUP_ITEMS).map(r => {
                if (!!r.value) {
                  const refTokens = r.value.toString().split('|');
                  return onRenderCategoryFacetItem(r, refTokens[refTokens.length - 1], level + 1);
                }
              })}
              {collapsedCount > 0 && (
                <div
                  className={classNames.action}
                  onClick={() => setShowAllFacetMap(map => ({ ...map, [value]: true }))}
                >
                  {t('query-tester.results-facets', {
                    count: collapsedCount,
                  })}
                </div>
              )}
            </Stack>
          );
        }
      }

      return (
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack horizontal verticalAlign={'baseline'}>
            {facet.refinements && level < 3 && (
              <Icon
                className={classNames.categoryFacetIcon}
                iconName={expandedCategoryFacets.includes(value) ? 'ChevronDown' : 'ChevronRight'}
                onClick={() => updateExpandedCategoryFacets(value)}
              />
            )}
            <TooltipHost overflowMode={TooltipOverflowMode.Self} hostClassName={classNames.overflow} content={value}>
              <div
                className={
                  facet.selected
                    ? mergeStyles(classNames.categoryFacetLabel, classNames.selectedCategoryFacet)
                    : classNames.categoryFacetLabel
                }
                onClick={() => onFacetOptionToggle(facet, !facet.selected)}
              >
                {value}
              </div>
            </TooltipHost>
          </Stack>

          {!!visibleRefinements && expandedCategoryFacets.includes(value) && (
            <Stack styles={{ root: { marginLeft: 12 } }}>{visibleRefinements}</Stack>
          )}
        </Stack>
      );
    },
    [
      classNames.action,
      classNames.categoryFacetIcon,
      classNames.categoryFacetLabel,
      classNames.overflow,
      classNames.selectedCategoryFacet,
      expandedCategoryFacets,
      onFacetOptionToggle,
      showAllFacetMap,
      t,
      updateExpandedCategoryFacets,
    ],
  );

  const onRenderCategoryFacetGroup = useCallback(
    (facet: Facet) => {
      if (showAllFacetMap[facet.field]) {
        return (
          <Stack tokens={{ childrenGap: 8 }}>
            {facet.refinements.map(r => r.value !== undefined && onRenderCategoryFacetItem(r, r.value.toString()))}
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: false }))}
            >
              {t('query-tester.results-facets-collapse')}
            </div>
          </Stack>
        );
      }
      const collapsedCount = facet.refinements.length - Math.min(facet.refinements.length, MAX_FACET_GROUP_ITEMS);
      return (
        <Stack tokens={{ childrenGap: 16 }}>
          {facet.refinements
            .slice(0, MAX_FACET_GROUP_ITEMS)
            .map(r => (r.value || _.isBoolean(r.value)) && onRenderCategoryFacetItem(r, r.value.toString()))}
          {collapsedCount > 0 && (
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: true }))}
            >
              {t('query-tester.results-facets', {
                count: collapsedCount,
              })}
            </div>
          )}
        </Stack>
      );
    },
    [classNames.action, onRenderCategoryFacetItem, showAllFacetMap, t],
  );

  const onRenderRangeFacetGroup = useCallback(
    (facet: Facet, formatter: (facet: FacetOption) => string) => {
      const rangeFacets = showAllFacetMap[facet.field]
        ? facet.refinements
        : facet.refinements.slice(0, MAX_FACET_GROUP_ITEMS);

      const collapsedCount = facet.refinements.length - Math.min(facet.refinements.length, MAX_FACET_GROUP_ITEMS);
      return (
        <Stack tokens={{ childrenGap: 18 }}>
          {rangeFacets.map(r => r.ge !== undefined && r.lt !== undefined && onRenderFacetCheckbox(r, formatter(r)))}
          {!showAllFacetMap[facet.field] && collapsedCount > 0 && (
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: true }))}
            >
              {t('query-tester.results-facets', {
                count: collapsedCount,
              })}
            </div>
          )}
          {showAllFacetMap[facet.field] && (
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: false }))}
            >
              {t('query-tester.results-facets-collapse')}
            </div>
          )}
        </Stack>
      );
    },
    [classNames.action, onRenderFacetCheckbox, showAllFacetMap, t],
  );

  const onRenderDefaultFacetGroup = useCallback(
    (facet: Facet) => {
      if (showAllFacetMap[facet.field]) {
        return (
          <Stack tokens={{ childrenGap: 18 }}>
            {facet.refinements.map(r => r.value !== undefined && onRenderFacetCheckbox(r, r.value.toString()))}
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: false }))}
            >
              {t('query-tester.results-facets-collapse')}
            </div>
          </Stack>
        );
      }
      const collapsedCount = facet.refinements.length - Math.min(facet.refinements.length, MAX_FACET_GROUP_ITEMS);
      return (
        <Stack tokens={{ childrenGap: 18 }}>
          {facet.refinements
            .slice(0, MAX_FACET_GROUP_ITEMS)
            .map(r => (r.value || _.isBoolean(r.value)) && onRenderFacetCheckbox(r, r.value.toString()))}
          {collapsedCount > 0 && (
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: true }))}
            >
              {t('query-tester.results-facets', {
                count: collapsedCount,
              })}
            </div>
          )}
        </Stack>
      );
    },
    [classNames.action, onRenderFacetCheckbox, showAllFacetMap, t],
  );

  const onRenderColorFacetOption = useCallback(
    (facet: FacetOption) =>
      facet.value && (
        <TooltipHost content={facet.value as string}>
          {facet.selected ? (
            <div className={classNames.selectedColor}>
              <div
                key={facet.value.toString()}
                className={classNames.color}
                onClick={() => onFacetOptionToggle(facet, false)}
                style={{ backgroundColor: normalizeColor(facet.value), width: 28, height: 28 }}
              />
            </div>
          ) : (
            <div
              key={facet.value.toString()}
              className={classNames.color}
              onClick={() => onFacetOptionToggle(facet, true)}
              style={{ backgroundColor: normalizeColor(facet.value) }}
            />
          )}
        </TooltipHost>
      ),
    [classNames.color, classNames.selectedColor, onFacetOptionToggle],
  );

  const onRenderColorFacetGroup = useCallback(
    (facet: Facet) => {
      const standardFacetRefinements = facet.refinements.filter(r => FacetColorSet.has(normalizeColor(r.value)));
      const customFacetRefinements = facet.refinements.filter(r => !FacetColorSet.has(normalizeColor(r.value)));

      if (showAllFacetMap[facet.field]) {
        return (
          <Stack tokens={{ childrenGap: 18 }}>
            <div key={facet.field} className={classNames.colorGroup}>
              {standardFacetRefinements.map(onRenderColorFacetOption)}
            </div>

            {!!standardFacetRefinements.length && !!customFacetRefinements.length && (
              <div className={classNames.subtitle}>{'others'}</div>
            )}

            {customFacetRefinements.map(r => r.value && onRenderFacetCheckbox(r, r.value.toString()))}
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: false }))}
            >
              {t('query-tester.results-facets-collapse')}
            </div>
          </Stack>
        );
      }

      const collapsedCount =
        facet.refinements.length -
        Math.min(standardFacetRefinements.length || customFacetRefinements.length, MAX_COLOR_FACET_GROUP_ITEMS);

      return (
        <Stack tokens={{ childrenGap: 18 }}>
          <div key={facet.field} className={classNames.colorGroup}>
            {standardFacetRefinements.slice(0, MAX_COLOR_FACET_GROUP_ITEMS).map(onRenderColorFacetOption)}
          </div>

          {!standardFacetRefinements.length &&
            customFacetRefinements
              .slice(0, MAX_COLOR_FACET_GROUP_ITEMS)
              .map(r => r.value && onRenderFacetCheckbox(r, r.value.toString()))}

          {collapsedCount > 0 && (
            <div
              className={classNames.action}
              onClick={() => setShowAllFacetMap(map => ({ ...map, [facet.field]: true }))}
            >
              {t('query-tester.results-facets', {
                count: collapsedCount,
              })}
            </div>
          )}
        </Stack>
      );
    },
    [
      classNames.action,
      classNames.colorGroup,
      classNames.subtitle,
      onRenderColorFacetOption,
      onRenderFacetCheckbox,
      showAllFacetMap,
      t,
    ],
  );

  const onRenderFacetGroupContent = useCallback(
    (facet: Facet): JSX.Element | null => {
      const facetIndexField = getSearchIndexField(searchIndex.fields, facet.field.toLowerCase());
      if (!facetIndexField) {
        return null;
      }

      switch (facet._type) {
        case 'BoolFacet':
        case 'StringFacet':
        case 'CategoryFacet':
          switch (facetIndexField.type) {
            case 'Color':
              return onRenderColorFacetGroup(facet);
            case 'Category':
              return isProductionEnvironment() ? onRenderDefaultFacetGroup(facet) : onRenderCategoryFacetGroup(facet);
            default:
              return onRenderDefaultFacetGroup(facet);
          }
        case 'DateTimeRangeFacet': {
          return onRenderRangeFacetGroup(
            facet,
            f => `${moment(f.ge).format('YYYY-MM-DD HH:mm:ss')} - ${moment(f.lt).format('YYYY-MM-DD HH:mm:ss')}`,
          );
        }
        case 'RangeFacet':
          switch (facetIndexField.type) {
            case 'Price':
              return activeTenant === '008bad85-dc86-46b4-ae5d-da85c77f5d24' ||
                searchIndex.id === '5800ada6-115e-41a8-8d7c-ee42f7c2f550'
                ? onRenderRangeFacetGroup(facet, f => `£${f.ge} - £${f.lt}`)
                : onRenderRangeFacetGroup(facet, f => `$${f.ge} - $${f.lt}`);
            default:
              return onRenderRangeFacetGroup(facet, f => `${f.ge} - ${f.lt}`);
          }
      }

      return null;
    },
    [
      activeTenant,
      onRenderCategoryFacetGroup,
      onRenderColorFacetGroup,
      onRenderDefaultFacetGroup,
      onRenderRangeFacetGroup,
      searchIndex.fields,
      searchIndex.id,
    ],
  );

  const onRenderFacetGroup = useCallback(
    (facet: Facet) => {
      if (!facet.refinements.length) {
        return null;
      }
      const facetGroupContent = onRenderFacetGroupContent(facet);
      return !!facetGroupContent ? (
        <div key={facet.field}>
          <TooltipHost
            overflowMode={TooltipOverflowMode.Self}
            content={_.upperFirst(facet.field)}
            hostClassName={classNames.titleTooltip}
          >
            <div className={classNames.title}>{_.upperFirst(facet.field)}</div>
          </TooltipHost>
          {facetGroupContent}
          <Separator />
        </div>
      ) : null;
    },
    [classNames.title, classNames.titleTooltip, onRenderFacetGroupContent],
  );

  if (!facets || facets.length === 0) {
    return null;
  }

  const facetGroups = !!facets ? facets.map(onRenderFacetGroup) : [];
  const containsHandledFacetTypes = !!facetGroups && facetGroups.filter(f => !!f).length > 0;
  return !!containsHandledFacetTypes ? (
    <div ref={componentRef} className={classNames.root}>
      {facetGroups}
    </div>
  ) : null;
};
