import { BufferListProps } from './BufferList.types';

import React from 'react';
import { List, IPage } from 'office-ui-fabric-react';

export function BufferListBase<T>(props: BufferListProps<T>) {
  const { onBufferItems, items } = props;

  return (
    <List
      renderedWindowsAhead={1}
      {...props}
      onPageAdded={(page?: IPage<T>) => {
        if (!page || !page.items || !items) {
          return;
        }

        // Ensure that we buffer next set of items on the last page that can be added
        // This is because onPageAdded can be invoked multiple times per window
        page.startIndex + page.items.length >= items.length && onBufferItems();
      }}
    />
  );
}
