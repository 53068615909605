import { TokenEditorStyleProps, TokenEditorStyles } from './TokenEditor.types';

export const styles = (props: TokenEditorStyleProps): TokenEditorStyles => {
  return {
    root: ['mbc-token-editor', props.className],
    sectionSeparator: [
      'mbc-section-separator',
      {
        background: props.theme.palette.neutralLight,
        width: '100%',
        height: 1,
      },
    ],
    configurationSection: [
      'mbc-token-editor-configuration',

      {
        minHeight: 200,
      },
    ],
    errorMessage: [
      'mbc-token-error-message',
      {
        fontSize: 12,
        fontWeight: 400,
        color: props.theme.palette.redDark,
      },
    ],
    tokenCreationOverlay: [
      'mbc-token-creation-overlay',
      {
        zIndex: 100000,
        selectors: {
          '.ms-Spinner': {
            height: '100%',
          },
          '.ms-Spinner-circle': {
            borderColor:
              props.theme.palette.themePrimary +
              ' ' +
              props.theme.palette.neutralLight +
              ' ' +
              props.theme.palette.neutralLight,
          },
          '.ms-Spinner-label': {
            fontSize: 18,
            color: props.theme.palette.neutralLight,
          },
        },
      },
    ],
  };
};
