import React, { useCallback } from 'react';
import { classNamesFunction, Stack, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { PageFooterStyles, PageFooterStyleProps, PageFooterProps } from './PageFooter.types';
import { useTranslation } from 'react-i18next';

const getClassNames = classNamesFunction<PageFooterStyleProps, PageFooterStyles>();

export const PageFooterBase = (props: PageFooterProps) => {
  const {
    styles,
    theme,
    className,
    componentRef,
    saveButtonText,
    saveButtonDisabled,
    cancelButtonDisabled,
    onSave,
    onCancel,
    onRenderfooterActions,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const { t } = useTranslation();

  const getFooterActions = useCallback((): JSX.Element => {
    return onRenderfooterActions ? (
      onRenderfooterActions()
    ) : (
      <>
        {!!onSave && (
          <PrimaryButton text={saveButtonText || t('common.create')} disabled={saveButtonDisabled} onClick={onSave} />
        )}
        {!!onCancel && <DefaultButton text={t('common.cancel')} onClick={onCancel} disabled={cancelButtonDisabled} />}
      </>
    );
  }, [cancelButtonDisabled, onCancel, onRenderfooterActions, onSave, saveButtonDisabled, saveButtonText, t]);

  return (
    <div ref={componentRef}>
      <Stack verticalAlign="center" className={classNames.root}>
        <Stack horizontal tokens={{ childrenGap: '8px' }}>
          {getFooterActions()}
        </Stack>
      </Stack>
    </div>
  );
};
