import { ListItemStyleProps, ListItemStyles } from './ListItem.types';

export const styles = (props: ListItemStyleProps): ListItemStyles => ({
  root: ['mbc-tree-list-container', props.className],
  listItemIcon: ['mbc-tree-list-item-icon', { height: 21, width: 21, marginLeft: '4px !important' }],
  listItemContainer: ['mbc-list-item-container', { paddingLeft: 14 }],
  listItemHeaderContainer: ['mbc-list-item-header-container', { marginTop: 12 }],
  listItemHeader: [
    'mbc-item-header',
    {
      paddingLeft: 8,
      fontSize: 14,
      fontWeight: 500,
      color: props.theme.palette.themePrimary,
    },
  ],
  listItemSubheader: [
    'mbc-list-item-subheader',
    {
      fontSize: 12,
      color: 'gray',
      paddingLeft: 24,
    },
  ],
  listItemSubheaderLastItem: [
    'mbc-list-item-subheader-last-item',
    {
      fontSize: 12,
      color: 'gray',
      paddingLeft: 42,
    },
  ],
  horizontalSeparator: [
    'mbc-horizontal-separator',
    {
      marginLeft: -1,
      maxWidth: 16,
      selectors: {
        ':before': {
          backgroundColor: '#D9D9D9',
        },
      },
    },
  ],
  verticalSeparatorLastItem: [
    'mbc-vertical-separator-last-item',
    {
      paddingRight: 0,
      minHeight: 'inherit',
      marginBottom: 7,
      selectors: {
        ':after': {
          backgroundColor: '#D9D9D9',
        },
      },
    },
  ],
  horizontalSeparatorLastItem: [
    'mbc-horizontal-separator-last-item',
    {
      marginLeft: '-1px !important',
      maxWidth: 16,
      paddingTop: 0,
      paddingBottom: 0,
      height: 0,
      marginBottom: 8,
      selectors: {
        ':before': {
          backgroundColor: '#D9D9D9',
        },
      },
    },
  ],
});
