import { EvaluateSearchModelStyles, EvaluateSearchModelStyleProps } from './EvaluateSearchModel.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const styles = (props: EvaluateSearchModelStyleProps): EvaluateSearchModelStyles => ({
  status: [
    'mbc-status',
    {
      display: 'grid',
      alignItems: 'center',
      gridColumnGap: '8px',
      gridTemplateColumns: 'auto 8px',
    },
  ],
  columnEndAlignHeader: ['mbc-end-header'],
  overflow: [
    'mbc-overflow-cell',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  subComponentStyles: {
    list: {
      root: [
        'mbc-header',
        {
          selectors: {
            '.ms-DetailsHeader-cell.mbc-end-header span': {
              justifyContent: 'end',
            },
          },
        },
      ],
    },
  },
});
