import { NdcgMetric } from './TrainSearchModel.types';

export const NdcgTrainMetric: NdcgMetric = {
  NDCGAt1: {
    key: 'NDCGMetric-ndcg@1_training_Node0',
    label: 'NDCG@1',
    color: '#5C2E91',
    legend: '@1',
  },
  NDCGAt3: {
    key: 'NDCGMetric-ndcg@3_training_Node0',
    label: 'NDCG@3',
    color: '#FFAA44',
    legend: '@3',
  },
  NDCGAt5: {
    key: 'NDCGMetric-ndcg@5_training_Node0',
    label: 'NDCG@5',
    color: '#0078D4',
    legend: '@5',
  },
};

export const NdcgTestMetric: NdcgMetric = {
  NDCGAt1: {
    key: 'NDCGMetric-ndcg@1_valid_1_Node0',
    label: 'NDCG@1',
    color: '#5C2E91',
    legend: '@1',
  },
  NDCGAt3: {
    key: 'NDCGMetric-ndcg@3_valid_1_Node0',
    label: 'NDCG@3',
    color: '#FFAA44',
    legend: '@3',
  },
  NDCGAt5: {
    key: 'NDCGMetric-ndcg@5_valid_1_Node0',
    label: 'NDCG@5',
    color: '#0078D4',
    legend: '@5',
  },
};
