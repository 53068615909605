import React, { useState, useEffect } from 'react';
import { AssignRolesProps } from './AssignRoles.types';
import { PanelType } from 'office-ui-fabric-react';
import { CustomPanel } from '../../common/CustomPanel';
import { RoleList } from '../../RoleList';
import { Role } from '../../../store/types/roleList.d';
import _ from 'lodash';

export const AssignRolesBase = (props: AssignRolesProps): JSX.Element => {
  const { isOpen, onDismiss, onSaveClick, onCancelClick, t, items } = props;

  const [selectedRoles, setSelectedRoles] = useState<Role[]>(items);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    setSelectedRoles(items);
  }, [items]);

  return (
    <CustomPanel
      isOpen={isOpen}
      headerText={t('roles-list.environment-editor-panel.header')}
      onDismiss={onDismiss}
      footer={{
        onSaveClick: () => onSaveClick(selectedRoles),
        onCancelClick: () => onCancelClick(),
        isSaveDisabled: saveDisabled,
      }}
      panelType={PanelType.medium}
      isHiddenOnDismiss={false}
    >
      <RoleList
        isPanel={true}
        onSelectionChanged={(selection: Role[]) => {
          setSelectedRoles(selection);
          setSaveDisabled(_.isEqual(items, selection));
        }}
        selectedItems={items.map(item => ({ ...item, key: item.id, canSelect: !item.hasAccessToAllEnvironments }))}
      />
    </CustomPanel>
  );
};
