import { AutosuggestStyleProps, AutosuggestStyles } from './AutoSuggest.types';

export const styles = (props: AutosuggestStyleProps): AutosuggestStyles => {
  const { className } = props;
  return {
    root: ['mbc-autosuggest', { width: 300 }, className],
    suggestion: ['mbc-text-suggestion', { fontWeight: 600 }],
    calloutItem: [
      'mbc-callout-item',
      {
        display: 'flex',
        cursor: 'pointer',
        padding: '12px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        selectors: {
          ':hover': {
            backgroundColor: props.theme.palette.neutralLight,
          },
        },
      },
    ],
  };
};
