import { BaseRequestInterceptor } from './BaseRequestInterceptor';
import { FetchRequest } from '../Types';
import { AddHeader } from '../Utils';
import { AdminTokensService } from '../../../../services/tokens.service';

export class CustomMLServiceInterceptor extends BaseRequestInterceptor {
  constructor() {
    super(/^https:\/\/commerce.bing.com\/api\/ml\/.*$/);
  }

  PerformAction(request: FetchRequest): Promise<FetchRequest> {
    if (request.isPublic) {
      return Promise.resolve(request);
    }
    return AdminTokensService.getToken(request.authInfo).then(adminToken => {
      return AddHeader(request, 'Authorization', `Bearer ${adminToken}`);
    });
  }
}
