/* eslint-disable @typescript-eslint/no-use-before-define */
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';

import { Action } from 'redux';

import {
  GET_SEARCH_INSTANCE_LIST,
  SET_ACTIVE_SEARCH_INSTANCE,
  RESET_ACTIVE_SEARCH_INSTANCE,
  LOADING_SEARCH_INSTANCE_LIST,
  RESET_SEARCH_INSTANCE_LIST,
  SearchInstance,
  DefaultSearchInstanceId,
} from '../../types/customizations/searchInstanceList.d';
import { getSearchInstances } from '../../../restful-apis/customizations/searchInstance.api';
import { getBusinessRuleList } from './businessRuleActions';
import { getSynonymSetList } from './synonymSetActions';
import { getUrlRedirectSetList } from './urlRedirectSetActions';
import { ActionSuccessHandler, ActionFailureHandler } from '../utils/CrudAction.types';
import { refreshActiveIndexDevops } from '../devopsActions';
import { ExecuteReduxCrudAction } from '../utils/CrudAction';
import {
  searchInstanceCreateAction,
  searchInstanceCloneAction,
  searchInstanceDeleteAction,
} from '../utils/crud-actions/search-instance/SearchInstanceCrudActions';

export const retrieveSearchInstances = (
  tenantId: string,
  indexId: string,
): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_SEARCH_INSTANCE_LIST,
    });
    getSearchInstances(tenantId, indexId).then(searchInstances => {
      if (searchInstances.length === 0) {
        dispatch(createSearchInstance({ id: DefaultSearchInstanceId.toLowerCase(), name: DefaultSearchInstanceId }));
      } else {
        dispatch({
          type: GET_SEARCH_INSTANCE_LIST,
          searchInstanceList: searchInstances,
        });
      }
    });
  };
};

export function resetSearchInstances() {
  return {
    type: RESET_SEARCH_INSTANCE_LIST,
  };
}

export const setActiveSearchInstance = (searchInstanceId: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const searchInstance = getState().searchInstanceList.searchInstanceIdMapper[searchInstanceId];
    if (!!searchInstance.id) {
      dispatch(getBusinessRuleList(searchInstanceId));
      dispatch(getSynonymSetList(searchInstanceId));
      dispatch(getUrlRedirectSetList(searchInstanceId));
      dispatch({
        type: SET_ACTIVE_SEARCH_INSTANCE,
        id: searchInstanceId,
      });
    }
  };
};

export const resetActiveSearchInstance = (): ThunkAction<void, AppState, null, Action> => dispatch =>
  dispatch({
    type: RESET_ACTIVE_SEARCH_INSTANCE,
  });

export const createSearchInstance = (
  searchInstance: SearchInstance,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<SearchInstance>,
  onFailure?: ActionFailureHandler<SearchInstance>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.environmentList.activeEnvironment.indexId;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Create ${searchInstance.id} search configuration` }
      : undefined;

    const onSuccessHandler = (createdSearchInstance: SearchInstance) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(createdSearchInstance);
    };

    dispatch(
      ExecuteReduxCrudAction(
        searchInstanceCreateAction(
          tenantId,
          searchIndexId,
          searchInstance,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const cloneSearchInstance = (
  srcSearchInstanceId: string,
  dstSearchInstanceId: string,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<SearchInstance>,
  onFailure?: ActionFailureHandler<SearchInstance>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? {
          author: state.user.email,
          message:
            commitMessage || `create ${dstSearchInstanceId} search configuration as clone from  ${srcSearchInstanceId}`,
        }
      : undefined;

    const onSuccessHandler = (clonedSearchInstance: SearchInstance) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(clonedSearchInstance);
    };

    dispatch(
      ExecuteReduxCrudAction(
        searchInstanceCloneAction(
          tenantId,
          searchIndexId,
          srcSearchInstanceId,
          dstSearchInstanceId,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};

export const deleteSearchInstance = (
  searchInstanceId: string,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<SearchInstance>,
  onFailure?: ActionFailureHandler<SearchInstance>,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const searchIndexId = state.searchIndex.searchIndex.id;
    const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;

    const devOpsCommitMetadata = isDevOpsEnabled
      ? { author: state.user.email, message: commitMessage || `Delete ${searchInstanceId} search configuration` }
      : undefined;

    const onSuccessHandler = (deletedSearchInstance: SearchInstance) => {
      isDevOpsEnabled && dispatch(refreshActiveIndexDevops());
      onSuccess && onSuccess(deletedSearchInstance);
    };

    dispatch(
      ExecuteReduxCrudAction(
        searchInstanceDeleteAction(
          tenantId,
          searchIndexId,
          searchInstanceId,
          devOpsCommitMetadata,
          onSuccessHandler,
          onFailure,
        ),
      ),
    );
  };
};
