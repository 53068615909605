import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { Action } from 'redux';
import { Dataset, LOADING_DATASET_LIST, SET_DATASET_LIST, RESET_DATASET_LIST } from '../../types/customML/dataset.d';
import { listRawDatasets } from '../../../restful-apis/customML.api';
import { ActionSuccessHandler, ActionFailureHandler, ExecuteReduxCrudAction } from '../utils';
import { createDatasetJobAction } from '../utils/crud-actions/dataset/DatasetCrudActions';

export const createRawDataset = (
  tenantId: string,
  dataset: Dataset,
  file: File,
  onSuccess?: ActionSuccessHandler<Dataset>,
  onFailure?: ActionFailureHandler<Dataset>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(createDatasetJobAction(tenantId, dataset, file, onSuccess, onFailure));

export const resetCustomMLDatasetList = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: RESET_DATASET_LIST,
    });
  };
};

export const setCustomMLDatasetList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_DATASET_LIST,
    });

    listRawDatasets(tenantId)
      .then(datasetList => {
        dispatch({
          type: SET_DATASET_LIST,
          datasetList: datasetList,
        });
      })
      .catch(() => {});
  };
};
