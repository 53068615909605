import { getI18n } from 'react-i18next';
import { Dataset, ADD_DATASET } from '../../../../types/customML/dataset.d';
import { createRawDataset } from '../../../../../restful-apis/customML.api';
import { ActionSuccessHandler, ReduxCrudAction, ActionFailureHandler } from '../../CrudAction.types';

export const createDatasetJobAction = (
  tenantId: string,
  dataset: Dataset,
  file: File,
  onSuccess?: ActionSuccessHandler<Dataset>,
  onFailure?: ActionFailureHandler<Dataset>,
): ReduxCrudAction<Dataset> => {
  const i18n = getI18n();
  return {
    request: createRawDataset(tenantId, file, dataset),
    postActionMapper: createdDataset => ({
      type: ADD_DATASET,
      dataset: { ...createdDataset, isNew: true },
    }),
    notification: {
      failure: () => ({
        title: i18n.t('custom-ml.dataset-list.upload-req-failure', { name: dataset.name }),
      }),
      inProgress: {
        title: i18n.t('custom-ml.dataset-list.upload-req-inprogress', { name: dataset.name }),
      },
      success: () => ({
        title: i18n.t('custom-ml.dataset-list.upload-req-success', { name: dataset.name }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, dataset),
  };
};
