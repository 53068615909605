import { ActionSuccessHandler, ActionFailureHandler, ReduxCrudAction } from '../../CrudAction.types';
import { searchIndexProducts } from '../../../../../restful-apis/search.api';
import {
  ProductSearchResult,
  SET_PRODUCT_SEARCH_RESULTS,
  LOADING_PRODUCT_SEARCH_RESULTS,
  SET_PRODUCT_SEARCH_NON_EDITABLE,
  SET_PRODUCT_SEARCH_EDITABLE,
  UPDATE_PRODUCT_SEARCH_RESULTS,
} from '../../../../types/productSearch.d';
import { SearchIndex } from '../../../../types/searchIndex.d';
import { deleteDocuments } from '../../../../../restful-apis/ingestion.api';
import { DocumentDeleteResponse } from '../../../../../restful-apis/dto/ingestion/document';
import { getI18n } from 'react-i18next';

export const updateIndexProductsAction = (
  tenantId: string,
  searchIndex: SearchIndex,
  query: string,
  skip: number,
  top: number,
  onSuccess?: ActionSuccessHandler<ProductSearchResult>,
  onFailure?: ActionFailureHandler<string>,
): ReduxCrudAction<ProductSearchResult> => ({
  request: searchIndexProducts(tenantId, searchIndex, query, skip, top),
  postActionMapper: productSearchResult => ({
    type: UPDATE_PRODUCT_SEARCH_RESULTS,
    productSearchResult: productSearchResult,
  }),
  onSuccess: onSuccess,
  onFailure: response => onFailure && onFailure(response, query),
});

export const searchIndexProductsAction = (
  tenantId: string,
  searchIndex: SearchIndex,
  query: string,
  skip: number,
  top: number,
  onSuccess?: ActionSuccessHandler<ProductSearchResult>,
  onFailure?: ActionFailureHandler<string>,
): ReduxCrudAction<ProductSearchResult> => ({
  request: searchIndexProducts(tenantId, searchIndex, query, skip, top),
  preActionMapper: {
    type: LOADING_PRODUCT_SEARCH_RESULTS,
  },
  postActionMapper: productSearchResult => ({
    type: SET_PRODUCT_SEARCH_RESULTS,
    productSearchResult: productSearchResult,
  }),
  onSuccess: onSuccess,
  onFailure: response => onFailure && onFailure(response, query),
});

export const deleteSearchIndexProductAction = (
  productId: string,
  tenantId: string,
  searchIndexId: string,
  onSuccess?: ActionSuccessHandler<DocumentDeleteResponse>,
  onFailure?: ActionFailureHandler<string>,
): ReduxCrudAction<DocumentDeleteResponse> => {
  const i18n = getI18n();
  return {
    request: deleteDocuments(tenantId, searchIndexId, [productId]),
    preActionMapper: {
      type: SET_PRODUCT_SEARCH_NON_EDITABLE,
      productId: productId,
    },
    postActionMapper: () => ({
      type: SET_PRODUCT_SEARCH_EDITABLE,
      productId: productId,
    }),
    notification: {
      failure: () => ({
        title: i18n.t('product-management.delete-req-failure', { name: productId }),
      }),
      inProgress: {
        title: i18n.t('product-management.delete-req-inprogress', { name: productId }),
      },
      success: () => ({
        title: i18n.t('product-management.delete-req-success', { name: productId }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: (response, dispatch) => {
      dispatch({
        type: SET_PRODUCT_SEARCH_EDITABLE,
        productId: productId,
      });
      onFailure && onFailure(response, productId);
    },
  };
};
