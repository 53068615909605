import { useDispatch } from '..';
import { addBreadcrumbItem, removeBreadcrumbItem, updateBreadcrumbItem } from '../../actions/breadcrumbsActions';
import { useState, useEffect } from 'react';

// Use Lazy Breadcrumb hook when the breadcrumbText value evaluated from store such as activeTenant, activeEnvironment and activeRole
export const useLazyBreadcrumb = (
  breadcrumbKey: string,
  breadcrumbUrl: string,
  breadcrumbText: string,
  waitForCondition: boolean,
): void => {
  const dispatch = useDispatch();
  const [prevBreadcrumbUrl, setPrevBreadcrumbUrl] = useState('');

  if (prevBreadcrumbUrl !== breadcrumbUrl) {
    dispatch(
      addBreadcrumbItem({
        href: breadcrumbUrl,
        key: breadcrumbKey,
        text: undefined,
      }),
    );
    setPrevBreadcrumbUrl(breadcrumbUrl);
  }

  useEffect(() => {
    breadcrumbText &&
      dispatch(
        updateBreadcrumbItem({
          href: breadcrumbUrl,
          key: breadcrumbKey,
          text: breadcrumbText,
        }),
      );
  }, [waitForCondition, breadcrumbKey, breadcrumbText, breadcrumbUrl, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbItem(breadcrumbKey));
    };
  }, [dispatch, breadcrumbUrl, breadcrumbKey]);
};
