import * as React from 'react';
import { MbcError } from '../MbcError';
import { ErrorBoundaryProps, ErrorBoundaryState, ErrorType } from './ErrorBoundary.types';

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { errorType: null };
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { errorType: error || ErrorType.Unknown };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.log(`Error in ${this.props.component}: ${error}`, info && info.componentStack);
  }

  render() {
    const { onRenderError, children } = this.props;
    if (this.state.errorType !== null && !!onRenderError) {
      return onRenderError;
    }

    if (this.state.errorType !== null) {
      return <MbcError errorType={this.state.errorType} />;
    }

    return children;
  }
}
