import { Action } from 'redux';
import { BoostCondition, Condition, ConditionBlock } from '../../../utils/customizations/conditions';
import { Moment } from 'moment';

export const SET_BUSINESS_RULE_LIST = 'SET_BUSINESS_RULE_LIST';
export const RESET_BUSINESS_RULE_LIST = 'RESET_BUSINESS_RULE_LIST';
export const SET_ACTIVE_BUSINESS_RULE = 'SET_ACTIVE_BUSINESS_RULE';
export const RESET_ACTIVE_BUSINESS_RULE = 'RESET_ACTIVE_BUSINESS_RULE';
export const POPULATE_ACTIVE_BUSINESS_RULE = 'POPULATE_ACTIVE_BUSINESS_RULE';
export const SET_BUSINESS_RULE_DETAILS = 'SET_BUSINESS_RULE_DETAILS';
export const LOADING_BUSINESS_RULE_LIST = 'LOADING_BUSINESS_RULE_LIST';
export const LOADING_BUSINESS_RULE_DETAILS = 'LOADING_BUSINESS_RULE_DETAILS';
export const CREATE_OR_UPDATE_BUSINESS_RULE = 'CREATE_OR_UPDATE_BUSINESS_RULE';
export const DELETE_BUSINESS_RULE = 'DELETE_BUSINESS_RULE';

export type BannerType = 'PlainText' | 'URL' | 'HTML';
export const BannerType = { PlainText: 'Plain text', URL: 'URL', HTML: 'HTML' };

export const excludedFieldTypes: string[] = ['DateTime', 'DateSegment'];
export const excludedConditionTypes: string[] = [Condition.DateTimeCondition];

export interface Banner {
  content: string;
  contentType: BannerType;
}

// BR id is used only for internal mapping, not exposed to backend, used for url mapping
// BR id is a lowercased version of BR name
// BR name is used for backend communication (as a rule id) and portal (as a rule display name)
// This is a workaround as there is no generated id for BR, but its name is used as an Id
interface BusinessRule {
  id: string;
  name: string;
  description?: string;
  enabled: boolean;
  promoteCondition?: BoostCondition;
  demoteCondition?: BoostCondition;
  filterCondition?: ConditionBlock;
  banner?: Banner;
  startDate?: Moment;
  endDate?: Moment;
  lastModifiedTime: Moment;
  lastModifiedBy: string;
  searchRequestCondition?: ConditionBlock;
}

export interface BusinessRuleIdMapper {
  [key: string]: BusinessRule;
}

export interface BusinessRuleState {
  isLoading: boolean;
  isRuleDetailsLoading: boolean;
  activeBusinessRule: BusinessRule;
  businessRuleIdMapper: BusinessRuleIdMapper;
}

interface SetBusinessRuleList extends Action<typeof SET_BUSINESS_RULE_LIST> {
  businessRuleList: BusinessRule[];
}

interface SetActiveBusinessRule extends Action<typeof SET_ACTIVE_BUSINESS_RULE> {
  id: string;
}

interface PopulateActiveBusinessRule extends Action<typeof POPULATE_ACTIVE_BUSINESS_RULE> {
  businessRule: BusinessRule;
}

interface CreateOrUpdateBusinessRule extends Action<typeof CREATE_OR_UPDATE_BUSINESS_RULE> {
  businessRule: BusinessRule;
}

interface SetBusinessRuleDetails extends Action<typeof SET_BUSINESS_RULE_DETAILS> {
  businessRule: BusinessRule;
}

interface DeleteBusinessRule extends Action<typeof DELETE_BUSINESS_RULE> {
  id: string;
}

export type BusinessRuleActionTypes =
  | SetBusinessRuleList
  | SetActiveBusinessRule
  | SetBusinessRuleDetails
  | PopulateActiveBusinessRule
  | CreateOrUpdateBusinessRule
  | DeleteBusinessRule
  | Action<typeof RESET_BUSINESS_RULE_LIST>
  | Action<typeof RESET_ACTIVE_BUSINESS_RULE>
  | Action<typeof LOADING_BUSINESS_RULE_LIST>
  | Action<typeof LOADING_BUSINESS_RULE_DETAILS>;
