import React from 'react';
import { Modal, Stack, Text, classNamesFunction } from 'office-ui-fabric-react';
import {
  TrialEnvironmentModalStyleProps,
  TrialEnvironmentModalStyles,
  TrialEnvironmentModalBaseProps,
} from './TrialEnvironmentModal.types';
import { useSelector } from '../../store/hooks';
import { useTenantList } from '../../store/hooks/use-list/useTenantList';

const getClassNames = classNamesFunction<TrialEnvironmentModalStyleProps, TrialEnvironmentModalStyles>();

export const TrialEnvironmentModalBase = (props: TrialEnvironmentModalBaseProps) => {
  const { t, styles, theme } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const tenantList = useTenantList();

  const isTenantListLoading = useSelector(state => state.tenantsList.isLoading);

  return (
    <Modal
      isOpen={!isTenantListLoading && !tenantList.length}
      layerProps={{ eventBubblingEnabled: true }}
      isBlocking={true}
    >
      <Stack
        className={classNames.root}
        tokens={{ childrenGap: '25px' }}
        horizontalAlign="center"
        verticalAlign="space-around"
      >
        <Text className={classNames.title}>{t('trial-env-modal.title')}</Text>

        <Text className={classNames.paragraph}>
          {t('trial-env-modal.invite-msg')}
          <a href="mailto:DemoRequestMBC@microsoft.com" rel="noopener noreferrer" target="_blank">
            DemoRequestMBC@microsoft.com
          </a>
        </Text>
      </Stack>
    </Modal>
  );
};
