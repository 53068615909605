import React from 'react';
import { classNamesFunction, Icon } from 'office-ui-fabric-react';
import { ToolTipFormatterProps, ToolTipFormatterStyleProps, ToolTipFormatterStyles } from './ToolTipFormatter.types';
import _ from 'lodash';

const getClassNames = classNamesFunction<ToolTipFormatterStyleProps, ToolTipFormatterStyles>();

export const ToolTipFormatterBase = (props: ToolTipFormatterProps) => {
  const { shouldReverseOrder, toolTipProps, className, theme, styles, componentRef } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  let startRange = 0;
  let endRange = 0;
  let step = 0;
  if (toolTipProps.payload) {
    startRange = shouldReverseOrder ? toolTipProps.payload.length - 1 : 0;
    endRange = shouldReverseOrder ? -1 : toolTipProps.payload.length;
    step = shouldReverseOrder ? -1 : 1;
  }

  let dateTime = !!toolTipProps && !!toolTipProps.label ? (toolTipProps.label as string).split(' ') : [];
  if (dateTime.length > 1) {
    if (dateTime[1].length === 5) {
      dateTime[1] = dateTime[1] + ':00';
    } else if (dateTime[1].length === 2) {
      dateTime[1] = dateTime[1] + ':00:00';
    }
  }

  return (
    <div ref={componentRef} className={classNames.root}>
      {toolTipProps.active && toolTipProps.payload && (
        <>
          <div className={classNames.label}>{dateTime.join(' ')}</div>
          {_.range(startRange, endRange, step).map(index => (
            <div className={classNames.contentItemContainer} key={index}>
              <Icon
                iconName={'RadioBullet'}
                className={classNames.toolTipIcon}
                styles={{ root: { color: toolTipProps.payload![index].color } }}
              />
              {`${toolTipProps.payload![index].name} : ${toolTipProps.payload![index].payload.yValues[index]}`}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
