import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import styles from './Footer.module.scss';
import { siteConsent } from '../../../../services/consent.service';

class Footer extends Component<WithTranslation> {
  manageCookies = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault();
    siteConsent && siteConsent.manageConsent();
  };
  render() {
    const { t } = this.props;
    return (
      <footer>
        <div className={styles.footerContainer}>
          <ul className={styles.footerInlineList}>
            <li>
              <a
                id="footer-privacy-link"
                href="https://go.microsoft.com/fwlink/?LinkId=521839"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('homepage.footer.privacy-cookies')}
              </a>
            </li>
            <li>
              <a
                id="footer-legal"
                href="https://go.microsoft.com/fwlink/?LinkID=246338"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('homepage.footer.legal')}
              </a>
            </li>
            <li>
              <a href="mailto:contactMBC@microsoft.com" rel="noopener noreferrer" target="_blank">
                {t('homepage.footer.contact-team')}
              </a>
            </li>
            {siteConsent && siteConsent.isConsentRequired && (
              <li>
                <a href="#" rel="noopener noreferrer" onClick={ev => this.manageCookies(ev)}>
                  {t('homepage.footer.manage-cookies')}
                </a>
              </li>
            )}
          </ul>
          <div className={styles.footerLogoDiv}>
            <span className={styles.microsoftLogo}>©&nbsp;2020&nbsp;Microsoft </span>
          </div>
        </div>
      </footer>
    );
  }
}
export default withTranslation()(Footer);
