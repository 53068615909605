import { useMemo } from 'react';
import { useSelector } from '..';

export const useDefaultSearchInstance = () => {
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const searchInstanceIdMapper = useSelector(state => state.searchInstanceList.searchInstanceIdMapper);

  return useMemo(
    () =>
      activeSearchInstance.id
        ? activeSearchInstance.id
        : Object.keys(searchInstanceIdMapper).includes('default')
        ? 'default'
        : Object.keys(searchInstanceIdMapper)[0] || '',
    [activeSearchInstance.id, searchInstanceIdMapper],
  );
};
