import React, { useState, useLayoutEffect, useRef } from 'react';
import { classNamesFunction, ComboBox } from 'office-ui-fabric-react';
import { MbcCompoBoxStyles, MbcCompoBoxStyleProps, MbcCompoBoxProps } from './MbcCompoBox.types';

const getClassNames = classNamesFunction<MbcCompoBoxStyleProps, MbcCompoBoxStyles>();

export const MbcCompoBoxBase = (props: MbcCompoBoxProps) => {
  const { styles, theme, className, compoBoxProps, maxHeight } = props;
  const classNames = getClassNames(styles, { theme: theme!, className: className });
  const comboBoxRef = useRef<HTMLDivElement>(null);

  const [compWidh, setCompWidh] = useState<number | undefined>(undefined);
  useLayoutEffect(() => {
    setCompWidh(comboBoxRef.current ? comboBoxRef.current.clientWidth : undefined);
  }, [comboBoxRef]);

  return (
    <div ref={comboBoxRef} className={classNames.root}>
      <ComboBox
        styles={{ callout: { width: compWidh }, optionsContainer: { maxHeight: maxHeight || 200 } }}
        {...compoBoxProps}
      />
    </div>
  );
};
