import { UserPersonaStyleProps, UserPersonaStyles } from './UserPersona.types';

export const styles = (props: UserPersonaStyleProps): UserPersonaStyles => {
  return {
    root: ['mbc-user-persona', props.className],
    personaDetails: [
      'mbc-persona-details',
      {
        margin: 10,
      },
    ],
  };
};
