import { useSelector } from '..';
import { useMemo } from 'react';
import { Environment } from '../../types/environmentList.d';

export const useEnvironmentListWithoutDeletingStatus = (): Environment[] => {
  const environmentIdMapper = useSelector(state => state.environmentList.environmentIdMapper);
  return useMemo(() => Object.values(environmentIdMapper).filter(env => !env.isIndexSoftDeleted), [
    environmentIdMapper,
  ]);
};
