import { configureDefaultObject, getLocaleTimeNow } from '../../../utils';
import {
  TrainingJob,
  BasicParameters,
  TrainingJobMapper,
  TrainingJobState,
  TrainingJobStateActionTypes,
  RESET_TRAINING_JOB_LIST,
  SET_TRAINING_JOB_LIST,
  ADD_TRAINING_JOB,
  LOADING_TRAINING_JOB_LIST,
  SET_TRAINING_JOB_METRICS,
  LOADING_TRAINING_JOB_METRICS,
  TrainingMetricsMapper,
  TrainingMetric,
  UPDATE_TRAINING_JOB,
  UPDATE_TRAINING_JOB_LIST,
} from '../../types/customML/trainingJob.d';
import { ModelJobStatus } from '../../types/customML/searchModel.d';

export const getInitialModelParameters = () => ({
  [BasicParameters.NumTrees]: '100',
  [BasicParameters.NumLeaves]: '31',
  [BasicParameters.MinDataInLeaf]: '20',
  [BasicParameters.LearningRate]: '0.3',
  [BasicParameters.LabelGain]: '0,1,3,7,15',
  [BasicParameters.FeatureSelected]: '',
  [BasicParameters.Parameters]: '',
});

export const createInitialTrainingMetric = (): TrainingMetric => ({
  key: '',
  xLabel: '',
  yLabel: '',
  points: [],
});

export const createTrainingMetricsMapper = (initialMapper: TrainingMetricsMapper = {}) =>
  configureDefaultObject<TrainingMetricsMapper, TrainingMetric>(initialMapper, createInitialTrainingMetric());

export const createInitialTrainingJob = (): TrainingJob => ({
  key: '',
  name: '',
  email: '',
  description: '',
  datasetKey: '',
  testDatasetKey: '',
  rankerModelKey: '',
  isLoadingMetrics: false,
  createdAt: getLocaleTimeNow(),
  updatedAt: getLocaleTimeNow(),
  status: ModelJobStatus.NotStarted,
  parameters: getInitialModelParameters(),
  metricsMapper: createTrainingMetricsMapper(),
});

const createTrainingJobMapper = (initialMapper: TrainingJobMapper = {}) =>
  configureDefaultObject<TrainingJobMapper, TrainingJob>(initialMapper, createInitialTrainingJob());

const initialState: TrainingJobState = {
  isLoading: true,
  trainingJobMapper: createTrainingJobMapper(),
};

export function trainingJobReducer(state = initialState, action: TrainingJobStateActionTypes): TrainingJobState {
  switch (action.type) {
    case LOADING_TRAINING_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_TRAINING_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
        trainingJobMapper: createTrainingJobMapper(),
      };
    }
    case SET_TRAINING_JOB_LIST: {
      return {
        ...state,
        isLoading: false,
        trainingJobMapper: createTrainingJobMapper({
          ...action.trainingJobList.reduce((mapper: TrainingJobMapper, trainingJob: TrainingJob) => {
            mapper[trainingJob.key] = trainingJob;
            return mapper;
          }, {}),
        }),
      };
    }
    case LOADING_TRAINING_JOB_METRICS: {
      const updatedTrainginJob = {
        ...state.trainingJobMapper[action.key],
        isLoadingMetrics: true,
      };
      return {
        ...state,
        trainingJobMapper: createTrainingJobMapper({
          ...state.trainingJobMapper,
          [action.key]: updatedTrainginJob,
        }),
      };
    }
    case SET_TRAINING_JOB_METRICS: {
      const updatedTrainingJob = {
        ...state.trainingJobMapper[action.key],
        isLoadingMetrics: false,
        metricsMapper: createTrainingMetricsMapper({
          ...action.metrics.reduce((mapper: TrainingMetricsMapper, trainingMetric: TrainingMetric) => {
            mapper[trainingMetric.key] = trainingMetric;
            return mapper;
          }, {}),
        }),
      };
      return {
        ...state,
        trainingJobMapper: createTrainingJobMapper({
          ...state.trainingJobMapper,
          [action.key]: updatedTrainingJob,
        }),
      };
    }
    case ADD_TRAINING_JOB: {
      return {
        ...state,
        trainingJobMapper: createTrainingJobMapper({
          [action.trainingJob.key]: action.trainingJob,
          ...state.trainingJobMapper,
        }),
      };
    }
    case UPDATE_TRAINING_JOB: {
      if (state.trainingJobMapper[action.trainingJob.key].key) {
        return {
          ...state,
          trainingJobMapper: createTrainingJobMapper({
            ...state.trainingJobMapper,
            [action.trainingJob.key]: action.trainingJob,
          }),
        };
      }

      return state;
    }
    case UPDATE_TRAINING_JOB_LIST: {
      return {
        ...state,
        trainingJobMapper: createTrainingJobMapper({
          ...state.trainingJobMapper,
          ...action.trainingJobList.reduce((mapper: TrainingJobMapper, trainingJob: TrainingJob) => {
            const updatedTrainingJob = {
              ...state.trainingJobMapper[trainingJob.key],
              status: trainingJob.status,
            };
            if (!!updatedTrainingJob.key) {
              mapper[trainingJob.key] = updatedTrainingJob;
            }
            return mapper;
          }, {}),
        }),
      };
    }

    default:
      return state;
  }
}
