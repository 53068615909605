import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import { BannersStyleProps, BannersStyles, BannersProps } from './Banners.types';

const getClassNames = classNamesFunction<BannersStyleProps, BannersStyles>();

export const BannersBase = (props: BannersProps) => {
  const { styles, theme, banners } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const { t } = useTranslation();

  return (
    <>
      {!!banners &&
        banners.map(
          (banner, index) =>
            !!banner.content && (
              <Stack key={index} className={classNames.root} tokens={{ childrenGap: 8 }}>
                <Stack horizontal horizontalAlign={'start'} verticalAlign={'center'} style={{ minHeight: '32px' }}>
                  <div className={classNames.header}>{t('query-tester.banners', { type: banner.contentType })}</div>
                </Stack>
                <Stack
                  grow
                  horizontal
                  verticalAlign={'center'}
                  horizontalAlign={'center'}
                  className={classNames.content}
                >
                  {banner.content}
                </Stack>
              </Stack>
            ),
        )}
    </>
  );
};
