import { SHOW_OOBE_DEMO, HIDE_OOBE_DEMO, OobeDemoStateActionTypes } from '../types/oobeDemo.d';

export function showOobeDemo(): OobeDemoStateActionTypes {
  return {
    type: SHOW_OOBE_DEMO,
  };
}

export function hideOobeDemo(): OobeDemoStateActionTypes {
  return {
    type: HIDE_OOBE_DEMO,
  };
}
