import { useMemo } from 'react';
import { useSelector } from '../../../../store/hooks';
import { useDefaultSearchInstance } from '../../../../store/hooks/use-search-instance/useDefaultSearchInstance';
import { createDefaultPostBody } from '../../../../restful-apis/queryTester.api';

export const useDefaultPostBody = (query: string = '') => {
  const defaultSearchInstance = useDefaultSearchInstance();
  const activeSearchIndex = useSelector(state => state.searchIndex.searchIndex);
  const isOobe = useSelector(state => state.tenantsList.activeTenant.isOobe);

  return useMemo(() => {
    return createDefaultPostBody(defaultSearchInstance, activeSearchIndex, query, undefined, undefined, isOobe);
  }, [activeSearchIndex, defaultSearchInstance, isOobe, query]);
};
