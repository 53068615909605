import React from 'react';
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Legend } from 'recharts';
import _ from 'lodash';
import { classNamesFunction } from 'office-ui-fabric-react';
import { BarChartProps, BarChartStyleProps, BarChartStyles, LayoutType } from './BarChart.types';
import { AxisTickFormatter, TickType } from '../common/AxisTickFormatter';

const getClassNames = classNamesFunction<BarChartStyleProps, BarChartStyles>();

export const BarChartBase: React.FunctionComponent<BarChartProps> = (props: BarChartProps) => {
  const {
    styles,
    theme,
    className,
    componentRef,
    data,
    yLabel,
    minWidth,
    minHeight,
    isLoading,
    noDataLabel,
    isLoadingLabel,
    toolTipFormatter,
    layout,
    yTickMaximumTextLength,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const isLoadedWithData = !isLoading && data.length > 0;
  const isLoadedAndEmpty = !isLoading && data.length === 0;

  let displayedData = isLoadedWithData
    ? data
    : _.range(1, 10).map(i => {
        let yValue = layout === LayoutType.vertical ? i : i > 5 ? 9 - i + 1 : i;
        let xValue = layout === LayoutType.vertical ? i * 10 : i;
        return { x: xValue, y: yValue };
      });
  const barFillColor = isLoadedWithData ? '#8DAACB' : '#B3B3B3';

  const showMockData = isLoading || isLoadedAndEmpty;
  const displayedLabel = isLoading ? isLoadingLabel : noDataLabel;

  // Responsive container width need to be 99% for it to be totally responsive
  return (
    <div ref={componentRef} className={classNames.root}>
      <ResponsiveContainer width="99%" minHeight={minHeight} minWidth={minWidth}>
        <ComposedChart
          data={displayedData}
          maxBarSize={350}
          layout={layout ? layout : 'horizontal'}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 30,
          }}
        >
          {showMockData && (
            <Legend wrapperStyle={{ paddingTop: '5px', fontSize: '13px' }} iconSize={14} iconType="circle" />
          )}
          {!showMockData && (
            <Tooltip
              contentStyle={{ fontSize: 'x-small', fontWeight: 600 }}
              formatter={(value, name, payload) => (toolTipFormatter ? toolTipFormatter(payload) : value)}
            />
          )}
          {/* Bar must be placed before axis for rendering order */}
          <Bar
            dataKey="x"
            maxBarSize={30}
            name={showMockData ? displayedLabel : yLabel}
            fill={barFillColor}
            background={{ fill: '#eee' }}
          />
          <XAxis
            dataKey="x"
            tickMargin={10}
            stroke="grey"
            type="number"
            domain={layout === LayoutType.vertical ? [0, 100] : undefined}
            orientation={layout === LayoutType.vertical ? 'top' : 'bottom'}
            axisLine={false}
            height={15}
            tick={payload => (
              <AxisTickFormatter
                payload={payload}
                points={displayedData}
                isXAxis
                tickType={showMockData ? TickType.Default : TickType.Percentage}
                fontSize={displayedData.length > 7 ? 10 : 12}
                blockRotation={true}
              />
            )}
          />
          <YAxis
            dataKey="y"
            stroke="grey"
            type="category"
            padding={{ top: 10, bottom: 0 }}
            width={layout === LayoutType.vertical ? 40 : 90}
            tickMargin={40}
            tickLine={false}
            axisLine={false}
            interval={0}
            tick={payload => (
              <AxisTickFormatter
                dx={layout === LayoutType.vertical ? 50 : undefined}
                dy={layout === LayoutType.vertical ? 4 : undefined}
                textAnchor={layout === LayoutType.vertical ? 'start' : undefined}
                payload={payload}
                points={displayedData}
                isXAxis={false}
                tickType={showMockData ? TickType.Default : TickType.Categorical}
                fontSize={displayedData.length > 7 ? 10 : 12}
                blockRotation={true}
                maximumTextLength={layout === LayoutType.vertical ? undefined : yTickMaximumTextLength}
              />
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
