import React from 'react';
import { classNamesFunction, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { MetricCardProps, MetricCardStyleProps, MetricCardStyles } from './MetricCard.types';
import numeral from 'numeral';
import { TitledContentCard } from '../../../../../components/common/ContentCard';

const getClassNames = classNamesFunction<MetricCardStyleProps, MetricCardStyles>();

export const MetricCardBase = (props: MetricCardProps) => {
  const {
    styles,
    theme,
    className,
    componentRef,
    title,
    value,
    isPercentageValue,
    isLoading,
    borderColor,
    actions,
    footer,
    t,
  } = props;
  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
    borderColor: borderColor,
    actions: actions,
  });

  const getCardFormatedValue = (value: number): string => {
    numeral.nullFormat(t('common.null-format'));
    if (value === 0 || value < 1e-4) {
      return '0';
    }
    if (isPercentageValue) {
      return numeral(value).format('0.00') + '%';
    }

    var numFormat = '0,0[.]0';
    return numeral(value).format(numFormat);
  };
  return (
    <div ref={componentRef} className={classNames.root}>
      <TitledContentCard
        componentRef={componentRef}
        styles={classNames.subComponentStyles.card}
        body={isLoading ? <Spinner size={SpinnerSize.medium} /> : <span>{getCardFormatedValue(value)}</span>}
        title={title}
        actions={actions}
        footer={footer && <div className={classNames.footer}>{footer}</div>}
      />
    </div>
  );
};
