import React from 'react';
import { ReactElement } from 'react';
import { CallableChildren, ConnectedProductGuardProps, GuardType } from './ProductGuard.types';
import { isArray } from '../../utils';

export const ProductGuard: React.FC<ConnectedProductGuardProps> = (
  props: ConnectedProductGuardProps,
): ReactElement | null => {
  const { supportedProducts, products, type, children } = props;
  const productsInternal = isArray(products) ? products : [products];

  function isCallableChildren(children: CallableChildren | ReactElement): children is CallableChildren {
    return typeof children === 'function';
  }

  const onRenderChildren = (children: CallableChildren | ReactElement): ReactElement | null =>
    isCallableChildren(children) ? children(supportedProducts) : children;

  const isAllowedProductsIncluded = (): boolean =>
    !productsInternal || productsInternal.length === 0 || productsInternal.some(p => supportedProducts.includes(p));

  const isOmittedProductsExcluded = (): boolean =>
    !productsInternal || productsInternal.every(p => !supportedProducts.includes(p));

  const isRenderingChildrenAllowed = (): boolean =>
    type === GuardType.Exclude ? isOmittedProductsExcluded() : isAllowedProductsIncluded();

  return children && isRenderingChildrenAllowed() ? onRenderChildren(children) : null;
};
