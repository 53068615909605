import React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react';
import { ContentCardProps, ContentCardStyleProps, ContentCardStyles } from './ContentCard.types';

export const getClassNames = classNamesFunction<ContentCardStyleProps, ContentCardStyles>();

export function ContentCardBase(props: ContentCardProps) {
  const { styles, theme, componentRef, className, header, body, footer } = props;

  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
    containsHeader: !!header,
    containsFooter: !!footer,
  });

  return (
    <div ref={componentRef} className={classNames.root}>
      <div className={classNames.header}>{header}</div>
      <div className={classNames.body}>{body}</div>
      <div className={classNames.footer}>{footer}</div>
    </div>
  );
}
