import { TitledContentCardStyles, TitledContentCardStyleProps } from './TitledContentCard.types';
import { getFlexColumnStyles } from '../../Styles';

export const styles = (props: TitledContentCardStyleProps): TitledContentCardStyles => {
  return {
    root: ['mbc-titled-content-card', getFlexColumnStyles(), props.className],
    subComponentStyles: {
      card: {},
      header: {},
    },
  };
};
