import { MbcRouteMap, MbcRouteKey, MbcRouteHash } from '../MbcRouter.types';
import { Tenant } from '../../store/types/tenantsList';
import { Environment } from '../../store/types/environmentList.d';
import { Environment as RuntimeEnvironment } from '../../guards/EnvironmentGuard/EnvironmentGuard';
import { Permission } from '../../config/userPermissions.config';
import { SearchAnalytics } from '../../pages/ProductSearch/SearchAnalytics/SearchAnalytics';
import { EnvironmentEditor } from '../../pages/EnvironmentList/EnvironmentEditor';
import { Environment as EnvironmentLandingPage } from '../../pages/EnvironmentList/Environment/Environment';
import { QueryTester } from '../../pages/ProductSearch/QueryTester';
import { AppRoute } from '../router.service';

export const MbcEnvironmentRouteMapper = (tenant: Tenant, environment: Environment): MbcRouteMap => ({
  [MbcRouteKey.Environment]: {
    key: MbcRouteKey.Environment,
    Component: EnvironmentLandingPage,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.AdminEnvironmentRead, Permission.SearchIndexRead],
  },
  [MbcRouteKey.EnvironmentEditor]: {
    Component: EnvironmentEditor,
    key: MbcRouteKey.EnvironmentEditor,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/edit`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.AdminEnvironmentReadWrite],
  },
  [MbcRouteKey.SchemaManagement]: {
    key: MbcRouteKey.SchemaManagement,
    Component: EnvironmentLandingPage,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/schema`),
    redirectUrl: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}${MbcRouteHash.Schema}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchIndexRead],
  },
  [MbcRouteKey.DocumentManagement]: {
    key: MbcRouteKey.DocumentManagement,
    Component: EnvironmentLandingPage,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/documents`),
    redirectUrl: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}${MbcRouteHash.ProductData}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchIndexRead],
  },
  [MbcRouteKey.IndexAnalytics]: {
    key: MbcRouteKey.IndexAnalytics,
    Component: EnvironmentLandingPage,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/dashboard`),
    redirectUrl: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}${MbcRouteHash.Insights}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    minimumPermissions: [Permission.SearchIndexRead],
  },
  [MbcRouteKey.LegacySearchAnalytics]: {
    key: MbcRouteKey.LegacySearchAnalytics,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/analytics`),
    redirectUrl: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/search/analytics`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.ProductSearch]: {
    key: MbcRouteKey.ProductSearch,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/search`),
    redirectUrl: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/search/querytester`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SearchAnalytics]: {
    Component: SearchAnalytics,
    key: MbcRouteKey.SearchAnalytics,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/search/analytics`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.QueryTester]: {
    Component: QueryTester,
    key: MbcRouteKey.QueryTester,
    url: AppRoute.append(`tenants/${tenant.id}/environments/${environment.id}/search/querytester`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
});
