import { CustomMLRankerEditorStyles, CustomMLRankerEditorStyleProps } from './CustomMLRankerEditor.types';

export const styles = (props: CustomMLRankerEditorStyleProps): CustomMLRankerEditorStyles => {
  const { className } = props;
  return {
    root: ['mbc-CustomMLRankerEditor', {}, className],
    subComponentStyles: {
      selectionContainer: {
        flexContainer: {
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: 'calc(100vh - 190px)',
        },
      },
    },
  };
};
