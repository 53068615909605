import {
  IColumn,
  IStyle,
  IStyleFunctionOrObject,
  ITheme,
  SelectionMode,
  IIconProps,
  IRefObject,
  IGroup,
  IDetailsGroupRenderProps,
  CheckboxVisibility,
} from 'office-ui-fabric-react';
import { ListItemsOrder } from '../../../utils';

/**
 * Exposed options menu props
 */
export interface OptionsItemProps {
  /**
   * Options menu item key
   */
  key: string;

  /**
   * Options menu item displayed text
   */
  text: string;

  /**
   * Options menu item disable, defaults to false
   */
  disabled: boolean;

  /**
   * Options menu item icon props
   */
  iconProps: IIconProps;

  /**
   * Options menu item onClick handler
   */
  onClick: (item: any) => void;
}

/**
 * Used when summary is enabled, to detect mapping every column at summary row
 */
export interface SummaryProps {
  /**
   * Summary expanded icon props label
   */
  expandedLabel: string;

  /**
   * Summary collapsed icon props label
   */
  collapsedLabel: string;

  /**
   * Summary column configuraiton props
   */
  columns: IColumn[];
}

/**
 * Forces list item to have a unique key value
 */
export interface ListItem {
  // Key must be unique
  key: string;
}

/**
 * Details list base props
 */
export interface DetailsListBaseProps {
  /**
   * Theme provided by the Higher Order Component
   */
  theme?: ITheme;

  /**
   * Style function to be passed in to override the themed or default styles
   */
  styles?: IStyleFunctionOrObject<DetailsListStyleProps, DetailsListStyles>;

  /**
   * Optional class name to add to the root element.
   */
  className?: string;

  /**
   * Optional callback to access the IDetailsList interface. Use this instead of ref for accessing
   * the public methods and properties of the component.
   */
  componentRef?: IRefObject<HTMLDivElement>;
}

export enum ColumnPriority {
  Primary,
  Secondary,
  Tertiary,
}

export enum SortDataType {
  numeric,
  text,
  dateTime,
}

export interface OrderByDetails {
  fieldName: string;
  orderBy?: ListItemsOrder;
  dataType: SortDataType;
}

export interface ColumnWithPriority extends IColumn {
  priority: ColumnPriority;
  fieldName: string;
  // Should include column data type to determine how to sort
  sortDataType?: SortDataType;
  centerAligned?: boolean;
  subTextField?: string;
}

export interface ResponsiveColumns {
  columns: ColumnWithPriority[];
  initialSortingDetails?: OrderByDetails;
  /* Maximum width in which after both secondary and teritary columns are hidden  */
  secondaryBreakPoint: number;
  /* Maximum width in which teritary columns are hidden  */
  teritaryBreakPoint: number;
}

export interface NoItemsToDisplayProps {
  text: string;
  subText?: string;
  actionProps?: { buttonText: string; onClick: () => void; disabled?: boolean };
}

/**
 * Extended details list props
 */

export interface SelectionItem extends ListItem {
  canSelect: boolean;
}
export interface DetailsListProps extends DetailsListBaseProps {
  /**
   * Details list items to render
   */
  items: ListItem[];

  /**
   * Controls details list shimmer state
   */
  isLoading: boolean;

  isLoadedAndEmpty: boolean;

  /**
   * Allows disabling render list headers, defaults to true
   */
  isHeaderVisible?: boolean;

  /**
   * Utilizes Fabric details list Selection props
   * Note: selection mode is none when summary is enabled
   */
  selectionProps?: {
    defaultSelected?: SelectionItem[];

    /**
     * Utilizes Fabric details list Selection zone to set checkbox visibility
     */
    checkboxVisibility: CheckboxVisibility;

    /**
     * Utilizes Fabric details list Selection zone to initially select all
     */
    isAllSelected?: boolean;

    /**
     * Utilizes Fabric details list Selection zone to determine selection mode
     * (none/multiple/single) items selection
     */
    selectionMode: SelectionMode;

    /**
     * Selection changes event handler, called on currently selected items change
     * Note: selection mode is none when summary is enabled
     */
    onSelectionChanged: (selection: string[]) => void;
  };

  /**
   * Fabric details list extended column configuration
   */
  responsiveColumns: ResponsiveColumns;

  /**
   * Undefined value disables summary for rendered details list
   * Note: selection mode is none when summary is enabled
   */
  summaryProps?: SummaryProps;

  /**
   * Overrides no items to display rendering, provide label to use default renderer or custom jsx to render
   */
  onRenderNoItemsToDisplay?: JSX.Element | NoItemsToDisplayProps;

  /**
   * Gets options items props from currently rendered item
   */
  optionsItemProps?: (item: any) => OptionsItemProps[];

  /**
   * Grouping list items configuration
   * Note: grouping mode is none when summary is enabled
   */
  groups?: IGroup[];

  /**
   * Grouping list items render props
   * Note: grouping mode is none when summary is enabled
   */
  groupProps?: IDetailsGroupRenderProps;
}

/**
 * Details list style props, enabling summary changes rendering styles
 */
export type DetailsListStyleProps = Required<Pick<DetailsListBaseProps, 'theme'>> &
  Pick<DetailsListBaseProps, 'className'> & {
    enableSummary: boolean;
  };

/**
 * Details list styles
 */
export interface DetailsListStyles {
  /**
   * Fabric details list root styles
   */
  root: IStyle;

  /**
   * Fabric details list headerWrapper styles
   */
  headerWrapper: IStyle;

  /**
   * Fabric details list contentWrapper styles
   */
  contentWrapper: IStyle;

  /**
   * Summary chevron icon base styles
   */
  chevronIcon: IStyle;

  /**
   * Summary chevron icon styles when expanded
   */
  chevronExpanded: IStyle;

  /**
   * Summary chevron icon styles when collapsed
   */
  chevronCollapsed: IStyle;

  /**
   * Common options item styles
   */
  optionsItem: IStyle;

  filtersContainer: IStyle;

  listCell: IStyle;

  columnWithSubText: IStyle;

  subText: IStyle;

  centeredColumns: IStyle;

  leftAlignedColumns: IStyle;

  summary?: IStyle;

  chevronButton: IStyle;

  /**
   * Fabric UI details list sub component styles
   */
  subComponentStyles: {
    /**
     * Contextual menu callout styles
     */
    optionsCallout?: IStyleFunctionOrObject<any, any>;
  };
}
