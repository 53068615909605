import {
  TrainingJob,
  SET_TRAINING_JOB_METRICS,
  LOADING_TRAINING_JOB_METRICS,
  LOADING_TRAINING_JOB_LIST,
  SET_TRAINING_JOB_LIST,
  RESET_TRAINING_JOB_LIST,
  UPDATE_TRAINING_JOB,
  UPDATE_TRAINING_JOB_LIST,
} from '../../types/customML/trainingJob.d';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { Action } from 'redux';
import { getTrainingJobMetrics, listTrainingJobs, getTrainingJob } from '../../../restful-apis/customML.api';
import { ActionSuccessHandler, ActionFailureHandler, ExecuteReduxCrudAction } from '../utils';
import { createTrainingJobAction } from '../utils/crud-actions/training-job/TrainingJobCrudActions';
import { ModelJobStatus } from '../../types/customML/searchModel.d';

export const trainSearchModel = (
  tenantId: string,
  searchModelKey: string,
  trainingJob: TrainingJob,
  onSuccess?: ActionSuccessHandler<TrainingJob>,
  onFailure?: ActionFailureHandler<TrainingJob>,
): ThunkAction<void, AppState, null, Action> =>
  ExecuteReduxCrudAction(createTrainingJobAction(tenantId, searchModelKey, trainingJob, onSuccess, onFailure));

export const setTrainingJobMetrics = (key: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;

    // Re-use already cached metrics
    // Don't send a request on other status than Finished
    const trainingJob = getState().trainingJobs.trainingJobMapper[key];
    if (!!Object.values(trainingJob.metricsMapper).length || trainingJob.status !== ModelJobStatus.Finished) {
      return;
    }

    dispatch({
      type: LOADING_TRAINING_JOB_METRICS,
      key: key,
    });

    getTrainingJobMetrics(tenantId, key)
      .then(metrics => {
        dispatch({
          type: SET_TRAINING_JOB_METRICS,
          key: key,
          metrics: metrics,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_TRAINING_JOB_METRICS,
          key: key,
          metrics: [],
        });
      });
  };
};

export const updateTrainingJob = (key: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const tenantId = getState().tenantsList.activeTenant.id;

    getTrainingJob(tenantId, key).then(trainingJob => {
      dispatch({
        type: UPDATE_TRAINING_JOB,
        trainingJob: trainingJob,
      });
    });
  };
};

export const setTrainingJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: LOADING_TRAINING_JOB_LIST,
    });

    listTrainingJobs(tenantId).then(trainingJobList => {
      dispatch({
        type: SET_TRAINING_JOB_LIST,
        trainingJobList: trainingJobList,
      });
    });
  };
};

export const updateTrainingJobList = (tenantId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    listTrainingJobs(tenantId, undefined, true).then(trainingJobList => {
      dispatch({
        type: UPDATE_TRAINING_JOB_LIST,
        trainingJobList: trainingJobList,
      });
    });
  };
};

export const resetTrainingJobList = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({
      type: RESET_TRAINING_JOB_LIST,
    });
  };
};
