import { SelectActionButtonStyles, SelectActionButtonStyleProps } from './SelectActionButton.types';

export const styles = (props: SelectActionButtonStyleProps): SelectActionButtonStyles => ({
  root: [
    'mbc-item-list-panel',
    {
      selectors: {
        '.ms-Panel-contentInner': {
          justifyContent: 'space-between',
        },
      },
    },
    props.className,
  ],
  header: [
    'mbc-panel-header',
    {
      fontSize: 21,
      marginLeft: 8,
      lineHeight: 28,
      fontWeight: 500,
    },
  ],
  action: [
    'mbc-action',
    {
      fontSize: 14,
      lineHeight: 28,
      fontWeight: 500,
    },
  ],
  searchBox: [
    'mbc-search-box',
    {
      width: 290,
      display: 'flex',
      marginTop: '32px !important',
      flexDirection: 'row-reverse',
      paddingLeft: 12,
      paddingRight: 6,
      border: 'none',
      background: props.theme.palette.neutralLighter,
    },
  ],
  subComponentStyles: {
    list: [],
  },
});
