import { CopyToClipboardStyles, CopyToClipboardStyleProps } from './CopyToClipboard.types';
import { getFlexRowStyles } from '../Styles';

export const styles = (props: CopyToClipboardStyleProps): CopyToClipboardStyles => ({
  root: [
    'mbc-copy-to-clipboard',
    getFlexRowStyles(),
    {
      paddingBottom: 16,
      alignItems: 'flex-end',
      selectors: {
        '.ms-TextField': {
          width: '100%',
        },
        '.ms-Button': {
          marginLeft: 4,
        },
        '.ms-Label': {
          fontSize: 12,
        },
        '.ms-TextField-field': {
          fontSize: 12,
        },
      },
    },
    props.className,
  ],
});
