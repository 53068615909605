import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../router/MbcRouter.base';
import { useBreadcrumb } from '../../store/hooks/use-breadcrumb/useBreadcrumb';
import { useCustomMLJobPolling } from '../../store/hooks/use-polling/useCustomMLJobPolling';
import { useProxyRouter } from '../../store/hooks/use-router/useProxyRouter';
import { MbcRouteKey } from '../../router/MbcRouter.types';
import { useCustomMLRouter } from '../../store/hooks/use-router/useCustomMLRouter';

const searchModelListBreadcrumbKey = 'Models';

export const SearchModelListProxy = (props: RouteComponentProps) => {
  useCustomMLJobPolling();
  useBreadcrumb(searchModelListBreadcrumbKey, props.match.url);

  const MbcProxyRouteMapper = useProxyRouter();
  const MbcCustomMLRouteMapper = useCustomMLRouter();

  return (
    <MbcRouter
      routes={[MbcCustomMLRouteMapper(MbcRouteKey.SearchModelList), MbcProxyRouteMapper(MbcRouteKey.SearchModelProxy)]}
    />
  );
};
