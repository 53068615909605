import React, { useMemo } from 'react';
import { useSelector, useDispatch } from '../../../../store/hooks';
import { MultiLineChart, Line } from '../../../../components/common/charts/MultiLineChart';
import { ChartContentCard } from '../Common/ChartContentCard';
import { TickType } from '../../../../components/common/charts/common/AxisTickFormatter';
import { useMediaQuery } from 'react-responsive';
import { TooltipProps } from 'recharts';
import { ToolTipFormatter } from '../../../../components/common/charts/common/ToolTipFormatter';
import { AppState } from '../../../../store/reducers';
import { classNamesFunction } from 'office-ui-fabric-react';
import { SearchQueriesStyleProps, SearchQueriesStyles, SearchQueriesBaseProps } from './SearchQueries.types';
import { updateSearchRequestsChart } from '../../../../store/actions/analyticsActions';
import { AggregationInterval } from '../../../../restful-apis/dto/analytics/analytics';

const getClassNames = classNamesFunction<SearchQueriesStyleProps, SearchQueriesStyles>();

export const SearchQueriesBase = (props: SearchQueriesBaseProps) => {
  const { t, styles, theme, className } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  const isTotalSearchQueriesOverTimeLoading = useSelector(
    (state: AppState) => state.analytics.isTotalSearchRequestsOverTimeLoading,
  );
  const totalSearchQueriesOverTime = useSelector((state: AppState) => state.analytics.totalSearchRequestsOverTime);

  const largeScreen = useMediaQuery({ minWidth: 1400 });
  const mediumScreen = useMediaQuery({ maxWidth: 1399 });
  const smallScreen = useMediaQuery({ maxWidth: 960 });

  const data = useMemo(
    () => totalSearchQueriesOverTime.series.map(point => ({ xValue: point.date, yValues: [point.value] })),
    [totalSearchQueriesOverTime.series],
  );
  const yLines: Line[] = useMemo(
    () => [
      {
        name: t('analytics.search-queries'),
        stroke: '#004578',
      },
    ],
    [t],
  );

  const dataToExport = useMemo(
    () =>
      data.map(dataPoint => ({
        date: dataPoint.xValue,
        ...dataPoint.yValues.reduce(
          (prevValue, currentValue, index) => ({ ...prevValue, [yLines[index].name]: currentValue }),
          {},
        ),
      })),
    [data, yLines],
  );

  return (
    <div className={classNames.root}>
      <ChartContentCard
        className={className}
        exportData={{ fileName: t('analytics.search-queries'), data: dataToExport }}
        chart={
          <MultiLineChart
            points={data}
            minWidth={300}
            minHeight={220}
            xLine={{
              name: 'Date',
              stroke: 'grey',
            }}
            ylines={yLines}
            legendProps={{ legendContent: [...yLines].reverse() }}
            xAxisProps={{
              interval: smallScreen
                ? data.length > 14
                  ? totalSearchQueriesOverTime.interval + 3
                  : data.length > 8
                  ? totalSearchQueriesOverTime.interval + 2
                  : totalSearchQueriesOverTime.interval + 1
                : mediumScreen
                ? data.length > 14
                  ? totalSearchQueriesOverTime.interval + 2
                  : data.length > 8
                  ? totalSearchQueriesOverTime.interval + 1
                  : totalSearchQueriesOverTime.interval
                : totalSearchQueriesOverTime.interval,
              tickProps: {
                tickType: TickType.DateTime12Hrs,
                fontSize: 12,
                blockRotation: largeScreen || (!smallScreen && data.length < 14),
                rotationBreakPoint: 7,
                rotatedFontSize: 10,
              },
            }}
            toolTipContent={(props: TooltipProps) => <ToolTipFormatter shouldReverseOrder toolTipProps={props} />}
            isLoading={isTotalSearchQueriesOverTimeLoading}
          />
        }
        title={t('analytics.search-queries')}
        isLoadedAndEmpty={!isTotalSearchQueriesOverTimeLoading && !totalSearchQueriesOverTime.series.length}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
          aggInterval: AggregationInterval,
        ) =>
          dispatch(
            updateSearchRequestsChart(
              responseCode,
              start,
              end,
              markets,
              regions,
              searchResultsStatus,
              searchInstances,
              aggInterval,
            ),
          )
        }
      />
    </div>
  );
};
