import { configureDefaultObject, getLocaleTimeNow } from '../../../utils';
import {
  CookingJob,
  CookingJobMapper,
  CookingJobState,
  CookingJobStateActionTypes,
  SET_COOKING_JOB_LIST,
  RESET_COOKING_JOB_LIST,
  ADD_COOKING_JOB_LIST,
  LOADING_COOKING_JOB_LIST,
  UPDATE_COOKING_JOB_LIST,
} from '../../types/customML/cookingJob.d';
import { ModelJobStatus } from '../../types/customML/searchModel.d';

export const createInitialCookingJob = (): CookingJob => ({
  key: '',
  name: '',
  email: '',
  indexId: '',
  description: '',
  inputDatasetKey: '',
  cookedDatasetKey: '',
  createdAt: getLocaleTimeNow(),
  updatedAt: getLocaleTimeNow(),
  status: ModelJobStatus.NotStarted,
});

const createCookingJobMapper = (initialMapper: CookingJobMapper = {}) =>
  configureDefaultObject<CookingJobMapper, CookingJob>(initialMapper, createInitialCookingJob());

const initialState: CookingJobState = {
  isLoading: true,
  cookingJobMapper: createCookingJobMapper(),
};

export function cookingJobReducer(state = initialState, action: CookingJobStateActionTypes): CookingJobState {
  switch (action.type) {
    case SET_COOKING_JOB_LIST: {
      return {
        ...state,
        isLoading: false,
        cookingJobMapper: createCookingJobMapper({
          ...action.cookingJobList.reduce((mapper: CookingJobMapper, cookingJob: CookingJob) => {
            mapper[cookingJob.key] = cookingJob;
            return mapper;
          }, {}),
        }),
      };
    }
    case LOADING_COOKING_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_COOKING_JOB_LIST: {
      return {
        ...state,
        isLoading: true,
        cookingJobMapper: createCookingJobMapper(),
      };
    }
    case ADD_COOKING_JOB_LIST: {
      return {
        ...state,
        cookingJobMapper: createCookingJobMapper({
          ...action.cookingJobList.reduce((mapper: CookingJobMapper, cookingJob: CookingJob) => {
            mapper[cookingJob.key] = cookingJob;
            return mapper;
          }, {}),
          ...state.cookingJobMapper,
        }),
      };
    }
    case UPDATE_COOKING_JOB_LIST: {
      return {
        ...state,
        cookingJobMapper: createCookingJobMapper({
          ...state.cookingJobMapper,
          ...action.cookingJobList.reduce((mapper: CookingJobMapper, cookingJob: CookingJob) => {
            const updatedCookingJob = {
              ...state.cookingJobMapper[cookingJob.key],
              status: cookingJob.status,
            };
            if (!!updatedCookingJob.key) {
              mapper[cookingJob.key] = updatedCookingJob;
            }
            return mapper;
          }, {}),
        }),
      };
    }
    default:
      return state;
  }
}
