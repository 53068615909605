import React, { useState, useCallback } from 'react';
import { classNamesFunction, Stack, StackItem, TextField, Label, Toggle, CommandButton } from 'office-ui-fabric-react';
import {
  GeneralDetailsEditorProps,
  GeneralDetailsEditorStyleProps,
  GeneralDetailsEditorStyles,
} from './GeneralDetails.types';
import { Moment } from 'moment';
import moment from 'moment';
import { ConditionBlock } from '../../../../../utils/customizations/conditions';
import { ConditionBlockEditor } from '../../../common/ConditionBlockEditor';
import { DateTimePicker } from '../../../../../components/common/DateTimePicker';
import { excludedFieldTypes } from '../../../../../store/types/customizations/businessRule.d';

const getClassNames = classNamesFunction<GeneralDetailsEditorStyleProps, GeneralDetailsEditorStyles>();

export const GeneralDetailsEditorBase = (props: GeneralDetailsEditorProps) => {
  const { styles, theme, className, generalDetails, editMode, onUpdate } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const [ruleSchedule, setRuleSchedule] = useState<boolean>(!!generalDetails.startDate || !!generalDetails.endDate);
  const [newConditionTrigger, setNewConditionTrigger] = useState<number>(0);

  const handleRuleNameChange = (val: string) => {
    onUpdate({ ...generalDetails, name: val });
  };

  const handleRuleDescriptionChange = (val: string) => {
    onUpdate({ ...generalDetails, description: val });
  };

  const handleRuleStartDateChange = (moment: Moment) => {
    onUpdate({ ...generalDetails, startDate: moment });
  };

  const handleRuleEndDateChange = (moment: Moment) => {
    onUpdate({ ...generalDetails, endDate: moment });
  };

  const handleRuleTriggerConditionChange = (conditionBlock: ConditionBlock) => {
    const updatedConditionBlock = !!conditionBlock && conditionBlock.conditions.length > 0 ? conditionBlock : undefined;
    onUpdate({ ...generalDetails, searchRequestCondition: updatedConditionBlock });
  };

  const updateRuleSchedule = (checked: boolean) => {
    setRuleSchedule(checked);
    if (!checked) {
      onUpdate({ ...generalDetails, startDate: undefined, endDate: undefined });
    }
  };

  const addNewCondition = useCallback(() => {
    setNewConditionTrigger(trigger => trigger + 1);
  }, []);

  return (
    <Stack className={classNames.root} verticalAlign={'space-between'}>
      <Stack className={classNames.generalDetailsContainer} tokens={{ childrenGap: '16' }}>
        <StackItem>
          <TextField
            required
            value={generalDetails.name}
            label={'Name'}
            disabled={editMode}
            onChange={(event, newValue) => handleRuleNameChange(newValue || '')}
          />
        </StackItem>
        <StackItem>
          <TextField
            value={generalDetails.description}
            label={'Description'}
            onChange={(event, newValue) => handleRuleDescriptionChange(newValue || '')}
          />
        </StackItem>

        <Stack className={classNames.schedule}>
          <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 16 }}>
            <Label styles={{ root: { fontSize: 16 } }}>Schedule</Label>
            <Toggle
              styles={{ root: { margin: 0 } }}
              checked={ruleSchedule}
              offText={'Off'}
              onText={'On'}
              onChange={(ev, checked) => updateRuleSchedule(checked || false)}
            />
          </Stack>
          <StackItem>
            <DateTimePicker
              disabled={!ruleSchedule}
              label={'Start date'}
              date={generalDetails.startDate ? generalDetails.startDate.toDate() : undefined}
              onDateChange={date => handleRuleStartDateChange(moment(date))}
            />
          </StackItem>
          <StackItem>
            <DateTimePicker
              disabled={!ruleSchedule}
              label={'End date'}
              date={generalDetails.endDate ? generalDetails.endDate.toDate() : undefined}
              onDateChange={date => handleRuleEndDateChange(moment(date))}
            />
          </StackItem>
        </Stack>

        <Stack>
          <Label styles={{ root: { fontSize: 16 } }}>Rule trigger</Label>
          <ConditionBlockEditor
            showSpecialFields={true}
            conditionBlock={generalDetails.searchRequestCondition}
            excludedFieldTypes={excludedFieldTypes}
            newConditionTrigger={newConditionTrigger}
            onConditionBlockUpdate={c => handleRuleTriggerConditionChange(c)}
          />
        </Stack>
      </Stack>
      <div className={classNames.addNewCondition}>
        <CommandButton iconProps={{ iconName: 'Add' }} text="Add new condition" onClick={() => addNewCondition()} />
      </div>
    </Stack>
  );
};
