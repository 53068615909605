import { Environment as RuntimeEnvironment } from '../guards/EnvironmentGuard/EnvironmentGuard';
import { EnvironmentListProxy } from '../pages/EnvironmentList';
import { MbcRouteMap, MbcRouteKey } from './MbcRouter.types';
import { ProductSearchProxy } from '../pages/ProductSearch';
import { HomePage } from '../pages/HomePage/HomePage';
import { TenantProxy } from '../pages/Tenant';
import { RoleListProxy } from '../pages/RoleList';
import { RoleProxy } from '../pages/RoleList/Role';
import { SearchModelProxy } from '../pages/CustomML/SearchModel/SeachModel.proxy';
import { EnvironmentProxy } from '../pages/EnvironmentList/Environment/Environment.proxy';
import { EvaluationListProxy } from '../pages/CustomML/SearchModel/EvaluationList/EvaluationList.proxy';
import { EvaluateSearchModelProxy } from '../pages/CustomML/SearchModel/EvaluationList/EvaluateSearchModel/EvaluateSearchModel.proxy';
import { SearchModelListProxy } from '../pages/CustomML/SeachModelList.proxy';
import { SearchInstanceProxy } from '../pages/ProductSearch/SearchInstance';
import { SynonymSetListProxy } from '../pages/ProductSearch/SynonymSetList';
import { SynonymSetProxy } from '../pages/ProductSearch/SynonymSetList/SynonymSet';
import { UrlRedirectSetListProxy } from '../pages/ProductSearch/UrlRedirectSetList';
import { UrlRedirectSetProxy } from '../pages/ProductSearch/UrlRedirectSetList/UrlRedirectSet';
import { BusinessRuleListProxy } from '../pages/ProductSearch/BusinessRuleList';
import { BusinessRuleProxy } from '../pages/ProductSearch/BusinessRuleList/BusinessRule';
import { TokenListProxy } from '../pages/TokenList/TokenList.proxy';
import { AppRoute } from './router.service';

const roleId = ':roleId';
const tenantId = ':tenantId';
const searchModelId = ':searchModelKey';
const environmentId = ':environmentId';
const evaluationJobId = ':evaluationKey';
const searchInstanceId = ':searchInstanceId';
const synonymSetId = ':synonymSetId';
const businessRuleId = ':businessRuleId';
const urlRedirectSetId = ':urlRedirectSetId';

export const MbcProxyRouteMapper = (): MbcRouteMap => ({
  [MbcRouteKey.HomePage]: {
    Component: HomePage,
    url: AppRoute.get(),
    key: MbcRouteKey.HomePage,
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.TenantProxy]: {
    isPartialMatch: true,
    Component: TenantProxy,
    url: AppRoute.append(`tenants/${tenantId}`),
    key: MbcRouteKey.TenantProxy,
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.EnvironmentProxy]: {
    isPartialMatch: true,
    Component: EnvironmentProxy,
    key: MbcRouteKey.EnvironmentProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SearchInstanceProxy]: {
    isPartialMatch: true,
    Component: SearchInstanceProxy,
    key: MbcRouteKey.SearchInstanceProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.EnvironmentListProxy]: {
    isPartialMatch: true,
    Component: EnvironmentListProxy,
    key: MbcRouteKey.EnvironmentListProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.TokenListProxy]: {
    isPartialMatch: true,
    Component: TokenListProxy,
    key: MbcRouteKey.TokenListProxy,
    url: AppRoute.append(`tenants/${tenantId}/tokens`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.RoleListProxy]: {
    isPartialMatch: true,
    Component: RoleListProxy,
    key: MbcRouteKey.RoleListProxy,
    url: AppRoute.append(`tenants/${tenantId}/roles`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.RoleProxy]: {
    Component: RoleProxy,
    key: MbcRouteKey.RoleProxy,
    url: AppRoute.append(`tenants/${tenantId}/roles/${roleId}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.ProductSearchProxy]: {
    isPartialMatch: true,
    Component: ProductSearchProxy,
    key: MbcRouteKey.ProductSearchProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SearchInstanceProxy]: {
    isPartialMatch: true,
    Component: SearchInstanceProxy,
    key: MbcRouteKey.SearchInstanceProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SearchModelListProxy]: {
    isPartialMatch: true,
    Component: SearchModelListProxy,
    url: AppRoute.append(`tenants/${tenantId}/models`),
    key: MbcRouteKey.SearchModelListProxy,
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SearchModelProxy]: {
    isPartialMatch: true,
    Component: SearchModelProxy,
    key: MbcRouteKey.SearchModelProxy,
    url: AppRoute.append(`tenants/${tenantId}/models/${searchModelId}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.EvaluationJobListProxy]: {
    isPartialMatch: true,
    Component: EvaluationListProxy,
    key: MbcRouteKey.EvaluationJobListProxy,
    url: AppRoute.append(`tenants/${tenantId}/models/${searchModelId}/evals`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.EvaluationJobProxy]: {
    Component: EvaluateSearchModelProxy,
    key: MbcRouteKey.EvaluationJobProxy,
    url: AppRoute.append(`tenants/${tenantId}/models/${searchModelId}/evals/${evaluationJobId}`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SynonymSetListProxy]: {
    isPartialMatch: true,
    Component: SynonymSetListProxy,
    key: MbcRouteKey.SynonymSetListProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/synonyms`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.SynonymSetProxy]: {
    isPartialMatch: true,
    Component: SynonymSetProxy,
    key: MbcRouteKey.SynonymSetProxy,
    url: AppRoute.append(
      `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/synonyms/${synonymSetId}`,
    ),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.BusinessRuleListProxy]: {
    isPartialMatch: true,
    Component: BusinessRuleListProxy,
    key: MbcRouteKey.BusinessRuleListProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/businessrules`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.BusinessRuleProxy]: {
    isPartialMatch: true,
    Component: BusinessRuleProxy,
    key: MbcRouteKey.BusinessRuleProxy,
    url: AppRoute.append(
      `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/businessrules/${businessRuleId}`,
    ),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.UrlRedirectSetListProxy]: {
    isPartialMatch: true,
    Component: UrlRedirectSetListProxy,
    key: MbcRouteKey.UrlRedirectSetListProxy,
    url: AppRoute.append(`tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/queryredirects`),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
  [MbcRouteKey.UrlRedirectSetProxy]: {
    isPartialMatch: true,
    Component: UrlRedirectSetProxy,
    key: MbcRouteKey.UrlRedirectSetProxy,
    url: AppRoute.append(
      `tenants/${tenantId}/environments/${environmentId}/search/${searchInstanceId}/queryredirects/${urlRedirectSetId}`,
    ),
    runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
  },
});
