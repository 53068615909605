import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';

import {
  SET_INDEX_PRODUCT_COUNT,
  LOADING_INDEX_PRODUCT_COUNT,
  SET_INDEX_DOCUMENTS_PER_SECOND,
  LOADING_INDEX_DOCUMENTS_PER_SECOND,
  SET_LATENCY_PER_DOCUMENT,
  LOADING_LATENCY_PER_DOCUMENT,
  SET_INDEX_AVG_DPS,
  LOADING_INDEX_AVG_DPS,
  SET_DOCUMENTS_BREAK_DOWN,
  LOADING_DOCUMENTS_BREAK_DOWN,
  CachePeriod,
  LOADING_INDEX_REQUESTS_PER_DAY,
  SET_INDEX_REQUESTS_PER_DAY,
  SET_PROVIDED_DOCUMENTS,
  LOADING_PROVIDED_DOCUMENTS,
  SET_FEATURE_COVERAGE,
  LOADING_FEATURE_COVERAGE,
  LOADING_KEY_LEVEL_ERROR,
  SET_KEY_LEVEL_ERROR,
} from '../types/insights.d';

import {
  getMetric,
  getDocumentBreakDownMetric,
  getFeaturesCoverageMetric,
  getLatencyPerDocumentMetric,
  getKeyLevelError,
} from '../../restful-apis/insights.api';

export const setFeaturesCoverage = (timeSpan: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_FEATURE_COVERAGE });
    getFeaturesCoverageMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      timeSpan.startsWith('PT') ? timeSpan.slice(2).toLocaleLowerCase() : timeSpan.slice(1).toLocaleLowerCase(),
      timeSpan,
    )
      .then(featureCoverage => {
        dispatch({ type: SET_FEATURE_COVERAGE, featureCoverage: featureCoverage });
      })
      .catch(() => {
        dispatch({
          type: SET_FEATURE_COVERAGE,
          featureCoverage: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setIndexAvgDPS = (timespan: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_INDEX_AVG_DPS });

    getMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      'DPS',
      'Document',
      timespan.startsWith('PT') ? timespan.slice(2).toLocaleLowerCase() : timespan.slice(1).toLocaleLowerCase(),
      timespan,
    )
      .then(indexAvgDPS => {
        dispatch({
          type: SET_INDEX_AVG_DPS,
          indexAvgDPS: indexAvgDPS.series.length
            ? { ...indexAvgDPS, value: indexAvgDPS.series[0].yValues[0] }
            : { ...indexAvgDPS, value: NaN },
        });
      })
      .catch(() => {
        dispatch({
          type: SET_INDEX_AVG_DPS,
          indexAvgDPS: { value: NaN, cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit } },
        });
      });
  };
};

export const setIndexProductCount = (timespan: string, interval: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_INDEX_PRODUCT_COUNT });

    getMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      'TotalProductCount',
      'Feature',
      interval,
      timespan,
    )
      .then(indexProductCount => {
        dispatch({ type: SET_INDEX_PRODUCT_COUNT, indexProductCount: indexProductCount });
      })
      .catch(() => {
        dispatch({
          type: SET_INDEX_PRODUCT_COUNT,
          indexProductCount: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setProvidedDocuments = (timespan: string, interval: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_PROVIDED_DOCUMENTS });

    getMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      'DocumentCount',
      'Document',
      interval,
      timespan,
    )
      .then(providedDocuments => {
        dispatch({ type: SET_PROVIDED_DOCUMENTS, providedDocuments: providedDocuments });
      })
      .catch(() => {
        dispatch({
          type: SET_PROVIDED_DOCUMENTS,
          providedDocuments: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setIndexRequestsPerDay = (
  timespan: string,
  interval: string,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_INDEX_REQUESTS_PER_DAY });

    getMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      'RequestsCount',
      'Index',
      interval,
      timespan,
    )
      .then(indexRequestsPerDay => {
        dispatch({ type: SET_INDEX_REQUESTS_PER_DAY, indexRequestsPerDay: indexRequestsPerDay });
      })
      .catch(() => {
        dispatch({
          type: SET_INDEX_REQUESTS_PER_DAY,
          indexRequestsPerDay: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setIndexDocumentsPerSecond = (
  timespan: string,
  interval: string,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_INDEX_DOCUMENTS_PER_SECOND });

    getMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      'DPS',
      'Document',
      interval,
      timespan,
    )
      .then(indexDocumentsPerSecond => {
        dispatch({ type: SET_INDEX_DOCUMENTS_PER_SECOND, indexDocumentsPerSecond: indexDocumentsPerSecond });
      })
      .catch(() => {
        dispatch({
          type: SET_INDEX_DOCUMENTS_PER_SECOND,
          indexDocumentsPerSecond: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setLatencyPerDocument = (
  timespan: string,
  interval: string,
  percentile: number,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_LATENCY_PER_DOCUMENT });

    getLatencyPerDocumentMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      'Latency',
      'Document',
      interval,
      timespan,
      percentile,
    )
      .then(latencyPerDocument => {
        dispatch({ type: SET_LATENCY_PER_DOCUMENT, latencyPerDocument: latencyPerDocument });
      })
      .catch(() => {
        dispatch({
          type: SET_LATENCY_PER_DOCUMENT,
          latencyPerDocument: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setDocumentsBreakDown = (
  timespan: string,
  interval: string,
): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_DOCUMENTS_BREAK_DOWN });
    getDocumentBreakDownMetric(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      interval,
      timespan,
    )
      .then(documentsBreakDown => {
        dispatch({ type: SET_DOCUMENTS_BREAK_DOWN, documentsBreakDown: documentsBreakDown });
      })
      .catch(() => {
        dispatch({
          type: SET_DOCUMENTS_BREAK_DOWN,
          documentsBreakDown: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        });
      });
  };
};

export const setKeyLevelError = (timeSpan: string): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_KEY_LEVEL_ERROR });
    getKeyLevelError(
      getState().tenantsList.activeTenant.id,
      getState().environmentList.activeEnvironment.indexId,
      timeSpan,
    )
      .then(keyLevelError => dispatch({ type: SET_KEY_LEVEL_ERROR, keyLevelError: keyLevelError }))
      .catch(() =>
        dispatch({
          type: SET_KEY_LEVEL_ERROR,
          keyLevelError: {
            series: [],
            cacheDetails: { lastUpdatedFrom: NaN, cachePeriod: CachePeriod.ExceededLimit },
          },
        }),
      );
  };
};
