import { ReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../../CrudAction.types';
import { updateSynonymSet, deleteSynonymSet } from '../../../../../restful-apis/customizations/synonymSet.api';
import {
  SynonymSet,
  DELETE_SYNONYM_SET,
  CREATE_OR_UPDATE_SYNONYM_SET,
} from '../../../../types/customizations/synonymSet.d';
import { DevOpsCommitMetadata } from '../../../../types/devOps';
import { getI18n } from 'react-i18next';

export const synonymSetUpdateAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  synonymSet: SynonymSet,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<SynonymSet>,
  onFailure?: ActionFailureHandler<SynonymSet>,
): ReduxCrudAction<SynonymSet> => {
  const i18n = getI18n();

  return {
    request: updateSynonymSet(tenantId, searchIndexId, searchInstanceId, synonymSet, devOpsCommitMetadata),
    postActionMapper: updatedSynonymSet => ({
      type: CREATE_OR_UPDATE_SYNONYM_SET,
      synonymSet: updatedSynonymSet,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.synonyms-update-progress-message', {
          synonymSetId: synonymSet.id,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.synonyms-update-success-message', {
          synonymSetId: synonymSet.id,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.synonyms-update-failure-message', {
          synonymSetId: synonymSet.id,
        }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, synonymSet),
  };
};

export const synonymSetCreateAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  synonymSet: SynonymSet,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<SynonymSet>,
  onFailure?: ActionFailureHandler<SynonymSet>,
): ReduxCrudAction<SynonymSet> => {
  const i18n = getI18n();

  return {
    request: updateSynonymSet(tenantId, searchIndexId, searchInstanceId, synonymSet, devOpsCommitMetadata),
    postActionMapper: createdSynonymSet => ({
      type: CREATE_OR_UPDATE_SYNONYM_SET,
      synonymSet: createdSynonymSet,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.synonyms-create-progress-message', {
          synonymSetId: synonymSet.id,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.synonyms-create-success-message', {
          synonymSetId: synonymSet.id,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.synonyms-create-failure-message', {
          synonymSetId: synonymSet.id,
        }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, synonymSet),
  };
};

export const synonymSetDeleteAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  synonymSetId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<SynonymSet>,
  onFailure?: ActionFailureHandler<SynonymSet>,
): ReduxCrudAction<SynonymSet> => {
  const i18n = getI18n();

  return {
    request: deleteSynonymSet(tenantId, searchIndexId, searchInstanceId, synonymSetId, devOpsCommitMetadata),
    postActionMapper: deletedSynonymSet => ({
      type: DELETE_SYNONYM_SET,
      id: deletedSynonymSet.id,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.synonyms-delete-progress-message', {
          synonymSetId: synonymSetId,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.synonyms-delete-success-message', {
          synonymSetId: synonymSetId,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.synonyms-delete-failure-message', {
          synonymSetId: synonymSetId,
        }),
      }),
    },
    onSuccess: res => onSuccess && onSuccess(res),
    onFailure: response => onFailure && onFailure(response, synonymSetId),
  };
};
