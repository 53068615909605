import {
  ConditionBlock,
  ConditionType,
  Condition,
  BoolCondition,
  StringSetCondition,
  StringCondition,
  NumericCondition,
  DateTimeCondition,
} from '../../../../utils/customizations/conditions';
import _ from 'lodash';

export const isValidCondition = (condition: ConditionType): boolean => {
  let isValid = false;
  switch (condition._type) {
    case Condition.BoolCondition:
      const boolCondition = condition as BoolCondition;
      isValid = !!boolCondition.field && !!boolCondition.value;
      break;
    case Condition.StringCondition:
      const stringCondition = condition as StringCondition;
      isValid = !!stringCondition.field && !!stringCondition.value;
      break;
    case Condition.NumericCondition:
      const numericCondition = condition as NumericCondition;
      isValid = !!numericCondition.field && !!numericCondition.value;
      break;
    case Condition.DateTimeCondition:
      const dateTimeCondition = condition as DateTimeCondition;
      isValid = !!dateTimeCondition.field && !!dateTimeCondition.value;
      break;
    case Condition.StringSetCondition:
      const stringSetCondition = condition as StringSetCondition;
      isValid = !!stringSetCondition.field && !!stringSetCondition.values && !!stringSetCondition.values.length;
      break;
  }
  return isValid;
};

export const getValidConditionBlock = (conditionBlock?: ConditionBlock): ConditionBlock | undefined => {
  const validatedCondition: ConditionBlock | undefined = conditionBlock && {
    ...conditionBlock,
    conditions: conditionBlock.conditions.filter(cnd => isValidCondition(cnd as ConditionType)),
  };

  return validatedCondition && validatedCondition.conditions.length > 0 ? validatedCondition : undefined;
};

export const getKeylessConditionBlock = (conditionBlock: ConditionBlock): ConditionBlock => {
  const keylessConditionBlock = _.cloneDeep(conditionBlock);
  delete keylessConditionBlock.key;
  keylessConditionBlock.conditions = keylessConditionBlock.conditions.map(cnd => {
    const keylessCondition = { ...cnd };
    delete keylessCondition.key;
    return keylessCondition;
  });
  return keylessConditionBlock;
};
