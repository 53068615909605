import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';
import { Action } from 'redux';
import { getCustomMlSearchConfig as getCustomMlSearchConfigService } from '../../../restful-apis/customizations/customMlSearchConfig.api';
import {
  SET_CUSTOM_ML_SEARCH_CONFIG,
  RESET_CUSTOM_ML_SEARCH_CONFIG,
  CustomMlSearchConfig,
} from '../../types/customizations/customMlSearchConfig.d';
import { CustomMlSearchConfigUpdateAction } from '../utils/crud-actions/custom-ml-search-config/CustomMlSearchConfigCrudActions';
import { ActionSuccessHandler, ActionFailureHandler, ExecuteReduxCrudAction } from '../utils';

export const getCustomMlSearchConfig = (): ThunkAction<void, AppState, null, Action> => {
  return (dispatch, getState) => {
    const state = getState();
    const tenantId = state.tenantsList.activeTenant.id;
    const indexId = state.searchIndex.searchIndex.id;
    const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
    getCustomMlSearchConfigService(tenantId, indexId, searchInstanceId)
      .then(searchConfig => {
        dispatch({
          type: SET_CUSTOM_ML_SEARCH_CONFIG,
          customMlSearchConfig: searchConfig,
        });
      })
      .catch(error => console.error(error));
  };
};

export const resetCustomMlSearchConfig = (): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    dispatch({ type: RESET_CUSTOM_ML_SEARCH_CONFIG });
  };
};

export const updateCustomMlSearchConfig = (
  customMlSearchConfig: CustomMlSearchConfig,
  commitMessage?: string,
  onSuccess?: ActionSuccessHandler<CustomMlSearchConfig>,
  onFailure?: ActionFailureHandler<CustomMlSearchConfig>,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenantsList.activeTenant.id;
  const indexId = state.searchIndex.searchIndex.id;
  const searchInstanceId = state.searchInstanceList.activeSearchInstance.id;
  const devOpsCommitMetadata = state.searchIndex.searchIndex.devOpsEnabled
    ? { author: state.user.email, message: commitMessage || 'Update machine learning search config' }
    : undefined;

  dispatch(
    ExecuteReduxCrudAction(
      CustomMlSearchConfigUpdateAction(
        tenantId,
        indexId,
        searchInstanceId,
        customMlSearchConfig,
        devOpsCommitMetadata,
        onSuccess,
        onFailure,
      ),
    ),
  );
};
