import { GeneralDetailsEditorStyles, GeneralDetailsEditorStyleProps } from './GeneralDetails.types';

export const styles = (props: GeneralDetailsEditorStyleProps): GeneralDetailsEditorStyles => {
  const { className } = props;
  return {
    root: ['mbc-GeneralDetailsEditor', {}, className],
    generalDetailsContainer: [
      'mbc-GeneralDetailsEditor-generalDetailsContainer',
      {
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      className,
    ],
    schedule: [
      'mbc-GeneralDetailsEditor-schedule',
      {
        borderTop: '1px solid #EDEBE9',
        borderBottom: '1px solid #EDEBE9',
        padding: '16px 0',
      },
    ],
    addNewCondition: [
      'mbc-ConditionBlockEditor-Header',
      {
        border: '1px solid #EDEBE9',
      },
    ],
  };
};
