import { AvailableTutorials, START_TUTORIAL } from '../../store/types/tutorial.d';
import { AppState } from '../../store/reducers';
import { Environment } from '../../store/types/environmentList.d';
import store from '../../store';
import { TutorialConfigItem, TutorialsId, TutorialSteps } from './Tutorials.types';
import { MbcRouteKey, MbcRouteHash, SearchInstanceRouteKey } from '../../router/MbcRouter.types';
import { showToast } from '../../store/actions/toastNotificationActions';
import { NotificationStatus } from '../../store/types/notificationCenter.d';
import { TOGGLE_IS_PANE_EXAPNDED } from '../../store/types/customizations/queryTester.d';
import { generateUniqueIdentifier, distinct, flat } from '../../utils';
import { MbcEnvironmentRouter } from '../../router/MbcEnvironmentRouter/MbcEnvironmentRouter';
import { MbcAdminRouter } from '../../router/MbcAdminRouter/MbcAdminRouter';
import { MbcSearchInstanceRouter } from '../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { getSearchInstances } from '../../restful-apis/customizations/searchInstance.api';

const getRedirectEnvironment = (state: AppState): Environment => {
  // Since env. list that is in the store doesn't take into consideration which roles user has access to
  const environmentList = Object.values(state.environmentList.environmentIdMapper);
  const userPrimaryEmail = state.user.email;
  const roleIdMapper = state.roleList.roleIdMapper;
  const userSecondaryEmail = state.user.secondaryEmail;
  const environmentIdMapper = state.environmentList.environmentIdMapper;

  const userRoles = Object.values(roleIdMapper).filter(
    r => !!r.users.find(u => u.email === userPrimaryEmail || u.email === userSecondaryEmail),
  );

  const hasAccessToAllEnvironments = !!userRoles.find(r => r.hasAccessToAllEnvironments);

  const roleEnvironmentList =
    hasAccessToAllEnvironments || userRoles.length === 0
      ? environmentList
      : distinct(flat(userRoles.map(r => r.environmentList)), (a, b) => a === b).map(e => environmentIdMapper[e]);

  let envsWithIndex: Environment[] = roleEnvironmentList.filter(env => !!env.indexId);
  if (envsWithIndex.length > 0) {
    return envsWithIndex[0];
  } else {
    store.dispatch(
      showToast({
        id: generateUniqueIdentifier(),
        title: 'There must be an environment with an index to show the tutorial',
        status: NotificationStatus.Failed,
      }),
    );
    return state.environmentList.activeEnvironment;
  }
};

const validSearchInstance = (
  tenantId: string,
  environment: Environment,
  searchInstancesIdList: string[],
  routeKey: SearchInstanceRouteKey,
): boolean => {
  if (searchInstancesIdList.length === 0) {
    store.dispatch(
      showToast({
        id: generateUniqueIdentifier(),
        title: 'There must be an environment with an index to show the tutorial',
        status: NotificationStatus.Failed,
      }),
    );
    return false;
  }

  if (searchInstancesIdList.includes('default')) {
    MbcSearchInstanceRouter(tenantId, environment.id, 'default')(routeKey).browserPush();
  } else {
    MbcSearchInstanceRouter(tenantId, environment.id, searchInstancesIdList[0])(routeKey).browserPush();
  }
  return true;
};

const getEnvSearchInstancesIdList = (
  tenantId: string,
  environment: Environment,
  tutorial: AvailableTutorials,
  routeKey: SearchInstanceRouteKey,
) =>
  getSearchInstances(tenantId, environment.indexId)
    .then(searchInstances => {
      let searchInstancesIdList = searchInstances.map(searchInstance => searchInstance.id);
      if (validSearchInstance(tenantId, environment, searchInstancesIdList, routeKey)) {
        store.dispatch({
          type: START_TUTORIAL,
          tutorial: tutorial,
        });
      }
    })
    .catch(() =>
      showToast({
        id: generateUniqueIdentifier(),
        title: 'Smothing went wrong, please try again.',
        status: NotificationStatus.Failed,
      }),
    );

const searchInstanceTutorialsStartup = (
  routeKey: SearchInstanceRouteKey,
  tutorialToStart: AvailableTutorials,
  state: AppState,
) => {
  if (state.router.location.state.mbcRouteKey === routeKey) {
    store.dispatch({
      type: START_TUTORIAL,
      tutorial: tutorialToStart,
    });
  } else if (state.searchInstanceList.activeSearchInstance.id) {
    MbcSearchInstanceRouter(
      state.tenantsList.activeTenant.id,
      state.environmentList.activeEnvironment.id,
      state.searchInstanceList.activeSearchInstance.id,
    )(routeKey).browserPush();
    store.dispatch({
      type: START_TUTORIAL,
      tutorial: tutorialToStart,
    });
  } else {
    let searchInstancesIdList: string[] = [];
    if (!!state.environmentList.activeEnvironment.id) {
      if (!!state.environmentList.activeEnvironment.indexId) {
        searchInstancesIdList = Object.keys(state.searchInstanceList.searchInstanceIdMapper);
        if (searchInstancesIdList.length === 0) {
          getEnvSearchInstancesIdList(
            state.tenantsList.activeTenant.id,
            state.environmentList.activeEnvironment,
            tutorialToStart,
            routeKey,
          );
        } else if (
          validSearchInstance(
            state.tenantsList.activeTenant.id,
            state.environmentList.activeEnvironment,
            searchInstancesIdList,
            routeKey,
          )
        ) {
          store.dispatch({
            type: START_TUTORIAL,
            tutorial: tutorialToStart,
          });
        }
      } else {
        store.dispatch(
          showToast({
            id: generateUniqueIdentifier(),
            title: "Can't start tutorial, current environment doesn't have a schema defined",
            status: NotificationStatus.Failed,
          }),
        );
      }
    } else {
      let redirectEnv = getRedirectEnvironment(state);
      getEnvSearchInstancesIdList(state.tenantsList.activeTenant.id, redirectEnv, tutorialToStart, routeKey);
    }
  }
};

export const tutorialConfig: TutorialConfigItem = {
  [AvailableTutorials.None]: {
    id: TutorialsId.None,
    NumOfSteps: 0,
    Steps: [],
    NextTutorial: AvailableTutorials.None,
    TutorialStartUpPrep: () => {
      store.dispatch({
        type: START_TUTORIAL,
        tutorial: AvailableTutorials.None,
      });
    },
  },

  [AvailableTutorials.EnvironmentList]: {
    id: TutorialsId.EnvironmentList,
    NumOfSteps: 3,
    Steps: [TutorialSteps.EnvList, TutorialSteps.EnvProducts, TutorialSteps.EnvName],
    NextTutorial: AvailableTutorials.Catalog,
    TutorialStartUpPrep: (state: AppState) => {
      if (state.router.location.state.mbcRouteKey !== MbcRouteKey.EnvironmentList) {
        MbcAdminRouter(state.tenantsList.activeTenant)(MbcRouteKey.EnvironmentList).browserPush();
      }

      store.dispatch({
        type: START_TUTORIAL,
        tutorial: AvailableTutorials.EnvironmentList,
      });
    },
  },

  [AvailableTutorials.Catalog]: {
    id: TutorialsId.Catalog,
    NumOfSteps: 6,
    Steps: [
      TutorialSteps.SchemaEditButton,
      TutorialSteps.SchemaAddField,
      TutorialSteps.SchemaFieldRow,
      TutorialSteps.SchemaDetection,
      TutorialSteps.SchemaDetectionPanel,
      TutorialSteps.SchemaDetectionNotification,
    ],
    NextTutorial: AvailableTutorials.QueryTester,
    TutorialStartUpPrep: (state: AppState) => {
      let redirectEnvironment: Environment = state.environmentList.activeEnvironment;
      if (state.router.location.hash !== MbcRouteHash.Schema) {
        if (!!state.environmentList.activeEnvironment.indexId) {
          MbcEnvironmentRouter(state.tenantsList.activeTenant, redirectEnvironment)(
            MbcRouteKey.SchemaManagement,
          ).browserPush();
        } else {
          if (!!state.environmentList.activeEnvironment.id) {
            store.dispatch(
              showToast({
                id: generateUniqueIdentifier(),
                title: "Can't start tutorial, current environment doesn't have a schema defined",
                status: NotificationStatus.Failed,
              }),
            );
          } else {
            redirectEnvironment = getRedirectEnvironment(state);
            if (!!redirectEnvironment) {
              MbcEnvironmentRouter(state.tenantsList.activeTenant, redirectEnvironment)(
                MbcRouteKey.SchemaManagement,
              ).browserPush();
            }
          }
        }
      }
      if (!!redirectEnvironment.indexId) {
        store.dispatch({
          type: START_TUTORIAL,
          tutorial: AvailableTutorials.Catalog,
        });
      }
    },
  },
  [AvailableTutorials.QueryTester]: {
    id: TutorialsId.QueryTester,
    NumOfSteps: 5,
    Steps: [
      TutorialSteps.QueryTesterSideNav,
      TutorialSteps.QueryTesterSearch,
      TutorialSteps.QueryTesterTreatmentPanel,
      TutorialSteps.QueryTesterPreviewTreatmentToggle,
      TutorialSteps.QueryTesterNextSteps,
    ],
    NextTutorial: AvailableTutorials.BusinessRulesFull,
    TutorialStartUpPrep: (state: AppState) => {
      let redirectEnvironment: Environment = state.environmentList.activeEnvironment;
      if (state.router.location.state.mbcRouteKey !== MbcRouteKey.QueryTester) {
        if (!!state.environmentList.activeEnvironment.indexId) {
          MbcEnvironmentRouter(state.tenantsList.activeTenant, redirectEnvironment)(
            MbcRouteKey.QueryTester,
          ).browserPush();
        } else {
          if (!!state.environmentList.activeEnvironment.id) {
            store.dispatch(
              showToast({
                id: generateUniqueIdentifier(),
                title: "Can't start tutorial, current environment doesn't have a schema defined",
                status: NotificationStatus.Failed,
              }),
            );
          } else {
            redirectEnvironment = getRedirectEnvironment(state);
            if (!!redirectEnvironment) {
              MbcEnvironmentRouter(state.tenantsList.activeTenant, redirectEnvironment)(
                MbcRouteKey.QueryTester,
              ).browserPush();
            }
          }
        }
      }
      if (!!redirectEnvironment.indexId) {
        if (!store.getState().queryTester.isPaneExpanded) {
          store.dispatch({ type: TOGGLE_IS_PANE_EXAPNDED });
        }
        store.dispatch({
          type: START_TUTORIAL,
          tutorial: AvailableTutorials.QueryTester,
        });
      }
    },
  },
  [AvailableTutorials.BusinessRulesFull]: {
    id: TutorialsId.BusinessRules,
    NumOfSteps: 5,
    Steps: [
      TutorialSteps.BusinessRulesSideNav,
      TutorialSteps.BusinessRulesList,
      TutorialSteps.BusinessRulesEditorSearch,
      TutorialSteps.BusinessRulesRightPane,
      TutorialSteps.BusinessRulesTreatmentToggle,
    ],
    NextTutorial: AvailableTutorials.SynonymsFull,
    TutorialStartUpPrep: (state: AppState) => {
      searchInstanceTutorialsStartup(MbcRouteKey.BusinessRuleList, AvailableTutorials.BusinessRulesFull, state);
    },
  },
  [AvailableTutorials.SynonymsFull]: {
    id: TutorialsId.Synonyms,
    NumOfSteps: 4,
    Steps: [
      TutorialSteps.SynonymsSideNav,
      TutorialSteps.SynonymsList,
      TutorialSteps.SynonymsEditorRightPane,
      TutorialSteps.SynonymsTerms,
    ],
    NextTutorial: AvailableTutorials.None,
    TutorialStartUpPrep: (state: AppState) => {
      searchInstanceTutorialsStartup(MbcRouteKey.SynonymSetList, AvailableTutorials.SynonymsFull, state);
    },
  },
};
