import {
  FetchRequest,
  ExecuteRequestInterceptors,
  ExecuteResponseInterceptors,
  FetchResponse,
} from './http-interceptors';

const DefaultHeaders = new Headers({
  'Content-Type': 'application/json',
});

export const DEMO_AUTH_INFO = 'DEMO_AUTH_INFO';

export interface TenantAuthInfo {
  tenantId: string;
}

export interface IndexAuthInfo extends TenantAuthInfo {
  indexId: string;
}

export type AuthInfo = TenantAuthInfo | IndexAuthInfo | typeof DEMO_AUTH_INFO;

export interface HttpRequest {
  url: string;
  headers?: Headers;
  authInfo?: AuthInfo;
  isPublic?: boolean;
  supressNotificationOnFailure?: boolean;
}

export interface UpdateHttpRequest extends HttpRequest {
  body: any;
}

export default class HttpClient {
  static resolveHttpResponse<T>(response: FetchResponse): Promise<T> {
    if (!response.content.ok) {
      throw response;
    }
    return Promise.resolve(response.content.json() as Promise<T>);
  }

  static fetchResponse<T>(request: FetchRequest, supressNotificationOnFailure: boolean = false): Promise<T> {
    return ExecuteRequestInterceptors(request).then(request =>
      fetch(request.input, request.init)
        .then(response =>
          ExecuteResponseInterceptors({
            content: response,
            supressNotificationOnFailure: supressNotificationOnFailure,
          }),
        )
        .then(response => HttpClient.resolveHttpResponse<T>(response)),
    );
  }

  /**
   * Constructs a get http request that interprets the body as `T` and returns the response in `T`.
   *
   * @param url     The endpoint URL.
   *
   *
   * @return An `T` of the response, with the response body as an `T`.
   */
  static get<T>(request: HttpRequest): Promise<T> {
    const requestHeaders = request.headers ? request.headers : DefaultHeaders;
    return HttpClient.fetchResponse(
      {
        input: request.url,
        init: { method: 'GET', headers: requestHeaders },
        authInfo: request.authInfo,
        isPublic: request.isPublic,
      },
      request.supressNotificationOnFailure,
    );
  }

  /**
   * Constructs a post http request that returns the response in `T`.
   *
   * @param url     The endpoint URL.
   * @param body    The HTTP body to send with the request.
   *
   *
   * @return An `T` of the response, with the response body as an `T`.
   */
  static post<T>(request: UpdateHttpRequest): Promise<T> {
    const requestHeaders = request.headers ? request.headers : DefaultHeaders;
    return HttpClient.fetchResponse(
      {
        input: request.url,
        init: {
          method: 'POST',
          headers: requestHeaders,
          body: requestHeaders.get('Content-Type') === 'application/json' ? JSON.stringify(request.body) : request.body, // Coordinate the body type with 'Content-Type'
        },
        authInfo: request.authInfo,
        isPublic: request.isPublic,
      },
      request.supressNotificationOnFailure,
    );
  }

  /**
   * Constructs a put http request that returns the response in `T`.
   *
   * @param url     The endpoint URL.
   * @param body    The HTTP body to send with the request.
   *
   *
   * @return An `T` of the response, with the response body as an `T`.
   */
  static put<T>(request: UpdateHttpRequest): Promise<T> {
    const requestHeaders = request.headers ? request.headers : DefaultHeaders;
    return HttpClient.fetchResponse(
      {
        input: request.url,
        init: {
          method: 'PUT',
          headers: requestHeaders,
          body: requestHeaders.get('Content-Type') === 'application/json' ? JSON.stringify(request.body) : request.body, // Coordinate the body type with 'Content-Type'
        },
        authInfo: request.authInfo,
        isPublic: request.isPublic,
      },
      request.supressNotificationOnFailure,
    );
  }

  /**
   * Constructs a delete http request that returns the response in `T`.
   *
   * @param url     The endpoint URL.
   * @param options The HTTP options to send with the request.
   *
   *
   * @return An `T` of the response, with the response body as an `T`.
   */
  static delete<T>(request: UpdateHttpRequest): Promise<T> {
    const requestHeaders = request.headers ? request.headers : DefaultHeaders;
    return HttpClient.fetchResponse(
      {
        input: request.url,
        init: {
          method: 'DELETE',
          headers: requestHeaders,
          body: requestHeaders.get('Content-Type') === 'application/json' ? JSON.stringify(request.body) : request.body, // Coordinate the body type with 'Content-Type'
        },
        authInfo: request.authInfo,
        isPublic: request.isPublic,
      },
      request.supressNotificationOnFailure,
    );
  }
}
