import { IRefObject, IStyleFunctionOrObject, IStyle, ITheme, Omit } from 'office-ui-fabric-react';
import { UserState } from '../../../store/types/user.d';
import { UserEmailMapper } from '../../../store/types/userList';

export enum PersonaType {
  WithDetails,
  HidePersonaDetails,
}

export interface UserPersonaBaseProps {
  className?: string;

  componentRef?: IRefObject<HTMLDivElement>;

  theme?: ITheme;

  styles?: IStyleFunctionOrObject<UserPersonaStyleProps, UserPersonaStyles>;
}

export interface ConnectedUserPersonaProps extends UserPersonaProps {
  updateTenantUserImageAction: Function;
  updateTenantUserMetaDataAction: Function;
  userEmailMapperStoreState: UserEmailMapper;
}

export interface UserPersonaProps extends UserPersonaBaseProps {
  key: string;
  personaType: PersonaType;
  ariaLabel: string;
  useNameAsPrimaryText?: boolean;
  user: Required<Pick<UserState, 'email'>> & Partial<Omit<UserState, 'email'>>;
}

export type UserPersonaStyleProps = Required<Pick<UserPersonaProps, 'theme'>> & Pick<UserPersonaBaseProps, 'className'>;

export interface UserPersonaStyles {
  root: IStyle;
  personaDetails: IStyle;
}
