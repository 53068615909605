import React from 'react';
import { Stack, Shimmer, ShimmerElementType } from 'office-ui-fabric-react';

export const ShimmeredAggregatedDataList = () => {
  return (
    <Stack horizontal tokens={{ childrenGap: '22' }} style={{ width: '100%' }}>
      <Stack tokens={{ childrenGap: '28' }} style={{ width: '50%' }}>
        <Shimmer shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 6 }]} />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.gap, width: '15%' },
            { type: ShimmerElementType.line, height: 8 },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.gap, width: '15%' },
            { type: ShimmerElementType.line, height: 8 },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.gap, width: '15%' },
            { type: ShimmerElementType.line, height: 8 },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.gap, width: '15%' },
            { type: ShimmerElementType.line, height: 8 },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.gap, width: '15%' },
            { type: ShimmerElementType.line, height: 8 },
          ]}
        />
      </Stack>
      <Stack tokens={{ childrenGap: '28' }} style={{ width: '50%' }}>
        <Shimmer shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 6 }]} />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 8 },
            { type: ShimmerElementType.gap, width: '15%' },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 8 },
            { type: ShimmerElementType.gap, width: '15%' },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 8 },
            { type: ShimmerElementType.gap, width: '15%' },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 8 },
            { type: ShimmerElementType.gap, width: '15%' },
          ]}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 8 },
            { type: ShimmerElementType.gap, width: '15%' },
          ]}
        />
      </Stack>
    </Stack>
  );
};
