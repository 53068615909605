import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../../../router/MbcRouter.base';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { ActiveUrlRedirectSetLoading } from '../../../../components/Loading';
import { useUrlRedirectSetRouter } from '../../../../store/hooks/use-router/useSearchInstanceRouter';
import {
  setActiveUrlRedirectSet,
  resetActiveUrlRedirectSet,
} from '../../../../store/actions/customizations/urlRedirectSetActions';
import { useEnvironmentPermissions } from '../../../../store/hooks/use-user-permissions/UseEnvironmentPermissions';

export const UrlRedirectSetProxy = (props: RouteComponentProps<{ urlRedirectSetId: string }>) => {
  const dispatch = useDispatch();

  const MbcUrlRedirectSetRouteMap = useUrlRedirectSetRouter();
  const environmentPermissions = useEnvironmentPermissions();
  const isUrlRedirectSetListLoading = useSelector(state => state.urlRedirectSet.isLoading);

  useEffect(() => {
    dispatch(setActiveUrlRedirectSet(props.match.params.urlRedirectSetId));
    return () => {
      dispatch(resetActiveUrlRedirectSet());
    };
  }, [dispatch, isUrlRedirectSetListLoading, props.match.params.urlRedirectSetId]);

  return (
    <ActiveUrlRedirectSetLoading>
      <MbcRouter
        userPermissions={environmentPermissions}
        routes={[MbcUrlRedirectSetRouteMap(MbcRouteKey.UrlRedirectSetEditor)]}
      />
    </ActiveUrlRedirectSetLoading>
  );
};
