import { Action } from 'redux';
import { SearchIndex } from './searchIndex.d';

export const SET_DETECTED_INDEX_SCHEMA = 'SET_DETECTED_INDEX_SCHEMA';
export const ADD_DETECTED_INDEX_SCHEMA = 'ADD_DETECTED_INDEX_SCHEMA';
export const RESET_DETECTED_INDEX_SCHEMA = 'RESET_DETECTED_INDEX_SCHEMA';

export interface SchemaDetectionMapper {
  [key: string]: SchemaDetection;
}

export type SchemaDetection = Pick<SearchIndex, 'fields' | 'transformationScript'> & {
  key: string;
  environmentId: string;
};

export interface SchemaDetectionState {
  activeSchemaDetection: SchemaDetection;
  schemaDetectionMapper: SchemaDetectionMapper;
}

interface ResetDetectedIndexSchema extends Action<typeof RESET_DETECTED_INDEX_SCHEMA> {
  key: string;
}

interface SetDetectedIndexSchema extends Action<typeof SET_DETECTED_INDEX_SCHEMA> {
  key: string;
}

interface AddDetectedIndexSchema extends Action<typeof ADD_DETECTED_INDEX_SCHEMA> {
  schemaDetection: SchemaDetection;
}

export type SchemaDetectionStateActionTypes =
  | SetDetectedIndexSchema
  | AddDetectedIndexSchema
  | ResetDetectedIndexSchema;
