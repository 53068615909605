import React from 'react';
import { classNamesFunction, Stack, IChoiceGroupOption, ChoiceGroup } from 'office-ui-fabric-react';
import {
  CustomMLRankerEditorProps,
  CustomMLRankerEditorStyleProps,
  CustomMLRankerEditorStyles,
} from './CustomMLRankerEditor.types';
import { DefaultRankerId, DefaultRankerText } from '../../../../store/types/customizations/customMlSearchConfig.d';

const getClassNames = classNamesFunction<CustomMLRankerEditorStyleProps, CustomMLRankerEditorStyles>();

export const CustomMLRankerEditorBase = (props: CustomMLRankerEditorProps) => {
  const { styles, theme, className, rankerList, selectedRanker, onUpdate } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const getRankerOptions = (): IChoiceGroupOption[] => {
    return [{ key: DefaultRankerId, text: DefaultRankerText }]
      .concat(
        rankerList.map(ranker => {
          return { key: ranker, text: ranker };
        }),
      )
      .map(opt => {
        return {
          ...opt,
          styles: {
            root: {
              padding: '15px 0',
              borderBottom: '1px solid #EDEBE9',
            },
          },
        };
      });
  };
  return (
    <Stack className={classNames.root}>
      <ChoiceGroup
        label={'Ranker selection'}
        styles={classNames.subComponentStyles.selectionContainer}
        selectedKey={selectedRanker}
        options={getRankerOptions()}
        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) =>
          option && onUpdate(option.key as string)
        }
      />
    </Stack>
  );
};
