import { useCallback } from 'react';
import { useSelector } from '..';
import { MbcCustomMLRouter, MbcCustomMLEvaluationRouter } from '../../../router/MbcCustomMLRouter/MbcCustomMLRouter';

export const useCustomMLRouter = (searchModelId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeSearchModel = useSelector(state => state.searchModels.activeSearchModel);
  const searchModelMapper = useSelector(state => state.searchModels.searchModelMapper);

  return useCallback(
    MbcCustomMLRouter(activeTenant, !!searchModelId ? searchModelMapper[searchModelId] : activeSearchModel),
    [activeSearchModel, activeTenant, searchModelId, searchModelMapper],
  );
};

export const useCustomMLEvaluationRouter = (evaluationJobId: string = '') => {
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeSearchModel = useSelector(state => state.searchModels.activeSearchModel);
  const activeEvaluationJob = useSelector(state => state.evaluationJobs.activeEvaluationJob);
  const evaluationJobMapper = useSelector(state => state.evaluationJobs.evaluationJobMapper);

  return useCallback(
    MbcCustomMLEvaluationRouter(
      activeTenant,
      activeSearchModel,
      !!evaluationJobId ? evaluationJobMapper[evaluationJobId] : activeEvaluationJob,
    ),
    [activeEvaluationJob, activeSearchModel, activeTenant, evaluationJobId, evaluationJobMapper],
  );
};
