import { Action } from 'redux';

export const SET_INDEX_PRODUCT_COUNT = 'SET_INDEX_PRODUCT_COUNT';
export const LOADING_INDEX_PRODUCT_COUNT = 'LOADING_INDEX_PRODUCT_COUNT';

export const SET_INDEX_REQUESTS_PER_DAY = 'SET_INDEX_REQUESTS_PER_DAY';
export const LOADING_INDEX_REQUESTS_PER_DAY = 'LOADING_INDEX_REQUESTS_PER_DAY';

export const SET_INDEX_DOCUMENTS_PER_SECOND = 'SET_INDEX_DOCUMENTS_PER_SECOND';
export const LOADING_INDEX_DOCUMENTS_PER_SECOND = 'LOADING_INDEX_DOCUMENTS_PER_SECOND';

export const SET_LATENCY_PER_DOCUMENT = 'SET_LATENCY_PER_DOCUMENT';
export const LOADING_LATENCY_PER_DOCUMENT = 'LOADING_LATENCY_PER_DOCUMENT';

export const SET_INDEX_AVG_DPS = 'SET_INDEX_AVG_DPS';
export const LOADING_INDEX_AVG_DPS = 'LOADING_INDEX_AVG_DPS';

export const SET_PROVIDED_DOCUMENTS = 'SET_PROVIDED_DOCUMENTS';
export const LOADING_PROVIDED_DOCUMENTS = 'LOADING_PROVIDED_DOCUMENTS';

export const SET_DOCUMENTS_BREAK_DOWN = 'SET_DOCUMENTS_BREAK_DOWN';
export const LOADING_DOCUMENTS_BREAK_DOWN = 'LOADING_DOCUMENTS_BREAK_DOWN';

export const SET_FEATURE_COVERAGE = 'SET_FEATURE_COVERAGE';
export const LOADING_FEATURE_COVERAGE = 'LOADING_FEATURE_COVERAGE';

export const SET_KEY_LEVEL_ERROR = 'SET_KEY_LEVEL_ERROR';
export const LOADING_KEY_LEVEL_ERROR = 'LOADING_KEY_LEVEL_ERROR';

// Values of enum matter when determening cache period for biaxial charts
export enum CachePeriod {
  RealTime = 0,
  Minutes = 1,
  Hours = 2,
  Days = 3,
  ExceededLimit = 4,
}

export interface Point {
  xValue: any;
  yValues: any[];
}

export interface BreakDownPoint {
  xValue: string;
  successfulDocuments: number;
  failedDocuments: number;
}

export interface ErrorDetails {
  numberOfOccurance: number;
  errorMessage: string;
}

export interface ErrorReportPoint {
  date: string;
  errorDetails: ErrorDetails[];
}

export interface CacheDetails {
  lastUpdatedFrom: number;
  cachePeriod: CachePeriod;
}

export interface MetricModel {
  series: Point[];
  cacheDetails: CacheDetails;
}

export interface ErrorReportModel {
  series: ErrorReportPoint[];
  cacheDetails: CacheDetails;
}

export interface DocumentBreakDownModel {
  series: BreakDownPoint[];
  cacheDetails: CacheDetails;
}

export interface NamePercentage {
  name: string;
  percentage: number;
}

export interface FeatureCoverageModel {
  series: NamePercentage[];
  cacheDetails: CacheDetails;
}

export interface MetricCardData {
  value: number;
  cacheDetails: CacheDetails;
}

export interface InsightsState {
  indexProductCount: MetricModel;
  isIndexProductCountLoading: boolean;

  indexAvgDPS: MetricCardData;
  isIndexAvgDPSLoading: boolean;

  indexDocumentsPerSecond: MetricModel;
  isIndexDocumentsPerSecondLoading: boolean;

  indexRequestsPerDay: MetricModel;
  isIndexRequestsPerDayLoading: boolean;

  latencyPerDocument: MetricModel;
  isLatencyPerDocumentLoading: boolean;

  documentsBreakDown: DocumentBreakDownModel;
  isDocumentsBreakDownLoading: boolean;

  providedDocuments: MetricModel;
  isProvidedDocumentsLoading: boolean;

  featureCoverage: FeatureCoverageModel;
  isFeatureCoverageLoading: boolean;

  keyLevelError: ErrorReportModel;
  isKeyLevelErrorLoading: boolean;
}

export interface SetIndexProductCount extends Action<typeof SET_INDEX_PRODUCT_COUNT> {
  indexProductCount: MetricModel;
}

export interface SetIndexDocumentsPerSecond extends Action<typeof SET_INDEX_DOCUMENTS_PER_SECOND> {
  indexDocumentsPerSecond: MetricModel;
}

export interface SetLatencyPerDocument extends Action<typeof SET_LATENCY_PER_DOCUMENT> {
  latencyPerDocument: MetricModel;
}

export interface SetIndexRequestsPerDay extends Action<typeof SET_INDEX_REQUESTS_PER_DAY> {
  indexRequestsPerDay: MetricModel;
}

export interface SetIndexAvgDPS extends Action<typeof SET_INDEX_AVG_DPS> {
  indexAvgDPS: MetricCardData;
}

export interface SetDocumentsBreakDown extends Action<typeof SET_DOCUMENTS_BREAK_DOWN> {
  documentsBreakDown: DocumentBreakDownModel;
}

export interface SetProvidedDocuments extends Action<typeof SET_PROVIDED_DOCUMENTS> {
  providedDocuments: MetricModel;
}
export interface SetFeatureCoverage extends Action<typeof SET_FEATURE_COVERAGE> {
  featureCoverage: FeatureCoverageModel;
}

export interface SetKeyLevelError extends Action<typeof SET_KEY_LEVEL_ERROR> {
  keyLevelError: ErrorReportModel;
}

export type InsightsStateActionTypes =
  | SetIndexProductCount
  | Action<typeof LOADING_INDEX_PRODUCT_COUNT>
  | SetIndexRequestsPerDay
  | Action<typeof LOADING_INDEX_REQUESTS_PER_DAY>
  | SetIndexDocumentsPerSecond
  | Action<typeof LOADING_INDEX_DOCUMENTS_PER_SECOND>
  | SetLatencyPerDocument
  | Action<typeof LOADING_LATENCY_PER_DOCUMENT>
  | SetIndexAvgDPS
  | Action<typeof LOADING_INDEX_AVG_DPS>
  | SetProvidedDocuments
  | Action<typeof LOADING_PROVIDED_DOCUMENTS>
  | SetDocumentsBreakDown
  | Action<typeof LOADING_DOCUMENTS_BREAK_DOWN>
  | SetFeatureCoverage
  | Action<typeof LOADING_FEATURE_COVERAGE>
  | SetKeyLevelError
  | Action<typeof LOADING_KEY_LEVEL_ERROR>;
