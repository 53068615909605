import React, { useCallback } from 'react';
import { MbcRouter } from '../../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../../router/MbcRouter.types';
import { useSearchInstanceRouter } from '../../../store/hooks/use-router/useSearchInstanceRouter';
import { useProxyRouter } from '../../../store/hooks/use-router/useProxyRouter';

export const SynonymSetListProxy = () => {
  const MbcSearchInstanceRouteMap = useSearchInstanceRouter();
  const MbcProxyRouteMapper = useProxyRouter();

  const getSynonymSetRoutes = useCallback(
    (): MbcRouteBase[] => [
      MbcSearchInstanceRouteMap(MbcRouteKey.SynonymSetList),
      MbcSearchInstanceRouteMap(MbcRouteKey.NewSynonymSet),
      MbcProxyRouteMapper(MbcRouteKey.SynonymSetProxy),
    ],
    [MbcProxyRouteMapper, MbcSearchInstanceRouteMap],
  );

  return <MbcRouter routes={getSynonymSetRoutes()} />;
};
