import React from 'react';
import { useSelector } from 'react-redux';
import { TotalPercentagContent } from '../../../../store/types/analytics';
import { AppState } from '../../../../store/reducers';
import { PieChart, NameValuePercentage } from '../../../../components/common/charts/PieChart';
import { ChartContentCard } from '../Common/ChartContentCard';
import { getPieChartColors } from '../../../../components/common/charts/chartsUtils';
import numeral from 'numeral';
import { ServingRegionsProps } from './ServingRegions.types';
import { classNamesFunction } from 'office-ui-fabric-react';
import { ServingRegionsStyleProps, ServingRegionsStyles } from '../ServingRegions/ServingRegions.types';
import { useDispatch } from '../../../../store/hooks';
import { SetRegionsDistribution } from '../../../../store/actions/analyticsActions';

const getClassNames = classNamesFunction<ServingRegionsStyleProps, ServingRegionsStyles>();

export const ServingRegionsBase = (props: ServingRegionsProps) => {
  const { t, onRegionInvoked, theme, className, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });
  const dispatch = useDispatch();

  const isRegionsDistributionLoading = useSelector((state: AppState) => state.analytics.isRegionsDistributionLoading);
  const regionsDistribution = useSelector((state: AppState) => state.analytics.regionsDistribution);

  const regionsDistributionGrouped =
    regionsDistribution.length > 8 ? [...regionsDistribution.slice(0, 8)] : regionsDistribution;
  return (
    <div className={classNames.root}>
      <ChartContentCard
        exportData={{ fileName: t('analytics.serving-regions-chart.title'), data: regionsDistribution }}
        chart={
          <PieChart
            isLoading={isRegionsDistributionLoading}
            pieColors={getPieChartColors(227, 49, regionsDistributionGrouped.map(region => region.content))}
            noDataLabel={t('common.charts-no-data')}
            isLoadingLabel={t('common.charts-loading')}
            onClick={(item: NameValuePercentage) => {
              return onRegionInvoked && onRegionInvoked(item.name);
            }}
            data={regionsDistributionGrouped.map(data => ({
              name: data.content,
              value: data.count,
              percentage: data.percentage,
            }))}
          />
        }
        title={t('analytics.serving-regions-chart.title')}
        isLoadedAndEmpty={!isRegionsDistributionLoading && !regionsDistribution.length}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
        ) =>
          dispatch(
            SetRegionsDistribution(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
          )
        }
        cardExpandDetails={
          !isRegionsDistributionLoading && regionsDistribution.length > 8
            ? {
                footerLinkText: t('analytics.see-more'),
                modalTitle: t('analytics.serving-regions-chart.title'),
                items: regionsDistribution,
                columns: [
                  {
                    key: 'Serving region name',
                    name: t('analytics.serving-regions-chart.serving-region-name-label'),
                    fieldName: 'content',
                    minWidth: 150,
                    isResizable: true,
                    isMultiline: false,
                    onRender: (item?: TotalPercentagContent): React.ReactNode =>
                      item && (
                        <div
                          data-selection-disabled={true}
                          title={item.content}
                          className={classNames.modalFirstColumn}
                        >
                          {item.content}
                        </div>
                      ),
                  },
                  {
                    key: 'count',
                    name: t('analytics.serving-regions-chart.count-label'),
                    fieldName: 'count',
                    minWidth: 100,
                    isMultiline: false,
                  },
                  {
                    key: 'percentage',
                    name: t('analytics.serving-regions-chart.percentage-label'),
                    fieldName: 'percentage',
                    minWidth: 100,
                    isMultiline: false,
                    // eslint-disable-next-line react/display-name
                    onRender: (item: TotalPercentagContent) => (
                      <span>{numeral(item.percentage).format('0.0000') + '%'}</span>
                    ),
                  },
                ],
              }
            : undefined
        }
      />
    </div>
  );
};
