import HttpClient from './http-client/httpClient';
import { mapCommitListDtoToModel, mapDeploymentListDtoToModel } from './mappers/devops.mapper';
import {
  Commit as CommitModel,
  Deployment as DeploymentModel,
  DevOpsCommitMetadata,
  DeploymentStatus,
} from '../store/types/devOps';
import { CommitList as CommitListDto, DeploymentList as DeploymentListDto } from './dto/devops/devops';

export function getDevOpsCommitHeaders(
  devOpsCommitMetadata: DevOpsCommitMetadata,
  includeContentType: boolean = true,
): Headers {
  const headers = new Headers({
    'x-ms-author': devOpsCommitMetadata.author,
    'x-ms-author-message': devOpsCommitMetadata.message,
  });
  includeContentType && headers.append('Content-Type', 'application/json');
  return headers;
}

function getDevopsBaseUri(tenantId: string, indexId: string): string {
  return `https://commerce.bing.com/api/devops/v1/${tenantId}/indexes/${indexId}`;
}

function getCommitsUri(tenantId: string, indexId: string): string {
  return `${getDevopsBaseUri(tenantId, indexId)}/scm`;
}

function getDeploymentsUri(tenantId: string, indexId: string): string {
  return `${getDevopsBaseUri(tenantId, indexId)}/deployments`;
}

export function getCommits(tenantId: string, indexId: string): Promise<CommitModel[]> {
  return HttpClient.get<CommitListDto>({
    url: getCommitsUri(tenantId, indexId),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapCommitListDtoToModel);
}

export function getDeployments(tenantId: string, indexId: string): Promise<DeploymentModel[]> {
  return HttpClient.get<DeploymentListDto>({
    url: getDeploymentsUri(tenantId, indexId),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapDeploymentListDtoToModel);
}

export function deployCommit(
  tenantId: string,
  indexId: string,
  commitId: string,
  devOpsCommitMetadata: DevOpsCommitMetadata,
): Promise<DeploymentModel> {
  return HttpClient.post<DeploymentListDto>({
    url: `${getDeploymentsUri(tenantId, indexId)}/${commitId}`,
    headers: getDevOpsCommitHeaders(devOpsCommitMetadata),
    authInfo: { tenantId: tenantId, indexId: indexId },
    body: {},
  })
    .then(mapDeploymentListDtoToModel)
    .then(deployments => deployments[0]);
}

export function redeploy(
  tenantId: string,
  indexId: string,
  deplymentId: string,
  devOpsCommitMetadata: DevOpsCommitMetadata,
): Promise<DeploymentModel> {
  return HttpClient.put<DeploymentListDto>({
    url: `${getDeploymentsUri(tenantId, indexId)}/${deplymentId}`,
    headers: getDevOpsCommitHeaders(devOpsCommitMetadata),
    authInfo: { tenantId: tenantId, indexId: indexId },
    body: {},
  })
    .then(mapDeploymentListDtoToModel)
    .then(deployments => deployments[0]);
}

export function getDeploymentById(tenantId: string, indexId: string, deplymentId: string): Promise<DeploymentModel[]> {
  return HttpClient.get<DeploymentListDto>({
    url: `${getDeploymentsUri(tenantId, indexId)}/${deplymentId}`,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapDeploymentListDtoToModel);
}

export function getDeploymentStatus(tenantId: string, indexId: string, deplymentId: string): Promise<DeploymentStatus> {
  return HttpClient.get<DeploymentListDto>({
    url: `${getDeploymentsUri(tenantId, indexId)}/${deplymentId}`,
    authInfo: { tenantId: tenantId, indexId: indexId },
  })
    .then(mapDeploymentListDtoToModel)
    .then(deplyments => deplyments[0].status);
}

export function getCommitZip(tenantId: string, indexId: string, commitId: string): Promise<string | undefined> {
  return HttpClient.get<CommitListDto>({
    url: `${getCommitsUri(tenantId, indexId)}/${commitId}/zip`,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(response => response.commits[0].zipUrl);
}

export function createCommit(
  tenantId: string,
  indexId: string,
  zipFile: File,
  devOpsCommitMetadata: DevOpsCommitMetadata,
): Promise<CommitModel> {
  return HttpClient.post<CommitListDto>({
    url: getCommitsUri(tenantId, indexId),
    body: zipFile,
    headers: getDevOpsCommitHeaders(devOpsCommitMetadata, false),
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  })
    .then(mapCommitListDtoToModel)
    .then(commits => commits[0]);
}
