import { IStyle, IStyleFunctionOrObject, ITheme } from 'office-ui-fabric-react';
import { WithTranslation } from 'react-i18next';

export interface BusinessRule {
  focus: () => void;
}

export const BusinessRulePanel = {
  General: 'General',
  Promote: 'Promote',
  Demote: 'Demote',
  Filter: 'Filter',
  Banner: 'Banner',
};
export type BusinessRulePanel = 'General' | 'Promote' | 'Demote' | 'Filter' | 'Banner';

export interface BusinessRuleProps extends React.HTMLAttributes<HTMLDivElement>, WithTranslation {
  componentRef?: (componentRef: BusinessRule) => void;
  theme?: ITheme;
  styles?: IStyleFunctionOrObject<BusinessRuleStyleProps, BusinessRuleStyles>;
}

export interface BusinessRuleStyleProps {
  theme: ITheme;
  className?: string;
}

export interface BusinessRuleStyles {
  root: IStyle;
  tutorialSubheader: IStyle;
}
