import {
  SynonymSet as SynonymSetDto,
  SynonymSetList as SynonymSetListDto,
  SynonymSetDeleteResponse,
} from '../../dto/customizations/synonymSet';
import { SynonymSet as SynonymSetModel } from '../../../store/types/customizations/synonymSet';

export const mapSynonymSetDtoToModel = (synonmSetDto: SynonymSetDto): SynonymSetModel => {
  return {
    id: synonmSetDto.synonymId,
    synonymList: synonmSetDto.synonyms,
  };
};

export const mapSynonymSetModelToDto = (synonmSetModel: SynonymSetModel): SynonymSetDto => {
  return {
    synonymId: synonmSetModel.id,
    synonyms: synonmSetModel.synonymList,
  };
};

export const mapSynonymSetListDtoToModel = (synonmSetListDto: SynonymSetListDto): SynonymSetModel[] => {
  return synonmSetListDto.values.map(mapSynonymSetDtoToModel);
};

export const mapDeletedSynonymSetDtoToModel = (deleteResponse: SynonymSetDeleteResponse): SynonymSetModel => {
  return {
    id: deleteResponse.synonymId,
    synonymList: [],
  };
};
