import React from 'react';
import { Icon, Stack, TooltipHost, classNamesFunction } from 'office-ui-fabric-react';
import { ProvisioningState } from '../../../store/types/searchIndex.d';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import {
  EnvironmentStatusProps,
  EnvrionmentStatusStyleProps,
  EnvrionmentStatusStyles,
} from './EnvironmentStatus.types';
import { environmentStatusIcon } from './EnvironmentStatus.config';

const getClassNames = classNamesFunction<EnvrionmentStatusStyleProps, EnvrionmentStatusStyles>();

export const EnvironmentStatusBase = (props: EnvironmentStatusProps) => {
  const { searchIndexId, styles, theme, className } = props;

  const searchIndexIdMapper = useSelector((state: AppState) => state.searchIndex.searchIndexIdMapper);
  const envIdMapper = useSelector((state: AppState) => state.environmentList.environmentIdMapper);

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  let statusIconProps = environmentStatusIcon()[ProvisioningState.Unknown];
  if (searchIndexId) {
    const searchIndex = searchIndexIdMapper[searchIndexId];

    // TODO: change this when we get the index status from the admin service
    var env = Object.values(envIdMapper).filter(env => env.indexId === searchIndexId)[0];
    if (env && env.isIndexSoftDeleted) {
      statusIconProps = environmentStatusIcon()['Deleting'];
    } else if (searchIndex.id) {
      statusIconProps = environmentStatusIcon()[searchIndex.status];
    }
  }
  return (
    <Stack horizontal verticalAlign={'baseline'} tokens={{ childrenGap: 's2' }}>
      <Icon
        iconName={statusIconProps.iconName}
        className={classNames.instanceStatusIcon}
        styles={{ root: { color: statusIconProps.color } }}
      />
      <TooltipHost content={statusIconProps.tooltipContent}>
        <div>{statusIconProps.displayName}</div>
      </TooltipHost>
    </Stack>
  );
};
