import { ModelParamsPreviewStyles, ModelParamsPreviewStyleProps } from './ModelParamsPreview.types';

export const styles = (props: ModelParamsPreviewStyleProps): ModelParamsPreviewStyles => ({
  borderLine: [
    'mbc-border-line',
    {
      borderBottom: '1px solid rgb(243, 242, 241)',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  ],
  overFlow: [
    'mbc-value-overflow',
    {
      width: 100,
      textAlign: 'end',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  ],
  datasetName: [
    'mbc-card-dataset',
    {
      fontSize: 13,
      lineHeight: 20,
      fontWeight: 500,
      wordBreak: 'break-all',
      color: props.theme.palette.themePrimary,
    },
  ],
  parameterValue: [
    'mbc-card-value',
    {
      fontSize: 12,
      lineHeight: 20,
      fontWeight: 500,
      color: props.theme.palette.neutralSecondaryAlt,
    },
  ],
  parameterLabel: [
    'mbc-card-label',
    {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: 500,
      color: '#171717',
    },
  ],
  parametersBtn: [
    'mbc-define-params',
    {
      selectors: {
        '.ms-Button': {
          fontSize: 14,
          lineHeight: 21,
          textAlign: 'center',
          letterSpacing: '-0.08px',
          color: props.theme.palette.themePrimary,
          borderColor: props.theme.palette.themePrimary,
        },
        '.ms-Button:hover': {
          color: props.theme.palette.themePrimary,
          backgroundColor: props.theme.palette.neutralLighterAlt,
        },
        '.ms-Button.is-disabled': {
          border: 'none',
          color: props.theme.palette.neutralSecondaryAlt,
          backgroundColor: props.theme.palette.neutralLighter,
        },
      },
    },
  ],
  subComponentStyles: {
    card: {},
  },
});
