import { useState, useEffect } from 'react';
import { useSelector } from '../../../store/hooks';
import { Product } from '../../../store/types/tenantsList.d';
import { MbcRouteKey } from '../../../router/MbcRouter.types';

export const useMbcProducts = (): [Product, React.Dispatch<React.SetStateAction<Product>>] => {
  const mbcRouteKey = useSelector<MbcRouteKey>(state => state.router.location.state.mbcRouteKey);

  const getProductFromRoute = (mbcRouteKey: MbcRouteKey): Product => {
    switch (mbcRouteKey) {
      case MbcRouteKey.QueryTester:
      case MbcRouteKey.SynonymSetList:
      case MbcRouteKey.NewSynonymSet:
      case MbcRouteKey.SynonymSetEditor:
      case MbcRouteKey.UrlRedirectSetList:
      case MbcRouteKey.NewUrlRedirectSet:
      case MbcRouteKey.UrlRedirectSetEditor:
      case MbcRouteKey.NewBusinessRule:
      case MbcRouteKey.BusinessRuleEditor:
      case MbcRouteKey.BusinessRuleList:
      case MbcRouteKey.CustomMLRanker:
      case MbcRouteKey.SearchAnalytics:
        return Product.TextSearch;
      default:
        return Product.Unknown;
    }
  };

  const [MbcProduct, setMbcProduct] = useState<Product>(getProductFromRoute(mbcRouteKey));

  useEffect(() => {
    setMbcProduct(getProductFromRoute(mbcRouteKey));
    return () => {};
  }, [mbcRouteKey]);

  return [MbcProduct, setMbcProduct];
};
