import React from 'react';
import {
  ProductSupportPanelItemProps,
  ListItem,
  ProductSupportPanelItemStyleProps,
  ProductSupportPanelItemStyles,
} from './ProductSupportPanelItem.types';
import { List, Link, Icon, Separator, classNamesFunction } from 'office-ui-fabric-react';

const getClassNames = classNamesFunction<ProductSupportPanelItemStyleProps, ProductSupportPanelItemStyles>();

export const ProductSupportPanelItemBase = (props: ProductSupportPanelItemProps): JSX.Element => {
  const { className, styles, theme, componentRef, title, subtitle, listItems } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const onRenderCellInternal = (item: ListItem | undefined) => {
    if (!!item) {
      return (
        <>
          <Link href={item.href} onClick={item.onClick} className={classNames.itemLink} target="_blank">
            {item.iconName && <Icon iconName={item.iconName} />}
            <div className={classNames.itemLinkText}>{item.text}</div>
          </Link>
          <Separator />
        </>
      );
    }
  };

  return (
    <div ref={componentRef} className={classNames.root}>
      <div className={classNames.itemTitle}>{title}</div>
      <div className={classNames.itemSubtitle}>{subtitle}</div>
      <List items={listItems} onRenderCell={onRenderCellInternal} />
    </div>
  );
};
