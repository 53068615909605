import { BoostItem, SearchResult as SearchResultModel } from '../store/types/searchDemo';
import {
  getMockedCompetitiveSearchResult,
  getMockedPersonalizedSearchResult,
  getMockedPromotedSearchResult,
  getMockedSearchResultWithQueryAlteration,
} from '../config/mockedSearchResults.config';

import HttpClient from './http-client/httpClient';
import { ProductSearchResult } from '../store/types/productSearch';
import { SearchIndex } from '../store/types/searchIndex';
import { SearchResults } from './dto/search/search';
import { getAnyFilterableSearchField } from '../pages/EnvironmentList/Environment/ProductManagement/ProductManagement.utils';
import { mapSearchProductListDtoToModel } from './mappers/productSearchMapper';

function getIndexProbeUri(tenantId: string, indexId: string, query?: string): string {
  return `https://commerce.bing.com/api/search/v1/${tenantId}/indexes/${indexId}?traffictype=portal${
    query ? `&q=${query}` : ''
  }`;
}

export function getDocumentsWithRange(
  tenantId: string,
  indexId: string,
  fieldKey: string | undefined,
  query: string | undefined,
  skipNum: number,
  topNum?: number,
): Promise<SearchResults> {
  const activeQueryBody = query
    ? {
        matchAll: query,
      }
    : {
        filter: {
          _type: 'StringCondition',
          field: fieldKey,
          operator: 'ne',
          value: 'foobar',
        },
      };

  return HttpClient.post<SearchResults>({
    url: getIndexProbeUri(tenantId, indexId),
    supressNotificationOnFailure: true,
    body: {
      items: {
        select: ['*'],
        skip: skipNum,
        top: topNum,
      },
      Query: activeQueryBody,
    },
    authInfo: { tenantId: tenantId, indexId: indexId },
  });
}

export const searchIndexProducts = (
  tenantId: string,
  searchIndex: SearchIndex,
  query: string | undefined,
  skip: number,
  top: number,
): Promise<ProductSearchResult> =>
  getDocumentsWithRange(tenantId, searchIndex.id, getAnyFilterableSearchField(searchIndex), query, skip, top).then(
    dto => mapSearchProductListDtoToModel(skip, dto, searchIndex),
  );

// /**
//  * Constructs a get http request that the documents from the index.
//  *
//  * @param query      The query to lookup.
//  *
//  * @return SearchResults.
//  */
// function getDemoSearchResultsInternal(
//   indexId: string,
//   query: string,
//   allowQueryAlteration: boolean = true,
//   features?: string,
//   boostItem?: BoostItem,
//   personalizeForFemale: boolean = false,
// ): Promise<SearchResultModel> {
//   const tenantId = '9396ad26-84dc-4bad-eb01-08d741f1b7a5';
//   return HttpClient.post<SearchResults>({
//     url: getIndexProbeUri(tenantId, indexId, undefined, features),
//     body: mapSearchRequestToDTO(query, 0, 6, allowQueryAlteration, personalizeForFemale, boostItem),
//     authInfo: DEMO_AUTH_INFO,
//   }).then(mapSearchResultsDtoToModel);
// }

export function getPromotedSearchResultWithQueryAlteration(
  // query: string,
  boostItem: BoostItem,
  personalizeForFemale?: boolean,
) {
  // return getDemoSearchResultsInternal(
  //   '53f4aadb-8959-4040-b1a7-53f3cfa7ad6b',
  //   query,
  //   true,
  //   undefined,
  //   boostItem,
  //   personalizeForFemale,
  // );
  return new Promise(resolve =>
    resolve(personalizeForFemale ? getMockedPersonalizedSearchResult() : getMockedPromotedSearchResult(boostItem)),
  );
}

export function getCompetitiveSearchResult(
  query: string,
  // features: string,
  // allowQueryAlteration: boolean,
): Promise<SearchResultModel> {
  // return getDemoSearchResultsInternal('92b28817-a8de-44eb-a6a8-cf84a7e0d7b7', query, allowQueryAlteration, features);
  return new Promise(resolve => resolve(getMockedCompetitiveSearchResult(query)));
}

export function getSearchResultWithQueryAlteration(
  query: string,
  // personalizeForFemale: boolean = false,
): Promise<SearchResultModel> {
  // return getDemoSearchResultsInternal(
  //   '53f4aadb-8959-4040-b1a7-53f3cfa7ad6b',
  //   query,
  //   true,
  //   undefined,
  //   undefined,
  //   personalizeForFemale,
  // );
  return new Promise(resolve => resolve(getMockedSearchResultWithQueryAlteration(query)));
}
