import { EnvironmentListStyleProps, EnvironmentListStyles } from './EnvironmentList.types';

export const styles = (props: EnvironmentListStyleProps): EnvironmentListStyles => {
  return {
    root: ['mbc-environments-list', props.className],
    productsContainer: [
      'mbc-products-container',
      {
        background: props.tutorialActive ? props.theme.palette.white : undefined,
        height: '100%',
      },
    ],
    productsIcon: [
      'mbc-products-icon',
      {
        height: '100%',
        selectors: {
          '.ms-TooltipHost': {
            height: '100%',
            selectors: {
              '.ms-Button': {
                height: '100%',
                selectors: {
                  ':hover': {
                    background: props.isPanel ? undefined : props.theme.palette.neutralQuaternaryAlt,
                  },
                },
              },
              '.is-disabled': {
                background: 'unset',
                selectors: {
                  path: {
                    fill: props.theme.palette.neutralTertiary,
                  },
                },
              },
            },
          },
        },
      },
    ],
    addButton: [
      'mbc-add-button',
      {
        selectors: {
          '.is-disabled': {
            selectors: {
              '.ms-Button-icon': {
                color: props.theme.palette.neutralTertiaryAlt,
              },
              '.ms-Button-textContainer': {
                color: props.theme.palette.neutralTertiaryAlt,
              },
            },
          },
          '.ms-Button': {
            marginLeft: 16,
            backgroundColor: 'unset',
            selectors: {
              ':hover': {
                backgroundColor: props.theme.palette.neutralLighter,
                height: 32,
              },
            },
          },
          '.ms-Button-icon': {
            color: props.theme.palette.themePrimary,
            selectors: {
              ':hover': {
                color: props.theme.palette.themePrimary + '!important',
              },
            },
          },
          '.ms-Button-textContainer': {
            color: props.theme.palette.black,
          },
        },
      },
    ],
    nameColumnCell: [
      'mbc-name-column-cell',
      {
        display: 'inline',
        lineHeight: 38,
        width: 'inherit',
        padding: props.tutorialActive ? '12px 42px 12px 12px' : undefined,
        background: props.tutorialActive ? props.theme.palette.white : undefined,
        cursor: 'pointer',
      },
    ],
    nameColumnToolTip: [
      'mbc-name-column-tool-tip',
      {
        color: props.theme.palette.themePrimary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        selectors: {
          ':hover': {
            color: props.theme.palette.themeDarker,
            textDecoration: 'underline',
          },
        },
      },
    ],
  };
};
