import { QueryTesterCodeEditorStyles, QueryTesterCodeEditorStyleProps } from './QueryTesterCodeEditor.types';

export const styles = (props: QueryTesterCodeEditorStyleProps): QueryTesterCodeEditorStyles => {
  const { className } = props;
  return {
    root: ['mbc-QueryTesterCodeEditor', {}, className],
    codeContainer: [
      'mbc-QueryTesterCodeEditor-codeContainer',
      {
        height: 'calc(100vh - 265px)',
      },
    ],
    errors: [{ color: '#a4262c' }],
  };
};
