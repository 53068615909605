import {
  BusinessRule,
  SET_BUSINESS_RULE_LIST,
  SET_ACTIVE_BUSINESS_RULE,
  SET_BUSINESS_RULE_DETAILS,
  RESET_BUSINESS_RULE_LIST,
  RESET_ACTIVE_BUSINESS_RULE,
  DELETE_BUSINESS_RULE,
  LOADING_BUSINESS_RULE_LIST,
  LOADING_BUSINESS_RULE_DETAILS,
  CREATE_OR_UPDATE_BUSINESS_RULE,
  BusinessRuleState,
  BusinessRuleIdMapper,
  BusinessRuleActionTypes,
  POPULATE_ACTIVE_BUSINESS_RULE,
} from '../../types/customizations/businessRule.d';
import { configureDefaultObject } from '../../../utils';
import {
  ConditionBlock,
  createConditionBlock,
  createStringSetCondition,
} from '../../../utils/customizations/conditions';
import moment from 'moment';

export const getDefaultSearchRequestCondition = (): ConditionBlock => {
  return createConditionBlock({
    conditions: [
      createStringSetCondition({
        values: ['*'],
        field: 'query',
      }),
    ],
  });
};

export const createEmptyBusinessRule = (): BusinessRule => {
  return {
    id: '',
    name: '',
    enabled: false,
    lastModifiedTime: moment(),
    lastModifiedBy: '',
    searchRequestCondition: getDefaultSearchRequestCondition(),
  };
};

const createBusinessRuleIdMapper = (initialMapper: BusinessRuleIdMapper = {}) => {
  return configureDefaultObject<BusinessRuleIdMapper, BusinessRule>(initialMapper, createEmptyBusinessRule());
};

const initialState: BusinessRuleState = {
  isLoading: true,
  isRuleDetailsLoading: true,
  activeBusinessRule: createEmptyBusinessRule(),
  businessRuleIdMapper: createBusinessRuleIdMapper(),
};

export function businessRuleReducer(state = initialState, action: BusinessRuleActionTypes): BusinessRuleState {
  switch (action.type) {
    case SET_BUSINESS_RULE_LIST: {
      const businessRuleIdMapper = createBusinessRuleIdMapper({
        ...action.businessRuleList.reduce((mapper: BusinessRuleIdMapper, businessRule: BusinessRule) => {
          mapper[businessRule.id] = businessRule;
          return mapper;
        }, {}),
      });
      return {
        ...state,
        isLoading: false,
        businessRuleIdMapper: businessRuleIdMapper,
      };
    }
    case SET_ACTIVE_BUSINESS_RULE: {
      return {
        ...state,
        activeBusinessRule: state.businessRuleIdMapper[action.id],
      };
    }
    case LOADING_BUSINESS_RULE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOADING_BUSINESS_RULE_DETAILS: {
      return {
        ...state,
        isRuleDetailsLoading: true,
      };
    }
    case RESET_ACTIVE_BUSINESS_RULE: {
      return {
        ...state,
        isRuleDetailsLoading: true,
        activeBusinessRule: createEmptyBusinessRule(),
      };
    }
    case POPULATE_ACTIVE_BUSINESS_RULE: {
      return {
        ...state,
        activeBusinessRule: action.businessRule,
      };
    }
    case RESET_BUSINESS_RULE_LIST: {
      return {
        ...state,
        isLoading: true,
        isRuleDetailsLoading: true,
        businessRuleIdMapper: createBusinessRuleIdMapper(),
      };
    }
    case SET_BUSINESS_RULE_DETAILS: {
      const updatedBusinessRuleIdMapper = createBusinessRuleIdMapper({
        ...state.businessRuleIdMapper,
        [action.businessRule.id]: action.businessRule,
      });
      return {
        ...state,
        isRuleDetailsLoading: false,
        businessRuleIdMapper: updatedBusinessRuleIdMapper,
        activeBusinessRule:
          state.activeBusinessRule.id === action.businessRule.id ? action.businessRule : state.activeBusinessRule,
      };
    }
    case CREATE_OR_UPDATE_BUSINESS_RULE: {
      const updatedBusinessRuleIdMapper = createBusinessRuleIdMapper({
        ...state.businessRuleIdMapper,
        [action.businessRule.id]: action.businessRule,
      });
      return {
        ...state,
        businessRuleIdMapper: updatedBusinessRuleIdMapper,
      };
    }
    case DELETE_BUSINESS_RULE: {
      var updatedBusinessRuleIdMapper = createBusinessRuleIdMapper({ ...state.businessRuleIdMapper });
      delete updatedBusinessRuleIdMapper[action.id];

      return {
        ...state,
        businessRuleIdMapper: updatedBusinessRuleIdMapper,
      };
    }

    default:
      return state;
  }
}
