import React from 'react';
import { TenantsList } from './TenantsList';
import { CustomPanel } from '../../../../common/CustomPanel';
import { TenantsListPanelProps, TenantsListPanelStyleProps, TenantsListPanelStyles } from './TenantsListPanel.types';
import { classNamesFunction } from 'office-ui-fabric-react';

const getClassNames = classNamesFunction<TenantsListPanelStyleProps, TenantsListPanelStyles>();

export const TenantsListPanelBase = (props: TenantsListPanelProps) => {
  const { t, className, componentRef, styles, theme, isOpen, onDismiss } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  return (
    <div ref={componentRef} className={classNames.root}>
      <CustomPanel
        isOpen={isOpen}
        headerText={t('tenants-list-panel.header')}
        onDismiss={onDismiss}
        isHiddenOnDismiss={false}
        lightDismiss
      >
        {<TenantsList onClick={onDismiss} />}
      </CustomPanel>
    </div>
  );
};
