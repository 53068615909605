import {
  GET_BLOCKED_SUGGESTIONS,
  SET_BLOCKED_LIST_LOADING,
  ADD_BLOCKED_SUGGESTION,
  REMOVE_BLOCKED_SUGGESTION,
  BlockedSuggestionsStateActionTypes,
  BlockedSuggestionsListState,
  SET_ID_TO_RECENTLY_ADDED_BLOCKED_SUGGESTION,
} from '../types/autosuggest.d';

const initialState: BlockedSuggestionsListState = {
  isLoading: true,
  blockedSuggestions: [],
};

export function blockedSuggestionsReducer(
  state = initialState,
  action: BlockedSuggestionsStateActionTypes,
): BlockedSuggestionsListState {
  switch (action.type) {
    case GET_BLOCKED_SUGGESTIONS: {
      return {
        ...state,
        isLoading: false,
        blockedSuggestions: action.blockedSuggestions,
      };
    }
    case SET_BLOCKED_LIST_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_BLOCKED_SUGGESTION: {
      return {
        ...state,
        blockedSuggestions: [...state.blockedSuggestions, action.submittedBlockedSuggestion],
      };
    }
    case SET_ID_TO_RECENTLY_ADDED_BLOCKED_SUGGESTION: {
      let newBlockedList = state.blockedSuggestions;
      const index = state.blockedSuggestions.indexOf(action.submittedBlockedSuggestion);
      newBlockedList.splice(index, 1, {
        ...action.submittedBlockedSuggestion,
        id: action.newId,
      });
      return {
        ...state,
        blockedSuggestions: [...newBlockedList],
      };
    }
    case REMOVE_BLOCKED_SUGGESTION: {
      const index = state.blockedSuggestions.lastIndexOf(action.submittedBlockedSuggestion);
      let newBlockedList = state.blockedSuggestions;
      if (index > -1) newBlockedList.splice(index, 1);
      return {
        ...state,
        blockedSuggestions: [...newBlockedList],
      };
    }
    default:
      return state;
  }
}
