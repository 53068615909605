import React, { useState } from 'react';
import {
  classNamesFunction,
  PrimaryButton,
  Dropdown,
  Stack,
  IconButton,
  DefaultButton,
  TextField,
} from 'office-ui-fabric-react';
import {
  SearchModelEditorStyleProps,
  SearchModelEditorStyles,
  SearchModelEditorProps,
} from './SearchModelEditor.types';
import {
  onGetEntityNameErrorMsg,
  onGetEntityDescriptionErrorMsg,
  isInvalidEntityName,
  isInvalidEntityDescription,
} from '../SearchModel/SearchModel.utils';
import { SearchModel } from '../../../store/types/customML/searchModel.d';
import { TitledContentCard } from '../../../components/common/ContentCard';
import { createInitialSearchModel } from '../../../store/reducers/customML/searchModelReducer';

export const getClassNames = classNamesFunction<SearchModelEditorStyleProps, SearchModelEditorStyles>();

export function SearchModelEditorBase(props: SearchModelEditorProps) {
  const { t, styles, theme, onDismiss, onSubmit } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const [editableSearchModel, setEditableSearchModel] = useState<SearchModel>(createInitialSearchModel());

  const isInvalidForm = () =>
    isInvalidEntityName(editableSearchModel.name) || isInvalidEntityDescription(editableSearchModel.description);

  return (
    <TitledContentCard
      styles={classNames.subComponentStyles.card}
      title={t('custom-ml.create-model.create-modal-title')}
      actions={<IconButton key="cancel" onClick={() => onDismiss()} iconProps={{ iconName: 'Cancel' }} />}
      body={
        <Stack horizontal wrap horizontalAlign={'center'} verticalAlign={'stretch'} tokens={{ childrenGap: 16 }}>
          <Stack grow disableShrink tokens={{ childrenGap: 16 }}>
            <TextField
              required
              validateOnLoad={false}
              onGetErrorMessage={onGetEntityNameErrorMsg}
              className={classNames.input}
              label={t('custom-ml.create-model.name-label')}
              placeholder={t('custom-ml.create-model.name-placeholder')}
              value={editableSearchModel.name}
              onChange={(ev, value) =>
                value !== undefined && setEditableSearchModel(state => ({ ...state, name: value }))
              }
            />
            <Dropdown
              className={classNames.input}
              required
              defaultSelectedKey={editableSearchModel.feature}
              label={t('custom-ml.create-model.feature-label')}
              placeholder={t('custom-ml.create-model.feature-placeholder')}
              options={[
                {
                  key: t('custom-ml.create-model.feature-search')!,
                  text: t('custom-ml.create-model.feature-search'),
                  disabled: false,
                },
                {
                  key: t('custom-ml.create-model.feature-recommendations')!,
                  text: t('custom-ml.create-model.feature-recommendations'),
                  disabled: true,
                },
              ]}
              onChange={(ev, value) =>
                value !== undefined && setEditableSearchModel(state => ({ ...state, feature: value.text }))
              }
            />
            <Dropdown
              className={classNames.input}
              required
              label={t('custom-ml.create-model.type-label')}
              defaultSelectedKey={editableSearchModel.modelType}
              placeholder={t('custom-ml.create-model.type-placeholder')}
              options={[
                {
                  key: 'Light GBM ranker',
                  text: t('custom-ml.create-model.type-value'),
                  disabled: false,
                },
              ]}
              onChange={(ev, value) =>
                value !== undefined && setEditableSearchModel(state => ({ ...state, type: value.text }))
              }
            />
          </Stack>
          <TextField
            required
            multiline
            value={editableSearchModel.description}
            validateOnLoad={false}
            onGetErrorMessage={onGetEntityDescriptionErrorMsg}
            className={classNames.multiLineInput}
            label={t('custom-ml.create-model.description-label')}
            placeholder={t('custom-ml.create-model.description-placeholder')}
            onChange={(ev, value) =>
              value !== undefined && setEditableSearchModel(state => ({ ...state, description: value }))
            }
          />
        </Stack>
      }
      footer={
        <div className={classNames.footer}>
          <PrimaryButton
            key="create"
            disabled={isInvalidForm()}
            onClick={() => {
              onSubmit(editableSearchModel);
              onDismiss();
            }}
          >
            {t('custom-ml.jobs.create')}
          </PrimaryButton>
          <DefaultButton key="cancel" onClick={() => onDismiss()}>
            {t('custom-ml.jobs.cancel')}
          </DefaultButton>
        </div>
      }
    />
  );
}
