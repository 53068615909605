import { TextField } from 'office-ui-fabric-react';
import React from 'react';
import { GeneralSectionContentProps } from './GeneralSectionContent.types';

export const GeneralSectionContentBase = (props: GeneralSectionContentProps) => {
  const { name, onNameChange, t, disabled } = props;

  return (
    <div data-test="token-editor-general-section">
      <TextField
        required
        label={t('token-editor.general-section.name-label')}
        value={name}
        onChange={onNameChange}
        disabled={disabled}
      />
    </div>
  );
};
