import React, { Component } from 'react';
import SearchDemo, { Scenario } from '../../../common/search-demo/searchDemo';
import styles from './PersonalizationB.module.scss';
import { getPersonalizationSearchResult } from '../../../../../store/actions/searchDemoActions';
import { SearchResult } from '../../../../../store/types/searchDemo';
import { connect } from 'react-redux';

interface PersonalizationBProps {
  getPersonalizationSearchResult: Function;
  personalizationBSearchResults: SearchResult;
  minHeight: number;
  componentRef: React.RefObject<HTMLDivElement>;
}

export class PersonalizationBBase extends Component<PersonalizationBProps> {
  render() {
    return (
      <div
        ref={this.props.componentRef}
        className={styles.wrapper}
        style={{ minHeight: this.props.minHeight > 0 ? this.props.minHeight : '100vh' }}
      >
        <SearchDemo
          defaultQuery={'shoes'}
          scenario={Scenario.Personalization}
          searchDemoTitle={'Search with personalization'}
          isPersonaMale={false}
          searchResultLabel={
            <div>
              {`Showing results `}
              <span className={styles.targetQuery}>{'personalized for Linda'}</span>
            </div>
          }
          SearchResultItems={this.props.personalizationBSearchResults.searchResultItems}
          onChangeQuery={(query: string) => this.props.getPersonalizationSearchResult(query)}
          showPoweredBy={true}
        ></SearchDemo>
      </div>
    );
  }
}

export default connect(
  undefined,
  { getPersonalizationSearchResult },
)(PersonalizationBBase);
