import { IRefObject, ITheme, IStyleFunctionOrObject, IStyle, IGroup } from 'office-ui-fabric-react';
import { WithTranslation } from 'react-i18next';
import { ViewMode } from '..';
import { IndexField } from '../../../../../store/types/searchIndex.d';

export interface GroupExtended extends IGroup {
  shouldRender: boolean;
}

export interface SchemaFilters {
  [key: string]: any[];
}

export enum FilterableFields {
  Searchable = 'Searchable',
  Retrievable = 'Retrievable',
  Filterable = 'Filterable',
  Facetable = 'Facetable',
  Sortable = 'Sortable',
  Type = 'type',
}

/**
 * schema header base props
 */
export interface SchemaListHeaderBaseProps extends WithTranslation {
  /**
   * Theme provided by the Higher Order Component
   */
  theme?: ITheme;

  /**
   * Style function to be passed in to override the themed or default styles
   */
  styles?: IStyleFunctionOrObject<SchemaListHeaderStyleProps, SchemaListHeaderStyles>;

  /**
   * Optional class name to add to the root element.
   */
  className?: string;

  /**
   * Optional callback to access the ISchemaListHeader interface. Use this instead of ref for accessing
   * the public methods and properties of the component.
   */
  componentRef?: IRefObject<HTMLDivElement>;
}

export interface ActionProps {
  onClick?: () => void;
  disabled?: boolean;
}

export interface SchemaListActions {
  upload: ActionProps;
  edit: ActionProps;
  addRow: ActionProps;
}

/**
 * Extended schema header props
 */
export interface SchemaListHeaderProps extends SchemaListHeaderBaseProps {
  indexFields: IndexField[];
  actions: SchemaListActions;
  schemaViewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
  // Callback function for search or filter
  onChange: (newGroups: GroupExtended[]) => void;
  onSchemaTutorialStepEnd: () => void;
}

/**
 * schema header style props, enabling summary changes rendering styles
 */
export type SchemaListHeaderStyleProps = Required<Pick<SchemaListHeaderBaseProps, 'theme'>> &
  Pick<SchemaListHeaderBaseProps, 'className'> & {
    isTutorialActive: boolean;
  };

/**
 * schema header styles
 */
export interface SchemaListHeaderStyles {
  /**
   * Fabric schema header root styles
   */
  root: IStyle;

  action: IStyle;
}
