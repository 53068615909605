import {
  LOADING_ACTIVE_SEARCH_INDEX,
  LOADING_SEARCH_INDEX_LIST,
  RESET_ACTIVE_SEARCH_INDEX,
  RESET_SEARCH_INDEX_LIST,
  SAVE_ACTIVE_SEARCH_INDEX,
  SET_ACTIVE_SEARCH_INDEX,
  SET_SEARCH_INDEX_LIST,
  SearchIndex,
  SearchIndexIdMapper,
  SearchIndexState,
  SearchIndexStateActionTypes,
  createInitialSearchIndex,
} from '../types/searchIndex.d';

import { configureDefaultObject } from '../../utils';

const createSearchIndexIdMapper = (initialMapper: SearchIndexIdMapper = {}) => {
  return configureDefaultObject<SearchIndexIdMapper, SearchIndex>(initialMapper, createInitialSearchIndex());
};

const initialState: SearchIndexState = {
  isLoading: true,
  isSearchIndexListLoading: true,
  editableSearchIndexSet: new Set(),
  searchIndex: createInitialSearchIndex(),
  searchIndexIdMapper: createSearchIndexIdMapper(),
};

export function searchIndexReducer(state = initialState, action: SearchIndexStateActionTypes): SearchIndexState {
  switch (action.type) {
    case LOADING_ACTIVE_SEARCH_INDEX: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOADING_SEARCH_INDEX_LIST: {
      return {
        ...state,
        isSearchIndexListLoading: true,
      };
    }
    case SET_SEARCH_INDEX_LIST: {
      const searchIndexIdMapper = createSearchIndexIdMapper({
        ...action.searchIndexList.reduce((mapper: SearchIndexIdMapper, searchIndex: SearchIndex) => {
          if (searchIndex.id) {
            mapper[searchIndex.id] = searchIndex;
          }
          return mapper;
        }, {}),
      });

      return {
        ...state,
        isSearchIndexListLoading: false,
        searchIndexIdMapper: searchIndexIdMapper,
      };
    }
    case SET_ACTIVE_SEARCH_INDEX: {
      const searchIndex = state.searchIndexIdMapper[action.searchIndexId];
      if (searchIndex) {
        return {
          ...state,
          isLoading: false,
          searchIndex: {
            ...searchIndex,
            transformationScript: action.transformationScript,
          },
        };
      } else return { ...state };
    }
    case RESET_ACTIVE_SEARCH_INDEX: {
      return {
        ...state,
        isLoading: false,
        searchIndex: createInitialSearchIndex(),
      };
    }
    case SAVE_ACTIVE_SEARCH_INDEX: {
      const updatedSearchIndexIdMapper = createSearchIndexIdMapper({
        ...state.searchIndexIdMapper,
        [action.searchIndex.id]: action.searchIndex,
      });
      const updatedSearchIndexSet = new Set(state.editableSearchIndexSet);
      updatedSearchIndexSet.delete(state.searchIndex.id);

      return {
        ...state,
        isLoading: false,
        searchIndex: action.searchIndex,
        editableSearchIndexSet: updatedSearchIndexSet,
        searchIndexIdMapper: updatedSearchIndexIdMapper,
      };
    }
    case RESET_SEARCH_INDEX_LIST: {
      return {
        ...state,
        isSearchIndexListLoading: true,
        searchIndexIdMapper: createSearchIndexIdMapper(),
      };
    }

    default:
      return state;
  }
}
