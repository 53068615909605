import {
  ADD_BREADCRUMB_ITEM,
  REMOVE_BREADCRUMB_ITEM,
  CLEAR_BREADCRUMB_ITEMS,
  UPDATE_BREADCRUMB_ITEM,
  BreadcrumbItem,
} from '../types/breadcrumbs.d';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';

export const addBreadcrumbItem = (
  breadcrumbItem: BreadcrumbItem,
): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: ADD_BREADCRUMB_ITEM,
    breadcrumbItem: breadcrumbItem,
  });
};

export const updateBreadcrumbItem = (
  breadcrumbItem: BreadcrumbItem,
): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: UPDATE_BREADCRUMB_ITEM,
    breadcrumbItem: breadcrumbItem,
  });
};

export const removeBreadcrumbItem = (
  breadcrumbItemKey: string,
): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: REMOVE_BREADCRUMB_ITEM,
    breadcrumbItemKey: breadcrumbItemKey,
  });
};

export const clearBreadcrumbItems = (): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: CLEAR_BREADCRUMB_ITEMS,
  });
};
