import { MbcNavBarStyleProps, MbcNavBarStyles } from './MbcNavBar.types';

export const styles = (props: MbcNavBarStyleProps): MbcNavBarStyles => {
  return {
    root: [
      'mbc-mbc-nav-bar',
      {
        height: '100%',
        boxSizing: 'border-box',
        selectors: {
          '.ms-ProgressIndicator-itemProgress': {
            padding: 0,
            position: 'absolute',
            width: '100%',
            zIndex: 1001, // make it above page content
          },
        },
      },
      props.className,
    ],
    navBarContent: [
      'mbc-nav-bar-content',
      {
        height: props.mediumScreen ? 96 : 48,
        display: 'grid',
        gridTemplateColumns: props.mediumScreen ? '50% 50%' : '254px 320px calc(100% - 574px)',
        background: props.theme.palette.themePrimary,
        margin: 0,
        padding: 0,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
    ],

    pivotContainer: [
      'mbc-pivot-container',
      {
        paddingLeft: props.mediumScreen && !props.smallScreen ? 28 : 16,
        gridRowStart: props.mediumScreen ? '2' : '1',
        gridColumnStart: props.mediumScreen ? '1' : '2',
      },
    ],

    navBarRightSide: ['mbc-nav-bar-right-side', { marginLeft: 'auto' }],
  };
};
