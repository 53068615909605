import { useMemo } from 'react';
import { getValidConditionBlock } from '../../../common/utils/ConditionBlock';
import { Augmentations } from '../../../common/SearchPreview/SearchPrevew.utils';
import { Banner } from '../../../../../store/types/customizations/businessRule';
import { BoostCondition, ConditionBlock } from '../../../../../utils/customizations/conditions';

export const useBusinessRuleAugmentation = (
  businessRuleName: string,
  filterCondition?: ConditionBlock,
  promoteCondition?: BoostCondition,
  demoteCondition?: BoostCondition,
  banner?: Banner,
): Augmentations => {
  return useMemo(() => {
    const promoteConditionBlock = getValidConditionBlock(promoteCondition && promoteCondition.condition);

    const demoteConditionBlock = getValidConditionBlock(demoteCondition && demoteCondition.condition);

    const filterConditionBlock = getValidConditionBlock(filterCondition && filterCondition);

    const validPromoteCondition: BoostCondition | undefined = promoteCondition &&
      promoteConditionBlock && {
        ...promoteCondition,
        condition: promoteConditionBlock,
      };

    const validDemoteCondition: BoostCondition | undefined = demoteCondition &&
      demoteConditionBlock && {
        ...demoteCondition,
        condition: demoteConditionBlock,
      };

    const boostConditions: BoostCondition[] = [];
    validPromoteCondition && boostConditions.push(validPromoteCondition);
    validDemoteCondition && boostConditions.push(validDemoteCondition);

    return {
      filterCondition: filterConditionBlock,
      boostConditions: boostConditions.length > 0 ? boostConditions : undefined,
      banners: banner ? [banner] : undefined,
      excludeBusinessRules: !!businessRuleName ? [businessRuleName] : undefined,
    };
  }, [promoteCondition, demoteCondition, filterCondition, banner, businessRuleName]);
};
