import { ReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../../CrudAction.types';
import {
  createOrUpdateBusinessRule,
  deleteBusinessRule,
} from '../../../../../restful-apis/customizations/businessRule.api';
import {
  BusinessRule,
  DELETE_BUSINESS_RULE,
  CREATE_OR_UPDATE_BUSINESS_RULE,
} from '../../../../types/customizations/businessRule.d';
import { DevOpsCommitMetadata } from '../../../../types/devOps';
import { getI18n } from 'react-i18next';

export const businessRuleUpdateAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  businessRule: BusinessRule,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ReduxCrudAction<BusinessRule> => {
  const i18n = getI18n();
  return {
    request: createOrUpdateBusinessRule(tenantId, searchIndexId, searchInstanceId, businessRule, devOpsCommitMetadata),
    postActionMapper: updatedBusinessRule => ({
      type: CREATE_OR_UPDATE_BUSINESS_RULE,
      businessRule: updatedBusinessRule,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.business-rules-update-progress-message', {
          businessRuleName: businessRule.name,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.business-rules-update-success-message', {
          businessRuleName: businessRule.name,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.business-rules-update-failure-message', {
          businessRuleName: businessRule.name,
        }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, businessRule),
  };
};

export const businessRuleCreateAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  businessRule: BusinessRule,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ReduxCrudAction<BusinessRule> => {
  const i18n = getI18n();
  return {
    request: createOrUpdateBusinessRule(tenantId, searchIndexId, searchInstanceId, businessRule, devOpsCommitMetadata),
    postActionMapper: createdBusinessRule => ({
      type: CREATE_OR_UPDATE_BUSINESS_RULE,
      businessRule: createdBusinessRule,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.business-rules-create-progress-message', {
          businessRuleName: businessRule.name,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.business-rules-create-success-message', {
          businessRuleName: businessRule.name,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.business-rules-create-failure-message', {
          businessRuleName: businessRule.name,
        }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, businessRule),
  };
};

export const businessRuleDeleteAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  businessRule: BusinessRule,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<BusinessRule>,
  onFailure?: ActionFailureHandler<BusinessRule>,
): ReduxCrudAction<BusinessRule> => {
  const i18n = getI18n();
  return {
    request: deleteBusinessRule(tenantId, searchIndexId, searchInstanceId, businessRule.name, devOpsCommitMetadata),
    postActionMapper: deletedBusinessRule => ({
      type: DELETE_BUSINESS_RULE,
      id: deletedBusinessRule.id,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.business-rules-delete-progress-message', {
          businessRuleName: businessRule.name,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.business-rules-delete-success-message', {
          businessRuleName: businessRule.name,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.business-rules-delete-failure-message', {
          businessRuleName: businessRule.name,
        }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, businessRule.name),
  };
};
