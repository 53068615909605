import { MbcRouterBase } from '../MbcRouter';
import { Role } from '../../store/types/roleList.d';
import { Tenant } from '../../store/types/tenantsList.d';
import { AdminRouteKey, RoleRouteKey } from '../MbcRouter.types';
import { MbcAdminRouteMapper, MbcRoleRouteMapper } from './MbcAdminRouter.config';

export const MbcAdminRouter = (tenant: Tenant) => MbcRouterBase<AdminRouteKey>(MbcAdminRouteMapper(tenant));

export const MbcRoleRouter = (tenant: Tenant, role: Role) =>
  MbcRouterBase<RoleRouteKey>(MbcRoleRouteMapper(tenant, role));
