import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MbcProductNavLinkMap, MbcNavLink } from '../MbcSideNav.types';
import { Product, Feature } from '../../../store/types/tenantsList.d';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { useSearchInstanceRouter } from '../../../store/hooks/use-router/useSearchInstanceRouter';
import { useEnvironmentRouter } from '../../../store/hooks/use-router/useEnvironmentRouter';
import { AppState } from '../../../store/reducers';
import { useSelector } from '../../../store/hooks';
import { isFeatureEnabledPerTenant } from '../../../guards/utils';
import { isDynamicsTenant } from '../../../utils/dynamics';

export enum LinksKeys {
  queryTester = 'queryTester',
  businessRules = 'businessRules',
  redirects = 'redirects',
  synonyms = 'synonyms',
  customMLRanker = 'customMLRanker',
  dashboard = 'dashboard',
}

export const useProductNavLinks = (MbcProduct: Product): [MbcNavLink[], (product: Product) => void] => {
  const { t } = useTranslation();
  const MbcEnvironmentRouteMap = useEnvironmentRouter();
  const MbcSearchInstanceRouteMap = useSearchInstanceRouter();

  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const hasAnalytics = useSelector((state: AppState) => state.environmentList.activeEnvironment.analyticsEnabled);

  const isDynamics = useMemo(() => isDynamicsTenant(activeTenant.id), [activeTenant.id]);

  const getProductNavLinks = useCallback(
    updatedProduct => {
      const queryTesterRoute = MbcEnvironmentRouteMap(MbcRouteKey.QueryTester);
      const businessRulesRoute = MbcSearchInstanceRouteMap(MbcRouteKey.BusinessRuleList);
      const synonymsRoute = MbcSearchInstanceRouteMap(MbcRouteKey.SynonymSetList);
      const urlRedirectsRoute = MbcSearchInstanceRouteMap(MbcRouteKey.UrlRedirectSetList);
      const customMLRankerRoute = MbcSearchInstanceRouteMap(MbcRouteKey.CustomMLRanker);
      const searchAnalyticsRoute = MbcEnvironmentRouteMap(MbcRouteKey.SearchAnalytics);
      let textSearchLinks: MbcNavLink[];
      // special handling due to dynamics tenant limitations
      if (isDynamics) {
        textSearchLinks = [
          {
            key: LinksKeys.queryTester,
            name: t('side-nav.links.queryTester'),
            url: queryTesterRoute.url,
            icon: 'QueryTester',
          },
        ];
      } else {
        textSearchLinks = [
          {
            key: LinksKeys.queryTester,
            name: t('side-nav.links.queryTester'),
            url: queryTesterRoute.url,
            icon: 'QueryTester',
          },
          {
            key: LinksKeys.businessRules,
            name: t('side-nav.links.businessRules'),
            url: businessRulesRoute.url,
            icon: 'BusinessRules',
          },
          {
            key: LinksKeys.redirects,
            name: t('side-nav.links.redirects'),
            url: urlRedirectsRoute.url,
            icon: 'Redirect',
          },
          {
            key: LinksKeys.synonyms,
            name: t('side-nav.links.synonyms'),
            url: synonymsRoute.url,
            icon: 'Synonyms',
          },
        ];

        if (isFeatureEnabledPerTenant(activeTenant, Feature.CustomML)) {
          textSearchLinks = [
            ...textSearchLinks,
            {
              key: LinksKeys.customMLRanker,
              name: t('side-nav.links.customMLRanker'),
              url: customMLRankerRoute.url,
              icon: 'Equalizer',
              disabled: activeTenant.isSandbox,
            },
          ];
        }
        if (hasAnalytics) {
          textSearchLinks = [
            ...textSearchLinks,
            {
              key: LinksKeys.dashboard,
              name: t('side-nav.links.dashboard'),
              url: searchAnalyticsRoute.url,
              icon: 'BarChartVertical',
              disabled: activeTenant.isSandbox,
            },
          ];
        }
      }
      const ProductNavLinkMap: MbcProductNavLinkMap = {
        [Product.TextSearch]: textSearchLinks,
        [Product.Unknown]: [],
      };

      return ProductNavLinkMap[updatedProduct];
    },
    [MbcEnvironmentRouteMap, MbcSearchInstanceRouteMap, activeTenant, hasAnalytics, isDynamics, t],
  );

  const [MbcProductNavLinks, setMbcProductNavLinks] = useState(getProductNavLinks(MbcProduct));

  const updateProductNavLinks = useCallback((product: Product) => setMbcProductNavLinks(getProductNavLinks(product)), [
    getProductNavLinks,
  ]);

  return [MbcProductNavLinks, updateProductNavLinks];
};
