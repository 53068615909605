import { MbcRouterBase } from '../MbcRouter';

import {
  MbcSearchInstanceRouteMapper,
  MbcSynonymSetRouteMapper,
  MbcBusinessRuleRouteMapper,
  MbcUrlRedirectSetRouteMapper,
} from './MbcSearchInstanceRouter.config';
import {
  SearchInstanceRouteKey,
  SynonymSetRouteKey,
  BusinessRuleRouteKey,
  UrlRedirectSetRouteKey,
} from '../MbcRouter.types';

export const MbcSearchInstanceRouter = (tenantId: string, environmentId: string, searchInstanceId: string) => {
  return MbcRouterBase<SearchInstanceRouteKey>(MbcSearchInstanceRouteMapper(tenantId, environmentId, searchInstanceId));
};

export const MbcBusinessRuleRouter = (
  tenantId: string,
  environmentId: string,
  searchInstanceId: string,
  businessRuleId: string,
) => {
  return MbcRouterBase<BusinessRuleRouteKey>(
    MbcBusinessRuleRouteMapper(tenantId, environmentId, searchInstanceId, businessRuleId),
  );
};

export const MbcSynonymSetRouter = (
  tenantId: string,
  environmentId: string,
  searchInstanceId: string,
  synonymSetId: string,
) => {
  return MbcRouterBase<SynonymSetRouteKey>(
    MbcSynonymSetRouteMapper(tenantId, environmentId, searchInstanceId, synonymSetId),
  );
};

export const MbcUrlRedirectSetRouter = (
  tenantId: string,
  environmentId: string,
  searchInstanceId: string,
  urlRedirectSetId: string,
) => {
  return MbcRouterBase<UrlRedirectSetRouteKey>(
    MbcUrlRedirectSetRouteMapper(tenantId, environmentId, searchInstanceId, urlRedirectSetId),
  );
};
