import { Tenant } from '../../store/types/tenantsList.d';
import { MbcRouteMap, MbcRouteKey } from '../MbcRouter.types';
import { Environment as RuntimeEnvironment } from '../../guards/EnvironmentGuard/EnvironmentGuard';
import { Role } from '../../store/types/roleList.d';
import { Permission } from '../../config/userPermissions.config';
import { EnvironmentEditor } from '../../pages/EnvironmentList/EnvironmentEditor';
import { EnvironmentList } from '../../pages/EnvironmentList';
import { TokenList } from '../../pages/TokenList';
import { RoleList } from '../../pages/RoleList/RoleList';
import { TokenEditor } from '../../pages/TokenEditor';
import { RoleEditor } from '../../pages/RoleEditor';
import { AppRoute } from '../router.service';

export const MbcAdminRouteMapper = (tenant: Tenant): MbcRouteMap => {
  return {
    [MbcRouteKey.Tenant]: {
      Component: EnvironmentList,
      url: AppRoute.append(`tenants/${tenant.id}`),
      key: MbcRouteKey.Tenant,
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
    [MbcRouteKey.EnvironmentList]: {
      Component: EnvironmentList,
      key: MbcRouteKey.EnvironmentList,
      url: AppRoute.append(`tenants/${tenant.id}`),
      redirectUrl: AppRoute.append(`tenants/${tenant.id}/environments`),
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
    [MbcRouteKey.EnvironmentCreator]: {
      Component: EnvironmentEditor,
      key: MbcRouteKey.EnvironmentCreator,
      url: AppRoute.append(`tenants/${tenant.id}/environments/create`),
      minimumPermissions: [Permission.AdminEnvironmentReadWrite],
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
    [MbcRouteKey.ApiAndTokenList]: {
      Component: TokenList,
      url: AppRoute.append(`tenants/${tenant.id}/tokens`),
      key: MbcRouteKey.ApiAndTokenList,
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
    [MbcRouteKey.CreateToken]: {
      Component: TokenEditor,
      url: AppRoute.append(`tenants/${tenant.id}/tokens/create`),
      key: MbcRouteKey.CreateToken,
      minimumPermissions: [Permission.AdminTokenCreate],
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
    [MbcRouteKey.RoleList]: {
      Component: RoleList,
      key: MbcRouteKey.RoleList,
      url: AppRoute.append(`tenants/${tenant.id}/roles`),
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
    [MbcRouteKey.RoleEditor]: {
      Component: RoleEditor,
      key: MbcRouteKey.RoleEditor,
      url: AppRoute.append(`tenants/${tenant.id}/roles/create`),
      minimumPermissions: [Permission.AdminRoleManagementReadWrite],
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
  };
};

export const MbcRoleRouteMapper = (tenant: Tenant, role: Role): MbcRouteMap => {
  return {
    [MbcRouteKey.Role]: {
      key: MbcRouteKey.Role,
      Component: RoleEditor,
      url: AppRoute.append(`tenants/${tenant.id}/roles/${role.id}`),
      minimumPermissions: [Permission.AdminRoleManagementRead],
      runtimeEnvironments: [RuntimeEnvironment.Production, RuntimeEnvironment.Staging],
    },
  };
};
