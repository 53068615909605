import React, { useState, useEffect } from 'react';
import { Dropdown, IDropdownOption, classNamesFunction } from 'office-ui-fabric-react';
import { convertTimeSpanToInterval, TimeFilterOptions } from '../../Insights.utils';
import { TimeSpanSelectorProps, TimeSpanSelectorStyleProps, TimeSpanSelectorStyles } from './TimeSpanSelector.types';

const getClassNames = classNamesFunction<TimeSpanSelectorStyleProps, TimeSpanSelectorStyles>();

export const TimeSpanSelectorBase = (props: TimeSpanSelectorProps) => {
  const { t, className, styles, theme, isLoading, defaultTimeSpan, availableTimes, onTimeSpanChange } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const [currentSelectedTime, setCurrentSelectedTime] = useState<TimeFilterOptions>();

  useEffect(() => {
    setCurrentSelectedTime(defaultTimeSpan);
  }, [defaultTimeSpan]);

  const mapTimeFilterOptionsToString = (timeToMap: TimeFilterOptions): string => {
    switch (timeToMap) {
      case TimeFilterOptions.ONE_HOUR:
        return t('insights.one-hour');
      case TimeFilterOptions.TWELVE_HOURS:
        return t('insights.twelve-hours');
      case TimeFilterOptions.ONE_DAY:
        return t('insights.one-day');
      case TimeFilterOptions.ONE_WEEK:
        return t('insights.one-week');
      case TimeFilterOptions.ONE_MONTH:
        return t('insights.one-month');
      default:
        return 'UNKNOWN_TIME';
    }
  };

  const generateTimeMenuOptions = (): IDropdownOption[] => {
    var availableTimesInternal = [...availableTimes];
    if (availableTimesInternal.includes(TimeFilterOptions.ALL)) {
      availableTimesInternal = [
        TimeFilterOptions.ONE_HOUR,
        TimeFilterOptions.TWELVE_HOURS,
        TimeFilterOptions.ONE_DAY,
        TimeFilterOptions.ONE_WEEK,
        TimeFilterOptions.ONE_MONTH,
      ];
    }
    return availableTimesInternal.map(x => {
      return { key: x, text: mapTimeFilterOptionsToString(x) };
    });
  };

  return (
    <div className={classNames.root}>
      <Dropdown
        disabled={isLoading}
        ariaLabel={t('insights.time-span-selector-aria')}
        styles={classNames.subComponentStyles.dropDownCallout}
        selectedKey={currentSelectedTime}
        options={generateTimeMenuOptions()}
        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
          if (!option) return;

          const selectedTime = option.key as TimeFilterOptions;
          setCurrentSelectedTime(selectedTime);
          !!onTimeSpanChange && onTimeSpanChange(selectedTime, convertTimeSpanToInterval(selectedTime));
        }}
      ></Dropdown>
    </div>
  );
};
