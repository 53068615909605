import {
  SET_SANDBOX_USER_DETAILS,
  SET_LOGGED_IN_USER_DETAILS,
  UserStateActionTypes,
  SET_USER_IMAGE,
  UPDATE_USER_DETAILS,
  SandBoxDetails,
} from '../types/user.d';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import { updateUserDetails } from '../../restful-apis/me.api';

export function setUserImage(image: string): UserStateActionTypes {
  return {
    type: SET_USER_IMAGE,
    image: image,
  };
}

export const updateUserDetailsAction = (sandBoxDetails: SandBoxDetails): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    updateUserDetails(sandBoxDetails).then(updatedSandBoxDetails => {
      dispatch({
        type: UPDATE_USER_DETAILS,
        sandBoxDetails: updatedSandBoxDetails,
      });
    });
  };
};

export function setSandboxUserDetails(sandBoxDetails: SandBoxDetails): UserStateActionTypes {
  return {
    type: SET_SANDBOX_USER_DETAILS,
    sandBoxDetails: sandBoxDetails,
  };
}

export function setLoggedInUserDetails(
  name: string,
  email: string,
  secondaryEmail: string,
  initials: string,
): UserStateActionTypes {
  return {
    type: SET_LOGGED_IN_USER_DETAILS,
    name: name,
    email: email,
    initials: initials,
    secondaryEmail: secondaryEmail,
  };
}
