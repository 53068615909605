import React from 'react';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import { PageHeaderStyles, PageHeaderStyleProps, PageHeaderProps } from './PageHeader.types';
import { InfoCard } from '../InfoCard';

const getClassNames = classNamesFunction<PageHeaderStyleProps, PageHeaderStyles>();

export const PageHeaderBase = (props: PageHeaderProps) => {
  const {
    styles,
    theme,
    className,
    componentRef,
    title,
    titleCaption,
    iconPath,
    infoProps,
    actions,
    shrinkTopMargin,
  } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className, shrinkTopMargin: shrinkTopMargin });

  return (
    <div ref={componentRef} className={classNames.root}>
      <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
        <Stack horizontal tokens={{ childrenGap: '11px' }}>
          {iconPath && <img src={iconPath} alt="Company logo" width={56} height={56} />}
          <Stack>
            <Stack horizontal>
              <div className={classNames.title}>{title}</div>
              {infoProps && <InfoCard {...infoProps} />}
            </Stack>
            <div className={classNames.titleCaption}>{titleCaption}</div>
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign={'center'}>
          {actions}
        </Stack>
      </Stack>
    </div>
  );
};
