import React, { Component } from 'react';
import styles from './requestTrial.module.scss';
import { PrimaryButton, Stack, Text, StackItem } from 'office-ui-fabric-react';
import { AuthContext } from '../../../../contexts';
import { browserHistory } from '../../../../history.module';
import Footer from '../../landing-page/footer/Footer';
import { AppRoute } from '../../../../router/router.service';

export default class RequestTrial extends Component {
  static contextType = AuthContext;

  render() {
    return (
      <Stack
        className={styles.container}
        tokens={{ childrenGap: '25px' }}
        horizontalAlign="center"
        verticalAlign="space-between"
      >
        <Stack
          tokens={{ childrenGap: '25px' }}
          verticalAlign="center"
          horizontalAlign="center"
          styles={{ root: { minHeight: 'calc(100vh - 100px)' } }}
        >
          <Text className={styles.title}>I want in</Text>
          <Text className={styles.paragraph}>
            Microsoft Bing for Commerce offers a unique set of features to empower your retail search experience
          </Text>
          <Text className={styles.paragraph}>Would you like to try it out? Login now to request a trial.</Text>
          <PrimaryButton
            className={styles.requestTrialButton}
            onClick={() => browserHistory.push(AppRoute.append('login'))}
            text="Sign in"
          ></PrimaryButton>
        </Stack>

        <StackItem className={styles.footerContainer}>
          <Footer></Footer>
        </StackItem>
      </Stack>
    );
  }
}
