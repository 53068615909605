import { IDropdownOption } from 'office-ui-fabric-react';
import { getI18n } from 'react-i18next';

export const ProductIDKey = 'ProductId';

export const getDefaultIndexTypeOptions = (): IDropdownOption[] => {
  const i18n = getI18n();
  return [
    {
      key: 'AgeGroup',
      text: i18n.t('schema-management.index-field-editor.opt-ageGroup'),
    },
    {
      key: 'Array',
      text: i18n.t('schema-management.index-field-editor.opt-array'),
    },
    {
      key: 'Boolean',
      text: i18n.t('schema-management.index-field-editor.opt-boolean'),
    },
    {
      key: 'Brand',
      text: i18n.t('schema-management.index-field-editor.opt-brand'),
    },
    {
      key: 'Category',
      text: i18n.t('schema-management.index-field-editor.opt-category'),
    },
    {
      key: 'Color',
      text: i18n.t('schema-management.index-field-editor.opt-color'),
    },
    {
      key: 'DateTime',
      text: i18n.t('schema-management.index-field-editor.opt-datetime'),
    },
    {
      key: 'Description',
      text: i18n.t('schema-management.index-field-editor.opt-description'),
    },
    {
      key: 'DupId',
      text: i18n.t('schema-management.index-field-editor.opt-dupId'),
    },
    {
      key: 'ExcludeFlag',
      text: i18n.t('schema-management.index-field-editor.opt-excludeFlag'),
    },
    {
      key: 'Gender',
      text: i18n.t('schema-management.index-field-editor.opt-gender'),
    },
    {
      key: 'Identifier',
      text: i18n.t('schema-management.index-field-editor.opt-identifier'),
    },
    {
      key: 'ImageUrl',
      text: i18n.t('schema-management.index-field-editor.opt-imageUrl'),
    },
    {
      key: 'Material',
      text: i18n.t('schema-management.index-field-editor.opt-material'),
    },
    {
      key: 'Model',
      text: i18n.t('schema-management.index-field-editor.opt-model'),
    },
    {
      key: 'Number',
      text: i18n.t('schema-management.index-field-editor.opt-number'),
    },
    {
      key: 'Price',
      text: i18n.t('schema-management.index-field-editor.opt-price'),
    },
    {
      key: ProductIDKey,
      text: i18n.t('schema-management.index-field-editor.opt-productId'),
    },
    {
      key: 'Rating',
      text: i18n.t('schema-management.index-field-editor.opt-rating'),
    },
    {
      key: 'Size',
      text: i18n.t('schema-management.index-field-editor.opt-size'),
    },
    {
      key: 'String',
      text: i18n.t('schema-management.index-field-editor.opt-string'),
    },
    {
      key: 'Title',
      text: i18n.t('schema-management.index-field-editor.opt-title'),
    },
    {
      key: 'Url',
      text: i18n.t('schema-management.index-field-editor.opt-url'),
    },
    {
      key: 'DateSegment',
      text: i18n.t('schema-management.index-field-editor.opt-datesegment'),
    },
  ];
};

export const getCvsIndexTypeOptions = (): IDropdownOption[] => {
  const i18n = getI18n();
  return [
    {
      key: 'Barcode',
      text: i18n.t('schema-management.index-field-editor.opt-barcode'),
    },
    {
      key: 'SecondaryImageUrls',
      text: i18n.t('schema-management.index-field-editor.opt-secondaryImageUrls'),
    },
  ];
};

export const getComplexIndexTypeOptions = (): IDropdownOption[] => {
  const i18n = getI18n();
  return [
    {
      key: 'Object',
      text: i18n.t('schema-management.index-field-editor.opt-object'),
    },
  ];
};

export const getGeoPointTypeOptions = (): IDropdownOption[] => {
  const i18n = getI18n();
  return [
    {
      key: 'GeoPoint',
      text: i18n.t('schema-management.index-field-editor.opt-geo-point'),
    },
  ];
};

export const getHotelDocumentScenariosCommonOptions = (): IDropdownOption[] => {
  const i18n = getI18n();
  return [
    {
      key: 'DocumentId',
      text: i18n.t('schema-management.index-field-editor.opt-document-id'),
    },
    {
      key: 'CreatedAt',
      text: i18n.t('schema-management.index-field-editor.opt-created-at'),
    },
    {
      key: 'ModifiedAt',
      text: i18n.t('schema-management.index-field-editor.opt-modified-at'),
    },
  ];
};

export const getHotelScenarioTypeOptions = (): IDropdownOption[] => {
  const i18n = getI18n();
  return [
    {
      key: 'Address',
      text: i18n.t('schema-management.index-field-editor.opt-address'),
    },
    {
      key: 'RatingCount',
      text: i18n.t('schema-management.index-field-editor.opt-rating-count'),
    },
    {
      key: 'ReviewCount',
      text: i18n.t('schema-management.index-field-editor.opt-review-count'),
    },
    {
      key: 'RatingScale',
      text: i18n.t('schema-management.index-field-editor.opt-rating-scale'),
    },
    {
      key: 'Amenities',
      text: i18n.t('schema-management.index-field-editor.opt-amenities'),
    },
    {
      key: 'StreetAddress',
      text: i18n.t('schema-management.index-field-editor.opt-street-address'),
    },
    {
      key: 'Locality',
      text: i18n.t('schema-management.index-field-editor.opt-locality'),
    },
    {
      key: 'SubRegion',
      text: i18n.t('schema-management.index-field-editor.opt-sub-region'),
    },
    {
      key: 'AddressRegion',
      text: i18n.t('schema-management.index-field-editor.opt-address-region'),
    },
    {
      key: 'PostalCode',
      text: i18n.t('schema-management.index-field-editor.opt-postal-code'),
    },
    {
      key: 'PostOfficeBoxNumber',
      text: i18n.t('schema-management.index-field-editor.opt-po-number'),
    },
    {
      key: 'Country',
      text: i18n.t('schema-management.index-field-editor.opt-country'),
    },
    {
      key: 'CountryIso',
      text: i18n.t('schema-management.index-field-editor.opt-country-iso'),
    },
    {
      key: 'Neighborhood',
      text: i18n.t('schema-management.index-field-editor.opt-neighborhood'),
    },
    {
      key: 'OtherAreas',
      text: i18n.t('schema-management.index-field-editor.opt-other-areas'),
    },
    {
      key: 'PhoneNumber',
      text: i18n.t('schema-management.index-field-editor.opt-phone-number'),
    },
  ];
};

export const getDocumentScenarioTypeOptions = (): IDropdownOption[] => {
  const i18n = getI18n();
  return [
    {
      key: 'Author',
      text: i18n.t('schema-management.index-field-editor.opt-author'),
    },
    {
      key: 'Paragraph',
      text: i18n.t('schema-management.index-field-editor.opt-paragraph'),
    },
    {
      key: 'SubHeading',
      text: i18n.t('schema-management.index-field-editor.opt-sub-heading'),
    },
    {
      key: 'SectionHeader',
      text: i18n.t('schema-management.index-field-editor.opt-section-header'),
    },
  ];
};
