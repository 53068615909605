import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../../../router/MbcRouter.base';
import { MbcRouteKey } from '../../../../router/MbcRouter.types';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { ActiveSynonymSetLoading } from '../../../../components/Loading';
import { useSynonymSetRouter } from '../../../../store/hooks/use-router/useSearchInstanceRouter';
import { setActiveSynonymSet, resetActiveSynonymSet } from '../../../../store/actions/customizations/synonymSetActions';
import { useEnvironmentPermissions } from '../../../../store/hooks/use-user-permissions/UseEnvironmentPermissions';

export const SynonymSetProxy = (props: RouteComponentProps<{ synonymSetId: string }>) => {
  const dispatch = useDispatch();

  const MbcSynonymSetRouteMap = useSynonymSetRouter();
  const environmentPermissions = useEnvironmentPermissions();
  const isSynonymSetListLoading = useSelector(state => state.synonymSet.isLoading);

  useEffect(() => {
    dispatch(setActiveSynonymSet(props.match.params.synonymSetId));
    return () => {
      dispatch(resetActiveSynonymSet());
    };
  }, [dispatch, isSynonymSetListLoading, props.match.params.synonymSetId]);

  return (
    <ActiveSynonymSetLoading>
      <MbcRouter
        userPermissions={environmentPermissions}
        routes={[MbcSynonymSetRouteMap(MbcRouteKey.SynonymSetEditor)]}
      />
    </ActiveSynonymSetLoading>
  );
};
