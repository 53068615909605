import { Action } from 'redux';
import { Product } from './tenantsList.d';

export const SET_ENVIRONMENT_LIST = 'SET_ENVIRONMENT_LIST';
export const RESET_ENVIRONMENT_LIST = 'RESET_ENVIRONMENT_LIST';
export const SET_ACTIVE_ENVIRONMENT = 'SET_ACTIVE_ENVIRONMENT';
export const RESET_ACTIVE_ENVIRONMENT = 'RESET_ACTIVE_ENVIRONMENT';
export const LOADING_ENVIRONMENT_LIST = 'LOADING_ENVIRONMENT_LIST';
export const SET_SEARCH_INDEX_ID = 'SET_SEARCH_INDEX_ID';
export const ADD_OR_UPDATE_ENVIRONMENT = 'ADD_OR_UPDATE_ENVIRONMENT';
export const DELETE_ENVIRONMENT = 'DELETE_ENVIRONMENT';
export const SET_ENVIRONMENT_INDEX_SOFT_DELETED = 'SET_ENVIRONMENT_INDEX_SOFT_DELETED';

export const UPDATING_ENVIRONMENT_SEARCH_INDEX = 'UPDATING_ENVIRONMENT_SEARCH_INDEX';
export const SET_ENVIRONMENT_SEARCH_INDEX_UPDATED = 'SET_ENVIRONMENT_SEARCH_INDEX_UPDATED';

export interface EnvironmentIdMapper {
  [key: string]: Environment;
}

export interface Environment {
  id: string;
  name: string;
  description: string;
  regions: string[];
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  indexId: string;
  products: Product[];
  analyticsEnabled: boolean;
  isIndexSoftDeleted?: boolean;
}

export interface EnvironmentWithRoles extends Environment {
  roleIdList: string[];
}

export interface EnvironmentListState {
  isLoading: boolean;
  activeEnvironment: Environment;
  environmentIdMapper: EnvironmentIdMapper;
  nonEditableSearchIndexSet: Set<string>;
}

export function createInitialEnvironment() {
  return {
    id: '',
    name: '',
    description: '',
    regions: [],
    createdBy: '',
    createdAt: '',
    updatedAt: '',
    indexId: '',
    products: [],
    analyticsEnabled: true,
  };
}

export function createInitialEnvironmentWithRoleList() {
  return { ...createInitialEnvironment(), roleIdList: [] };
}

interface SetEnvironmentList extends Action<typeof SET_ENVIRONMENT_LIST> {
  environmentList: Environment[];
}

interface SetActiveEnvironment extends Action<typeof SET_ACTIVE_ENVIRONMENT> {
  environment: Environment;
}

interface SetSearchIndexId extends Action<typeof SET_SEARCH_INDEX_ID> {
  indexId: string;
}

interface AddOrUpdateEnvironment extends Action<typeof ADD_OR_UPDATE_ENVIRONMENT> {
  environment: Environment;
}

interface DeleteEnvironment extends Action<typeof DELETE_ENVIRONMENT> {
  environmentId: string;
}

interface SetEnvIndexIsSoftDeleted extends Action<typeof SET_ENVIRONMENT_INDEX_SOFT_DELETED> {
  environmentId: string;
}

interface UpdatingEnvironmentSearchIndex extends Action<typeof UPDATING_ENVIRONMENT_SEARCH_INDEX> {
  environmentId: string;
}

interface SetEnvironmentSearchIndexUpdated extends Action<typeof SET_ENVIRONMENT_SEARCH_INDEX_UPDATED> {
  environmentId: string;
}

export type EnvironmentListStateActionTypes =
  | SetEnvironmentList
  | Action<typeof RESET_ENVIRONMENT_LIST>
  | SetActiveEnvironment
  | SetSearchIndexId
  | DeleteEnvironment
  | SetEnvIndexIsSoftDeleted
  | AddOrUpdateEnvironment
  | UpdatingEnvironmentSearchIndex
  | SetEnvironmentSearchIndexUpdated
  | Action<typeof RESET_ACTIVE_ENVIRONMENT>
  | Action<typeof LOADING_ENVIRONMENT_LIST>;
