import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MbcRouter } from '../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../router/MbcRouter.types';
import { useBreadcrumb } from '../../store/hooks/use-breadcrumb/useBreadcrumb';
import { useAdminRouter } from '../../store/hooks/use-router/useAdminRouter';
import { useDispatch, useSelector } from '../../store/hooks';
import { getTokens } from '../../store/actions/tokenListActions';

const TokensListBreadcrumbItemKey = 'API Tokens';

export const TokenListProxy = (props: RouteComponentProps) => {
  useBreadcrumb(TokensListBreadcrumbItemKey, props.match.url);
  const tenantId = useSelector(state => state.tenantsList.activeTenant.id);
  const isTokenListLoading = useSelector(state => state.tokenList.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isTokenListLoading) {
      dispatch(getTokens(tenantId));
    }
  }, [dispatch, isTokenListLoading, tenantId]);

  const MbcAdminRouteMapper = useAdminRouter();

  const getMbcDefaultAdminRoutes = useCallback(
    (): MbcRouteBase[] => [
      MbcAdminRouteMapper(MbcRouteKey.ApiAndTokenList),
      MbcAdminRouteMapper(MbcRouteKey.CreateToken),
    ],
    [MbcAdminRouteMapper],
  );

  return <MbcRouter routes={getMbcDefaultAdminRoutes()} />;
};
