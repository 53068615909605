import { Stack } from 'office-ui-fabric-react';
import React from 'react';
import { PermissionsEditor } from '../../../components/editors-common/PermissionsEditor';
import { PermissionsSectionContentProps } from './PermissionsSectionContent.types';

export const PermissionsSectionContentBase = (props: PermissionsSectionContentProps) => {
  return (
    <Stack data-test="token-editor-permissions-section" tokens={{ childrenGap: '21px' }}>
      <PermissionsEditor
        initialPermissions={[]}
        hiddenPermissions={[]}
        onRolePermissionUpdate={props.onPermissionsChange}
        disabled={props.disabled}
      ></PermissionsEditor>
    </Stack>
  );
};
