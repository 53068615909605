import { SynonymSetListStyles, SynonymSetListStyleProps } from './SynonymSetList.types';

export const styles = (props: SynonymSetListStyleProps): SynonymSetListStyles => {
  const { className, theme } = props;
  return {
    root: ['mbc-SynonymSetList', {}, className],
    nameColumnCell: [
      'mbc-nameColumnCell',
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        selectors: {
          '.ms-Link': {
            color: theme.palette.themePrimary,
          },
        },
      },
    ],
    nameColumnCellDescription: [
      'mbc-nameColumnCellDescription',
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.neutralSecondary,
      },
    ],
  };
};
