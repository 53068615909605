import { BoostItem, SearchResult as SearchResultModel } from '../store/types/searchDemo';

// const getFall2019TopProductsRankerResults = () => ({
//   resultCount: 6,
//   isLoading: false,
//   alteredQuery: "men's fall jackets",
//   originalQuery: "men's fall jackets",
//   facets: [
//     {
//       field: 'Brand',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Northwind Traders', count: 21, label: '' },
//         { value: 'World Wide Importers', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Categories',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Fashion', count: 21, label: '' },
//         { value: 'Menswear', count: 21, label: '' },
//         { value: 'Sweaters', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Size',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'medium', count: 21, label: '' },
//         { value: 'large', count: 21, label: '' },
//         { value: 'small', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Color',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Black', count: 21, label: '' },
//         { value: 'Gray', count: 21, label: '' },
//         { value: 'Purple', count: 21, label: '' },
//         { value: 'Blue', count: 21, label: '' },
//         { value: 'LightBlue', count: 21, label: '' },
//         { value: 'Green', count: 21, label: '' },
//         { value: 'Red', count: 21, label: '' },
//       ],
//     },
//   ],
//   searchResultItems: [
//     {
//       id: '78719490696',
//       name: 'Solid Black Leather Jacket For Men',
//       description: 'A black colored leather biker jacket. A classy product that provides an stylish look.',
//       price: 87,
//       imgUrl: 'https://bing.com/th?id=AMMS_5e75ca2d02116bd18c73eacffe5dbd74',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491326',
//       name: 'Zipper Cushioned Winter Jacket',
//       description: 'A dark green colored cushioned winter jacket. Stylish regular fit look. Wash care: dry clean.',
//       price: 169,
//       imgUrl: 'https://bing.com/th?id=AMMS_49613f408abd2b5c362536675cd6e1d3',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491499',
//       name: 'Green Warm Working Jacket',
//       description:
//         'A green warm jacket. Very cozy,soft,comfortable.Specially used while working. One pocket given for cool look.',
//       price: 400,
//       imgUrl: 'https://bing.com/th?id=AMMS_8900c82481d810eb719a8e92968a041d',
//       onSale: 'false',
//       categories: 'Fashion|Unisex|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491139',
//       name: 'Dual Toned Zipper Jacket',
//       description: 'A white and black colored jacket.Simple design for casual look.',
//       price: 119,
//       imgUrl: 'https://bing.com/th?id=AMMS_474383efb971b4620d2b77826d662ecc',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491324',
//       name: 'Unisex Gray Turtleneck Sweater',
//       description:
//         'This is an unisex dark gray turtleneck full sleves sweater. Made from a high quality wool and cotton, a good choice for your day-to-dayuse.',
//       price: 99,
//       imgUrl: 'https://bing.com/th?id=AMMS_903af202bcebafe6ce731f04cfd6173e',
//       onSale: 'false',
//       categories: 'Fashion|Unisex|Sweaters',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491496',
//       name: 'Black Turtle Neck Sweater',
//       description:
//         'Black turtle neck sweater. Very soft and comfortable. Woolen material which keeps body warm. Gives a really smart look to anybody.',
//       price: 169,
//       imgUrl: 'https://bing.com/th?id=AMMS_f8687206d7de73502d51fa085213a6fe',
//       onSale: 'false',
//       categories: 'Fashion|Unisex|Sweaters',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//   ],
// });

// const getHighLearningRateTest1RankerResults = () => ({
//   resultCount: 6,
//   isLoading: false,
//   alteredQuery: "men's fall jackets",
//   originalQuery: "men's fall jackets",
//   facets: [
//     {
//       field: 'Brand',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Northwind Traders', count: 21, label: '' },
//         { value: 'World Wide Importers', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Categories',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Fashion', count: 21, label: '' },
//         { value: 'Coats', count: 21, label: '' },
//         { value: 'Jackets', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Size',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'medium', count: 21, label: '' },
//         { value: 'large', count: 21, label: '' },
//         { value: 'small', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Color',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Green', count: 21, label: '' },
//         { value: 'Black', count: 21, label: '' },
//         { value: 'Blue', count: 21, label: '' },
//         { value: 'Purple', count: 21, label: '' },
//         { value: 'LightBlue', count: 21, label: '' },
//         { value: 'Dark Gray', count: 21, label: '' },
//         { value: 'Red', count: 21, label: '' },
//       ],
//     },
//   ],
//   searchResultItems: [
//     {
//       id: '78719491499',
//       name: 'Green Warm Working Jacket',
//       description: 'A green warm jacket. Very cozy,soft,comfortable.Specially used while working.',
//       price: 400,
//       imgUrl: 'https://bing.com/th?id=AMMS_8900c82481d810eb719a8e92968a041d',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491077',
//       name: "Men's Lightweight Grey Sweatshirt",
//       description: 'This light classic design is the perfect lifestyle garment for boys and teen kids.',
//       price: 176,
//       imgUrl: 'https://bing.com/th?id=AMMS_d1183b286a1ca6eb6cef4df2de3456c8',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Sweaters',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491496',
//       name: 'Black Unisex Turtle Neck Sweater',
//       description: 'Black turtle neck sweater. Very soft and comfortable. Woolen material which keeps body warm.',
//       price: 169,
//       imgUrl: 'https://bing.com/th?id=AMMS_f8687206d7de73502d51fa085213a6fe',
//       onSale: 'false',
//       categories: 'Fashion|Unisex|Sweaters',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491326',
//       name: 'Zipper Cushioned Winter Jacket',
//       description: 'A dark green colored cushioned winter jacket. Stylish regular fit look. Wash care: dry clean.',
//       price: 169,
//       imgUrl: 'https://bing.com/th?id=AMMS_49613f408abd2b5c362536675cd6e1d3',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491324',
//       name: 'Unisex Gray Turtleneck Sweater',
//       description:
//         'This is an unisex dark gray turtleneck full sleves sweater. Made from a high quality wool and cotton.',
//       price: 99,
//       imgUrl: 'https://bing.com/th?id=AMMS_903af202bcebafe6ce731f04cfd6173e',
//       onSale: 'false',
//       categories: 'Fashion|Unisex|Sweaters',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491139',
//       name: 'Dual Toned Zipper Jacket',
//       description: 'A white and black colored jacket.Simple design for casual look.',
//       price: 119,
//       imgUrl: 'https://bing.com/th?id=AMMS_474383efb971b4620d2b77826d662ecc',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//   ],
// });

// const getBM25StandardRankerResults = () => ({
//   resultCount: 6,
//   isLoading: false,
//   alteredQuery: "men's fall jackets",
//   originalQuery: "men's fall jackets",
//   facets: [
//     {
//       field: 'Brand',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Northwind Traders', count: 21, label: '' },
//         { value: 'World Wide Importers', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Categories',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Fashion', count: 21, label: '' },
//         { value: 'Jackets', count: 21, label: '' },
//         { value: 'Menswear', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Size',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'medium', count: 21, label: '' },
//         { value: 'large', count: 21, label: '' },
//         { value: 'small', count: 21, label: '' },
//       ],
//     },
//     {
//       field: 'Color',
//       _type: 'string',
//       label: '',
//       refinements: [
//         { value: 'Green', count: 21, label: '' },
//         { value: 'Black', count: 21, label: '' },
//         { value: 'Blue', count: 21, label: '' },
//         { value: 'Purple', count: 21, label: '' },
//         { value: 'LightBlue', count: 21, label: '' },
//         { value: 'Dark Gray', count: 21, label: '' },
//         { value: 'Red', count: 21, label: '' },
//       ],
//     },
//   ],
//   searchResultItems: [
//     {
//       id: '78719491355',
//       name: 'Purple Winter Parka',
//       description: "Moisture and cold winds aren't a concern when you're clad in this multitasking softshell jacket.",
//       price: 230,
//       imgUrl: 'https://bing.com/th?id=AMMS_c72322f2ebeff3bd3cd2cf8f008e8f43',
//       onSale: 'false',
//       categories: 'Fashion|Womenswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491324',
//       name: 'Unisex Gray Turtleneck Sweater',
//       description:
//         'This is an unisex dark gray turtleneck full sleves sweater. Made from a high quality wool and cotton, a good choice for your day-to-dayuse.',
//       price: 99,
//       imgUrl: 'https://bing.com/th?id=AMMS_903af202bcebafe6ce731f04cfd6173e',
//       onSale: 'false',
//       categories: 'Fashion|Unisex|Sweaters',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491139',
//       name: 'Dual Toned Zipper Jacket',
//       description: 'A white and black colored jacket.Simple design for casual look.',
//       price: 119,
//       imgUrl: 'https://bing.com/th?id=AMMS_474383efb971b4620d2b77826d662ecc',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491496',
//       name: 'Black Turtle Neck Sweater',
//       description:
//         'Black turtle neck sweater. Very soft and comfortable. Woolen material which keeps body warm. Gives a really smart look to anybody.',
//       price: 169,
//       imgUrl: 'https://bing.com/th?id=AMMS_f8687206d7de73502d51fa085213a6fe',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Sweaters',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719490696',
//       name: 'Solid Black Leather Jacket For Men',
//       description: 'A black colored leather biker jacket. A classy product that provides an stylish look.',
//       price: 87,
//       imgUrl: 'https://bing.com/th?id=AMMS_5e75ca2d02116bd18c73eacffe5dbd74',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//     {
//       id: '78719491326',
//       name: 'Zipper Cushioned Winter Jacket',
//       description: 'A dark green colored cushioned winter jacket. Stylish regular fit look. Wash care: dry clean.',
//       price: 169,
//       imgUrl: 'https://bing.com/th?id=AMMS_49613f408abd2b5c362536675cd6e1d3',
//       onSale: 'false',
//       categories: 'Fashion|Menswear|Coats & Jackets',
//       collection: 'Home',
//       promotionDisplay: '',
//       currency: '$',
//     },
//   ],
// });

// export const getMockedCustomMLSearchResults = (rankerModel: CustomMLModel, query: string): SearchResultModel => {
//   switch (rankerModel.key.toLowerCase()) {
//     case 'cf3a82ae-se3a-437c-b16d-426843ddeed3':
//       switch (query.toLowerCase()) {
//         case "men's fall jackets":
//           return getFall2019TopProductsRankerResults();
//       }
//       break;
//     case 'ef3a82ae-se3a-437c-b16d-426843ddeed3':
//       switch (query.toLowerCase()) {
//         case "men's fall jackets":
//           return getHighLearningRateTest1RankerResults();
//       }
//       break;
//     default:
//       switch (query.toLowerCase()) {
//         case "men's fall jackets":
//           return getBM25StandardRankerResults();
//       }
//       break;
//   }

//   return {
//     resultCount: 0,
//     isLoading: false,
//     alteredQuery: '',
//     originalQuery: query,
//     searchResultItems: [],
//   };
// };

const getChockerSearchResultWithQueryAlteration = () => ({
  resultCount: 25,
  isLoading: false,
  alteredQuery: 'choker',
  originalQuery: 'chocker',
  searchResultItems: [
    {
      id: '68719492152',
      name: 'Stranded Glass Bead Choker with Pink Stone Pendant',
      description: 'Northwind Traders',
      price: 185,
      imgUrl: '/assets/images/Glass _Bead_Choker.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Accessories|Jewelry',
      collection: 'Anniversary',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719492155',
      name: 'Pink Crystal Round Necklace',
      description: 'Wide World Importers',
      price: 260,
      imgUrl: '/assets/images/pink_crystal.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Jewelry',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719492164',
      name: 'Woven Pearl Necklace and Bracelet',
      description: 'Northwind Traders',
      price: 350,
      imgUrl: '/assets/images/woven_pearl_necklace.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Jewelry',
      collection: 'Executive',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719492162',
      name: 'Silver Freshwater Pearl Two-Strand Necklace',
      description: 'Northwind Traders',
      price: 420,
      imgUrl: '/assets/images/silver_freshwater_pearl.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Accessories|Jewelry',
      collection: 'Executive',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719492159',
      name: 'Gold Teardrop Pendant Necklace',
      description: 'Wide World Importers',
      price: 265,
      imgUrl: '/assets/images/gold_teardrop.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Jewelry',
      collection: 'Engagement',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719492160',
      name: 'Gold Filligree Statement Necklace',
      description: 'Northwind Traders',
      price: 375,
      imgUrl: '/assets/images/gold_filligree.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Accessories|Jewelry',
      collection: 'Formal',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
  ],
});

const getPartyDressSearchResultWithQueryAlteration = () => ({
  resultCount: 4,
  alteredQuery: '',
  isLoading: false,
  originalQuery: 'party dress',
  searchResultItems: [
    {
      id: '68719494403',
      name: 'Vintage Polka-dot Party  Dress',
      description: 'Wide World Importers',
      price: 290,
      imgUrl: '/assets/images/vintage_polka_dot.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719494486',
      name: 'Satin Strapless Cocktail Dress',
      description: 'Wide World Importers',
      price: 225,
      imgUrl: '/assets/images/satin_strapless.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719494495',
      name: 'Strapless Full Skirt Cocktail Dress',
      description: 'Northwind Traders',
      price: 280,
      imgUrl: '/assets/images/strapless_full_skirt.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Wedding',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719494380',
      name: 'A-line Grandient Gown',
      description: 'Wide World Importers',
      price: 170,
      imgUrl: '/assets/images/gradient_gown.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Anniversary',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
  ],
});

const getShortHandleBagsSearchResultWithQueryAlteration = () => ({
  resultCount: 14,
  alteredQuery: '',
  isLoading: false,
  originalQuery: 'short handle bags',
  searchResultItems: [
    {
      id: '68719491375',
      name: 'Croc-Embossed Leather Top Handle Bag',
      description: 'Wide World Importers',
      price: 330,
      imgUrl: '/assets/images/Croc-Embossed_Leather.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Handbags & Wallets|Handbags',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719491377',
      name: 'Leather Top Handle Bag',
      description: 'Northwind Traders',
      price: 260,
      imgUrl: '/assets/images/Leather _Top_Handle.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Handbags & Wallets|Handbags',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719491372',
      name: 'Small Leather Top Handle Satchel',
      description: 'Wide World Importers',
      price: 180,
      imgUrl: '/assets/images/Small_Leather_Top.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Accessories|Handbags & Wallets|Handbags',
      collection: '',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719491383',
      name: 'Brown Leather Handle Satchel',
      description: 'Northwind Traders',
      price: 320,
      imgUrl: '/assets/images/Brown_Leather_Handle.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Accessories|Handbags & Wallets|Handbags',
      collection: '',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719491400',
      name: 'Medium Leather Shoulder Bag',
      description: 'Northwind Traders',
      price: 150,
      imgUrl: '/assets/images/91042_000_001.png',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Handbags & Wallets|Handbags',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719491374',
      name: 'Medium Red Leather Shoulder Bag',
      description: 'Northwind Traders',
      price: 290,
      imgUrl: '/assets/images/Medium_Red_Leather_Shoulder_Bag.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Handbags & Wallets|Handbags',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
  ],
});

const getWinterWearSearchResultWithQueryAlteration = () => ({
  resultCount: 4,
  alteredQuery: '',
  isLoading: false,
  originalQuery: 'winter wear',
  searchResultItems: [
    {
      id: '68719492941',
      name: 'Down Winter Jacket',
      description: 'Northwind Traders',
      price: 275,
      imgUrl: '/assets/images/Down_Winter_Jacket.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Coats & Jackets',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719492954',
      name: 'Down Jacket with Fur Trim',
      description: 'Northwind Traders',
      price: 320,
      imgUrl: '/assets/images/Down_Jacket_with_fur.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Coats & Jackets',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719497488',
      name: "Men's Denim Sports Jacket",
      description: 'Northwind Traders',
      price: 275,
      imgUrl: '/assets/images/Denim_Sports_Jacket.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Suits & Sportcoats',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719492969',
      name: "Men's Insulated Rain Coat",
      description: 'Northwind Traders',
      price: 70,
      imgUrl: '/assets/images/Insulated_rain_coat.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Coats & Jackets',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
  ],
});

const getSportsCapSearchResultWithQueryAlteration = () => ({
  resultCount: 7,
  isLoading: false,
  alteredQuery: '',
  originalQuery: 'sports cap',
  searchResultItems: [
    {
      id: '68719495950',
      name: 'Navy Low Profile Baseball Cap',
      description: 'Northwind Traders',
      price: 25,
      imgUrl: '/assets/images/91055_000_001.png',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Hats',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719495948',
      name: 'Red Low Profile Baseball Cap',
      description: 'Northwind Traders',
      price: 22,
      imgUrl: '/assets/images/91053_000_001.png',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Hats',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719495945',
      name: 'Low Profile Baseball Cap',
      description: 'Northwind Traders',
      price: 18,
      imgUrl: '/assets/images/91051_000_001.png',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Hats',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719495952',
      name: 'Black Twill Dressy Baseball Cap',
      description: 'Northwind Traders',
      price: 30,
      imgUrl: '/assets/images/91057_000_001.png',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Hats',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719495949',
      name: 'Grey Twill Ball Cap',
      description: 'Northwind Traders',
      price: 15,
      imgUrl: '/assets/images/91054_000_001.png',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Hats',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719495951',
      name: 'Tan Low Profile Baseball Cap',
      description: 'Northwind Traders',
      price: 27,
      imgUrl: '/assets/images/91056_000_001.png',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Accessories|Hats',
      collection: '',
      promotionDisplay: '',
      currency: '$',
    },
  ],
});

const getCasualDressSearchResultWithQueryAlteration = () => ({
  resultCount: 150,
  isLoading: false,
  alteredQuery: '',
  originalQuery: 'casual dress',
  searchResultItems: [
    {
      id: '68719494532',
      name: 'Sleeveless Belted Gradient Dress',
      description: 'Northwind Traders',
      price: 110,
      imgUrl: '/assets/images/sleevless_belted_dress.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719494546',
      name: 'Sleeveless Swing Dress',
      description: 'Northwind Traders',
      price: 165,
      imgUrl: '/assets/images/sleevless_swing_dress.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719494426',
      name: 'Long Sleeve Fitted A-line Dress',
      description: 'Wide World Importers',
      price: 170,
      imgUrl: '/assets/images/long_sleeve_fitted_dress.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719494454',
      name: 'Ruched Chiffon Shirt Dress',
      description: 'Wide World Importers',
      price: 145,
      imgUrl: '/assets/images/shiffon_shirt_dress.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719494437',
      name: 'Ruffle Sleeve Print Dress',
      description: 'Northwind Traders',
      price: 165,
      imgUrl: '/assets/images/Ruffle_Sleeve_Print_Dress.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719494403',
      name: 'Vintage Polka-dot Party  Dress',
      description: 'Wide World Importers',
      price: 290,
      imgUrl: '/assets/images/vintage_polka_dot.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: '',
      currency: '$',
    },
  ],
});

export const getMockedSearchResultWithQueryAlteration = (query: string): SearchResultModel => {
  switch (query) {
    case 'chocker':
      return getChockerSearchResultWithQueryAlteration();
    case 'party dress':
      return getPartyDressSearchResultWithQueryAlteration();
    case 'short handle bags':
      return getShortHandleBagsSearchResultWithQueryAlteration();
    case 'winter wear':
      return getWinterWearSearchResultWithQueryAlteration();
    case 'sports cap':
      return getSportsCapSearchResultWithQueryAlteration();
    case 'casual dress':
      return getCasualDressSearchResultWithQueryAlteration();
  }

  return {
    resultCount: 0,
    isLoading: false,
    alteredQuery: '',
    originalQuery: query,
    searchResultItems: [],
  };
};

const getShoesCompetitiveSearchResult = () => ({
  resultCount: 966,
  isLoading: false,
  alteredQuery: '',
  originalQuery: 'shoes',
  searchResultItems: [
    {
      id: '68719493395',
      name: 'Leather Tie Dress Shoe',
      description: 'Northwind Traders',
      price: 166,
      imgUrl: '/assets/images/Leather_Tie_Dress_Shoe.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493511',
      name: 'Brown Suede Walking Shoe',
      description: 'Northwind Traders',
      price: 114,
      imgUrl: '/assets/images/Brown_Suede_Walking_Shoe.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493415',
      name: 'Genuine Leather Tuxedo Shoe',
      description: 'Northwind Traders',
      price: 150,
      imgUrl: '/assets/images/Genuine_Leather_Tuxedo_Shoe.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Wedding',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493431',
      name: 'Black Leather Oxfords',
      description: 'Northwind Traders',
      price: 290,
      imgUrl: '/assets/images/Black_Leather_Oxfords.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493531',
      name: 'White Leather Athletic Sneaker',
      description: 'Wide World Importers',
      price: 90,
      imgUrl: '/assets/images/White_Leather_Athletic_Sneaker.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493462',
      name: 'Square Toe Leather Oxfords',
      description: 'Wide World Importers',
      price: 140,
      imgUrl: '/assets/images/Square_Toe_Leather_Oxfords.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Executive',
      promotionDisplay: '',
      currency: '$',
    },
  ],
});

const getCasualDressCompetitiveSearchResult = () => ({
  isLoading: false,
  resultCount: 125,
  alteredQuery: '',
  originalQuery: 'casual dress',
  searchResultItems: [
    {
      id: '68719493085',
      name: 'Casual Fit Button-Down Shirt',
      description: 'Wide World Importers',
      price: 65,
      imgUrl: '/assets/images/Casual_Fit_Button-Down_Shirt.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Dress Shirts',
      collection: 'Casual',
      promotionDisplay: undefined,
      currency: '$',
    },
    {
      id: '68719494538',
      name: 'Sleeveless Belted Gradient Dress',
      description: 'Northwind Traders',
      price: 110,
      imgUrl: '/assets/images/sleevless_belted_dress.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: undefined,
      currency: '$',
    },
    {
      id: '68719494546',
      name: 'Sleeveless Swing Dress',
      description: 'Northwind Traders',
      price: 165,
      imgUrl: '/assets/images/sleevless_swing_dress.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: undefined,
      currency: '$',
    },
    {
      id: '68719494424',
      name: 'Long Sleeve Fitted A-line Dress',
      description: 'Wide World Importers',
      price: 170,
      imgUrl: '/assets/images/long_sleeve_fitted_dress.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: undefined,
      currency: '$',
    },
    {
      id: '68719494458',
      name: 'Ruched Chiffon Shirt Dress',
      description: 'Wide World Importers',
      price: 145,
      imgUrl: '/assets/images/shiffon_shirt_dress.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: undefined,
      currency: '$',
    },
    {
      id: '68719494437',
      name: 'Ruffle Sleeve Print Dress',
      description: 'Northwind Traders',
      price: 165,
      imgUrl: '/assets/images/Ruffle_Sleeve_Print_Dress.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: undefined,
      currency: '$',
    },
  ],
});

const getWinterWearCompetitiveSearchResult = () => ({
  resultCount: 0,
  alteredQuery: '',
  isLoading: false,
  originalQuery: 'winter wear',
  searchResultItems: [],
});

const getSportsCapCompetitiveSearchResult = () => ({
  resultCount: 0,
  alteredQuery: '',
  isLoading: false,
  originalQuery: 'sports cap',
  searchResultItems: [],
});

const getShortHandleBagsCompetitiveSearchResult = () => ({
  resultCount: 0,
  alteredQuery: '',
  isLoading: false,
  originalQuery: 'short handle bags',
  searchResultItems: [],
});

const getPartyDressCompetitiveSearchResult = () => ({
  resultCount: 1,
  alteredQuery: '',
  isLoading: false,
  originalQuery: 'party dress',
  searchResultItems: [
    {
      id: '68719494401',
      name: 'Vintage Polka-dot Party  Dress',
      description: 'Wide World Importers',
      price: 290,
      imgUrl: '/assets/images/vintage_polka_dot.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Dresses',
      collection: 'Casual',
      promotionDisplay: undefined,
      currency: '$',
    },
  ],
});

const getChockerCompetitiveSearchResult = () => ({
  resultCount: 1,
  isLoading: false,
  alteredQuery: 'choker',
  originalQuery: 'chocker',
  searchResultItems: [
    {
      id: '68719492152',
      name: 'Stranded Glass Bead Choker with Pink Stone Pendant',
      description: 'Northwind Traders',
      price: 185,
      imgUrl: '/assets/images/Glass _Bead_Choker.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Accessories|Jewelry',
      collection: 'Anniversary',
      promotionDisplay: undefined,
      currency: '$',
    },
  ],
});

export const getMockedCompetitiveSearchResult = (query: string): SearchResultModel => {
  switch (query) {
    case 'shoes':
      return getShoesCompetitiveSearchResult();
    case 'chocker':
      return getChockerCompetitiveSearchResult();
    case 'sports cap':
      return getSportsCapCompetitiveSearchResult();
    case 'winter wear':
      return getWinterWearCompetitiveSearchResult();
    case 'party dress':
      return getPartyDressCompetitiveSearchResult();
    case 'casual dress':
      return getCasualDressCompetitiveSearchResult();
    case 'short handle bags':
      return getShortHandleBagsCompetitiveSearchResult();
  }

  return {
    resultCount: 0,
    isLoading: false,
    alteredQuery: '',
    originalQuery: query,
    searchResultItems: [],
  };
};

export const getMockedPersonalizedSearchResult = (): SearchResultModel => ({
  resultCount: 966,
  isLoading: false,
  alteredQuery: '',
  originalQuery: 'shoes',
  searchResultItems: [
    {
      id: '68719495398',
      name: 'Sandal Flat',
      description: 'Northwind Traders',
      price: 65,
      imgUrl: '/assets/images/Sandal_Flat.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Shoes',
      collection: 'Home',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719494894',
      name: 'Platform Suede Stileto Booties',
      description: 'Northwind Traders',
      price: 175,
      imgUrl: '/assets/images/Platform_Suede_Stileto_Booties.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Shoes',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719495285',
      name: 'Strappy Platform Pumps',
      description: 'Northwind Traders',
      price: 175,
      imgUrl: '/assets/images/Strappy_Platform_Pumps.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Shoes',
      collection: 'Engagement',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719495052',
      name: 'Open-toe Suede Pumps',
      description: 'Northwind Traders',
      price: 250,
      imgUrl: '/assets/images/Open-toe_Suede_Pumps.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Shoes',
      collection: 'Formal',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719495222',
      name: 'Open-toe Snakeskin Pumps',
      description: 'Northwind Traders',
      price: 265,
      imgUrl: '/assets/images/Open-toe_Snakeskin_Pumps.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Womenswear|Shoes',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719494998',
      name: 'Platform Leather Pumps',
      description: 'Wide World Importers',
      price: 360,
      imgUrl: '/assets/images/Platform_Leather_Pumps.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Womenswear|Shoes',
      collection: 'Formal',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
  ],
});

const getMockedOnSalePromotedSearchResult = () => ({
  isLoading: false,
  resultCount: 966,
  alteredQuery: '',
  originalQuery: 'shoes',
  searchResultItems: [
    {
      id: '68719493381',
      name: 'Leather Tie Dress Shoe',
      description: 'Northwind Traders',
      price: 166,
      imgUrl: '/assets/images/Leather_Tie_Dress_Shoe.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493513',
      name: 'Brown Suede Walking Shoe',
      description: 'Northwind Traders',
      price: 114,
      imgUrl: '/assets/images/Brown_Suede_Walking_Shoe.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493402',
      name: 'Genuine Leather Tuxedo Shoe',
      description: 'Northwind Traders',
      price: 150,
      imgUrl: '/assets/images/Genuine_Leather_Tuxedo_Shoe.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Wedding',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493435',
      name: 'Black Leather Oxfords',
      description: 'Northwind Traders',
      price: 290,
      imgUrl: '/assets/images/Black_Leather_Oxfords.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493523',
      name: 'White Leather Athletic Sneaker',
      description: 'Wide World Importers',
      price: 90,
      imgUrl: '/assets/images/White_Leather_Athletic_Sneaker.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493456',
      name: 'Square Toe Leather Oxfords',
      description: 'Wide World Importers',
      price: 140,
      imgUrl: '/assets/images/Square_Toe_Leather_Oxfords.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Executive',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
  ],
});

const getMockedBrandPromotedSearchResult = () => ({
  isLoading: false,
  resultCount: 966,
  alteredQuery: '',
  originalQuery: 'shoes',
  searchResultItems: [
    {
      id: '68719493303',
      name: "Men's Boat Shoes",
      description: 'Litware, Inc.',
      price: 225,
      imgUrl: "/assets/images/Men's_Boat_Shoes.jpg",
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493493',
      name: 'Traditional Canvas Sneaker',
      description: 'Litware, Inc.',
      price: 65,
      imgUrl: '/assets/images/Traditional_Canvas_Sneaker.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493395',
      name: 'Leather Tie Dress Shoe',
      description: 'Northwind Traders',
      price: 166,
      imgUrl: '/assets/images/Leather_Tie_Dress_Shoe.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493511',
      name: 'Brown Suede Walking Shoe',
      description: 'Northwind Traders',
      price: 114,
      imgUrl: '/assets/images/Brown_Suede_Walking_Shoe.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493415',
      name: 'Genuine Leather Tuxedo Shoe',
      description: 'Northwind Traders',
      price: 150,
      imgUrl: '/assets/images/Genuine_Leather_Tuxedo_Shoe.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Wedding',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493431',
      name: 'Black Leather Oxfords',
      description: 'Northwind Traders',
      price: 290,
      imgUrl: '/assets/images/Black_Leather_Oxfords.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
  ],
});

const getMockedPricePromotedSearchResult = () => ({
  isLoading: false,
  resultCount: 966,
  alteredQuery: '',
  originalQuery: 'shoes',
  searchResultItems: [
    {
      id: '68719493395',
      name: 'Leather Tie Dress Shoe',
      description: 'Northwind Traders',
      price: 166,
      imgUrl: '/assets/images/Leather_Tie_Dress_Shoe.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493511',
      name: 'Brown Suede Walking Shoe',
      description: 'Northwind Traders',
      price: 114,
      imgUrl: '/assets/images/Brown_Suede_Walking_Shoe.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Casual',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493415',
      name: 'Genuine Leather Tuxedo Shoe',
      description: 'Northwind Traders',
      price: 150,
      imgUrl: '/assets/images/Genuine_Leather_Tuxedo_Shoe.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Wedding',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719493435',
      name: 'Black Leather Oxfords',
      description: 'Northwind Traders',
      price: 290,
      imgUrl: '/assets/images/Black_Leather_Oxfords.jpg',
      onSale: 'true',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Formal',
      promotionDisplay: 'On Sale',
      currency: '$',
    },
    {
      id: '68719493462',
      name: 'Square Toe Leather Oxfords',
      description: 'Wide World Importers',
      price: 140,
      imgUrl: '/assets/images/Square_Toe_Leather_Oxfords.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Executive',
      promotionDisplay: '',
      currency: '$',
    },
    {
      id: '68719498124',
      name: 'Grand Cap Toe Leather Oxfords',
      description: 'Northwind Traders',
      price: 390,
      imgUrl: '/assets/images/Grand_Cap_Toe_Leather_Oxfords.jpg',
      onSale: 'false',
      categories: 'Fabrikam Fashion|Menswear|Shoes',
      collection: 'Executive',
      promotionDisplay: '',
      currency: '$',
    },
  ],
});

export const getMockedPromotedSearchResult = (boostItem: BoostItem): SearchResultModel => {
  switch (boostItem.key) {
    case 'OnSale':
      return getMockedOnSalePromotedSearchResult();
    case 'Brand':
      return getMockedBrandPromotedSearchResult();
    case 'Price':
      return getMockedPricePromotedSearchResult();
  }

  return {
    resultCount: 0,
    isLoading: false,
    alteredQuery: '',
    originalQuery: 'shoes',
    searchResultItems: [],
  };
};
