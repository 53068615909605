import { MembersListStyles } from './MembersList.types';

export const styles = (): MembersListStyles => {
  return {
    root: {
      overflow: 'auto',
      maxHeight: 500,
    },
  };
};
