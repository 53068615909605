import { NoItemsToDisplayStyleProps, NoItemsToDisplayStyles } from './NoItemsToDisplay.types';

export const styles = (props: NoItemsToDisplayStyleProps): NoItemsToDisplayStyles => {
  return {
    root: [
      'mbc-no-items-container',
      {
        background: props.theme.palette.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 22,
        fontWeight: 600,
        color: props.theme.palette.black,
        minHeight: 400,
        padding: 40,
        selectors: {
          '.ms-Button': {
            paddingRight: 40,
            paddingLeft: 40,
          },
        },
      },
    ],
    noItemsSubText: [
      'mbc-no-items-subtext',
      {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: 400,
      },
    ],
  };
};
