import { AggregationInterval } from '../../../restful-apis/dto/analytics/analytics';

// MUST UPDATE AGG. INTERVAL
export const getAggregationInterval = (startDate: Date, endDate: Date): AggregationInterval => {
  let dateDiffInMilli = endDate.getTime() - startDate.getTime();
  let ellapsedDays = dateDiffInMilli / (1000 * 3600 * 24);

  // As log as we didn't exceed two weeks limit show them daily
  if (Math.floor(ellapsedDays) <= 3) {
    return AggregationInterval.HOURLY;
  } else {
    return AggregationInterval.DAILY;
  }
};
