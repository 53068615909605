import {
  TenantsListStateActionTypes,
  SET_TENANTS_LIST,
  TenantsListState,
  TenantIdMapper,
  RESET_TENANTS_LIST,
  LOADING_TENANTS_LIST,
  Tenant,
  createEmptyTenant,
  SET_ACTIVE_TENANT,
  RESET_ACTIVE_TENANT,
  UPDATE_ACTIVE_TENANT,
} from '../types/tenantsList.d';
import { configureDefaultObjectWithId } from '../../utils';

export const createTenantIdMapper = (initialMapper: TenantIdMapper = {}) => {
  return configureDefaultObjectWithId<TenantIdMapper, Tenant>(initialMapper, createEmptyTenant());
};

const initialState: TenantsListState = {
  isLoading: true,
  activeTenant: createEmptyTenant(),
  tenantIdMapper: createTenantIdMapper(),
};

export function tenantsListReducer(state = initialState, action: TenantsListStateActionTypes): TenantsListState {
  switch (action.type) {
    case SET_TENANTS_LIST: {
      return {
        ...state,
        isLoading: false,
        tenantIdMapper: createTenantIdMapper({
          ...action.tenants.reduce((mapper: TenantIdMapper, tenant: Tenant) => {
            mapper[tenant.id] = tenant;
            return mapper;
          }, {}),
        }),
      };
    }
    case LOADING_TENANTS_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_TENANTS_LIST: {
      return {
        ...state,
        isLoading: true,
        tenantIdMapper: createTenantIdMapper(),
      };
    }
    case SET_ACTIVE_TENANT: {
      const activeTenant = state.tenantIdMapper[action.tenantId];
      if (activeTenant) {
        return {
          ...state,
          activeTenant: activeTenant,
        };
      }
      return state;
    }
    case UPDATE_ACTIVE_TENANT: {
      return {
        ...state,
        activeTenant: state.activeTenant.id === action.tenant.id ? action.tenant : state.activeTenant,
        tenantIdMapper: {
          ...state.tenantIdMapper,
          [action.tenant.id]: action.tenant,
        },
      };
    }
    case RESET_ACTIVE_TENANT: {
      return {
        ...state,
        activeTenant: createEmptyTenant(),
      };
    }

    default:
      return state;
  }
}
