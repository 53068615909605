import React, { useCallback, useMemo } from 'react';
import { IndexFieldEditorProps, IndexFieldEditorStyles, IndexFieldEditorStyleProps } from './IndexFieldEditor.types';
import {
  classNamesFunction,
  GroupHeader,
  DetailsRow,
  Dropdown,
  TextField,
  IDropdownOption,
  Stack,
  IconButton,
} from 'office-ui-fabric-react';
import { Checkbox } from '../../../../../components/common/DetailsList/Check';
import {
  getCvsIndexTypeOptions,
  getDefaultIndexTypeOptions,
  getComplexIndexTypeOptions,
  getGeoPointTypeOptions,
  getHotelScenarioTypeOptions,
  getDocumentScenarioTypeOptions,
  ProductIDKey,
  getHotelDocumentScenariosCommonOptions,
} from './IndexFieldEditor.config';
import { useTranslation } from 'react-i18next';
import { SearchIndexTypeConfig } from '../SchemaManagement.config';
import { GROUD_INDENT_WIDTH_IN_PIXELS } from './IndexFieldEditor.styles';
import { useSelector } from '../../../../../store/hooks';
import { SearchScenario } from '../../../../../store/types/tenantsList.d';

const getClassNames = classNamesFunction<IndexFieldEditorStyleProps, IndexFieldEditorStyles>();

export const IndexFieldEditorBase = (props: IndexFieldEditorProps) => {
  const {
    theme,
    styles,
    indexField,
    onInsertIndexField,
    onUpdateIndexField,
    onRemoveIndexField,
    enableGeoPointType,
    enableCvsFieldTypes,
    enableComplexFieldTypes,
  } = props;

  const { t } = useTranslation();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);

  const classNames = getClassNames(styles, {
    theme: theme!,
    groupLevel: props.groupLevel || 0,
    column: props.columns && props.columns.find(c => c.key === 'name'),
    complexType:
      !!indexField.type &&
      !!SearchIndexTypeConfig[indexField.type] &&
      SearchIndexTypeConfig[indexField.type].dataType === 'Object',
  });

  const indexFieldTypeOptions = useMemo(() => {
    let indexFieldTypes = getDefaultIndexTypeOptions();
    if (enableCvsFieldTypes) {
      indexFieldTypes = indexFieldTypes.concat(getCvsIndexTypeOptions());
    }
    if (enableComplexFieldTypes) {
      indexFieldTypes = indexFieldTypes.concat(getComplexIndexTypeOptions());
    }
    if (enableGeoPointType) {
      indexFieldTypes = indexFieldTypes.concat(getGeoPointTypeOptions());
    }

    if (
      activeTenant.searchScenario === SearchScenario.Document ||
      activeTenant.searchScenario === SearchScenario.Hotel
    ) {
      indexFieldTypes = indexFieldTypes.filter(type => type.key !== ProductIDKey);
      indexFieldTypes = indexFieldTypes.concat(getHotelDocumentScenariosCommonOptions());
    }

    if (activeTenant.searchScenario === SearchScenario.Hotel) {
      indexFieldTypes = indexFieldTypes.concat(getHotelScenarioTypeOptions());
    }

    if (activeTenant.searchScenario === SearchScenario.Document) {
      indexFieldTypes = indexFieldTypes.concat(getDocumentScenarioTypeOptions());
    }

    return indexFieldTypes;
  }, [activeTenant.searchScenario, enableComplexFieldTypes, enableCvsFieldTypes, enableGeoPointType]);

  const onRenderIndexFieldFeature = useCallback(
    (feature: string, checked: boolean) => (
      <Checkbox checked={checked} onChange={(checked: boolean) => onUpdateIndexField(feature, checked)} />
    ),
    [onUpdateIndexField],
  );

  const onRenderIndexFieldName = useCallback(
    (name: string) => (
      <div className={classNames.primaryCell}>
        <TextField
          value={name}
          styles={classNames.subComponentStyles.textField}
          ariaLabel={t('schema-management.index-field-editor.name-aria-label')}
          placeholder={t('schema-management.index-field-editor.name-placeholder')}
          onChange={(ev: any, value?: string) => onUpdateIndexField('name', value || '')}
        />
      </div>
    ),
    [classNames.primaryCell, classNames.subComponentStyles.textField, onUpdateIndexField, t],
  );

  const onRenderIndexFieldType = useCallback(
    (selectedType: string) => (
      <Dropdown
        selectedKey={selectedType}
        options={indexFieldTypeOptions}
        ariaLabel={t('schema-management.index-field-editor.type-aria-label')}
        placeholder={t('schema-management.index-field-editor.type-placeholder')}
        onChange={(ev: any, option?: IDropdownOption) =>
          onUpdateIndexField('type', !!option ? option.key.toString() : '')
        }
        styles={classNames.subComponentStyles.dropdown}
        calloutProps={{
          styles: classNames.subComponentStyles.callout,
        }}
      />
    ),
    [
      classNames.subComponentStyles.callout,
      classNames.subComponentStyles.dropdown,
      indexFieldTypeOptions,
      onUpdateIndexField,
      t,
    ],
  );

  const onRenderEditActions = useCallback(
    () => (
      <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 4 }}>
        <IconButton
          className={classNames.action}
          onClick={() => onRemoveIndexField()}
          iconProps={{ iconName: 'Remove' }}
        />
      </Stack>
    ),
    [classNames.action, onRemoveIndexField],
  );

  const onRenderIndexFieldColumn = useCallback(
    (key: string, value: string | boolean) => {
      switch (key) {
        case 'name':
          return onRenderIndexFieldName(value as string);
        case 'type':
          return onRenderIndexFieldType(value as string);
        case 'searchable':
          return onRenderIndexFieldFeature(key, value as boolean);
        case 'filterable':
          return onRenderIndexFieldFeature(key, value as boolean);
        case 'retrievable':
          return onRenderIndexFieldFeature(key, value as boolean);
        case 'facetable':
          return onRenderIndexFieldFeature(key, value as boolean);
        case 'sortable':
          return onRenderIndexFieldFeature(key, value as boolean);
        default:
          return null;
      }
    },
    [onRenderIndexFieldName, onRenderIndexFieldType, onRenderIndexFieldFeature],
  );

  const onRenderGroupHeader = useCallback(
    () => (
      <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} grow>
        {onRenderIndexFieldName(indexField.name)}
        <Stack
          horizontal
          horizontalAlign={'end'}
          verticalAlign={'center'}
          style={{ margin: '11px 8px 11px 12px' }}
          tokens={{ childrenGap: 4 }}
        >
          <IconButton
            className={classNames.action}
            iconProps={{ iconName: 'Add' }}
            onClick={() => onInsertIndexField && onInsertIndexField()}
          />
          <IconButton
            className={classNames.action}
            onClick={() => onRemoveIndexField()}
            iconProps={{ iconName: 'Remove' }}
          />
        </Stack>
      </Stack>
    ),
    [indexField.name, classNames.action, onInsertIndexField, onRemoveIndexField, onRenderIndexFieldName],
  );

  return !!indexField.type &&
    !!SearchIndexTypeConfig[indexField.type] &&
    SearchIndexTypeConfig[indexField.type].dataType === 'Object' ? (
    <GroupHeader
      {...props}
      onRenderTitle={onRenderGroupHeader}
      indentWidth={GROUD_INDENT_WIDTH_IN_PIXELS}
      styles={classNames.subComponentStyles.groupHeader}
    />
  ) : (
    <DetailsRow
      {...props}
      itemIndex={-1}
      styles={classNames.subComponentStyles.detailsRow}
      item={{
        key: indexField.key,
        options: onRenderEditActions(),
        name: onRenderIndexFieldColumn('name', indexField.name),
        type: onRenderIndexFieldColumn('type', indexField.type),
        sortable: onRenderIndexFieldColumn('sortable', indexField.features.sortable),
        facetable: onRenderIndexFieldColumn('facetable', indexField.features.facetable),
        filterable: onRenderIndexFieldColumn('filterable', indexField.features.filterable),
        searchable: onRenderIndexFieldColumn('searchable', indexField.features.searchable),
        retrievable: onRenderIndexFieldColumn('retrievable', indexField.features.retrievable),
      }}
    />
  );
};
