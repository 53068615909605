import { RoleEditorStyles, RoleEditorStyleProps } from './RoleEditor.types';

export const styles = (props: RoleEditorStyleProps): RoleEditorStyles => {
  return {
    root: ['mbc-role-editor', props.className],
    editorHeader: [
      'mbc-role-editor-header',
      {
        maxWidth: '65%',
        selectors: {
          '.ms-Button': {
            height: 30,
          },
        },
      },
    ],
    sectionSeparator: [
      'mbc-section-separator',
      {
        background: props.theme.palette.neutralLight,
        width: '100%',
        height: 1,
      },
    ],
  };
};
