import React, { Component } from 'react';
import SearchDemo, { Scenario } from '../../../common/search-demo/searchDemo';
import styles from './customizationsA.module.scss';
import { SearchResult } from '../../../../../store/types/searchDemo';

interface CustomizationsAProps {
  promotedASearchResults: SearchResult;
  minHeight: number;
  componentRef: React.RefObject<HTMLDivElement>;
}

class CustomizationsA extends Component<CustomizationsAProps> {
  render() {
    return (
      <div
        ref={this.props.componentRef}
        className={styles.wrapper}
        style={{ minHeight: this.props.minHeight > 0 ? this.props.minHeight : '100vh' }}
      >
        <SearchDemo
          scenario={Scenario.BusinessOptimizations}
          searchDemoTitle={'Search without business optimizations'}
          searchResultLabel={<div></div>}
          SearchResultItems={this.props.promotedASearchResults.searchResultItems.map(item => ({
            ...item,
            showSale: true,
          }))}
          invisibleSearchBox={true}
          showPoweredBy={false}
        ></SearchDemo>
      </div>
    );
  }
}

export default CustomizationsA;
