const localeTimeOptions = {
  day: '2-digit',
  month: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

export const getLocaleTimeNow = () => new Date().toLocaleString('en-us', localeTimeOptions);

export const getLocaleDateTime = (unixTime: number) =>
  new Date(unixTime * 1000).toLocaleString('en-us', localeTimeOptions);

export function generateUniqueIdentifier() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

// TODO: revise this generateUniqueId implementation vs generateUniqueIdentifier and only keep one of them
let uniqueId = 0;

export function generateUniqueId(): number {
  return uniqueId++;
}

interface KeyValueArray<T> {
  [index: string]: T[];
}

declare type GetValue<T> = (key: T) => string;

export function groupBy<T>(array: T[], getValue: GetValue<T>): KeyValueArray<T> {
  return array.reduce((objectsByKeyValue: KeyValueArray<T>, obj: T) => {
    const value = getValue(obj);
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
}

declare type Equals<T> = (first: T, second: T) => boolean;

export function distinct<T>(array: T[], equals: Equals<T>) {
  return array.filter((value, index, array) => !array.find((v, i) => equals(v, value) && i > index));
}

interface IndexableObject<V> {
  [key: string]: V;
}

interface ObjectWithId {
  id: string;
}

/*
 * Configures the default returned object when key is not found on an indexable object. And keys are meant to be case insensitive.
 */
export function configureDefaultObjectWithId<T extends IndexableObject<V>, V extends ObjectWithId>(
  initial: T,
  value: V,
) {
  var handler = {
    get: function(target: T, name: string): V {
      return target.hasOwnProperty(name.toLowerCase()) ? target[name.toLowerCase()] : value;
    },
    set: function(target: IndexableObject<V>, name: string, value: V): boolean {
      target[name.toLowerCase()] = { ...value, id: value.id.toLowerCase() };
      return true;
    },
  };

  return new Proxy(initial, handler);
}

/*
 * Configures the default returned object when key is not found on an indexable object.
 */
export function configureDefaultObject<T extends IndexableObject<V>, V>(initial: T, value: V) {
  var handler = {
    get: function(target: T, name: string): V {
      return target.hasOwnProperty(name) ? target[name] : value;
    },
  };

  return new Proxy(initial, handler);
}

export function flat<T>(array: T[][]) {
  return array.reduce((prev, curr) => prev.concat(curr), []);
}

// All dates must be in UTC for now.
export function addDays(days: number, date: Date): Date {
  date.setDate(date.getDate() + days);
  return date;
}

export enum ListItemsOrder {
  ASCENDING,
  DESCENDING,
}

export function splitIntegerDecimal(integer: number) {
  return {
    integer: integer > 0 ? Math.floor(integer) : Math.ceil(integer),
    decimal: Math.round((integer % 1) * 100),
  };
}

export function filterMap<T, K extends keyof T>(mapper: T, predicate: (item: T[K]) => boolean): T[K][] {
  return Object.values(mapper).filter(predicate);
}

export function isArray(target: any | any[]): target is any[] {
  return Array.isArray(target);
}

export const getLatestJob = <T extends { createdAt: string }>(jobs: T[]): T | null => {
  const sortedJobs = jobs.sort((a, b) => {
    const createAtDateA = new Date(a.createdAt);
    const createAtDateB = new Date(b.createdAt);
    return createAtDateA > createAtDateB ? -1 : createAtDateA < createAtDateB ? 1 : 0;
  });
  return sortedJobs.length > 0 ? sortedJobs[0] : null;
};

export const isAuthorized = (userPermissions: string[], minimumPermissions: string | string[]): boolean => {
  if (!minimumPermissions) {
    return true;
  }
  if (typeof minimumPermissions === 'string') {
    return !minimumPermissions || userPermissions.some(permission => minimumPermissions === permission);
  }
  if (minimumPermissions.length === 0) {
    return true;
  }
  return userPermissions.some(permission => minimumPermissions.includes(permission));
};
