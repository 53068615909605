import {
  SearchIndex as SearchIndexModel,
  TransformationConfig as TransformationConfigModel,
} from '../store/types/searchIndex.d';
import {
  mapSearchIndexDtoToModel,
  mapSearchIndexListDtoToModel,
  mapSearchIndexModelToDTO,
  mapTransformationConfigDtoToModel,
} from './mappers/searchIndexMapper';

import { DevOpsCommitMetadata } from '../store/types/devOps';
import HttpClient from './http-client/httpClient';
import { SearchIndexList as SearchIndexListDTO } from './dto/search-index/searchIndexList';
import { TransformationConfig as TransformationConfigDTO } from './dto/search-index/transformationConfig';
import { getDevOpsCommitHeaders } from './devops.api';

function getIndexListAPIUri(tenantId: string): string {
  return `https://commerce.bing.com/api/ingestion/v1/${tenantId}/indexes`;
}

function getIndexAPIUri(tenantId: string, indexId?: string): string {
  return `https://commerce.bing.com/api/ingestion/v1/${tenantId}/indexes/${indexId}`;
}

function getTransformationConfigBaseUri(tenantId: string, indexId: string): string {
  return `https://commerce.bing.com/api/ingestion/v1/${tenantId}/indexes/${indexId}/transformation`;
}

function getAdminApiV2BaseUri(tenantId: string): string {
  return `https://commerce.bing.com/api/admin/v2/tenants/${tenantId}/environments`;
}

export function getSearchIndexList(tenantId: string): Promise<SearchIndexModel[]> {
  return HttpClient.get<SearchIndexListDTO>({
    url: getIndexListAPIUri(tenantId),
    authInfo: {
      tenantId: tenantId,
    },
  }).then(mapSearchIndexListDtoToModel);
}

export function getSearchIndex(tenantId: string, searchIndexId: string): Promise<SearchIndexModel> {
  return HttpClient.get<SearchIndexListDTO>({
    url: getIndexAPIUri(tenantId, searchIndexId),
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndexId,
    },
  }).then(mapSearchIndexDtoToModel);
}

export function getTransformationConfig(tenantId: string, indexId: string): Promise<TransformationConfigModel> {
  return HttpClient.get<TransformationConfigDTO>({
    url: `${getTransformationConfigBaseUri(tenantId, indexId)}`,
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
    supressNotificationOnFailure: true,
  })
    .then(config => mapTransformationConfigDtoToModel(config, indexId))
    .catch(() => {
      return Promise.resolve({ instanceId: indexId, transformationScript: undefined });
    });
}

export function setTransformationConfig(
  tenantId: string,
  indexId: string,
  transformationScript: string,
): Promise<TransformationConfigModel> {
  return HttpClient.put<TransformationConfigDTO>({
    url: `${getTransformationConfigBaseUri(tenantId, indexId)}`,
    supressNotificationOnFailure: true,
    body: transformationScript,
    headers: new Headers({
      'Content-Type': 'text/plain',
    }),
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
  }).then(mapTransformationConfigDtoToModel);
}

export function deleteTransformationConfig(tenantId: string, indexId: string): void {
  HttpClient.delete<TransformationConfigDTO>({
    url: `${getTransformationConfigBaseUri(tenantId, indexId)}`,
    supressNotificationOnFailure: true,
    authInfo: {
      tenantId: tenantId,
      indexId: indexId,
    },
    body: {},
  }).catch(() => console.log('No transformation script found'));
}

/**
 * Constructs a post http request that returns the response in `SearchIndex` model.
 *
 * @param tenantId      The environment tenant.
 * @param searchIndex      The DTO body to send with the request.
 *
 * @return An `SearchIndex` model of the response, with the response body as an `SearchIndex` DTO.
 */
export function createSearchIndex(
  tenantId: string,
  environmentId: string,
  searchIndex: SearchIndexModel,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<SearchIndexModel> {
  const searchIndexDto = mapSearchIndexModelToDTO(searchIndex);
  // TODO: remove the tenant specific code
  if (tenantId === '9396ad26-84dc-4bad-eb01-08d741f1b7a5') {
    searchIndexDto.segment = 'Clothing';
  }
  return HttpClient.post<SearchIndexListDTO>({
    url: `${getAdminApiV2BaseUri(tenantId)}/${environmentId}/indexes`,
    body: searchIndexDto,
    supressNotificationOnFailure: true,
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndex.id,
    },
  })
    .then(mapSearchIndexDtoToModel)
    .then(createdSearchIndex =>
      !!createdSearchIndex.transformationScript
        ? setTransformationConfig(tenantId, createdSearchIndex.id, createdSearchIndex.transformationScript).then(
            config => {
              createdSearchIndex.transformationScript = config.transformationScript;
              return createdSearchIndex;
            },
          )
        : createdSearchIndex,
    );
}

export function updateSearchIndex(
  tenantId: string,
  searchIndex: SearchIndexModel,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<SearchIndexModel> {
  const searchIndexDto = mapSearchIndexModelToDTO(searchIndex);
  return HttpClient.put<SearchIndexListDTO>({
    url: getIndexAPIUri(tenantId, searchIndexDto.id),
    body: searchIndexDto,
    supressNotificationOnFailure: true,
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: {
      tenantId: tenantId,
      indexId: searchIndex.id,
    },
  })
    .then(mapSearchIndexDtoToModel)
    .then(updatedIndex => {
      if (searchIndex.transformationScript) {
        return setTransformationConfig(tenantId, updatedIndex.id, searchIndex.transformationScript).then(config => {
          updatedIndex.transformationScript = config.transformationScript;
          return updatedIndex;
        });
      }
      deleteTransformationConfig(tenantId, updatedIndex.id);
      updatedIndex.transformationScript = undefined;
      return updatedIndex;
    });
}
