import React from 'react';
import { PageTemplate } from '../../components/common/PageTemplate';
import { SideNavMode } from '../../components/common/PageTemplate/PageTemplate.types';
import { RoleList } from '../../components/RoleList';
import { useTranslation } from 'react-i18next';

export const RoleListBase = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate
      pageHeaderProps={{
        title: t('roles-list.roles-title'),
        titleCaption: t('roles-list.roles-sub-title'),
        infoProps: {
          headline: t('roles-list.info-headline'),
          content: t('roles-list.info-content'),
          linkProps: {
            linkText: t('roles-list.info-link'),
            to: '/docs/Portal%20Documentation/#roles-and-permissions',
          },
        },
      }}
      breadcrumbVisible
      sideNavMode={SideNavMode.None}
      sideNavOnClick={() => {}}
      onRenderContent={() => <RoleList />}
    />
  );
};
