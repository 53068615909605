import { SearchPreviewStyleProps, SearchPreviewStyles } from './SearchPreview.types';
import { getFlexColumnStyles } from '../../../../components/common/Styles';

export const styles = (props: SearchPreviewStyleProps): SearchPreviewStyles => ({
  root: ['mbc-SearchPreview', getFlexColumnStyles(), { height: '100%' }, props.className],
  content: [
    'mbc-preview-content',
    getFlexColumnStyles(),
    {
      position: 'relative',
      gridColumn: '2 / 3',
    },
  ],
  resultContainer: [
    {
      position: 'relative',
      height: 'calc(100vh - 285px)',
    },
  ],
  scrollablePane: [
    'mbc-result',
    {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflowY: 'auto',
      position: 'absolute',
    },
  ],
  tutorialSubheader: [
    'mbc-tutorials-subheader',
    {
      fontWeight: 600,
    },
  ],
  tutorialToggle: [
    'mbc-tutorials-toggle',
    {
      paddingRight: 24,
      backgroundColor: props.theme.palette.white,
    },
  ],
  subComponentStyles: {
    card: {
      root: {
        boxShadow: 'none',
        selectors: {
          '.mbc-content-card-body': {
            padding: 0,
            border: 'none',
          },
          '.mbc-content-card-header': {
            border: 'none',
          },
        },
      },
    },
    actionsCard: {
      root: {
        selectors: {
          '.ms-card-body': {
            padding: 0,
          },
        },
      },
    },
  },
});
