import { Action } from 'redux';

export const SET_SYNONYM_SET_LIST = 'SET_SYNONYM_SET_LIST';
export const RESET_SYNONYM_SET_LIST = 'RESET_SYNONYM_SET_LIST';
export const SET_ACTIVE_SYNONYM_SET = 'SET_ACTIVE_SYNONYM_SET';
export const RESET_ACTIVE_SYNONYM_SET = 'RESET_ACTIVE_SYNONYM_SET';
export const LOADING_SYNONYM_SET_LIST = 'LOADING_SYNONYM_SET_LIST';
export const CREATE_OR_UPDATE_SYNONYM_SET = 'CREATE_OR_UPDATE_SYNONYM_SET';
export const DELETE_SYNONYM_SET = 'DELETE_SYNONYM_SET';

export interface SynonymSet {
  id: string;
  synonymList: string[];
}

export interface SynonymSetIdMapper {
  [key: string]: SynonymSet;
}

export interface SynonymSetState {
  isLoading: boolean;
  activeSynonymSet: SynonymSet;
  synonymSetIdMapper: SynonymSetIdMapper;
}

interface SetSynonymSetList extends Action<typeof SET_SYNONYM_SET_LIST> {
  synonymSetList: SynonymSet[];
}

interface SetActiveSynonymSet extends Action<typeof SET_ACTIVE_SYNONYM_SET> {
  id: string;
}

interface CreateOrUpdateSynonymSet extends Action<typeof CREATE_OR_UPDATE_SYNONYM_SET> {
  synonymSet: SynonymSet;
}

interface DeleteSynonymSet extends Action<typeof DELETE_SYNONYM_SET> {
  id: string;
}

export type SynonymSetActionTypes =
  | SetSynonymSetList
  | SetActiveSynonymSet
  | CreateOrUpdateSynonymSet
  | DeleteSynonymSet
  | Action<typeof RESET_SYNONYM_SET_LIST>
  | Action<typeof RESET_ACTIVE_SYNONYM_SET>
  | Action<typeof LOADING_SYNONYM_SET_LIST>;
