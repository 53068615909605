import { DeepLinkStyles, DeepLinkStyleProps } from './DeepLink.types';

export const styles = (props: DeepLinkStyleProps): DeepLinkStyles => ({
  title: [
    'ms-deep-link-title',
    {
      fontSize: 18,
      lineHeight: 21,
    },
  ],
  caption: [
    'ms-deep-link-subtitle',
    {
      fontSize: 14,
      lineHeight: 21,
      color: props.theme.palette.neutralPrimary,
    },
  ],
  searchbox: [
    'ms-search-box',
    {
      border: '1px solid rgba(0, 0, 0, 0.1)',
    },
  ],
  dropdown: [
    'ms-deep-link-dropdown',
    {
      width: 250,
      selectors: {
        '.ms-Dropdown.is-disabled': {
          selectors: {
            '.ms-Dropdown-title': {
              border: 'none',
            },
          },
        },
        '.ms-Dropdown-title': {
          border: '1px solid rgba(0, 0, 0, 0.1)',
        },
        '.ms-Dropdown-label': {
          paddingBottom: 12,
        },
      },
    },
  ],
  subComponentStyles: {
    callout: {
      calloutMain: {
        maxHeight: '250px !important',
      },
    },
  },
});
