/* eslint-disable @typescript-eslint/no-unused-vars */
import HttpClient from './http-client/httpClient';
import {
  QueryCount as TopQueryModel,
  QueryCount as NoResultQueryModel,
  SearchParameter as FilterUsedModel,
  SearchMetricsModel,
  TotalPercentagContent as ResponseCodeModel,
  SearchParameter as QueryLanguageModel,
  TotalCount as TotalSearchRequestsModel,
  TotalCount as AverageSearchLatencyModel,
  SearchParameter as SortedByParameterModel,
  TotalPercentage as TotalDistinctQueriesModel,
  TotalPercentage as TotalSearchRequestsWithNoResultsModel,
  TotalPercentagContent as FiltersUsageModel,
  TotalPercentagContent as FiltersDistributionModel,
  TotalPercentagContent as SearchInstancesChartModel,
  TotalPercentagContent as ServingRegionsModel,
} from '../store/types/analytics';
import {
  mapAnalyticsRequestToDto,
  mapSearchMetricsDtoToModel,
  mapPercentageResponseDtoToModel,
  mapContentCountDtoToStringArray,
  mapAnalyticsAggregatedRequestToDto,
  mapContentCountDtoToQueryCountModel,
  mapContentCountDtoToSearchParameterModel,
  mapContentCountDtoToTotalCountModel,
  mapContentCountPercentageDtoToPercentagContentModel,
} from './mappers/analyticsMapper';
import {
  AggregationInterval,
  ContentCountResponse as TopQueryDTO,
  ContentCountPercentageResponse as ResponseCodeDTO,
  ContentCountResponse as NoResultQueryDTO,
  ContentCountResponse as AvailableMarketsDTO,
  ContentCountResponse as QueryLanguageDTO,
  SearchMetricsResponse as SearchMetricsDTO,
  BaseCount as TotalSearchRequestsDTO,
  BaseCount as AverageSearchLatencyDTO,
  // ContentCountResponse as SortedByParameterDTO,
  PercentageResponse as TotalDistinctQueriesDTO,
  PercentageResponse as TotalSearchRequestsWithNoResultsDTO,
  ContentCountPercentageResponse as FiltersUsageDTO,
  ContentCountPercentageResponse as FiltersDistributionDTO,
  ContentCountPercentageResponse as SearchInstancesChartDTO,
  ContentCountPercentageResponse as SearchInstancesFilterDTO,
  ContentCountPercentageResponse as ServingRegionsDTO,
} from './dto/analytics/analytics';
import _ from 'lodash';
import { addDays } from '../utils';
import moment from 'moment';

function getAnalyticsBaseUri(tenantId: string, indexId: string): string {
  return `https://commerce.bing.com/api/analytics/v1/${tenantId}/indexes/${indexId}`;
}

/**
 * Constructs a get http request that returns search metrics over a period of time.
 *
 * @param tenantId  The search metrics over a period of time.
 *
 * @return The search metrics over a period of time, with the response body as an `TotalQueriesLast7Days` array.
 */
export function getTotalSearchRequestsOverTime(
  tenantId: string,
  indexId: string,
  aggregationInterval: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SearchMetricsModel> {
  return HttpClient.post<SearchMetricsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/trafficovertime`,
    body: mapAnalyticsAggregatedRequestToDto(
      aggregationInterval,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(searchMetricsDTO => mapSearchMetricsDtoToModel(searchMetricsDTO, start, end, aggregationInterval));
}

export function getSuccessfulRequestsOverTime(
  tenantId: string,
  indexId: string,
  aggregationInterval: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SearchMetricsModel> {
  return HttpClient.post<SearchMetricsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/successfultrafficovertime`,
    body: mapAnalyticsAggregatedRequestToDto(
      aggregationInterval,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(searchMetricsDTO => mapSearchMetricsDtoToModel(searchMetricsDTO, start, end, aggregationInterval));
}

export function getUserErrorRequestsOverTime(
  tenantId: string,
  indexId: string,
  aggregationInterval: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SearchMetricsModel> {
  return HttpClient.post<SearchMetricsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/clienterrortrafficovertime`,
    body: mapAnalyticsAggregatedRequestToDto(
      aggregationInterval,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(searchMetricsDTO => mapSearchMetricsDtoToModel(searchMetricsDTO, start, end, aggregationInterval));
}

export function getFailedRequestsOverTime(
  tenantId: string,
  indexId: string,
  aggregationInterval: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SearchMetricsModel> {
  return HttpClient.post<SearchMetricsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/failedtrafficovertime`,
    body: mapAnalyticsAggregatedRequestToDto(
      aggregationInterval,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(searchMetricsDTO => mapSearchMetricsDtoToModel(searchMetricsDTO, start, end, aggregationInterval));
}

export function getDistinctSearchRequestsOverTime(
  tenantId: string,
  indexId: string,
  aggregationInterval: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SearchMetricsModel> {
  return HttpClient.post<SearchMetricsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/distinctqueriesovertime`,
    body: mapAnalyticsAggregatedRequestToDto(
      aggregationInterval,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(searchMetricsDTO => mapSearchMetricsDtoToModel(searchMetricsDTO, start, end, aggregationInterval));
}

/**
 * Constructs a get http request that returns the top queries.
 *
 * @param tenantId       The top queries.
 *
 * @return The top queries, with the response body as an `TopQuery` array.
 */
export function getTopQueries(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  count: number,
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<TopQueryModel[]> {
  return HttpClient.post<TopQueryDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/topqueries?count=${count}`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToQueryCountModel);
}

/**
 * Constructs a get http request that returns the top queries.
 *
 * @param tenantId       The top queries.
 *
 * @return The top queries, with the response body as an `TopQuery` array.
 */
export function getNoResultsQueries(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  count: number,
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<NoResultQueryModel[]> {
  return HttpClient.post<NoResultQueryDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/noresultsqueries?count=${count}`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToQueryCountModel);
}

/**
 * Constructs a get http request that returns the total queries over a period of time.
 *
 * @param tenantId       The total queries last 7 days.
 *
 * @return The total queries last 7 days, with the response body as an `TotalQueriesLast7Days` array.
 */
export function getSortedByParameters(
  instanceId: string,
  responseCode: string[],
  query: string,
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SortedByParameterModel[]> {
  // return HttpClient.post<SortedByParameterDTO>(
  //   `${getAnalyticsBaseUri(instanceId)}/sortby`,
  //   mapAnalyticsQueryableRequestToDto(responseCode, markets, query, start, end),
  // ).then(mapContentCountDtoToSearchParameterModel);
  return Promise.resolve([]);
}

/**
 * Constructs a get http request that returns the total queries over a period of time.
 *
 * @param tenantId       The total queries last 7 days.
 *
 * @return The total queries last 7 days, with the response body as an `TotalQueriesLast7Days` array.
 */
export function getQueryLanguages(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<QueryLanguageModel[]> {
  return HttpClient.post<QueryLanguageDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/languages`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToSearchParameterModel);
}

export function getRegionsDistribution(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<ServingRegionsModel[]> {
  return HttpClient.post<ServingRegionsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/regions`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountPercentageDtoToPercentagContentModel);
}

export function getRegionsDistributionFilter(tenantId: string, indexId: string): Promise<string[]> {
  return HttpClient.post<ServingRegionsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/regions`,
    body: mapAnalyticsRequestToDto(
      [],
      addDays(-89, new Date(moment.utc().format('YYYY-MM-DD HH:mm:ss'))),
      new Date(moment.utc().format('YYYY-MM-DD HH:mm:ss')),
      [],
      [],
      [],
      [],
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToStringArray);
}

export function getResponseCodes(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<ResponseCodeModel[]> {
  return HttpClient.post<ResponseCodeDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/responsecodes`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountPercentageDtoToPercentagContentModel);
}

export function getResponseCodesFilter(tenantId: string, indexId: string): Promise<string[]> {
  return HttpClient.post<ResponseCodeDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/responsecodes`,
    body: mapAnalyticsRequestToDto(
      [],
      addDays(-89, new Date(moment.utc().format('YYYY-MM-DD HH:mm:ss'))),
      new Date(moment.utc().format('YYYY-MM-DD HH:mm:ss')),
      [],
      [],
      [],
      [],
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToStringArray);
}

export function getFiltersUsage(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<FiltersUsageModel[]> {
  return HttpClient.post<FiltersUsageDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/filtersusage`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountPercentageDtoToPercentagContentModel);
}

export function getFiltersDistribution(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<FiltersDistributionModel[]> {
  return HttpClient.post<FiltersDistributionDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/filters`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountPercentageDtoToPercentagContentModel);
}

export function getSearchInstancesChart(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SearchInstancesChartModel[]> {
  return HttpClient.post<SearchInstancesChartDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/searchinstances`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountPercentageDtoToPercentagContentModel);
}

export function getSearchInstancesFilter(tenantId: string, indexId: string): Promise<string[]> {
  return HttpClient.post<SearchInstancesFilterDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/searchinstances`,
    body: mapAnalyticsRequestToDto(
      [],
      addDays(-89, new Date(moment.utc().format('YYYY-MM-DD HH:mm:ss'))),
      new Date(moment.utc().format('YYYY-MM-DD HH:mm:ss')),
      [],
      [],
      [],
      [],
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToStringArray);
}

export function getTotalSearchRequests(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<TotalSearchRequestsModel> {
  return HttpClient.post<TotalSearchRequestsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/totaltraffic`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToTotalCountModel);
}

export function getTotalDistinctQueries(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<TotalDistinctQueriesModel> {
  return HttpClient.post<TotalDistinctQueriesDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/totaldistinctqueries`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapPercentageResponseDtoToModel);
}

/**
 * Constructs a get http request that returns the total queries over a period of time.
 *
 * @param tenantId       The total queries last 7 days.
 *
 * @return The total queries last 7 days, with the response body as an `TotalQueriesLast7Days` array.
 */
export function getAverageSearchLatency(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<AverageSearchLatencyModel> {
  return HttpClient.post<AverageSearchLatencyDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/averagelatency`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToTotalCountModel);
}

export function getSearchLatencyPercentile(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
  percentile: number,
): Promise<AverageSearchLatencyModel> {
  return HttpClient.post<AverageSearchLatencyDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/percentilelatency?percentile=${percentile}`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToTotalCountModel);
}

/**
 * Constructs a get http request that returns the total queries over a period of time.
 *
 * @param tenantId       The total queries last 7 days.
 *
 * @return The total queries last 7 days, with the response body as an `TotalQueriesLast7Days` array.
 */
export function getTotalSearchRequestsWithNoResults(
  tenantId: string,
  indexId: string,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<TotalSearchRequestsWithNoResultsModel> {
  return HttpClient.post<TotalSearchRequestsWithNoResultsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/totalnoresultstraffic`,
    body: mapAnalyticsRequestToDto(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapPercentageResponseDtoToModel);
}

export function getAvailableMarkets(tenantId: string, indexId: string): Promise<string[]> {
  return HttpClient.post<AvailableMarketsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/markets`,
    body: {},
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapContentCountDtoToStringArray);
}

export function getSearchLatencyOverTime(
  tenantId: string,
  indexId: string,
  percentile: number,
  aggregationInterval: AggregationInterval,
  responseCode: string[],
  start: Date,
  end: Date,
  markets: string[],
  regions: string[],
  searchResultsStatus: string[],
  searchInstances: string[],
): Promise<SearchMetricsModel> {
  return HttpClient.post<SearchMetricsDTO>({
    url: `${getAnalyticsBaseUri(tenantId, indexId)}/percentilelatencyovertime?percentile=${percentile}`,
    body: mapAnalyticsAggregatedRequestToDto(
      aggregationInterval,
      responseCode,
      start,
      end,
      markets,
      regions,
      searchResultsStatus,
      searchInstances,
    ),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(searchMetricsDTO => mapSearchMetricsDtoToModel(searchMetricsDTO, start, end, aggregationInterval));
}
