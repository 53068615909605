import { BannersStyleProps, BannersStyles } from './Banners.types';
import { getFlexColumnStyles } from '../../../../../components/common/Styles';

export const styles = (props: BannersStyleProps): BannersStyles => ({
  root: [
    'mbc-facets-preview',
    getFlexColumnStyles(),
    {
      borderRadius: 4,
      marginBottom: 16,
      padding: '12px 16px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: props.theme.palette.neutralLight,
      background: props.theme.palette.white,
    },
  ],
  header: [
    'mbc-banner-header',
    {
      fontSize: 14,
      lineHeight: 16,
      fontWeight: 600,
      color: '#292827',
    },
  ],
  content: [
    'mbc-banner-content',
    {
      margin: '18px 0',
    },
  ],
});
