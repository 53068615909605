import { TokenWithPermissions as TokenWithPermissionsModel } from '../../store/types/tokenList.d';
import { Token as TokenModel } from '../../store/types/tokenList';
import { Token as TokenResponseDto } from '../dto/tokens/response/token';
import { Token as TokenRequestDto } from '../dto/tokens/request/token';
import { TokenList as TokenListDto } from '../dto/tokens/response/tokenList';

export function mapTokenModelToDTO(token: TokenWithPermissionsModel): TokenRequestDto {
  return {
    name: token.name,
    Permissions: token.permissions,
    IsRefresh: token.isRefreshToken,
    AllEnvironments: token.hasAccessToAllEnvironments,
    Environments: token.environments.map(environment => environment.id),
    MinutesToLive: token.isRefreshToken
      ? undefined
      : Math.floor(new Date(new Date(token.expirationDate).getTime() - Date.now()).getTime() / 1000 / 60),
  };
}

export function mapTokenDtoToModel(token: TokenResponseDto): TokenModel {
  return {
    name: token.name,
    id: token.id,
    createdAt: token.createdAt,
    createdBy: token.createdBy,
    expirationDate: token.expirationDate,
    isRefreshToken: token.isRefresh,
    accessToken: token.accessToken ? token.accessToken : token.jwt,
    refreshToken: token.refreshToken,
  };
}

export function mapTokenListDtoToModel(tokenList: TokenListDto): TokenModel[] {
  return tokenList.tokens.map(mapTokenDtoToModel);
}

export function mapTokenListDtoToSingleModel(tokenList: TokenListDto): TokenModel {
  return tokenList.tokens.map(mapTokenDtoToModel)[0];
}

export function mapTokenListDtoToEncodedToken(tokenList: TokenListDto): string {
  return mapTokenListDtoToSingleModel(tokenList).accessToken;
}
