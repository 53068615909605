import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { breadcrumbsReducer } from './breadcrumbsReducer';
import { rightPanelReducer } from './rightPanelReducer';
import { browserHistory } from '../../history.module';
import { History } from 'history';
import { notificationsReducer } from './notificationsReducer';
import { authenticationReducer } from './authenticationReducer';
import { roleListReducer } from './roleListReducer';
import { userListReducer } from './userListReducer';
import { environmentListReducer } from './environmentListReducer';
import { tenantsListReducer } from './tenantsListReducer';
import { tokenListReducer } from './tokenListReducer';
import { analyticsReducer } from './analyticsReducer';
import { searchIndexReducer } from './searchIndexReducer';
import { businessRuleReducer } from './customizations/businessRuleReducer';
import { queryTesterReducer } from './customizations/queryTesterReducer';
import { synonymSetReducer } from './customizations/synonymSetReducer';
import { urlRedirectSetReducer } from './customizations/urlRedirectSetReducer';
import { blockedSuggestionsReducer } from './blockedSuggestionsReducer';
import { searchDemoReducer } from './searchDemoReducer';
import { NotificationMessageReducer } from './notificationMessageReducer';
import { searchInstanceListReducer } from './customizations/searchInstanceListReducer';
import { ProgressIndicatorReducer } from './progressIndicatorReducer';
import { indexProbeReducer } from './indexProbeReducer';
import { adminTokensReducer } from './adminTokensReducer';
import { oobeDemoReducer } from './oobeDemoReducer';
import { RouterWrapper } from './routerReducer';
import { insightsReducer } from './insightsReducer';
import { notificationCenterReducer } from './notificationCenterReducer';
import { toastNotificationReducer } from './toastNotificationReducer';
import { searchModelReducer } from './customML/searchModelReducer';
import { trainingJobReducer } from './customML/trainingJobReducer';
import { cookingJobReducer } from './customML/cookingJobReducer';
import { deploymentJobReducer } from './customML/deploymentJobReducer';
import { datasetReducer } from './customML/datasetReducer';
import { evaluationJobReducer } from './customML/evaluationJobReducer';
import { CustomMlSearchConfigReducer } from './customizations/customMlSearchConfigReducer';
import { devopsReducer } from './devopsReducer';
import { productSearchReducer } from './productSearchReducer';
import { schemaDetectionReducer } from './schemaDetectionReducer';
import { tutorialReducer } from './tutorialReducer';
import { sideNavReducer } from './sideNavReducer';

function createRootReducer(history: History) {
  return combineReducers({
    router: RouterWrapper(history),
    user: userReducer,
    roleList: roleListReducer,
    userList: userListReducer,
    tokenList: tokenListReducer,
    analytics: analyticsReducer,
    rightPanel: rightPanelReducer,
    breadcrumbs: breadcrumbsReducer,
    tenantsList: tenantsListReducer,
    notifications: notificationsReducer,
    environmentList: environmentListReducer,
    searchIndex: searchIndexReducer,
    authentication: authenticationReducer,
    indexProbe: indexProbeReducer,
    businessRule: businessRuleReducer,
    queryTester: queryTesterReducer,
    searchInstanceList: searchInstanceListReducer,
    synonymSet: synonymSetReducer,
    searchDemo: searchDemoReducer,
    urlRedirectSet: urlRedirectSetReducer,
    blockedSuggestions: blockedSuggestionsReducer,
    notificationMessages: NotificationMessageReducer,
    progressIndicator: ProgressIndicatorReducer,
    adminTokens: adminTokensReducer,
    oobeDemo: oobeDemoReducer,
    insights: insightsReducer,
    notificationsCenter: notificationCenterReducer,
    toastNotification: toastNotificationReducer,
    datasets: datasetReducer,
    searchModels: searchModelReducer,
    trainingJobs: trainingJobReducer,
    cookingJobs: cookingJobReducer,
    evaluationJobs: evaluationJobReducer,
    deploymentJobs: deploymentJobReducer,
    customMlSearchConfig: CustomMlSearchConfigReducer,
    productSearch: productSearchReducer,
    devops: devopsReducer,
    schemaDetection: schemaDetectionReducer,
    tutorial: tutorialReducer,
    sideNav: sideNavReducer,
  });
}

export const rootReducer = createRootReducer(browserHistory);
export type AppState = ReturnType<typeof rootReducer>;
