/* eslint-disable react/display-name */
import React, { useCallback, useState, useMemo } from 'react';
import {
  classNamesFunction,
  IconButton,
  IContextualMenuProps,
  IContextualMenuListProps,
  IRenderFunction,
  Stack,
  DirectionalHint,
  Link,
  TooltipOverflowMode,
  TooltipHost,
  Checkbox,
  SearchBox,
  Icon,
} from 'office-ui-fabric-react';
import {
  SchemaListFiltersProps,
  SchemaListFiltersStyles,
  SchemaListFiltersStyleProps,
  FiltersOptionsObject,
} from './SchemaListFilters.types';
import { IndexField } from '../../../../../../store/types/searchIndex.d';
import { FilterableFields } from '..';

const getClassNames = classNamesFunction<SchemaListFiltersStyleProps, SchemaListFiltersStyles>();

export const SchemaListFiltersBase = (props: SchemaListFiltersProps): JSX.Element => {
  const { theme, className, styles, onClick, disabled, onFilterChange, t, indexFields } = props;

  const classNames = getClassNames(styles, {
    theme: theme!,
    className: className,
  });

  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState<string[]>([]);

  // Available subMenu options with which to show and which to hide based on search query
  const [checkBoxesOptions, setCheckBoxesOptions] = useState<FiltersOptionsObject[]>([]);

  const getAvailableTypesFilters = useCallback(
    (inputFields: IndexField[]): string[] =>
      inputFields
        .map(field => {
          if (field.fields && field.fields.length) {
            return [field.type, ...getAvailableTypesFilters(field.fields)];
          }
          return field.type;
        })
        .flat()
        .filter((currValue, index, array) => array.indexOf(currValue) === index),
    [],
  );

  const uniqueTypeFilters = getAvailableTypesFilters(indexFields);
  const uniqueBooleanFilters = useMemo((): boolean[] => [true, false], []);

  const onRenderSubMenuItem = useCallback(
    (item: any, field: string) => (
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
        className={classNames.menuCheckBox}
        onClick={() => {
          let checked = selectedCheckBoxes.includes(item.key + field);
          if (checked) {
            setSelectedCheckBoxes(state => state.filter(checkBoxKey => checkBoxKey !== item.key + field));
          } else {
            setSelectedCheckBoxes(state => [...state, item.key + field]);
          }
          !!item && !!item.onClick && item.onClick();
          onFilterChange(field, item.key, !checked);
        }}
      >
        <Checkbox
          checked={selectedCheckBoxes.includes(item.key + field)}
          onChange={(ev, checked) => {
            if (checked) {
              setSelectedCheckBoxes(state => state.filter(checkBoxKey => checkBoxKey !== item.key + field));
            } else {
              setSelectedCheckBoxes(state => [...state, item.key + field]);
            }
            !!item && !!item.onClick && item.onClick();
            onFilterChange(field, item.key, !checked);
          }}
        />
        <TooltipHost overflowMode={TooltipOverflowMode.Self} content={item.name}>
          <div className={classNames.drillDownContent}>
            {item.name && item.name.length > 60 ? item.name.slice(0, 60) + '...' : item.name}
          </div>
        </TooltipHost>
      </Stack>
    ),
    [classNames.drillDownContent, classNames.menuCheckBox, onFilterChange, selectedCheckBoxes],
  );

  const getSearchBox = useCallback(
    (currentFieldName: string): JSX.Element => {
      return (
        <SearchBox
          key={currentFieldName}
          underlined={true}
          ariaLabel={currentFieldName}
          placeholder={t('details-list-filter.search-box-placeholder', {
            columnName: currentFieldName,
          })}
          onSearch={query =>
            setCheckBoxesOptions(state =>
              state.map(value => ({
                value: value.value,
                display: value.value.toLowerCase().includes(query.toLowerCase()),
              })),
            )
          }
          onChange={(ev, query) => {
            if (query) {
              setCheckBoxesOptions(state =>
                state.map(value => ({
                  value: value.value,
                  display: value.value.toLowerCase().includes(query.toLowerCase()),
                })),
              );
            } else {
              setCheckBoxesOptions(state =>
                state.map(value => ({
                  value: value.value,
                  display: true,
                })),
              );
            }
          }}
        />
      );
    },
    [t],
  );

  const onRenderSubMenuList = useCallback(
    (
      currentFieldName: string,
      menuListProps?: IContextualMenuListProps,
      defaultRender?: IRenderFunction<IContextualMenuListProps>,
    ) => {
      return (
        <>
          {getSearchBox(currentFieldName)}
          {defaultRender && defaultRender(menuListProps)}
        </>
      );
    },
    [getSearchBox],
  );

  const getSubMenuProps = useCallback(
    (fieldName: string, displayName: string, isBoolean = true) => {
      const itemsToDisplay = checkBoxesOptions.filter(uniqueValue => uniqueValue.display);
      return {
        onMenuDismissed: () => {
          setCheckBoxesOptions([]);
        },
        onMenuOpened: () => {
          setCheckBoxesOptions(
            isBoolean
              ? uniqueBooleanFilters.map(uniqueValue => ({ value: uniqueValue, display: true }))
              : uniqueTypeFilters.map(uniqueValue => ({ value: uniqueValue, display: true })),
          );
        },
        items: itemsToDisplay.length
          ? itemsToDisplay.map(uniqueValue => ({
              key: uniqueValue.value,
              name: (uniqueValue.value + '').replace(/^\w/, c => c.toUpperCase()),
              canCheck: true,
              onRender: (item: any) => onRenderSubMenuItem(item, fieldName),
            }))
          : [
              {
                key: 'no_results',
                // eslint-disable-next-line react/display-name
                onRender: () => (
                  <Stack horizontal key="no_results" className={classNames.noDataContainer} tokens={{ childrenGap: 8 }}>
                    <Icon iconName="SearchIssue" title={t('common.no-search-result')} />
                    <span>{t('common.no-search-result')}</span>
                  </Stack>
                ),
              },
            ],
        onRenderMenuList: (
          menuListProps?: IContextualMenuListProps,
          defaultRender?: IRenderFunction<IContextualMenuListProps>,
        ) => onRenderSubMenuList(displayName, menuListProps, defaultRender),
        directionalHint: DirectionalHint.rightTopEdge,
        directionalHintFixed: true,
        gapSpace: -6,
        styles: { container: { width: 170 }, list: { width: 170 } },
      };
    },
    [
      checkBoxesOptions,
      classNames.noDataContainer,
      onRenderSubMenuItem,
      onRenderSubMenuList,
      t,
      uniqueBooleanFilters,
      uniqueTypeFilters,
    ],
  );

  const getMenuProps = useCallback((): IContextualMenuProps => {
    return {
      items: [
        {
          key: FilterableFields.Type,
          name: 'Data type',
          subMenuProps: {
            ...getSubMenuProps(FilterableFields.Type, 'Data type', false),
          },
        },
        {
          key: FilterableFields.Searchable,
          name: FilterableFields.Searchable,
          subMenuProps: {
            ...getSubMenuProps(FilterableFields.Searchable, FilterableFields.Searchable),
          },
        },
        {
          key: FilterableFields.Retrievable,
          name: FilterableFields.Retrievable,
          subMenuProps: {
            ...getSubMenuProps(FilterableFields.Retrievable, FilterableFields.Retrievable),
          },
        },
        {
          key: FilterableFields.Filterable,
          name: FilterableFields.Filterable,
          subMenuProps: {
            ...getSubMenuProps(FilterableFields.Filterable, FilterableFields.Filterable),
          },
        },
        {
          key: FilterableFields.Facetable,
          name: FilterableFields.Facetable,
          subMenuProps: {
            ...getSubMenuProps(FilterableFields.Facetable, FilterableFields.Retrievable),
          },
        },
        {
          key: FilterableFields.Sortable,
          name: FilterableFields.Sortable,
          subMenuProps: {
            ...getSubMenuProps(FilterableFields.Sortable, FilterableFields.Sortable),
          },
        },
      ],
      shouldFocusOnMount: true,
      hidden: false,
      className: classNames.contextualMenu,
      directionalHint: DirectionalHint.bottomRightEdge,
      styles: {
        container: { width: 180 },
        list: { width: 180 },
      },
      onRenderMenuList: (
        menuListProps?: IContextualMenuListProps,
        defaultRender?: IRenderFunction<IContextualMenuListProps>,
      ) => (
        <>
          <Stack horizontalAlign={'end'} padding={8}>
            <Link
              onClick={() => {
                setSelectedCheckBoxes([]);
                onFilterChange('all', 'all', false);
              }}
              disabled={selectedCheckBoxes.length === 0}
            >
              {'Clear all'}
            </Link>
          </Stack>
          {defaultRender && defaultRender(menuListProps)}
        </>
      ),
    };
  }, [classNames.contextualMenu, getSubMenuProps, onFilterChange, selectedCheckBoxes.length]);

  return (
    <IconButton
      key="Filter"
      iconProps={{ iconName: 'Filter' }}
      ariaLabel={t('common.filter')}
      disabled={disabled}
      menuProps={getMenuProps()}
      onClick={onClick}
    />
  );
};
