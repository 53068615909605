import { connectRouter, RouterState } from 'connected-react-router';
import { Reducer } from 'redux';
import { History } from 'history';
import { LocationChangeActionTypes, SET_LOCATION_ROUTE } from '../types/router.d';
import { MbcRouteKey } from '../../router/MbcRouter.types';

export const RouterWrapper = (history: History<any>): Reducer<RouterState, LocationChangeActionTypes> => {
  const reducer = connectRouter(history);
  const updateRouterState = (state: RouterState, routeKey: MbcRouteKey = MbcRouteKey.Unknown): RouterState => {
    return {
      ...state,
      location: {
        ...state.location,
        state: {
          mbcRouteKey: routeKey,
        },
      },
    };
  };

  return (state: RouterState | undefined, action: LocationChangeActionTypes): RouterState => {
    let routerState: RouterState = reducer(state, action);
    if (action.type === SET_LOCATION_ROUTE) {
      return updateRouterState(routerState, action.mbcRouteKey);
    }

    const updatedRouterState = updateRouterState(
      routerState,
      !!routerState.location.state && !!routerState.location.state.mbcRouteKey
        ? routerState.location.state.mbcRouteKey
        : MbcRouteKey.Unknown,
    );
    return {
      ...updatedRouterState,
      location: {
        ...updatedRouterState.location,
        pathname: updatedRouterState.location.pathname.toLocaleLowerCase(),
      },
    };
  };
};
