import * as React from 'react';
import { CheckProps, CheckboxProps } from './Check.types';
import { CheckStyleProps, CheckStyles } from './Check.types';
import { classNamesFunction, Icon, Check } from 'office-ui-fabric-react';
import { useCallback } from 'react';

const getClassNames = classNamesFunction<CheckStyleProps, CheckStyles>();

export const CheckBase = (props: CheckProps) => {
  const { checked = false, className, theme, styles } = props;

  const classNames = getClassNames(styles!, { theme: theme!, className, checked });

  return (
    <div className={classNames.root}>
      <Icon iconName="Checkbox" className={classNames.circle} ariaLabel="Select row" />
      <Icon iconName="StatusCircleCheckmark" className={classNames.check} />
    </div>
  );
};

export const CheckboxBase = (props: CheckboxProps) => {
  const { disabled, checked = false, onChange } = props;

  const onCheckChange = useCallback(() => {
    if (!disabled) onChange(!checked);
  }, [checked, disabled, onChange]);

  return (
    <div onClick={onCheckChange}>
      <Check {...props} />
    </div>
  );
};
