import { TopQueriesStyleProps, TopQueriesStyles } from './TopQueries.types';

export const styles = (props: TopQueriesStyleProps): TopQueriesStyles => {
  return {
    root: [
      'mbc-top-queries-chart',
      {
        selectors: {
          '.mbc-content-card': {
            borderTop: '4px solid #0078d4',
            boxSizing: 'border-box',
          },
        },
      },
      props.className,
    ],
  };
};
