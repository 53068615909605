import {
  PUSH_NOTIFICATION_MESSAGE,
  POP_NOTIFICATION_MESSAGE,
  CLEAR_NOTIFICATION_MESSAGES,
  NotificationMessageDetails,
  NotificationMessageActionTypes,
} from '../types/notificationMessage.d';

export function pushNotificationMessage(message: NotificationMessageDetails): NotificationMessageActionTypes {
  return {
    type: PUSH_NOTIFICATION_MESSAGE,
    notificationMessage: message,
  };
}

export function popNotificationMessage(): NotificationMessageActionTypes {
  return {
    type: POP_NOTIFICATION_MESSAGE,
  };
}

export function clearNotificationMessages(): NotificationMessageActionTypes {
  return {
    type: CLEAR_NOTIFICATION_MESSAGES,
  };
}
