import React, { useMemo } from 'react';
import { classNamesFunction, Stack, StackItem, IComboBoxOption, Label, Toggle } from 'office-ui-fabric-react';
import {
  GeneralSettingsEditorProps,
  GeneralSettingsEditorStyleProps,
  GeneralSettingsEditorStyles,
} from './GeneralSettings.types';
import { MarketOptions, LanguageOptions } from '../../../common/ConditionEditor/utils';
import { useSortableFields } from '../../../../../store/hooks/use-index-schema/useSortableFields';
import { useSelector } from '../../../../../store/hooks';
import { MbcCompoBox } from '../../../../../components/common/MbcCompoBox';

const getClassNames = classNamesFunction<GeneralSettingsEditorStyleProps, GeneralSettingsEditorStyles>();

const getLanguageOptions = () => {
  return ([{ key: '', text: '--' }] as IComboBoxOption[]).concat(LanguageOptions);
};

const getMarketOptions = () => {
  return ([{ key: '', text: '--' }] as IComboBoxOption[]).concat(MarketOptions);
};

export const GeneralSettingsEditorBase = (props: GeneralSettingsEditorProps) => {
  const { styles, theme, className, generalSettings, onUpdate } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const sortableFields: string[] = useSortableFields();
  const searchInstanceIdMapper = useSelector(state => state.searchInstanceList.searchInstanceIdMapper);

  const searchInstanceOptions = useMemo(() => {
    return Object.keys(searchInstanceIdMapper).map(key => {
      return { key: key, text: key };
    });
  }, [searchInstanceIdMapper]);

  const sortOptions = useMemo(() => {
    let options: IComboBoxOption[] = [{ key: '', text: '--' }];
    sortableFields.forEach(fieldName => {
      options = options.concat([
        { key: `${fieldName} asc`, text: `${fieldName} - ascending` },
        { key: `${fieldName} desc`, text: `${fieldName} - descending` },
      ]);
    });
    return options;
  }, [sortableFields]);

  return (
    <Stack className={classNames.root} tokens={{ childrenGap: '10' }}>
      <StackItem>
        <MbcCompoBox
          compoBoxProps={{
            label: 'Search configuration',
            allowFreeform: true,
            selectedKey: generalSettings.selectedSearchInstance || '',
            autoComplete: 'on',
            options: searchInstanceOptions,
            onChange: (ev, option, index, value) => {
              const newValue = option ? (option.key as string) : value;
              onUpdate({ ...generalSettings, selectedSearchInstance: newValue || '' });
            },
          }}
        />
      </StackItem>
      <StackItem>
        <MbcCompoBox
          compoBoxProps={{
            label: 'Sort by',
            allowFreeform: true,
            selectedKey: generalSettings.sortBy || '',
            autoComplete: 'on',
            options: sortOptions,
            onChange: (ev, option, index, value) => {
              const newValue = option ? (option.key as string) : value;
              onUpdate({ ...generalSettings, sortBy: newValue });
            },
          }}
        />
      </StackItem>
      <StackItem>
        <MbcCompoBox
          compoBoxProps={{
            label: 'Language',
            allowFreeform: true,
            selectedKey: generalSettings.selectedLanguage || '',
            autoComplete: 'on',
            options: getLanguageOptions(),
            onChange: (ev, option, index, value) => {
              const newValue = option ? (option.key as string) : value;
              onUpdate({ ...generalSettings, selectedLanguage: newValue });
            },
          }}
        />
      </StackItem>
      <StackItem>
        <MbcCompoBox
          compoBoxProps={{
            label: 'Market',
            allowFreeform: true,
            selectedKey: generalSettings.selectedMarket || '',
            autoComplete: 'on',
            options: getMarketOptions(),
            onChange: (ev, option, index, value) => {
              const newValue = option ? (option.key as string) : value;
              onUpdate({ ...generalSettings, selectedMarket: newValue });
            },
          }}
        />
      </StackItem>
      <StackItem>
        <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'}>
          <Label>Query spelling correction:</Label>
          <Toggle
            styles={{ root: { margin: 0 } }}
            offText={'Off'}
            onText={'On'}
            checked={generalSettings.alterationEnabled === undefined ? true : generalSettings.alterationEnabled}
            onChange={(ev, checked) => onUpdate({ ...generalSettings, alterationEnabled: checked })}
          />
        </Stack>
      </StackItem>
    </Stack>
  );
};
