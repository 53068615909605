import { DetailsListStyleProps, DetailsListStyles } from './DetailsList.types';

export const getStyles = (props: DetailsListStyleProps): DetailsListStyles => ({
  root: ['mbc-details-list', { position: 'relative', height: '100%' }, props.className],
  optionsItem: [
    'mbc-options-column',
    {
      display: 'flex',
      justifyContent: 'flex-end',
      height: '100%',
      selectors: {
        '.ms-Button': {
          height: '100%',
          selectors: {
            ':hover': {
              background: props.theme.palette.neutralQuaternaryAlt,
            },
          },
        },
      },
    },
  ],
  chevronButton: [
    'mbc-chevron-button',
    {
      selectors: {
        ':hover': {
          background: props.theme.palette.neutralQuaternaryAlt,
        },
      },
    },
  ],
  chevronIcon: [
    'mbc-chevron',
    {
      fontSize: 14,
    },
  ],
  chevronCollapsed: [
    'mbc-chevron-collapsed',
    {
      width: 42,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  ],
  chevronExpanded: [
    'mbc-chevron-expanded',
    {
      width: 42,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: props.theme.palette.neutralLighterAlt,
      borderLeftStyle: 'solid',
      borderLeftColor: props.theme.palette.neutralLight,
      borderRightStyle: 'solid',
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderRightColor: props.theme.palette.neutralLight,
    },
  ],
  headerWrapper: [
    {
      selectors: {
        '.ms-DetailsHeader-cell': {
          selectors: {
            span: {
              fontSize: 14,
              lineHeight: 20,
              fontWeight: 600,
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              width: '100%',
            },
            '.ms-Icon': {
              paddingLeft: 4,
              height: 16,
            },
          },
        },
      },
    },
    props.enableSummary && {
      selectors: {
        '.ms-DetailsHeader-cell:first-child .ms-DetailsHeader-cellTitle': {
          paddingLeft: 58,
        },
      },
    },
  ],
  contentWrapper: [
    {
      selectors: {
        '.is-selected': {
          borderBottom: '1px solid rgb(243, 242, 241)',
          background: 'rgb(255, 255, 255)',
        },
        '.ms-DetailsRow': {
          minHeight: 42,
          animationFillMode: 'initial',
          selectors: {
            ':hover': {
              backgroundColor: props.theme.palette.neutralLighter,
            },
          },
        },
        '.ms-DetailsRow-cell': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: 0,
          paddingBottom: 0,
        },
        '.ms-DetailsRow-cell:first-child': {
          color: props.theme.palette.black,
          fontSize: 14,
        },
        '.is-checked::before': {
          backgroundColor: props.theme.palette.themePrimary,
        },
      },
    },
    props.enableSummary && {
      selectors: {
        '.ms-DetailsRow-cell:first-child': {
          padding: 0,
        },
      },
    },
  ],
  leftAlignedColumns: [
    'mbc-normal',
    {
      selectors: {
        '.ms-DetailsHeader-cellName': {
          justifyContent: 'flex-end',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          width: '100%',
          selectors: {
            i: {
              paddingLeft: 4,
            },
          },
        },
      },
    },
  ],
  centeredColumns: [
    'mbc-centered',
    {
      alignItems: 'center',
      selectors: {
        '.ms-DetailsHeader-cellName': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row-reverse',
          selectors: {
            i: {
              paddingLeft: 4,
            },
          },
        },
      },
    },
  ],
  filtersContainer: [
    'mbc-filters-container',
    {
      marginBottom: 16,
    },
  ],
  listCell: [
    'mbc-details-list-cell',
    {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: props.theme.palette.neutralPrimary,
    },
  ],
  columnWithSubText: [
    'mbc-column-with-subtext',
    {
      paddingTop: 8,
      paddingBottom: 8,
    },
  ],
  subText: [
    'mbc-sub-text',
    {
      fontSize: 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: props.theme.palette.neutralSecondary,
    },
  ],
  summary: [
    'mbc-details-list-summary',
    {
      selectors: {
        '.ms-DetailsRow-fields': {
          paddingLeft: 58,
        },
      },
    },
  ],
  subComponentStyles: {
    optionsCallout: {
      subComponentStyles: {
        callout: {
          root: {
            width: 180,
          },
        },
      },
    },
  },
});
