import { MetricCardStyleProps, MetricCardStyles } from './MetricCard.types';

export const styles = (props: MetricCardStyleProps): MetricCardStyles => {
  return {
    root: [
      'mbc-metric-card',
      {
        selectors: {
          '.mbc-content-card': {
            minHeight: 155,
            maxHeight: 155,
          },
          '.mbc-content-card-header': {
            padding: '4px 8px 4px 12px',
          },
          '.mbc-content-card-body': {
            padding: '12px 12px 8px 12px',
            minHeight: 60,
          },
          '.mbc-card-footer': {
            padding: '8px 12px',
            fontSize: 10,
            fontStyle: 'italic',
            color: props.theme.palette.neutralSecondary,
          },
        },
      },
      props.className,
    ],
    bodyContainer: [
      'mbc-metric-card-body',
      {
        minHeight: 60,
      },
    ],
    refreshButton: [
      'mbc-metric-card-refresh',
      {
        paddingLeft: 0,
        selectors: {
          '.ms-TooltipHost': {
            width: 24,
            height: 24,
            display: 'flex',
          },
          '.ms-Button': {
            width: 24,
            height: 24,
            padding: 0,
            selectors: {
              ':hover': {
                backgroundColor: 'unset',
              },
              '.ms-Icon': {
                fontSize: 12,
                marginLeft: 0,
                marginRight: 0,
              },
            },
          },
        },
      },
    ],
    refreshText: [
      'mbc-card-refresh-text',
      {
        fontSize: 10,
        fontWeight: 'normal',
      },
    ],
    value: [
      'mbc-metric-card-value',
      {
        fontSize: 28,
        lineHeight: 32,
      },
    ],
    valueDescription: [
      'mbc-value-description',
      {
        fontSize: 12,
        lineHeight: 16,
        paddingBottom: 2,
        color: props.theme.palette.neutralSecondary,
      },
    ],
  };
};
