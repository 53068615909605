import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  DetailsListLayoutMode,
  SelectionMode,
  ConstrainMode,
  ShimmeredDetailsList,
  DetailsRow,
  IDetailsRowStyles,
  IDetailsRowProps,
} from 'office-ui-fabric-react';
import styles from './aggregatedDataList.module.scss';
import { ChartContentCard, RefreshCallType } from '../ChartContentCard';
import { ShimmeredAggregatedDataList } from './shimmeredAggregatedDataList';

export interface QueryCount {
  query: string;
  count: number;
}

interface AggregatedDataListProps extends WithTranslation {
  title: string;
  data: QueryCount[];
  isDataLoading: boolean;
  onQueryInvoked?: Function;
  refreshCall: RefreshCallType;
  className?: string;
}

interface AggregatedDataListState {
  isModalOpen: boolean;
}

class AggregatedDataList extends Component<AggregatedDataListProps, AggregatedDataListState> {
  constructor(props: AggregatedDataListProps) {
    super(props);
    this.state = { isModalOpen: false };
  }

  onRenderRowInternal = (props?: IDetailsRowProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 !== 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: '#FAFAFA' };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  getChartDetails(): JSX.Element {
    const { t } = this.props;
    let displayedLabel = this.props.isDataLoading
      ? t('common.charts-loading')
      : !this.props.data.length
      ? t('common.charts-no-data')
      : '';
    return !this.props.data.length || this.props.isDataLoading ? (
      <div className={styles.noDataContainer}>
        <ShimmeredAggregatedDataList />
        <div className={styles.noDataLabel}>{displayedLabel}</div>
      </div>
    ) : (
      <ShimmeredDetailsList
        isHeaderVisible={false}
        className={styles.aggregatedDataList}
        enableShimmer={this.props.isDataLoading || !this.props.data.length}
        shimmerLines={5}
        onActiveItemChanged={(item: QueryCount) => this.props.onQueryInvoked && this.props.onQueryInvoked(item.query)}
        items={this.props.data.slice(0, 5)}
        selectionMode={SelectionMode.none}
        onRenderRow={props => this.onRenderRowInternal(props)}
        columns={[
          {
            key: 'query',
            name: t('analytics.top-queries.th-query'),
            className: styles.aggregatedDataListQuery,
            fieldName: 'query',
            minWidth: 150,
            isMultiline: false,
            onRender: (item?: QueryCount): React.ReactNode =>
              item && (
                <div data-selection-disabled={true} title={item.query} className={styles.queryColumn}>
                  {item.query}
                </div>
              ),
          },
          {
            key: 'count',
            name: t('analytics.top-queries.th-count'),
            className: styles.aggregatedDataListCount,
            fieldName: 'count',
            minWidth: 100,
            isMultiline: false,
          },
        ]}
        constrainMode={ConstrainMode.horizontalConstrained}
        layoutMode={DetailsListLayoutMode.fixedColumns}
      />
    );
  }
  render() {
    const { t } = this.props;
    return (
      <ChartContentCard
        chart={this.getChartDetails()}
        title={this.props.title}
        className={this.props.className}
        exportData={{ data: this.props.data, fileName: this.props.title }}
        refreshCall={this.props.refreshCall}
        isLoadedAndEmpty={!this.props.isDataLoading && !this.props.data.length}
        cardExpandDetails={
          !this.props.isDataLoading && this.props.data.length > 5
            ? {
                footerLinkText: this.props.t('analytics.see-more'),
                modalTitle: this.props.title,
                items: this.props.data,
                columns: [
                  {
                    key: 'query',
                    name: t('analytics.top-queries.th-query'),
                    className: styles.aggregatedDataListQuery,
                    fieldName: 'query',
                    minWidth: 150,
                    isResizable: true,
                    isMultiline: false,
                    onRender: (item?: QueryCount): React.ReactNode =>
                      item && (
                        <div data-selection-disabled={true} title={item.query} className={styles.queryColumn}>
                          {item.query}
                        </div>
                      ),
                  },
                  {
                    key: 'count',
                    name: t('analytics.top-queries.th-count'),
                    className: styles.aggregatedDataListCount,
                    fieldName: 'count',
                    minWidth: 100,
                    isMultiline: false,
                  },
                ],
              }
            : undefined
        }
      />
    );
  }
}

export default withTranslation()(AggregatedDataList);
