import React from 'react';
import { CustomMLCard } from '../CustomMLCard';
import { CardState } from '../CustomMLCard.types';
import { ManageDataCardProps } from './ManageDataCard.types';
import { MbcRouteKey } from '../../../../../router/MbcRouter.types';
import { ModelJobStatus } from '../../../../../store/types/customML/searchModel.d';
import { useRawDatasetList } from '../../../../../store/hooks/use-list/useRawDatasetList';
import { useCookingJobList } from '../../../../../store/hooks/use-list/useCookingJobList';
import { useCustomMLRouter } from '../../../../../store/hooks/use-router/useCustomMLRouter';

export function ManageDataCardBase(props: ManageDataCardProps) {
  const { t, cardComponentRef } = props;

  const datasetList = useRawDatasetList();
  const cookingJobList = useCookingJobList();
  const MbcCustomMLRouter = useCustomMLRouter();

  const failedCookingList = cookingJobList.filter(cookingJob => cookingJob.status === ModelJobStatus.Error);
  const completedCookingList = cookingJobList.filter(cookingJob => cookingJob.status === ModelJobStatus.Finished);
  const inprogressCookingList = cookingJobList.filter(cookingJob => cookingJob.status === ModelJobStatus.Running);

  return (
    <CustomMLCard
      componentRef={cardComponentRef}
      title={t('custom-ml.job-card.dataset.title')}
      state={datasetList.length === 0 ? CardState.Active : CardState.Completed}
      completedCardProps={{
        label: t('custom-ml.job-card.dataset.action'),
        onClick: MbcCustomMLRouter(MbcRouteKey.DatasetList).browserPush,
        contentProps: {
          title: t('custom-ml.job-card.dataset.completed-title', { count: datasetList.length + cookingJobList.length }),
          statusList: [
            {
              status: ModelJobStatus.Finished,
              label: t('custom-ml.job-card.dataset.status.upload', { count: datasetList.length }),
            },
            {
              status: ModelJobStatus.Finished,
              label: t('custom-ml.job-card.dataset.status.completed-cook', { count: completedCookingList.length }),
            },
            {
              status: ModelJobStatus.Running,
              label: t('custom-ml.job-card.dataset.status.running-cook', { count: inprogressCookingList.length }),
            },
            {
              status: ModelJobStatus.Error,
              label: t('custom-ml.job-card.dataset.status.failed-cook', { count: failedCookingList.length }),
            },
          ],
        },
      }}
      activeCardProps={{
        iconProps: { iconName: 'Tiles' },
        label: t('custom-ml.job-card.dataset.action'),
        onClick: MbcCustomMLRouter(MbcRouteKey.DatasetList).browserPush,
      }}
    />
  );
}
