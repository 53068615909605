import {
  TokenListState,
  TokenListStateActionTypes,
  GET_TENANT_TOKENS,
  RESET_TENANT_TOKENS,
  LOADING_TENANT_TOKENS,
  CREATE_TENANT_TOKEN,
  REVOKE_TENANT_TOKEN,
} from '../types/tokenList.d';

const initialState: TokenListState = {
  isLoading: true,
  tokens: [],
};

export function tokenListReducer(state = initialState, action: TokenListStateActionTypes): TokenListState {
  switch (action.type) {
    case CREATE_TENANT_TOKEN: {
      return {
        ...state,
        tokens: state.tokens.concat(action.token),
      };
    }
    case REVOKE_TENANT_TOKEN: {
      return {
        ...state,
        tokens: state.tokens.filter(token => token.id !== action.tokenId),
      };
    }
    case GET_TENANT_TOKENS: {
      return {
        ...state,
        isLoading: false,
        tokens: action.tokens,
      };
    }
    case LOADING_TENANT_TOKENS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_TENANT_TOKENS: {
      return {
        ...state,
        isLoading: true,
        tokens: [],
      };
    }
    default:
      return state;
  }
}
