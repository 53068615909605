import React, { useState } from 'react';
import { ImageFit, Image, ImageLoadState } from 'office-ui-fabric-react';

import { splitIntegerDecimal } from '../../../../utils';
import { getClassNames } from '../ProductItemCard.styles';
import { ProductItemCardProps } from '../ProductItemCard.types';
import { MbcClamp } from '../../MbcClamp';
import { useSelector } from '../../../../store/hooks';

const ALTERNATIVE_IMAGE_URL = '/assets/images/NoImage.svg';

export function ProductItemCardBase(props: ProductItemCardProps): React.ReactElement {
  const { styles, theme, item, onClick, actionProps } = props;
  const classNames = getClassNames(styles, {
    theme: theme!,
    enabled: true,
    interactable: !!onClick,
    hasActions: !!actionProps,
  });

  const activeIndex = useSelector(state => state.environmentList.activeEnvironment.indexId);
  const activeTenant = useSelector(state => state.tenantsList.activeTenant.id);

  const { integer, decimal } = splitIntegerDecimal(parseFloat(item.price || '0'));

  const [imageUrl, setImageUrl] = useState<string>(item.imgUrl || ALTERNATIVE_IMAGE_URL);

  return (
    <div className={classNames.root} onClick={onClick}>
      <Image
        src={imageUrl}
        alt={item.name}
        imageFit={ImageFit.cover}
        onLoadingStateChange={state =>
          state === ImageLoadState.error && imageUrl !== ALTERNATIVE_IMAGE_URL && setImageUrl(ALTERNATIVE_IMAGE_URL)
        }
        styles={classNames.subComponentStyles.imageStyles}
      />
      <div className={classNames.detailsContainer}>
        <div className={classNames.descriptionContainer}>
          <div className={classNames.priceText}>
            <div>
              <span>
                {activeTenant === '008bad85-dc86-46b4-ae5d-da85c77f5d24' ||
                activeIndex === '5800ada6-115e-41a8-8d7c-ee42f7c2f550'
                  ? '£'
                  : '$'}
              </span>
              <span className={classNames.integerText}>{integer}</span>
              {decimal > 0 && <sup className={classNames.superscriptPriceText}>{decimal}</sup>}
            </div>
          </div>
          {item.name && <MbcClamp text={item.name} lines={1} className={classNames.name} />}
          {item.url && <MbcClamp clickable text={item.url} lines={1} className={classNames.url} />}
          {item.description && <MbcClamp text={item.description} lines={2} className={classNames.description} />}
        </div>
      </div>
    </div>
  );
}
