import { ReduxCrudAction, ActionSuccessHandler, ActionFailureHandler } from '../../CrudAction.types';
import { DevOpsCommitMetadata } from '../../../../types/devOps';
import {
  createSearchInstance,
  deleteSearchInstance,
  cloneSearchInstance,
} from '../../../../../restful-apis/customizations/searchInstance.api';
import {
  SearchInstance,
  CLONE_SEARCH_INSTANCE,
  CREATE_SEARCH_INSTANCE,
  DELETE_SEARCH_INSTANCE,
} from '../../../../types/customizations/searchInstanceList.d';
import { getI18n } from 'react-i18next';

export const searchInstanceCloneAction = (
  tenantId: string,
  searchIndexId: string,
  srcSearchInstanceId: string,
  dstSearchInstanceId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<SearchInstance>,
  onFailure?: ActionFailureHandler<SearchInstance>,
): ReduxCrudAction<SearchInstance> => {
  const i18n = getI18n();
  return {
    request: cloneSearchInstance(
      tenantId,
      searchIndexId,
      srcSearchInstanceId,
      dstSearchInstanceId,
      devOpsCommitMetadata,
    ),
    postActionMapper: updatedSearchInstance => ({
      type: CLONE_SEARCH_INSTANCE,
      searchInstance: updatedSearchInstance,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.search-instances-clone-progress-message', {
          searchInstanceId: srcSearchInstanceId,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.search-instances-clone-success-message', {
          searchInstanceId: srcSearchInstanceId,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.search-instances-clone-failure-message', {
          searchInstanceId: srcSearchInstanceId,
        }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, dstSearchInstanceId),
  };
};

export const searchInstanceCreateAction = (
  tenantId: string,
  searchIndexId: string,
  SearchInstance: SearchInstance,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<SearchInstance>,
  onFailure?: ActionFailureHandler<SearchInstance>,
): ReduxCrudAction<SearchInstance> => {
  const i18n = getI18n();
  return {
    request: createSearchInstance(tenantId, searchIndexId, SearchInstance, devOpsCommitMetadata),
    postActionMapper: createdSearchInstance => ({
      type: CREATE_SEARCH_INSTANCE,
      searchInstance: createdSearchInstance,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.search-instances-create-progress-message', {
          searchInstanceId: SearchInstance.id,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.search-instances-create-success-message', {
          searchInstanceId: SearchInstance.id,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.search-instances-create-failure-message', {
          searchInstanceId: SearchInstance.id,
        }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, SearchInstance),
  };
};

export const searchInstanceDeleteAction = (
  tenantId: string,
  searchIndexId: string,
  searchInstanceId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
  onSuccess?: ActionSuccessHandler<SearchInstance>,
  onFailure?: ActionFailureHandler<SearchInstance>,
): ReduxCrudAction<SearchInstance> => {
  const i18n = getI18n();
  return {
    request: deleteSearchInstance(tenantId, searchIndexId, searchInstanceId, devOpsCommitMetadata),
    postActionMapper: deletedSearchInstance => ({
      type: DELETE_SEARCH_INSTANCE,
      id: deletedSearchInstance.id,
    }),
    notification: {
      inProgress: {
        title: i18n.t('notifications.customizations.search-instances-delete-progress-message', {
          searchInstanceId: searchInstanceId,
        }),
      },
      success: () => ({
        title: i18n.t('notifications.customizations.search-instances-delete-success-message', {
          searchInstanceId: searchInstanceId,
        }),
      }),
      failure: () => ({
        title: i18n.t('notifications.customizations.search-instances-delete-failure-message', {
          searchInstanceId: searchInstanceId,
        }),
      }),
    },
    onSuccess: res => onSuccess && onSuccess(res),
    onFailure: response => onFailure && onFailure(response, searchInstanceId),
  };
};
