import { NotificationItemStyleProps, NotificationItemStyles } from './NotificationItem.types';
import { NotificationStatus } from '../../../../../../store/types/notificationCenter.d';

export const styles = (props: NotificationItemStyleProps): NotificationItemStyles => ({
  root: ['mbc-notification-item', props.className],
  itemStackContainer: [
    'mbc-notification-item-stack',
    {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      paddingTop: 12,
      paddingBottom: 12,
    },
  ],
  titleStackContainer: [
    'mbc-notification-title-stack',
    {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  ],
  titleContainer: [
    'mbc-notification-title-container',
    {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 16,
      marginLeft: 8,
      marginRight: 18,
    },
  ],
  title: [
    'mbc-notification-title',
    {
      paddingLeft: 8,
      width: 260,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  ],
  titleIcon: [
    'mbc-notification-title-icon',
    {
      paddingTop: 4,
      color:
        props.notification.status === NotificationStatus.InProgress
          ? props.theme.palette.yellow
          : props.notification.status === NotificationStatus.Succeeded
          ? props.theme.palette.greenLight
          : props.notification.status === NotificationStatus.Failed
          ? props.theme.palette.orangeLight
          : undefined,
    },
  ],
  dismissButton: [
    'mbc-notification-dismiss-button',
    {
      color: 'black',
      width: 'fit-content',
      height: 'fit-content',
      alignSelf: 'flex-end',
      selectors: {
        '.ms-Button-icon': {
          fontSize: 8,
        },
      },
    },
  ],
  footer: [
    'mbc-footer',
    {
      fontSize: 12,
      marginTop: 8,
      paddingTop: 8,
      marginRight: 8,
      width: '100%',
    },
  ],
  creationTime: [
    'mbc-creation-time',
    {
      marginLeft: 'auto',
    },
  ],
  primaryButton: [
    'mbc-primary-button',
    {
      fontSize: 12,
      maxHeight: 26,
      padding: 5,
      marginLeft: 8,
      borderRadius: 2,
      border: '1px solid ' + props.theme.palette.themePrimary,
      backgroundColor: props.theme.palette.white,
      color: props.theme.palette.themePrimary,
    },
  ],
  errorMessage: [
    'mbc-error-message',
    {
      marginLeft: 8,
      marginRight: 18,
      fontSize: 12,
      width: 260,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  ],
});
