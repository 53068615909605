import {
  BusinessRule as BusinessRuleListItem,
  BusinessRuleDetails as BusinessRuleDto,
  BusinessRuleListResponse,
  BusinessRuleDetails,
  BusinessRuleDeleteResponse,
  Banner as BannerDto,
  SearchBanner,
} from '../../dto/customizations/businessRules';
import {
  BusinessRule as BusinessRuleModel,
  Banner,
  BannerType,
} from '../../../store/types/customizations/businessRule';
import moment from 'moment';
import { BoostCondition, ConditionBlock } from '../../../utils/customizations/conditions';
import {
  createEmptyBusinessRule,
  getDefaultSearchRequestCondition,
} from '../../../store/reducers/customizations/businessRuleReducer';
import { mapConditionDtoToModel, mapConditionBlockDtoToModel } from './conditionMapper';

export const DATE_TIME_FORMAT = 'YYYYMMDDHHmmss';

export const mapBannerDtoToModel = (banner: BannerDto): Banner => {
  return {
    contentType: banner.type as BannerType,
    content: banner.value,
  };
};

export const mapBannerModelToDto = (banner: Banner): BannerDto => {
  return {
    type: banner.contentType,
    value: banner.content,
  };
};

export const mapSearchBannerToBannerModel = (banner: SearchBanner): Banner => {
  return {
    contentType: banner._type as BannerType,
    content: banner.value,
  };
};

export const mapBannerModelToSearchBanner = (banner: Banner): SearchBanner => {
  return {
    _type: banner.contentType,
    value: banner.content,
  };
};

export const mapBusinessRuleListItemDtoToModel = (BRListItem: BusinessRuleListItem): BusinessRuleModel => {
  return {
    id: BRListItem.ruleId.toLowerCase(),
    name: BRListItem.ruleId,
    description: BRListItem.description,
    enabled: BRListItem.enabled,
    startDate: BRListItem.startTimeUtc ? moment.utc(BRListItem.startTimeUtc, DATE_TIME_FORMAT) : undefined,
    endDate: BRListItem.endTimeUtc ? moment.utc(BRListItem.endTimeUtc, DATE_TIME_FORMAT) : undefined,
    lastModifiedTime: moment.utc(BRListItem.lastModifiedTimeUtc, DATE_TIME_FORMAT),
    lastModifiedBy: BRListItem.lastModifiedBy,
    searchRequestCondition: mapConditionDtoToModel(BRListItem.searchRequestCondition) as ConditionBlock,
  };
};

export const mapBusinessRuleDtoToModel = (businessRule: BusinessRuleDetails): BusinessRuleModel => {
  const promoteConditionDto =
    businessRule.boosts && businessRule.boosts.filter(boostCondition => boostCondition.boost > 0)[0];
  const promoteConditionModel: BoostCondition | undefined = promoteConditionDto
    ? { boost: promoteConditionDto.boost, condition: mapConditionBlockDtoToModel(promoteConditionDto.condition) }
    : undefined;

  const demoteConditionDto =
    businessRule.boosts && businessRule.boosts.filter(boostCondition => boostCondition.boost < 0)[0];
  const demoteConditionModel: BoostCondition | undefined = demoteConditionDto
    ? { boost: demoteConditionDto.boost, condition: mapConditionBlockDtoToModel(demoteConditionDto.condition) }
    : undefined;

  return {
    id: businessRule.rule.toLowerCase(),
    name: businessRule.rule,
    description: businessRule.description,
    enabled: businessRule.enabled,
    startDate: businessRule.startTimeUtc ? moment.utc(businessRule.startTimeUtc, DATE_TIME_FORMAT) : undefined,
    endDate: businessRule.endTimeUtc ? moment.utc(businessRule.endTimeUtc, DATE_TIME_FORMAT) : undefined,
    lastModifiedTime: moment.utc(businessRule.lastModifiedTimeUtc, DATE_TIME_FORMAT),
    lastModifiedBy: businessRule.lastModifiedBy,
    searchRequestCondition: mapConditionDtoToModel(businessRule.searchRequestCondition) as ConditionBlock,
    filterCondition: businessRule.filter ? (mapConditionDtoToModel(businessRule.filter) as ConditionBlock) : undefined,
    promoteCondition: promoteConditionModel,
    demoteCondition: demoteConditionModel,
    banner: businessRule.banner ? mapBannerDtoToModel(businessRule.banner) : undefined,
  };
};

export const mapBusinessRuleModelToDto = (BRModel: BusinessRuleModel): BusinessRuleDto => {
  let boostConditions: BoostCondition[] = [];
  boostConditions =
    BRModel.promoteCondition && BRModel.promoteCondition.condition.conditions.length > 0
      ? boostConditions.concat(BRModel.promoteCondition)
      : boostConditions;
  boostConditions =
    BRModel.demoteCondition && BRModel.demoteCondition.condition.conditions.length > 0
      ? boostConditions.concat(BRModel.demoteCondition)
      : boostConditions;

  const searchRequestCondition = BRModel.searchRequestCondition
    ? BRModel.searchRequestCondition
    : getDefaultSearchRequestCondition();

  return {
    rule: BRModel.name,
    description: BRModel.description,
    enabled: BRModel.enabled,
    filter:
      BRModel.filterCondition && (BRModel.filterCondition as ConditionBlock).conditions.length > 0
        ? BRModel.filterCondition
        : undefined,
    boosts: boostConditions.length > 0 ? boostConditions : undefined,
    banner: BRModel.banner ? mapBannerModelToDto(BRModel.banner) : undefined,
    startTimeUtc: BRModel.startDate ? BRModel.startDate.format(DATE_TIME_FORMAT) : undefined,
    endTimeUtc: BRModel.endDate ? BRModel.endDate.format(DATE_TIME_FORMAT) : undefined,
    lastModifiedBy: BRModel.lastModifiedBy,
    lastModifiedTimeUtc: BRModel.lastModifiedTime.format(DATE_TIME_FORMAT),
    searchRequestCondition: searchRequestCondition,
  };
};

export const mapBusinessRuleListDtoToModel = (businessRuleList: BusinessRuleListResponse): BusinessRuleModel[] => {
  return businessRuleList.rules.map(mapBusinessRuleListItemDtoToModel);
};

export const mapDeletedBusinessRuleDtoToModel = (deleteResponse: BusinessRuleDeleteResponse): BusinessRuleModel => {
  return { ...createEmptyBusinessRule(), id: deleteResponse.rule.toLowerCase(), name: deleteResponse.rule };
};
