import * as graph from '@microsoft/microsoft-graph-client';

function getAuthenticatedClient(accessToken: string) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: done => {
      done(null, accessToken);
    },
  });

  return client;
}

export async function getUserDetails(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  return await client.api('/me').get();
}

export async function getProfilePicture(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  return await client.api('/me/photo/$value').get();
}

export async function getUserMetaData(userPrincipalName: string, accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  return await client.api(`/users/${userPrincipalName}`).get();
}

export async function getUserProfilePicture(userPrincipalName: string, accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  return await client.api(`/users/${userPrincipalName}/photo/$value`).get();
}
