import React from 'react';
import { useSelector } from 'react-redux';
import { TotalPercentagContent } from '../../../../store/types/analytics.d';
import { AppState } from '../../../../store/reducers';
import { PieChart, NameValuePercentage, PieColors } from '../../../../components/common/charts/PieChart';
import { ChartContentCard } from '../Common/ChartContentCard';
import { getPieChartColors } from '../../../../components/common/charts/chartsUtils';
import numeral from 'numeral';
import { classNamesFunction } from 'office-ui-fabric-react';
import { ResponseCodesStyleProps, ResponseCodesProps, ResponseCodesStyles } from './ResponseCodes.types';
import { SetResponseCodes } from '../../../../store/actions/analyticsActions';
import { useDispatch } from '../../../../store/hooks';

const getClassNames = classNamesFunction<ResponseCodesStyleProps, ResponseCodesStyles>();

export const ResponseCodesBase = (props: ResponseCodesProps) => {
  const { t, onResponseCodeInvoked, className, theme, styles } = props;

  const classNames = getClassNames(styles, { theme: theme!, className: className });

  const dispatch = useDispatch();

  const responseCodes = useSelector((state: AppState) => state.analytics.responseCodes);
  const isResponseCodesLoading = useSelector((state: AppState) => state.analytics.isResponseCodesLoading);

  let croppedResponseCodes = responseCodes.length > 8 ? [...responseCodes.slice(0, 8)] : responseCodes;
  let sortedResponseCodes = croppedResponseCodes.sort((a, b) => parseInt(a.content) - parseInt(b.content));

  const twoHundredResposeCodes = sortedResponseCodes
    .filter(dataPoint => dataPoint.content[0] === '2')
    .sort((a, b) => b.count - a.count);

  const fourHundredResposeCodes = sortedResponseCodes
    .filter(dataPoint => dataPoint.content[0] === '4')
    .sort((a, b) => b.count - a.count);

  const fiveHundredResposeCodes = sortedResponseCodes
    .filter(dataPoint => dataPoint.content[0] === '5')
    .sort((a, b) => b.count - a.count);

  sortedResponseCodes = [...twoHundredResposeCodes, ...fourHundredResposeCodes, ...fiveHundredResposeCodes];

  let pieColors: PieColors = {};
  if (twoHundredResposeCodes.length > 0) {
    pieColors = {
      ...pieColors,
      ...getPieChartColors(206, 100, twoHundredResposeCodes.map(code => code.content), 8, 44),
    };
  }

  if (fourHundredResposeCodes.length > 0) {
    pieColors = {
      ...pieColors,
      ...getPieChartColors(0, 0, fourHundredResposeCodes.map(code => code.content), 8, 50),
    };
  }

  if (fiveHundredResposeCodes.length > 0) {
    pieColors = {
      ...pieColors,
      ...getPieChartColors(15, 100, fiveHundredResposeCodes.map(code => code.content), 8, 44),
    };
  }

  return (
    <div className={classNames.root}>
      <ChartContentCard
        exportData={{ fileName: t('analytics.response-codes-chart.title'), data: responseCodes }}
        isLoadedAndEmpty={!isResponseCodesLoading && !responseCodes.length}
        refreshCall={(
          responseCode: string[],
          start: Date,
          end: Date,
          markets: string[],
          regions: string[],
          searchResultsStatus: string[],
          searchInstances: string[],
        ) =>
          dispatch(SetResponseCodes(responseCode, start, end, markets, regions, searchResultsStatus, searchInstances))
        }
        chart={
          <PieChart
            isLoading={isResponseCodesLoading}
            pieColors={pieColors}
            noDataLabel={t('common.charts-no-data')}
            isLoadingLabel={t('common.charts-loading')}
            onClick={(item: NameValuePercentage) => onResponseCodeInvoked && onResponseCodeInvoked(item.name)}
            data={sortedResponseCodes.map(data => ({
              name: data.content,
              value: data.count,
              percentage: data.percentage,
            }))}
          />
        }
        title={t('analytics.response-codes-chart.title')}
        cardExpandDetails={
          !isResponseCodesLoading && responseCodes.length > 8
            ? {
                footerLinkText: t('analytics.see-more'),
                modalTitle: t('analytics.response-codes-chart.title'),
                items: responseCodes,
                columns: [
                  {
                    key: 'Response code',
                    name: t('analytics.response-codes-chart.response-codes-name-label'),
                    fieldName: 'content',
                    minWidth: 150,
                    isResizable: true,
                    isMultiline: false,
                    onRender: (item?: TotalPercentagContent): React.ReactNode =>
                      item && (
                        <div
                          data-selection-disabled={true}
                          title={item.content}
                          className={classNames.modalFirstColumn}
                        >
                          {item.content}
                        </div>
                      ),
                  },
                  {
                    key: 'count',
                    name: t('analytics.response-codes-chart.count-label'),
                    fieldName: 'count',
                    minWidth: 100,
                    isMultiline: false,
                  },
                  {
                    key: 'percentage',
                    name: t('analytics.response-codes-chart.percentage-label'),
                    fieldName: 'percentage',
                    minWidth: 100,
                    isMultiline: false,
                    // eslint-disable-next-line react/display-name
                    onRender: (item: TotalPercentagContent) => {
                      return <span>{numeral(item.percentage).format('0.0000') + '%'}</span>;
                    },
                  },
                ],
              }
            : undefined
        }
      />
    </div>
  );
};
