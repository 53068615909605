import React, { Component } from 'react';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react';
import { BoostItem } from '../../../../../store/types/searchDemo';

export interface BoostOption extends BoostItem {
  text: string;
}

interface BoostGroupProps {
  boostOptions: BoostOption[];
  selectedKey?: string;
  onChange: (option: BoostOption) => void;
}

export default class BoostGroup extends Component<BoostGroupProps> {
  private onChangeInternal(ev?: any, option?: IChoiceGroupOption) {
    if (option) {
      const selectedItem = this.props.boostOptions.find(item => item.key === option.key);
      if (selectedItem) {
        this.props.onChange(selectedItem);
      }
    }
  }

  render() {
    return (
      <ChoiceGroup
        options={this.props.boostOptions.map(item => ({
          key: item.key,
          text: item.text,
        }))}
        selectedKey={this.props.selectedKey ? this.props.selectedKey : undefined}
        onChange={this.onChangeInternal.bind(this)}
        styles={{
          flexContainer: {
            display: 'grid',
            width: '855px',
            gridTemplateColumns: '272px 272px 272px',
            gridColumnGap: '16px',
            gridRowGap: '16px',
            selectors: {
              '.ms-ChoiceField': {
                margin: 0,
                borderRadius: 4,
                overflow: 'visible',
              },
              '.ms-ChoiceField-wrapper': {
                width: '100%',
              },
              '.ms-ChoiceField-field': {
                background: '#fff',
                color: '#3177CD',
                width: '100%',
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 5px rgba(112, 118, 138, 0.4)',
              },
              '.ms-ChoiceField-field:hover': {
                background: '#3177CD',
                color: '#fff',
                boxShadow: '3px 3px 5px rgba(112, 118, 138, 0.9)',
              },
              '.ms-ChoiceField-field::before': {
                display: 'none',
              },
              '.ms-ChoiceField-field::after': {
                display: 'none',
              },
              '.ms-ChoiceField-field.is-checked': {
                background: '#3177CD',
                color: '#fff',
                boxShadow: '3px 3px 5px rgba(112, 118, 138, 0.9)',
              },
              '.ms-ChoiceField-field .ms-ChoiceFieldLabel': {
                paddingLeft: 0,
                fontWeight: 600,
              },
              '.ms-ChoiceField-field:hover .ms-ChoiceFieldLabel': {
                color: '#fff',
              },
            },
          },
        }}
      />
    );
  }
}
