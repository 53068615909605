import { MbcSideNavStyleProps, MbcSideNavStyles } from './MbcSideNav.types';
import { INavStyles } from 'office-ui-fabric-react';

const calloutNavStyles = (): Partial<INavStyles> => ({
  root: {
    width: 220,
    overflow: 'hidden',
    height: 'fit-content',
  },
  groupContent: {
    marginBottom: 0,
  },
  compositeLink: {
    selectors: {
      '.ms-Button': {
        paddingRight: 0,
        paddingLeft: 16,
      },
      '.ms-Button-flexContainer': {
        paddingLeft: 0,
        selectors: {
          i: {
            marginRight: 8,
          },
        },
      },
    },
  },
});

const collapsibleNavStyles = (props: MbcSideNavStyleProps): Partial<INavStyles> => ({
  compositeLink: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    selectors: {
      '&:hover': {
        backgroundColor: props.theme.palette.neutralLighter,
      },
      '.ms-Button': {
        width: '100%',
        height: '100%',
        paddingRight: 0,
        border: 0,
      },
      '.ms-Button-icon': {
        width: 24,
        height: 16,
        marginRight: 8,
      },
    },
  },
  chevronButton: {
    position: 'relative',
    paddingRight: 4,
    minWidth: 51,
    height: '100%',
    left: 0,
    top: 0,
  },
  chevronIcon: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    left: 0,
    top: 0,
  },
  navItem: {
    width: '100%',
    selectors: {
      '.ms-HoverCard-host': {
        height: '40px !important',
      },
      '.is-selected': {
        selectors: {
          '.ms-HoverCard-host .ms-Button': {
            backgroundColor: props.theme.palette.neutralLight,
            selectors: {
              ':hover': {
                backgroundColor: props.theme.palette.neutralLighter,
              },
              ':after': {
                content: "''",
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                pointerEvents: 'none',
                borderLeftWidth: 2,
                borderLeftStyle: 'solid',
                borderLeftColor: props.theme.palette.themePrimary,
              },
            },
          },
        },
      },
    },
  },
});

export const styles = (props: MbcSideNavStyleProps): MbcSideNavStyles => ({
  navigation: [
    'ms-navigation',
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: props.collapsed ? 'center' : 'flex-start',
      paddingBottom: 11,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: props.theme.palette.neutralLighter,
    },
    !props.collapsed && {
      marginLeft: 4,
    },
  ],
  tutorialLink: [
    'mbc-tutorial-link',
    {
      color: props.theme.palette.white,
      fontWeight: 600,
    },
  ],
  linkSuperscript: {
    marginLeft: 5,
    color: props.theme.palette.neutralSecondary,
    fontSize: 'smaller',
    verticalAlign: 'super',
    textTransform: 'uppercase',
  },
  linkLockedTag: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    selectors: {
      'span:last-child': {
        paddingRight: 2,
        minWidth: 51,
        height: '100%',
      },
    },
  },
  collapsedGroupHeader: {
    paddingLeft: 16,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
  },
  subComponentStyles: {
    calloutNav: calloutNavStyles(),
    collapsibleNav: collapsibleNavStyles(props),
  },
});
