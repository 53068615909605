import {
  SearchInstanceListState,
  SearchInstanceActionTypes,
  GET_SEARCH_INSTANCE_LIST,
  SET_ACTIVE_SEARCH_INSTANCE,
  RESET_ACTIVE_SEARCH_INSTANCE,
  RESET_SEARCH_INSTANCE_LIST,
  LOADING_SEARCH_INSTANCE_LIST,
  CREATE_SEARCH_INSTANCE,
  DELETE_SEARCH_INSTANCE,
  CLONE_SEARCH_INSTANCE,
  SearchInstanceIdMapper,
  SearchInstance,
} from '../../types/customizations/searchInstanceList.d';
import { configureDefaultObject } from '../../../utils';

export function createInitialSearchInstance(): SearchInstance {
  return {
    id: '',
    name: '',
  };
}

const createSearchInstanceIdMapper = (initialMapper: SearchInstanceIdMapper = {}) => {
  return configureDefaultObject<SearchInstanceIdMapper, SearchInstance>(initialMapper, createInitialSearchInstance());
};

const initialState: SearchInstanceListState = {
  isLoading: true,
  activeSearchInstance: createInitialSearchInstance(),
  searchInstanceIdMapper: createSearchInstanceIdMapper(),
};

export function searchInstanceListReducer(
  state = initialState,
  action: SearchInstanceActionTypes,
): SearchInstanceListState {
  switch (action.type) {
    case GET_SEARCH_INSTANCE_LIST: {
      const searchInstanceIdMapper = createSearchInstanceIdMapper({
        ...action.searchInstanceList.reduce((mapper: SearchInstanceIdMapper, searchInstance: SearchInstance) => {
          mapper[searchInstance.id] = searchInstance;
          return mapper;
        }, {}),
      });
      return {
        ...state,
        isLoading: false,
        searchInstanceIdMapper: searchInstanceIdMapper,
      };
    }
    case RESET_SEARCH_INSTANCE_LIST: {
      return {
        ...state,
        isLoading: true,
        searchInstanceIdMapper: createSearchInstanceIdMapper(),
      };
    }
    case LOADING_SEARCH_INSTANCE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_ACTIVE_SEARCH_INSTANCE: {
      return {
        ...state,
        activeSearchInstance: createInitialSearchInstance(),
      };
    }
    case SET_ACTIVE_SEARCH_INSTANCE: {
      const searchInstance = state.searchInstanceIdMapper[action.id];
      if (searchInstance) {
        return {
          ...state,
          activeSearchInstance: searchInstance,
        };
      }
      return state;
    }
    case CLONE_SEARCH_INSTANCE:
    case CREATE_SEARCH_INSTANCE: {
      const searchInstanceIdMapper = createSearchInstanceIdMapper({
        ...state.searchInstanceIdMapper,
        [action.searchInstance.id]: action.searchInstance,
      });
      return {
        ...state,
        searchInstanceIdMapper: searchInstanceIdMapper,
      };
    }
    case DELETE_SEARCH_INSTANCE: {
      var searchInstanceIdMapper = createSearchInstanceIdMapper({ ...state.searchInstanceIdMapper });
      delete searchInstanceIdMapper[action.id];

      return {
        ...state,
        searchInstanceIdMapper: searchInstanceIdMapper,
      };
    }

    default:
      return state;
  }
}
