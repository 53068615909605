import React, { useCallback } from 'react';
import { MbcRouter } from '../../../router/MbcRouter.base';
import { MbcRouteKey, MbcRouteBase } from '../../../router/MbcRouter.types';
import { useSearchInstanceRouter } from '../../../store/hooks/use-router/useSearchInstanceRouter';
import { useProxyRouter } from '../../../store/hooks/use-router/useProxyRouter';

export const BusinessRuleListProxy = () => {
  const MbcSearchInstanceRouteMap = useSearchInstanceRouter();
  const MbcProxyRouteMapper = useProxyRouter();

  const getBusinessRuleRoutes = useCallback(
    (): MbcRouteBase[] => [
      MbcSearchInstanceRouteMap(MbcRouteKey.BusinessRuleList),
      MbcSearchInstanceRouteMap(MbcRouteKey.NewBusinessRule),
      MbcProxyRouteMapper(MbcRouteKey.BusinessRuleProxy),
    ],
    [MbcProxyRouteMapper, MbcSearchInstanceRouteMap],
  );

  return <MbcRouter routes={getBusinessRuleRoutes()} />;
};
