import _ from 'lodash';
import { IndexField } from '../../../../../store/types/searchIndex';

const regexExp = new RegExp(' ', 'g');

export const normalizeColor = (color: string | boolean | undefined) =>
  _.replace((color || '').toString().toLowerCase(), regexExp, '').trim();

export const getSearchIndexField = (
  indexFieldList: IndexField[],
  field: string,
  index: number = 0,
): IndexField | null => {
  const fieldSplit = field.split('.');
  if (fieldSplit.length <= index) {
    return null;
  }

  const candidateField =
    index + 1 === fieldSplit.length
      ? indexFieldList.find(f => f.name.toLowerCase() === fieldSplit[index].toLowerCase()) ||
        indexFieldList.find(f => f.name === '*')
      : null;

  let nestedIndexField: IndexField | null = null;
  const wildCardField = indexFieldList.find(f => f.name === '*');
  return (
    candidateField ||
    indexFieldList.reduce((prev: IndexField | null, curr: IndexField) => {
      if (prev !== null) {
        return prev;
      }
      if (curr.name.toLowerCase() === fieldSplit[index].toLowerCase()) {
        return getSearchIndexField(curr.fields || [], field, index + 1);
      } else {
        return null;
      }
    }, nestedIndexField) ||
    getSearchIndexField(wildCardField && wildCardField.fields ? wildCardField.fields : [], field, index + 1)
  );
};
