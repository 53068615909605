import React, { useState, useEffect } from 'react';
import { CustomPanel } from '../../../../components/common/CustomPanel';
import { AssignEnvironmentsProps } from './AssignEnvironments.types';
import { Environment } from '../../../../store/types/environmentList';
import { EnvironmentList } from '../../../../components/EnvironmentList';
import { PanelType } from 'office-ui-fabric-react';

export const AssignEnvironmentsBase = (props: AssignEnvironmentsProps): JSX.Element => {
  const { isOpen, onDismiss, onSaveClick, onCancelClick, t, items } = props;

  const [selectedEnvironments, setSelectedEnvironments] = useState<Environment[]>([]);

  useEffect(() => {
    setSelectedEnvironments(items);
  }, [items]);

  return (
    <CustomPanel
      isOpen={isOpen}
      headerText={t('token-editor.envs-section.panel-title')}
      onDismiss={onDismiss}
      footer={{ onSaveClick: () => onSaveClick(selectedEnvironments), onCancelClick: () => onCancelClick() }}
      panelType={PanelType.medium}
    >
      <EnvironmentList
        isPanel={true}
        onSelectionChanged={selection => {
          setSelectedEnvironments(selection);
        }}
        selectedItems={items.map(item => ({ ...item, key: item.id, canSelect: true }))}
      />
    </CustomPanel>
  );
};
