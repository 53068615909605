import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  classNamesFunction,
  DefaultButton,
  PrimaryButton,
  TextField,
  Stack,
  Panel,
  IColumn,
  TooltipHost,
  TooltipOverflowMode,
} from 'office-ui-fabric-react';
import {
  EvaluateModelPanelStyleProps,
  EvaluateModelPanelStyles,
  EvaluateModelPanelProps,
} from './EvaluateModelPanel.types';
import { ModelJobStatus } from '../../../../../../store/types/customML/searchModel.d';
import { EvaluationJob } from '../../../../../../store/types/customML/evaluationJob.d';
import { useTrainingJobList } from '../../../../../../store/hooks/use-list/useTrainingJobList';
import { createInitialEvaluationJob } from '../../../../../../store/reducers/customML/evaluationJobReducer';
import { useSelector } from '../../../../../../store/hooks';
import {
  onGetLabelGainErrorMsg,
  isInvalidLabelGain,
  isInvalidEntityName,
  onGetEntityNameErrorMsg,
} from '../../../SearchModel.utils';
import { useCookingJobList } from '../../../../../../store/hooks/use-list/useCookingJobList';
import { useActiveTraining } from '../../../../../../store/hooks/use-active-job/useActiveTraining';
import { ColumnWithPriority, SortDataType, ColumnPriority } from '../../../../../../components/common/DetailsList';
import { TreeListView } from '../../../../../../components/common/TreeView';
import { SelectActionButton } from '../../../../../../components/common/SelectActionButton';

export const getClassNames = classNamesFunction<EvaluateModelPanelStyleProps, EvaluateModelPanelStyles>();

export function EvaluateModelPanelBase(props: EvaluateModelPanelProps) {
  const { t, styles, theme, onDismiss, onSubmit, isOpen } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const activeTrainingJob = useActiveTraining();
  const userEmail = useSelector(state => state.user.email);
  const isCookedDatasetListLoading = useSelector<boolean>(state => state.cookingJobs.isLoading);
  const cookedDatasetList = useCookingJobList().filter(job => job.status === ModelJobStatus.Finished);
  const trainingJobList = useTrainingJobList().filter(trainingJob => trainingJob.status === ModelJobStatus.Finished);

  const initEvaluationJob = useCallback(
    () => ({ ...createInitialEvaluationJob(), email: userEmail, baselineModelKey: activeTrainingJob.rankerModelKey }),
    [activeTrainingJob.rankerModelKey, userEmail],
  );

  const [editableEvaluationJob, setEditableEvaluationJob] = useState<EvaluationJob>(() => initEvaluationJob());

  useEffect(() => {
    return () => {
      setEditableEvaluationJob(initEvaluationJob());
    };
  }, [initEvaluationJob, isOpen]);

  const isInvalidForm = () =>
    editableEvaluationJob.testDatasetKey === '' ||
    editableEvaluationJob.baselineModelKey === '' ||
    editableEvaluationJob.comparisonModelKey === '' ||
    isInvalidEntityName(editableEvaluationJob.name) ||
    isInvalidLabelGain(editableEvaluationJob.labelGain);

  const onRenderItemNameColumn = useCallback(
    (item?: any, index?: number, column?: IColumn) =>
      item &&
      column && (
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          hostClassName={classNames.overflow}
          style={{ maxWidth: column.maxWidth }}
          content={item.name}
        >
          <div style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined, display: 'inline' }}>
            {item.name}
          </div>
        </TooltipHost>
      ),
    [classNames.overflow],
  );

  const panelColumns: ColumnWithPriority[] = useMemo(
    () => [
      {
        name: '',
        minWidth: 200,
        maxWidth: 300,
        key: 'model-name',
        fieldName: 'name',
        sortDataType: SortDataType.text,
        priority: ColumnPriority.Primary,
        onRender: onRenderItemNameColumn,
      },
    ],
    [onRenderItemNameColumn],
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={onDismiss}
      className={classNames.root}
      onRenderNavigation={(props, defaultRender) => (
        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ padding: 16 }}>
          <div className={classNames.header}>{t('custom-ml.eval-job.panel-title')}</div>
          {defaultRender!(props)}
        </Stack>
      )}
      onRenderFooter={() => (
        <Stack horizontal className={classNames.footer} tokens={{ padding: 16, childrenGap: 16 }}>
          <PrimaryButton
            key="create"
            disabled={isInvalidForm()}
            onClick={() => {
              onSubmit(editableEvaluationJob);
              onDismiss();
            }}
          >
            {t('custom-ml.jobs.create')}
          </PrimaryButton>
          <DefaultButton key="cancel" onClick={() => onDismiss()}>
            {t('custom-ml.jobs.cancel')}
          </DefaultButton>
        </Stack>
      )}
    >
      <Stack tokens={{ childrenGap: 21 }}>
        <TextField
          validateOnLoad={false}
          className={classNames.textInput}
          value={editableEvaluationJob.name}
          label={t('custom-ml.eval-job.name-label')}
          placeholder={t('custom-ml.eval-job.name-placeholder')}
          onGetErrorMessage={onGetEntityNameErrorMsg}
          onChange={(ev, value) =>
            value !== undefined &&
            setEditableEvaluationJob(state => ({
              ...state,
              name: value,
            }))
          }
        />
        <TextField
          validateOnLoad={false}
          className={classNames.textInput}
          value={editableEvaluationJob.labelGain}
          label={t('custom-ml.eval-job.label-gain')}
          onGetErrorMessage={onGetLabelGainErrorMsg}
          onChange={(ev, value) =>
            value !== undefined &&
            setEditableEvaluationJob(state => ({
              ...state,
              labelGain: value,
            }))
          }
        />
        <SelectActionButton
          className={classNames.selectionAction}
          responsiveColumns={{
            columns: panelColumns,
            secondaryBreakPoint: 800,
            teritaryBreakPoint: 1156,
          }}
          items={trainingJobList.map(trainingJob => ({
            name: trainingJob.name,
            key: trainingJob.rankerModelKey,
          }))}
          isLoading={isCookedDatasetListLoading}
          header={t('custom-ml.eval-job.compare-models-title')}
          itemLabel={t('custom-ml.eval-job.compare-models-label')}
          actionLabel={t('custom-ml.eval-job.compare-models-label')}
          onSelectionChange={(key: string | undefined) =>
            setEditableEvaluationJob(state => ({ ...state, comparisonModelKey: !!key ? key : '' }))
          }
        />
        <SelectActionButton
          className={classNames.selectionAction}
          responsiveColumns={{
            columns: panelColumns,
            secondaryBreakPoint: 800,
            teritaryBreakPoint: 1156,
          }}
          items={cookedDatasetList.map(dataset => ({
            name: dataset.name,
            key: dataset.cookedDatasetKey,
          }))}
          isLoading={isCookedDatasetListLoading}
          header={t('custom-ml.eval-job.test-data-title')}
          itemLabel={t('custom-ml.eval-job.test-data-label')}
          actionLabel={t('custom-ml.eval-job.test-data-label')}
          onSelectionChange={(key: string | undefined) =>
            setEditableEvaluationJob(state => ({ ...state, testDatasetKey: !!key ? key : '' }))
          }
        />
        <TreeListView
          disableCount
          header={t('custom-ml.eval-job.baseline-header')}
          items={[
            {
              header: {
                width: 250,
                title: activeTrainingJob.name,
              },
              subHeader1: t('custom-ml.eval-job.baseline-subheader', { date: activeTrainingJob.createdAt }),
            },
          ]}
        />
      </Stack>
    </Panel>
  );
}
