import { ReactElement } from 'react';

export type CallableChildren = (features: string[]) => ReactElement;

export enum GuardType {
  Include,
  Exclude,
}

export interface FeatureGuardProps {
  children?: CallableChildren | ReactElement;
  // TODO: features type should be changed to enum
  // When both tenant and environment enums are unified
  type?: GuardType;
  features: string | string[];
}

export interface ConnectedFeatureGuardProps extends FeatureGuardProps {
  supportedFeatures: string[];
}
