import React, { useCallback } from 'react';
import { classNamesFunction, Stack } from 'office-ui-fabric-react';
import {
  BusinessRuleEditorProps,
  BusinessRuleEditorStyleProps,
  BusinessRuleEditorStyles,
} from './BusinessRuleEditor.types';
import { GeneralDetailsEditor } from './GeneralDetails';
import { ConditionBlockEditor } from '../../common/ConditionBlockEditor';
import { ConditionBlock } from '../../../../utils/customizations/conditions';
import { GeneralDetails } from './GeneralDetails/GeneralDetails.types';
import { BusinessRule, Banner, excludedFieldTypes } from '../../../../store/types/customizations/businessRule.d';
import { BannerEditor } from '../../common/BannerEditor';
import { BusinessRulePanel } from '../BusinessRule/BusinessRule.types';

const getClassNames = classNamesFunction<BusinessRuleEditorStyleProps, BusinessRuleEditorStyles>();

export const BusinessRuleEditorBase = (props: BusinessRuleEditorProps) => {
  const { styles, theme, className, businessRule, selectedPanel, editMode, onUpdate } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const handleGeneralDetailsChange = useCallback(
    (generalDetails: GeneralDetails) => {
      const updatedBusinessRule: BusinessRule = {
        ...businessRule,
        name: generalDetails.name,
        description: generalDetails.description,
        startDate: generalDetails.startDate,
        endDate: generalDetails.endDate,
        searchRequestCondition: generalDetails.searchRequestCondition,
      };
      onUpdate(updatedBusinessRule);
    },
    [businessRule, onUpdate],
  );

  const handlePromoteConditionChange = useCallback(
    (conditionBlock: ConditionBlock) => {
      const updatedBoostCondition =
        !!conditionBlock && conditionBlock.conditions.length > 0
          ? { boost: 500, condition: conditionBlock }
          : undefined;
      const updatedBusinessRule = { ...businessRule, promoteCondition: updatedBoostCondition };
      onUpdate(updatedBusinessRule);
    },
    [businessRule, onUpdate],
  );

  const handleDemoteConditionChange = useCallback(
    (conditionBlock: ConditionBlock) => {
      const updatedBoostCondition =
        !!conditionBlock && conditionBlock.conditions.length > 0
          ? { boost: -500, condition: conditionBlock }
          : undefined;
      const updatedBusinessRule = { ...businessRule, demoteCondition: updatedBoostCondition };
      onUpdate(updatedBusinessRule);
    },
    [businessRule, onUpdate],
  );

  const handleFilterConditionChange = useCallback(
    (conditionBlock: ConditionBlock) => {
      const updatedConditionBlock =
        !!conditionBlock && conditionBlock.conditions.length > 0 ? conditionBlock : undefined;
      const updatedBusinessRule = { ...businessRule, filterCondition: updatedConditionBlock };
      onUpdate(updatedBusinessRule);
    },
    [businessRule, onUpdate],
  );

  const handleBannerUpdate = useCallback(
    (banner: Banner) => {
      const updatedBusinessRule = { ...businessRule, banner: banner };
      onUpdate(updatedBusinessRule);
    },
    [businessRule, onUpdate],
  );

  const getSelectedPanelContent = useCallback((): React.ReactNode => {
    switch (selectedPanel) {
      case BusinessRulePanel.General:
        return (
          <div className={classNames.panelBody}>
            <GeneralDetailsEditor
              generalDetails={{ ...businessRule }}
              editMode={editMode}
              onUpdate={handleGeneralDetailsChange}
            />
          </div>
        );

      case BusinessRulePanel.Promote:
        return (
          <div className={classNames.panelBody}>
            <ConditionBlockEditor
              key={'Promote-Condition-Block'}
              label={'Promote Conditions'}
              conditionBlock={businessRule.promoteCondition ? businessRule.promoteCondition.condition : undefined}
              excludedFieldTypes={excludedFieldTypes}
              onConditionBlockUpdate={handlePromoteConditionChange}
            />
          </div>
        );
      case BusinessRulePanel.Demote:
        return (
          <div className={classNames.panelBody}>
            <ConditionBlockEditor
              key={'Demote-Condition-Block'}
              label={'Demote Conditions'}
              conditionBlock={businessRule.demoteCondition ? businessRule.demoteCondition.condition : undefined}
              excludedFieldTypes={excludedFieldTypes}
              onConditionBlockUpdate={handleDemoteConditionChange}
            />
          </div>
        );
      case BusinessRulePanel.Filter:
        return (
          <div className={classNames.panelBody}>
            <ConditionBlockEditor
              key={'Filter-Condition-Block'}
              label={'Filter Conditions'}
              excludedFieldTypes={excludedFieldTypes}
              conditionBlock={businessRule.filterCondition}
              onConditionBlockUpdate={handleFilterConditionChange}
            />
          </div>
        );
      case BusinessRulePanel.Banner:
        return (
          <div className={classNames.panelBody}>
            <BannerEditor initialBanner={businessRule.banner} onBannerUpdate={handleBannerUpdate} />
          </div>
        );
      default:
        return null;
    }
  }, [
    businessRule,
    classNames.panelBody,
    editMode,
    handleBannerUpdate,
    handleDemoteConditionChange,
    handleFilterConditionChange,
    handleGeneralDetailsChange,
    handlePromoteConditionChange,
    selectedPanel,
  ]);
  return <Stack className={classNames.root}>{getSelectedPanelContent()}</Stack>;
};
