import { OobeDemoState, SHOW_OOBE_DEMO, HIDE_OOBE_DEMO, OobeDemoStateActionTypes } from '../types/oobeDemo.d';

const initialState: OobeDemoState = {
  showDemo: false,
};

export function oobeDemoReducer(state = initialState, action: OobeDemoStateActionTypes): OobeDemoState {
  switch (action.type) {
    case SHOW_OOBE_DEMO: {
      return {
        ...state,
        showDemo: true,
      };
    }
    case HIDE_OOBE_DEMO: {
      return {
        ...state,
        showDemo: false,
      };
    }
    default:
      return state;
  }
}
