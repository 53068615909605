import { NotificationPanelStyleProps, NotificationPanelStyles } from './NotificationPanel.types';

export const styles = (props: NotificationPanelStyleProps): NotificationPanelStyles => ({
  root: [
    'mbc-search-requests-container',
    {
      selectors: {
        '.ms-Panel-navigation': {
          height: 'fit-content',
        },
      },
    },
    props.className,
  ],

  dismissAllNotificationsLink: [
    'mbc-dismiss-all-link',
    {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 5,
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
  ],

  panelContainer: [
    'mbc-panel-container',
    {
      height: 'fit-content',
    },
  ],

  panelHeaderContainer: [
    'mbc-header-container',
    {
      width: '100%',
      alignItems: 'center',
      height: 85,
      selectors: { '.ms-Button-flexContainer': { height: 36 } },
    },
  ],

  panelTitle: [
    'mbc-panel-title',
    {
      fontWeight: 600,
      paddingLeft: 10,
      fontSize: 22,
    },
  ],

  noNotificationsStack: [
    'mbc-no-notifications-stack',
    {
      padding: 30,
      paddingTop: 85,
    },
  ],

  noNotificationsIcon: [
    'mbc-no-notifications-icon',
    {
      fontSize: 120,
      color: 'lightgray',
    },
  ],

  noNotificationsText: [
    'mbc-no-notifications-text',
    {
      fontWeight: 500,
    },
  ],
});
