import React, { useState, createRef, useEffect } from 'react';
import { classNamesFunction, Stack, StackItem, TextField, Button } from 'office-ui-fabric-react';
import { SynonymSetEditorProps, SynonymSetEditorStyleProps, SynonymSetEditorStyles } from './SynonymSetEditor.types';
import { SimpleList } from '../../common/SimpleList';
import { Tutorial, TutorialSteps } from '../../../../components/Tutorials';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const getClassNames = classNamesFunction<SynonymSetEditorStyleProps, SynonymSetEditorStyles>();

export const SynonymSetEditorBase = (props: SynonymSetEditorProps) => {
  const { styles, theme, className, synonymSet, editMode, onUpdate } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const { t } = useTranslation();

  const synonymsListRef = createRef<HTMLDivElement>();

  const [synonymId, setSynonymId] = useState<string>(synonymSet.id);
  const [newSynonym, setNewSynonym] = useState<string>('');
  const [synonymList, setSynonymList] = useState<string[]>(synonymSet.synonymList);

  useEffect(() => {
    if (synonymSet.id !== synonymId) {
      setSynonymId(synonymSet.id);
    }
    if (!_.isEqual(synonymSet.synonymList, synonymList)) {
      setSynonymList(synonymSet.synonymList);
    }
  }, [synonymId, synonymList, synonymSet]);

  const addNewSynonym = () => {
    if (newSynonym) {
      const updatedsynonymList = synonymList.concat(newSynonym);
      setSynonymList(updatedsynonymList);
      onUpdate({ id: synonymId, synonymList: updatedsynonymList });
      setNewSynonym('');
    }
  };

  const deleteSynonym = (synonymToDelete: string) => {
    const updatedsynonymList = synonymList.filter(synonym => synonym !== synonymToDelete);
    setSynonymList(updatedsynonymList);
    onUpdate({ id: synonymId, synonymList: updatedsynonymList });
  };

  const handleNewSynonymKeyUp = (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    ev.key === 'Enter' && addNewSynonym();
    ev.key === 'Escape' && setNewSynonym('');
  };

  const handleSynonymIdChange = (val: string) => {
    setSynonymId(val);
    onUpdate({ id: val, synonymList: synonymList });
  };

  return (
    <>
      <Tutorial
        target={synonymsListRef}
        step={TutorialSteps.SynonymsTerms}
        headline={t('product-tours-panel.synonyms-tour.headline4')}
        onDismiss={() => {
          const updatedsynonymList: string[] = [];
          setSynonymId('');
          setSynonymList(updatedsynonymList);
          onUpdate({ id: '', synonymList: updatedsynonymList });
        }}
        onStepEnd={() => {
          const updatedsynonymList: string[] = [];
          setSynonymId('');
          setSynonymList(updatedsynonymList);
          onUpdate({ id: '', synonymList: updatedsynonymList });
        }}
      >
        <>
          <p>{t('product-tours-panel.synonyms-tour.step-4.pargraph1')}</p>
          <p className={classNames.tutorialSubheading}>{t('product-tours-panel.synonyms-tour.step-4.pargraph2')}</p>
        </>
      </Tutorial>
      <Stack className={classNames.root} tokens={{ childrenGap: '10' }}>
        <StackItem>
          <TextField
            required
            value={synonymId}
            label={'Name'}
            disabled={editMode}
            onChange={(event, newValue) => handleSynonymIdChange(newValue || '')}
          />
        </StackItem>
        <Stack horizontal tokens={{ childrenGap: '8' }} horizontalAlign={'space-between'} verticalAlign={'end'}>
          <StackItem grow>
            <TextField
              value={newSynonym}
              label={'Term'}
              onChange={(event, newValue) => setNewSynonym(newValue || '')}
              onKeyUp={ev => handleNewSynonymKeyUp(ev)}
            />
          </StackItem>
          <Button styles={{ root: { width: 95 } }} iconProps={{ iconName: 'Add' }} onClick={() => addNewSynonym()}>
            Add
          </Button>
        </Stack>
        {synonymList.length > 0 && (
          <StackItem>
            <div ref={synonymsListRef}>
              <SimpleList items={synonymList} onItemDelete={key => deleteSynonym(key)} />
            </div>
          </StackItem>
        )}
      </Stack>
    </>
  );
};
