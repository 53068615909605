import { PaginationStyleProps, PaginationStyles } from './Pagination.types';

export const styles = (props: PaginationStyleProps): PaginationStyles => ({
  root: [
    'mbc-pagination',
    {
      margin: 8,
      borderRadius: 3,
      justifyContent: 'center',
      selectors: {
        button: {
          fontSize: 14,
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: 2,
          textAlign: 'center',
          boxSizing: 'border-box',
          padding: '0px 0px 0px 0px',
          backgroundColor: 'inherit',
          selectors: {
            '&:disabled': {
              color: '#605e5c',
              border: 'unset',
            },
          },
        },
      },
    },
  ],
  chevron: [
    'mbc-chevron',
    {
      minWidth: 32,
      lineHeight: 19,
      marginRight: 16,
      selectors: {
        '.ms-Button-flexContainer': {
          maxHeight: 19,
          justifyContent: 'center',
          color: props.theme.palette.neutralPrimary,
        },
      },
    },
  ],
  sections: [
    'mbc-section',
    {
      justifyContent: 'center',
    },
  ],
  selected: [
    'mbc-selected',
    {
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: props.theme.palette.themePrimary,
    },
  ],
  unselected: [
    'mbc-unselected',
    {
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
    },
  ],
  pageButton: [
    'mbc-page-button',
    {
      minWidth: 32,
      lineHeight: 19,
      marginRight: 16,
      selectors: {
        '.ms-Button-flexContainer': {
          justifyContent: 'center',
          maxHeight: 19,
          color: props.theme.palette.neutralPrimary,
        },
      },
    },
  ],
});
