import React, { useState, useEffect, useCallback } from 'react';
import { classNamesFunction, PrimaryButton, Stack, TextField, DefaultButton, Panel } from 'office-ui-fabric-react';
import {
  DatasetUploadPanelStyleProps,
  DatasetUploadPanelStyles,
  DatasetUploadPanelProps,
} from './DatasetUploadPanel.types';
import {
  onGetEntityNameErrorMsg,
  onGetEntityDescriptionErrorMsg,
  isInvalidEntityName,
  isInvalidEntityDescription,
} from '../../SearchModel/SearchModel.utils';
import { useSelector } from '../../../../store/hooks';
import { DatasetType, Dataset } from '../../../../store/types/customML/dataset.d';
import { createInitialDataset } from '../../../../store/reducers/customML/datasetReducer';
import { FileUploaderTreeView } from '../../../../components/common/FileUploaderTreeView';

export const getClassNames = classNamesFunction<DatasetUploadPanelStyleProps, DatasetUploadPanelStyles>();

export function DatasetUploadPanelBase(props: DatasetUploadPanelProps) {
  const { styles, theme, t, onDismiss, onUpload, isOpen } = props;

  const classNames = getClassNames(styles, { theme: theme! });

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const userEmail = useSelector<string>(state => state.user.email);

  const initDataset = useCallback(
    () => ({
      ...createInitialDataset(),
      email: userEmail,
      datasetType: DatasetType.RawData,
    }),
    [userEmail],
  );

  const [editableDataset, setEditableDataset] = useState<Dataset>(initDataset());

  useEffect(() => {
    return () => {
      setEditableDataset(initDataset());
    };
  }, [initDataset, isOpen]);

  const isInvalidForm = () =>
    selectedFiles.length === 0 ||
    isInvalidEntityName(editableDataset.name) ||
    isInvalidEntityDescription(editableDataset.description);

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={onDismiss}
      className={classNames.root}
      onRenderNavigation={(props, defaultRender) => (
        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ padding: 16 }}>
          <div className={classNames.header}>{t('custom-ml.upload-data.upload-modal-title')}</div>
          {defaultRender!(props)}
        </Stack>
      )}
      onRenderFooter={() => (
        <Stack horizontal className={classNames.footer} tokens={{ padding: 16, childrenGap: 16 }}>
          <PrimaryButton
            key="upload"
            disabled={isInvalidForm()}
            onClick={() => {
              onUpload(selectedFiles[0], editableDataset);
              onDismiss();
            }}
          >
            {t('custom-ml.jobs.upload')}
          </PrimaryButton>
          <DefaultButton key="cancel" onClick={() => onDismiss()}>
            {t('custom-ml.jobs.cancel')}
          </DefaultButton>
        </Stack>
      )}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        <TextField
          required={true}
          validateOnLoad={false}
          className={classNames.textInput}
          onGetErrorMessage={onGetEntityNameErrorMsg}
          label={t('custom-ml.upload-data.dataset-name-label')}
          placeholder={t('custom-ml.upload-data.dataset-name-placeholder')}
          value={editableDataset.name}
          onChange={(ev, value) => value !== undefined && setEditableDataset(state => ({ ...state, name: value }))}
        />
        <TextField
          required
          multiline
          validateOnLoad={false}
          className={classNames.multiLineInput}
          onGetErrorMessage={onGetEntityDescriptionErrorMsg}
          label={t('custom-ml.upload-data.dataset-description-label')}
          placeholder={t('custom-ml.upload-data.dataset-description-placeholder')}
          value={editableDataset.description}
          onChange={(ev, value) =>
            value !== undefined && setEditableDataset(state => ({ ...state, description: value }))
          }
        />
        <FileUploaderTreeView
          width={200}
          label={t('custom-ml.upload-data.choose-data-label')}
          onFilesSelection={files => setSelectedFiles(files)}
        />
      </Stack>
    </Panel>
  );
}
