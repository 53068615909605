import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../reducers';

import { Action } from 'redux';
import {
  RESET_INDEX_COMMITS_DEPLOYMENTS,
  ADD_INDEX_COMMIT,
  SET_INDEX_COMMITS,
  SET_INDEX_DEPLOYMENTS,
  DeploymentStatus,
  Deployment,
} from '../types/devOps.d';
import {
  getCommits,
  getDeployments,
  deployCommit as deployCommitService,
  redeploy as redeployService,
  createCommit as createCommitService,
  getDeploymentStatus,
  getCommitZip,
} from '../../restful-apis/devops.api';
import { pushNotificationMessage } from './notificationMessageActions';
import { NotificationMessageDetails } from '../types/notificationMessage';
import { showProgressIndicator, hideProgressIndicator } from './progressIndicatorActions';
import { getSearchIndex } from '../../restful-apis/searchIndex.api';
import { SAVE_ACTIVE_SEARCH_INDEX } from '../types/searchIndex.d';

export const setIndexDevops = (tenantId: string, indexId: string): ThunkAction<void, AppState, null, Action> => {
  return dispatch => {
    getCommits(tenantId, indexId).then(commits => {
      dispatch({ type: SET_INDEX_COMMITS, commits: commits });
    });
    getDeployments(tenantId, indexId).then(deployments => {
      dispatch({ type: SET_INDEX_DEPLOYMENTS, deployments: deployments });
    });
  };
};

const checkDeploymentStatus = (
  tenantId: string,
  indexId: string,
  deployment: Deployment,
  notifications: { [key: string]: NotificationMessageDetails },
  dispatch: ThunkDispatch<AppState, null, Action>,
) => {
  dispatch(pushNotificationMessage(notifications[deployment.status]));
  const interval = setInterval(() => {
    getDeploymentStatus(tenantId, indexId, deployment.deploymentId)
      .then(status => {
        if (status === DeploymentStatus.Succeeded) {
          clearInterval(interval);
          getDeployments(tenantId, indexId)
            .then(deployments => {
              dispatch(hideProgressIndicator());
              dispatch(pushNotificationMessage(notifications[status]));
              dispatch({ type: SET_INDEX_DEPLOYMENTS, deployments: deployments });
            })
            .then(() => {
              getSearchIndex(tenantId, indexId).then(searchIndex => {
                dispatch({
                  type: SAVE_ACTIVE_SEARCH_INDEX,
                  searchIndex: searchIndex,
                });
              });
            });
        } else if (status === DeploymentStatus.Failed || status === DeploymentStatus.Cancelled) {
          clearInterval(interval);
          dispatch(hideProgressIndicator());
          dispatch(pushNotificationMessage(notifications[status]));
        }
      })
      .catch(() => {
        clearInterval(interval);
        dispatch(hideProgressIndicator());
      });
  }, 3000);
};

export const deployCommit = (
  commitId: string,
  notifications: { [key: string]: NotificationMessageDetails },
  commitMessage?: string,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenantsList.activeTenant.id;
  const indexId = state.searchIndex.searchIndex.id;
  const devOpsCommitMetadata = { author: state.user.email, message: commitMessage || `Deploy commit: ${commitId}` };
  dispatch(showProgressIndicator());
  return deployCommitService(tenantId, indexId, commitId, devOpsCommitMetadata).then(deployment =>
    checkDeploymentStatus(tenantId, indexId, deployment, notifications, dispatch),
  );
};

export const exportCommit = (commitId: string): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenantsList.activeTenant.id;
  const indexId = state.searchIndex.searchIndex.id;
  return getCommitZip(tenantId, indexId, commitId).then(zipUrl => {
    if (zipUrl) {
      const link = document.createElement('a');
      link.href = zipUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
};

export const redeploy = (
  deploymentId: string,
  notifications: { [key: string]: NotificationMessageDetails },
  commitMessage?: string,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenantsList.activeTenant.id;
  const indexId = state.searchIndex.searchIndex.id;
  const devOpsCommitMetadata = {
    author: state.user.email,
    message: commitMessage || `Redeploy deployment: ${deploymentId}`,
  };
  dispatch(showProgressIndicator());
  return redeployService(tenantId, indexId, deploymentId, devOpsCommitMetadata).then(deployment =>
    checkDeploymentStatus(tenantId, indexId, deployment, notifications, dispatch),
  );
};

export const createCommit = (
  zipFile: File,
  notifications: { [key: string]: NotificationMessageDetails },
  commitMessage?: string,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenantsList.activeTenant.id;
  const indexId = state.searchIndex.searchIndex.id;
  const devOpsCommitMetadata = {
    author: state.user.email,
    message: commitMessage || `Create new commit`,
  };
  dispatch(showProgressIndicator());
  return createCommitService(tenantId, indexId, zipFile, devOpsCommitMetadata).then(commit => {
    dispatch(deployCommit(commit.commitId, notifications, devOpsCommitMetadata.message));
    dispatch({ type: ADD_INDEX_COMMIT, commit: commit });
  });
};

export const refreshActiveIndexDevops = (): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const tenantId = getState().tenantsList.activeTenant.id;
  const indexId = getState().searchIndex.searchIndex.id;
  dispatch(setIndexDevops(tenantId, indexId));
};

export function resetIndexDevops() {
  return {
    type: RESET_INDEX_COMMITS_DEPLOYMENTS,
  };
}
