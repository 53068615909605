import { Item, SearchResults } from '../dto/search/search';
import { ProductSearchResult, ProductSearchResultItem } from '../../store/types/productSearch.d';

import { FacetOption } from '../dto/search/facet';
import { SearchIndex } from '../../store/types/searchIndex.d';
import { ProductSearchResult as SearchPreviewResult } from '../../store/types/searchDemo.d';
import { getFacetsFromApiResponse } from '../facet.api';
import { SearchPostRequest } from '../../utils/customizations/search/searchRequest';
import { BannerType } from '../../store/types/customizations/businessRule';

/**
 * Those are all the respected index types
 * Grouping product items types with priorities to show
 */
export const IndexTypePriorityMap: { [key: string]: number } = {
  description: 0,
  brand: 1,
  category: 2,
  material: 3,
  model: 4,
  ageGroup: 5,
  color: 6,
  gender: 7,
  string: 8,

  url: 0,

  imageurl: 0,
  secondaryimageurls: 1,

  price: 0,

  size: 0,

  rating: 1,

  title: 0,
  identifier: 1,
  dupid: 2,
  number: 3,

  productid: 1,

  // Used as others
  boolean: 0,
  datetime: 0,
  excludeflag: 0,
  barcode: 0,
  object: 0,
  array: 0,
};

const mapSearchResultItem = (searchIndex: SearchIndex, item: Item): ProductSearchResultItem => {
  const productSearchResultItem: ProductSearchResultItem = {
    // ProductId is a manadatory field
    id: item.itemId,

    description: '',
    altImgUrl: '',
    imgUrl: '',
    rating: '',
    price: '',
    name: '',
    size: '',
    url: '',
    data: item.fields || {},
  };

  let candidateNames: any[] = [];
  let candidateImgUrls: any[] = [];
  let candidateDescriptions: any[] = [];

  const document = productSearchResultItem.data;
  // Add candidates by periorities for the fallback logic
  searchIndex.fields.map(schemaField => {
    const schemaFieldType = schemaField.type.toLowerCase();
    const schemaFieldName = schemaField.name.toLowerCase();
    const documentItemValue = document[schemaFieldName];
    if (!!documentItemValue) {
      const index = IndexTypePriorityMap[schemaFieldType];
      switch (schemaFieldType) {
        case 'productid':
          productSearchResultItem.id = documentItemValue;
          break;
        case 'size':
          productSearchResultItem.size = documentItemValue;
          break;
        case 'rating':
          productSearchResultItem.rating = documentItemValue;
          break;
        case 'price':
          productSearchResultItem.price = documentItemValue;
          break;
        case 'url':
          productSearchResultItem.url = documentItemValue;
          break;
        case 'identifier':
        case 'number':
        case 'title':
        case 'dupid':
          candidateNames[index] = documentItemValue;
          break;
        case 'imageurl':
        case 'secondaryimageurls':
          candidateImgUrls[index] = documentItemValue;
          break;
        case 'description':
        case 'category':
        case 'material':
        case 'ageGroup':
        case 'gender':
        case 'string':
        case 'brand':
        case 'model':
        case 'color':
          candidateDescriptions[index] = documentItemValue;
          break;
      }
    }
  });

  productSearchResultItem.name = candidateNames.reduce((prev, curr) => (!!prev ? prev : curr), '');
  productSearchResultItem.description = candidateDescriptions.reduce((prev, curr) => (!!prev ? prev : curr), '');

  productSearchResultItem.imgUrl = candidateImgUrls.reduce((prev, curr) => (!!prev ? prev : curr), '');
  productSearchResultItem.altImgUrl = candidateImgUrls.reduce(
    (prev, curr) => (!!prev ? prev : curr === productSearchResultItem.imgUrl ? prev : curr),
    '',
  );

  return productSearchResultItem;
};

export const mapSearchProductListDtoToModel = (
  skip: number,
  searchResults: SearchResults,
  searchIndex: SearchIndex,
): ProductSearchResult => ({
  skip: skip,
  totalEstimatedMatches: searchResults.items ? searchResults.items.totalEstimatedMatches : 0,
  query: searchResults.queryContext ? searchResults.queryContext.originalQuery : '',
  alteredQuery: searchResults.queryContext ? searchResults.queryContext.alteredQuery : '',
  searchResultItems: searchResults.items
    ? searchResults.items.value.map(item => mapSearchResultItem(searchIndex, item))
    : [],
});

export const mapSearchPreviewProductListDtoToModel = (
  skip: number,
  top: number,
  searchResults: SearchResults,
  searchIndex: SearchIndex,
  selectedRefinements: FacetOption[],
  url: string,
  postBody: SearchPostRequest,
): SearchPreviewResult => ({
  skip: skip,
  top: top,
  isLoading: false,
  redirectUrl: searchResults.redirectUrl,
  banners: searchResults.banners
    ? searchResults.banners.map(b => ({
        content: b.value,
        contentType: b._type as BannerType,
      }))
    : [],
  facets: getFacetsFromApiResponse(searchResults, selectedRefinements),
  searchRawResponse: { url: url, postBody: postBody, Response: searchResults },
  query: searchResults.queryContext ? searchResults.queryContext.originalQuery : '',
  alteredQuery: searchResults.queryContext ? searchResults.queryContext.alteredQuery : '',
  totalEstimatedMatches: searchResults.items ? searchResults.items.totalEstimatedMatches : 0,
  searchResultItems: searchResults.items
    ? searchResults.items.value.map(item => mapSearchResultItem(searchIndex, item))
    : [],
});
