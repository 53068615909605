import { getI18n } from 'react-i18next';
import { UPDATE_SEARCH_MODEL_EVALUATION_JOB } from '../../../../types/customML/searchModel.d';
import { EvaluationJob, ADD_EVALUATION_JOB } from '../../../../types/customML/evaluationJob.d';
import { ActionSuccessHandler, ActionFailureHandler, ReduxCrudAction } from '../../CrudAction.types';
import { createEvaluationJob } from '../../../../../restful-apis/customML.api';

export const createEvaluationJobAction = (
  tenantId: string,
  searchModelKey: string,
  evaluationJob: EvaluationJob,
  onSuccess?: ActionSuccessHandler<EvaluationJob>,
  onFailure?: ActionFailureHandler<EvaluationJob>,
): ReduxCrudAction<EvaluationJob> => {
  const i18n = getI18n();
  return {
    request: createEvaluationJob(tenantId, searchModelKey, evaluationJob),
    postActionMapper: createdEvaluationJob => [
      {
        type: ADD_EVALUATION_JOB,
        evaluationJob: { ...createdEvaluationJob, isNew: true },
      },
      {
        type: UPDATE_SEARCH_MODEL_EVALUATION_JOB,
        key: searchModelKey,
        evaluationJobKey: createdEvaluationJob.key,
      },
    ],
    notification: {
      failure: () => ({
        title: i18n.t('custom-ml.eval-list.req-failure', { name: evaluationJob.name }),
      }),
      inProgress: {
        title: i18n.t('custom-ml.eval-list.req-inprogress', { name: evaluationJob.name }),
      },
      success: () => ({
        title: i18n.t('custom-ml.eval-list.req-success', { name: evaluationJob.name }),
      }),
    },
    onSuccess: onSuccess,
    onFailure: response => onFailure && onFailure(response, evaluationJob),
  };
};
