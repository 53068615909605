import { FetchRequest } from '../Types';

export abstract class BaseRequestInterceptor {
  private targetUrl: RegExp;

  constructor(targetUrl: RegExp) {
    this.targetUrl = targetUrl;
  }

  public Execute(request: FetchRequest): Promise<FetchRequest> {
    if (this.isMatching(request)) {
      return this.PerformAction(request);
    }
    return Promise.resolve(request);
  }

  protected abstract PerformAction(request: FetchRequest): Promise<FetchRequest>;

  private isMatching(request: FetchRequest) {
    return this.targetUrl.test(request.input.toString());
  }
}
