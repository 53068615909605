import { configureDefaultObject, getLocaleTimeNow } from '../../../utils';
import {
  Dataset,
  DatasetMapper,
  DatasetState,
  DatasetStateActionTypes,
  RESET_DATASET_LIST,
  SET_DATASET_LIST,
  ADD_DATASET,
  LOADING_DATASET_LIST,
  DatasetType,
} from '../../types/customML/dataset.d';

export const createInitialDataset = (): Dataset => ({
  key: '',
  name: '',
  email: '',
  isNew: false,
  description: '',
  createdAt: getLocaleTimeNow(),
  updatedAt: getLocaleTimeNow(),
  datasetType: DatasetType.Unknown,
});

const createDatasetMapper = (initialMapper: DatasetMapper = {}) =>
  configureDefaultObject<DatasetMapper, Dataset>(initialMapper, createInitialDataset());

const initialState: DatasetState = {
  isLoading: true,
  datasetMapper: createDatasetMapper(),
};

export function datasetReducer(state = initialState, action: DatasetStateActionTypes): DatasetState {
  switch (action.type) {
    case LOADING_DATASET_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_DATASET_LIST: {
      return {
        ...state,
        isLoading: true,
        datasetMapper: createDatasetMapper(),
      };
    }
    case SET_DATASET_LIST: {
      return {
        ...state,
        isLoading: false,
        datasetMapper: createDatasetMapper({
          ...action.datasetList.reduce((mapper: DatasetMapper, Dataset: Dataset) => {
            mapper[Dataset.key] = Dataset;
            return mapper;
          }, {}),
        }),
      };
    }
    case ADD_DATASET: {
      return {
        ...state,
        datasetMapper: createDatasetMapper({
          [action.dataset.key]: action.dataset,
          ...state.datasetMapper,
        }),
      };
    }
    default:
      return state;
  }
}
