import HttpClient from '../http-client/httpClient';
import { SearchInstance as SearchInstanceModel } from '../../store/types/customizations/searchInstanceList';
import { SearchInstance as SearchInstanceDTO } from '../dto/search-instance/searchInstance';
import { SearchInstanceList as SearchInstanceListDTO } from '../dto/search-instance/searchInstanceList';
import { mapSearchInstanceDtoToModel, mapSearchInstanceModelToDto } from '../mappers/searchInstanceMapper';
import { DevOpsCommitMetadata } from '../../store/types/devOps';
import { getDevOpsCommitHeaders } from '../devops.api';
import { isDynamicsTenant } from '../../utils/dynamics';

function getSearchInstanceAPIBaseUri(tenantId: string, indexId: string): string {
  return `https://commerce.bing.com/api/customization/v1/searchinstance/${tenantId}/indexes/${indexId}`;
}

export function getSearchInstances(tenantId: string, indexId: string): Promise<SearchInstanceModel[]> {
  // special handling due to dynamics tenant limitations
  if (isDynamicsTenant(tenantId)) {
    return Promise.resolve([{ id: 'default', name: 'Default' }]);
  }
  return HttpClient.get<SearchInstanceListDTO>({
    url: getSearchInstanceAPIBaseUri(tenantId, indexId),
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(instances => instances.values.map(mapSearchInstanceDtoToModel));
}

export function cloneSearchInstance(
  tenantId: string,
  indexId: string,
  srcSearchInstanceId: string,
  clonedSearchInstanceName: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<SearchInstanceModel> {
  return HttpClient.post<SearchInstanceDTO>({
    url: getSearchInstanceAPIBaseUri(tenantId, indexId),
    body: {
      CloneFrom: srcSearchInstanceId,
      SearchInstanceId: clonedSearchInstanceName,
    },
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapSearchInstanceDtoToModel);
}

export function createSearchInstance(
  tenantId: string,
  indexId: string,
  searchInstance: SearchInstanceModel,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<SearchInstanceModel> {
  return HttpClient.post<SearchInstanceDTO>({
    url: getSearchInstanceAPIBaseUri(tenantId, indexId),
    body: mapSearchInstanceModelToDto(searchInstance),
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapSearchInstanceDtoToModel);
}

export function deleteSearchInstance(
  tenantId: string,
  indexId: string,
  searchInstanceId: string,
  devOpsCommitMetadata?: DevOpsCommitMetadata,
): Promise<SearchInstanceModel> {
  return HttpClient.delete<SearchInstanceDTO>({
    url: `${getSearchInstanceAPIBaseUri(tenantId, indexId)}?searchinstanceid=${searchInstanceId}&forceDelete=true`,
    body: {},
    headers: devOpsCommitMetadata ? getDevOpsCommitHeaders(devOpsCommitMetadata) : undefined,
    authInfo: { tenantId: tenantId, indexId: indexId },
  }).then(mapSearchInstanceDtoToModel);
}
