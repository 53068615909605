import {
  Commit as CommitModel,
  Deployment as DeploymentModel,
  DeploymentDetails as DeploymentDetailsModel,
  DeploymentStatus,
} from '../../store/types/devOps';
import {
  Commit as CommitDto,
  CommitList as CommitListDto,
  Deployment as DeploymentDto,
  DeploymentList as DeploymentListDto,
  DeploymentDetails as DeploymentDetailsDto,
} from '../dto/devops/devops';

export function mapCommitDtoToModel(commit: CommitDto): CommitModel {
  return {
    commitId: commit.commitId,
    author: commit.author,
    message: commit.message,
    createdDateTime: commit.createdDateTime,
  };
}

export function mapDeploymentDetailsDtoToModel(deploymentDetails: DeploymentDetailsDto): DeploymentDetailsModel {
  return {
    service: deploymentDetails.service,
    path: deploymentDetails.path,
    status: deploymentDetails.status as DeploymentStatus,
  };
}

export function mapDeploymentDtoToModel(deployment: DeploymentDto): DeploymentModel {
  return {
    deploymentId: deployment.deploymentId,
    status: deployment.status as DeploymentStatus,
    source: deployment.source,
    author: deployment.author,
    commitId: deployment.commitId,
    startTime: deployment.startTime,
    endTime: deployment.endTime,
    isActive: deployment.isActive,
    details: deployment.details.map(mapDeploymentDetailsDtoToModel),
  };
}

export function mapCommitListDtoToModel(commitList: CommitListDto): CommitModel[] {
  return commitList.commits.map(mapCommitDtoToModel);
}

export function mapDeploymentListDtoToModel(DeploymentList: DeploymentListDto): DeploymentModel[] {
  return DeploymentList.deployments.map(mapDeploymentDtoToModel);
}
