import { LatencyPerDocumentStyleProps, LatencyPerDocumentStyles } from './LatencyPerDocument.types';

export const styles = (props: LatencyPerDocumentStyleProps): LatencyPerDocumentStyles => ({
  root: [
    'mbc-latency-per-document-container',
    {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      selectors: {
        '.mbc-card-body': {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        },
      },
    },
    props.className,
  ],
  cardBody: [
    'mbc-card-body',
    {
      marginBottom: 12,
      width: '100%',
    },
  ],
  legendsContainer: [
    'mbc-legends-container',
    {
      height: 'fit-content',
      width: '84%',
    },
  ],
  legendContainer: [
    'mbc-legend-container',
    {
      cursor: 'pointer',
      alignItems: 'center',
      paddingBottom: 4,
      width: 120,
      marginRight: 0,
      marginLeft: 0,
    },
  ],
  legendIcon: [
    'mbc-legend-icon',
    {
      height: 12,
      width: 21,
      borderRadius: 4,
    },
  ],
  legendLabel: [
    'mbc-legend-label',
    {
      fontSize: 12,
      lineHeight: 14,
    },
  ],
  subComponentStyles: {
    card: {},
  },
});
