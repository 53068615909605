import { generateUniqueIdentifier } from '../../../../../../utils';
import { SearchIndexTypeConfig } from '../../../SchemaManagement/SchemaManagement.config';
import { ProductItem, Product, ProductItemValueType } from '../ProductPreview.types';
import { IndexField } from '../../../../../../store/types/searchIndex';
import _ from 'lodash';

export const mapProductDtoToDefaultListModel = (indexFieldList: IndexField[], product: Product): ProductItem[] => {
  const nonWildcardItemList: ProductItem[] = indexFieldList.map(field => {
    const isObjectTypeField =
      !!SearchIndexTypeConfig[field.type] && SearchIndexTypeConfig[field.type].dataType === 'Object';
    const productItemValue = product[field.name.toLowerCase()];
    return {
      ...field,
      isWildcardMatch: false,
      isWildcard: field.name === '*',
      key: generateUniqueIdentifier(),
      placeholder: '',
      value: isObjectTypeField
        ? mapProductDtoToDefaultListModel(
            field.fields || [],
            productItemValue || SearchIndexTypeConfig[field.type].defaultValue,
          )
        : SearchIndexTypeConfig[field.type].matches(productItemValue)
        ? productItemValue
        : null,
    };
  });

  const wildcardItemIndex = indexFieldList.findIndex(field => field.name === '*');
  if (wildcardItemIndex === -1) {
    return nonWildcardItemList;
  }

  let wildcardItemList: ProductItem[] = [];
  const wildcardItem = indexFieldList[wildcardItemIndex];
  const isObjectTypeWildcard =
    !!SearchIndexTypeConfig[wildcardItem.type] && SearchIndexTypeConfig[wildcardItem.type].dataType === 'Object';
  wildcardItemList = Object.entries(product)
    .filter((entry: [string, any]) => {
      const matchesAnyOfSchemFields = indexFieldList.find(field => field.name.toLowerCase() === entry[0]);
      const matchesWildcardType = SearchIndexTypeConfig[wildcardItem.type].matches(entry[1]);

      return !matchesAnyOfSchemFields && matchesWildcardType;
    })
    .map((entry: [string, any]) => {
      const productItemLabel = entry[0];
      const productItemValue = entry[1];
      return {
        ...wildcardItem,
        placeholder: '',
        isWildcard: false,
        isWildcardMatch: true,
        name: productItemLabel,
        key: generateUniqueIdentifier(),
        value: isObjectTypeWildcard
          ? mapProductDtoToDefaultListModel(wildcardItem.fields || [], productItemValue)
          : productItemValue,
      };
    });

  return nonWildcardItemList.concat(wildcardItemList);
};

const deepCloneProductItem = (product: ProductItem): ProductItem => ({
  ...product,
  key: generateUniqueIdentifier(),
  value:
    !!SearchIndexTypeConfig[product.type] && SearchIndexTypeConfig[product.type].dataType !== 'Object'
      ? product.value
      : (product.value as ProductItem[]).map(deepCloneProductItem),
});

const getProductItem = (productItemList: ProductItem[], productItemKey: string): ProductItem | null => {
  const productItemIndex = productItemList.findIndex(productItem => productItem.key === productItemKey);
  if (productItemIndex !== -1) {
    return productItemList[productItemIndex];
  }

  for (let i = 0; i < productItemList.length; i++) {
    if (
      !!SearchIndexTypeConfig[productItemList[i].type] &&
      SearchIndexTypeConfig[productItemList[i].type].dataType !== 'Object'
    ) {
      continue;
    }

    const productItem = getProductItem(productItemList[i].value as ProductItem[], productItemKey);
    if (productItem !== null) {
      return productItem;
    }
  }

  return null;
};

export const updateProductItemList = (
  productItemList: ProductItem[],
  productItem: ProductItem,
): ProductItem[] | null => {
  const productItemIndex = productItemList.findIndex(item => item.key === productItem.key);
  if (productItemIndex !== -1) {
    productItemList[productItemIndex] = productItem;
    return [...productItemList];
  }

  for (let i = 0; i < productItemList.length; i++) {
    if (
      !!SearchIndexTypeConfig[productItemList[i].type] &&
      SearchIndexTypeConfig[productItemList[i].type].dataType !== 'Object'
    ) {
      continue;
    }

    const updatedProductItemList = updateProductItemList(productItemList[i].value as ProductItem[], productItem);
    if (updatedProductItemList !== null) {
      productItemList[i].value = updatedProductItemList;
      return [...productItemList];
    }
  }

  return null;
};

export const removewildcardItemFromProductItemList = (
  productItemList: ProductItem[],
  activeProductItemKey: string,
  productItem: ProductItem,
): ProductItem[] | null => {
  const activeProductItem = getProductItem(productItemList, activeProductItemKey);
  if (activeProductItem === null) {
    return null;
  }

  return updateProductItemList(productItemList, {
    ...activeProductItem,
    value: (activeProductItem.value as ProductItem[]).filter(item => item.key !== productItem.key),
  });
};

export const insertwildcardItemToProductItemList = (
  productItemList: ProductItem[],
  activeProductItemKey: string,
  productItem: ProductItem,
): ProductItem[] | null => {
  const activeProductItem = getProductItem(productItemList, activeProductItemKey);
  if (activeProductItem === null) {
    return null;
  }

  const wildcardProductItem = [...(activeProductItem.value as ProductItem[])];
  // Skip the first wildcard item and add next to it.
  wildcardProductItem.splice(1, 0, deepCloneProductItem(productItem));
  return updateProductItemList(productItemList, {
    ...activeProductItem,
    value: wildcardProductItem,
  });
};

export const isInvalidProductItem = (productType: string, value: ProductItemValueType) =>
  !!value &&
  !_.isEqual(value, SearchIndexTypeConfig[productType].defaultValue) &&
  !!SearchIndexTypeConfig[productType] &&
  SearchIndexTypeConfig[productType].dataType !== 'Object' &&
  !SearchIndexTypeConfig[productType].matches(value);

export const isInvalidProductItemList = (productItemList: ProductItem[]): boolean =>
  productItemList.reduce(
    (prev: boolean, curr: ProductItem): boolean =>
      prev ||
      isInvalidProductItem(curr.type, curr.value) ||
      (!!SearchIndexTypeConfig[curr.type] &&
        SearchIndexTypeConfig[curr.type].dataType === 'Object' &&
        isInvalidProductItemList(curr.value as ProductItem[])),
    false,
  );
