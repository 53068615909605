import {
  ADD_DETECTED_INDEX_SCHEMA,
  RESET_DETECTED_INDEX_SCHEMA,
  SET_DETECTED_INDEX_SCHEMA,
  SchemaDetection,
  SchemaDetectionMapper,
  SchemaDetectionState,
  SchemaDetectionStateActionTypes,
} from '../types/schemaDetection.d';

import { configureDefaultObject } from '../../utils';

const createInitialSchemaDetection = () => ({
  key: '',
  fields: [],
  environmentId: '',
  transformationScript: '',
});

const createSchemaDetectionMapper = (initialMapper: SchemaDetectionMapper = {}) => {
  return configureDefaultObject<SchemaDetectionMapper, SchemaDetection>(initialMapper, createInitialSchemaDetection());
};

const initialState: SchemaDetectionState = {
  schemaDetectionMapper: createSchemaDetectionMapper(),
  activeSchemaDetection: createInitialSchemaDetection(),
};

export function schemaDetectionReducer(
  state = initialState,
  action: SchemaDetectionStateActionTypes,
): SchemaDetectionState {
  switch (action.type) {
    case ADD_DETECTED_INDEX_SCHEMA: {
      const updatedSchemaDetectionMapper = createSchemaDetectionMapper({
        ...state.schemaDetectionMapper,
        [action.schemaDetection.key]: action.schemaDetection,
      });
      return {
        ...state,
        schemaDetectionMapper: updatedSchemaDetectionMapper,
      };
    }
    case SET_DETECTED_INDEX_SCHEMA: {
      return {
        ...state,
        activeSchemaDetection: state.schemaDetectionMapper[action.key],
      };
    }
    case RESET_DETECTED_INDEX_SCHEMA: {
      return {
        ...state,
        activeSchemaDetection:
          state.activeSchemaDetection.key === action.key ? createInitialSchemaDetection() : state.activeSchemaDetection,
      };
    }
    default:
      return state;
  }
}
