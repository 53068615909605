import React, { Component, Fragment } from 'react';
import { AppState } from '../store/reducers';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

interface AuthGuardProps {
  isLoggedIn: boolean;
}

export class AuthGuardBase extends Component<AuthGuardProps> {
  render() {
    return <Fragment>{this.props.isLoggedIn === true ? this.props.children : <Redirect to="/" />}</Fragment>;
  }
}
const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
});

export default connect(mapStateToProps)(AuthGuardBase);
