import {
  LOADING_ACTIVE_SEARCH_INDEX,
  LOADING_SEARCH_INDEX_LIST,
  RESET_ACTIVE_SEARCH_INDEX,
  RESET_SEARCH_INDEX_LIST,
  SET_ACTIVE_SEARCH_INDEX,
  SET_SEARCH_INDEX_LIST,
  SearchIndex,
  SearchIndexStateActionTypes,
} from '../types/searchIndex.d';
import {
  createSearchIndexSchemaAction,
  updateSearchIndexSchemaAction,
} from './utils/crud-actions/index-schema/IndexSchemaCrudActions';
import { resetIndexDevops, setIndexDevops } from './devopsActions';

import { Action } from 'redux';
import { AppState } from '../reducers';
import { ExecuteReduxCrudAction } from './utils';
import { ThunkAction } from 'redux-thunk';
import { getBlockedSuggestionsAction } from './autosuggestActions';
import { getTransformationConfig } from '../../restful-apis/searchIndex.api';
import { retrieveSearchInstances } from './customizations/searchInstanceListActions';

type OnSearchIndexActionSuccess = (searchIndex: SearchIndex) => void;
type OnSearchIndexActionFailure = (response: Response) => void;

export function startLoadingSearchIndexList(): SearchIndexStateActionTypes {
  return {
    type: LOADING_SEARCH_INDEX_LIST,
  };
}

export const saveActiveSearchIndex = (
  searchIndex: SearchIndex,
  onSuccess?: OnSearchIndexActionSuccess,
  onFailure?: OnSearchIndexActionFailure,
  // commitMessage?: string,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenantsList.activeTenant.id;
  const environmentId = state.environmentList.activeEnvironment.id;
  // const isDevOpsEnabled = state.searchIndex.searchIndex.devOpsEnabled;
  // const devOpsCommitMetadata = isDevOpsEnabled
  //   ? { author: state.user.email, message: commitMessage ? commitMessage : 'Update search index' }
  //   : undefined;
  dispatch(
    ExecuteReduxCrudAction(updateSearchIndexSchemaAction(tenantId, environmentId, searchIndex, onSuccess, onFailure)),
  );
};

export const setActiveSearchIndex = (searchIndexId: string): ThunkAction<void, AppState, null, Action> => (
  dispatch,
  getState,
) => {
  dispatch({
    type: LOADING_ACTIVE_SEARCH_INDEX,
  });
  const tenant = getState().tenantsList.activeTenant;
  const devOpsEnabled = getState().searchIndex.searchIndexIdMapper[searchIndexId].devOpsEnabled;
  getTransformationConfig(tenant.id, searchIndexId).then(transformationConfig => {
    dispatch({
      type: SET_ACTIVE_SEARCH_INDEX,
      searchIndexId: searchIndexId,
      transformationScript: transformationConfig.transformationScript,
    });
  });
  dispatch(retrieveSearchInstances(tenant.id, searchIndexId));
  dispatch(getBlockedSuggestionsAction(tenant.id, searchIndexId));
  devOpsEnabled && dispatch(setIndexDevops(tenant.id, searchIndexId));
};

export const setSearchIndexList = (
  searchIndexList: SearchIndex[],
): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: SET_SEARCH_INDEX_LIST,
    searchIndexList: searchIndexList,
  });
};

export const createNewSearchIndex = (
  searchIndex: SearchIndex,
  onSuccess?: OnSearchIndexActionSuccess,
  onFailure?: OnSearchIndexActionFailure,
  // commitMessage?: string,
): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenantsList.activeTenant.id;
  const environmentId = state.environmentList.activeEnvironment.id;
  // const devOpsCommitMetadata = state.searchIndex.searchIndex.devOpsEnabled
  //   ? { author: state.userl.email, message: commitMessage || 'Create new search index' }
  //   : undefined;
  dispatch(
    ExecuteReduxCrudAction(
      createSearchIndexSchemaAction(
        tenantId,
        environmentId,
        {
          ...searchIndex,
          searchServices: state.environmentList.activeEnvironment.products,
          regions: state.environmentList.activeEnvironment.regions,
        },
        onSuccess,
        onFailure,
      ),
    ),
  );
};

export const toggleDevOpsEnabled = (): ThunkAction<void, AppState, null, Action> => (dispatch, getState) => {
  const updatedSearchIndex = getState().searchIndex.searchIndex;
  updatedSearchIndex.devOpsEnabled = !updatedSearchIndex.devOpsEnabled;
  dispatch(saveActiveSearchIndex(updatedSearchIndex));
};

export const resetActiveSearchIndex = (): ThunkAction<void, AppState, null, Action> => dispatch => {
  dispatch({
    type: RESET_ACTIVE_SEARCH_INDEX,
  });
  dispatch(resetIndexDevops());
};

export function resetSearchIndexList(): SearchIndexStateActionTypes {
  return {
    type: RESET_SEARCH_INDEX_LIST,
  };
}
