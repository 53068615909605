import React, { Component } from 'react';
import SearchDemo, { Scenario } from '../../../common/search-demo/searchDemo';
import styles from './RelevanceB.module.scss';
import { SearchResult } from '../../../../../store/types/searchDemo';
import { getRelevanceSearchResult } from '../../../../../store/actions/searchDemoActions';
import { connect } from 'react-redux';

interface RelevanceBProps {
  getRelevanceSearchResult: Function;
  relevanceBSearchResults: SearchResult;
  minHeight: number;
  componentRef: React.RefObject<HTMLDivElement>;
}

interface RelevanceBState {
  alteredQuery: string;
  originalQuery: string;
}

export class RelevanceBBase extends Component<RelevanceBProps, RelevanceBState> {
  constructor(props: RelevanceBProps) {
    super(props);
    this.state = {
      originalQuery: '',
      alteredQuery: '',
    };
  }
  private onChangeQuery(query: string) {
    this.setState({
      originalQuery: query,
      alteredQuery: this.props.relevanceBSearchResults.alteredQuery,
    });

    this.props.getRelevanceSearchResult(query);
  }

  render() {
    return (
      <div
        ref={this.props.componentRef}
        className={styles.wrapper}
        style={{ minHeight: this.props.minHeight > 0 ? this.props.minHeight : '100vh' }}
      >
        <SearchDemo
          scenario={Scenario.QueryUnderstanding}
          defaultQuery={'casual dress'}
          searchDemoTitle={'Search with query understanding'}
          searchResultLabel={
            <div>
              <span>{'Showing results for '}</span>
              <span className={styles.targetQuery}>
                {this.props.relevanceBSearchResults.alteredQuery
                  ? this.props.relevanceBSearchResults.alteredQuery
                  : this.props.relevanceBSearchResults.originalQuery}
              </span>
            </div>
          }
          SearchResultItems={this.props.relevanceBSearchResults.searchResultItems}
          onChangeQuery={this.onChangeQuery.bind(this)}
          showPoweredBy={true}
        ></SearchDemo>
      </div>
    );
  }
}

export default connect(
  undefined,
  { getRelevanceSearchResult },
)(RelevanceBBase);
