import { useDispatch } from '..';
import { addBreadcrumbItem, removeBreadcrumbItem } from '../../actions/breadcrumbsActions';
import { useState, useEffect } from 'react';

// Use Lazy Breadcrumb hook when the breadcrumbText value can be determined from URL such as Roles and Tokens
export const useBreadcrumb = (breadcrumbKey: string, breadcrumbUrl: string, onItemRender?: () => JSX.Element): void => {
  const dispatch = useDispatch();
  const [prevBreadcrumbUrl, setPrevBreadcrumbUrl] = useState('');

  if (prevBreadcrumbUrl !== breadcrumbUrl) {
    dispatch(
      addBreadcrumbItem({
        href: breadcrumbUrl,
        key: breadcrumbKey,
        text: breadcrumbKey,
        onItemRender: onItemRender,
      }),
    );

    setPrevBreadcrumbUrl(breadcrumbUrl);
  }

  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbItem(breadcrumbKey));
    };
  }, [dispatch, breadcrumbUrl, breadcrumbKey]);
};
