import React, { useCallback, ReactNode, useState, createRef, useEffect } from 'react';
import { classNamesFunction, IColumn, TooltipHost, TooltipOverflowMode, Link, Stack } from 'office-ui-fabric-react';
import { SynonymSetListProps, SynonymSetListStyleProps, SynonymSetListStyles } from './SynonymSetList.types';
import { useSelector, useDispatch } from '../../../store/hooks';
import { AppState } from '../../../store/reducers';
import { PageTemplate } from '../../../components/common/PageTemplate';
import { PageHeaderProps } from '../../../components/common/PageHeader';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { SynonymSet } from '../../../store/types/customizations/synonymSet';
import { ColumnPriority, SortDataType, OptionsItemProps, ListItem } from '../../../components/common/DetailsList';
import { DetailsListCard } from '../../../components/common/DetailsListCard';
import { SearchInstanceSelector } from '../SearchInstance/SearchInstanceSelector';
import { deleteSynonymSet } from '../../../store/actions/customizations/synonymSetActions';
import {
  MbcSynonymSetRouter,
  MbcSearchInstanceRouter,
} from '../../../router/MbcSearchInstanceRouter/MbcSearchInstanceRouter';
import { MbcRouteKey } from '../../../router/MbcRouter.types';
import { ConfirmationDialog } from '../../../components/common/Dialog/ConfirmationDialog';
import { toggleSideNavExpanded } from '../../../store/actions/sideNavActions';
import { EnvironmentPermissionContext, EnvironmentPermissionContextType } from '../../../contexts';
import { Permission } from '../../../config/userPermissions.config';
import { Tutorial, TutorialSteps, TutorialsId } from '../../../components/Tutorials';
import { useTranslation } from 'react-i18next';
import { startTutorial } from '../../../store/actions/tutorialActions';
import { AvailableTutorials } from '../../../store/types/tutorial.d';

interface SynonymSetListItem extends ListItem {
  name: string;
}

const getClassNames = classNamesFunction<SynonymSetListStyleProps, SynonymSetListStyles>();

export const SynonymSetListBase = (props: SynonymSetListProps) => {
  const { styles, theme, className } = props;

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const containerRef = createRef<HTMLDivElement>();

  const { t } = useTranslation();

  const [synonymToDelete, setSynonymToDelete] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const activeEnvironment = useSelector(state => state.environmentList.activeEnvironment);
  const activeSearchInstance = useSelector(state => state.searchInstanceList.activeSearchInstance);
  const isSynonymSetListLoading = useSelector<boolean>((state: AppState) => state.synonymSet.isLoading);
  const synonymSetList = useSelector<SynonymSet[]>((state: AppState) =>
    Object.values(state.synonymSet.synonymSetIdMapper),
  );
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);
  const completedTutorials = useSelector(state => state.user.sandBoxDetails.completedTutorials);
  const currentTutorial = useSelector(state => state.tutorial.currentTutorial);

  const i18n = useTranslation();

  const pageHeaderProps: PageHeaderProps = {
    title: i18n.t('synonym-list.title'),
    infoProps: {
      content: i18n.t('synonym-list.info-content'),
      headline: i18n.t('synonym-list.title'),
      linkProps: {
        linkText: i18n.t('synonym-list.info-link'),
        to: '/docs/Portal%20Documentation/#manage-synonyms',
      },
    },
  };

  useEffect(() => {
    if (!completedTutorials.includes(TutorialsId.Synonyms) && currentTutorial !== AvailableTutorials.SynonymsFull) {
      dispatch(startTutorial(AvailableTutorials.SynonymsFull));
    }
  }, [completedTutorials, currentTutorial, dispatch]);

  const handleEditItemClick = useCallback(
    (item: SynonymSetListItem) => {
      MbcSynonymSetRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id, item.key)(
        MbcRouteKey.SynonymSetEditor,
      ).browserPush();
    },
    [activeEnvironment.id, activeSearchInstance.id, activeTenant.id],
  );

  const handleAddItemClick = useCallback(() => {
    MbcSearchInstanceRouter(activeTenant.id, activeEnvironment.id, activeSearchInstance.id)(
      MbcRouteKey.NewSynonymSet,
    ).browserPush();
  }, [activeEnvironment.id, activeSearchInstance.id, activeTenant.id]);

  const onRenderItemColumn = useCallback(
    (isAuthorized: boolean, item?: any, index?: number, column?: IColumn): ReactNode => {
      if (item && column) {
        switch (column.key) {
          case 'name':
            return (
              <Stack>
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.nameColumnCell}
                  style={{ maxWidth: column.maxWidth }}
                  content={item.name}
                >
                  {!!isAuthorized ? (
                    <Link
                      onClick={() => handleEditItemClick(item)}
                      style={{ maxWidth: column.maxWidth ? column.maxWidth + 1 : undefined }}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    item.name
                  )}
                </TooltipHost>
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Self}
                  hostClassName={classNames.nameColumnCellDescription}
                  style={{ maxWidth: column.maxWidth }}
                  content={item.name}
                >
                  {item.description}
                </TooltipHost>
              </Stack>
            );

          default:
            return <span>{item[column.fieldName as string] as string}</span>;
        }
      }
    },
    [classNames.nameColumnCell, classNames.nameColumnCellDescription, handleEditItemClick],
  );

  const getOptionsItemProps = useCallback(
    (item: any, isAuthorized: boolean): OptionsItemProps[] => {
      return [
        {
          key: 'Edit',
          text: 'Edit',
          iconProps: { iconName: 'Edit' },
          disabled: !isAuthorized,
          onClick: () => {
            handleEditItemClick(item);
          },
        },
        {
          key: 'Delete',
          text: 'Delete',
          iconProps: { iconName: 'Delete' },
          disabled: !isAuthorized,
          onClick: () => {
            setSynonymToDelete(item.key);
          },
        },
      ];
    },
    [handleEditItemClick],
  );

  const onDeleteConfirmation = useCallback(() => {
    synonymToDelete && dispatch(deleteSynonymSet(synonymToDelete));
    setSynonymToDelete(undefined);
  }, [dispatch, synonymToDelete]);

  const onDeleteDismiss = useCallback(() => {
    setSynonymToDelete(undefined);
  }, []);

  const getSynonymSetListItems = useCallback((): SynonymSetListItem[] => {
    return synonymSetList.map(synonymSet => {
      return {
        key: synonymSet.id,
        name: synonymSet.id,
        description: synonymSet.synonymList.join(', '),
      };
    });
  }, [synonymSetList]);

  const getSynonymDetailsListProps = useCallback(
    (isAuthorized: boolean) => {
      return {
        isLoading: isSynonymSetListLoading,
        items: getSynonymSetListItems(),
        onRenderNoItemsToDisplay: {
          text: 'No synonyms created yet',
          subText: isAuthorized
            ? 'Get started by creating your first synonym'
            : 'Permission required to create synonyms is not granted',
          actionProps: isAuthorized
            ? {
                buttonText: 'Get started',
                onClick: handleAddItemClick,
              }
            : undefined,
        },
        responsiveColumns: {
          columns: [
            {
              key: 'name',
              name: 'Name',
              fieldName: 'name',
              priority: ColumnPriority.Primary,
              sortDataType: SortDataType.text,
              minWidth: 200,
              subTextField: 'description',
              onRender: (item?: any, index?: number, column?: IColumn) =>
                onRenderItemColumn(isAuthorized, item, index, column),
            },
          ],
          secondaryBreakPoint: 500,
          teritaryBreakPoint: 800,
        },
        optionsItemProps: (item: any) => getOptionsItemProps(item, isAuthorized),
      };
    },
    [getOptionsItemProps, getSynonymSetListItems, handleAddItemClick, isSynonymSetListLoading, onRenderItemColumn],
  );

  const getHeaderActions = useCallback(
    (isAuthorized: boolean) => {
      return isAuthorized && getSynonymSetListItems().length > 0
        ? {
            add: {
              onClick: () => {
                handleAddItemClick();
              },
            },
          }
        : undefined;
    },
    [getSynonymSetListItems, handleAddItemClick],
  );

  const onRenderContent = (isAuthorized: boolean): JSX.Element => {
    return (
      <div ref={containerRef} style={{ height: '100%' }}>
        <DetailsListCard
          searchActionProps={{ searchBy: 'name' }}
          listProps={getSynonymDetailsListProps(isAuthorized)}
          header={{
            actions: getHeaderActions(isAuthorized),
          }}
        />
      </div>
    );
  };

  return (
    <div className={classNames.root}>
      <EnvironmentPermissionContext.Consumer>
        {(props: EnvironmentPermissionContextType) => {
          const isAuthorized = props.isAuthorized(Permission.SearchCustomizationReadWrite);
          return (
            <>
              <Tutorial
                target={containerRef}
                step={TutorialSteps.SynonymsList}
                headline={t('product-tours-panel.synonyms-tour.headline2')}
                coverTarget={true}
                lightBackground
                onStepEnd={() => {
                  handleAddItemClick();
                }}
              >
                <>
                  <p>{t('product-tours-panel.synonyms-tour.step-2.pargraph1')}</p>
                  <p>{t('product-tours-panel.synonyms-tour.step-2.pargraph2')}</p>
                </>
              </Tutorial>
              <PageTemplate
                pageHeaderProps={pageHeaderProps}
                onRenderContent={() => onRenderContent(isAuthorized)}
                breadcrumbVisible={true}
                onSecondaryNavMenuRender={() => <SearchInstanceSelector />}
                sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
                sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
              />
              <ConfirmationDialog
                key={synonymToDelete}
                isOpen={!!synonymToDelete}
                title={'Delete synonym'}
                subText={`Are you sure you want to delete synonym ${synonymToDelete}?`}
                onConfirm={onDeleteConfirmation}
                onDismiss={onDeleteDismiss}
              ></ConfirmationDialog>
            </>
          );
        }}
      </EnvironmentPermissionContext.Consumer>
    </div>
  );
};
