import React, { useState, useEffect, useCallback, useMemo, createRef } from 'react';
import {
  classNamesFunction,
  ActionButton,
  Stack,
  Icon,
  Pivot,
  PivotItem,
  StackItem,
  CommandBarButton,
} from 'office-ui-fabric-react';
import {
  QueryTesterProps,
  QueryTesterStyleProps,
  QueryTesterStyles,
  QueryTesterDetails,
  ViewMode,
  SharedSettings,
  QueryTesterPanel,
} from './QueryTester.types';
import { SplitPageTemplate } from '../../../components/common/PageTemplate/PageTemplate';
import { SideNavMode } from '../../../components/common/PageTemplate/PageTemplate.types';
import { SearchPreview } from '../common/SearchPreview';
import { QueryTesterEditor } from './QueryTesterEditor';
import { useDispatch } from 'react-redux';
import { populateActiveBusinessRule } from '../../../store/actions/customizations/businessRuleActions';
import { useDefaultSearchInstance } from '../../../store/hooks/use-search-instance/useDefaultSearchInstance';
import {
  setActiveSearchInstance,
  resetActiveSearchInstance,
} from '../../../store/actions/customizations/searchInstanceListActions';
import { useQueryTesterAugmentations } from './hooks/useQueryTesterAugmentations';
import { ConditionType } from '../../../utils/customizations/conditions';
import { excludedConditionTypes } from '../../../store/types/customizations/businessRule.d';
import {
  getFieldValueSuggestions,
  resetFieldValueSuggestions,
  toggleIsPaneExpanded,
} from '../../../store/actions/customizations/queryTesterActions';
import { useFacetableStringFields } from '../../../store/hooks/use-index-schema/useFacetableStringFields';
import { ActiveSearchInstanceLoading } from '../../../components/Loading';
import { useSelector } from '../../../store/hooks';
import { SearchPostRequest } from '../../../utils/customizations/search/searchRequest';
import { useDefaultPostBody } from './hooks/useDefaultPostBody';
import { toggleSideNavExpanded } from '../../../store/actions/sideNavActions';
import { Tutorial, TutorialSteps, TutorialsId, FocusState } from '../../../components/Tutorials';
import { useTranslation } from 'react-i18next';
import { startTutorial } from '../../../store/actions/tutorialActions';
import { AvailableTutorials } from '../../../store/types/tutorial.d';
import { isDynamicsTenant } from '../../../utils/dynamics';

const getClassNames = classNamesFunction<QueryTesterStyleProps, QueryTesterStyles>();

export const QueryTesterBase = (props: QueryTesterProps) => {
  const { styles, theme, className } = props;

  const { t } = useTranslation();

  const customizeTreatmentRef = createRef<HTMLDivElement>();
  const containerRef = createRef<HTMLDivElement>();

  const classNames = getClassNames(styles!, {
    theme: theme!,
    className,
  });

  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const defaultSearchInstance = useDefaultSearchInstance();
  const defaultPostBody = useDefaultPostBody();
  const [selectedPanel, setSelectedPanel] = useState<string>(QueryTesterPanel.General);

  const [queryTesterDetails, setQueryTesterDetails] = useState<QueryTesterDetails>({
    generalSettings: { selectedSearchInstance: defaultSearchInstance },
  });

  const activeTenant = useSelector(state => state.tenantsList.activeTenant);
  const isSandboxTenant = useSelector(state => state.tenantsList.activeTenant.isSandbox);
  const completedTutorials = useSelector(state => state.user.sandBoxDetails.completedTutorials);
  const isSideNavExpanded = useSelector(state => state.sideNav.isExpanded);
  const isPaneExpanded = useSelector(state => state.queryTester.isPaneExpanded);

  const [augmentedPostBody, setAugmentedPostBody] = useState<SearchPostRequest>(defaultPostBody);

  const [currentViewMode, setCurrentViewMode] = useState<any>(ViewMode.FORM);

  const facetableStringFields = useFacetableStringFields();

  const i18n = useTranslation();

  const [sharedSettings, setSharedSettings] = useState<SharedSettings>({
    query: isSandboxTenant ? 'coats' : '',
    top: 10,
    skip: 0,
  });

  const [dragPosition, setDragPosition] = useState<number | undefined>(undefined);

  const isDynamics = useMemo(() => isDynamicsTenant(activeTenant.id), [activeTenant.id]);

  const onDragPositionChange = useCallback((pos: number) => {
    setDragPosition(pos);
  }, []);

  // Needed as when searchInstancesList is first loaded we need to update both selectedSearchInstance and activeSearchInstance.
  useEffect(() => {
    if (defaultSearchInstance !== queryTesterDetails.generalSettings.selectedSearchInstance) {
      setQueryTesterDetails(state => ({
        ...state,
        generalSettings: { ...state.generalSettings, selectedSearchInstance: defaultSearchInstance },
      }));
      setAugmentedPostBody(state => ({ ...state, searchInstanceId: defaultSearchInstance }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearchInstance, dispatch]);

  useEffect(() => {
    if (!isPaneExpanded) {
      dispatch(toggleIsPaneExpanded());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(setActiveSearchInstance(defaultSearchInstance));
    return () => {
      dispatch(resetActiveSearchInstance());
    };
  }, [defaultSearchInstance, dispatch]);

  useEffect(() => {
    if (!completedTutorials.includes(TutorialsId.QueryTester)) {
      dispatch(startTutorial(AvailableTutorials.QueryTester));
    }
  }, [completedTutorials, dispatch]);

  useEffect(() => {
    dispatch(getFieldValueSuggestions(facetableStringFields));
    return () => {
      dispatch(resetFieldValueSuggestions());
    };
  }, [dispatch, facetableStringFields]);

  const memoizedAugmentations = useQueryTesterAugmentations(queryTesterDetails);

  const showSupportHint = useMemo(() => {
    return (
      (queryTesterDetails.filterCondition &&
        !!queryTesterDetails.filterCondition.conditions.find(condition =>
          excludedConditionTypes.includes((condition as ConditionType)._type),
        )) ||
      (queryTesterDetails.promoteCondition &&
        !!queryTesterDetails.promoteCondition.condition.conditions.find(condition =>
          excludedConditionTypes.includes((condition as ConditionType)._type),
        )) ||
      (queryTesterDetails.demoteCondition &&
        !!queryTesterDetails.demoteCondition.condition.conditions.find(condition =>
          excludedConditionTypes.includes((condition as ConditionType)._type),
        ))
    );
  }, [queryTesterDetails]);

  const handleCreateBusinessRule = useCallback(() => {
    dispatch(
      populateActiveBusinessRule(
        queryTesterDetails.promoteCondition,
        queryTesterDetails.demoteCondition,
        queryTesterDetails.filterCondition,
        queryTesterDetails.banner,
      ),
    );
  }, [
    dispatch,
    queryTesterDetails.banner,
    queryTesterDetails.demoteCondition,
    queryTesterDetails.filterCondition,
    queryTesterDetails.promoteCondition,
  ]);

  const onRenderFooterActions = useCallback((): JSX.Element => {
    return (
      <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleCreateBusinessRule}>
        create business rule
      </ActionButton>
    );
  }, [handleCreateBusinessRule]);

  const onEditorUpdate = useCallback(
    (queryTesterDetails: QueryTesterDetails, augmentedPostBody: SearchPostRequest) => {
      setQueryTesterDetails(queryTesterDetails);
      setAugmentedPostBody(augmentedPostBody);
      setSortBy(queryTesterDetails.generalSettings.sortBy);
      queryTesterDetails.generalSettings.selectedSearchInstance !== defaultSearchInstance &&
        dispatch(setActiveSearchInstance(queryTesterDetails.generalSettings.selectedSearchInstance));
    },
    [defaultSearchInstance, dispatch],
  );

  const onSharedSettingsChange = useCallback((settings: SharedSettings) => {
    setSharedSettings(settings);
  }, []);

  const editorHeaderActions = useMemo(() => {
    return [
      <Stack horizontal key={'query-actions'}>
        <CommandBarButton
          styles={{ root: { height: 32, lineHeight: 20, padding: 4 } }}
          iconProps={{ iconName: currentViewMode === ViewMode.FORM ? 'QueryTesterCodeEdit' : 'QueryTesterFormEdit' }}
          text={currentViewMode === ViewMode.FORM ? 'Edit code' : 'Edit form'}
          onClick={() =>
            setCurrentViewMode((mode: ViewMode) => (mode === ViewMode.CODE ? ViewMode.FORM : ViewMode.CODE))
          }
        />
      </Stack>,
    ];
  }, [currentViewMode]);

  const onRenderPaneContent = useCallback(() => {
    return (
      <div ref={customizeTreatmentRef} className={classNames.tutorialTargetBackground}>
        <Stack grow verticalFill styles={{ root: { height: '100%' } }} verticalAlign={'space-between'}>
          <StackItem>{editorHeaderActions}</StackItem>
          <Stack grow verticalFill verticalAlign={'space-between'}>
            <QueryTesterEditor
              queryTesterDetails={queryTesterDetails}
              augmentedPostBody={augmentedPostBody}
              sharedSettings={sharedSettings}
              viewMode={currentViewMode}
              selectedPanel={selectedPanel}
              onUpdate={onEditorUpdate}
            />
            {showSupportHint && (
              <Stack
                horizontal
                className={classNames.supportHint}
                tokens={{ childrenGap: 5 }}
                verticalAlign={'baseline'}
              >
                <Icon iconName="Warning" />
                <p>
                  <span>{excludedConditionTypes.join(',')}</span>{' '}
                  <span>{excludedConditionTypes.length > 1 ? 'are ' : 'is '}</span>
                  not supported in Business rules
                </p>
              </Stack>
            )}
          </Stack>
        </Stack>
      </div>
    );
  }, [
    augmentedPostBody,
    classNames.supportHint,
    classNames.tutorialTargetBackground,
    currentViewMode,
    customizeTreatmentRef,
    editorHeaderActions,
    onEditorUpdate,
    queryTesterDetails,
    selectedPanel,
    sharedSettings,
    showSupportHint,
  ]);

  const onRenderLeftSideContent = useCallback(() => {
    return (
      <SearchPreview
        augmentations={memoizedAugmentations}
        augmentedPostBody={augmentedPostBody}
        onSharedSettingsChange={onSharedSettingsChange}
        sortBy={sortBy}
      />
    );
  }, [augmentedPostBody, memoizedAugmentations, onSharedSettingsChange, sortBy]);

  const getTutorialSteps = () => {
    return (
      <>
        <Tutorial
          target={customizeTreatmentRef}
          step={TutorialSteps.QueryTesterTreatmentPanel}
          headline={t('product-tours-panel.quey-tester-tour.headline3')}
        >
          {
            <>
              <p>{t('product-tours-panel.quey-tester-tour.step-3.pargraph1')}</p>
              <p>
                <span className={classNames.tutorialSubheader}>
                  {t('product-tours-panel.quey-tester-tour.step-3.pargraph2.subheading')}
                </span>
                {t('product-tours-panel.quey-tester-tour.step-3.pargraph2.body')}
              </p>
              <p>
                <span className={classNames.tutorialSubheader}>
                  {t('product-tours-panel.quey-tester-tour.step-3.pargraph3.subheading')}
                </span>
                {t('product-tours-panel.quey-tester-tour.step-3.pargraph3.body')}
              </p>
              <p>
                <span className={classNames.tutorialSubheader}>
                  {t('product-tours-panel.quey-tester-tour.step-3.pargraph4.subheading')}
                </span>
                {t('product-tours-panel.quey-tester-tour.step-3.pargraph4.body')}
              </p>
            </>
          }
        </Tutorial>

        <Tutorial
          target={containerRef}
          step={TutorialSteps.QueryTesterNextSteps}
          headline={t('product-tours-panel.quey-tester-tour.headline5')}
          isBeakVisible={true}
          focusOnTarget={FocusState.NoOverlay}
        >
          <>
            <p>{t('product-tours-panel.quey-tester-tour.step-5.pargraph1')}</p>
            <p className={classNames.tutorialSubheader}>{t('product-tours-panel.quey-tester-tour.step-5.pargraph2')}</p>
          </>
        </Tutorial>
      </>
    );
  };

  const getEditorPanelHeader = useCallback(() => {
    return (
      <Pivot
        styles={{ root: { display: 'flex' }, link: { height: 43 }, linkIsSelected: { height: 43 } }}
        selectedKey={selectedPanel}
        onLinkClick={(item?: PivotItem) => {
          setSelectedPanel(
            item && item.props.itemKey ? (item.props.itemKey as QueryTesterPanel) : QueryTesterPanel.General,
          );
        }}
        headersOnly={true}
      >
        <PivotItem headerText={QueryTesterPanel.General} itemKey={QueryTesterPanel.General} />
        <PivotItem headerText={QueryTesterPanel.Promote} itemKey={QueryTesterPanel.Promote} />
        <PivotItem headerText={QueryTesterPanel.Demote} itemKey={QueryTesterPanel.Demote} />
        <PivotItem headerText={QueryTesterPanel.Filter} itemKey={QueryTesterPanel.Filter} />
        <PivotItem headerText={QueryTesterPanel.Banner} itemKey={QueryTesterPanel.Banner} />
      </Pivot>
    );
  }, [selectedPanel]);

  return (
    <ActiveSearchInstanceLoading>
      <>
        {getTutorialSteps()}
        <div className={classNames.root}>
          <SplitPageTemplate
            dragPosition={dragPosition}
            onDragPositionChange={onDragPositionChange}
            pageHeaderProps={{
              title: (
                <div ref={containerRef} style={{ marginRight: 8 }}>
                  {'Query tester'}
                </div>
              ),
              infoProps: {
                content: i18n.t('query-tester.info-content'),
                headline: i18n.t('query-tester.title'),
                linkProps: {
                  linkText: i18n.t('query-tester.info-link'),
                  to: '/docs/Portal%20Documentation/#debug-search-queries-for-product-search',
                },
              },
            }}
            onRenderLeftSideContent={onRenderLeftSideContent}
            collapsiblePaneProps={{
              isExpanded: isPaneExpanded,
              onSwitchStateClicked: () => {
                dispatch(toggleIsPaneExpanded());
              },
              onRenderPaneHeader: getEditorPanelHeader,
              onRenderPaneContent: onRenderPaneContent,
              footerProps: isDynamics ? undefined : { onRenderfooterActions: onRenderFooterActions },
            }}
            breadcrumbVisible={true}
            sideNavMode={isSideNavExpanded ? SideNavMode.Expanded : SideNavMode.Collapsed}
            sideNavOnClick={() => dispatch(toggleSideNavExpanded())}
          />
        </div>
      </>
    </ActiveSearchInstanceLoading>
  );
};
